import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import TextArea from "../../../components/inputs/text-area";
import { API_BASE, isAdmin } from "../../../Utils/helpers";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Countries from "../../../components/country/country";
import BookCoverUpload from "../../publisher/rich-page/components/book-cover-upload";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";

export default function AdminCreateSalesAccount() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [emailConfirmationMode, setEmailConfirmationMode] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [isHuman, setIsHuman] = useState(true);
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [coverFileSelected, setCoverFileSelected] = useState("");
  const [pageCoverPhoto, setPageCoverPhoto] = useState("");
  const [fileSelected, setFileSelected] = useState("");

  const register = () => {
    setIsAuthenticating(true);
    const url = API_BASE + "/admin/sales/create";

    let formData = new FormData();
    formData.append("profile_photo", pageCoverPhoto);
    formData.append("name", firstName + " " + lastName);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("country", country);
    formData.append("country_code", countryCode);
    formData.append("state_province", stateProvince);

    authRequestService()
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          setIsAuthenticating(false);
          toast("Registration Successful", { type: "success" });
          setTimeout(() => {
            window.location.href = "/admin/sales/accounts";
          }, 500);
        }
      })
      .catch((error) => {
        setIsAuthenticating(false);
        if (error.response.status == 422) {
          if ("duplicate_email" in error.response.data) {
            toast("Sorry, the provided email address is already registered!", {
              type: "error",
            });
            return false;
          }
          if ("duplicate_phone" in error.response.data) {
            toast("Sorry, the provided phone number is already registered!", {
              type: "error",
            });
            return false;
          }
        } else {
          toast("Something went wrong!", { type: "error" });
        }
      });
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e);
  };

  const handleLastNameChange = (e) => {
    setLastName(e);
  };

  const handleEmailChange = (e) => {
    setEmail(e);
  };

  const handlePhoneChange = (e) => {
    setPhone(e);
  };

  const handleCountryChange = (e) => {
    let objectTarget = JSON.parse(e.target.value);
    setCountry(objectTarget.country);
    setCountryCode(objectTarget.country_code);
  };

  const handleStateProvinceChange = (e) => {
    setStateProvince(e);
  };

  const handleEmailConfirmation = () => {
    if (
      firstName.toLowerCase().includes("litzapp") ||
      firstName.toLowerCase().includes("litzap") ||
      lastName.toLowerCase().includes("litzapp") ||
      lastName.toLowerCase().includes("litzap")
    ) {
      toast(
        "Sorry! Litzapp cannot be used in First Name Or Last Name Field(s)!",
        {
          type: "error",
        }
      );
      return;
    }
    setEmailConfirmationMode((prev) => !prev);
  };

  const handleCoverPhotoChange = (value) => {
    setFileSelected("");
    setPageCoverPhoto(value);
  };

  const handleSelectedFileChange = (file) => {
    setCoverFileSelected(file);
  };

  const handleRegistration = () => {
    register();
  };

  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
  }, []);

  return (
    <>
      <div className="w-full flex flex-col items-center justify-center">
        <div className="bg-white m-2 p-2 flex justify-between shadow w-full">
          <div>
            <h1 className="text-2xl text-center m-2 font-bold">
              Sales Account
            </h1>
          </div>
          <div className="flex gap-2">
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>{" "}
        <div className="my-4 text-center flex flex-col gap-4 justify-between w-full md:w-10/12">
          <div class="container px-6 mx-auto ">
            <br />
            <div class="flex flex-col text-center md:text-left justify-evenly items-center">
              <div class="flex flex-col w-full items-center">
                <h1 class="text-2xl text-gray-800 font-bold my-2">Create</h1>
                <p class="mx-auto md:mx-0 text-gray-500">
                  Please fill out the form
                </p>
              </div>
              <div className={`w-full md:w-6/12 mx-auto md:mx-0`}>
                <div class="bg-white p-10 flex flex-col w-full shadow-xl rounded-xl">
                  {/* <h2 class="text-2xl font-bold text-gray-800 text-left mb-5">
                Sigin
              </h2> */}
                  <form action="" class="w-full">
                    <div id="input" class="flex flex-col w-full my-5">
                      <label for="username" class="text-gray-500 mb-2">
                        First Name
                      </label>
                      <input
                        onChange={(e) => handleFirstNameChange(e.target.value)}
                        type="text"
                        id="username"
                        value={firstName}
                        placeholder="Please insert your First Name"
                        class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                      />
                    </div>
                    <div id="input" class="flex flex-col w-full my-5">
                      <label for="username" class="text-gray-500 mb-2">
                        Last Name
                      </label>
                      <input
                        onChange={(e) => handleLastNameChange(e.target.value)}
                        type="text"
                        id="username"
                        value={lastName}
                        placeholder="Please insert your Last Name"
                        class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                      />
                    </div>
                    <div id="input" class="flex flex-col w-full my-5">
                      <label for="username" class="text-gray-500 mb-2">
                        Email
                      </label>
                      <input
                        onChange={(e) => handleEmailChange(e.target.value)}
                        type="text"
                        id="username"
                        value={email}
                        placeholder="Please insert your email"
                        class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                      />
                    </div>

                    <div id="input" class="flex flex-col w-full my-5">
                      <label for="username" class="text-gray-500 mb-2">
                        Phone
                      </label>
                      <input
                        onChange={(e) => handlePhoneChange(e.target.value)}
                        type="text"
                        id="phone"
                        value={phone}
                        placeholder="Please insert your email"
                        class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                      />
                    </div>

                    <div id="input" class="flex flex-col w-full my-5">
                      <label for="username" class="text-gray-500 mb-2">
                        Country {country}
                      </label>
                      <Countries handleCountryChange={handleCountryChange} />
                    </div>
                    <div id="input" class="flex flex-col w-full my-5 ">
                      <label
                        for="password"
                        class="text-gray-500 mb-2 text-left"
                      >
                        State Province
                      </label>
                      <input
                        onChange={(e) =>
                          handleStateProvinceChange(e.target.value)
                        }
                        type="text"
                        id="state_province"
                        value={stateProvince}
                        placeholder="Enter State/Province"
                        class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                      />
                    </div>
                    <div className="p-4 relative">
                      <BookCoverUpload
                        setCoverPhoto={handleCoverPhotoChange}
                        setFileSelected={handleSelectedFileChange}
                        fileSelected={coverFileSelected}
                      />
                    </div>

                    <div id="button" class="flex flex-col w-full my-5">
                      <button
                        disabled={
                          isAuthenticating ||
                          !firstName ||
                          !lastName ||
                          !country ||
                          !email ||
                          !stateProvince
                        }
                        onClick={() => handleRegistration()}
                        type="button"
                        class={`w-full py-4 ${
                          isAuthenticating ||
                          !firstName ||
                          !lastName ||
                          !country ||
                          !email ||
                          !stateProvince
                            ? "bg-gray-500"
                            : "bg-black"
                        }  rounded-lg text-green-100`}
                      >
                        {!isAuthenticating ? (
                          <div class="flex flex-row items-center justify-center">
                            <div class="mr-2">
                              <svg
                                class="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                                ></path>
                              </svg>
                            </div>
                            <div class="font-bold">Create</div>
                          </div>
                        ) : (
                          "Creating Account..."
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
