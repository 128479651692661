import {
  isAuthUser,
  authUserData,
  isAdmin,
  isPublisher,
  isSales,
  isSponsor,
} from "../../../../Utils/helpers";
import DesktopHamburgerBar from "./hamburger-bar";
import { useState } from "react";
export default function DesktopRightBar(props) {
  const { handleLogout, togleMobileMenu } = props;

  const showResumeApplicationButton = () => {
    return localStorage.getItem("resume_supplier_application") !== null;
  };

  const getSupplierActionText = () => {
    if (localStorage.getItem("resume_supplier_application") == "redo")
      return "Redo Application";
    if (localStorage.getItem("resume_supplier_application") == "resume")
      return "Resume Application";
  };

  const getRightBarDivision = () => {
    return showResumeApplicationButton() ? "w-6/12" : "w-3/12";
  };
  return (
    <div
      class={`md:flex  md:flex-row items-center justify-end gap-2 ${getRightBarDivision()} ${
        isAuthUser() && !isAdmin() && !isPublisher() ? "w-3/12" : ""
      }`}
    >
      {isAuthUser() &&
        (isAdmin() || isPublisher() || isSales() || isSponsor()) && (
          <p
            onClick={() => {
              if (isAdmin())
                return (window.location.href = "/admin/publishers");
              if (isSales()) return (window.location.href = "/sales/home");
              if (isPublisher())
                return (window.location.href = `/publisher/publications/options/${
                  authUserData().uuid
                }`);
              if (isSponsor())
                return (window.location.href = "/sponsor/dashboard");
            }}
            style={{
              cursor: "pointer",
              textAlign: "right",
              color: "tomato",
            }}
            className=""
          >
            Dashboard
          </p>
        )}

      {isAuthUser() &&
        !isAdmin() &&
        !isPublisher() &&
        !isSales() &&
        !isSponsor() && (
          <a
            href="/user/activation/supplier"
            class="text-sm font-medium text-white hover:text-white mx-2 bg-black rounded-lg p-2 w-full text-center"
          >
            <span>Become a Monetizer</span>
          </a>
        )}

      {isPublisher() && showResumeApplicationButton() && (
        <a
          href="/publisher/supplier/onboarding/start"
          class="text-sm font-medium text-black mx-2 my-bg-yellow-500 rounded-lg p-2  text-center"
        >
          <span>{getSupplierActionText()}</span>
        </a>
      )}
      {isAuthUser() && isPublisher() && !showResumeApplicationButton() && (
        <a
          href={`/publisher/create/options`}
          class="text-sm font-medium text-black mx-2 my-bg-yellow-500 rounded-lg p-2 w-full text-center"
        >
          <span>Create</span>
        </a>
      )}

      {!isAuthUser() && (
        <>
          <a
            href="/login"
            class="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
          >
            {" "}
            Sign in{" "}
          </a>
          <a
            href="/signup/options"
            class="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-black hover:bg-black"
          >
            {" "}
            Create Account{" "}
          </a>
        </>
      )}
      <DesktopHamburgerBar togleMobileMenu={togleMobileMenu} />
    </div>
  );
}

{
  /* <p
          onClick={handleLogout}
          style={{
            cursor: "pointer",
            textAlign: "right",
            color: "tomato",
          }}
          className="mx-2"
        >
          Logout
        </p> */
}
