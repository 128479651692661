import RenderPhotoAlbum from "./photo-album";
import RenderProfilePhotoCircle from "./circle-avatar";
import BasicButton from "../../../buttons/basic-button";
import { BASE_URL } from "../../../../Utils/helpers";
export default function RenderStandardBook(props) {
  const { book, getFristPageOfBook } = props;
  if (book.type == "photo-album") {
    return (
      <RenderPhotoAlbum book={book} getFristPageOfBook={getFristPageOfBook} />
    );
  }
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <div
      className="w-full cursor-pointer"
      onClick={() => {
        if (book.group == "standard") {
          openInNewTab(`/web/reader/${book.uuid}`);
        }
      }}
    >
      <div className="mb-2 w-full">
        <BasicButton
          classes={"my-black text-white px-8"}
          title={"View Item"}
          handleClick={() =>
            (window.location.href = `/web/${book.owneroptimized.uuid}`)
          }
        />
      </div>
      <div class="stacked-card bg-black relative">
        <img
          className="w-full object-cover rounded-full w-32 h-32 md:w-48 md:h-48"
          src={
            BASE_URL + "/storage" + book.cover_photo_thumb.split("public")[1]
          }
        />
        <div className="absolute absolute bottom-5 -left-8 md:-left-8">
          <RenderProfilePhotoCircle book={book} />
        </div>
      </div>
      <div className="w-full flex flex-col items-center">
        {" "}
        <div className="flex  flex-col justify-center cursor-pointer">
          <div className="flex justify-center gap-2">
            <div className="flex"> By:</div>
            <div className="flex">{book.owneroptimized.name}</div>
          </div>
        </div>
        <p>
          {" "}
          <span className="text-yellow-600 text-xs font-bold">
            {book.owneroptimized.username}
          </span>
        </p>
        <div>
          <h1 className="font-bold text-xl text-center">{book.name}</h1>
        </div>
      </div>
    </div>
  );
}
