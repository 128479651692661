import TextArea from "../../../../../../components/inputs/text-area";
export default function ReplyInfo(props) {
  const { meta } = props;
  const { productReplyMeta } = props;
  const { handleProductReplyMetaChange } = props;
  return (
    <div className="w-full flex">
      {" "}
      {meta.purchaser_reply_enabled ? (
        <div className="my-6 w-full border flex flex-col items-center justify-center bg-gray-50 p-2 h-48 overflow-y-scroll">
          <label className="font-bold">{meta.purchaser_reply}</label>
          <TextArea
            placeholder={"Enter text here.."}
            classes="rounded-lg pr-8"
            handleChange={(e) =>
              handleProductReplyMetaChange(e, "reply", "text")
            }
            value={productReplyMeta.reply}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
