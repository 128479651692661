import Checkbox from "../../../../../../../components/inputs/checkbox";
export default function TrackQuantity(props) {
  const { productMeta, handleMetaChange } = props;
  return (
    <div className="flex gap-2 w-full justify-center">
      <div>
        <Checkbox
          handleChange={(e) => {
            if (productMeta.quantity_enabled) {
              handleMetaChange("", "quantity", "text");
            }
            handleMetaChange(e, "quantity_enabled", "checkbox");
          }}
          checked={productMeta.quantity_enabled}
        />
      </div>
      <span>Track Quantity</span>
    </div>
  );
}
