import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import EditorTooltip from "../../tooltip/editor-tooltip";
import BasicButton from "../../../../../../../components/buttons/basic-button";

export default function BlockedStartDates(props) {
  const {
    productMeta,
    addBlockedDate,
    openDatePicker,
    removeBlockedDate,
    setPickerOpen,
    pickeRref,
    pickerOpen,
  } = props;
  return (
    <>
      {" "}
      <div className="flex flex-col items-center gap-2 w-full md:w-6/12 my-5 relative">
        <div className="flex gap-2">
          <label for="timezone" value="Your Timezone" className="font-bold">
            Blocked Start Dates
          </label>
          <EditorTooltip
            body={
              "Blocked Start Dates are dates when a <br/ > subscription cannot begin. However, if a subscription starts <br/ > on an available date and later overlaps <br/ > with a Blocked Start Date,<br/ > it will still continue since it didn't start on a blocked date."
            }
            top={"-295px"}
            right={"-35px"}
          />
        </div>
        {pickerOpen ? (
          <DatePicker
            ref={pickeRref}
            onFocus={(e) => e.target.blur()}
            onClickOutside={() => setPickerOpen(false)}
            disabled
            onChange={(date) => {
              if (
                productMeta.blocked_dates &&
                productMeta.blocked_dates.length
              ) {
                const exists = productMeta.blocked_dates.filter(
                  (d) =>
                    date
                      .toLocaleString("en-GB", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                      })
                      .replace(/\//g, "-") ===
                    d.date
                      .toLocaleString("en-GB", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                      })
                      .replace(/\//g, "-")
                );
                if (exists.length) {
                  toast("Date already added to Blocked Start Dates", {
                    type: "error",
                  });
                  return false;
                }
              }
              addBlockedDate(date);
            }}
            className="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer border"
            minDate={new Date()}
            open={true}
            dateFormat="dd/MM/YYYY"
          />
        ) : (
          ""
        )}
      </div>
      <BasicButton
        classes={"bg-gray-50 border text-black hover:text-white"}
        title={"Add Date +"}
        handleClick={() => openDatePicker()}
      />
      {productMeta.blocked_dates && productMeta.blocked_dates.length ? (
        <div className="h-24 overflow-auto w-full my-1 flex flex-col items-center">
          {productMeta.blocked_dates.map((d, i) => (
            <p className="m-2 mb-2 w-8/12 bg-gray-50 p-2 relative text-center">
              <span
                className="bg-white rounded rounded-full bg-white p-0 top-0 right-0 absolute px-1 cursor-pointer"
                onClick={() => removeBlockedDate(i)}
              >
                <i class="fa fa-times-circle text-red-500 z-50 text-2xl"></i>
              </span>

              <label className="">
                {d.date.toLocaleString("en-GB", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
              </label>
            </p>
          ))}
        </div>
      ) : (
        ""
      )}
    </>
  );
}
