import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BasicButton from "../../../../components/buttons/basic-button";
import TextField from "../../../../components/inputs/text-input";
import Pagination from "../../../../components/pagination";
import useDebounce from "../../../../components/debounce/debounce-helper";
import LoadingProgress from "../../../publisher/rich-page/components/loading-progress";
import { useSendMailStore } from "../../../../stores/send-mail-store";
import FilterBar from "./components/filter";
import authRequestService from "../../../../services/HttpRequests/authenticated-request-services";
import authPaginatedRequestService from "../../../../services/HttpRequests/authenticated-paginated-req-service";
import DesktopTable from "./components/desktop-table";
import MobileTable from "./components/mobile-table";

import { isAdmin, API_BASE, isResponsiveMode } from "../../../../Utils/helpers";

import { toast } from "react-toastify";
export default function AdminReaderMyProductPurchases() {
  const [currentPage, setCurrentPage] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [paginateMode, setPaginateMode] = useState(false);
  const [user, setUser] = useState({});
  const [blockedEmails, setBlockedEmails] = useState([]);
  const mountedRef = useRef();
  const navigate = useNavigate();
  const mailStore = useSendMailStore((state) => state);

  const { uuid } = useParams();

  const fetchUser = () => {
    const url = API_BASE + "/publisher/" + uuid + "/optimized";
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setUser(response.data.publisher);
          mailStore.setUser(response.data.publisher);
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  const doPagination = (page) => {
    if (mailStore.data.first_page_url) {
      mailStore.setData([]);
      setCurrentPage(page);
      setPaginateMode(true);
      setProcessing(true);
      authPaginatedRequestService()
        .get(mailStore.data.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          mailStore.setData(res.data.purchases);
          mailStore.setIds(res.data.ids);
          setProcessing(false);
        })
        .catch((err) => setProcessing(false));
    }
  };

  const getPurchases = () => {
    mailStore.setData([]);
    setProcessing(true);
    setPaginateMode(false);

    const url =
      API_BASE +
      "/admin/non-monetizer/my/product/purchases/" +
      uuid +
      "/" +
      mailStore.query.countPerPage +
      "/" +
      mailStore.query.searchText +
      " ";

    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          mailStore.setData(response.data.purchases);
          setProcessing(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const handleSearchTextChange = (e) => {
    mailStore.setQuery("searchText", e);
  };

  const handleCountPerPageChange = (e) => {
    mailStore.setQuery("countPerPage", e.target.value);
  };

  const handlePurchaseOptionChange = (e) => {
    mailStore.setQuery("purchaseOption", e.target.value);
  };

  const handleLatestEntriesChange = (e) => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
    mailStore.setQuery("selectedLatest", e.target.value);
    mailStore.setQuery("pageLimit", e.target.value);
  };

  const handleOldestEntriesChange = (e) => {
    // mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
    mailStore.setQuery("selectedOldest", e.target.value);
    mailStore.setQuery("pageLimit", e.target.value);
  };

  useEffect(() => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedLatest]);

  useEffect(() => {
    // mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedOldest]);

  const clearFilter = () => {
    mailStore.resetQuery();
  };

  useDebounce(
    () => {
      if (mailStore.refresh) {
        getPurchases();
      }
      mailStore.setRefresh(true);
    },
    [mailStore.query],
    800
  );

  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
    fetchUser();
  }, []);

  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">
              {" "}
              Product Buys:{" "}
              <span className="text-yellow-600 font-bold">
                {user?.handle_id}
              </span>
            </h1>
          </div>
          <div className="flex justify-between gap-2 items-start">
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            <p className="w-full text-center my-4">
              We found {mailStore.data.total ? mailStore.data.total : ""}{" "}
              records
            </p>
            {/* <FilterBar
              handleCountPerPageChange={handleCountPerPageChange}
              handleSearchTextChange={handleSearchTextChange}
              serchQValue={mailStore.query.searchText}
              handleLatestEntriesChange={handleLatestEntriesChange}
              latestQValue={mailStore.query.selectedLatest}
              handleOldestEntriesChange={handleOldestEntriesChange}
              handlePurchaseOptionChange={handlePurchaseOptionChange}
              purchaseOption={mailStore.query.purchaseOption}
              oldestQValue={mailStore.query.selectedOldest}
              clearFilter={clearFilter}
              model={"product-purchases"}
            /> */}

            <div className="w-full flex justify-center">
              {mailStore.data.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            {isResponsiveMode() ? <MobileTable /> : <DesktopTable />}
            <LoadingProgress loading={processing} />
            <div className="w-full flex justify-center">
              {mailStore.data.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
