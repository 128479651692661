import { isResponsiveMode } from "../../../../Utils/helpers";
import ColorPicker, { colorPickerPlugin } from "draft-js-color-picker";

import {
  BlockStyleControls,
  InlineStyleControls,
  changeIndent,
  applyFont,
} from "../helpers/functions";
import PremiumButton from "./premium-button";
import PaymentControls from "./payment-controls";
import InsertAdvert from "./insert-advert";
import VideoButton from "./video-button";
import ImageButton from "./image-button";
import TableButton from "./table-button";
import ButtonLink from "./button-link";
import HireButton from "./hire-button";
import AccordionButton from "./accordion-button";
import IndentLeftIcon from "../resources/IndentLeftIcon";
import IndentRightIcon from "../resources/IndentRightIcon";
import InsertLinkButton from "./insert-link-button";
import { PRESET_COLORS } from "../editor-objects";
import ApplyFont from "./font-component";
import ProductMetaButton from "./product-meta-button";
import SelectImagesButton from "./select-images";
import BannerButton from "./banner-button";
import Socials from "./socials";
export default function ToolBar(props) {
  const {
    showAccordionModalCreateBox,
    showTableModalCreateBox,
    showPaymentControlsBox,
    showImageModalCreateBox,
    insertSignupBoxIntoEditor,
    showButtonLinkModalBox,
    showProductMetaSetupBox,
    cannotDisplaySPButton,
    cannotDisplayREButton,
    displaySelectImagesBox,
    canDisplayImageOptions,
    displayProductMeta,
    getDefaultKeyBinding,
    setDefaultFontSize,
    showVideoModalBox,
    showSocialsLinkBox,
    displayBannerBox,
    selectionHasLink,
    AtomicBlockUtils,
    selectionAnchors,
    defaultFontSize,
    setEditorOutput,
    setEditorState,
    onInlineClick,
    EditorState,
    onBlockClick,
    makePremium,
    editorState,
    showLinkBox,
    editorType,
    showAdBox,
    RichUtils,
    addSpace,
    indentRef,
    Modifier,
    picker,
    page,
    user,
  } = props;

  const isSubEnabled = () => {
    if (page && page.owner) {
      if (page.owner.enable_subscription) {
        return true;
      }
      return false;
    }

    if (user && user.id) {
      if (user.enable_subscription) {
        return true;
      }
      return false;
    }
    return false;
  };
  //...
  const isAdVisibilityEnabled = () => {
    if (page && page.owner) {
      if (page.owner.system_setting.ads_controls_visibility) {
        return true;
      }
      return false;
    }

    if (user && user.id) {
      if (user.system_setting.ads_controls_visibility) {
        return true;
      }
      return false;
    }

    return false;
  };
  return (
    <>
      {" "}
      {/* Menu bar start */}
      <div
        className={`flex flex-col gap-1 bg-white sticky-menu pt-2 z-50  ${
          !isResponsiveMode() ? " shadow" : ""
        } `}
        id="my-sp-toolbar"
      >
        {/* new button layout text buttons */}

        <div>
          {" "}
          {editorType == "product" && (
            <ProductMetaButton
              displayProductMeta={displayProductMeta}
              showProductMetaSetupBox={showProductMetaSetupBox}
              cannotDisplaySPButton={cannotDisplaySPButton}
              cannotDisplayREButton={cannotDisplayREButton}
            />
          )}
          {editorType == "product" && (
            <SelectImagesButton
              canDisplayImageOptions={canDisplayImageOptions}
              displaySelectImagesBox={displaySelectImagesBox}
            />
          )}
          {editorType == "content" && (
            <PremiumButton
              editorState={editorState}
              onToggle={(toggledColor) =>
                makePremium(
                  toggledColor,
                  editorState,
                  Modifier,
                  EditorState,
                  RichUtils,
                  setEditorState
                )
              }
            />
          )}
          {isSubEnabled() && editorType == "content" && (
            <PaymentControls
              editorState={editorState}
              showPaymentControlsBox={showPaymentControlsBox}
            />
          )}
          {isAdVisibilityEnabled() && editorType == "content" && (
            <InsertAdvert editorState={editorState} showAdBox={showAdBox} />
          )}
          {/* <VideoButton showVideoModal={showVideoModalBox} /> */}
          <ImageButton showImageModal={showImageModalCreateBox} />
          <button
            className="border border-gray rounded-lg p-1"
            onClick={() => {
              insertSignupBoxIntoEditor(
                "x",
                editorState,
                AtomicBlockUtils,
                EditorState,
                setEditorState
              );
            }}
          >
            Email Updates{" "}
          </button>
          <ButtonLink showButtonLinkModal={showButtonLinkModalBox} />
          <TableButton showTableModalCreateBox={showTableModalCreateBox} />
          <AccordionButton
            showAccordionModalCreateBox={showAccordionModalCreateBox}
          />
          <BannerButton displayBannerBox={displayBannerBox} />
          {/* {editorType !== "content" && (
            <Socials showSocialsLinkBox={showSocialsLinkBox} />
          )} */}
          {/* <HireButton showHireModal={showHireModalCreateBox} /> */}
        </div>
        {/* new button layout text buttons */}

        <div className="w-full flex">
          <BlockStyleControls
            onToggle={onBlockClick}
            editorState={editorState}
          />
          <InlineStyleControls onToggle={onInlineClick} />
          <IndentLeftIcon
            handleClick={(e) =>
              changeIndent(
                e,
                "increase",
                RichUtils,
                editorState,
                setEditorOutput,
                setEditorState,
                getDefaultKeyBinding,
                indentRef
              )
            }
          />
          <IndentRightIcon
            handleClick={(e) =>
              changeIndent(
                e,
                "decrease",
                RichUtils,
                editorState,
                setEditorOutput,
                setEditorState,
                getDefaultKeyBinding,
                indentRef
              )
            }
          />
          <div className="hidden md:flex gap-2">
            <div className="bg-gray-50 px-1 rounded-lg flex flex-col">
              <span>color</span>
              <ColorPicker
                toggleColor={(color) => picker.addColor(color)}
                presetColors={PRESET_COLORS}
                color={picker.currentColor(editorState)}
              />
            </div>
            <ApplyFont
              editorState={editorState}
              onClick={(toggledColor) => {
                applyFont(
                  toggledColor,
                  editorState,
                  Modifier,
                  EditorState,
                  RichUtils,
                  setEditorState
                );
              }}
              defaultFontSize={defaultFontSize}
              setDefaultFontSize={setDefaultFontSize}
            />
            <InsertLinkButton
              addSpace={addSpace}
              showLinkBox={showLinkBox}
              editorState={editorState}
              selectionHasLink={selectionHasLink}
              selectionAnchors={selectionAnchors}
              setEditorOutput={setEditorOutput}
              setEditorState={setEditorState}
            />
          </div>
        </div>
        <div className="flex w-full gap-2 md:hidden">
          <div className="bg-gray-50 px-1 rounded-lg flex flex-col">
            <span>color</span>
            <ColorPicker
              toggleColor={(color) => picker.addColor(color)}
              presetColors={PRESET_COLORS}
              color={picker.currentColor(editorState)}
            />
          </div>
          <ApplyFont
            editorState={editorState}
            onClick={(toggledColor) => {
              applyFont(
                toggledColor,
                editorState,
                Modifier,
                EditorState,
                RichUtils,
                setEditorState
              );
            }}
            defaultFontSize={defaultFontSize}
            setDefaultFontSize={setDefaultFontSize}
          />
          <InsertLinkButton
            addSpace={addSpace}
            showLinkBox={showLinkBox}
            editorState={editorState}
            selectionHasLink={selectionHasLink}
            selectionAnchors={selectionAnchors}
            setEditorOutput={setEditorOutput}
            setEditorState={setEditorState}
          />
        </div>
      </div>
      {/* Menu bar end */}
    </>
  );
}
