import TextField from "../components/inputs/text-input";
import BasicButton from "../components/buttons/basic-button";
import { useBookStore } from "../stores/book-store";
import EditBook from "../pages/publisher/book/edit-book";

export default function LinkOutsidePromptModal({
  modalOpen,
  hideAdModal,
  title,
  yesActionText,
  noActionText,
  processing,
  linkOutsideHref,
  action,
}) {
  const { pageAdvertId } = useBookStore((state) => state);
  return (
    <div
      className={`fixed overflow-y-auto top-1/2 -translate-y-1/2 w-full left-0 z-999999 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-6/12 h-4/12 "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <h1 className="text-1xl">{title}</h1>
            <p className="text-yellow-600">{linkOutsideHref}</p>
            <div className="flex justify-center gap-2 my-2">
              {" "}
              <BasicButton
                disabled={processing}
                title={processing ? "Deleting.." : yesActionText}
                handleClick={() => action()}
                classes={
                  processing
                    ? "my-bg-yellow-500 text-black"
                    : "my-bg-yellow-500 my-bg-yellow-500 text-black"
                }
              />{" "}
              <BasicButton
                title={noActionText}
                handleClick={() => hideAdModal()}
                classes={processing ? "bg-black-50" : "bg-black"}
              />
            </div>
            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideAdModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
            {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
