import { isResponsiveMode } from "../../Utils/helpers";
import { Document, Page, pdfjs } from "react-pdf";
export default function RenderTopResponsiveAdMedia(props) {
  const {
    pageNumber,
    portraitMode,
    views,
    page,
    onDocumentLoadSuccess,
    isAdPage,
    isPublisherAdPage,
    isSystemAdPage,
  } = props;

  const getPrevPageIndex = () => {
    if (isPublisherAdPage()) {
      return 1;
    }
    if (isSystemAdPage()) {
      return 2;
    }
    return 1;
  };

  return (
    <>
      {isResponsiveMode() &&
        portraitMode &&
        isAdPage() &&
        views[page - getPrevPageIndex()].type == "image" && (
          <div className="flex items-center justify-center">
            <img
              src={
                process.env.PUBLIC_URL + views[page - getPrevPageIndex()].file
              }
              width="85%"
            />
          </div>
        )}
      {isResponsiveMode() &&
        portraitMode &&
        isAdPage() &&
        views[page - getPrevPageIndex()].type == "pdf" && (
          <div className="flex items-center justify-center margin-center">
            <Document
              file={
                process.env.PUBLIC_URL + views[page - getPrevPageIndex()].file
              }
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} />
            </Document>
          </div>
        )}
    </>
  );
}
