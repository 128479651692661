export default function AdminDesktopTable({ data }) {
  const getSelectorContext = (a, b, c, d) => {
    return Number(a) + Number(b) + Number(c) + Number(d);
  };
  return (
    <>
      <div className="overflow-x-scroll md:h-screen w-full">
        <table class="table-auto w-full">
          <thead className="text-base">
            <tr className={window.innerWidth < 720 ? "hidden" : ""}>
              <th class="px-4 py-2 text-gray-600">Item</th>
              <th class="px-4 py-2 text-gray-600">Sorting Score</th>
              <th class="px-4 py-2 text-gray-600">Recency</th>
              <th class="px-4 py-2 text-gray-600">Rating</th>
              <th class="px-4 py-2 text-gray-600">Views</th>
              <th class="px-4 py-2 text-gray-600">Purchase</th>
            </tr>
          </thead>
          <tbody className="w-full">
            {data.data
              ? data.data.map((p, i) => {
                  return (
                    <tr
                      className={
                        window.innerWidth < 820
                          ? `flex flex-col items-center my-8 w-full bg-gray-50 p-4 `
                          : ""
                      }
                    >
                      <td class="border  px-2 py-2  font-medium text-center md:text-left">
                        <p>
                          <b>Item Title:&nbsp;</b>
                          <span className="my-text-yellow-500 font-bold">
                            {p?.name}{" "}
                          </span>
                        </p>
                        <p>
                          {" "}
                          <b>Item ID:&nbsp;</b>
                          <span className="">{p?.item_id} </span>
                        </p>
                        <p>
                          <b>Publisher Name:&nbsp;</b>
                          <span className="">{p?.owneroptimized?.name} </span>
                        </p>
                        <p>
                          {" "}
                          <b>User Handle:&nbsp;</b>
                          <span className="">
                            {" "}
                            {p?.owneroptimized?.handle_id}{" "}
                          </span>
                        </p>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs">
                          Date Created
                        </h3>
                        {getSelectorContext(
                          p.is_recent,
                          p.avg_count,
                          p.t_views_count,
                          p.product_subscribers_count
                        )}
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center text-center">
                        <p className="font-bold  text-xxs">Date Created</p>
                        {p.refactoredCreatedAtTime}
                        <br /> <br />
                        <p className="font-bold  text-xxs">Sort Score</p>
                        {p.is_recent}
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center text-center">
                        <p className="font-bold  text-xxs">Average Rating:</p>
                        {p.average_rating_count}
                        <br /> <br />
                        <p className="font-bold  text-xxs">Sort Score</p>
                        {p.avg_count}
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center text-center">
                        <p className="font-bold  text-xxs">Total Views:</p>
                        {p.total_sort_views_count}
                        <br /> <br />
                        <p className="font-bold  text-xxs">Sort Score</p>
                        {p.t_views_count}
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center">
                        <p className="font-bold  text-xxs">Purchase Count:</p>
                        {p.total_sort_purchase_count}
                        <br /> <br />
                        <p className="font-bold  text-xxs">Sort Score</p>
                        {p.product_subscribers_count}
                      </td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
      </div>
    </>
  );
}
