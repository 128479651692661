import TextField from "../components/inputs/text-input";
import BasicButton from "../components/buttons/basic-button";
import { useBookStore } from "../stores/book-store";
import { toast } from "react-toastify";

export default function AdInsertModal({
  modalOpen,
  hideAdModal,
  requestAdvert,
  advertRequestStatus,
}) {
  const { pageAdvertId } = useBookStore((state) => state);

  const copyText = (text) => {
    // standard way of copying
    var textArea = document.createElement("textarea");
    textArea.setAttribute("style", "width:1px;border:0;opacity:0;");
    document.body.appendChild(textArea);
    textArea.value = text;
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    toast("Copied", { type: "success" });
  };

  const doCopy = () => {
    copyText(`Upon submitting your Ad Code request, it will be sent to the administrator for approval. If your request is auto-approved, ads will immediately start displaying within your content as you insert them. However, please be aware that the purpose of auto-approved ads is to allow Litzapp and our ad partners to monitor the content you publish on Litzapp and assess your adherence to the rules of managing ads within your content, which include:

1. Avoid clicking on your own ads: If detected, our partners will promptly cease serving ads to your account. This rule is maintained even after the seven-day probationary period.
2. Avoid inserting ads within the following types of content:
   - Pornographic
   - Illegal
   - Plagiarized
   - Unauthorized
   - Threatening
   - Terroristic
   - Misleading
   - In violation of any intellectual property rights

Failure to comply with any of these rules, even after the seven-day probationary period, will result in our partners terminating the delivery of ads to your account and may potentially lead to the termination of your Litzapp account.

After the seven-day probationary period, you will have access to the "My Ad Results" link in your dashboard menu. Once this appears on your dashboard, you can review the performance of your ads and the revenue they generate, as determined by our ad partners (please note that Litzapp has no control over the information shown in your ads performance report). Please be aware that the "My Ad Results" link may appear in your dashboard menu earlier than the designated seven-day period, indicating a shortened probationary period.

After clicking the "I Accept & Copy" button, save the copied information in a file for easy access. Then, publish your content and navigate to the dashboard link in the menu. Scroll down to locate the published content, click the "Share" button to copy the content link, and log out of your account (if using a browser). Paste the copied link in the browser's URL bar to view the ad displayed within your content (please be aware that if you are logged in via a browser, you may not be able to see the ad displayed within your own content. This depends on which of our partners is serving ads to your account. To view the ad displayed within your content, please log out of your account and then proceed to view your content).

If you are not seeing any ads displayed in your published content, there are a few possible reasons. First, please ensure that you have properly "Inserted Ad" or saved your content if you published content on a custom page; please double-check your content. Additionally, it's possible that your Ad Code Request was not auto-approved. If your Ad Code Request was not auto-approved, please wait between seven (7) days to four (4) weeks for the Litzapp Ads Team to review your request. If there are still no ads displayed after the four (4) week waiting period, it indicates that Litzapp was unable to secure any of our ad partners to serve ads to your account.`);
  };
  return (
    <div
      className={`fixed z-40 overflow-y-auto top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-6/12 h-4/12 "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <h1 className="text-2xl font-bold text-center">
                {/* {getActiveWidgetTitle(currentEditingWidget, widgets)} */}
                Request Ad Code
              </h1>
            </div>

            <div className="p-2 flex justify-center text-lg">
              {advertRequestStatus == "rejected" ? (
                <p>Your Ad Code Request was rejected.</p>
              ) : (
                ""
              )}
            </div>

            <div className="p-2 text-left text-lg h-48 overflow-y-scroll flex flex-col items-start justify-start gap-2">
              {advertRequestStatus == "" ? (
                <>
                  <p>
                    Upon submitting your Ad Code request, it will be sent to the
                    administrator for approval. If your request is
                    auto-approved, ads will immediately start displaying within
                    your content as you insert them. However, please be aware
                    that the purpose of auto-approved ads is to allow Litzapp
                    and our ad partners to monitor the content you publish on
                    Litzapp and assess your adherence to the rules of managing
                    ads within your content, which include:
                  </p>
                  <ul>
                    <li>
                      {" "}
                      1. Avoid clicking on your own ads: If detected, our
                      partners will promptly cease serving ads to your account.
                      This rule is maintained even after the seven-day
                      probationary period.
                    </li>
                    <li>
                      <b>
                        2. Avoid inserting ads within the following types of
                        content:
                      </b>{" "}
                      <ul>
                        {" "}
                        <li> - Pornographic</li>
                        <li> - Illegal</li>
                        <li>- Plagiarized</li>
                        <li>- Unauthorized</li>
                        <li> - Threatening</li>
                        <li> - Terroristic</li>
                        <li>- Misleading</li>
                        <li>- Content with over forty percent ad inserts</li>
                        <li>
                          {" "}
                          - In violation of any intellectual property rights
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p>
                    Failure to comply with any of these rules, even after the
                    seven-day probationary period, will result in our partners
                    terminating the delivery of ads to your account and may
                    potentially lead to the termination of your Litzapp account.
                  </p>

                  <p>
                    After the seven-day probationary period, you will have
                    access to the "My Ad Results" link in your dashboard menu.
                    Once this appears on your dashboard, you can review the
                    performance of your ads and the revenue they generate, as
                    determined by our ad partners (please note that Litzapp has
                    no control over the information shown in your ads
                    performance report). Please be aware that the "My Ad
                    Results" link may appear in your dashboard menu earlier than
                    the designated seven-day period, indicating a shortened
                    probationary period.
                  </p>

                  <p>
                    After clicking the "I Accept & Copy" button, save the copied
                    information in a file for easy access. Then, publish your
                    content and navigate to the dashboard link in the menu.
                    Scroll down to locate the published content, click the
                    "Share" button to copy the content link, and log out of your
                    account (if using a browser). Paste the copied link in the
                    browser's URL bar to view the ad displayed within your
                    content (please be aware that if you are logged in via a
                    browser, you may not be able to see the ad displayed within
                    your own content. This depends on which of our partners is
                    serving ads to your account. To view the ad displayed within
                    your content, please log out of your account and then
                    proceed to view your content).
                  </p>

                  <p>
                    If you are not seeing any ads displayed in your published
                    content, there are a few possible reasons. First, please
                    ensure that you have properly "Inserted Ad" or saved your
                    content if you published content on a custom page; please
                    double-check your content. Additionally, it's possible that
                    your Ad Code Request was not auto-approved. If your Ad Code
                    Request was not auto-approved, please wait between seven (7)
                    days to four (4) weeks for the Litzapp Ads Team to review
                    your request. If there are still no ads displayed after the
                    four (4) week waiting period, it indicates that Litzapp was
                    unable to secure any of our ad partners to serve ads to your
                    account.
                  </p>
                  <></>
                </>
              ) : (
                ""
              )}
            </div>

            {advertRequestStatus == "unprocessed" ? (
              <>
                <div className="p-2 flex justify-center text-lg">
                  <p>
                    While ads are showing within your content right now, You
                    will be able to view your ad earnings table in 5 days, in
                    the meantime we'll be monitorin your ad earnings.
                  </p>{" "}
                </div>
                <div className="p-2 flex flex-col justify-center text-lg">
                  <p className="font-bold">
                    Email Subject: The subject of the email should be "Ad Code
                    Request by [insert your User Handle Here]."
                  </p>
                </div>
                <div className="p-2 flex flex-col  justify-center text-lg">
                  <p className="font-bold">
                    Email Body: In the email body, please state your User Handle
                    (located in your dashboard under the Username) and indicate
                    when you first sent the request. If sending a second
                    reminder, also mention the date of your initial reminder.
                  </p>
                </div>
              </>
            ) : (
              ""
            )}

            <div className="flex justify-center">
              {advertRequestStatus == "" ? (
                <BasicButton
                  title={"I Accept & Copy"}
                  classes="px-8 w-8/12"
                  handleClick={() => {
                    doCopy();
                    requestAdvert("from_modal");
                  }}
                />
              ) : (
                ""
              )}
            </div>

            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideAdModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
            {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
