import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BasicButton from "../../../components/buttons/basic-button";
import TextField from "../../../components/inputs/text-input";
import TextArea from "../../../components/inputs/text-area";
import Pagination from "../../../components/pagination";
import useDebounce from "../../../components/debounce/debounce-helper";
import LoadingProgress from "../rich-page/components/loading-progress";
import Checkbox from "../../../components/inputs/checkbox";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSendMailStore } from "../../../stores/send-mail-store";
import DeletePromptModal from "../../../modals/delete-warning-modal";
import MailSuccessPromptModal from "../../../modals/mail-sent-success-modal";
import UpdateEmailBody from "./components/email-body-templates/update-email-body";
import AdditionEmailBody from "./components/email-body-templates/addition-email-body";
import PriceChangeEmailBody from "./components/email-body-templates/price-change-body";
import SubscriptionPriceChangeEmailBody from "./components/email-body-templates/subscription-price-change-body";
import ReleaseDateEmailEmailBody from "./components/email-body-templates/release-date-email-body";

import {
  isAdmin,
  API_BASE,
  isPublisher,
  isAuthUser,
  authUserData,
  getLocation,
  generateItemLink,
} from "../../../Utils/helpers";
import { toast } from "react-toastify";
import Books from "../series/books";

export default function PubSendMail() {
  const [consentMessage, setConsentMessage] = useState("");
  const [consentModalOpen, setConsentModalOpen] = useState(false);
  const [mailuccessModalOpen, setMailSuccessModalOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const mailStore = useSendMailStore((state) => state);

  const { model } = useParams();

  const fetchUser = () => {
    const url = API_BASE + "/publisher/" + authUserData().uuid + "/optimized";
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setUser(response.data.publisher);
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  const canSendMail = () => {
    const url = API_BASE + "/user/" + authUserData().uuid;
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((d) => {
        if (
          Number(d.data.user.email_dispatch_limit) <
          Number(mailStore.selectedCount)
        ) {
          navigate("/publisher/mailing/email-purchase/limit/" + model);
        } else {
          setConsentMessage(`Are you sure you want to send an email to ${
            mailStore.selectedCount
          } recipients? 
Email Subject: ${getEmailSubject(mailStore.subject)} : ${
            mailStore.currItemName
          }`);
          setConsentModalOpen(true);
        }
        setProcessing(false);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const sendMail = () => {
    setProcessing(true);
    const url = API_BASE + `/publisher/mail/send/${model}`;
    const formData = new FormData();
    formData.append("greeting", mailStore.greeting);
    formData.append("item", mailStore.item);
    formData.append(
      "start_date",
      mailStore.startDate.toLocaleString("en-GB", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    );
    formData.append("time", mailStore.time);
    formData.append("subject", mailStore.subject);
    formData.append("am_pm", mailStore.AMPM);
    formData.append("price", mailStore.price);
    formData.append("body", mailStore.body);
    formData.append("selection_data", "");
    formData.append("recipient_name_type", mailStore.recipientNameType);
    formData.append("selType", mailStore.selType);
    formData.append("selected", JSON.stringify(mailStore.selected));
    formData.append("deSelected", JSON.stringify(mailStore.deSelected));
    formData.append("selectedCount", mailStore.selectedCount);
    formData.append("isAllSelected", mailStore.isAllSelected ? 1 : 0);
    formData.append("queryBag", JSON.stringify(mailStore.query));

    authRequestService()
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          fetchUser();
          toast("Success!", { type: "success" });
          mailStore.resetSendPageState();
          mailStore.resetMailStore();
          setTimeout(() => {
            exitPage();
          }, 3000);
        }
        setProcessing(false);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
        setProcessing(false);
      });
  };

  const getBooks = () => {
    mailStore.setBooks([]);
    const url =
      API_BASE + `/books/publisher/no-pagination/all/${authUserData().uuid}`;
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.books) {
            mailStore.setBooks(response.data.books);
          }
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const unloadCallback = (event) => {
    console.log("EVENT", event);
    event.preventDefault();
    event.returnValue = "";
    return "";
  };

  useEffect(() => {
    if (!isPublisher()) {
      window.location.href = "/";
    }
    getBooks();
    fetchUser();

    if (!mailStore.isAllSelected && mailStore.selectedCount == 0) {
      if (model == "purchases") {
        window.location.href = "/publisher/purchases";
      }
      if (model == "subscribers") {
        window.location.href = "/publisher/subscribers";
      }
      if (model == "donations") {
        window.location.href = "/publisher/donations";
      }
      if (model == "email-subscribers") {
        window.location.href = "/publisher/subscriptions";
      }
      if (model == "trackers") {
        window.location.href = "/publisher/trackers";
      }
      if (model == "product-subscriptions") {
        window.location.href = "/publisher/product-transactions/recurring";
      }
      if (model == "product-purchases") {
        window.location.href = "/publisher/product-transactions/single";
      }
    }
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  const handleGreetingChange = (e) => {
    mailStore.setGreeting(e.target.value);
  };
  const handleRecipientNameChange = (e) => {
    mailStore.setRecipientNameType(e.target.value);
  };
  const handleItemChange = (e) => {
    mailStore.setItem(e.target.value);
  };
  const handleSubjectChange = (e) => {
    mailStore.setSubject(e.target.value);
  };
  const handleTimeChange = (e) => {
    mailStore.setTime(e.target.value);
  };
  const handleAMPMChange = (e) => {
    mailStore.setAMPM(e.target.value);
  };

  const handlePriceChange = (e) => {
    mailStore.setPrice(e);
  };

  const handleBodyChange = (e) => {
    mailStore.setBody(e);
  };

  const handleLanguageChange = (e) => {
    mailStore.setLanguage(e.target.value);
  };

  const hideConsentModal = () => {
    setConsentModalOpen(false);
  };

  const hideMailSuccessModal = () => {
    setMailSuccessModalOpen(false);
  };

  const openSuccessModal = () => {
    setMailSuccessModalOpen(false);
  };

  const exitPage = () => {
    mailStore.setRefresh(true);
    window.history.back();
  };

  const getEmailSubject = (slug) => {
    if (slug == "update") return "Update";
    if (slug == "addition_notification") return "Addition Notification";
    if (slug == "price_change") return "Price Change";
    if (slug == "release_date_update") return "Release Date Update";
    if (slug == "subscription_price_change") return "Subscription Price Change";
    return "";
  };

  const shouldRenderBody = () => {
    if (
      mailStore.language &&
      mailStore.item &&
      mailStore.greeting &&
      mailStore.subject
      // &&
      // mailStore.recipientNameType
    )
      return true;

    return false;
  };

  const validatePriceChange = () => {
    if (mailStore.price && mailStore.price !== mailStore.currItemPrice)
      return true;

    return false;
  };

  const validateSubscriptionPriceChange = () => {
    if (mailStore.price && mailStore.price !== mailStore.currItemPrice)
      return true;

    return false;
  };

  const shouldRenderSendButton = () => {
    // if (mailStore.subject == "price_change") {
    //   return validatePriceChange();
    // }

    // if (mailStore.subject == "subscription_price_change") {
    //   return validateSubscriptionPriceChange();
    // }

    if (
      mailStore.greeting &&
      mailStore.greeting !== "none" &&
      !mailStore.recipientNameType
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (mailStore.subject == "price_change" && mailStore.item) {
      const itemPrice = mailStore.books
        .filter((b) => b.uuid == mailStore.item)
        .map((b) => b.price);
      mailStore.setPrice(itemPrice);
      mailStore.setCurrItemPrice(itemPrice);
    }

    if (mailStore.subject == "subscription_price_change") {
      const itemPrice = mailStore.user?.subscription_cost;
      mailStore.setPrice(itemPrice);
    }

    if (mailStore.item) {
      const name = mailStore.books
        .filter((b) => b.uuid == mailStore.item)
        .map((b) => b.name);
      mailStore.setCurrItemName(name);
    }
  }, [mailStore.subject, mailStore.item]);

  return (
    <>
      <div className="main w-full">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">Send Emails</h1>
          </div>
          <div className="flex justify-between gap-2 items-start">
            <BasicButton
              title={"Go Back"}
              handleClick={() => {
                mailStore.setRefresh(false);
                window.history.back();
              }}
            />
          </div>
        </div>
        <div className="m-2 p-2 flex items-center w-full justify-center p-2 text-center">
          <div className="w-full flex justify-center">
            <p>
              NUMBER OF RECIPIENTS:{" "}
              <b className="text-green-500">({mailStore.selectedCount})</b>
            </p>
          </div>

          {user?.emaildispatchpayment ? (
            <div className="w-full flex justify-center">
              <p>
                Email Dispatches Available:{" "}
                <b className="text-green-500">({user?.email_dispatch_limit})</b>
              </p>
            </div>
          ) : (
            ""
          )}

          {user?.emaildispatchpayment ? (
            <div className="hidden md:flex justify-center w-full">
              <BasicButton
                title={"Add More Email Dispatches"}
                classes={"bg-teal-500"}
                handleClick={() =>
                  navigate("/publisher/mailing/email-purchase/add/" + model)
                }
              />
            </div>
          ) : (
            ""
          )}

          {/* <div className="flex justify-between gap-2 items-start"></div> */}
        </div>

        <div className="bg-white m-2 p-2 flex justify-center md:hidden">
          {user?.emaildispatchpayment ? (
            <BasicButton
              title={"Add More Email Dispatches"}
              classes={"bg-teal-500"}
              handleClick={() =>
                navigate("/publisher/mailing/email-purchase/add/" + model)
              }
            />
          ) : (
            ""
          )}
        </div>

        <div className="flex flex-col items-center justify-start my-4">
          <div className="flex flex-col items-center gap-4 p-2 w-10/12 md:w-7/12">
            <div className="flex flex-col items-start w-full">
              <label for="timezone" value="Your Timezone" className="font-bold">
                PREFERRED LANGUAGE
              </label>
              <select
                value={mailStore.language}
                onChange={handleLanguageChange}
                required
                class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
              >
                <option value="" defaultValue disabled>
                  Select
                </option>
                <option value="normal_1000000" defaultValue>
                  English
                </option>
              </select>
            </div>
            <div
              className={`flex flex-col items-start w-full  ${
                !mailStore.language ? "hidden" : ""
              } `}
            >
              <label for="timezone" value="Your Timezone" className="font-bold">
                GREETING PREFERENCE
              </label>
              <select
                value={mailStore.greeting}
                onChange={handleGreetingChange}
                required
                class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
              >
                <option value="" defaultValue>
                  Select
                </option>
                <option value="none">None</option>
                <option value="Dear">Dear: </option>
                <option value="Hi">Hi</option>
                <option value="Hello">Hello:</option>
                <option value="Yo">Yo: </option>
                <option value="Hey">Hey: </option>
                <option value="Congrats">Congrats: </option>
                <option value="Greetings">Greetings: </option>
                <option value="Salutions">Salutions: </option>
                <option value="Howdy">Howdy: </option>
                <option value="What’s Up">What’s Up: </option>
                <option value="Wassup">Wassup: </option>
                <option value="How’s it Going">How’s it Going: </option>
                <option value="Namaste">Namaste: </option>
                <option value="Happy New Year">Happy New Year: </option>
                <option value="Merry Christmas">Merry Christmas: </option>
                <option value="Happy Easter">Happy Easter: </option>
                <option value="Seasons Greetings">Seasons Greetings: </option>
                <option value="Happy Holidays">Happy Holidays: </option>
                <option value="Get Ready To Rumble">
                  Get Ready To Rumble:
                </option>
              </select>
            </div>

            <div
              className={`flex flex-col items-start w-full ${
                !mailStore.greeting || mailStore.greeting == "none"
                  ? "hidden"
                  : ""
              }`}
            >
              <label for="timezone" value="Your Timezone" className="font-bold">
                RECIPIENT NAME TYPE
              </label>
              <select
                value={mailStore.recipientNameType}
                onChange={handleRecipientNameChange}
                required
                class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
              >
                <option value="" defaultValue disabled>
                  Select
                </option>
                <option value="first_name">
                  <b className="font-bold">First Name</b>
                </option>
                <option value="last_name">
                  <b className="font-bold">Last Name</b>
                </option>
                <option value="all">
                  <b className="font-bold">
                    All (addresses all selected recipients, e.g., Dear All,
                    etc.)
                  </b>
                </option>
              </select>
            </div>

            <div
              className={`flex flex-col items-start w-full ${
                !mailStore.language || !mailStore.greeting ? "hidden" : ""
              }`}
            >
              <label for="timezone" value="Your Timezone" className="font-bold">
                ITEM
              </label>
              <select
                value={mailStore.item}
                onChange={handleItemChange}
                required
                class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
              >
                <option value="" defaultValue disabled>
                  Select Item
                </option>
                {mailStore.books.length &&
                  mailStore.books.map((b) => (
                    <option value={b.uuid}>{b.name}</option>
                  ))}
              </select>
            </div>
            <div
              className={`flex flex-col items-start w-full ${
                !mailStore.item ? "hidden" : ""
              }`}
            >
              <label for="timezone" value="Your Timezone" className="font-bold">
                SUBJECT
              </label>
              <select
                value={mailStore.subject}
                onChange={handleSubjectChange}
                required
                class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="update">Update</option>
                <option value="addition_notification">
                  Addition Notification
                </option>
                <option value="price_change">Price Change</option>
                <option value="subscription_price_change">
                  Subscription Price Change{" "}
                </option>
                <option value="release_date_update">
                  Release Date Update{" "}
                </option>
              </select>
            </div>

            <div
              className={`flex flex-col items-center w-full ${
                mailStore.subject == "subscription_price_change" ||
                mailStore.subject == "price_change" ||
                mailStore.subject == "release_date_update"
                  ? ""
                  : "hidden"
              }`}
            >
              <label
                for="timezone"
                value="Your Timezone"
                className="text-center"
              >
                <span className="font-bold">EVENT DATE TIME</span>{" "}
                <span className="text-yellow-600">(?)</span>
              </label>
              <br />
              <div className="flex flex-col md:flex-row gap-2 items-end">
                <div className="flex flex-col items-center  gap-2 w-full">
                  <label
                    for="timezone"
                    value="Your Timezone"
                    className="font-bold"
                  >
                    Calendar
                  </label>
                  <DatePicker
                    selected={mailStore.startDate}
                    onChange={(date) => mailStore.setStartDate(date)}
                    className="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer border"
                    minDate={new Date()}
                  />
                </div>
                <div className="flex flex-col items-center gap-2 w-full">
                  {" "}
                  <label
                    for="timezone"
                    value="Your Timezone"
                    className="flex gap-2"
                  >
                    <span className="font-bold text-base">Time</span>
                    <span className="text-yellow-600"> (?)</span>
                  </label>
                  <select
                    value={mailStore.time}
                    onChange={handleTimeChange}
                    required
                    class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer border"
                  >
                    <option value="0">Optional</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                </div>
                <div
                  className={`flex flex-col gap-2 w-full ${
                    mailStore.time == "0" ? "hidden" : ""
                  }`}
                >
                  <label
                    for="timezone"
                    value="Your Timezone"
                    className="font-bold"
                  >
                    AM/PM
                  </label>

                  <select
                    value={mailStore.AMPM}
                    onChange={handleAMPMChange}
                    required
                    class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer border justify-self-end"
                  >
                    <option value="">Select</option>
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </div>
            </div>

            <div
              className={`flex flex-col items-start w-full ${
                mailStore.subject == "subscription_price_change" ||
                mailStore.subject == "price_change"
                  ? ""
                  : "hidden"
              }`}
            >
              <label for="timezone" value="Your Timezone" className="font-bold">
                PRICE
              </label>
              <TextField
                classes={"p-6 my-2"}
                placeholder={"Price.."}
                type="number"
                handleChange={handlePriceChange}
                value={mailStore.price}
              />
            </div>
            <div
              className={`flex flex-col items-start w-full ${
                shouldRenderBody() ? "" : "hidden"
              }`}
            >
              <label for="timezone" value="Your Timezone" className="font-bold">
                Body
              </label>

              <UpdateEmailBody />
              <AdditionEmailBody />
              <PriceChangeEmailBody />
              <SubscriptionPriceChangeEmailBody />
              <ReleaseDateEmailEmailBody />
              <div className={shouldRenderSendButton() ? "" : "hidden"}>
                <BasicButton
                  disabled={processing}
                  title={processing ? "Processing..." : "SEND EMAIL"}
                  classes={"bg-teal-500"}
                  handleClick={() => canSendMail()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <DeletePromptModal
        modalOpen={consentModalOpen}
        hideAdModal={hideConsentModal}
        title={consentMessage}
        yesActionText={"Yes, send mail"}
        noActionText={"No, return to email builder"}
        action={() => sendMail()}
        processing={processing}
      />

      <MailSuccessPromptModal
        modalOpen={mailuccessModalOpen}
        hideAdModal={hideMailSuccessModal}
        title={consentMessage}
        yesActionText={"Exit"}
        action={() => exitPage()}
        processing={processing}
      />
    </>
  );
}
