import { REOptions } from "../../constants";
export default function PurchaseOptionDef(props) {
  const { productMeta, handleMetaChange, pageContext, state } = props;
  return (
    <>
      {state.metaMode == 2 && (
        <div className="w-full flex items-center justify-center items-center">
          <div className="flex flex-col">
            {" "}
            <div className="text-black font-bold flex items-center gap-1">
              <span> Preferred Purchase Duration</span>
              {!productMeta.p_type_sub_opt && (
                <span className="text-red-500 text-lg">*</span>
              )}
            </div>
            <select
              disabled={pageContext == "update"}
              value={productMeta.p_type_sub_opt}
              onChange={(e) => {
                if (!pageContext == "create") return false;
                if (productMeta.contract_type == "default") {
                  handleMetaChange("", "contract_type", "text");
                  handleMetaChange("", "default_contract_name", "text");
                  handleMetaChange("", "contract_id", "dropdown");
                }
                handleMetaChange(e, "p_type_sub_opt", "dropdown");
                handleMetaChange(
                  { label: "", price: "", uuid: "" },
                  "Fortnightly",
                  "product_short"
                );
                handleMetaChange(
                  { label: "", price: "", uuid: "" },
                  "Weekly",
                  "product_short"
                );
                handleMetaChange(
                  { label: "", price: "", uuid: "" },
                  "Daily",
                  "product_short"
                );
              }}
              required
              class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
            >
              <option value="" defaultValue disabled>
                Not Selected
              </option>
              {REOptions.map((b) => (
                <option value={b.label}>{b.label}</option>
              ))}
            </select>
          </div>
        </div>
      )}
    </>
  );
}
