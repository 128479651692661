import { useState } from "react";
import { isResponsiveMode } from "../../../../../../Utils/helpers";
import BasicButton from "../../../../../../components/buttons/basic-button";
import React, { useRef, useEffect } from "react";
function EditorTooltip(props) {
  const { body, top, left, right, height } = props;
  const [currTooltip, setCurrentTooltip] = useState(0);
  const [show, setShow] = useState(false);
  const [scrollPercentage, setScrollPercentage] = useState(20);

  const contentRef = useRef(null);
  const scrollToPercentage = () => {
    return;
  };

  return (
    <div
      className="self-center"
      onClick={() => !isResponsiveMode() && setShow(true)}
      onMouseOver={() => !isResponsiveMode() && setShow(true)}
      onMouseLeave={() => !isResponsiveMode() && setShow(false)}
    >
      {isResponsiveMode() ? (
        <i
          onClick={() => setShow(true)}
          onMouseOver={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
          class="fas fa-question-circle my-text-yellow-500 cursor-pointer"
        ></i>
      ) : (
        <i className="fas fa-question-circle my-text-yellow-500 cursor-pointer"></i>
      )}

      <div
        className={`absolute ${
          isResponsiveMode()
            ? "m-auto left-0 right-0 w-10/12 shadow bg-black"
            : ""
        } md:w-8/12 absolute  text-white md:text-white rounded-lg overflow-auto md:h-fit text-sm  px-1 z-50 py-4 ${
          show ? "flex flex-col items-center" : "hidden"
        }`}
        style={{
          right: right || "0px",
          top: top || "10px",
        }}
      >
        <div className="relative">
          <div
            ref={contentRef}
            className={`${
              height ? height : ""
            } overflow-auto p-2 text-left relative bg-black md:bg-black pb-2`}
          >
            <p
              className="p-2 w-full"
              dangerouslySetInnerHTML={{ __html: body }}
            ></p>
          </div>
        </div>
        <div className="flex justify-center w-full mt-1">
          {height && !isResponsiveMode() && (
            <div class="absolute bottom-6 cursor-pointer circle-icon bg-gray-900">
              <i
                className="fas fa-arrow-down text-1xl fa-bounce"
                style={{ color: "#ffffff" }}
                onClick={() => scrollToPercentage(20)}
              ></i>
            </div>
          )}
          {!isResponsiveMode() && <div class="bottom-triangle"></div>}
        </div>
        <BasicButton
          handleClick={() => setShow(false)}
          classes={"bg-gray-500 w-5/12 md:hidden"}
          title={"Close"}
        />
      </div>
    </div>
  );
}

export default EditorTooltip;
