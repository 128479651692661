import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState } from "react";
import WebHeader from "./web/header";
import axios from "axios";
import BasicButton from "../components/buttons/basic-button";
import StripeForm from "../payment/pay-button";
import { API_BASE } from "../Utils/helpers";
import Timezones from "../components/timezone/timezone";
import StripeSubscriptionModal from "../modals/stripe-subscription-modal";
import { useParams } from "react-router-dom";
import {
  isAuthUser,
  authUserData,
  subscritionCostWithSystemFees,
} from "../Utils/helpers";
import Countries from "../components/country/country";
import { useBookStore } from "../stores/book-store";

export default function Register2({ action, mode, action2, userIdentifier }) {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [timeZone, setTimezone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [subSecretKey, setSubSecretKey] = useState();
  const [showSubModal, setShowSubModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameMode, setUsernameMode] = useState(false);
  const [type, setType] = useState("modal");
  const naviagte = useNavigate();
  const { setSubModalOpen } = useBookStore((state) => state);
  let { userId } = useParams();

  const register = () => {
    setIsAuthenticating(true);
    const url = API_BASE + "/api/register/complete";
    axios
      .post(url, {
        country: country,
        country_code: countryCode,
        timezone: timeZone,
        password: password,
        user_id: getUserId(),
        password_reset: isPasswordModified() ? 1 : 0,
      })
      .then((response) => {
        if (response.status == 200) {
          toast("Registration Complete", { type: "success" });
          localStorage.removeItem("registration_type");
          action();
          if (mode == "password_reset_no_login") {
            action2();
          }

          if (mode == "password_reset_username") {
            window.location.href = `/login`;
          }
        }
        setIsAuthenticating(false);
      })
      .catch((error) => {
        setIsAuthenticating(false);
      });
  };

  const handleCountryChange = (e) => {
    let objectTarget = JSON.parse(e.target.value);
    setCountry(objectTarget.country);
    setCountryCode(objectTarget.country_code);
  };

  const handleRegistration = () => {
    register();
  };

  const handlePasswordChange = (e) => {
    setPassword(e);
  };

  const handlePasswordConfirmationChange = (e) => {
    setPasswordConfirm(e);
  };

  const handleTimezoneChange = (e) => {
    setTimezone(e);
  };

  const shouldDisableButton = () => {
    if (isPasswordModified()) {
      return (
        isAuthenticating ||
        !country ||
        !timeZone ||
        !password ||
        password !== passwordConfirm
      );
    } else {
      return isAuthenticating || !country || !timeZone;
    }
  };

  const isPasswordModified = () => {
    if (
      mode == "password_reset_transaction" ||
      mode == "password_reset_username"
    ) {
      return true;
    }

    return false;
  };
  const getUserId = () => {
    if (mode == "password_reset_no_login") {
      return userId;
    }

    if (mode == "password_reset_transaction") {
      return authUserData().uuid;
    }

    if (mode == "password_reset_username") {
      return userIdentifier;
    }
  };

  return (
    <>
      <div class="container px-6 mx-auto">
        <br />
        <div class="flex flex-col text-left justify-evenly items-center">
          <div class="flex flex-col w-full items-center">
            <h1 class="text-2xl text-gray-800 font-bold my-2">
              Please complete your registration
            </h1>
          </div>
          <div
            className={`w-full ${
              type !== "modal" ? "md:w-6/12 " : "md:w-10/12 "
            }${type !== "modal" ? "lg:w-5/12" : "lg:w-10/12"} mx-auto md:mx-0`}
          >
            <div class="bg-white p-10 flex flex-col w-full shadow-xl rounded-xl">
              {/* <h2 class="text-2xl font-bold text-gray-800 text-left mb-5">
                Sigin
              </h2> */}
              <form action="" class="w-full ">
                <div id="input" class="flex flex-col w-full my-5">
                  <label for="username" class="text-gray-500 mb-2 text-left">
                    Country
                  </label>
                  <Countries handleCountryChange={handleCountryChange} />
                </div>

                <div id="input" class="flex flex-col w-full my-5 ">
                  <label for="password" class="text-gray-500 mb-2 text-left">
                    Timezone (GMT, UTC..etc) or General Area
                  </label>
                  <Timezones
                    handleTimezoneChange={handleTimezoneChange}
                    value={timeZone}
                  />
                </div>

                {isPasswordModified() ? (
                  <>
                    {" "}
                    <div
                      id="input"
                      class="flex flex-col items-start w-full my-5"
                    >
                      <label
                        for="password"
                        class="text-gray-500 mb-2 text-left"
                      >
                        Password
                      </label>
                      <input
                        onChange={(e) => handlePasswordChange(e.target.value)}
                        type="password"
                        id="password"
                        value={password}
                        placeholder="Please insert your password"
                        class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full"
                      />
                    </div>
                    <div
                      id="input"
                      class="flex flex-col items-start w-full my-5"
                    >
                      <label for="password" class="text-gray-500 mb-2">
                        Confirm Password
                      </label>
                      <input
                        onChange={(e) =>
                          handlePasswordConfirmationChange(e.target.value)
                        }
                        type="password"
                        id="password"
                        value={passwordConfirm}
                        placeholder="Please insert your password"
                        class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full"
                      />
                      <p className="text-pink-500">
                        {password &&
                        passwordConfirm &&
                        password !== passwordConfirm
                          ? "Password Mismatch, make sure Password and Confirmed Password are a match"
                          : ""}
                      </p>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div id="button" class="flex flex-col w-full my-5">
                  <button
                    disabled={shouldDisableButton()}
                    onClick={() => {
                      handleRegistration();
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    type="button"
                    class={`w-full py-4  ${
                      shouldDisableButton() ? "bg-gray-400" : "bg-black"
                    } rounded-lg text-green-100`}
                  >
                    {isAuthenticating ? "Completing.." : "Complete"}
                  </button>
                  {/* <div class="flex justify-evenly mt-5">
                  <a
                    href="#"
                    class="w-full text-center font-medium text-gray-500"
                  >
                    Recover password!
                  </a>
                  <a
                    href="#"
                    class="w-full text-center font-medium text-gray-500"
                  >
                    Singup!
                  </a>
                </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
