import axios from "axios";
import { useEffect, useState } from "react";
import BasicButton from "../../../components/buttons/basic-button";
import TextField from "../../../components/inputs/text-input";
import Pagination from "../../../components/pagination";
import DeletePromptModal from "../../../modals/delete-warning-modal";
import useDebounce from "../../../components/debounce/debounce-helper";
import LoadingProgress from "../../publisher/rich-page/components/loading-progress";
import {
  isAdmin,
  API_BASE,
  isPublisher,
  isAuthUser,
  getLocation,
  authUserData,
  isReader,
} from "../../../Utils/helpers";
import { toast } from "react-toastify";
export default function CustomerRequestedUpdates() {
  const [subscriptions, setSubscriptions] = useState({});
  const [searchText, setSearchText] = useState("");
  const [countPerPage, setCountPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [currentSub, setCurrentSub] = useState({});
  const [showDeletePromptModal, setShowDeletePromptModal] = useState(false);

  const doPagination = (page) => {
    if (subscriptions.first_page_url) {
      setCurrentPage(page);
      axios
        .get(subscriptions.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          setSubscriptions(res.data.subscriptions);
        })
        .catch((err) => console.log(err));
    }
  };

  const getSubscriptions = () => {
    setSubscriptions([]);
    setProcessing(true);

    const url =
      API_BASE +
      "/api/user/my/email/subscriptions/" +
      authUserData().uuid +
      "/" +
      countPerPage +
      "/" +
      searchText;

    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setProcessing(false);
          setSubscriptions(response.data.subscriptions);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const cancelSubscription = () => {
    const url =
      API_BASE +
      "/api/publisher/my/email/subscriptions/" +
      JSON.parse(isAuthUser()).uuid +
      "/unregister/" +
      currentSub.id;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          getSubscriptions();
          hideDeletePromtModal();
          toast("Success", { type: "success" });
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e);
  };

  const handleCountPerPageChange = (e) => {
    setCountPerPage(e.target.value);
  };

  const openDeletePromtModal = (sub) => {
    setCurrentSub(sub);
    setShowDeletePromptModal(true);
  };

  const hideDeletePromtModal = () => {
    setShowDeletePromptModal(false);
  };

  useDebounce(
    () => {
      getSubscriptions();
    },
    [searchText, countPerPage],
    800
  );

  useEffect(() => {
    if (!isReader()) {
      window.location.href = "/";
    }
  }, []);
  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">Requested Updates</h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            <p className="w-full text-center my-4">
              We found {subscriptions.total ? subscriptions.total : ""} records
            </p>
            <div className="flex flex-col md:flex-row w-full gap-4">
              <div className="w-11/12">
                <TextField
                  title="Search"
                  placeholder={"Search"}
                  classes="rounded-lg"
                  handleChange={handleSearchTextChange}
                  value={searchText}
                />
              </div>

              <div className="w-1/12">
                <label for="timezone" value="Your Timezone" />
                <select
                  onChange={handleCountPerPageChange}
                  required
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full"
                >
                  <option value="10" default>
                    10
                  </option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <div className="flex justify-center">
              {" "}
              {subscriptions.data ? (
                subscriptions.data.length ? (
                  <Pagination
                    pagination={subscriptions}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            <table class="table-auto w-full my-4">
              <thead>
                <tr className={window.innerWidth < 720 ? "hidden" : ""}>
                  <th class="px-4 py-2 text-gray-600">Publisher</th>
                  <th class="px-4 py-2 text-gray-600">Email</th>
                  <th class="px-4 py-2 text-gray-600">Channel</th>
                  <th class="px-4 py-2 text-gray-600">Item Title</th>
                  {/* <th class="px-4 py-2 text-gray-600">Item Type</th>
                  <th class="px-4 py-2 text-gray-600">Item</th> */}
                  <th class="px-4 py-2 text-gray-600">Item ID</th>
                  <th class="px-4 py-2 text-gray-600">Member</th>
                  <th class="px-4 py-2 text-gray-600">Date</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.data
                  ? subscriptions.data.map((subscription) => {
                      return (
                        <tr
                          className={
                            window.innerWidth < 820 ? "flex flex-col" : ""
                          }
                        >
                          <td class="border  px-4 py-2  font-medium">
                            <h3 className="font-bold md:hidden">Publisher</h3>
                            {subscription.book.owner}
                          </td>
                          <td class="border  px-4 py-2  font-medium">
                            <h3 className="font-bold md:hidden">Email</h3>
                            <p className="word-wrap break-words break-all">
                              {" "}
                              {subscription.email}
                            </p>
                          </td>
                          <td class="border  px-4 py-2  font-medium">
                            <h3 className="font-bold md:hidden">Channel</h3>
                            {subscription.channel == "platform_updates"
                              ? "PLatform Update"
                              : "Information Page"}
                          </td>
                          <td class="border  px-4 py-2  font-medium">
                            <h3 className="font-bold md:hidden">Item Title</h3>
                            <p className="my-text-yellow-500">
                              {" "}
                              {subscription.book.group == "standard"
                                ? "File"
                                : "Custom Page"}
                            </p>
                            <p>
                              <a
                                target="_blank"
                                className="underline"
                                href={
                                  subscription.book.group == "standard"
                                    ? `/publisher/${subscription.book.owneruuid}/publications/series/m/book/${subscription.book.book_id}`
                                    : `/web/rich-page/render/${subscription.book.book_uuid}`
                                }
                              >
                                {subscription.book.name}
                              </a>
                            </p>
                          </td>

                          <td class="border  px-4 py-2 font-medium">
                            <h3 className="font-bold md:hidden">Item ID</h3>
                            {subscription.book.item_id}
                          </td>
                          <td class="border  px-4 py-2  font-medium">
                            <h3 className="font-bold md:hidden">Member</h3>
                            {subscription.subscriber_id ? "Yes" : "No"}
                          </td>
                          <td class="border  px-4 py-2  font-medium">
                            <h3 className="font-bold md:hidden">Date</h3>
                            <p>
                              {subscription.refactoredCreatedAtTime
                                ? new Date(
                                    subscription.refactoredCreatedAtTime.replace(
                                      /-/g,
                                      "/"
                                    )
                                  ).getDate() +
                                  "-" +
                                  (new Date(
                                    subscription.refactoredCreatedAtTime.replace(
                                      /-/g,
                                      "/"
                                    )
                                  ).getMonth() +
                                    1) +
                                  "-" +
                                  new Date(
                                    subscription.refactoredCreatedAtTime.replace(
                                      /-/g,
                                      "/"
                                    )
                                  ).getFullYear()
                                : "N/A"}
                            </p>
                          </td>
                          <td class="border border-gray px-4 py-2 font-medium text-center md:text-left">
                            <div className="flex flex-col justify-center items-center">
                              {/* <h3 className="font-bold md:hidden">Action</h3> */}
                              <BasicButton
                                disabled={!subscription.active || processing}
                                title={
                                  processing
                                    ? "Processing.."
                                    : subscription.active
                                    ? "Unregister"
                                    : "Unregistered"
                                }
                                handleClick={() =>
                                  openDeletePromtModal(subscription)
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </table>
            <LoadingProgress loading={processing} />
            <div className="flex justify-center">
              {" "}
              {subscriptions.data ? (
                subscriptions.data.length ? (
                  <Pagination
                    pagination={subscriptions}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {currentSub.id ? (
        <DeletePromptModal
          modalOpen={showDeletePromptModal}
          hideAdModal={hideDeletePromtModal}
          title={`If you unregister, you will no longer receive email updates from [Publisher name] again unless you register with ${currentSub.owner} again. Are you sure you want to unregister?`}
          yesActionText={"Yes, unregister"}
          noActionText={"No, exit"}
          action={() =>
            currentSub.id ? cancelSubscription(currentSub.id) : null
          }
          processing={processing}
        />
      ) : (
        ""
      )}
    </>
  );
}
