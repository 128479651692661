import {
  isAuthUser,
  isAdmin,
  isResponsiveMode,
} from "../../../../../Utils/helpers";
import MSharedMenuItems from "./shared";
export default function MAdminMenuItems() {
  const renderMenu = () => {
    return isAuthUser() && isAdmin();
  };

  return renderMenu() && isResponsiveMode() ? (
    <>
      <a
        style={{
          cursor: "pointer",
          textAlign: "right",
          color: "tomato",
        }}
        href="/admin/publishers"
        class="text-base font-medium text-gray-500 hover:text-gray-900"
      >
        Dashboard
      </a>
    </>
  ) : (
    ""
  );
}
