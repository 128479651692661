import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import BasicButton from "../../../../../components/buttons/basic-button";
import Pagination from "../../../../../components/pagination";
import useDebounce from "../../../../../components/debounce/debounce-helper";
import LoadingProgress from "../../../rich-page/components/loading-progress";
import { useSupplierApplicationsStore } from "../../../../../stores/supplier-applications-store";
import FilterBar from "./components/filter";
import authRequestService from "../../../../../services/HttpRequests/authenticated-request-services";
import authPaginatedRequestService from "../../../../../services/HttpRequests/authenticated-paginated-req-service";
import DeletePromptModal from "../../../../../modals/delete-warning-modal";
import NoticeModal from "./components/notice-modal";
import DesktopTable from "./components/desktop-table";
import MobileTable from "./components/mobile-table";

import {
  API_BASE,
  isPublisher,
  isAuthUser,
  authUserData,
  isResponsiveMode,
} from "../../../../../Utils/helpers";
import { toast } from "react-toastify";
export default function PubSupplierMyContracts() {
  const [currentPage, setCurrentPage] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [paginateMode, setPaginateMode] = useState(false);
  const [user, setUser] = useState({});
  const [blockedEmails, setBlockedEmails] = useState([]);
  const mountedRef = useRef();
  const navigate = useNavigate();
  const mailStore = useSupplierApplicationsStore((state) => state);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [currSelContract, setCurSelContract] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [noticeModalOpen, setNoticeModalOpen] = useState(false);
  const [noticeText, setNoticeText] = useState("");

  const fetchUser = () => {
    const url = API_BASE + "/publisher/" + authUserData().uuid + "/optimized";
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setUser(response.data.publisher);
          mailStore.setUser(response.data.publisher);
          if (response.data.publisher.emailblocklist)
            setBlockedEmails(
              response.data.publisher.emailblocklist.map((d) => d.email)
            );
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  const doPagination = (page) => {
    if (mailStore.data.first_page_url) {
      mailStore.setData([]);
      setCurrentPage(page);
      setPaginateMode(true);
      setProcessing(true);
      authPaginatedRequestService()
        .get(mailStore.data.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          mailStore.setData(res.data.contracts);
          setProcessing(false);
        })
        .catch((err) => {
          toast("Something went wrong", { type: "error" });
          setProcessing(false);
        });
    }
  };

  const deleteContract = () => {
    setProcessing(true);
    const url =
      API_BASE + "/publisher/supplier/contracts/delete/" + currSelContract.uuid;
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setTimeout(() => {
            getContracts();
            setProcessing(false);
            closeDeleteModal();
          }, 6000);
        }
      })
      .catch((error) => {
        if (error.response.status == 422) {
          setNoticeModalOpen(true);
          //closeDeleteModal();
          setProcessing(false);
          return false;
        }
        toast("Something went wrong", { type: "error" });
        setProcessing(false);
      });
  };

  const setQueryStatus = () => {
    if (!mailStore.query.status) {
      return "all";
    }

    return mailStore.query.status;
  };

  const getContracts = () => {
    mailStore.setData([]);
    setProcessing(true);

    const url =
      API_BASE +
      "/publisher/supplier/contracts/retrieve" +
      "/" +
      mailStore.query.countPerPage +
      "/" +
      mailStore.query.pageLimit +
      "/" +
      setQueryStatus() +
      "/" +
      mailStore.query.searchText;

    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          mailStore.setData(response.data.contracts);
          setProcessing(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const handleSearchTextChange = (e) => {
    mailStore.setQuery("searchText", e);
  };

  const handleCountPerPageChange = (e) => {
    mailStore.setQuery("countPerPage", e.target.value);
  };

  const handleLatestEntriesChange = (e) => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
    mailStore.setQuery("selectedLatest", e.target.value);
    mailStore.setQuery("pageLimit", e.target.value);
  };

  const handleOldestEntriesChange = (e) => {
    mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
    mailStore.setQuery("selectedOldest", e.target.value);
    mailStore.setQuery("pageLimit", e.target.value);
  };

  const handleStatusChange = (e) => {
    mailStore.setQuery("status", e.target.value);
  };

  const showSendButton = () => {
    if (!mailStore.data.total) return false;
    if (mailStore.selected.length) return true;
    if (mailStore.isAllSelected) {
      if (mailStore.deSelected.length !== mailStore.data.total) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedLatest]);

  useEffect(() => {
    mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedOldest]);

  useDebounce(
    () => {
      if (mailStore.refresh) {
        getContracts();
      }
      mailStore.setRefresh(true);
    },
    [mailStore.query],
    800
  );

  const preSubCancelPromt = (s) => {
    setDeleteModalOpen(true);
    setCurSelContract(s);
  };

  const closeDeleteModal = (d) => {
    setCurSelContract({});
    setDeleteModalOpen(false);
  };

  const closeNoticeModal = (d) => {
    setNoticeModalOpen(false);
  };

  const openCommentModal = (Comment) => {
    mailStore.handleExistingCommentChange(Comment);
    setShowCommentModal(true);
  };
  const closeCommentModal = () => {
    setShowCommentModal(false);
  };

  useEffect(() => {
    if (!isPublisher()) {
      window.location.href = "/";
    }
    fetchUser();
  }, []);

  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">
              My Supplier Contracts
            </h1>
          </div>
          <div className="flex justify-between gap-2 items-start">
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            <p className="w-full text-center my-4">
              We found {mailStore.data.total ? mailStore.data.total : ""}{" "}
              records
            </p>

            <FilterBar
              handleCountPerPageChange={handleCountPerPageChange}
              handleSearchTextChange={handleSearchTextChange}
              serchQValue={mailStore.query.searchText}
              showSendButton={showSendButton}
              handleLatestEntriesChange={handleLatestEntriesChange}
              latestQValue={mailStore.query.selectedLatest}
              handleOldestEntriesChange={handleOldestEntriesChange}
              oldestQValue={mailStore.query.selectedOldest}
              handleStatusChange={handleStatusChange}
              status={mailStore.query.status}
              model={"purchases"}
            />

            <div className="w-full flex justify-center">
              {mailStore.data.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            {isResponsiveMode() ? (
              <MobileTable
                blockedEmails={blockedEmails}
                preSubCancelPromt={preSubCancelPromt}
                openCommentModal={openCommentModal}
              />
            ) : (
              <DesktopTable
                blockedEmails={blockedEmails}
                preSubCancelPromt={preSubCancelPromt}
                openCommentModal={openCommentModal}
              />
            )}
            <LoadingProgress loading={processing} />
            <div className="w-full flex justify-center">
              {mailStore.data.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {currSelContract.id ? (
        <DeletePromptModal
          modalOpen={deleteModalOpen}
          hideAdModal={closeDeleteModal}
          title={`Are you sure you want to delete this contract?`}
          yesActionText={"Yes, Delete Contract"}
          noActionText={"No, Do Not Cancel"}
          action={deleteContract}
          processing={processing}
        />
      ) : (
        ""
      )}

      {currSelContract.id ? (
        <NoticeModal
          modalOpen={noticeModalOpen}
          hideAdModal={closeNoticeModal}
          title={noticeText}
          currSelContract={currSelContract}
          action={deleteContract}
          processing={processing}
        />
      ) : (
        ""
      )}
    </>
  );
}
