import TextNumberField from "../../../../../../../components/inputs/text-number-input";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useEffect } from "react";
import { useRef } from "react";
export default function ServiceLocation(props) {
  const { productMeta, handleMetaChange, pageContext, user } = props;
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: "ng" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      console.log("NEW VAL", place.name);
      console.log(place);
    });
  }, []);

  const handleGoogleLocationChange = (val) => {
    handleMetaChange(val.label, "service_location", "text");
    // setShowDefValue(true);
    console.log("NEW VAL", val.label);
  };

  const handleLocationChange = () => {};

  return (
    <div className="m-2 mb-2 w-full md:w-6/12">
      <label className="text-black">Service Location</label>
      {/* {productMeta.service_location} */}
      <div className="control w-full google-autocomoplete-supplier-form">
        <div className="selected-value">
          {/* <label for="password" class="text-gray-500 mb-2">
            Enter Location:
          </label> */}
          {/* {location.address} */}
          <GooglePlacesAutocomplete
            autocompletionRequest={{
              componentRestrictions: {
                //to set the specific country
              },
            }}
            selectProps={{
              className: "w-full",
              //set default value
              onInputChange: (newVal) => handleLocationChange(newVal),
              onChange: handleGoogleLocationChange, //save the value gotten from google
              onKeyDown: (newVal) => {
                {
                  /* setShowDefValue(false); */
                }
                handleMetaChange("", "service_location", "text");
              },
              placeholder: productMeta.service_location || "Enter Location..",
              styles: {
                input: (provided) => ({
                  ...provided,
                  color: "#222222",
                  width: "100%",
                  maxWidth: "100%",
                }),
                option: (provided) => ({
                  ...provided,
                  color: "#222222",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "#222222",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: "#000000",
                }),
                multiValue: (base, state) => ({
                  ...base,
                  display: "flex",
                  flexWrap: "wrap", // Allow the label to wrap to the next line
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  whiteSpace: "normal", // Allow the label to break into multiple lines
                }),
              },
            }}
            onLoadFailed={(error) => {
              console.log(error);
            }}
          />
          {/* <input ref={inputRef} value={location.address} /> */}
        </div>
      </div>
    </div>
  );
}
