import { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  API_BASE,
  BASE_URL,
  isAuthUser,
  authUserData,
  isResponsiveMode,
  getLocation,
} from "../../../Utils/helpers";

import AdSense from "react-adsense";
import RatingBox from "../../rating";
import { toast } from "react-toastify";
import Adsterra from "../../advert/adsterra";
import TextField from "../../inputs/text-input";
import ProfileList from "../../user/profiles/list";
import BasicButton from "../../buttons/basic-button";
import useDebounce from "../../debounce/debounce-helper";
import ProfileSectionOne from "./components/layout/section-one";
import ProfileSectionTwo from "./components/layout/section-two";
import ProfileSectionThree from "./components/layout/section-three";
import AuthPromptModal from "../../../modals/auth-prompt-modal";
import RatingPromptModal from "../../../modals/rating-prompt-modal";
import { useWebProfileStore } from "../../../stores/webProfileStore";
import ProductAvailibilitySearch from "./components/product-availability";
import openRequestService from "../../../services/HttpRequests/unauthenticated-request-services";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";
import HomepageAvailabilityCalendar from "./components/product";
import axios from "axios";

export default function BookSliderGrid({ publisherId, type }) {
  const [lastRatedBookId, setLastRatedBookId] = useState("");
  const [lastRatedBook, setLastRatedBook] = useState();
  const [currentRating, setCurrentRating] = useState();
  const [currentRatingFilter, setCurrentRatingFilter] = useState(0);
  const [showAuthPromptModal, setShowAuthPromptModal] = useState(false);
  const [showRatingPromptModal, setShowRatingPromptModal] = useState(false);
  const [ratingPromptModalMessage, setShowRatingPromptModalMessage] =
    useState("");
  const [
    currentRatingFilterSpecification,
    setCurrentRatingFilterSpecification,
  ] = useState("");
  const [renderSlider, setRenderSlider] = useState(true);
  const [rating, setRating] = useState(0);
  const boxRef = useRef();
  const cardContainerRef = useRef();
  const [pageNumber, setPageNumber] = useState(1);
  const [published, setPublished] = useState([]);
  const [currEditingBook, setCurrEditingBook] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [fileSelected, setFileSelected] = useState();
  const [profileText, setProfileText] = useState("");
  const [showRatingDropdown, setShowRatingDropdown] = useState(false);
  const [publisher, setPublisher] = useState({});
  const [bookType, setBookType] = useState("slides");
  const [searchText, setSearchText] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [currSelProduct, setCurrSelProduct] = useState({});
  const [showAvailabilityCalendar, setShowAvailabilityCalendar] =
    useState(false);
  const litzappAdCodeRef = useRef();
  const [moreTags, setMoreTags] = useState([]);
  const loadMoreBtRef = useRef();
  const multiSearchRef = useRef();
  const webProfileStore = useWebProfileStore((state) => state);
  var autoSlide;

  /*lists*/
  const [isListItemsMode, setIsListItemsMode] = useState(false);

  // const [currentEditingList, setEditingList] = useState({});

  const openAvailabilityCalendar = (data) => {
    setShowAvailabilityCalendar(true);
    setCurrSelProduct(data);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const closeAvailabilityCalendar = () => {
    setShowAvailabilityCalendar(false);
    setCurrSelProduct({});
  };

  const getBooks = () => {
    setProcessing(true);
    setIsListItemsMode(false);
    webProfileStore.setBooks([]);
    webProfileStore.setBooksWithAds([]);
    const url = `/books/${bookType}/paginated/web/user/` + publisherId;
    openRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.books) {
            webProfileStore.setBooks(response.data.books.data);
            webProfileStore.setNextPageURL(response.data.books.next_page_url);
          }
        }
        setProcessing(false);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
        setProcessing(false);
      });
  };

  const getBooksByAppliedDFilters = () => {
    webProfileStore.setBooks([]);
    webProfileStore.setBooksWithAds([]);
    setProcessing(true);
    const url = `/books/${bookType}/paginated/filtered/web/user/${publisherId}/${currentRatingFilter}/${
      currentRatingFilterSpecification.value
    }/${searchText.replace("/", "___")}`;

    window.scrollTo(0, 400);
    openRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.books) {
            webProfileStore.setBooks(response.data.books.data);
            webProfileStore.setNextPageURL(response.data.books.next_page_url);
          }
        }
        setProcessing(false);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getBooksByListIDFilters = (listId) => {
    webProfileStore.setBooks([]);
    webProfileStore.setBooksWithAds([]);
    setIsListItemsMode(true);
    setProcessing(true);
    const url =
      API_BASE +
      `/api/books/lists/web/list/${listId}/${searchText.replace("/", "___")}`;
    window.scrollTo(0, 400);
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          webProfileStore.setBooks(response.data.books.data);
          webProfileStore.setNextPageURL(response.data.books.next_page_url);
        }
        setProcessing(false);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const loadMore = () => {
    if (webProfileStore.nextPageURL) {
      setProcessing(true);
      const url =
        getLocation().hostname == "litzapp.com"
          ? webProfileStore.nextPageURL.split("api/api")[1]
          : webProfileStore.nextPageURL.split("api")[1];

      // loadMoreBtRef.current && loadMoreBtRef.current.scrollIntoView();

      openRequestService()
        .get(url)
        .then((response) => {
          if (response.status == 200) {
            let oldBooks = webProfileStore.books;
            let newBooks = oldBooks.concat(response.data.books.data);
            if (response.data.books.data) {
              webProfileStore.setBooks(newBooks);
              webProfileStore.setNextPageURL(response.data.books.next_page_url);
            }
          }
          setProcessing(false);
        })
        .catch((error) => {
          setProcessing(false);
          toast("Something went wrong", { type: "error" });
        });
    }
  };

  const rateBook = (value, book) => {
    const url = API_BASE + "/rating";

    if (!isAuthUser()) {
      openRatingPromtModal("Log in to leave a rating");
      return false;
    }
    if (book?.owneroptimized?.uuid == authUserData().uuid) {
      return false;
    }
    if (
      book.lastpageviewed &&
      !book.lastpageviewed.filter((p) => p.user_id == authUserData().id)
        .length &&
      !hasPurchasedBook(book)
    ) {
      openRatingPromtModal(
        "You can only leave a rating if you have viewed the content to its last slide/page or you have purchased the content."
      );
      return false;
    }

    setLastRatedBookId(book.uuid);
    setCurrentRating(value);
    authRequestService()
      .post(url, {
        value: value,
        bookId: book.uuid,
        userId: authUserData().uuid,
      })
      .then((response) => {
        if (response.status == 200) {
          setLastRatedBook(response.data.book);
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const hasPurchasedBook = (book) => {
    if (!isAuthUser()) {
      return false;
    }

    if (book) {
      const bought = book.buyer_ids.filter((id) => {
        if (id == authUserData().id) {
          return id;
        }
      });

      const hasSubscribed = hasSubscribedToPublisher(book);

      return bought.length || hasSubscribed ? true : false;
    }
  };

  const hasSubscribedToPublisher = (book) => {
    if (!authUserData()) {
      return false;
    }

    if (book?.owneroptimized?.subscriber_ids.length) {
      console.log(book?.owneroptimized?.subscriber_ids);
      const subscribed = book?.owneroptimized?.subscriber_ids.filter((sub) => {
        if (authUserData().id == sub.user_id && sub.active) {
          return sub;
        }
      });

      return subscribed.length ? true : false;
    }
  };

  const trackPublisher = (publisherId, trackerId) => {
    const url = API_BASE + "/track";
    authRequestService()
      .post(url, { publisherId: publisherId, trackerId: trackerId })
      .then((response) => {
        if (response.status == 200) {
          getPublisher();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const untrackPublisher = (publisherId, trackerId) => {
    const url = API_BASE + "/untrack";

    authRequestService()
      .post(url, { publisherId: publisherId, trackerId: trackerId })
      .then((response) => {
        if (response.status == 200) {
          getPublisher();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const isTrackingPublisher = (owner) => {
    if (owner.trackers && isAuthUser()) {
      const isTracking = owner.trackers.filter(
        (t) => t.tracker_id == authUserData().id
      );
      return isTracking.length ? true : false;
    }

    return false;
  };

  const getPublisher = () => {
    let url = "/publisher/" + publisherId + "/optimized";
    return openRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setPublisher(response.data.publisher);
        }
      })
      .catch((error) => {
        // toast("Something went wrong!", { type: "error" });
        return publisher;
      });
  };

  const updateBook = (book) => {
    const url = "/book/update";
    let formData = new FormData();
    formData.append("uuid", book.uuid);
    formData.append(
      "admin_publish_status",
      published.includes(book.uuid) ? 1 : 0
    );
    openRequestService()
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          toast("Updated!", { type: "success" });
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const handlePublishStatus = (e, book) => {
    let newPublish = published;
    if (newPublish.filter((p) => p == book.uuid).length) {
      let removeItem = newPublish.filter((p) => p !== book.uuid);
      setPublished(removeItem);
      setCurrEditingBook(book);
      console.log("item exists");
    } else {
      console.log("item not exists");
      setCurrEditingBook(book);
      setPublished((prev) => [...prev, book.uuid]);
    }
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e);
    setFirstLoad(false);
  };

  const applyRatingFilter = (value) => {
    setCurrentRatingFilter(value);
    setCurrentRatingFilterSpecification("");
    setShowRatingDropdown(true);
  };

  const resetRating = () => {
    handleSearchTextChange("");
    setCurrentRatingFilter(0);
    setCurrentRatingFilterSpecification("");
  };

  const applyRatingFilterSpecification = (e) => {
    setCurrentRatingFilterSpecification(e);
    setShowRatingDropdown(false);
    setFirstLoad(false);
  };

  const openRatingPromtModal = (msg) => {
    setShowRatingPromptModalMessage(msg);
    setShowRatingPromptModal(true);
  };

  const hideRatingPromtModal = () => {
    setShowRatingPromptModalMessage("");
    setShowRatingPromptModal(false);
  };

  let adsCounter = 0;

  const shouldRenderAd = () => {
    adsCounter++;
    if (adsCounter == 3) {
      adsCounter = 0;
      return true;
    }
    return false;
  };

  useEffect(() => {
    webProfileStore.books.map((book) =>
      setPublished((prevState) =>
        !prevState.includes(book.uuid) && book.admin_publish_status
          ? [...prevState, book.uuid]
          : prevState
      )
    );
  }, [webProfileStore.books]);

  useEffect(() => {
    console.log("published", published);
    if (currEditingBook) {
      updateBook(currEditingBook);
    }
  }, [published]);

  useEffect(() => {
    // if (isResponsiveMode()) {
    //   import("../../../src/landing_mobile.css");
    // } else {
    //   import("../../../src/landing.css");
    // }
  }, []);

  useEffect(() => {
    getBooks();
    getPublisher();
  }, []);

  useEffect(() => {
    if (activeTab == 0) {
      setBookType("slides");
    }

    if (activeTab == 3) {
      setBookType("comic_book_photo_album");
    }
    if (activeTab == 4) {
      setBookType("tracking");
    }
    if (activeTab == 5) {
      setBookType("trackers");
    }
    if (activeTab == 6) {
      setBookType("custom");
    }
    if (activeTab == 7) {
      setBookType("product");
    }
    if (activeTab == 9) {
      setBookType("list");
    }
    resetRating();
    setSearchText("");
    setFirstLoad(false);
  }, [activeTab]);

  useEffect(() => {
    if (!firstLoad) {
      getBooks();
    }
  }, [bookType]);

  const openAuthPromtModal = () => {
    setShowAuthPromptModal(true);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };

  const hideAuthPromtModal = () => {
    setShowAuthPromptModal(false);
  };

  useEffect(() => {
    if (publisher.name) {
      //   alert(JSON.stringify(book));
      setProfileText(publisher.profile_text);
      if (publisher?.profile_photo && publisher?.backup_profile_photo) {
        setFileSelected(
          BASE_URL + "/storage" + publisher?.profile_photo?.split("public")[1]
        );
      }
      if (publisher?.profile_photo && !publisher?.backup_profile_photo) {
        setFileSelected(
          BASE_URL + "/storage" + publisher.profile_photo?.split("public")[1]
        );
      }

      if (!publisher?.profile_photo && publisher?.backup_profile_photo) {
        setFileSelected(
          BASE_URL +
            "/storage" +
            publisher?.backup_profile_photo?.split("public")[1]
        );
      }
    }
  }, [publisher]);

  const clearSearchText = () => {
    setSearchText("");
  };

  useDebounce(
    () => {
      if (currentRatingFilter > 0) {
      } else {
        setShowRatingDropdown(false);
      }

      if (
        (activeTab < 4 && currentRatingFilterSpecification.value) ||
        searchText
      ) {
        getBooksByAppliedDFilters();
      } else {
        getBooks();
      }
    },
    [currentRatingFilterSpecification, searchText],
    800
  );

  const initBook = () => {
    var booksWithAdsCollection = [];
    if (
      publisher.advertrequest &&
      publisher.advertrequest.status == "approved"
    ) {
      let adcode = "";
      if (publisher.advertrequest?.provider == "ADSTERRA") {
        adcode = publisher?.advertrequest?.system_ad_2_code;
      } else {
        adcode = publisher?.advertrequest?.system_ad_code;
      }
      for (var i = 0; i < webProfileStore.books.length; i++) {
        booksWithAdsCollection.push({
          content: webProfileStore.books[i],
          type: "book",
        });
        if (webProfileStore.books.length > 2 && shouldRenderAd()) {
          if (true) {
            booksWithAdsCollection.push({
              content: adcode,
              type: "advert",
              provider: publisher.advertrequest?.provider,
            });
          }
        }
      }
    } else {
      for (var i = 0; i < webProfileStore.books.length; i++) {
        booksWithAdsCollection.push({
          content: webProfileStore.books[i],
          type: "book",
        });
      }
    }

    webProfileStore.setBooksWithAds(booksWithAdsCollection);
  };

  const renderTags = (book) => {
    let tagsToRender = 0;
    let updatedTags = [];

    if (moreTags.includes(book.uuid)) {
      for (let index = 0; index < book.tags.length; index++) {
        updatedTags.push({ type: "tag", content: book.tags[index] });

        if (tagsToRender == book.tags.length - 1) {
          updatedTags.push({ type: "view_less", content: "" });
        }
        tagsToRender++;
      }
    } else {
      for (let index = 0; index < book.tags.length; index++) {
        tagsToRender++;
        if (tagsToRender > 3) {
          updatedTags.push({ type: "view_more", content: "" });
          break;
        } else {
          updatedTags.push({ type: "tag", content: book.tags[index] });
        }
      }
    }

    return updatedTags.map((t) => {
      if (t.type == "tag") {
        return (
          <div
            className="rounded-full text-black border p-1 inline relative m-1 px-1 bg-pink-50 cursor-pointer"
            onClick={() => handleSearchTextChange("$" + t.content.name)}
          >
            <span className="text-xs pr-1">${t.content.name}</span>
          </div>
        );
      }

      if (t.type == "view_more") {
        return (
          <p
            className="text-xs text-blue-500 cursor-pointer underline"
            onClick={() => showMoreTags(book.uuid)}
          >
            View More Tags...
          </p>
        );
      }

      if (t.type == "view_less") {
        return (
          <p
            className="text-xs text-blue-500 cursor-pointer underline"
            onClick={() => showLessTags(book.uuid)}
          >
            View Less Tags...
          </p>
        );
      }
    });
  };

  const showMoreTags = (id) => {
    setMoreTags((prev) => [...prev, id]);
  };

  const showLessTags = (id) => {
    let currTags = moreTags;
    let filteredTags = currTags.filter((t) => t !== id);
    setMoreTags(filteredTags);
  };

  // const renderCoverPhotoWithFallbackImage = (data) => {
  //   if (data?.content?.cover_photo_thumb) {
  //     return (
  //       BASE_URL +
  //       "/storage" +
  //       data.content.cover_photo_thumb.split("public")[1]
  //     );
  //   }

  //   if (data.content?.productimages?.length) {
  //     return (
  //       BASE_URL +
  //       "/storage" +
  //       data.content?.productimages[0]?.product_thumb?.split("public")[1]
  //     );
  //   }

  //   if (data.content?.owner_optimized_data?.profile_photo) {
  //     return (
  //       BASE_URL +
  //       "/storage/" +
  //       data.content?.owner_optimized_data?.profile_photo?.split("public")[1]
  //     );
  //   } else {
  //     return (
  //       BASE_URL +
  //       "/storage/" +
  //       data.content?.owner_optimized_data?.backup_profile_photo?.split(
  //         "public"
  //       )[1]
  //     );
  //   }
  // };

  const goToContent = (d) => {
    if (d.group == "custom" && d.type == "list") {
      getBooksByListIDFilters(d.uuid);
      return false;
    }

    if (d.group == "standard") {
      if (webProfileStore.books.length) {
        {
          {
            hasPurchasedBook(d)
              ? (document.location.href = `/web/book/${d.uuid}`)
              : (document.location.href = `/web/reader/${d.uuid}`);
          }
        }
      }
    } else {
      document.location.href = "/web/rich-page/render/" + d.uuid;
    }

    if (d.group == "custom" && d.type !== "product") {
      window.location.href = "/web/rich-page/render/" + d.uuid;
    }

    if (d.group == "custom" && d.type == "product") {
      window.location.href = "/web/rich-page/render/" + d.uuid;
    }
  };

  const goBackToList = () => {
    getBooks();
  };

  const searchProps = {
    searchText,
    clearSearchText,
    handleSearchTextChange,
    activeTab,
  };

  const ratingProps = {
    currentRatingFilterSpecification,
    currentRatingFilter,
    showRatingDropdown,
    applyRatingFilterSpecification,
    applyRatingFilter,
    resetRating,
    activeTab,
  };

  const dashSectionOne = {
    publisher,
    publisherId,
    isTrackingPublisher,
    setActiveTab,
    activeTab,
    fileSelected,
    untrackPublisher,
    trackPublisher,
    openAuthPromtModal,
    profileText,
  };

  const dashSectionTwoProps = {
    publisher,
    activeTab,
    setActiveTab,
    multiSearchRef,
    openAvailabilityCalendar,
  };

  const dashSectionThreeProps = {
    handlePublishStatus,
    isListItemsMode,
    currentRating,
    lastRatedBook,
    goBackToList,
    goToContent,
    renderTags,
    processing,
    published,
    activeTab,
    loadMore,
    rateBook,
  };

  useEffect(() => {
    if (webProfileStore.books.length && publisher.id) {
      initBook();
    }
  }, [webProfileStore.books, publisher]);

  return (
    <section>
      <main class="bg-gray-100 bg-opacity-25 ">
        <div class="md:w-12/12 lg:mx-auto ">
          <ProfileSectionOne {...dashSectionOne} />
          <ProfileSectionTwo
            rating={ratingProps}
            search={searchProps}
            {...dashSectionTwoProps}
          />
        </div>
      </main>
      {activeTab == 4 ? (
        <ProfileList
          publisherId={publisherId}
          type="tracking"
          searchText={searchText}
        />
      ) : (
        ""
      )}
      {activeTab == 5 ? (
        <ProfileList
          publisherId={publisherId}
          type="trackers"
          searchText={searchText}
        />
      ) : (
        ""
      )}
      {activeTab < 4 || activeTab == 7 || activeTab == 6 || activeTab == 9 ? (
        <ProfileSectionThree {...dashSectionThreeProps} />
      ) : (
        ""
      )}
      <input ref={loadMoreBtRef} className="h-0" readonly />
      <AuthPromptModal
        hideAdModal={hideAuthPromtModal}
        modalOpen={showAuthPromptModal}
      />
      <RatingPromptModal
        hideAdModal={hideRatingPromtModal}
        modalOpen={showRatingPromptModal}
        body={ratingPromptModalMessage}
      />
      <HomepageAvailabilityCalendar
        hideModal={closeAvailabilityCalendar}
        modalOpen={showAvailabilityCalendar}
        state={{ data: [] }}
      />
    </section>
  );
}
