import { isAuthUser, isResponsiveMode } from "../../../../../Utils/helpers";
import MSharedMenuItems from "./shared";
export default function MUnauthorizedMenuItems() {
  const renderMenu = () => {
    return !isAuthUser();
  };
  return renderMenu() && isResponsiveMode() ? (
    <>
      <a
        href="/login"
        class="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
      >
        {" "}
        Sign in{" "}
      </a>
      {/* <a href="/signup">
        <span class="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-black hover:bg-black my-2">
          {" "}
          Create Account{" "}
        </span>
      </a> */}
    </>
  ) : (
    ""
  );
}
