import TextField from "../components/inputs/text-input";
import BasicButton from "../components/buttons/basic-button";
import { useBookStore } from "../stores/book-store";
import InsertVideo from "../pages/publisher/rich-page/components/insert-video";
import { isResponsiveMode } from "../Utils/helpers";
import YouTube from "react-youtube";
import { useState } from "react";

export default function ButtonLinkModalEditor({
  modalOpen,
  hideLinkModal,
  insertButtonLink,
  handleButtonLinkChange,
  handleButtonLinkNameChange,
  linkValue,
  nameValue,
}) {
  return (
    <div
      className={` ${
        isResponsiveMode() ? "fixed" : "fixed"
      }  z-40 overflow-y-auto h-screen  top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-6/12 h-4/12 "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <h1 className="text-2xl font-bold text-center">
                {/* {getActiveWidgetTitle(currentEditingWidget, widgets)} */}
                Add Button Link
              </h1>
            </div>

            <div
              className={`my-6 w-full border flex flex-col items-center justify-center bg-gray-50 p-2`}
            >
              <div className="w-full relative mx-2 flex flex-col justify-start items-start">
                <h5 className="font-bold">Title</h5>
                <TextField
                  placeholder={"Enter Title..."}
                  classes="rounded-lg pr-8"
                  handleChange={handleButtonLinkNameChange}
                  value={nameValue}
                />
                <br />
                <h5 className="font-bold mt-2">Link</h5>
                <TextField
                  placeholder={"Paste Link..."}
                  classes="rounded-lg pr-8"
                  handleChange={handleButtonLinkChange}
                  value={linkValue}
                />
              </div>
            </div>

            <div className="flex justify-center">
              <BasicButton
                disabled={!linkValue || !nameValue}
                title={"Add"}
                classes="px-8 w-8/12"
                handleClick={() => insertButtonLink()}
              />
            </div>

            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideLinkModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
            {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
