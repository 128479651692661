import { useRef, useEffect, useState } from "react";
import {
  API_BASE,
  BASE_URL,
  isAuthUser,
  authUserData,
  isResponsiveMode,
  getLocation,
  isPublisher,
} from "../../Utils/helpers";
import { toast } from "react-toastify";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
import AdSense from "react-adsense";
import { score, rate, average } from "average-rating";
import RatingBox from "../rating";
import Switch from "react-switch";
import TextField from "../inputs/text-input";
import ProfileList from "../user/profiles/list";
import ProfileImageUpload from "../../pages/publisher/rich-page/components/profile-image-upload";
import ProfileText from "../../pages/publisher/rich-page/components/profile-text";
import ImageUploadSingle from "../../pages/publisher/rich-page/components/image-upload-single";

export default function BookSliderGridReader({ userId }) {
  const [books, setBooks] = useState([]);
  const [nextPageURL, setNextPageURL] = useState("");
  const [lastRatedBookId, setLastRatedBookId] = useState("");
  const [lastRatedBook, setLastRatedBook] = useState();
  const [currentRating, setCurrentRating] = useState();
  const [currentRatingFilter, setCurrentRatingFilter] = useState(0);
  const [
    currentRatingFilterSpecification,
    setCurrentRatingFilterSpecification,
  ] = useState("");
  const [renderSlider, setRenderSlider] = useState(true);
  const [rating, setRating] = useState(0);
  const boxRef = useRef();
  const cardContainerRef = useRef();
  const [pageNumber, setPageNumber] = useState(1);
  const [published, setPublished] = useState([]);
  const [currEditingBook, setCurrEditingBook] = useState();
  const [activeTab, setactActiveTab] = useState(0);
  const [coverPhoto, setCoverPhoto] = useState("");
  const [fileSelected, setFileSelected] = useState("");
  const [profileText, setProfileText] = useState("");
  const [showRatingDropdown, setShowRatingDropdown] = useState(false);
  const [publisher, setPublisher] = useState({});
  const [bookType, setBookType] = useState("slides");
  const [searchText, setSearchText] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);

  var autoSlide;

  const updateCoverPhoto = () => {
    const url = API_BASE + "/api/publisher/update";
    let formData = new FormData();
    formData.append("user_id", userId);
    formData.append("profile_photo", coverPhoto);
    axios
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          toast("Updated", { type: "success" });
          getPublisher();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const getBooks = () => {
    setBooks([]);
    const url = API_BASE + `/api/books/${bookType}/paginated/user/` + userId;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.books) {
            setBooks(response.data.books.data);
            setNextPageURL(response.data.books.next_page_url);
          }
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const getBooksByAppliedDFilters = () => {
    setBooks([]);
    const url =
      API_BASE +
      `/api/books/${bookType}/paginated/filtered/user/${userId}/${currentRatingFilter}/${currentRatingFilterSpecification.value}/${searchText}`;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.books) {
            setBooks(response.data.books.data);
            setNextPageURL(response.data.books.next_page_url);
          }
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const loadMore = () => {
    if (nextPageURL) {
      const url =
        getLocation().hostname == "litzapp.com"
          ? API_BASE + `/api` + nextPageURL.split("api/api")[1]
          : API_BASE + `/api/` + nextPageURL.split("api")[1];

      axios
        .get(url)
        .then((response) => {
          if (response.status == 200) {
            let oldBooks = books;
            let newBooks = oldBooks.concat(response.data.books.data);
            if (response.data.books.data) {
              setBooks(newBooks);
              setNextPageURL(response.data.books.next_page_url);
            }
          }
        })
        .catch((error) => {
          toast("Something went wrong", { type: "error" });
        });
    }
  };

  const rateBook = (value, book) => {
    const url = API_BASE + "/api/rating";

    if (!isAuthUser()) {
      toast("Log in to leave a rating", { type: "notice" });
      return false;
    }
    if (book.owner.uuid == authUserData().uuid) {
      return false;
    }
    if (
      book.lastpageviewed &&
      !book.lastpageviewed.filter((p) => p.user_id == authUserData().id)
        .length &&
      !hasPurchasedBook(book)
    ) {
      toast(
        "You can only leave a rating if you have viewed the content to its last slide/page or you have purchased the content.",
        { type: "notice" }
      );
      return false;
    }

    setLastRatedBookId(book.uuid);
    setCurrentRating(value);
    axios
      .post(url, {
        value: value,
        bookId: book.uuid,
        userId: authUserData().uuid,
      })
      .then((response) => {
        if (response.status == 200) {
          setLastRatedBook(response.data.book);
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const hasPurchasedBook = (book) => {
    if (!isAuthUser()) {
      return false;
    }

    if (book) {
      const bought = book.buyers.filter((buyer) => {
        if (buyer.buyer_id == authUserData().id) {
          return buyer;
        }
      });

      return bought.length ? true : false;
    }
  };

  const trackPublisher = (userId, trackerId) => {
    const url = API_BASE + "/api/track";
    axios
      .post(url, { publisherId: userId, trackerId: trackerId })
      .then((response) => {
        if (response.status == 200) {
          getPublisher();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const untrackPublisher = (userId, trackerId) => {
    const url = API_BASE + "/api/untrack";

    axios
      .post(url, { publisherId: userId, trackerId: trackerId })
      .then((response) => {
        if (response.status == 200) {
          getPublisher();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const isTrackingPublisher = (owner) => {
    if (owner.trackers && isAuthUser()) {
      const isTracking = owner.trackers.filter(
        (t) => t.tracker_id == authUserData().id
      );
      return isTracking.length ? true : false;
    }

    return false;
  };

  const getPublisher = () => {
    let url = API_BASE + "/api/publisher/" + userId;
    return axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setPublisher(response.data.publisher);
          setCoverPhoto("");
        }
      })
      .catch((error) => {
        // toast("Something went wrong!", { type: "error" });
        return publisher;
      });
  };

  const updateBook = (book) => {
    const url = API_BASE + "/api/book/update";
    let formData = new FormData();
    formData.append("uuid", book.uuid);
    formData.append(
      "publisher_publish_status",
      published.includes(book.uuid) ? 1 : 0
    );
    axios
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          toast("Updated!", { type: "success" });
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const handlePublishStatus = (e, book) => {
    let newPublish = published;
    if (newPublish.filter((p) => p == book.uuid).length) {
      let removeItem = newPublish.filter((p) => p !== book.uuid);
      setPublished(removeItem);
      setCurrEditingBook(book);
      console.log("item exists");
    } else {
      console.log("item not exists");
      setCurrEditingBook(book);
      setPublished((prev) => [...prev, book.uuid]);
    }
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e);
    setFirstLoad(false);
  };

  const handleCoverPhotoChange = (value) => {
    setCoverPhoto(value);
  };

  const handleSelectedFileChange = (file) => {
    setFileSelected(file);
  };

  const applyRatingFilter = (value) => {
    setCurrentRatingFilter(value);
    setCurrentRatingFilterSpecification("");
    setShowRatingDropdown(true);
  };

  const resetRating = () => {
    handleSearchTextChange("");
    setCurrentRatingFilter(0);
    setCurrentRatingFilterSpecification("");
    // getBooks();
  };

  const applyRatingFilterSpecification = (e) => {
    setCurrentRatingFilterSpecification(e);
    setShowRatingDropdown(false);
    setFirstLoad(false);
  };

  useEffect(() => {
    books.map((book) =>
      setPublished((prevState) =>
        !prevState.includes(book.uuid) && book.publisher_publish_status
          ? [...prevState, book.uuid]
          : prevState
      )
    );
  }, [books]);

  useEffect(() => {
    console.log("published", published);
    if (currEditingBook) {
      updateBook(currEditingBook);
    }
  }, [published]);

  useEffect(() => {
    if (isResponsiveMode()) {
      import("../../../src/landing_mobile.css");
    } else {
      import("../../../src/landing.css");
    }
  }, []);

  useEffect(() => {
    // getBooks();
    getPublisher();
    setCoverPhoto("");
    console.log("loading books debug");
  }, []);

  useEffect(() => {
    if (activeTab == 0) {
      setBookType("slides");
    }
    if (activeTab == 1) {
      setBookType("photo-album");
    }
    if (activeTab == 2) {
      setBookType("book");
    }
    if (activeTab == 3) {
      setBookType("comic");
    }
    if (activeTab == 4) {
      setBookType("tracking");
    }
    if (activeTab == 5) {
      setBookType("trackers");
    }
    if (activeTab == 6) {
      setBookType("page");
    }
    resetRating();
    setSearchText("");
    setFirstLoad(false);
  }, [activeTab]);

  useEffect(() => {
    if (!firstLoad) {
      getBooks();
    }
  }, [bookType]);

  useEffect(() => {
    if (publisher.name) {
      //   alert(JSON.stringify(book));
      setProfileText(publisher.profile_text);
      if (publisher.profile_photo)
        setFileSelected(
          BASE_URL + "/storage" + publisher.profile_photo.split("public")[1]
        );
    }
  }, [publisher]);

  // useEffect(() => {
  //   if (currentRatingFilter > 0) {
  //   } else {
  //     setShowRatingDropdown(false);
  //   }

  //   if (
  //     activeTab < 4 &&
  //     (currentRatingFilterSpecification.value || searchText)
  //   ) {
  //     if (!firstLoad) {
  //       getBooksByAppliedDFilters();
  //     }
  //   } else {
  //     getBooks();
  //   }
  // }, [currentRatingFilterSpecification, searchText]);

  useEffect(() => {
    if (publisher && fileSelected && coverPhoto) {
      updateCoverPhoto();
    }
  }, [fileSelected]);

  return (
    <section>
      <header class="flex flex-wrap items-center md:items-start p-4 ">
        <div class="w-full md:w-3/12 md:ml-16 ">
          {/* <!-- profile image --> */}
          <div className="relative md:w-7/12 text-center ">
            <p className="font-bold mt-4">Profile Picture</p>
            <ImageUploadSingle
              setCoverPhoto={handleCoverPhotoChange}
              setFileSelected={handleSelectedFileChange}
              fileSelected={fileSelected}
              classes="rounded-full"
            />
          </div>
        </div>

        {/* <!-- profile meta --> */}
        <div class="w-full md:w-8/12 md:w-7/12 ml-4 ">
          {/* <!-- post, following, followers list for medium screens --> */}
          <ul class="hidden md:flex space-x-8 mb-2">
            {isPublisher() ? (
              <li>
                <span class="font-semibold p-1">
                  {publisher ? publisher.books_count : 0}
                </span>
                Content
              </li>
            ) : (
              ""
            )}

            {isPublisher() ? (
              <li
                onClick={() => setactActiveTab(5)}
                className={
                  activeTab == 5
                    ? "border-t border-gray-700 -mt-px text-gray-700 text-center cursor-pointer"
                    : "text-center cursor-pointer"
                }
              >
                <span class="font-semibold p-1">
                  {" "}
                  {publisher && publisher.trackers
                    ? publisher.trackers.length
                    : 0}
                </span>
                Trackers
              </li>
            ) : (
              ""
            )}
            <li
              onClick={() => setactActiveTab(4)}
              className={
                activeTab == 4
                  ? "border-t border-gray-700 -mt-px text-gray-700 text-center cursor-pointer"
                  : "text-center cursor-pointer"
              }
            >
              <span class="font-semibold p-1">
                {" "}
                {publisher && publisher.tracking
                  ? publisher.tracking.length
                  : 0}
              </span>
              Tracking
            </li>
          </ul>

          {/* <!-- user meta form medium screens --> */}
          <div class="hidden text md:block">
            {/* <h1 class="font-semibold">
              {publisher.name ? publisher.name : ""}
            </h1> */}

            <p className="font-bold mt-4">Personal Text</p>
            <div className="h-26 overflow-y-auto">
              <ProfileText userId={userId} />
            </div>
          </div>
        </div>

        {/* <!-- user meta form small screens --> */}
        <div class="flex flex-col items-center md:hidden text-sm my-2 w-full">
          <p className="font-bold mt-4">Personal Text</p>
          <ProfileText userId={userId} />
        </div>
      </header>
      <main class="bg-gray-100 bg-opacity-25">
        <div class="md:w-10/12 lg:mx-auto mb-4">
          <header class="flex flex-wrap items-center p-4 ">
            {/* <!-- profile meta --> */}

            {/* <!-- user meta form small screens --> */}
          </header>

          {/* <!-- posts --> */}
          <div class="px-px md:px-3">
            {/* <!-- user following for mobile only --> */}
            <ul
              class="flex md:hidden justify-around space-x-8 border-t 
                text-center p-2 text-gray-600 leading-snug text-sm"
            >
              {/* <li>
                <span class="font-semibold text-gray-800 block">
                  {" "}
                  {publisher ? publisher.books_count : 0}
                </span>
                Slides
              </li> */}

              {/* <li
                onClick={() => setactActiveTab(5)}
                className={
                  activeTab == 5
                    ? "border-t border-gray-700 -mt-px text-gray-700 text-center cursor-pointer"
                    : "text-center cursor-pointer"
                }
              >
                <span class="font-semibold text-gray-800 block">
                  {" "}
                  {publisher && publisher.trackers
                    ? publisher.trackers.length
                    : 0}
                </span>
                Tracker(s)
              </li> */}
              <li
                onClick={() => setactActiveTab(4)}
                className={
                  activeTab == 4
                    ? "border-t border-gray-700 -mt-px text-gray-700 text-center cursor-pointer"
                    : "text-center cursor-pointer"
                }
              >
                <span class="font-semibold text-gray-800 block">
                  {" "}
                  {publisher && publisher.tracking
                    ? publisher.tracking.length
                    : 0}
                </span>
                Tracking
              </li>
            </ul>

            {/* <!-- insta freatures --> */}
            <div className="flex flex-col md:flex-row md:grid-cols-3 justify-between items-center m-2 gap-2">
              <div className="w-full md:col-span-2 ">
                <TextField
                  title="Search"
                  placeholder={"Search"}
                  handleChange={handleSearchTextChange}
                  value={searchText}
                />
              </div>
              {activeTab < 4 && isPublisher() ? (
                <div className="relative flex flex-col md:flex-row justify-around items-center gap-2  w-full md:w-4/12">
                  <div className="flex flex-col justify-between justify-center items-center gap-1 text-xs mt-1 z-99999 w-full">
                    <span>
                      {currentRatingFilterSpecification
                        ? currentRatingFilterSpecification.label
                        : ""}
                    </span>
                    <div className="flex justify-between justify-center w-8/12">
                      {" "}
                      <i
                        className={`fa fa-star cursor-pointer  ${
                          currentRatingFilter >= 1 ? "text-yellow-500" : ""
                        }`}
                        onClick={() => applyRatingFilter(1)}
                      ></i>
                      <i
                        className={`fa fa-star cursor-pointer   ${
                          currentRatingFilter >= 2 ? "text-yellow-500" : ""
                        }`}
                        onClick={() => applyRatingFilter(2)}
                      ></i>
                      <i
                        className={`fa fa-star cursor-pointer   ${
                          currentRatingFilter >= 3 ? "text-yellow-500" : ""
                        }`}
                        onClick={() => applyRatingFilter(3)}
                      ></i>
                      <i
                        className={`fa fa-star cursor-pointer   ${
                          currentRatingFilter >= 4 ? "text-yellow-500" : ""
                        }`}
                        onClick={() => applyRatingFilter(4)}
                      ></i>
                      <i
                        className={`fa fa-star cursor-pointer   ${
                          currentRatingFilter >= 5 ? "text-yellow-500" : ""
                        }`}
                        onClick={() => applyRatingFilter(5)}
                      ></i>
                    </div>
                    <div>
                      {" "}
                      <span>Reset Filter(s)</span>
                      <i
                        class="fas fa-undo border shadow p-1 text-black mx-2 cursor-pointer"
                        aria-hidden="true"
                        onClick={() => resetRating()}
                      ></i>
                    </div>
                    <div
                      className={`w-8/12 absolute ${
                        !showRatingDropdown ? "hidden" : ""
                      }`}
                      style={{ top: "60px" }}
                    >
                      <div className="bg-white rounded-lg shadow p-2">
                        <ul>
                          <li
                            className="cursor-pointer hover:bg-gray-200 text-sm"
                            onClick={() =>
                              applyRatingFilterSpecification({
                                label: " Exact Rating",
                                value: "exact",
                              })
                            }
                          >
                            Exact Rating
                          </li>
                          <li
                            className="cursor-pointer hover:bg-gray-200 text-sm"
                            onClick={() =>
                              applyRatingFilterSpecification({
                                label: "Exact or Less",
                                value: "exact-less",
                              })
                            }
                          >
                            Exact or Less
                          </li>
                          <li
                            className="cursor-pointer hover:bg-gray-200 text-sm"
                            onClick={() =>
                              applyRatingFilterSpecification({
                                label: "Exact or More",
                                value: "exact-more",
                              })
                            }
                          >
                            Exact or More
                          </li>
                        </ul>{" "}
                        <label className="text-xxs text-blue-500">
                          Specify Rating:
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {isPublisher() ? (
              <ul
                class="flex items-center justify-around justify-center   
                    uppercase tracking-widest font-semibold text-xs text-gray-600
                    border-t"
              >
                <li
                  onClick={() => setactActiveTab(0)}
                  className={
                    activeTab == 0
                      ? "border-t border-gray-700 -mt-px text-gray-700 text-center"
                      : "text-center"
                  }
                >
                  <a class="flex flex-col p-3 " href="#">
                    <i class="fas fa-window-restore text-xl md:text-xs"></i>
                    <span class=" md:inline p-1">Content</span>
                  </a>
                </li>
                <li
                  onClick={() => setactActiveTab(1)}
                  className={
                    activeTab == 1
                      ? "border-t border-gray-700 md:-mt-px text-gray-700 text-center"
                      : "text-center"
                  }
                >
                  <a class="flex flex-col p-3" href="#">
                    <i class="fas fa-image text-xl md:text-xs"></i>
                    <span class=" inline p-1">Photo Albums</span>
                  </a>
                </li>
                <li
                  onClick={() => setactActiveTab(2)}
                  className={
                    activeTab == 2
                      ? "border-t border-gray-700 -mt-px text-gray-700 text-center"
                      : "text-center"
                  }
                >
                  <a class="flex flex-col p-3" href="#">
                    <i
                      class="fas fa-book 
                             px-1 pt-1 rounded text-xl md:text-xs"
                    ></i>
                    <span class="inline p-1">Books</span>
                  </a>
                </li>
                <li
                  onClick={() => setactActiveTab(3)}
                  className={
                    activeTab == 3
                      ? "border-t border-gray-700 -mt-px text-gray-700 text-center"
                      : "text-center"
                  }
                >
                  <a class="flex flex-col p-3" href="#">
                    <i
                      class="fas fa-meteor
                             px-1 pt-1 rounded text-xl md:text-xs"
                    ></i>
                    <span class="inline p-1">Comics</span>
                  </a>
                </li>
              </ul>
            ) : (
              ""
            )}
          </div>
        </div>
      </main>

      {activeTab == 4 ? (
        <ProfileList
          publisherId={userId}
          type="tracking"
          searchText={searchText}
        />
      ) : (
        ""
      )}

      {activeTab == 5 ? (
        <ProfileList
          publisherId={userId}
          type="trackers"
          searchText={searchText}
        />
      ) : (
        ""
      )}

      {activeTab < 4 && isPublisher() ? (
        <div className="w-full p-2">
          <div className="relative flex flex-col md:grid md:grid-cols-4 items-center content-center justify-center w-full gap-4 ">
            {books.map((book, i) => (
              <div className="p-3 shadow m-2 text-center z-50 md:w-full cursor-pointer flex flex-col items-center relative">
                <div
                  className="bg-white rounded rounded-full bg-white p-0 -top-5 right-0 absolute px-1 cursor-pointer shadow"
                  onClick={() =>
                    book.group == "standard"
                      ? (window.location.href = `/publisher/${book.owner.uuid}/publications/series/m/book/${book.id}`)
                      : (window.location.href = `/publisher/rich-page/update/user/${userId}/page/${book.uuid}`)
                  }
                >
                  <i class="fa fa-pen text-blue-500  z-50 text-2xl"></i>
                </div>
                <img
                  className="rounded-lg
                       p-1 w-48"
                  onClick={() =>
                    book.group == "standard"
                      ? (window.location.href = `/publisher/${book.owner.uuid}/publications/series/m/book/${book.id}`)
                      : (window.location.href = `/publisher/rich-page/update/user/${userId}/page/${book.uuid}`)
                  }
                  src={
                    BASE_URL + "/storage/" + book.cover_photo.split("public")[1]
                  }
                />
                <div className="">
                  <p className="font-bold text-lg text-center">{book.name}</p>
                  {/* <p className="font-bold text-2xl text-center">{book.name}</p> */}

                  {/* <p className="text-gray-600">
                  {book.series && book.series.name}
                </p> */}
                  <p className="text-red-500 text-sm">by</p>
                  <p className="text-black-500">{book.owner.name}</p>

                  <p className="text-black-500 mt-2">
                    Views{" "}
                    {book.pages &&
                      book.pages.length &&
                      `(${book.pages[0].views})`}
                  </p>
                </div>
                <RatingBox
                  book={book}
                  lastRatedBook={lastRatedBook}
                  rateBook={rateBook}
                  currentRating={currentRating}
                />
                <div className="my-4 mx-1 text-center bg-gray-100 rounded-lg p-2">
                  <div className="flex justify-around gap-4">
                    <label className="flex items-center gap-4">
                      <div>
                        {" "}
                        <span className="font-bold">
                          {" "}
                          {published.includes(book.uuid) ? "Hide" : "Unhide"}
                        </span>
                      </div>
                    </label>
                    <div className="flex items-center gap-2">
                      <Switch
                        onChange={(e) => handlePublishStatus(e, book)}
                        checked={published.includes(book.uuid)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center w-full">
            <button
              disabled={!nextPageURL}
              class={`whitespace-nowrap inline-flex items-center justify-center px-8 py-4 mt-8 border border-transparent rounded-md shadow-sm text-base font-medium text-white ${
                !nextPageURL ? "bg-gray-500 hover:bg-gray-500" : "bg-black"
              } hover:bg-black md:mr-8 `}
              onClick={() => loadMore()}
            >
              View More
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </section>
  );
}
