export default function LoadingProgress(props) {
  return (
    <>
      <div className="flex justify-center w-full">
        {" "}
        {props.loading ? (
          <p className="text-lg text-red-700 font-light">Loading...</p>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
