import Checkbox from "../../../../../../../components/inputs/checkbox";
import EditorTooltip from "../../tooltip/editor-tooltip";
export default function ReqPurchaserReply(props) {
  const { productMeta, handleMetaChange } = props;
  return (
    <div className="inline-flex gap-1 w-full md:w-6/12 justify-center relative">
      <div>
        <Checkbox
          handleChange={(e) => {
            if (productMeta.purchaser_reply_enabled) {
              handleMetaChange("", "purchaser_reply", "text");
            }
            handleMetaChange(e, "purchaser_reply_enabled", "checkbox");
          }}
          checked={productMeta.purchaser_reply_enabled}
        />
      </div>
      <span>Request Purchaser Reply</span>
      <EditorTooltip
        body={`When this option is enabled, a textbox will appear below allowing you to prompt the customer to supply specific details before they can continue with the purchase.`}
        top={"-230px"}
        right={"-55px"}
      />
    </div>
  );
}
