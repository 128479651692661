import BasicButton from "../components/buttons/basic-button";
import { useState, useEffect } from "react";
import WebHeader from "./web/header";

import {
  isAdmin,
  isPublisher,
  isAuthUser,
  isResponsiveMode,
  isSupplier,
  authUserData,
  API_BASE,
} from "../Utils/helpers";

export default function SignupOptions() {
  const [currTooltip, setCurrentTooltip] = useState(0);

  useEffect(() => {}, []);

  return (
    <>
      <WebHeader />
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 bold">Create</h1>
        </div>
        <div className="flex gap-2 items-start">
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-4 mt-8">
        <div className="w-full p-2">
          <div className="flex flex-col gap-4 relative">
            <div className="text-center flex justify-center gap-2">
              <BasicButton
                classes={
                  "bg-black-500 hover:bg-black-500 w-full md:w-4/12 py-6"
                }
                title={"Create Purchaser Account"}
                handleClick={() => (window.location.href = `/signup`)}
              />

              {/* <div className="self-center">
                <i
                  class="fas fa-question-circle my-text-yellow-500 cursor-pointer"
                  onClick={() => setCurrentTooltip(2)}
                  onMouseOver={() => setCurrentTooltip(2)}
                  onMouseLeave={() => setCurrentTooltip(0)}
                ></i>

                <div
                  className={`absolute ${
                    isResponsiveMode() ? "m-auto left-0 right-0 w-10/12" : ""
                  } md:w-3/12 absolute bg-white md:bg-black text-black md:text-white rounded-lg overflow-auto md:h-fit text-sm shadow px-1 z-50 py-4 ${
                    currTooltip == 4 ? "flex flex-col items-center" : "hidden"
                  }`}
                  style={{
                    right: !isResponsiveMode() ? "50px" : "0px",
                    top: "10px",
                  }}
                >
                  <div className="h-48 md:h-fit overflow-auto p-2 text-left"></div>
                  <BasicButton
                    handleClick={() => setCurrentTooltip(0)}
                    classes={"bg-gray-500 w-5/12 md:hidden"}
                    title={"Close"}
                  />
                </div>
              </div> */}
            </div>{" "}
            {/* <div className="text-center flex justify-center gap-2">
              <BasicButton
                classes={
                  "bg-black-500 hover:bg-black-500 w-full md:w-4/12 py-6"
                }
                title={"Create Content Monetizer Account"}
                handleClick={() =>
                  (window.location.href = `/contentmonetizers`)
                }
              />
            </div> */}
            <div className="text-center flex justify-center gap-2">
              <BasicButton
                classes={
                  "bg-black-500 hover:bg-black-500 w-full md:w-4/12 py-6"
                }
                title={"Create Monetizer Account"}
                handleClick={() => (window.location.href = `/supplier/signup`)}
              />
              {/* <div className="self-center">
                <i
                  class="fas fa-question-circle my-text-yellow-500 cursor-pointer"
                  onClick={() => setCurrentTooltip(1)}
                  onMouseOver={() => setCurrentTooltip(1)}
                  onMouseLeave={() => setCurrentTooltip(0)}
                ></i>
                <div
                  className={`absolute ${
                    isResponsiveMode() ? "m-auto left-0 right-0 w-10/12" : ""
                  } md:w-3/12 absolute bg-white md:bg-black text-black md:text-white rounded-lg overflow-auto md:h-fit text-sm shadow px-1 z-50 py-4 ${
                    currTooltip == 1 ? "flex flex-col items-center" : "hidden"
                  }`}
                  style={{
                    right: !isResponsiveMode() ? "50px" : "0px",
                    top: "10px",
                  }}
                >
                  <div className="h-48 md:h-fit overflow-auto p-2 "></div>
                  <BasicButton
                    handleClick={() => setCurrentTooltip(0)}
                    classes={"bg-gray-500 w-5/12 md:hidden"}
                    title={"Close"}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
