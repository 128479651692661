import { useEffect, useState } from "react";
import BasicButton from "../../../../../components/buttons/basic-button";
import authRequestService from "../../../../../services/HttpRequests/authenticated-request-services";
import { useParams } from "react-router-dom";
import {
  isAdmin,
  API_BASE,
  isAuthUser,
  isResponsiveMode,
  BASE_URL_WITH_LOCAL,
  BASE_URL,
} from "../../../../../Utils/helpers";
import { toast } from "react-toastify";

export default function AdminREContractsUpload() {
  const [fileSelected, setFileSelected] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");
  const [processing, setProcessing] = useState(false);
  const [contract, setContract] = useState({});

  let { type } = useParams();

  const uploadContract = () => {
    const url = API_BASE + "/admin/supplier/contract/recurring/create";
    setProcessing(true);
    let formData = new FormData();
    formData.append("type", type);
    formData.append("file", fileSelected);
    formData.append("name", getPageTitle());
    authRequestService()
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        getContract();
        setSelectedFileType("");
        setFileSelected("");
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getContract = () => {
    const url =
      API_BASE + "/admin/supplier/contract/admin/" + type + "/default";
    setProcessing(true);

    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        setContract(response.data.contract);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const uploadCover = (e) => {
    let [file] = e.target.files;

    if (file) {
      if (!file["type"].includes("pdf")) {
        toast("Invalid file type, please upload (PDF)", {
          type: "error",
        });
        return false;
      } else {
        setSelectedFileType("pdf");
        setFileSelected(file);
      }
    }
  };

  const removeCover = () => {
    setFileSelected("");
  };

  const getPageTitle = () => {
    const titles = {
      "subscription-contract": "Subscription Contract (Recurring Purchase)",
      "hire-contract": "Hire Contract (Recurring Purchase)",
      "property-rental-contract":
        "Property Rental Contract (Recurring Purchase)",
      "item-rental-contract": "Item Rental Contract (Recurring Purchase)",
      "vacation-rental-contract":
        "Vacation Rental Contract (Recurring Purchase)",
    };
    return titles[type];
  };

  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
    getContract();
  }, []);

  return (
    <>
      <div className="main w-full">
        <div className="bg-white m-2 p-2 flex justify-between shadow px-4">
          <div>
            <h1 className="text-2xl text-center m-2 bold"> {getPageTitle()}</h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex justify-center my-4 overflow-hidden">
          <div class="flex flex-col items-center p-10 gap-4 md:w-6/12 border rouunded-lg p-4">
            <div>
              {" "}
              {contract?.id && (
                <div className="flex gap-4 justify-center items-center bg-gray-50 p-2">
                  <div className="w-12 p-2 relative">
                    <img
                      src={
                        type == "subscription-contract" ||
                        type == "hire-contract" ||
                        type == "property-rental-contract" ||
                        type == "item-rental-contract" ||
                        type == "vacation-rental-contract"
                          ? "/pdf-icon.png"
                          : "/word-icon.png"
                      }
                      className="w-full"
                    />
                  </div>{" "}
                  <div className="">
                    <a
                      href={
                        type == "subscription-contract" ||
                        type == "hire-contract" ||
                        type == "property-rental-contract" ||
                        type == "item-rental-contract" ||
                        type == "vacation-rental-contract"
                          ? BASE_URL_WITH_LOCAL +
                            "/storage" +
                            contract.file.split("public")[1]
                          : "#"
                      }
                      target="_blank"
                    >
                      <span className="bg-teal-500 rounded-lg p-2 text-white">
                        View Contract
                      </span>
                    </a>
                  </div>
                </div>
              )}
              <hr />
            </div>
            <div className="m-2 text-center">
              <label className="text-black m-2">Upload Contract</label>
              <div className="flex justify-center my-2">
                {!fileSelected && (
                  <div className="w-32 h-32 bg-gray-100 p-2">
                    <div className="flex flex-col justify-center  h-full">
                      <i class="fa fa-plus cursor-pointer text-green-500"></i>
                      <p className="text-xs">Click to upload a pdf</p>
                      <label className="my-2">
                        <input
                          type="file"
                          onChange={(e) => uploadCover(e)}
                          className="text-sm 
                        text-grey-500
                        file:mr-2
                        file:py-2
                        file:px-2
                        file:rounded-full
                        file:border-0
                        file:text-md
                        file:font-semibold
                        file:text-white
                        file:bg-gradient-to-r
                        file:from-blue-600
                        file:to-amber-600
                        hover:file:cursor-pointer
                        hover:file:opacity-80"
                        />
                      </label>
                    </div>
                  </div>
                )}

                {fileSelected && selectedFileType == "pdf" && (
                  <div className="w-32 h-32 bg-gray-100 p-2 relative">
                    <img src={"/pdf-icon.png"} className="w-full" />
                    <div
                      className="bg-white rounded rounded-full bg-white p-0 top-0 right-0 absolute px-1 cursor-pointer"
                      onClick={() => removeCover()}
                    >
                      <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
                    </div>
                  </div>
                )}
                {fileSelected && selectedFileType == "word" && (
                  <div className="w-32 h-32 bg-gray-100 p-2 relative">
                    <img src={"/word-icon.png"} className="w-full" />
                    <div
                      className="bg-white rounded rounded-full bg-white p-0 top-0 right-0 absolute px-1 cursor-pointer"
                      onClick={() => removeCover()}
                    >
                      <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {fileSelected && (
              <BasicButton
                disabled={processing}
                title={processing ? "Uploading.." : "Upload"}
                handleClick={() => uploadContract()}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
