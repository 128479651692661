import {
  removeAtomicBlocFromEditor,
  editEditorImaeAtBlockPosition,
} from "../helpers/functions";
import { deleteEditorFileRequest } from "../API/editor-api-calls";
import { useBookStore } from "../../../../stores/book-store";
import { convertToRaw } from "draft-js";
import { toast } from "react-toastify";
import { useState } from "react";

export default function Hire({
  pageId,
  blockKey,
  hireText,
  hirePrice,
  hireReplySubject,
  replyEnabled,
  editorState,
  setEditorState,
  editorStateHelper,
  showHireModalUpdateBox,
}) {
  const { isDataSubExpired } = useBookStore((state) => state);
  const deleteEditorHire = () => {
    removeAtomicBlocFromEditor(
      blockKey,
      editorState,
      setEditorState,
      editorStateHelper
    );
  };

  return (
    <div class="flex flex-wrap justify-center mb-10">
      <p className="bg-pink-100 p-2 rounded-lg relative px-6">
        {!isDataSubExpired ? (
          <span
            className="bg-white rounded rounded-full bg-white p-0 right-0 absolute px-1 cursor-pointer"
            style={{ top: -22, right: 40 }}
          >
            <i
              class="fa fa-edit text-blue-500  z-50 text-2xl"
              onClick={() => {
                showHireModalUpdateBox(
                  hireText,
                  hirePrice,
                  hireReplySubject,
                  replyEnabled,
                  blockKey
                );
              }}
            ></i>
          </span>
        ) : (
          ""
        )}
        {!isDataSubExpired ? (
          <span
            className="bg-white rounded rounded-full bg-white p-0  right-0 absolute px-1 cursor-pointer"
            style={{ top: -22, right: 0 }}
            onClick={() => {
              deleteEditorHire();
            }}
          >
            <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
          </span>
        ) : (
          ""
        )}
        <span className="text-black font-bold">Hire Now</span>{" "}
      </p>
    </div>
  );
}
