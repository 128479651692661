import axios from "axios";
import AdSense from "react-adsense";
import { useEffect, useState } from "react";
import { globalAds, getLocation, API_BASE } from "../../../Utils/helpers";
import Adsterra from "../../../components/advert/adsterra";
import { useIntersectionObserver } from "@uidotdev/usehooks";

export function RenderAd({ filename, adRequest, bookId }) {
  useEffect(() => {}, []);

  const [refInViewPort, entryInViewPort] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: "0px",
  });

  const [hasRecordedImps, setHasRecordedImps] = useState(false);

  const randomPick = Math.floor(Math.random() * 16);
  const imageUrl = globalAds[randomPick].image;
  const imageLink = globalAds[randomPick].link;

  const recordAdImpressions = (res) => {
    const url = API_BASE + "/api/advert/record/impressions";

    axios
      .post(url, {
        book_id: bookId,
      })
      .then((response) => {
        if (response.status == 200) {
          setHasRecordedImps(true);
        }
      })
      .catch((e) => {});
  };

  const recordAdClicks = (link) => {
    const url = API_BASE + "/api/advert/record/clicks";

    axios
      .post(url, {
        book_id: bookId,
      })
      .then((response) => {
        if (response.status == 200) {
          window.location.href = link;
        }
      })
      .catch((e) => {});
  };

  const renderAdvertTemplate = (code) => {
    if (adRequest.status == "approved") {
      if (adRequest.provider == "ADSENSE") {
        const pubCode = adRequest.ad_code;
        const litzappCode = adRequest.system_ad_code;
        return (
          <>
            {" "}
            <div className="flex justify-center w-full">
              <AdSense.Google
                client="ca-pub-8191791319921859"
                slot={pubCode}
                style={{ width: 250, height: 250, float: "left" }}
                layout="in-article"
                format=""
              />
            </div>
          </>
        );
        /*  */
      } else {
        const pubCode = adRequest.ad_2_code;
        const litzappCode = adRequest.system_ad_2_code;
        return (
          <>
            <div className="flex justify-center w-full">
              <Adsterra slot={pubCode} />;
            </div>
          </>
        );
      }
    }

    if (adRequest.status == "unprocessed") {
      return (
        <>
          <div className="flex justify-center w-full" ref={refInViewPort}>
            <p className="text-black text-2xl">
              {entryInViewPort?.isIntersecting && !hasRecordedImps
                ? recordAdImpressions()
                : ""}
            </p>{" "}
            {globalAds.length && (
              <img
                className="cursor-pointer"
                src={imageUrl}
                onClick={() => {
                  recordAdClicks(imageLink);
                }}
              />
            )}
          </div>
          <p className="mt-0"></p>
        </>
      );
    }
  };

  return getLocation().hostname == "litzapp.com" ? (
    renderAdvertTemplate()
  ) : (
    <>
      <div className="flex justify-center w-full" ref={refInViewPort}>
        <p className="text-black text-2xl">
          {entryInViewPort?.isIntersecting && !hasRecordedImps
            ? recordAdImpressions()
            : ""}
        </p>{" "}
        {globalAds.length && (
          <img
            className="cursor-pointer"
            src={imageUrl}
            onClick={() => {
              const link = globalAds[randomPick].link;
              recordAdClicks(link);
            }}
          />
        )}
      </div>
      <p className="mt-0"></p>
    </>
  );
}
