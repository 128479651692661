import { removeAtomicBlocFromEditor } from "../helpers/functions";

export default function Slides({
  blockKey,
  payload,
  editorState,
  setEditorState,
  editorStateHelper,
}) {
  const deleteEditorSlide = () => {
    removeAtomicBlocFromEditor(
      blockKey,
      editorState,
      setEditorState,
      editorStateHelper
    );
  };

  return (
    <div class="flex flex-wrap justify-center mb-5">
      {/* {JSON.parse(payload).block_meta_id} */}
      {/* <p className="w-30 z-80"> {payload}</p> */}
      <div
        className="border bg-gray-50 p-2 rounded-lg flex gap-2 items-center"
        contenteditable="false"
      >
        Slide
        <span
          className="cursor-pointer"
          onClick={() => {
            deleteEditorSlide();
          }}
        >
          <i
            class={`fa fa-times-circle text-red-500  z-50 text-2xl product-page-editor-button`}
          ></i>
        </span>
      </div>
    </div>
  );
}
