import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BasicButton from "../../components/buttons/basic-button";
import TextField from "../../components/inputs/text-input";
import authRequestService from "../../services/HttpRequests/authenticated-request-services";
import Pagination from "../../components/pagination";
import LoadingProgress from "../publisher/rich-page/components/loading-progress";

import SponsorDesktopSystemSubTable from "./components/desktop-table";
import SponsorMobileSystemSubTable from "./components/mobile-table";
import DeletePromptModal from "../../modals/delete-warning-modal";
import PaymentSuccessModal from "../../modals/payment-success-modal";

import {
  authUserData,
  isAuthUser,
  isAdmin,
  API_BASE,
  BASE_URL,
  getLocation,
  isResponsiveMode,
  isSponsor,
} from "../../Utils/helpers";
import { toast } from "react-toastify";

import axios from "axios";
export default function SponsorDashboard() {
  const [user, setUser] = useState({});
  const [processing, setProcessing] = useState(false);
  const [subPlans, setSubPlans] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [currSelSub, setCurrSelSub] = useState({});
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [paySuccessModalOpen, setPaySuccessModalOpen] = useState(false);

  const userId = JSON.parse(isAuthUser()).uuid;
  const getSponsorSystemSubs = () => {
    let url = API_BASE + "/sponsor/system/subscription/" + userId;
    setProcessing(true);
    return authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setSubPlans(response.data.subs);
          setPaySuccessModalOpen(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const doPagination = (page) => {
    setProcessing(true);
    if (subPlans.first_page_url) {
      setCurrentPage(page);
      axios
        .get(subPlans.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          setSubPlans(res.data.subs);

          setProcessing(false);
        })
        .catch((err) => {
          setProcessing(false);
          toast("Something went wrong", { type: "error" });
        });
    }
  };

  const cancelSubscriptionPlan = (id) => {
    const url = API_BASE + `/sponsor/system/subscription/plan/${id}/cancel`;
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((response) => {
        toast("Cancelled", { type: "success" });
        getSponsorSystemSubs();
        closeCancelModal();
        setCurrSelSub({});
        setProcessing(false);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const closeCancelModal = () => {
    setCancelModalOpen(false);
  };

  const openCancelModal = (d) => {
    setCurrSelSub(d);
    setCancelModalOpen(true);
  };

  const openPaySuccessModal = () => {
    setPaySuccessModalOpen(true);
  };

  const closePaySuccessModal = () => {
    setPaySuccessModalOpen(false);
  };

  const f = {
    openCancelModal: openCancelModal,
  };
  useEffect(() => {
    if (!isAuthUser() || !isSponsor()) {
      window.location.href = "/login";
    }

    setTimeout(() => {
      if (localStorage.getItem("sponsor_payment_redirect") !== null) {
        localStorage.removeItem("sponsor_payment_redirect");
        openPaySuccessModal();
      }
    }, 600);
    getSponsorSystemSubs();
  }, []);

  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow items-center">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold flex flex-col md:flex-row">
            <span className="text-lg font-bold">
              {subPlans.data && subPlans.data[0].user.name}
            </span>
          </h1>
        </div>

        <div className="flex flex-row gap-2">
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div className="flex justify-center my-4 overflow-hidden">
        <div class="flex flex-col items-center p-10 w-full">
          <p className="w-full text-center my-4">
            We found {subPlans.total ? subPlans.total : ""} records
          </p>

          <div className="flex justify-center">
            {subPlans.data ? (
              subPlans.data.length ? (
                <Pagination pagination={subPlans} doPagination={doPagination} />
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>

          {isResponsiveMode() ? (
            <SponsorMobileSystemSubTable data={subPlans} f={f} />
          ) : (
            <SponsorDesktopSystemSubTable data={subPlans} f={f} />
          )}

          <LoadingProgress loading={processing} />

          <div className="flex justify-center">
            {subPlans.data ? (
              subPlans.data.length ? (
                <Pagination pagination={subPlans} doPagination={doPagination} />
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {currSelSub.id ? (
        <DeletePromptModal
          modalOpen={cancelModalOpen}
          hideAdModal={closeCancelModal}
          title={"Are you sure you want to cancel your subscription?"}
          yesActionText={"Yes, Delete"}
          noActionText={"No, Cancel"}
          action={() => cancelSubscriptionPlan(currSelSub.uuid)}
          processing={processing}
        />
      ) : (
        ""
      )}
      <PaymentSuccessModal
        modalOpen={paySuccessModalOpen}
        hideAdModal={closePaySuccessModal}
        action={getSponsorSystemSubs}
        processing={processing}
      />
    </>
  );
}
