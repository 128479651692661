import axios from "axios";
import { useEffect, useState } from "react";
import BasicButton from "../../../components/buttons/basic-button";
import TextField from "../../../components/inputs/text-input";
import Pagination from "../../../components/pagination";
import useDebounce from "../../../components/debounce/debounce-helper";
import LoadingProgress from "../rich-page/components/loading-progress";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";
import authPaginatedRequestService from "../../../services/HttpRequests/authenticated-paginated-req-service";
import {
  isAdmin,
  API_BASE,
  isPublisher,
  isAuthUser,
  authUserData,
  getLocation,
  isReader,
} from "../../../Utils/helpers";
import { toast } from "react-toastify";
export default function PublisherMyPurchases() {
  const [purchases, setPurchases] = useState({});
  const [searchText, setSearchText] = useState("");
  const [countPerPage, setCountPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [processing, setProcessing] = useState(false);

  const doPagination = (page) => {
    if (purchases.first_page_url) {
      setCurrentPage(page);
      authPaginatedRequestService()
        .get(purchases.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          setPurchases(res.data.purchases);
        })
        .catch((err) => console.log(err));
    }
  };

  const getPurchases = () => {
    setProcessing(true);
    setPurchases([]);
    const url =
      API_BASE +
      "/publisher/my/purchases/" +
      authUserData().uuid +
      "/" +
      countPerPage +
      "/" +
      searchText;
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setProcessing(false);
          setPurchases(response.data.purchases);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e);
  };

  const handleCountPerPageChange = (e) => {
    setCountPerPage(e.target.value);
  };

  useDebounce(
    () => {
      getPurchases();
    },
    [searchText, countPerPage],
    800
  );

  useEffect(() => {
    if (!isPublisher()) {
      window.location.href = "/";
    }
  }, []);
  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">My Content Buys</h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            <p className="w-full text-center my-4">
              We found {purchases.total ? purchases.total : ""} records
            </p>
            <div className="flex  w-full gap-4">
              <div className="w-11/12">
                <TextField
                  title="Search"
                  placeholder={"Search"}
                  classes="rounded-lg"
                  handleChange={handleSearchTextChange}
                  value={searchText}
                />
              </div>

              <div className="w-1/12">
                <label for="timezone" value="Your Timezone" />
                <select
                  onChange={handleCountPerPageChange}
                  required
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full"
                >
                  <option value="10" default>
                    10
                  </option>
                  {/* <option value="2">2</option> */}
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <div className="flex justify-center">
              {" "}
              {purchases.data ? (
                purchases.data.length ? (
                  <Pagination
                    pagination={purchases}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>

            <table class="table-auto w-full my-4">
              <thead>
                <tr className={window.innerWidth < 720 ? "hidden" : ""}>
                  <th class="px-4 py-2 text-gray-600">Publisher</th>
                  <th class="px-4 py-2 text-gray-600">Item Title</th>
                  <th class="px-4 py-2 text-gray-600">Item ID</th>
                  <th class="px-4 py-2 text-gray-600">Price</th>
                  <th class="px-4 py-2 text-gray-600">Transaction ID</th>
                  <th class="px-4 py-2 text-gray-600">Date</th>
                </tr>
              </thead>
              <tbody>
                {purchases.data
                  ? purchases.data.map((purchase) => {
                      return (
                        <tr
                          className={
                            window.innerWidth < 820
                              ? "flex flex-col items-center my-4"
                              : ""
                          }
                        >
                          <td class="border  px-4 py-2  font-medium text-center md:text-left">
                            <h3 className="font-bold md:hidden">Publisher</h3>
                            {purchase.book?.author}
                            <p>
                              <a
                                href={`/web/${purchase.book.owneruuid}`}
                                className="underline"
                              >
                                <p className="my-2">
                                  <span className="text-xs">
                                    {" "}
                                    user handle:{" "}
                                  </span>
                                  <span className="text-yellow-600 text-xs font-bold">
                                    {purchase.book.OwnerUserHandle}
                                  </span>
                                </p>
                              </a>
                            </p>
                          </td>
                          {/* <td class="border px-4 py-2  font-medium">
                          <h3 className="font-bold md:hidden">Email</h3>
                          <p className="word-wrap break-words">
                            {purchase.buyer.email}
                          </p>
                        </td> */}
                          <td class="border px-4 py-2  font-medium text-center md:text-left">
                            <h3 className="font-bold md:hidden">Item Title</h3>
                            <p className="my-text-yellow-500">
                              {" "}
                              {purchase.book.group == "standard"
                                ? "File"
                                : "Custom Page"}
                            </p>
                            <p>
                              <a
                                className="underline"
                                href={
                                  purchase.book.group == "standard"
                                    ? `/web/reader/${purchase.book.book_uuid}`
                                    : "/web/rich-page/render/" +
                                      purchase.book.book_uuid
                                }
                              >
                                {purchase.book.name}
                              </a>
                            </p>
                          </td>

                          <td class="border  px-4 py-2  font-medium text-center md:text-left">
                            <h3 className="font-bold md:hidden">Item ID</h3>
                            {purchase.book.item_id}
                          </td>
                          <td class="border  px-4 py-2  font-medium text-center md:text-left">
                            <h3 className="font-bold md:hidden">Price</h3>$
                            {purchase.amount / 100}
                          </td>
                          <td class="border  px-4 py-2  font-medium text-center md:text-left">
                            <h3 className="font-bold md:hidden">
                              Transaction ID
                            </h3>
                            {purchase.transaction_id}
                          </td>

                          <td class="border  px-4 py-2  font-medium text-center md:text-left">
                            <h3 className="font-bold md:hidden">Date</h3>
                            <p>
                              {purchase.refactoredCreatedAtTime
                                ? new Date(
                                    purchase.refactoredCreatedAtTime.replace(
                                      /-/g,
                                      "/"
                                    )
                                  ).getDate() +
                                  "-" +
                                  (new Date(
                                    purchase.refactoredCreatedAtTime.replace(
                                      /-/g,
                                      "/"
                                    )
                                  ).getMonth() +
                                    1) +
                                  "-" +
                                  new Date(
                                    purchase.refactoredCreatedAtTime.replace(
                                      /-/g,
                                      "/"
                                    )
                                  ).getFullYear()
                                : "N/A"}
                            </p>
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </table>
            <LoadingProgress loading={processing} />
            <div className="flex justify-center">
              {" "}
              {purchases.data ? (
                purchases.data.length ? (
                  <Pagination
                    pagination={purchases}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
