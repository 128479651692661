import RadioBox from "../../../../../components/inputs/radio";
import Checkbox from "../../../../../components/inputs/checkbox";
import "react-datepicker/dist/react-datepicker.css";
import "react-tooltip/dist/react-tooltip.css";
import { useState, useEffect } from "react";
import BasicButton from "../../../../../components/buttons/basic-button";
import MultiImageUploader from "../../components/image-upload-multi";
import { BASE_URL } from "../../../../../Utils/helpers";
export default function SelectImagesModal({
  modalOpen,
  hideAdModal,
  selectedSlides,
  productSlides,
  handleCheckSlide,
  addSlidesToEditor,
  pageContext,
}) {
  const getImageURL = (slide) => {
    if (pageContext == "create") {
      return URL.createObjectURL(slide.file);
    } else {
      return BASE_URL + "/storage" + slide.file.split("public")[1];
    }
  };

  return (
    <div
      className={`absolute z-50 overflow-y-auto top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-6/12 h-4/12 P-2"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className="rounded rounded-full p-0 -top-2 -right-1 absolute px-1 cursor-pointer opacity-75"
            onClick={() => hideAdModal()}
          >
            <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
          </div>
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <h1 className="text-2xl font-bold text-center flex items-center justify-center">
                {" "}
                <span>Image Slide</span>
              </h1>
              <div className="flex gap-2 w-full justify-center">
                <div className="flex gap-1"></div>
              </div>

              <div className="flex flex-col w-full justify-center ">
                {productSlides.length ? (
                  <div className="w-full p-2 my-2 h-64 overflow-auto border">
                    <div className="-m-1 grid grid-cols-2 md:grid-cols-4 md:-m-2 py-3">
                      {productSlides.map((slide, i) => (
                        <div className="flex items-center" key={i}>
                          <Checkbox
                            handleChange={() => handleCheckSlide(slide?.id)}
                            checked={selectedSlides.includes(slide?.id)}
                          />
                          <div class="w-full p-1 md:p-2">
                            {slide?.file && (
                              <img
                                alt="gallery"
                                class="block h-full w-full rounded-lg object-cover object-center"
                                src={getImageURL(slide)}
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <BasicButton
                handleClick={addSlidesToEditor}
                disabled={!selectedSlides.length}
                title={"Create Slide"}
                classes={"my-3"}
              />
            </div>
            {/* {!createProductMetaMode ? productMeta.p_type_sub_opt : "Invalid"} */}

            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => {
                hideAdModal();
              }}
            >
              <i class="fas fa-times"></i> Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
