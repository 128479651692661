import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BasicButton from "../../../../../components/buttons/basic-button";
import Pagination from "../../../../../components/pagination";
import useDebounce from "../../../../../components/debounce/debounce-helper";
import LoadingProgress from "../../../../publisher/rich-page/components/loading-progress";
import { useSupplierApplicationsStore } from "../../../../../stores/supplier-applications-store";
import FilterBar from "./components/filter";
import authRequestService from "../../../../../services/HttpRequests/authenticated-request-services";
import DesktopTable from "./components/desktop-table";
import MobileTable from "./components/mobile-table";
import ReplaceContractModal from "./components/replace-contract-modal";
import authPaginatedRequestService from "../../../../../services/HttpRequests/authenticated-paginated-req-service";

import {
  isAdmin,
  API_BASE,
  isPublisher,
  isAuthUser,
  authUserData,
  getLocation,
  isResponsiveMode,
} from "../../../../../Utils/helpers";
import { toast } from "react-toastify";

export default function PubSupplierMyContractsConnButtons() {
  const [currentPage, setCurrentPage] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [paginateMode, setPaginateMode] = useState(false);
  const [user, setUser] = useState({});
  const [blockedEmails, setBlockedEmails] = useState([]);
  const mountedRef = useRef();
  const navigate = useNavigate();
  const mailStore = useSupplierApplicationsStore((state) => state);
  const [showReplaceContractModal, setShowReplaceContractModal] =
    useState(false);
  const [productContracts, setProductContracts] = useState([]);
  const [selectedContractId, setSelectedContractId] = useState("");

  const { id } = useParams();

  const fetchUser = () => {
    const url = API_BASE + "/publisher/" + authUserData().uuid + "/optimized";
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setUser(response.data.publisher);
          mailStore.setUser(response.data.publisher);
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  const doPagination = (page) => {
    if (mailStore.data.first_page_url) {
      mailStore.setData([]);
      setCurrentPage(page);
      setPaginateMode(true);
      setProcessing(true);
      authPaginatedRequestService()
        .get(mailStore.data.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          mailStore.setData(res.data.metas);
          setProcessing(false);
        })
        .catch((err) => setProcessing(false));
    }
  };

  const getMetas = () => {
    mailStore.setData([]);
    setProcessing(true);
    setPaginateMode(false);
    const url = API_BASE + `/publisher/supplier/contract/${id}/metas`;
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          mailStore.setData(response.data.metas);
          setProcessing(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getApprovedContracts = () => {
    setProcessing(true);
    const url = API_BASE + "/publisher/supplier/contracts/approved/retrieve";
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setProductContracts(response.data.contracts);
          setProcessing(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const processSelection = () => {
    setProcessing(true);
    const url = API_BASE + "/publisher/supplier/contracts/replace/process";
    authRequestService()
      .post(url, {
        current_contract_id: id,
        selected_contract_id: selectedContractId,
      })
      .then((response) => {
        if (response.status == 200) {
          getMetas();
          setProcessing(false);
          closeReplaceContractModal();
          toast("Success", { type: "success" });
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const handleContractIdChange = (e) => {
    setSelectedContractId(e.target.value);
  };

  const handleSearchTextChange = (e) => {
    mailStore.setQuery("searchText", e);
  };

  const handleCountPerPageChange = (e) => {
    mailStore.setQuery("countPerPage", e.target.value);
  };

  const handleLatestEntriesChange = (e) => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
    mailStore.setQuery("selectedLatest", e.target.value);
    mailStore.setQuery("pageLimit", e.target.value);
  };

  const handleOldestEntriesChange = (e) => {
    mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
    mailStore.setQuery("selectedOldest", e.target.value);
    mailStore.setQuery("pageLimit", e.target.value);
  };

  const handleStatusChange = (e) => {
    mailStore.setQuery("status", e.target.value);
  };

  const showSendButton = () => {
    if (!mailStore.data.total) return false;
    if (mailStore.selected.length) return true;
    if (mailStore.isAllSelected) {
      if (mailStore.deSelected.length !== mailStore.data.total) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedLatest]);

  useEffect(() => {
    mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedOldest]);

  useDebounce(
    () => {
      if (mailStore.refresh) {
        // getApplications();
      }
      mailStore.setRefresh(true);
    },
    [mailStore.query],
    800
  );

  const openReplaceContractModal = (Comment) => {
    setSelectedContractId("");
    setShowReplaceContractModal(true);
    getApprovedContracts();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const closeReplaceContractModal = () => {
    setShowReplaceContractModal(false);
  };

  useEffect(() => {
    if (!isPublisher()) {
      window.location.href = "/";
    }
    fetchUser();
    getMetas();
  }, []);

  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">Connected Buttons</h1>
          </div>
          <div className="flex justify-between gap-2 items-start">
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            <p className="w-full text-center my-4">
              We found {mailStore.data?.total ? mailStore.data.total : ""}{" "}
              records
            </p>

            <FilterBar
              handleCountPerPageChange={handleCountPerPageChange}
              handleSearchTextChange={handleSearchTextChange}
              serchQValue={mailStore.query.searchText}
              showSendButton={showSendButton}
              handleLatestEntriesChange={handleLatestEntriesChange}
              latestQValue={mailStore.query.selectedLatest}
              handleOldestEntriesChange={handleOldestEntriesChange}
              oldestQValue={mailStore.query.selectedOldest}
              handleStatusChange={handleStatusChange}
              status={mailStore.query.status}
              model={"purchases"}
            />

            <div className="w-full flex justify-center">
              {mailStore.data.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            {isResponsiveMode() ? (
              <MobileTable
                blockedEmails={blockedEmails}
                openReplaceContractModal={openReplaceContractModal}
              />
            ) : (
              <DesktopTable
                blockedEmails={blockedEmails}
                openReplaceContractModal={openReplaceContractModal}
              />
            )}
            <LoadingProgress loading={processing} />
            <div className="w-full flex justify-center">
              {mailStore.data.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <ReplaceContractModal
        processSelection={processSelection}
        productContracts={productContracts}
        modalOpen={showReplaceContractModal}
        closeModal={closeReplaceContractModal}
        handleContractIdChange={handleContractIdChange}
        selectedContractId={selectedContractId}
        currentContractId={id}
        processing={processing}
      />
    </>
  );
}
