import RenderLeftAdPageMediaPreview from "../left-ad-media-preview";
import { isResponsiveMode } from "../../../Utils/helpers";
import LeftPagination from "./left-pagination";
export default function LeftPaginationComposer(props) {
  const {
    views,
    page,
    isAdPage,
    portraitMode,
    isPublisherAdPage,
    setPaginationVerticalPosition,
    checkLastPageHasAdvert,
    isComponentVisible,
    paginateOnAdPage,
    pageNumber,
    onDocumentLoadSuccess,
    showNavigation,
    shouldShowBorder,
    isSystemAdPage,
    refLeftNav,
    paginateLeft,
    bookPagesCount,
    setNavType,
  } = props;

  return (
    <>
      {true ? (
        <div
          className={`left ${
            isResponsiveMode() && shouldShowBorder() ? "border-r" : ""
          } ${
            portraitMode
              ? "col-span-2 p-1"
              : views.length && views[page].type == "pdf"
              ? "col-span-2"
              : "col-span-4"
          }  z-20  ${
            !isResponsiveMode()
              ? "h-screen flex flex-col items-center justify-center"
              : "h-full flex flex-col items-center justify-center"
          }`}
          style={{ backgroundColor: "" }}
          ref={refLeftNav}
          onClick={() => {
            setNavType("CLICK");
            if (views.length && isAdPage()) {
              if (isPublisherAdPage()) {
                paginateOnAdPage && paginateLeft();
              } else {
                paginateLeft();
              }
            } else {
              isResponsiveMode() && paginateLeft();
            }
          }}
        >
          {showNavigation && isComponentVisible && !isResponsiveMode() && (
            <>
              {page !== 0 ? (
                <div
                  className="absolute flex flex-col items-center"
                  style={{
                    top: views.length && isAdPage() ? "15%" : "25%",
                    left: views.length && isAdPage() ? "29%" : "11%",
                  }}
                >
                  <p className="text-white text-xs">Tap Page</p>
                  <p className="text-white text-xs">to Hide/</p>
                  <p className="text-white text-xs">Show</p>
                  <p className="text-white text-xs">Title Panel</p>
                </div>
              ) : (
                ""
              )}

              <div
                className="flex items-center justify-center absolute"
                style={{
                  top:
                    views.length && isAdPage()
                      ? "15%"
                      : setPaginationVerticalPosition(),
                  left: views.length && isAdPage() ? "25%" : "11%",
                }}
              >
                <LeftPagination page={page} handleClick={paginateLeft} />
              </div>
            </>
          )}
          {showNavigation && isResponsiveMode() && (
            <div className="flex flex-col items-center  w-full">
              <div
                className={
                  page !== 0
                    ? ` z-20  ${
                        portraitMode ? "" : "w-full"
                      } flex flex-col items-center justify-center mr-2 text-center text-sm my-4`
                    : "hidden"
                }
              >
                {views.length && !isAdPage() && views[page].type !== "info" ? (
                  <>
                    {" "}
                    <p className="text-white">Tap Page</p>
                    <p className="text-white">to Hide/</p>
                    <p className="text-white">Show</p>
                    <p className="text-white">Title Panel</p>
                    <p className="text-white my-4 ml-0">
                      <LeftPagination handleClick={paginateLeft} />
                    </p>
                  </>
                ) : (
                  <p className="text-white my-4 ml-1">
                    <LeftPagination handleClick={paginateLeft} />
                  </p>
                )}

                <p className="text-white text-sm break-normal"> Previous</p>
              </div>
            </div>
          )}

          {views.length && (
            <RenderLeftAdPageMediaPreview
              views={views}
              page={page}
              pageNumber={pageNumber}
              onDocumentLoadSuccess={onDocumentLoadSuccess}
              portraitMode={portraitMode}
              isAdPage={isAdPage}
              isPublisherAdPage={isPublisherAdPage}
              isSystemAdPage={isSystemAdPage}
            />
          )}
        </div>
      ) : (
        <div
          className={`left ${
            portraitMode
              ? "col-span-2"
              : views.length && views[page].type == "pdf"
              ? "col-span-2"
              : "col-span-4"
          }  z-20  ${
            !isResponsiveMode()
              ? "h-screen flex flex-col items-center justify-center"
              : "h-full flex flex-col items-center justify-center"
          }`}
          style={{ backgroundColor: "" }}
          ref={refLeftNav}
        >
          {showNavigation && isComponentVisible && !isResponsiveMode() && (
            <div
              className="flex items-center justify-center absolute"
              style={{
                top: setPaginationVerticalPosition(),
                left: "11%",
              }}
            >
              <LeftPagination page={page} handleClick={paginateLeft} />
            </div>
          )}

          {showNavigation && isResponsiveMode() && (
            <div
              className={
                page !== 0
                  ? ` z-20  ${
                      portraitMode ? "" : "w-full"
                    } flex flex-col items-center justify-center mr-2 text-center text-sm my-4`
                  : "hidden"
              }
            >
              {views.length &&
              views[page].type !== "advert" &&
              views[page].type !== "info" ? (
                <>
                  {" "}
                  <p className="text-white">Tap Page</p>
                  <p className="text-white">to Hide/</p>
                  <p className="text-white">Show</p>
                  <p className="text-white">Title Panel</p>
                  <p className="text-white my-4 ml-2">
                    <LeftPagination handleClick={paginateLeft} />
                  </p>
                </>
              ) : (
                <p
                  className="text-white my-4 ml-1"
                  onClick={() => paginateLeft()}
                >
                  <LeftPagination handleClick={paginateLeft} />
                </p>
              )}

              <p className="text-white text-sm break-normal"> Previous</p>
            </div>
          )}
        </div>
      )}
    </>
  );
}
