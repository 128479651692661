import { useSupplierApplicationsStore } from "../../../../../stores/supplier-applications-store";
import { isResponsiveMode } from "../../../../../Utils/helpers";
import Checkbox from "../../../../../components/inputs/checkbox";
import BasicButton from "../../../../../components/buttons/basic-button";
import { useNavigate } from "react-router-dom";
import { BASE_URL_WITH_LOCAL } from "../../../../../Utils/helpers";

export default function DesktopTable(props) {
  const mailStore = useSupplierApplicationsStore((state) => state);
  const {
    openCommentModal,
    preProcessApplication,
    processApplication,
    processing,
  } = props;

  const navigate = useNavigate();

  const appicationStatus = {
    approve: "Approve",
    rejecte: "Reject",
  };

  return (
    <div className="overflow-x-scroll md:h-screen w-full">
      {" "}
      <table class="table-auto w-full my-4">
        <thead>
          <tr className={window.innerWidth < 720 ? "hidden" : ""}>
            <th class="px-4 py-2 text-gray-600">View Contract</th>
            <th class="px-4 py-2 text-gray-600">Action</th>
            <th class="px-4 py-2 text-gray-600">Process</th>
          </tr>
        </thead>
        <tbody className="w-full">
          {mailStore.contract?.id && (
            <tr
              className={
                window.innerWidth < 820 ? "flex flex-col items-center " : ""
              }
            >
              <td class="border  px-4 py-2  font-medium text-center">
                <h3 className="font-bold md:hidden">Date</h3>

                <a
                  href={
                    BASE_URL_WITH_LOCAL +
                    "/storage" +
                    mailStore.contract?.file?.split("public")[1]
                  }
                  target="_blank"
                >
                  {" "}
                  <BasicButton
                    disabled={!mailStore.contract?.file}
                    title={"View"}
                    classes={
                      "my-bg-yellow-500 text-black hover:text-black  hover:bg-yellow-500"
                    }
                    handleClick={() => null}
                  />
                </a>
              </td>

              <td class="border  px-4 py-2  font-medium text-center">
                <h3 className="font-bold md:hidden">Action</h3>

                <select
                  value={mailStore.selectedApplicantStatus}
                  onChange={(e) =>
                    mailStore.setSelectedApplicantStatus(e.target.value)
                  }
                  required
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer text-center"
                  style={{ width: "200px" }}
                >
                  <option value="" default disabled selected>
                    Select
                  </option>
                  <option value="approved">Approve</option>
                  <option value="rejected">Reject</option>
                </select>
              </td>

              <td class="border  px-4 py-2  font-medium text-center">
                <h3 className="font-bold md:hidden">Application</h3>
                <BasicButton
                  disabled={!mailStore.selectedApplicantStatus || processing}
                  title={processing ? "Processing..." : "Process"}
                  classes={"my-bg-yellow-500 text-black"}
                  handleClick={() =>
                    mailStore.selectedApplicantStatus == "rejected"
                      ? preProcessApplication()
                      : processApplication()
                  }
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
