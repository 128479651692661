import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import WebHeader from "./web/header";
import { isAdmin } from "../Utils/helpers";
import { isAuthUser, API_BASE } from "../Utils/helpers";
import Footer from "../layout/footer";
import axios from "axios";

export default function Contact() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const naviagte = useNavigate();

  return (
    <>
      {" "}
      <WebHeader />
      <div class=" px-6 ">
        <br />
        <div class="flex flex-col text-center md:text-left justify-evenly items-center">
          <h1 class="text-2xl text-gray-800 font-bold my-2">Contact Us</h1>
          <p class="">Please contact Litzapp at:</p>

          <div class="flex w-full items-center justify-center">
            <div>
              <br />
              <p class="w-8/12 mx-auto md:mx-0 text-gray-500">
                Remote Analytics Jobs Inc.
              </p>
              <p class="w-8/12 mx-auto md:mx-0 text-gray-500">
                126 Southwest 17th Road, Unit 509 Miami, Florida 33129.
              </p>
              <br />
              <p class="w-8/12 mx-auto md:mx-0 text-gray-500">
                <p>
                  <strong>Email Address:</strong>
                </p>
                <p>
                  {" "}
                  <a
                    href="mailto:customerservices@litzapp.com"
                    className="text-red-500"
                  >
                    customerservices@litzapp.com
                  </a>
                </p>
                <br />
                <p class="w-8/12 mx-auto md:mx-0 text-gray-500">
                  <strong>Telephone number:</strong>
                </p>
                <a href="tel:+16692915495"> 📱 +1 (305) 927-1096</a>
              </p>
              <br />
              {/* <p class="w-8/12 mx-auto md:mx-0 text-gray-500">
                Effective as of August 11, 2022
              </p> */}
            </div>
            {/* <p class="w-5/12 mx-auto md:mx-0 text-gray-500 mt-2">
              We have begun with the unlimited SMS API and will continue to add
              services to our APIs to bring affordability to the market.
            </p> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
