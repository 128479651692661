import RenderProfilePhotoForPhotoAlbum from "./photo-album-avatar";
import BasicButton from "../../../buttons/basic-button";
import { BASE_URL } from "../../../../Utils/helpers";
export default function RenderPhotoAlbum(props) {
  const { book, getFristPageOfBook } = props;
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <div
      className="w-full cursor-pointer"
      onClick={() => {
        if (book.group == "standard") {
          openInNewTab(`/web/reader/${book.uuid}`);
        }
      }}
    >
      <div className="w-full mb-2">
        <BasicButton
          classes={"my-black text-white px-8"}
          title={"View Item"}
          handleClick={() =>
            (window.location.href = `/web/${book.owneroptimized.uuid}`)
          }
        />
      </div>
      <div class="flex justify-center h-32 md:h-40 mt-5">
        <div
          className="relative flex justify-center h-32 md:h-40"
          style={{ width: "350px", margin: "calc(4vh - 30px) auto 0 auto" }}
        >
          {" "}
          <img
            className="h-24 w-24 my-1 border-4 border-white rounded-full bg-gray-300 z-0 md:w-36 md:h-36 absolute left-6 object-cover"
            onClick={() => {
              if (book.group == "standard") {
                {
                  /* document.location.href = `/web/reader/${book.uuid}`; */
                }
              }
            }}
            src={
              getFristPageOfBook(book).file
                ? process.env.PUBLIC_URL + getFristPageOfBook(book).file
                : BASE_URL +
                  "/storage" +
                  book.cover_photo_thumb.split("public")[1]
            }
          />
          <img
            className="h-24 w-24 my-1 border-4 border-white rounded-full bg-gray-300 z-10 md:w-36 md:h-36 absolute z-1 object-cover"
            onClick={() => {
              if (book.group == "standard") {
                {
                  /* document.location.href = `/web/reader/${book.uuid}`; */
                }
              }
            }}
            src={
              BASE_URL + "/storage" + book.cover_photo_thumb.split("public")[1]
            }
          />
          <RenderProfilePhotoForPhotoAlbum book={book} />
        </div>
      </div>
      <div className="w-full flex flex-col items-center">
        {" "}
        <div className="flex  flex-col justify-center cursor-pointer">
          <div className="flex justify-center gap-2">
            <div className="flex"> By:</div>
            <div className="flex">{book.owneroptimized.name}</div>
          </div>
        </div>
        <p>
          {" "}
          <span className="text-yellow-600 text-xs font-bold">
            {book.owneroptimized.username}
          </span>
        </p>
        <div>
          <h1 className="font-bold text-xl text-center">{book.name}</h1>
        </div>
      </div>
    </div>
  );
}
