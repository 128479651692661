import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import TextArea from "../../../components/inputs/text-area";
import { API_BASE, isAdmin } from "../../../Utils/helpers";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Countries from "../../../components/country/country";
import BookCoverUpload from "../../publisher/rich-page/components/book-cover-upload";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";

export default function AdminSystemEditSubscriber() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [email, setEmail] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [subscription, setSubscription] = useState({});

  const { id } = useParams();
  const update = () => {
    setIsAuthenticating(true);
    const url = API_BASE + "/admin/system/subscription/update";
    let formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("id", id);

    authRequestService()
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          toast("Updated", { type: "success" });
          setIsAuthenticating(false);
        }
      })
      .catch((error) => {
        setIsAuthenticating(false);
        if (error.response.status == 422) {
          if ("duplicate_title" in error.response.data) {
            toast("Sorry, the provided title already exists!", {
              type: "error",
            });
            return false;
          }
        } else {
          toast("Something went wrong!", { type: "error" });
        }
      });
  };

  const fetchSubscription = () => {
    setIsAuthenticating(true);
    const url = API_BASE + "/admin/system/subscription/single/" + id;

    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setIsAuthenticating(false);
          setSubscription(response.data.subscription);
        }
      })
      .catch((error) => {
        setIsAuthenticating(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const handleTitleChange = (e) => {
    setTitle(e);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e);
  };

  const handlePriceChange = (e) => {
    setPrice(e);
  };

  const handleRegistration = () => {
    update();
  };

  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
    fetchSubscription();
  }, []);

  useEffect(() => {
    if (subscription.id) {
      setTitle(subscription.title);
      setDescription(subscription.description);
      setPrice(subscription.lateststripeprice.unit_amount);
      setEmail(subscription.user.email);
    }
  }, [subscription]);

  return (
    <>
      <div className="w-full flex flex-col items-center justify-center">
        <div className="bg-white m-2 p-2 flex justify-between shadow w-full">
          <div>
            <h1 className="text-2xl text-center m-2 font-bold">
              Update Subscriber
            </h1>
          </div>
          <div className="flex gap-2">
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>{" "}
        <div className="my-4 text-center flex flex-col gap-4 justify-between w-full md:w-10/12">
          <div class="container px-6 mx-auto ">
            <br />
            <div class="flex flex-col text-center md:text-left justify-evenly items-center">
              <div class="flex flex-col w-full items-center">
                <h1 class="text-2xl text-gray-800 font-bold my-2">Update</h1>
                <p class="mx-auto md:mx-0 text-gray-500">
                  Please fill out the form
                </p>
              </div>
              <div className={`w-full md:w-6/12 mx-auto md:mx-0`}>
                <div class="bg-white p-10 flex flex-col w-full shadow-xl rounded-xl">
                  {/* <h2 class="text-2xl font-bold text-gray-800 text-left mb-5">
                Sigin
              </h2> */}
                  <form action="" class="w-full">
                    <div id="input" class="flex flex-col w-full my-5">
                      <label for="username" class="text-gray-500 mb-2">
                        Title
                      </label>
                      <input
                        onChange={(e) => handleTitleChange(e.target.value)}
                        type="text"
                        id="title"
                        value={title}
                        placeholder="Please insert title here"
                        class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                      />
                    </div>
                    <div id="input" class="flex flex-col w-full my-5">
                      <label for="username" class="text-gray-500 mb-2">
                        Email
                      </label>
                      <input
                        disabled
                        type="text"
                        id="title"
                        value={email}
                        placeholder="Please insert title here"
                        class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                      />
                    </div>
                    <div id="input" class="flex flex-col w-full my-5">
                      <label for="description" class="text-gray-500 mb-2">
                        Description
                      </label>
                      <textarea
                        onChange={(e) =>
                          handleDescriptionChange(e.target.value)
                        }
                        type="text"
                        id="price"
                        value={description}
                        rows={"3"}
                        placeholder="Please insert description here"
                        class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                      />
                    </div>
                    <div id="input" class="flex flex-col w-full my-5">
                      <label for="username" class="text-gray-500 mb-2">
                        Price
                      </label>
                      <input
                        onChange={(e) => handlePriceChange(e.target.value)}
                        type="number"
                        id="username"
                        value={price}
                        placeholder="Please insert price here"
                        class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                      />
                    </div>

                    <div id="button" class="flex flex-col w-full my-5">
                      <button
                        disabled={
                          isAuthenticating || !title || !description | !price
                        }
                        onClick={() => handleRegistration()}
                        type="button"
                        class={`w-full py-4 ${
                          isAuthenticating || !title || !description | !price
                            ? "bg-gray-500"
                            : "bg-black"
                        }  rounded-lg text-green-100`}
                      >
                        {!isAuthenticating ? (
                          <div class="flex flex-row items-center justify-center">
                            <div class="mr-2">
                              <svg
                                class="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                                ></path>
                              </svg>
                            </div>
                            <div class="font-bold">Update</div>
                          </div>
                        ) : (
                          "Updating..."
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
