import { BASE_URL } from "../../../Utils/helpers";
import BasicButton from "../../../components/buttons/basic-button";

import { useState } from "react";

export default function SponsorMobileSystemSubTable({ data, f }) {
  const { openCancelModal } = f;
  return (
    <>
      <div className="w-full flex flex-col justify-center my-4 text-center">
        {data.data
          ? data.data.map((p, i) => {
              return (
                <div
                  className="overflow-x-scroll inline"
                  style={{
                    width: document.documentElement.clientWidth - 90 + "px",
                    margin: "0 auto",
                  }}
                >
                  <div className="flex">
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">
                        Description
                      </h3>
                      {p.description}
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">Price</h3>
                      {p.lateststripeprice.unit_amount}
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">Start</h3>
                      {p.stripesubscription?.refactoredStartTime || "N/A"}
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">Ends</h3>
                      {p.stripesubscription?.refactoredEndTime || "N/A"}
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs"></h3>
                      <BasicButton
                        disabled={p?.stripesubscription?.status !== "active"}
                        title={"Cancel"}
                        classes={"bg-red-500 text-white"}
                        handleClick={() =>
                          openCancelModal(p.stripesubscription)
                        }
                      />
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">Status</h3>
                      {p.stripesubscription?.status}
                    </div>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    </>
  );
}
