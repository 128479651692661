import TextField from "../../components/inputs/text-input";
import BasicButton from "../../components/buttons/basic-button";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import authRequestService from "../../services/HttpRequests/authenticated-request-services";
import {
  isAdmin,
  isPublisher,
  isAuthUser,
  isResponsiveMode,
  isSupplier,
  authUserData,
  API_BASE,
} from "../../Utils/helpers";
import { Tooltip } from "react-tooltip";

import axios from "axios";
export default function CreateOptions() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [currTooltip, setCurrentTooltip] = useState(0);
  const [publications, setPublications] = useState([]);
  const [user, setUser] = useState({});
  const [processing, setProcessing] = useState(false);

  //let { userId } = useParams();

  const fetchUser = () => {
    const url = API_BASE + "/publisher/" + authUserData().uuid + "/optimized";
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setUser(response.data.publisher);
          let user = response.data.publisher;
          if (user.roles && user.roles.length > 0) {
            let localUser = JSON.parse(localStorage.getItem("user"));
            localUser.roles = user.roles;
            localStorage.setItem("user", JSON.stringify(localUser));
            if (isSupplier()) {
              localStorage.removeItem("resume_supplier_application");
            }
          }
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  const checkCanCreateProduct = () => {
    if (user.supplier_status == "can_create") {
      window.location.href = `/publisher/rich-product-page/create/${userId}`;
    } else {
      window.location.href = `/publisher/supplier/onboarding/start`;
    }
  };

  useEffect(() => {
    if (!isPublisher()) {
      window.location.href = "/";
    }
    fetchUser();
  }, []);

  const userId = JSON.parse(isAuthUser()).uuid;
  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 bold">Create</h1>
        </div>
        <div className="flex gap-2 items-start">
          <BasicButton
            title={"Tutorials"}
            handleClick={() =>
              (window.location.href = "https://litzapp.com/share/sh/nWBVlkmtwF")
            }
            classes={"my-bg-yellow-500 text-black"}
          />
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      {processing ? (
        <div className="bg-white m-2 p-2 flex flex-col items-center justify-center h-screen text-center">
          <p className="text-2xl"> Loading Options..</p>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center gap-4 mt-8">
          <div className="w-full p-2">
            <div className="flex flex-col gap-4 relative">
              {user?.supplier_account_enabled && (
                <div className="text-center flex justify-center gap-2">
                  <BasicButton
                    classes={
                      "my-bg-yellow-500 text-black hover:bg-black-500 w-full md:w-4/12 py-6"
                    }
                    title={"Create Property/Product Page"}
                    handleClick={() => {
                      checkCanCreateProduct();
                    }}
                  />

                  <div className="self-center">
                    <i
                      class="fas fa-question-circle bg-white text-black cursor-pointer"
                      onClick={() => setCurrentTooltip(5)}
                      onMouseOver={() => setCurrentTooltip(5)}
                      onMouseLeave={() => setCurrentTooltip(0)}
                    ></i>

                    <div
                      className={`absolute ${
                        isResponsiveMode()
                          ? "m-auto left-0 right-0 w-10/12"
                          : ""
                      } md:w-3/12 absolute bg-white md:bg-black text-black md:text-white rounded-lg overflow-auto md:h-fit text-sm shadow px-1 z-50 py-4 ${
                        currTooltip == 9
                          ? "flex flex-col items-center"
                          : "hidden"
                      }`}
                      style={{
                        right: !isResponsiveMode() ? "50px" : "0px",
                        top: "10px",
                      }}
                    >
                      <div className="h-48 md:h-fit overflow-auto p-2 text-left"></div>
                      <BasicButton
                        handleClick={() => setCurrentTooltip(0)}
                        classes={"bg-gray-500 w-5/12 md:hidden"}
                        title={"Close"}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="text-center flex justify-center gap-2">
                <BasicButton
                  classes={
                    "bg-black-500 hover:bg-black-500 w-full md:w-4/12 py-6"
                  }
                  title={"Create Content Page"}
                  handleClick={() =>
                    (window.location.href = `/publisher/rich-page/create/${userId}`)
                  }
                />

                <div className="self-center">
                  <i
                    class="fas fa-question-circle my-text-yellow-500 cursor-pointer"
                    onClick={() => setCurrentTooltip(4)}
                    onMouseOver={() => setCurrentTooltip(4)}
                    onMouseLeave={() => setCurrentTooltip(0)}
                  ></i>

                  <div
                    className={`absolute ${
                      isResponsiveMode() ? "m-auto left-0 right-0 w-10/12" : ""
                    } md:w-3/12 absolute bg-white md:bg-black text-black md:text-white rounded-lg overflow-auto md:h-fit text-sm shadow px-1 z-50 py-4 ${
                      currTooltip == 4 ? "flex flex-col items-center" : "hidden"
                    }`}
                    style={{
                      right: !isResponsiveMode() ? "50px" : "0px",
                      top: "10px",
                    }}
                  >
                    <div className="h-48 md:h-fit overflow-auto p-2 text-left">
                      <p>
                        ⦾ This lets you construct a versatile page allowing
                        text, images, image links, videos, and button links.
                      </p>
                      <p className="my-1">
                        ⦾ Incorporate ads from our ad network on any part of the
                        page.
                      </p>
                      <p>
                        ⦾ Secure specific portions (paragraphs, lines, or
                        sentences) of your content behind a blurred paywall,
                        making the rest of the page freely accessible to
                        visitors.
                      </p>
                      <p className="my-1">
                        ⦾ Useful for articles, long-form updates, journals,
                        property/job listings, poems, and more.
                      </p>
                      <p>
                        ⦾ Authors can release and monetize books chapter by
                        chapter, similar to a TV show.
                      </p>
                      <p>
                        ⦾ Re-monetize popular videos, news articles, and
                        trending topics.
                      </p>
                    </div>
                    <BasicButton
                      handleClick={() => setCurrentTooltip(0)}
                      classes={"bg-gray-500 w-5/12 md:hidden"}
                      title={"Close"}
                    />
                  </div>
                </div>
              </div>{" "}
              <div className="text-center flex justify-center gap-2">
                <BasicButton
                  classes={
                    "bg-black-500 hover:bg-black-500 w-full md:w-4/12 py-6 my-text-yellow-500"
                  }
                  title={"Create Content Slide"}
                  handleClick={() =>
                    (window.location.href = `/publisher/create/options/photo-album/${userId}`)
                  }
                />
                <div className="self-center">
                  <i
                    class="fas fa-question-circle my-text-yellow-500 cursor-pointer"
                    onClick={() => setCurrentTooltip(1)}
                    onMouseOver={() => setCurrentTooltip(1)}
                    onMouseLeave={() => setCurrentTooltip(0)}
                  ></i>
                  <div
                    className={`absolute ${
                      isResponsiveMode() ? "m-auto left-0 right-0 w-10/12" : ""
                    } md:w-3/12 absolute bg-white md:bg-black text-black md:text-white rounded-lg overflow-auto md:h-fit text-sm shadow px-1 z-50 py-4 ${
                      currTooltip == 1 ? "flex flex-col items-center" : "hidden"
                    }`}
                    style={{
                      right: !isResponsiveMode() ? "50px" : "0px",
                      top: "10px",
                    }}
                  >
                    <div className="h-48 md:h-fit overflow-auto p-2 ">
                      Use this feature to build an interactive photo slideshow.
                      Monetize by inserting ads from our ad networks between
                      slides, implementing a paywall at any point (requiring
                      viewers to subscribe to your account for access to
                      subsequent slides), and even including a slide to solicit
                      donations. Only image files are allowed. Great for
                      monetizing photos of weddings, vacations, fun events, news
                      events, and more.
                    </div>
                    <BasicButton
                      handleClick={() => setCurrentTooltip(0)}
                      classes={"bg-gray-500 w-5/12 md:hidden"}
                      title={"Close"}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="text-center flex justify-center gap-2">
                <BasicButton
                  handleClick={() =>
                    (window.location.href = `/publisher/create/options/book/${userId}`)
                  }
                  classes={
                    "bg-black-500 hover:bg-black-500 w-full md:w-4/12 py-6"
                  }
                  title={"Create Book Slide"}
                />

                <div className="self-center">
                  <i
                    class="fas fa-question-circle my-text-yellow-500 cursor-pointer"
                    onClick={() => setCurrentTooltip(2)}
                    onMouseOver={() => setCurrentTooltip(2)}
                    onMouseLeave={() => setCurrentTooltip(0)}
                  ></i>

                  <div
                    className={`absolute ${
                      isResponsiveMode() ? "m-auto left-0 right-0 w-10/12" : ""
                    } md:w-3/12 absolute bg-white md:bg-black text-black md:text-white rounded-lg overflow-auto md:h-fit text-sm shadow px-1 z-50 py-4 ${
                      currTooltip == 2 ? "flex flex-col items-center" : "hidden"
                    }`}
                    style={{
                      right: !isResponsiveMode() ? "50px" : "0px",
                      top: "10px",
                    }}
                  >
                    <div className="h-48 md:h-fit overflow-auto p-2">
                      Leverage this feature to construct a dynamic slideshow
                      that mimics the flow of a book. It allows you to upload
                      pages individually. Monetize by inserting ads from our ad
                      networks between slides, implementing a paywall at any
                      point (requiring viewers to subscribe to your account for
                      access to subsequent slides), and even including a slide
                      to solicit donations. Image and PDF files are allowed.
                    </div>
                    <BasicButton
                      handleClick={() => setCurrentTooltip(0)}
                      classes={"bg-gray-500 w-5/12 md:hidden"}
                      title={"Close"}
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="text-center flex justify-center gap-2">
                <BasicButton
                  classes={
                    "bg-black-500 hover:bg-black-500 w-full md:w-4/12 py-6"
                  }
                  title={"Create Comic Slide"}
                  handleClick={() =>
                    (window.location.href = `/publisher/create/options/comic/${userId}`)
                  }
                />

                <div className="self-center">
                  {" "}
                  <i
                    class="fas fa-question-circle my-text-yellow-500 cursor-pointer"
                    onClick={() => setCurrentTooltip(3)}
                    onMouseOver={() => setCurrentTooltip(3)}
                    onMouseLeave={() => setCurrentTooltip(0)}
                  ></i>
                  <div
                    className={`absolute ${
                      isResponsiveMode() ? "m-auto left-0 right-0 w-10/12" : ""
                    } md:w-3/12 absolute bg-white md:bg-black text-black md:text-white rounded-lg overflow-auto md:h-fit text-sm shadow px-1 z-50 py-4 ${
                      currTooltip == 3 ? "flex flex-col items-center" : "hidden"
                    }`}
                    style={{
                      right: !isResponsiveMode() ? "50px" : "0px",
                      top: "10px",
                    }}
                  >
                    <div className="h-48 md:h-fit overflow-auto p-2 text-left">
                      Leverage this feature to construct a dynamic slideshow
                      that mimics the flow of a comic book. It allows you to
                      upload pages individually. Monetize by inserting ads from
                      our ad networks between slides, implementing a paywall at
                      any point (requiring viewers to subscribe to your account
                      for access to subsequent slides), and even including a
                      slide to solicit donations. Only image files are allowed.
                    </div>
                    <BasicButton
                      handleClick={() => setCurrentTooltip(0)}
                      classes={"bg-gray-500 w-5/12 md:hidden"}
                      title={"Close"}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
