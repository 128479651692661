import { IdentityOptions } from "./identity-options-list";
import { useState, useEffect } from "react";
import authRequestService from "../../../../services/HttpRequests/authenticated-request-services";
import { API_BASE, authUserData } from "../../../../Utils/helpers";
import BasicButton from "../../../../components/buttons/basic-button";
import BizLinkAssistSlider from "./slider";
import { useRef } from "react";
export default function SupplierBizLinkVerification(props) {
  const {
    stage,
    regType,
    bizLink,
    handlePrevStage,
    handleNextStage,
    handleBizLinkChange,
  } = props;

  const inputRef = useRef(null);
  const [savingStage, setSavingStage] = useState(false);
  const [showSlider, setShowSlider] = useState(false);

  const saveStage = () => {
    const url = API_BASE + "/publisher/supplier/stages/save";
    setSavingStage(true);
    authRequestService()
      .post(url, {
        biz_link: bizLink,
        stage: stage,
        type: regType,
        uuid: authUserData().uuid,
      })
      .then((res) => {
        handleNextStage();
      })
      .catch((err) => setSavingStage(false));
  };

  const openSliderModal = () => {
    setShowSlider(true);
  };

  const hideSliderModal = () => {
    setShowSlider(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className="my-identity dropdown">
        <div id="input" class="flex flex-col w-full  my-5">
          <label for="password" class="text-gray-500 mb-2">
            Share Your Business Link from Google Maps or Bing Maps
          </label>
          <input
            value={bizLink}
            onChange={(e) => handleBizLinkChange(e.target.value)}
            type="text"
            id="identit-link"
            placeholder={`Paste your Business Link here`}
            class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
          />
          <small>
            Kindly avoid using shortened link formats. To ensure clarity about
            the destination, please provide the full link. For assistance in
            locating your business profile link on Google or Bing,{" "}
            <span
              className="text-blue-500 cursor-pointer"
              onClick={() => openSliderModal()}
            >
              click Here
            </span>
            .
          </small>
        </div>
        <div className="w-full border flex flex justify-center items-center gap-1 my-1">
          <BasicButton
            handleClick={() => handlePrevStage()}
            title={"Back"}
            disabled={savingStage}
            classes={"bg-black w-full"}
          />
          <BasicButton
            handleClick={() => saveStage()}
            title={savingStage ? "Processing.." : "Next"}
            disabled={savingStage || !bizLink}
            classes={"bg-black w-full"}
          />
        </div>
      </div>
      <BizLinkAssistSlider
        modalOpen={showSlider}
        closeModal={hideSliderModal}
      />
    </>
  );
}
