import { useSendMailStore } from "../../../../../../stores/send-mail-store";
import { isResponsiveMode } from "../../../../../../Utils/helpers";
import Checkbox from "../../../../../../components/inputs/checkbox";
import BasicButton from "../../../../../../components/buttons/basic-button";

export default function DesktopTable(props) {
  const mailStore = useSendMailStore((state) => state);

  const { processing, preSubCancelPromt } = props;

  function capitalizeFirstLetter(str) {
    return str[0].toUpperCase() + str.slice(1);
  }

  return (
    <div className="overflow-x-scroll md:h-screen w-full">
      <table class="table-auto w-full">
        <thead>
          <tr className={window.innerWidth < 720 ? "hidden" : ""}>
            <th class="px-4 py-2 text-gray-600">Name</th>
            <th class="px-4 py-2 text-gray-600">Country</th>
            <th class="px-4 py-2 text-gray-600">Purchase Option</th>
            <th class="px-4 py-2 text-gray-600">Price</th>
            <th class="px-4 py-2 text-gray-600">Item Title</th>
            <th class="px-4 py-2 text-gray-600">Item ID</th>
            <th class="px-4 py-2 text-gray-600">Subscription Cycle</th>
            <th class="px-4 py-2 text-gray-600">Transaction ID</th>
            <th class="px-4 py-2">Status</th>
            <th class="px-4 py-2">Purchase Date</th>
            <th class="px-4 py-2">Duration</th>
            <th class="px-4 py-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {mailStore.data.data
            ? mailStore.data.data.length
              ? mailStore.data.data.map((subscription) => {
                  return (
                    <tr
                      className={
                        window.innerWidth < 820
                          ? "flex flex-col items-center"
                          : ""
                      }
                    >
                      <td class="border border-gray px-4 py-2  font-medium">
                        <div className="flex flex-col justify-center items-center">
                          <h3 className="font-bold md:hidden">Name</h3>
                          {subscription.sellerdata?.name}
                        </div>
                      </td>

                      <td class="border border-gray px-4 py-2  font-medium">
                        <div className="flex flex-col justify-center items-center">
                          <h3 className="font-bold md:hidden">Country</h3>
                          {subscription.sellerdata?.country}
                        </div>
                      </td>

                      <td class="border border-gray px-4 py-2  font-medium">
                        <div className="flex flex-col justify-center items-center">
                          <h3 className="font-bold md:hidden">
                            Purchase Option
                          </h3>
                          {subscription.metadata.purchase_option_name}
                        </div>
                      </td>
                      <td class="border border-gray px-4 py-2  font-medium">
                        <div className="flex flex-col justify-center items-center">
                          <h3 className="font-bold md:hidden">Price</h3>$
                          {subscription.unit_amount}
                        </div>
                      </td>

                      <td class="border border-gray px-4 py-2  font-medium">
                        <div className="flex flex-col justify-center items-center">
                          <h3 className="font-bold md:hidden">Item Title</h3>
                          {subscription.itemdata.item_name}
                        </div>
                      </td>
                      <td class="border border-gray px-4 py-2  font-medium">
                        <div className="flex flex-col justify-center items-center">
                          <h3 className="font-bold md:hidden">Item ID</h3>
                          {subscription.itemdata.item_id}
                        </div>
                      </td>
                      <td class="border border-gray px-4 py-2  font-medium">
                        <div className="flex flex-col justify-center items-center">
                          <h3 className="font-bold md:hidden">
                            Subscription Cycle
                          </h3>
                          {subscription.metadata.cycle}
                        </div>
                      </td>
                      <td class="border border-gray px-4 py-2  font-medium">
                        <div className="flex flex-col justify-center items-center">
                          <h3 className="font-bold md:hidden">
                            Transaction ID
                          </h3>
                          {subscription.transaction_id}
                        </div>
                      </td>

                      <td class="border border-gray px-4 py-2  font-medium">
                        <div className="flex flex-col justify-center items-center">
                          <h3 className="font-bold md:hidden">Status</h3>
                          {capitalizeFirstLetter(subscription.status)}
                        </div>
                      </td>
                      <td class="border border-gray px-4 py-2  font-medium">
                        <div className="flex justify-center">
                          {" "}
                          <h3 className="font-bold md:hidden">Date</h3>
                          {subscription.simplecreatedat
                            ? new Date(
                                subscription.simplecreatedat.replace(/-/g, "/")
                              ).getDate() +
                              "-" +
                              (new Date(
                                subscription.simplecreatedat.replace(/-/g, "/")
                              ).getMonth() +
                                1) +
                              "-" +
                              new Date(
                                subscription.simplecreatedat.replace(/-/g, "/")
                              ).getFullYear()
                            : "N/A"}
                        </div>
                      </td>
                      <td
                        class="border border-gray px-4 py-2 font-medium text-center"
                        style={{ width: "400px;" }}
                      >
                        <h3 className="font-bold md:hidden ">Duration</h3>
                        <div className="flex flex-col items-center gap-4 w-full">
                          {" "}
                          <p>
                            <b>Starts: </b>
                            {subscription.start_date
                              ? new Date(
                                  subscription.start_date.replace(/-/g, "/")
                                ).getDate() +
                                "-" +
                                (new Date(
                                  subscription.start_date.replace(/-/g, "/")
                                ).getMonth() +
                                  1) +
                                "-" +
                                new Date(
                                  subscription.start_date.replace(/-/g, "/")
                                ).getFullYear()
                              : "N/A"}
                          </p>
                          <p>
                            <b>Ends: </b>
                            {subscription.end_date
                              ? new Date(
                                  subscription.end_date.replace(/-/g, "/")
                                ).getDate() +
                                "-" +
                                (new Date(
                                  subscription.end_date.replace(/-/g, "/")
                                ).getMonth() +
                                  1) +
                                "-" +
                                new Date(
                                  subscription.end_date.replace(/-/g, "/")
                                ).getFullYear()
                              : "N/A"}
                          </p>
                        </div>
                      </td>
                      <td class="border border-gray px-4 py-2 font-medium text-center">
                        <h3 className="font-bold md:hidden ">Duration</h3>
                        <div className="flex flex-col items-center gap-4">
                          {!subscription.cancancelsubwithcap ? (
                            <BasicButton
                              disabled={
                                (subscription.status != "active" &&
                                  subscription.status !== "Pending") ||
                                processing
                              }
                              title={
                                processing
                                  ? "Cancelling.."
                                  : subscription.status == "active" ||
                                    subscription.status == "Pending"
                                  ? "Cancel Subscription"
                                  : "Cancelled"
                              }
                              handleClick={() =>
                                preSubCancelPromt(subscription)
                              }
                            />
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              : ""
            : ""}
        </tbody>
      </table>
    </div>
  );
}
