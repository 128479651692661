import { useSupplierApplicationsStore } from "../../../../../stores/supplier-applications-store";
import { isResponsiveMode } from "../../../../../Utils/helpers";
import Checkbox from "../../../../../components/inputs/checkbox";
import BasicButton from "../../../../../components/buttons/basic-button";
import { useState } from "react";
import AdminSupplierPhotoViewerModal from "./photo-viewer";
import { BASE_URL, BASE_URL_WITH_LOCAL } from "../../../../../Utils/helpers";

export default function DesktopAdminApplicantTable(props) {
  const {
    openCommentModal,
    preProcessApplication,
    processApplication,
    processing,
    downloadVideo,
  } = props;
  const mailStore = useSupplierApplicationsStore((state) => state);

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const openImageModal = (d) => {
    const link = BASE_URL + "/storage" + d.photo.split("public")[1];
    setImageUrl(link);
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
  };

  const appicationStatus = {
    redo_visual_verification: "Redo Visual Verification",
    redo_business_verification: "Redo Business Verification",
    redo_contract_verification: "Redo Contract Verification",
    redo_supplier_verification: "Redo Supplier Verification",
    approved: "Application Approved",
    rejected: "Application Rejected",
  };

  const contractTitles = {
    "subscription-contract": "Subscription Contract (Recurring Purchase)",
    "hire-contract": "Hire Contract (Recurring Purchase)",
    "rent-contract": "Rent Contract (Recurring Purchase)",
    "lease-contract": "Lease Contract (Recurring Purchase)",
    "vacation-rental-contract": "Vacation Rental Contract (Recurring Purchase)",
    "sp-contract": "Default Contract (Single Purchase)",
  };

  return (
    <>
      <div className="overflow-x-scroll md:h-screen w-full">
        <table class="table-auto w-full my-4">
          <thead>
            <tr className={window.innerWidth < 720 ? "hidden" : ""}>
              <th class="px-4 py-2 text-gray-600">Date Created</th>
              <th class="px-4 py-2 text-gray-600">Name</th>
              <th class="px-4 py-2 text-gray-600">Username</th>
              <th class="px-4 py-2 text-gray-600">User Handle</th>
              <th class="px-4 py-2 text-gray-600">Supplier Type</th>
              {mailStore.application?.type == "individual" ? (
                <th class="px-4 py-2 text-gray-600">Identity Option</th>
              ) : (
                <th class="px-4 py-2 text-gray-600">Business Name</th>
              )}
              {mailStore.application?.type == "individual" ? (
                <th class="px-4 py-2 text-gray-600">Profile Link</th>
              ) : (
                <th class="px-4 py-2 text-gray-600">Business Link</th>
              )}

              <th class="px-4 py-2 text-gray-600">Business Location</th>
              <th class="px-4 py-2 text-gray-600">Face Photo</th>
              <th class="px-4 py-2 text-gray-600">Video Verification Code</th>
              <th class="px-4 py-2 text-gray-600">Video</th>
              <th class="px-4 py-2 text-gray-600">Contract</th>
              <th class="px-4 py-2 text-gray-600">Action</th>
              <th class="px-4 py-2 text-gray-600">Process</th>
            </tr>
          </thead>

          <tbody className="w-full">
            {mailStore.application?.id && (
              <tr
                className={
                  window.innerWidth < 820 ? "flex flex-col items-center " : ""
                }
              >
                <td class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Date</h3>
                  <p>
                    {mailStore.application.refactoredCreatedAtTime
                      ? new Date(
                          mailStore.application.refactoredCreatedAtTime.replace(
                            /-/g,
                            "/"
                          )
                        ).getDate() +
                        "-" +
                        (new Date(
                          mailStore.application.refactoredCreatedAtTime.replace(
                            /-/g,
                            "/"
                          )
                        ).getMonth() +
                          1) +
                        "-" +
                        new Date(
                          mailStore.application.refactoredCreatedAtTime.replace(
                            /-/g,
                            "/"
                          )
                        ).getFullYear()
                      : "N/A"}
                  </p>
                </td>

                <td class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Name</h3>
                  {mailStore.application.user.name}
                </td>
                <td class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Username</h3>
                  {mailStore.application.user.username}
                </td>
                <td class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">User Handle</h3>
                  {mailStore.application.user.handle_id}
                </td>
                <td class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Supplier Type</h3>
                  {mailStore?.application?.type || "Individual"}
                </td>

                <td class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Email</h3>
                  {mailStore.application?.type == "individual"
                    ? mailStore.application?.profile_identity
                    : mailStore.application?.business_name}
                </td>

                <td class="border  px-4 py-2  font-medium wrap-words wrap-all">
                  <h3 className="font-bold md:hidden">Email</h3>
                  {mailStore.application?.type == "individual"
                    ? mailStore.application?.profile_identity_link
                    : mailStore.application?.business_link}
                </td>

                <td class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Business Location</h3>
                  {mailStore?.application?.address || "Individual"}
                </td>

                <td class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Face Photo</h3>
                  <BasicButton
                    title={"View"}
                    classes={"my-bg-yellow-500 text-black"}
                    handleClick={() => openImageModal(mailStore?.application)}
                  />
                </td>
                <td class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Verification Code</h3>
                  {
                    mailStore?.application?.user
                      ?.latest_supplier_verification_code?.code
                  }
                </td>
                <td class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Video</h3>
                  <BasicButton
                    title={"View"}
                    classes={"my-bg-yellow-500 text-black"}
                    handleClick={() =>
                      downloadVideo(
                        mailStore?.application.video,
                        mailStore.application.user.handle_id
                      )
                    }
                  />
                </td>
                <td class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Contract</h3>
                  {!mailStore.application?.contract_type ||
                  mailStore.application?.contract_type == "upload" ? (
                    <p>
                      {!mailStore?.application?.user?.product_supplier_contract
                        ?.file ? (
                        contractTitles["vacation-rental-contract"]
                      ) : (
                        <a
                          href={
                            BASE_URL_WITH_LOCAL +
                            "/storage" +
                            mailStore?.application?.user?.product_supplier_contract?.file?.split(
                              "public"
                            )[1]
                          }
                          target="_blank"
                        >
                          {" "}
                          <BasicButton
                            disabled={!mailStore.application?.user?.uuid}
                            title={"View"}
                            classes={"my-bg-yellow-500 text-black"}
                            handleClick={() => null}
                          />
                        </a>
                      )}
                    </p>
                  ) : (
                    contractTitles[mailStore.application?.contract_type]
                  )}
                </td>
                <td class="border  px-4 py-2 font-medium">
                  <h3 className="font-bold md:hidden">Contract</h3>
                  <div
                    className="flex flex-col items-center"
                    style={{ width: "200px" }}
                  >
                    {mailStore.application.status == "unprocessed" ? (
                      <select
                        value={mailStore.selectedApplicantStatus}
                        onChange={(e) =>
                          mailStore.setSelectedApplicantStatus(e.target.value)
                        }
                        required
                        class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer text-center"
                        style={{ width: "200px" }}
                      >
                        <option value="" default disabled selected>
                          Unprocessed
                        </option>
                        <option value="redo_business_verification">
                          Redo Business Verification
                        </option>
                        <option value="redo_visual_verification">
                          Redo Visual Verification
                        </option>
                        {/* <option value="redo_contract_verification">
                          Redo Contract Verification
                        </option> */}
                        <option value="redo_supplier_verification">
                          Redo Supplier Application
                        </option>
                        <option value="approved">Application Approved</option>
                        <option value="rejected">Application Rejcted</option>
                      </select>
                    ) : (
                      appicationStatus[mailStore?.application?.status]
                    )}
                  </div>
                </td>
                <td class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Process</h3>

                  {mailStore?.application?.status == "unprocessed" && (
                    <BasicButton
                      disabled={
                        !mailStore.application?.user?.uuid || processing
                      }
                      title={processing ? "Processing..." : "Process"}
                      classes={"my-bg-yellow-500 text-black"}
                      handleClick={() =>
                        mailStore.selectedApplicantStatus !== "unprocessed" &&
                        mailStore.selectedApplicantStatus !== "approved" &&
                        mailStore.selectedApplicantStatus !== "rejected"
                          ? preProcessApplication()
                          : processApplication()
                      }
                    />
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <AdminSupplierPhotoViewerModal
        modalOpen={imageModalOpen}
        closeModal={closeImageModal}
        imageUrl={imageUrl}
      />
    </>
  );
}
