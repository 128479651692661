import { useSendMailStore } from "../../../../../../stores/send-mail-store";
import { isResponsiveMode } from "../../../../../../Utils/helpers";
import Checkbox from "../../../../../../components/inputs/checkbox";

export default function DesktopTable(props) {
  const mailStore = useSendMailStore((state) => state);

  return (
    <div className="overflow-x-scroll md:h-screen w-full">
      <table class="table-auto w-full my-4">
        <thead>
          <tr className={window.innerWidth < 720 ? "hidden" : ""}>
            <th class="px-4 py-2 text-gray-600">Name</th>
            {/* <th class="px-4 py-2 text-gray-600">Country</th> */}
            <th class="px-4 py-2 text-gray-600">Purchase Option</th>
            <th class="px-4 py-2 text-gray-600">Item Title</th>
            <th class="px-4 py-2 text-gray-600">Item ID</th>
            <th class="px-4 py-2 text-gray-600">Price</th>
            <th class="px-4 py-2 text-gray-600">Quantity</th>
            <th class="px-4 py-2 text-gray-600">Transaction ID</th>
            <th class="px-4 py-2 text-gray-600">Date</th>
          </tr>
        </thead>
        <tbody className="w-full">
          {mailStore.data.data &&
            mailStore.data.data.map((purchase) => {
              return (
                <tr
                  className={
                    window.innerWidth < 820 ? "flex flex-col items-center " : ""
                  }
                >
                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Supplier</h3>
                    {purchase?.sellerdata?.name}
                  </td>
                  {/* <td class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Country</h3>
                  {purchase?.sellerdata?.country}
                </td> */}
                  <td class="border border-gray px-4 py-2  font-medium">
                    <div className="flex flex-col justify-center items-center">
                      <h3 className="font-bold md:hidden">Purchase Option</h3>
                      {purchase.metadata.purchase_option_name}
                    </div>
                  </td>
                  <td class="border px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Item Title</h3>
                    <p className="my-text-yellow-500">
                      {" "}
                      {purchase.book.group == "standard"
                        ? "File"
                        : "Custom Page"}
                    </p>

                    <p>
                      <a
                        target="_blank"
                        className="underline"
                        href={
                          purchase.book.group == "standard"
                            ? `/publisher/${purchase.book.owneruuid}/publications/series/m/book/${purchase.book.book_id}`
                            : `/web/rich-page/render/${purchase.book.book_uuid}`
                        }
                      >
                        {purchase.book.name}
                      </a>
                    </p>
                  </td>

                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Item ID</h3>
                    {purchase.book.item_id}
                  </td>
                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Price</h3>$
                    {purchase.price / 100}
                  </td>
                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Quauntity</h3>
                    {purchase.quantity}
                  </td>
                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Transaction ID</h3>
                    {purchase.transaction_id}
                  </td>

                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Date</h3>
                    <p>
                      {purchase.refactoredCreatedAtTime
                        ? new Date(
                            purchase.refactoredCreatedAtTime.replace(/-/g, "/")
                          ).getDate() +
                          "-" +
                          (new Date(
                            purchase.refactoredCreatedAtTime.replace(/-/g, "/")
                          ).getMonth() +
                            1) +
                          "-" +
                          new Date(
                            purchase.refactoredCreatedAtTime.replace(/-/g, "/")
                          ).getFullYear()
                        : "N/A"}
                    </p>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
