import Checkbox from "../../../../../../../components/inputs/checkbox";
import EditorTooltip from "../../tooltip/editor-tooltip";
export default function BlockedWeekendStart(props) {
  const { productMeta, handleMetaChange } = props;

  return (
    <div className="flex gap-2 w-full md:w-6/12 justify-center my-5 relative">
      <div>
        <Checkbox
          handleChange={(e) =>
            handleMetaChange(e, "block_weekend_start", "checkbox")
          }
          checked={productMeta.block_weekend_start}
        />
      </div>
      <span className="font-bold">Block Weekend Start</span>
      <EditorTooltip
        body={
          "Block Weekend Start means no subscription starts on weekends, but other subscriptions can overlap with weekends if they start on a weekday."
        }
        top={"-210px"}
        right={"-47px"}
      />
    </div>
  );
}
