import { useEffect } from "react";
export default function TextWholeNumberField({
  placeholder,
  handleChange,
  classes,
  value,
  key,
  type,
  disabled,
}) {
  const validateEntredVal = (evt) => {
    let charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
  };

  return (
    <input
      min="0"
      type="number"
      onKeyDown={validateEntredVal}
      readonly
      className={` form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none ${classes}`}
      id="exampleFormControlInput1"
      placeholder={placeholder}
      value={value}
      onChange={(e) => handleChange && handleChange(e.target.value)}
      disabled={disabled ? disabled : false}
    />
  );
}
