import TextField from "../../../../../inputs/text-input";
export default function BasiSearchBar(props) {
  const { clearSearchText } = props;
  const { handleSearchTextChange } = props;
  const { searchText } = props;
  return (
    <div className="w-full md:col-span-2 relative text-center">
      <i
        class="fa fa-times-circle text-black  z-50 z-50 text-2xl absolute right-1 -top-0 z-40 cursor-pointer"
        onClick={() => clearSearchText()}
      ></i>
      <TextField
        title="Search"
        placeholder={"Search (ID, title, or multi-tags)"}
        handleChange={handleSearchTextChange}
        classes={"landing-page-search"}
        value={searchText}
      />
      <small className="w-full text-center my-xsm">
        Multi-tag search allowed. Include $ before each tag (e.g., Red $school
        work $remote services $fun etc.)
      </small>
      {searchText.includes("$") && (
        <p>
          {" "}
          <small className="w-full text-center my-xsm text-blue-500">
            Use & to get a result(s) that include all searched tags (e.g., & Red
            $school work $remote services $fun etc.)
          </small>
        </p>
      )}
    </div>
  );
}
