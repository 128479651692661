import TextField from "../components/inputs/text-input";
import BasicButton from "../components/buttons/basic-button";
import TermsAccordion from "../accordions/terms-accordion";
import { useBookStore } from "../stores/book-store";
import { forwardRef, useState, useEffect } from "react";
import StripeForm from "../payment/pay-button";
import Register from "../pages/register";

export default function StripeRedirectModal({ modalOpen, hideModal, link }) {
  const [timer, setTimer] = useState(10);
  useEffect(() => {
    setInterval(() => {
      setTimer((prev) => prev--);
    }, 5000);
  }, [modalOpen]);

  useEffect(() => {
    setInterval(() => {
      setTimer((prev) => prev--);
    }, 5000);
  }, []);

  const redirectToStripe = () => {
    link && window.location.replace(link);
  };

  return (
    <div
      className={`fixed top-1/2 -translate-y-1/2 w-full left-0 z-999999 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-10/12 h-4/12"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex justify-center">
            {/* modal body */}
            <p className="w-8/12">
              You'll be redirected to Stripe{" "}
              <CountdownTimer redirectToStripe={redirectToStripe} />
              seconds to complete your account setup.
            </p>

            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 flex justify-center items-center">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function CountdownTimer({ redirectToStripe }) {
  const countDownDate = new Date().getTime();
  const [countDown, setCountDown] = useState(5);

  useEffect(() => {
    const intervalReal = setInterval(() => {
      setCountDown(countDown - 1);
    }, 1000);

    if (countDown == 0) {
      clearInterval(intervalReal);
      redirectToStripe();
    }

    return () => {
      clearInterval(intervalReal);
    };
  }, [countDownDate]);

  return <span>{countDown} </span>;
}
