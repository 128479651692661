import TextField from "../components/inputs/text-input";

import BasicButton from "../components/buttons/basic-button";
import { useBookStore } from "../stores/book-store";
import { isAuthUser } from "../Utils/helpers";
import { useState } from "react";
import Switch from "react-switch";

export default function PageSettingsModal({
  modalOpen,
  hideModal,
  book,
  action,
  handleValueChange,
  adTimerDelay,
  enableAdTimer,
  handleEnableTitleBarPurchaseChange,
  handleEnableTitleBarSubChange,
  titleBarPurchaseChange,
  titleBarSubChange,
  handleEnableAdTimerChange,
  handlePriceEditModeChange,
  priceEditMode,
  handlePriceChange,
  updatePrice,
  price,
}) {
  return (
    <div
      className={`absolute z-40 overflow-y-auto top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-6/12 h-4/12 "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <h1 className="text-2xl font-bold text-center">File Settings</h1>
            </div>

            <div className="w-full text-left relative py-2 flex flex-col items-start">
              <label className="text-black text-left">Price</label>
              <div
                className="bg-white rounded rounded-full bg-white p-0 top-3 right-0 absolute px-1 cursor-pointer shadow"
                onClick={() => handlePriceEditModeChange()}
              >
                <i class="fa fa-pen text-blue-500  z-50 text-xxl"></i>
              </div>
              {priceEditMode && price ? (
                <div
                  className="bg-white rounded rounded-full bg-white p-0 top-3 right-7 absolute px-1 cursor-pointer shadow"
                  onClick={() => updatePrice()}
                >
                  <i class="fa fa-check text-green-500  z-50 text-xxl"></i>
                </div>
              ) : (
                ""
              )}
              {priceEditMode ? (
                <TextField
                  classes={"w-full"}
                  placeholder={"Price.."}
                  handleChange={handlePriceChange}
                  value={price}
                />
              ) : (
                <p className="text-black border p-2 w-full text-left">
                  ${price}
                </p>
              )}
              {priceEditMode
                ? book.owner.system_setting.constant_fee_on_payments && price
                  ? `Service Charge $${Number(
                      book.owner.system_setting.constant_fee_on_payments
                    )} + ${price} = $${
                      Number(price) +
                      Number(book.owner.system_setting.constant_fee_on_payments)
                    }`
                  : ``
                : ""}
              {priceEditMode ? (
                <p className="text-xs bg-pink-50 rounded-lg p-1 my-2">
                  Tap or click the check icon to save changes.
                </p>
              ) : (
                ""
              )}
            </div>

            {book &&
              book.owner &&
              book.owner.system_setting.ads_controls_visibility && (
                <div className="flex flex-col justify-start bg-gray-50">
                  <div className="w-full p-2">
                    {/* <h1 className="text-lg  text-center">Ad Timer</h1> */}
                  </div>
                  <div className="m-2 mb-6 flex flex-col items-start">
                    <label className="text-black text-left">
                      Ad Timer Delay
                    </label>
                    <TextField
                      classes={"p-6 my-2"}
                      placeholder={"Value.."}
                      handleChange={handleValueChange}
                      value={adTimerDelay}
                    />
                    <br />
                    <label className="flex items-center gap-4">
                      <span>{enableAdTimer ? "Hide Timer" : "Show Timer"}</span>
                      <Switch
                        onChange={handleEnableAdTimerChange}
                        checked={enableAdTimer}
                      />
                    </label>
                  </div>
                </div>
              )}

            <div className="flex flex-col justify-start bg-gray-50">
              <div className="w-full p-2">
                {/* <h1 className="text-lg  text-center">Ad Timer</h1> */}
              </div>
              <div className="m-2 mb-6 flex flex-col items-start">
                <label className="text-black text-left">
                  Hide purchase button in reader title
                </label>
                <br />
                <label className="flex items-center gap-4">
                  <span>{titleBarPurchaseChange ? "Hide" : "Show"}</span>
                  <Switch
                    onChange={handleEnableTitleBarPurchaseChange}
                    checked={titleBarPurchaseChange}
                  />
                </label>
              </div>
            </div>

            <div className="flex flex-col justify-start bg-gray-50">
              <div className="w-full p-2">
                {/* <h1 className="text-lg  text-center">Ad Timer</h1> */}
              </div>
              <div className="m-2 mb-6 flex flex-col items-start">
                <label className="text-black text-left">
                  Hide subscription button in reader title
                </label>

                <br />
                <label className="flex items-center gap-4">
                  <span>{titleBarSubChange ? "Hide" : "Show"}</span>
                  <Switch
                    onChange={handleEnableTitleBarSubChange}
                    checked={titleBarSubChange}
                  />
                </label>
              </div>
            </div>

            <div className="text-center">
              <BasicButton title={"Update"} handleClick={action} />
            </div>
            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 flex justify-between">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
