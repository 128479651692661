import BookingCalendar from "../../../../../components/calendar";
import MainSubButton from "./components/main-button";
import ShortSubButtons from "./components/short-buttons";

export const DateSelection = (props) => {
  const { preDateSelectionData } = props;
  const { subMainButton } = props;
  const { shortMainButtons } = props;

  return (
    <div className="flex flex-col my-2 w-full">
      {" "}
      <BookingCalendar {...preDateSelectionData} />
      <MainSubButton {...subMainButton} />
      <ShortSubButtons {...shortMainButtons} />
    </div>
  );
};

export default DateSelection;
