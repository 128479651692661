import axios from "axios";
import { useEffect, useState } from "react";

import Pagination from "../../../components/pagination";
import BasicButton from "../../../components/buttons/basic-button";
import LoadingProgress from "../../publisher/rich-page/components/loading-progress";
import useDebounce from "../../../components/debounce/debounce-helper";
import TextField from "../../../components/inputs/text-input";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";
import DeletePromptModal from "../../../modals/delete-warning-modal";
import {
  isAdmin,
  API_BASE,
  isAuthUser,
  isResponsiveMode,
} from "../../../Utils/helpers";
import { toast } from "react-toastify";

export default function AdminContracts() {
  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
  }, []);

  return (
    <>
      <div className="main w-full">
        <div className="bg-white m-2 p-2 flex justify-between shadow px-4">
          <div>
            <h1 className="text-2xl text-center m-2 bold">
              {" "}
              Product Contracts
            </h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex justify-center my-4 overflow-hidden">
          <div class="flex flex-col items-center p-10 gap-4 md:w-6/12">
            <div className="w-full">
              <a href="/admin/supplier/product-contracts/supplier-contract">
                <BasicButton
                  title={"Product Supplier Agreement"}
                  handleClick={() => window.history.back()}
                  classes={"w-full"}
                />
              </a>
            </div>
            <div className="w-full">
              <a href="/admin/supplier/product-contracts/buyer-contract">
                <BasicButton
                  title={"Product Buyer Agreement"}
                  handleClick={() => window.history.back()}
                  classes={"w-full"}
                />
              </a>
            </div>
            <div className="w-full">
              <a href="/admin/supplier/product-contracts/sample/sample-contract">
                <BasicButton
                  title={"Sample Contracts"}
                  handleClick={() => window.history.back()}
                  classes={"w-full"}
                />
              </a>
            </div>
            <div className="w-full">
              <a href="/admin/supplier/product-contracts/default/options">
                <BasicButton
                  title={"Default Contracts"}
                  handleClick={() => window.history.back()}
                  classes={"w-full"}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
