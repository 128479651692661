export default function InvalidLink({ isInvalidLink }) {
  return (
    isInvalidLink && (
      <p>
        Update Emails can no longer be restored due to a change in the
        publisher’s account settings.
      </p>
    )
  );
}
