import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import AdInsertModal from "../../../modals/ad-insert-modal";
import AdEditModal from "../../../modals/ad-edit-modal";
import InformationPageModal from "../../../modals/information-page-modal";
import WebHeader from "../header";
import {
  API_BASE,
  BASE_URL,
  authUserData,
  isAuthUser,
} from "../../../Utils/helpers";

export default function CustomerMaster({ component }) {
  return (
    <>
      <WebHeader />
      <div className="w-full mt-0">
        {true && (
          <div className="m-2 p-2  justify-center">
            {/* <div className="w-full flex flex-col items-start text-white bg-gray-900 h-screen p-4">
              <a href="/user/dashboard">
                <p className="m-2">My Books</p>
              </a>

              <a href="/user/subscriptions">
                <p className="m-2">Email Subscriptions</p>
              </a>

              <hr />
            </div> */}

            <div className=" p-2 rounded h-full">{component}</div>
          </div>
        )}
      </div>
    </>
  );
}
