import { toast } from "react-toastify";
import StripeCheckout from "react-stripe-checkout";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import BasicButton from "../components/buttons/basic-button";
import {
  authToken,
  authUserData,
  isAuthUser,
  API_BASE,
  STRIPE_PK,
  donationPriceWithSystemFees,
} from "../Utils/helpers";
import axios from "axios";

function StripeDonateForm({ book, amount, action, email }) {
  const product = {
    amount: donationPriceWithSystemFees(book, amount),
    description: "Donation to " + book.name,
  };

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    // alert("hi");
  }, []);

  const headers = {
    accept: "application/json",
    Authorization: "bearer " + authToken(),
  };

  async function handleToken(token, addresses) {
    setProcessing(true);
    const response = await axios.request({
      method: "post",
      headers: headers,
      url: API_BASE + "/api/stripe/charge/donation",
      data: {
        token,
        product,
        book,
        user_uuid: isAuthUser() && authUserData().uuid,
        email: email,
      },
    });

    // console.log(response.status);

    if (response.status === 200) {
      setProcessing(false);
      toast("Donation Received!", { type: "success" });
      action && action();
    } else {
      setProcessing(false);
      toast("Something went wrong", { type: "error" });
      action && action();
    }
  }

  useEffect(() => {
    // alert(amount);
  }, []);

  return (
    <StripeCheckout
      class="center"
      stripeKey={STRIPE_PK}
      token={handleToken}
      amount={`$${50 * 100}`}
      name={`Donation`}
      disabled={processing}
    >
      <BasicButton
        title={
          processing
            ? "Processing..."
            : `Donate  $${donationPriceWithSystemFees(book, amount)}`
        }
        classes="w-full"
        disabled={processing}
      />
    </StripeCheckout>
  );
}

export default StripeDonateForm;
