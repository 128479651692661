import EditorTooltip from "../../tooltip/editor-tooltip";
import Checkbox from "../../../../../../../components/inputs/checkbox";

export default function BookingInterruption(props) {
  const { productMeta, pageContext, handleMetaChange } = props;
  return (
    <div className="flex gap-2 w-full md:w-6/12 justify-center my-5 relative">
      <div>
        <Checkbox
          disabled={pageContext == "update"}
          handleChange={(e) => {
            if (
              pageContext == "update" ||
              productMeta.integration_type == "pricelabs"
            )
              return;

            if (productMeta.booking_interruption) {
              handleMetaChange("", "quantity", "text");
            }

            if (!productMeta.booking_interruption) {
              if (productMeta.subscription_cap_enabled) {
                handleMetaChange(e, "subscription_cap_enabled", "checkbox");
                handleMetaChange(e, "subscription_cap_duration", "");
              }

              handleMetaChange(
                { label: "", price: "", uuid: "" },
                "Fortnightly",
                "product_short"
              );
              handleMetaChange(
                { label: "", price: "", uuid: "" },
                "Weekly",
                "product_short"
              );
              handleMetaChange(
                { label: "", price: "", uuid: "" },
                "Daily",
                "product_short"
              );
            }
            handleMetaChange(e, "booking_interruption", "checkbox");
          }}
          checked={!productMeta.booking_interruption}
        />
      </div>
      <span>Disallow Booking Interruption</span>
      <EditorTooltip
        body={`A <b>Booking Interruption</b> occurs when an existing subscription is prevented from automatically renewing because another customer has booked a subscription that starts during the next potential renewal period. <br/ >
For instance: <br/ >
⦾ Imagine you operate on a weekly booking cycle. <br/ >
⦾ Customer A has a subscription for an <br/ > item beginning on June 1st, with the <br/ > option to automatically renew for another <br/ > week—provided they do not cancel the subscription—by <br/ > the end of the cycle on June <br/ > 8th, potentially extending their booking until <br/ > June 15th and further.<br/ >
⦾ If booking interruption is allowed, it enables <br/ > another customer — say, Customer B — to reserve <br/ > the same item — say, on June 5th — <br/ > and schedule their subscription to start on <br/ > June 11th and end on June 18th. <br/ >
⦾ Obviously, this generates a conflicting schedule with <br/ > Customer A's prospective renewal period spanning June <br/ > 8th to June 15th. <br/ >
⦾ Therefore, Customer A's subscription will not automatically <br/ > renew at the end of their current <br/ > booking period on June 8th, rather Customer <br/ > A's subscription will end as initially scheduled <br/ > on June 8th, allowing for a smooth <br/ > transition to Customer B's booking period without <br/ > any overlaps, thereby ensuring a harmonious <br/ > sharing of resources among the customers.<br/ >

PLEASE NOTE: <br/ >
• Booking Interruption is allowed by default on the system. <br/ >
• Booking Interruption is only applicable on any <br/ > given day when the available quantity of <br/ > an item is 0, irrespective of the <br/ > item's availability in the past or in <br/ > the near or distant future; consequently, Booking <br/ > Interruption is determined by the conditions of <br/ > the current day.<br/ >
• If Booking Interruption is disallowed via this <br/ > checkbox, and there is at least 1 <br/ > active subscription linked to the button for <br/ > the current day, coupled with the day's <br/ > available quantity being 0, then the <br/ > purchase button — along with any other button <br/ > merged with its quantity — will become inactive <br/ > on your customer-facing page. This condition <br/ > persists as long as, on that day,<br/ > there is an active subscription and the <br/ > available quantity is 0, irrespective of the <br/ > item's availability in the future. <br/ >
• To avoid Booking Interruption, even if <br/ > it is allowed by not ticking this <br/ > checkbox, simply increase the available quantity of <br/ > the item under Track Quantity, on any <br/ > given day when the available quantity is 0. <br/ >
• If Booking Interruption is disallowed, it simply <br/ > means that a customer can subscribe to <br/ > an item on your page and expect <br/ > their subscription to renew automatically without any <br/ > fear of interruption. <br/ >
• Booking Interruption can be retroactively disallowed via <br/ > the checkbox, even if the button already <br/ > has multiple subscriptions on it. The retroactive <br/ > disablement simply means that existing subscriptions (current & future) <br/ > will continue as normal, with the last <br/ > subscription in the sequence being the one <br/ > that renews automatically, but the button will <br/ > be inactive if the available quantity on <br/ > the day when a customer accesses it is 0.<br/ > Finally, please note that if Booking Interruption is not enabled, this means that if just one customer makes a booking on the product page, then no other customer can make a booking on that product page unless the initial booking by that customer is cancelled. Additionally, it does not matter what start date the first customer chooses; no other customer will be able to make a booking on that page afterwards. In such a scenario, the Track Quantity setting no longer comes into play because this purchase setting is intended for one customer and one item or one bundle of items. Disabling Booking Interruption is very useful if you want to dedicate a page to a particular customer whose constant renewal you do not want to be interrupted.`}
        top={"-220px"}
        right={"-75px"}
        height="h-48 h-48"
      />
    </div>
  );
}
