export default function MenuBox(props) {
  const { publisher, setActiveTab, activeTab } = props;

  const getSlidesSum = () => {
    let count = 0;
    if (publisher?.individual_category_sum?.photo_album) {
      count += Number(publisher?.individual_category_sum?.photo_album);
    }
    if (publisher?.individual_category_sum?.book) {
      count += Number(publisher?.individual_category_sum?.book);
    }

    if (publisher?.individual_category_sum?.comic) {
      count += Number(publisher?.individual_category_sum?.comic);
    }

    return count;
  };

  return (
    <ul
      class="flex items-center justify-around justify-center   
                    uppercase tracking-widest font-semibold  text-gray-600
                    border-t"
    >
      {/* <!-- posts tab is active --> */}
      <li
        onClick={() => setActiveTab(0)}
        className={
          activeTab == 0
            ? "border-t border-gray-700 text-gray-700 text-center"
            : "text-center"
        }
      >
        <div class="flex flex-col text-xxs cursor-pointer">
          <i class="fas fa-window-restore text-xl md:text-xs"></i>
          <span class="md:inline md:p-1 my-xxsm md:text-xs">
            All{" "}
            <span className="hidden md:inline-block">
              ({publisher?.individual_category_sum?.slides})
            </span>
          </span>

          <span className="md:hidden">
            ({publisher?.individual_category_sum?.slides})
          </span>
        </div>
      </li>
      <li
        onClick={() => setActiveTab(9)}
        className={
          activeTab == 9
            ? "border-t border-gray-700 text-gray-700 text-center"
            : "text-center"
        }
      >
        <div class="flex flex-col text-xxs cursor-pointer">
          <i class="fas fa-window-restore text-xl md:text-xs"></i>
          <span class="md:inline md:p-1 my-xxsm md:text-xs">
            Lists{" "}
            <span className="hidden md:inline-block">
              ({publisher?.individual_category_sum?.list})
            </span>
          </span>

          <span className="md:hidden">
            ({publisher?.individual_category_sum?.list})
          </span>
        </div>
      </li>
      <li
        onClick={() => setActiveTab(7)}
        className={
          activeTab == 7
            ? "border-t border-gray-700 -mt-px text-gray-700 text-center"
            : "text-center"
        }
      >
        <div class="flex flex-col md:p-3 cursor-pointer">
          <i
            class="fa fa-file
                             px-1 pt-1 rounded text-xl md:text-xs"
          ></i>
          <span class="inline md:p-1 my-xxsm md:text-xs">
            Products{" "}
            <span className="hidden md:inline-block">
              ({publisher?.individual_category_sum?.product})
            </span>
          </span>
          <span className="md:hidden">
            ({publisher?.individual_category_sum?.product})
          </span>
        </div>
      </li>
      <li
        onClick={() => setActiveTab(6)}
        className={
          activeTab == 6
            ? "border-t border-gray-700 -mt-px text-gray-700 text-center"
            : "text-center"
        }
      >
        <div class="flex flex-col md:p-3 cursor-pointer">
          <i
            class="fa fa-file
                             px-1 pt-1 rounded text-xl md:text-xs"
          ></i>
          <span class="inline md:p-1 my-xxsm md:text-xs">
            Content{" "}
            <span className="hidden md:inline-block">
              ({publisher?.individual_category_sum?.custom})
            </span>
          </span>
          <span className="md:hidden">
            ({publisher?.individual_category_sum?.custom})
          </span>
        </div>
      </li>
      <li
        onClick={() => setActiveTab(3)}
        className={
          activeTab == 3
            ? "border-t border-gray-700 -mt-px text-gray-700 text-center"
            : "text-center"
        }
      >
        <div class="flex flex-col md:p-3 cursor-pointer">
          <i
            class="fa fa-file
                             px-1 pt-1 rounded text-xl md:text-xs"
          ></i>
          <span class="inline md:p-1 my-xxsm md:text-xs">
            Slides{" "}
            <span className="hidden md:inline-block">({getSlidesSum()})</span>
          </span>
          <span className="md:hidden">({getSlidesSum()})</span>
        </div>
      </li>
    </ul>
  );
}
