import BasicButton from "../../../../components/buttons/basic-button";
import { isPublisher } from "../../../../Utils/helpers";

export default function PubSupplierOnboardingOpts(props) {
  const {
    stage,
    handleRegTypeChange,
    submitted,
    approved,
    rejected,
    redoMessageMode,
    loadingPage,
    loadingPageFail,
  } = props;

  return (
    stage == 1 &&
    !submitted &&
    !approved &&
    !rejected &&
    !redoMessageMode &&
    !loadingPage &&
    !loadingPageFail && (
      <div className="p-2 w-full">
        {/* content start */}
        <div className="flex flex-col items-center mt-10">
          <div>
            <h2 className="font-bold text-2xl">Are You a Business?</h2>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center gap-4 mt-8">
            <div className="p-2 ">
              <div className="flex flex-col gap-4 relative">
                <div className="text-center flex justify-center gap-2">
                  <BasicButton
                    classes={"bg-black-500 hover:bg-black-500 w-full  py-6"}
                    title={"I am an Individual"}
                    handleClick={() => handleRegTypeChange("individual")}
                  />
                </div>
              </div>
            </div>
            <div className="p-2">
              <div className="flex flex-col gap-4 relative">
                <div className="text-center flex justify-center gap-2">
                  <BasicButton
                    classes={"bg-black-500 hover:bg-black-500 w-full  py-6"}
                    title={"I am a Business"}
                    handleClick={() => handleRegTypeChange("business")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
