import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { isAuthUser, API_BASE, isSponsor } from "../../Utils/helpers";
import BasicButton from "../../components/buttons/basic-button";
import WebHeader from "../web/header";
import { useParams } from "react-router-dom";
import Footer from "../../layout/footer";

export default function SponsorPaymenRedirect() {
  const [subscription, setSubscription] = useState({});
  const [processing, setProcessing] = useState(false);
  const [hasSubscribed, setHasSubscribed] = useState(false);
  const [status, setStatus] = useState("");

  const { id } = useParams();
  const naviagte = useNavigate();
  useEffect(() => {
    if (isAuthUser()) {
      localStorage.setItem("sponsor_payment_redirect", "xxx");
      window.location.href = "/sponsor/dashboard";
    }
  }, []);

  const authUser = () => {
    setProcessing(true);
    const url = API_BASE + "/api/sponsor/system/authenticate/" + id;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setProcessing(false);
          let user = response.data.data;
          if (user.roles && user.roles.length > 0) {
            localStorage.setItem("user", JSON.stringify(user));
            // localStorage.setItem("user_email", user.email);
            localStorage.setItem("token", response.data.access_token);
            // localStorage.setItem("roles", JSON.stringify(user.roles));
            localStorage.setItem("sponsor_payment_redirect", "xxx");
            if (isSponsor()) {
              naviagte("/sponsor/dashboard");
            }
          } else {
            toast("Unauthorized Access", { type: "error" });
          }
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  useEffect(() => {
    //authUser();
  }, []);

  return (
    <>
      <WebHeader />
      <div class=" px-6 ">
        <br />
        <div class="flex items-center justify-center h-screen">
          <div>
            <div class="flex flex-col items-center space-y-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="text-green-600 w-28 h-28"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <h1 class="text-4xl font-bold">Thank You !</h1>
              <p>Your payment was successful!.</p>
              <p>
                Please check your mailbox, we sent you a link to complete your
                account setup.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
