const InsertAdvert = (props) => {
  const { showAdBox } = props;
  return (
    <button
      className="border border-gray rounded-lg p-1 m-1"
      onClick={() => showAdBox("from_editor")}
    >
      Insert Ad
    </button>
  );
};

export default InsertAdvert;
