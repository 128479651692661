import { useSupplierApplicationsStore } from "../../../../../../stores/supplier-applications-store";
import BasicButton from "../../../../../../components/buttons/basic-button";
import { BASE_URL_WITH_LOCAL } from "../../../../../../Utils/helpers";

import { useNavigate } from "react-router-dom";

export default function MobileTable(props) {
  const navigate = useNavigate();

  const { openCommentModal, preSubCancelPromt } = props;

  const mailStore = useSupplierApplicationsStore((state) => state);

  return (
    <div className="w-full flex flex-col justify-center my-4 text-center">
      {" "}
      <div
        className="overflow-x-scroll inline"
        style={{
          width: document.documentElement.clientWidth - 90 + "px",
          margin: "0 auto",
        }}
      >
        {mailStore.data.data &&
          mailStore.data.data.map((c) => {
            return (
              <div className="flex">
                <div class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Date</h3>
                  <p>
                    {c.refactoredCreatedAtTime
                      ? new Date(
                          c.refactoredCreatedAtTime.replace(/-/g, "/")
                        ).getDate() +
                        "-" +
                        (new Date(
                          c.refactoredCreatedAtTime.replace(/-/g, "/")
                        ).getMonth() +
                          1) +
                        "-" +
                        new Date(
                          c.refactoredCreatedAtTime.replace(/-/g, "/")
                        ).getFullYear()
                      : "N/A"}
                  </p>
                </div>

                <div class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Contract Name</h3>
                  {c?.name}
                </div>
                <div class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Contract</h3>
                  <a
                    href={
                      BASE_URL_WITH_LOCAL +
                      "/storage" +
                      c?.file?.split("public")[1]
                    }
                    target="_blank"
                  >
                    {" "}
                    <BasicButton
                      disabled={!c?.file}
                      title={"View"}
                      classes={
                        "my-bg-yellow-500 text-black hover:text-black  hover:bg-yellow-500"
                      }
                      handleClick={() => null}
                    />
                  </a>
                </div>
                <div class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Location</h3>
                  {c?.location || "N/A"}
                </div>
                <div class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Status</h3>
                  {c?.status || "N/A"}
                </div>

                <div class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Status</h3>
                  <div className="flex flex-col items-center gap-2">
                    {" "}
                    <BasicButton
                      disabled={!c.id}
                      title={"Edit"}
                      classes={
                        "bg-blue-500 text-white  hover:text-white   hover:bg-blue-500 w-full"
                      }
                      handleClick={() =>
                        navigate(
                          `/publisher/supplier/my-supplier-contracts/${c.uuid}/edit`
                        )
                      }
                    />
                    <BasicButton
                      disabled={!c.uuid}
                      title={"View Buttons"}
                      classes={
                        "my-bg-yellow-500 text-black  hover:text-black  hover:bg-yellow-500 w-full"
                      }
                      handleClick={() => {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                        navigate(
                          `/publisher/supplier/my-supplier-contracts/${c.uuid}/view-buttons`
                        );
                      }}
                    />
                    <BasicButton
                      disabled={!c.uuid}
                      title={"Delete"}
                      classes={
                        "bg-red-500 text-white  hover:text-white  hover:bg-red-500 w-full"
                      }
                      handleClick={() => preSubCancelPromt(c)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
