import {
  isAuthUser,
  authUserData,
  isReader,
  isResponsiveMode,
} from "../../../../../Utils/helpers";
import MSharedMenuItems from "./shared";
export default function MReaderMenuItems() {
  const renderMenu = () => {
    return isAuthUser() && isReader();
  };
  return renderMenu() && isResponsiveMode() ? (
    <>
      <a
        href="/user/purchases"
        class="text-base font-medium text-gray-500 hover:text-gray-900 mx-2"
      >
        My Purchases
      </a>

      <a
        href="/user/product/buys"
        class="text-base font-medium text-gray-500 hover:text-gray-900 mx-2"
      >
        My Product Buys
      </a>

      <a
        href="/user/product/subscriptions"
        class="text-base font-medium text-gray-500 hover:text-gray-900 mx-2"
      >
        My Product Subs
      </a>

      <a
        href="/user/email/requested-updates"
        class="text-base font-medium text-gray-500 hover:text-gray-900 mx-2"
      >
        Requested Updates
      </a>

      <a
        href={`/user/profile/${authUserData().uuid}`}
        class="text-base font-medium text-gray-500 hover:text-gray-900 mx-2"
      >
        Profile
      </a>
    </>
  ) : (
    ""
  );
}
