import TextField from "../components/inputs/text-input";
import BasicButton from "../components/buttons/basic-button";
import { useBookStore } from "../stores/book-store";
import { isAuthUser, authUserData } from "../Utils/helpers";
import StripeForm from "../payment/pay-button";
import StripeSubscriptionPayButton from "../payment/stripe-subscription-pay-button";
import StripeSubscriptionModal from "./stripe-subscription-modal";
import { API_BASE, getClientBaseURL } from "../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";

export default function AdPaymentModal({
  modalOpen,
  hideModal,
  book,
  hasPurchasedBook,
  showRegistrationModal,
}) {
  const [showSubModal, setShowSubModal] = useState(false);
  const [subSecretKey, setSubSecretKey] = useState();
  const [processing, setProcessing] = useState(false);

  const getStripePaymentSecret = () => {
    let formData = new FormData();
    const url = API_BASE + "/api/user/payment/get-stripe-payment-secret";
    setProcessing(true);
    axios
      .post(url, {
        book_id: book.uuid,
        user_id: isAuthUser() ? authUserData().uuid : "",
      })
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          // alert(response.data.secret_key);
          setSubSecretKey(response.data.secret_key);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", {
          type: "error",
        });
      });
  };

  const hideSubModal = () => {
    setSubSecretKey();
    setShowSubModal((prev) => (prev = !prev));
  };

  const openSubModal = () => {
    getStripePaymentSecret();
    setShowSubModal(true);
    // if (subSecretKey) {
    //   setShowSubModal(true);
    // }
  };

  useState(() => {
    // alert(getClientBaseURL());
  }, []);
  return (
    <>
      <StripeSubscriptionModal
        modalOpen={showSubModal}
        hideModal={hideSubModal}
        book={book}
        subSecretKey={subSecretKey}
      />
      <div
        className={`absolute z-40 overflow-y-auto top-0 w-full left-0 ${
          modalOpen ? "" : "hidden"
        } id="modal"`}
      >
        <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-6/12 h-4/12 "
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div class="px-2 pt-5 pb-4 sm:p-6 sm:pb-4">
              {/* modal body */}
              <div className="p-2">
                <h1 className="text-2xl font-bold text-center">
                  {/* {getActiveWidgetTitle(currentEditingWidget, widgets)} */}
                  Subscribe Ad-Free
                </h1>
              </div>

              {book.owner && book.owner.enable_subscription ? (
                <div className="flex justify-center">
                  <p className="w-8/12">
                    Purchase a monthly Ad-free subscription for $
                    {book.owner.subscription_cost}/Month to access the
                    publisher's entire collection Ad-Free.
                  </p>
                </div>
              ) : (
                ""
              )}

              {/* modal body */}
            </div>
            <div class="bg-gray-200 px-4 py-3 flex flex-col md:flex-row justify-between gap-2">
              {book.owner && book.owner.enable_subscription == true ? (
                <div className="flex justify-center">
                  {hasPurchasedBook && !hasPurchasedBook() ? (
                    isAuthUser() ? (
                      <BasicButton
                        title={`Subscribe Ad-Free $${book.owner.subscription_cost}/Month`}
                        handleClick={openSubModal}
                        disabled={processing}
                      />
                    ) : (
                      <BasicButton
                        title={`Subscribe Ad-Free $${book.owner.subscription_cost}/Month`}
                        handleClick={showRegistrationModal}
                        disabled={processing}
                      />
                    )
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              <button
                type="button"
                class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
                onClick={() => hideModal()}
              >
                <i class="fas fa-times"></i> Close
              </button>
              {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
