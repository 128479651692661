import { useNavigate } from "react-router-dom";
import TextField from "../../../../../../components/inputs/text-input";
import BasicButton from "../../../../../../components/buttons/basic-button";
export default function AdminSupplierFilterBar(props) {
  const { handleCountPerPageChange } = props;
  const { handleSearchTextChange } = props;
  const { serchQValue } = props;
  const { handleLatestEntriesChange } = props;
  const { latestQValue } = props;
  const { handleOldestEntriesChange } = props;
  const { oldestQValue } = props;
  const { model } = props;

  const navigate = useNavigate();

  return (
    <>
      {" "}
      <div className="flex md:flex-row md:justify-between w-full gap-4 justify-center">
        <div className="w-full">
          <TextField
            title="Search"
            placeholder={"Search"}
            classes="rounded-lg w-full"
            handleChange={handleSearchTextChange}
            value={serchQValue}
          />
        </div>

        <div className="flex items-center">
          <label for="timezone" value="Your Timezone" />
          <select
            onChange={handleCountPerPageChange}
            required
            class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg cursor-pointer"
          >
            <option value="3" default>
              10
            </option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
      <div className="flex justify-center md:justify-between w-full gap-2 md:mr-4 my-2 items-center">
        <div className="flex gap-2 justify-center w-full">
          {" "}
          <div className="flex flex-col items-center">
            <label for="timezone" value="Your Timezone">
              {" "}
              Latest Entries
            </label>
            <select
              value={latestQValue}
              onChange={handleLatestEntriesChange}
              required
              class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
            >
              <option value="normal_1000000" defaultValue>
                Not selected
              </option>
              <option value="latest_100">Latest 100</option>
              <option value="latest_500">Latest 500</option>
              <option value="latest_1000">Latest 1,000</option>
              <option value="latest_5000">Latest 5,000</option>
              <option value="latest_10000">Latest 10,000</option>
            </select>
          </div>
          <div className="flex flex-col items-center">
            <label for="timezone" value="Your Timezone">
              Oldest Entries
            </label>
            <select
              value={oldestQValue}
              onChange={handleOldestEntriesChange}
              required
              class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
            >
              <option value="normal_1000000" defaultValue>
                Not selected
              </option>
              <option value="oldest_100">Oldest 100</option>
              <option value="oldest_500">Oldest 500</option>
              <option value="oldest_1000">Oldest 1,000</option>
              <option value="oldest_5000">Oldest 5,000</option>
              <option value="oldest_10000">Oldest 10,000</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
}
