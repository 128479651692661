import authRequestService from "../../../../services/HttpRequests/authenticated-request-services";
import { useState, useEffect } from "react";
import { API_BASE, authUserData } from "../../../../Utils/helpers";
import { useRef } from "react";
import axios from "axios";
export default function SupplierVideoVerificationCode(props) {
  const { code, setCode } = props;

  const [processing, setProcessing] = useState(false);

  const getCode = () => {
    const url =
      API_BASE +
      "/publisher/" +
      authUserData().uuid +
      "/supplier/get-video-verification-code";
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((res) => {
        setCode(res.data.code);
        setProcessing(false);
      })
      .catch((err) => setProcessing(false));
  };

  useEffect(() => {
    getCode();
  }, []);

  return (
    <div className="my-identity dropdown flex items-center w-full">
      <div className="control flex flex-col items-center">
        <h1 className="font-bold my-5">Video Verification Code</h1>
        <div className="bg-gray text-black">{code}</div>
        <small>
          Please write this code boldly on a white piece of paper as we will
          require it in the next step
        </small>
      </div>
    </div>
  );
}
