import BasicButton from "../../../../../../components/buttons/basic-button";
export default function ShortSubButtons(props) {
  return (
    <div className="">
      {" "}
      {props.showCalendar && props.productReplyMeta.start_date ? (
        <div className="w-full">
          {props.shortDurations.map((s) => (
            <div className="flex justify-center w-full mb-1">
              {/* {s.id} */}
              <BasicButton
                disabled={!props.shortDurations.length}
                title={props.getFullShortMetaName(s.name) + " $" + s.price}
                classes="px-8 w-full md:w-8/12"
                handleClick={() => {
                  props.setSelType("short");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  props.setShortDurationMeta(s);
                  props.setShowCalendar(false);
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
