import { useSupplierApplicationsStore } from "../../../../../../stores/supplier-applications-store";
import { isResponsiveMode } from "../../../../../../Utils/helpers";
import Checkbox from "../../../../../../components/inputs/checkbox";
import BasicButton from "../../../../../../components/buttons/basic-button";
import { useNavigate } from "react-router-dom";

export default function DesktopTable(props) {
  const mailStore = useSupplierApplicationsStore((state) => state);
  const { openCommentModal, processing } = props;

  const navigate = useNavigate();

  return !processing ? (
    <div className="overflow-x-scroll md:h-screen w-full">
      {" "}
      <table class="table-auto w-full my-4">
        <thead>
          <tr className={window.innerWidth < 720 ? "hidden" : ""}>
            <th class="px-4 py-2 text-gray-600">Date Created</th>
            <th class="px-4 py-2 text-gray-600">Name</th>
            <th class="px-4 py-2 text-gray-600">User Handle</th>
            <th class="px-4 py-2 text-gray-600">Email</th>
            <th class="px-4 py-2 text-gray-600">Phone</th>
            <th class="px-4 py-2 text-gray-600">Country</th>
            <th class="px-4 py-2 text-gray-600">Application</th>
          </tr>
        </thead>
        <tbody className="w-full">
          {mailStore.data.data &&
            mailStore.data.data.map((application) => {
              return (
                <tr
                  className={
                    window.innerWidth < 820 ? "flex flex-col items-center " : ""
                  }
                >
                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Date</h3>
                    <p>
                      {application.refactoredCreatedAtTime
                        ? new Date(
                            application.refactoredCreatedAtTime.replace(
                              /-/g,
                              "/"
                            )
                          ).getDate() +
                          "-" +
                          (new Date(
                            application.refactoredCreatedAtTime.replace(
                              /-/g,
                              "/"
                            )
                          ).getMonth() +
                            1) +
                          "-" +
                          new Date(
                            application.refactoredCreatedAtTime.replace(
                              /-/g,
                              "/"
                            )
                          ).getFullYear()
                        : "N/A"}
                    </p>
                  </td>

                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Name</h3>
                    {application.user.name}
                  </td>
                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">User Handle</h3>
                    {application.user.handle_id}
                  </td>
                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Email</h3>
                    {application.user.email}
                  </td>
                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Phone</h3>
                    {application.user?.phone?.phone || "N/A"}
                  </td>
                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Country</h3>
                    {application.user.country}
                  </td>

                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Application</h3>
                    <BasicButton
                      disabled={!application?.user?.uuid}
                      title={"View"}
                      classes={"my-bg-yellow-500 text-black"}
                      handleClick={() =>
                        navigate(
                          `/admin/supplier/biz-relocation/applicant/${application.user.uuid}`
                        )
                      }
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  ) : (
    ""
  );
}
