import { useSupplierApplicationsStore } from "../../../../../stores/supplier-applications-store";
import { isResponsiveMode } from "../../../../../Utils/helpers";
import Checkbox from "../../../../../components/inputs/checkbox";
import BasicButton from "../../../../../components/buttons/basic-button";
import { useNavigate } from "react-router-dom";
import { BASE_URL_WITH_LOCAL } from "../../../../../Utils/helpers";

export default function MobileTable(props) {
  const navigate = useNavigate();

  const {
    openCommentModal,
    preProcessApplication,
    processApplication,
    processing,
  } = props;

  const mailStore = useSupplierApplicationsStore((state) => state);

  return (
    <div class="w-full flex flex-col justify-center my-4 text-center">
      {" "}
      <div
        className="overflow-x-scroll inline"
        style={{
          width: document.documentElement.clientWidth - 90 + "px",
          margin: "0 auto",
        }}
      >
        <div class="flex">
          {mailStore.contract?.id && (
            <div className="flex">
              {" "}
              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold">View Contract</h3>
                <a
                  href={
                    BASE_URL_WITH_LOCAL +
                    "/storage" +
                    mailStore.contract?.file?.split("public")[1]
                  }
                  target="_blank"
                >
                  {" "}
                  <BasicButton
                    disabled={!mailStore.contract?.file}
                    title={"View"}
                    classes={
                      "my-bg-yellow-500 text-black hover:text-black  hover:bg-yellow-500"
                    }
                    handleClick={() => null}
                  />
                </a>
              </div>
              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold">Status</h3>
                <select
                  value={mailStore.selectedApplicantStatus}
                  onChange={(e) =>
                    mailStore.setSelectedApplicantStatus(e.target.value)
                  }
                  required
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer text-center"
                  style={{ width: "200px" }}
                >
                  <option value="" default disabled selected>
                    Select
                  </option>
                  <option value="approved">Approve</option>
                  <option value="rejected">Reject</option>
                </select>
              </div>
              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold">Application</h3>
                <BasicButton
                  disabled={!mailStore.selectedApplicantStatus || processing}
                  title={processing ? "Processing..." : "Process"}
                  classes={"my-bg-yellow-500 text-black"}
                  handleClick={() =>
                    mailStore.selectedApplicantStatus == "rejected"
                      ? preProcessApplication()
                      : processApplication()
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
