import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import WebHeader from "../../../web/header";

import {
  isAdmin,
  isAuthUser,
  API_BASE,
  getLocation,
  getClientBaseURL,
  isRegistrationComplete,
  isSupplier,
  isPublisher,
  isReader,
} from "../../../../Utils/helpers";
import Footer from "../../../../layout/footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import Registration2Modal from "../../../../modals/registration-2-modal";

export default function CPProdSubscriptionComplete() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [registration2ModalOpen, setRegistration2ModalOpen] = useState(false);

  const hideRegistration2Modal = () => {
    setRegistration2ModalOpen(false);
  };
  let { userId } = useParams();
  const naviagte = useNavigate();

  const getRedirectUrl = () => {
    if (isSupplier() || isPublisher()) {
      return getLocation().hostname == "litzapp.com"
        ? getClientBaseURL() + `/publisher/my/product-transactions/recurring`
        : getClientBaseURL() +
            `:3000/publisher/my/product-transactions/recurring`;
    }

    if (isReader) {
      return getLocation().hostname == "litzapp.com"
        ? getClientBaseURL() + `/user/product/subscriptions	`
        : getClientBaseURL() + `:3000/user/product/subscriptions	`;
    }

    return "/";
  };

  useEffect(() => {
    if (!isRegistrationComplete()) {
      setRegistration2ModalOpen(true);
      localStorage.removeItem("registration_type");
    }
  }, []);

  return (
    <>
      {" "}
      <WebHeader />
      <div class=" px-6 ">
        <br />
        <div class="flex items-center justify-center h-screen">
          <div>
            <div class="flex flex-col items-center space-y-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="text-green-600 w-28 h-28"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <h1 class="text-4xl font-bold">Thank You !</h1>
              <p>Your payment was successful!.</p>
              <a
                href={getRedirectUrl()}
                class="inline-flex items-center px-4 py-2 text-white bg-black border border-indigo-600 rounded rounded-full hover:bg-black focus:outline-none focus:ring cursor pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-3 h-3 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M7 16l-4-4m0 0l4-4m-4 4h18"
                  />
                </svg>
                <span class="text-sm font-medium">
                  View My Product Subscriptions
                </span>
              </a>
              <p className="text-center">
                Check your inbox, spam or junk email folder for email
                confirmation of this purchase.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Registration2Modal
        modalOpen={registration2ModalOpen}
        hideModal={hideRegistration2Modal}
        mode="password_reset_transaction"
      />
    </>
  );
}
