import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import AdInsertModal from "../../../modals/ad-insert-modal";
import AdEditModal from "../../../modals/ad-edit-modal";
import InformationPageModal from "../../../modals/information-page-modal";
import WebHeader from "../header";
import {
  API_BASE,
  BASE_URL,
  authUserData,
  isAuthUser,
} from "../../../Utils/helpers";

export default function EmailSubscriptions() {
  const [subscriptions, setSubscriptions] = useState([]);

  const getSubscriptions = () => {
    const url = API_BASE + "/api/subscriptions/user/" + authUserData().uuid;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setSubscriptions(response.data.data);
        }
      })
      .catch((error) => {
        alert(error.message);
        console.error("There was an error!", error);
      });
  };

  const cancelSubscription = () => {
    const url =
      API_BASE + "/api/subscriptions/user/" + authUserData().uuid + "/cancel";
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setSubscriptions(response.data.data);
        }
      })
      .catch((error) => {
        alert(error.message);
        console.error("There was an error!", error);
      });
  };

  useEffect(() => {
    getSubscriptions();
    // if (!isAdmin()) {
    //   window.location.href = "/";
    // }
  }, []);
  return (
    <>
      <div className="main">
        <div className="border border-b-gray border-t-white border-r-white border-l-white m-2 p-2 flex justify-between rounded-lg flex-1">
          <div>
            <h1 className="text-2xl text-center m-2 bold">
              {" "}
              Email Subscriptions
            </h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex justify-center my-4 flex-1">
          <div class="rounded-t-xl overflow-hidden border border-b-gray border-t-white border-r-white border-l-white p-10 w-full ">
            <table class="table-auto w-full">
              <thead>
                <tr className={window.innerWidth < 720 ? "hidden" : ""}>
                  <th class="px-4 py-2">Email</th>
                  <th class="px-4 py-2">Channel</th>
                  <th class="px-4 py-2">Member</th>
                  <th class="px-4 py-2">Date</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.map((subscription) => {
                  return (
                    <tr
                      className={window.innerWidth < 820 ? "flex flex-col" : ""}
                    >
                      <td class="border border-sky-500 px-4 py-2 text-gray-200 font-medium">
                        <h3 className="font-bold md:hidden">Email</h3>
                        {subscription.email}
                      </td>
                      <td class="border border-sky-500 px-4 py-2 text-gray-200 font-medium">
                        <h3 className="font-bold md:hidden">Channel</h3>
                        {subscription.channel == "platform_updates"
                          ? "Platform Update"
                          : "Information Page"}
                      </td>

                      <td class="border border-sky-500 px-4 py-2 text-gray-200 font-medium">
                        <h3 className="font-bold md:hidden">Member</h3>
                        {subscription.user_id ? "Yes" : "No"}
                      </td>
                      <td class="border border-sky-500 px-4 py-2 text-gray-200 font-medium">
                        <h3 className="font-bold md:hidden">Date</h3>
                        {subscription.created_at}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        {!subscriptions ||
          (subscriptions < 1 && (
            <div className="w-full  flex justify-center items-center flex-1">
              <p className="text-white">We found 0 Email Subscriptions</p>
            </div>
          ))}
      </div>
    </>
  );
}
