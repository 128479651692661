import BasicButton from "../../../../components/buttons/basic-button";
import {
  authUserData,
  isAuthUser,
  isAdmin,
  isReader,
  API_BASE,
  BASE_URL,
} from "../../../../Utils/helpers";
import { useState, useEffect } from "react";
import WebHeader from "../../../../pages/web/header";
import Footer from "../../../../layout/footer";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BookSliderGrid from "../../../../components/slider/profile/book-slider-grid";
import BookSliderGridReader from "../../../../components/slider/book-slider-grid-reader";

export const PublisherProfile = () => {
  const [publisher, setPublisher] = useState({});
  const [platformModalOpen, setPlatformModalOpen] = useState(false);
  const [signupSuccess, setShowSignupSuccess] = useState(false);
  const [books, setBooks] = useState([]);
  const { publisheruuid } = useParams();
  const [activeTab, setactActiveTab] = useState(0);
  const [fileSelected, setFileSelected] = useState();
  const [profileText, setProfileText] = useState("");

  const getPublisher = () => {
    const url = API_BASE + "/api/publisher/" + publisheruuid;

    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setPublisher(response.data.publisher);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const getBook = () => {
    const url = API_BASE + "/api/books";

    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setBooks(response.data.books);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const trackPublisher = (publisherId, trackerId) => {
    const url = API_BASE + "/api/track";

    // console.log("publisherId", publisherId);
    // console.log("trackerId", trackerId);

    axios
      .post(url, { publisherId: publisherId, trackerId: trackerId })
      .then((response) => {
        if (response.status == 200) {
          getPublisher();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const untrackPublisher = (publisherId, trackerId) => {
    const url = API_BASE + "/api/untrack";

    axios
      .post(url, { publisherId: publisherId, trackerId: trackerId })
      .then((response) => {
        if (response.status == 200) {
          getPublisher();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const isTrackingPublisher = (owner) => {
    if (owner.trackers && isAuthUser()) {
      const isTracking = owner.trackers.filter(
        (t) => t.tracker_id == authUserData().id
      );
      return isTracking.length ? true : false;
    }

    return false;
  };

  useEffect(() => {
    getPublisher();
  }, []);

  useEffect(() => {
    if (publisher.name) {
      setProfileText(publisher.profile_text);
      if (publisher.profile_photo)
        setFileSelected(
          BASE_URL + "/storage" + publisher.profile_photo.split("public")[1]
        );
    }
  }, [publisher]);
  return (
    <>
      {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
      <WebHeader />

      <main class="bg-gray-100 bg-opacity-25 pt-0">
        <div class="lg:w-8/12 lg:mx-auto mb-8">
          <BookSliderGrid publisherId={publisheruuid} />
        </div>
      </main>

      <Footer />
    </>
  );
};

export default PublisherProfile;
