import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BasicButton from "../../../../../components/buttons/basic-button";
import LoadingProgress from "../../../../publisher/rich-page/components/loading-progress";
import { useSupplierApplicationsStore } from "../../../../../stores/supplier-applications-store";
import authRequestService from "../../../../../services/HttpRequests/authenticated-request-services";
import DesktopAdminApplicantTable from "./desktop-table";
import MobileAdminApplicantTable from "./mobile-table";
import AdminSupplierPhotoViewerModal from "./photo-viewer";
import CommentBox from "./comment-modal";

import {
  isAdmin,
  API_BASE,
  BASE_URL,
  isResponsiveMode,
  authUserData,
} from "../../../../../Utils/helpers";
import { toast } from "react-toastify";
export default function AdminSupplierApplicant() {
  const [processing, setProcessing] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const { user_id } = useParams();

  const navigate = useNavigate();
  const mailStore = useSupplierApplicationsStore((state) => state);

  const downloadVideo = (video, userHandle) => {
    //alert(Config.backendUrl())
    axios({
      url: BASE_URL + "/storage" + video.split("public")[1],
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fURL = document.createElement("a");

        fURL.href = fileURL;
        fURL.setAttribute("download", userHandle + "." + video.split(".")[1]);
        document.body.appendChild(fURL);

        fURL.click();
      })
      .catch(() => {
        console.log("handle server error from here");
      });
  };

  const getApplicant = () => {
    mailStore.setData([]);
    setProcessing(true);
    const url =
      API_BASE + `/admin/supplier/applicant/${user_id}/application/retrieve`;
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          mailStore.setApplicationData(response.data.application);
          setProcessing(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const processApplication = () => {
    setProcessing(true);
    const url = API_BASE + `/admin/supplier/application/process`;
    authRequestService()
      .post(url, {
        status: mailStore.selectedApplicantStatus,
        comment: mailStore.comment,
        user_id: user_id,
      })
      .then((response) => {
        if (response.status == 200) {
          setProcessing(false);
          getApplicant();
          closeCommentModal();
          mailStore.setSelectedApplicantStatus("");
          toast("Processed", { type: "success" });
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const preProcessApplication = () => {
    console.log(mailStore.selectedApplicantStatus);
    if (
      mailStore.selectedApplicantStatus == "redo_visual_verification" ||
      mailStore.selectedApplicantStatus == "redo_business_verification" ||
      mailStore.selectedApplicantStatus == "redo_contract_verification" ||
      mailStore.selectedApplicantStatus == "redo_supplier_verification"
    ) {
      openCommentModal();
    }
  };

  const openCommentModal = () => {
    setShowCommentModal(true);
  };
  const closeCommentModal = () => {
    setShowCommentModal(false);
  };

  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
    getApplicant();
  }, []);

  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">Applicant Page</h1>
          </div>
          <div className="flex justify-between gap-2 items-start">
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            {isResponsiveMode() ? (
              <MobileAdminApplicantTable
                processing={processing}
                openCommentModal={openCommentModal}
                preProcessApplication={preProcessApplication}
                processApplication={processApplication}
                downloadVideo={downloadVideo}
              />
            ) : (
              <DesktopAdminApplicantTable
                processing={processing}
                openCommentModal={openCommentModal}
                preProcessApplication={preProcessApplication}
                processApplication={processApplication}
                downloadVideo={downloadVideo}
              />
            )}
            <LoadingProgress loading={processing} />
          </div>
        </div>
      </div>
      <CommentBox
        modalOpen={showCommentModal}
        closeModal={closeCommentModal}
        processApplication={processApplication}
        processing={processing}
      />
    </>
  );
}
