import EditorTooltip from "../../tooltip/editor-tooltip";
export default function FutureAvailability(props) {
  const { productMeta, handleMetaChange } = props;
  return (
    <div className="w-full md:w-6/12 my-5 flex flex-col items-center justify-center relative">
      <div className="inline-flex gap-1">
        {" "}
        <span className="font-bold">Future Availability</span>
        <EditorTooltip
          body={
            "This feature allows a product supplier to set how far in the future customers  can start their subscriptions."
          }
          top={"-175px"}
          right={"-25px"}
        />
      </div>
      <div className="flex gap-2">
        <select
          value={productMeta?.future_availability}
          onChange={(e) =>
            handleMetaChange(e, "future_availability", "dropdown")
          }
          required
          class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
        >
          <option value="">Not Selected</option>
          <option value={"30"}>1 month (30 Days)</option>
          <option value={"60"}> 2 months (60 Days)</option>
          <option value={"90"}>3 months (90 Days)</option>
          <option value={"120"}> 4 months (120 Days)</option>
          <option value={"150"}>5 months (150 Days)</option>
          <option value={"180"}> 6 months (180 Days)</option>
          <option value={"210"}> 7 months (210 Days)</option>
          <option value={"240"}> 8 months (240 Days)</option>
          <option value={"270"}> 9 months (270 Days)</option>
          <option value={"300"}> 10 months (300 Days)</option>
          <option value={"330"}>11 months (330 Days)</option>
          <option value={"360"}>12 months (360 Days)</option>
        </select>
        {!productMeta?.future_availability && (
          <span className="text-red-500 text-2xl">*</span>
        )}
      </div>
    </div>
  );
}
