import axios from "axios";
import { useEffect, useState } from "react";
import BasicButton from "../../../components/buttons/basic-button";
import { isAdmin, API_BASE } from "../../../Utils/helpers";
export default function AdminPubSubscriptions() {
  const [subscriptions, setSubscriptions] = useState([]);
  const url = API_BASE + "/api/subscriptions";
  const getSubscriptions = () => {
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setSubscriptions(response.data.data);
        }
      })
      .catch((error) => {
        alert(error.message);
        console.error("There was an error!", error);
      });
  };

  useEffect(() => {
    getSubscriptions();
    if (!isAdmin()) {
      window.location.href = "/";
    }
  }, []);
  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">Subscriptions</h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            <table class="table-auto w-full">
              <thead>
                <tr className={window.innerWidth < 720 ? "hidden" : ""}>
                  <th class="px-4 py-2 text-gray-600">Email</th>
                  <th class="px-4 py-2 text-gray-600">Channel</th>
                  <th class="px-4 py-2 text-gray-600">Member</th>
                  <th class="px-4 py-2 text-gray-600">Date</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.map((subscription) => {
                  return (
                    <tr
                      className={window.innerWidth < 820 ? "flex flex-col" : ""}
                    >
                      <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <h3 className="font-bold md:hidden">Email</h3>
                        {subscription.email}
                      </td>
                      <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <h3 className="font-bold md:hidden">Channel</h3>
                        {subscription.channel == "platform_updates"
                          ? "PLatform Update"
                          : "Information Page"}
                      </td>

                      <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <h3 className="font-bold md:hidden">Member</h3>
                        {subscription.user_id ? "Yes" : "No"}
                      </td>
                      <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <h3 className="font-bold md:hidden">Date</h3>
                        {subscription.created_at}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
