import axios from "axios";
import { API_BASE } from "../../Utils/helpers";
import { authToken } from "../../Utils/helpers";
import { getLocation } from "../../Utils/helpers";
import { toast } from "react-toastify";
// const axios = require('axios');

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.
const customAxios = axios.create({
  baseURL: API_BASE + getLocation().hostname == "litzapp.com" ? "" : "/api/",
  timeout: 10000,
});

const authRequestService = () => {
  // Step-3: Configure/make use of request & response interceptors from Axios
  // Note: You can create one method say configureInterceptors, add below in that,
  // export and call it in an init function of the application/page.
  customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
  );

  customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
  );

  return customAxios;
};

// Step-2: Create request, response & error handlers
const requestHandler = (request) => {
  // Token will be dynamic so we can use any app-specific way to always
  // fetch the new token before making the call
  request.headers.Authorization = `Bearer ${authToken()}`;

  return request;
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    localStorage.removeItem("platform");
    window.location = "/login";
  }
  return Promise.reject(error);
};

// Step-4: Export the newly created Axios instance to be used in different locations.
export default authRequestService;
