import { useNavigate } from "react-router-dom";
export default function AdminMobileTable({ data }) {
  const getSelectorContext = (a, b, c, d) => {
    return Number(a) + Number(b) + Number(c) + Number(d);
  };
  return (
    <>
      <div className="w-full flex flex-col justify-center my-4 text-center">
        {data.data
          ? data.data.map((p, i) => {
              return (
                <div
                  className="overflow-x-scroll inline"
                  style={{
                    width: document.documentElement.clientWidth - 90 + "px",
                    margin: "0 auto",
                  }}
                >
                  <div className="flex">
                    <div class="border  px-4 py-2  font-medium text-center flex flex-col gap-2">
                      <div>
                        <p className="font-bold">Item Title:&nbsp;</p>
                        <span className="my-text-yellow-500 font-bold">
                          {p?.name}{" "}
                        </span>
                      </div>
                      <div>
                        {" "}
                        <p className="font-bold">Item ID:&nbsp;</p>
                        <span className="">{p?.item_id} </span>
                      </div>
                      <div>
                        <p className="font-bold">Publisher Name:&nbsp;</p>
                        <span className="">{p?.owneroptimized?.name} </span>
                      </div>
                      <div>
                        {" "}
                        <p className="font-bold">User Handle:&nbsp;</p>
                        <span className="">
                          {" "}
                          {p?.owneroptimized?.handle_id}{" "}
                        </span>
                      </div>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:hidden">
                      <h3 className="font-bold md:hidden text-xxs">
                        Sort Score
                      </h3>
                      {getSelectorContext(
                        p.is_recent,
                        p.avg_count,
                        p.t_views_count,
                        p.product_subscribers_count
                      )}
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:hidden">
                      <p className="font-bold text-xxs">Recency</p>
                      <br /> <br />
                      <p className="font-bold  text-xxs">Date Created</p>
                      {p.refactoredCreatedAtTime}
                      <br /> <br />
                      <p className="font-bold  text-xxs">Sort Score</p>
                      {p.is_recent}
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:hidden">
                      <p className="font-bold text-xxs">Rating</p>
                      <br /> <br />
                      <p className="font-bold  text-xxs">Average Rating:</p>
                      {p.average_rating_count}
                      <br /> <br />
                      <p className="font-bold  text-xxs">Sort Score</p>
                      {p.avg_count}
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:hidden">
                      <p className="font-bold text-xxs">Views</p>
                      <br /> <br />
                      <p className="font-bold  text-xxs">Total Views:</p>
                      {p.total_sort_views_count}
                      <br /> <br />
                      <p className="font-bold  text-xxs">Sort Score</p>
                      {p.t_views_count}
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:hidden">
                      <p className="font-bold text-xxs">Purchase</p>
                      <br /> <br />
                      <p className="font-bold  text-xxs">Purchase Count:</p>
                      {p.total_sort_purchase_count}
                      <br /> <br />
                      <p className="font-bold  text-xxs">Sort Score</p>
                      {p.product_subscribers_count}
                    </div>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    </>
  );
}
