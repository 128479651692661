import Normal from "./Normal.png";
// import Selected from "./Selected.png";
export default function IndentRightIcon(props) {
  const { selected } = props;

  const iconParentStyle = {
    height: 55,
    width: 55,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div
      style={{
        ...iconParentStyle,
      }}
      className="bg-gray-50 px-1 mx-1 rounded-lg cursor-pointer"
      onClick={props.handleClick}
    >
      <img src={Normal} style={{ height: 24, width: 24 }} />
    </div>
  );
}
