import YouTube from "react-youtube";
import { useState } from "react";
import getVideoId from "get-video-id";
import TextField from "../../../../../../../../../components/inputs/text-input";
import BasicButton from "../../../../../../../../../components/buttons/basic-button";
import { isResponsiveMode } from "../../../../../../../../../Utils/helpers";
import InsertVideo from "./components/insert-video";

export default function YoutubeLinkVideoBox(props) {
  const { insertYoutubeVideo } = props;
  const { handleVideoLinkChange } = props;
  const { videoFileSelected } = props;
  const { handleVideoPreview } = props;
  const { resetVideoPreview } = props;
  const { resetVideoLinkPreview } = props;
  const { videoLinkValue } = props;
  const { processing } = props;

  const [linkMode, setLinkMode] = useState(true);

  const _onReady = (e) => {
    // access to player in all event handlers via event.target
    e.target.pauseVideo();
  };
  const opts = {
    width: isResponsiveMode() ? "100%" : "500px",
    height: "250px",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const youtubeVideoIdExists = (url) => {
    if (!url) {
      return false;
    }

    let { id, service } = getVideoId(url);

    if (!id || service !== "youtube") {
      return false;
    }

    return getVideoId(url);
  };

  return (
    <div class="px-4">
      {linkMode ? (
        <div
          className={`my-2 w-full border flex flex-col items-center justify-center bg-gray-50 p-2 ${
            !videoLinkValue ? "h-48" : ""
          }`}
        >
          <h5 className="font-bold">YoutubeLink</h5>
          <div className="w-full relative mx-2">
            {" "}
            <i
              class="fa fa-times-circle text-black z-50 z-50 text-2xl absolute right-1 -top-0 z-40 cursor-pointer"
              onClick={() => resetVideoLinkPreview()}
            ></i>
            <TextField
              placeholder={"Paste Link..."}
              classes="rounded-lg pr-8"
              handleChange={handleVideoLinkChange}
              value={videoLinkValue}
            />
          </div>

          {videoLinkValue && youtubeVideoIdExists(videoLinkValue) ? (
            <div className="p-2">
              {" "}
              <YouTube
                videoId={youtubeVideoIdExists(videoLinkValue).id}
                opts={opts}
                onReady={_onReady}
              />
            </div>
          ) : (
            ""
          )}
          {!youtubeVideoIdExists(videoLinkValue) ? (
            <p className="text-red-700 my-2">
              Please paste a valid YouTube link
            </p>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {!linkMode && !videoFileSelected ? (
        <div className="my-6 w-full border flex flex-col items-center justify-center bg-gray-50 h-48">
          <i class="fas fa-video text-5xl"></i>
          <InsertVideo previewVideo={handleVideoPreview} />
        </div>
      ) : (
        ""
      )}

      {!linkMode && videoFileSelected ? (
        <div className="my-6 border flex flex-col items-center justify-center bg-gray-50 p-2  relative">
          <span
            className="rounded rounded-full p-0 right-0 absolute px-1 cursor-pointer"
            style={{ top: -15, right: -5 }}
            onClick={() => resetVideoPreview()}
          >
            <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
          </span>
          <video
            controls="controls"
            className="w-full"
            src={videoFileSelected}
            type="video/mp4"
            height="200px"
          ></video>
        </div>
      ) : (
        ""
      )}

      <div className="flex justify-center">
        <BasicButton
          disabled={
            (!youtubeVideoIdExists(videoLinkValue) && !videoFileSelected) ||
            processing
          }
          title={processing ? "Uploading..." : "Upload"}
          classes="px-8 w-8/12"
          handleClick={() => insertYoutubeVideo()}
        />
      </div>

      {/* modal body */}
    </div>
  );
}
