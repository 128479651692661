import axios from "axios";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import ReCAPTCHA from "react-google-recaptcha";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import BasicButton from "../../../../components/buttons/basic-button";
import authRequestService from "../../../../services/HttpRequests/authenticated-request-services";
import {
  isAuthUser,
  API_BASE,
  isSales,
  authUserData,
} from "../../../../Utils/helpers";
import Countries from "../../../../components/country/country";
import Timezones from "../../../../components/timezone/timezone";

export default function CreateCustomerServiceSet() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [processing, setProcessing] = useState(false);

  const captchaRef = useRef(null);
  const naviagte = useNavigate();

  const createSet = () => {
    setProcessing(true);
    const url = API_BASE + "/publisher/supplier/my-customer-services/create";
    authRequestService()
      .post(url, {
        name: name,
        email: email,
        phone: phone,
      })
      .then((response) => {
        setProcessing(false);
        setTimeout(() => {
          if (response?.data?.set)
            window.location.href = `/publisher/supplier/my-customer-services/set/${response?.data?.set?.uuid}/edit`;
        }, 500);
      })
      .catch((error) => {
        if (error.response.status == 422) {
          if ("duplicate_email" in error.response.data) {
            toast("Sorry, the provided Email Address is already added!", {
              type: "error",
            });
            setProcessing(false);
            return false;
          }
          if ("duplicate_email_non_user" in error.response.data) {
            toast(
              "Sorry, the provided Email Address belongs to another user!",
              {
                type: "error",
              }
            );
            setProcessing(false);
            return false;
          }
          if ("duplicate_phone" in error.response.data) {
            toast("Sorry, the provided Phone Number is already added!", {
              type: "error",
            });
            setProcessing(false);

            return false;
          }
          if ("duplicate_phone_non_user" in error.response.data) {
            toast("Sorry, the provided Phone Number belongs to another user!", {
              type: "error",
            });
            setProcessing(false);

            return false;
          }
        } else {
          toast("Something went wrong!", { type: "error" });
        }
        setProcessing(false);
      });
  };

  const handleNameChange = (e) => {
    setName(e);
  };

  const handleEmailChange = (e) => {
    setEmail(e);
  };

  const handlePhoneChange = (e) => {
    setPhone(e);
  };

  useEffect(() => {
    // if (!isSales()) {
    //   window.location.href = "/";
    // }
  }, []);

  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 bold">Create Set</h1>
        </div>
        <div className="flex gap-2 items-start">
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>

      <div class="container px-6 mx-auto">
        <br />
        <div class="flex flex-col text-center md:text-left justify-evenly items-center">
          <div class="flex flex-col w-full items-center">
            <h1 class="text-2xl text-gray-800 font-bold my-2">Add</h1>
            <p class="mx-auto md:mx-0 text-gray-500">
              Please fill out the form
            </p>
          </div>
          <div className={`w-full md:w-6/12 mx-auto md:mx-0`}>
            <div class="bg-white p-10 flex flex-col w-full shadow-xl rounded-xl">
              {/* <h2 class="text-2xl font-bold text-gray-800 text-left mb-5">
                Sigin
              </h2> */}
              <form action="" class="w-full">
                <div id="input" class="flex flex-col w-full my-5">
                  <label for="username" class="text-gray-500 mb-2">
                    Name
                  </label>
                  <input
                    onChange={(e) => handleNameChange(e.target.value)}
                    type="text"
                    id="username"
                    value={name}
                    placeholder="Please insert Name"
                    class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                  />
                </div>

                <div id="input" class="flex flex-col w-full my-5">
                  <label for="username" class="text-gray-500 mb-2">
                    Email
                  </label>
                  <input
                    onChange={(e) => handleEmailChange(e.target.value)}
                    type="text"
                    id="username"
                    value={email}
                    placeholder="Please insert your email"
                    class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                  />
                </div>

                <div id="input" class="flex flex-col w-full my-5">
                  <label for="username" class="text-gray-500 mb-2">
                    Phone
                  </label>
                  <PhoneInput
                    containerClass="react-input-customer-service"
                    country={"eg"}
                    enableSearch={true}
                    value={phone}
                    onChange={(phone) => handlePhoneChange(phone)}
                  />
                </div>

                <div id="button" class="flex flex-col w-full my-5">
                  <button
                    disabled={processing || !name || !email || !phone}
                    onClick={() => createSet()}
                    type="button"
                    class={`w-full py-4 ${
                      processing || !name || !email || !phone
                        ? "bg-gray-500"
                        : "bg-black"
                    }  rounded-lg text-green-100`}
                  >
                    {!processing ? (
                      <div class="flex flex-row items-center justify-center">
                        <div class="mr-2">
                          <svg
                            class="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                            ></path>
                          </svg>
                        </div>
                        <div class="font-bold">Create</div>
                      </div>
                    ) : (
                      "Creating..."
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
