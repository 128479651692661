import BasicButton from "../../../../components/buttons/basic-button";
import { isResponsiveMode } from "../../../../Utils/helpers";
export default function AdminMobileTable({ data, f }) {
  const { adsEnabled, dataSubType, totalEstAdsEarnings } = f;

  return (
    <>
      <div className="w-full flex flex-col justify-center my-4 text-center">
        {data.data
          ? data.data.map((p, i) => {
              return (
                <div
                  className="overflow-x-scroll inline"
                  style={{
                    width: document.documentElement.clientWidth - 90 + "px",
                    margin: "0 auto",
                  }}
                >
                  <div className="flex">
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">
                        Date Created
                      </h3>
                      {p.simpleUserCreatedDate}
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">
                        Date Publisher Status
                      </h3>
                      <span className="break-words">
                        {p.simpleVerificationDate}
                      </span>
                    </div>
                    <div class="border  px-4 py-2 font-medium text-center md:text-left">
                      <p className="break-words">
                        <p className="text-green-500"> First Name</p>
                        <small className="text-base"> {p.first_name}</small>
                      </p>
                      <p className="break-words">
                        <p className="text-green-500"> Last Name</p>
                        <small className="text-base"> {p.last_name}</small>
                      </p>
                      <p className="break-words">
                        <p className="text-green-500"> Username</p>
                        <small className="text-base"> {p.username}</small>
                      </p>
                      <p className="break-words">
                        <p className="text-green-500"> User Handle</p>
                        <small className="text-base"> {p.handle_id}</small>
                      </p>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left w-full">
                      <h3 className="font-bold md:hidden text-xxs">Email</h3>
                      <span className="break-words">{p?.email ?? "N/A"}</span>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left w-full">
                      <h3 className="font-bold md:hidden text-xxs">Phone</h3>
                      <span className="break-words">
                        {p?.verifiedphone?.phone ?? "N/A"}
                      </span>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">Country</h3>
                      <span className="break-words">{p?.country ?? "N/A"}</span>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">Timezone</h3>
                      <span className="break-words">
                        {p?.timezone ?? "N/A"}
                      </span>
                    </div>

                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">Items</h3>
                      <a
                        href={`/admin/publisher/publications/options/${p.uuid}`}
                      >
                        <BasicButton
                          classes={"bg-black-500 w-full"}
                          title={p.books_count}
                          handleClick={null}
                        />
                      </a>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">
                        Ads Enabled
                      </h3>
                      <span className="break-words">
                        <p> {adsEnabled(p).enabled}</p>
                        <p>{adsEnabled(p).provider}</p>
                      </span>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">
                        Total Data Used
                      </h3>
                      <span className="break-words">
                        {p.active_data_usage} GB
                      </span>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">
                        Data Threshold Tier
                      </h3>
                      <span className="break-words">{dataSubType(p)}</span>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">
                        Total Ads Earnings
                      </h3>
                      <span className="break-words">
                        {totalEstAdsEarnings(p)}
                      </span>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">
                        Total Payment Earnings
                      </h3>
                      <span className="break-words">
                        {p.total_payment_earning}
                      </span>
                    </div>
                    <div class="border px-4 py-2 font-medium text-center">
                      <div className="flex flex-col items-center gap-2">
                        <h3 className="font-bold md:hidden text-xxs">
                          Total Purchase Earnings
                        </h3>
                        <div>
                          <span className="text-green-500">Amount</span>
                          <p className="text-base">
                            $ {p?.total_publisher_payment_record?.sum}
                          </p>
                        </div>
                        <div>
                          <span className="text-green-500">
                            Total number of purchases
                          </span>
                          <p>{p?.total_publisher_payment_record?.count}</p>
                        </div>
                      </div>
                    </div>
                    <div class="border px-4 py-2  font-medium text-center ">
                      <div className="flex flex-col items-center gap-2">
                        <h3 className="font-bold md:hidden text-xxs">
                          Total Subscription Earnings
                        </h3>
                        <div>
                          <span className="text-green-500">Amount</span>
                          <p>$ {p?.total_publisher_subscription_record?.sum}</p>
                        </div>
                        <div>
                          <span className="text-green-500">
                            Total number of Subscribers
                          </span>
                          <p>{p?.total_publisher_subscription_record?.count}</p>
                        </div>
                      </div>
                    </div>
                    <div class="border px-4 py-2  font-medium text-center ">
                      <div className="flex flex-col items-center gap-2">
                        <h3 className="font-bold md:hidden text-xxs">
                          Total Hire Purchase Earnings
                        </h3>
                        <div>
                          {" "}
                          <span className="text-green-500">Amount</span>
                          <p>
                            $ {p?.total_publisher_hire_purchase_record?.sum}
                          </p>
                        </div>
                        <div>
                          {" "}
                          <span className="text-green-500">
                            Total number of Hire Purchases
                          </span>
                          <p>
                            {p?.total_publisher_hire_purchase_record?.count}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center ">
                      <div className="flex flex-col items-center gap-2">
                        <h3 className="font-bold md:hidden text-xxs">
                          Total Donation Earnings
                        </h3>{" "}
                        <div>
                          <span className="text-green-500">Amount</span>
                          <p>
                            $ {p?.total_publisher_donation_record?.sum}
                          </p>{" "}
                        </div>
                        <div>
                          <span className="text-green-500">
                            Total number of Donors
                          </span>
                          <p>{p?.total_publisher_donation_record?.count}</p>{" "}
                        </div>
                      </div>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">Clicks</h3>
                      <span className="break-words">{p.clicks}</span>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">
                        Impressions
                      </h3>
                      <span className="break-words">{p.imps}</span>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">
                        CTI Ratio
                      </h3>
                      <span className="break-words">
                        <p>{Number(p.clicks) / Number(p.imps)}</p>{" "}
                      </span>
                    </div>

                    <div className="border  px-4 py-2  font-medium text-center md:text-left">
                      <div>
                        <span className="text-green-500">Total Views</span>
                        <p>{p.total_content_bot_cti.total_views}</p>{" "}
                      </div>
                    </div>

                    <div className="border  px-4 py-2  font-medium text-center md:text-left">
                      <div>
                        <span className="text-green-500">Total Bot Count</span>
                        <p>{p.total_content_bot_cti.total_bot_count}</p>{" "}
                      </div>
                    </div>

                    <div className="border  px-4 py-2  font-medium text-center md:text-left">
                      <div>
                        <span className="text-green-500">Total BTV Ratio</span>
                        <p>{p.total_content_bot_cti.cti_ratio}</p>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    </>
  );
}
