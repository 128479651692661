import { toast } from "react-toastify";
import {
  INLINE_STYLES,
  COLOR_TYPES,
  BLOCK_TYPES,
  PRESET_COLORS,
  FONT_EXPORT_CLASS_MAPPING,
} from "../editor-objects";
import { CompositeDecorator } from "draft-js";
import LinkComponent from "../components/link-component";
import ColorPicker, { colorPickerPlugin } from "draft-js-color-picker";
import { Modifier, RichUtils } from "draft-js";
import { isResponsiveMode } from "../../../../Utils/helpers";
import { deleteEditorImageRequest } from "../API/editor-api-calls";
import { getDefaultKeyBinding, KeyBindingUtil } from "draft-js";
const { hasCommandModifier } = KeyBindingUtil;

const createExportCompatibleFontMapping = () => {
  const mappedFonts = [];
  Object.keys(COLOR_TYPES).map((font) => {
    mappedFonts.push({
      [font]: {
        element: "span",
        style: { fontSize: COLOR_TYPES[font].fontSize + "px" },
      },
    });

    //console.log("FONTS", JSON.stringify(mappedFonts));
  });
  return Object.assign({}, mappedFonts);
};

const createExportCompatibleColorMapping = () => {
  const mappedFonts = [];
  Object.keys(PRESET_COLORS).map((color) => {
    mappedFonts.push({
      [color]: {
        element: "span",
        style: { color: PRESET_COLORS[color] },
      },
    });

    //console.log("FONTS", JSON.stringify(mappedFonts));
  });
  return Object.assign({}, mappedFonts);
};

export const htmlRenderOptions = (picker, editorState) => {
  return {
    inlineStyles: {
      ...getColorMappings(picker, editorState),
      ...FONT_EXPORT_CLASS_MAPPING,
      //   BOLD: {
      //     element: "b",
      //     attributes: { class: "foo" },
      //   },
      ITALIC: {
        // Add custom attributes. You can also use React-style `className`.
        attributes: { class: "foo" },
        // Use camel-case. Units (`px`) will be added where necessary.
        //style: { fontSize: 12 },
      },
      premium: {
        style: {
          color: "transparent",
          textShadow: "0 0 8px rgba(0,0,0,0.5)",
          userSelect: "none",
        },
        attributes: { class: "monetizedappq" },
      },
    },
    entityStyleFn: (entity) => {
      const entityType = entity.get("type").toLowerCase();
      if (entityType === "advert") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "advert-block",
            adCode: data.adCode,
            systemAdCode: data.systemAdCode,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "signup_for_updates") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "signup-block###*",
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "video") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "video-box-xyz",
            src: data.src,
            linkMode: data.linkMode,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "button_link") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "button-link-xyz",
            name: data.buttonLinkName,
            link: data.link,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "hire_button") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "hire-button-xyz",
            hireText: data.hireText,
            replyEnabled: data.replyEnabled,
            hireReplySubject: data.hireReplySubject,
            hirePrice: data.hirePrice,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "product_meta") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "product-meta-xyz",
            payload: data.payload,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "slides") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "product-slides-xyz",
            payload: data.payload,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "banners") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "banners-xyz",
            payload: data.payload,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "social_link") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "social-link-xyz",
            payload: data.payload,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "social_embed") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "social-embed-xyz",
            payload: data.payload,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "table") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "table-xyz",
            payload: data.payload,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "accordion") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "accordion-xyz",
            payload: data.payload,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "image") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "image-link-xyz",
            src: data.src,
            imageLink: data.imageLink,
          },
          style: {
            // Put styles here...
          },
        };
      }
    },
  };
};

const getColorMappings = (picker, editorState) => {
  const mappedColors = picker.exporter(editorState);
  let colors = {};
  Object.keys(mappedColors).map((color) => {
    if (color.includes("CUSTOM_COLOR_rgba")) {
      colors[color] = {};
      colors[color]["style"] = { color: mappedColors[color].style.color };
      //return { [color]: { color: mappedColors[color] } };
    }
  });

  return colors;
};

export const htmlRenderOptionsFree = (picker, editorState) => {
  return {
    inlineStyles: {
      ...getColorMappings(picker, editorState),
      ...FONT_EXPORT_CLASS_MAPPING,
      //   BOLD: {
      //     element: "b",
      //     attributes: { class: "font-bold" },
      //     style: { fontSize: 19 },
      //   },
      ITALIC: {
        // Add custom attributes. You can also use React-style `className`.
        attributes: { class: "foo" },
        // Use camel-case. Units (`px`) will be added where necessary.
        style: { fontSize: 12 },
      },
    },
    entityStyleFn: (entity) => {
      const entityType = entity.get("type").toLowerCase();
      if (entityType === "advert") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "advert-block",
            adCode: data.adCode,
            systemAdCode: data.systemAdCode,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "signup_for_updates") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "signup-block###*",
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "video") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "video-box-xyz",
            src: data.src,
            linkMode: data.linkMode,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "button_link") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "button-link-xyz",
            name: data.buttonLinkName,
            link: data.link,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "hire_button") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "hire-button-xyz",
            hireText: data.hireText,
            replyEnabled: data.replyEnabled,
            hireReplySubject: data.hireReplySubject,
            hirePrice: data.hirePrice,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "product_meta") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "product-meta-xyz",
            payload: data.payload,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "slides") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "product-slides-xyz",
            payload: data.payload,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "banners") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "banners-xyz",
            payload: data.payload,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "social_link") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "social-link-xyz",
            payload: data.payload,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "social_embed") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "social-embed-xyz",
            payload: data.payload,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "table") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "table-xyz",
            payload: data.payload,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "accordion") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "accordion-xyz",
            payload: data.payload,
          },
          style: {
            // Put styles here...
          },
        };
      }

      if (entityType === "image") {
        const data = entity.getData();
        return {
          element: "div",
          attributes: {
            className: "image-link-xyz",
            src: data.src,
            imageLink: data.imageLink,
          },
          style: {
            // Put styles here...
          },
        };
      }
    },
  };
};

export const changeIndent = (
  e,
  indentDirection,
  RichUtils,
  editorState,
  setEditorOutput,
  setEditorState,
  getDefaultKeyBinding,
  indentRef
) => {
  e.preventDefault();

  const blocksSelected = editorState.getSelection().toMap().toArray();
  if (blocksSelected[0] !== blocksSelected[2]) {
    toast(
      "Please note, you can only indent or outdent one line at a time, and this only works on bullet and numbered lists",
      {
        type: "notice",
      }
    );
  }

  if (!indentRef.hasIndented) {
    indentRef.hasIndented = true;
  }

  if (indentDirection && indentDirection === "increase") {
    e.shiftKey = true;
  }
  const currentBlockType = RichUtils.getCurrentBlockType(editorState);
  setEditorOutput(editorState.getSelection());

  if (
    currentBlockType === "ordered-list-item" ||
    currentBlockType === "unordered-list-item"
  ) {
    const newState = RichUtils.onTab(e, editorState, 4);
    if (newState) {
      setEditorState(newState);
      return "handled";
    } else {
      return "not-handled";
    }
  }

  return getDefaultKeyBinding(e);
};

export const shortenString = (str) => {
  if (str.length > 10) {
    return str.substring(0, 30);
  }
  return str;
};

export const insertSignupBoxIntoEditor = (
  adcode,
  editorState,
  AtomicBlockUtils,
  EditorState,
  setEditorState
) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    "signup_for_updates",
    "IMMUTABLE",
    { code: adcode }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });
  const newState = AtomicBlockUtils.insertAtomicBlock(
    newEditorState,
    entityKey,
    " "
  );
  setEditorState(newState);
};

export const insertButtonLink = (
  name,
  link,
  editorState,
  AtomicBlockUtils,
  EditorState,
  setEditorState
) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    "button_link",
    "IMMUTABLE",
    {
      buttonLinkName: name,
      link: link.indexOf("://") === -1 ? "http://" + link : link,
    }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });
  const newState = AtomicBlockUtils.insertAtomicBlock(
    newEditorState,
    entityKey,
    " "
  );
  setEditorState(newState);
};

export const insertAdvertIntoEditor = (
  adcode,
  systemAdCode,
  editorState,
  AtomicBlockUtils,
  EditorState,
  setEditorState
) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    "advert",
    "IMMUTABLE",
    {
      adCode: adcode,
      systemAdCode,
    }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });
  const newState = AtomicBlockUtils.insertAtomicBlock(
    newEditorState,
    entityKey,
    " "
  );
  setEditorState(newState);
};

export const insertHireIntoEditor = (
  hireText,
  hireReplySubject,
  replyEnabled,
  editorState,
  AtomicBlockUtils,
  EditorState,
  setEditorState
) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    "hire_button",
    "IMMUTABLE",
    {
      hireText: hireText,
      replyEnabled: replyEnabled,
      hireReplySubject: replyEnabled == true ? hireReplySubject : "",
    }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });
  const newState = AtomicBlockUtils.insertAtomicBlock(
    newEditorState,
    entityKey,
    " "
  );
  setEditorState(newState);
};

export const insertProductMetaIntoEditor = (
  payload,
  editorState,
  AtomicBlockUtils,
  EditorState,
  setEditorState
) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    "product_meta",
    "IMMUTABLE",
    {
      payload: payload,
    }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });
  const newState = AtomicBlockUtils.insertAtomicBlock(
    newEditorState,
    entityKey,
    " "
  );
  setEditorState(newState);
};

export const insertSlideIntoEditor = (
  payload,
  editorState,
  AtomicBlockUtils,
  EditorState,
  setEditorState
) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    "slides",
    "IMMUTABLE",
    {
      payload: payload,
    }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });
  const newState = AtomicBlockUtils.insertAtomicBlock(
    newEditorState,
    entityKey,
    " "
  );
  setEditorState(newState);
};

export const insertBannerIntoEditor = (
  payload,
  editorState,
  AtomicBlockUtils,
  EditorState,
  setEditorState
) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    "banners",
    "IMMUTABLE",
    {
      payload: payload,
    }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });
  const newState = AtomicBlockUtils.insertAtomicBlock(
    newEditorState,
    entityKey,
    " "
  );
  setEditorState(newState);
};

export const insertSocialEmbedIntoEditor = (
  payload,
  editorState,
  AtomicBlockUtils,
  EditorState,
  setEditorState
) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    "social_embed",
    "IMMUTABLE",
    {
      payload: payload,
    }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });
  const newState = AtomicBlockUtils.insertAtomicBlock(
    newEditorState,
    entityKey,
    " "
  );
  setEditorState(newState);
};

export const insertSocialImageLinksIntoEditor = (
  payload,
  editorState,
  AtomicBlockUtils,
  EditorState,
  setEditorState
) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    "social_link",
    "IMMUTABLE",
    {
      payload: payload,
    }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });
  const newState = AtomicBlockUtils.insertAtomicBlock(
    newEditorState,
    entityKey,
    " "
  );
  setEditorState(newState);
};

export const insertTableIntoEditor = (
  payload,
  editorState,
  AtomicBlockUtils,
  EditorState,
  setEditorState
) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    "table",
    "IMMUTABLE",
    {
      payload: payload,
    }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });
  const newState = AtomicBlockUtils.insertAtomicBlock(
    newEditorState,
    entityKey,
    " "
  );
  setEditorState(newState);
};

export const insertAccordionIntoEditor = (
  payload,
  editorState,
  AtomicBlockUtils,
  EditorState,
  setEditorState
) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    "accordion",
    "IMMUTABLE",
    {
      payload: payload,
    }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });
  const newState = AtomicBlockUtils.insertAtomicBlock(
    newEditorState,
    entityKey,
    " "
  );
  setEditorState(newState);
};

export const insertImageIntoEditor = (
  url,
  imageLink,
  imageId,
  editorState,
  AtomicBlockUtils,
  EditorState
) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    "image",
    "IMMUTABLE",
    { src: url, imageLink: imageLink, imageId: imageId }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });
  return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
};

export const insertVideoIntoEditor = (
  url,
  linkMode,
  editorState,
  AtomicBlockUtils,
  EditorState,
  videoId
) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    "video",
    "IMMUTABLE",
    { src: url, linkMode: linkMode, videoId: videoId }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });
  return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
};

export const deleteAd = (
  blockKey,
  editorState,
  setEditorState,
  EditorState
) => {
  const contentState = editorState.getCurrentContent();
  // this is the important one that actually deletes a block
  const newBlockMap = contentState.blockMap.delete(blockKey);
  const newContentState = contentState.set("blockMap", newBlockMap);
  const newEditorState = EditorState.push(
    editorState,
    newContentState,
    "remove-block"
  );
  // return newEditorState;
  setEditorState(newEditorState);
};

export const removeAtomicBlocFromEditor = (
  blockKey,
  editorState,
  setEditorState,
  EditorState
) => {
  const contentState = editorState.getCurrentContent();
  // this is the important one that actually deletes a block
  const newBlockMap = contentState.blockMap.delete(blockKey);
  const newContentState = contentState.set("blockMap", newBlockMap);
  const newEditorState = EditorState.push(
    editorState,
    newContentState,
    "remove-block"
  );
  // return newEditorState;
  setEditorState(newEditorState);
};

export const editEditorImaeAtBlockPosition = (
  blockKey,
  editorState,
  setEditorState,
  EditorState
) => {};

export const StyleButton = (props) => {
  const onToggle = (e) => {
    e.preventDefault();
    props.onToggle(props.style);
  };

  let style;

  return props.icon ? (
    <span style={style} onMouseDown={onToggle}>
      {props.icon}
    </span>
  ) : (
    <span style={style} onMouseDown={onToggle}>
      {props.label}
    </span>
  );
};

export const InlineStyleControls = (props) => {
  return (
    <div>
      {INLINE_STYLES.map((type) => (
        <button
          className="border border-gray rounded-lg p-1 m-1"
          onClick={() => props.onToggle(type.style)}
        >
          {!type.icon ? (
            <StyleButton
              key={type.label}
              label={type.label}
              style={type.style}
            />
          ) : (
            <StyleButton
              key={type.label}
              label={type.label}
              icon={type.icon}
              style={type.style}
            />
          )}
        </button>
      ))}
    </div>
  );
};

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

const strategyDecorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: LinkComponent,
  },
]);

export const insertLink = (
  editorState,
  currPastedLink,
  EditorState,
  setEditorState,
  setCurrPastedLink,
  hideLinkModal,
  RichUtils
) => {
  const contentState = editorState.getCurrentContent();

  if (currPastedLink) {
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      {
        url:
          currPastedLink.indexOf("://") === -1
            ? "http://" + currPastedLink
            : currPastedLink,
      }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    // Apply entity
    let nextEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
      decorator: strategyDecorator,
    });

    // Apply selection
    nextEditorState = RichUtils.toggleLink(
      nextEditorState,
      nextEditorState.getSelection(),
      entityKey
    );

    setEditorState(nextEditorState);
    setCurrPastedLink("");
    hideLinkModal();
  }
};

export const removeLink = (
  e,
  selectionAnchors,
  editorState,
  SelectionState,
  Modifier,
  setEditorState,
  EditorState
) => {
  if (selectionAnchors.start) {
    const content = editorState.getCurrentContent();
    const contentState = editorState.getCurrentContent();
    const startKey = editorState.getSelection().getStartKey();
    const block = content.getBlockForKey(startKey);
    //console.log(editorState);
    //console.log("REMOVE LINK BLOCK KEY", block.getKey());

    // You need to create a selection for entire length of text in the block
    const mySelection = SelectionState.createEmpty(block.getKey());
    const updatedSelection = mySelection.merge({
      anchorOffset: selectionAnchors.start,
      focusOffset: selectionAnchors.end,
    });

    const newContentState = Modifier.applyEntity(
      content,
      updatedSelection,
      null
    );
    // setEditorState(newContentState);

    setEditorState(
      EditorState.set(editorState, { currentContent: newContentState })
    );
    if (e) {
      e.preventDefault();
    }
  }
};

// removeLinkTest = (
//   selectionAnchors,
//   editorState,
//   SelectionState,
//   Modifier,
//   setEditorState,
//   EditorState
// ) => {in
//   const { editorState } = this.state;
//   const selection = editorState.getSelection();
//   if (!selection.isCollapsed()) {
//     this.setState({
//       editorState: RichUtils.toggleLink(editorState, selection, null),
//     });
//   }
// };

export const getCharacterLength = (selection, editorState) => {
  var selectionState = selection;
  var anchorKey = selectionState.getAnchorKey();
  var currentContent = editorState.getCurrentContent();
  var currentContentBlock = currentContent.getBlockForKey(anchorKey);
  var start = selectionState.getStartOffset();
  var end = selectionState.getEndOffset();
  var selectedText = currentContentBlock.getText().slice(start, end);

  return selectedText.length;
};

export const makePremium = (
  toggledColor,
  editorState,
  Modifier,
  EditorState,
  RichUtils,
  setEditorState
) => {
  // This object provides the styling information for our custom color
  const selection = editorState.getSelection();
  const blocksSelected = editorState.getSelection().toMap().toArray();

  //console.log("selected monetization blocks", blocksSelected);
  if (
    getCharacterLength(selection, editorState) < 50 &&
    blocksSelected[0] == blocksSelected[2]
  ) {
    toast("You can only monetize with a selection of 50 characters or more", {
      type: "error",
    });
    return false;
  }

  // Let's just allow one color at a time. Turn off all active colors.

  const nextContentState = Object.keys({ premium: COLOR_TYPES.premium }).reduce(
    (contentState, color) => {
      return Modifier.removeInlineStyle(contentState, selection, color);
    },
    editorState.getCurrentContent()
  );
  let nextEditorState = EditorState.push(
    editorState,
    nextContentState,
    "change-inline-style"
  );
  const currentStyle = editorState.getCurrentInlineStyle();
  // Unset style override for current color.

  if (selection.isCollapsed()) {
    nextEditorState = currentStyle.reduce((state, color) => {
      return RichUtils.toggleInlineStyle(state, color);
    }, nextEditorState);
  }
  // If the color is being toggled on, apply it.
  if (!currentStyle.has(toggledColor)) {
    nextEditorState = RichUtils.toggleInlineStyle(
      nextEditorState,
      toggledColor
    );
  }
  setEditorState(nextEditorState);
};

export const applyFont = (
  toggledColor,
  editorState,
  Modifier,
  EditorState,
  RichUtils,
  setEditorState
) => {
  const selection = editorState.getSelection();
  const nextContentState = Object.keys(COLOR_TYPES).reduce(
    (contentState, color) => {
      return Modifier.removeInlineStyle(contentState, selection, color);
    },
    editorState.getCurrentContent()
  );

  let nextEditorState = EditorState.push(
    editorState,
    nextContentState,
    "change-inline-style"
  );
  const currentStyle = editorState.getCurrentInlineStyle();
  if (selection.isCollapsed()) {
    nextEditorState = currentStyle.reduce((state, color) => {
      return RichUtils.toggleInlineStyle(state, color);
    }, nextEditorState);
  }
  // If the color is being toggled on, apply it.
  if (!currentStyle.has(toggledColor)) {
    nextEditorState = RichUtils.toggleInlineStyle(
      nextEditorState,
      toggledColor
    );
  }
  setEditorState(nextEditorState);
};

export const BlockStyleControls = (props) => {
  const selection = props.editorState.getSelection();
  const blockType = props.editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div>
      {BLOCK_TYPES.map((type) => (
        <button className="border border-gray rounded-lg p-1 m-1">
          {!type.icon ? (
            <StyleButton
              key={type.label}
              label={type.label}
              onToggle={props.onToggle}
              style={type.style}
              active={type.style === blockType}
            />
          ) : (
            <StyleButton
              key={type.label}
              label={type.label}
              icon={type.icon}
              onToggle={props.onToggle}
              style={type.style}
              active={type.style === blockType}
            />
          )}
        </button>
      ))}
    </div>
  );
};

export const BACKSPACE_ON_ATOMIC_BLOCK = "BACKSPACE_ON_ATOMIC_BLOCK";
export const ENTER_ON_ATOMIC_BLOCK = "ENTER_ON_ATOMIC_BLOCK";
export const DELETE_ON_ATOMIC_BLOCK = "DELETE_ON_ATOMIC_BLOCK";

export const atomicSelection = (editorState) => {
  const selectionKey = editorState.getSelection().getAnchorKey();
  const block = editorState.getCurrentContent().getBlockForKey(selectionKey);
  const start = editorState.getSelection().getStartOffset();
  const end = editorState.getSelection().getEndOffset();
  alert(start + "###" + end);
  return block.getType() === "atomic";
};

export const atomicKeyBindings = (editorState, e) => {
  const backSpace = 8;
  const enter = 13;
  const del = 46;
  return getDefaultKeyBinding(e);
  if (e.keyCode === backSpace) {
    if (atomicSelection(editorState)) return BACKSPACE_ON_ATOMIC_BLOCK;
  }
  if (e.keyCode === enter) {
    if (atomicSelection(editorState)) return ENTER_ON_ATOMIC_BLOCK;
  }

  if (e.keyCode === del) {
    if (atomicSelection(editorState)) return DELETE_ON_ATOMIC_BLOCK;
  }
};

export const atomicKeyCommands = (
  command,
  editorState,
  updateEditorState,
  productCanBeDeleted
) => {
  if (command === "backspace" || command === "enter" || command === "delete") {
    if (atomicSelection(editorState)) {
      return "handled";
      const selectionKey = editorState.getSelection().getAnchorKey();
      const block = editorState
        .getCurrentContent()
        .getBlockForKey(selectionKey);
      const contentState = editorState.getCurrentContent();
      const entity = contentState.getEntity(block.getEntityAt(0));
      if (entity.getType() == "product_meta") {
        const entityPayload = entity.getData("payload");
        const payload = JSON.parse(entityPayload.payload);
        return productCanBeDeleted(payload.block_meta_id);
        return "handled";
      } else {
        //do stuff for non product meta blocks
      }
    } else {
      return "not-handled";
    }
  }

  // if (command === ENTER_ON_ATOMIC_BLOCK) {
  //   return "not-handled";
  // }
};
