import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  isAdmin,
  API_BASE,
  isPublisher,
  BASE_URL,
} from "../../../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
import Switch from "react-switch";
import TextArea from "../../../components/inputs/text-area";
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
export default function DashboardPublisherProfile() {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [publisherId, setPublisherId] = useState("");
  const [fileSelected, setFileSelected] = useState("");
  const [processing, setProcessing] = useState(false);
  const [published, setPublished] = useState(false);
  const [profileText, setProfileText] = useState("");
  const [publisher, setPublisher] = useState({});
  const [shouldDeleteCoverPhoto, setShouldDeleteCoverPhoto] = useState(0);

  const url = API_BASE + "/api/book-create";
  let { userId } = useParams();
  let { seriesId } = useParams();
  let { type } = useParams();
  const previewImageRef = useRef();

  const getPublisher = () => {
    let url = API_BASE + "/api/publisher/" + userId;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setPublisher(response.data.publisher);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const updateUser = () => {
    const url = API_BASE + "/api/publisher/update";
    setProcessing(true);
    let formData = new FormData();
    formData.append("profile_text", profileText);
    formData.append("user_id", publisherId);
    formData.append("profile_photo", coverPhoto);
    formData.append("delete_profile_photo", shouldDeleteCoverPhoto);
    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          toast("Updated!", {
            type: "success",
          });
        }
      })
      .catch((error) => {
        toast("Something went wrong!", {
          type: "error",
        });
        setProcessing(false);
        // toast("Something went wrong!", { type: "error" });
      });
  };

  const uploadCover = (e) => {
    let [file] = e.target.files;
    if (file) {
      setFileSelected(URL.createObjectURL(file));
      previewImageRef.src = URL.createObjectURL(file);
      setCoverPhoto(file);
      console.log(file);
      //   previewImageRef.classList.remove("hidden");
      const image = getBase64(file);
      image.then((res) => {
        // console.log("My image", res);
      });
    }
  };

  const removeCover = () => {
    setFileSelected("");
    setCoverPhoto("");
    setShouldDeleteCoverPhoto(1);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleProfileTextChange = (text) => {
    setProfileText(text);
  };

  const getBookType = () => {
    if (type == "book") return "Book";
    if (type == "comic") return "Comic";
    if (type == "photo-album") return "Photo Album";
    if (type == "slides") return "Slides";
  };

  useEffect(() => {
    // alert(seriesId);
    setPublisherId(userId);
    if (!isPublisher()) {
      window.location.href = "/";
    }
    getPublisher();
  }, []);

  useEffect(() => {
    if (publisher.name) {
      //   alert(JSON.stringify(book));
      setProfileText(publisher.profile_text);
      if (publisher.profile_photo)
        setFileSelected(
          BASE_URL + "/storage" + publisher.profile_photo.split("public")[1]
        );
      //   alert(coverPhoto);
    }
  }, [publisher]);

  useEffect(() => {
    if (profileText) {
      console.log("editor text", profileText);
    }
  }, [profileText]);

  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">Profile</h1>
        </div>
        <div>
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-4 mt-8">
        <div className="w-8/12 md:w-6/12 bg-gray-50 p-4">
          {/* <div className="m-2 mb-6">
            <label className="text-black">Name</label>
            <TextField
              classes={"p-6 my-2"}
              placeholder={"Name.."}
              handleChange={handleNameChange}
            />
          </div>

          <div className="m-2 mb-8">
            <label className="text-black">Price (USD)</label>
            <TextField
              classes={"p-6 my-2"}
              placeholder={"Price.."}
              type="number"
              handleChange={handlePriceChange}
            />
          </div> */}
          {/* <div className="my-4 mx-4 text-center ">
            <br />
            <div className="flex justify-around">
              <label className="flex items-center ">
                <div>
                  {" "}
                  <span className="font-bold">
                    {published ? "Published" : "Draft"}
                  </span>
                </div>
              </label>
              <div className="flex items-center">
                <Switch onChange={handlePuublishStatus} checked={published} />
              </div>
            </div>
          </div>
          <hr /> */}
          <div className="m-2 text-center">
            <label className="text-black m-2">Profile Photo (Optional)</label>
            <div className="flex justify-center my-2">
              {!fileSelected && (
                <div className="w-32 h-32 bg-gray-100 p-2 rounded-full">
                  <div className="flex flex-col justify-center  h-full">
                    <i class="fa fa-plus cursor-pointer text-green-500"></i>
                    <p className="text-xs">Click to upload</p>
                    <p className="text-xs"> (jpeg, jpg, png)</p>
                    <label className="my-2">
                      <input
                        type="file"
                        onChange={(e) => uploadCover(e)}
                        class="text-sm 
                        text-grey-500
                        file:mr-2
                        file:py-2
                        file:px-2
                        file:rounded-full
                        file:border-0
                        file:text-md
                        file:font-semibold
                        file:text-white
                        file:bg-gradient-to-r
                        file:from-blue-600
                        file:to-amber-600
                        hover:file:cursor-pointer
                        hover:file:opacity-80"
                      />
                    </label>
                    {/* <input type="file" onChange={(e) => uploadCover(e)} /> */}
                  </div>
                </div>
              )}

              {fileSelected && (
                <div className="w-32 h-32 bg-gray-100 p-2 relative">
                  <img src={fileSelected} className="w-full rounded-full" />
                  <div
                    className="bg-white rounded rounded-full bg-white p-0 top-0 right-0 absolute px-1 cursor-pointer"
                    onClick={() => removeCover()}
                  >
                    <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
                  </div>
                </div>
              )}
            </div>
            {/* <TextField
              classes={"p-6"}
              placeholder={"Email.."}
              handleChange={handleEmailChange}
            /> */}
          </div>{" "}
          <label className=" m-2 text-black">Profile Text</label>
          <div className="m-2 mb-8 bg-white">
            {/* <Editor
              editorState={profileText}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              toolbar={{
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
              onEditorStateChange={handleProfileTextChange}
            /> */}

            <TextArea
              classes={"p-6 my-2"}
              placeholder={"Enter Text.."}
              type="text"
              handleChange={handleProfileTextChange}
              value={profileText}
            />
          </div>
          <div className="my-8 text-center">
            <br />
            <BasicButton
              disabled={(!profileText && !fileSelected) || processing}
              title={processing ? "Updating.." : "Update"}
              classes={"p-6 w-9/12 mt-4"}
              handleClick={updateUser}
            />
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
}
