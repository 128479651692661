import { deleteAd } from "../helpers/functions";
export default function Advert({
  blockKey,
  editorState,
  setEditorState,
  editorStateHelper,
}) {
  return (
    <div class="flex flex-wrap justify-center">
      <p className="bg-pink-100 p-2 rounded-lg relative">
        <span
          className="bg-white rounded rounded-full bg-white p-0  right-0 absolute px-1 cursor-pointer"
          style={{ top: -19, right: 0 }}
          onClick={() =>
            deleteAd(blockKey, editorState, setEditorState, editorStateHelper)
          }
        >
          <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
        </span>

        <span className="text-green font-bold px-6">Advert</span>
      </p>
    </div>
  );
}
