import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import StripeRedirectModal from "../../../modals/stripe-redirect-modal";
import StripeRedirectModeModal from "../../../modals/stripe-redirect-mode-modal";
import DeletePromptModal from "../../../modals/delete-warning-modal";
import Pagination from "../../../components/pagination";
import AdminAdInsertModal from "../../../modals/admin-ad-insert-modal";
import useDebounce from "../../../components/debounce/debounce-helper";

import {
  isAdmin,
  API_BASE,
  isPublisher,
  getLocation,
  authUserData,
  isAuthUser,
  getClientBaseURL,
} from "../../../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
import Switch from "react-switch";
import PasswordPromptModal from "../../../modals/password-prompt-modal";
import CreateTierModal from "../../../modals/create-tier-modal";
export default function AdminAdProtectionRation() {
  const [processing, setProcessing] = useState(false);
  const [adRatio, setAdRatio] = useState("");
  const [systemSetting, setSystemSetting] = useState({});

  const getSyatemSetting = () => {
    setProcessing(true);
    let url = API_BASE + "/api/system/settings";
    axios
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setSystemSetting(response.data);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const updateAdProtectionRatio = () => {
    setProcessing(true);
    let url = API_BASE + "/api/system/settings/ads/ad-protection-ratio";

    if (adRatio > 0.05) {
      toast("Ad protection ratio must be 0.05 or less!", { type: "error" });
      setProcessing(false);
      return false;
    }

    axios
      .post(url, {
        ad_protection_ratio: adRatio,
      })
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          toast("Updated!", { type: "success" });
          getSyatemSetting();
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const handleAdRatioChange = (e) => {
    setAdRatio(e);
  };

  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
    getSyatemSetting();
  }, []);

  useEffect(() => {
    if (systemSetting.ad_protection_ratio) {
      setAdRatio(systemSetting.ad_protection_ratio);
    }
  }, [systemSetting]);
  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">
            Advert Protection Ratio
          </h1>
        </div>
        <div className="flex gap-2">
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>

      <div className="flex flex-col justify-center gap-4 mt-8 items-center mb-4">
        {/* <h1 className="font-bold text-xl">Storage Tiers</h1> */}
        <div className="w-full p-8 flex flex-col justify-center content-center gap-2 items-center">
          <div className="w-5/12">
            <TextField
              type="number"
              title="Ad Rtaio"
              placeholder={"Ad Rtaio"}
              classes="rounded-lg"
              handleChange={handleAdRatioChange}
              value={adRatio}
            />
          </div>
          <div className="w-5/12">
            <BasicButton
              classes={"w-full"}
              disabled={processing || !adRatio}
              title={processing ? "Updating..." : "Update"}
              handleClick={() => updateAdProtectionRatio()}
            />
          </div>{" "}
        </div>{" "}
      </div>
    </>
  );
}
