import { useSupplierApplicationsStore } from "../../../../../stores/supplier-applications-store";
import { isResponsiveMode } from "../../../../../Utils/helpers";
import Checkbox from "../../../../../components/inputs/checkbox";
import BasicButton from "../../../../../components/buttons/basic-button";
import { useNavigate } from "react-router-dom";

export default function MobileTable(props) {
  const navigate = useNavigate();

  const { openCommentModal } = props;

  const mailStore = useSupplierApplicationsStore((state) => state);

  return (
    <div class="w-full flex flex-col justify-center my-4 text-center">
      {mailStore.data.data &&
        mailStore.data.data.map((c) => {
          return (
            <div
              className="overflow-x-scroll inline"
              style={{
                width: document.documentElement.clientWidth - 90 + "px",
                margin: "0 auto",
              }}
            >
              <div className="flex">
                {" "}
                <div class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold">Date</h3>
                  <p>
                    {c.refactoredCreatedAtTime
                      ? new Date(
                          c.refactoredCreatedAtTime.replace(/-/g, "/")
                        ).getDate() +
                        "-" +
                        (new Date(
                          c.refactoredCreatedAtTime.replace(/-/g, "/")
                        ).getMonth() +
                          1) +
                        "-" +
                        new Date(
                          c.refactoredCreatedAtTime.replace(/-/g, "/")
                        ).getFullYear()
                      : "N/A"}
                  </p>
                </div>
                <div class="border  px-4 py-2  font-medium">
                  <div className="flex flex-col gap-2 text-left">
                    <div>
                      <p className="font-bold">Name</p>
                      {c.contract_owner_data?.name}
                    </div>
                    <div>
                      <p className="font-bold">Email</p>
                      {c.contract_owner_data?.email}
                    </div>
                    <div>
                      <p className="font-bold">User Handle</p>
                      {c.contract_owner_data?.user_handle}
                    </div>
                    <div>
                      <p className="font-bold">Username</p>
                      {c.contract_owner_data?.username}
                    </div>
                  </div>
                </div>
                <div class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold">Status</h3>
                  {c?.status}
                </div>
                <div class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold">Application</h3>
                  <BasicButton
                    disabled={!c?.uuid}
                    title={"View"}
                    classes={"my-bg-yellow-500 text-black"}
                    handleClick={() =>
                      navigate(
                        `/admin/supplier/product/contract/${c.uuid}/user/${c?.contract_owner_data?.uuid}`
                      )
                    }
                  />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}
