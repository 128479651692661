import axios from "axios";
import { useEffect, useState } from "react";
import Pagination from "../../../components/pagination";
import BasicButton from "../../../components/buttons/basic-button";
import LoadingProgress from "../../publisher/rich-page/components/loading-progress";
import useDebounce from "../../../components/debounce/debounce-helper";
import TextField from "../../../components/inputs/text-input";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";
import DeletePromptModal from "../../../modals/delete-warning-modal";
import { useParams } from "react-router-dom";
import {
  isAdmin,
  API_BASE,
  BASE_URL_WITH_LOCAL,
  isAuthUser,
  isResponsiveMode,
} from "../../../Utils/helpers";
import { toast } from "react-toastify";

export default function AdminContractViewer() {
  const [fileSelected, setFileSelected] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");
  const [processing, setProcessing] = useState(false);
  const [contract, setContract] = useState({});

  let { type } = useParams();

  const getContract = () => {
    const url = API_BASE + "/admin/supplier/contract/admin/" + type;
    setProcessing(true);

    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        setContract(response.data.contract);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getPageTitle = () => {
    const titles = {
      "sample-contract-single": "Sample Contract (Single Purchase)",
      "sample-contract-recurring": "Sample Contract (Recurring Purchase)",
      "supplier-contract": "Product Supplier Agreement",
      "buyer-contract": "Product Buyer Agreement",
    };
    return titles[type];
  };

  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
    getContract();
  }, []);

  return (
    <>
      <div className="main w-full">
        <div className="bg-white m-2 p-2 flex justify-between shadow px-4">
          <div>
            <h1 className="text-2xl text-center m-2 bold"> {getPageTitle()}</h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex justify-center my-4 overflow-hidden">
          <div class="flex flex-col items-center p-10 gap-4 md:w-10/12 border rouunded-lg p-4">
            {contract?.id && (
              <>
                <embed
                  src={
                    BASE_URL_WITH_LOCAL +
                    "/storage" +
                    contract.file.split("public")[1]
                  }
                  type="application/pdf"
                  width="100%"
                  height="600px"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
