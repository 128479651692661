export default function BlockSuccess({ data, isBlocked }) {
  return (
    data?.publisher &&
    isBlocked && (
      <p>
        {" "}
        <b className="font-bold">{data.subscriber_name}</b>, your request has
        been processed successfully.{" "}
        <b className="font-bold">{data.publisher.name}</b>, user handle:
        <b className="font-bold my-text-yellow-500">
          {data.publisher.handle_id}
        </b>{" "}
        , will no longer be able to send update emails to you. If you decide to
        receive updates from <b className="font-bold"> {data.publisher.name}</b>{" "}
        again in the future, simply click on the "Restore Updates From{" "}
        <b className="font-bold">{data.publisher.name}</b>" link provided in the
        email you will receive shortly. Thank you for your time.
      </p>
    )
  );
}
