import { useEffect, useState, useRef } from "react";
import RenderAccordionMediaBox from "./components/media";
export default function RenderAccordionBox(props) {
  const [currAccordion, serCurrAccordion] = useState("none");
  const { data } = props;
  const html = data; //Your html string
  const tmpDiv = document.createElement("div");
  tmpDiv.innerHTML = html;
  const payload = tmpDiv
    .querySelector(".accordion-xyz")
    .getAttribute("payload");
  const p = JSON.parse(payload);

  const checkIsValidJson = (str) => {
    let parsedJson;
    try {
      parsedJson = JSON.parse(str);
      /** parsed JSON will not be undefined if it is parsed successfully because undefined is not a valid JSON */
      return parsedJson;
    } catch (e) {
      /** returning undefined because null, boolean, string, array or object is a valid JSON whereas undefined is invalid JSON  */
      return undefined;
    }
  };

  return p.data ? (
    <>
      {p.data.map((d, i) => (
        <div className="border border-b-0 border-gray-200 mb-2">
          {" "}
          <h2 id="accordion-collapse-heading-2">
            <button
              onClick={() => {
                currAccordion == i
                  ? serCurrAccordion("none")
                  : serCurrAccordion(i);
              }}
              type="button"
              class="bg-gray-50 flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500  focus:ring-1 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
              data-accordion-target="#accordion-collapse-body-2"
              aria-expanded="false"
              aria-controls="accordion-collapse-body-2"
            >
              <span className="font-bold">{d.header.value}</span>
              <svg
                data-accordion-icon
                class="w-3 h-3 rotate-180 shrink-0"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5 5 1 1 5"
                />
              </svg>
            </button>
          </h2>
          <div
            id="accordion-collapse-body-2"
            className={currAccordion == i ? "" : "hidden"}
            aria-labelledby="accordion-collapse-heading-2"
          >
            <div class="p-5  dark:border-gray-700">
              <p
                class="mb-2 text-gray-500 dark:text-gray-400 whitespace-pre whitespace-pre-line whitespace-pre-wrap"
                dangerouslySetInnerHTML={{ __html: d.body.value }}
              ></p>
              <RenderAccordionMediaBox payload={d} />
            </div>
          </div>
        </div>
      ))}
    </>
  ) : (
    ""
  );
}
