import BasicButton from "../../../../../components/buttons/basic-button";
import EditorTooltip from "./tooltip/editor-tooltip";
import { useState } from "react";
import PurchaseOPtionDef from "./single/purchase-opt-def";
import TrackQuantity from "./single/track-quantity";
import QuantityBox from "./single/quantity-box";
import ProductContract from "./single/product-contract";
import AfterPurchaseMsg from "./single/after-purchase-msg";
import RequestReply from "./single/request-reply";
import PurchaserReply from "./single/purchaser-reply";
import LocaionFulfilment from "./single/location-fulfilment";
import MakeInactive from "./single/make-inactive";
import HideButton from "./single/hide-button";
import ItemPrice from "./single/price";
import CustomerServices from "./single/customer-services";
import ServiceLocation from "./single/service-location";
import ServiceZipCode from "./single/service-zip-code";
export default function SPPurchaseBox({
  user,
  productContracts,
  productDefaultContracts,
  customerServiceSets,
  productMetaData,
  pageContext,
  state,
}) {
  const productMeta = state.productMeta;

  const addLocationCharge = () => {
    state.setProductMeta((prev) => ({
      ...prev,
      ["location_fulfilment"]: [
        ...prev["location_fulfilment"],
        { location: "", charge: "" },
      ],
    }));
  };

  const removeLocationCharge = (index) => {
    const f = productMeta.location_fulfilment;

    state.setProductMeta((prev) => ({
      ...prev,
      ["location_fulfilment"]: f.filter((d, i) => i !== index),
    }));
  };

  const handleLFChange = (e, key, index) => {
    const f = productMeta.location_fulfilment;
    f[index][key] = e;
    state.setProductMeta((prev) => ({
      ...prev,
      ["location_fulfilment"]: f,
    }));
  };

  const handleMetaChange = (e, key, type) => {
    if (type == "checkbox") {
      state.setProductMeta((prev) => ({ ...prev, [key]: !prev[key] }));
    } else if (type == "text" || type == "radio" || type == "dropdown") {
      if (type == "dropdown") {
        state.setProductMeta((prev) => ({ ...prev, [key]: e.target.value }));
      } else {
        state.setProductMeta((prev) => ({ ...prev, [key]: e }));
      }
    } else {
      state.setProductMeta((prev) => ({ ...prev, [key]: e }));
    }
  };

  const cannotDisableQuantityTracking = () => {
    if (state.productMetas.length) {
      return state.productMetas.filter(
        (p) => p.payload.quantity_merge == productMeta.block_meta_id
      ).length;
    }
    return false;
  };

  return (
    <div>
      {state.metaMode == 1 && (
        <PurchaseOPtionDef
          pageContext={pageContext}
          productMeta={productMeta}
          handleMetaChange={handleMetaChange}
        />
      )}
      {productMeta.p_type_sub_opt && state.metaMode == 1 && (
        <div className={`flex flex-col gap-2 w-full p-2`}>
          {productMeta.p_type_sub_opt && (
            <div className="flex flex-col items-center">
              <ItemPrice
                user={user}
                productMeta={productMeta}
                handleMetaChange={handleMetaChange}
              />
              <TrackQuantity
                productMeta={productMeta}
                handleMetaChange={handleMetaChange}
              />
              {/* <p className="text-green-500">{quantityAvailable()} Available</p> */}
              <p className="text-green-500">
                {productMetaData?.activepaymentcount || "0"} Purchased
              </p>

              <QuantityBox
                productMeta={productMeta}
                handleMetaChange={handleMetaChange}
              />

              <ProductContract
                productMeta={productMeta}
                handleMetaChange={handleMetaChange}
                productContracts={productContracts}
                productDefaultContracts={productDefaultContracts}
              />
              <CustomerServices
                productMeta={productMeta}
                handleMetaChange={handleMetaChange}
                customerServiceSets={customerServiceSets}
              />
              <ServiceLocation
                productMeta={productMeta}
                pageContext={pageContext}
                handleMetaChange={handleMetaChange}
                user={user}
              />
              <ServiceZipCode
                productMeta={productMeta}
                pageContext={pageContext}
                handleMetaChange={handleMetaChange}
                user={user}
              />

              <AfterPurchaseMsg
                productMeta={productMeta}
                handleMetaChange={handleMetaChange}
              />
              <RequestReply
                productMeta={productMeta}
                handleMetaChange={handleMetaChange}
              />
              <PurchaserReply
                productMeta={productMeta}
                handleMetaChange={handleMetaChange}
              />
              <LocaionFulfilment
                productMeta={productMeta}
                handleLFChange={handleLFChange}
                addLocationCharge={addLocationCharge}
                removeLocationCharge={removeLocationCharge}
              />
              <hr />
              <MakeInactive
                productMeta={productMeta}
                handleMetaChange={handleMetaChange}
              />
              <HideButton
                productMeta={productMeta}
                pageContext={pageContext}
                handleMetaChange={handleMetaChange}
                productMetaData={productMetaData}
              />
              <BasicButton
                disabled={state.cannotDisplaySPButton()}
                classes={"w-full mt-10"}
                title={"Save"}
                handleClick={() => {
                  {
                    /* toast("Updated", { type: "success" }); */
                  }
                  state.saveMetaConfig();
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
