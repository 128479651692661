import React, { useState, useEffect, useRef, forwardRef } from "react";
import "../reader.css";
import AdSense from "react-adsense";
import { useBookStore } from "../stores/book-store";
import { Document, Page, pdfjs } from "react-pdf";
import BasicButton from "../components/buttons/basic-button";
import StripeForm from "../payment/pay-button";
import StripePaymentModal from "../modals/stripe-payment-modal";
import StripeSubscriptionModal from "../modals/stripe-subscription-modal";
import Registration2Modal from "../modals/registration-2-modal";
import { getLocation, isPublisher } from "../Utils/helpers";
import RenderLeftAdPageMediaPreview from "./web-reader/right-ad-media-preview";
import RenderRightAdPageMediaPreview from "./web-reader/left-ad-media-preview";
import RenderTopResponsiveAdMedia from "./web-reader/top-responsive-ad-media";
import RenderBottomResponsiveAdMedia from "./web-reader/bottom-responsive-ad-media";
import LinkOutsidePromptModal from "../modals/link-outside-warning-modal";
import Adsterra from "../components/advert/adsterra";
import { RenderAd } from "./web-reader/components/RenderAd";
import { RenderSystemAd } from "./web-reader/components/RenderSystemAd";
import { useIntersectionObserver } from "@uidotdev/usehooks";

import {
  authUserData,
  isAuthUser,
  sizes,
  isResponsiveMode,
  globalAds,
  bookPriceWithSystemFees,
  subscritionCostWithSystemFees,
  isRegistrationComplete,
  donationPriceWithSystemFees,
} from "../Utils/helpers";
import TextNumberField from "../components/inputs/text-number-input";
import StripeDonateForm from "../payment/donate-button";
import StripeCustomDonateForm from "../payment/donate-button-custom";
import PlatformUpdateModal from "../modals/platform-updates-modal";

import {
  RenderCurrentScaleProps,
  RenderZoomInProps,
  RenderZoomOutProps,
  zoomPlugin,
} from "@react-pdf-viewer/zoom";
import { useParams } from "react-router-dom";
// Import the main component
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import PageSavePaymentModal from "../modals/page-save-payment";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import { useSwipeable } from "react-swipeable";
import SavePageModal from "../modals/save-page-modal";
import AdPaymentModal from "../modals/ad-payment-modal";
import RegistrationModal from "../modals/registration-modal";
import uniqueId from "react-uuid";

import { API_BASE, BASE_URL } from "../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
import userEvent from "@testing-library/user-event";
import { load } from "@fingerprintjs/botd";
import DonationEmailModal from "../modals/donation-email-modal";
import LeftPaginationComposer from "./web-reader/pagination/left-pagination-composer";
import RightPaginationComposer from "./web-reader/pagination/right-pagination-composer";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function WebReader() {
  const [numPages, setNumPages] = useState(0);
  const [bookPagesCount, setBookPagesCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageWithoutAd, setPageWithoutAd] = useState(0);
  const [views, setViews] = useState([]);
  const [showZoomTool, setShowZoomTool] = useState(false);
  const [showToolBar, setShowToolBar] = useState(false);
  const [book, setBook] = useState({});
  const [pages, setPages] = useState([]);
  const [pagesWithoutAds, setPagesWithoutAds] = useState([]);
  const [counAdPages, setCountAdPages] = useState(0);
  const [showNavigation, setShowNaviation] = useState(true);
  const [savePageModalOpen, setSavePageModalOpen] = useState(false);
  const [adPaymentModalOpen, setAdPaymentModalOpen] = useState(false);
  const [savePagePaymentModalOpen, setSavePagePaymentModalOpen] =
    useState(false);
  const [stripeModalOpen, setStripeModalOpen] = useState(false);
  const [registrationModalOpen, setRegistrationModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [pageSaveMode, setPageSaveMode] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [noAdMode, setNoAdMode] = useState(false);
  const [isSavingPage, setIsSavingPage] = useState(false);
  const [showAdTimerFakeText, setShowAdTimerFakeText] = useState(false);
  const [portraitMode, setPortraitMode] = useState(true);
  const [localIsComponentVisible, setLocalIsComponentVisible] = useState(true);
  const [paginateOnAdPage, sePaginateOnAdPage] = useState(false);
  const [pagesViewed, sePagesViewed] = useState([]);
  const [lastPageHasAdvert, setLastPageHasAdvert] = useState(false);
  const [lastPageHasAdvertId, setLastPageHasAdvertId] = useState(0);
  const [paymentType, setPaymentType] = useState("");
  const [subSecretKey, setSubSecretKey] = useState();
  const [showSubModal, setShowSubModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [navType, setNavType] = useState("");
  const [linkOutsideModalOpen, setLinkOutsideModalOpen] = useState(false);
  const [linkOutsideHref, setLinkOutsideHref] = useState("");
  // Initialize an agent at application startup, once per page/app.
  const botdPromise = load();

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;
  const zoomPluginInstance = zoomPlugin();
  const {
    ZoomInButton,
    ZoomOutButton,
    ZoomPopover,
    CurrentScale,
    ZoomIn,
    ZoomOut,
  } = zoomPluginInstance;

  const {
    imageZoomMode,
    setImageZoomMode,
    isComponentVisible,
    setIsComponentVisible,
    isRenderingImage,
    isPayToViewPage,
    setIsPayToViewPage,
  } = useBookStore((state) => state);

  // const [isComponentVisible, setIsComponentVisible] = useState(true);
  const ref = useRef();
  const ref2 = useRef();
  const myStateRef = useRef(showNavigation);
  const refLeftNav = useRef();
  const refRightNav = useRef();
  const refBookTitleTop = useRef();
  const refBookNameTop = useRef();
  const refPaginationTop = useRef();
  const refSavePageTop = useRef();
  const refSavePageModalOpen = useRef();
  const refComponentType = useRef();
  const viewCountRef = useRef();
  const currPageRef = useRef();
  const currPageTypeRef = useRef();
  const keyDownInitializerCountRefOne = useRef();
  const keyDownInitializerCountRefTwo = useRef();
  let { uuid } = useParams();
  let { useruuid } = useParams();
  let { pageuuid } = useParams();
  let bookPagesWithAds = [];
  let controlsTimer;
  const refComponentVisibleRef = useRef();
  currPageRef.current = 0;
  keyDownInitializerCountRefOne.current = 0;
  keyDownInitializerCountRefTwo.current = 0;

  const topLevelRefs = {
    ref: ref,
    refLeftNav: refLeftNav,
    refRightNav: refRightNav,
    refBookTitleTop: refBookTitleTop,
    refBookNameTop: refBookNameTop,
    refPaginationTop: refPaginationTop,
    refPaginationTop: refPaginationTop,
    refSavePageTop: refSavePageTop,
    refSavePageModalOpen: refSavePageModalOpen,
  };

  useEffect(() => {
    refComponentVisibleRef.current = isComponentVisible;
    document.addEventListener("contextmenu", (event) => {
      //event.preventDefault();
    });
  }, []);

  useEffect(
    () =>
      useBookStore.subscribe((state) => {
        // console.log(
        //   "recording bookstore @isComponentVisible update",
        //   state.isComponentVisible
        // );
      }),
    []
  );

  const getBook = () => {
    const url = API_BASE + "/api/book/" + uuid;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          response.data && setBook(response.data.book);
        }
      })
      .catch((error) => {
        toast("Something went wrong.", {
          type: "error",
        });
      });
  };

  const initBook = () => {
    bookPagesWithAds = [];
    if (book.pages) {
      if (book.infopage) {
        if (book.infopage.page_start == 1) {
          bookPagesWithAds.push({
            ...book.infopage,
            type: "info",
          });
          // alert("has info page");
        }
      }
      for (let i = 0; i < book.pages.length; i++) {
        bookPagesWithAds.push(book.pages[i]);

        if (book.infopage) {
          if (
            book.infopage.position > 0 &&
            book.pages[i].position == book.infopage.position
          ) {
            bookPagesWithAds.push({
              ...book.infopage,
              type: "info",
            });
          }
        }

        if (!hasPurchasedBook(book)) {
          if (book.enable_pay_per_view && book.pay_per_view_page_position) {
            if (book.pages[i].position == book.pay_per_view_page_position) {
              bookPagesWithAds.push({
                type: "pay_per_view",
              });
            }
          }
        }

        if (book.pages[i].adverts.length && book.owner) {
          if (
            book.owner?.advertrequest?.status == "approved" ||
            book.owner?.advertrequest?.status == "unprocessed"
          ) {
            if (isAuthUser()) {
              if (!hasPurchasedBook()) {
                bookPagesWithAds.push({
                  ...book.pages[i].adverts[0],
                  type: "advert",
                  adRequest: book.owner?.advertrequest,
                });
              } else {
                // console.log("no ads");
              }
            } else {
              bookPagesWithAds.push({
                ...book.pages[i].adverts[0],
                type: "advert",
                adRequest: book.owner?.advertrequest,
              });
            }
          }
        }

        if (book.pages[i].adverts.length && book.owner) {
          if (
            book.owner?.advertrequest?.status == "approved" ||
            book.owner?.advertrequest?.status == "unprocessed"
          ) {
            if (isAuthUser()) {
              if (!hasPurchasedBook()) {
                bookPagesWithAds.push({
                  ...book.pages[i].adverts[0],
                  type: "system_advert",
                  adRequest: book.owner?.advertrequest,
                });
              } else {
                // console.log("no ads");
              }
            } else {
              bookPagesWithAds.push({
                ...book.pages[i].adverts[0],
                type: "system_advert",
                adRequest: book.owner?.advertrequest,
              });
            }
          }
        }
      }
      if (book.infopage) {
        if (book.infopage.page_end == 1) {
          bookPagesWithAds.push({
            ...book.infopage,
            type: "info",
          });
          // alert("has info page");
        }
      }
    }

    book.pages && setPages(bookPagesWithAds);
    book.pages && setPagesWithoutAds(book.pages);
  };

  useEffect(() => {
    initBook();
  }, [book]);

  useEffect(() => {
    hasPurchasedBook();
  }, [book.buyers]);

  useEffect(() => {
    if (pages.length) {
      const bookPages = pages.map((page) => {
        if (page.file) {
          if (page.file.includes("pdf")) {
            return {
              widget: (
                <RenderPDF
                  filename={
                    BASE_URL + "/storage" + page.file.split("public")[1]
                  }
                  zoomPluginHandle={zoomPluginInstance}
                />
              ),
              type: "pdf",
              id: page.uuid,
              name: page.name,
              file: BASE_URL + "/storage" + page.file.split("public")[1],
            };
          } else {
            refComponentType.current = "image";
            return {
              widget: (
                <RenderImage
                  filename={
                    BASE_URL + "/storage" + page.file.split("public")[1]
                  }
                  topLevelRefs={topLevelRefs}
                />
              ),
              type: "image",
              id: page.uuid,
              name: page.name,
              file: BASE_URL + "/storage" + page.file.split("public")[1],
            };
          }
        } else if (page.type == "advert") {
          return {
            widget: (
              <RenderAd
                filename={page}
                adRequest={page.adRequest}
                bookId={book.uuid}
              />
            ),
            type: "advert",
            id: page.uuid,
          };
        } else if (page.type == "system_advert") {
          return {
            widget: (
              <RenderSystemAd
                filename={page}
                adRequest={page.adRequest}
                bookId={book.uuid}
              />
            ),
            type: "system_advert",
            id: page.uuid,
          };
        } else if (page.type == "info") {
          return {
            widget: <RenderInfoPage filename={page} book={book} />,
            type: "info",
            id: page.id,
          };
        } else if (page.type == "pay_per_view") {
          return {
            widget: (
              <RenderPerView
                filename={page}
                book={book}
                hasPurchasedBook={hasPurchasedBook}
                openSubModal={openSubModal}
                showRegistrationModal={showRegistrationModal}
              />
            ),
            type: "pay_per_view",
            id: page.id,
          };
        }
      });
      setViews(bookPages);
      setBookPagesCount(bookPages.length);
      // alert(bookPages.length);
    }
  }, [pages]);

  const hasPurchasedBook = () => {
    if (!authUserData()) {
      return false;
    }

    if (book.owner && book.owner.id == authUserData().id) {
      return true;
    }
    if (book.buyers) {
      const bought = book.buyers.filter((buyer) => {
        // alert(buyer.user_id == authUserData().id);
        if (buyer.buyer_id == authUserData().id) {
          return buyer;
        }
      });

      const hasSubscribed = hasSubscribedToPublisher();

      return bought.length || hasSubscribed ? true : false;
    }
  };

  const hasSubscribedToPublisher = () => {
    if (!authUserData()) {
      return false;
    }

    if (book.owner.subscribers) {
      // alert(JSON.stringify(book.owner.subscribers));
      const subscribed = book.owner.subscribers.filter((subscriber) => {
        // alert(buyer.user_id == authUserData().id);
        if (subscriber.user_id == authUserData().id && subscriber.active) {
          return subscriber;
        }
      });

      //   alert(bought);

      return subscribed.length ? true : false;
    }
  };

  const savePage = () => {
    setIsSavingPage(true);
    const url = API_BASE + "/api/guest/page/save";

    localStorage.setItem("cookie_id", uniqueId());
    const cookieId = localStorage.getItem("cookie_id");

    axios
      .post(url, {
        email: email,
        cookie_id: cookieId,
        book_uuid: book.uuid,
        page_uuid: views[page].id,
      })
      .then((response) => {
        toast("Page successfully saved, please check your email for details.", {
          type: "success",
        });
        setIsSavingPage(false);
        if (response.status == 200) {
          hideSavePageModal();
        }
      })
      .catch((error) => {
        setIsSavingPage(false);
        console.log("There was an error!", error);
      });
  };

  const shouldSetSavedPage = () => {
    const cookieId = localStorage.getItem("cookie_id");

    if ((pageuuid && cookieId) || (pageuuid && hasPurchasedBook())) {
      const url = `${API_BASE}/api/page/${pageuuid}/cookieId/${cookieId}`;

      if (pageuuid && hasPurchasedBook()) {
        if (views.length) {
          for (let i = 0; i < views.length; i++) {
            if (views[i].id == pageuuid) {
              setPage(i);
              setPageWithoutAd(i);
              setPageSaveMode(true);
            }
          }
        }
      } else {
        // alert("hi");
        axios
          .get(url)
          .then((response) => {
            if (response.status == 200) {
              if (views.length) {
                for (let i = 0; i < views.length; i++) {
                  if (views[i].id == pageuuid) {
                    setPage(i);
                    setPageWithoutAd(i);
                    setPageSaveMode(true);
                    // alert("valid saved page");
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log("There was an error!", error);
          });
      }
    }
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => console.log("Swiped"),
    // onSwipedLeft: (eventData) => showNavigation && paginateRight(), // After LEFT swipe  (SwipeEventData) => void
    // onSwipedRight: (eventData) => showNavigation && paginateLeft(),
  });

  const handleClickOutsideo = (event) => {
    if (currPageTypeRef.current == "pdf") {
      if (event.target.tagName == "A") {
        if (
          event.target.hostname !== "litzapp.com" &&
          event.target.hostname !== "localhost"
        ) {
          event.preventDefault();
          setLinkOutsideHref(event.target.href);
          openLinkOutsidePromtModal();
          console.log(event, "clicked");
        }
      }
    }

    if (
      topLevelRefs.refLeftNav.current &&
      !topLevelRefs.refLeftNav.current.contains(event.target) &&
      topLevelRefs.refRightNav.current &&
      !topLevelRefs.refRightNav.current.contains(event.target) &&
      topLevelRefs.refBookTitleTop.current &&
      !topLevelRefs.refBookTitleTop.current.contains(event.target) &&
      topLevelRefs.refBookNameTop.current &&
      !topLevelRefs.refBookNameTop.current.contains(event.target) &&
      topLevelRefs.refPaginationTop.current &&
      !topLevelRefs.refPaginationTop.current.contains(event.target) &&
      topLevelRefs.refSavePageTop.current &&
      !topLevelRefs.refSavePageTop.current.contains(event.target)
    ) {
      setLocalIsComponentVisible((prev) => !prev);
    }
  };

  const recordPageView = (id) => {
    const url = API_BASE + "/api/page/views";
    if (localStorage.getItem("unique_visitor_id")) {
      axios
        .post(url, {
          uuid: id,
          unique_user_id: localStorage.getItem("unique_visitor_id"),
        })
        .catch((e) => {});
    } else {
      const userUniqueId = uniqueId();
      localStorage.setItem("unique_visitor_id", userUniqueId);
      axios
        .post(url, {
          uuid: id,
          unique_user_id: userUniqueId,
        })
        .catch((e) => {});
    }
  };

  const recordLastPageView = (userId, bookId) => {
    const url = API_BASE + "/api/views/last-viewed";
    axios.post(url, { userId: userId, bookId: bookId }).catch((e) => {});
  };

  const bookHasInformationPage = () => {
    if (views.length) {
      return views
        .map((p) => p)
        .filter((p) => {
          return p.type == "info";
        }).length
        ? true
        : false;
    }

    return false;
  };

  const showSavePageModal = () => {
    // localStorage.removeItem("cookie_id");
    if (hasPurchasedBook()) {
      setIsComponentVisible(false);
      setSavePageModalOpen(true);
    }

    if (pageSaveMode && !hasPurchasedBook()) {
      setIsComponentVisible(false);
      setSavePagePaymentModalOpen(true);
    }

    if (!pageSaveMode && !localStorage.getItem("cookie_id")) {
      setIsComponentVisible(false);
      setSavePageModalOpen(true);
    }

    if (
      !pageSaveMode &&
      localStorage.getItem("cookie_id") &&
      !hasPurchasedBook()
    ) {
      setIsComponentVisible(false);
      setSavePagePaymentModalOpen(true);
    }
    // setIsComponentVisible(false);
    refSavePageModalOpen.current = true;
  };

  const hideSavePageModal = () => {
    setSavePageModalOpen(false);
    refSavePageModalOpen.current = null;
  };

  const hideAdPaymentModal = () => {
    setAdPaymentModalOpen(false);
  };

  const showSavePagePaymentModal = () => {
    setSavePagePaymentModalOpen(true);
  };

  const hideSavePagePaymentModal = () => {
    setIsComponentVisible(true);
    setSavePagePaymentModalOpen(false);
  };

  const showStripeModal = () => {
    setStripeModalOpen(true);
  };

  const hideStripeModal = () => {
    setStripeModalOpen(false);
  };

  const showRegistrationModal = (paymentType) => {
    if (paymentType) {
      setPaymentType(paymentType);
    }
    setRegistrationModalOpen(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const hideRegistrationModal = () => {
    setRegistrationModalOpen(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e);
  };

  const hideLinkOutsidePromtModal = () => {
    setLinkOutsideHref("");
    setLinkOutsideModalOpen(false);
  };

  const openLinkOutsidePromtModal = () => {
    setLinkOutsideModalOpen(true);
  };

  const setupClickOutsideEventListener = () => {
    // alert("clicked");
    // if (views.length && views[page].type == "pdf") {
    //   document
    //     .getElementById("web-reader-generic-content")
    //     ?.addEventListener("click", (e) => {
    //       e.preventDefault();
    //       if (e.target.tagName == "A") {
    //         if (
    //           e.target.hostname !== "litzapp.com" &&
    //           e.target.hostname !== "localhost"
    //         ) {
    //           setLinkOutsideHref(e.target.href);
    //           openLinkOutsidePromtModal();
    //           console.log(e, "clicked");
    //         }
    //       }
    //     });
    // }
  };

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  useEffect(() => {
    shouldSetSavedPage();
    if (
      views.length &&
      !isAdPage() &&
      views[page].type !== "info_page" &&
      !pagesViewed.includes(views[page].id)
    ) {
      recordPageView(views[page].id);
      sePagesViewed((prev) => [...prev, views[page].id]);
    }
    if (views.length) {
      if (
        views[views.length - 1].type == "advert" ||
        views[views.length - 1].type == "system_advert"
      ) {
        setLastPageHasAdvert(true);
        setLastPageHasAdvertId(views.length - 1);
      }
    }
    if (views.length) {
      viewCountRef.current = views.length;
    }
    if (views.length) {
      currPageTypeRef.current = views[page].type;
    }

    // if (bookPagesCount > 1) {
    //   document.addEventListener("keydown", handleKeyDown, true);
    //   keyDownInitializerCountRefOne.current = 1;
    // }
  }, [views]);

  const restoreNavigation = () => {
    // setIsComponentVisible(true);
    setShowNaviation(true);
    sePaginateOnAdPage(true);
    myStateRef.current = true;
  };

  const hidePagination = () => {
    setIsComponentVisible(false);
  };

  useEffect(() => {
    if (views.length && isPublisherAdPage()) {
      setShowAdTimerFakeText(false);
      setShowNaviation(false);
      myStateRef.current = false;
    }

    if (views.length && views[page].type == "image") {
      refComponentType.current = "image";
      // alert("image");
    } else {
      // alert("nnn");
      refComponentType.current = null;
    }
    // alert(views.length && views[page].type);
    currPageRef.current = page;

    if (views.length) {
      currPageTypeRef.current = views[page].type;
    }

    currPageRef.current = page;
    //views.length && alert(views[page].type);

    if (navType == "ARROW" && !isCurrPageInfo() && !isAdPage()) {
      setIsComponentVisible(false);
      setLocalIsComponentVisible(false);
    } else {
      setIsComponentVisible(true);
      setLocalIsComponentVisible(true);
    }
  }, [page]);

  useEffect(() => {
    window.addEventListener("orientationchange", setOrientation, false);
    if (
      views.length &&
      !isAdPage() &&
      views[page].type !== "info_page" &&
      !pagesViewed.includes(views[page].id)
    ) {
      recordPageView(views[page].id);
      sePagesViewed((prev) => [...prev, views[page].id]);
    }
    if (views.length && page == views.length - 1 && isAuthUser()) {
      recordLastPageView(JSON.parse(isAuthUser()).uuid, book.uuid);
    }

    // document.addEventListener("keydown", handleKeyDown, true);

    // return () => {
    //   document.removeEventListener("keydown", handleKeyDown, true);
    // };
  }, [page]);

  const reattachHandleKeyDownListener = () => {
    document.addEventListener("keydown", handleKeyDown, true);
    myStateRef.current && paginateLeft();
  };

  const handleKeyDown = (event) => {
    const key = event.key; // "ArrowRight", "ArrowLeft", "ArrowUp", or "ArrowDown"
    if (true) {
      if (key == "ArrowRight") {
        views.length && paginateRight();
      } else if (key == "ArrowLeft") {
        views.length && paginateLeft();
      }
      setNavType("ARROW");
    }
  };

  const paginateRight = () => {
    console.log("pright total pages", views.length);
    console.log("pright curr page", currPageRef.current);

    if (!showNavigation) {
      return false;
    }

    if (views.length && views[page].type == "pay_per_view") {
      return false;
    }

    if (page !== views.length - 1) {
      setPage(page + 1);
      if (!isAdPage() && !isNextPageInfo() && !isNextPagePayToView()) {
        setPageWithoutAd(pageWithoutAd + 1);
      } else {
        sePaginateOnAdPage(false);
      }
      setIsComponentVisible(true);
      setLocalIsComponentVisible(true);
      setImageZoomMode(false);
    } else {
      return;
    }
  };

  const paginateLeft = () => {
    if (!showNavigation) {
      return false;
    }
    if (page !== 0) {
      setPage(page - 1);

      if (isPublisherAdPage() || hasPurchasedBook()) {
        if (!isNextPageInfo()) setPageWithoutAd(pageWithoutAd - 1);
      } else {
        sePaginateOnAdPage(false);
      }
      setIsComponentVisible(true);
      setLocalIsComponentVisible(true);
      setImageZoomMode(false);
    } else {
      return;
    }
  };

  const showAdTimerElapsedText = () => {
    setShowAdTimerFakeText(true);
  };

  const setOrientation = () => {
    if (window.orientation == 0 || window.orientation == 180) {
      // WHEN IN PORTRAIT MODE
      // alert("Portrait");
      setPortraitMode(true);
    } else {
      // alert("Landscape");
      setPortraitMode(false);
      // WHEN IN LANDSCAPE MODE
    }
  };

  const setPaginationVerticalPosition = () => {
    if (isResponsiveMode()) {
      if (portraitMode && imageZoomMode) {
        return "65%";
      } else if (portraitMode && !imageZoomMode && isRenderingImage) {
        return "55%";
      } else if (portraitMode && !imageZoomMode && !isRenderingImage) {
        return "45%";
      } else if (!portraitMode && imageZoomMode) {
        return "33%";
      } else if (!portraitMode && !imageZoomMode) {
        return "25%";
      }
    }

    return "43%";
  };

  const setTimerToHideControls = () => {};

  const shouldShowBorder = () => {
    if (views.length && views[page].type == "advert") {
      if (paginateOnAdPage) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };

  const checkBotActivity = (res) => {
    const url = API_BASE + "/api/bot/record";

    axios
      .post(url, {
        result: res.bot ? 1 : 0,
        book_id: uuid,
      })
      .catch((e) => {});
  };

  useEffect(() => {
    getBook();
    setTimeout(() => {
      botdPromise
        .then((botd) => botd.detect())
        .then((result) => console.log(checkBotActivity(result)))
        .catch((error) => console.error(error));
    }, 1000);
    document.addEventListener("click", handleClickOutsideo, true);
    myStateRef.current = true;
  }, []);

  // useEffect(() => {
  //   console.log("pages viewed", pagesViewed);
  // }, []);

  // return { ref, isComponentVisible, setIsComponentVisible };

  const shouldRenderTitleBar = () => {
    return localIsComponentVisible && isComponentVisible;
  };

  const calculateTitleBarTopPosition = () => {
    if (!shouldRenderTitleBar()) {
      return window.innerWidth < 720 ? 1000 : 1000;
    }

    return window.innerWidth < 720 ? 20 : 10;
  };

  const calculatePaginationBarTopPosition = () => {
    if (!shouldRenderTitleBar()) {
      return window.innerWidth < 720 ? 1000 : 1000;
    }

    return window.innerWidth < 720 ? 90 : 90;
  };

  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const checkLastPageHasAdvert = () => {
    if (lastPageHasAdvert && page + 1 === lastPageHasAdvertId) {
      return true;
    }

    return false;
  };

  const getStripePaymentSecret = () => {
    let formData = new FormData();
    const url = API_BASE + "/api/user/payment/get-stripe-payment-secret";
    setProcessing(true);
    axios
      .post(url, {
        book_id: book.uuid,
        user_id: isAuthUser() ? authUserData().uuid : "",
      })
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          // alert(response.data.secret_key);
          setSubSecretKey(response.data.secret_key);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", {
          type: "error",
        });
      });
  };

  const hideSubModal = () => {
    setSubSecretKey();
    setShowSubModal((prev) => (prev = !prev));
  };

  const openSubModal = () => {
    getStripePaymentSecret();
    setShowSubModal(true);
  };

  const isTitleBarHidden = () => {
    if (!isResponsiveMode()) {
      return !isComponentVisible || imageZoomMode;
    } else {
      return !localIsComponentVisible;
    }
    return true;
  };

  const isAdPage = () => {
    return views[page].type == "advert" || views[page].type == "system_advert";
  };

  const isNextPageInfo = () => {
    if (page == pagesWithoutAds - 1) {
      return true;
    }
    return views[page + 1].type == "info";
  };

  const isCurrPageInfo = () => {
    return views[page].type == "info";
  };

  const isNextPagePayToView = () => {
    if (page == pagesWithoutAds - 1) {
      return true;
    }
    return views[page + 1].type == "pay_per_view";
  };
  const isPublisherAdPage = () => {
    return views[page].type == "advert";
  };

  const isSystemAdPage = () => {
    return views[page].type == "system_advert";
  };

  const showCurrentLocation = () => {
    if (!book.uuid) return false;
    if (getLocation().hostname == "litzapp.com") {
      copyText("https://litzapp.com/share/sh/" + book.item_id);
    } else {
      copyText("https://wordalbums1.test/sh/" + book.item_id);
    }
  };

  const copyText = (text) => {
    // standard way of copying
    var textArea = document.createElement("textarea");
    textArea.setAttribute("style", "width:1px;border:0;opacity:0;");
    document.body.appendChild(textArea);
    textArea.value = text;
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    toast("Link Copied", { type: "success" });
  };

  useEffect(() => {
    if (isResponsiveMode()) {
      import("../../src/mobile.css");
    } else {
      import("../../src/desktop.css");
    }
  }, []);

  const mainDivFocusRef = useRef(null);

  useEffect(() => {
    mainDivFocusRef.current.focus();
  }, []);

  return (
    <>
      {/* <h1 className="text-2xl flex justify-center">Welcome</h1> */}
      {book.id && book.admin_publish_status == false ? (
        <div className="bg-black flex flex-col items-center justify-center h-screen text-white">
          <p className="pt-10 pb-10">This content is no longer available.</p>
          <span
            className="cursor-pointer border p-2 text-center"
            onClick={() => {
              //document.location.href = `/web/book/${book.uuid}`;
              {
                /* alert(document.referrer); */
              }
              if (
                (document.referrer &&
                  document.referrer.includes("localhost")) ||
                (document.referrer && document.referrer.includes("litzapp"))
              ) {
                hasPurchasedBook() && isAuthUser()
                  ? window.history.back()
                  : window.history.back();
              } else {
                window.location.href = `/web/${book.owner.uuid}`;
              }
            }}
          >
            <i class="fa fa-times-circle text-white ml-2 z-50 text-1xl mr-1"></i>
            exit
          </span>
        </div>
      ) : (
        <div
          className="bg-black"
          onClick={() => {
            setShowZoomTool((prev) => !prev);

            {
              /* setShowZoomTool(true); */
            }
          }}
          tabIndex={0}
          onKeyDown={handleKeyDown}
          ref={mainDivFocusRef}
        >
          <div
            className={`grid grid-cols-12 relative  ${
              portraitMode ? "h-screen" : ""
            }  gap-2 ${isResponsiveMode() ? "items-center" : "items-center"}`}
          >
            <LeftPaginationComposer
              views={views}
              page={page}
              isAdPage={isAdPage}
              portraitMode={portraitMode}
              isPublisherAdPage={isPublisherAdPage}
              setPaginationVerticalPosition={setPaginationVerticalPosition}
              checkLastPageHasAdvert={checkLastPageHasAdvert}
              isComponentVisible={isComponentVisible}
              paginateOnAdPage={paginateOnAdPage}
              pageNumber={pageNumber}
              onDocumentLoadSuccess={onDocumentLoadSuccess}
              showNavigation={showNavigation}
              shouldShowBorder={shouldShowBorder}
              isSystemAdPage={isSystemAdPage}
              refLeftNav={refLeftNav}
              paginateLeft={paginateLeft}
              bookPagesCount={bookPagesCount}
              setNavType={setNavType}
            />
            <div
              className={`main bg-black   ${
                portraitMode
                  ? "col-span-8"
                  : views.length && views[page].type == "pdf"
                  ? "col-span-8"
                  : "col-span-4"
              } relative  `}
              {...handlers}
              style={{ backgroundImage: 'url("loader.gif")' }}
            >
              {/* top bar */}
              {views.length &&
              views[page].type !== "pay_per_view" &&
              views.length &&
              !isAdPage() ? (
                <div
                  className={`absolute left-0 bottom-0 right-0 z-40 h-26 text-black h-24 `}
                  style={{
                    top:
                      views.length && views[page].type == "pdf"
                        ? calculateTitleBarTopPosition()
                        : window.innerWidth < 720
                        ? 20
                        : 10,
                  }}
                >
                  <div
                    className={`${
                      !isResponsiveMode() ? "w-60" : "w-55"
                    } rounded`}
                    style={{
                      margin: "0 auto",
                      backgroundColor: "rgba(0, 0, 0, 0.8)",
                    }}
                  >
                    {
                      <>
                        {" "}
                        {views.length &&
                          !isAdPage() &&
                          views[page].type !== "info" &&
                          !savePageModalOpen && (
                            <>
                              <h1
                                ref={refBookTitleTop}
                                className={`flex justify-center items-center text-white rounded-full text-base md:text-lg text-center ${
                                  isTitleBarHidden() ? "hidden" : ""
                                } `}
                              >
                                {book.name}
                              </h1>
                              <p
                                onClick={() =>
                                  (window.location.href = `/web/${book.owner.uuid}`)
                                }
                                ref={refBookNameTop}
                                className={`cursor-pointer flex justify-center items-center text-white rounded-full p-2 underline text-xs ${
                                  isTitleBarHidden() ? "hidden" : ""
                                } `}
                              >
                                {/* {views[page].name} */}
                                {book.owner.name}
                              </p>
                            </>
                          )}
                      </>
                    }
                  </div>
                </div>
              ) : (
                ""
              )}

              <div
                className={`absolute left-0 bottom-0 right-0 z-40 h-24  text-black ${
                  page &&
                  views.length &&
                  views[page].type !== "pay_per_view" &&
                  views[page].type == "info"
                    ? "hidden"
                    : ""
                }`}
                style={{
                  top:
                    views.length && views[page].type == "pdf"
                      ? calculatePaginationBarTopPosition()
                      : 90,
                }}
              >
                <div
                  className={`${
                    !isResponsiveMode() ? "w-60" : "w-55"
                  }  rounded pr-1`}
                  style={{
                    margin: "0 auto",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  }}
                >
                  {
                    <>
                      {" "}
                      {views.length &&
                        views[page].type !== "pay_per_view" &&
                        views.length &&
                        !isAdPage() &&
                        views[page].type !== "info" &&
                        !savePageModalOpen && (
                          <p
                            ref={refPaginationTop}
                            className={`flex justify-center items-center text-white rounded-full p-2 text-sm  ${
                              isTitleBarHidden() ? "hidden" : ""
                            } `}
                          >
                            {views.length && views[page].type == "pdf" && (
                              <>
                                <CustomizeZoomButtonsExample
                                  zoomPluginHandle={zoomPluginInstance}
                                />
                                <span className="white mx-2">|</span>
                              </>
                            )}
                            {/* page {pageWithoutAd + 1} of {pagesWithoutAds.length} */}
                            {!hasPurchasedBook() && (
                              <p className="text-sm">
                                {"page " +
                                  (pageWithoutAd + 1) +
                                  " of " +
                                  "Next/Slide"}
                              </p>
                            )}
                            {hasPurchasedBook() && (
                              <>
                                page {page + 1} of {pagesWithoutAds.length}
                              </>
                            )}
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                //document.location.href = `/web/book/${book.uuid}`;
                                {
                                  /* alert(document.referrer); */
                                }
                                if (
                                  (document.referrer &&
                                    document.referrer.includes("localhost")) ||
                                  (document.referrer &&
                                    document.referrer.includes("litzapp"))
                                ) {
                                  hasPurchasedBook() && isAuthUser()
                                    ? window.history.back()
                                    : window.history.back();
                                } else {
                                  window.location.href = `/web/${book.owner.uuid}`;
                                }
                              }}
                            >
                              <i class="fa fa-times-circle text-white ml-2 z-50 text-1xl mr-1"></i>
                              exit
                            </span>
                          </p>
                        )}
                    </>
                  }

                  {views.length &&
                    views[page].type !== "pay_per_view" &&
                    views.length &&
                    !isAdPage() && (
                      <div
                        className={`flex flex-col justify-center items-center p-1 ${
                          isTitleBarHidden() ? "hidden" : ""
                        } `}
                        ref={refSavePageTop}
                      >
                        <div onClick={() => showCurrentLocation()}>
                          <i class="fa fa-share-alt cursor-pointer text-white"></i>
                          {/* {getLocation().href} */}
                        </div>
                        <p
                          className={`text-white rounded-full p-2 text-center cursor-pointer  ${
                            isTitleBarHidden() ? "hidden" : ""
                          } `}
                          onClick={() => showSavePageModal(page)}
                        >
                          {views.length &&
                            views[page].type !== "pay_per_view" &&
                            views[page].type !== "info" && (
                              <>
                                <i class="fas fa-save cursor-pointer"></i> Save
                                Page Position
                              </>
                            )}
                        </p>

                        {!hasPurchasedBook(book) && isAuthUser() ? (
                          <div className="flex flex-col items-center gap-2">
                            {book.enable_title_bar_purchase ? (
                              <StripeForm
                                book={book}
                                classes="my-bg-yellow-500 text-black z-50"
                                title={"Purchase"}
                              />
                            ) : (
                              ""
                            )}
                            {book.owner &&
                            book.enable_title_bar_subscription &&
                            book.owner.enable_subscription ? (
                              <BasicButton
                                classes="my-bg-yellow-500 text-black text-xxs z-40 p-0"
                                title={`Subscribe $${subscritionCostWithSystemFees(
                                  book
                                )}/mth`}
                                handleClick={() => openSubModal()}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {!isAuthUser() ? (
                          <div className="flex flex-col gap-2">
                            {book.enable_title_bar_purchase ? (
                              <BasicButton
                                title={`Purchase $${bookPriceWithSystemFees(
                                  book
                                )}`}
                                handleClick={() =>
                                  showRegistrationModal("one-time")
                                }
                                classes="my-bg-yellow-500 text-black"
                              />
                            ) : (
                              ""
                            )}

                            {book.owner &&
                            book.enable_title_bar_subscription &&
                            book.owner.enable_subscription ? (
                              <BasicButton
                                title={`Subscribe $${subscritionCostWithSystemFees(
                                  book
                                )}/mth`}
                                handleClick={() =>
                                  showRegistrationModal("subscription")
                                }
                                classes="my-bg-yellow-500 text-black"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                </div>
              </div>
              {views.length && isSystemAdPage() && (
                <div className="my-2">
                  <RenderTopResponsiveAdMedia
                    pageNumber={pageNumber}
                    onDocumentLoadSuccess={onDocumentLoadSuccess}
                    portraitMode={portraitMode}
                    isAdPage={isAdPage}
                    isPublisherAdPage={isPublisherAdPage}
                    isSystemAdPage={isSystemAdPage}
                    views={views}
                    page={page}
                  />
                </div>
              )}

              <div
                className={
                  views.length && views[page].type == "image"
                    ? "flex justify-center"
                    : ""
                }
              >
                {views.length && isPublisherAdPage() && (
                  <>
                    <div className="" style={{ top: 160 }}>
                      <p
                        ref={ref}
                        className={`text-white rounded-full p-2 text-center ${
                          !isComponentVisible && ""
                        } `}
                      >
                        {views.length && (
                          <div className="my-2">
                            {" "}
                            <RenderTopResponsiveAdMedia
                              pageNumber={pageNumber}
                              onDocumentLoadSuccess={onDocumentLoadSuccess}
                              portraitMode={portraitMode}
                              isAdPage={isAdPage}
                              isPublisherAdPage={isPublisherAdPage}
                              isSystemAdPage={isSystemAdPage}
                              views={views}
                              page={page}
                            />
                          </div>
                        )}
                        <div className="flex justify-center mb-2">
                          {isAuthUser() ? (
                            <>
                              <div className="flex flex-col gap-2">
                                {book.enable_title_bar_purchase ? (
                                  <StripeForm
                                    book={book}
                                    title={"Buy Ad-Free"}
                                    classes="my-bg-yellow-500 text-black z-50"
                                  />
                                ) : (
                                  ""
                                )}
                                {book.owner &&
                                book.enable_title_bar_subscription &&
                                book.owner.enable_subscription ? (
                                  <BasicButton
                                    classes="my-bg-yellow-500 text-black text-xxs z-40 p-0"
                                    title={`Subscribe Ad-Free $${subscritionCostWithSystemFees(
                                      book
                                    )}/mth`}
                                    handleClick={() => openSubModal()}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </>
                          ) : (
                            <div className="flex flex-col gap-2">
                              {book.enable_title_bar_purchase ? (
                                <BasicButton
                                  title={`Buy Ad-Free Book $${bookPriceWithSystemFees(
                                    book
                                  )}`}
                                  handleClick={() =>
                                    showRegistrationModal("one-time")
                                  }
                                  classes="my-bg-yellow-500 text-black"
                                />
                              ) : (
                                ""
                              )}

                              {book.owner &&
                              book.enable_title_bar_subscription &&
                              book.owner.enable_subscription ? (
                                <BasicButton
                                  title={`Subscribe Ad-Free $${subscritionCostWithSystemFees(
                                    book
                                  )}/mth`}
                                  handleClick={() =>
                                    showRegistrationModal("subscription")
                                  }
                                  classes="my-bg-yellow-500 text-black"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </div>
                      </p>
                    </div>
                    <p className="text-white text-center">
                      <CountdownTimer
                        restoreNavigation={restoreNavigation}
                        showAdTimerElapsedText={showAdTimerElapsedText}
                        showAdTimerFakeText={showAdTimerFakeText}
                        book={book}
                      />

                      {!showAdTimerFakeText ? `` : ""}
                    </p>
                  </>
                )}

                <div
                  className=""
                  id="web-reader-generic-content"
                  onLoad={() => setupClickOutsideEventListener()}
                >
                  {" "}
                  {views.length ? views[page].widget : ""}
                </div>
              </div>

              {/* {advert} */}
              {views.length && isPublisherAdPage() && (
                <div
                  className={` left-0 bottom-0 right-0 z-40 h-24  text-black  ${
                    page && views[page].type == "info" ? "hidden" : ""
                  }`}
                  style={{ bottom: -110 }}
                >
                  <div
                    className="w-60 rounded text-white"
                    style={{
                      margin: "0 auto",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    {views.length && isPublisherAdPage() && (
                      <>
                        <div className="" style={{ top: 160 }}>
                          <p
                            ref={ref}
                            className={`text-white rounded-full p-2 text-center ${
                              !isComponentVisible && ""
                            } `}
                          >
                            {" "}
                            <CountdownTimer
                              restoreNavigation={restoreNavigation}
                              showAdTimerElapsedText={showAdTimerElapsedText}
                              showAdTimerFakeText={showAdTimerFakeText}
                              book={book}
                            />
                            {showAdTimerFakeText
                              ? "It's been 10 seconds. Please slide to the previous/next page"
                              : ""}
                            {!showAdTimerFakeText
                              ? ` Buy Ad Free book
                      for USD ${bookPriceWithSystemFees(book)}`
                              : ""}
                          </p>
                          <div className="flex justify-center mb-2 w-full">
                            {isAuthUser() ? (
                              <div className="flex flex-col justify-center items-center gap-2">
                                {" "}
                                {book.enable_title_bar_purchase ? (
                                  <StripeForm
                                    book={book}
                                    title={"Buy Ad-Free"}
                                    classes="my-bg-yellow-500 text-black z-50 w-full"
                                  />
                                ) : (
                                  ""
                                )}
                                {book.owner &&
                                book.enable_title_bar_subscription &&
                                book.owner.enable_subscription ? (
                                  <BasicButton
                                    classes="my-bg-yellow-500 text-black text-xxs z-40 p-0"
                                    title={`Subscribe Ad-Free $${subscritionCostWithSystemFees(
                                      book
                                    )}/mth`}
                                    handleClick={() => openSubModal()}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              <div className="flex flex-col gap-3">
                                {book.enable_title_bar_purchase ? (
                                  <BasicButton
                                    title={`Buy Ad-Free Book $${bookPriceWithSystemFees(
                                      book
                                    )}`}
                                    handleClick={() =>
                                      showRegistrationModal("one-time")
                                    }
                                    classes="my-bg-yellow-500 text-black w-full"
                                  />
                                ) : (
                                  ""
                                )}

                                {book.owner &&
                                book.enable_title_bar_subscription &&
                                book.owner.enable_subscription ? (
                                  <BasicButton
                                    title={`Subscribe Ad-Free $${subscritionCostWithSystemFees(
                                      book
                                    )}/mth`}
                                    handleClick={() =>
                                      showRegistrationModal("subscription")
                                    }
                                    classes="my-bg-yellow-500 text-black w-full"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </div>

                          <RenderBottomResponsiveAdMedia
                            pageNumber={pageNumber}
                            onDocumentLoadSuccess={onDocumentLoadSuccess}
                            portraitMode={portraitMode}
                            isAdPage={isAdPage}
                            isPublisherAdPage={isPublisherAdPage}
                            isSystemAdPage={isSystemAdPage}
                            views={views}
                            page={page}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              {/* advert */}
              {views.length && isSystemAdPage() && (
                <div className="my-2">
                  <RenderBottomResponsiveAdMedia
                    pageNumber={pageNumber}
                    onDocumentLoadSuccess={onDocumentLoadSuccess}
                    portraitMode={portraitMode}
                    isAdPage={isAdPage}
                    isPublisherAdPage={isPublisherAdPage}
                    isSystemAdPage={isSystemAdPage}
                    views={views}
                    page={page}
                  />
                </div>
              )}
              {/* <HideNavigationCountdownTimer hidePagination={hidePagination} /> */}
            </div>

            <RightPaginationComposer
              views={views}
              page={page}
              isAdPage={isAdPage}
              portraitMode={portraitMode}
              isPublisherAdPage={isPublisherAdPage}
              setPaginationVerticalPosition={setPaginationVerticalPosition}
              checkLastPageHasAdvert={checkLastPageHasAdvert}
              isComponentVisible={isComponentVisible}
              paginateOnAdPage={paginateOnAdPage}
              pageNumber={pageNumber}
              onDocumentLoadSuccess={onDocumentLoadSuccess}
              showNavigation={showNavigation}
              shouldShowBorder={shouldShowBorder}
              isSystemAdPage={isSystemAdPage}
              refRightNav={refRightNav}
              paginateRight={paginateRight}
              bookPagesCount={bookPagesCount}
              setNavType={setNavType}
            />
          </div>
        </div>
      )}

      {savePageModalOpen ? (
        <SavePageModal
          modalOpen={savePageModalOpen}
          handleEmailChange={handleEmailChange}
          hideModal={hideSavePageModal}
          savePage={savePage}
          email={email}
          book={book}
          showRegistrationModal={showRegistrationModal}
          hasPurchasedBook={hasPurchasedBook}
          isSavingPage={isSavingPage}
        />
      ) : (
        ""
      )}

      <AdPaymentModal
        modalOpen={adPaymentModalOpen}
        handleEmailChange={handleEmailChange}
        hideModal={hideAdPaymentModal}
        savePage={savePage}
        email={email}
        book={book}
        showRegistrationModal={showRegistrationModal}
        hasPurchasedBook={hasPurchasedBook}
      />
      <PageSavePaymentModal
        modalOpen={savePagePaymentModalOpen}
        hideModal={hideSavePagePaymentModal}
        book={book}
        showRegistrationModal={showRegistrationModal}
        hasPurchasedBook={hasPurchasedBook}
      />

      <StripePaymentModal
        modalOpen={stripeModalOpen}
        hideModal={hideStripeModal}
        book={book}
      />

      <RegistrationModal
        modalOpen={registrationModalOpen}
        hideModal={hideRegistrationModal}
        book={book}
        paymentType={paymentType}
      />

      <StripeSubscriptionModal
        modalOpen={showSubModal}
        hideModal={hideSubModal}
        book={book}
        subSecretKey={subSecretKey}
      />
      <LinkOutsidePromptModal
        modalOpen={linkOutsideModalOpen}
        hideAdModal={hideLinkOutsidePromtModal}
        linkOutsideHref={linkOutsideHref}
        title={
          "The link you clicked will take you to an external application or site outside Litzapp's platform. While the link may be safe, we cannot guarantee its safety, and Litzapp is not liable for any damages or inconveniences you may encounter. Proceed at your own discretion."
        }
        yesActionText={"Proceed to External Site / App"}
        noActionText={"Do not Proceed. Cancel"}
        action={() =>
          linkOutsideHref
            ? (window.location.href = linkOutsideHref)
            : console.log("")
        }
        processing={processing}
      />
    </>
  );
}

export function RenderPDF({ filename, zoomPluginHandle }) {
  const { setIsComponentVisible } = useBookStore((state) => state);
  var controlsTimer;
  const setTimerToHideControls = () => {
    // controlsTimer && clearTimeout(controlsTimer);
    // controlsTimer = setTimeout(() => {
    //   setIsComponentVisible(false);
    // }, 4000);
  };

  useEffect(() => {
    setTimerToHideControls();
  }, []);
  return (
    <div className="">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
        <div className="h-screen">
          <Viewer
            fileUrl={process.env.PUBLIC_URL + filename}
            defaultScale={SpecialZoomLevel.PageWidth}
            plugins={[zoomPluginHandle]}
          />
        </div>
      </Worker>
    </div>
  );
}

export function RenderImage({ filename, topLevelRefs }) {
  const imageRef = useRef();
  const containerRef = useRef();
  const progressBarRef = useRef();
  const imaeContainerRef = useRef();
  const panUpRef = useRef();
  const panDownRef = useRef();
  const panLeftRef = useRef();
  const panRightRef = useRef();
  const zoomOutRef = useRef();
  const zoomInRef = useRef();
  const autoPanRef = useRef();
  const autoZoomRef = useRef();
  const autoPanIntvalRef = useRef();
  const autoZoomIntvalRef = useRef();
  const imageDefaultRef = useRef();
  const touchMoveRef = useRef();
  const zoomModeRef = useRef();
  const viewCountRef = useRef();
  const imageDownloadingRef = useRef();
  const [defaultImageWidth, setDefaultImageWidth] = useState();
  const [defaultImageHeight, setDefaultImageHeight] = useState();
  const [defaultImageOffset, setDefaultImageOffset] = useState();
  const [currentImageWidth, setCurrentImageWidth] = useState();
  const [imageTopOffset, setImageTopOffset] = useState();
  const [imageLeftOffset, setImageLeftOffset] = useState();
  const [panDirection, setPanDirection] = useState();
  const [zoomLevel, setZoomLevel] = useState(100);
  const [zoomMode, setZoomMode] = useState(false);
  const [imageDownloading, setImageDownloading] = useState(false);
  const [mobileContainerHeight, setMobileContainerHeight] = useState();
  const [responsiveHeight, setResponsiveHeight] = useState(
    sizes().windowHeight * 0.5
  );
  const [responsiveWidth, setResponsiveWidth] = useState(sizes().windowWidth);
  const {
    imageZoomMode,
    setImageZoomMode,
    isComponentVisible,
    setIsComponentVisible,
    setIsRenderingImage,
  } = useBookStore((state) => state);
  // const [isComponentVisible, setIsComponentVisible] = useState(true);
  const [portraitMode, setPotraitMode] = useState(true);

  useEffect(() => {
    window.document.addEventListener("click", handleClickOutside, true);
  }, []);

  var img_ele = null,
    x_cursor = 0,
    y_cursor = 0,
    x_img_ele = 0,
    y_img_ele = 0,
    autoPanIntval = null,
    controlsTimer;

  const zoomIn = (zoomincrement) => {
    img_ele = imageRef.current;
    // setZoomMode(true);
    var pre_width = img_ele.getBoundingClientRect().width,
      pre_height = img_ele.getBoundingClientRect().height;
    img_ele.style.width = pre_width + 30 + "px";
    // img_ele.style.height = pre_height * zoomincrement + "px";
    setCurrentImageWidth(img_ele.style.width);
    setZoomLevel((prev) => prev + 10);
    // alert(currentImageWidth);
    img_ele = null;
    setImageZoomMode(true);
    zoomModeRef.current = true;

    setTimerToHideControls();
  };

  const zoomOut = (zoomincrement) => {
    // setZoomMode(true);
    img_ele = imageRef.current;
    var pre_width = img_ele.getBoundingClientRect().width,
      pre_height = img_ele.getBoundingClientRect().height;
    img_ele.style.width = pre_width - 30 + "px";
    // img_ele.style.height = pre_height * zoomincrement + "px";
    setCurrentImageWidth(img_ele.style.width);
    setZoomLevel((prev) => prev - 10);
    img_ele = null;
    setImageZoomMode(true);
    zoomModeRef.current = true;

    setTimerToHideControls();
  };

  const restoreDefaultSize = () => {
    setImageZoomMode(false);
    zoomModeRef.current = false;
    setZoomLevel(100);
    // setZoomMode(false);
    imageRef.current.style.width = defaultImageWidth + "px";
    imageRef.current.style.top = defaultImageOffset.top + "px";
    imageRef.current.style.left = defaultImageOffset.left + "px";
    img_ele = imageRef.current;
    setCurrentImageWidth(img_ele.style.width);
    // setIsComponentVisible(false);
    setTimerToHideControls();
  };

  const panLeft = () => {
    setTimerToHideControls();
    img_ele = imageRef.current;
    if (!imageLeftOffset) {
      setImageLeftOffset(img_ele.offsetLeft);
      setPanDirection("left");
    } else {
      setImageLeftOffset((prev) => prev + 50);
      setPanDirection("left");
    }
  };
  const panRight = () => {
    setTimerToHideControls();
    img_ele = imageRef.current;
    if (!imageLeftOffset) {
      setImageLeftOffset(img_ele.offsetLeft);
      setPanDirection("right");
      // alert("Not image left offset");
    } else {
      setImageLeftOffset((prev) => prev - 50);
      setPanDirection("right");
    }
  };
  const panUp = () => {
    setTimerToHideControls();
    img_ele = imageRef.current;
    if (!imageTopOffset) {
      setImageTopOffset(img_ele.offsetTop);
      setPanDirection("up");
    } else {
      setImageTopOffset((prev) => prev + 50);
      setPanDirection("up");
    }
  };
  const panDown = () => {
    setTimerToHideControls();
    img_ele = imageRef.current;
    if (!imageTopOffset) {
      setImageTopOffset(img_ele.offsetTop);
      setPanDirection("down");
    } else {
      setImageTopOffset((prev) => prev - 50);
      setPanDirection("down");
    }
  };

  const doIsComponentVisible = () => {
    setIsComponentVisible();
  };

  const handleClickOutside = (event) => {
    // alert("ll");
    // alert(topLevelRefs && topLevelRefs.refLeftNav);

    if (!isResponsiveMode()) {
      // alert(panDownRef.current.contains(event.target) ? "yes" : "no");
      if (
        topLevelRefs.refLeftNav.current &&
        !topLevelRefs.refLeftNav.current.contains(event.target) &&
        topLevelRefs.refRightNav.current &&
        !topLevelRefs.refRightNav.current.contains(event.target) &&
        topLevelRefs.refBookTitleTop.current &&
        !topLevelRefs.refBookTitleTop.current.contains(event.target) &&
        topLevelRefs.refBookNameTop.current &&
        !topLevelRefs.refBookNameTop.current.contains(event.target) &&
        topLevelRefs.refPaginationTop.current &&
        !topLevelRefs.refPaginationTop.current.contains(event.target) &&
        topLevelRefs.refSavePageTop.current &&
        !topLevelRefs.refSavePageTop.current.contains(event.target) &&
        //LOW LEVEL REFS
        imageDefaultRef.current &&
        !imageDefaultRef.current.contains(event.target) &&
        panUpRef.current &&
        !panUpRef.current.contains(event.target) &&
        panDownRef.current &&
        !panDownRef.current.contains(event.target) &&
        panLeftRef.current &&
        !panLeftRef.current.contains(event.target) &&
        panRightRef.current &&
        !panRightRef.current.contains(event.target) &&
        zoomOutRef.current &&
        !zoomOutRef.current.contains(event.target) &&
        zoomInRef.current &&
        !zoomInRef.current.contains(event.target)
      ) {
        setIsComponentVisible();
      } else {
      }
    } else {
      // if (
      //   //TOP LEVEL REFS
      //   topLevelRefs.refLeftNav.current &&
      //   !topLevelRefs.refLeftNav.current.contains(event.target) &&
      //   topLevelRefs.refRightNav.current &&
      //   !topLevelRefs.refRightNav.current.contains(event.target) &&
      //   topLevelRefs.refBookTitleTop.current &&
      //   !topLevelRefs.refBookTitleTop.current.contains(event.target) &&
      //   topLevelRefs.refBookNameTop.current &&
      //   !topLevelRefs.refBookNameTop.current.contains(event.target) &&
      //   topLevelRefs.refPaginationTop.current &&
      //   !topLevelRefs.refPaginationTop.current.contains(event.target) &&
      //   topLevelRefs.refSavePageTop.current &&
      //   !topLevelRefs.refSavePageTop.current.contains(event.target)
      //   //LOW LEVEL REFS
      // ) {
      //   doIsComponentVisible();
      // } else {
      //   // alert(imageDefaultRef.current);
      // }
    }
  };

  const setTimerToHideControls = () => {};

  const getMaxValue = (e) => {
    return Math.max(-1, Math.min(1, e));
  };

  const isInZoomMode = () => {
    return imageZoomMode;
  };

  var doScroll = (e) => {
    const deltaX = Math.max(-1, Math.min(1, e.deltaX));
    const deltaY = Math.max(-1, Math.min(1, e.deltaY));
    // Do something with `delta`
    const elmId = `#x${deltaX}y${deltaY}`;

    if ((elmId.includes("#x0y0") || elmId == "#x0y1") && zoomModeRef.current) {
      panUp();
    }
    if (
      (elmId.includes("#x0y-0") || elmId == "#x0y-1") &&
      zoomModeRef.current &&
      zoomModeRef.current
    ) {
      panDown();
    }
    if ((elmId.includes("#x0.") || elmId == "#x1y0") && zoomModeRef.current) {
      panLeft();
    }
    if ((elmId.includes("#x-0.") || elmId == "#x-1y0") && zoomModeRef.current) {
      panRight();
    }
    // e.preventDefault();
  };

  /**
   * Loads an image with progress callback.
   *
   * The `onprogress` callback will be called by XMLHttpRequest's onprogress
   * event, and will receive the loading progress ratio as an whole number.
   * However, if it's not possible to compute the progress ratio, `onprogress`
   * will be called only once passing -1 as progress value. This is useful to,
   * for example, change the progress animation to an undefined animation.
   *
   * @param  {string}   imageUrl   The image to load
   * @param  {Function} onprogress
   * @return {Promise}
   */
  function loadImage(imageUrl, onprogress) {
    setImageDownloading(false);
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      var notifiedNotComputable = false;

      xhr.open("GET", imageUrl, true);
      xhr.responseType = "arraybuffer";

      xhr.onprogress = function (ev) {
        if (ev.lengthComputable) {
          onprogress(parseInt((ev.loaded / ev.total) * 100));
        } else {
          if (!notifiedNotComputable) {
            notifiedNotComputable = true;
            onprogress(-1);
          }
        }
      };

      xhr.onloadend = function () {
        if (!xhr.status.toString().match(/^2/)) {
          reject(xhr);
        } else {
          if (!notifiedNotComputable) {
            onprogress(100);
          }

          var options = {};
          var headers = xhr.getAllResponseHeaders();
          var m = headers.match(/^Content-Type\:\s*(.*?)$/im);

          if (m && m[1]) {
            options.type = m[1];
          }

          var blob = new Blob([this.response], options);

          resolve(window.URL.createObjectURL(blob));
        }
      };

      xhr.send();
    });
  }

  useEffect(() => {
    touchMoveRef.current = false;
    setIsRenderingImage(true);
    window.addEventListener("wheel", doScroll, false);
    return () => {
      setIsRenderingImage(false);
      touchMoveRef.current = true;
    };
  }, []);

  useEffect(() => {
    loadImage(process.env.PUBLIC_URL + filename, (ratio) => {
      if (ratio == -1) {
        // Ratio not computable. Let's make this bar an undefined one.
        // Remember that since ratio isn't computable, calling this function
        // makes no further sense, so it won't be called again.
        //progressBarRef.current.removeAttribute("value");
        setImageDownloading(false);
        imageRef.current.src = "/loader.gif";
      } else {
        // We have progress ratio; update the bar.
        setImageDownloading(false);
        imageRef.current.src = "/loader.gif";
      }
    }).then(
      (imgSrc) => {
        // Loading successfuly complete; set the image and probably do other stuff.
        // setImageDownloading(false);
        setImageDownloading(true);
        setTimeout(() => {
          imageRef.current.src = imgSrc;
        }, 800);
      },
      (xhr) => {
        // An error occured. We have the XHR object to see what happened.
      }
    );
  }, [filename]);

  useEffect(() => {
    if (!isResponsiveMode()) {
      if (panDirection == "up" && imageTopOffset) {
        imageRef.current.style.top = imageTopOffset + 50 + "px";
      }

      if (panDirection == "down" && imageTopOffset) {
        imageRef.current.style.top = imageTopOffset - 50 + "px";
      }

      if (panDirection == "left" && imageLeftOffset) {
        imageRef.current.style.left = imageLeftOffset + 50 + "px";
      }

      if (panDirection == "right" && imageLeftOffset) {
        imageRef.current.style.left = imageLeftOffset - 50 + "px";
      }
      setPanDirection("");
    }
  }, [imageLeftOffset, imageTopOffset]);

  useEffect(() => {
    if (!isResponsiveMode()) {
      setTimerToHideControls();
      if (!defaultImageOffset) {
        setDefaultImageOffset({
          top: imageRef.current.offsetTop,
          left: imageRef.current.offsetLeft,
        });
      }

      if (!defaultImageHeight && !defaultImageWidth) {
        img_ele = imageRef.current;
        var pre_width = img_ele.getBoundingClientRect().width,
          pre_height = img_ele.getBoundingClientRect().height;
        setDefaultImageWidth(pre_width);
        setDefaultImageHeight(pre_height);
      }
    }
  }, []);

  useEffect(() => {
    if (
      zoomLevel &&
      zoomLevel == 100 &&
      imageRef.current &&
      defaultImageWidth
    ) {
      imageRef.current.style.width = defaultImageWidth + "px";
      imageRef.current.style.top = defaultImageOffset.top + "px";
      imageRef.current.style.left = defaultImageOffset.left + "px";
      setCurrentImageWidth(imageRef.current.style.width);
    }
  }, [zoomLevel]);

  return !isResponsiveMode() ? (
    <div
      className={`relative ${
        !isResponsiveMode() ? "" : "drag-container-mobile"
      }  overflow-hidden`}
      style={{
        width: sizes().pageWidth - 50,
        height: sizes().pageWidth / 3,
      }}
    >
      {/* item */}
      {imageZoomMode && isComponentVisible && (
        <div
          className="fixed rounded-full px-1 z-50"
          style={{
            top: "43%",
            left: "47%",
          }}
        >
          <span className="p-4 rounded-full cursor-pointer">
            <span className="text-white">{zoomLevel}%</span>
          </span>
        </div>
      )}
      {/* item */}
      {/* item */}

      <div
        className="fixed rounded-full px-1 z-50"
        ref={imageDefaultRef}
        style={{
          top: "50%",
          left: "47%",
        }}
      >
        {" "}
        {imageZoomMode && isComponentVisible && (
          <span
            className="p-4 rounded-full cursor-pointer border border-white"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
          >
            <span className="text-white" onClick={() => restoreDefaultSize()}>
              100%
            </span>
          </span>
        )}
      </div>

      <>
        <span ref={panUpRef}>
          {imageZoomMode && isComponentVisible && (
            <ActionIcon t={10} l={48} action={panUp} icon="fa fa-arrow-up" />
          )}
        </span>
        <span ref={panDownRef}>
          {imageZoomMode && isComponentVisible && (
            <ActionIcon
              b={10}
              l={48}
              action={panDown}
              icon="fa fa-arrow-down"
            />
          )}
        </span>
      </>
      <span ref={zoomOutRef}>
        {isComponentVisible && (
          <ActionIcon
            t={imageZoomMode ? 38 : 46}
            l={20}
            action={() => zoomOut(0.5)}
            icon="fa fa-minus"
          />
        )}
      </span>
      <span ref={zoomInRef}>
        {isComponentVisible && (
          <ActionIcon
            t={imageZoomMode ? 38 : 46}
            r={20}
            action={() => zoomIn(1.5)}
            icon="fa fa-plus"
          />
        )}
      </span>

      <span ref={panRightRef}>
        {imageZoomMode && isComponentVisible && (
          <ActionIcon
            t={50}
            r={20}
            action={panRight}
            icon="fa fa-arrow-right"
          />
        )}
      </span>
      {/* item */}
      {/* item */}
      <span ref={panLeftRef}>
        {imageZoomMode && isComponentVisible && (
          <ActionIcon t={50} l={20} action={panLeft} icon="fa fa-arrow-left" />
        )}
      </span>
      {/* src={process.env.PUBLIC_URL + filename} */}
      <div className={`drag-container-inner`} ref={containerRef}>
        <img
          ref={imageRef}
          className="drag-img"
          style={{ width: sizes().windowWidth * 0.5 * 0.5 }}
        />

        {/* <progress
          ref={progressBarRef}
          id="progress"
          value="0"
          max="100"
          style="width: 100%;"
        ></progress> */}
      </div>
    </div>
  ) : (
    <div
      className={`relative ${
        !isResponsiveMode() ? "drag-container-pc" : ""
      }  overflow-hidden overflow-hidden`}
      style={{
        width: "100%",
      }}
    >
      {/* item */}
      {/* src={process.env.PUBLIC_URL + filename} */}
      <div
        className="relative w-full h-screen flex items-center justify-center"
        ref={containerRef}
      >
        <img
          ref={imageRef}
          className="rounded-lg"
          style={{
            height: () => sizes().windowHeight * 0.5,
            top: "2470px",
          }}
        />
      </div>
    </div>
  );
}

export function ActionIcon({ l, t, r, b, action, icon, ref }) {
  return (
    <>
      <div
        onClick={() => action()}
        className="fixed rounded-full px-1 z-50"
        style={{
          top: t && t + "%",
          bottom: b && b + "%",
          left: l && l + "%",
          right: r && r + "%",
        }}
      >
        <span
          className="p-4 rounded-full cursor-pointer border border-white"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
        >
          <i className={`${icon} text-white`}></i>
        </span>
      </div>
    </>
  );
}

export function RenderPerView({
  book,
  hasPurchasedBook,
  openSubModal,
  showRegistrationModal,
}) {
  useEffect(() => {}, []);
  return (
    <div className="flex flex-col justify-center items-center mb-4">
      <div className="text-white md:w-6/12">
        <div className="flex justify-center">
          <p className="w-8/12"></p>
          Please purchase or subscribe to {book.owner.name} to view the rest of
          the content. If you subscribe, you can view all of {book.owner.name}'s
          files and pages, both current and future, without any further paywall.
        </div>
      </div>
      <div className="flex justify-center w-full mt-4 z-50">
        {isAuthUser() ? (
          <div className="flex flex-col md:flex-row items-center gap-2">
            {book.enable_title_bar_purchase ? (
              <StripeForm
                book={book}
                classes="my-bg-yellow-500 text-black z-50"
                title={"Purchase"}
              />
            ) : (
              ""
            )}

            {book.owner &&
            book.owner.enable_subscription &&
            book.enable_title_bar_subscription ? (
              <BasicButton
                classes="my-bg-yellow-500 text-black text-xxs z-40 p-0"
                title={`Subscribe $${subscritionCostWithSystemFees(book)}/mth`}
                handleClick={() => openSubModal()}
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        {!isAuthUser() ? (
          <div className="flex flex-col md:flex-row gap-2">
            {book.enable_title_bar_purchase ? (
              <BasicButton
                title={`Purchase $${bookPriceWithSystemFees(book)}`}
                handleClick={() => showRegistrationModal("one-time")}
                classes="my-bg-yellow-500 text-black"
              />
            ) : (
              ""
            )}

            {book.owner &&
            book.owner.enable_subscription &&
            book.enable_title_bar_subscription ? (
              <BasicButton
                title={`Subscribe $${subscritionCostWithSystemFees(book)}/mth`}
                handleClick={() => showRegistrationModal("subscription")}
                classes="my-bg-yellow-500 text-black"
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <p className="mt-0"></p>
    </div>
  );
}

export function RenderInfoPage({ filename, book }) {
  const [donationAmount, setDonationAmount] = useState(0);
  const [platformModalOpen, setPlatformModalOpen] = useState(false);
  const [signupSuccess, setShowSignupSuccess] = useState(false);

  var controlsTimer;
  // const setTimerToHideControls = () => {
  //   controlsTimer && clearTimeout(controlsTimer);
  //   controlsTimer = setTimeout(() => {
  //     setIsComponentVisible(false);
  //   }, 4000);
  // };
  const [donationEmailModalOpen, setDonationEmaillModalOpen] = useState(false);
  const [donationEmail, setDonationEmaill] = useState("");
  const [customDonationMode, setCustomDonationMode] = useState("");

  useEffect(() => {
    // setIsComponentVisible(true);
  }, []);
  const coverPhoto = filename.cover_photo
    ? filename.cover_photo.split("public")[1]
    : "";
  let styles = {
    cover_Style: {
      backgroundPosition: "50%",
      backgroundImage: `url(${BASE_URL + "/storage" + coverPhoto})`,
    },
  };
  const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;

  const handleEmailChange = (e) => {
    setDonationEmaill(e);
  };

  const handleAmountChange = (e) => {
    if (rx_live.test(e)) {
      setDonationAmount(e);
    }
  };

  const showPlatformModal = () => {
    setPlatformModalOpen(true);
  };

  const hidePLatformModal = () => {
    setPlatformModalOpen(false);
  };

  const showDonationEmailModal = (type) => {
    setCustomDonationMode(type);
    setDonationEmaillModalOpen(true);
  };

  const hideDonationEmailModal = () => {
    setCustomDonationMode("");
    setDonationEmaillModalOpen(false);
  };

  const showSignupSuccess = () => {
    setShowSignupSuccess(true);
  };

  const showInvalidDonationAmount = () => {
    toast(
      "Please donations cannot be less than $1 as our payment providers take a percentage of the payment",
      { type: "notice" }
    );
  };

  useEffect(() => {
    // alert(filename.cover_photo ? "present" : "not present");
  }, []);
  return (
    <div
      className="h-screen flex flex-col items-center justify-center w-full relative p-1"
      style={styles.cover_Style}
    >
      <div
        className={` p-2  rounded-lg  w-full md:w-8/12 glassmorphism ${
          !filename.cover_photo ? "bg-white" : ""
        }`}
      >
        <p className="mt-8 p-1 text-center text-lg font-bold">Information</p>

        <div className="p-2 rounded-lg  h-28 overflow-y-scroll text-center">
          <p className="text-center text-black">[Tap to Scroll]</p>
          <p className="text-black text-xs">{filename.release_information}</p>
        </div>

        <div className="flex justify-center">
          {!signupSuccess ? (
            <button
              className={`text-white right-2.5 bottom-2.5 bg-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-1.5 py-2 dark:bg-black dark:hover:bg-back dark:focus:ring-blue-800 text-xs 
              `}
              onClick={() => showPlatformModal()}
            >
              Sign up for updates
            </button>
          ) : (
            ""
          )}

          {signupSuccess ? (
            <h3 className="text-2xl">Thanks for Signing up</h3>
          ) : (
            ""
          )}
        </div>
      </div>

      <div
        className={`m-2 p-4 rounded-lg md:w-8/12 flex ${
          isResponsiveMode() ? "flex-col items-center" : "flex-row"
        } ${
          filename.amount > 0 ? "justify-between" : "justify-center"
        }  items-center glassmorphism ${
          !filename.cover_photo ? "bg-white" : ""
        }`}
      >
        {/* <BasicButton
          title={`Donate $${filename.donation_amount}`}
          handleClick={() => null}
        /> */}
        <div className="md:w-full flex items-center md:items-start">
          {filename.amount > 0 ? (
            isAuthUser() ? (
              <StripeDonateForm
                book={book}
                amount={filename.amount}
                email={donationEmail}
              />
            ) : (
              <BasicButton
                title={`Donate $${donationPriceWithSystemFees(
                  book,
                  filename.amount
                )}`}
                classes=""
                handleClick={() => showDonationEmailModal("normal")}
              />
            )
          ) : (
            ""
          )}
        </div>
        {filename.amount > 0 ? (
          <div className="p-2 w-full flex text-center">
            Or enter a different amount
          </div>
        ) : (
          ""
        )}
        <div className="flex items-center w-full">
          <TextNumberField
            placeholder={`Enter amount (USD)`}
            classes="rounded-none"
            handleChange={handleAmountChange}
            value={donationAmount}
          />

          {isAuthUser() ? (
            <StripeCustomDonateForm
              book={book}
              amount={donationAmount}
              email={
                isAuthUser() ? JSON.parse(isAuthUser()).email : donationEmail
              }
            />
          ) : (
            <BasicButton
              title={`Donate`}
              classes="w-6/12 rounded-none"
              handleClick={() =>
                donationAmount < 1
                  ? showInvalidDonationAmount()
                  : showDonationEmailModal("custom")
              }
            />
          )}
        </div>
      </div>

      <PlatformUpdateModal
        modalOpen={platformModalOpen}
        hideModal={hidePLatformModal}
        type={"information_page"}
        showSignupSuccess={showSignupSuccess}
        book={book}
      />

      <DonationEmailModal
        modalOpen={donationEmailModalOpen}
        hideModal={hideDonationEmailModal}
        handleEmailChange={handleEmailChange}
        email={donationEmail}
        amount={
          customDonationMode == "custom" ? donationAmount : filename.amount
        }
        book={book}
      />
    </div>
  );
}

const CustomizeZoomButtonsExample = ({ zoomPluginHandle }) => {
  const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginHandle;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div className="flex justify-center">
        <div style={{ padding: "0px 2px" }}>
          <ZoomOut>
            {(RenderZoomOutProps) => (
              <i
                class="fa fa-minus cursor-pointer text-white"
                onClick={RenderZoomOutProps.onClick}
              ></i>
            )}
          </ZoomOut>
        </div>
        <div style={{ padding: "0px 2px" }} className="mx-2">
          <CurrentScale>
            {(RenderCurrentScaleProps) => (
              <>{`${Math.round(RenderCurrentScaleProps.scale * 100)}%`}</>
            )}
          </CurrentScale>
        </div>
        <div style={{ padding: "0px 2px" }} className>
          <ZoomIn>
            {(RenderZoomInProps) => (
              <i
                class="fa fa-plus cursor-pointer text-white"
                onClick={RenderZoomInProps.onClick}
              ></i>
            )}
          </ZoomIn>
        </div>
      </div>
    </div>
  );
};

export function CountdownTimer({
  restoreNavigation,
  showAdTimerElapsedText,
  showAdTimerFakeText,
  book,
}) {
  const countDownDate = new Date().getTime();
  const [countDown, setCountDown] = useState(book.timer);
  const [countDownFake, setCountDownFake] = useState(book.timer + 10);

  useEffect(() => {
    const intervalReal = setInterval(() => {
      setCountDown(countDown - 1);
      clearInterval(intervalReal);
    }, 1000);

    const intervalFake = setInterval(() => {
      setCountDownFake(countDownFake - 1);
      clearInterval(intervalFake);
    }, 1000);

    if (countDown == 0) {
      clearInterval(intervalReal);
      restoreNavigation();
    }

    if (countDownFake == 0) {
      clearInterval(intervalFake);
      showAdTimerElapsedText();
    }

    return () => {
      // clearInterval(intervalReal);
      // clearInterval(intervalFake);
    };
  }, [countDownDate]);

  const showOrHideCounter = () => {
    if (book.show_timer) {
      return "wait " + countDown + " seconds to view";
    } else {
      return "";
    }
  };

  return <span>{` ${!showAdTimerFakeText ? showOrHideCounter() : ""}`} </span>;
}

export default WebReader;
