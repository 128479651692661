import {
  isAuthUser,
  authUserData,
  isAdmin,
  isPublisher,
  isReader,
  isResponsiveMode,
} from "../../../../Utils/helpers";
export default function CreateAccountBar(props) {
  const renderItem = () => !isAuthUser();

  return renderItem() ? (
    <div className="w-full flex justify-center self-center">
      <button
        onClick={() => (window.location.href = `/signup/options`)}
        class="text-base font-medium text-white hover:text-white mx-2 bg-black rounded-lg p-2 w-full text-center"
      >
        <span>Create Account</span>
      </button>
    </div>
  ) : (
    ""
  );
}
