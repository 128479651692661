import TextArea from "../../../../../../../../components/inputs/text-area";
export default function PinterestEmbedBox(props) {
  const { handleChange, embedValue } = props;
  return (
    <div>
      <TextArea
        handleChange={(e) => handleChange(e, "pinterest")}
        value={embedValue}
      />
    </div>
  );
}
