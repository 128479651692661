import axios from "axios";
import { useEffect, useState } from "react";
import BasicButton from "../../../components/buttons/basic-button";
import { isAdmin, API_BASE } from "../../../Utils/helpers";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
export default function AdminSinlgleAdUnitReport() {
  let { adId } = useParams();
  const [dateFilter, setDateFilter] = useState("");
  const [reports, setReports] = useState([]);
  const [advert, setAdvert] = useState({});
  const fetchAdData = () => {
    const url = API_BASE + "/api/adverts/" + adId;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setAdvert(response.data.advert);
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const fetchReport = () => {
    const url = API_BASE + "/api/reports/" + dateFilter;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setReports(response.data);
          console.log("Adsense reports", response.data);
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const handleDateFilterChange = (e) => {
    setDateFilter(e);
  };

  useEffect(() => {
    // getPublishers();
    // console.log("Reports", reports[0]["headers"]);
    // console.log("Adsence Report", JSON.parse(JSON.stringify(reports)));
    if (!isAdmin()) {
      window.location.href = "/";
    }
    fetchReport();
    fetchAdData();
  }, []);

  const extractPublisherMetrics = (data) => {
    if (data.length) {
      return data.filter(
        (row, i) =>
          (i == 0 && row.value.split(":")[1] == advert.data) ||
          row.value.split(":")[1] == advert.data_mobile
      );
    }
    return [];
  };

  useEffect(() => {
    fetchReport();
  }, [dateFilter]);
  return (
    <>
      <div className="main w-full">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">Reports</h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            <div className="flex justify-center md:justify-end gap-4 items-center">
              <div>Filter:</div>
              <div className="w-4/12 mb-2">
                <select
                  className=" form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  onChange={(e) => handleDateFilterChange(e.target.value)}
                  value={dateFilter}
                >
                  <option value="TODAY" default>
                    Today
                  </option>
                  <option value="YESTERDAY">Yesterday</option>
                  <option value="LAST_7_DAYS">Last 7 Days</option>
                  <option value="LAST_30_DAYS">Last 30 Days</option>
                </select>
              </div>
            </div>
            <table class="table-auto w-full">
              <thead>
                <tr className={window.innerWidth < 720 ? "hidden" : ""}>
                  {reports.headers &&
                    reports.headers.map((header, i) => (
                      <th class="px-4 py-2 text-gray-600">{header.name}</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {reports.rows &&
                  reports.rows.map((row) => {
                    return (
                      <tr
                        className={
                          window.innerWidth < 820
                            ? "flex flex-col items-center"
                            : ""
                        }
                      >
                        {row["cells"].map((data, i) => {
                          return extractPublisherMetrics(row["cells"])
                            .length ? (
                            <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                              <h3 className="font-bold md:hidden">
                                {reports.headers[i].name}
                              </h3>
                              <span className="break-words">{data.value}</span>
                              <span>
                                {/* Count{i == 0 ? data.value.split(":")[1] : "false"} */}
                              </span>
                              {/* {publisher.name} */}
                            </td>
                          ) : (
                            ""
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
