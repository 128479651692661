import Normal from "./Normal.png";
import Selected from "./Selected.png";
export default function BoldIcon(props) {
  const { selected } = props;

  const iconParentStyle = {
    height: 30,
    width: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div
      style={{
        ...iconParentStyle,
      }}
    >
      <img
        src={selected ? Selected : Normal}
        style={{ height: 24, width: 24 }}
      />
    </div>
  );
}
