import { useSendMailStore } from "../../../../stores/send-mail-store";
import { isResponsiveMode } from "../../../../Utils/helpers";
import Checkbox from "../../../../components/inputs/checkbox";
export default function DesktopPubTrackersTable(props) {
  const mailStore = useSendMailStore((state) => state);

  const { blockedEmails } = props;
  const { shouldMarkItem } = props;
  const { markItem } = props;

  return (
    <table class="table-auto w-full my-4">
      <thead>
        <tr className={window.innerWidth < 720 ? "hidden" : ""}>
          <th class="px-4 py-2 text-gray-600 text-center">Name</th>
          <th class="px-4 py-2 text-gray-600 text-center">Country</th>
          <th class="px-4 py-2 text-gray-600 text-center">Date</th>
          <th class="px-4 py-2 text-gray-600 text-center">Action</th>
        </tr>
      </thead>
      <tbody className="w-full">
        {mailStore.data.data &&
          mailStore.data.data.map((t) => {
            return (
              <tr
                className={
                  window.innerWidth < 820 ? "flex flex-col items-center " : ""
                }
              >
                <td
                  class={`${
                    isResponsiveMode() ? "border text-center" : ""
                  } px-4 py-2 font-medium md:hidden `}
                >
                  {!blockedEmails.includes(t.track_data.trackers.email) ? (
                    <div className="md:hidden">
                      {" "}
                      <Checkbox
                        checked={shouldMarkItem(t.id) ? true : false}
                        handleChange={() => markItem(t.id)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </td>
                <td class="border px-4 py-2  font-medium ">
                  <h3 className="font-bold md:hidden">Name</h3>
                  {t.track_data.trackers.name}
                </td>
                <td class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Country</h3>
                  {t.track_data.trackers.country}
                </td>

                <td class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Date</h3>
                  <p>{t.refactoredCreatedAtTime}</p>
                </td>
                <td
                  class={`${
                    !isResponsiveMode() ? "border" : ""
                  } px-4 py-2  font-medium text-center `}
                >
                  {!blockedEmails.includes(t.track_data.trackers.email) ? (
                    <div className="hidden md:block">
                      {" "}
                      <Checkbox
                        checked={shouldMarkItem(t.id) ? true : false}
                        handleChange={() => markItem(t.id)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}
