import RadioBox from "../../../../../../components/inputs/radio";
export default function UploadOptions(props) {
  const { uploadType } = props;
  const { handleUploadTypeChange } = props;
  const { createImageUploadMode } = props;
  const { handleSocialEmbdedCodeChange } = props;

  const canShowOptions = () => {
    return createImageUploadMode;
  };

  return (
    canShowOptions() && (
      <div className="flex gap-2 w-full justify-center text-xs md:text-base">
        <div>
          <RadioBox
            checked={uploadType == "upload"}
            handleChange={() => {
              handleSocialEmbdedCodeChange("");
              handleUploadTypeChange("upload");
            }}
          />{" "}
          <span>Upload Image</span>
        </div>
        <div>
          <RadioBox
            checked={uploadType == "youtube"}
            handleChange={() => {
              handleSocialEmbdedCodeChange("");
              handleUploadTypeChange("youtube");
            }}
          />{" "}
          <span>YouTube</span>
        </div>
        <div>
          <RadioBox
            checked={uploadType == "instagram"}
            handleChange={() => {
              handleSocialEmbdedCodeChange("");
              handleUploadTypeChange("instagram");
            }}
          />{" "}
          <span>Instagram</span>
        </div>

        <div>
          <RadioBox
            checked={uploadType == "facebook"}
            handleChange={() => {
              handleSocialEmbdedCodeChange("");
              handleUploadTypeChange("facebook");
            }}
          />{" "}
          <span>Facebook</span>
        </div>
        <div>
          <RadioBox
            checked={uploadType == "twitter"}
            handleChange={() => {
              handleSocialEmbdedCodeChange("");
              handleUploadTypeChange("twitter");
            }}
          />{" "}
          <span>Twitter</span>
        </div>
        <div>
          <RadioBox
            checked={uploadType == "pinterest"}
            handleChange={() => {
              handleSocialEmbdedCodeChange("");
              handleUploadTypeChange("pinterest");
            }}
          />{" "}
          <span>Pinterest</span>
        </div>
      </div>
    )
  );
}
