import { isAuthUser } from "../../../../../Utils/helpers";
export default function UnauthorizedMenuItems(props) {
  const { showMobileMenu } = props;
  const renderMenu = () => {
    return !isAuthUser();
  };
  return renderMenu() && !showMobileMenu ? (
    <>
      <a
        href="/about"
        class="text-base font-medium text-gray-500 hover:text-gray-900 mx-2"
      >
        About
      </a>

      <a
        href="/contact-us"
        class="text-base font-medium text-gray-500 hover:text-gray-900"
      >
        Contact Us
      </a>
    </>
  ) : (
    ""
  );
}
