import { deleteAd } from "../helpers/functions";
import { useBookStore } from "../../../../stores/book-store";
export default function ButtonLinkBox({
  name,
  blockKey,
  editorState,
  setEditorState,
  editorStateHelper,
}) {
  const { isDataSubExpired } = useBookStore((state) => state);

  return (
    <div class="flex flex-wrap justify-center">
      <p className="bg-pink-100 p-2 rounded-lg relative">
        {!isDataSubExpired ? (
          <span
            className="bg-white rounded rounded-full bg-white p-0  right-0 absolute px-1 cursor-pointer"
            style={{ top: -19, right: 0 }}
            onClick={() =>
              deleteAd(blockKey, editorState, setEditorState, editorStateHelper)
            }
          >
            <i class="fa fa-times-circle text-red-500 z-50 text-2xl"></i>
          </span>
        ) : (
          ""
        )}
        <span className="text-black font-bold">{name}</span>{" "}
      </p>
    </div>
  );
}
