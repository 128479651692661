export default function MSharedMenuItems() {
  return (
    <>
      {" "}
      <a href="https://litzapp.com/share/sh/cOVVi27hzB" target="_blank">
        {" "}
        <p class="text-base font-bold">Frequently Asked Questions</p>
      </a>
      <a href="/about">
        <p class="text-base font-bold">About Us</p>
      </a>
      <a href="/terms">
        {" "}
        <p class="text-base font-bold">Terms & Conditions</p>
      </a>
      <a href="/sales-terms">
        {" "}
        <p class="text-base font-bold">Sales Account Terms of Service</p>
      </a>
      <a href="/privacy-policy">
        <p class="mb-4 text-base font-bold">Privacy Policy</p>
      </a>
      <a href="/contact-us">
        {" "}
        <p class="text-base font-bold">Contact Us</p>
      </a>
      <a className="text-center">
        <p class="">Remote Analytics Jobs Inc.</p>
        <p class="">126 Southwest 17th Road,</p>
        <p>Miami, Florida 33129</p>
        <p>Unitded States of America.</p>
        <br />

        <p class="mb-4 text-base font-bold">Telephone number</p>
        <a href="tel:+16692915495"> 📱 +1 (305) 927-1096</a>
      </a>
    </>
  );
}
