export const SPOptions = [
  { label: "Buy", value: 1 },
  { label: "Order", value: 2 },
  { label: "Pre-order", value: 3 },
  { label: "Book", value: 4 },
  { label: "Reserve", value: 5 },
  { label: "Hire", value: 6 },
  { label: "Pay", value: 6 },
  { label: "Purchase", value: 7 },
  { label: "Upgrade", value: 8 },
  { label: "Access", value: 9 },
  { label: "Enroll", value: 10 },
  { label: "Register", value: 11 },
  { label: "Get Started", value: 12 },
  { label: "Join", value: 13 },
  { label: "Invest", value: 14 },
  { label: "Donate", value: 15 },
  { label: "Support", value: 16 },
  { label: "Sponsor", value: 17 },
  { label: "Contribute", value: 18 },
  { label: "Pledge", value: 19 },
];

export const REOptions = [
  { label: "Daily Subscription", value: 1 },
  { label: "Weekly Subscription", value: 2 },
  { label: "Fortnightly Subscription", value: 3 },
  { label: "Monthly Subscription", value: 4 },
  { label: "Daily Hire", value: 5 },
  { label: "Weekly Hire", value: 6 },
  { label: "Fortnightly Hire", value: 7 },
  { label: "Monthly Hire", value: 8 },
  { label: "Daily Property Rental", value: 9 },
  { label: "Weekly Property Rental", value: 10 },
  { label: "Fortnightly Property Rental", value: 11 },
  { label: "Monthly Property Rental", value: 12 },
  { label: "Daily Item Rental", value: 13 },
  { label: "Weekly Item Rental", value: 14 },
  { label: "Fortnightly Item Rental", value: 15 },
  { label: "Monthly Item Rental", value: 16 },
  { label: "Daily Vacation Rental", value: 17 },
  { label: "Weekly Vacation Rental", value: 18 },
  { label: "Fortnightly Vacation Rental", value: 19 },
  { label: "Monthly Vacation Rental", value: 20 },
];

export const REPriceLabsOptions = [
  { label: "Daily Vacation Rental", value: 17 },
];

export const REMonthOptions = [
  { label: "Fortnightly", value: 1 },
  { label: "Weekly", value: 2 },
  { label: "Daily", value: 3 },
];

export const REForthnightlyOptions = [
  { label: "Weekly", value: 1 },
  { label: "Daily", value: 2 },
];

export const REWeekOptions = [{ label: "Daily", value: 1 }];
