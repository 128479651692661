import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import WebHeader from "../../../../../pages/web/header";
import Footer from "../../../../../layout/footer";
import RatingPromptModal from "../../../../../modals/rating-prompt-modal";
import {
  BASE_URL,
  API_BASE,
  getLocation,
  isAuthUser,
  authUserData,
} from "../../../../../Utils/helpers";
import { useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import RatingBox from "../../../../rating";
import BasicButton from "../../../../buttons/basic-button";
import openRequestService from "../../../../../services/HttpRequests/unauthenticated-request-services";
import authRequestService from "../../../../../services/HttpRequests/authenticated-request-services";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import uuid from "react-uuid";
export default function CustomBookList() {
  const { listid } = useParams();
  const [books, setBooks] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [nextPageURL, setNextPageURL] = useState("");
  const [moreTags, setMoreTags] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [listName, setListName] = useState("");
  const [listPageId, setListPageId] = useState("");
  const [currentRating, setCurrentRating] = useState();
  const [lastRatedBookId, setLastRatedBookId] = useState("");
  const [lastRatedBook, setLastRatedBook] = useState();
  const [ratingPromptModalMessage, setShowRatingPromptModalMessage] =
    useState("");
  const [showRatingPromptModal, setShowRatingPromptModal] = useState(false);
  const navigate = useNavigate();

  const getBooks = () => {
    setBooks([]);
    setProcessing(true);
    const url =
      API_BASE +
      `/api/books/lists/web/list/${listid}/${searchText.replace("/", "___")}`;
    window.scrollTo(0, 400);
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setBooks(response.data.books.data);
          setListName(response?.data?.list_data.name);
          setListPageId(response?.data?.list_data.list_page_id);
          setNextPageURL(response.data.books.next_page_url);
        }
        setProcessing(false);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const loadMore = () => {
    if (nextPageURL) {
      setProcessing(true);
      const url =
        getLocation().hostname == "litzapp.com"
          ? nextPageURL.split("api/api")[1]
          : nextPageURL.split("api")[1];

      openRequestService()
        .get(url)
        .then((response) => {
          setProcessing(false);
          if (response.status == 200) {
            setBooks((prev) => [...prev, ...response.data.books.data]);
            setNextPageURL(response.data.books.next_page_url);
          }
        })
        .catch((error) => {
          setProcessing(false);
          toast("Something went wrong", { type: "error" });
        });
    }
  };

  const rateBook = (value, book) => {
    const url = API_BASE + "/rating";
    if (!isAuthUser()) {
      openRatingPromtModal("Please###");
      return false;
    }
    if (book?.owneroptimized?.uuid == authUserData().uuid) {
      return false;
    }

    setLastRatedBookId(book.uuid);
    setCurrentRating(value);
    authRequestService()
      .post(url, {
        value: value,
        bookId: book.uuid,
        userId: authUserData().uuid,
      })
      .then((response) => {
        if (response.status == 200) {
          setLastRatedBook(response.data.book);
        }
      })
      .catch((error) => {
        if (error.response.status == 422) {
          if ("no_purchase_record" in error.response.data) {
            openRatingPromtModal(
              "You can only leave a rating if you have purchased the product."
            );
            return false;
          }
          openRatingPromtModal(
            "You can only leave a rating if you have viewed the content to its last slide/page."
          );
          return false;
        }
        toast("Something went wrong", { type: "error" });
      });
  };

  const recordPageView = () => {
    const url = API_BASE + "/api/page/views";

    if (localStorage.getItem("unique_visitor_id")) {
      axios
        .post(url, {
          uuid: listPageId,
          unique_user_id: localStorage.getItem("unique_visitor_id"),
        })
        .catch((e) => {});
    } else {
      const userUniqueId = uuid();
      localStorage.setItem("unique_visitor_id", userUniqueId);
      axios
        .post(url, {
          uuid: listPageId,
          unique_user_id: userUniqueId,
        })
        .catch((e) => {});
    }
  };

  const openRatingPromtModal = (msg) => {
    setShowRatingPromptModalMessage(msg);
    setShowRatingPromptModal(true);
  };

  const hideRatingPromtModal = () => {
    setShowRatingPromptModalMessage("");
    setShowRatingPromptModal(false);
  };

  const renderDisplayImage = (data) => {
    if (data?.cover_photo_thumb) {
      return (
        BASE_URL + "/storage" + data?.cover_photo_thumb?.split("public")[1]
      );
    }

    if (data?.productimages?.length) {
      return (
        BASE_URL +
        "/storage" +
        data?.productimages[0]?.product_thumb?.split("public")[1]
      );
    }

    if (data?.owner_optimized_data?.profile_photo) {
      return (
        BASE_URL +
        "/storage/" +
        data?.owner_optimized_data?.profile_photo?.split("public")[1]
      );
    } else {
      return (
        BASE_URL +
        "/storage/" +
        data?.owner_optimized_data?.backup_profile_photo?.split("public")[1]
      );
    }
  };

  const goToContent = (d) => {
    if (d.group == "custom" && d.type == "product") {
      window.location.href = "/web/rich-page/render/" + d.uuid;
    }
  };

  const renderTags = (book) => {
    let tagsToRender = 0;
    let updatedTags = [];

    if (moreTags.includes(book.uuid)) {
      for (let index = 0; index < book.tags.length; index++) {
        updatedTags.push({ type: "tag", content: book.tags[index] });

        if (tagsToRender == book.tags.length - 1) {
          updatedTags.push({ type: "view_less", content: "" });
        }
        tagsToRender++;
      }
    } else {
      for (let index = 0; index < book.tags.length; index++) {
        tagsToRender++;
        if (tagsToRender > 3) {
          updatedTags.push({ type: "view_more", content: "" });
          break;
        } else {
          updatedTags.push({ type: "tag", content: book.tags[index] });
        }
      }
    }

    return updatedTags.map((t) => {
      if (t.type == "tag") {
        return (
          <div
            className="rounded-full text-black border p-1 inline relative m-1 px-1 bg-pink-50 cursor-pointer"
            onClick={() => null}
          >
            <span className="text-xs pr-1">${t.content.name}</span>
          </div>
        );
      }

      if (t.type == "view_more") {
        return (
          <p
            className="text-xs text-blue-500 cursor-pointer underline"
            onClick={() => showMoreTags(book.uuid)}
          >
            View More Tags...
          </p>
        );
      }

      if (t.type == "view_less") {
        return (
          <p
            className="text-xs text-blue-500 cursor-pointer underline"
            onClick={() => showLessTags(book.uuid)}
          >
            View Less Tags...
          </p>
        );
      }
    });
  };

  const showMoreTags = (id) => {
    setMoreTags((prev) => [...prev, id]);
  };

  const showLessTags = (id) => {
    let currTags = moreTags;
    let filteredTags = currTags.filter((t) => t !== id);
    setMoreTags(filteredTags);
  };

  useEffect(() => {
    getBooks();
  }, []);

  useEffect(() => {
    if (listPageId) {
      //localStorage.removeItem("unique_visitor_id");
      console.log("recording...");
      recordPageView(localStorage.getItem("unique_visitor_id"));
      const timer = setTimeout(function () {}, 500);

      return () => clearTimeout(timer);
    }
  }, [listPageId]);

  const bookProps = {
    books,
    renderDisplayImage,
    renderTags,
    goToContent,
    rateBook,
    lastRatedBook,
  };

  return (
    <>
      {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
      <WebHeader />
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">
            {" "}
            {listName && listName}
          </h1>
        </div>
        <div className="flex flex-col md:flex-row justify-between gap-2">
          <BasicButton title={"Go Back"} handleClick={() => navigate("/")} />
        </div>
      </div>
      <main class="bg-gray-100 bg-opacity-25 pt-0 flex justify-center">
        <div class="w-10/12 lg:w-8/12 lg:mx-auto mb-8">
          <RenderBasicBook {...bookProps} />
        </div>
      </main>
      <div className="flex justify-center w-full">
        <button
          disabled={!nextPageURL || processing}
          class={`whitespace-nowrap inline-flex items-center justify-center px-8 py-4 mt-8 border border-transparent rounded-md shadow-sm text-base font-medium text-white ${
            !nextPageURL || processing
              ? "bg-gray-500 hover:bg-gray-500"
              : "bg-black"
          } hover:bg-black md:mr-8 `}
          onClick={() => !processing && loadMore()}
        >
          {processing ? "Loading..." : "View More"}
        </button>
      </div>
      <RatingPromptModal
        hideAdModal={hideRatingPromtModal}
        modalOpen={showRatingPromptModal}
        body={ratingPromptModalMessage}
      />

      <Footer />
    </>
  );
}

const RenderBasicBook = (props) => {
  const { books } = props;
  const { renderDisplayImage } = props;
  const { renderTags } = props;
  const { currentRating } = props;
  const { lastRatedBook } = props;
  const { goToContent } = props;
  const { rateBook } = props;

  return (
    <div className="relative flex flex-col md:grid md:grid-cols-4 md:items-start content-center justify-center w-full gap-4 items-center">
      {" "}
      {books.map((data) => (
        <div className="p-3 shadow m-2 text-center z-50 w-10/12 md:w-full cursor-pointer flex flex-col items-center">
          <img
            className="w-36 h-36  md:w-36 md:h-36 object-cover rounded-full bg-gray-300 
                       p-1"
            onClick={() => goToContent(data)}
            src={renderDisplayImage(data)}
          />

          <div className="">
            <p className="font-bold text-lg text-center">{data.name}</p>

            <p className="my-2 text-xs">
              <span className="text-xs"> Item ID: </span>
              <span className="text-yellow-600 text-xs font-bold">
                {data.item_id}
              </span>
            </p>
            <p className="text-red-500 text-sm">by</p>
            <p className="text-black-500">{data?.owneroptimized?.name}</p>

            <p className="text-black-500 mt-2">
              Views{" "}
              {data.pages && data.pages.length && `(${data.pages[0].views})`}
            </p>
          </div>
          <RatingBox
            book={data}
            lastRatedBook={lastRatedBook}
            rateBook={rateBook}
            currentRating={currentRating}
          />
          <div className="flex flex-wrap  justify-center items-center">
            {data.tags && renderTags(data)}
          </div>
        </div>
      ))}
    </div>
  );
};
