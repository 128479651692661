import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import WebHeader from "../header";
import { useParams } from "react-router-dom";
import Footer from "../../../layout/footer";
import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import BlockSuccess from "./components/email-block/block-success";
import BlockWarning from "./components/email-block/block-warning";
import InvalidLink from "./components/email-block/invalid-link";
import BlockAction from "./components/email-block/block-action";

import {
  API_BASE,
  getLocation,
  getClientBaseURL,
} from "../../../Utils/helpers";
import axios from "axios";

export default function BlockEmailUpdates() {
  const [email, setEmail] = useState("");
  const [isBlocked, setIsBlocked] = useState(false);
  const [isInvalidLink, setIsInvalidLink] = useState(false);
  const [blockData, setBlockData] = useState({});
  const [pageData, setPageData] = useState({});
  const [emailLinkSent, setEmailLinkSent] = useState(false);

  const { subscriberEmail, publisherEmail, service } = useParams();
  console.log(`${subscriberEmail} ${publisherEmail}`);
  const [processing, setProcessing] = useState(false);

  const stopEmails = () => {
    const url =
      API_BASE +
      "/api/publisher/mail/unsubscribe/" +
      subscriberEmail +
      "/" +
      publisherEmail +
      "/" +
      service;
    setProcessing(true);
    axios
      .post(url)
      .then((response) => {
        setProcessing(false);
        if (response.data.blocked == 1) {
          setIsBlocked(true);
        }
        setBlockData(response.data);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const getPageData = () => {
    const url =
      API_BASE +
      "/api/publisher/mail/user/validate/data/" +
      subscriberEmail +
      "/" +
      publisherEmail +
      "/" +
      service;
    setProcessing(true);
    axios
      .post(url)
      .then((response) => {
        setProcessing(false);

        if (response.data.blocked == 1) {
          setIsBlocked(true);
        }
        if (response.data.invalid_link == 1) {
          setIsInvalidLink(true);
        }
        if (!response.data.publisher?.name) {
          // toast("Invalid Link!", { type: "error" });
          return false;
        }
        setPageData(response.data);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const enableBlockButton = (e) => {
    return pageData?.publisher?.name;
  };

  useEffect(() => {
    if (subscriberEmail && publisherEmail) {
      getPageData();
    }
  }, []);

  return (
    <>
      <div class="px-6">
        <br />
        <div class="flex flex-col text-center md:text-left justify-evenly items-center">
          <div class="flex flex-col w-full items-center">
            <div className="text-center w-10/12 md:w-6/12">
              <h2 className="text-2xl font-bold">STOP UPDATE EMAILS </h2>
              <InvalidLink isInvalidLink={isInvalidLink} />
              <BlockWarning
                data={pageData}
                isBlocked={isBlocked}
                enableBlockButton={enableBlockButton}
              />
              <BlockSuccess data={pageData} isBlocked={isBlocked} />
              <br />
              <BlockAction
                enableBlockButton={enableBlockButton}
                stopEmails={stopEmails}
                isBlocked={isBlocked}
                processing={processing}
                isInvalidLink={isInvalidLink}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
