import TextField from "../../components/inputs/text-input";
import BasicButton from "../../components/buttons/basic-button";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  authUserData,
  isAuthUser,
  isAdmin,
  API_BASE,
  BASE_URL,
} from "../../Utils/helpers";
import { toast } from "react-toastify";
import BookSliderGridAdmin from "../../components/slider/book-slider-grid-admin";
import BookSliderGridPublisher from "../../components/slider/dashboard/book-slider-publisher-grid";
import ProfileList from "../../components/user/profiles/list";
import axios from "axios";
export default function AdminPublicationOptions() {
  const [publisher, setPublisher] = useState({});
  const [platformModalOpen, setPlatformModalOpen] = useState(false);
  const [signupSuccess, setShowSignupSuccess] = useState(false);
  const [books, setBooks] = useState([]);
  const { publisheruuid } = useParams();
  const [activeTab, setactActiveTab] = useState(0);
  const [fileSelected, setFileSelected] = useState();
  const [profileText, setProfileText] = useState("");
  const [showRatingDropdown, setShowRatingDropdown] = useState(false);
  const [currentRating, setCurrentRating] = useState(0);

  let { userId } = useParams();

  const applyRatingFilter = (value, book) => {};
  const getPublisher = () => {
    let url = API_BASE + "/api/publisher/" + userId;
    return axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setPublisher(response.data.publisher);
        }
      })
      .catch((error) => {
        // toast("Something went wrong!", { type: "error" });
        return publisher;
      });
  };

  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
    // getPublisher();
  }, []);

  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow items-center">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold flex flex-col md:flex-row">
            <span>Slides</span>{" "}
            <span className="text-gray-500 text-lg">
              ({publisher && publisher.name} )
            </span>
          </h1>
        </div>
        <div>
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <BookSliderGridAdmin
        publisherId={userId}
        publisher={publisher}
        getPublisher={getPublisher}
      />
    </>
  );
}
