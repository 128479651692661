import EditorTooltip from "../../tooltip/editor-tooltip";
import TextField from "../../../../../../../components/inputs/text-input";
import Checkbox from "../../../../../../../components/inputs/checkbox";
export default function QuantityBox(props) {
  const { productMeta, handleMetaChange } = props;
  return (
    <>
      {" "}
      {productMeta.quantity_enabled ? (
        <div className="m-2 mb-2">
          <TextField
            disabled={productMeta.quantity_merge}
            classes={"p-6 my-2"}
            placeholder={"Inventory Quantity.."}
            type="number"
            handleChange={(e) => handleMetaChange(e, "quantity", "text")}
            value={productMeta.quantity}
          />
          <p className="text-base text-pink-500">
            {/* {productMeta.quantity &&
                      productMeta.quantity == 0 &&
                      "Please enter a quantity greater than 0"} */}
          </p>
        </div>
      ) : (
        ""
      )}
      <div className="inline-flex gap-1 relative">
        <div>
          <Checkbox
            handleChange={(e) =>
              handleMetaChange(e, "quantity_sel_enabled", "checkbox")
            }
            checked={productMeta.quantity_sel_enabled}
          />
        </div>
        <span>Allow Quantity Selection by Customer</span>
        <EditorTooltip
          body={
            "If selected, it indicates that you would like the purchaser to determine the quantity of the item they can buy in one transaction"
          }
          top={"-180px"}
          right={"-90px"}
        />
      </div>
    </>
  );
}
