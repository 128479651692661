import { useSendMailStore } from "../../../../stores/send-mail-store";
import BasicButton from "../../../../components/buttons/basic-button";
import { isResponsiveMode } from "../../../../Utils/helpers";
import { useNavigate } from "react-router-dom";
export default function MobileTable(props) {
  const mailStore = useSendMailStore((state) => state);

  const { preDeleteSet } = props;
  const navigate = useNavigate();

  return (
    <div class="w-full flex flex-col justify-center my-4 text-center">
      {mailStore?.data?.data &&
        mailStore.data.data.map((d) => {
          return (
            <div
              className="overflow-x-scroll inline"
              style={{
                width: document.documentElement.clientWidth - 90 + "px",
                margin: "0 auto",
              }}
            >
              <div className="flex">
                <div class="border  px-4 py-2  font-medium">
                  <div className="text-center md:text-left">
                    <p className="break-words">
                      <p className="text-green-500"> Name</p>
                      <small className="text-base"> {d?.name}</small>
                    </p>
                  </div>
                </div>
                <div class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Email</h3>
                  <p>
                    <small className="text-base"> {d.email}</small>
                  </p>
                  <small className="text-base">
                    {" "}
                    Verified: {d.email_verified ? "Yes" : "No"}
                  </small>
                </div>
                <div class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Phone</h3>
                  <p>
                    <small className="text-base"> {d.phone}</small>
                  </p>
                  <small className="text-base">
                    {" "}
                    Verified: {d.phone_verified ? "Yes" : "No"}
                  </small>
                </div>

                <div class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Date</h3>
                  <p>
                    {d?.refactoredCreatedAtTime
                      ? new Date(
                          d?.refactoredCreatedAtTime.replace(/-/g, "/")
                        ).getDate() +
                        "-" +
                        (new Date(
                          d?.refactoredCreatedAtTime.replace(/-/g, "/")
                        ).getMonth() +
                          1) +
                        "-" +
                        new Date(
                          d.refactoredCreatedAtTime.replace(/-/g, "/")
                        ).getFullYear()
                      : "N/A"}
                  </p>
                </div>

                <div class="border  px-4 py-2  font-medium">
                  <div className="flex flex-col items-center gap-2">
                    {" "}
                    <BasicButton
                      disabled={!d.id}
                      title={"Edit"}
                      classes={
                        "bg-black-500 text-white  hover:text-white  w-full"
                      }
                      handleClick={() =>
                        navigate(
                          `/publisher/supplier/my-customer-services/set/${d.uuid}/edit`
                        )
                      }
                    />
                    <BasicButton
                      disabled={!d.uuid}
                      title={"Delete"}
                      classes={
                        "bg-red-500 text-white  hover:text-white  hover:bg-red-500 w-full"
                      }
                      handleClick={() => preDeleteSet(d)}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}
