import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import WebHeader from "./web/header";
import axios from "axios";
import BasicButton from "../components/buttons/basic-button";
import StripeForm from "../payment/pay-button";
import { API_BASE } from "../Utils/helpers";
import Timezones from "../components/timezone/timezone";
import StripeSubscriptionModal from "../modals/stripe-subscription-modal";
import {
  isAuthUser,
  authUserData,
  subscritionCostWithSystemFees,
} from "../Utils/helpers";
import Countries from "../components/country/country";
import { useBookStore } from "../stores/book-store";

export default function Register({ type, action, book, paymentType }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [emailConfirmationMode, setEmailConfirmationMode] = useState(false);
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [timeZone, setTimezone] = useState("");
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [subSecretKey, setSubSecretKey] = useState();
  const [showSubModal, setShowSubModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameMode, setUsernameMode] = useState(false);
  const naviagte = useNavigate();
  const { setSubModalOpen } = useBookStore((state) => state);
  const { setIsUserAuthenticated } = useBookStore((state) => state);

  const getStripePaymentSecret = () => {
    let formData = new FormData();
    const url = API_BASE + "/api/user/payment/get-stripe-payment-secret";
    setProcessing(true);
    axios
      .post(url, {
        book_id: book.uuid,
        user_id: isAuthUser() ? authUserData().uuid : "",
      })
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setSubSecretKey(response.data.secret_key);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", {
          type: "error",
        });
      });
  };

  const hideSubModal = () => {
    setSubSecretKey();
    setShowSubModal((prev) => (prev = !prev));
    setSubModalOpen(false);
  };

  const openSubModal = () => {
    getStripePaymentSecret();
    setShowSubModal(true);
    setSubModalOpen(true);
  };

  const register = () => {
    setIsAuthenticating(true);
    const url = API_BASE + "/api/register";

    axios
      .post(url, {
        name: firstName + " " + lastName,
        first_name: firstName,
        last_name: lastName,
        email: email,
        type: "transaction",
      })
      .then((response) => {
        if (response.status == 200) {
          if (!response.data) {
            //   alertMe.show(result.message, { type: "error" });
          } else {
            if (response.data) {
              let user = response.data.data;
              if (user.roles && user.roles.length > 0) {
                localStorage.setItem("user", JSON.stringify(user));
                // localStorage.setItem("user_email", user.email);
                localStorage.setItem("token", response.data.access_token);
                // localStorage.setItem("roles", JSON.stringify(user.roles));
                localStorage.setItem("registration_type", "transaction");

                toast("Registration Successful", { type: "success" });
                // handleEmailConfirmation();
                setRegisterSuccess(true);
                setUsernameMode(true);
                setUsername(user.username);
                // action();
              } else {
                toast("Error", { type: "error" });
                // alertMe.show("Unauthorized Access", { type: "error" });
              }
            }
          }
        }
        setIsAuthenticating(false);
      })
      .catch((error) => {
        if (error.response.status == 422) {
          toast("Duplicate registration, email address already registered!", {
            type: "error",
          });
        } else {
          toast("Something went wrong!", { type: "error" });
        }
        setIsAuthenticating(false);
      });
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e);
  };

  const handleLastNameChange = (e) => {
    setLastName(e);
  };

  const handleEmailChange = (e) => {
    setEmail(e);
  };

  const handleEmailConfirmation = () => {
    if (
      firstName.toLowerCase().includes("litzapp") ||
      firstName.toLowerCase().includes("litzap") ||
      lastName.toLowerCase().includes("litzapp") ||
      lastName.toLowerCase().includes("litzap")
    ) {
      toast(
        "Sorry! Litzapp cannot be used in First Name Or Last Name Field(s)!",
        {
          type: "error",
        }
      );
      return;
    }
    setEmailConfirmationMode((prev) => !prev);
  };

  const handleRegistration = () => {
    register();
  };

  const showPaymentOption = () => {
    copyText(username);
    setUsernameMode(false);
  };

  const copyText = (text) => {
    // standard way of copying
    var textArea = document.createElement("textarea");
    textArea.setAttribute("style", "width:1px;border:0;opacity:0;");
    document.body.appendChild(textArea);
    textArea.value = text;
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    toast("Copied", { type: "success" });
  };

  useEffect(() => {
    setInterval(() => {
      setIsUserAuthenticated("v");
    }, 500);
  }, []);
  return (
    <>
      {!emailConfirmationMode && (
        <div class="container px-6 mx-auto">
          <br />
          <div class="flex flex-col text-left justify-evenly items-center">
            <div class="flex flex-col w-full items-center">
              <h1 class="text-2xl text-gray-800 font-bold my-2">Register</h1>
              <p class="mx-auto md:mx-0 text-gray-500">
                Please fill out the form
              </p>
            </div>
            <div
              className={`w-full ${
                type !== "modal" ? "md:w-6/12 " : "md:w-10/12 "
              }${
                type !== "modal" ? "lg:w-5/12" : "lg:w-10/12"
              } mx-auto md:mx-0`}
            >
              <div class="bg-white p-10 flex flex-col w-full shadow-xl rounded-xl">
                {/* <h2 class="text-2xl font-bold text-gray-800 text-left mb-5">
                Sigin
              </h2> */}
                <form action="" class="w-full ">
                  <div id="input" class="flex flex-col items-start w-full my-5">
                    <label for="username" class="text-gray-500 mb-2 ">
                      First Name
                    </label>
                    <input
                      onChange={(e) => handleFirstNameChange(e.target.value)}
                      type="text"
                      id="username"
                      value={firstName}
                      placeholder="Please insert your First Name"
                      class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full"
                    />
                  </div>
                  <div id="input" class="flex flex-col items-start w-full my-5">
                    <label for="username" class="text-gray-500 mb-2">
                      Last Name
                    </label>
                    <input
                      onChange={(e) => handleLastNameChange(e.target.value)}
                      type="text"
                      id="username"
                      value={lastName}
                      placeholder="Please insert your Last Name"
                      class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full"
                    />
                  </div>
                  <div id="input" class="flex flex-col items-start w-full my-5">
                    <label for="username" class="text-gray-500 mb-2">
                      Email
                    </label>
                    <input
                      onChange={(e) => handleEmailChange(e.target.value)}
                      type="text"
                      id="username"
                      value={email}
                      placeholder="Please insert your email"
                      class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full"
                    />
                  </div>

                  {/* <div id="input" class="flex flex-col w-full my-5">
                    <label for="username" class="text-gray-500 mb-2 text-left">
                      Country
                    </label>
                    <Countries handleCountryChange={handleCountryChange} />
                  </div>

                  <div id="input" class="flex flex-col w-full my-5 ">
                    <label for="password" class="text-gray-500 mb-2 text-left">
                      Timezone
                    </label>
                    <Timezones handleTimezoneChange={handleTimezoneChange} />
                  </div>

                  <div id="input" class="flex flex-col items-start w-full my-5">
                    <label for="password" class="text-gray-500 mb-2 text-left">
                      Password
                    </label>
                    <input
                      onChange={(e) => handlePasswordChange(e.target.value)}
                      type="password"
                      id="password"
                      value={password}
                      placeholder="Please insert your password"
                      class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full"
                    />
                  </div>
                  <div id="input" class="flex flex-col items-start w-full my-5">
                    <label for="password" class="text-gray-500 mb-2">
                      Confirm Password
                    </label>
                    <input
                      onChange={(e) =>
                        handlePasswordConfirmationChange(e.target.value)
                      }
                      type="password"
                      id="password"
                      value={passwordConfirm}
                      placeholder="Please insert your password"
                      class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full"
                    />
                  </div> */}
                  <div id="button" class="flex flex-col w-full my-5">
                    <button
                      disabled={
                        isAuthenticating || !firstName || !lastName || !email
                      }
                      onClick={() => {
                        handleEmailConfirmation();
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      type="button"
                      class="w-full py-4 bg-black rounded-lg text-green-100"
                    >
                      {!isAuthenticating ? (
                        <div class="flex flex-row items-center justify-center">
                          <div class="mr-2">
                            <svg
                              class="w-6 h-6"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                              ></path>
                            </svg>
                          </div>
                          <div class="font-bold">Next</div>
                        </div>
                      ) : (
                        "Creating Account..."
                      )}
                    </button>
                    {/* <div class="flex justify-evenly mt-5">
                  <a
                    href="#"
                    class="w-full text-center font-medium text-gray-500"
                  >
                    Recover password!
                  </a>
                  <a
                    href="#"
                    class="w-full text-center font-medium text-gray-500"
                  >
                    Singup!
                  </a>
                </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {emailConfirmationMode && !registerSuccess && (
        <div class="container px-6">
          <br />
          <div class="flex flex-col text-center md:text-left justify-evenly items-center">
            <div class="w-full text-center">
              <p class="text-lg text-gray-800 font-bold my-2">
                Is <span className="text-green-500">{email}</span> spelt
                correctly.
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <BasicButton
                disabled={isAuthenticating}
                title={`${
                  isAuthenticating ? "Registering..." : "Yes, Register"
                }`}
                classes="w-full bg-teal-500 hover:bg-teal-500"
                handleClick={handleRegistration}
              />
              <BasicButton
                disabled={isAuthenticating}
                title={`No, Go Back`}
                classes="w-full bg-red-700"
                handleClick={handleEmailConfirmation}
              />
            </div>
          </div>
        </div>
      )}

      {registerSuccess && !usernameMode && (
        <div class="container px-6">
          <br />
          <div class="flex flex-col text-center md:text-left justify-evenly items-center">
            <div class="w-full text-center">
              <h1 class="text-3xl text-gray-800 font-bold my-2 mb-4">
                Payment
              </h1>
            </div>
            <div className="grid grid-cols-1 gap-4">
              {/* <BasicButton
                title={`Go Back`}
                classes="w-full"
                handleClick={handleEmailConfirmation}
              /> */}

              {book.owner ? (
                paymentType == "subscription" ? (
                  <BasicButton
                    title={`Subscribe  $${subscritionCostWithSystemFees(
                      book
                    )}/Month`}
                    handleClick={openSubModal}
                    disabled={processing}
                  />
                ) : (
                  <StripeForm book={book} action={action} />
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}

      {registerSuccess && usernameMode && (
        <div class="container px-6">
          <br />
          <div class="flex flex-col text-center md:text-left justify-evenly items-center">
            <div class="w-full text-center">
              <h1 class="text-3xl text-gray-800 font-bold my-2 mb-4">
                Payment
              </h1>
            </div>
            <div className="grid grid-cols-1 gap-4">
              <p>
                Your username is{" "}
                <span className="text-yellow-600 font-bold">{username}</span>
              </p>
              <BasicButton
                title={`Copy and Proceed`}
                handleClick={showPaymentOption}
                disabled={processing}
              />
            </div>
          </div>
        </div>
      )}
      <StripeSubscriptionModal
        modalOpen={showSubModal}
        hideModal={hideSubModal}
        book={book}
        subSecretKey={subSecretKey}
      />
    </>
  );
}
