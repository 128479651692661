import { useEffect } from "react";
import BasicButton from "../../../../../components/buttons/basic-button";
import { isAdmin } from "../../../../../Utils/helpers";

export default function AdminREDefaultContractsOpt() {
  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
  }, []);

  return (
    <>
      <div className="main w-full">
        <div className="bg-white m-2 p-2 flex justify-between shadow px-4">
          <div>
            <h1 className="text-2xl text-center m-2 bold">
              {" "}
              Default Contracts (Recurring Purchase)
            </h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex justify-center my-4 overflow-hidden">
          <div class="flex flex-col items-center p-10 gap-4 md:w-6/12">
            <div className="w-full">
              <a href="/admin/supplier/product-contracts/recurring/subscription-contract">
                <BasicButton
                  title={"Subscription"}
                  handleClick={() => window.history.back()}
                  classes={"w-full"}
                />
              </a>
            </div>
            <div className="w-full">
              <a href="/admin/supplier/product-contracts/recurring/hire-contract">
                <BasicButton
                  title={"Hire"}
                  handleClick={() => window.history.back()}
                  classes={"w-full"}
                />
              </a>
            </div>
            <div className="w-full">
              <a href="/admin/supplier/product-contracts/recurring/property-rental-contract">
                <BasicButton
                  title={"Property Rental"}
                  handleClick={() => window.history.back()}
                  classes={"w-full"}
                />
              </a>
            </div>
            <div className="w-full">
              <a href="/admin/supplier/product-contracts/recurring/item-rental-contract">
                <BasicButton
                  title={"Item Rental"}
                  handleClick={() => window.history.back()}
                  classes={"w-full"}
                />
              </a>
            </div>
            <div className="w-full">
              <a href="/admin/supplier/product-contracts/recurring/vacation-rental-contract">
                <BasicButton
                  title={"Vacation Rental"}
                  handleClick={() => window.history.back()}
                  classes={"w-full"}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
