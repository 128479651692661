import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import AdInsertModal from "../../../modals/ad-insert-modal";
import AdEditModal from "../../../modals/ad-edit-modal";
import InformationPageModal from "../../../modals/information-page-modal";
import PayPerViewModal from "../../../modals/pay-per-view-modal";
import EditBookModal from "../../../modals/edit-book-modal";
import FreeContentCoverLinks from "../rich-page/components/free-content-cover-links";
import BugReportNotice from "../rich-page/components/bug-report-notice";
import { isDataSubExpired } from "../../../services/DataThreshold";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";
import TierUpgradeNoticeModal from "../../../modals/data-tier-upgrade-notice";
import PublisherTutorialBar from "../../../layout/nav/header/mobile/tutorial-bar";

import {
  isAdmin,
  API_BASE,
  BASE_URL,
  isPublisher,
  isAuthUser,
  isResponsiveMode,
  getLocation,
} from "../../../Utils/helpers";
import { toast } from "react-toastify";
import PageSettingsModal from "../../../modals/page-settings-modal";
import ImageUploadSingleSmall from "../rich-page/components/image-upload-single-small";
import DeletePromptModal from "../../../modals/delete-warning-modal";
import EditBook from "../book/edit-book";

export default function Book() {
  const [name, setName] = useState("");
  const [publisher, setPublisher] = useState({});
  const [nameEditMode, setNameEditMode] = useState(false);
  const [price, setPrice] = useState("");
  const [priceEditMode, setPriceEditMode] = useState(false);
  const [email, setEmail] = useState("");
  const [publication, setPublication] = useState();
  const [previewMode, setPreviewMode] = useState(false);
  const [showAdModal, setShowAdModal] = useState(false);
  const [showAdEditModal, setShowAdEditModal] = useState(false);
  const [currentEditingPageAd, setCurrentEditingPageAd] = useState();
  const [currentEditingAdId, setcurrentEditingAdId] = useState();
  const [infoPageModalOpen, setInfoPageModalOpen] = useState(false);
  const [payPerViewModalOpen, setPayPerViewModalOpen] = useState(false);
  const [book, setBook] = useState();
  const [lowestPageValue, setLowestPageValue] = useState(0);
  const [highestPageValue, setHighestPageValue] = useState(0);
  const [pageSettingsModalOpen, setPageSettingsModalOpen] = useState(false);
  const [adTimerDelay, setAdTimerDelay] = useState(0);
  const [enableAdTimer, setEnableAdTimer] = useState(false);
  const [enablePayPerViewChange, setEnablePayPerViewChange] = useState(false);
  const [pagePerViewPositionValue, setPagePerViewPositionValue] = useState();
  const [showEditBookModal, setShowEditBookModal] = useState(false);
  const [currEditingBook, setCurrEditingBook] = useState();
  const [coverPhoto, setCoverPhoto] = useState("");
  const [fileSelected, setFileSelected] = useState("");
  const [showDeletePromptModal, setShowDeletePromptModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [enableTitleBarPurchaseChange, setEnableTitleBarPurchaseChange] =
    useState(false);
  const [enableTitleBarSubChange, setEnableTitleBarSubChange] = useState(false);
  const [setting, setSetting] = useState({});
  const [firstLoad, setFirstLoad] = useState(false);
  const [movingPage, setMovingPage] = useState(false);
  const [canShare, setCanShare] = useState(false);
  const [tierNoticeModalOpen, setTierNoticeModalOpen] = useState(false);
  const [tierModalNotice, setTierModalNotice] = useState("");
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState("");

  /*advert*/
  const [advertRequestStatus, setAvertRequestStatus] = useState("");
  /*advert*/

  //   const { pageAdvertId, setpageAdvertId } = useBookStore((state) => state);

  let { userId } = useParams();
  let { seriesId } = useParams();
  let { bookId } = useParams();
  let { device } = useParams();
  let firstLoadRef = useParams();

  const getPublication = () => {
    const url =
      API_BASE +
      "/api/book/" +
      bookId +
      "/series/" +
      seriesId +
      "/publisher/" +
      userId;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setBook(response.data.book);
          setCoverPhoto("");
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const movePage = (page, direction) => {
    // alert(`${page.name} ${direction}`);
    setMovingPage(true);
    const url =
      API_BASE +
      "/api/page/" +
      page.uuid +
      "/book/" +
      book.id +
      "/move/" +
      direction;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          getPublication();
          setMovingPage(false);
        }
      })
      .catch((error) => {
        // alert(error.message);
        setMovingPage(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const deletePage = (page) => {
    const url = `${API_BASE}/api/page/${page.uuid}/delete`;
    axios
      .delete(url)
      .then((response) => {
        if (response.status == 200) {
          getPublication();
          toast("Deleted", { type: "success" });
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const deleteBook = (book) => {
    setProcessing(true);
    const url = API_BASE + "/api/book/delete/" + book.uuid;
    axios
      .delete(url)
      .then((response) => {
        if (response.status == 200) {
          toast("Deleted", { type: "success" });
          setTimeout(() => {
            window.location.href = `/publisher/publications/options/${userId}`;
          }, 300);
          setProcessing(false);
        }
      })
      .catch((error) => {
        alert(error.message);
        console.error("There was an error!", error);
        setProcessing(false);
      });
  };

  const saveAd = (clickOrigin, page) => {
    const url = API_BASE + "/api/publisher/check-ad-request-status";
    setProcessing(true);
    axios
      .post(url, {
        page_id: page ? page.id : currentEditingPageAd.id,
        user_id: userId,
        book_type: "standard",
        click_origin: clickOrigin,
      })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status == "unprocessed") {
            //setAvertRequestStatus("unprocessed");
            if (clickOrigin == "from_page") {
              //setShowAdModal(true);
            } else {
              setShowAdModal(false);
              toast("Ad Code Request Submitted", { type: "success" });
            }
            getPublication();
          }

          if (
            response.data.status == "approved" ||
            response.data.status == "unprocessed"
          ) {
            getPublication();
            hideAdBox();
          }

          if (response.data.status == "rejected") {
            setAvertRequestStatus("rejected");
            setShowAdModal(true);
          }

          if (!response.data.status) {
            setShowAdModal(true);
          }
          setProcessing(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.data.invalid_ad_code) {
          toast("Invalid Ad Code", { type: "error" });
        } else {
          toast("Something went wrong", { type: "error" });
        }
      });
  };

  const updateAd = () => {
    const url = API_BASE + "/api/page/advert/update";
    axios
      .post(url, {
        page: currentEditingPageAd.id,
        ad_id: currentEditingAdId,
      })
      .then((response) => {
        if (response.status == 200) {
          getPublication();
          hideEditAdBox();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const deleteAd = (page) => {
    const url = API_BASE + "/api/page/advert/delete/" + page.id;
    axios
      .delete(url)
      .then((response) => {
        if (response.status == 200) {
          getPublication();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const updateAdSettings = () => {
    const url = API_BASE + "/api/book/" + book.uuid + "/settings";
    axios
      .post(url, {
        value: adTimerDelay,
        showTimer: enableAdTimer,
        enable_pay_per_view: enablePayPerViewChange,
        pay_per_view_page_position: pagePerViewPositionValue,
        enable_title_bar_purchase: enableTitleBarPurchaseChange,
        enable_title_bar_subscription: enableTitleBarSubChange,
      })
      .then((response) => {
        if (response.status == 200) {
          getPublication();
          toast("Updated", { type: "success" });
          hidePageSettingsModal();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const updateCoverPhoto = () => {
    const url = API_BASE + "/api/book/update";
    let formData = new FormData();
    formData.append("user_id", userId);
    formData.append("cover_photo", coverPhoto);
    formData.append("uuid", book.uuid);
    axios
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          toast("Updated", { type: "success" });
          getPublication();
        }
      })
      .catch((error) => {
        if ("larger_than_data_tier" in error.response.data) {
          let data = error.response.data["larger_than_data_tier"];
          setTierModalNotice(data.message_one);
          setTierNoticeModalOpen(true);
          return;
        }
        if ("will_break_my_tier_limit" in error.response.data) {
          let data = error.response.data["will_break_my_tier_limit"];
          setTierModalNotice(data.message_two);
          setTierNoticeModalOpen(true);
          return;
        }
        if ("larger_than_max_upload_limit" in error.response.data) {
          toast("You cannot upload a file larger than 20 MB", {
            type: "error",
          });
          return;
        }

        if (error.response.status == 422) {
          let errorValues = Object.values(error.response.data.errors).flat();
          for (let index = 0; index < errorValues.length; index++) {
            const element = errorValues[index];
            toast(element, {
              type: "error",
            });
          }
          return false;
        }
        toast("Something went wrong", { type: "error" });
      });
  };

  const updateTitle = () => {
    const url = API_BASE + "/api/book/update";
    let formData = new FormData();
    formData.append("name", name);
    formData.append("uuid", book.uuid);
    axios
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          toast("Updated", { type: "success" });
          setNameEditMode(false);
          getPublication();
          setCoverPhoto("");
        }
      })
      .catch((error) => {
        if (error.response.status == 422) {
          toast("A content item already exists with that name", {
            type: "error",
          });
          return false;
        }
        toast("Something went wrong", { type: "error" });
      });
  };

  const saveTagToDB = (tagsToSave) => {
    const url = API_BASE + "/api/book/update/tags";
    let formData = new FormData();
    formData.append("tags", JSON.stringify(tagsToSave));
    formData.append("uuid", book.uuid);
    formData.append("action", "new");
    axios
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          toast("Added", { type: "success" });
          getPublication();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const removeTagFromDB = (tagId) => {
    const url = API_BASE + "/api/book/update/tags";
    let formData = new FormData();
    formData.append("tag_id", tagId);
    formData.append("uuid", book.uuid);
    formData.append("action", "delete");
    axios
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          toast("Deleted", { type: "success" });
          getPublication();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const updatePrice = () => {
    const url = API_BASE + "/api/book/update";
    let formData = new FormData();
    if (Number(price) < 0.5) {
      toast(
        "Sorry, price must be $0.50 or greater as our payment providers take a percentage of the payment",
        { type: "notice" }
      );
      return false;
    }
    formData.append("price", price);
    formData.append("uuid", book.uuid);
    axios
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          toast("Updated", { type: "success" });
          setPriceEditMode(false);
          getPublication();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const showAdBox = (page, clickOrigin) => {
    setAvertRequestStatus("");
    setCurrentEditingPageAd(page);
    setcurrentEditingAdId(null);
    saveAd(clickOrigin, page);
  };

  const showEditAdBox = (page) => {
    setShowAdEditModal(true);
    setCurrentEditingPageAd(page);
    setcurrentEditingAdId(page.adverts[0].uuid);
    // alert(page.adverts[0].uuid);
  };

  const hideEditAdBox = () => {
    setShowAdEditModal(false);
    setCurrentEditingPageAd(null);
    setcurrentEditingAdId("");
    // alert(page.adverts[0].uuid);
  };

  const hideAdBox = () => {
    setShowAdModal(false);
    setCurrentEditingPageAd(null);
    setcurrentEditingAdId("");
  };

  const handleAdIdChange = (e) => {
    setcurrentEditingAdId(e);
  };

  const handleEnableAdTimerChange = (e) => {
    setEnableAdTimer((prev) => !prev);
  };

  const handlePayPerViewChange = (e) => {
    setEnablePayPerViewChange((prev) => !prev);
  };

  const showInfoPageModal = () => {
    setInfoPageModalOpen(true);
  };

  const hideInfoPageModal = () => {
    setInfoPageModalOpen(false);
  };

  const showPayPerViewModal = () => {
    setPayPerViewModalOpen(true);
  };

  const hidePayPerViewModal = () => {
    setPayPerViewModalOpen(false);
  };

  const showPageSettingsModal = () => {
    setPageSettingsModalOpen(true);
  };

  const hidePageSettingsModal = () => {
    setPageSettingsModalOpen(false);
  };

  const handleAdTimerDelayChange = (delay) => {
    setAdTimerDelay(delay);
  };

  const handleEnableTitleBarPurchaseChange = () => {
    setEnableTitleBarPurchaseChange((prev) => !prev);
  };
  const handleEnableTitleBarSubChange = () => {
    setEnableTitleBarSubChange((prev) => !prev);
  };

  const handlePagePerViewPagePositionChange = (value) => {
    setPagePerViewPositionValue(value);
  };
  const hideBookEditModal = () => {
    setShowEditBookModal(false);
  };

  const showBookEditModal = (book) => {
    setCurrEditingBook(book);
    setShowEditBookModal(true);
  };

  const handleCoverPhotoChange = (value) => {
    setCoverPhoto(value);
  };

  const handleSelectedFileChange = (file) => {
    setFileSelected(file);
  };
  const handleNameChange = (name) => {
    setName(name);
  };

  const handlePriceChange = (price) => {
    setPrice(price);
  };

  const handlePriceEditModeChange = () => {
    setPriceEditMode((prev) => !prev);
  };

  const openDeletePromtModal = () => {
    setShowDeletePromptModal(true);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };

  const hideDeletePromtModal = () => {
    setShowDeletePromptModal(false);
  };

  const hideTierNoticeModal = () => {
    setTierNoticeModalOpen(false);
  };

  const handleTagChange = (tag) => {
    setTag(tag);
  };

  useEffect(() => {
    getPublication();
    getPublisher();
    if (!isPublisher() || JSON.parse(isAuthUser()).uuid !== userId) {
      window.location.href = "/";
    }

    if (book) {
      setAdTimerDelay(book.timer);
      setEnableAdTimer(book.show_timer ? true : false);
      setEnablePayPerViewChange(book.enable_pay_per_view ? true : false);
      setPagePerViewPositionValue(book.pay_per_view_page_position);
      setEnableTitleBarPurchaseChange(book.enable_title_bar_purchase);
      setEnableTitleBarSubChange(book.enable_title_bar_subscription);
    }
    getSystemSetting();
  }, []);

  const getBookType = (book) => {
    if (book.type == "book") return "Book";
    if (book.type == "comic") return "Comic";
    if (book.type == "photo-album") return "Photo Album";
    if (book.type == "slides") return "Slides";
  };

  const getSystemSetting = () => {
    const url = API_BASE + "/api/system/settings";
    setProcessing(true);

    axios
      .get(url)
      .then((response) => {
        setProcessing(false);
        setSetting(response.data);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const getPublisher = () => {
    let url = API_BASE + "/publisher/" + userId + "/optimized";
    return authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setPublisher(response.data.publisher);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  useEffect(() => {
    if (book) {
      if (book.pages && book.pages.length) {
        var min = Math.min(...book.pages.map((item) => item.position));
        var max = Math.max(...book.pages.map((item) => item.position));
        setLowestPageValue(min);
        setHighestPageValue(max);
      }
      setAdTimerDelay(book.timer);
      setEnableAdTimer(book.show_timer ? true : false);
      setEnablePayPerViewChange(book.enable_pay_per_view ? true : false);
      setPagePerViewPositionValue(book.pay_per_view_page_position);
      setFileSelected(
        BASE_URL + "/storage/" + book.cover_photo.split("public")[1]
      );
      setName(book.name);
      setPrice(book.price);
      setEnableTitleBarPurchaseChange(book.enable_title_bar_purchase);
      setEnableTitleBarSubChange(book.enable_title_bar_subscription);
      let tagNames = [];
      if (book.tags) {
        book.tags.map((t) => tagNames.push(t));
        setTags(tagNames);
      }
    }
    if (!firstLoad) {
      setTimeout(() => {
        window.scrollTo(0, 9999);
        document.getElementById("book-pages-container").scrollTo(0, 9999);
        setFirstLoad(true);
        if (!isResponsiveMode()) {
          window.scrollTo(0, 0);
        }
      }, 800);
      // setFileSelected(true);
    }
  }, [book]);

  const showCurrentLocation = () => {
    if (getLocation().hostname == "litzapp.com") {
      copyText("https://litzapp.com/share/sh/" + book.item_id);
    } else {
      copyText("http://localhost:3000/sh/" + book.item_id);
    }
  };

  const copyText = (text) => {
    // standard way of copying
    var textArea = document.createElement("textarea");
    textArea.setAttribute("style", "width:1px;border:0;opacity:0;");
    document.body.appendChild(textArea);
    textArea.value = text;
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    toast("Link Copied", { type: "success" });
  };

  const addTag = () => {
    if (tags.length == 10) {
      toast("Only a maximum of 10 tags allowed", { type: "error" });
      return false;
    }
    if (tags) {
      let currentTagNames = tags.map((t) => t.name);
      let tgs = tag.split(",");
      if (tgs.length > 1) {
        for (let index = 0; index < tgs.length; index++) {
          if (currentTagNames.includes(tgs[index])) {
            toast(tgs[index] + " already exists!", { type: "error" });
            return false;
          }
        }
        let trimEmptyTags = tgs.filter(
          (t) => t !== null && t !== " " && t !== ""
        );
        let trimTags = trimEmptyTags.map((t) => t.trim());
        console.log(trimTags, "trim");
        if (trimTags.length + tags.length > 10) {
          toast("Only a maximum of 10 tags allowed xx", { type: "error" });
          return false;
        }
        saveTagToDB(trimTags);
        setTags((prev) => [...prev, ...trimTags]);
      } else {
        if (currentTagNames.includes(tag)) {
          toast(tag + " already exists!", { type: "error" });
          return false;
        }

        let trimTag = tag.trim();
        saveTagToDB([tag]);
        setTags((prev) => [...prev, trimTag]);
      }
    }
    setTag("");
  };

  const removeTag = (t) => {
    if (tags.length == 3) {
      toast(
        "There must be at least 3 tags. Before deleting any tags, make sure to first add the new tags that you want, ensuring that there are at least three tags in total.",
        { type: "error" }
      );
      return false;
    }

    let currentTags = tags;
    let filteredTags = currentTags.filter((x) => x.id !== t.id);
    setTags(filteredTags);
    removeTagFromDB(t.id);
  };

  useEffect(() => {
    if (book && fileSelected && coverPhoto) {
      updateCoverPhoto();
    }
  }, [fileSelected]);

  useEffect(() => {
    if (book && book.uuid) {
      if (
        !book.name ||
        !book.price ||
        !book.cover_photo ||
        !book.pages.length
      ) {
        setCanShare(false);
      } else {
        setCanShare(true);
      }
    }
  }, [book]);

  return (
    <>
      <div className="bg-white p-1 flex flex-col md:flex-col justify-center md:justify-start shadow">
        <div className="flex flex-col md:flex-row justify-center md:justify-between my-1">
          <div className="md:w-full md:flex md:justify-start">
            <h1 className="text-lg text-center  font-bold my-1">
              <span className="text-black font-bold ml-2">
                {book && book.name}
              </span>
            </h1>
          </div>
          <PublisherTutorialBar
            classes={
              "md:w-full text-center md:flex md:justify-center md:items-center self-center"
            }
            btClass={"hidden md:flex"}
          />

          <div className="md:w-full md:flex md:items-end md:justify-end md:flex-col">
            <h1 className="text-lg text-center font-bold my-1 text-base md:text-lg">
              Type
              <span className="text-gray-500 font-normal ml-2">
                ({book && getBookType(book)})
              </span>
            </h1>
            <div className="flex justify-center md:justify-end w-full">
              <BugReportNotice />
            </div>
          </div>
        </div>
        <hr />
        <div className="bg-white p-1 flex flex-col md:flex-row justify-center md:justify-between my-1">
          <div>
            {/* <nav class="flex justify-center" aria-label="Breadcrumb">
              <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li class="inline-flex items-center">
                  <a
                    href="/publishers"
                    class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
                  >
                    <svg
                      class="w-4 h-4 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                    </svg>
                    Publishers
                  </a>
                </li>
                <li>
                  <div class="flex items-center">
                    <svg
                      class="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>

                    <a
                      href={`/publisher/publications/options/${userId}`}
                      class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                    >
                      Options
                    </a>
                  </div>
                </li>
                <li aria-current="page">
                  <div class="flex items-center">
                    <svg
                      class="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <a
                      href={`/publisher/publications/series/${userId}`}
                      class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                    >
                      Series
                    </a>
                  </div>
                </li>
                <li aria-current="page">
                  <div class="flex items-center">
                    <svg
                      class="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                      {book && book.name}
                    </span>
                  </div>
                </li>
              </ol>
            </nav> */}
          </div>

          <div class="flex flex-col md:flex-row justify-center gap-4">
            <div>
              {book && (
                <a
                  href={`/publisher/create-page/${book.type}/${userId}/${
                    book && book.id
                  }
            `}
                >
                  <BasicButton
                    classes={"bg-teal-500 hover:bg-teal-400 md:ml-2 w-full"}
                    title={"Add Slide"}
                    handleClick={() => null}
                  />
                </a>
              )}
            </div>
            <BasicButton
              handleClick={showPayPerViewModal}
              title="Pay To View"
              classes={"md:ml-2"}
            />
            <BasicButton
              classes={"bg-black hover:bg-black "}
              title={"Donation Slide"}
              handleClick={() => showInfoPageModal()}
            />

            <BasicButton
              handleClick={showPageSettingsModal}
              title="File Setting"
            />

            <div onClick={() => (canShare ? showCurrentLocation() : "")}>
              <i
                class={`fa fa-share-alt cursor-pointer ${
                  canShare ? "" : "text-gray-500"
                }`}
              ></i>
              {/* {getLocation().href} */}
            </div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
              classes={"my-bg-yellow-500 text-black"}
            />

            {/* <BasicButton
              classes={"bg-black hover:bg-black md:ml-2"}
              title={"Pay To View"}
              handleClick={() => showPayPerViewModal()}
            /> */}
          </div>
        </div>
      </div>
      {book && (
        <div className="bg-white m-2 p-2 grid md:flex md:gap-2 ">
          <div className="flex flex-col items-center md:w-4/12">
            <div className="mb-1 relative">
              <ImageUploadSingleSmall
                setCoverPhoto={handleCoverPhotoChange}
                setFileSelected={handleSelectedFileChange}
                fileSelected={fileSelected}
              />
              {fileSelected ? (
                <div className="absolute my-bg-yellow-500 rounded-full p-1 px-1 bottom-2 -right-2">
                  <span className="text-xs text-white">
                    $
                    {`${
                      book && setting.constant_fee_on_payments
                        ? Number(setting.constant_fee_on_payments) +
                          Number(book.price)
                        : book.price
                    }`}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
            <FreeContentCoverLinks />
            <div className="m-2 mb-8 flex flex-col items-center">
              <label className="text-black">Enter Tags</label>
              <small>
                Enter at least 3 tags describing your content and separated by
                commas
              </small>
              <TextField
                classes={"p-6 my-2 text-xxs"}
                placeholder={
                  "Tags...(eg office manual, news, school work, services, etc)"
                }
                handleChange={handleTagChange}
                value={tag}
              />
              <small>You can always add or delete a tag afterwards</small>
              <BasicButton
                disabled={!tag || tags.length == 10}
                title={processing ? "Adding.." : "Add Tag(s)"}
                classes={"p-6 w-9/12 mt-4"}
                handleClick={addTag}
              />
            </div>
            <div className="m-2 flex flex-wrap">
              {tags.map((t) => (
                <div className="rounded-full text-black border p-2 inline relative m-1 px-3">
                  <span className="text-sm pr-1">
                    ${t.name}
                    <span
                      className="fa fa-times absolute top-3 right-1 text-xs cursor-pointer text-gray-500"
                      onClick={() => removeTag(t)}
                    ></span>
                  </span>
                </div>
              ))}
            </div>
            <div className="relative flex flex-col items-center gap-1 w-full my-1">
              <div
                className="bg-white rounded rounded-full bg-white p-0 -top-5 right-0 absolute px-1 cursor-pointer shadow"
                onClick={() => setNameEditMode((prev) => !prev)}
              >
                <i class="fa fa-pen text-blue-500  z-50 text-xxl"></i>
              </div>
              {nameEditMode && name ? (
                <div
                  className="bg-white rounded rounded-full bg-white p-0 -top-5 right-7 absolute px-1 cursor-pointer shadow"
                  onClick={() => updateTitle()}
                >
                  <i class="fa fa-check text-green-500  z-50 text-xxl"></i>
                </div>
              ) : (
                ""
              )}
              <div className="w-full">
                {nameEditMode ? (
                  <TextField
                    classes={"w-full"}
                    placeholder={"Name.."}
                    handleChange={handleNameChange}
                    value={name}
                  />
                ) : (
                  <p className="text-black border p-2 w-full">{book.name}</p>
                )}
                {nameEditMode ? (
                  <p className="text-xs bg-pink-50 rounded-lg p-1 my-2">
                    Tap or click the check icon to save changes.
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="flex flex-col items-center gap-1 w-full">
              <div className="w-full">
                {" "}
                <a href={`/web/reader/${book && book.uuid}`}>
                  <BasicButton
                    classes={"bg-teal-500 hover:bg-teal-400 w-full"}
                    disabled={!book || !book.pages.length}
                    title={"Preview File"}
                    handleClick={null}
                  />
                </a>
              </div>

              <div className="w-full">
                {book.buyers &&
                !book.buyers.length &&
                !isDataSubExpired(publisher) ? (
                  <BasicButton
                    classes={"bg-red-500 hover:bg-red-400 w-full"}
                    disabled={!book || !book.pages.length}
                    title={"Delete File"}
                    handleClick={() => openDeletePromtModal()}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="w-full text-center">
                {/* <p className="mb-2">Price: ${book.price}</p> */}
                {setting.constant_fee_on_payments && price
                  ? `Service Charge $${Number(
                      setting.constant_fee_on_payments
                    )} + ${price} = $${
                      Number(price) + Number(setting.constant_fee_on_payments)
                    }`
                  : ``}
              </div>
            </div>
          </div>
          <div
            className="md:w-8/12 bg-gray-50 p-2 rounded h-96 overflow-y-scroll"
            id="book-pages-container"
          >
            <div className="flex justify-between items-start ">
              <p className="text-black m-2">
                <span className="text-2xl font-bold">Pages</span> (Tap here to
                scroll)
              </p>
            </div>
            <hr />
            <div className="w-full md:w-11/12">
              <div className="grid content-start gap-4 p-2">
                {book &&
                  book.pages.map((page, i) => {
                    return (
                      <>
                        <div className="bg-white rounded p-2 relative">
                          <div className="grid md:grid-cols-6 items-center">
                            {" "}
                            <div className="flex col-span-3">
                              <div className="flex items-center">
                                {page.file.includes("pdf") && (
                                  <img className="w-16" src={"/pdf-icon.png"} />
                                )}
                                {!page.file.includes("pdf") && (
                                  <img
                                    className="w-16"
                                    src={
                                      BASE_URL +
                                      "/storage/" +
                                      page.file.split("public")[1]
                                    }
                                  />
                                )}
                              </div>
                              <div className="p-2">
                                {/* <p className="font-bold text-2xl">{book.name}</p> */}
                                {/* <p className="text-gray-500">{page.name}</p> */}
                                <p className="text-teal-500">
                                  Page {page.position}
                                </p>
                                <p className="text-teal-500">
                                  Views ({page.views})
                                </p>
                              </div>
                            </div>
                            <div className="p-2 text-right flex col-span-3">
                              {page.position !== lowestPageValue && (
                                <BasicButton
                                  classes={
                                    "bg-teal-500 hover:bg-teal-400 w-full"
                                  }
                                  disabled={movingPage}
                                  title={movingPage ? "Moving..." : "Move Up"}
                                  handleClick={() => movePage(page, "up")}
                                />
                              )}
                              {page.position !== highestPageValue && (
                                <BasicButton
                                  classes={
                                    "bg-teal-500 hover:bg-teal-400 ml-2 mr-1 w-full"
                                  }
                                  disabled={movingPage}
                                  title={movingPage ? "Moving..." : "Move Down"}
                                  handleClick={() => movePage(page, "down")}
                                />
                              )}
                            </div>
                            {/* <div className="p-2 text-right"></div> */}
                            <></>
                          </div>
                          {!isDataSubExpired(publisher) ? (
                            <div
                              className="bg-white rounded rounded-full bg-white p-0  right-0 absolute px-1 cursor-pointer"
                              style={{ top: -13 }}
                              onClick={() => deletePage(page)}
                            >
                              <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {
                          <div className="border border-dashed p-2 py-6 flex justify-center relative">
                            {page.adverts.length ? (
                              <div
                                className="bg-white rounded rounded-full bg-white p-0  right-0 absolute px-1 cursor-pointer"
                                style={{ top: -13 }}
                                onClick={() => deleteAd(page)}
                              >
                                <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
                              </div>
                            ) : (
                              ""
                            )}
                            {/* {page.adverts.length ? (
                              <div
                                className="bg-white rounded rounded-full bg-white p-0  right-0 absolute px-1 cursor-pointer"
                                style={{ top: -13, right: 40 }}
                                onClick={() => showEditAdBox(page)}
                              >
                                <i class="fa fa-edit text-blue-500  z-50 text-2xl"></i>
                              </div>
                            ) : (
                              ""
                            )} */}

                            {book &&
                              book.owner &&
                              book.owner.system_setting &&
                              book.owner.system_setting
                                .ads_controls_visibility && (
                                <div className="">
                                  {!page.adverts.length ? (
                                    <BasicButton
                                      classes={
                                        !processing
                                          ? "bg-gray-500 hover:bg-teal-400 ml-2 mr-1 "
                                          : "bg-gray-500 hover:bg-gray-400 ml-2 mr-1 "
                                      }
                                      disabled={processing}
                                      title={
                                        processing
                                          ? "Inserting..."
                                          : "Insert Ad"
                                      }
                                      handleClick={() =>
                                        showAdBox(page, "from_page")
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {page.adverts.length ? (
                                    <p>
                                      <span className="text-black font-bold">
                                        Ad ID:
                                      </span>{" "}
                                      {page.adverts[0].uuid}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                          </div>
                        }
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="shadow mt-6 mb-3">
            {book && device && (
              <a
                href={`/publisher/create-page/${book.type}/${userId}/${
                  book && book.id
                }
            `}
              >
                <BasicButton
                  classes={"bg-black hover:bg-black md:ml-2 py-4 w-full"}
                  title={"Add Slide"}
                  handleClick={() => null}
                />
              </a>
            )}
          </div>
        </div>
      )}
      <div className="flex flex-col justify-center items-center gap-4 mt-8">
        {/* <div>
          <BasicButton
            title={"Create"}
            classes={"p-6"}
            handleClick={() => null}
          />
        </div>{" "} */}
      </div>
      {/* <ReaderPreview
        pages={publication && book.pages}
        showModal={previewMode}
        hideModal={hidePreviewModal}
      /> */}
      {/* <AdInsertModal
        modalOpen={showAdModal}
        hideAdModal={hideAdBox}
        saveAdvert={saveAd}
        handleAdIdChange={handleAdIdChange}
        value={currentEditingAdId}
      /> */}

      <AdInsertModal
        modalOpen={showAdModal}
        hideAdModal={hideAdBox}
        advertRequestStatus={advertRequestStatus}
        requestAdvert={saveAd}
      />

      <AdEditModal
        modalOpen={showAdEditModal}
        hideAdModal={hideEditAdBox}
        updateAdvert={updateAd}
        handleAdIdChange={handleAdIdChange}
        value={currentEditingAdId}
      />
      <InformationPageModal
        modalOpen={infoPageModalOpen}
        hideModal={hideInfoPageModal}
        book={book}
        action={getPublication}
      />
      <PayPerViewModal
        modalOpen={payPerViewModalOpen}
        hideModal={hidePayPerViewModal}
        book={book}
        action={updateAdSettings}
        handlePayPerViewChange={handlePayPerViewChange}
        enablePayPerView={enablePayPerViewChange}
        handlePagePerViewPagePositionChange={
          handlePagePerViewPagePositionChange
        }
        pagePerViewPositionValue={pagePerViewPositionValue}
      />
      <PageSettingsModal
        modalOpen={pageSettingsModalOpen}
        hideModal={hidePageSettingsModal}
        book={book}
        action={updateAdSettings}
        handleValueChange={handleAdTimerDelayChange}
        adTimerDelay={adTimerDelay}
        handleEnableAdTimerChange={handleEnableAdTimerChange}
        enableAdTimer={enableAdTimer}
        handlePayPerViewChange={handlePayPerViewChange}
        enablePayPerView={enablePayPerViewChange}
        handlePagePerViewPagePositionChange={
          handlePagePerViewPagePositionChange
        }
        handleEnableTitleBarPurchaseChange={handleEnableTitleBarPurchaseChange}
        handleEnableTitleBarSubChange={handleEnableTitleBarSubChange}
        titleBarPurchaseChange={enableTitleBarPurchaseChange}
        titleBarSubChange={enableTitleBarSubChange}
        handlePriceEditModeChange={handlePriceEditModeChange}
        pagePerViewPositionValue={pagePerViewPositionValue}
        handlePriceChange={handlePriceChange}
        priceEditMode={priceEditMode}
        updatePrice={updatePrice}
        price={price}
      />
      <EditBookModal
        modalOpen={showEditBookModal}
        hideAdModal={hideBookEditModal}
        book={currEditingBook}
        action={getPublication}
      />
      {book && (
        <DeletePromptModal
          modalOpen={showDeletePromptModal}
          hideAdModal={hideDeletePromtModal}
          title={
            "If you delete this file, all the slides you have created and all the settings that you have made will not be recoverable. Are you sure you want to delete this file?"
          }
          yesActionText={"Yes, Delete File"}
          noActionText={"No, Cancel"}
          action={() => deleteBook(book)}
          processing={processing}
        />
      )}
      <TierUpgradeNoticeModal
        modalOpen={tierNoticeModalOpen}
        hideModal={hideTierNoticeModal}
        notice={tierModalNotice}
      />
    </>
  );
}
