export default function Footer() {
  return (
    <>
      <footer class="footer-1 mt-4">
        <div class="w-full px-4 bg-gray-100">
          <div class="grid md:grid-cols-3 gap-4 p-4">
            <div class="px-4">
              <a href="/">
                <img src="/litzap_logo.png" className="w-1/12 mb-2" />
              </a>
              <strong>Let's keep in touch!</strong>
            </div>

            <div class="px-4  md:mt-0">
              <h6 class="font-bold mb-2">Useful Links</h6>
              <a href="/">
                <p class="mb-4 text-sm">Home</p>
              </a>
              <a href="/about">
                <p class="mb-4 text-sm">About</p>
              </a>
              <a href="/terms">
                {" "}
                <p class="mb-4 text-sm">Terms & Conditions</p>
              </a>
              {/* <a href="/payment-terms">
                {" "}
                <p class="mb-4 text-sm">Payment Terms</p>
              </a> */}
              <a href="/sales-terms">
                {" "}
                <p class="mb-4 text-sm">Sales Account Terms of Service</p>
              </a>
              <a href="/privacy-policy">
                <p class="mb-4 text-sm">Privacy Policy</p>
              </a>
              <a href="/contact-us">
                {" "}
                <p class="mb-4 text-sm">Contact Us</p>
              </a>
              <a href="https://litzapp.com/share/sh/cOVVi27hzB" target="_blank">
                {" "}
                <p class="mb-4 text-sm">FAQ</p>
              </a>
            </div>
            <div class="px-4  md:mt-0">
              <h6 class="font-bold mb-2">Address</h6>
              <address class="not-italic mb-4 text-sm">
                <p class="">Remote Analytics Jobs Inc.</p>
                <p class="">126 Southwest 17th Road,</p>
                <p>Unit 509 Miami, Florida 33129.</p>
                <br />
                <h6 class="font-bold mb-2">Telephone number:</h6>
                <p class="w-8/12 mx-auto md:mx-0 text-gray-500">
                  {/* <strong className="font-bold mb-2">Telephone number:</strong> */}
                </p>

                <a href="tel:+16692915495"> 📱 +1 (305) 927-1096</a>
              </address>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
