import TextField from "../components/inputs/text-input";
import BasicButton from "../components/buttons/basic-button";
import { useBookStore } from "../stores/book-store";
import { useState } from "react";

export default function AdminAdInsertModal(props) {
  const { pageAdvertId } = useBookStore((state) => state);

  const [mode, setMode] = useState(0);

  const switchModes = () => {
    setMode((prev) => (prev == 0 ? 1 : 0));
  };

  return (
    <div
      className={`fixed z-10 overflow-y-auto top-0 w-full left-0 ${
        props.modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle w-6/12 h-4/12 "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <h1 className="text-2xl font-bold text-center">
                {/* {getActiveWidgetTitle(currentEditingWidget, widgets)} */}
                Ad ID
              </h1>
            </div>
            <div className="flex flex-col md:flex-row mb-2 justify-center w-full">
              <BasicButton
                title={"Adsense"}
                classes={`px-8 md:w-4/12 rounded-none ${
                  mode == 0 ? "bg-black" : "bg-gray-500 hover:bg-gray-500"
                }`}
                handleClick={() => switchModes()}
              />
              <BasicButton
                title={"Adcash"}
                classes={`px-8 md:w-4/12 rounded-none ${
                  mode == 1 ? "bg-black" : "bg-gray-500 hover:bg-gray-500"
                }`}
                handleClick={() => switchModes()}
              />
            </div>

            {mode == 0 ? (
              <div className="w-full">
                <div className="p-2 flex flex-col items-center justify-center">
                  <p className="text-left w-8/12">Publisher Ad Unit</p>
                  <TextField
                    placeholder={"Place ad code..."}
                    classes="w-8/12"
                    handleChange={props.pAd1CodeChange}
                    value={props.pAd1Code}
                    disabled={
                      props?.currReq.ad_code && props.advertMode == "SWITCH"
                    }
                  />
                </div>

                <div className="p-2 flex flex-col items-center justify-center">
                  <p className="text-left w-8/12">System Ad Unit</p>
                  <TextField
                    placeholder={"Place ad code..."}
                    classes="w-8/12"
                    handleChange={props.sAd1CodeChange}
                    value={props.sAd1Code}
                    disabled={
                      props?.currReq.system_ad_code &&
                      props.advertMode == "SWITCH"
                    }
                  />
                </div>
                <p className="text-pink-500">
                  {props.pAd1Code &&
                  props.sAd1Code &&
                  props.pAd1Code == props.sAd1Code
                    ? "Publisher Ad Unit cannot be the same as Ssytem Ad Unit"
                    : ""}
                </p>
              </div>
            ) : (
              <div className="w-full">
                <div className="p-2 flex flex-col items-center justify-center mb-2">
                  <p className="text-left w-8/12">Publisher Ad Unit</p>
                  <TextField
                    placeholder={"Place ad code..."}
                    classes="w-8/12"
                    handleChange={props.pAd2CodeChange}
                    value={props.pAd2Code}
                    disabled={
                      props?.currReq.ad_2_code && props.advertMode == "SWITCH"
                    }
                  />
                </div>

                <div className="p-2 flex flex-col items-center justify-center">
                  <p className="text-left w-8/12">System Ad Unit</p>
                  <TextField
                    placeholder={"Place ad code..."}
                    classes="w-8/12"
                    handleChange={props.sAd2CodeChange}
                    value={props.sAd2Code}
                    disabled={
                      props?.currReq.system_ad_2_code &&
                      props.advertMode == "SWITCH"
                    }
                  />
                </div>

                <p className="text-pink-500">
                  {props.pAd2Code &&
                  props.sAd2Code &&
                  props.pAd2Code == props.sAd2Code
                    ? "Publisher Ad Unit cannot be the same as SyStem Ad Unit"
                    : ""}
                </p>
              </div>
            )}
            <div className="flex justify-center gap-4 font-bold">
              Active Provider
            </div>
            <div className="flex justify-center gap-4">
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="ADSENSE"
                    checked={props.defaultProvider === "ADSENSE"}
                    onChange={props.onProviderChange}
                  />
                  <span className="pr-1"> Adsense</span>
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="ADSTERRA"
                    checked={props.defaultProvider === "ADSTERRA"}
                    onChange={props.onProviderChange}
                  />
                  <span className="pl-1">Adcash</span>
                </label>
              </div>

              {/* <div>Selected option is : {this.state.selectedOption}</div>
              <button className="btn btn-default" type="submit">
                Submit
              </button> */}
            </div>

            {mode == 0 ? (
              <div className="flex justify-center">
                <BasicButton
                  disabled={
                    !props.pAd1Code ||
                    !props.sAd1Code ||
                    props.pAd1Code == props.sAd1Code ||
                    !props.defaultProvider
                  }
                  title={"Update"}
                  classes="px-8 w-8/12"
                  handleClick={() => props.saveAdvert()}
                />
              </div>
            ) : (
              <div className="flex justify-center">
                <BasicButton
                  disabled={
                    !props.pAd2Code ||
                    !props.sAd2Code ||
                    props.pAd2Code == props.sAd2Code ||
                    !props.defaultProvider
                  }
                  title={"Update"}
                  classes="px-8 w-8/12"
                  handleClick={() => props.saveAdvert()}
                />
              </div>
            )}

            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => props.hideAdModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
            {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
