import TextNumberField from "../../../../../../../components/inputs/text-number-input";
export default function PreferredPrice(props) {
  const { productMeta, handleMetaChange, pageContext, user } = props;

  return (
    <div className="m-2 mb-2 w-full md:w-6/12">
      <label className="text-black">Price (USD)</label>
      <div className="flex gap-2 items-center">
        <TextNumberField
          disabled={
            productMeta?.subscription_cap_enabled && pageContext == "update"
          }
          classes={"p-6 my-2"}
          placeholder={"Price.."}
          handleChange={(e) => handleMetaChange(e, "price", "text")}
          value={productMeta.price}
        />
        {!productMeta.price && <span className="text-red-500 text-2xl">*</span>}
      </div>
      <p className="text-xs">
        {" "}
        {user.id
          ? user.system_setting.constant_fee_on_payments && productMeta.price
            ? `Service Charge $${Number(
                user.system_setting.constant_fee_on_payments
              )} + ${productMeta.price} = $${
                Number(productMeta.price) +
                Number(user.system_setting.constant_fee_on_payments)
              }`
            : ``
          : ""}
      </p>
      <p className="text-base text-pink-500">
        {productMeta.price &&
          productMeta.price == 0 &&
          "Please enter a price greater than 0"}
      </p>
    </div>
  );
}
