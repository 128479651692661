import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BasicButton from "../../../../../../components/buttons/basic-button";
import LoadingProgress from "../../../../../publisher/rich-page/components/loading-progress";
import { useSupplierApplicationsStore } from "../../../../../../stores/supplier-applications-store";
import authRequestService from "../../../../../../services/HttpRequests/authenticated-request-services";
import DesktopTable from "./components/desktop-table";
import MobileTable from "./components/mobile-table";
import AdminSupplierPhotoViewerModal from "./components/photo-viewer";
import AdminApplicantComment from "./components/comment-modal";

import {
  isAdmin,
  API_BASE,
  isResponsiveMode,
  authUserData,
} from "../../../../../../Utils/helpers";
import { toast } from "react-toastify";
export default function AdminSupplierBizRelocationApplicant() {
  const [processing, setProcessing] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const { user_id } = useParams();
  const navigate = useNavigate();
  const mailStore = useSupplierApplicationsStore((state) => state);

  const getApplicant = () => {
    mailStore.setData([]);
    mailStore.setSelectedApplicantStatus("");
    setProcessing(true);
    const url =
      API_BASE + `/admin/supplier/applicant/${user_id}/application/retrieve`;
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          mailStore.setApplicationData(response.data.application);
          setTimeout(() => {
            setProcessing(false);
          }, 500);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const processApplication = () => {
    setProcessing(true);
    const url =
      API_BASE + `/admin/supplier/application/biz-location-update/process`;
    authRequestService()
      .post(url, {
        status: mailStore.selectedApplicantStatus,
        comment: mailStore.comment,
        user_id: user_id,
      })
      .then((response) => {
        if (response.status == 200) {
          setProcessing(false);
          closeCommentModal();
          mailStore.setSelectedApplicantStatus("");
          mailStore.handleCommentChange("");
          toast("Processed", { type: "success" });
          navigate("/admin/supplier/business/relocations");
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const preProcessApplication = () => {
    console.log(mailStore.selectedApplicantStatus);
    if (mailStore.selectedApplicantStatus == "reject") {
      openCommentModal();
    }
  };

  const openCommentModal = () => {
    setShowCommentModal(true);
  };
  const closeCommentModal = () => {
    setShowCommentModal(false);
    mailStore.handleCommentChange("");
  };

  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
    getApplicant();
  }, []);

  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">Applicant Page</h1>
          </div>
          <div className="flex justify-between gap-2 items-start">
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <LoadingProgress loading={processing} />
        <div className="flex flex-col items-center justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            {isResponsiveMode() ? (
              <MobileTable
                processing={processing}
                openCommentModal={openCommentModal}
                preProcessApplication={preProcessApplication}
                processApplication={processApplication}
              />
            ) : (
              <DesktopTable
                processing={processing}
                openCommentModal={openCommentModal}
                preProcessApplication={preProcessApplication}
                processApplication={processApplication}
              />
            )}
          </div>
        </div>
      </div>
      <AdminApplicantComment
        modalOpen={showCommentModal}
        closeModal={closeCommentModal}
        processApplication={processApplication}
        processing={processing}
      />
    </>
  );
}
