import { useDashboardStore } from "../../../../../stores/dashboardStore";
import BasicButton from "../../../../buttons/basic-button";
import { BASE_URL } from "../../../../../Utils/helpers";
import Switch from "react-switch";
import AdSense from "react-adsense";
import RatingBox from "../../../../rating";
import Adsterra from "../../../../advert/adsterra";
import ListsModal from "./lists/components/lists-modal";
import { useState } from "react";
export default function DashSectionThree(props) {
  const dashboardStore = useDashboardStore((state) => state);
  const { handlePublishStatus } = props;
  const { showCurrentLocation } = props;
  const { openProductCalendar } = props;
  const { renderTags } = props;
  const { processing } = props;
  const { currentRating } = props;
  const { lastRatedBook } = props;
  const { openListsModal } = props;
  const { goToContent } = props;
  const { published } = props;
  const { activeTab } = props;
  const { rateBook } = props;
  const { canShare } = props;
  const { loadMore } = props;
  const { isListItemsMode } = props;
  const { goBackToList } = props;
  const { getList } = props;

  const renderDisplayImage = (data) => {
    if (data?.content?.type == "list") {
      return (
        BASE_URL +
        "/storage" +
        data?.content?.book_list_thumb?.split("public")[1]
      );
    }
    if (data?.content?.cover_photo_thumb) {
      return (
        BASE_URL +
        "/storage" +
        data?.content?.cover_photo_thumb?.split("public")[1]
      );
    }

    if (data.content?.productimages?.length) {
      return (
        BASE_URL +
        "/storage" +
        data?.content?.productimages[0]?.product_thumb?.split("public")[1]
      );
    }

    if (data.content?.owner_optimized_data?.profile_photo) {
      return (
        BASE_URL +
        "/storage/" +
        data?.content?.owner_optimized_data?.profile_photo?.split("public")[1]
      );
    } else {
      return (
        BASE_URL +
        "/storage/" +
        data?.content?.owner_optimized_data?.backup_profile_photo?.split(
          "public"
        )[1]
      );
    }
  };

  const bookRebderProps = {
    renderDisplayImage,
    handlePublishStatus,
    showCurrentLocation,
    openProductCalendar,
    renderTags,
    processing,
    currentRating,
    lastRatedBook,
    openListsModal,
    goToContent,
    published,
    activeTab,
    rateBook,
    canShare,
    loadMore,
    getList,
  };

  return (
    <div className="w-full p-2 bg-white">
      {activeTab == 9 || isListItemsMode ? (
        <div className="w-full md:w-11/12  flex justify-end mb-4 p-2 md:px-12">
          <BasicButton
            title={!isListItemsMode ? "Create List" : "Go Back"}
            classes={"my-bg-yellow-500 text-black"}
            handleClick={!isListItemsMode ? openListsModal : goBackToList}
          />
        </div>
      ) : (
        ""
      )}

      <div className="relative flex flex-col md:grid md:grid-cols-4 md:items-start content-center justify-center w-full gap-4 ">
        {dashboardStore.booksWithAds.length
          ? dashboardStore.booksWithAds.map((data, i) =>
              data.type == "advert" ? (
                <div className="p-3 shadow m-2 text-center z-50 md:w-full cursor-pointer flex flex-row items-center justify-center relative">
                  {data.provider == "ADSTERRA" ? (
                    <Adsterra slot={data.content} />
                  ) : (
                    <AdSense.Google
                      client="ca-pub-8191791319921859"
                      slot={data.content}
                      style={{ width: 250, height: 250, float: "left" }}
                      layout="in-article"
                      format=""
                    />
                  )}
                </div>
              ) : (
                <>
                  {data.content.type !== "list" ? (
                    <RenderBasicBook data={data} {...bookRebderProps} />
                  ) : (
                    <RenderListBook data={data} {...bookRebderProps} />
                  )}
                </>
              )
            )
          : ""}
      </div>
      <div className="flex justify-center w-full">
        <button
          disabled={!dashboardStore.nextPageURL}
          class={`whitespace-nowrap inline-flex items-center justify-center px-8 py-4 mt-8 border border-transparent rounded-md shadow-sm text-base font-medium text-white ${
            !dashboardStore.nextPageURL
              ? "bg-gray-500 hover:bg-gray-500"
              : "bg-black"
          } hover:bg-black md:mr-8 `}
          onClick={() => !processing && loadMore()}
        >
          {processing ? "Loading..." : "View More"}
        </button>
      </div>
    </div>
  );
}

const RenderBasicBook = (props) => {
  const { data } = props;
  const { handlePublishStatus } = props;
  const { showCurrentLocation } = props;
  const { openProductCalendar } = props;
  const { renderDisplayImage } = props;
  const { renderTags } = props;
  const { currentRating } = props;
  const { lastRatedBook } = props;
  const { goToContent } = props;
  const { published } = props;
  const { rateBook } = props;
  const { canShare } = props;

  return (
    <div className="p-3 shadow m-2 text-center z-50 md:w-full cursor-pointer flex flex-col items-center relative">
      <div
        className="bg-white rounded rounded-full bg-white p-0 -top-5 right-10 absolute px-1 cursor-pointer shadow"
        onClick={() => goToContent(data.content)}
      >
        <i class="fa fa-pen text-blue-500  z-50 text-2xl"></i>
      </div>
      <div
        className="bg-white rounded rounded-full bg-white p-0 -top-5 right-0 absolute px-1 cursor-pointer shadow"
        onClick={() =>
          canShare.includes(data.content.uuid)
            ? showCurrentLocation(data.content)
            : ""
        }
      >
        <i
          class={`cursor-pointer fa fa-share-alt  z-50 text-2xl ${
            canShare.includes(data.content.uuid)
              ? "text-blue-500"
              : "text-gray-500"
          }`}
        ></i>
      </div>

      {data.content.cover_photo || data.content.productimages ? (
        <img
          className="w-36 h-36 w-36 h-36 md:w-36 md:h-36 object-cover rounded-full"
          onClick={() => goToContent(data.content)}
          src={renderDisplayImage(data)}
        />
      ) : (
        <div
          className="w-full h-28 bg-black text-white flex flex-col justify-center font-bold"
          onClick={() => goToContent(data.content)}
        >
          <p>No Cover</p>
        </div>
      )}

      <div className="">
        <p className="font-bold text-lg text-center">{data.content.name}</p>

        <p className="my-2 text-xs">
          <span className=""> Item ID: </span>
          <span className="text-yellow-600 font-bold">
            {data.content.item_id}
          </span>
        </p>
        <p className="text-red-500 text-sm">by</p>
        <p className="text-black-500">
          {data.content?.owner_optimized_data?.name}
        </p>

        <p className="text-black-500 mt-2">
          Views{" "}
          {data.content.pages &&
            data.content.pages.length &&
            `(${data.content.pages[0].views})`}
        </p>
      </div>
      <RatingBox
        book={data.content}
        lastRatedBook={lastRatedBook}
        rateBook={rateBook}
        currentRating={currentRating}
      />
      <div className="my-4 mx-1 text-center bg-gray-100 rounded-lg p-2">
        <div className="flex justify-around gap-4 mb-2">
          <label className="flex items-center gap-4">
            <div>
              {" "}
              <span className="font-bold">
                {" "}
                {published.includes(data.content.uuid) ? "Hide" : "Unhide"}
              </span>
            </div>
          </label>
          <div className="flex items-center gap-2">
            <Switch
              onChange={(e) => handlePublishStatus(e, data.content)}
              checked={published.includes(data.content.uuid)}
            />
          </div>
        </div>
        {!published.includes(data.content.uuid) ? (
          <p className="text-xs">Not Visible On Homepage or Public Profile</p>
        ) : (
          ""
        )}
        {published.includes(data.content.uuid) ? (
          <p className="text-xs">Visible On Homepage or Public Profile</p>
        ) : (
          ""
        )}
      </div>

      {data.content.type == "product" &&
      data.content.meta_payment_type == "recurring" ? (
        <div className="w-full flex justify-center my-6">
          <BasicButton
            classes={"my-bg-yellow-500 text-black mb-2"}
            title={"View Calendar"}
            handleClick={() => openProductCalendar(data)}
          />
        </div>
      ) : (
        ""
      )}
      <div className="flex flex-wrap  justify-center items-center">
        {data.content.tags && renderTags(data.content)}
      </div>
    </div>
  );
};
const RenderListBook = (props) => {
  const { data } = props;
  const { handlePublishStatus } = props;
  const { renderDisplayImage } = props;
  const { goToContent } = props;
  const { published } = props;
  const { getList } = props;

  return (
    <div className="p-3 shadow m-2 text-center z-50 md:w-full cursor-pointer flex flex-col items-center relative">
      {data.content.cover_photo || data.content.productimages ? (
        <img
          className="w-36 h-36 w-36 h-36 md:w-36 md:h-36 object-cover rounded-full"
          onClick={() => goToContent(data.content)}
          src={renderDisplayImage(data)}
        />
      ) : (
        <div
          className="w-full h-28 bg-black text-white flex flex-col justify-center font-bold"
          onClick={() => goToContent(data.content)}
        >
          <p>No Cover</p>
        </div>
      )}

      <div className="">
        <p className="font-bold text-lg text-center">{data.content.name}</p>
        {/* <p className="font-bold text-2xl text-center">{book.name}</p> */}

        {/* <p className="text-gray-600">
                  { data.content.series &&  data.content.series.name}
                </p> */}
        <p className="my-2 text-xs">
          <span className=""> Item ID: </span>
          <span className="text-yellow-600 font-bold">
            {data.content.item_id}
          </span>
        </p>

        <p className="text-black-500 mt-2">
          Views{" "}
          {data.content.pages &&
            data.content.pages.length &&
            `(${data.content.pages[0].views})`}
        </p>
      </div>

      <div className="my-4 mx-1 text-center bg-gray-100 rounded-lg p-2">
        <div className="flex justify-around gap-4 mb-2">
          <label className="flex items-center gap-4">
            <div>
              {" "}
              <span className="font-bold">
                {" "}
                {published.includes(data.content.uuid) ? "Hide" : "Unhide"}
              </span>
            </div>
          </label>
          <div className="flex items-center gap-2">
            <Switch
              onChange={(e) => handlePublishStatus(e, data.content)}
              checked={published.includes(data.content.uuid)}
            />
          </div>
        </div>
        {!published.includes(data.content.uuid) ? (
          <p className="text-xs">Not Visible On Homepage or Public Profile</p>
        ) : (
          ""
        )}
        {published.includes(data.content.uuid) ? (
          <p className="text-xs">Visible On Homepage or Public Profile</p>
        ) : (
          ""
        )}
      </div>
      <div className="w-full flex justify-center my-2">
        <BasicButton
          classes={"my-bg-yellow-500 text-black mb-2"}
          title={"Edit List"}
          handleClick={() => getList(data.content.uuid)}
        />
      </div>
    </div>
  );
};
