import {
  InstagramEmbed,
  FacebookEmbed,
  PinterestEmbed,
  XEmbed,
} from "react-social-media-embed";
import YouTube from "react-youtube";
import getVideoId from "get-video-id";
import ImageGallery from "react-image-gallery";
import { useState, useEffect, useRef } from "react";
import { isResponsiveMode, BASE_URL } from "../../../../../Utils/helpers";
export default function RenderAccordionMediaBox({ payload }) {
  if (!payload?.media?.length) return <span></span>;

  const p = {};
  p.type = payload.media[0].type;
  p.data = payload.media[0].link;
  p.assets = payload.media[0]?.assets;

  if (p.type == "upload") {
    return <RenderSlidesBox slides={p.assets} />;
  }

  if (p.type == "youtube") {
    return <RenderVideoBox url={p.data} />;
  }

  if (p.type == "instagram") {
    if (!p.data.includes("blockquote")) {
      return (
        <div className="flex justify-center my-2">
          <InstagramEmbed url={p.data} width={328} captioned />
        </div>
      );
    }
  }

  if (p.type == "facebook") {
    if (!p.data.includes("iframe")) {
      return (
        <div className="flex justify-center my-2">
          <FacebookEmbed url={p.data} width={550} />;
        </div>
      );
    }
  }

  if (p.type == "twitter") {
    if (!p.data.includes("blockquote")) {
      return (
        <div className="flex justify-center my-2">
          <XEmbed url={p.data} width={325} />
        </div>
      );
    }
  }

  if (p.type == "pinterest") {
    if (!p.data.includes("blockquote")) {
      try {
        const id = p?.data?.match(/pin\/(\d+)/);
        if (id) {
          return (
            <div className="flex justify-center my-2">
              <PinterestEmbed url={p?.data} width={345} height={467} />
            </div>
          );
        }
      } catch (error) {}
    }
  }

  return (
    <div className="flex justify-center my-2">
      <div
        className="relative "
        dangerouslySetInnerHTML={{ __html: p.data }}
      ></div>
    </div>
  );
}

const RenderVideoBox = ({ url }) => {
  const opts = {
    width: isResponsiveMode() ? "100%" : "100%",
    height: isResponsiveMode() ? "250px" : "500px",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  const youtubeVideoIdExists = (url) => {
    if (!url) {
      return false;
    }

    let { id, service } = getVideoId(url);

    if (!id || service !== "youtube") {
      return false;
    }

    return getVideoId(url);
  };

  const _onReady = (e) => {
    // access to player in all event handlers via event.target
    e.target.pauseVideo();
  };
  return (
    <div class="flex flex-wrap justify-center w-full my-2">
      <div className="relative w-full">
        <YouTube
          className="w-full"
          videoId={youtubeVideoIdExists(url).id}
          opts={opts}
          onReady={_onReady}
        />
      </div>
    </div>
  );
};

const RenderSlidesBox = ({ slides }) => {
  const imageGalleryRef = useRef(null);
  const [fullscreen, setFullScreen] = useState(false);

  const images = slides.map((slide) => ({
    original: BASE_URL + "/storage" + slide.url.split("public")[1],
    thumbnail: null,
  }));

  return (
    <div className="relative">
      {isResponsiveMode() && fullscreen && (
        <button
          onClick={() => imageGalleryRef.current.toggleFullScreen()}
          className={
            "fixed z-999999 bg-yellow-500  top-0 left-5 right-5 p-1 w-4/12 mx-auto rounded-lg mt-5"
          }
        >
          Exit
        </button>
      )}
      {slides.length && (
        <ImageGallery
          items={images}
          showThumbnails={false}
          showBullets={true}
          useBrowserFullscreen={isResponsiveMode() ? false : true}
          ref={imageGalleryRef}
          fullscreen={fullscreen}
          onScreenChange={(screen) => setFullScreen(screen)}
          onClick={(e) => {
            if (!fullscreen) {
              imageGalleryRef.current.toggleFullScreen();
            }
          }}
        />
      )}
    </div>
  );
};
