import axios from "axios";
import FileUploaderBox from "./file-uploader";
import SocialsUploader from "./socials";
import UploadOptions from "./upload-options";
import {
  isResponsiveMode,
  authUserData,
} from "../../../../../../Utils/helpers";
import { useCallback, useEffect, useState } from "react";

export default function ImageModalEditor({
  modalOpen,
  hideLinkModal,
  insertImage,
  handleImageLinkChange,
  imageFileSelected,
  currEditingImagePreview,
  handleImagePreview,
  resetImagePreview,
  resetImageLinkPreview,
  createImageUploadMode,
  processing,
  value,
  authTokens,
  handleCheckSocialPhoto,
  selectedSocialPhoto,
  handleSocialEmbdedCodeChange,
  socialEmbedValue,
  handleSocialUploadTypeChange,
  handleSocialUploadSubTypeChange,
  socialUploadType,
  socialUploadSubType,
  addSocialImageLinksToEditor,
  addSocialEmbedToEditor,
  handleVideoPreview,
  handleVideoLinkChange,
  resetVideoPreview,
  resetVideoLinkPreview,
  videoFileSelected,
  videoLinkValue,
  insertYoutubeVideo,
}) {
  const [linkMode, setLinkMode] = useState(false);
  const [authButtonToShow, setAuthButtonToShow] = useState("");
  const [photos, setPhotos] = useState([]);

  const isCreateMode = () => {
    return !currEditingImagePreview;
  };

  //   alert(setCurrentEditingImageSource);

  const canUpload = () => {
    if (createImageUploadMode) {
      return !imageFileSelected || processing;
    } else {
      if ((!value && !imageFileSelected) || processing) {
        return true;
      }
    }
  };

  useEffect(() => {
    handleSocialEmbdedCodeChange("");
    handleSocialUploadSubTypeChange("");
    resetVideoPreview();
    resetVideoLinkPreview();
  }, [socialUploadType]);

  const insertSocial = () => {
    if (socialUploadType == "instagram") {
      if (socialUploadSubType == "my-instagram") {
        addSocialImageLinksToEditor();
      } else {
        addSocialEmbedToEditor();
      }
    } else {
      addSocialEmbedToEditor();
    }
  };

  return (
    <div
      className={` ${
        isResponsiveMode() ? "fixed" : "fixed"
      }  z-40 overflow-y-auto top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-6/12"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {/* px-4 pt-2 pb-4 sm:p-6 sm:pb-4 */}
          <div class="">
            {/* modal body */}
            <div className="p-2">
              <h1 className="text-2xl font-bold text-center">
                {/* {getActiveWidgetTitle(currentEditingWidget, widgets)} */}
                Add Media
              </h1>
            </div>

            <div className="my-2 w-full flex flex-col items-center justify-center p-2">
              <UploadOptions
                uploadType={socialUploadType}
                handleUploadTypeChange={handleSocialUploadTypeChange}
                imageFileSelected={imageFileSelected}
                currEditingImagePreview={currEditingImagePreview}
                createImageUploadMode={createImageUploadMode}
                handleSocialEmbdedCodeChange={handleSocialEmbdedCodeChange}
                isCreateMode={isCreateMode}
              />
              <FileUploaderBox
                canUpload={canUpload}
                processing={processing}
                insertImage={insertImage}
                uploadType={socialUploadType}
                imageFileSelected={imageFileSelected}
                currEditingImagePreview={currEditingImagePreview}
                resetImagePreview={resetImagePreview}
                handleImageLinkChange={handleImageLinkChange}
                handleImagePreview={handleImagePreview}
                createImageUploadMode={createImageUploadMode}
                isCreateMode={isCreateMode}
                value={value}
              />
              <SocialsUploader
                photos={photos}
                uploadType={socialUploadType}
                authButtonToShow={authButtonToShow}
                handleCheckSocialPhoto={handleCheckSocialPhoto}
                selectedSocialPhoto={selectedSocialPhoto}
                createImageUploadMode={createImageUploadMode}
                isCreateMode={isCreateMode}
                handleEmbdedCodeChange={handleSocialEmbdedCodeChange}
                handleSocialUploadSubTypeChange={
                  handleSocialUploadSubTypeChange
                }
                socialUploadSubType={socialUploadSubType}
                embedValue={socialEmbedValue}
                insertSocial={insertSocial}
                handleVideoPreview={handleVideoPreview}
                handleVideoLinkChange={handleVideoLinkChange}
                resetVideoPreview={resetVideoPreview}
                resetVideoLinkPreview={resetVideoLinkPreview}
                videoFileSelected={videoFileSelected}
                videoLinkValue={videoLinkValue}
                insertYoutubeVideo={insertYoutubeVideo}
                processing={processing}
              />
            </div>

            {/* modal body */}
          </div>
          <div class="text-right my-2">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideLinkModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
