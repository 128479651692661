import TextField from "../components/inputs/text-input";
import BasicButton from "../components/buttons/basic-button";
import { useBookStore } from "../stores/book-store";
import InsertVideo from "../pages/publisher/rich-page/components/insert-video";
import { isResponsiveMode } from "../Utils/helpers";
import YouTube from "react-youtube";
import { useState } from "react";
import getVideoId from "get-video-id";

export default function VideoModalEditor({
  modalOpen,
  hideLinkModal,
  insertVideo,
  handleVideoLinkChange,
  videoFileSelected,
  handleVideoPreview,
  resetVideoPreview,
  resetVideoLinkPreview,
  processing,
  value,
}) {
  const { pageAdvertId } = useBookStore((state) => state);
  const [linkMode, setLinkMode] = useState(true);

  const _onReady = (e) => {
    // access to player in all event handlers via event.target
    e.target.pauseVideo();
  };
  const opts = {
    width: isResponsiveMode() ? "100%" : "500px",
    height: "250px",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const youtubeVideoIdExists = (url) => {
    if (!url) {
      return false;
    }

    let { id, service } = getVideoId(url);

    if (!id || service !== "youtube") {
      return false;
    }

    return getVideoId(url);
  };

  return (
    <div
      className={` ${
        isResponsiveMode() ? "absolute" : "fixed"
      }  z-40 overflow-y-auto top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-6/12 h-4/12 "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <h1 className="text-2xl font-bold text-center">
                {/* {getActiveWidgetTitle(currentEditingWidget, widgets)} */}
                Add Video
              </h1>
              <p className="text-red-700">Supported format MP4</p>
            </div>

            {linkMode ? (
              <div
                className={`my-4 w-full border flex flex-col items-center justify-center bg-gray-50 p-2 ${
                  !value ? "h-48" : ""
                }`}
              >
                <h5 className="font-bold">YoutubeLink</h5>
                <div className="w-full relative mx-2">
                  {" "}
                  <i
                    class="fa fa-times-circle text-black  z-50 z-50 text-2xl absolute right-1 -top-0 z-40 cursor-pointer"
                    onClick={() => resetVideoLinkPreview()}
                  ></i>
                  <TextField
                    placeholder={"Paste Link..."}
                    classes="rounded-lg pr-8"
                    handleChange={handleVideoLinkChange}
                    value={value}
                  />
                </div>

                {value && youtubeVideoIdExists(value) ? (
                  <div className="p-2">
                    {" "}
                    <YouTube
                      videoId={youtubeVideoIdExists(value).id}
                      opts={opts}
                      onReady={_onReady}
                    />
                  </div>
                ) : (
                  ""
                )}
                {!youtubeVideoIdExists(value) ? (
                  <p className="text-red-700 my-2">
                    Please paste a valid YouTube link
                  </p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}

            {!linkMode && !videoFileSelected ? (
              <div className="my-6 w-full border flex flex-col items-center justify-center bg-gray-50 h-48">
                <i class="fas fa-video text-5xl"></i>
                <InsertVideo previewVideo={handleVideoPreview} />
              </div>
            ) : (
              ""
            )}

            {!linkMode && videoFileSelected ? (
              <div className="my-6 border flex flex-col items-center justify-center bg-gray-50 p-2  relative">
                <span
                  className="rounded rounded-full p-0 right-0 absolute px-1 cursor-pointer"
                  style={{ top: -15, right: -5 }}
                  onClick={() => resetVideoPreview()}
                >
                  <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
                </span>
                <video
                  controls="controls"
                  className="w-full"
                  src={videoFileSelected}
                  type="video/mp4"
                  height="200px"
                ></video>
              </div>
            ) : (
              ""
            )}

            <div className="flex justify-center">
              <BasicButton
                disabled={
                  (!youtubeVideoIdExists(value) && !videoFileSelected) ||
                  processing
                }
                title={processing ? "Uploading..." : "Upload"}
                classes="px-8 w-8/12"
                handleClick={() => insertVideo()}
              />
            </div>

            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideLinkModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
            {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
