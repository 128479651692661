import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import WebHeader from "../web/header";
import { isAdmin, isResponsiveMode } from "../../Utils/helpers";
import StripeCustomPageForm from "../../payment/custom-page-pay-button";
import AdSense from "react-adsense";
import Adsterra from "../../components/advert/adsterra";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import authRequestService from "../../services/HttpRequests/authenticated-request-services";
import openRequestService from "../../services/HttpRequests/unauthenticated-request-services";
import ImageGallery from "react-image-gallery";
import RenderTableBox from "./components/table";
import RenderAccordionBox from "./components/accordion";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import { Tweet } from "react-tweet";
import {
  InstagramEmbed,
  FacebookEmbed,
  PinterestEmbed,
  XEmbed,
} from "react-social-media-embed";

import uuid from "react-uuid";
import {
  isAuthUser,
  API_BASE,
  authUserData,
  BASE_URL,
  bookPriceWithSystemFees,
  subscritionCostWithSystemFees,
  isRegistrationComplete,
  getLocation,
  globalAds,
} from "../../Utils/helpers";

import axios from "axios";
import YouTube from "react-youtube";
import getVideoId from "get-video-id";
import Footer from "../../layout/footer";
import { load } from "@fingerprintjs/botd";
import StripeForm from "../../payment/pay-button";
import BasicButton from "../../components/buttons/basic-button";
import RegistrationModal from "../../modals/registration-modal";
import Registration2Modal from "../../modals/registration-2-modal";
import Registration4Modal from "../../modals/registration-4-modal";
import Registration5Modal from "../../modals/registration-5-modal";
import StripePaymentModal from "../../modals/stripe-payment-modal";
import { FacebookShareButton, FacebookIcon } from "react-share";
import PlatformUpdateModal from "../../modals/platform-updates-modal";
import StripeSubscriptionModal from "../../modals/stripe-subscription-modal";
import LinkOutsidePromptModal from "../../modals/link-outside-warning-modal";
import StripeCPProductSubModal from "../../modals/stripe-cp-product-sub-modal";
import SPPurchaseModal from "../custom-page/sp-product-meta/components/sp-purchase-modal";
import REPurchaseModal from "../custom-page/re-produuct-meta/components/re-purchase-modal";
import RenderREProductMetaBox from "../custom-page/re-produuct-meta/re-product-meta-box";
import RenderSPProductMetaBox from "../custom-page/sp-product-meta/sp-product-meta-box";
import RatingBox from "../../components/rating";
import TrackBox from "../../components/track";
import RatingPromptModal from "../../modals/rating-prompt-modal";
import AuthPromptModal from "../../modals/auth-prompt-modal";

export default function CustomPageRenderer() {
  const [page, setPage] = useState({});
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [webData, setWebData] = useState("");
  const [hireReply, setHireReply] = useState("");
  const [subSecretKey, setSubSecretKey] = useState();
  const [paymentType, setPaymentType] = useState("");
  const [processing, setProcessing] = useState(false);
  const [webDataNoAds, setWebDataNoAds] = useState("");
  const [productMetas, setProductMetas] = useState([]);
  const [currScrollPos, setCurrScrollPos] = useState(0);
  const [showSubModal, setShowSubModal] = useState(false);
  const [CurrCPPRodMeta, setCurrCPPRodMeta] = useState("");
  const [webDataTokenized, setWebDataTokens] = useState([]);
  const [linkOutsideHref, setLinkOutsideHref] = useState("");
  const [productMetaInfo, setProductMetaInfo] = useState({});
  const [replyCaptured, setReplyCaptured] = useState(false);
  const [signupSuccess, setShowSignupSuccess] = useState(false);
  const [stripeModalOpen, setStripeModalOpen] = useState(false);
  const [selectPurchaseType, setSelectPurchaseType] = useState("");
  const [CPPRodShowSubModal, setCPPRodSubSecretKey] = useState("");
  const [platformModalOpen, setPlatformModalOpen] = useState(false);
  const [productMetaModeMode, setProductMetaMode] = useState(false);
  const [CPPRodSubModalOpen, setCPPRodSubModalOpen] = useState(false);
  const [productMetaModalOpen, setProductMetaModalOpen] = useState(false);
  const [linkOutsideModalOpen, setLinkOutsideModalOpen] = useState(false);
  const [registrationModalOpen, setRegistrationModalOpen] = useState(false);
  const [registration2ModalOpen, setRegistration2ModalOpen] = useState(false);
  const [registration4ModalOpen, setRegistration4ModalOpen] = useState(false);
  const [registration5ModalOpen, setRegistration5ModalOpen] = useState(false);
  const [productSlides, setProductSlides] = useState([]);
  const [currentRating, setCurrentRating] = useState();
  const [showRatingPromptModal, setShowRatingPromptModal] = useState(false);
  const [ratingPromptModalMessage, setShowRatingPromptModalMessage] =
    useState("");
  const [lastRatedBook, setLastRatedBook] = useState();
  const [showAuthPromptModal, setShowAuthPromptModal] = useState(false);

  // Initialize an agent at application startup, once per page/app.
  const botdPromise = load();
  // Get detection results when you need them.

  const naviagte = useNavigate();
  let { pageId } = useParams();
  const userHasScroledToBottomRef = useRef();
  const isNotRobotRef = useRef();
  let monetizationContentMatch = 0;
  let basicContentMatch = 0;
  let clickOutsideCounter = 0;

  const saveCurrScrollPos = () => {
    var scrollPosition = window.scrollY;
    setCurrScrollPos(scrollPosition);
  };

  const fetchPage = () => {
    const url = API_BASE + "/api/book/custom/single/" + pageId;
    setProcessing(true);
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setProcessing(false);
          setWebData(response.data.page.html_data);
          setTitle(response.data.page.name);
          setPage(response.data.page);
          setWebDataNoAds(response.data.page.html_adfree_data);
          setProductMetas(response.data.page.productmetas);
          if (response?.data?.page?.productimages) {
            const slides = response.data.page.productimages;
            const slideObj = slides.map((s) => ({
              id: s.client_id,
              thumb: s.product_thumb,
              large: s.product_large,
              slide_id: s.id,
            }));
            setProductSlides(slideObj);
          }
        }
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.data.invalid_ad_code) {
          toast("Invalid Ad Code", { type: "error" });
        } else {
          toast("Something went wrong", { type: "error" });
        }
      });
  };

  const getStripePaymentSecret = () => {
    let formData = new FormData();
    const url = API_BASE + "/api/user/payment/get-stripe-payment-secret";
    setProcessing(true);
    axios
      .post(url, {
        book_id: page.uuid,
        user_id: isAuthUser() ? authUserData().uuid : "",
      })
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setSubSecretKey(response.data.secret_key);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", {
          type: "error",
        });
      });
  };

  const getMetaByMetaBlockId = (meta) => {
    const url = API_BASE + "/api/book/custom/product/payment-secret";
    setProcessing(true);

    setTimeout(() => {
      axios
        .post(url, {
          page_uuid: page.uuid,
          start_date: meta.start_date,
          reply: meta.reply,
          user_id: authUserData().uuid,
          block_meta_id: meta.block_meta_id,
          price: meta.price,
        })
        .then((response) => {
          setProcessing(false);
          if (response.status == 200) {
            const priceLabsMode = response?.data?.data?.pricelabs;
            const state = {
              meta: response.data.meta,
              page_uuid: page.uuid,
              start_date: meta.start_date,
              client_secret: response.data.client_secret,
              price: !priceLabsMode
                ? response.data.meta.price
                : response?.data?.data?.pricelabs?.price,
            };
            setCurrCPPRodMeta(state);
            setCPPRodSubModalOpen(true);
          }
        })
        .catch((error) => {
          setProcessing(false);
          toast("Something went wrong!", {
            type: "error",
          });
        });
    }, 100);
  };

  const recordPageView = () => {
    const url = API_BASE + "/api/page/views";

    if (localStorage.getItem("unique_visitor_id")) {
      axios
        .post(url, {
          uuid: page.pages[0].uuid,
          unique_user_id: localStorage.getItem("unique_visitor_id"),
        })
        .catch((e) => {});
    } else {
      const userUniqueId = uuid();
      localStorage.setItem("unique_visitor_id", userUniqueId);
      axios
        .post(url, {
          uuid: page.pages[0].uuid,
          unique_user_id: userUniqueId,
        })
        .catch((e) => {});
    }
  };

  const hasPurchasedBook = () => {
    if (!authUserData()) {
      return false;
    }

    // if (page.owner && page.owner.id == authUserData().id) {
    //   return true;
    // }
    if (page.buyers) {
      const bought = page.buyers.filter((buyer) => {
        if (buyer.buyer_id == authUserData().id) {
          return buyer;
        }
      });
      const hasSubscribed = hasSubscribedToPublisher();
      return bought.length || hasSubscribed ? true : false;
    }
  };

  const hasSubscribedToPublisher = () => {
    if (!authUserData()) {
      return false;
    }

    if (page.owner.subscribers) {
      const subscribed = page.owner.subscribers.filter((subscriber) => {
        if (subscriber.user_id == authUserData().id && subscriber.active) {
          return subscriber;
        }
      });
      return subscribed.length ? true : false;
    }
  };

  const hideStripeModal = () => {
    setStripeModalOpen(false);
  };

  const scrollToTop = () => {
    // Get the current scroll position of the page.
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // Scroll the page to the top.
    window.scrollTo(0, scrollTop);
  };
  const showRegistrationModal = (paymentType) => {
    if (paymentType) {
      setPaymentType(paymentType);
    }

    setRegistrationModalOpen(true);
    if (isResponsiveMode) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      window.scrollTo(0, 0);
      scrollToTop();
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const hideRegistrationModal = () => {
    setRegistrationModalOpen(false);
  };
  const hideRegistration5Modal = () => {
    setRegistration5ModalOpen(false);
  };

  const hideRegistration2Modal = () => {
    setRegistration2ModalOpen(false);
  };
  const hideRegistration4Modal = () => {
    // if (productMetaModeMode && isAuthUser()) {
    //   setProductMetaModalOpen(true);
    // }
    setRegistration4ModalOpen(false);
  };

  const hideHireReplyModal = () => {
    setReplyCaptured(false);
    setProductMetaModalOpen(false);
    setSelectPurchaseType("");
    setProductMetaInfo({});
    window.scrollTo({
      top: currScrollPos,
      behavior: "smooth",
    });
  };

  const hideSubModal = () => {
    setSubSecretKey();
    setShowSubModal((prev) => (prev = !prev));
  };

  const hideCPProdSubModal = () => {
    setCPPRodSubSecretKey();
    setCurrCPPRodMeta("");
    setCPPRodSubModalOpen((prev) => (prev = !prev));
  };

  const openSubModal = () => {
    getStripePaymentSecret();
    setShowSubModal(true);
  };

  const insertDivIntoHTMLBlock = () => {
    if (webData && !hasPurchasedBook(page)) {
      const doc = new DOMParser().parseFromString(webData, "text/html");
      const arr = [...doc.body.children].map((child) => child.outerHTML);
      setWebDataTokens(arr);
    } else {
      if (webDataNoAds && hasPurchasedBook(page)) {
        const doc = new DOMParser().parseFromString(webDataNoAds, "text/html");
        const arr = [...doc.body.children].map((child) => child.outerHTML);
        setWebDataTokens(arr);
      }
    }
  };

  const insertAdsIntoDom = () => {
    let adCodes = document.getElementsByClassName("advert-block");
    let ads = [];
    if (adCodes && adCodes.length) {
      for (var i = 0; i < adCodes.length; i++) {
        adCodes[i].className += " " + "flex justify-center";
        var elem = document.createElement("img");
        elem.setAttribute("src", "/ad_1.PNG");
        adCodes[i].appendChild(elem);
        ads.push(adCodes[i].getAttribute("code"));
      }
    }
  };

  const showPlatformModal = () => {
    setPlatformModalOpen(true);
  };

  const hidePLatformModal = () => {
    setPlatformModalOpen(false);
  };

  const showSignupSuccess = () => {
    setShowSignupSuccess(true);
  };

  const handleHireReplyChange = (e) => {
    setHireReply(e);
  };

  const hideLinkOutsidePromtModal = () => {
    setLinkOutsideHref("");
    setLinkOutsideModalOpen(false);
  };

  const openLinkOutsidePromtModal = () => {
    setLinkOutsideModalOpen(true);
  };

  const processProductBuyerReply = () => {
    if (isAuthUser()) {
      setReplyCaptured(true);
    } else {
      setRegistration4ModalOpen(true);
    }
  };

  //Called from recurring purchase modal after date selected and submitted
  const processRecurringPayment = (meta) => {
    getMetaByMetaBlockId(meta);
    // setProductMetaModalOpen(false);
  };

  const processRecurringPreReg = () => {
    setSelectPurchaseType("recurring");
    setProductMetaModalOpen(true);
    if (productMetaInfo.meta.purchaser_reply_enabled) {
      setProductMetaMode(true);
    }
    hideRegistration5Modal();
  };

  const processSPPreReg = () => {
    setSelectPurchaseType("single");
    setProductMetaModalOpen(true);
    if (productMetaInfo.meta.purchaser_reply_enabled) {
      setProductMetaMode(true);
    }
    hideRegistration4Modal();
  };

  const shoudShowSubscription = (page) => {
    if (
      page &&
      page.enable_custom_page_purchase &&
      page.owner.enable_subscription &&
      page.enable_custom_page_subscription
    ) {
      return true;
    }

    if (
      page.owner.enable_subscription &&
      !page.enable_custom_page_subscription
    ) {
      return true;
    }

    return false;
  };
  const shoudShowPurchase = (page) => {
    if (
      page &&
      page.enable_custom_page_purchase &&
      !page.owner.enable_subscription &&
      page.enable_custom_page_subscription
    ) {
      return true;
    }
    if (
      page &&
      page.enable_custom_page_purchase &&
      page.owner.enable_subscription &&
      page.enable_custom_page_subscription
    ) {
      return true;
    }

    if (page.price && !page.enable_custom_page_purchase) {
      return true;
    }
    return false;
  };

  const renderAdvertTemplate = (code) => {
    if (page.owner && page.owner?.advertrequest?.status == "approved") {
      if (page.owner.advertrequest.provider == "ADSENSE") {
        const pubCode = page.owner.advertrequest.ad_code;
        const litzappCode = page.owner.advertrequest.system_ad_code;
        return (
          <>
            {" "}
            <div className="flex justify-center w-full">
              <AdSense.Google
                client="ca-pub-8191791319921859"
                slot={pubCode}
                style={{ width: 250, height: 250, float: "left" }}
                layout="in-article"
                format=""
              />
            </div>
            <div className="flex justify-center w-full mt-2">
              <AdSense.Google
                client="ca-pub-8191791319921859"
                slot={litzappCode}
                style={{ width: 250, height: 250, float: "left" }}
                layout="in-article"
                format=""
              />
            </div>
          </>
        );
        /*  */
      } else {
        const pubCode = page.owner.advertrequest.ad_2_code;
        const litzappCode = page.owner.advertrequest.system_ad_2_code;
        return (
          <>
            <div className="flex justify-center w-full">
              <Adsterra slot={pubCode} />;
            </div>
            <div className="flex justify-center w-full mt-2">
              <Adsterra slot={litzappCode} />;
            </div>
          </>
        );
      }
    }

    if (page.owner && page.owner?.advertrequest.status == "unprocessed") {
      return (
        <div className="flex justify-center w-full mt-2">
          <Adsterra slot={"7280370"} />
        </div>
      );
    }
  };

  const RenderSignupFoUpdatesBox = () => {
    return (
      <div className="w-full text-center my-1">
        {!signupSuccess ? (
          <BasicButton
            title={`Sign up for updates`}
            handleClick={() => showPlatformModal()}
            classes="bg-black text-white shadow"
          />
        ) : (
          ""
        )}

        {signupSuccess ? (
          <h3 className="text-2xl text-white">Thanks for Signing up</h3>
        ) : (
          ""
        )}
      </div>
    );
  };

  const RenderButtonLinkBox = (data) => {
    const html = data.data; //Your html string
    const tmpDiv = document.createElement("div");
    tmpDiv.innerHTML = html;
    const buttonLinkName = tmpDiv
      .querySelector(".button-link-xyz")
      .getAttribute("name");
    const src = tmpDiv.querySelector(".button-link-xyz").getAttribute("link");
    return (
      <div className="w-full text-center my-2">
        <a
          href={src}
          target="_blank"
          onClick={(e) => setupClickOutsideBtEventListener(e, src)}
        >
          <BasicButton
            title={buttonLinkName}
            classes="bg-custom-page-link-button text-white shadow"
            handleClick={() => null}
          />
        </a>
      </div>
    );
  };

  const RenderImageBox = (data) => {
    const html = data.data; //Your html string
    const tmpDiv = document.createElement("div");
    tmpDiv.innerHTML = html;
    const imageLink = tmpDiv
      .querySelector(".image-link-xyz")
      .getAttribute("imageLink");
    const src = tmpDiv.querySelector(".image-link-xyz").getAttribute("src");
    return (
      <div className="w-full text-center my-2">
        {imageLink && imageLink !== "" ? (
          <a href={imageLink} target="_blank" className="cursor-pointer">
            <img src={src} />
          </a>
        ) : (
          <img src={src} />
        )}
      </div>
    );
  };

  const RenderVideoBox = (data) => {
    const html = data.data; //Your html string
    const tmpDiv = document.createElement("div");
    tmpDiv.innerHTML = html;
    const src = tmpDiv.querySelector(".video-box-xyz").getAttribute("src");
    const linkMode = tmpDiv
      .querySelector(".video-box-xyz")
      .getAttribute("linkMode");
    // console.log(tmpDiv);
    const opts = {
      width: isResponsiveMode() ? "100%" : "100%",
      height: isResponsiveMode() ? "250px" : "500px",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
      },
    };
    const youtubeVideoIdExists = (url) => {
      if (!url) {
        return false;
      }

      let { id, service } = getVideoId(url);

      if (!id || service !== "youtube") {
        return false;
      }

      return getVideoId(url);
    };

    // const src = "";
    // const linkMode = "";

    const _onReady = (e) => {
      // access to player in all event handlers via event.target
      e.target.pauseVideo();
    };
    return (
      <div class="flex flex-wrap justify-center w-full my-2">
        <div className="relative w-full">
          {linkMode == "link" && youtubeVideoIdExists(src) ? (
            <YouTube
              className="w-full"
              videoId={youtubeVideoIdExists(src).id}
              opts={opts}
              onReady={_onReady}
            />
          ) : (
            <video
              controls="controls"
              className="w-full"
              src={src}
              type="video/mp4"
              height="200px"
            ></video>
          )}
        </div>
      </div>
    );
  };

  const RenderUPdatedMonetization = ({
    section,
    monetizedCount,
    basicCount,
  }) => {
    if (monetizedCount > 1) {
      return (
        <div className="relative flex justify-center w-full">
          <div
            className="w-full custom-page-content"
            dangerouslySetInnerHTML={{
              __html: section,
            }}
          ></div>
        </div>
      );
    }
    return (
      <div className="relative flex justify-center w-full">
        <div
          className="w-full custom-page-content"
          dangerouslySetInnerHTML={{
            __html: section.replace(/<a[^>]*>|<\/a>/g, ""),
          }}
        ></div>
        {!hasPurchasedBook(page) &&
        isAuthUser() &&
        page.owner &&
        page.owner.id !== authUserData().id ? (
          <div className="flex md:flex-row items-center gap-2 absolute top-0 bottom-0">
            {page.price && shoudShowPurchase(page) ? (
              <StripeForm
                book={page}
                classes="my-bg-yellow-500 text-black z-50"
                action={fetchPage}
                title={"Purchase"}
              />
            ) : (
              ""
            )}
            {page.owner &&
            page.owner.enable_subscription &&
            shoudShowSubscription(page) ? (
              <BasicButton
                classes="my-bg-yellow-500 text-black text-xxs z-40 p-0"
                title={`Subscribe $${subscritionCostWithSystemFees(page)}/mth`}
                handleClick={() => openSubModal()}
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        {isAuthUser() && page.owner && page.owner.id == authUserData().id ? (
          <div className="flex md:flex-row items-center gap-2 absolute top-0 bottom-0">
            {page.price && shoudShowPurchase(page) ? (
              <BasicButton
                title={`Purchase $${bookPriceWithSystemFees(page)}`}
                handleClick={() => toast("Preview Only", { type: "notice" })}
                classes="my-bg-yellow-500 text-black"
              />
            ) : (
              ""
            )}

            {page.owner &&
            page.owner.enable_subscription &&
            shoudShowSubscription(page) ? (
              <BasicButton
                title={`Subscribe $${subscritionCostWithSystemFees(page)}/mth`}
                handleClick={() => toast("Preview Only", { type: "notice" })}
                classes="my-bg-yellow-500 text-black"
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {!isAuthUser() ? (
          <div className="flex md:flex-row items-center gap-2 absolute top-0 bottom-0">
            {page.price && shoudShowPurchase(page) ? (
              <BasicButton
                title={`Purchase Page $${bookPriceWithSystemFees(page)}`}
                handleClick={() => showRegistrationModal("one-time")}
                classes="my-bg-yellow-500 text-black"
              />
            ) : (
              ""
            )}

            {page.owner &&
            page.owner.enable_subscription &&
            shoudShowSubscription(page) ? (
              <BasicButton
                title={`Subscribe $${subscritionCostWithSystemFees(page)}/mth`}
                handleClick={() => showRegistrationModal("subscription")}
                classes="my-bg-yellow-500 text-black"
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const RenderFreeUserPageItems = (html) => {
    clickOutsideCounter++;
    const shouldRenderAds =
      page.owner?.advertrequest?.status == "approved" ||
      page.owner?.advertrequest?.status == "unprocessed";
    if (html.includes('style="color: transparent;')) {
      // console.log('monetization section found')
      monetizationContentMatch++;
      basicContentMatch = 0;
      let updatedHtml = html.replace(/<a[^>]*>|<\/a>/g, "");
      return (
        <RenderUPdatedMonetization
          section={updatedHtml}
          monetizedCount={monetizationContentMatch}
          basicCount={basicContentMatch}
        />
      );
    }

    if (html.includes('class="signup-block') && !signupSuccess) {
      basicContentMatch++;
      monetizationContentMatch = 0;
      return <RenderSignupFoUpdatesBox />;
    }

    if (html.includes('class="video-box-xyz')) {
      basicContentMatch++;
      monetizationContentMatch = 0;
      return <RenderVideoBox data={html} />;
    }

    if (html.includes('class="image-link-xyz')) {
      basicContentMatch++;
      monetizationContentMatch = 0;
      return <RenderImageBox data={html} />;
    }

    if (html.includes('class="product-meta-xyz')) {
      basicContentMatch++;
      monetizationContentMatch = 0;
      return page.owner ? (
        <RenderProductMetaBox
          data={html}
          page={page}
          state={{
            setProductMetaModalOpen: setProductMetaModalOpen,
            setProductMetaInfo: setProductMetaInfo,
            setProductMetaMode: setProductMetaMode,
            setRegistration4ModalOpen: setRegistration4ModalOpen,
            setRegistration5ModalOpen: setRegistration5ModalOpen,
            setSelectPurchaseType: setSelectPurchaseType,
            saveCurrScrollPos: saveCurrScrollPos,
          }}
        />
      ) : (
        ""
      );
    }

    if (html.includes('class="product-slides-xyz')) {
      return <RenderSlidesBox data={html} productSlides={productSlides} />;
    }

    if (html.includes('class="banners-xyz')) {
      return <RenderBannerBox data={html} productSlides={productSlides} />;
    }

    if (html.includes('class="social-embed-xyz')) {
      return <RenderSocialEmbedBox data={html} />;
    }

    if (html.includes('class="social-link-xyz')) {
      return <RenderSocialLinkBox data={html} />;
    }

    if (html.includes('class="table-xyz')) {
      return <RenderTableBox data={html} />;
    }
    if (html.includes('class="accordion-xyz')) {
      return <RenderAccordionBox data={html} />;
    }

    if (html.includes('class="button-link-xyz')) {
      basicContentMatch++;
      monetizationContentMatch = 0;
      return <RenderButtonLinkBox data={html} />;
    }

    if (html.includes('class="advert-block') && page.owner && shouldRenderAds) {
      basicContentMatch++;
      monetizationContentMatch = 0;
      return (
        <RenderAdvertBox
          data={html}
          renderAdvertTemplate={renderAdvertTemplate}
          pageId={pageId}
          page={page}
        />
      );
    }

    basicContentMatch++;
    monetizationContentMatch = 0;

    const clickOutsideId = "custom-page-generic-content-" + clickOutsideCounter;
    let htmlToRender = html;

    if ("<p>Type here...</p>" == html) {
      htmlToRender = "";
    }
    return (
      <div
        className="custom-page-content"
        id={clickOutsideId}
        onClick={(e) => setupClickOutsideEventListener(e, clickOutsideId)}
        dangerouslySetInnerHTML={{ __html: htmlToRender }}
      ></div>
    );
  };

  const RenderPaidUserPageItems = (html) => {
    const shouldRenderAds =
      page.owner?.advertrequest?.status == "approved" ||
      page.owner?.advertrequest?.status == "unprocessed";
    if (html.includes('class="signup-block') && !signupSuccess) {
      return <RenderSignupFoUpdatesBox />;
    }

    if (html.includes('class="video-box-xyz')) {
      return <RenderVideoBox data={html} />;
    }
    if (html.includes('class="image-link-xyz')) {
      return <RenderImageBox data={html} />;
    }

    if (html.includes('class="product-meta-xyz')) {
      return page.owner ? (
        <RenderProductMetaBox
          data={html}
          page={page}
          state={{
            setProductMetaModalOpen: setProductMetaModalOpen,
            setProductMetaInfo: setProductMetaInfo,
            setProductMetaMode: setProductMetaMode,
            setRegistration4ModalOpen: setRegistration4ModalOpen,
            setRegistration5ModalOpen: setRegistration5ModalOpen,
            setSelectPurchaseType: setSelectPurchaseType,
            saveCurrScrollPos: saveCurrScrollPos,
          }}
        />
      ) : (
        ""
      );
    }

    if (html.includes('class="product-slides-xyz')) {
      return <RenderSlidesBox data={html} productSlides={productSlides} />;
    }
    if (html.includes('class="banners-xyz')) {
      return <RenderBannerBox data={html} productSlides={productSlides} />;
    }

    if (html.includes('class="social-embed-xyz')) {
      return <RenderSocialEmbedBox data={html} />;
    }

    if (html.includes('class="social-link-xyz')) {
      return <RenderSocialLinkBox data={html} />;
    }

    if (html.includes('class="table-xyz')) {
      return <RenderTableBox data={html} />;
    }
    if (html.includes('class="accordion-xyz')) {
      return <RenderAccordionBox data={html} />;
    }

    if (html.includes('class="button-link-xyz')) {
      return <RenderButtonLinkBox data={html} />;
    }

    if (html.includes('class="advert-block') && page.owner && shouldRenderAds) {
      return (
        <RenderAdvertBox
          data={html}
          renderAdvertTemplate={renderAdvertTemplate}
          pageId={pageId}
          page={page}
        />
      );
    }

    const clickOutsideId = "custom-page-generic-content-" + clickOutsideCounter;

    return (
      <div
        className="custom-page-content"
        id={clickOutsideId}
        onClick={(e) => setupClickOutsideEventListener(e, clickOutsideId)}
        dangerouslySetInnerHTML={{ __html: html }}
      ></div>
    );
  };

  const hasNotScrolledToBottom = () => {
    if (page && page.owner && page.owner.id == authUserData().id) {
      return false;
    }
    if (!page.lastpageviewed) {
      return true;
    }
    return !page.lastpageviewed.filter((p) => p.user_id == authUserData().id)
      .length;
  };

  const bottomVisible = () => {
    const el = document.getElementById("bottom");
    const rect = el?.getBoundingClientRect();
    const visible =
      rect?.top >= 0 &&
      rect?.left >= 0 &&
      rect?.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth);

    if (
      page &&
      hasNotScrolledToBottom() &&
      !userHasScroledToBottomRef.at_bottom &&
      visible
    ) {
      // alert("About to record last page");
      userHasScroledToBottomRef.at_bottom = true;
      recordLastPageView(authUserData().uuid, page.uuid);
    }

    return visible;
  };

  const setupScrollListener = () => {
    document.addEventListener("scroll", bottomVisible);
  };

  const recordLastPageView = (userId, bookId) => {
    const url = API_BASE + "/api/views/last-viewed";
    axios.post(url, { userId: userId, bookId: bookId }).catch((e) => {});
  };

  const checkBotActivity = (res) => {
    const url = API_BASE + "/api/bot/record";

    axios
      .post(url, {
        result: res.bot ? 1 : 0,
        book_id: pageId,
      })
      .catch((e) => {});
  };

  const isNotRobot = () => {
    if (
      (isNotRobotRef.current && isNotRobotRef.current == "mouse") ||
      isNotRobotRef.current == "scroll"
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (page) {
      if (true) {
        setTimeout(() => {
          insertDivIntoHTMLBlock();
        }, 500);
      } else {
      }
    }
  }, [webData]);

  useEffect(() => {
    if (page.id) {
      //localStorage.removeItem("unique_visitor_id");
      recordPageView(localStorage.getItem("unique_visitor_id"));
      const timer = setTimeout(function () {
        if (isAuthUser()) {
          setupScrollListener();
        }
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [page]);

  useEffect(() => {
    fetchPage();
    if (!isRegistrationComplete()) {
      setRegistration2ModalOpen(true);
      localStorage.removeItem("registration_type");
    }
    document.addEventListener("contextmenu", (event) => {
      //event.preventDefault();
    });

    document.addEventListener("mousemove", () => {
      isNotRobotRef.current = "mouse";
    });

    document.addEventListener("scroll", () => {
      isNotRobotRef.current = "scroll";
    });

    setTimeout(() => {
      botdPromise
        .then((botd) => botd.detect())
        .then((result) => console.log(checkBotActivity(result)))
        .catch((error) => console.error(error));
    }, 1000);
  }, []);

  const setupClickOutsideEventListener = (e, clickOutsideId) => {
    console.log("id", e.target.tagName);
    if (e.target.tagName == "A") {
      if (
        e.target.hostname !== "litzapp.com" &&
        e.target.hostname !== "localhost"
      ) {
        e.preventDefault();
        setLinkOutsideHref(e.target.href);
        openLinkOutsidePromtModal();
        console.log(e, "clicked");
      }
    }
  };

  const setupClickOutsideBtEventListener = (e, src) => {
    if (!src.includes("litzapp.com") && !src.includes("localhost")) {
      e.preventDefault();
      setLinkOutsideHref(src);
      openLinkOutsidePromtModal();
    }
  };

  const showCurrentLocation = () => {
    if (getLocation().hostname == "litzapp.com") {
      copyText("https://litzapp.com/share/sh/" + page.item_id);
    } else {
      copyText("https://wordalbums1.test/sh/" + page.item_id);
    }
  };

  const copyText = (text) => {
    // standard way of copying
    var textArea = document.createElement("textarea");
    textArea.setAttribute("style", "width:1px;border:0;opacity:0;");
    document.body.appendChild(textArea);
    textArea.value = text;
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    toast("Link Copied", { type: "success" });
  };

  const disableProductPage = () => {
    if (page.id) {
      if (page?.owner.supplier_account_enabled) {
        return false;
      }
    }
    return true;
  };

  const SocialsLinks = () => {
    const links = [];
    try {
      if (page?.social_links) {
        const obj = JSON.parse(JSON.parse(page.social_links));
        const countMedia = Object.keys(obj).filter((k) => obj[k] !== "").length;
        let gap = 2;
        if (isResponsiveMode()) {
          // if (countMedia < 10) gap = 4;
          if (countMedia < 5) gap = 4;
        } else {
          gap = 2;
        }
        return (
          <div
            className={`w-full inline-flex justify-center text-center align-centet`}
          >
            {Object.keys(obj)
              .filter((k) => obj[k] !== "")
              .map((key) => {
                return (
                  <div key={key} className="mx-1">
                    <a href={obj[key]} target="_blank">
                      <img
                        src={"/assets/socials/" + key + ".png"}
                        className="w-6 md:w-8"
                      />
                    </a>
                  </div>
                );
              })}
          </div>
        );
      }
    } catch (error) {
      return <span></span>;
    }
  };

  const openAuthPromtModal = () => {
    setShowAuthPromptModal(true);
  };

  const hideAuthPromtModal = () => {
    setShowAuthPromptModal(false);
  };

  const openRatingPromtModal = (msg) => {
    setShowRatingPromptModalMessage(msg);
    setShowRatingPromptModal(true);
  };

  const hideRatingPromtModal = () => {
    setShowRatingPromptModalMessage("");
    setShowRatingPromptModal(false);
  };

  const rateBook = (value, book) => {
    const url = API_BASE + "/rating";
    if (!isAuthUser()) {
      openRatingPromtModal("Please###");
      return false;
    }
    if (book?.owneroptimized?.uuid == authUserData().uuid) {
      return false;
    }

    setCurrentRating(value);
    authRequestService()
      .post(url, {
        value: value,
        bookId: book.uuid,
        userId: authUserData().uuid,
      })
      .then((response) => {
        if (response.status == 200) {
          setLastRatedBook(response.data.book);
        }
      })
      .catch((error) => {
        if (error.response.status == 422) {
          if ("no_purchase_record" in error.response.data) {
            openRatingPromtModal(
              "You can only leave a rating if you have purchased the product."
            );
            return false;
          }
          openRatingPromtModal(
            "You can only leave a rating if you have viewed the content to its last slide/page."
          );
          return false;
        }
        toast("Something went wrong", { type: "error" });
      });
  };

  const isTrackingPublisher = (book) => {
    if (book?.owner?.trackers && isAuthUser()) {
      const isTracking = book?.owner?.trackers.filter(
        (t) => t.tracker_id == authUserData().id
      );
      return isTracking.length ? true : false;
    }

    return false;
  };

  const trackPublisher = (book, trackerId) => {
    const url = API_BASE + "/track";
    authRequestService()
      .post(url, {
        publisherId: book?.owner?.uuid,
        trackerId: trackerId,
      })
      .then((response) => {
        if (response.status == 200) {
          fetchPage();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const untrackPublisher = (book, trackerId) => {
    const url = "/untrack";
    openRequestService()
      .post(url, {
        publisherId: book?.owner?.uuid,
        trackerId: trackerId,
      })
      .then((response) => {
        if (response.status == 200) {
          fetchPage();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <>
      {" "}
      <WebHeader />
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">
            {" "}
            {title && title}
          </h1>
        </div>
        <div className="flex flex-col md:flex-row  justify-between gap-2">
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div class="">
        <br />
        <div class="flex flex-col text-center md:text-left justify-evenly items-center">
          <div class="flex flex-col w-full items-center">
            <div></div>
            <h1 class="text-2xl text-gray-800 font-bold mt-2">
              {/* {title && title} */}
            </h1>
            {page.id ? (
              <div className="flex justify-center gap-4 my-4 md:w-6/12">
                <BasicButton
                  classes={"my-bg-yellow-500 text-black px-8"}
                  title={"By" + " " + page.owner.name}
                  handleClick={() =>
                    (window.location.href = `/web/${page.owner.uuid}`)
                  }
                />
                <div onClick={() => showCurrentLocation()}>
                  <i class="fa fa-share-alt cursor-pointer"></i>
                  {/* {getLocation().href} */}
                </div>
                <div className="flex gap-2">
                  {page.owner.profile_photo ? (
                    <img
                      onClick={() =>
                        (window.location.href = `/web/${page.owner.uuid}`)
                      }
                      className="rounded-full object-cover h-8 w-8 cursor-pointer"
                      src={
                        BASE_URL +
                        "/storage/" +
                        page.owner.profile_photo.split("public")[1]
                      }
                    />
                  ) : (
                    <img
                      onClick={() =>
                        (window.location.href = `/web/${page.owner.uuid}`)
                      }
                      className="rounded-full h-8 w-8 cursor-pointer"
                      src={"/avatar.png"}
                    />
                  )}
                  <span>
                    {" "}
                    {page.created_at ? page.refactoredCreatedAtTime : "N/A"}
                  </span>
                </div>
                {page?.id ? (
                  <div className="hidden md:block">
                    {" "}
                    <TrackBox
                      page={page}
                      lastRatedBook={lastRatedBook}
                      isTrackingPublisher={isTrackingPublisher}
                      untrackPublisher={untrackPublisher}
                      trackPublisher={trackPublisher}
                      openAuthPromtModal={openAuthPromtModal}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {page?.type == "product" && page?.id && page?.productmetas[0] && (
              <div className="flex justify-center gap-4">
                <a
                  href={`mailto:${page.productmetas[0].customerservicedata.email}`}
                >
                  <div className="flex gap-1">
                    <span>
                      <i class="fa fa-envelope" aria-hidden="true"></i>
                    </span>
                    {page.productmetas[0].customerservicedata.email}
                  </div>
                </a>
                <a
                  href={`tel:${page.productmetas[0].customerservicedata.phone}`}
                >
                  <div className="flex gap-1">
                    <span> 📱</span>
                    <span>
                      {page.productmetas[0].customerservicedata.phone}
                    </span>
                  </div>
                </a>
              </div>
            )}
            <SocialsLinks />
            {page?.id ? (
              <div className="md:hidden">
                {" "}
                <TrackBox
                  page={page}
                  lastRatedBook={lastRatedBook}
                  isTrackingPublisher={isTrackingPublisher}
                  untrackPublisher={untrackPublisher}
                  trackPublisher={trackPublisher}
                  openAuthPromtModal={openAuthPromtModal}
                />
              </div>
            ) : (
              ""
            )}

            <div className="my-2">
              {" "}
              {page.id ? (
                <RatingBox
                  book={page}
                  rateBook={rateBook}
                  lastRatedBook={lastRatedBook}
                  currentRating={currentRating}
                />
              ) : (
                ""
              )}
            </div>

            <br />
            {page.admin_publish_status == false && (
              <p className="pt-20 pb-10">
                This content is no longer available.
              </p>
            )}

            {disableProductPage() && !processing && page?.id && (
              <p className="pt-20 pb-10">
                The monetizer account is currently restricted.
              </p>
            )}
            {!page.admin_publish_status == false && !disableProductPage() && (
              <div
                className="text-left break-words disable-text-selection flex flex-col items-center w-full"
                onmousedown="return false"
                onselectstart="return false"
              >
                {page &&
                  page.owner &&
                  !hasPurchasedBook(page) &&
                  webDataTokenized.map((html) => {
                    const fScreen = html.includes('class="banners-xyz');
                    return (
                      <div
                        className={` ${
                          fScreen ? "w-full my-2 mb-4" : "w-10/12 md:w-6/12"
                        }`}
                      >
                        {RenderFreeUserPageItems(html)}
                      </div>
                    );
                  })}
                {page &&
                  page.owner &&
                  hasPurchasedBook(page) &&
                  webDataTokenized.map((html) => {
                    const fScreen = html.includes('class="banners-xyz');
                    return (
                      <div
                        className={` ${
                          fScreen ? "w-full my-2 mb-4" : "w-10/12 md:w-6/12"
                        }`}
                      >
                        {RenderPaidUserPageItems(html)}
                      </div>
                    );
                  })}
                <p id="bottom"></p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
      <StripePaymentModal
        modalOpen={stripeModalOpen}
        hideModal={hideStripeModal}
        book={page}
      />
      <RegistrationModal
        modalOpen={registrationModalOpen}
        hideModal={hideRegistrationModal}
        book={page}
        paymentType={paymentType}
      />
      {productMetaInfo.price && page?.uuid ? (
        <Registration5Modal
          modalOpen={registration5ModalOpen}
          hideModal={hideRegistration5Modal}
          data={productMetaInfo}
          action={processRecurringPreReg}
        />
      ) : (
        ""
      )}
      {productMetaInfo.price && page?.uuid ? (
        <Registration4Modal
          modalOpen={registration4ModalOpen}
          hideModal={hideRegistration4Modal}
          data={productMetaInfo}
          paymentType={paymentType}
          action={processSPPreReg}
        />
      ) : (
        ""
      )}
      {productMetaInfo.price &&
      page &&
      page.uuid &&
      selectPurchaseType &&
      selectPurchaseType == "single" ? (
        <SPPurchaseModal
          modalOpen={productMetaModalOpen}
          hideModal={hideHireReplyModal}
          handleHireReplyChange={handleHireReplyChange}
          replyCaptured={replyCaptured}
          processProductBuyerReply={processProductBuyerReply}
          setReplyCaptured={setReplyCaptured}
          setProductMetaInfo={setProductMetaInfo}
          data={productMetaInfo}
          page={page}
        />
      ) : (
        ""
      )}
      {productMetaInfo.price &&
      page &&
      page.uuid &&
      selectPurchaseType &&
      selectPurchaseType == "recurring" ? (
        <REPurchaseModal
          modalOpen={productMetaModalOpen}
          hideModal={hideHireReplyModal}
          replyCaptured={replyCaptured}
          data={productMetaInfo}
          processing={processing}
          state={{
            processRecurringPayment: processRecurringPayment,
            page: page,
          }}
        />
      ) : (
        ""
      )}
      <StripeSubscriptionModal
        modalOpen={showSubModal}
        hideModal={hideSubModal}
        book={page}
        meta={{}}
        subSecretKey={subSecretKey}
      />
      <StripeCPProductSubModal
        modalOpen={CPPRodSubModalOpen}
        hideModal={hideCPProdSubModal}
        state={CurrCPPRodMeta}
      />
      <PlatformUpdateModal
        modalOpen={platformModalOpen}
        hideModal={hidePLatformModal}
        type={"page"}
        book={page}
        showSignupSuccess={showSignupSuccess}
      />
      <Registration2Modal
        modalOpen={registration2ModalOpen}
        hideModal={hideRegistration2Modal}
        mode="password_reset_transaction"
      />
      <LinkOutsidePromptModal
        modalOpen={linkOutsideModalOpen}
        hideAdModal={hideLinkOutsidePromtModal}
        linkOutsideHref={linkOutsideHref}
        title={
          "The link you clicked will take you to an external application or site outside Litzapp's platform. While the link may be safe, we cannot guarantee its safety, and Litzapp is not liable for any damages or inconveniences you may encounter. Proceed at your own discretion."
        }
        yesActionText={"Proceed to External Site / App"}
        noActionText={"Do not Proceed. Cancel"}
        action={() =>
          linkOutsideHref
            ? (window.location.href = linkOutsideHref)
            : console.log("")
        }
        processing={processing}
      />
      <RatingPromptModal
        hideAdModal={hideRatingPromtModal}
        modalOpen={showRatingPromptModal}
        body={ratingPromptModalMessage}
      />
      <AuthPromptModal
        hideAdModal={hideAuthPromtModal}
        modalOpen={showAuthPromptModal}
      />
    </>
  );
}

const RenderProductMetaBox = ({ data, page, state }) => {
  const html = data; //Your html string
  const tmpDiv = document.createElement("div");
  tmpDiv.innerHTML = html;
  const payload = tmpDiv
    .querySelector(".product-meta-xyz")
    .getAttribute("payload");
  const meta = JSON.parse(payload);

  const supplierCanAcceptPayments = () => {
    if (page.id) {
      if (
        page?.owner?.supplier_status == "redo" ||
        page?.owner?.supplier_status == "resume" ||
        page?.owner?.supplier_status == "rejected"
      )
        return false;
    }
    return true;
  };

  if (!supplierCanAcceptPayments()) return <span></span>;

  if (meta.purchase_type == 1) {
    return (
      <RenderSPProductMetaBox payload={payload} page={page} state={state} />
    );
  } else {
    return (
      <RenderREProductMetaBox payload={payload} page={page} state={state} />
    );
  }
};

const RenderSlidesBox = ({ data, productSlides }) => {
  const imageGalleryRef = useRef(null);
  const [fullscreen, setFullScreen] = useState(false);

  const html = data; //Your html string
  const tmpDiv = document.createElement("div");
  tmpDiv.innerHTML = html;
  const payload = tmpDiv
    .querySelector(".product-slides-xyz")
    .getAttribute("payload");
  const slides = JSON.parse(payload);

  const images = productSlides
    .filter((obj) => slides.includes(obj.id))
    .map((slide) => ({
      original: BASE_URL + "/storage" + slide.large.split("public")[1],
      thumbnail: null,
    }));

  {
    /* imageContainer.addEventListener("click", checkImageClick); */
  }

  return (
    <div className="relative">
      {isResponsiveMode() && fullscreen && (
        <button
          onClick={() => imageGalleryRef.current.toggleFullScreen()}
          className={
            "fixed z-999999 bg-yellow-500  top-0 left-5 right-5 p-1 w-4/12 mx-auto rounded-lg mt-5"
          }
        >
          Exit
        </button>
      )}
      {productSlides.length && (
        <ImageGallery
          items={images}
          showThumbnails={false}
          showBullets={true}
          useBrowserFullscreen={isResponsiveMode() ? false : true}
          ref={imageGalleryRef}
          fullscreen={fullscreen}
          onScreenChange={(screen) => setFullScreen(screen)}
          onClick={(e) => {
            if (!fullscreen) {
              imageGalleryRef.current.toggleFullScreen();
            }
          }}
        />
      )}
    </div>
  );
};

const RenderBannerBox = ({ data, productSlides }) => {
  const imageGalleryRef = useRef(null);
  const [fullscreen, setFullScreen] = useState(false);

  function arrayDoesNotContainObjects(arr) {
    return arr.every((item) => typeof item !== "object" || item === null);
  }

  const html = data; //Your html string
  const tmpDiv = document.createElement("div");
  tmpDiv.innerHTML = html;
  const payload = tmpDiv.querySelector(".banners-xyz").getAttribute("payload");
  const slides = JSON.parse(payload);
  if (arrayDoesNotContainObjects(slides)) return <span></span>;
  const slideIds = slides.map((s) => s.id);
  const getSlideMeta = (slide) => {
    const item = slides.filter((s) => s.id == slide.id);
    if (item.length) return item[0];
    return {};
  };
  //Check to ensure pictures from database contained in payload have not been deleted.
  const images = productSlides
    .filter((obj) => slideIds.includes(obj.id))
    .map((slide) => {
      const url = getSlideMeta(slide)?.url;
      const text = getSlideMeta(slide)?.text;
      return {
        source: BASE_URL + "/storage" + slide.large.split("public")[1],
        text: text,
        url:
          url.indexOf("://") === -1
            ? "http://" + getSlideMeta(slide)?.url
            : url,
      };
    });

  {
    /* imageContainer.addEventListener("click", checkImageClick); */
  }
  if (!images.length) return <span></span>;

  const AutoplaySlider = withAutoplay(AwesomeSlider);

  function isEmptyOrSpaces(str) {
    return str.trim().length === 0;
  }

  return (
    <div className="relative w-full mb-6">
      {isResponsiveMode() && fullscreen && (
        <button
          onClick={() => imageGalleryRef.current.toggleFullScreen()}
          className={
            "fixed z-999999 bg-yellow-500  top-0 left-5 right-5 p-1 w-4/12 mx-auto rounded-lg mt-5"
          }
        >
          Exit
        </button>
      )}
      <AutoplaySlider play={true} interval={3000} cancelOnInteraction={false}>
        {images.map((img) => {
          return (
            <div className="w-full relative">
              {isEmptyOrSpaces(img.url) ? (
                <img className="w-full" src={img.source} />
              ) : (
                <a href={img.url} target="_blank" className="cursor-pointer">
                  {" "}
                  <img className="w-full" src={img.source} />
                </a>
              )}

              {!isEmptyOrSpaces(img.text) ? (
                <div className="w-8/12 bg-black z-999999 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 roundedlg opacity-75 text-center">
                  <p className="text-white text-1xl md:text-2xl">{img.text}</p>
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </AutoplaySlider>
      {/* <AwesomeSlider media={images} /> */}
    </div>
  );
};

const RenderSocialEmbedBox = ({ data }) => {
  const html = data; //Your html string
  const tmpDiv = document.createElement("div");
  tmpDiv.innerHTML = html;
  const payload = tmpDiv
    .querySelector(".social-embed-xyz")
    .getAttribute("payload");
  const p = JSON.parse(payload);
  if (p.type == "twitter") {
    const regex = /\d{19}/; // Regular expression for 17-digit tweet ID
    const match = regex.exec(p.data);
    if (match) {
      const tweetId = match[0];
      if (tweetId)
        return (
          <div className="flex justify-center my-2">
            <Tweet id={tweetId} />
          </div>
        );

      return <p></p>;
    } else {
      return <p></p>;
    }
  }

  if (p.type == "instagram") {
    if (!p.data.includes("blockquote")) {
      return (
        <div className="flex justify-center my-2">
          <InstagramEmbed url={p.data} width={328} captioned />
        </div>
      );
    }
  }

  if (p.type == "facebook") {
    if (!p.data.includes("iframe")) {
      return (
        <div className="flex justify-center my-2">
          <FacebookEmbed url={p.data} width={550} />;
        </div>
      );
    }
  }

  if (p.type == "twitter") {
    if (!p.data.includes("blockquote")) {
      return (
        <div className="flex justify-center my-2">
          <XEmbed url={p.data} width={325} />
        </div>
      );
    }
  }

  if (p.type == "pinterest") {
    if (!p.data.includes("blockquote")) {
      try {
        const id = p?.data?.match(/pin\/(\d+)/);
        if (id) {
          return (
            <div className="flex justify-center my-2">
              <PinterestEmbed url={p?.data} width={345} height={467} />
            </div>
          );
        }
      } catch (error) {}
    }
  }

  return (
    <div className="flex justify-center my-2">
      <div
        className="relative "
        dangerouslySetInnerHTML={{ __html: p.data }}
      ></div>
    </div>
  );
};

const RenderSocialLinkBox = ({ data }) => {
  const imageGalleryRef = useRef(null);
  const [fullscreen, setFullScreen] = useState(false);

  useEffect(() => {
    if (window.instgrm) window.instgrm.Embeds.process();
  }, []);

  const html = data; //Your html string
  const tmpDiv = document.createElement("div");
  tmpDiv.innerHTML = html;
  const payload = tmpDiv
    .querySelector(".social-link-xyz")
    .getAttribute("payload");
  const p = JSON.parse(payload);
  const photos = JSON.parse(p.data);

  {
    const images = photos.map((slide) => ({
      original: slide,
      thumbnail: null,
    }));

    return (
      <div className="relative my-2 w-full">
        {isResponsiveMode() && fullscreen && (
          <button
            onClick={() => imageGalleryRef.current.toggleFullScreen()}
            className={
              "fixed z-999999 bg-yellow-500  top-0 left-5 right-5 p-1 w-4/12 mx-auto rounded-lg mt-5"
            }
          >
            Exit
          </button>
        )}
        {images.length && (
          <ImageGallery
            items={images}
            showThumbnails={false}
            showBullets={true}
            useBrowserFullscreen={isResponsiveMode() ? false : true}
            ref={imageGalleryRef}
            fullscreen={fullscreen}
            onScreenChange={(screen) => setFullScreen(screen)}
            onClick={(e) => {
              if (!fullscreen) {
                imageGalleryRef.current.toggleFullScreen();
              }
            }}
          />
        )}
      </div>
    );
  }
};

{
  /* return <div className="relative my-2 w-full">{p?.name}</div>; */
}

const RenderAdvertBox = ({ data, renderAdvertTemplate, pageId, page }) => {
  const [refInViewPort, entryInViewPort] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: "0px",
  });

  const [hasRecordedImps, setHasRecordedImps] = useState(false);

  const randomPick = Math.floor(Math.random() * 16);
  const randomPick2 = Math.floor(Math.random() * 16);
  const imageUrl = globalAds[randomPick].image;
  const imageLink = globalAds[randomPick].link;
  const imageUrl2 = globalAds[randomPick2].image;
  const imageLink2 = globalAds[randomPick2].link;

  const recordAdImpressions = (res) => {
    const url = API_BASE + "/api/advert/record/impressions";

    axios
      .post(url, {
        book_id: pageId,
      })
      .then((response) => {
        if (response.status == 200) {
          setHasRecordedImps(true);
        }
      })
      .catch((e) => {});
  };

  const recordAdClicks = (link) => {
    const url = API_BASE + "/api/advert/record/clicks";

    axios
      .post(url, {
        book_id: pageId,
      })
      .then((response) => {
        if (response.status == 200) {
          window.location.href = link;
        }
      })
      .catch((e) => {});
  };
  const html = data; //Your html string
  const tmpDiv = document.createElement("div");
  tmpDiv.innerHTML = html;
  const adCode = tmpDiv.querySelector(".advert-block").getAttribute("adCode");
  const systemAdCode = tmpDiv
    .querySelector(".advert-block")
    .getAttribute("systemAdCode");

  return getLocation().hostname == "litzapp.com" &&
    page.owner &&
    page.owner?.advertrequest.status == "approved" ? (
    renderAdvertTemplate()
  ) : (
    <>
      <div className="flex justify-center w-full" ref={refInViewPort}>
        <p className="text-black text-2xl">
          {entryInViewPort?.isIntersecting && !hasRecordedImps
            ? recordAdImpressions()
            : ""}
        </p>{" "}
        {adCode && globalAds.length && (
          <img
            className="cursor-pointer"
            src={imageUrl}
            onClick={() => {
              recordAdClicks(imageLink);
            }}
          />
        )}
      </div>
      {/* mm */}
      <div className="flex justify-center w-full my-2">
        {systemAdCode && globalAds.length && (
          <img
            className="cursor-pointer"
            src={imageUrl2}
            onClick={() => (window.location.href = imageLink2)}
          />
        )}
      </div>
      <p className="mt-0"></p>
    </>
  );
};
