import { isPublisher } from "../../../../Utils/helpers";
export default function PubSuppApplicationResumeBar(props) {
  const { location } = props;
  const isApplicationPage = () => {
    return location.pathname.includes("publisher/supplier/onboarding/");
  };

  const showResumeApplicationButton = () => {
    return (
      localStorage.getItem("resume_supplier_application") !== null &&
      isPublisher() &&
      !isApplicationPage()
    );
  };

  const getSupplierActionText = () => {
    if (localStorage.getItem("resume_supplier_application") == "redo")
      return "Redo Application";
    if (localStorage.getItem("resume_supplier_application") == "resume")
      return "Resume Application";
  };

  //old link to monetization options window.location.href = "/user/activation/options
  return showResumeApplicationButton() ? (
    <div className="w-full flex justify-center self-center">
      <button
        onClick={() =>
          (window.location.href = "/publisher/supplier/onboarding/start")
        }
        class="text-base font-medium text-black  mx-2 my-bg-yellow-500  rounded-lg p-2 w-full text-center"
      >
        <span>{getSupplierActionText()}</span>
      </button>
    </div>
  ) : (
    ""
  );
}
