import { IdentityOptions } from "./identity-options-list";
import { useState, useEffect } from "react";
import authRequestService from "../../../../services/HttpRequests/authenticated-request-services";
import { API_BASE, authUserData } from "../../../../Utils/helpers";
import BasicButton from "../../../../components/buttons/basic-button";
import { useRef } from "react";
export default function SupplierIDProfileVerification(props) {
  const {
    stage,
    regType,
    profileOption,
    handlePrevStage,
    handleNextStage,
    handleProfileOptionChange,
  } = props;

  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [savingStage, setSavingStage] = useState(false);

  const inputRef = useRef(null);

  const saveStage = () => {
    const url = API_BASE + "/publisher/supplier/stages/save";
    setSavingStage(true);
    authRequestService()
      .post(url, {
        identity: profileOption,
        stage: stage,
        type: regType,
        uuid: authUserData().uuid,
      })
      .then((res) => {
        handleNextStage();
      })
      .catch((err) => setSavingStage(false));
  };

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    setQuery(() => "");
    handleProfileOptionChange(option["name"]);
    setIsOpen((isOpen) => !isOpen);
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (profileOption) return profileOption;

    return "";
  };

  const filter = (options) => {
    return options.filter(
      (option) => option["name"].toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  };

  return (
    <div className="my-identity dropdown">
      <div className="control">
        <div className="selected-value">
          <input
            autocomplete="off"
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
              handleProfileOptionChange(null);
            }}
            onClick={toggle}
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
      </div>

      <div className={`options ${isOpen ? "open" : ""}`}>
        {filter(IdentityOptions).map((option, index) => {
          return (
            <div
              onClick={() => selectOption(option)}
              className={`option ${
                option["name"] === profileOption ? "selected" : ""
              }`}
              key={`${"id"}-${index}`}
            >
              {option["name"]}
            </div>
          );
        })}
      </div>

      <div className="w-full border flex flex justify-center items-center gap-1 my-1">
        <BasicButton
          handleClick={() => handlePrevStage()}
          title={"Back"}
          disabled={savingStage}
          classes={"bg-black w-full"}
        />
        <BasicButton
          handleClick={() => saveStage()}
          title={savingStage ? "Processing.." : "Next"}
          disabled={savingStage || !profileOption}
          classes={"bg-black w-full"}
        />
      </div>
    </div>
  );
}
