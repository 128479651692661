export const ads = [
  [
    {
      title: "Millennia Atlantic University (MAU) 0 1",
      img: "/categories/books.jpg",
      body: "Testing ads..",
    },
    {
      title: "Millennia Atlantic University (MAU) 0 2",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
    {
      title: "Sugar East Hotel Southwest 8th Street 0 3",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
    {
      title: "Millennia Atlantic University (MAU) 0 4",
      img: "/categories/books.jpg",
      body: "Testing ads..",
    },
    {
      title: "Millennia Atlantic University (MAU) 0 5",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
    {
      title: "Sugar East Hotel Southwest 8th Street0 6",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
  ],
  [
    {
      title: "Millennia Atlantic University (MAU) 1 1",
      img: "/categories/books.jpg",
      body: "Testing ads..",
    },
    {
      title: "Millennia Atlantic University (MAU) 1 2",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
    {
      title: "Sugar East Hotel Southwest 8th Street 1 3",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
    {
      title: "Millennia Atlantic University (MAU) 1 4",
      img: "/categories/books.jpg",
      body: "Testing ads..",
    },
    {
      title: "Millennia Atlantic University (MAU) 1 5",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
    {
      title: "Sugar East Hotel Southwest 8th Street 1 6",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
  ],
  [
    {
      title: "Millennia Atlantic University (MAU) 2 1",
      img: "/categories/books.jpg",
      body: "Testing ads..",
    },
    {
      title: "Millennia Atlantic University (MAU) 2 2",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
    {
      title: "Sugar East Hotel Southwest 8th Street 2 3",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
    {
      title: "Millennia Atlantic University (MAU) 2 4",
      img: "/categories/books.jpg",
      body: "Testing ads..",
    },
    {
      title: "Millennia Atlantic University (MAU) 2 5",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
    {
      title: "Sugar East Hotel Southwest 8th Street 2 6",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
  ],
  [
    {
      title: "Millennia Atlantic University (MAU) 3 1",
      img: "/categories/books.jpg",
      body: "Testing ads..",
    },
    {
      title: "Millennia Atlantic University (MAU) 3 2",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
    {
      title: "Sugar East Hotel Southwest 8th Street 3 3",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
    {
      title: "Millennia Atlantic University (MAU) 3 4",
      img: "/categories/books.jpg",
      body: "Testing ads..",
    },
    {
      title: "Millennia Atlantic University (MAU)3 5",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
    {
      title: "Sugar East Hotel Southwest 8th Street3 6",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
  ],
  [
    {
      title: "Millennia Atlantic University (MAU) 4 1",
      img: "/categories/books.jpg",
      body: "Testing ads..",
    },
    {
      title: "Millennia Atlantic University (MAU) 4 2",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
    {
      title: "Sugar East Hotel Southwest 8th Street 4 3",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
    {
      title: "Millennia Atlantic University (MAU) 4 4",
      img: "/categories/books.jpg",
      body: "Testing ads..",
    },
    {
      title: "Millennia Atlantic University (MAU) 4 5",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
    {
      title: "Sugar East Hotel Southwest 8th Street 4 6",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
  ],
  [
    {
      title: "Millennia Atlantic University (MAU) 5 1",
      img: "/categories/books.jpg",
      body: "Testing ads..",
    },
    {
      title: "Millennia Atlantic University (MAU) 5 2",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
    {
      title: "Sugar East Hotel Southwest 8th Street 5 3",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
    {
      title: "Millennia Atlantic University (MAU) 5 4",
      img: "/categories/books.jpg",
      body: "Testing ads..",
    },
    {
      title: "Millennia Atlantic University (MAU) 5 5",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
    {
      title: "Sugar East Hotel Southwest 8th Street 5 6",
      img: "/categories/photo_album.jpg",
      body: "Testing ads..",
    },
  ],
];
