import axios from "axios";
import { useEffect, useState } from "react";
import BasicButton from "../../../../components/buttons/basic-button";
import {
  isSales,
  isAdmin,
  API_BASE,
  isResponsiveMode,
} from "../../../../Utils/helpers";
import useDebounce from "../../../../components/debounce/debounce-helper";
import TextField from "../../../../components/inputs/text-input";
import Pagination from "../../../../components/pagination";
import LoadingProgress from "../../../publisher/rich-page/components/loading-progress";
import authRequestService from "../../../../services/HttpRequests/authenticated-request-services";
import authPaginatedRequestService from "../../../../services/HttpRequests/authenticated-paginated-req-service";
import { useSendMailStore } from "../../../../stores/send-mail-store";
import MobileTable from "./components/mobile-table";
import DesktopTable from "./components/desktop-table";
import LinkModal from "./components/link-modal";
import FilterBar from "./components/filter";
import { toast } from "react-toastify";
export default function SalesSuppliers() {
  const [processing, setProcessing] = useState(false);
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const mailStore = useSendMailStore((state) => state);

  const openLinkModal = () => {
    setLinkModalOpen(true);
  };
  const closeLinkModal = () => {
    setLinkModalOpen(false);
  };

  const getSuppliers = () => {
    mailStore.setData([]);
    const searchText = mailStore.query.searchText || "*";
    const status = mailStore.query.searchText || "*";
    const url = API_BASE + "/sales/suppliers/list/" + searchText + "/" + status;

    setProcessing(true);
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          mailStore.setData(response.data.suppliers);
        }
        setProcessing(false);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const doPagination = (page) => {
    if (mailStore.data.first_page_url) {
      mailStore.setData([]);
      setCurrentPage(page);

      setProcessing(true);
      authPaginatedRequestService()
        .get(mailStore.data.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          mailStore.setData(res.data.suppliers);
          setProcessing(false);
        })
        .catch((err) => setProcessing(false));
    }
  };

  const handleSearchTextChange = (e) => {
    mailStore.setQuery("searchText", e);
  };

  const handleStatusOptionChange = (e) => {
    mailStore.setQuery("status", e.target.value);
  };

  useDebounce(
    () => {
      if (mailStore.refresh) {
        getSuppliers();
      }
      mailStore.setRefresh(true);
    },
    [mailStore.query],
    800
  );

  useEffect(() => {
    if (!isSales()) {
      window.location.href = "/";
    }
  }, []);

  return (
    <>
      <div className="main w-full">
        <div className="bg-white m-2 p-2 flex flex-col md:flex-row justify-between shadow px-4">
          <div>
            <h1 className="text-2xl text-center m-2 bold">Suppliers</h1>
          </div>
          <div className="flex gap-2">
            <div>
              <BasicButton
                title={"Generate Application Link"}
                handleClick={openLinkModal}
              />
            </div>

            <div>
              <a href="/sales/supplier/create">
                <BasicButton
                  title={"Create Supplier Account"}
                  handleClick={null}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="flex justify-center my-4 overflow-hidden">
          <div class="flex flex-col items-center p-10 w-full">
            <p className="w-full text-center my-4"></p>
            <FilterBar
              handleSearchTextChange={handleSearchTextChange}
              serchQValue={mailStore.query.searchText}
              handleStatusOptionChange={handleStatusOptionChange}
              status={mailStore.query.status}
              model={"sales-suppliers"}
            />
            <div className="w-full flex justify-center">
              {mailStore.data.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            {isResponsiveMode() ? <MobileTable /> : <DesktopTable />}
            <LoadingProgress loading={processing} />
            <div className="w-full flex justify-center">
              {mailStore.data.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            <div className="flex w-full gap-4"></div>
          </div>
        </div>
      </div>
      <LinkModal modalOpen={linkModalOpen} hideModal={closeLinkModal} />
    </>
  );
}
