import EditorTooltip from "../../tooltip/editor-tooltip";
import Checkbox from "../../../../../../../components/inputs/checkbox";
export default function MakeInactive(props) {
  const { productMeta, handleMetaChange } = props;
  return (
    <div className="inline-flex gap-1 w-full justify-center mt-10 relative">
      <div>
        <Checkbox
          handleChange={(e) => handleMetaChange(e, "make_inactive", "checkbox")}
          checked={productMeta.make_inactive}
        />
      </div>
      <span>Make Inactive</span>
      <EditorTooltip
        body={`When this checkbox is activated, the purchase <br/> button will show as inactive on the page viewed by the customer, irrespective of the item's actual availability. While visible in a somewhat dimmed state, it will be unclickable.`}
        top={"-200px"}
        right={"15px"}
      />
    </div>
  );
}
