import { Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import BasicButton from "../components/buttons/basic-button";
import { getClientBaseURL, getLocation, STRIPE_PK } from "../Utils/helpers";
import { toast } from "react-toastify";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PK);

export default function StripeDatathresholdPayElement({ dataThreshold }) {
  const stripe = useStripe();
  const elements = useElements();

  const createReturnURL = () => {
    return getLocation().hostname == "litzapp.com"
      ? getClientBaseURL() + `/publisher/storage/redirect/back`
      : getClientBaseURL() + `:3000/publisher/storage/redirect/back`;
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: createReturnURL(),
      },
    });

    if (result.success) {
      console.log("Payment result", result);
    }
    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      toast("Something went wrong", { type: "error" });
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <div className="flex justify-center p-4">
      <div className="w-10/12 md:w-full">
        <form onSubmit={handleSubmit}>
          <PaymentElement />
          <div className="flex justify-center mt-6">
            <BasicButton title={"Submit"} />
          </div>
        </form>
      </div>
    </div>
  );
}
