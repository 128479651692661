import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { STRIPE_PK } from "../Utils/helpers";
import StripeCPPayElement from "./cp-product-pay-element";
import { Elements, useStripe, useElements } from "@stripe/react-stripe-js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PK);

export default function StripeCPProductWrapper({ state }) {
  //   const options = {
  //     // passing the client secret obtained from the server
  //     clientSecret: state.secret,
  //   };

  const options = {
    clientSecret: state.client_secret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeCPPayElement state={state} />
    </Elements>
  );
}
