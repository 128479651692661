import { useSupplierApplicationsStore } from "../../../../../../../stores/supplier-applications-store";
import BasicButton from "../../../../../../../components/buttons/basic-button";
import { useState } from "react";
import AdminSupplierPhotoViewerModal from "./photo-viewer";
import {
  BASE_URL,
  BASE_URL_WITH_LOCAL,
} from "../../../../../../../Utils/helpers";

export default function MobileTable(props) {
  const {
    openCommentModal,
    preProcessApplication,
    processApplication,
    processing,
  } = props;
  const mailStore = useSupplierApplicationsStore((state) => state);

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const openImageModal = (d) => {
    const link = BASE_URL + "/storage" + d.photo.split("public")[1];
    setImageUrl(link);
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
  };

  return (
    <>
      <div class="w-full flex flex-col justify-center my-4 text-center">
        <div
          className="overflow-x-scroll inline"
          style={{
            width: document.documentElement.clientWidth - 90 + "px",
            margin: "0 auto",
          }}
        >
          {mailStore.application?.id && (
            <div className="flex">
              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold md:hidden">Date</h3>
                <p>
                  {mailStore.application.refactoredCreatedAtTime
                    ? new Date(
                        mailStore.application.refactoredCreatedAtTime.replace(
                          /-/g,
                          "/"
                        )
                      ).getDate() +
                      "-" +
                      (new Date(
                        mailStore.application.refactoredCreatedAtTime.replace(
                          /-/g,
                          "/"
                        )
                      ).getMonth() +
                        1) +
                      "-" +
                      new Date(
                        mailStore.application.refactoredCreatedAtTime.replace(
                          /-/g,
                          "/"
                        )
                      ).getFullYear()
                    : "N/A"}
                </p>
              </div>

              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold md:hidden">Face Photo</h3>
                <BasicButton
                  title={"View"}
                  classes={"my-bg-yellow-500 text-black"}
                  handleClick={() => openImageModal(mailStore?.application)}
                />
              </div>
              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold md:hidden">Business Location</h3>
                {mailStore?.application?.updated_address || "Individual"}
              </div>
              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold md:hidden">Verification Code</h3>
                {
                  mailStore?.application?.user
                    ?.latest_supplier_verification_code?.code
                }
              </div>
              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold md:hidden">Video</h3>
                <BasicButton
                  title={"View"}
                  classes={"my-bg-yellow-500 text-black"}
                  handleClick={() => null}
                />
              </div>

              <div class="border  px-4 py-2 font-medium">
                <h3 className="font-bold md:hidden">Contract</h3>
                <div
                  className="flex flex-col items-center"
                  style={{ width: "200px" }}
                >
                  <select
                    value={mailStore.selectedApplicantStatus}
                    onChange={(e) =>
                      mailStore.setSelectedApplicantStatus(e.target.value)
                    }
                    required
                    class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer text-center"
                    style={{ width: "200px" }}
                  >
                    <option value="" default disabled selected>
                      Select
                    </option>
                    <option value="approve">Approve</option>
                    <option value="reject">Reject</option>
                  </select>
                </div>
              </div>
              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold md:hidden">Process</h3>

                <BasicButton
                  disabled={
                    !mailStore.application?.user?.uuid ||
                    processing ||
                    !mailStore.selectedApplicantStatus
                  }
                  title={processing ? "Processing..." : "Process"}
                  classes={"my-bg-yellow-500 text-black"}
                  handleClick={() =>
                    mailStore.selectedApplicantStatus == "reject"
                      ? preProcessApplication()
                      : processApplication()
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <AdminSupplierPhotoViewerModal
        modalOpen={imageModalOpen}
        closeModal={closeImageModal}
        imageUrl={imageUrl}
      />
    </>
  );
}
