import moment from "moment";
import BookingCalendar from "../../../../../components/calendar";
import BasicButton from "../../../../../components/buttons/basic-button";
import BuyerContractConsent from "../contract-consent";
import CheckoutInfo from "./components/checkout-info";
import ReplyInfo from "./components/reply-info";
const SubSummary = (props) => {
  const { showCalendar } = props;
  const { productReplyMeta } = props;
  const { canRenderSubSummary } = props;
  const { handleDateChangeFromSubSummary } = props;

  /*other props*/
  const { buyerConsentData } = props;
  const { checkoutInfoData } = props;
  const { replyInfoData } = props;
  return (
    canRenderSubSummary() && (
      <div className="flex flex-col my-2 w-full">
        <div className="w-full flex flex-col items-center justify-center">
          <p>
            {" "}
            <span className="font-bold"> Your start date is </span>
            <span className="font-bold text-2xl text-green-500">
              {productReplyMeta.start_date
                ? moment.utc(productReplyMeta.start_date).format("DD-MM-YYYY")
                : ""}
            </span>
          </p>
          <div className="flex">
            <BasicButton
              disabled={false}
              title={"Change Date"}
              classes="px-8 w-full"
              handleClick={() => handleDateChangeFromSubSummary()}
            />
          </div>
        </div>

        {!showCalendar ? (
          <div>
            <ReplyInfo {...replyInfoData} />
            <div className="w-full flex">
              <BuyerContractConsent {...buyerConsentData} />
            </div>
            <CheckoutInfo {...checkoutInfoData} />
          </div>
        ) : (
          ""
        )}
      </div>
    )
  );
};

export default SubSummary;
