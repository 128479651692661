import { useSupplierApplicationsStore } from "../../../../../stores/supplier-applications-store";
import { isResponsiveMode } from "../../../../../Utils/helpers";
import Checkbox from "../../../../../components/inputs/checkbox";
import BasicButton from "../../../../../components/buttons/basic-button";
import { useNavigate } from "react-router-dom";

export default function DesktopTable(props) {
  const mailStore = useSupplierApplicationsStore((state) => state);
  const { openCommentModal } = props;

  const navigate = useNavigate();

  return (
    <div className="overflow-x-scroll md:h-screen w-full">
      {" "}
      <table class="table-auto w-full my-4">
        <thead>
          <tr className={window.innerWidth < 720 ? "hidden" : ""}>
            <th class="px-4 py-2 text-gray-600">Date Submitted</th>
            <th class="px-4 py-2 text-gray-600">User Handle</th>
            <th class="px-4 py-2 text-gray-600">Status</th>
            <th class="px-4 py-2 text-gray-600">Application</th>
          </tr>
        </thead>
        <tbody className="w-full">
          {mailStore.data.data &&
            mailStore.data.data.map((c) => {
              return (
                <tr
                  className={
                    window.innerWidth < 820 ? "flex flex-col items-center " : ""
                  }
                >
                  <td class="border  px-4 py-2  font-medium text-center">
                    <h3 className="font-bold md:hidden">Date</h3>
                    <p>
                      {c.refactoredCreatedAtTime
                        ? new Date(
                            c.refactoredCreatedAtTime.replace(/-/g, "/")
                          ).getDate() +
                          "-" +
                          (new Date(
                            c.refactoredCreatedAtTime.replace(/-/g, "/")
                          ).getMonth() +
                            1) +
                          "-" +
                          new Date(
                            c.refactoredCreatedAtTime.replace(/-/g, "/")
                          ).getFullYear()
                        : "N/A"}
                    </p>
                  </td>
                  <td class="border  px-4 py-2  font-medium text-center">
                    <div className="flex flex-col gap-2 text-left">
                      <div>
                        <p className="font-bold">Name</p>
                        {c.contract_owner_data?.name}
                      </div>
                      <div>
                        <p className="font-bold">Email</p>
                        {c.contract_owner_data?.email}
                      </div>
                      <div>
                        <p className="font-bold">User Handle</p>
                        {c.contract_owner_data?.user_handle}
                      </div>
                      <div>
                        <p className="font-bold">Username</p>
                        {c.contract_owner_data?.username}
                      </div>
                    </div>
                  </td>

                  <td class="border  px-4 py-2  font-medium text-center">
                    <h3 className="font-bold md:hidden">Status</h3>
                    {c.status}
                  </td>

                  <td class="border  px-4 py-2  font-medium text-center">
                    <h3 className="font-bold md:hidden">Application</h3>
                    <BasicButton
                      disabled={!c?.id}
                      title={"View"}
                      classes={"my-bg-yellow-500 text-black"}
                      handleClick={() =>
                        navigate(
                          `/admin/supplier/product/contract/${c.uuid}/user/${c?.contract_owner_data?.uuid}`
                        )
                      }
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
