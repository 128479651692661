import { toast } from "react-toastify";
import StripeCheckout from "react-stripe-checkout";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import BasicButton from "../components/buttons/basic-button";
import { authToken, authUserData, API_BASE, STRIPE_PK } from "../Utils/helpers";
import axios from "axios";

function StripeEmailDispatchPageForm({
  data,
  action,
  classes,
  title,
  disabled,
}) {
  //   alert(JSON.stringify(data));
  const product = {
    name: data.name,
    price: data.price,
    description: data.description,
  };

  const [processing, setProcessing] = useState(false);

  const headers = {
    accept: "application/json",
    Authorization: "bearer " + authToken(),
  };

  async function handleToken(token, addresses) {
    try {
      setProcessing(true);
      const response = await axios.request({
        method: "post",
        headers: headers,
        url: API_BASE + "/api/stripe/email-dispatch/charge",
        data: {
          token,
          product,
          data,
          user_uuid: authUserData().uuid,
        },
      });
      // console.log(response.status);
      if (response.status === 200) {
        setProcessing(false);
        toast("Payment Successful!", { type: "success" });
        setTimeout(() => {
          action && action();
        }, 3000);
      } else {
        setProcessing(false);
      }
    } catch (error) {
      setProcessing(false);
      toast("Payment error, please try again later!", { type: "error" });
    }
  }

  useEffect(() => {}, []);

  return (
    <StripeCheckout
      class="center"
      stripeKey={STRIPE_PK}
      token={handleToken}
      amount={`$${product.price * 100}`}
      name={data.name}
      disabled={processing || disabled}
    >
      <BasicButton
        title={`${
          !processing
            ? title
              ? title
              : "PURCHASE EMAIL DISPATCHES"
            : "Processing.."
        } ${data.price && !processing ? "$" + data.price : ""}`}
        disabled={processing || disabled}
        classes={classes ? classes : ""}
      />
    </StripeCheckout>
  );
}

export default StripeEmailDispatchPageForm;
