import moment from "moment";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

function CalendarDays(props) {
  const [clickedDateIndex, setClickedDateIndex] = useState("");
  const firstDayOfMonth = new Date(
    props.day.getFullYear(),
    props.day.getMonth(),
    1
  );
  // console.log("mth", props.day.getMonth());
  const weekdayOfFirstDay = firstDayOfMonth.getDay();
  let currentDays = [];

  for (let day = 0; day < 42; day++) {
    if (day === 0 && weekdayOfFirstDay === 0) {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 7);
    } else if (day === 0) {
      firstDayOfMonth.setDate(
        firstDayOfMonth.getDate() + (day - weekdayOfFirstDay)
      );
    } else {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
    }

    let calendarDay = {
      currentMonth: firstDayOfMonth.getMonth() === props.day.getMonth(),
      date: new Date(firstDayOfMonth),
      month: firstDayOfMonth.getMonth(),
      number: firstDayOfMonth.getDate(),
      selected: firstDayOfMonth.toDateString() === props.day.toDateString(),
      year: firstDayOfMonth.getFullYear(),
    };

    currentDays.push(calendarDay);
  }

  const correcDateFormat = (d) => {
    const day = d.getUTCDate();
    const month = d.getUTCMonth() + 1; // Months are zero-based, so add 1
    const year = d.getUTCFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    //console.log(formattedDate);
    return formattedDate;
  };

  const addDateColorClasses = (date) => {
    // const dateStamp = date.setHours(0, 0, 0, 0);
    const dateToCheck = moment(date.date).format("YYYY-MM-DD");
    const res = props.parentProps.bookings.filter(
      (d) => d.date_only === dateToCheck
    );

    if (res.length) {
      if (
        props.parentProps.meta.block_weekend_start &&
        (moment(date.date).format("dddd") == "Saturday" ||
          moment(date.date).format("dddd") == "Sunday")
      ) {
        // console.log(moment(date.date).format("dddd"));

        return "";
      }
      return res[0].color.toString() + " cursor-pointer ";
    }
    return "";
  };

  const getSubCountAndColor = (date) => {
    // const dateStamp = date.setHours(0, 0, 0, 0);
    const dateToCheck = moment(date.date).format("YYYY-MM-DD");
    const res = props.parentProps.bookings.filter(
      (d) => d.date_only === dateToCheck
    );

    if (res.length) {
      return res[0].sub_count;
    }
    return "";
  };

  const getAvailabilityQAndColor = (date) => {
    const dateToCheck = moment(date.date).format("YYYY-MM-DD");
    const res = props.parentProps.bookings.filter(
      (d) => d.date_only === dateToCheck
    );

    if (res.length) {
      return res[0].available_quantity;
    }
    return "";
  };

  const convertToUTC = (date) => {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  };

  const showAvailabilityTable = (date, index) => {
    const dateToCheck = moment(date.date).format("YYYY-MM-DD");
    const res = props.parentProps.bookings.filter(
      (d) => d.date_only == dateToCheck
    );

    // if (res.length) {
    //   if (res[0].code == "GREY") {
    //     return toast(res[0].message, {
    //       type: "error",
    //     });
    //   } else {
    //     window.location.href = `/publisher/product/meta/${
    //       props.parentProps.meta.block_meta_id
    //     }/availability/${correcDateFormat(convertToUTC(date.date))}`;
    //   }
    // }
    window.location.href = `/publisher/product/meta/${
      props.parentProps.meta.block_meta_id
    }/availability/${correcDateFormat(convertToUTC(date.date))}`;
  };

  useEffect(() => {}, [props?.parentProps?.state]);
  useEffect(() => {}, [props.day]);

  const startDate = props?.parentProps?.state?.productReplyMeta?.start_date;

  return (
    <div className="grid grid-cols-7 bg-white w-full">
      {currentDays.map((day, index) => {
        const subCount = getSubCountAndColor(day);
        return (
          <div
            key={index}
            className={
              "p-1 calendar-day cursor-pointer flex flex-col items-center justify-center"
            }
            onClick={() => {
              showAvailabilityTable(day, index);
            }}
          >
            <div className={`border-b-2`}>
              <div
                className={`w-12 md:w-4 h-12 md:h-4 px-3.5 border-b-2 ${
                  startDate
                    ? index == clickedDateIndex
                      ? "border-green-500 border-2"
                      : ""
                    : ""
                }  flex justify-center items-center`}
              >
                <p
                  className={
                    true
                      ? ` current bold text-gray-300 font-bold ${addDateColorClasses(
                          day
                        )}`
                      : "text-gray-100"
                  }
                >
                  {day.number}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-1 md:gap-4">
              <div
                className={
                  subCount > 0
                    ? "text-red-500 my-text-red-500 font-bold"
                    : "text-green-500 my-text-green-500 font-bold"
                }
              >
                {subCount}
              </div>
              <div>|</div>
              <div
                className={
                  true
                    ? ` current bold text-gray-300 font-bold ${addDateColorClasses(
                        day
                      )}`
                    : "text-gray-100"
                }
              >
                {getAvailabilityQAndColor(day)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default CalendarDays;
