import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import TextArea from "../../../components/inputs/text-area";
import { API_BASE, isAdmin } from "../../../Utils/helpers";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

export default function AdminDashboardCreateTier() {
  const [tierName, setTierName] = useState("");
  const [tierPrice, setTierPrice] = useState("");
  const [tierThreshold, setTierThreshold] = useState("");
  const [tierMessageOne, setTierMessageOne] = useState("");
  const [tierMessageTwo, setTierMessageTwo] = useState("");
  const [tierModalOpen, setTierModalOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [setting, setSetting] = useState({});
  const { userId } = useParams();

  const naviagte = useNavigate();

  const handleTierNameChange = (e) => {
    setTierName(e);
  };

  const handleTierPriceChange = (e) => {
    setTierPrice(e);
  };

  const handleTierThresholdChange = (e) => {
    setTierThreshold(e);
  };

  const handleTierMessageOneChange = (e) => {
    setTierMessageOne(e);
  };

  const handleTierMessageTwoChange = (e) => {
    setTierMessageTwo(e);
  };

  const createTier = () => {
    if (!checkPremiumTierIsreaterThanFree()) {
      toast(
        "Current Threshold must be greater than or equal to Free Threshold!",
        {
          type: "error",
        }
      );
      return false;
    }
    setProcessing(true);
    let url = API_BASE + "/api/storage/create";
    let formData = new FormData();
    formData.append("name", tierName);
    formData.append("unit_amount", tierPrice);
    formData.append("threshold", tierThreshold);
    formData.append("message_one", tierMessageOne);
    formData.append("message_two", tierMessageTwo);
    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200 || response.status == 201) {
          naviagte("/admin/storage/" + userId);
        }
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.status !== 422) {
          toast("Something went wrong!", { type: "error" });
        }
        if (error.response.status == 422) {
          if ("negative_remnant" in error.response.data) {
            let data = error.response.data["negative_remnant"];
            toast(`Negative remnant payment detected against ${data.name}`, {
              type: "error",
              position: "top-right",
              theme: "dark",
            });
          }
          if (error.response.data.includes("duplicate_name")) {
            toast("Tier name already exists!", {
              type: "error",
            });
          }

          if (error.response.data.includes("duplicate_threshold")) {
            toast("Tier threshold value already exists!", {
              type: "error",
            });
          }

          if (error.response.data.includes("passed")) {
            toast("Passed!", {
              type: "error",
            });
          }
        }
        //toast("Something went wrong!", { type: "error" });
      });
  };

  const checkPremiumTierIsreaterThanFree = () => {
    if (!setting.free_storage_tier) {
      return false;
    }
    return tierThreshold >= Number(setting.free_storage_tier);
  };

  const getSystemSetting = () => {
    const url = API_BASE + "/api/system/settings";
    setProcessing(true);

    axios
      .get(url)
      .then((response) => {
        setProcessing(false);
        setSetting(response.data);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
    getSystemSetting();
  }, []);

  return (
    <>
      <div className="w-full flex flex-col items-center justify-center">
        <div className="bg-white m-2 p-2 flex justify-between shadow w-full">
          <div>
            <h1 className="text-2xl text-center m-2 font-bold">
              Create Storage Tier
            </h1>
          </div>
          <div className="flex gap-2">
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>{" "}
        <div className="my-4 text-center flex flex-col gap-4 justify-between w-6/12">
          <div className="text-left w-full flex flex-col items-start">
            <label for="html">Tier Name</label>
            <TextField
              classes={"p-6 my-2 w-full"}
              placeholder={"Tier Name.."}
              type="text"
              handleChange={handleTierNameChange}
            />
          </div>
          <div className="text-left my-1 w-full flex flex-col items-start">
            <div className="flex justify-between w-full">
              <label for="javascript">Threshold </label>
              <span>
                Free Tier (
                {setting.free_storage_tier ? setting.free_storage_tier : ""})
              </span>
            </div>
            <TextField
              classes={"p-6 my-2 w-full"}
              placeholder={"Threshold.."}
              type="number"
              handleChange={handleTierThresholdChange}
            />
          </div>

          <div className="text-left my-1 w-full flex flex-col items-start">
            <label for="javascript">Price</label>
            <TextField
              classes={"p-6 my-2 w-full"}
              placeholder={"Price.."}
              type="number"
              handleChange={handleTierPriceChange}
            />
          </div>
          <div className="text-left my-1 w-full flex flex-col items-start">
            <label for="javascript">
              Threshold Prompt Message (File Larger than Threshold)
            </label>
            <TextArea
              classes={"p-6 my-2 w-full"}
              placeholder={"Enter Message..."}
              type="number"
              handleChange={handleTierMessageOneChange}
            />
          </div>
          <div className="text-left my-1 w-full flex flex-col items-start">
            <label for="javascript">
              Threshold Prompt Message ( Extra File will break Data Threshold)
            </label>
            <TextArea
              classes={"p-6 my-2 w-full"}
              placeholder={"Enter Message..."}
              type="number"
              handleChange={handleTierMessageTwoChange}
            />
          </div>
          <div className="my-1 w-full">
            <BasicButton
              disabled={
                !tierPrice ||
                !tierName ||
                !tierThreshold ||
                !tierMessageOne ||
                !tierMessageTwo
              }
              title={processing ? "Creating.." : "Create Tier"}
              classes={"p-6 mt-4 w-full"}
              handleClick={() => createTier()}
            />
          </div>
        </div>
      </div>
    </>
  );
}
