import { useState, useRef } from "react";
import { useParams } from "react";
import { toast } from "react-toastify";
export default function ImageUploadSingle({
  setCoverPhoto,
  setFileSelected,
  fileSelected,
  classes,
}) {
  const previewImageRef = useRef();
  //   const [fileSelected, setFielSelected] = useState("");

  const uploadCover = (e) => {
    let [file] = e.target.files;
    if (file) {
      if (
        file["type"] !== "image/png" &&
        file["type"] !== "image/jpeg" &&
        file["type"] !== "image/gif" &&
        file["type"] !== "image/webp"
      ) {
        toast("Invalid file type, please upload (JPEG, PNG, GIF or WEBP)", {
          type: "error",
        });
        return false;
      }
      setFileSelected(URL.createObjectURL(file));
      previewImageRef.src = URL.createObjectURL(file);
      setCoverPhoto(file);
      const image = getBase64(file);
      image.then((res) => {});
    }
  };

  const removeCover = () => {
    setFileSelected("");
    setCoverPhoto("");
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center gap-4 mt-1">
        <div className="">
          <div className="text-center">
            <div className="flex justify-center">
              {!fileSelected && (
                <div className="w-full p-2 w-full">
                  <div className="flex flex-col items-center justify-center">
                    <label for="files" class="btn">
                      Select Image
                    </label>
                    <input
                      type="file"
                      onChange={(e) => uploadCover(e)}
                      className="text-sm 
                        file:mr-2
                        file:py-2
                        file:px-1
                        file:rounded-full
                        file:border
                        file:border-gray
                        file:text-md
                        file:text-black
                        hover:file:cursor-pointer
                        z-45"
                    />
                    <p className="text-xs">Click to upload</p>
                    <p className="text-xs"> (jpeg, jpg, png)</p>
                    {/* <input type="file" onChange={(e) => uploadCover(e)} /> */}
                  </div>
                </div>
              )}

              {fileSelected && (
                <div className="w-48 bg-gray-50 p-2 relative flex justify-center">
                  <img
                    src={fileSelected}
                    className={`w-full ${classes ? classes : ""}`}
                  />
                  <div
                    className="bg-white rounded rounded-full bg-white p-0 -top-4 right-0 absolute px-1 cursor-pointer"
                    onClick={() => removeCover()}
                  >
                    <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
}
