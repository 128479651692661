import { useRef, useEffect, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const AddressVerification = (props) => {
  const { setLocation, location, verification } = props;
  const [savingStage, setSavingStage] = useState(false);
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: "ng" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      setLocation((prev) => ({
        ...prev,
        address: place.name,
      }));
      console.log(place.address_components);
    });
  }, []);

  const handleGoogleLocationChange = (val) => {
    console.log("google", val);
    setLocation((prev) => ({
      ...prev,
      address: val.label,
    }));
  };

  const handleLocationChange = (val) => {
    // setLocation((prev) => ({
    //   ...prev,
    //   address: val,
    // }));
  };

  return (
    <div className="my-identity dropdown mb-10">
      <div className="control">
        <div className="selected-value">
          <label class="text-gray-500 mb-2">
            Enter Business Location {location.address}
          </label>
          <GooglePlacesAutocomplete
            autocompletionRequest={{
              componentRestrictions: {
                //to set the specific country
              },
            }}
            selectProps={{
              onInputChange: (newVal) => handleLocationChange(newVal),
              onChange: handleGoogleLocationChange, //save the value gotten from google
              placeholder: location.address,
              onKeyDown: (newVal) => {
                setLocation((prev) => ({
                  ...prev,
                  address: "",
                }));
              },
              styles: {
                input: (provided) => ({
                  ...provided,
                  color: "#222222",
                }),
                option: (provided) => ({
                  ...provided,
                  color: "#222222",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "#222222",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: "#000000",
                }),
              },
            }}
            onLoadFailed={(error) => {
              console.log(error);
            }}
          />
          {/* <input ref={inputRef} value={location.address} /> */}
        </div>
      </div>
    </div>
  );
};
export default AddressVerification;
