import { useEffect, useState } from "react";
import { authUserData, isAuthUser } from "../../../Utils/helpers";

export default function DumbRatingBox({
  book,
  lastRatedBook,
  rateBook,
  currentRating,
}) {
  const [localCurrentRating, setLocalCurrentRating] = useState();
  const getRating = () => {
    let ratings = [];
    let localBook = lastRatedBook
      ? lastRatedBook?.uuid == book?.uuid
        ? lastRatedBook
        : book
      : book;
    if (isAuthUser()) {
      if (localBook.rating) {
        let rating = localBook.rating.filter(
          (r) => r.rater_id == authUserData().id
        );
        if (rating.length) {
          ratings.push(rating[0].one);
          ratings.push(rating[0].two);
          ratings.push(rating[0].three);
          ratings.push(rating[0].four);
          ratings.push(rating[0].five);

          let finalRating = ratings.filter((r) => r > 0);
          // console.log("final rating", finalRating);
          return finalRating[0];
        }

        return 0;
      }
    }

    return 0;
  };

  useEffect(() => {
    // setCurrentRating();
  }, []);

  return (
    <div className="flex justify-between gap-2 text-xs mt-1 z-99999">
      <span className="text-black">
        {/* {book.average_rating} */}
        {lastRatedBook && lastRatedBook.uuid == book.uuid
          ? Number(lastRatedBook.average_rating).toFixed(1)
          : Number(book.average_rating).toFixed(1)}{" "}
        avg
      </span>
      <i
        className={`fa fa-star cursor-pointer  ${
          book.rating && getRating() >= 1 ? "text-yellow-500" : ""
        }`}
      ></i>
      <i
        className={`fa fa-star cursor-pointer   ${
          book.rating && getRating() >= 2 ? "text-yellow-500" : ""
        }`}
      ></i>
      <i
        className={`fa fa-star cursor-pointer   ${
          book.rating && getRating() >= 3 ? "text-yellow-500" : ""
        }`}
      ></i>
      <i
        className={`fa fa-star cursor-pointer   ${
          book.rating && getRating() >= 4 ? "text-yellow-500" : ""
        }`}
      ></i>
      <i
        className={`fa fa-star cursor-pointer   ${
          book.rating && getRating() >= 5 ? "text-yellow-500" : ""
        }`}
      ></i>
      <span>
        (
        {/* {calculateTotalRating(
                      lastRatedBook && lastRatedBook.uuid == book.uuid
                        ? lastRatedBook
                        : book
                    )} */}
        {lastRatedBook && lastRatedBook.uuid == book.uuid
          ? lastRatedBook.rating.length
          : book.rating.length}
        )
      </span>
    </div>
  );
}
