import InsertImage from "./insert-image";
import TextField from "../../../../../../../../components/inputs/text-input";
export default function UploadFile(props) {
  const { handleImageUpload } = props;
  const { parentIndex } = props;
  const { accordions } = props;
  const { deleteMedia } = props;
  const { selectedImages } = props;
  const { mediaType } = props;
  const showUploader = () => {
    return true;
  };

  const media = accordions[parentIndex].media.filter((m) => m.type == "upload");
  return (
    <>
      {mediaType == "upload" && (
        <div className="flex flex-col items-center w-full">
          {" "}
          {showUploader() && (
            <div className="w-8/12 md:w-3/12 relative my-2 flex flex-col">
              {" "}
              <p className="text-red-700 text-xs">
                Supported Formats (GIF,PNG,JPEG)
              </p>
              <i class="fas fa-image text-3xl"></i>
              <InsertImage handleImageUpload={handleImageUpload} />
            </div>
          )}
          {selectedImages.length ? (
            <div className="w-full p-2 my-2 h-64 overflow-auto">
              <p className="text-center">
                {selectedImages.length} images uploaded
              </p>
              <div className="-m-1 flex flex-wrap md:-m-2 py-3">
                {selectedImages.map((slide, i) => (
                  <div class="flex w-1/3 flex-wrap relative">
                    <div class="w-full p-1 md:p-2">
                      <img
                        class="block h-full w-full rounded-lg object-cover object-center"
                        src={URL.createObjectURL(slide.file)}
                      />
                    </div>
                    <div className="bg-white rounded rounded-full bg-white p-0 -top-1 -right-1 absolute px-1 cursor-pointer">
                      <i
                        class="fa fa-times-circle text-red-500  z-50 text-1xl"
                        onClick={() => deleteMedia(i)}
                      ></i>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
}
