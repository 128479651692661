import { useState } from "react";
import { BASE_URL } from "../../../Utils/helpers";
import BasicButton from "../../../components/buttons/basic-button";

export default function SponsorDesktopSystemSubTable({ data, f }) {
  const { openCancelModal } = f;
  return (
    <>
      <div className="overflow-x-scroll md:h-screen w-full">
        <table class="table-auto w-full">
          <thead className="text-base">
            <tr className={window.innerWidth < 720 ? "hidden" : ""}>
              {/* <th class="px-4 py-2 text-gray-600">Date Created</th> */}

              <th class="px-4 py-2 text-gray-600">Description</th>
              <th class="px-4 py-2 text-gray-600">Price</th>
              <th class="px-4 py-2 text-gray-600">Start Date</th>
              <th class="px-4 py-2 text-gray-600">End Date</th>
              {/* <th class="px-4 py-2 text-gray-600">Edit</th> */}
              <th class="px-4 py-2 text-gray-600">Status</th>
            </tr>
          </thead>
          <tbody className="w-full">
            {data.data
              ? data.data.map((p, i) => {
                  return (
                    <tr
                      className={
                        window.innerWidth < 820
                          ? `flex flex-col items-center my-8 w-full bg-gray-50 p-4 `
                          : ""
                      }
                    >
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs">
                          Description
                        </h3>
                        {p.description}
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs"></h3>${" "}
                        {p.lateststripeprice.unit_amount}
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs"></h3>
                        {p.stripesubscription?.refactoredStartTime || "N/A"}
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs"></h3>
                        {p.stripesubscription?.refactoredEndTime || "N/A"}
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center text-left">
                        <h3 className="font-bold md:hidden text-xxs"></h3>
                        <BasicButton
                          disabled={p?.stripesubscription?.status !== "active"}
                          title={"Cancel"}
                          classes={"bg-red-500 text-white"}
                          handleClick={() =>
                            openCancelModal(p.stripesubscription)
                          }
                        />
                      </td>

                      <td class="border px-4 py-2  font-medium text-center md:text-center">
                        <h3 className="font-bold md:hidden text-xxs">Price</h3>
                        {p.stripesubscription?.status}
                      </td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
      </div>
    </>
  );
}
