import TextField from "../../../inputs/text-input";
import BasicButton from "../../../buttons/basic-button";
export default function ProductAvailibilitySearch(props) {
  const { openAvailabilityCalendar } = props;

  return (
    <div className="w-full flex justify-center my-6">
      <BasicButton
        classes={"my-bg-yellow-500 text-black w-8/12"}
        title={"Search Availability"}
        handleClick={() => openAvailabilityCalendar()}
      />
    </div>
  );
}
