import UnauthorizedMenuItems from "./center/unauthorized-bar";
import ReaderMenuItems from "./center/reader-bar";
import PublisherMenuItems from "./center/publisher-bar";
import AdminMenuItems from "./center/admin-bar";
export default function DesktopCenterBar(props) {
  const { showMobileMenu } = props;
  return (
    <div className="w-full flex justify-center w-full">
      <nav className="hidden md:flex md:flex-row gap-4 items-center">
        <a
          href="/"
          class="text-base font-medium text-gray-500 hover:text-gray-900 "
        >
          Home
        </a>
        <UnauthorizedMenuItems showMobileMenu={showMobileMenu} />
        <PublisherMenuItems showMobileMenu={showMobileMenu} />
        <ReaderMenuItems showMobileMenu={showMobileMenu} />
        <AdminMenuItems showMobileMenu={showMobileMenu} />
      </nav>
    </div>
  );
}
