import { useSendMailStore } from "../../../../../stores/send-mail-store";
import { generateItemLink } from "../../../../../Utils/helpers";
export default function AdditionEmailBody() {
  const getItem = () => {
    return mailStore.item
      ? mailStore.books.filter((b) => b.uuid == mailStore.item).map((b) => b)[0]
      : {};
  };

  const getRecipientNameType = (slug) => {
    if (slug == "first_name") return "First Name";
    if (slug == "last_name") return "Last Name";
    if (slug == "all") return "All";
    return "";
  };

  const mailStore = useSendMailStore((state) => state);

  return (
    mailStore.subject == "addition_notification" &&
    mailStore.user.id &&
    mailStore.item &&
    getItem()?.id && (
      <div className="bg-pink-50 rounded-lg p-2 mb-4">
        {mailStore.greeting !== "none" ? (
          <p>
            {mailStore.greeting}
            <b> {getRecipientNameType(mailStore.recipientNameType)}</b>,
          </p>
        ) : (
          ""
        )}
        <br />
        <p>
          <b className="my-text-yellow-500">{getItem().name}</b> (Content Item
          ID: <b className="my-text-yellow-500"> {getItem().item_id})</b> has
          been added to our collection.
        </p>
        <br />
        <p>
          To access it, please use the following link:{" "}
          <a
            href={generateItemLink(getItem())}
            className="underline text-blue-500"
            target="_blank"
          >
            {getItem().name}
          </a>
          .
        </p>
        <br />
        <p>
          Please use{" "}
          <a
            href="/web/mails/unsubscribe/self"
            className="underline text-blue-500"
            target="_blank"
          >
            No More Updates
          </a>{" "}
          to no longer receive an update email from me.
        </p>
        <br />
        {/* <p>Thank you for your unwavering interest in our work. </p>
        <p>Best Regards,</p>
        <p className="font-bold">{mailStore.user.name}</p>
        <p>
          Litzapp User Handle:{" "}
          <b className="my-text-yellow-500">{mailStore.user.handle_id}</b>
        </p>
        <br />
        <p>
          Sent via Litzapp, a platform owned by Remote Analytics Jobs Inc.
          Litzapp is the Ultimate Content and Skills Monetization Platform.
          Litzapp.com{" "}
        </p> */}
      </div>
    )
  );
}
