import { isResponsiveMode, API_BASE } from "../../Utils/helpers";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import axios from "axios";
import { toast } from "react-toastify";
import BookingCalendar from "./calendar";
import { useEffect, useState } from "react";
import TextField from "../inputs/text-input";
import BasicButton from "../buttons/basic-button";
import authRequestService from "../../services/HttpRequests/authenticated-request-services";
import TextNumberNoFloatField from "../inputs/text-number-no-float";

export default function ProductCalendarModal({ modalOpen, hideModal, state }) {
  const data = state?.data;
  const payload = data?.preferred_meta;
  const [bookings, setBookings] = useState([]);
  const [quantity, setQuantity] = useState();
  const [processing, setProcessing] = useState(false);
  const [meta, setMeta] = useState({});
  const getSubsForMeta = () => {
    const url =
      API_BASE +
      "/api/book/custom/product/meta/map/custom/" +
      meta.block_meta_id +
      "/subs";

    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          const subs = [];
          Object.keys(response.data.subs).forEach(function (key, index) {
            subs.push({
              color: response.data.subs[key].color,
              date: new Date(response.data.subs[key].date),
              code: response.data.subs[key].code,
              message: response.data.subs[key].message,
              date_only: key,
              sub_count: response.data.subs[key].sub_count,
              available_quantity: response.data.subs[key].available_quantity,
            });
          });

          setBookings(subs);
        }
      })
      .catch((e) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const updateProductMeta = () => {
    const url =
      API_BASE +
      `/book/custom/product/meta/block/${payload.block_meta_id}/page/` +
      data?.uuid +
      "/quantity/update";
    let formData = new FormData();
    setProcessing(true);
    formData.append("block_meta_id", meta.block_meta_id);
    formData.append("page_id", data?.uuid);
    formData.append("quantity", quantity);
    authRequestService()
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          setProcessing(false);
          if (response?.data?.meta?.payload) {
            setMeta(JSON.parse(response.data.meta.payload));
            getSubsForMeta();
            toast("Updated", { type: "success" });
          }
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const handleSelectedDateChange = (e, type) => {};
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + 13);
  useEffect(() => {
    if (payload) {
      const meta = payload ? JSON.parse(payload) : {};
      setMeta(meta);
    }
  }, [modalOpen]);

  useEffect(() => {
    if (meta?.booking_interruption && meta?.quantity_enabled) {
      setQuantity(meta.quantity);
    }
    if (meta.block_meta_id) {
      getSubsForMeta();
    }
  }, [meta]);

  useEffect(() => {}, [payload]);

  const canUpdateQuantity = () => {
    if (meta?.booking_interruption && meta.quantity_enabled) {
      return true;
    }
    return false;
  };

  return (
    <div
      className={`${
        isResponsiveMode() ? "absolute" : "absolute"
      }  z-99999 overflow-y-auto top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="relative inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-8/12"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className="rounded rounded-full p-0 -top-2 -right-1 absolute px-1 cursor-pointer opacity-75"
            onClick={() => hideModal()}
          >
            <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
          </div>

          <div class="px-4 pt-3 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <div className="w-full flex flex-col items-start">
                <p>
                  Item ID:{" "}
                  <span className="text-yellow-700 my-text-yellow-700 font-bold">
                    {data?.item_id}
                  </span>
                </p>
                <p>
                  Item: Title:
                  <span className="text-yellow-600 my-text-yellow-600">
                    {data?.name}
                  </span>{" "}
                </p>
              </div>
              {canUpdateQuantity() && (
                <div className="w-full md:w-8/12 flex items-center justify-start gap-2">
                  <div className="flex justify-start">
                    <p>Quantity</p>
                  </div>
                  <div className="flex justify-start w-full">
                    <TextNumberNoFloatField
                      handleChange={(e) => setQuantity(e)}
                      value={quantity}
                    />
                  </div>
                  <div className="w-full flex justify-start">
                    <BasicButton
                      disabled={!quantity || !meta?.block_meta_id || processing}
                      classes={"my-bg-yellow-500 text-black"}
                      title={processing ? "Updating..." : "Update Quantity"}
                      handleClick={() => updateProductMeta()}
                    />
                  </div>
                </div>
              )}

              {bookings.length ? (
                <BookingCalendar
                  bookings={bookings}
                  meta={meta}
                  minDate={new Date()}
                  maxDate={addDays(
                    new Date(),
                    Number(meta.future_availability)
                  )}
                  setSelected={handleSelectedDateChange}
                  show={true}
                  state={{}}
                />
              ) : (
                <p>Loading...</p>
              )}
            </div>
            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
            {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
