import axios from "axios";
import { useEffect, useState } from "react";
import BasicButton from "../../components/buttons/basic-button";
import { isAdmin, API_BASE } from "../../Utils/helpers";
import { toast } from "react-toastify";
export default function BookReaders() {
  const [readers, setReaders] = useState([]);
  const url = API_BASE + "/api/readers";
  const getReaders = () => {
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setReaders(response.data.data);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  useEffect(() => {
    getReaders();
    if (!isAdmin()) {
      window.location.href = "/";
    }
  }, []);
  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">Readers</h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            <table class="table-auto w-full">
              <thead>
                <tr className={window.innerWidth < 720 ? "hidden" : ""}>
                  <th class="px-4 py-2 text-gray-600">Name</th>
                  <th class="px-4 py-2 text-gray-600">Email</th>
                  <th class="px-4 py-2 text-gray-600">Payments</th>
                  <th class="px-4 py-2 text-gray-600">Donations</th>
                </tr>
              </thead>
              <tbody>
                {readers.map((reader) => {
                  return (
                    <tr
                      className={window.innerWidth < 820 ? "flex flex-col" : ""}
                    >
                      <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <h3 className="font-bold md:hidden">Name</h3>
                        {reader.name}
                      </td>
                      <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <h3 className="font-bold md:hidden">Email</h3>
                        {reader.email}
                      </td>

                      <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <a href={`/payments/${reader.uuid}`}>
                          <BasicButton
                            classes={"bg-teal-500 hover:bg-teal-400 w-full"}
                            title={"Purchases"}
                            handleClick={null}
                          />
                        </a>
                      </td>
                      <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <a href={``}>
                          <BasicButton
                            classes={"bg-green-500 hover:bg-green-400 w-full"}
                            title={"Donations"}
                            handleClick={null}
                          />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
