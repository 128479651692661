import BasicButton from "../../../../components/buttons/basic-button";
import { useState, useEffect } from "react";
import { isPublisher, isAuthUser, API_BASE } from "../../../../Utils/helpers";
import authRequestService from "../../../../services/HttpRequests/authenticated-request-services";
import PubSupplierBusinessProfile from "./components/business-profile";
import PubSupplierIndividualProfile from "./components/individual-profile";
import LoadingProgress from "../../rich-page/components/loading-progress";

export default function PubSupplierProfile() {
  const [stage, setStage] = useState(1);
  const [regType, setRegType] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [approved, setApproved] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [verification, setVerification] = useState({});

  const getVerificationData = () => {
    const url = API_BASE + "/publisher/supplier/application/retrieve";
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((res) => {
        setVerification(res.data.verification);
        setProcessing(false);
      })
      .catch((err) => {
        setProcessing(false);
      });
  };

  useEffect(() => {
    if (!isPublisher()) {
      window.location.href = "/";
    }
    getVerificationData();
  }, []);

  return (
    <>
      <div className="bg-white m-2 p-2 flex  items-center justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 bold">Supplier Profile</h1>
        </div>
        <div className="flex gap-2 items-start">
          {/* <BasicButton
            title={"Tutorials"}
            handleClick={() =>
              (window.location.href = "https://litzapp.com/share/sh/nWBVlkmtwF")
            }
            classes={"my-bg-yellow-500 text-black"}
          /> */}
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div className="p-2 w-full flex flex-col items-center">
        <LoadingProgress loading={processing} />
        <div className="p-2 w-full md:w-8/12">
          {verification?.type == "individual" && (
            <PubSupplierIndividualProfile
              verification={verification}
              getVerificationData={getVerificationData}
              processing={processing}
            />
          )}
          {verification?.type == "business" && (
            <PubSupplierBusinessProfile
              verification={verification}
              getVerificationData={getVerificationData}
              processing={processing}
            />
          )}
        </div>
      </div>
    </>
  );
}
