import React, { useRef, useEffect, useState, useReducer } from "react";
import {
  API_BASE,
  BASE_URL,
  isAuthUser,
  authUserData,
  isResponsiveMode,
  axiosRequestInstance,
  getLocation,
  globalAds,
} from "./../../../Utils/helpers";

import openRequestService from "./../../../services/HttpRequests/unauthenticated-request-services";
import authRequestService from "./../../../services/HttpRequests/authenticated-request-services";

import { toast } from "react-toastify";
import AuthPromptModal from "./../../../modals/auth-prompt-modal";
import RatingPromptModal from "./../../../modals/rating-prompt-modal";
import useDebounce from "./../../debounce/debounce-helper";
import BookTemplate from "./components/book-template";
import RenderStandardBook from "./components/standard-book";
import RenderCustomBook from "./components/custom-book";
import RatingBar from "./components/rating-bar";
import SearchBar from "./components/search-bar";
import AdsenseBar from "./components/adsense-bar";
import ContentComposer from "./components/content-composer";
import AdSection from "./components/ad-section";
import LoadingStatus from "./components/loading-status";
import HomepageAvailabilityCalendar from "./components/product";
import ProductAvailibilitySearch from "./components/product-availability";
import { ads } from "./util/ads";
import { useHomepageStore } from "../../../stores/homepage-store";
import { useIntersectionObserver } from "@uidotdev/usehooks";

export default function BookSlider() {
  const [books, setBooks] = useState([]);
  const [paidAdverts, setPaidAdverts] = useState([]);
  const [booksWithAds, setBooksWithAds] = useState([]);
  // const [nextPageURL, setNextPageURL] = useState();
  const [lastRatedBookId, setLastRatedBookId] = useState("");
  const [lastRatedBook, setLastRatedBook] = useState();
  const [currentRating, setCurrentRating] = useState();
  const [renderSlider, setRenderSlider] = useState(true);
  const [rating, setRating] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [adCounter, setAdCounter] = useState(0);
  const [adSlotPositions, setAdSlotPositions] = useState([]);
  const boxRef = useRef();
  const cardContainerRef = useRef();
  const loadMoreBtRef = useRef();
  const multiSearchRef = useRef();
  const [pageNumber, setPageNumber] = useState(1);
  const [currentRatingFilter, setCurrentRatingFilter] = useState(0);
  const [showRatingDropdown, setShowRatingDropdown] = useState(false);
  const [bookType, setBookType] = useState("slides");
  const [searchText, setSearchText] = useState("");
  const [moreTags, setMoreTags] = useState([]);
  const [hydrateInfiniteLoop, setHydrateInfiniteLoop] = useState(false);
  const [showAuthPromptModal, setShowAuthPromptModal] = useState(false);
  const [showRatingPromptModal, setShowRatingPromptModal] = useState(false);
  const [showAvailabilityCalendar, setShowAvailabilityCalendar] =
    useState(false);
  const [currSelProduct, setCurrSelProduct] = useState({});
  const [ratingPromptModalMessage, setShowRatingPromptModalMessage] =
    useState("");
  const [
    currentRatingFilterSpecification,
    setCurrentRatingFilterSpecification,
  ] = useState("");
  const forceUpdate = React.useReducer((bool) => !bool, true)[1];
  function onDocumentLoadSuccess({ numPages }) {}
  const homePageStore = useHomepageStore((state) => state);

  const [hasLoadedMore, setHasLoadedMore] = useState(false);
  const [refInViewPort, entryInViewPort] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: "0px",
  });

  var autoSlide;
  let adsCounter = 0;
  let loadMoreCounter = 0;
  var bookCount = 0;
  var adIndexStart = true;
  var adIndex = 0;

  const handleLocationChange = () => {};

  const openAvailabilityCalendar = (data) => {
    setShowAvailabilityCalendar(true);
    setCurrSelProduct(data);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const closeAvailabilityCalendar = () => {
    setShowAvailabilityCalendar(false);
    setCurrSelProduct({});
  };

  const getPaidAdvert = () => {
    const url = "/paid-adverts";
    setProcessing(true);

    openRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);

        if (response.status == 200) {
          setPaidAdverts(response.data.adverts);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
        setProcessing(false);
      });
  };

  const getBooks = () => {
    setProcessing(true);
    const url = "/books/optimized";
    openRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          homePageStore.setBooks(response.data.books.data);
          homePageStore.setNextPageURL(response.data.books.next_page_url);
        }
        setProcessing(false);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
        setProcessing(false);
      });
  };

  const rateBook = (value, book) => {
    const url = API_BASE + "/rating";
    if (!isAuthUser()) {
      openRatingPromtModal("Please###");
      return false;
    }
    if (book?.owneroptimized?.uuid == authUserData().uuid) {
      return false;
    }

    setLastRatedBookId(book.uuid);
    setCurrentRating(value);
    authRequestService()
      .post(url, {
        value: value,
        bookId: book.uuid,
        userId: authUserData().uuid,
      })
      .then((response) => {
        if (response.status == 200) {
          setLastRatedBook(response.data.book);
        }
      })
      .catch((error) => {
        if (error.response.status == 422) {
          if ("no_purchase_record" in error.response.data) {
            openRatingPromtModal(
              "You can only leave a rating if you have purchased the product."
            );
            return false;
          }
          openRatingPromtModal(
            "You can only leave a rating if you have viewed the content to its last slide/page."
          );
          return false;
        }
        toast("Something went wrong", { type: "error" });
      });
  };

  const getBooksByAppliedDFilters = () => {
    setProcessing(true);
    setBooks([]);
    const url = `/books/${bookType}/paginated/filtered/all/${currentRatingFilter}/${
      currentRatingFilterSpecification.value
    }/${searchText.replace("/", "___")}`;
    window.scrollTo(0, 250);
    //  window.scrollTo(0, 250);
    openRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.books) {
            homePageStore.setBooks(response.data.books.data);
            homePageStore.setNextPageURL(response.data.books.next_page_url);
          }
        }
        setProcessing(false);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
        setProcessing(false);
      });
  };

  const loadMore = () => {
    console.log("load more");
    if (homePageStore.nextPageURL) {
      setProcessing(true);
      const url =
        getLocation().hostname == "litzapp.com"
          ? homePageStore.nextPageURL.split("api/api")[1]
          : homePageStore.nextPageURL.split("api")[1];

      openRequestService()
        .get(url)
        .then((response) => {
          if (response.status == 200) {
            let oldBooks = homePageStore.books;
            let newBooks = oldBooks.concat(response.data.books.data);
            if (response.data.books.data) {
              homePageStore.setBooks(newBooks);
              homePageStore.setNextPageURL(response.data.books.next_page_url);
              if (
                !response.data.books.next_page_url &&
                response.data.books.prev_page_url &&
                response.data.books.per_page > 1
              ) {
                setHydrateInfiniteLoop(true);
                homePageStore.setNextPageURL(
                  response.data.books.prev_page_url.split("page=")[0] + "page=1"
                );
              } else {
                // setHydrateInfiniteLoop(false);
              }
            }
          }
          setProcessing(false);
        })
        .catch((error) => {
          setProcessing(false);
          toast("Something went wrong", { type: "error" });
        });
    }
  };

  const getFristPageOfBook = (book) => {
    const bookPagesWithAds = [];
    if (book.pages) {
      if (book.infopage) {
        if (book.infopage.page_start == 1) {
          bookPagesWithAds.push({
            ...book.infopage,
            type: "info",
          });
          // alert("has info page");
        }
      }
      for (let i = 0; i < book.pages.length; i++) {
        bookPagesWithAds.push(book.pages[i]);

        if (book.infopage) {
          if (
            book.infopage.position > 0 &&
            book.pages[i].position == book.infopage.position
          ) {
            bookPagesWithAds.push({
              ...book.infopage,
              type: "info",
            });
          }
        }
      }
      if (book.infopage) {
        if (book.infopage.page_end == 1) {
          bookPagesWithAds.push({
            ...book.infopage,
            type: "info",
          });
          // alert("has info page");
        }
      }
    }

    if (book.pages && book.pages.length) {
      if (book.pages[0].file) {
        if (book.pages[0].file.includes("pdf")) {
          return {
            file: BASE_URL + "/storage" + book.pages[0].file.split("public")[1],
            type: "pdf",
          };
        } else {
          return {
            file: BASE_URL + "/storage" + book.pages[0].file.split("public")[1],
            type: "image",
          };
        }
      }
    }
    return {};
  };

  const getBook = (bookId) => {
    const url = API_BASE + "/book/" + bookId;
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setLastRatedBook(response.data.book);
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const trackPublisher = (book, trackerId) => {
    const url = API_BASE + "/track";
    authRequestService()
      .post(url, {
        publisherId: book?.owneroptimized?.uuid,
        trackerId: trackerId,
      })
      .then((response) => {
        if (response.status == 200) {
          getBook(book.uuid);
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const untrackPublisher = (book, trackerId) => {
    const url = "/untrack";
    openRequestService()
      .post(url, {
        publisherId: book?.owneroptimized?.uuid,
        trackerId: trackerId,
      })
      .then((response) => {
        if (response.status == 200) {
          getBook(book.uuid);
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const isTrackingPublisher = (book) => {
    if (book?.owneroptimized?.trackers && isAuthUser()) {
      const isTracking = book?.owneroptimized?.trackers.filter(
        (t) => t.tracker_id == authUserData().id
      );
      return isTracking.length ? true : false;
    }

    return false;
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e);
  };

  const applyRatingFilter = (value) => {
    setCurrentRatingFilter(value);
    setCurrentRatingFilterSpecification("");
    setShowRatingDropdown(true);
  };

  const resetRating = (value) => {
    handleSearchTextChange("");
    setCurrentRatingFilter(0);
    setCurrentRatingFilterSpecification("");
    setShowRatingDropdown(false);
  };

  const applyRatingFilterSpecification = (e) => {
    setCurrentRatingFilterSpecification(e);
    setShowRatingDropdown(false);
  };

  const renderBookByGroupType = (book) => {
    if (book.group == "standard") {
      return (
        <RenderStandardBook
          book={book}
          getFristPageOfBook={getFristPageOfBook}
        />
      );
    } else {
      return <RenderCustomBook book={book} />;
    }
  };

  const openAuthPromtModal = () => {
    setShowAuthPromptModal(true);
  };

  const hideAuthPromtModal = () => {
    setShowAuthPromptModal(false);
  };

  const openRatingPromtModal = (msg) => {
    setShowRatingPromptModalMessage(msg);
    setShowRatingPromptModal(true);
  };

  const hideRatingPromtModal = () => {
    setShowRatingPromptModalMessage("");
    setShowRatingPromptModal(false);
  };

  const clearSearchText = () => {
    setSearchText("");
  };

  const shouldRenderAd = () => {
    adsCounter++;
    if (adsCounter == 5) {
      adsCounter = 0;
      return true;
    }
    return false;
  };

  const shouldRenderLoadMore = () => {
    loadMoreCounter++;
    if (loadMoreCounter == 5) {
      loadMoreCounter = 0;
      return true;
    }
    return false;
  };

  const renderBookTemplate = () => {
    var booksWithAdsCollection = [];
    homePageStore.books.map((book, count) => {
      bookCount++;
      booksWithAdsCollection.push({
        content: book,
        type: "book",
        load_more:
          shouldRenderLoadMore() || count == homePageStore.books.length - 1
            ? 1
            : 0,
      });
      if (books.length > 5 && shouldRenderAd()) {
        if (true) {
          booksWithAdsCollection.push({
            content: "c2687284dc6b1e8c39f73cf6af7bc1c5",
            type: "advert",
          });
        }
      }
    });

    return booksWithAdsCollection;
  };

  const renderTags = (book) => {
    let tagsToRender = 0;
    let updatedTags = [];

    if (moreTags.includes(book.uuid)) {
      for (let index = 0; index < book.tags.length; index++) {
        updatedTags.push({ type: "tag", content: book.tags[index] });

        if (tagsToRender == book.tags.length - 1) {
          updatedTags.push({ type: "view_less", content: "" });
        }
        tagsToRender++;
      }
    } else {
      for (let index = 0; index < book.tags.length; index++) {
        tagsToRender++;
        if (tagsToRender > 1) {
          updatedTags.push({ type: "view_more", content: "" });
          break;
        } else {
          updatedTags.push({ type: "tag", content: book.tags[index] });
        }
      }
    }

    return updatedTags.map((t) => {
      if (t.type == "tag") {
        return (
          <div
            className="rounded-full text-black border p-1 inline relative m-1 px-3 bg-pink-50 cursor-pointer"
            onClick={() => handleSearchTextChange("$" + t.content.name)}
          >
            <span className="text-xs pr-1">${t.content.name}</span>
          </div>
        );
      }

      if (t.type == "view_more") {
        return (
          <p
            className="text-xs text-blue-500 cursor-pointer underline"
            onClick={() => showMoreTags(book.uuid)}
          >
            View More Tags...
          </p>
        );
      }

      if (t.type == "view_less") {
        return (
          <p
            className="text-xs text-blue-500 cursor-pointer underline"
            onClick={() => showLessTags(book.uuid)}
          >
            View Less Tags...
          </p>
        );
      }
    });
  };

  const showMoreTags = (id) => {
    setMoreTags((prev) => [...prev, id]);
  };

  const showLessTags = (id) => {
    let currTags = moreTags;
    let filteredTags = currTags.filter((t) => t !== id);
    setMoreTags(filteredTags);
  };

  useEffect(() => {
    if (homePageStore.books.length) {
      let bookPos = [];
      homePageStore.books.map((book, count) =>
        count % 2 !== 0 ? bookPos.push(count) : ""
      );
      setAdSlotPositions(bookPos);
    }
  }, [homePageStore.books]);

  useEffect(() => {
    if (isResponsiveMode()) {
      import("../../../../src/landing_mobile.css");
    } else {
      import("../../../../src/landing.css");
    }
    getPaidAdvert();
  }, []);

  useDebounce(
    () => {
      if (currentRatingFilter > 0) {
      } else {
        setShowRatingDropdown(false);
      }

      if (currentRatingFilterSpecification.value || searchText) {
        getBooksByAppliedDFilters();
      } else {
        getBooks();
      }
    },
    [currentRatingFilterSpecification, searchText],
    800
  );

  const randomPick = Math.floor(Math.random() * 16);
  const imageUrl = globalAds[randomPick].image;
  const imageLink = globalAds[randomPick].link;

  const incrementAdCount = () => {
    if (!adIndexStart) {
      adIndex++;
    }
    if (adIndex == paidAdverts.length) adIndex = 0;
    adIndexStart = false;
  };

  const doLoadMore = (c) => {
    if (!hasLoadedMore && entryInViewPort?.isIntersecting && !processing) {
      // setHasLoadedMore(true);
      loadMore();
    }
  };

  return renderSlider ? (
    <section className="flex flex-col items-center">
      <input ref={multiSearchRef} className="h-0" />
      <ProductAvailibilitySearch
        openAvailabilityCalendar={openAvailabilityCalendar}
      />
      <div className="flex flex-col md:flex-row md:grid-cols-3 justify-between items-center m-2 gap-2 w-8/12">
        <SearchBar
          clearSearchText={clearSearchText}
          handleSearchTextChange={handleSearchTextChange}
          searchText={searchText}
        />

        <RatingBar
          currentRatingFilterSpecification={currentRatingFilterSpecification}
          applyRatingFilterSpecification={applyRatingFilterSpecification}
          currentRatingFilter={currentRatingFilter}
          applyRatingFilter={applyRatingFilter}
          showRatingDropdown={showRatingDropdown}
          resetRating={resetRating}
        />
      </div>
      <div className="w-full">
        <div className="flex justify-center w-full gap-2">
          <AdsenseBar />
          {/* <img className="cursor-pointer" src={imageUrl} /> */}
          {/* <Adsterra slot={"7280370"} /> */}
        </div>
        <div className="relative grid grid-cols-2 md:grid-cols-4 items-start justify-center w-full">
          {renderBookTemplate().map((c, i) => {
            if (c.type == "book" && c.load_more == 1) {
              paidAdverts.length && incrementAdCount();
            }
            return (
              <>
                {" "}
                <ContentComposer
                  d={c}
                  renderBookByGroupType={renderBookByGroupType}
                  rateBook={rateBook}
                  currentRating={currentRating}
                  isTrackingPublisher={isTrackingPublisher}
                  lastRatedBook={lastRatedBook}
                  untrackPublisher={untrackPublisher}
                  trackPublisher={trackPublisher}
                  openAuthPromtModal={openAuthPromtModal}
                  renderTags={renderTags}
                  loadMore={loadMore}
                  processing={processing}
                />
                {/* <AdSection
                  c={c}
                  adIndex={adIndex}
                  loadMore={loadMore}
                  processing={processing}
                  adverts={paidAdverts}
                /> */}
              </>
            );
          })}
        </div>
        <div className="w-full md:w-7/12" ref={refInViewPort}>
          {doLoadMore()}
        </div>
        <LoadingStatus processing={processing} />
        <br />
        <br />
      </div>{" "}
      <input ref={loadMoreBtRef} className="h-0" />
      <AuthPromptModal
        hideAdModal={hideAuthPromtModal}
        modalOpen={showAuthPromptModal}
      />
      <RatingPromptModal
        hideAdModal={hideRatingPromtModal}
        modalOpen={showRatingPromptModal}
        body={ratingPromptModalMessage}
      />
      <HomepageAvailabilityCalendar
        hideModal={closeAvailabilityCalendar}
        modalOpen={showAvailabilityCalendar}
        state={{ data: [] }}
      />
    </section>
  ) : (
    <div>
      <p className="text-center my-6">Loading...</p>
    </div>
  );
}
