export default function BlockWarning({ data, enableBlockButton, isBlocked }) {
  return (
    data?.publisher &&
    isBlocked && (
      <p>
        <b className="font-bold">{data.subscriber_name}</b>, are you certain
        that you wish to receive update emails from
        <b className="font-bold"> {data.publisher.name}</b>?
      </p>
    )
  );
}
