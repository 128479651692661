import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../../layout/footer";
import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import { API_BASE, isAuthUser } from "../../../Utils/helpers";
import axios from "axios";

export default function SponsorPasswordSetup() {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [processing, setProcessing] = useState();
  const [linkExpired, setLinkExpired] = useState(false);
  const naviagte = useNavigate();
  let { userId } = useParams();
  let { token } = useParams();

  const handlePasswordChange = (e) => {
    setPassword(e);
  };

  const handlePasswordConfirmChange = (e) => {
    setPasswordConfirm(e);
  };

  const updatePassword = () => {
    const url = API_BASE + "/api/user/update/validate-token-update-password";
    setProcessing(true);
    let formData = new FormData();
    formData.append("user_id", userId);
    formData.append("token", token);
    formData.append("password", password);
    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        toast("Your account is now successfully set up!", {
          type: "success",
        });

        setTimeout(() => {
          if (isAuthUser()) {
            window.location.href = `/sponsor/dashboard`;
          } else {
            window.location.href = `/login`;
          }
        }, 600);
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.status == 422) {
          //incomplete_registration

          if (" invalid_link" in error.response.data) {
            toast("Invalid or expired link!", {
              type: "error",
            });
          }
        } else {
          toast("Something went wrong!", { type: "error" });
        }
      });
  };

  const validateLink = () => {
    const url = API_BASE + "/api/user/update/validate-password-reset-token";
    setProcessing(true);
    let formData = new FormData();
    formData.append("user_id", userId);
    formData.append("token", token);
    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.status == 422) {
          setLinkExpired(true);
        }
      });
  };

  useEffect(() => {
    if (isAuthUser()) {
      window.location.href = "/";
    }
    validateLink();
  }, []);

  return (
    <>
      <div class="w-full">
        <br />
        <div class="flex flex-col text-center md:text-left justify-evenly items-center">
          <div class="flex flex-col w-full items-center">
            {!linkExpired ? (
              <div className="w-full w-6/12">
                <h1 class="text-2xl text-gray-800 font-bold my-2">
                  Password Setup
                </h1>
                <div class="flex flex-col w-full  my-1">
                  <label class="text-gray-500 mb-2">Password</label>
                  <TextField
                    placeholder={"Password"}
                    handleChange={handlePasswordChange}
                    type="password"
                  />
                </div>

                <div class="flex flex-col w-full my-5">
                  <label class="text-gray-500 mb-2">Confirm Password</label>
                  <TextField
                    placeholder={"Confirm Password"}
                    handleChange={handlePasswordConfirmChange}
                    type="password"
                  />

                  <p className="text-yellow-600">
                    {password && passwordConfirm && password !== passwordConfirm
                      ? "Password Mismatch, make sure Password and Confirmed Password are a match"
                      : ""}
                  </p>
                </div>

                <div className="text-center w-full flex flex-col items-center">
                  <br />
                  <BasicButton
                    disabled={
                      !password ||
                      !passwordConfirm ||
                      password !== passwordConfirm
                    }
                    title={processing ? "Updating.." : "Update Password"}
                    classes={"p-6 w-full mt-2"}
                    handleClick={updatePassword}
                  />
                </div>
              </div>
            ) : (
              <div>
                <p>Sorry, the link has expired.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
