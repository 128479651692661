import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { API_BASE } from "../../Utils/helpers";
import axios from "axios";

const Adsterra = ({ slot, random }) => {
  const [id, setId] = useState(Math.random());
  const [sloty, setSloty] = useState("");
  const randomSlots = [
    "722404fdc645aa4dc5f60217738ff721",
    "c1a58bb55661cf0f11088ae4b619b4ae",
    "f21462f40aaf51504ec24015d4f64a7d",
  ];

  const getId = () => `atContainer-${id}`;

  const ref = useRef();
  const refEval = useRef();

  var codeToEval;

  const getPageData = () => {
    const url = API_BASE + "/api/advert/conversion/" + slot;
    axios
      .get(url)
      .then((response) => {
        refEval.current = response.data;
        if (codeToEval !== "") {
          eval(
            refEval.current.replace(
              "document.write(content);",
              "ref.current = content;"
            )
          );
          setSloty("xx");
        }
      })
      .catch((error) => {
        // toast("Something went wrong!", { type: "error" });
      });
  };

  useEffect(() => {
    // if (!ref.current.firstChild && slot) {
    //   const atAsyncOptions = {
    //     async: true,
    //     container: getId(),
    //     params: {},
    //   };
    //   const conf = document.createElement("script");
    //   conf.innerHTML = `
    //     if (typeof atAsyncOptions !== 'object') var atAsyncOptions = []
    //   `;
    //   conf.type = "text/javascript";
    //   const script = document.createElement("script");
    //   script.async = true;
    //   script.src = "//predictivadvertising.com/a/display.php?r=7280370";
    //   script.type = "text/javascript";
    //   if (ref.current) {
    //     ref.current.append(conf);
    //     ref.current.append(script);
    //   }
    // }
    // if (codeToEval) {
    //   eval(
    //     codeToEval.replace("document.write(content);", "ref.current = content;")
    //   );
    // }
    if (slot) {
      getPageData();
    }
  }, [id]);

  return (
    <>
      {/* <div id={getId()} /> */}
      <div dangerouslySetInnerHTML={{ __html: ref.current }} />
    </>
  );
};

export default Adsterra;
