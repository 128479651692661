export default function FreeContentCoverLinks() {
  return (
    <>
      <div className="text-xs text-center">
        <p>
          If you upload content that is pornographic, illegal, plagiarized,
          unauthorized, offensive, discriminatory, defamatory, misleading,
          spammy, or in violation of any intellectual property rights, your
          account will be DEACTIVATED.
        </p>
        <p className="text-red-700 mb-2 text-base">
          If you are having trouble getting a cover photo for your work, try:
        </p>
        <p className="mb-1">
          <a
            className="underline text-blue-900"
            href="https://pixabay.com"
            target={"_blank"}
          >
            Pixabay
          </a>
          &nbsp;&nbsp;(Free Downloads){" "}
        </p>
        <p className="mb-1">
          <a
            className="underline text-blue-900"
            href="https://burst.shopify.com"
            target={"_blank"}
          >
            Burst
          </a>{" "}
          &nbsp;(Free Downloads)
        </p>
        <p className="mb-1">
          <a
            className="underline text-blue-900"
            href="https://www.wonder-ai.com"
            target={"_blank"}
          >
            Wonder AI Art Generator
          </a>{" "}
          &nbsp;(Free Tier Included)
        </p>
        <p className="mb-1">
          {" "}
          <a
            className="underline text-blue-900"
            href="https://openai.com/product/dall-e-2"
            target={"_blank"}
          >
            DALL·E 2 AI Art Generator
          </a>
        </p>
        {/* <p>
          All published work on Litzapp MUST MEET LEGAL REQUIREMENTS, including
          your cover photo.
        </p>
        <p>
          For better quality, the cover photo should be in a Portrait/Vertical ▯
          Orientation rather than a landscape orientation ▭.
        </p> */}
      </div>
    </>
  );
}
