import { useState } from "react";
import Terms from "../pages/terms";
export default function TermsAccordion() {
  const [termsOpen, setTermsOpen] = useState(false);

  return (
    <div>
      <div className="border border-gray-400 rounded-lg py-4 px-2">
        <p className="font-bold text-lg flex justify-between">
          Terms and Conditions{" "}
          <i
            class="fa fa-angle-down cursor-pointer"
            onClick={() => {
              setTermsOpen((prev) => !prev);
            }}
          ></i>
        </p>
        <div className={`h-44 overflow-y-scroll ${!termsOpen ? "hidden" : ""}`}>
          {/* <h3 className="font-bold text-center text-3xl mb-6">
            Terms and Conditions
          </h3> */}
          <div>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">
                Agreement between User and Litzapp.com
              </span>
            </p>
            <p class="terms-condition-c0">
              <span>Welcome to Litzapp. The Litzapp platform (the &quot;</span>
              <span class="terms-condition-c3">Platform</span>
              <span>
                &quot;) is owned and operated by Remote Analytics Jobs Inc.
                Litzapp is offered to you conditioned on your acceptance without
                modification of the terms, conditions, and notices contained
                herein (the &quot;
              </span>
              <span class="terms-condition-c3">Terms</span>
              <span class="terms-condition-c1">
                &quot;). Your use of Litzapp constitutes your agreement to all
                such Terms. Please read these terms carefully, and keep a copy
                of them for your reference.
              </span>
            </p>
            <br />
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Litzapp is a literary marketplace platform where writers,
                authors, photographers, and other digital content creators can
                publish and sell their works in digital format at prices they
                set.{" "}
              </span>
            </p>
            <br />
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">Privacy</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Your use of Litzapp is subject to Litzapp&#39;s Privacy Policy.
                Please review our Privacy Policy, which also governs the
                Platform and informs users of our data collection practices.
              </span>
            </p>
            <br />
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">Electronic Communications</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Visiting Litzapp or sending emails to Litzapp constitutes
                electronic communications. You consent to receive electronic
                communications and you agree that all agreements, notices,
                disclosures and other communications that we provide to you
                electronically, via email and on the Platform, satisfy any legal
                requirement that such communications be in writing.
              </span>
            </p>
            <br />
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">Your Account</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                If you use the Platform, you are responsible for maintaining the
                confidentiality of your account and password and for restricting
                access to your computer, and you agree to accept responsibility
                for all activities that occur under your account or password.
                You may not assign or otherwise transfer your account to any
                other person or entity. You acknowledge that Litzapp is not
                responsible for third-party access to your account that results
                from theft or misappropriation of your account. Litzapp and its
                associates reserve the right to refuse or cancel service,
                terminate accounts, or remove or edit content at our sole
                discretion.
              </span>
            </p>
            <br />
            <p class="terms-condition-c0">
              <span class="c6 terms-condition-c3">Publisher</span>
            </p>
            <p class="terms-condition-c0">
              <span>The term &quot;</span>
              <span class="terms-condition-c3">Publisher</span>
              <span class="terms-condition-c1">
                &quot; shall refer to any user who publishes content on the
                platform. The Publisher shall be solely responsible for ensuring
                that any content published under the Publisher&#39;s account
                does not infringe upon any third-party rights, including but not
                limited to copyright, trademark, or other proprietary rights.
                Furthermore, the Publisher shall ensure that such content is not
                illegal, threatening, harmful, unauthorized, or sexually
                explicit in nature. The Publisher shall be fully responsible for
                any and all liability arising from the publication of any such
                content.{" "}
              </span>
            </p>
            <p class="terms-condition-c0 c2">
              <span class="terms-condition-c1"></span>
            </p>
            <p class="terms-condition-c0">
              <span class="c6 terms-condition-c3">Content Responsibility</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Litzapp shall not be held responsible for the content published
                on the platform by a Publisher. If a user becomes aware of
                content that infringes upon any third-party rights, including
                but not limited to copyright, trademark, or other proprietary
                rights, such user may report such content to legal@litzapp.com,
                along with evidence of the alleged infringement. Litzapp
                reserves the right, in its sole discretion, to determine the
                validity of such reports, and to take any action it deems
                appropriate, including but not limited to removing the allegedly
                infringing content from the platform and/or suspending the
                Publisher&#39;s account. Please note that due to the high volume
                of reports received by Litzapp, a prompt response may not always
                be possible. Litzapp will, however, make its best efforts to
                investigate each report and take appropriate action.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">Children Under Thirteen</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Litzapp does not knowingly collect, either online or offline,
                personal information from persons under the age of thirteen. If
                you are under 18, you may use Litzapp only with the permission
                of a parent or guardian.
              </span>
            </p>
            <p class="terms-condition-c0 c2">
              <span class="terms-condition-c1"></span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">Content Purchases</span>
            </p>
            <p class="terms-condition-c0">
              <span>A user who purchases content (&quot;</span>
              <span class="terms-condition-c3">Purchaser</span>
              <span>
                &quot;) shall have access to the purchased content (&quot;
              </span>
              <span class="terms-condition-c3">Purchase</span>
              <span class="terms-condition-c1">
                &quot;) for a period of three (3) weeks, or 504 hours, whichever
                comes first. Litzapp, at its sole discretion, may extend the
                Purchaser&#39;s access to the Purchase beyond 504 hours. The
                decision to extend access shall be made by Litzapp and shall not
                be contingent upon the Publisher of the Purchase. The Publisher,
                however, shall retain the right to modify the content of the
                Purchase at any time, subject to the terms and conditions of the
                agreement between the Publisher and Litzapp.
              </span>
            </p>
            <p class="terms-condition-c0 c2">
              <span class="terms-condition-c1"></span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">Subscriptions</span>
            </p>
            <p class="terms-condition-c0">
              <span>
                A user who subscribes to a Publisher&#39;s account on Litzapp
                (&quot;
              </span>
              <span class="terms-condition-c3">Subscriber</span>
              <span class="terms-condition-c1">
                &quot;) shall have access to all content available on the
                Publisher&#39;s account without further charge until the
                beginning of the Subscriber&rsquo;s next subscription cycle to
                the Publisher&rsquo;s account, provided the Subscriber has
                successfully paid the subscription fee. The Subscriber&#39;s
                access to the Publisher&#39;s content shall be limited to a
                period of thirty (30) days from the date of subscription, after
                which the Subscriber may be required to renew their subscription
                in order to continue accessing the Publisher&#39;s content. The
                terms and conditions of the subscription and access to the
                Publisher&#39;s content shall be governed by the agreement
                between Litzapp and the Subscriber. The Publisher shall retain
                the right to modify any content published on the
                Publisher&rsquo;s account at any time, subject to the terms and
                conditions of the agreement between the Publisher and Litzapp.
              </span>
            </p>
            <p class="terms-condition-c0 c2">
              <span class="terms-condition-c1"></span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">Content Deletion</span>
              <span class="terms-condition-c1">
                <br />
                Pursuant to the terms and conditions set forth in these Terms,
                the Publisher reserves the right to delete any content published
                within its account, regardless of the subscription status of any
                Subscriber. However, it should be noted that the Publisher may
                not have the authority to entirely delete a Purchase.
              </span>
            </p>
            <p class="terms-condition-c0 c2">
              <span class="terms-condition-c1"></span>
            </p>
            <p class="terms-condition-c0">
              <span class="c6 terms-condition-c3">Storage Space and Fees</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                In accordance with the Terms provided by Litzapp, Publishers are
                granted the privilege of publishing up to 500 MB of content on
                the platform for free. In the event that a Publisher exceeds the
                allotted 500 MB of storage space, a fee of $5.75 per month will
                be incurred for the first set of 20 GB. For each subsequent set
                of 20 GB, an additional fee of $3.75 per month will be added to
                the monthly charge. The storage space available for use by the
                Publisher will increase incrementally in sets of 20 GB, with
                each additional set thereafter incurring a fee of $3.75 per
                month.
              </span>
            </p>
            <p class="terms-condition-c0 c2">
              <span class="terms-condition-c1"></span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                However, it is important to note that the cost of storage is
                subject to change without prior notice to the Publishers. It is,
                therefore, important for the Publisher to be proactive in
                keeping up-to-date with the latest changes in storage fees.
              </span>
            </p>
            <p class="terms-condition-c0 c2">
              <span class="terms-condition-c1"></span>
            </p>
            <p class="terms-condition-c0">
              <span class="c6 terms-condition-c3">Advertisements</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                In accordance with the Terms established by the platform,
                Publishers have the discretion to determine the placement of
                advertisements within their published content. However, it is
                strictly prohibited for Publishers to engage in any form of
                self-dealing, including clicking on advertisements or attempting
                to manipulate the algorithm that determines payment for views of
                their content. Any such behavior deemed to be in violation of
                these terms may result in the restriction or deactivation of the
                Publisher&#39;s account.
              </span>
            </p>
            <p class="terms-condition-c0 c2">
              <span class="terms-condition-c1"></span>
            </p>
            <p class="terms-condition-c0">
              <span class="c6 terms-condition-c3">Star Rating</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Pursuant to the Terms set forth by Litzapp, in the event that
                the Platform determines that the star rating assigned to a
                Publisher&#39;s content is artificially inflated and not based
                on genuine user engagement, Litzapp reserves the right to take
                corrective action, which may include restriction or deactivation
                of the publisher&#39;s account.
              </span>
            </p>
            <p class="terms-condition-c0 c2">
              <span class="terms-condition-c1"></span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3 c6">Trackers</span>
            </p>
            <p class="terms-condition-c0">
              <span>An individual who utilizes the &quot;</span>
              <span class="terms-condition-c3">Track</span>
              <span>
                &quot; feature on Litzapp to monitor the publishing activity of
                another user on the Platform shall be referred to as a &quot;
              </span>
              <span class="terms-condition-c3">Tracker</span>
              <span class="terms-condition-c1">
                .&quot; Litzapp does not exercise control over the decisions of
                users to track or untrack other accounts on the platform.
              </span>
            </p>
            <p class="terms-condition-c0 c2">
              <span class="terms-condition-c1"></span>
            </p>
            <p class="terms-condition-c0">
              <span class="c6 terms-condition-c3">
                Phone Number Verification
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Any telephone number provided for verification of a user&#39;s
                account must be the property of the user. In the event that
                Litzapp determines that the telephone number provided does not
                belong to the user, the user&#39;s account may be subject to
                deactivation.
              </span>
            </p>
            <p class="terms-condition-c0 c2">
              <span class="terms-condition-c1"></span>
            </p>
            <p class="terms-condition-c0">
              <span class="c6 terms-condition-c3">Content View Count</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                The Platform cannot provide a guarantee of the accuracy of the
                view count for any content published on the platform. However,
                Litzapp shall make reasonable efforts to ensure that the view
                count is as accurate as practicable, given the limitations of
                the platform&#39;s technology and expertise.
              </span>
            </p>
            <p class="terms-condition-c0 c2">
              <span class="terms-condition-c1"></span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">Cancellation/Refund Policy</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Given the inherent nature of the Internet, Litzapp cannot
                provide any warranties regarding the uninterrupted and
                continuous availability and accessibility of the platform.
                Litzapp reserves the right to limit the availability of the
                platform or specific sections thereof, when necessary due to
                capacity constraints, server security or integrity
                considerations, or for the purpose of conducting maintenance
                activities aimed at ensuring the proper functioning of the
                platform.
              </span>
            </p>
            <p class="terms-condition-c0 c2">
              <span class="terms-condition-c1"></span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Litzapp does not offer any refunds for purchases or
                subscriptions made on the Platform. Purchasers are advised to
                exercise due diligence and carefully consider their decision
                before purchasing content or subscribing to a Publisher&#39;s
                account.
              </span>
            </p>
            <p class="terms-condition-c0 c2">
              <span class="terms-condition-c1"></span>
            </p>
            <p class="terms-condition-c0">
              <span class="c6 terms-condition-c3">Account Deactivation</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Litzapp reserves the right to terminate any account on the
                platform without prior notice and without providing any
                explanation thereafter. In the event that Litzapp determines
                that a Publisher&#39;s account has published unauthorized
                content, the Publisher&#39;s account may be subject to
                deactivation.
              </span>
            </p>
            <p class="terms-condition-c0 c2">
              <span class="terms-condition-c1"></span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">
                Links to Third Party Sites/Third Party Services
              </span>
            </p>
            <p class="terms-condition-c0">
              <span>
                Litzapp may contain links to other websites or apps (&quot;
              </span>
              <span class="terms-condition-c3">Linked Sites</span>
              <span class="terms-condition-c1">
                &quot;). The Linked Sites are not under the control of Litzapp
                and Litzapp is not responsible for the contents of any Linked
                Site, including without limitation any link contained in a
                Linked Site, or any changes or updates to a Linked Site. Litzapp
                is providing these links to you only as a convenience, and the
                inclusion of any link does not imply endorsement by Litzapp of
                the Platform or any association with its operators.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Certain services made available via Litzapp are delivered by
                third-party sites and organizations. By using any product,
                service or functionality originating from the Litzapp domain,
                you hereby acknowledge and consent that Litzapp may share such
                information and data with any third party with whom Litzapp has
                a contractual relationship to provide the requested product,
                service or functionality on behalf of Litzapp users and
                customers.
              </span>
            </p>
            <p class="c5">
              <span>
                Currently, the sole payment processor and payout processor
                (&ldquo;
              </span>
              <span class="terms-condition-c3">Processor</span>
              <span>
                &rdquo;) on the Litzapp Platform is Stripe. Stripe, Inc. is
                organized under the laws of Delaware, which is a technical
                services provider and may offer the services as an agent of one
                or more financial institutions in the United States (each, a
                &ldquo;
              </span>
              <span class="terms-condition-c3">
                Financial Services Provider
              </span>
              <span>
                &rdquo;). The processing and settlement of Transactions (&ldquo;
              </span>
              <span class="terms-condition-c3">Payment Processing</span>
              <span>
                &rdquo;) are carried out by the Processor and any of the
                Financial Services Providers under a separate Stripe Checkout
                User Terms of Service and/or Stripe Connected Account Agreement,
                including the United States Stripe Services Agreement and the
                applicable Financial Services Terms, and to the extent you use a
                payment and/or payout method that is subject to additional terms
                (collectively, the &ldquo;
              </span>
              <span class="terms-condition-c3">Processor Terms</span>
              <span>
                &rdquo;). By accepting this Agreement, you are also accepting
                and agreeing to be bound by the Processor Terms, which is the
                legal agreement between you and the Processor.
              </span>
            </p>
            <p class="terms-condition-c0 c2">
              <span class="terms-condition-c1"></span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">
                No Unlawful or Prohibited Use/Intellectual Property{" "}
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                You are granted a non-exclusive, non-transferable, revocable
                license to access and use Litzapp strictly in accordance with
                these terms of use. As a condition of your use of the Platform,
                you warrant to Litzapp that you will not use the Platform for
                any purpose that is unlawful or prohibited by these Terms. You
                may not use the Platform in any manner which could damage,
                disable, overburden, or impair the Platform or interfere with
                any other party&#39;s use and enjoyment of the Platform. You may
                not obtain or attempt to obtain any materials or information
                through any means not intentionally made available or provided
                for through the Platform.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                All content included as part of the Service, such as text,
                graphics, logos, images, as well as the compilation thereof, and
                any software used on the Platform, is the property of Litzapp or
                its suppliers and protected by copyright and other laws that
                protect intellectual property and proprietary rights. The
                concept of allowing users to view and read books for free while
                placing advertisements in between the pages is the exclusive
                intellectual property of Litzapp. Also, the concept of the Save
                Page Position functionality that allows users to access Saved
                Pages&ndash;via a link&ndash;only on the browser and/or device
                through which the page was saved, is the exclusive intellectual
                property of Litzapp. You agree to observe and abide by all
                copyright and other proprietary notices, legends or other
                restrictions contained in any such content and will not make any
                changes thereto.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                You will not modify, publish, transmit, reverse engineer,
                participate in the transfer or sale, create derivative works, or
                in any way exploit&nbsp;in whole or in part any of the content
                found on the Platform or the Litzapp platform itself. Litzapp
                content is not for resale. Your use of the Platform does not
                entitle you to make any unauthorized use of any protected
                content, and in particular you will not delete or alter any
                proprietary rights or attribution notices in any content. You
                will use protected content solely for your personal use, and
                will make no other use of the content without the express
                written permission of Litzapp and the copyright owner. You agree
                that you do not acquire any ownership rights in any protected
                content. We do not grant you any licenses, express or implied,
                to the intellectual property of Litzapp or our licensors except
                as expressly authorized by these Terms.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">
                Use of Communication Services
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                The Platform may contain bulletin board services, chat areas,
                news groups, forums, communities, personal web pages, calendars,
                and/or other message or communication facilities designed to
                enable you to communicate with the public at large or with a
                group (collectively, &quot;Communication Services&quot;). You
                agree to use the Communication Services only to post, send and
                receive messages and material that are proper and related to the
                particular Communication Service.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                By way of example, and not as a limitation, you agree that when
                using a Communication Service, you will not: defame, abuse,
                harass, stalk, threaten or otherwise violate the legal rights
                (such as rights of privacy and publicity) of others; publish,
                post, upload, distribute or disseminate any inappropriate,
                profane, defamatory, infringing, obscene, indecent or unlawful
                topic, name, material or information; upload files that contain
                software or other material protected by intellectual property
                laws (or by rights of privacy of publicity) unless you own or
                control the rights thereto or have received all necessary
                consents; upload files that contain viruses, corrupted files, or
                any other similar software or programs that may damage the
                operation of another&#39;s computer; advertise or offer to sell
                or buy any goods or services for any business purpose, unless
                such Communication Service specifically allows such messages;
                conduct or forward surveys, contests, pyramid schemes or chain
                letters; download any file posted by another user of a
                Communication Service that you know, or reasonably should know,
                cannot be legally distributed in such manner; falsify or delete
                any author attributions, legal or other proper notices or
                proprietary designations or labels of the origin or source of
                software or other material contained in a file that is uploaded;
                restrict or inhibit any other user from using and enjoying the
                Communication Services; violate any code of conduct or other
                guidelines which may be applicable for any particular
                Communication Service; harvest or otherwise collect information
                about others, including e-mail addresses, without their consent;
                violate any applicable laws or regulations.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Litzapp has no obligation to monitor the Communication Services.
                However, Litzapp reserves the right to review materials posted
                to a Communication Service and to remove any materials in its
                sole discretion. Litzapp reserves the right to terminate your
                access to any or all of the Communication Services at any time
                without notice for any reason whatsoever.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Litzapp reserves the right at all times to disclose any
                information as necessary to satisfy any applicable law,
                regulation, legal process or governmental request, or to edit,
                refuse to post or to remove any information or materials, in
                whole or in part, in Litzapp&#39;s sole discretion.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Always use caution when giving out any personally identifying
                information about yourself or your children in any Communication
                Service. Litzapp does not control or endorse the content,
                messages or information found in any Communication Service and,
                therefore, Litzapp specifically disclaims any liability with
                regard to the Communication Services and any actions resulting
                from your participation in any Communication Service. Managers
                and hosts are not authorized Litzapp spokespersons, and their
                views do not necessarily reflect those of Litzapp.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Materials uploaded to a Communication Service may be subject to
                posted limitations on usage, reproduction and/or dissemination.
                You are responsible for adhering to such limitations if you
                upload the materials.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">
                Materials Provided to Litzapp or Posted on Any Litzapp Web Page
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Litzapp does not claim ownership of the materials you provide to
                Litzapp (including feedback and suggestions) or post, upload,
                input or submit to any Litzapp Site or our associated services
                (collectively &quot;Submissions&quot;). However, by posting,
                uploading, inputting, providing or submitting your Submission
                you are granting Litzapp, our affiliated companies and necessary
                sublicensees permission to use your Submission in connection
                with the operation of their Internet businesses including,
                without limitation, the rights to: copy, distribute, transmit,
                publicly display, publicly perform, reproduce, edit, translate
                and reformat your Submission; and to publish your name in
                connection with your Submission.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                No compensation will be paid with respect to the use of your
                Submission, as provided herein. Litzapp is under no obligation
                to post or use any Submission you may provide and may remove any
                Submission at any time in Litzapp&#39;s sole discretion.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                By posting, uploading, inputting, providing or submitting your
                Submission you warrant and represent that you own or otherwise
                control all of the rights to your Submission as described in
                this section including, without limitation, all the rights
                necessary for you to provide, post, upload, input or submit the
                Submissions.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">Third Party Accounts</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                You will be able to connect your Litzapp account to third party
                accounts. By connecting your Litzapp account to your third party
                account, you acknowledge and agree that you are consenting to
                the continuous release of information about you to others (in
                accordance with your privacy settings on those third party
                sites). If you do not want information about you to be shared in
                this manner, do not use this feature.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">International Users</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                The Service is controlled, operated and administered by Litzapp
                from our offices within the USA. If you access the Service from
                a location outside the USA, you are responsible for compliance
                with all local laws. You agree that you will not use the Litzapp
                Content accessed through Litzapp in any country or in any manner
                prohibited by any applicable laws, restrictions or regulations.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">Indemnification</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                You agree to indemnify, defend and hold harmless Litzapp, its
                officers, directors, employees, agents and third parties, for
                any losses, costs, liabilities and expenses (including
                reasonable attorney&#39;s fees) relating to or arising out of
                your use of or inability to use the Platform or services, any
                user postings made by you, your violation of any terms of this
                Agreement or your violation of any rights of a third party, or
                your violation of any applicable laws, rules or regulations.
                Litzapp reserves the right, at its own cost, to assume the
                exclusive defense and control of any matter otherwise subject to
                indemnification by you, in which event you will fully cooperate
                with Litzapp in asserting any available defenses.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">Arbitration</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                In the event the parties are not able to resolve any dispute
                between them arising out of or concerning these Terms and
                Conditions, or any provisions hereof, whether in contract, tort,
                or otherwise at law or in equity for damages or any other
                relief, then such dispute shall be resolved only by final and
                binding arbitration pursuant to the Federal Arbitration Act,
                conducted by a single neutral arbitrator and administered by the
                American Arbitration Association, or a similar arbitration
                service selected by the parties, in a location mutually agreed
                upon by the parties. The arbitrator&#39;s award shall be final,
                and judgment may be entered upon it in any court having
                jurisdiction. In the event that any legal or equitable action,
                proceeding or arbitration arises out of or concerns these Terms
                and Conditions, the prevailing party shall be entitled to
                recover its costs and reasonable attorney&#39;s fees. The
                parties agree to arbitrate all disputes and claims in regards to
                these Terms and Conditions or any disputes arising as a result
                of these Terms and Conditions, whether directly or indirectly,
                including Tort claims that are a result of these Terms and
                Conditions. The parties agree that the Federal Arbitration Act
                governs the interpretation and enforcement of this provision.
                The entire dispute, including the scope and enforceability of
                this arbitration provision shall be determined by the
                Arbitrator. This arbitration provision shall survive the
                termination of these Terms and Conditions.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">Class Action Waiver</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Any arbitration under these Terms and Conditions will take place
                on an individual basis; class arbitrations and
                class/representative/collective actions are not permitted. THE
                PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER
                ONLY IN EACH&#39;S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF
                OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR
                REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE
                ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both
                you and Litzapp agree otherwise, the arbitrator may not
                consolidate more than one person&#39;s claims, and may not
                otherwise preside over any form of a representative or class
                proceeding.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">Liability Disclaimer</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
                AVAILABLE THROUGH the Platform MAY INCLUDE INACCURACIES OR
                TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
                INFORMATION HEREIN. Remote Analytics Jobs Inc. AND/OR ITS
                SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN the Platform
                AT ANY TIME.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Remote Analytics Jobs Inc. AND/OR ITS SUPPLIERS MAKE NO
                REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY,
                AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION,
                SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON
                the Platform FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY
                APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS,
                SERVICES AND RELATED GRAPHICS ARE PROVIDED &quot;AS IS&quot;
                WITHOUT WARRANTY OR CONDITION OF ANY KIND. Remote Analytics Jobs
                Inc. AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND
                CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS,
                SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES
                OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, TITLE AND NON-INFRINGEMENT.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                SHALL Remote Analytics Jobs Inc. AND/OR ITS SUPPLIERS BE LIABLE
                FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
                CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING,
                WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS,
                ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR
                PERFORMANCE OF the Platform, WITH THE DELAY OR INABILITY TO USE
                the Platform OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO
                PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS,
                SERVICES AND RELATED GRAPHICS OBTAINED THROUGH the Platform, OR
                OTHERWISE ARISING OUT OF THE USE OF the Platform, WHETHER BASED
                ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE,
                EVEN IF Remote Analytics Jobs Inc. OR ANY OF ITS SUPPLIERS HAS
                BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME
                STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE
                LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH
                ANY PORTION OF the Platform, OR WITH ANY OF THESE TERMS OF USE,
                YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING the
                Platform.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">
                Termination/Access Restriction{" "}
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Litzapp reserves the right, in its sole discretion, to terminate
                your access to the Platform and the related services or any
                portion thereof at any time, without notice. To the maximum
                extent permitted by law, this agreement is governed by the laws
                of the State of Florida and you hereby consent to the exclusive
                jurisdiction and venue of courts in Florida in all disputes
                arising out of or relating to the use of the Platform. Use of
                the Platform is unauthorized in any jurisdiction that does not
                give effect to all provisions of these Terms, including, without
                limitation, this section.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                You agree that no joint venture, partnership, employment, or
                agency relationship exists between you and Litzapp as a result
                of this agreement or use of the Platform. Litzapp&#39;s
                performance of this agreement is subject to existing laws and
                legal process, and nothing contained in this agreement is in
                derogation of Litzapp&#39;s right to comply with governmental,
                court and law enforcement requests or requirements relating to
                your use of the Platform or information provided to or gathered
                by Litzapp with respect to such use. If any part of this
                agreement is determined to be invalid or unenforceable pursuant
                to applicable law including, but not limited to, the warranty
                disclaimers and liability limitations set forth above, then the
                invalid or unenforceable provision will be deemed superseded by
                a valid, enforceable provision that most closely matches the
                intent of the original provision and the remainder of the
                agreement shall continue in effect.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Unless otherwise specified herein, this agreement constitutes
                the entire agreement between the user and Litzapp with respect
                to the Platform and it supersedes all prior or contemporaneous
                communications and proposals, whether electronic, oral or
                written, between the user and Litzapp with respect to the
                Platform. A printed version of this agreement and of any notice
                given in electronic form shall be admissible in judicial or
                administrative proceedings based upon or relating to this
                agreement to the same extent and subject to the same conditions
                as other business documents and records originally generated and
                maintained in printed form. It is the express wish of the
                parties that this agreement and all related documents be written
                in English.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">Changes to Terms</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Litzapp reserves the right, in its sole discretion, to change
                the Terms under which Litzapp is offered. The most current
                version of the Terms will supersede all previous versions.
                Litzapp encourages you to periodically review the Terms to stay
                informed of our updates.
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">&nbsp;</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c3">Contact Us</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Litzapp welcomes your questions or comments regarding the Terms:
              </span>
            </p>
            <br />
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">Remote Analytics Jobs Inc.</span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                126 Southwest 17th Road, Unit 509
              </span>
            </p>
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">Miami, Florida 33129</span>
            </p>
            <br />
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">Email Address:</span>
            </p>
            <p class="terms-condition-c0">
              <span class="c4">
                <a
                  class="terms-condition-c10 text-blue-900 underline"
                  href="mailto:customerservices@spewhub.com"
                >
                  customerservices@Litzapp.com
                </a>
              </span>
            </p>
            <br />
            <p class="terms-condition-c0">
              <span class="terms-condition-c1">
                Effective as of February 11, 2023
              </span>
            </p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
    </div>
  );
}
