import axios from "axios";
import FileUploaderBox from "./file-uploader";
import SocialsUploader from "./socials";
import UploadOptions from "./upload-options";
import uuid from "react-uuid";
import {
  isResponsiveMode,
  authUserData,
} from "../../../../../../Utils/helpers";
import { useCallback, useEffect, useState } from "react";

export default function AccordionMedia(props) {
  const {
    parentIndex,
    accordions,
    saveAccordionImages,
    addYoutubeToAccordion,
    addSocialToAccordion,
    accordionSectionKey,
    accordionId,
    processing,
  } = props;

  const [mediaType, setMediaType] = useState("");
  const [accordionSocialValue, setAccordionSocialValue] = useState("");
  const [videoLinkValue, setVideoLinkValue] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);

  const handleAccordionSocialValueChange = (e) => {
    setAccordionSocialValue(e);
  };

  const resetAccordionVideoPreview = () => {};
  const resetAccordionVideoLinkPreview = () => {};
  const handleMediaTypeChange = (e) => {
    setMediaType(e);
  };
  const handleImageUpload = (files) => {
    const f = [...files];
    const data = f.map((f) => ({ id: uuid(), file: f }));
    setSelectedImages((prev) => [...prev, ...data]);
  };
  const insertYoutubeVideo = () => {
    addYoutubeToAccordion(videoLinkValue, parentIndex);
  };
  const insertSocial = () => {
    addSocialToAccordion(mediaType, accordionSocialValue, parentIndex);
  };
  const uploadImages = () => {};

  // Video Section
  const handleVideoLinkChange = (e) => {
    setVideoLinkValue(e);
  };

  const resetVideoPreview = () => {
    setVideoLinkValue("");
  };
  const resetVideoLinkPreview = () => {
    setVideoLinkValue("");
  };

  const deleteMedia = (i) => {
    const items = selectedImages;
    const slides = items.filter((item, index) => index !== i);
    setSelectedImages(slides);
  };
  useEffect(() => {
    resetAccordionVideoPreview();
    resetAccordionVideoLinkPreview();
    resetVideoPreview();
    setSelectedImages([]);
  }, [mediaType]);

  return (
    <div className="my-2 w-full flex flex-col items-center justify-center p-2">
      <UploadOptions
        mediaType={mediaType}
        handleMediaTypeChange={handleMediaTypeChange}
      />
      <FileUploaderBox
        parentIndex={parentIndex}
        accordions={accordions}
        uploadImages={uploadImages}
        mediaType={mediaType}
        deleteMedia={deleteMedia}
        selectedImages={selectedImages}
        handleImageUpload={handleImageUpload}
        saveAccordionImages={saveAccordionImages}
        accordionSectionKey={accordionSectionKey}
        accordionId={accordionId}
        processing={processing}
      />
      <SocialsUploader
        mediaType={mediaType}
        handleAccordionSocialValueChange={handleAccordionSocialValueChange}
        accordionSocialValue={accordionSocialValue}
        handleVideoLinkChange={handleVideoLinkChange}
        resetVideoPreview={resetVideoPreview}
        resetVideoLinkPreview={resetVideoLinkPreview}
        videoLinkValue={videoLinkValue}
        insertYoutubeVideo={insertYoutubeVideo}
        insertSocial={insertSocial}
        accordionId={accordionId}
      />
    </div>
  );
}
