export const countryList = [
  {
    country: "AFGHANISTAN",
    country_code: "AF",
  },
  {
    country: "ALBANIA",
    country_code: "AL",
  },
  {
    country: "ALGERIA",
    country_code: "DZ",
  },
  {
    country: "AMERICAN SAMOA",
    country_code: "AS",
  },
  {
    country: "ANDORRA",
    country_code: "AD",
  },
  {
    country: "ANGOLA",
    country_code: "AO",
  },
  {
    country: "ANTARCTICA",
    country_code: "AQ",
  },
  {
    country: "ANTIGUA AND BARBUDA",
    country_code: "AG",
  },
  {
    country: "ARGENTINA",
    country_code: "AR",
  },
  {
    country: "ARMENIA",
    country_code: "AM",
  },
  {
    country: "ARUBA",
    country_code: "AW",
  },
  {
    country: "AUSTRALIA",
    country_code: "AU",
  },
  {
    country: "AUSTRIA",
    country_code: "AT",
  },
  {
    country: "AZERBAIJAN",
    country_code: "AZ",
  },
  {
    country: "BAHAMAS",
    country_code: "BS",
  },
  {
    country: "BAHRAIN",
    country_code: "BH",
  },
  {
    country: "BANGLADESH",
    country_code: "BD",
  },
  {
    country: "BARBADOS",
    country_code: "BB",
  },
  {
    country: "BELARUS",
    country_code: "BY",
  },
  {
    country: "BELGIUM",
    country_code: "BE",
  },
  {
    country: "BELIZE",
    country_code: "BZ",
  },
  {
    country: "BENIN",
    country_code: "BJ",
  },
  {
    country: "BERMUDA",
    country_code: "BM",
  },
  {
    country: "BHUTAN",
    country_code: "BT",
  },
  {
    country: "BOLIVIA",
    country_code: "BO",
  },
  {
    country: "BOSNIA AND HERZEGOVINA",
    country_code: "BA",
  },
  {
    country: "BOTSWANA",
    country_code: "BW",
  },
  {
    country: "BOUVET ISLAND",
    country_code: "BV",
  },
  {
    country: "BRAZIL",
    country_code: "BR",
  },
  {
    country: "BRITISH INDIAN OCEAN TERRITORY",
    country_code: "IO",
  },
  {
    country: "BRUNEI DARUSSALAM",
    country_code: "BN",
  },
  {
    country: "BULGARIA",
    country_code: "BG",
  },
  {
    country: "BURKINA FASO",
    country_code: "BF",
  },
  {
    country: "BURUNDI",
    country_code: "BI",
  },
  {
    country: "CAMBODIA",
    country_code: "KH",
  },
  {
    country: "CAMEROON",
    country_code: "CM",
  },
  {
    country: "CANADA",
    country_code: "CA",
  },
  {
    country: "CAPE VERDE",
    country_code: "CV",
  },
  {
    country: "CAYMAN ISLANDS",
    country_code: "KY",
  },
  {
    country: "CENTRAL AFRICAN REPUBLIC",
    country_code: "CF",
  },
  {
    country: "CHAD",
    country_code: "TD",
  },
  {
    country: "CHILE",
    country_code: "CL",
  },
  {
    country: "CHINA",
    country_code: "CN",
  },
  {
    country: "CHRISTMAS ISLAND",
    country_code: "CX",
  },
  {
    country: "COCOS (KEELING) ISLANDS",
    country_code: "CC",
  },
  {
    country: "COLOMBIA",
    country_code: "CO",
  },
  {
    country: "COMOROS",
    country_code: "KM",
  },
  {
    country: "CONGO",
    country_code: "CG",
  },
  {
    country: "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
    country_code: "CD",
  },
  {
    country: "COOK ISLANDS",
    country_code: "CK",
  },
  {
    country: "COSTA RICA",
    country_code: "CR",
  },
  {
    country: "CÔTE D'IVOIRE",
    country_code: "CI",
  },
  {
    country: "CROATIA",
    country_code: "HR",
  },
  {
    country: "CUBA",
    country_code: "CU",
  },
  {
    country: "CYPRUS",
    country_code: "CY",
  },
  {
    country: "CZECH REPUBLIC",
    country_code: "CZ",
  },
  {
    country: "DENMARK",
    country_code: "DK",
  },
  {
    country: "DJIBOUTI",
    country_code: "DJ",
  },
  {
    country: "DOMINICA",
    country_code: "DM",
  },
  {
    country: "DOMINICAN REPUBLIC",
    country_code: "DO",
  },
  {
    country: "ECUADOR",
    country_code: "EC",
  },
  {
    country: "EGYPT",
    country_code: "EG",
  },
  {
    country: "EL SALVADOR",
    country_code: "SV",
  },
  {
    country: "EQUATORIAL GUINEA",
    country_code: "GQ",
  },
  {
    country: "ERITREA",
    country_code: "ER",
  },
  {
    country: "ESTONIA",
    country_code: "EE",
  },
  {
    country: "ETHIOPIA",
    country_code: "ET",
  },
  {
    country: "FALKLAND ISLANDS (MALVINAS)",
    country_code: "FK",
  },
  {
    country: "FAROE ISLANDS",
    country_code: "FO",
  },
  {
    country: "FIJI",
    country_code: "FJ",
  },
  {
    country: "FINLAND",
    country_code: "FI",
  },
  {
    country: "FRANCE",
    country_code: "FR",
  },
  {
    country: "FRENCH GUIANA",
    country_code: "GF",
  },
  {
    country: "FRENCH POLYNESIA",
    country_code: "PF",
  },
  {
    country: "FRENCH SOUTHERN TERRITORIES",
    country_code: "TF",
  },
  {
    country: "GABON",
    country_code: "GA",
  },
  {
    country: "GAMBIA",
    country_code: "GM",
  },
  {
    country: "GEORGIA",
    country_code: "GE",
  },
  {
    country: "GERMANY",
    country_code: "DE",
  },
  {
    country: "GHANA",
    country_code: "GH",
  },
  {
    country: "GIBRALTAR",
    country_code: "GI",
  },
  {
    country: "GREECE",
    country_code: "GR",
  },
  {
    country: "GREENLAND",
    country_code: "GL",
  },
  {
    country: "GRENADA",
    country_code: "GD",
  },
  {
    country: "GUADELOUPE",
    country_code: "GP",
  },
  {
    country: "GUAM",
    country_code: "GU",
  },
  {
    country: "GUATEMALA",
    country_code: "GT",
  },
  {
    country: "GUINEA",
    country_code: "GN",
  },
  {
    country: "GUINEA-BISSAU",
    country_code: "GW",
  },
  {
    country: "GUYANA",
    country_code: "GY",
  },
  {
    country: "HAITI",
    country_code: "HT",
  },
  {
    country: "HEARD ISLAND AND MCDONALD ISLANDS",
    country_code: "HM",
  },
  {
    country: "HONDURAS",
    country_code: "HN",
  },
  {
    country: "HONG KONG",
    country_code: "HK",
  },
  {
    country: "HUNGARY",
    country_code: "HU",
  },
  {
    country: "ICELAND",
    country_code: "IS",
  },
  {
    country: "INDIA",
    country_code: "IN",
  },
  {
    country: "INDONESIA",
    country_code: "ID",
  },
  {
    country: "IRAN, ISLAMIC REPUBLIC OF",
    country_code: "IR",
  },
  {
    country: "IRAQ",
    country_code: "IQ",
  },
  {
    country: "IRELAND",
    country_code: "IE",
  },
  {
    country: "ISRAEL",
    country_code: "IL",
  },
  {
    country: "ITALY",
    country_code: "IT",
  },
  {
    country: "JAMAICA",
    country_code: "JM",
  },
  {
    country: "JAPAN",
    country_code: "JP",
  },
  {
    country: "JORDAN",
    country_code: "JO",
  },
  {
    country: "KAZAKHSTAN",
    country_code: "KZ",
  },
  {
    country: "KENYA",
    country_code: "KE",
  },
  {
    country: "KIRIBATI",
    country_code: "KI",
  },
  {
    country: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
    country_code: "KP",
  },
  {
    country: "KOREA, REPUBLIC OF",
    country_code: "KR",
  },
  {
    country: "KUWAIT",
    country_code: "KW",
  },
  {
    country: "KYRGYZSTAN",
    country_code: "KG",
  },
  {
    country: "LAO PEOPLE'S DEMOCRATIC REPUBLIC (LAOS)",
    country_code: "LA",
  },
  {
    country: "LATVIA",
    country_code: "LV",
  },
  {
    country: "LEBANON",
    country_code: "LB",
  },
  {
    country: "LESOTHO",
    country_code: "LS",
  },
  {
    country: "LIBERIA",
    country_code: "LR",
  },
  {
    country: "LIBYA, STATE OF",
    country_code: "LY",
  },
  {
    country: "LIECHTENSTEIN",
    country_code: "LI",
  },
  {
    country: "LITHUANIA",
    country_code: "LT",
  },
  {
    country: "LUXEMBOURG",
    country_code: "LU",
  },
  {
    country: "MACAO",
    country_code: "MO",
  },
  {
    country: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
    country_code: "MK",
  },
  {
    country: "MADAGASCAR",
    country_code: "MG",
  },
  {
    country: "MALAWI",
    country_code: "MW",
  },
  {
    country: "MALAYSIA",
    country_code: "MY",
  },
  {
    country: "MALDIVES",
    country_code: "MV",
  },
  {
    country: "MALI",
    country_code: "ML",
  },
  {
    country: "MALTA",
    country_code: "MT",
  },
  {
    country: "MARSHALL ISLANDS",
    country_code: "MH",
  },
  {
    country: "MARTINIQUE",
    country_code: "MQ",
  },
  {
    country: "MAURITANIA",
    country_code: "MR",
  },
  {
    country: "MAURITIUS",
    country_code: "MU",
  },
  {
    country: "MAYOTTE",
    country_code: "YT",
  },
  {
    country: "MEXICO",
    country_code: "MX",
  },
  {
    country: "MICRONESIA, FEDERATED STATES OF",
    country_code: "FM",
  },
  {
    country: "MOLDOVA, REPUBLIC OF",
    country_code: "MD",
  },
  {
    country: "MONACO",
    country_code: "MC",
  },
  {
    country: "MONGOLIA",
    country_code: "MN",
  },
  {
    country: "MONTENEGRO",
    country_code: "ME",
  },
  {
    country: "MONTSERRAT",
    country_code: "MS",
  },
  {
    country: "MOROCCO",
    country_code: "MA",
  },
  {
    country: "MOZAMBIQUE",
    country_code: "MZ",
  },
  {
    country: "MYANMAR",
    country_code: "MM",
  },
  {
    country: "NAMIBIA",
    country_code: "NA",
  },
  {
    country: "NAURU",
    country_code: "NR",
  },
  {
    country: "NEPAL, FEDERAL DEMOCRATIC REPUBLIC OF",
    country_code: "NP",
  },
  {
    country: "NETHERLANDS",
    country_code: "NL",
  },
  {
    country: "NETHERLANDS ANTILLES",
    country_code: "AN",
  },
  {
    country: "NEW CALEDONIA",
    country_code: "NC",
  },
  {
    country: "NEW ZEALAND",
    country_code: "NZ",
  },
  {
    country: "NICARAGUA",
    country_code: "NI",
  },
  {
    country: "NIGER",
    country_code: "NE",
  },
  {
    country: "NIGERIA",
    country_code: "NG",
  },
  {
    country: "NIUE",
    country_code: "NU",
  },
  {
    country: "NORFOLK ISLAND",
    country_code: "NF",
  },
  {
    country: "NORTHERN MARIANA ISLANDS",
    country_code: "MP",
  },
  {
    country: "NORWAY",
    country_code: "NO",
  },
  {
    country: "OMAN",
    country_code: "OM",
  },
  {
    country: "PAKISTAN",
    country_code: "PK",
  },
  {
    country: "PALAU",
    country_code: "PW",
  },
  {
    country: "PALESTINE, STATE OF",
    country_code: "PS",
  },
  {
    country: "PANAMA",
    country_code: "PA",
  },
  {
    country: "PAPUA NEW GUINEA",
    country_code: "PG",
  },
  {
    country: "PARAGUAY",
    country_code: "PY",
  },
  {
    country: "PERU",
    country_code: "PE",
  },
  {
    country: "PHILIPPINES",
    country_code: "PH",
  },
  {
    country: "PITCAIRN",
    country_code: "PN",
  },
  {
    country: "POLAND",
    country_code: "PL",
  },
  {
    country: "PORTUGAL",
    country_code: "PT",
  },
  {
    country: "PUERTO RICO",
    country_code: "PR",
  },
  {
    country: "QATAR",
    country_code: "QA",
  },
  {
    country: "RÉUNION",
    country_code: "RE",
  },
  {
    country: "ROMANIA",
    country_code: "RO",
  },
  {
    country: "RUSSIAN FEDERATION",
    country_code: "RU",
  },
  {
    country: "RWANDA",
    country_code: "RW",
  },
  {
    country: "SAINT HELENA",
    country_code: "SH",
  },
  {
    country: "SAINT KITTS AND NEVIS",
    country_code: "KN",
  },
  {
    country: "SAINT LUCIA",
    country_code: "LC",
  },
  {
    country: "SAINT PIERRE AND MIQUELON",
    country_code: "PM",
  },
  {
    country: "SAINT VINCENT AND THE GRENADINES",
    country_code: "VC",
  },
  {
    country: "SAMOA",
    country_code: "WS",
  },
  {
    country: "SAN MARINO",
    country_code: "SM",
  },
  {
    country: "SAO TOME AND PRINCIPE",
    country_code: "ST",
  },
  {
    country: "SAUDI ARABIA",
    country_code: "SA",
  },
  {
    country: "SENEGAL",
    country_code: "SN",
  },
  {
    country: "SERBIA",
    country_code: "RS",
  },
  {
    country: "SEYCHELLES",
    country_code: "SC",
  },
  {
    country: "SIERRA LEONE",
    country_code: "SL",
  },
  {
    country: "SINGAPORE",
    country_code: "SG",
  },
  {
    country: "SLOVAKIA",
    country_code: "SK",
  },
  {
    country: "SLOVENIA",
    country_code: "SI",
  },
  {
    country: "SOLOMON ISLANDS",
    country_code: "SB",
  },
  {
    country: "SOMALIA",
    country_code: "SO",
  },
  {
    country: "SOUTH AFRICA",
    country_code: "ZA",
  },
  {
    country: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
    country_code: "GS",
  },
  {
    country: "SOUTH SUDAN",
    country_code: "SS",
  },
  {
    country: "SPAIN",
    country_code: "ES",
  },
  {
    country: "SRI LANKA",
    country_code: "LK",
  },
  {
    country: "SUDAN",
    country_code: "SD",
  },
  {
    country: "SURINAME",
    country_code: "SR",
  },
  {
    country: "SVALBARD AND JAN MAYEN",
    country_code: "SJ",
  },
  {
    country: "SWAZILAND",
    country_code: "SZ",
  },
  {
    country: "SWEDEN",
    country_code: "SE",
  },
  {
    country: "SWITZERLAND",
    country_code: "CH",
  },
  {
    country: "SYRIAN ARAB REPUBLIC",
    country_code: "SY",
  },
  {
    country: "TAIWAN",
    country_code: "TW",
  },
  {
    country: "TAJIKISTAN",
    country_code: "TJ",
  },
  {
    country: "TANZANIA, UNITED REPUBLIC OF",
    country_code: "TZ",
  },
  {
    country: "THAILAND",
    country_code: "TH",
  },
  {
    country: "TIMOR-LESTE",
    country_code: "TL",
  },
  {
    country: "TOGO",
    country_code: "TG",
  },
  {
    country: "TOKELAU",
    country_code: "TK",
  },
  {
    country: "TONGA",
    country_code: "TO",
  },
  {
    country: "TRINIDAD AND TOBAGO",
    country_code: "TT",
  },
  {
    country: "TUNISIA",
    country_code: "TN",
  },
  {
    country: "TURKEY",
    country_code: "TR",
  },
  {
    country: "TURKMENISTAN",
    country_code: "TM",
  },
  {
    country: "TURKS AND CAICOS ISLANDS",
    country_code: "TC",
  },
  {
    country: "TUVALU",
    country_code: "TV",
  },
  {
    country: "UGANDA",
    country_code: "UG",
  },
  {
    country: "UKRAINE",
    country_code: "UA",
  },
  {
    country: "UNITED ARAB EMIRATES",
    country_code: "AE",
  },
  {
    country: "UNITED KINGDOM",
    country_code: "GB",
  },
  {
    country: "UNITED STATES",
    country_code: "US",
  },
  {
    country: "UNITED STATES MINOR OUTLYING ISLANDS",
    country_code: "UM",
  },
  {
    country: "URUGUAY",
    country_code: "UY",
  },
  {
    country: "UZBEKISTAN",
    country_code: "UZ",
  },
  {
    country: "VANUATU",
    country_code: "VU",
  },
  {
    country: "VENEZUELA",
    country_code: "VE",
  },
  {
    country: "VIET NAM",
    country_code: "VN",
  },
  {
    country: "VIRGIN ISLANDS, BRITISH",
    country_code: "VG",
  },
  {
    country: "VIRGIN ISLANDS, U.S.",
    country_code: "VI",
  },
  {
    country: "WALLIS AND FUTUNA",
    country_code: "WF",
  },
  {
    country: "WESTERN SAHARA",
    country_code: "EH",
  },
  {
    country: "YEMEN",
    country_code: "YE",
  },
  {
    country: "ZAMBIA",
    country_code: "ZM",
  },
  {
    country: "ZIMBABWE",
    country_code: "ZW",
  },
];
