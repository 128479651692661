import { BASE_URL } from "../../../../Utils/helpers";
export default function RenderProfilePhotoForPhotoAlbum(props) {
  const { book } = props;
  if (book.owneroptimized?.profile_photo) {
    return (
      <img
        className="absolute right-6 h-24 w-24 md:h-36 md:w-36 my-1 border-4 border-white rounded-full bg-gray-300 z-2 object-cover"
        onClick={() => null}
        src={
          BASE_URL +
          "/storage/" +
          book.owneroptimized.profile_photo.split("public")[1]
        }
      />
    );
  } else {
    return (
      <img
        className="absolute right-6  h-24 w-24 md:h-36 md:w-36 my-1 border-4 border-white rounded-full bg-gray-50 z-2 object-cover"
        onClick={() => null}
        src={"/avatar.png"}
      />
    );
  }
}
