import StripeDatathresholdPayElement from "./stripe-datathreshold-pay-element";
import { Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { STRIPE_PK } from "../Utils/helpers";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PK);

export default function StripeDatathresholdElementWrapper({
  dataThreshold,
  secretKey,
}) {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: secretKey,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeDatathresholdPayElement dataThreshold={dataThreshold} />
    </Elements>
  );
}
