import moment from "moment";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

function CalendarDays(props) {
  const [clickedDateIndex, setClickedDateIndex] = useState("");
  const firstDayOfMonth = new Date(
    props.day.getFullYear(),
    props.day.getMonth(),
    1
  );
  // console.log("mth", props.day.getMonth());
  const weekdayOfFirstDay = firstDayOfMonth.getDay();
  let currentDays = [];

  for (let day = 0; day < 42; day++) {
    if (day === 0 && weekdayOfFirstDay === 0) {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 7);
    } else if (day === 0) {
      firstDayOfMonth.setDate(
        firstDayOfMonth.getDate() + (day - weekdayOfFirstDay)
      );
    } else {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
    }

    let calendarDay = {
      currentMonth: firstDayOfMonth.getMonth() === props.day.getMonth(),
      date: new Date(firstDayOfMonth),
      month: firstDayOfMonth.getMonth(),
      number: firstDayOfMonth.getDate(),
      selected: firstDayOfMonth.toDateString() === props.day.toDateString(),
      year: firstDayOfMonth.getFullYear(),
    };

    currentDays.push(calendarDay);
  }
  const isTodayOrOlder = (date, index) => {
    return moment(date.date).isSameOrAfter() || clickedDateIndex == index;
  };

  const isNotOlderThanMax = (date) => {
    // console.log("SOODDD", props.parentProps.maxDate);
    if (!props.parentProps.maxDate) return true;
    return moment.utc(date.date).isSameOrBefore(props.parentProps.maxDate);
  };

  const addDateColorClasses = (date) => {
    // const dateStamp = date.setHours(0, 0, 0, 0);
    const dateToCheck = moment(date.date).format("YYYY-MM-DD");
    const res = props.parentProps.bookings.filter(
      (d) => d.date_only === dateToCheck
    );

    if (res.length) {
      if (
        props.parentProps.meta.block_weekend_start &&
        (moment(date.date).format("dddd") == "Saturday" ||
          moment(date.date).format("dddd") == "Sunday")
      ) {
        // console.log(moment(date.date).format("dddd"));

        return "";
      }
      return res[0].color.toString() + " cursor-pointer ";
    }
    return "";
  };

  const convertToUTC = (date) => {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  };

  const canBookDate = (date, index) => {
    const dateToCheck = moment(date.date).format("YYYY-MM-DD");
    const res = props.parentProps.bookings.filter(
      (d) => d.date_only == dateToCheck
    );

    if (res.length) {
      if (
        props.parentProps.meta.block_weekend_start &&
        (moment(date.date).format("dddd") == "Saturday" ||
          moment(date.date).format("dddd") == "Sunday")
      ) {
        return toast("This date is blocked", {
          type: "error",
        });
      }

      if (res[0].code == "R") {
        return toast(res[0].message, {
          type: "error",
        });
      }
      if (res[0].code == "O") {
        return toast("Sucess", {
          type: "success",
        });
      }

      if (res[0].code == "Y") {
        props.parentProps.setSelected(convertToUTC(date.date), "short");
        setClickedDateIndex(index);
        props?.parentProps?.state?.checkAvailability(
          convertToUTC(date.date),
          "Y"
        );
        return false;
      }

      if (res[0].code == "G") {
        props.parentProps.setSelected(convertToUTC(date.date), "preferred");
        setClickedDateIndex(index);
        if (props?.parentProps?.meta?.short_durr_on_preferred_available) {
          props?.parentProps?.state?.checkAvailability(
            convertToUTC(date.date),
            "G"
          );
        }

        return false;
      }

      if (res[0].code == "GREY") {
        return toast(res[0].message, {
          type: "error",
        });
      }
    }
    return toast("This date is blocked", {
      type: "error",
    });
  };

  useEffect(() => {}, [props?.parentProps?.state]);
  useEffect(() => {
    setClickedDateIndex("");
    props.parentProps.setSelected("", "preferred");
  }, [props.day]);

  const startDate = props?.parentProps?.state?.productReplyMeta?.start_date;

  return (
    <div className="grid grid-cols-7 bg-white w-full">
      {currentDays.map((day, index) => {
        return (
          <div
            key={index}
            className={
              "p-1 calendar-day cursor-pointer flex justify-center" +
              (true
                ? ` current bold text-gray-300 font-bold ${addDateColorClasses(
                    day
                  )}`
                : "text-gray-100")
            }
            onClick={() => {
              canBookDate(day, index);

              {
                /* toast("This date is blocked", {
                type: "error",
              }); */
              }
            }}
          >
            <div
              className={`w-10 h-10 px-3.5 rounded-full ${
                startDate
                  ? index == clickedDateIndex
                    ? "border-green-500 border-2"
                    : ""
                  : ""
              }  flex justify-center items-center`}
            >
              <p>{day.number}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default CalendarDays;
