import { isResponsiveMode } from "../../Utils/helpers";
import { Document, Page, pdfjs } from "react-pdf";
export default function RenderRightAdPageMediaPreview(props) {
  const {
    views,
    page,
    pageNumber,
    onDocumentLoadSuccess,
    isAdPage,
    portraitMode,
    isPublisherAdPage,
    isSystemAdPage,
  } = props;

  const getNextPageIndex = () => {
    if (isPublisherAdPage()) {
      return 2;
    }
    if (isSystemAdPage()) {
      return 1;
    }
    return 1;
  };
  return (
    <>
      {/* <p className="text-white text-4xl">
        {views[page + getNextPageIndex()].type}
      </p> */}
      {isResponsiveMode() &&
        !portraitMode &&
        isAdPage() &&
        views[page + getNextPageIndex()]?.type == "image" && (
          <div className="flex items-center justify-center">
            <img
              src={
                process.env.PUBLIC_URL + views[page + getNextPageIndex()].file
              }
              width="85%"
            />
          </div>
        )}
      {isResponsiveMode() &&
        !portraitMode &&
        views.length &&
        isAdPage() &&
        views[page + getNextPageIndex()].type == "pdf" && (
          <div className="flex items-center justify-center">
            <div width="100 bg-green-500">
              <Document
                file={
                  process.env.PUBLIC_URL + views[page + getNextPageIndex()].file
                }
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
          </div>
        )}
      {!isResponsiveMode() &&
        views.length &&
        isAdPage() &&
        views[page + getNextPageIndex()].type == "image" && (
          <div
            className="flex items-center justify-center absolute"
            style={{
              top: "25%",
              right: "19%",
            }}
          >
            <img
              src={
                process.env.PUBLIC_URL + views[page + getNextPageIndex()].file
              }
              width="240"
            />
          </div>
        )}
      {/* mm */}
      {!isResponsiveMode() &&
        views.length &&
        isAdPage() &&
        views[page + getNextPageIndex()].type == "pdf" && (
          <div
            className="flex items-center justify-center absolute"
            style={{
              top: "25%",
              right: "-19%",
            }}
          >
            <Document
              file={
                process.env.PUBLIC_URL + views[page + getNextPageIndex()].file
              }
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} />
            </Document>
          </div>
        )}
    </>
  );
}
