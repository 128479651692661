import axios from "axios";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import ReCAPTCHA from "react-google-recaptcha";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import BasicButton from "../../../../components/buttons/basic-button";
import authRequestService from "../../../../services/HttpRequests/authenticated-request-services";
import PhoneVerification from "./phone-verification";
import EmailVerification from "./email-verification";
import { useParams } from "react-router-dom";
import {
  isAuthUser,
  API_BASE,
  isSales,
  authUserData,
  isPublisher,
  isResponsiveMode,
} from "../../../../Utils/helpers";
import Countries from "../../../../components/country/country";
import Timezones from "../../../../components/timezone/timezone";
import { formatRelativeWithOptions } from "date-fns/fp";

export default function EditCustomerServiceSet() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneVerificationCode, setPhoneVerificationCode] = useState("");
  const [emailVerificationCode, setEmailVerificationCode] = useState("");
  const [phoneVerModalOpen, setPhoneVerModalOpen] = useState(false);
  const [emailVerModalOpen, setEmailVerModalOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [currentSet, setCurrentSet] = useState({});

  const captchaRef = useRef(null);
  const naviagte = useNavigate();

  const { id } = useParams();

  const phoneVerificationCodeChange = (e) => {
    setPhoneVerificationCode(e);
  };

  const verifyPhoneNumber = () => {
    setProcessing(true);
    const url =
      API_BASE +
      `/publisher/supplier/my-customer-services/set/verify/phone/otp`;
    authRequestService()
      .post(url, { code: phoneVerificationCode, uuid: id, phone: phone })
      .then((response) => {
        setProcessing(false);
        toast("Verication Successful!", {
          type: "success",
        });
        closePhoneVerModal();
        getSet();
      })
      .catch((error) => {
        if (error.response.status == 422) {
          if ("invalid_otp_code" in error.response.data) {
            toast("Sorry, the provided phone verification code is invalid!", {
              type: "error",
            });
            setProcessing(false);
            return false;
          }
        } else {
          toast("Something went wrong!", { type: "error" });
        }
        setProcessing(false);
      });
  };

  const openPhoneVerModal = () => {
    setPhoneVerModalOpen(true);
  };
  const closePhoneVerModal = () => {
    setPhoneVerModalOpen(false);
    setPhoneVerificationCode("");
  };

  const generatePhoneVerificationOTP = () => {
    if (isResponsiveMode()) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setProcessing(true);
    const type =
      !currentSet.phone_verified && currentSet.phone == phone
        ? "existing"
        : "new";
    const url =
      API_BASE +
      `/publisher/supplier/my-customer-services/set/generate/phone/otp`;
    authRequestService()
      .post(url, { uuid: id, phone: phone, type: type })
      .then((response) => {
        setProcessing(false);
        toast("We sent a verification code to your phone!", {
          type: "success",
        });
        openPhoneVerModal();
      })
      .catch((error) => {
        if (error.response.status == 422) {
          if ("duplicate_belongs_to_another_user" in error.response.data) {
            toast(
              "Sorry, the provided Phone Number belongs to another Litzapp Account!",
              {
                type: "error",
              }
            );
            setProcessing(false);
            return false;
          }
          if ("exists_my_other_customer_service" in error.response.data) {
            toast(
              "Sorry, the provided Phone Number is already added to one of your existing Customer Service Set!",
              {
                type: "error",
              }
            );
            setProcessing(false);
            return false;
          }

          if ("exists_other_user_customer_service" in error.response.data) {
            toast(
              "Sorry, the provided Phone Number belongs to another Litzapp Account!",
              {
                type: "error",
              }
            );
            setProcessing(false);
            return false;
          }
        } else {
          toast("Something went wrong!", { type: "error" });
        }
        setProcessing(false);
      });
  };

  const generateEmailAddressOTP = () => {
    if (isResponsiveMode()) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setProcessing(true);
    const type =
      !currentSet.email_verified && currentSet.email == email
        ? "existing"
        : "new";
    const url =
      API_BASE +
      `/publisher/supplier/my-customer-services/set/generate/email/otp`;
    authRequestService()
      .post(url, { email: email, uuid: id, type: type })
      .then((response) => {
        setProcessing(false);
        toast("We sent a verification code to your email address!", {
          type: "success",
        });
        openEmailVerModal();
      })
      .catch((error) => {
        if (error.response.status == 422) {
          if ("duplicate_belongs_to_another_user" in error.response.data) {
            toast(
              "Sorry, the provided email address belongs to another Litzapp Account!",
              {
                type: "error",
              }
            );
            setProcessing(false);
            return false;
          }
          if ("exists_my_other_customer_service" in error.response.data) {
            toast(
              "Sorry, the provided email address is already added to one of your existing Customer Service Set!",
              {
                type: "error",
              }
            );
            setProcessing(false);
            return false;
          }

          if ("exists_other_user_customer_service" in error.response.data) {
            toast(
              "Sorry, the provided email address belongs to another Litzapp Account!",
              {
                type: "error",
              }
            );
            setProcessing(false);
            return false;
          }
        } else {
          toast("Something went wrong!", { type: "error" });
        }

        setProcessing(false);
      });
  };

  const verifyEmailAddress = () => {
    setProcessing(true);
    const url =
      API_BASE +
      `/publisher/supplier/my-customer-services/set/verify/email/otp`;
    authRequestService()
      .post(url, { code: emailVerificationCode, uuid: id, email: email })
      .then((response) => {
        setProcessing(false);
        toast("Verification Successful!", {
          type: "success",
        });
        closeEmailVerModal();
        getSet();
      })
      .catch((error) => {
        if (error.response.status == 422) {
          if ("invalid_otp_code" in error.response.data) {
            toast("Sorry, the provided email verification code is invalid!", {
              type: "error",
            });
            setProcessing(false);
            return false;
          }
        } else {
          toast("Something went wrong!", { type: "error" });
        }
        setProcessing(false);
      });
  };

  const emailVerificationCodeChange = (e) => {
    setEmailVerificationCode(e);
  };

  const openEmailVerModal = () => {
    setEmailVerModalOpen(true);
  };
  const closeEmailVerModal = () => {
    setEmailVerModalOpen(false);
    setEmailVerificationCode("");
  };

  const getSet = () => {
    setProcessing(true);
    const url =
      API_BASE + `/publisher/supplier/my-customer-services/set/${id}/single`;
    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        setName(response.data.set.name);
        setPhone(response.data.set.phone);
        setEmail(response.data.set.email);
        setCurrentSet(response.data.set);
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });

        setProcessing(false);
      });
  };

  const updateName = (type) => {
    setProcessing(true);
    const url =
      API_BASE + `/publisher/supplier/my-customer-services/update/name`;
    authRequestService()
      .post(url, {
        name: name,
        uuid: id,
      })
      .then((response) => {
        setProcessing(false);
        toast("Updated!", {
          type: "success",
        });
        getSet();
      })
      .catch((error) => {
        if (error.response.status == 422) {
          if ("duplicate_name" in error.response.data) {
            toast("Sorry, the Set Name already exists!", {
              type: "error",
            });
            setProcessing(false);
            return false;
          }
        } else {
          toast("Something went wrong!", { type: "error" });
        }
        setProcessing(false);
      });
  };

  const handleNameChange = (e) => {
    setName(e);
  };

  const handleEmailChange = (e) => {
    setEmail(e);
  };

  const handlePhoneChange = (e) => {
    setPhone(e);
  };

  useEffect(() => {
    if (!isPublisher()) {
      window.location.href = "/";
    }
    getSet();
  }, []);

  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 bold">Create Set</h1>
        </div>
        <div className="flex gap-2 items-start">
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>

      <div class="container px-6 mx-auto">
        <br />
        <div class="flex flex-col text-center md:text-left justify-evenly items-center">
          <div class="flex flex-col w-full items-center">
            <h1 class="text-2xl text-gray-800 font-bold my-2">Update Set</h1>
          </div>
          <div className={`w-full md:w-6/12 mx-auto md:mx-0`}>
            <div class="bg-white p-10 flex flex-col w-full shadow-xl rounded-xl">
              <p class="mx-auto md:mx-0 text-red-500 text-center">
                {!currentSet.email_verified && !currentSet.phone_verified && (
                  <p>Email Address and Phone Number verification pending.</p>
                )}
                {!currentSet.email_verified && currentSet.phone_verified ? (
                  <p>Email Address verification pending.</p>
                ) : (
                  ""
                )}
                {!currentSet.phone_verified && currentSet.email_verified ? (
                  <p>Phone Number verification pending.</p>
                ) : (
                  ""
                )}
              </p>
              {/* <h2 class="text-2xl font-bold text-gray-800 text-left mb-5">
                Sigin
              </h2> */}
              <form action="" class="w-full">
                <div id="input" class="flex flex-col w-full my-5 gap-1">
                  <label for="username" class="text-gray-500 mb-2">
                    Name
                  </label>
                  <input
                    onChange={(e) => handleNameChange(e.target.value)}
                    type="text"
                    value={name}
                    placeholder="Please insert Name"
                    class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                    autoComplete="off"
                  />
                  {name && (
                    <button
                      disabled={!name || processing}
                      processing
                      onClick={() => updateName()}
                      type="button"
                      class="py-2 bg-black rounded-lg text-green-100"
                    >
                      {!processing ? (
                        <div class="flex flex-row items-center justify-center">
                          <div class="font-bold">Update</div>
                        </div>
                      ) : (
                        "Updating..."
                      )}
                    </button>
                  )}
                </div>

                <div id="input" class="flex flex-col w-full my-5 gap-1">
                  <label for="username" class="text-gray-500 mb-2">
                    Email
                  </label>
                  <input
                    onChange={(e) => handleEmailChange(e.target.value)}
                    type="text"
                    value={email}
                    autoComplete="off"
                    placeholder="Please insert your email"
                    class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                  />
                  {!currentSet.email_verified && currentSet.email == email && (
                    <button
                      processing
                      onClick={() => generateEmailAddressOTP()}
                      type="button"
                      class="py-2 bg-black rounded-lg text-green-100"
                    >
                      {!processing ? (
                        <div class="flex flex-row items-center justify-center">
                          <div class="font-bold">Verify Email Address</div>
                        </div>
                      ) : (
                        "Verifying..."
                      )}
                    </button>
                  )}
                  {currentSet.email !== email && (
                    <button
                      disabled={!email || processing}
                      processing
                      onClick={() => generateEmailAddressOTP()}
                      type="button"
                      class="py-2 bg-black rounded-lg text-green-100"
                    >
                      {!processing ? (
                        <div class="flex flex-row items-center justify-center">
                          <div class="font-bold">Verify and Update</div>
                        </div>
                      ) : (
                        "Verifying..."
                      )}
                    </button>
                  )}
                </div>

                <div id="input" class="flex flex-col w-full my-5 gap-1">
                  <label for="username" class="text-gray-500 mb-2">
                    Phone
                  </label>
                  <PhoneInput
                    disabled={processing}
                    containerClass="react-input-customer-service"
                    country={"eg"}
                    enableSearch={true}
                    value={phone}
                    onChange={(phone) => handlePhoneChange(phone)}
                  />
                  {!currentSet.phone_verified && currentSet.phone == phone && (
                    <button
                      processing
                      onClick={() => generatePhoneVerificationOTP()}
                      type="button"
                      class="py-2 bg-black rounded-lg text-green-100"
                    >
                      {!processing ? (
                        <div class="flex flex-row items-center justify-center">
                          <div class="font-bold">Verify Phone Number</div>
                        </div>
                      ) : (
                        "Verifying..."
                      )}
                    </button>
                  )}
                  {currentSet.phone !== phone && (
                    <button
                      processing
                      onClick={() => generatePhoneVerificationOTP()}
                      type="button"
                      class="py-2 bg-black rounded-lg text-green-100"
                    >
                      {!processing ? (
                        <div class="flex flex-row items-center justify-center">
                          <div class="font-bold">Verify and Update</div>
                        </div>
                      ) : (
                        "Verifying..."
                      )}
                    </button>
                  )}
                </div>

                {/* <div id="button" class="flex flex-col w-full my-5">
                  <button
                    disabled={processing || !name || !email || !phone}
                    onClick={() => editSet()}
                    type="button"
                    class={`w-full py-4 ${
                      processing || !name || !email || !phone
                        ? "bg-gray-500"
                        : "bg-black"
                    }  rounded-lg text-green-100`}
                  >
                    {!processing ? (
                      <div class="flex flex-row items-center justify-center">
                        <div class="mr-2">
                          <svg
                            class="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                            ></path>
                          </svg>
                        </div>
                        <div class="font-bold">Update</div>
                      </div>
                    ) : (
                      "Updating..."
                    )}
                  </button>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>

      <PhoneVerification
        modalOpen={phoneVerModalOpen}
        hideModal={closePhoneVerModal}
        verifyPhoneNumber={verifyPhoneNumber}
        phoneVerificationCodeChange={phoneVerificationCodeChange}
        phoneVerificationCode={phoneVerificationCode}
      />
      <EmailVerification
        modalOpen={emailVerModalOpen}
        hideModal={closeEmailVerModal}
        verifyEmailAddress={verifyEmailAddress}
        emailVerificationCodeChange={emailVerificationCodeChange}
        emailVerificationCode={emailVerificationCode}
      />
    </>
  );
}
