import { ACTION_TYPES } from "../editor-objects";
import { StyleButton } from "../helpers/functions";
export default function SelectImagesButton(props) {
  const { canDisplayImageOptions, displaySelectImagesBox } = props;
  return (
    <button
      className="border border-gray rounded-lg p-1 m-1"
      onClick={() => {
        if (!canDisplayImageOptions()) {
          return false;
        }
        displaySelectImagesBox();
      }}
    >
      Add Slide
    </button>
  );
}
