import TextField from "../../../../components/inputs/text-input";
import BasicButton from "../../../../components/buttons/basic-button";
import { isResponsiveMode, API_BASE } from "../../../../Utils/helpers";
import { useState, useEffect } from "react";
import TextArea from "../../../../components/inputs/text-area";
import StripeCustomPageForm from "../../../../payment/custom-page-pay-button";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, getDay, getDate } from "date-fns";
import axios from "axios";
import { toast } from "react-toastify";
import { useRef } from "react";
import DateSelection from "./pre-date-selection";
import SubSummary from "./sub-summary";
import SubCapNotice from "./sub-cap-notice";

export default function REPurchaseModal({
  modalOpen,
  hideModal,
  replyCaptured,
  data,
  processing,
  state,
}) {
  const [subCapMode, setSubCapMode] = useState(false);
  const [subCapTermsAccepted, setSubCapTermsAccepted] = useState(false);
  const meta = JSON.parse(data.meta);
  const defaultMeta = {};

  const myRef = useRef(null);
  const canSubmit = () => {
    return true;
  };

  const initialProductReplyMeta = {
    reply: "",
    quantity: 1,
    start_date: "",
  };

  const [productReplyMeta, setProductReplyMeta] = useState(
    initialProductReplyMeta
  );
  const [consent, setConsent] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [shortDurationMeta, setShortDurationMeta] = useState({});
  const [shortDurations, setShortDurations] = useState([]);
  const [showCalendar, setShowCalendar] = useState(true);
  const [selType, setSelType] = useState("preferred");
  const [litzappMode, setLitzappMode] = useState(true);
  const [priceLabsData, setPriceLabsData] = useState();
  const [checkingShortAvailability, setCheckingShortAvailability] =
    useState(false);

  const getSubsForMeta = () => {
    const url =
      API_BASE +
      "/api/book/custom/product/meta/map/basic/" +
      meta.block_meta_id +
      "/subs";

    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          const subs = [];
          Object.keys(response.data.subs).forEach(function (key, index) {
            subs.push({
              color: response.data.subs[key].color,
              date: new Date(response.data.subs[key].date),
              code: response.data.subs[key].code,
              message: response.data.subs[key].message,
              date_only: key,
            });
          });

          setBookings(subs);
          if (response?.data?.data?.pricelabs)
            setPriceLabsData(response?.data?.data?.pricelabs?.price);

          if (response.data?.data?.pricelabs) setLitzappMode(false);
          if (!response.data?.data?.pricelabs) setLitzappMode(true);
        }
      })
      .catch((e) => {});
  };

  const checkAvailability = (date, type) => {
    setCheckingShortAvailability(true);
    setShortDurations([]);

    const url = API_BASE + "/api/book/custom/product/meta/short-duration/check";
    axios
      .post(url, {
        page_uuid: state.page.uuid,
        start_date: date,
        block_meta_id: meta.block_meta_id,
        type: type,
      })
      .then((response) => {
        if (response.status == 200) {
          setShortDurations(response.data.subs);
        }
        setCheckingShortAvailability(false);
        if (response?.data?.data?.pricelabs)
          setPriceLabsData(response?.data?.data?.pricelabs?.price);

        if (response.data?.data?.pricelabs) setLitzappMode(false);
        if (!response.data?.data?.pricelabs) setLitzappMode(true);
      })
      .catch((error) => {
        setCheckingShortAvailability(false);
        toast("Something went wrong!", {
          type: "error",
        });
      });
  };

  useEffect(() => {
    //console.log("BOOKINGS", bookings);
  }, [bookings]);

  const handleProductReplyMetaChange = (e, key, type) => {
    if (type == "checkbox") {
      setProductReplyMeta((prev) => ({ ...prev, [key]: !prev[key] }));
    } else if (type == "text" || type == "radio" || type == "dropdown") {
      if (type == "dropdown") {
        setProductReplyMeta((prev) => ({ ...prev, [key]: e.target.value }));
      } else {
        setProductReplyMeta((prev) => ({ ...prev, [key]: e }));
      }
    } else {
      setProductReplyMeta((prev) => ({ ...prev, [key]: e }));
    }
  };

  useEffect(() => {
    setProductReplyMeta(initialProductReplyMeta);
    getSubsForMeta();
    if (myRef.current) {
      myRef.current.scrollIntoView();
    }

    if (meta?.subscription_cap_enabled) {
      setSubCapMode(true);
    }
  }, []);

  const handleSelectedDateChange = (e, type) => {
    handleProductReplyMetaChange(e, "start_date", "text");
    if (type == "preferred") {
      // setShortDurations([]);
      setSelType("preferred");
      defaultMeta.type = "preferred";
      defaultMeta.start_date = e;
    } else {
      setSelType("short");
      defaultMeta.type = "short";
      defaultMeta.start_date = e;
    }
  };

  const handleSubCapTermsAcceptedChange = () => {
    setSubCapTermsAccepted(true);
  };
  const handleSubCapTermsAcceptedChangeBack = () => {
    setSubCapTermsAccepted(false);
    setShowCalendar(true);
  };

  const handleDateChangeFromSubSummary = () => {
    if (myRef.current) {
      myRef.current.scrollIntoView();
    }
    handleProductReplyMetaChange("", "start_date", "text");
    setShowCalendar(true);
    setSubCapTermsAccepted(false);
  };

  const getFullShortMetaName = (name) => {
    return name;
  };

  const getLabelAndPrice = () => {
    if (selType == "preferred") {
      return {
        label: meta.p_type_sub_opt,
        price: litzappMode ? data.price : priceLabsData,
      };
    } else {
      if (shortDurationMeta?.payload) {
        return {
          label: getFullShortMetaName(shortDurationMeta.name),
          price: shortDurationMeta.price,
        };
      }
      return {
        label: "",
        price: "",
      };
    }
  };

  const canRenderSubCapNotice = () => {
    return !showCalendar && subCapMode && !subCapTermsAccepted;
  };

  const canRenderSubSummary = () => {
    if (!showCalendar && subCapMode && subCapTermsAccepted) {
      return true;
    }

    if (!showCalendar && !subCapMode) {
      return true;
    }

    return false;
  };

  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + 13);

  const preDateSelectionData = {
    bookings: bookings,
    meta: meta,
    minDate: new Date(),
    maxDate: addDays(new Date(), Number(meta.future_availability)),
    setSelected: handleSelectedDateChange,
    show: showCalendar,
    notHideOnSelect: setShowCalendar,
    state: {
      productReplyMeta: productReplyMeta,
      checkAvailability: checkAvailability,
    },
  };

  const subSummaryData = {
    myRef: myRef,
    showCalendar: showCalendar,
    canRenderSubSummary: canRenderSubSummary,
    productReplyMeta: productReplyMeta,
    setShowCalendar: setShowCalendar,
    handleProductReplyMetaChange: handleProductReplyMetaChange,
    handleDateChangeFromSubSummary: handleDateChangeFromSubSummary,
  };

  const subMainButton = {
    selType: selType,
    processing: processing,
    showCalendar: showCalendar,
    setShowCalendar: setShowCalendar,
    getLabelAndPrice: getLabelAndPrice,
    productReplyMeta: productReplyMeta,
    checkingShortAvailability: checkingShortAvailability,
  };

  const shortMainButtons = {
    showCalendar: showCalendar,
    productReplyMeta: productReplyMeta,
    shortDurations: shortDurations,
    getFullShortMetaName: getFullShortMetaName,
    setSelType: setSelType,
    setShortDurationMeta: setShortDurationMeta,
    setShowCalendar: setShowCalendar,
  };

  const buyerConsentData = {
    consent: consent,
    setConsent: setConsent,
    file: data.contract,
    file2: data.systembuyercontractfile,
  };

  const checkoutInfoData = {
    processing: processing,
    consent: consent,
    productReplyMeta: productReplyMeta,
    shortDurationMeta: shortDurationMeta,
    meta: meta,
    getLabelAndPrice: getLabelAndPrice,
    defaultMeta: defaultMeta,
    selType: selType,
    canSubmit: canSubmit,
    showCalendar: showCalendar,
    state: state,
    data: data,
  };

  const replyInfoData = {
    meta: meta,
    productReplyMeta: productReplyMeta,
    handleProductReplyMetaChange: handleProductReplyMetaChange,
  };

  const subCapNoticeData = {
    canRenderSubCapNotice: canRenderSubCapNotice,
    handleSubCapTermsAcceptedChange: handleSubCapTermsAcceptedChange,
    handleSubCapTermsAcceptedChangeBack: handleSubCapTermsAcceptedChangeBack,
    meta: meta,
    data: data,
  };

  return (
    <div
      className={`${
        isResponsiveMode() ? "absolute" : "absolute"
      }  z-40 overflow-y-auto top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="relative inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-8/12"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className="rounded rounded-full p-0 -top-2 -right-1 absolute px-1 cursor-pointer opacity-75"
            onClick={() => hideModal()}
          >
            <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
          </div>

          <div class="px-4 pt-3 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <p
                className="text-1xl md:text-2xl font-bold text-center text-black"
                ref={myRef}
              >
                {replyCaptured
                  ? "Payment"
                  : showCalendar
                  ? `Select Start Date  (  ${meta.p_type_sub_opt}  )`
                  : getLabelAndPrice().label}
              </p>
            </div>
            {replyCaptured ? (
              <div className="grid grid-cols-1 gap-4"></div>
            ) : (
              <>
                <SubSummary
                  {...subSummaryData}
                  buyerConsentData={buyerConsentData}
                  checkoutInfoData={checkoutInfoData}
                  replyInfoData={replyInfoData}
                />
                <DateSelection
                  preDateSelectionData={preDateSelectionData}
                  subMainButton={subMainButton}
                  shortMainButtons={shortMainButtons}
                />
                <SubCapNotice {...subCapNoticeData} />
                <p className="text-red-700 font-light">
                  {!bookings.length && "Loading..."}
                </p>
                <p className="text-red-700 font-light">
                  {checkingShortAvailability && "Loading..."}
                </p>
              </>
            )}

            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
            {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
