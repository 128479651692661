import { useRef, useEffect, useState } from "react";
import {
  API_BASE,
  BASE_URL,
  isAuthUser,
  authUserData,
  isResponsiveMode,
  getLocation,
} from "../../../Utils/helpers";
import { toast } from "react-toastify";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
import AdSense from "react-adsense";
import { score, rate, average } from "average-rating";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";

export default function ProfileList({ publisherId, type, searchText }) {
  const [publishers, setPublishers] = useState([]);
  const [nextPageURL, setNextPageURL] = useState("");
  const [lastRatedBookId, setLastRatedBookId] = useState("");
  const [lastActionedPublisher, setLastActionedPublisher] = useState();
  const [currentRating, setCurrentRating] = useState();
  const [renderSlider, setRenderSlider] = useState(true);
  const [rating, setRating] = useState(0);

  const hasPurchasedBook = (book) => {
    if (!isAuthUser()) {
      return false;
    }

    if (book) {
      const bought = book.buyers.filter((buyer) => {
        // alert(buyer.user_id == authUserData().id);
        if (buyer.user_id == authUserData().id) {
          return buyer;
        }
      });

      //   alert(bought);

      return bought.length ? true : false;
    }

    // return false;
  };

  const getPublishersByAppliedDFilters = () => {
    setPublishers([]);
    const url =
      API_BASE +
      `/api/publishers/paginated/filtered/${type}/${publisherId}/${searchText}`;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.users) {
            setPublishers(response.data.users.data);
            setNextPageURL(response.data.users.next_page_url);
          }
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const loadMore = () => {
    if (nextPageURL) {
      const url =
        getLocation().hostname == "litzapp.com"
          ? API_BASE + nextPageURL.split("api/api")[1]
          : nextPageURL.split("api")[1];
      authRequestService()
        .get(url)
        .then((response) => {
          if (response.status == 200) {
            let oldPublishers = publishers;
            let newPublishers = oldPublishers.concat(response.data.users.data);
            setPublishers(newPublishers);
            setNextPageURL(response.data.users.next_page_url);
          }
        })
        .catch((error) => {
          toast("Something went wrong", { type: "error" });
        });
    }
  };

  // const loadMore = () => {
  //   if (nextPageURL) {
  //     const url =
  //       API_BASE + "/api/track/" + type + "?page=" + nextPageURL.split("=")[1];
  //     // alert(API_BASE + "/api" + nextPageURL.split("/api")[1]);
  //     axios
  //       .get(url)
  //       .then((response) => {
  //         if (response.status == 200) {
  //           let oldPublishers = publishers;
  //           let newPublishers = oldPublishers.concat(
  //             response.data.publishers.data
  //           );
  //           setPublishers(newPublishers);
  //           setNextPageURL(response.data.publishers.next_page_url);
  //         }
  //       })
  //       .catch((error) => {
  //         toast("Something went wrong", { type: "error" });
  //       });
  //   }
  // };

  const trackPublisher = (publisherId, trackerId) => {
    const url = API_BASE + "/api/track";

    axios
      .post(url, { publisherId: publisherId, trackerId: trackerId })
      .then((response) => {
        if (response.status == 200) {
          setLastActionedPublisher(response.data.publisher);
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const untrackPublisher = (publisherId, trackerId) => {
    const url = API_BASE + "/api/untrack";

    axios
      .post(url, { publisherId: publisherId, trackerId: trackerId })
      .then((response) => {
        if (response.status == 200) {
          setLastActionedPublisher(response.data.publisher);
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const isTrackingPublisher = (publisher) => {
    if (publisher.trackers && isAuthUser()) {
      const isTracking = publisher.trackers.filter(
        (t) => t.tracker_id == authUserData().id
      );
      return isTracking.length ? true : false;
    }

    return false;
  };

  const getTrackData = () => {
    const url = API_BASE + "/api/track/" + type + "/" + publisherId;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setPublishers(response.data.users.data);
          setNextPageURL(response.data.users.next_page_url);
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  useEffect(() => {
    getTrackData();
  }, []);

  const getPublisherInfo = (publisher) => {
    if (publisher.track_data) {
      if (type == "tracking") {
        return publisher.track_data.tracking;
      } else {
        return publisher.track_data.trackers;
      }
    }
    return publisher;
  };

  useEffect(() => {
    getPublishersByAppliedDFilters();
  }, [searchText]);
  return renderSlider ? (
    <section>
      <div className="w-full">
        <div className="relative grid md:grid-cols-4 content-start items-center justify-center w-full">
          {publishers.map((publisher, i) => (
            <div className="">
              <div className="p-4 shadow m-2 bg-white text-center z-50 ">
                <i class="fas fa-user" style={{ fontSize: "5rem" }}></i>

                <div className="">
                  <p className="font-bold text-lg text-center my-1">
                    {getPublisherInfo(publisher).name}
                  </p>

                  {getPublisherInfo(publisher).roles.filter(
                    (r) => r.name == "publisher"
                  ).length ? (
                    <button
                      disabled={
                        !isAuthUser() ||
                        getPublisherInfo(publisher).uuid == authUserData().uuid
                      }
                      onClick={() =>
                        isAuthUser()
                          ? isTrackingPublisher(
                              lastActionedPublisher
                                ? lastActionedPublisher
                                : getPublisherInfo(publisher)
                            )
                            ? untrackPublisher(
                                getPublisherInfo(publisher).uuid,
                                authUserData().uuid
                              )
                            : trackPublisher(
                                getPublisherInfo(publisher).uuid,
                                authUserData().uuid
                              )
                          : null
                      }
                      class={`whitespace-nowrap inline-flex items-center justify-center px-8 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white ${
                        !isAuthUser() ||
                        getPublisherInfo(publisher).uuid == authUserData().uuid
                          ? "bg-gray-500 hover:bg-gray-500"
                          : "bg-black hover:bg-black"
                      }`}
                    >
                      {isTrackingPublisher(
                        lastActionedPublisher
                          ? lastActionedPublisher
                          : getPublisherInfo(publisher)
                      )
                        ? "Untrack"
                        : "Track"}
                    </button>
                  ) : (
                    <div>
                      {" "}
                      <button className="whitespace-nowrap inline-flex items-center justify-center px-8 py-8 border border-transparent rounded-md shadow-sm text-base font-medium text-white"></button>
                      <div>
                        {" "}
                        <a className="text-blue-500 text-lg"></a>
                      </div>
                    </div>
                  )}

                  {getPublisherInfo(publisher).roles.filter(
                    (r) => r.name == "publisher"
                  ).length ? (
                    <div>
                      {" "}
                      <a
                        href={`/web/${getPublisherInfo(publisher).uuid}`}
                        className="text-blue-500 text-lg"
                      >
                        View Profile
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center w-full">
          {" "}
          <button
            disabled={!nextPageURL}
            class={`whitespace-nowrap inline-flex items-center justify-center px-8 py-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white ${
              !nextPageURL ? "bg-gray-500 hover:bg-gray-500" : "bg-black"
            } hover:bg-black md:mr-8 `}
            onClick={() => loadMore()}
          >
            View More
          </button>
        </div>
      </div>
    </section>
  ) : (
    <div>
      <p className="text-center my-6">Loading...</p>
    </div>
  );
}
