import { useState, useEffect } from "react";
import { useRef } from "react";
export default function SupplierBizNameVerification(props) {
  const { bizName, verification, handleBizNameChange } = props;

  const [savingStage, setSavingStage] = useState(false);

  return (
    <div className="my-identity dropdown">
      <div id="input" class="flex flex-col w-full  my-5">
        <label for="password" class="text-gray-500 mb-2">
          Enter Business Name
        </label>
        <input
          value={bizName}
          onChange={(e) => handleBizNameChange(e.target.value)}
          type="text"
          id="identit-name"
          placeholder={`Paste your Enter Business Name here`}
          class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
        />
      </div>
    </div>
  );
}
