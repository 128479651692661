import {
  isAuthUser,
  isPublisher,
  authUserData,
  isResponsiveMode,
  isSponsor,
} from "../../../../../Utils/helpers";
import MSharedMenuItems from "./shared";
export default function MSponsorMenuItems() {
  const renderMenu = () => {
    return isAuthUser() && isSponsor();
  };

  return renderMenu() && isResponsiveMode() ? (
    <>
      <a
        href={`/sponsor/dashboard`}
        class="text-base font-medium my-text-yellow-500"
      >
        Dashboard
      </a>
    </>
  ) : (
    ""
  );
}
