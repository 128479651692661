import Checkbox from "../../../../components/inputs/checkbox";
import { isResponsiveMode } from "../../../../Utils/helpers";
import { useSendMailStore } from "../../../../stores/send-mail-store";

export default function MobilePubEmailSubscriptionsTable(props) {
  const mailStore = useSendMailStore((state) => state);
  const { blockedEmails } = props;
  const { shouldMarkItem } = props;
  const { markItem } = props;

  return (
    <div class="w-full flex flex-col justify-center my-4 text-center">
      {mailStore.data.data
        ? mailStore.data.data.length
          ? mailStore.data.data.map((subscription) => {
              return (
                <div
                  className="overflow-x-scroll inline"
                  style={{
                    width: document.documentElement.clientWidth - 90 + "px",
                    margin: "0 auto",
                  }}
                >
                  <div className="flex">
                    <div
                      class={`${
                        isResponsiveMode() ? "border text-center" : ""
                      } px-4 py-2 font-medium md:hidden `}
                    >
                      {!blockedEmails.includes(subscription?.email) ? (
                        <div className="md:hidden">
                          {" "}
                          <Checkbox
                            checked={
                              shouldMarkItem(subscription.id) ? true : false
                            }
                            handleChange={() => markItem(subscription.id)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold md:hidden">First Name</h3>
                      {subscription.first_name}
                    </div>
                    <div class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold md:hidden">Last Name</h3>
                      {subscription.last_name}
                    </div>
                    <div class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold md:hidden">Email</h3>
                      <p className="word-wrap break-words break-all">
                        {" "}
                        {subscription.email}
                      </p>
                    </div>
                    <div class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold md:hidden">Country</h3>
                      <p className="word-wrap break-words break-all">
                        {" "}
                        {subscription.country}
                      </p>
                    </div>
                    <div class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold md:hidden">Channel</h3>
                      {subscription.channel == "platform_updates"
                        ? "PLatform Update"
                        : "Information Page"}
                    </div>
                    <div class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold md:hidden">Item Title</h3>
                      <p className="my-text-yellow-500">
                        {" "}
                        {subscription?.book?.group == "standard"
                          ? "File"
                          : "Custom Page"}
                      </p>
                      <p>
                        <a
                          target="_blank"
                          className="underline"
                          href={
                            subscription?.book?.group == "standard"
                              ? `/publisher/${subscription?.book?.owneruuid}/publications/series/m/book/${subscription?.book?.book_uuid}`
                              : `/web/rich-page/render/${subscription?.book?.book_uuid}`
                          }
                        >
                          {subscription?.book?.name}
                        </a>
                      </p>
                    </div>

                    <div class="border  px-4 py-2 font-medium">
                      <h3 className="font-bold md:hidden">Item ID</h3>
                      {subscription?.book?.item_id}
                    </div>
                    <div class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold md:hidden">Member</h3>
                      {subscription.subscriber_id ? "Yes" : "No"}
                    </div>
                    <div class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold md:hidden">Date</h3>
                      <p>
                        {subscription.refactoredCreatedAtTime
                          ? new Date(
                              subscription.refactoredCreatedAtTime.replace(
                                /-/g,
                                "/"
                              )
                            ).getDate() +
                            "-" +
                            (new Date(
                              subscription.refactoredCreatedAtTime.replace(
                                /-/g,
                                "/"
                              )
                            ).getMonth() +
                              1) +
                            "-" +
                            new Date(
                              subscription.refactoredCreatedAtTime.replace(
                                /-/g,
                                "/"
                              )
                            ).getFullYear()
                          : "N/A"}
                      </p>
                    </div>
                    <div
                      class={`${
                        !isResponsiveMode() ? "border" : ""
                      } px-4 py-2  font-medium `}
                    >
                      {!blockedEmails.includes(subscription?.email) ? (
                        <div className="hidden md:block">
                          {" "}
                          <Checkbox
                            checked={
                              shouldMarkItem(subscription.id) ? true : false
                            }
                            handleChange={() => markItem(subscription.id)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          : ""
        : ""}
    </div>
  );
}
