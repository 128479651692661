import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import StripeRedirectModal from "../../../modals/stripe-redirect-modal";
import StripeRedirectModeModal from "../../../modals/stripe-redirect-mode-modal";
import DeletePromptModal from "../../../modals/delete-warning-modal";
import Pagination from "../../../components/pagination";
import AdminAdInsertModal from "../../../modals/admin-ad-insert-modal";
import useDebounce from "../../../components/debounce/debounce-helper";

import {
  isAdmin,
  API_BASE,
  isPublisher,
  getLocation,
  authUserData,
  isAuthUser,
  getClientBaseURL,
} from "../../../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
import Switch from "react-switch";
import PasswordPromptModal from "../../../modals/password-prompt-modal";
import CreateTierModal from "../../../modals/create-tier-modal";
export default function AdminPaidAds() {
  const [genAds, setGenAds] = useState({});
  const naviagte = useNavigate();
  const getGenAdverts = () => {
    let url = API_BASE + "/api/system/settings";
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setGenAds(response.data);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };
  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
    getGenAdverts();
  }, []);
  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">Paid Advert</h1>
        </div>
        <div className="flex gap-2">
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center gap-4 mt-8 items-center mb-4">
        {/* <h1 className="font-bold text-xl">Storage Tiers</h1> */}
        <div className="w-full p-8 flex flex-col">
          <div className="bg-white m-2 p-2 flex justify-between shadow flex flex-col gap-2">
            <BasicButton
              title={"Set 1"}
              handleClick={() => naviagte("/admin/paid-adverts/set/1/slots")}
            />
            <BasicButton
              title={"Set 2"}
              handleClick={() => naviagte("/admin/paid-adverts/set/2/slots")}
            />
            <BasicButton
              title={"Set 3"}
              handleClick={() => naviagte("/admin/paid-adverts/set/3/slots")}
            />
            <BasicButton
              title={"Set 4"}
              handleClick={() => naviagte("/admin/paid-adverts/set/4/slots")}
            />
            <BasicButton
              title={"Set 5"}
              handleClick={() => naviagte("/admin/paid-adverts/set/5/slots")}
            />
            <BasicButton
              title={"Set 6"}
              handleClick={() => naviagte("/admin/paid-adverts/set/6/slots")}
            />
          </div>
        </div>
      </div>
    </>
  );
}
