import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import BasicButton from "../../../../../components/buttons/basic-button";
import Pagination from "../../../../../components/pagination";
import useDebounce from "../../../../../components/debounce/debounce-helper";
import LoadingProgress from "../../../rich-page/components/loading-progress";
import { useSendMailStore } from "../../../../../stores/send-mail-store";
import FilterBar from "./components/filter";
import authRequestService from "../../../../../services/HttpRequests/authenticated-request-services";
import authPaginatedRequestService from "../../../../../services/HttpRequests/authenticated-paginated-req-service";
import DesktopTable from "./components/desktop-table";
import MobileTable from "./components/mobile-table";
import DeletePromptModal from "../../../../../modals/delete-warning-modal";

import {
  isAdmin,
  API_BASE,
  isPublisher,
  isAuthUser,
  authUserData,
  getLocation,
  isResponsiveMode,
} from "../../../../../Utils/helpers";

import { toast } from "react-toastify";
export default function PubMyProductTransactionsRecurring() {
  const [currentPage, setCurrentPage] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [paginateMode, setPaginateMode] = useState(false);
  const [user, setUser] = useState({});
  const [blockedEmails, setBlockedEmails] = useState([]);
  const mountedRef = useRef();
  const navigate = useNavigate();
  const mailStore = useSendMailStore((state) => state);
  const [currSelSub, setCurSelSub] = useState({});
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const convertToUTC = (date) => {
    const d = new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
    return d;
  };

  const correcDateFormat = (d) => {
    const day = d.getUTCDate();
    const month = d.getUTCMonth() + 1; // Months are zero-based, so add 1
    const year = d.getUTCFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    //console.log(formattedDate);
    return formattedDate;
  };

  const fetchUser = () => {
    const url = API_BASE + "/publisher/" + authUserData().uuid + "/optimized";
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setUser(response.data.publisher);
          mailStore.setUser(response.data.publisher);
          if (response.data.publisher.emailblocklist)
            setBlockedEmails(
              response.data.publisher.emailblocklist.map((d) => d.email)
            );
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  const doPagination = (page) => {
    if (mailStore.data.first_page_url) {
      mailStore.setData([]);
      setCurrentPage(page);
      setPaginateMode(true);
      setProcessing(true);
      authPaginatedRequestService()
        .get(mailStore.data.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          mailStore.setData(res.data.subscriptions);
          setProcessing(false);
        })
        .catch((err) => setProcessing(false));
    }
  };

  const getSubscriptions = () => {
    mailStore.setData([]);
    setProcessing(true);
    setPaginateMode(false);

    // console.log("DDD", mailStore.query.startDate);
    const start =
      mailStore.query.startDate instanceof Date
        ? correcDateFormat(mailStore.query.startDate)
        : "*";
    const end =
      mailStore.query.endDate instanceof Date
        ? correcDateFormat(mailStore.query.endDate)
        : "*";
    const url =
      API_BASE +
      "/publisher/my/product-page-subscribers/" +
      authUserData().uuid +
      "/" +
      mailStore.query.countPerPage +
      "/" +
      mailStore.query.pageLimit +
      "/" +
      start +
      "/" +
      end +
      "/" +
      mailStore.query.subStatus +
      "/" +
      mailStore.query.purchaseOption +
      "/" +
      mailStore.query.searchText +
      " ";

    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          mailStore.setData(response.data.subscriptions);
          setProcessing(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const cancelSubscription = () => {
    setProcessing(true);
    const url =
      API_BASE +
      "/publisher/product-page/my/subscription/" +
      currSelSub.subscription_id +
      "/cancel";
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setTimeout(() => {
            getSubscriptions();
            setProcessing(false);
            closCancelModal();
          }, 6000);
        }
      })
      .catch((error) => {
        // console.log("There was an error!", error);
        setProcessing(false);
      });
  };

  const computeSelectedCount = () => {
    if (!mailStore.data.total) return false;
    if (mailStore.isAllSelected) {
      if (mailStore.deSelected.length) {
        // console.log("ALL SEL FLAGS ACTIVE WITH SOME DESELECTED");
        mailStore.setSelectedCount(
          mailStore.data.total - mailStore.deSelected.length
        );
      } else {
        // console.log("ALL SEL FLAGS ACTIVE WITH NONE DESELECTED");
        mailStore.setSelectedCount(mailStore.data.total);
      }
    } else {
      // console.log("ALL SEL FLAGS INACTIVE");
      mailStore.setSelectedCount(mailStore.selected.length);
    }
  };

  const handleSearchTextChange = (e) => {
    mailStore.setQuery("searchText", e);
  };

  const handleCountPerPageChange = (e) => {
    mailStore.setQuery("countPerPage", e.target.value);
  };

  const handleLatestEntriesChange = (e) => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
    mailStore.setQuery("selectedLatest", e.target.value);
    mailStore.setQuery("pageLimit", e.target.value);
  };

  const handleOldestEntriesChange = (e) => {
    mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
    mailStore.setQuery("selectedOldest", e.target.value);
    mailStore.setQuery("pageLimit", e.target.value);
  };

  const handleEndDateChange = (d) => {
    mailStore.setQuery("endDate", convertToUTC(d));
  };

  const handleStartDateChange = (d) => {
    mailStore.setQuery("startDate", convertToUTC(d));
  };

  const handleSubStatusChange = (e) => {
    mailStore.setQuery("subStatus", e.target.value);
  };

  const handlePurchaseOptionChange = (e) => {
    mailStore.setQuery("purchaseOption", e.target.value);
  };

  useEffect(() => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedLatest]);

  useEffect(() => {
    mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedOldest]);

  const markItem = (id) => {
    if (!mailStore.isAllSelected && !mailStore.selected.includes(id)) {
      mailStore.setSelType("M");
      const item = mailStore.selected;
      mailStore.setSelected([...item, id]);
    } else {
      const item = mailStore.deSelected;
      if (mailStore.isAllSelected) {
        if (mailStore.deSelected.includes(id)) {
          const filteredItems = mailStore.deSelected.filter((e) => e !== id);
          mailStore.setDeselected(filteredItems);
        } else {
          mailStore.setDeselected([...item, id]);
        }
      }
      const filteredItems = mailStore.selected.filter((e) => e !== id);
      mailStore.setSelected(filteredItems);
    }
  };

  const clearMarkedItems = (id) => {
    mailStore.setSelType("M");
    mailStore.setSelected([]);
    mailStore.setDeselected([]);
    mailStore.setIsAllSelected(false);
    mailStore.setQuery("selectedLatest", "");
    mailStore.setQuery("selectedOldest", "");
    mailStore.setQuery("pageLimit", "normal_1000000");
  };

  const markAllItems = () => {
    mailStore.setSelType("A");
    mailStore.setIsAllSelected();
  };

  const addNewItemsToMakrList = () => {
    const items = mailStore.selected;
    if (mailStore.isAllSelected) {
      if (!mailStore.data.data) return false;
      mailStore.setSelected(items);
    } else {
      if (mailStore.selType == "A") {
        mailStore.setSelected([]);
        mailStore.setDeselected([]);
        // console.log("DESELECTING");
      }
    }
  };

  const shouldMarkItem = (id) => {
    if (!mailStore.isAllSelected && mailStore.selected.includes(id)) {
      return true;
    }
    if (mailStore.isAllSelected && !mailStore.deSelected.includes(id)) {
      return true;
    }

    return false;
  };

  const showSendButton = () => {
    if (!mailStore.data.total) return false;
    if (mailStore.selected.length) return true;
    if (mailStore.isAllSelected) {
      if (mailStore.deSelected.length !== mailStore.data.total) {
        return true;
      }
    }
    return false;
  };

  const clearFilter = () => {
    mailStore.resetQuery();
  };

  useEffect(() => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedLatest]);

  useEffect(() => {
    computeSelectedCount();
  }, [mailStore.selected]);

  useEffect(() => {
    computeSelectedCount();
  }, [mailStore.deSelected]);

  useEffect(() => {
    mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedOldest]);

  useEffect(() => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedLatest]);

  useEffect(() => {
    computeSelectedCount();
  }, [mailStore.selected]);

  useEffect(() => {
    computeSelectedCount();
  }, [mailStore.deSelected]);

  useEffect(() => {
    mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedOldest]);

  useEffect(() => {
    addNewItemsToMakrList();
    computeSelectedCount();
    if (!mailStore.isAllSelected) {
      mailStore.setDeselected([]);
    } else {
      mailStore.setSelected([]);
    }
  }, [mailStore.isAllSelected]);

  useEffect(() => {
    addNewItemsToMakrList();
    computeSelectedCount();

    if (
      !mailStore.isAllSelected &&
      mailStore.selected.length &&
      mailStore.refresh &&
      !paginateMode
    ) {
      mailStore.setSelected([]);
    }

    if (!paginateMode && mailStore.refresh) mailStore.setIsAllSelected(false);
    fetchUser();
  }, [mailStore.data]);

  useDebounce(
    () => {
      if (mailStore.refresh) {
        getSubscriptions();
      }
      mailStore.setRefresh(true);
    },
    [mailStore.query],
    800
  );
  const preSubCancelPromt = (s) => {
    setCancelModalOpen(true);
    setCurSelSub(s);
  };

  const closCancelModal = (d) => {
    setCurSelSub({});
    setCancelModalOpen(false);
  };

  useEffect(() => {
    if (!isPublisher()) {
      window.location.href = "/";
    }
    fetchUser();
  }, []);

  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">
              My Product Subscriptions
            </h1>
          </div>
          <div className="flex justify-between gap-2 items-start">
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            <p className="w-full text-center my-4">
              We found {mailStore?.data?.total || ""} records
            </p>
            <FilterBar
              handleCountPerPageChange={handleCountPerPageChange}
              handleSearchTextChange={handleSearchTextChange}
              serchQValue={mailStore.query.searchText}
              handleLatestEntriesChange={handleLatestEntriesChange}
              latestQValue={mailStore.query.selectedLatest}
              handleOldestEntriesChange={handleOldestEntriesChange}
              oldestQValue={mailStore.query.selectedOldest}
              handleEndDateChange={handleEndDateChange}
              endDate={mailStore.query.endDate}
              handleStartDateChange={handleStartDateChange}
              startDate={mailStore.query.startDate}
              handleSubStatusChange={handleSubStatusChange}
              subStatus={mailStore.query.subStatus}
              handlePurchaseOptionChange={handlePurchaseOptionChange}
              purchaseOption={mailStore.query.purchaseOption}
              selectAllFlag={mailStore.isAllSelected}
              clearMarkedItems={clearMarkedItems}
              markAllItems={markAllItems}
              showSendButton={showSendButton}
              clearFilter={clearFilter}
              model={"product-subscriptions"}
            />

            <div className="w-full flex justify-center">
              {mailStore?.data?.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            {isResponsiveMode() ? (
              <MobileTable
                preSubCancelPromt={preSubCancelPromt}
                shouldMarkItem={shouldMarkItem}
                blockedEmails={blockedEmails}
                processing={processing}
                markItem={markItem}
              />
            ) : (
              <DesktopTable
                preSubCancelPromt={preSubCancelPromt}
                shouldMarkItem={shouldMarkItem}
                blockedEmails={blockedEmails}
                processing={processing}
                markItem={markItem}
              />
            )}
            <LoadingProgress loading={processing} />
            <div className="w-full flex justify-center">
              {mailStore?.data?.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                    processing={processing}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {currSelSub.id ? (
        <DeletePromptModal
          modalOpen={cancelModalOpen}
          hideAdModal={closCancelModal}
          title={`Are you certain you wish to cancel the subscription for ${currSelSub?.subscriber?.name} ${currSelSub?.subscriber?.handle_id}?`}
          yesActionText={"Yes, Cancel the Subscription"}
          noActionText={"No, Do Not Cancel"}
          action={cancelSubscription}
          processing={processing}
        />
      ) : (
        ""
      )}
    </>
  );
}
