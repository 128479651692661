import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState, useEffect, useRef } from "react";
import WebHeader from "./web/header";
import { isAdmin, isReader, isSales, isSponsor } from "../Utils/helpers";
import Registration3Modal from "../modals/registration-3-modal";
import Registration2Modal from "../modals/registration-2-modal";
import ReCAPTCHA from "react-google-recaptcha";
import {
  isAuthUser,
  API_BASE,
  getLocation,
  getClientBaseURL,
} from "../Utils/helpers";
import Footer from "../layout/footer";
import axios from "axios";

export default function LoginHydrate(props) {
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [processing, setProcessing] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isHuman, setIsHuman] = useState(true);
  const naviagte = useNavigate();
  const captchaRef = useRef(null);
  const [registration3ModalOpen, setRegistration3ModalOpen] = useState(false);
  const [registration2ModalOpen, setRegistration2ModalOpen] = useState(false);
  const { action } = props;

  const hideRegistration3Modal = () => {
    setRegistration3ModalOpen(false);
  };

  const hideRegistration2Modal = () => {
    setRegistration2ModalOpen(false);
  };

  const showReistration2AfterUsernameValidation = (userId) => {
    setUserId(userId);
    hideRegistration3Modal();
    setRegistration2ModalOpen(true);
  };

  const completeRegistration2 = () => {
    hideRegistration2Modal();
  };

  const login = () => {
    setIsAuthenticating(true);
    const url = API_BASE + "/api/login";
    axios
      .post(url, { email: email, password: password })
      .then((response) => {
        if (response.status == 200) {
          if (!response.data) {
            //   alertMe.show(result.message, { type: "error" });
          } else {
            if (response.data) {
              let user = response.data.data;
              if (user.status == "inactive") {
                toast("Account Suspended", { type: "error" });
                setIsAuthenticating(false);
                return false;
              }
              if (user.roles && user.roles.length > 0) {
                if (user.has_sub_record) {
                  localStorage.setItem("has_sub_record", "exists");
                }
                localStorage.setItem("user", JSON.stringify(user));
                // localStorage.setItem("user_email", user.email);
                localStorage.setItem("token", response.data.access_token);
                // localStorage.setItem("roles", JSON.stringify(user.roles));
                toast("Welcome Back " + user.name, { type: "success" });
                action();
              } else {
                toast("Unauthorized Access", { type: "error" });
              }
            }
          }
        }
        setIsAuthenticating(false);
      })
      .catch((error) => {
        toast("Invalid Email Or Password", { type: "error" });
        setIsAuthenticating(false);
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e);
  };

  const handlePasswordChange = (e) => {
    setPassword(e);
  };

  const handleLogin = () => {
    login();
  };

  const validateRecaptcha = () => {
    const token = captchaRef.current.getValue();
    const url = API_BASE + "/api/user/recaptcha/verify";
    axios
      .post(url, { token: token })
      .then((res) => {
        setIsHuman(true);
        // captchaRef.current.reset();
      })
      .catch((error) => {
        console.log(error);
        // captchaRef.current.reset();
      });
  };

  useEffect(() => {
    if (isAuthUser()) {
      window.location.href = "/";
    }
  }, []);

  return (
    <div className="w-full">
      <div class="container px-6 mx-auto">
        <br />
        <div class="flex flex-col text-left justify-evenly items-center">
          <div class="flex flex-col w-full items-center">
            <div></div>
            <h1 class="text-2xl text-gray-800 font-bold my-2">Members Area</h1>
            <p class="w-5/12 mx-auto md:mx-0 text-gray-500"></p>
          </div>
          <div class="w-full md:w-6/12 lg:w-5/12 mx-auto md:mx-0">
            <div class="bg-white p-10 flex flex-col w-full shadow-xl rounded-xl">
              <h2 class="text-2xl font-bold text-gray-800 text-left mb-5">
                Sign In
              </h2>
              <form action="" class="w-full">
                <div id="input" class="flex flex-col w-full my-5">
                  <label for="username" class="text-gray-500 mb-2 text-left">
                    Email
                  </label>
                  <input
                    onChange={(e) => handleEmailChange(e.target.value)}
                    type="text"
                    id="username"
                    placeholder="Please insert your username"
                    class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                  />
                </div>
                <div id="input" class="flex flex-col w-full my-5">
                  <label for="password" class="text-gray-500 mb-2 text-left">
                    Password
                  </label>
                  <input
                    onChange={(e) => handlePasswordChange(e.target.value)}
                    type="password"
                    id="password"
                    placeholder="Please insert your password"
                    class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                  />
                </div>
                <div id="button" class="flex flex-col w-full my-5">
                  <button
                    disabled={
                      isAuthenticating ||
                      !isHuman ||
                      !email ||
                      !password ||
                      !isHuman
                    }
                    onClick={() => handleLogin()}
                    type="button"
                    class={`w-full py-4 ${
                      isAuthenticating || !isHuman || !email || !password
                        ? "bg-gray-300"
                        : "bg-black"
                    } rounded-lg text-green-100`}
                  >
                    {!isAuthenticating ? (
                      <div class="flex flex-row items-center justify-center cursor-pointer">
                        <div class="mr-2">
                          <svg
                            class="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                            ></path>
                          </svg>
                        </div>
                        <div class="font-bold">Sign In</div>
                      </div>
                    ) : (
                      "Authenticating..."
                    )}
                  </button>
                </div>
                <div className="flex justify-center gap-4">
                  <p
                    className="text-center cursor-pointer text-yellow-600  font-bold"
                    onClick={() =>
                      (window.location.href = `/password-reset/validate-email`)
                    }
                  >
                    Forgot Password
                  </p>
                  <p
                    className="text-center cursor-pointer text-yellow-600  font-bold"
                    onClick={() => setRegistration3ModalOpen(true)}
                  >
                    Enter Username
                  </p>
                </div>
                <div className="flex justify-center w-full my-4">
                  <ReCAPTCHA
                    sitekey="6Lfk5kckAAAAAJrzP9vN1itokN_LIC9Cvx3nmWAW"
                    ref={captchaRef}
                    onChange={validateRecaptcha}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Registration3Modal
        modalOpen={registration3ModalOpen}
        hideModal={hideRegistration3Modal}
        action={showReistration2AfterUsernameValidation}
      />
      <Registration2Modal
        modalOpen={registration2ModalOpen}
        hideModal={hideRegistration2Modal}
        action={completeRegistration2}
        mode={"password_reset_username"}
        userIdentifier={userId}
      />
    </div>
  );
}
