import create from "zustand";

const initialQuery = {
  countPerPage: 10,
  pageLimit: "normal_1000000",
  searchText: "",
  selectedLatest: "",
  selectedOldest: "",
  startDate: "",
  endDate: "",
  subStatus: "*",
  purchaseOption: "*",
  subscriptionCancelled: "*",
};

const inititlState = {
  ids: [],
  refresh: true,
  selType: "M",
  selected: [],
  deSelected: [],
  selectedCount: 0,
  data: {},
  isAllSelected: false,
  query: initialQuery,
};

const initStateSendPage = {
  books: [],
  greeting: "",
  recipientNameType: "",
  item: "",
  currItemPrice: 0,
  currItemName: "",
  subject: "",
  startDate: new Date(),
  time: "0",
  AMPM: "",
  price: 0,
  body: "",
  language: "",
  user: {},
};

export const useSendMailStore = create((set) => ({
  ...inititlState,
  setIds: (value) =>
    set((state) => ({
      isFirstLoad: value,
    })),
  setRefresh: (value) =>
    set((state) => ({
      refresh: value,
    })),
  setSelType: (value) =>
    set((state) => ({
      selType: value,
    })),
  setData: (value) =>
    set((state) => ({
      data: value,
    })),
  setQuery: (key, value) =>
    set((state) => ({
      query: { ...state.query, [key]: value },
    })),
  resetQuery: () =>
    set((state) => ({
      query: initialQuery,
    })),
  setSelected: (value) =>
    set((state) => ({
      selected: value,
    })),
  setDeselected: (value) =>
    set((state) => ({
      deSelected: value,
    })),
  setSelectedCount: (value) =>
    set((state) => ({
      selectedCount: value,
    })),
  setIsAllSelected: (value) =>
    set((state) => ({
      isAllSelected: value ?? (state.isAllSelected = !state.isAllSelected),
    })),
  resetMailStore: () => {
    set(inititlState);
  }, ///END LIST STATE
  ...initStateSendPage,
  setBooks: (value) =>
    set((state) => ({
      books: value,
    })),
  setGreeting: (value) =>
    set((state) => ({
      greeting: value,
    })),
  setRecipientNameType: (value) =>
    set((state) => ({
      recipientNameType: value,
    })),
  setItem: (value) =>
    set((state) => ({
      item: value,
    })),
  setCurrItemName: (value) =>
    set((state) => ({
      currItemName: value,
    })),
  setSubject: (value) =>
    set((state) => ({
      subject: value,
    })),
  setStartDate: (value) =>
    set((state) => ({
      startDate: value,
    })),
  setTime: (value) =>
    set((state) => ({
      time: value,
    })),
  setAMPM: (value) =>
    set((state) => ({
      AMPM: value,
    })),
  setPrice: (value) =>
    set((state) => ({
      price: value,
    })),
  setCurrItemPrice: (value) =>
    set((state) => ({
      currItemPrice: value,
    })),
  setBody: (value) =>
    set((state) => ({
      body: value,
    })),
  setLanguage: (value) =>
    set((state) => ({
      language: value,
    })),
  setUser: (value) =>
    set((state) => ({
      user: value,
    })),
  resetSendPageState: () => {
    set(initStateSendPage);
  },
}));
