import { useSendMailStore } from "../../../../stores/send-mail-store";
import { isResponsiveMode } from "../../../../Utils/helpers";
import Checkbox from "../../../../components/inputs/checkbox";
export default function DesktopPubDonationsTable(props) {
  const mailStore = useSendMailStore((state) => state);

  const { blockedEmails } = props;
  const { shouldMarkItem } = props;
  const { markItem } = props;

  return (
    <table class="table-auto w-full my-4">
      <thead>
        <tr className={window.innerWidth < 720 ? "hidden" : ""}>
          <th class="px-4 py-2 text-gray-600">Item Title</th>
          <th class="px-4 py-2 text-gray-600">Item ID</th>
          <th class="px-4 py-2 text-gray-600">Amount</th>
          <th class="px-4 py-2 text-gray-600">Transaction ID</th>
          <th class="px-4 py-2 text-gray-600">Date</th>
        </tr>
      </thead>
      <tbody>
        {mailStore.data.data
          ? mailStore.data.data.length
            ? mailStore.data.data.map((purchase) => {
                return (
                  <tr
                    className={window.innerWidth < 820 ? "flex flex-col" : ""}
                  >
                    <td
                      class={`${
                        isResponsiveMode() ? "border text-center" : ""
                      } px-4 py-2 font-medium md:hidden `}
                    >
                      {!blockedEmails.includes(purchase?.email) ? (
                        <div className="md:hidden">
                          {" "}
                          <Checkbox
                            checked={shouldMarkItem(purchase.id) ? true : false}
                            handleChange={() => markItem(purchase.id)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                    <td class="border px-4 py-2  font-medium">
                      <h3 className="font-bold md:hidden">Item Title</h3>
                      <p className="my-text-yellow-500">
                        {" "}
                        {purchase.book.group == "standard"
                          ? "File"
                          : "Custom Page"}
                      </p>
                      <p>
                        <a
                          target="_blank"
                          className="underline"
                          href={
                            purchase.book.group == "standard"
                              ? `/publisher/${purchase.book.owneruuid}/publications/series/m/book/${purchase.book.book_id}`
                              : `/web/rich-page/render/${purchase.book.book_uuid}`
                          }
                        >
                          {purchase.book.name}
                        </a>
                      </p>
                    </td>

                    <td class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold md:hidden">Item ID</h3>
                      {purchase.book.item_id}
                    </td>
                    <td class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold md:hidden">Price</h3>$
                      {purchase.amount / 100}
                    </td>
                    <td class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold md:hidden">Transaction ID</h3>
                      {purchase.transaction_id}
                    </td>

                    <td class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold md:hidden">Date</h3>
                      <p>
                        {purchase.refactoredCreatedAtTime
                          ? new Date(
                              purchase.refactoredCreatedAtTime.replace(
                                /-/g,
                                "/"
                              )
                            ).getDate() +
                            "-" +
                            (new Date(
                              purchase.refactoredCreatedAtTime.replace(
                                /-/g,
                                "/"
                              )
                            ).getMonth() +
                              1) +
                            "-" +
                            new Date(
                              purchase.refactoredCreatedAtTime.replace(
                                /-/g,
                                "/"
                              )
                            ).getFullYear()
                          : "N/A"}
                      </p>
                    </td>
                    <td
                      class={`${
                        !isResponsiveMode() ? "border" : ""
                      } px-4 py-2  font-medium `}
                    >
                      {!blockedEmails.includes(purchase?.email) ? (
                        <div className="hidden md:block">
                          {" "}
                          <Checkbox
                            checked={shouldMarkItem(purchase.id) ? true : false}
                            handleChange={() => markItem(purchase.id)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })
            : ""
          : ""}
      </tbody>
    </table>
  );
}
