import create from "zustand";
import { isAuthUser } from "../Utils/helpers";

export const useBookStore = create((set) => ({
  isRenderingImage: false,
  imageZoomMode: false,
  isComponentVisible: true,
  subModalOpen: false,
  isDataSubExpired: false,
  isUserAuthenticated: false,
  isPayToViewPage: false,
  setIsPayToViewPage: (value) =>
    set((state) => ({
      isPayToViewPage: (state.isPayToViewPage = !state.isPayToViewPage),
    })),
  setIsUserAuthenticated: (value) =>
    set((state) => ({
      isUserAuthenticated: isAuthUser() ? true : false,
    })),
  setIsDataSubExpired: (value) =>
    set((state) => ({
      isDataSubExpired: value,
    })),
  setSubModalOpen: (value) =>
    set((state) => ({
      subModalOpen: value,
    })),
  setImageZoomMode: (mode) =>
    set((state) => ({
      imageZoomMode: mode,
    })),
  setIsComponentVisible: (mode) => {
    set((state) => {
      return {
        isComponentVisible: mode
          ? mode
          : (state.isComponentVisible = !state.isComponentVisible),
      };
    });
  },
  setIsRenderingImage: (mode) =>
    set((state) => ({
      isRenderingImage: mode,
    })),
  publisher: {},
  pageAdvertId: "",
  setpageAdvertId: (id) => set((state) => ({ pageAdvertId: id })),
  setPublisherInfo: (field, data) =>
    set((state) => ({ bears: state.bears + 1 })),
}));
