import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import StripeRedirectModal from "../../../modals/stripe-redirect-modal";
import StripeRedirectModeModal from "../../../modals/stripe-redirect-mode-modal";
import DeletePromptModal from "../../../modals/delete-warning-modal";
import StripeDataThresholdSubModal from "../../../modals/stripe-datathreshold-sub-modal";

import {
  isAdmin,
  API_BASE,
  isPublisher,
  getLocation,
  authUserData,
  isAuthUser,
  getClientBaseURL,
} from "../../../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
import Switch from "react-switch";
import PasswordPromptModal from "../../../modals/password-prompt-modal";
import CreateTierModal from "../../../modals/create-tier-modal";
export default function PublisherDashboardStorage() {
  const [tiers, setTiers] = useState({});
  const [showSubModal, setShowSubModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [subSecretKey, setSubSecretKey] = useState();
  const [dataThreshold, setDataThreshold] = useState();
  const [deleteTierModalOpen, setDeleteTierModalOpen] = useState(false);
  const [user, setUser] = useState({});
  const [activeSub, setActiveSub] = useState({});
  const [subsToDisable, setActiveSubsToDisable] = useState([]);
  const [disableUpdateButton, setDisableUpdateButton] = useState(true);

  let { action } = useParams();
  var checker;

  const hideSubModal = () => {
    setSubSecretKey();
    setShowSubModal((prev) => (prev = !prev));
  };

  const openSubModal = (tier) => {
    getStripePaymentSecret(tier);
    setShowSubModal(true);
    setDataThreshold(tier);
  };

  const getStoragePlans = () => {
    let url = API_BASE + "/api/storage/all";
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setTiers(response.data);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const getStripePaymentSecret = (tier) => {
    let formData = new FormData();
    const url =
      API_BASE + "/api/user/payment/datathrshold/get-stripe-payment-secret";
    setProcessing(true);
    axios
      .post(url, {
        threshold_id: tier.uuid,
        user_id: isAuthUser() ? authUserData().uuid : "",
      })
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setSubSecretKey(response.data.secret_key);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", {
          type: "error",
        });
      });
  };

  const getUser = () => {
    let url = API_BASE + "/api/publisher/" + authUserData().uuid + "/optimized";
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.publisher.has_sub_record) {
            localStorage.setItem("has_sub_record", "exists");
          }
          setUser(response.data.publisher);
          let sub = response.data.publisher.activedatasub;
          if (sub) {
            setActiveSub(sub);
          }
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  useEffect(() => {
    if (!isPublisher() || !isAuthUser()) {
      window.location.href = "/";
    }
    getStoragePlans();
    if (!action) {
      //window.location.href = "/publisher/storage";
      setDisableUpdateButton(false);
    }
  }, []);

  useEffect(() => {
    if (tiers.data && activeSub.active) {
      let activeThreshold = activeSub.datathreshold.threshold;
      console.log("threshold", activeThreshold);
      const filteredTierToDisable = tiers.data
        .filter(
          (d) =>
            d.uuid == activeSub.datathreshold.uuid ||
            Number(d.threshold) < Number(activeThreshold)
        )
        .map((d) => d.uuid);
      setActiveSubsToDisable(filteredTierToDisable);
    }

    if (activeSub.active && action && subsToDisable.length) {
      setDisableUpdateButton(false);
      clearInterval(checker);
    }
  }, [activeSub]);

  useEffect(() => {
    if (tiers.data && action) {
      checker = setInterval(() => {
        getUser();
      }, 4000);
    } else {
      getUser();
    }
  }, [tiers]);

  useEffect(() => {
    if (user.id) {
    }
  }, [user]);

  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">Storage Tiers</h1>
        </div>
        <div className="flex gap-2">
          <BasicButton
            title={"Payment History"}
            handleClick={() =>
              (window.location.href = "/publisher/storage/payments")
            }
          />
          <BasicButton
            classes={"my-bg-yellow-500 text-black"}
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center gap-4 mt-8 items-center mb-4">
        {/* <h1 className="font-bold text-xl">Storage Tiers</h1> */}
        <div className="w-full flex justify-center px-6 ap-4">
          <div className="flex gap-4 flex flex-col md:flex-row">
            <div>
              {" "}
              Current Plan:{" "}
              {activeSub.id && activeSub.active ? (
                <b>{activeSub.datathreshold.name} </b>
              ) : (
                <b className="font-bold">Free Tier</b>
              )}
            </div>
            <div>
              {" "}
              Data Used:{" "}
              {user ? (
                <b className="font-bold">{user.active_data_usage} </b>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </div>

        <div className="w-full p-8 flex flex-col">
          <div className="hidden md:flex justify-between w-full border-b p-4">
            {" "}
            <div className="text-center w-full font-bold">Name</div>
            <div className="text-center w-full font-bold">Data Threshold</div>
            <div className="text-center w-full font-bold">Price</div>
            <div className="text-center w-full font-bold">Action</div>
          </div>
          {user.id ? (
            <div className="flex flex-col gap-2 md:flex-row justify-between w-full border-b p-4 bg-gray-50 mb-1">
              {" "}
              <div className="text-center w-full">
                {" "}
                <p className="md:hidden font-bold">Name</p>
                Free Tier
              </div>
              <div className="text-center w-full">
                <p className="md:hidden font-bold">Threshold</p>
                {user.system_setting.free_storage_tier}
              </div>
              <div className="text-center w-full">N/A</div>
              <div className="text-center w-full">N/A</div>
            </div>
          ) : (
            ""
          )}
          {tiers.data
            ? tiers.data.map((tier) => (
                <div className="flex flex-col gap-2 md:flex-row justify-between w-full border-b p-4 bg-gray-50 mb-1">
                  {" "}
                  <div className="text-center w-full">
                    <p className="md:hidden font-bold">Name</p>
                    {tier.name}
                  </div>
                  <div className="text-center w-full">
                    {" "}
                    <p className="md:hidden font-bold">Threshold</p>
                    {tier.threshold}
                  </div>
                  <div className="text-center w-full">
                    {" "}
                    <p className="md:hidden font-bold">Price</p>$
                    {tier.unit_amount} / Month
                  </div>
                  <div className="text-center w-full">
                    <BasicButton
                      title={"Upgrade"}
                      disabled={
                        (subsToDisable.length &&
                          subsToDisable.includes(tier.uuid)) ||
                        disableUpdateButton
                      }
                      classes={`${
                        (subsToDisable.length &&
                          subsToDisable.includes(tier.uuid)) ||
                        disableUpdateButton
                          ? "bg-gray-500 hover:bg-gray-500"
                          : "bg-teal-500 hover:bg-teal-500"
                      }  w-full`}
                      handleClick={() => openSubModal(tier)}
                    />
                  </div>
                </div>
              ))
            : ""}
        </div>
      </div>
      <StripeDataThresholdSubModal
        modalOpen={showSubModal}
        hideModal={hideSubModal}
        dataThreshold={dataThreshold}
        subSecretKey={subSecretKey}
        activeSub={activeSub}
      />
    </>
  );
}
