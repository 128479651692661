import EditorTooltip from "../../tooltip/editor-tooltip";
import Checkbox from "../../../../../../../components/inputs/checkbox";
export default function ShortOnPreferred(props) {
  const { productMeta, handleMetaChange } = props;

  return (
    <div className="flex flex-col items-center gap-2 w-full md:w-6/12 my-5">
      <div className="inline-flex items-center gap-1 relative">
        <div>
          <Checkbox
            handleChange={(e) =>
              handleMetaChange(
                e,
                "short_durr_on_preferred_available",
                "checkbox"
              )
            }
            checked={!productMeta.short_durr_on_preferred_available}
          />
        </div>
        <div>
          <span className="text-sm">
            Hide Short Purchase Durations when Preferred Purchase Duration Is
            Available
          </span>
        </div>
        <EditorTooltip
          body={
            "This hides the purchase button(s) for short purchase durations on dates when the preferred purchase duration is available."
          }
          top={"-170px"}
          right={"-80px"}
        />
      </div>
    </div>
  );
}
