import axios from "axios";

export function isAuthUser() {
  return localStorage.getItem("user");
}

export function authToken() {
  return localStorage.getItem("token");
}

export function authUserData() {
  return JSON.parse(localStorage.getItem("user"));
}

export function isRegistrationComplete() {
  return localStorage.getItem("registration_type") ? false : true;
}

export function isAdmin() {
  if (isAuthUser()) {
    return JSON.parse(localStorage.getItem("user")).roles.filter(
      (r) => r.name == "admin"
    ).length
      ? true
      : false;
  }
  return false;
}

export function isSales() {
  if (isAuthUser()) {
    return JSON.parse(localStorage.getItem("user")).roles.filter(
      (r) => r.name == "sales"
    ).length
      ? true
      : false;
  }
  return false;
}

export function isSponsor() {
  if (isAuthUser()) {
    return JSON.parse(localStorage.getItem("user")).roles.filter(
      (r) => r.name == "sponsor"
    ).length
      ? true
      : false;
  }
  return false;
}

export function isReader() {
  if (isAuthUser()) {
    return JSON.parse(localStorage.getItem("user")).roles.filter(
      (r) => r.name == "reader"
    ).length
      ? true
      : false;
  }
  return false;
}

export function isPublisher() {
  if (isAuthUser()) {
    return JSON.parse(localStorage.getItem("user")).roles.filter(
      (r) => r.name == "publisher"
    ).length
      ? true
      : false;
  }
  return false;
}

export function isSupplier() {
  if (isAuthUser()) {
    return JSON.parse(localStorage.getItem("user")).roles.filter(
      (r) => r.name == "supplier"
    ).length
      ? true
      : false;
  }
  return false;
}

export function isPublisherAndReader() {
  const hasReaderRole = false;
  const hasPublisherRole = false;
  if (isAuthUser) {
    JSON.parse(localStorage.getItem("user")).roles.map((data) => {
      if (data.name == "reader") {
        hasReaderRole = true;
      }

      if (data.name == "publisher") {
        hasPublisherRole = true;
      }
    });
    return hasReaderRole && hasPublisherRole;
  }
  return false;
}

export function ValidateEmail(input) {
  if (!input) return false;
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (input.match(validRegex)) {
    return true;
  } else {
    return false;
  }
}

export const globalAds = [
  { image: "/ad_1.PNG", link: "https://www.namecheap.com/hosting/" },
  {
    image: "/ad_3.PNG",
    link: "https://themeforest.net/item/newspaper/5489609",
  },
  {
    image: "/ad_4.PNG",
    link: "https://www.zonkafeedback.com/?utm_source=adroll&utm_medium=PPC&utm_campaign=RM_Other_countries",
  },
  {
    image: "/ad_5.png",
    link: " https://www.pngmart.com/image/tag/computer-mouse",
  },
  {
    image: "/ad_6.png",
    link: "https://www.microsoft.com/en/microsoft-365/business/compare-all-microsoft-365-business-products-b?market=ng&ef_id=_k_CjwKCAjwoqGnBhAcEiwAwK-Okc_01Q6u6--zYLVS92wl9hrLmTSkstdOG8auVdGfxC29bEilKbGaBhoCqL0QAvD_BwE_k_&OCID=AIDcmm3kqfz21s_SEM__k_CjwKCAjwoqGnBhAcEiwAwK-Okc_01Q6u6--zYLVS92wl9hrLmTSkstdOG8auVdGfxC29bEilKbGaBhoCqL0QAvD_BwE_k_&gclid=CjwKCAjwoqGnBhAcEiwAwK-Okc_01Q6u6--zYLVS92wl9hrLmTSkstdOG8auVdGfxC29bEilKbGaBhoCqL0QAvD_BwE",
  },
  {
    image: "/ad_7.png",
    link: "https://www.lg.com/africa/computer-electronics",
  },
  {
    image: "/ad_8.png",
    link: "https://blinkforhome.com/",
  },
  {
    image: "/ad_9.png",
    link: "https://ielts.idp.com/lp/ielts-by-idp-app",
  },
  {
    image: "/ad_10.png",
    link: "https://acrossthehorizon.net/blog/how-to-relocate-to-canada-within-3-6-months",
  },
  {
    image: "/ad_11.png",
    link: "https://www.binance.com/",
  },
  {
    image: "/ad_12.png",
    link: "https://www.airbnb.com/",
  },
  {
    image: "/ad_13.png",
    link: "https://www.uber.com/ng/en/",
  },
  {
    image: "/ad_14.png",
    link: "https://www.paypal.com/ng/home",
  },
  {
    image: "/ad_15.png",
    link: "https://stripe.com/",
  },
  {
    image: "/ad_16.png",
    link: "https://www.flyzipline.com/",
  },
  {
    image: "/ad_17.png",
    link: "https://www.rolex.com/watchmaking/excellence-in-the-making/hallmarks-of-excellence?ef_id=CjwKCAjwoqGnBhAcEiwAwK-OkcXwoLQxNNaruULhYWvVI1c43PB3X47nghLxkFcrVX706y4IQby4TxoCE74QAvD_BwE:G:s&s_kwcid=AL!141!3!660516330666!e!!g!!rolex!8671263043!87790163035",
  },
];

export const getRandomAdSlot = () => {
  return globalAds[(Math.random() * globalAds.length) | 0];
};

export const sizes = () => {
  let contentWidth =
    [...document.body.children].reduce(
      (a, el) => Math.max(a, el.getBoundingClientRect().right),
      0
    ) - document.body.getBoundingClientRect().x;

  return {
    windowWidth: document.documentElement.clientWidth,
    windowHeight: document.documentElement.clientHeight,
    pageWidth: Math.min(document.body.scrollWidth, contentWidth),
    pageHeight: document.body.scrollHeight,
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    pageX: document.body.getBoundingClientRect().x,
    pageY: document.body.getBoundingClientRect().y,
    screenX: -window.screenX,
    screenY: -window.screenY - (window.outerHeight - window.innerHeight),
  };
};

export function isResponsiveMode() {
  return sizes().windowWidth < 1000;
}

// export const API_BASE = "/api";
// export const BASE_URL = "https://litzapp.com/api";

export const API_BASE = getLocation().hostname == "litzapp.com" ? "/api" : "";
export const BASE_URL =
  getLocation().hostname == "litzapp.com" ? "https://litzapp.com/api" : "";

export const BASE_URL_WITH_LOCAL =
  getLocation().hostname == "litzapp.com"
    ? "https://litzapp.com/api"
    : "https://wordalbums1.test";

export const STRIPE_PK =
  getLocation().hostname == "litzapp.com"
    ? "pk_live_gTjG4x72F2E00sQ28Q9VTjMi"
    : "pk_test_51LOIxbEzLEQuHiLRcxD8NPU1OkQnqVd4tJQNqH0Ss5SMXplVrH3K29BZM7gEjAlBW95sQiCOVHuhQPdDhKohrIA900cyouHzVO";

export function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("roles");
  localStorage.removeItem("platform");
  window.location.href = "/login";
}

export function getLocation(href) {
  var location = document.createElement("a");
  location.href = href;
  // IE doesn't populate all link properties when setting .href with a relative URL,
  // however .href will return an absolute URL which then can be used on itself
  // to populate these additional fields.
  if (location.host == "") {
    location.href = location.href;
  }
  return location;
}

export function getClientBaseURL() {
  return `${getLocation().protocol}//${getLocation().hostname}`;
}

export function axiosRequestInstance() {
  return axios.create({
    headers: {
      Domain: getLocation().hostname,
    },
  });
}

export function bookPriceWithSystemFees(book) {
  if (
    book &&
    book.system_setting &&
    book.system_setting.constant_fee_on_payments
  ) {
    return (
      Number(book.price) + Number(book.system_setting.constant_fee_on_payments)
    );
  }

  return book.price;
}

export function donationPriceWithSystemFees(book, donationAmount) {
  if (
    book &&
    book.system_setting &&
    book.system_setting.constant_fee_on_payments
  ) {
    return (
      Number(donationAmount) +
      Number(book.system_setting.constant_fee_on_payments)
    );
  }

  return donationAmount;
}

export function subscritionCostWithSystemFees(book) {
  return book.owner.subscription_cost_with_service_charge;
}

export function getBookType(type) {
  if (type == "book") return "Books";
  if (type == "comic") return "Comics";
  if (type == "photo-album") return "Photo Albums";
  if (type == "custom") return "Page";
}

export function generateItemLink(item) {
  if (getLocation().hostname == "litzapp.com") {
    if (item.group == "standard")
      return `https://litzapp.com/web/reader/${item.uuid}`;
    return `https://litzapp.com/web/rich-page/render/${item.uuid}`;
  } else {
    if (item.group == "standard")
      return `http://localhost:3000/web/reader/${item.uuid}`;
    return `http://localhost:3000/web/rich-page/render/${item.uuid}`;
  }
}

export function generateSalesApplicationLink(id) {
  if (getLocation().hostname == "litzapp.com") {
    return `https://litzapp.com/supplier/signup/ref/${id}`;
  } else {
    return `http://localhost:3000/supplier/signup/ref/${id}`;
  }
}
