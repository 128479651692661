export default function BannerButton(props) {
  const { displayBannerBox } = props;
  return (
    <button
      className="border border-gray rounded-lg px-2 py-1 m-1"
      onClick={() => {
        displayBannerBox();
      }}
    >
      <span className="px-1">Add Banner</span>
    </button>
  );
}
