import React, { useState, useEffect, useRef, forwardRef } from "react";
import { isAuthUser } from "../../../Utils/helpers";
export default function RightPagination(props) {
  const { page, bookPagesCount, handleClick } = props;

  //   const canPageRight= () =>{
  //     if(isAuthUser() &&  page !== bookPagesCount - 1){
  //       return true
  //     }

  //     if (!isAuthUser() && page == bookPagesCount) {
  //       return true;
  //     }
  //   }

  return (
    <>
      <span
        className="p-4 rounded-full cursor-pointer border border-white"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
        onClick={() => handleClick()}
        className={
          page !== bookPagesCount - 1
            ? "p-3 px-4 rounded-full cursor-pointer border border-white text-white z-50"
            : "hiden"
        }
      >
        <span className={page !== bookPagesCount - 1 ? "text-white" : ""}>
          ►|
        </span>
      </span>
    </>
  );
}
