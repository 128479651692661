import {
  authUserData,
  isAdmin,
  isAuthUser,
  isPublisher,
  API_BASE,
  isSupplier,
} from "../../Utils/helpers";
import { useNavigate } from "react-router-dom";

export default function PublisherLeftNav() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    localStorage.removeItem("platform");
    localStorage.removeItem("has_sub_record");
    localStorage.removeItem("has_r_e_product");
    localStorage.removeItem("registration_type");
    localStorage.removeItem("resume_supplier_application");
    window.location.href = "/";
  };

  return (
    <ul class="space-y-2 space-x-6">
      <li>
        {" "}
        <div
          className="hidden md:flex md:justify-center my-2 cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img class=" w-2/12" src="/litzap_logo.png" alt="" />
        </div>
      </li>
      <li>
        <a
          href="/"
          class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
        >
          <i
            class="fa fa-home
                             px-1 pt-1 rounded text-xl md:text-lg text-gray-500"
          ></i>
          <span class="ml-3">Home</span>
        </a>
      </li>
      <li>
        <a
          href={`/publisher/publications/options/${authUserData().uuid}`}
          class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
        >
          <svg
            class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
          </svg>
          <span class="flex-1 ml-3 whitespace-nowrap">
            {isAdmin() ? "Publishers" : "Dashboard"}
          </span>
        </a>
      </li>
      <>
        {localStorage.getItem("has_r_e_product") ? (
          <li>
            <a
              href="/publisher/product/account/calendar"
              class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <i
                class="fa fa-money-bill
                             px-1 pt-1 rounded text-xl md:text-lg text-gray-500"
              ></i>
              <span class="flex-1 ml-3 whitespace-nowrap">
                Product Calendar
              </span>
            </a>
          </li>
        ) : (
          ""
        )}

        <li>
          <a
            href="/publisher/product-transactions/single"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <i
              class="fa fa-money-bill
                             px-1 pt-1 rounded text-xl md:text-lg text-gray-500"
            ></i>
            <span class="flex-1 ml-3 whitespace-nowrap">Product Buys</span>
          </a>
        </li>
        <li>
          <a
            href="/publisher/product-transactions/recurring"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <i
              class="fa fa-money-bill
                             px-1 pt-1 rounded text-xl md:text-lg text-gray-500"
            ></i>
            <span class="flex-1 ml-3 whitespace-nowrap">
              Product Subscriptions
            </span>
          </a>
        </li>
        <li>
          <a
            href="/publisher/subscriptions"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <i
              class="fa fa-envelope
                             px-1 pt-1 rounded text-xl md:text-lg text-gray-500"
            ></i>
            <span class="flex-1 ml-3 whitespace-nowrap">
              Request Update List
            </span>
          </a>
        </li>
        <li>
          <a
            href="/publisher/trackers"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <i
              class="fa fa-envelope
                             px-1 pt-1 rounded text-xl md:text-lg text-gray-500"
            ></i>
            <span class="flex-1 ml-3 whitespace-nowrap">Tracker(s) List</span>
          </a>
        </li>
        {/* <li>
          <a
            href="/publisher/hire/purchases"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <i
              class="fa fa-money-bill
                             px-1 pt-1 rounded text-xl md:text-lg text-gray-500"
            ></i>
            <span class="flex-1 ml-3 whitespace-nowrap">
              Hire Purchase List
            </span>
          </a>
        </li> */}
        <li>
          <a
            href={`/publisher/create/options`}
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <svg
              class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
              <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
            </svg>
            <span class="ml-3">Create</span>
          </a>
        </li>
        <li>
          <a
            href="/publisher/purchases"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <i
              class="fa fa-money-bill
                             px-1 pt-1 rounded text-xl md:text-lg text-gray-500"
            ></i>
            <span class="flex-1 ml-3 whitespace-nowrap">Content Purchases</span>
          </a>
        </li>
        <li>
          <a
            href={`/publisher/subscribers`}
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <svg
              class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>
              <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>
            </svg>
            <span class="flex-1 ml-3 whitespace-nowrap">
              Content Subscribers
            </span>
          </a>
        </li>
        <li>
          <a
            href="/publisher/donations"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <svg
              class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>
              <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>
            </svg>
            <span class="flex-1 ml-3 whitespace-nowrap">Content Donations</span>
          </a>
        </li>
        {isSupplier() && (
          <li>
            <a
              href={`/publisher/supplier/profile`}
              class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <svg
                class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>
                <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>
              </svg>
              <span class="flex-1 ml-3 whitespace-nowrap">
                Supplier Profile
              </span>
            </a>
          </li>
        )}
      </>

      <li>
        <a
          href="/publisher/my/product-transactions/single"
          class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
        >
          <i
            class="fa fa-money-bill
                             px-1 pt-1 rounded text-xl md:text-lg text-gray-500"
          ></i>
          <span class="flex-1 ml-3 whitespace-nowrap">My Product Buys</span>
        </a>
      </li>
      <li>
        <a
          href="/publisher/my/product-transactions/recurring"
          class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
        >
          <i
            class="fa fa-money-bill
                             px-1 pt-1 rounded text-xl md:text-lg text-gray-500"
          ></i>
          <span class="flex-1 ml-3 whitespace-nowrap">
            My Product Subscriptions
          </span>
        </a>
      </li>
      <li>
        <a
          href="/publisher/my/requested-updates"
          class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
        >
          <i
            class="fa fa-money-bill
                             px-1 pt-1 rounded text-xl md:text-lg text-gray-500"
          ></i>
          <span class="flex-1 ml-3 whitespace-nowrap">
            My Requested Updates
          </span>
        </a>
      </li>
      {isSupplier() && (
        <li>
          <a
            href={`/publisher/supplier/my-supplier-contracts`}
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <svg
              class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>
              <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>
            </svg>
            <span class="flex-1 ml-3 whitespace-nowrap">
              My Supplier Contracts
            </span>
          </a>
        </li>
      )}
      {isSupplier() && (
        <li>
          <a
            href={`/publisher/supplier/my-customer-services`}
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <svg
              class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>
              <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>
            </svg>
            <span class="flex-1 ml-3 whitespace-nowrap">
              My Customer Services
            </span>
          </a>
        </li>
      )}
      <>
        <li>
          <a
            href="/publisher/my/purchases"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <i
              class="fa fa-money-bill
                             px-1 pt-1 rounded text-xl md:text-lg text-gray-500"
            ></i>
            <span class="flex-1 ml-3 whitespace-nowrap">My Content Buys</span>
          </a>
        </li>
        {/* <li>
          <a
            href="/publisher/my/hire/purchases"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <i
              class="fa fa-money-bill
                             px-1 pt-1 rounded text-xl md:text-lg text-gray-500"
            ></i>
            <span class="flex-1 ml-3 whitespace-nowrap">My Hires</span>
          </a>
        </li> */}
        <li>
          <a
            href="/publisher/my/subscriptions"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <i
              class="fa fa-money-bill
                             px-1 pt-1 rounded text-xl md:text-lg text-gray-500"
            ></i>
            <span class="flex-1 ml-3 whitespace-nowrap">My Content Subs</span>
          </a>
        </li>

        <li>
          <a
            href="/publisher/my/ads-record"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <i
              class="fa fa-money-bill
                             px-1 pt-1 rounded text-xl md:text-lg text-gray-500"
            ></i>
            <span class="flex-1 ml-3 whitespace-nowrap">My Ads Record</span>
          </a>
        </li>
      </>
      <li>
        <a
          href={`/publisher/settings/${authUserData().uuid}/default`}
          class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
        >
          <svg
            class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>
            <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>
          </svg>
          <span class="flex-1 ml-3 whitespace-nowrap">Account Setting</span>
        </a>
      </li>

      {localStorage.getItem("has_sub_record") ? (
        <li>
          <a
            href="/publisher/storage"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <svg
              class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
            </svg>
            <span class="flex-1 ml-3 whitespace-nowrap">Data Threshold</span>
          </a>
        </li>
      ) : (
        ""
      )}
      <li onClick={() => handleLogout()}>
        <div class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer">
          {" "}
          <svg
            class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>
            <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>
          </svg>
          <span class="flex-1 ml-3 whitespace-nowrap">Logout</span>
        </div>
      </li>
    </ul>
  );
}
