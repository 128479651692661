import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import WebHeader from "./web/header";
import { isAdmin } from "../Utils/helpers";
import { isAuthUser, API_BASE } from "../Utils/helpers";
import Footer from "../layout/footer";
import AdSense from "react-adsense";
import axios from "axios";

export default function About() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const naviagte = useNavigate();

  return (
    <>
      {" "}
      <WebHeader />
      <div class=" px-6 ">
        <br />
        <h1 class="text-2xl text-gray-800 font-bold my-2 text-center">
          About Us
        </h1>
        <br />
        <div class="flex flex-col text-center md:text-left justify-evenly items-center">
          <div class="flex flex-col w-full md:w-7/12">
            <div></div>

            <h4 className="font-bold">Welcome to Litzapp!</h4>
            <p>
              Litzapp is a cutting-edge monetization platform designed to
              empower entrepreneurs and creators to transform their ideas,
              products, services, and content into sustainable sources of
              income. Owned and operated by Remote Analytics Jobs Inc., Litzapp
              is dedicated to providing a versatile and user-friendly
              environment where innovation meets opportunity.
            </p>
            <br />
            <h4 className="font-bold">Our Mission</h4>
            <p>
              At Litzapp, our mission is to democratize the monetization process
              by offering accessible tools and resources that enable anyone to
              leverage their skills and creativity. We strive to create a
              dynamic ecosystem where Users can seamlessly monetize their
              offerings, connect with a global audience, and achieve financial
              independence.
            </p>
            <br />
            <h4 className="font-bold">What We Offer</h4>
            <ul className="pl-5 list-disc">
              <li>
                Product Monetization: Whether you're an artisan, developer, or
                entrepreneur, Litzapp provides the tools you need to sell your
                products to a global audience. Our platform supports both
                physical and digital products, ensuring you have the flexibility
                to showcase your unique offerings.
              </li>
              <li>
                Service Monetization: Service providers can easily list and
                manage their offerings, connect with clients, and receive
                payments securely. From consulting and coaching to freelancing
                and beyond, Litzapp helps you monetize your expertise.
              </li>
              <li>
                Content Monetization: Content creators can publish and monetize
                their work, including videos, articles, music, and more. Litzapp
                supports various monetization models, including subscriptions,
                pay-per-view, and ad revenue sharing, giving you the freedom to
                choose how you want to earn.
              </li>
              <li>
                Secure Transactions: We partner with industry-leading payment
                processors like Stripe to ensure secure and reliable
                transactions. Our platform is designed to protect both buyers
                and sellers, providing peace of mind with every transaction.
              </li>
            </ul>
            <br />
            <h4 className="font-bold">Our Values</h4>
            <ul className="pl-5 list-disc">
              <li>
                Innovation: We are committed to continuous innovation, always
                seeking new ways to enhance our platform and offer cutting-edge
                features that meet the evolving needs of our Users.
              </li>
              <li>
                Accessibility: Litzapp is built with accessibility in mind,
                ensuring that anyone, regardless of technical expertise, can
                easily use our platform to monetize their offerings.
              </li>
              <li>
                Community: We believe in the power of community and strive to
                create a supportive and collaborative environment where Users
                can connect, share insights, and grow together.
              </li>
              <li>
                Transparency: We maintain transparent practices and policies,
                providing clear information about fees, terms, and processes to
                ensure Users can make informed decisions.
              </li>
            </ul>
            <br />
            <h4 className="font-bold">Our Story</h4>
            <p>
              Founded with a vision to revolutionize the way people monetize
              their talents and passions, Litzapp aims to become the trusted
              platform for creators and entrepreneurs worldwide. Our team of
              dedicated professionals is passionate about empowering individuals
              to achieve their financial goals and unlock new opportunities.
            </p>
            <br />
            <h4 className="font-bold">Join Us</h4>
            <p>
              Whether you're a creator looking to monetize your work, a buyer
              seeking unique products and services, or someone interested in
              exploring the limitless possibilities of our platform, Litzapp is
              here to support you every step of the way. Join us and be part of
              a vibrant community that values creativity, innovation, and
              success.
            </p>
            <p>
              Thank you for choosing Litzapp. Together, we can turn your vision
              into reality.
            </p>
            <p>
              For more information or to get started, visit{" "}
              <span className="text-blue-500">
                <a href="https://litzapp.com" target="_blank">
                  Litzapp.com
                </a>{" "}
              </span>
              or contact us at{" "}
              <span className="text-blue-500">
                <a href="mailto:customerservice@litzapp.com">
                  customerservice@litzapp.com
                </a>
              </span>
              .
            </p>
            <br />
            <h4 className="font-bold">Contact Us</h4>
            <p>
              Remote Analytics Jobs Inc.
              <br />
              <br />
              126 Southwest 17th Road, Unit 509
              <br />
              Miami, Florida, 33129
              <br />
              United States of America.
              <br />
              <span className="text-blue-500">
                <a href="mailto:customerservice@litzapp.com">
                  customerservice@litzapp.com
                </a>
              </span>
            </p>
            <p>+ 1 (305) 927-1096</p>
            <br />
            <p>
              Discover the future of monetization with Litzapp. Your journey
              starts here.
            </p>

            <br />

            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
