import { useSendMailStore } from "../../../../stores/send-mail-store";
import BasicButton from "../../../../components/buttons/basic-button";
import { isResponsiveMode } from "../../../../Utils/helpers";
import { useNavigate } from "react-router-dom";
export default function DesktopTable(props) {
  const mailStore = useSendMailStore((state) => state);
  const { preDeleteSet } = props;
  const navigate = useNavigate();
  return (
    <div className="overflow-x-scroll md:h-screen w-full">
      <table class="table-auto w-full my-4">
        <thead>
          <tr className={window.innerWidth < 720 ? "hidden" : ""}>
            <th class="px-4 py-2 text-gray-600">Set Name</th>
            {/* <th class="px-4 py-2 text-gray-600">Country</th> */}
            <th class="px-4 py-2 text-gray-600">Phone Number</th>
            <th class="px-4 py-2 text-gray-600">Email Address</th>
            <th class="px-4 py-2 text-gray-600">Date Created</th>
          </tr>
        </thead>
        <tbody className="w-full">
          {mailStore?.data?.data &&
            mailStore.data.data.map((d) => {
              return (
                <tr
                  className={
                    window.innerWidth < 820 ? "flex flex-col items-center " : ""
                  }
                >
                  <td class="border  px-4 py-2  font-medium">
                    {" "}
                    <small className="text-base"> {d.name}</small>
                  </td>
                  <td class="border  px-4 py-2  font-medium">
                    {" "}
                    <p>
                      <small className="text-base"> {d.phone}</small>
                    </p>
                    <small className="text-base">
                      {" "}
                      Verified: {d.phone_verified ? "Yes" : "No"}
                    </small>
                  </td>
                  <td class="border border-gray px-4 py-2  font-medium">
                    <div className="text-center md:text-left">
                      <p className="break-words">
                        <small className="text-base"> {d.email}</small>
                      </p>
                      <p className="break-words">
                        <small className="text-base">
                          {" "}
                          Verified: {d.email_verified ? "Yes" : "No"}
                        </small>
                      </p>
                    </div>
                  </td>

                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Date</h3>

                    {d?.refactoredCreatedAtTime
                      ? new Date(
                          d?.refactoredCreatedAtTime.replace(/-/g, "/")
                        ).getDate() +
                        "-" +
                        (new Date(
                          d?.refactoredCreatedAtTime.replace(/-/g, "/")
                        ).getMonth() +
                          1) +
                        "-" +
                        new Date(
                          d.refactoredCreatedAtTime.replace(/-/g, "/")
                        ).getFullYear()
                      : "N/A"}
                  </td>
                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Status</h3>
                    <div className="flex flex-col items-center gap-2">
                      {" "}
                      <BasicButton
                        disabled={!d.id}
                        title={"Edit"}
                        classes={
                          "bg-black-500 text-white  hover:text-white  w-full"
                        }
                        handleClick={() =>
                          navigate(
                            `/publisher/supplier/my-customer-services/set/${d.uuid}/edit`
                          )
                        }
                      />
                      <BasicButton
                        disabled={!d.uuid}
                        title={"Delete"}
                        classes={
                          "bg-red-500 text-white  hover:text-white  hover:bg-red-500 w-full"
                        }
                        handleClick={() => preDeleteSet(d)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
