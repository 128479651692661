import TextField from "../components/inputs/text-input";
import BasicButton from "../components/buttons/basic-button";
import { useBookStore } from "../stores/book-store";
import InsertImage from "../pages/publisher/rich-page/components/insert-image";
import { isResponsiveMode } from "../Utils/helpers";
import YouTube from "react-youtube";
import { useState } from "react";

export default function SocialsLinkEditor({
  modalOpen,
  hideLinkModal,
  handleSocialsLinkChange,
  socialLinkValue,
  save,
}) {
  const handleMetaChange = (e, key, type) => {
    // state.setProductMeta((prev) => ({ ...prev, [key]: e }));
  };
  return (
    <div
      className={` absolute z-50 overflow-y-auto top-0 w-full left-0  ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-6/12 h-4/12 "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className="rounded rounded-full p-0 -top-2 -right-1 absolute px-1 cursor-pointer opacity-75"
            onClick={() => hideLinkModal()}
          >
            <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
          </div>
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2  flex flex-col justify-center items-center">
              <h1 className="text-2xl font-bold text-center">
                {/* {getActiveWidgetTitle(currentEditingWidget, widgets)} */}
                Social Media
              </h1>
              <div className="m-2 mb-2 w-full md:w-6/12">
                <label className="text-black">Instagram</label>
                <div className="flex gap-2 items-center">
                  <TextField
                    classes={"p-6 my-2"}
                    placeholder={"Enter Link..."}
                    handleChange={(e) =>
                      handleSocialsLinkChange(e, "instagram", "text")
                    }
                    value={socialLinkValue?.instagram}
                  />
                </div>
              </div>
              <div className="m-2 mb-2 w-full md:w-6/12">
                <label className="text-black">Facebook </label>
                <div className="flex gap-2 items-center">
                  <TextField
                    classes={"p-6 my-2"}
                    placeholder={"Enter Link..."}
                    handleChange={(e) =>
                      handleSocialsLinkChange(e, "facebook", "text")
                    }
                    value={socialLinkValue?.facebook}
                  />
                </div>
              </div>
              <div className="m-2 mb-2 w-full md:w-6/12">
                <label className="text-black">X (Twitter)</label>
                <div className="flex gap-2 items-center">
                  <TextField
                    classes={"p-6 my-2"}
                    placeholder={"Enter Link..."}
                    handleChange={(e) =>
                      handleSocialsLinkChange(e, "twitter", "text")
                    }
                    value={socialLinkValue?.twitter}
                  />
                </div>
              </div>
              <div className="m-2 mb-2 w-full md:w-6/12">
                <label className="text-black">YouTube</label>
                <div className="flex gap-2 items-center">
                  <TextField
                    classes={"p-6 my-2"}
                    placeholder={"Enter Link..."}
                    handleChange={(e) =>
                      handleSocialsLinkChange(e, "youtube", "text")
                    }
                    value={socialLinkValue?.youtube}
                  />
                </div>
              </div>
              <div className="m-2 mb-2 w-full md:w-6/12">
                <label className="text-black">TikTok</label>
                <div className="flex gap-2 items-center">
                  <TextField
                    classes={"p-6 my-2"}
                    placeholder={"Enter Link..."}
                    handleChange={(e) =>
                      handleSocialsLinkChange(e, "tiktok", "text")
                    }
                    value={socialLinkValue?.tiktok}
                  />
                </div>
              </div>
              <div className="m-2 mb-2 w-full md:w-6/12">
                <label className="text-black">Pinterest</label>
                <div className="flex gap-2 items-center">
                  <TextField
                    classes={"p-6 my-2"}
                    placeholder={"Enter Link..."}
                    handleChange={(e) =>
                      handleSocialsLinkChange(e, "pinterest", "text")
                    }
                    value={socialLinkValue?.pinterest}
                  />
                </div>
              </div>
              <div className="m-2 mb-2 w-full md:w-6/12">
                <label className="text-black">LinkedIn</label>
                <div className="flex gap-2 items-center">
                  <TextField
                    classes={"p-6 my-2"}
                    placeholder={"Enter Link..."}
                    handleChange={(e) =>
                      handleSocialsLinkChange(e, "linkedin", "text")
                    }
                    value={socialLinkValue?.linkedin}
                  />
                </div>
              </div>
              <div className="m-2 mb-2 w-full md:w-6/12">
                <label className="text-black">Snapchat</label>
                <div className="flex gap-2 items-center">
                  <TextField
                    classes={"p-6 my-2"}
                    placeholder={"Enter Link..."}
                    handleChange={(e) =>
                      handleSocialsLinkChange(e, "snapchat", "text")
                    }
                    value={socialLinkValue?.snapchat}
                  />
                </div>
              </div>
              <div className="m-2 mb-2 w-full md:w-6/12">
                <label className="text-black">Reddit</label>
                <div className="flex gap-2 items-center">
                  <TextField
                    classes={"p-6 my-2"}
                    placeholder={"Enter Link..."}
                    handleChange={(e) =>
                      handleSocialsLinkChange(e, "reddit", "text")
                    }
                    value={socialLinkValue?.reddit}
                  />
                </div>
              </div>
              <div className="m-2 mb-2 w-full md:w-6/12">
                <label className="text-black">WhatsApp</label>
                <div className="flex gap-2 items-center">
                  <TextField
                    classes={"p-6 my-2"}
                    placeholder={"Enter Link..."}
                    handleChange={(e) =>
                      handleSocialsLinkChange(e, "whatsapp", "text")
                    }
                    value={socialLinkValue?.whatsapp}
                  />
                </div>
              </div>
              <div className="m-2 mb-2 w-full md:w-6/12">
                <label className="text-black">Tumblr</label>
                <div className="flex gap-2 items-center">
                  <TextField
                    classes={"p-6 my-2"}
                    placeholder={"Enter Link..."}
                    handleChange={(e) =>
                      handleSocialsLinkChange(e, "tumblr", "text")
                    }
                    value={socialLinkValue?.tumblr}
                  />
                </div>
              </div>
              <div className="m-2 mb-2 w-full md:w-6/12">
                <label className="text-black">Discord</label>
                <div className="flex gap-2 items-center">
                  <TextField
                    classes={"p-6 my-2"}
                    placeholder={"Enter Link..."}
                    handleChange={(e) =>
                      handleSocialsLinkChange(e, "discord", "text")
                    }
                    value={socialLinkValue?.discord}
                  />
                </div>
              </div>
              <div className="m-2 mb-2 w-full md:w-6/12">
                <label className="text-black">Twitch</label>
                <div className="flex gap-2 items-center">
                  <TextField
                    classes={"p-6 my-2"}
                    placeholder={"Enter Link..."}
                    handleChange={(e) =>
                      handleSocialsLinkChange(e, "twitch", "text")
                    }
                    value={socialLinkValue?.twitch}
                  />
                </div>
              </div>
              <div className="m-2 mb-2 w-full md:w-6/12">
                <label className="text-black">Clubhouse</label>
                <div className="flex gap-2 items-center">
                  <TextField
                    classes={"p-6 my-2"}
                    placeholder={"Enter Link..."}
                    handleChange={(e) =>
                      handleSocialsLinkChange(e, "clubhouse", "text")
                    }
                    value={socialLinkValue?.clubhouse}
                  />
                </div>
              </div>
              <div className="m-2 mb-2 w-full md:w-6/12">
                <label className="text-black">Medium</label>
                <div className="flex gap-2 items-center">
                  <TextField
                    classes={"p-6 my-2"}
                    placeholder={"Enter Link..."}
                    handleChange={(e) =>
                      handleSocialsLinkChange(e, "medium", "text")
                    }
                    value={socialLinkValue?.medium}
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <BasicButton
                disabled={false}
                title={"Save"}
                classes="px-8 w-8/12"
                handleClick={() => save()}
              />
            </div>

            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideLinkModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
            {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
