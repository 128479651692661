import axios from "axios";
import { useEffect, useState } from "react";

import Pagination from "../../../components/pagination";
import BasicButton from "../../../components/buttons/basic-button";
import AdminMobileSalesTable from "./components/mobile-table";
import AdminDesktopSalesTable from "./components/desktop-table";
import LoadingProgress from "../../publisher/rich-page/components/loading-progress";
import useDebounce from "../../../components/debounce/debounce-helper";
import TextField from "../../../components/inputs/text-input";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";
import DeletePromptModal from "../../../modals/delete-warning-modal";
import {
  isAdmin,
  API_BASE,
  isAuthUser,
  isResponsiveMode,
} from "../../../Utils/helpers";
import { toast } from "react-toastify";

export default function AdminSalesAccounts() {
  const [users, setUusers] = useState({});
  const [searchText, setSearchText] = useState("");
  const [countPerPage, setCountPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [currDelUser, setCurrDelUser] = useState({});
  const [deleteModalOpen, setDelModalOpen] = useState(false);

  const getUsers = () => {
    const url = API_BASE + "/admin/sales/" + countPerPage + "/" + searchText;
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setUusers(response.data.users);
        }
        setProcessing(false);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const deleteUser = (id) => {
    const url = API_BASE + "/admin/sales/delete/" + id;
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((response) => {
        toast("Deleted", { type: "success" });
        getUsers();
        closeDeleteModal();
        setCurrDelUser({});
        setProcessing(false);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const doPagination = (page) => {
    setProcessing(true);
    if (users.first_page_url) {
      setCurrentPage(page);
      axios
        .get(users.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          setUusers(res.data.users);
          setProcessing(false);
        })
        .catch((err) => {
          setProcessing(false);
          toast("Something went wrong", { type: "error" });
        });
    }
  };

  useEffect(() => {
    getUsers();
    if (!isAdmin()) {
      window.location.href = "/";
    }
  }, []);

  const handleSearchTextChange = (e) => {
    setSearchText(e);
  };

  const handleCountPerPageChange = (e) => {
    setCountPerPage(e.target.value);
  };

  const closeDeleteModal = () => {
    setDelModalOpen(false);
  };

  const openDeleteModal = (d) => {
    setCurrDelUser(d);
    setDelModalOpen(true);
  };

  useDebounce(
    () => {
      setUusers({});
      getUsers();
    },
    [searchText, countPerPage],
    800
  );

  const f = { deleteUser: deleteUser, openDeleteModal: openDeleteModal };

  return (
    <>
      <div className="main w-full">
        <div className="bg-white m-2 p-2 flex justify-between shadow px-4">
          <div>
            <h1 className="text-2xl text-center m-2 bold">Sales Accounts</h1>
          </div>
          <div>
            <a href="/admin/sales/create-account">
              <BasicButton title={"Create Account"} handleClick={null} />
            </a>
          </div>
        </div>
        <div className="flex justify-center my-4 overflow-hidden">
          <div class="flex flex-col items-center p-10 w-full">
            <p className="w-full text-center my-4">
              We found {users.total ? users.total : ""} records
            </p>
            <div className="flex w-full gap-4">
              <div className="w-11/12">
                <TextField
                  title="Search"
                  placeholder={"Search"}
                  classes="rounded-lg"
                  handleChange={handleSearchTextChange}
                  value={searchText}
                />
              </div>

              <div className="w-1/12">
                <label for="timezone" value="Your Timezone" />
                <select
                  onChange={handleCountPerPageChange}
                  required
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full text-black"
                >
                  <option value="10" default>
                    10
                  </option>
                  {/* <option value="2">2</option> */}
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <div className="flex justify-center">
              {users.data ? (
                users.data.length ? (
                  <Pagination pagination={users} doPagination={doPagination} />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>

            {isResponsiveMode() ? (
              <AdminMobileSalesTable data={users} f={f} />
            ) : (
              <AdminDesktopSalesTable data={users} f={f} />
            )}

            <LoadingProgress loading={processing} />

            <div className="flex justify-center">
              {users.data ? (
                users.data.length ? (
                  <Pagination pagination={users} doPagination={doPagination} />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      {currDelUser.id ? (
        <DeletePromptModal
          modalOpen={deleteModalOpen}
          hideAdModal={closeDeleteModal}
          title={
            "Are you sure you want to delete user " + currDelUser.name + " ?"
          }
          yesActionText={"Yes, Delete"}
          noActionText={"No, Cancel"}
          action={() => deleteUser(currDelUser.id)}
          processing={processing}
        />
      ) : (
        ""
      )}
    </>
  );
}
