import TextField from "../../../../../../../components/inputs/text-input";
import EditorTooltip from "../../tooltip/editor-tooltip";
import BasicButton from "../../../../../../../components/buttons/basic-button";
export default function LocaionFulfilment(props) {
  const {
    productMeta,
    handleLFChange,
    addLocationCharge,
    removeLocationCharge,
  } = props;
  return (
    <>
      <div className=" flex gap-2 m-2 mb-2 relative w-full justify-center">
        {" "}
        <label className="font-bold">
          Location Fulfillment Charge (Optional):
        </label>{" "}
        <EditorTooltip
          body={`The Location Fulfillment Charge is an optional feature that allows suppliers to add additional charges to the product based on the customer's chosen fulfillment location.<br/><br/>

⦾ To set it up, input the location in the textbox and assign the charges in the number box. The charges can only be set to a minimum of $0; entering a negative value is not allowed.<br/><br/>

⦾ When a customer is purchasing or subscribing to a product, they will choose their fulfillment location from a dropdown list in the pre-payment modal, and the corresponding fee will be added to the final total.<br/><br/>

⦾ The charge can be multiplied based on the quantity of items selected by the customer.`}
          top={"-500px"}
          right={"-78px"}
        />
      </div>
      {productMeta.location_fulfilment.map((d, i) => (
        <div className=" flex flex-col m-2 mb-2 w-8/12 bg-gray-50 p-2 relative">
          <div
            className="bg-white rounded rounded-full bg-white p-0 top-0 right-0 absolute px-1 cursor-pointer"
            onClick={() => removeLocationCharge(i)}
          >
            <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
          </div>
          <div className="flex flex-col justify-start">
            {" "}
            <label className="">Location</label>
            <TextField
              classes={"p-6 my-2"}
              placeholder={"Enter location.."}
              type="text"
              handleChange={(e) => handleLFChange(e, "location", i)}
              value={d.location}
            />
          </div>

          <div className="flex flex-col justify-start">
            {" "}
            <label className="">Charge</label>
            <TextField
              classes={"p-6 my-2"}
              placeholder={"Location Charge.."}
              type="number"
              handleChange={(e) => handleLFChange(e, "charge", i)}
              value={d.charge}
            />
          </div>
          <p className="text-pink-500">
            {(d.location !== " " && !d.charge) || d.charge == 0
              ? "Numbers below 0 are not allowed."
              : ""}
          </p>
          <p className="text-pink-500">
            {d.charge && !d.location
              ? "Please enter a location for the provided charge ."
              : ""}
          </p>
        </div>
      ))}
      <BasicButton
        classes={"bg-gray-50 border text-black  hover:border hover:text-white"}
        title={"Add Location Charge +"}
        handleClick={() => addLocationCharge()}
      />
    </>
  );
}
