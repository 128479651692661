import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BasicButton from "../../../../../components/buttons/basic-button";
import LoadingProgress from "../../../../publisher/rich-page/components/loading-progress";
import { useSupplierApplicationsStore } from "../../../../../stores/supplier-applications-store";
import authRequestService from "../../../../../services/HttpRequests/authenticated-request-services";
import DesktopTable from "./desktop-table";
import MobileTable from "./mobile-table";
import CommentBox from "./comment-modal";

import {
  isAdmin,
  API_BASE,
  isResponsiveMode,
  authUserData,
} from "../../../../../Utils/helpers";
import { toast } from "react-toastify";
export default function AdminSupplierContract() {
  const [processing, setProcessing] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const { user_id, contract_id } = useParams();

  const navigate = useNavigate();
  const mailStore = useSupplierApplicationsStore((state) => state);

  const getContract = () => {
    mailStore.setData([]);
    mailStore.setContractData([]);
    mailStore.setSelectedApplicantStatus("");
    setProcessing(true);
    const url = API_BASE + "/admin/supplier/contract/" + contract_id;
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          mailStore.setContractData(response.data.contract);
          setTimeout(() => {
            setProcessing(false);
          }, 500);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const processApplication = () => {
    setProcessing(true);
    const url = API_BASE + `/admin/supplier/contract/process`;
    authRequestService()
      .post(url, {
        status: mailStore.selectedApplicantStatus,
        comment: mailStore.comment,
        user_id: user_id,
        contract_id: contract_id,
      })
      .then((response) => {
        if (response.status == 200) {
          setProcessing(false);
          getContract();
          closeCommentModal();
          mailStore.setSelectedApplicantStatus("");
          toast("Processed", { type: "success" });
          setTimeout(() => {
            navigate("/admin/supplier/product/contracts");
          }, 500);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const preProcessApplication = () => {
    console.log(mailStore.selectedApplicantStatus);
    if (mailStore.selectedApplicantStatus == "rejected") {
      openCommentModal();
    }
  };

  const openCommentModal = () => {
    setShowCommentModal(true);
  };
  const closeCommentModal = () => {
    setShowCommentModal(false);
  };

  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
    getContract();
  }, []);

  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">
              Contract Approval Request for{" "}
              {mailStore?.contract?.contract_owner_data?.username}
            </h1>
          </div>
          <div className="flex justify-between gap-2 items-start">
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>

        <div className="flex flex-col items-center justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            {isResponsiveMode() ? (
              <MobileTable
                processing={processing}
                openCommentModal={openCommentModal}
                preProcessApplication={preProcessApplication}
                processApplication={processApplication}
              />
            ) : (
              <DesktopTable
                processing={processing}
                openCommentModal={openCommentModal}
                preProcessApplication={preProcessApplication}
                processApplication={processApplication}
              />
            )}
          </div>
        </div>
        <LoadingProgress loading={processing} />
      </div>
      <CommentBox
        modalOpen={showCommentModal}
        closeModal={closeCommentModal}
        processApplication={processApplication}
        processing={processing}
      />
    </>
  );
}
