export const NewTimezoneList = [
  {
    index: 1,
    name: "Africa/Abidjan",
  },
  {
    index: 2,
    name: "Africa/Accra",
  },
  {
    index: 3,
    name: "Africa/Addis_Ababa",
  },
  {
    index: 4,
    name: "Africa/Algiers",
  },
  {
    index: 5,
    name: "Africa/Asmara",
  },
  {
    index: 6,
    name: "Africa/Asmera",
  },
  {
    index: 7,
    name: "Africa/Bamako",
  },
  {
    index: 8,
    name: "Africa/Bangui",
  },
  {
    index: 9,
    name: "Africa/Banjul",
  },
  {
    index: 10,
    name: "Africa/Bissau",
  },
  {
    index: 11,
    name: "Africa/Blantyre",
  },
  {
    index: 12,
    name: "Africa/Brazzaville",
  },
  {
    index: 13,
    name: "Africa/Bujumbura",
  },
  {
    index: 14,
    name: "Africa/Cairo",
  },
  {
    index: 15,
    name: "Africa/Casablanca",
  },
  {
    index: 16,
    name: "Africa/Ceuta",
  },
  {
    index: 17,
    name: "Africa/Conakry",
  },
  {
    index: 18,
    name: "Africa/Dakar",
  },
  {
    index: 19,
    name: "Africa/Dar_es_Salaam",
  },
  {
    index: 20,
    name: "Africa/Djibouti",
  },
  {
    index: 21,
    name: "Africa/Douala",
  },
  {
    index: 22,
    name: "Africa/El_Aaiun",
  },
  {
    index: 23,
    name: "Africa/Freetown",
  },
  {
    index: 24,
    name: "Africa/Gaborone",
  },
  {
    index: 25,
    name: "Africa/Harare",
  },
  {
    index: 26,
    name: "Africa/Johannesburg",
  },
  {
    index: 27,
    name: "Africa/Juba",
  },
  {
    index: 28,
    name: "Africa/Kampala",
  },
  {
    index: 29,
    name: "Africa/Khartoum",
  },
  {
    index: 30,
    name: "Africa/Kigali",
  },
  {
    index: 31,
    name: "Africa/Kinshasa",
  },
  {
    index: 32,
    name: "Africa/Lagos",
  },
  {
    index: 33,
    name: "Africa/Libreville",
  },
  {
    index: 34,
    name: "Africa/Lome",
  },
  {
    index: 35,
    name: "Africa/Luanda",
  },
  {
    index: 36,
    name: "Africa/Lubumbashi",
  },
  {
    index: 37,
    name: "Africa/Lusaka",
  },
  {
    index: 38,
    name: "Africa/Malabo",
  },
  {
    index: 39,
    name: "Africa/Maputo",
  },
  {
    index: 40,
    name: "Africa/Maseru",
  },
  {
    index: 41,
    name: "Africa/Mbabane",
  },
  {
    index: 42,
    name: "Africa/Mogadishu",
  },
  {
    index: 43,
    name: "Africa/Monrovia",
  },
  {
    index: 44,
    name: "Africa/Nairobi",
  },
  {
    index: 45,
    name: "Africa/Ndjamena",
  },
  {
    index: 46,
    name: "Africa/Niamey",
  },
  {
    index: 47,
    name: "Africa/Nouakchott",
  },
  {
    index: 48,
    name: "Africa/Ouagadougou",
  },
  {
    index: 49,
    name: "Africa/Porto-Novo",
  },
  {
    index: 50,
    name: "Africa/Sao_Tome",
  },
  {
    index: 51,
    name: "Africa/Timbuktu",
  },
  {
    index: 52,
    name: "Africa/Tripoli",
  },
  {
    index: 53,
    name: "Africa/Tunis",
  },
  {
    index: 54,
    name: "Africa/Windhoek",
  },
  {
    index: 55,
    name: "America/Adak",
  },
  {
    index: 56,
    name: "America/Anchorage",
  },
  {
    index: 57,
    name: "America/Anguilla",
  },
  {
    index: 58,
    name: "America/Antigua",
  },
  {
    index: 59,
    name: "America/Araguaina",
  },
  {
    index: 60,
    name: "America/Argentina/Buenos_Aires",
  },
  {
    index: 61,
    name: "America/Argentina/Catamarca",
  },
  {
    index: 62,
    name: "America/Argentina/ComodRivadavia",
  },
  {
    index: 63,
    name: "America/Argentina/Cordoba",
  },
  {
    index: 64,
    name: "America/Argentina/Jujuy",
  },
  {
    index: 65,
    name: "America/Argentina/La_Rioja",
  },
  {
    index: 66,
    name: "America/Argentina/Mendoza",
  },
  {
    index: 67,
    name: "America/Argentina/Rio_Gallegos",
  },
  {
    index: 68,
    name: "America/Argentina/Salta",
  },
  {
    index: 69,
    name: "America/Argentina/San_Juan",
  },
  {
    index: 70,
    name: "America/Argentina/San_Luis",
  },
  {
    index: 71,
    name: "America/Argentina/Tucuman",
  },
  {
    index: 72,
    name: "America/Argentina/Ushuaia",
  },
  {
    index: 73,
    name: "America/Aruba",
  },
  {
    index: 74,
    name: "America/Asuncion",
  },
  {
    index: 75,
    name: "America/Atikokan",
  },
  {
    index: 76,
    name: "America/Atka",
  },
  {
    index: 77,
    name: "America/Bahia",
  },
  {
    index: 78,
    name: "America/Bahia_Banderas",
  },
  {
    index: 79,
    name: "America/Barbados",
  },
  {
    index: 80,
    name: "America/Belem",
  },
  {
    index: 81,
    name: "America/Belize",
  },
  {
    index: 82,
    name: "America/Blanc-Sablon",
  },
  {
    index: 83,
    name: "America/Boa_Vista",
  },
  {
    index: 84,
    name: "America/Bogota",
  },
  {
    index: 85,
    name: "America/Boise",
  },
  {
    index: 86,
    name: "America/Buenos_Aires",
  },
  {
    index: 87,
    name: "America/Cambridge_Bay",
  },
  {
    index: 88,
    name: "America/Campo_Grande",
  },
  {
    index: 89,
    name: "America/Cancun",
  },
  {
    index: 90,
    name: "America/Caracas",
  },
  {
    index: 91,
    name: "America/Catamarca",
  },
  {
    index: 92,
    name: "America/Cayenne",
  },
  {
    index: 93,
    name: "America/Cayman",
  },
  {
    index: 94,
    name: "America/Chicago",
  },
  {
    index: 95,
    name: "America/Chihuahua",
  },
  {
    index: 96,
    name: "America/Coral_Harbour",
  },
  {
    index: 97,
    name: "America/Cordoba",
  },
  {
    index: 98,
    name: "America/Costa_Rica",
  },
  {
    index: 99,
    name: "America/Creston",
  },
  {
    index: 100,
    name: "America/Cuiaba",
  },
  {
    index: 101,
    name: "America/Curacao",
  },
  {
    index: 102,
    name: "America/Danmarkshavn",
  },
  {
    index: 103,
    name: "America/Dawson",
  },
  {
    index: 104,
    name: "America/Dawson_Creek",
  },
  {
    index: 105,
    name: "America/Denver",
  },
  {
    index: 106,
    name: "America/Detroit",
  },
  {
    index: 107,
    name: "America/Dominica",
  },
  {
    index: 108,
    name: "America/Edmonton",
  },
  {
    index: 109,
    name: "America/Eirunepe",
  },
  {
    index: 110,
    name: "America/El_Salvador",
  },
  {
    index: 111,
    name: "America/Ensenada",
  },
  {
    index: 112,
    name: "America/Fort_Nelson",
  },
  {
    index: 113,
    name: "America/Fort_Wayne",
  },
  {
    index: 114,
    name: "America/Fortaleza",
  },
  {
    index: 115,
    name: "America/Glace_Bay",
  },
  {
    index: 116,
    name: "America/Godthab",
  },
  {
    index: 117,
    name: "America/Goose_Bay",
  },
  {
    index: 118,
    name: "America/Grand_Turk",
  },
  {
    index: 119,
    name: "America/Grenada",
  },
  {
    index: 120,
    name: "America/Guadeloupe",
  },
  {
    index: 121,
    name: "America/Guatemala",
  },
  {
    index: 122,
    name: "America/Guayaquil",
  },
  {
    index: 123,
    name: "America/Guyana",
  },
  {
    index: 124,
    name: "America/Halifax",
  },
  {
    index: 125,
    name: "America/Havana",
  },
  {
    index: 126,
    name: "America/Hermosillo",
  },
  {
    index: 127,
    name: "America/Indiana/Indianapolis",
  },
  {
    index: 128,
    name: "America/Indiana/Knox",
  },
  {
    index: 129,
    name: "America/Indiana/Marengo",
  },
  {
    index: 130,
    name: "America/Indiana/Petersburg",
  },
  {
    index: 131,
    name: "America/Indiana/Tell_City",
  },
  {
    index: 132,
    name: "America/Indiana/Vevay",
  },
  {
    index: 133,
    name: "America/Indiana/Vincennes",
  },
  {
    index: 134,
    name: "America/Indiana/Winamac",
  },
  {
    index: 135,
    name: "America/Indianapolis",
  },
  {
    index: 136,
    name: "America/Inuvik",
  },
  {
    index: 137,
    name: "America/Iqaluit",
  },
  {
    index: 138,
    name: "America/Jamaica",
  },
  {
    index: 139,
    name: "America/Jujuy",
  },
  {
    index: 140,
    name: "America/Juneau",
  },
  {
    index: 141,
    name: "America/Kentucky/Louisville",
  },
  {
    index: 142,
    name: "America/Kentucky/Monticello",
  },
  {
    index: 143,
    name: "America/Knox_IN",
  },
  {
    index: 144,
    name: "America/Kralendijk",
  },
  {
    index: 145,
    name: "America/La_Paz",
  },
  {
    index: 146,
    name: "America/Lima",
  },
  {
    index: 147,
    name: "America/Los_Angeles",
  },
  {
    index: 148,
    name: "America/Louisville",
  },
  {
    index: 149,
    name: "America/Lower_Princes",
  },
  {
    index: 150,
    name: "America/Maceio",
  },
  {
    index: 151,
    name: "America/Managua",
  },
  {
    index: 152,
    name: "America/Manaus",
  },
  {
    index: 153,
    name: "America/Marigot",
  },
  {
    index: 154,
    name: "America/Martinique",
  },
  {
    index: 155,
    name: "America/Matamoros",
  },
  {
    index: 156,
    name: "America/Mazatlan",
  },
  {
    index: 157,
    name: "America/Mendoza",
  },
  {
    index: 158,
    name: "America/Menominee",
  },
  {
    index: 159,
    name: "America/Merida",
  },
  {
    index: 160,
    name: "America/Metlakatla",
  },
  {
    index: 161,
    name: "America/Mexico_City",
  },
  {
    index: 162,
    name: "America/Miquelon",
  },
  {
    index: 163,
    name: "America/Moncton",
  },
  {
    index: 164,
    name: "America/Monterrey",
  },
  {
    index: 165,
    name: "America/Montevideo",
  },
  {
    index: 166,
    name: "America/Montreal",
  },
  {
    index: 167,
    name: "America/Montserrat",
  },
  {
    index: 168,
    name: "America/Nassau",
  },
  {
    index: 169,
    name: "America/New_York",
  },
  {
    index: 170,
    name: "America/Nipigon",
  },
  {
    index: 171,
    name: "America/Nome",
  },
  {
    index: 172,
    name: "America/Noronha",
  },
  {
    index: 173,
    name: "America/North_Dakota/Beulah",
  },
  {
    index: 174,
    name: "America/North_Dakota/Center",
  },
  {
    index: 175,
    name: "America/North_Dakota/New_Salem",
  },
  {
    index: 176,
    name: "America/Ojinaga",
  },
  {
    index: 177,
    name: "America/Panama",
  },
  {
    index: 178,
    name: "America/Pangnirtung",
  },
  {
    index: 179,
    name: "America/Paramaribo",
  },
  {
    index: 180,
    name: "America/Phoenix",
  },
  {
    index: 181,
    name: "America/Port-au-Prince",
  },
  {
    index: 182,
    name: "America/Port_of_Spain",
  },
  {
    index: 183,
    name: "America/Porto_Acre",
  },
  {
    index: 184,
    name: "America/Porto_Velho",
  },
  {
    index: 185,
    name: "America/Puerto_Rico",
  },
  {
    index: 186,
    name: "America/Punta_Arenas",
  },
  {
    index: 187,
    name: "America/Rainy_River",
  },
  {
    index: 188,
    name: "America/Rankin_Inlet",
  },
  {
    index: 189,
    name: "America/Recife",
  },
  {
    index: 190,
    name: "America/Regina",
  },
  {
    index: 191,
    name: "America/Resolute",
  },
  {
    index: 192,
    name: "America/Rio_Branco",
  },
  {
    index: 193,
    name: "America/Rosario",
  },
  {
    index: 194,
    name: "America/Santa_Isabel",
  },
  {
    index: 195,
    name: "America/Santarem",
  },
  {
    index: 196,
    name: "America/Santiago",
  },
  {
    index: 197,
    name: "America/Santo_Domingo",
  },
  {
    index: 198,
    name: "America/Sao_Paulo",
  },
  {
    index: 199,
    name: "America/Scoresbysund",
  },
  {
    index: 200,
    name: "America/Shiprock",
  },
  {
    index: 201,
    name: "America/Sitka",
  },
  {
    index: 202,
    name: "America/St_Barthelemy",
  },
  {
    index: 203,
    name: "America/St_Johns",
  },
  {
    index: 204,
    name: "America/St_Kitts",
  },
  {
    index: 205,
    name: "America/St_Lucia",
  },
  {
    index: 206,
    name: "America/St_Thomas",
  },
  {
    index: 207,
    name: "America/St_Vincent",
  },
  {
    index: 208,
    name: "America/Swift_Current",
  },
  {
    index: 209,
    name: "America/Tegucigalpa",
  },
  {
    index: 210,
    name: "America/Thule",
  },
  {
    index: 211,
    name: "America/Thunder_Bay",
  },
  {
    index: 212,
    name: "America/Tijuana",
  },
  {
    index: 213,
    name: "America/Toronto",
  },
  {
    index: 214,
    name: "America/Tortola",
  },
  {
    index: 215,
    name: "America/Vancouver",
  },
  {
    index: 216,
    name: "America/Virgin",
  },
  {
    index: 217,
    name: "America/Whitehorse",
  },
  {
    index: 218,
    name: "America/Winnipeg",
  },
  {
    index: 219,
    name: "America/Yakutat",
  },
  {
    index: 220,
    name: "America/Yellowknife",
  },
  {
    index: 221,
    name: "Antarctica/Casey",
  },
  {
    index: 222,
    name: "Antarctica/Davis",
  },
  {
    index: 223,
    name: "Antarctica/DumontDUrville",
  },
  {
    index: 224,
    name: "Antarctica/Macquarie",
  },
  {
    index: 225,
    name: "Antarctica/Mawson",
  },
  {
    index: 226,
    name: "Antarctica/McMurdo",
  },
  {
    index: 227,
    name: "Antarctica/Palmer",
  },
  {
    index: 228,
    name: "Antarctica/Rothera",
  },
  {
    index: 229,
    name: "Antarctica/South_Pole",
  },
  {
    index: 230,
    name: "Antarctica/Syowa",
  },
  {
    index: 231,
    name: "Antarctica/Troll",
  },
  {
    index: 232,
    name: "Antarctica/Vostok",
  },
  {
    index: 233,
    name: "Arctic/Longyearbyen",
  },
  {
    index: 234,
    name: "Asia/Aden",
  },
  {
    index: 235,
    name: "Asia/Almaty",
  },
  {
    index: 236,
    name: "Asia/Amman",
  },
  {
    index: 237,
    name: "Asia/Anadyr",
  },
  {
    index: 238,
    name: "Asia/Aqtau",
  },
  {
    index: 239,
    name: "Asia/Aqtobe",
  },
  {
    index: 240,
    name: "Asia/Ashgabat",
  },
  {
    index: 241,
    name: "Asia/Ashkhabad",
  },
  {
    index: 242,
    name: "Asia/Atyrau",
  },
  {
    index: 243,
    name: "Asia/Baghdad",
  },
  {
    index: 244,
    name: "Asia/Bahrain",
  },
  {
    index: 245,
    name: "Asia/Baku",
  },
  {
    index: 246,
    name: "Asia/Bangkok",
  },
  {
    index: 247,
    name: "Asia/Barnaul",
  },
  {
    index: 248,
    name: "Asia/Beirut",
  },
  {
    index: 249,
    name: "Asia/Bishkek",
  },
  {
    index: 250,
    name: "Asia/Brunei",
  },
  {
    index: 251,
    name: "Asia/Calcutta",
  },
  {
    index: 252,
    name: "Asia/Chita",
  },
  {
    index: 253,
    name: "Asia/Choibalsan",
  },
  {
    index: 254,
    name: "Asia/Chongqing",
  },
  {
    index: 255,
    name: "Asia/Chungking",
  },
  {
    index: 256,
    name: "Asia/Colombo",
  },
  {
    index: 257,
    name: "Asia/Dacca",
  },
  {
    index: 258,
    name: "Asia/Damascus",
  },
  {
    index: 259,
    name: "Asia/Dhaka",
  },
  {
    index: 260,
    name: "Asia/Dili",
  },
  {
    index: 261,
    name: "Asia/Dubai",
  },
  {
    index: 262,
    name: "Asia/Dushanbe",
  },
  {
    index: 263,
    name: "Asia/Famagusta",
  },
  {
    index: 264,
    name: "Asia/Gaza",
  },
  {
    index: 265,
    name: "Asia/Harbin",
  },
  {
    index: 266,
    name: "Asia/Hebron",
  },
  {
    index: 267,
    name: "Asia/Ho_Chi_Minh",
  },
  {
    index: 268,
    name: "Asia/Hong_Kong",
  },
  {
    index: 269,
    name: "Asia/Hovd",
  },
  {
    index: 270,
    name: "Asia/Irkutsk",
  },
  {
    index: 271,
    name: "Asia/Istanbul",
  },
  {
    index: 272,
    name: "Asia/Jakarta",
  },
  {
    index: 273,
    name: "Asia/Jayapura",
  },
  {
    index: 274,
    name: "Asia/Jerusalem",
  },
  {
    index: 275,
    name: "Asia/Kabul",
  },
  {
    index: 276,
    name: "Asia/Kamchatka",
  },
  {
    index: 277,
    name: "Asia/Karachi",
  },
  {
    index: 278,
    name: "Asia/Kashgar",
  },
  {
    index: 279,
    name: "Asia/Kathmandu",
  },
  {
    index: 280,
    name: "Asia/Katmandu",
  },
  {
    index: 281,
    name: "Asia/Khandyga",
  },
  {
    index: 282,
    name: "Asia/Kolkata",
  },
  {
    index: 283,
    name: "Asia/Krasnoyarsk",
  },
  {
    index: 284,
    name: "Asia/Kuala_Lumpur",
  },
  {
    index: 285,
    name: "Asia/Kuching",
  },
  {
    index: 286,
    name: "Asia/Kuwait",
  },
  {
    index: 287,
    name: "Asia/Macao",
  },
  {
    index: 288,
    name: "Asia/Macau",
  },
  {
    index: 289,
    name: "Asia/Magadan",
  },
  {
    index: 290,
    name: "Asia/Makassar",
  },
  {
    index: 291,
    name: "Asia/Manila",
  },
  {
    index: 292,
    name: "Asia/Muscat",
  },
  {
    index: 293,
    name: "Asia/Nicosia",
  },
  {
    index: 294,
    name: "Asia/Novokuznetsk",
  },
  {
    index: 295,
    name: "Asia/Novosibirsk",
  },
  {
    index: 296,
    name: "Asia/Omsk",
  },
  {
    index: 297,
    name: "Asia/Oral",
  },
  {
    index: 298,
    name: "Asia/Phnom_Penh",
  },
  {
    index: 299,
    name: "Asia/Pontianak",
  },
  {
    index: 300,
    name: "Asia/Pyongyang",
  },
  {
    index: 301,
    name: "Asia/Qatar",
  },
  {
    index: 302,
    name: "Asia/Qyzylorda",
  },
  {
    index: 303,
    name: "Asia/Rangoon",
  },
  {
    index: 304,
    name: "Asia/Riyadh",
  },
  {
    index: 305,
    name: "Asia/Saigon",
  },
  {
    index: 306,
    name: "Asia/Sakhalin",
  },
  {
    index: 307,
    name: "Asia/Samarkand",
  },
  {
    index: 308,
    name: "Asia/Seoul",
  },
  {
    index: 309,
    name: "Asia/Shanghai",
  },
  {
    index: 310,
    name: "Asia/Singapore",
  },
  {
    index: 311,
    name: "Asia/Srednekolymsk",
  },
  {
    index: 312,
    name: "Asia/Taipei",
  },
  {
    index: 313,
    name: "Asia/Tashkent",
  },
  {
    index: 314,
    name: "Asia/Tbilisi",
  },
  {
    index: 315,
    name: "Asia/Tehran",
  },
  {
    index: 316,
    name: "Asia/Tel_Aviv",
  },
  {
    index: 317,
    name: "Asia/Thimbu",
  },
  {
    index: 318,
    name: "Asia/Thimphu",
  },
  {
    index: 319,
    name: "Asia/Tokyo",
  },
  {
    index: 320,
    name: "Asia/Tomsk",
  },
  {
    index: 321,
    name: "Asia/Ujung_Pandang",
  },
  {
    index: 322,
    name: "Asia/Ulaanbaatar",
  },
  {
    index: 323,
    name: "Asia/Ulan_Bator",
  },
  {
    index: 324,
    name: "Asia/Urumqi",
  },
  {
    index: 325,
    name: "Asia/Ust-Nera",
  },
  {
    index: 326,
    name: "Asia/Vientiane",
  },
  {
    index: 327,
    name: "Asia/Vladivostok",
  },
  {
    index: 328,
    name: "Asia/Yakutsk",
  },
  {
    index: 329,
    name: "Asia/Yangon",
  },
  {
    index: 330,
    name: "Asia/Yekaterinburg",
  },
  {
    index: 331,
    name: "Asia/Yerevan",
  },
  {
    index: 332,
    name: "Atlantic/Azores",
  },
  {
    index: 333,
    name: "Atlantic/Bermuda",
  },
  {
    index: 334,
    name: "Atlantic/Canary",
  },
  {
    index: 335,
    name: "Atlantic/Cape_Verde",
  },
  {
    index: 336,
    name: "Atlantic/Faeroe",
  },
  {
    index: 337,
    name: "Atlantic/Faroe",
  },
  {
    index: 338,
    name: "Atlantic/Jan_Mayen",
  },
  {
    index: 339,
    name: "Atlantic/Madeira",
  },
  {
    index: 340,
    name: "Atlantic/Reykjavik",
  },
  {
    index: 341,
    name: "Atlantic/South_Georgia",
  },
  {
    index: 342,
    name: "Atlantic/St_Helena",
  },
  {
    index: 343,
    name: "Atlantic/Stanley",
  },
  {
    index: 344,
    name: "Australia/ACT",
  },
  {
    index: 345,
    name: "Australia/Adelaide",
  },
  {
    index: 346,
    name: "Australia/Brisbane",
  },
  {
    index: 347,
    name: "Australia/Broken_Hill",
  },
  {
    index: 348,
    name: "Australia/Canberra",
  },
  {
    index: 349,
    name: "Australia/Currie",
  },
  {
    index: 350,
    name: "Australia/Darwin",
  },
  {
    index: 351,
    name: "Australia/Eucla",
  },
  {
    index: 352,
    name: "Australia/Hobart",
  },
  {
    index: 353,
    name: "Australia/LHI",
  },
  {
    index: 354,
    name: "Australia/Lindeman",
  },
  {
    index: 355,
    name: "Australia/Lord_Howe",
  },
  {
    index: 356,
    name: "Australia/Melbourne",
  },
  {
    index: 357,
    name: "Australia/NSW",
  },
  {
    index: 358,
    name: "Australia/North",
  },
  {
    index: 359,
    name: "Australia/Perth",
  },
  {
    index: 360,
    name: "Australia/Queensland",
  },
  {
    index: 361,
    name: "Australia/South",
  },
  {
    index: 362,
    name: "Australia/Sydney",
  },
  {
    index: 363,
    name: "Australia/Tasmania",
  },
  {
    index: 364,
    name: "Australia/Victoria",
  },
  {
    index: 365,
    name: "Australia/West",
  },
  {
    index: 366,
    name: "Australia/Yancowinna",
  },
  {
    index: 367,
    name: "Brazil/Acre",
  },
  {
    index: 368,
    name: "Brazil/DeNoronha",
  },
  {
    index: 369,
    name: "Brazil/East",
  },
  {
    index: 370,
    name: "Brazil/West",
  },
  {
    index: 371,
    name: "CET",
  },
  {
    index: 372,
    name: "CST6CDT",
  },
  {
    index: 373,
    name: "Canada/Atlantic",
  },
  {
    index: 374,
    name: "Canada/Central",
  },
  {
    index: 375,
    name: "Canada/Eastern",
  },
  {
    index: 376,
    name: "Canada/Mountain",
  },
  {
    index: 377,
    name: "Canada/Newfoundland",
  },
  {
    index: 378,
    name: "Canada/Pacific",
  },
  {
    index: 379,
    name: "Canada/Saskatchewan",
  },
  {
    index: 380,
    name: "Canada/Yukon",
  },
  {
    index: 381,
    name: "Chile/Continental",
  },
  {
    index: 382,
    name: "Chile/EasterIsland",
  },
  {
    index: 383,
    name: "Cuba",
  },
  {
    index: 384,
    name: "EET",
  },
  {
    index: 385,
    name: "EST",
  },
  {
    index: 386,
    name: "EST5EDT",
  },
  {
    index: 387,
    name: "Egypt",
  },
  {
    index: 388,
    name: "Eire",
  },
  {
    index: 389,
    name: "Etc/GMT",
  },
  {
    index: 390,
    name: "Etc/GMT+0",
  },
  {
    index: 391,
    name: "Etc/GMT+1",
  },
  {
    index: 392,
    name: "Etc/GMT+10",
  },
  {
    index: 393,
    name: "Etc/GMT+11",
  },
  {
    index: 394,
    name: "Etc/GMT+12",
  },
  {
    index: 395,
    name: "Etc/GMT+2",
  },
  {
    index: 396,
    name: "Etc/GMT+3",
  },
  {
    index: 397,
    name: "Etc/GMT+4",
  },
  {
    index: 398,
    name: "Etc/GMT+5",
  },
  {
    index: 399,
    name: "Etc/GMT+6",
  },
  {
    index: 400,
    name: "Etc/GMT+7",
  },
  {
    index: 401,
    name: "Etc/GMT+8",
  },
  {
    index: 402,
    name: "Etc/GMT+9",
  },
  {
    index: 403,
    name: "Etc/GMT-0",
  },
  {
    index: 404,
    name: "Etc/GMT-1",
  },
  {
    index: 405,
    name: "Etc/GMT-10",
  },
  {
    index: 406,
    name: "Etc/GMT-11",
  },
  {
    index: 407,
    name: "Etc/GMT-12",
  },
  {
    index: 408,
    name: "Etc/GMT-13",
  },
  {
    index: 409,
    name: "Etc/GMT-14",
  },
  {
    index: 410,
    name: "Etc/GMT-2",
  },
  {
    index: 411,
    name: "Etc/GMT-3",
  },
  {
    index: 412,
    name: "Etc/GMT-4",
  },
  {
    index: 413,
    name: "Etc/GMT-5",
  },
  {
    index: 414,
    name: "Etc/GMT-6",
  },
  {
    index: 415,
    name: "Etc/GMT-7",
  },
  {
    index: 416,
    name: "Etc/GMT-8",
  },
  {
    index: 417,
    name: "Etc/GMT-9",
  },
  {
    index: 418,
    name: "Etc/GMT0",
  },
  {
    index: 419,
    name: "Etc/Greenwich",
  },
  {
    index: 420,
    name: "Etc/UCT",
  },
  {
    index: 421,
    name: "Etc/UTC",
  },
  {
    index: 422,
    name: "Etc/Universal",
  },
  {
    index: 423,
    name: "Etc/Zulu",
  },
  {
    index: 424,
    name: "Europe/Amsterdam",
  },
  {
    index: 425,
    name: "Europe/Andorra",
  },
  {
    index: 426,
    name: "Europe/Astrakhan",
  },
  {
    index: 427,
    name: "Europe/Athens",
  },
  {
    index: 428,
    name: "Europe/Belfast",
  },
  {
    index: 429,
    name: "Europe/Belgrade",
  },
  {
    index: 430,
    name: "Europe/Berlin",
  },
  {
    index: 431,
    name: "Europe/Bratislava",
  },
  {
    index: 432,
    name: "Europe/Brussels",
  },
  {
    index: 433,
    name: "Europe/Bucharest",
  },
  {
    index: 434,
    name: "Europe/Budapest",
  },
  {
    index: 435,
    name: "Europe/Busingen",
  },
  {
    index: 436,
    name: "Europe/Chisinau",
  },
  {
    index: 437,
    name: "Europe/Copenhagen",
  },
  {
    index: 438,
    name: "Europe/Dublin",
  },
  {
    index: 439,
    name: "Europe/Gibraltar",
  },
  {
    index: 440,
    name: "Europe/Guernsey",
  },
  {
    index: 441,
    name: "Europe/Helsinki",
  },
  {
    index: 442,
    name: "Europe/Isle_of_Man",
  },
  {
    index: 443,
    name: "Europe/Istanbul",
  },
  {
    index: 444,
    name: "Europe/Jersey",
  },
  {
    index: 445,
    name: "Europe/Kaliningrad",
  },
  {
    index: 446,
    name: "Europe/Kiev",
  },
  {
    index: 447,
    name: "Europe/Kirov",
  },
  {
    index: 448,
    name: "Europe/Lisbon",
  },
  {
    index: 449,
    name: "Europe/Ljubljana",
  },
  {
    index: 450,
    name: "Europe/London",
  },
  {
    index: 451,
    name: "Europe/Luxembourg",
  },
  {
    index: 452,
    name: "Europe/Madrid",
  },
  {
    index: 453,
    name: "Europe/Malta",
  },
  {
    index: 454,
    name: "Europe/Mariehamn",
  },
  {
    index: 455,
    name: "Europe/Minsk",
  },
  {
    index: 456,
    name: "Europe/Monaco",
  },
  {
    index: 457,
    name: "Europe/Moscow",
  },
  {
    index: 458,
    name: "Europe/Nicosia",
  },
  {
    index: 459,
    name: "Europe/Oslo",
  },
  {
    index: 460,
    name: "Europe/Paris",
  },
  {
    index: 461,
    name: "Europe/Podgorica",
  },
  {
    index: 462,
    name: "Europe/Prague",
  },
  {
    index: 463,
    name: "Europe/Riga",
  },
  {
    index: 464,
    name: "Europe/Rome",
  },
  {
    index: 465,
    name: "Europe/Samara",
  },
  {
    index: 466,
    name: "Europe/San_Marino",
  },
  {
    index: 467,
    name: "Europe/Sarajevo",
  },
  {
    index: 468,
    name: "Europe/Saratov",
  },
  {
    index: 469,
    name: "Europe/Simferopol",
  },
  {
    index: 470,
    name: "Europe/Skopje",
  },
  {
    index: 471,
    name: "Europe/Sofia",
  },
  {
    index: 472,
    name: "Europe/Stockholm",
  },
  {
    index: 473,
    name: "Europe/Tallinn",
  },
  {
    index: 474,
    name: "Europe/Tirane",
  },
  {
    index: 475,
    name: "Europe/Tiraspol",
  },
  {
    index: 476,
    name: "Europe/Ulyanovsk",
  },
  {
    index: 477,
    name: "Europe/Uzhgorod",
  },
  {
    index: 478,
    name: "Europe/Vaduz",
  },
  {
    index: 479,
    name: "Europe/Vatican",
  },
  {
    index: 480,
    name: "Europe/Vienna",
  },
  {
    index: 481,
    name: "Europe/Vilnius",
  },
  {
    index: 482,
    name: "Europe/Volgograd",
  },
  {
    index: 483,
    name: "Europe/Warsaw",
  },
  {
    index: 484,
    name: "Europe/Zagreb",
  },
  {
    index: 485,
    name: "Europe/Zaporozhye",
  },
  {
    index: 486,
    name: "Europe/Zurich",
  },
  {
    index: 487,
    name: "GB",
  },
  {
    index: 488,
    name: "GB-Eire",
  },
  {
    index: 489,
    name: "GMT",
  },
  {
    index: 490,
    name: "GMT+0",
  },
  {
    index: 491,
    name: "GMT-0",
  },
  {
    index: 492,
    name: "GMT0",
  },
  {
    index: 493,
    name: "Greenwich",
  },
  {
    index: 494,
    name: "HST",
  },
  {
    index: 495,
    name: "Hongkong",
  },
  {
    index: 496,
    name: "Iceland",
  },
  {
    index: 497,
    name: "Indian/Antananarivo",
  },
  {
    index: 498,
    name: "Indian/Chagos",
  },
  {
    index: 499,
    name: "Indian/Christmas",
  },
  {
    index: 500,
    name: "Indian/Cocos",
  },
  {
    index: 501,
    name: "Indian/Comoro",
  },
  {
    index: 502,
    name: "Indian/Kerguelen",
  },
  {
    index: 503,
    name: "Indian/Mahe",
  },
  {
    index: 504,
    name: "Indian/Maldives",
  },
  {
    index: 505,
    name: "Indian/Mauritius",
  },
  {
    index: 506,
    name: "Indian/Mayotte",
  },
  {
    index: 507,
    name: "Indian/Reunion",
  },
  {
    index: 508,
    name: "Iran",
  },
  {
    index: 509,
    name: "Israel",
  },
  {
    index: 510,
    name: "Jamaica",
  },
  {
    index: 511,
    name: "Japan",
  },
  {
    index: 512,
    name: "Kwajalein",
  },
  {
    index: 513,
    name: "Libya",
  },
  {
    index: 514,
    name: "MET",
  },
  {
    index: 515,
    name: "MST",
  },
  {
    index: 516,
    name: "MST7MDT",
  },
  {
    index: 517,
    name: "Mexico/BajaNorte",
  },
  {
    index: 518,
    name: "Mexico/BajaSur",
  },
  {
    index: 519,
    name: "Mexico/General",
  },
  {
    index: 520,
    name: "NZ",
  },
  {
    index: 521,
    name: "NZ-CHAT",
  },
  {
    index: 522,
    name: "Navajo",
  },
  {
    index: 523,
    name: "PRC",
  },
  {
    index: 524,
    name: "PST8PDT",
  },
  {
    index: 525,
    name: "Pacific/Apia",
  },
  {
    index: 526,
    name: "Pacific/Auckland",
  },
  {
    index: 527,
    name: "Pacific/Bougainville",
  },
  {
    index: 528,
    name: "Pacific/Chatham",
  },
  {
    index: 529,
    name: "Pacific/Chuuk",
  },
  {
    index: 530,
    name: "Pacific/Easter",
  },
  {
    index: 531,
    name: "Pacific/Efate",
  },
  {
    index: 532,
    name: "Pacific/Enderbury",
  },
  {
    index: 533,
    name: "Pacific/Fakaofo",
  },
  {
    index: 534,
    name: "Pacific/Fiji",
  },
  {
    index: 535,
    name: "Pacific/Funafuti",
  },
  {
    index: 536,
    name: "Pacific/Galapagos",
  },
  {
    index: 537,
    name: "Pacific/Gambier",
  },
  {
    index: 538,
    name: "Pacific/Guadalcanal",
  },
  {
    index: 539,
    name: "Pacific/Guam",
  },
  {
    index: 540,
    name: "Pacific/Honolulu",
  },
  {
    index: 541,
    name: "Pacific/Johnston",
  },
  {
    index: 542,
    name: "Pacific/Kiritimati",
  },
  {
    index: 543,
    name: "Pacific/Kosrae",
  },
  {
    index: 544,
    name: "Pacific/Kwajalein",
  },
  {
    index: 545,
    name: "Pacific/Majuro",
  },
  {
    index: 546,
    name: "Pacific/Marquesas",
  },
  {
    index: 547,
    name: "Pacific/Midway",
  },
  {
    index: 548,
    name: "Pacific/Nauru",
  },
  {
    index: 549,
    name: "Pacific/Niue",
  },
  {
    index: 550,
    name: "Pacific/Norfolk",
  },
  {
    index: 551,
    name: "Pacific/Noumea",
  },
  {
    index: 552,
    name: "Pacific/Pago_Pago",
  },
  {
    index: 553,
    name: "Pacific/Palau",
  },
  {
    index: 554,
    name: "Pacific/Pitcairn",
  },
  {
    index: 555,
    name: "Pacific/Pohnpei",
  },
  {
    index: 556,
    name: "Pacific/Ponape",
  },
  {
    index: 557,
    name: "Pacific/Port_Moresby",
  },
  {
    index: 558,
    name: "Pacific/Rarotonga",
  },
  {
    index: 559,
    name: "Pacific/Saipan",
  },
  {
    index: 560,
    name: "Pacific/Samoa",
  },
  {
    index: 561,
    name: "Pacific/Tahiti",
  },
  {
    index: 562,
    name: "Pacific/Tarawa",
  },
  {
    index: 563,
    name: "Pacific/Tongatapu",
  },
  {
    index: 564,
    name: "Pacific/Truk",
  },
  {
    index: 565,
    name: "Pacific/Wake",
  },
  {
    index: 566,
    name: "Pacific/Wallis",
  },
  {
    index: 567,
    name: "Pacific/Yap",
  },
  {
    index: 568,
    name: "Poland",
  },
  {
    index: 569,
    name: "Portugal",
  },
  {
    index: 570,
    name: "ROC",
  },
  {
    index: 571,
    name: "ROK",
  },
  {
    index: 572,
    name: "Singapore",
  },
  {
    index: 573,
    name: "Turkey",
  },
  {
    index: 574,
    name: "UCT",
  },
  {
    index: 575,
    name: "US/Alaska",
  },
  {
    index: 576,
    name: "US/Aleutian",
  },
  {
    index: 577,
    name: "US/Arizona",
  },
  {
    index: 578,
    name: "US/Central",
  },
  {
    index: 579,
    name: "US/East-Indiana",
  },
  {
    index: 580,
    name: "US/Eastern",
  },
  {
    index: 581,
    name: "US/Hawaii",
  },
  {
    index: 582,
    name: "US/Indiana-Starke",
  },
  {
    index: 583,
    name: "US/Michigan",
  },
  {
    index: 584,
    name: "US/Mountain",
  },
  {
    index: 585,
    name: "US/Pacific",
  },
  {
    index: 586,
    name: "US/Pacific-New",
  },
  {
    index: 587,
    name: "US/Samoa",
  },
  {
    index: 588,
    name: "UTC",
  },
  {
    index: 589,
    name: "Universal",
  },
  {
    index: 590,
    name: "W-SU",
  },
  {
    index: 591,
    name: "WET",
  },
];
