import "react-datepicker/dist/react-datepicker.css";
import "react-tooltip/dist/react-tooltip.css";
import BasicButton from "../../../../../components/buttons/basic-button";

export default function SelectPurchaseOptions(props) {
  const { state } = props;
  return (
    <div
      className={`absolute z-50 overflow-y-auto top-0 w-full left-0 ${
        state.modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-6/12 h-4/12 "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <h1 className="text-2xl font-bold text-center">
                {/* {getActiveWidgetTitle(currentEditingWidget, widgets)} */}
                Display Button Options
              </h1>
            </div>
            {/* {!createProductMetaMode ? productMeta.p_type_sub_opt : "Invalid"} */}

            <div className="flex flex-col gap-4 w-full justify-center  mb-4 bg-gray-50 py-2">
              <div>
                <BasicButton
                  classes={"my-bg-yellow-500 text-black mb-2"}
                  disabled={state.cannotDisplayREButton()}
                  title={"Display Recurring Purchase Button"}
                  handleClick={() => state.addCustomPageProductMeta(2)}
                />
                <p className="text-pink-500 text-sm cursor-pointer">
                  {state.cannotDisplayREButton()
                    ? "Click here to setup Recurring Purchase Button"
                    : ""}
                </p>
              </div>
              <div>
                <BasicButton
                  classes={"my-bg-yellow-500 text-black mb-2"}
                  disabled={state.cannotDisplaySPButton()}
                  title={"Display Single Purchase Button"}
                  handleClick={() => state.addCustomPageProductMeta(1)}
                />
                <p className="text-pink-500 text-sm cursor-pointer">
                  {state.cannotDisplaySPButton()
                    ? "Click here to setup Single Purchase Button"
                    : ""}
                </p>
              </div>
            </div>

            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-black text-white rounded  mr-2"
              onClick={() => {
                state.hideAdModal();
              }}
            >
              <i class="fas fa-times"></i> Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
