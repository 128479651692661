import { useState, useEffect } from "react";
import BizLinkAssistSlider from "./slider";
import { useRef } from "react";
export default function SupplierBizLinkVerification(props) {
  const { bizLink, verification, handleBizLinkChange } = props;

  const inputRef = useRef(null);
  const [savingStage, setSavingStage] = useState(false);
  const [showSlider, setShowSlider] = useState(false);

  const openSliderModal = () => {
    setShowSlider(true);
  };

  const hideSliderModal = () => {
    setShowSlider(false);
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className="my-identity dropdown">
        <div id="input" class="flex flex-col w-full  my-5">
          <label for="password" class="text-gray-500 mb-2">
            Share Your Business Link from Google Maps or Bing Maps
          </label>
          <input
            value={bizLink}
            onChange={(e) => handleBizLinkChange(e.target.value)}
            type="text"
            id="identit-link"
            placeholder={`Paste your Business Link here`}
            class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
          />
          <small>
            Kindly avoid using shortened link formats. To ensure clarity about
            the destination, please provide the full link. For assistance in
            locating your business profile link on Google or Bing,{" "}
            <span
              className="text-blue-500 cursor-pointer"
              onClick={() => openSliderModal()}
            >
              click Here
            </span>
            .
          </small>
        </div>
      </div>
      <BizLinkAssistSlider
        modalOpen={showSlider}
        closeModal={hideSliderModal}
      />
    </>
  );
}
