import FacebookEmbedBox from "./components/facebook";
import PinterestEmbedBox from "./components/pinterest";
import TwitterEmbedBox from "./components/twitter";
import InstagramEmbedBox from "./components/instagram";
import BasicButton from "../../../../../../../components/buttons/basic-button";
import YoutubeLinkVideoBox from "./components/youtube/youtube";
import RadioBox from "../../../../../../../components/inputs/radio";
export default function SocialsUploader(props) {
  const { photos } = props;
  const { mediaType } = props;
  const { processing } = props;
  const { insertSocial } = props;
  const { handleAccordionSocialValueChange } = props;
  const { accordionSocialValue } = props;

  const { handleVideoPreview } = props;
  const { handleVideoLinkChange } = props;
  const { resetVideoPreview } = props;
  const { resetVideoLinkPreview } = props;
  const { videoFileSelected } = props;
  const { videoLinkValue } = props;
  const { insertYoutubeVideo } = props;

  const disableUploadButton = () => {
    if (!accordionSocialValue) return true;

    return false;
  };

  return (
    true && (
      <div className="my-4 w-full">
        {mediaType == "facebook" && (
          <FacebookEmbedBox
            handleChange={handleAccordionSocialValueChange}
            embedValue={accordionSocialValue}
          />
        )}

        {mediaType == "pinterest" && (
          <PinterestEmbedBox
            handleChange={handleAccordionSocialValueChange}
            embedValue={accordionSocialValue}
          />
        )}

        {mediaType == "twitter" && (
          <TwitterEmbedBox
            handleChange={handleAccordionSocialValueChange}
            embedValue={accordionSocialValue}
          />
        )}

        {mediaType == "instagram" && (
          <InstagramEmbedBox
            handleChange={handleAccordionSocialValueChange}
            embedValue={accordionSocialValue}
          />
        )}

        {mediaType == "youtube" && (
          <YoutubeLinkVideoBox
            handleVideoPreview={handleVideoPreview}
            handleVideoLinkChange={handleVideoLinkChange}
            resetVideoPreview={resetVideoPreview}
            resetVideoLinkPreview={resetVideoLinkPreview}
            videoFileSelected={videoFileSelected}
            videoLinkValue={videoLinkValue}
            insertYoutubeVideo={insertYoutubeVideo}
            processing={processing}
          />
        )}

        <div className="w-full my-2">
          {mediaType !== "upload" && mediaType !== "youtube" && (
            <BasicButton
              disabled={disableUploadButton()}
              title={processing ? "Uploading..." : "Upload"}
              classes="px-8 w-8/12 my-bg-yellow-500 text-black"
              handleClick={() => insertSocial()}
            />
          )}
        </div>
      </div>
    )
  );
}
