import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import StripeRedirectModal from "../../../modals/stripe-redirect-modal";
import StripeRedirectModeModal from "../../../modals/stripe-redirect-mode-modal";
import DeletePromptModal from "../../../modals/delete-warning-modal";
import Pagination from "../../../components/pagination";

import {
  isAdmin,
  API_BASE,
  isPublisher,
  getLocation,
  authUserData,
  isAuthUser,
  getClientBaseURL,
} from "../../../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
import Switch from "react-switch";
import PasswordPromptModal from "../../../modals/password-prompt-modal";
import CreateTierModal from "../../../modals/create-tier-modal";
export default function AdminDashboardStorage() {
  const [processing, setProcessing] = useState(false);
  const [deleteTierModalOpen, setDeleteTierModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [tiers, setTiers] = useState({});

  const { userId } = useParams();

  const showDeleteTierModal = () => {};

  const hideDeleteTierModal = () => {};

  const CreateTier = () => {};

  const getStoragePlans = () => {
    let url = API_BASE + "/api/storage/all";
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setTiers(response.data);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const deleteTier = (tierId) => {
    const url = API_BASE + "/api/storage/delete/" + tierId;
    setProcessing(true);

    axios
      .delete(url)
      .then((response) => {
        setProcessing(false);
        getStoragePlans();
        toast("Deleted!", { type: "success" });
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const doPagination = (page) => {
    if (tiers.first_page_url) {
      setCurrentPage(page);
      axios
        .get(tiers.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          setTiers(res.data);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    getStoragePlans();
  }, []);

  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">Storage</h1>
        </div>
        <div className="flex gap-2">
          <BasicButton
            title={"Create Tier"}
            classes="bg-teal-700"
            handleClick={() =>
              (window.location.href = `/admin/storage/${userId}/create`)
            }
          />
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center gap-4 mt-8 items-center mb-4">
        <h1 className="font-bold text-xl">Storage Tiers</h1>

        <div className="w-full p-8 flex flex-col">
          <div className="hidden md:flex justify-between w-full border-b p-4">
            {" "}
            <div className="text-center w-full font-bold">Name</div>
            <div className="text-center w-full font-bold">Threshold</div>
            <div className="text-center w-full font-bold">Price</div>
            <div className="text-center w-full text-base font-bold">
              <p>Prompt (File Larger than Threshold)</p>
            </div>
            <div className="text-center w-full text-base font-bold">
              <p>Prompt ( Extra File will break Data Threshold)</p>
            </div>
            <div className="text-center w-full font-bold">Date Created</div>
            <div className="text-center w-full font-bold">Modify</div>
            <div className="text-center w-full font-bold">Delete</div>
          </div>
          {tiers.data
            ? tiers.data.map((tier) => (
                <div className="flex flex-col gap-2 md:flex-row justify-between w-full border-b p-4 bg-gray-50 mb-1 ">
                  {" "}
                  <div className="text-center w-full">
                    <p className="md:hidden font-bold">Name</p>
                    {tier.name}
                  </div>
                  <div className="text-center w-full">
                    {" "}
                    <p className="md:hidden font-bold">Threshold</p>
                    {tier.threshold}
                  </div>
                  <div className="text-center w-full">
                    {" "}
                    <p className="md:hidden font-bold">Price</p>$
                    {tier.unit_amount}
                  </div>
                  <div className="text-center w-full">
                    {" "}
                    <p className="md:hidden font-bold">
                      Prompt (File Larger than Threshold)
                    </p>
                    {tier.message_one}
                  </div>
                  <div className="text-center w-full">
                    <p className="md:hidden font-bold">
                      Prompt ( Extra File will break Data Threshold)
                    </p>
                    {tier.message_two}
                  </div>
                  <div className="text-center w-full">
                    <p className="md:hidden font-bold">Created</p>
                    {tier.created_at}
                  </div>
                  <div className="text-center w-full p-1">
                    <BasicButton
                      title={"Edit"}
                      classes="bg-teal-700 w-full"
                      handleClick={() =>
                        (window.location.href = `/admin/storage/${userId}/edit/${tier.uuid}`)
                      }
                    />
                  </div>
                  <div className="text-center w-full p-1">
                    <BasicButton
                      disabled={tier.ActiveSubCount > 0}
                      title={"Delete"}
                      classes="bg-red-700 w-full"
                      handleClick={() => deleteTier(tier.uuid)}
                    />
                  </div>
                </div>
              ))
            : ""}
        </div>

        <div className="flex justify-center">
          {" "}
          {tiers.data ? (
            tiers.data.length ? (
              <Pagination pagination={tiers} doPagination={doPagination} />
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </div>

      <DeletePromptModal
        modalOpen={deleteTierModalOpen}
        hideAdModal={hideDeleteTierModal}
        title={
          "If you delete your account, you will stop receiving payments. Are you sure you want to delete this account?"
        }
        yesActionText={"Yes, Delete Account"}
        noActionText={"No, Cancel"}
        action={() => deleteTier()}
        processing={processing}
      />
    </>
  );
}
