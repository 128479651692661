export default function HamburgerBar(props) {
  const { togleMobileMenu } = props;
  return (
    <div className="w-2/12 flex justify-end">
      <button
        onClick={() => togleMobileMenu()}
        type="button"
        class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 border border-gray-500 shadow px-3"
        aria-expanded="false"
      >
        <span class="sr-only">Open menu</span>
        {/* <!-- Heroicon name: outline/menu --> */}
        <svg
          class="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>
  );
}
