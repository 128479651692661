import TextField from "../../../../components/inputs/text-input";
import BasicButton from "../../../../components/buttons/basic-button";
import { useBookStore } from "../../../../stores/book-store";
import InsertImage from "../../../publisher/rich-page/components/insert-image";
import { isResponsiveMode } from "../../../../Utils/helpers";
import YouTube from "react-youtube";
import { useState, useEffect } from "react";
import TextArea from "../../../../components/inputs/text-area";
import StripeCustomPageForm from "../../../../payment/custom-page-pay-button";
import BuyerContractConsent from "./contract-consent";

export default function SPPurchaseModal({
  modalOpen,
  hideModal,
  replyCaptured,
  setReplyCaptured,
  setProductMetaInfo,
  processProductBuyerReply,
  page,
  data,
}) {
  const meta = data.meta;

  const initialPurchaseMeta = {
    reply: "",
    fulfilment_location: "",
  };

  const [purchaseMeta, setPurchaseMeta] = useState(initialPurchaseMeta);
  const [consent, setConsent] = useState(false);

  function isNumber(n) {
    const testVal = !isNaN(parseFloat(n)) && isFinite(n);
    if (!testVal) {
      return false;
    }
    return parseFloat(n) > 0;
  }

  const hasValidFufilment = () => {
    if (!meta.location_fulfilment) return true;
    let fulCheck = meta.location_fulfilment.filter(
      (d) => d.location && isNumber(d.charge)
    );
    if (fulCheck.length && !purchaseMeta.fulfilment_location) {
      return false;
    }
    return true;
  };

  const shouldRenderFufilmentBox = () => {
    if (!meta.location_fulfilment) return true;
    return meta.location_fulfilment.filter(
      (d) => d.location && isNumber(d.charge)
    ).length;
  };

  const canSubmit = () => {
    if (!consent) return false;
    if (
      (computeMetaQuauntityValue() == "" || computeMetaQuauntityValue() == 0) &&
      meta.quantity_enabled
    )
      return false;
    if (!hasValidFufilment()) return false;
    if (meta.purchaser_reply_enabled && !purchaseMeta.reply) return false;
    return true;
  };

  const handlePurchaseMetaChange = (e, key, type) => {
    if (type == "checkbox") {
      setPurchaseMeta((prev) => ({ ...prev, [key]: !prev[key] }));
    } else if (type == "text" || type == "radio" || type == "dropdown") {
      if (type == "dropdown") {
        console.log("DROPDOWN", e.target.value);
        setPurchaseMeta((prev) => ({ ...prev, [key]: e.target.value }));
      } else {
        setPurchaseMeta((prev) => ({ ...prev, [key]: e }));
      }
    } else {
      setPurchaseMeta((prev) => ({ ...prev, [key]: e }));
    }
  };

  const computeMetaQuauntityValue = () => {
    if (!page.productmetas) return "";
    if (!meta.quantity && !meta.quantity_merge) {
      return "";
    } else if (meta.quantity) {
      return meta.quantity ? meta.quantity : "";
    } else if (meta.quantity_merge) {
      return page.productmetas.length
        ? page.productmetas.filter(
            (d) => d.block_meta_id == meta.quantity_merge
          )[0].quantity
        : "";
    }

    return "";
  };

  useEffect(() => {
    if (purchaseMeta.fulfilment_location) {
      const charge =
        Number(data.price) +
        Number(
          meta.location_fulfilment[purchaseMeta.fulfilment_location].charge
        );

      console.log("ZZZ" + " " + charge);
      const location =
        meta.location_fulfilment[purchaseMeta.fulfilment_location].location;
      setProductMetaInfo((prev) => ({
        ...prev,
        sel_fulfilment_location: location,
        sel_fulfilment_charge: charge,
        price: charge,
      }));
    } else {
      setProductMetaInfo((prev) => ({
        ...prev,
        sel_fulfilment_location: "",
        sel_fulfilment_charge: "",
        price: data.base_price,
      }));
    }
  }, [purchaseMeta.fulfilment_location]);

  useEffect(() => {
    if (purchaseMeta.reply) {
      setProductMetaInfo((prev) => ({
        ...prev,
        reply: purchaseMeta.reply,
      }));
    }
  }, [purchaseMeta.reply]);

  useEffect(() => {
    setPurchaseMeta(initialPurchaseMeta);
    setProductMetaInfo((prev) => ({
      ...prev,
      sel_fulfilment_location: "",
      sel_fulfilment_charge: "",
      reply: "",
    }));
  }, []);

  return (
    <div
      className={` ${
        isResponsiveMode() ? "fixed" : "fixed"
      }  z-40 overflow-y-auto top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="relative inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-6/12 h-4/12"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className="rounded rounded-full p-0 -top-2 -right-1 absolute px-1 cursor-pointer opacity-75"
            onClick={() => hideModal()}
          >
            <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
          </div>

          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <h1 className="text-2xl font-bold text-center text-black">
                {/* {getActiveWidgetTitle(currentEditingWidget, widgets)} */}
                {replyCaptured ? "Payment" : "Pre Payment"}
              </h1>
            </div>
            {replyCaptured ? (
              <div className="grid grid-cols-1 gap-4">
                <StripeCustomPageForm
                  data={data}
                  action={hideModal}
                  computeMetaQuauntityValue={computeMetaQuauntityValue}
                />
              </div>
            ) : (
              <>
                {shouldRenderFufilmentBox() ? (
                  <div className="w-full my-4">
                    <label className="font-bold">Fulfillment Location</label>
                    <select
                      value={purchaseMeta.fulfilment_location}
                      onChange={(e) =>
                        handlePurchaseMetaChange(
                          e,
                          "fulfilment_location",
                          "dropdown"
                        )
                      }
                      required
                      class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
                    >
                      <option value="">Select your location</option>

                      {meta.location_fulfilment
                        .filter((d) => d.location && isNumber(d.charge))
                        .map((b, i) => (
                          <option value={i}>
                            {" "}
                            {b.location} +${b.charge}
                          </option>
                        ))}
                    </select>
                  </div>
                ) : (
                  ""
                )}

                {meta.purchaser_reply_enabled ? (
                  <div className="my-3 w-full border flex flex-col items-center justify-center bg-gray-50 p-2 h-26 py-6 overflow-y-scroll">
                    <p>{meta.purchaser_reply}</p>{" "}
                    <TextArea
                      placeholder={"Enter text here.."}
                      classes="rounded-lg pr-8"
                      handleChange={(e) =>
                        handlePurchaseMetaChange(e, "reply", "text")
                      }
                      value={purchaseMeta.reply}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="w-full flex my-2">
                  <BuyerContractConsent
                    consent={consent}
                    setConsent={setConsent}
                    file={data.contract}
                    file2={data.systembuyercontractfile}
                  />
                </div>
                <div className="flex justify-center">
                  <BasicButton
                    disabled={!canSubmit()}
                    title={
                      "Proceed to " +
                      meta.p_type_sub_opt +
                      " " +
                      "$" +
                      data.price
                    }
                    classes="px-8 w-8/12"
                    handleClick={() =>
                      canSubmit() && processProductBuyerReply()
                    }
                  />
                </div>
              </>
            )}

            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            {replyCaptured && (
              <button
                type="button"
                class="py-2 px-4 bg-black text-white rounded hover:bg-gray-700 mr-2"
                onClick={() => setReplyCaptured(false)}
              >
                Cancel
              </button>
            )}
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
            {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
