import { useSendMailStore } from "../../../../../stores/send-mail-store";
import { isResponsiveMode } from "../../../../../Utils/helpers";
import BasicButton from "../../../../../components/buttons/basic-button";

export default function DesktopTable(props) {
  const mailStore = useSendMailStore((state) => state);

  return (
    <div className="overflow-x-scroll md:h-screen w-full">
      <table class="table-auto w-full my-4">
        <thead>
          <tr className={window.innerWidth < 720 ? "hidden" : ""}>
            <th class="px-4 py-2 text-gray-600">Name</th>
            {/* <th class="px-4 py-2 text-gray-600">Country</th> */}
            <th class="px-4 py-2 text-gray-600">Supplier</th>
            <th class="px-4 py-2 text-gray-600">Status</th>
            <th class="px-4 py-2 text-gray-600">Creation Type</th>
            <th class="px-4 py-2 text-gray-600">Application</th>
            <th class="px-4 py-2 text-gray-600">Date Created</th>
          </tr>
        </thead>
        <tbody className="w-full">
          {mailStore.data.data &&
            mailStore.data.data.map((d) => {
              return (
                <tr
                  className={
                    window.innerWidth < 820 ? "flex flex-col items-center " : ""
                  }
                >
                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Supplier</h3>
                    {d?.name}
                  </td>
                  <td class="border border-gray px-4 py-2  font-medium">
                    <div className="text-center md:text-left">
                      <p className="break-words">
                        <p className="text-green-500"> First Name</p>
                        <small className="text-base"> {d.first_name}</small>
                      </p>
                      <p className="break-words">
                        <p className="text-green-500"> Last Name</p>
                        <small className="text-base"> {d.last_name}</small>
                      </p>
                      <p className="break-words">
                        <p className="text-green-500"> Username</p>
                        <small className="text-base"> {d.username}</small>
                      </p>
                      <p className="break-words">
                        <p className="text-green-500"> User Handle</p>
                        <small className="text-base"> {d.handle_id}</small>
                      </p>
                      <p className="break-words">
                        <p className="text-green-500"> Email</p>
                        <small className="text-base"> {d.email}</small>
                      </p>
                      <p className="break-words">
                        <p className="text-green-500"> Phone</p>
                        <small className="text-base"> {d?.phone?.phone}</small>
                      </p>
                    </div>
                  </td>

                  <td class="border border-gray px-4 py-2  font-medium">
                    <div className="flex flex-col justify-center items-center">
                      {d?.reg_stage}
                    </div>
                  </td>
                  <td class="border border-gray px-4 py-2  font-medium">
                    <div className="flex flex-col justify-center items-center">
                      {d?.sales_supplier_creation_type}
                    </div>
                  </td>
                  <td class="border border-gray  py-2  font-medium">
                    <div className="items-center w-full">
                      <BasicButton
                        disabled={d.sales_supplier_creation_type == "link"}
                        classes={"bg-black-500 hover:bg-black-500 w-full py-6"}
                        title={"View Application"}
                        handleClick={() =>
                          (window.location.href = `/sales/supplier/${d?.uuid}/supplier-application`)
                        }
                      />
                    </div>
                  </td>

                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Date</h3>

                    {d?.refactoredCreatedAtTime
                      ? new Date(
                          d?.refactoredCreatedAtTime.replace(/-/g, "/")
                        ).getDate() +
                        "-" +
                        (new Date(
                          d?.refactoredCreatedAtTime.replace(/-/g, "/")
                        ).getMonth() +
                          1) +
                        "-" +
                        new Date(
                          d.refactoredCreatedAtTime.replace(/-/g, "/")
                        ).getFullYear()
                      : "N/A"}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
