import { isResponsiveMode } from "../../Utils/helpers";
import { Document, Page, pdfjs } from "react-pdf";
export default function RenderLeftAdPageMediaPreview(props) {
  const {
    views,
    page,
    pageNumber,
    onDocumentLoadSuccess,
    isAdPage,
    portraitMode,
    isPublisherAdPage,
    isSystemAdPage,
  } = props;

  const getPrevPageIndex = () => {
    if (views.length && isPublisherAdPage()) {
      return 1;
    }
    if (views.length && isSystemAdPage()) {
      return 2;
    }
    console.log("Page xxxxx", page);
    return 1;
  };

  return (
    <>
      {isResponsiveMode() &&
        !portraitMode &&
        isAdPage() &&
        views[page - getPrevPageIndex()]?.type == "image" && (
          <div className="flex items-center justify-center">
            <img
              className="mt-5"
              src={
                process.env.PUBLIC_URL + views[page - getPrevPageIndex()].file
              }
              width="85%"
            />
          </div>
        )}
      {isResponsiveMode() &&
        !portraitMode &&
        isAdPage() &&
        views[page - getPrevPageIndex()]?.type == "pdf" && (
          <div className="flex items-center justify-center">
            {/* {views.length && (
                      <p className="text-white">{views[page + 1].file}</p>
                    )} */}

            <div width="100 bg-green-500">
              {/* <p className="text-white">{views[page - 1].file}</p> */}
              <Document
                file={
                  process.env.PUBLIC_URL + views[page - getPrevPageIndex()].file
                }
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
          </div>
        )}
      {!isResponsiveMode() &&
        isAdPage() &&
        views[page - getPrevPageIndex()]?.type == "image" && (
          <div
            className="flex items-center justify-center absolute"
            style={{
              top: "25%",
              left: "19%",
            }}
          >
            <img
              src={
                process.env.PUBLIC_URL + views[page - getPrevPageIndex()].file
              }
              width="240"
            />
          </div>
        )}
      {!isResponsiveMode() &&
        views.length &&
        isAdPage() &&
        views[page - getPrevPageIndex()]?.type == "pdf" && (
          <div
            className="flex items-center justify-center absolute"
            style={{
              top: "25%",
              left: "20%",
            }}
          >
            <div width="">
              <Document
                file={
                  process.env.PUBLIC_URL + views[page - getPrevPageIndex()].file
                }
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
          </div>
        )}
    </>
  );
}
