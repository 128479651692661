import moment from "moment";
import { useEffect, useState } from "react";
import { isResponsiveMode } from "../../../../../../Utils/helpers";
export default function StickyRightPanel(props) {
  const { subs, monthDays, div1Ref, selYear, selMonth, monthsList } = props;

  function getDaysInMonth(month, year) {
    // Create a Date object for the first day of the month
    const date = new Date(year, month, 1);

    // Get the last day of the month (0-indexed)
    const lastDay = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();

    const dates = [];
    for (let i = 1; i <= lastDay; i++) {
      // Create a new Date object for each day
      const dayDate = new Date(year, month, i);

      // Add the date object to the dates array
      dates.push(dayDate);
    }
    return dates;
  }

  useEffect(() => {
    if (div1Ref.current) {
      // div1Ref.current?.addEventListener("scroll", handleDiv1Scroll);
    }
    console.log(getDaysInMonth(4, selYear));
  }, []);

  const cWidth = isResponsiveMode() ? 170 : 550;

  return (
    <div
      ref={div1Ref}
      className="overflow-x-scroll overflow-y-hidden inline"
      style={{
        width: document.documentElement.clientWidth - cWidth + "px",
        margin: "0 auto",
      }}
    >
      {" "}
      <div className="flex">
        {selMonth && selYear ? (
          getDaysInMonth(monthsList[selMonth], selYear).map((d, i) => (
            <div className="font-bold border flex flex-col">
              <p className="border h-10 w-40">
                {i + 1} -{moment(d).format("ddd")}
              </p>
            </div>
          ))
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
}
