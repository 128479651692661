import BasicButton from "../../../../../../components/buttons/basic-button";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { isResponsiveMode } from "../../../../../../Utils/helpers";
import TextArea from "../../../../../../components/inputs/text-area";
import { useSupplierApplicationsStore } from "../../../../../../stores/supplier-applications-store";
import { useState } from "react";
export default function ReplaceContractModal(props) {
  const {
    modalOpen,
    closeModal,
    processing,
    processSelection,
    productContracts,
    selectedContractId,
    handleContractIdChange,
    currentContractId,
  } = props;
  const [sliderToShow, setSliderToShow] = useState(0);
  const mailStore = useSupplierApplicationsStore((state) => state);

  return (
    <div
      className={` ${
        isResponsiveMode() ? "fixed" : "absolute"
      }  z-40 overflow-y-auto top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen ">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-6/12"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <h1 className="text-2xl font-bold text-center">
                {/* {getActiveWidgetTitle(currentEditingWidget, widgets)} */}
                Replace Contract for All
              </h1>
            </div>
            <div className="w-full  m-2">
              <div className="px-6 mb-2 w-full text-center">
                {/* <h2> Single Item</h2> */}
                <div className="w-full my-5">
                  <p>
                    {" "}
                    This feature allows you to replace the contract in all the
                    buttons listed in the table with another one from the
                    dropdown list below. If you do not want to proceed, click
                    “Exit”. If you wish to proceed, simply select a contract
                    from the dropdown list below and click “Replace Contract.
                  </p>
                </div>

                <div className="w-full my-5">
                  <label className="font-bold">Select Contract</label>
                  <select
                    value={selectedContractId}
                    onChange={(e) => handleContractIdChange(e)}
                    required
                    class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
                  >
                    <option value="">Not Selected</option>

                    {productContracts.length &&
                      productContracts
                        .filter((c) => c.uuid !== currentContractId)
                        .map((c) => <option value={c.id}>{c.name}</option>)}
                  </select>
                </div>
              </div>
            </div>
            <BasicButton
              disabled={processing || !selectedContractId}
              title={processing ? "Replacing.." : "Replace Contract for All"}
              handleClick={processSelection}
            />
            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => closeModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
            {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
