import BasicButton from "../../../../components/buttons/basic-button";
import { BASE_URL } from "../../../../Utils/helpers";
import SalesImageModal from "./photo-viewer";
import { useState } from "react";
export default function AdminMobileSalesTable({ data, f }) {
  const { openDeleteModal } = f;
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const openImageModal = (d) => {
    const link = BASE_URL + "/storage" + d.profile_photo.split("public")[1];
    setImageUrl(link);
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
  };
  return (
    <>
      <div className="w-full flex flex-col justify-center my-4 text-center">
        {data.data
          ? data.data.map((p, i) => {
              return (
                <div
                  className="overflow-x-scroll inline"
                  style={{
                    width: document.documentElement.clientWidth - 90 + "px",
                    margin: "0 auto",
                  }}
                >
                  <div className="flex">
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">
                        Date Created
                      </h3>
                      {p.simpleDateString}
                    </div>

                    <div class="border  px-4 py-2 font-medium text-center md:text-left">
                      <p className="break-words">
                        <p className="text-green-500"> First Name</p>
                        <small className="text-base"> {p.first_name}</small>
                      </p>
                      <p className="break-words">
                        <p className="text-green-500"> Last Name</p>
                        <small className="text-base"> {p.last_name}</small>
                      </p>
                      <p className="break-words">
                        <p className="text-green-500"> Username</p>
                        <small className="text-base"> {p.username}</small>
                      </p>
                      <p className="break-words">
                        <p className="text-green-500"> User Handle</p>
                        <small className="text-base"> {p.handle_id}</small>
                      </p>
                      <p className="break-words">
                        <p className="text-green-500"> Email</p>
                        <small className="text-base"> {p?.email}</small>
                      </p>
                      <p className="break-words">
                        <p className="text-green-500"> Phone</p>
                        <small className="text-base"> {p?.phone?.phone}</small>
                      </p>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left w-full">
                      <h3 className="font-bold md:hidden text-xxs">Email</h3>
                      <span className="break-words">{p?.email ?? "N/A"}</span>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left w-full">
                      <h3 className="font-bold md:hidden text-xxs">Phone</h3>
                      <span className="break-words">
                        {p?.verifiedphone?.phone ?? "N/A"}
                      </span>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">Country</h3>
                      <span className="break-words">{p?.country ?? "N/A"}</span>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">
                        State/Province
                      </h3>
                      <span className="break-words">
                        {p?.state_province ?? "N/A"}
                      </span>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">Photo</h3>
                      {p.profile_photo ? (
                        <BasicButton
                          title={"View"}
                          handleClick={() => openImageModal(p)}
                        />
                      ) : (
                        "N/A"
                      )}
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">Delete</h3>
                      <BasicButton
                        title={"Delete"}
                        classes={"bg-red-500 text-white"}
                        handleClick={() => openDeleteModal(p)}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
      <SalesImageModal
        modalOpen={imageModalOpen}
        closeModal={closeImageModal}
        imageUrl={imageUrl}
      />
    </>
  );
}
