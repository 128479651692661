export default function CustomerServices(props) {
  const { productMeta, handleMetaChange, customerServiceSets } = props;

  return (
    <div className="w-full md:w-6/12 my-5">
      {/* {JSON.stringify(customerServiceSets)} */}
      <label className="font-bold">Customer Service Contact</label>
      <div className="flex gap-2">
        <select
          value={productMeta.supplier_customer_service_id}
          onChange={(e) =>
            handleMetaChange(e, "supplier_customer_service_id", "dropdown")
          }
          required
          class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
        >
          <option value="">Not Selected</option>

          {customerServiceSets.length &&
            customerServiceSets.map((c) => (
              <option value={c.id}>{c.name}</option>
            ))}
        </select>
        {/* {!productMeta.supplier_customer_service_id && (
          <span className="text-red-500 text-2xl">*</span>
        )} */}
      </div>
    </div>
  );
}
