import { useState } from "react";
import {
  authUserData,
  isAdmin,
  isSales,
  isAuthUser,
  isPublisher,
  API_BASE,
  isSponsor,
} from "../Utils/helpers";
import BasicButton from "../components/buttons/basic-button";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AdminLeftNav from "./nav/admin-left-nav";
import PublisherLeftNav from "./nav/publisher-left-nav";
import SalesLeftNav from "./nav/sales-left-nav";
import SponsorLeftNav from "./nav/sponsor-left-nav";
import PublisherTutorialBar from "./nav/header/mobile/tutorial-bar";

export default function LeftNav() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [user, setUser] = useState({});
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const showResumeApplicationButton = () => {
    return localStorage.getItem("resume_supplier_application") !== null;
  };

  const isPublisherDash = () =>
    location.pathname.includes("publisher/publications/options/") &&
    isPublisher();

  return (
    <>
      <aside
        class="col-span-5 md:col-span-1 AsideBackground bg-gray-50 p-1"
        aria-label="Sidebar"
      >
        {/* mobile  */}
        {/* flex md:hidden flex-row justify-between w-full shadow p-2 cursor-pointer */}

        <div className="flex md:hidden flex-row justify-between w-full gap-2 p-1">
          <div className="w-2/12">
            {" "}
            <a href="/">
              <img class="w-full" src="/litzap_logo.png" alt="" />
            </a>
          </div>
          {showResumeApplicationButton() && isPublisherDash() && (
            <BasicButton
              title={"Resume Application"}
              classes="my-bg-yellow-500 text-black md:hidden w-full"
              handleClick={() =>
                (window.location.href = `/publisher/supplier/onboarding/start`)
              }
            />
          )}

          <PublisherTutorialBar
            classes={"w-full text-center md:hidden self-center"}
            btClass={"md:hidden"}
          />

          <div className="w-2/12 flex justify-end">
            <button
              onClick={() => setShowMobileMenu((showMenu) => !showMenu)}
              type="button"
              class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 border border-gray-500 shadow"
              aria-expanded="false"
            >
              <span class="sr-only">Open menu</span>
              {/* <!-- Heroicon name: outline/menu --> */}
              <svg
                class="h-6 w-9"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
        {/* mobile */}
        <div
          class="overflow-y-auto py-4 px-3 rounded md:h-screen bg-gray-100 bg-green-50"
          className={`${
            !showMobileMenu
              ? "hidden md:flex flex-col md:flex-row gap-4 items-center"
              : "flex flex-col md:flex-row gap-4 items-center shadow"
          }`}
        >
          {isAdmin() && <AdminLeftNav />}
          {isPublisher() && <PublisherLeftNav />}
          {isSales() && <SalesLeftNav />}
          {isSponsor() && <SponsorLeftNav />}
        </div>
      </aside>
    </>
  );
}
