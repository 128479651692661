import axios from "axios";
import { useEffect, useState } from "react";
import BasicButton from "../../../components/buttons/basic-button";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";

import {
  API_BASE,
  BASE_URL_WITH_LOCAL,
  BASE_URL,
} from "../../../Utils/helpers";
import { toast } from "react-toastify";

export default function PubSupplierSampleContracts() {
  const [processing, setProcessing] = useState(false);
  const downloadDoc = (contract) => {
    //alert(Config.backendUrl())
    axios({
      url: BASE_URL + "/storage" + contract.file.split("public")[1],
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fURL = document.createElement("a");

        fURL.href = fileURL;
        fURL.setAttribute(
          "download",
          contract.type.split(" ").join("_") + "." + contract.file.split(".")[1]
        );
        document.body.appendChild(fURL);

        fURL.click();
      })
      .catch(() => {
        console.log("handle server error from here");
      });
  };

  const getContract = (type) => {
    const url = API_BASE + "/admin/supplier/contract/admin/" + type;
    setProcessing(true);

    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.data.contract) {
          downloadDoc(response.data.contract);
        } else {
          toast("Sorry, this action cannot be completed at the moment.", {
            type: "error",
          });
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };
  return (
    <>
      <div className="main w-full">
        <div className="bg-white m-2 p-2 flex justify-between shadow px-4">
          <div>
            <h1 className="text-2xl text-center m-2 bold"> Sample Contracts</h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex justify-center my-4 overflow-hidden">
          <div class="flex flex-col items-center p-10 gap-4 md:w-6/12">
            <div className="w-full">
              <BasicButton
                title={"Sample Contract (Single Purchase)"}
                handleClick={() => getContract("sample-contract-single")}
                classes={"w-full"}
              />
            </div>
            <div className="w-full">
              <BasicButton
                title={"Sample Contract (Recurring Purchase)"}
                handleClick={() => getContract("sample-contract-recurring")}
                classes={"w-full"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
