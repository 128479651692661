import create from "zustand";

const inititState = {
  ids: [],
  refresh: true,
  selType: "M",
  selected: [],
  deSelected: [],
  selectedCount: 0,
  data: {},
  isAllSelected: false,
  query: {
    countPerPage: 10,
    pageLimit: "normal_1000000",
    searchText: "",
    selectedLatest: "",
    selectedOldest: "",
    status: "",
    selectedApplicantStatus: "",
    comment: "",
    existing_comment: "",
    application: {},
    contract: {},
  },
};

const initStateSendPage = {
  books: [],
  greeting: "",
  recipientNameType: "",
  item: "",
  currItemPrice: 0,
  currItemName: "",
  subject: "",
  startDate: new Date(),
  time: "0",
  AMPM: "",
  price: 0,
  body: "",
  language: "",
  user: {},
};

export const useSupplierApplicationsStore = create((set) => ({
  ...inititState,
  setData: (value) =>
    set((state) => ({
      data: value,
    })),
  setApplicationData: (value) =>
    set((state) => ({
      application: value,
    })),
  setContractData: (value) =>
    set((state) => ({
      contract: value,
    })),
  setQuery: (key, value) =>
    set((state) => ({
      query: { ...state.query, [key]: value },
    })),

  setSelectedApplicantStatus: (value) =>
    set((state) => ({
      selectedApplicantStatus: value,
    })),
  handleCommentChange: (value) =>
    set((state) => ({
      comment: value,
    })),
  handleExistingCommentChange: (value) =>
    set((state) => ({
      existing_comment: value,
    })),
  resetMailStore: () => {
    set(inititState);
  }, ///END LIST STATE
  ...initStateSendPage,
  setUser: (value) =>
    set((state) => ({
      user: value,
    })),
  resetSendPageState: () => {
    set(initStateSendPage);
  },
}));
