import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import WebHeader from "./web/header";
import { isAdmin } from "../Utils/helpers";
import { isAuthUser, API_BASE } from "../Utils/helpers";
import Footer from "../layout/footer";
import axios from "axios";

export default function FAQ() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const naviagte = useNavigate();

  return (
    <>
      {" "}
      <WebHeader />
      <div class="px-6">
        <br />
        <div class="flex flex-col text-center text-left justify-evenly items-center">
          <div class="flex flex-col w-full md:w-7/12 items-center faq-page">
            <div></div>
            <h1 class="text-3xl text-gray-800 font-bold my-2">
              Frequently Asked Questions
            </h1>
            <p>
              <b className="font-bold">What is Litzapp?</b>
            </p>
            <p>
              Litzapp is the&nbsp;
              <b className="font-bold">Ultimate Content Monetization</b>
              &nbsp;platform that empowers content creators to&nbsp;
              <b className="font-bold">make money</b>&nbsp;from their finished
              or unfinished books, photos, articles, comics, videos, school
              materials, property listings, public diaries, business reports,
              job postings, school assignments, digital leaflets, product
              manuals, invitations, leaflets, and other content forms.&nbsp;
            </p>
            <p>
              <br />
            </p>
            <p>
              It is the most flexible online content monetization platform.
              Content creators can instantly monetize their content or skills
              via&nbsp;
              <b className="font-bold">
                ad revenue,&nbsp;subscriptions,&nbsp;purchases
              </b>
              , or&nbsp;<b className="font-bold">donations.</b>
            </p>
            <p>
              <br />
            </p>
            <p>
              <b className="font-bold">
                How do I create an account on Litzapp and activate a Publisher
                Account?
              </b>
            </p>
            <p>
              Click&nbsp;
              <a
                href="https://litzapp.com/web/rich-page/render/bde7fa92-83d7-4f2b-b240-c752501220c2"
                className="text-blue-600"
              >
                HERE
              </a>{" "}
              for a simple video guide on using the Litzapp platform.
            </p>
            <p>
              <br />
            </p>
            <p>
              <b className="font-bold">
                How do I Update My Name, Email, Country, and Password?
              </b>
            </p>
            <p>
              Non-Publisher Account (that is a normal user account): Simply log
              in, go to the menu and select Update Password&ndash;for password
              changes&ndash;or Update Info for changes to your name, email, and
              country.
            </p>
            <p>
              <br />
            </p>
            <p>
              Publisher Account: Simply log in, go to your dashboard menu, click
              Account Setting, scroll down to Holder Information to update your
              name and country, or scroll down to update your email and
              password.
            </p>
            <p>
              <br />
            </p>
            <p className="font-bold">
              How do I view content items I have purchased or accounts that I am
              subscribed to?
            </p>
            <p>
              Non-Publisher Account (that is a normal user account): Log in to
              your account go to the menu and select My Purchases to view
              content items that you have purchased, or select My Subscriptions
              to view Publisher accounts that you are subscribed to.
            </p>
            <p>
              <br />
            </p>
            <p>
              Publisher Account: Log into your account, go to your dashboard
              menu, and select My Purchases to view content items that you have
              purchased, or select My Subscriptions to view the Publishers that
              you are subscribed to.
            </p>
            <p>
              <br />
            </p>
            <p className="font-bold">
              How do I cancel my subscriptions on Litzapp?
            </p>
            <p>
              Non-Publisher Account (that is a normal user account): Log in to
              your account go to the menu and select My Subscriptions to view
              the Publisher accounts that you are subscribed to. When in there,
              you can cancel subscriptions to the various Publisher accounts.
            </p>
            <p>
              <br />
            </p>
            <p>
              Publisher Account: Log into your account, go to your dashboard
              menu, and select My Subscriptions to view the Publishers that you
              are subscribed to. When in there, you can cancel subscriptions to
              the various Publisher accounts.
            </p>
            <p>
              <br />
            </p>
            <p className="font-bold">
              How can I view Publishers that I have registered with to get email
              updates?
            </p>
            <p>
              Non-Publisher Account (that is a normal user account): Log in to
              your account go to the menu and select Requested Updates to view
              the Publisher accounts that you have registered your email with to
              receive updates.
            </p>
            <p>
              <br />
            </p>
            <p>
              Publisher Account: Log into your account, go to your dashboard
              menu, and select My Requested Updates to view the Publisher
              accounts that you have registered your email with to receive
              updates.
            </p>
            <p>
              <br />
            </p>
            <p className="font-bold">
              Can a Purchased Content Item be modified or deleted?
            </p>
            <p>
              A purchased content item can be modified by its Publisher. A
              purchased content item, however, cannot be deleted.
            </p>
            <p>
              <br />
            </p>
            <p className="font-bold">
              Can a Publisher delete content items from the Publisher&rsquo;s
              account even though the account has active subscribers?
            </p>
            <p>
              Yes, a Publisher can delete content items from the
              Publisher&rsquo;s account even though the account has active
              subscriptions. A Publisher, however, cannot delete a content item
              that has been purchased individually.
            </p>
            <p>
              <br />
            </p>
            <p className="font-bold">
              What are Item IDs and User Handles Used For?
            </p>
            <p>
              &quot;Item IDs&quot; and &quot;User Handles&quot; are public
              unique identifiers assigned to a content item or a user,
              respectively. These identifiers remain the same, regardless of any
              name changes made to the item or user. By using these identifiers,
              it is possible to ensure that the content item or user can still
              be easily located and referenced. This can be particularly useful
              when searching for a specific content item or user on Litzapp.
            </p>
            <p>
              <br />
            </p>
            <p className="font-bold">
              How do I enable the Subscription Feature on my Publisher account?
            </p>
            <p>
              Log in to your Publisher account, go to the dashboard, and select
              Account Setting in the dashboard menu. If you are on mobile, the
              dashboard menu can be accessed via the square with three lines on
              the top-right corner of the screen. When you are on the Account
              Setting page, find the Enable Subscription section and click the
              Enable Subscription toggle. If the toggle changes to
              &ldquo;Disable&rdquo; and has a white tick ✓ symbol within a green
              background, then subscription has been enabled on your Publisher
              account. This means that users can subscribe to your account.
              Remember to click Update when you are done so that your decision
              is saved.
            </p>
            <p>
              <br />
            </p>
            <p>
              When you enable the subscription feature, you must enter your
              Subscription Cost under &ldquo;Subscription Cost (USD)&rdquo;.
              Subscription Cost (USD) is the amount your subscribers will pay
              you monthly if they have an active subscription with your
              Publisher account on Litzapp. Please note that whatever amount you
              enter under Subscription Cost (USD) will be added to the Litzapp
              service charge to produce the final amount that your subscribers
              will pay monthly.
            </p>
            <p>
              <br />
            </p>
            <p className="font-bold">
              What is the Litzapp Service Charge and Publisher Fee?
            </p>
            <p>
              Litzapp charges two types of fees: the Service Charge and the
              Publisher Fee.
            </p>
            <p>
              <br />
            </p>
            <p>
              The Service Charge is the amount that Litzapp charges for each
              purchase or subscription, and it is paid by either the purchaser
              or the subscriber. It&apos;s worth noting that the Service Charge
              may change from time to time, and Publishers need to stay up to
              date with the changing Service Charge to know how much their
              customers are finally charged. The current Service Charge can be
              viewed when creating or updating a content item or updating a
              Subscription Cost.
            </p>
            <p>
              <br />
            </p>
            <p>
              The Publisher Fee, on the other hand, is a percentage of the
              Subscription Cost or content item cost that remains after our
              payout service providers have taken out their standard payment
              transaction fees; the Publisher Fee goes to Litzapp. The Publisher
              Fee ranges between 3% to 5%, depending on the Publisher&apos;s
              sales performance. The more or higher the Publisher sells, the
              less the Publisher Fee for that Publisher. However, during
              promotions, the Publisher Fee may be set at a flat rate of 3% for
              all Publishers.
            </p>
            <p>
              <br />
            </p>
            <p className="font-bold">
              What is the Payout Service Provider Fee?
            </p>
            <p>
              The Payout Service Provider Fee is the fee charged to a Publisher
              by any of our Payout Service Providers for facilitating the payout
              to the Publisher&rsquo;s bank card or bank account. The Payout
              Service Provider that Litzapp is currently using is Stripe. The
              Stripe payout fee varies depending on several factors, such as the
              currency and country of the bank account receiving the payout, as
              well as the payment method used to send the payout.
            </p>
            <p>
              <br />
            </p>
            <p>
              The standard Stripe fee is the fee that is charged on every
              transaction that is processed through the Stripe platform. This
              fee includes a flat rate of 30 cents per successful card charge,
              plus a percentage fee of 2.9% of the total transaction amount.
              Additionally, there are additional fees that may be charged for
              certain types of transactions, such as manually entered cards
              (0.5%), international cards (1%), and currency conversion (1%).
            </p>
            <p>
              <br />
            </p>
            <p>
              Payments; Cards and wallets &middot; 2.9% + 30&cent; per
              successful card charge +0.5% for manually entered cards +1% for
              international cards +1% if currency conversion
            </p>
            <p>
              <br />
            </p>
            <p>
              In addition to the standard Stripe fee, there may also be a payout
              fee that is charged when funds are transferred from a Stripe
              account to a bank account. The payout fee varies depending on the
              country of the payee, with the fee being 0.25 USD per transfer in
              the United States. In some other countries, the fee may be higher,
              especially if the payout is being made to a bank account in a
              different currency.
            </p>
            <p>
              <br />
            </p>
            <p>
              Finally, there may be additional fees for sending Instant Payouts,
              which are immediate transfers of funds to a connected bank
              account. These fees are typically 1% of the total payout volume,
              with a minimum fee of 50 cents.
            </p>
            <p>
              <br />
            </p>
            <p>
              It&apos;s important to note that the exact fees charged by Stripe
              may vary depending on the specific circumstances of each
              transaction, so it&apos;s always a good idea to review
              Stripe&apos;s pricing and fee structure to ensure that you have a
              clear understanding of the costs involved.
            </p>
            <p>
              <br />
            </p>
            <p>
              It&apos;s best to check Stripe&apos;s website or contact their
              support team directly to get the most up-to-date information on
              payout fees for your specific situation.
            </p>
            <p>
              <br />
            </p>
            <p className="font-bold">
              Payout Details: How can I add or update either my bank card or
              bank account on my Publisher account?
            </p>
            <p>
              Log into your account, go to Dashboard, click Account Setting,
              scroll down to Payout, and either Connect or Update your bank card
              or bank account.
            </p>
            <p>
              <br />
            </p>
            <p className="font-bold">
              How do I view purchases of my content items, subscribers to my
              account, and donations to my work?
            </p>
            <p>
              Log in to your Publisher account, go to the dashboard, and select
              Subscribers List to view subscribers to your account, select
              Purchase List to view all purchases of content items on your
              account, or select Donations List to view all donations and donors
              to your work.
            </p>
            <p>
              <br />
            </p>
            <p>
              How do I view users that have registered their emails on my
              account in order to get updates from me?
            </p>
            <p>
              Log in to your Publisher account, go to the dashboard, and select
              Request Update List to view all users that have registered with
              your account in order to get updates from you.&nbsp;
            </p>
            <p>&nbsp;</p>
            <p className="font-bold">
              How do I create books, photo albums, articles, or comics on
              Litzapp?
            </p>
            <p>
              Log into your Publisher account, go to your dashboard, click
              Create, and select what type of content item you want to create.
              The content item types are books, photos, articles (page), and
              comics. For the article page item type, you are simply creating
              just one page using a text editor which can be as long as you
              want. For books, photos, and comics, you will create these item
              types slide-by-slide or page-by-page.
            </p>
            <p>
              &nbsp;The book item type allows you to upload pdf and image slides
              or pages. The photos item type allows you to upload image slides
              or pages. The comics item type also only allows you to upload
              image slides or pages.
            </p>
            <p>
              <br />
            </p>
            <p className="font-bold">
              What type of content is prohibited on Litzapp?
            </p>
            <p>
              There are certain actions that a user can take on Litzapp that are
              considered serious violations of the platform&apos;s policies, and
              engaging in such actions will result in severe consequences.
              Specifically, if a Publisher engages in any of the below-listed
              actions, the Publisher&apos;s account will
              be&nbsp;banned&nbsp;from the platform, and any&nbsp;pending
              payouts will be stopped.
            </p>
            <p>
              Moreover, some of the below-listed actions may be so severe that
              they could even result in a&nbsp;lawsuit&nbsp;being brought
              against the Publisher by the legal owner of the content, a user, a
              governing body, or Litzapp itself.
            </p>
            <p>
              <br />
            </p>
            <p>
              Please avoid engaging in the following listed actions or
              activities, on Litzapp:
            </p>
            <p>
              <br />
            </p>
            <p>
              COPYRIGHT INFRINGEMENT: Litzapp requires that all content
              published on the platform be either original work or permitted by
              the legal owner of the content.
            </p>
            <p>
              In other words, Litzapp does not permit users to share or publish
              copyrighted material without permission from the rightful owner.
              By expecting original or permitted content, Litzapp is ensuring
              that users are respecting intellectual property rights and not
              infringing on the rights of others.
            </p>
            <p>
              <br />
            </p>
            <p className="font-bold">PORNOGRAPHY:&nbsp;</p>
            <ul className="list-disc text-left p-2">
              <li>
                <p>
                  Publishing or sharing pornographic or mature audience content,
                  including but not limited to literature, imagery, and other
                  media, that depict nudity or explicit sexual acts.
                </p>
              </li>
              <li>
                <p>
                  Offering or seeking adult services such as prostitution,
                  escort services, pay-per-view, sexual massages, or any other
                  services that are explicitly sexual.
                </p>
              </li>
              <li>
                <p>
                  Promoting adult video stores that sell or distribute
                  pornography or other mature audience content.
                </p>
              </li>
              <li>
                <p>
                  Promoting gentleman&apos;s clubs, topless bars, or strip
                  clubs, which typically provide adult entertainment services.
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p className="font-bold">PROMOTION OF CONTROLLED DRUGS</p>
            <ul className="list-disc text-left p-2">
              <li>
                <p>
                  Selling, buying, or distributing illegal drugs, including but
                  not limited to controlled substances, prescription drugs, and
                  recreational drugs that are not legal in a user&apos;s
                  jurisdiction.
                </p>
              </li>
              <li>
                <p>
                  Offering or selling equipment that is specifically designed
                  for making or using drugs, including but not limited to drug
                  paraphernalia.
                </p>
              </li>
              <li>
                <p>
                  Providing fake references or identification services that may
                  be used for illegal activities, such as obtaining drugs or
                  other prohibited items.
                </p>
              </li>
              <li>
                <p>
                  Selling or distributing cannabis products, including but not
                  limited to marijuana, CBD oil, or other derivatives of the
                  cannabis plant.
                </p>
              </li>
              <li>
                <p>
                  Running cannabis dispensaries or any other businesses related
                  to cannabis, including those that are legal in some
                  jurisdictions.
                </p>
              </li>
              <li>
                <p>
                  Selling or promoting products containing any amount of CBD or
                  THC, which are the active ingredients in cannabis.
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p className="font-bold">
              PROMOTION OR SALE OF ILLEGAL TELECOMMUNICATIONS DEVICES
            </p>
            <ul className="list-disc text-left p-2">
              <li>
                <p>
                  Selling, buying, or distributing telecommunications
                  manipulation equipment, including devices used for jamming or
                  interfering with communication signals.
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p className="font-bold">
              PROMOTION, FACILITATION, OR THREAT OF VIOLENCE
            </p>
            <ul className="list-disc text-left p-2">
              <li>
                <p>
                  Unlawful violence or physical harm to persons or property,
                  especially based on race, religion, disability, or any other
                  immutable characteristic
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p className="font-bold">
              CONTENT ABOUT AN ONGOING WAR OR CONFLICT
            </p>
            <ul className="list-disc text-left p-2">
              <li>
                <p>
                  Any content about an ongoing war or conflict anywhere in the
                  world, especially Ukraine, Ethiopia, Yemen, and Syria.
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p className="font-bold">QUICK MONEY SCHEMES</p>
            <ul className="list-disc text-left p-2">
              <li>
                <p>
                  Participating in pyramid schemes or any other fraudulent
                  financial schemes designed to exploit users.
                </p>
              </li>
              <li>
                <p>
                  Promoting or engaging in &quot;get rich quick&quot; schemes
                  that promise unrealistic rewards with little or no effort.
                </p>
              </li>
              <li>
                <p>
                  Making false or exaggerated claims, using deceptive
                  testimonials, or engaging in high-pressure upselling tactics
                  to deceive or defraud users.
                </p>
              </li>
              <li>
                <p>
                  Offering services for sale or resale without providing any
                  additional value to the buyer.
                </p>
              </li>
              <li>
                <p>
                  Reselling government offerings without proper authorization or
                  adding any value to the product or service.
                </p>
              </li>
              <li>
                <p>
                  Selling online traffic or engagement, as well as engaging in
                  negative response marketing or telemarketing practices.
                </p>
              </li>
              <li>
                <p>
                  Offering predatory mortgage consulting, lending, credit
                  repair, or counseling services.
                </p>
              </li>
              <li>
                <p>
                  Promoting or offering predatory investment opportunities that
                  require little or no money down.
                </p>
              </li>
              <li>
                <p>
                  Participating in activities such as essay mills, chain
                  letters, door-to-door sales, or any other activities designed
                  to defraud or deceive users.
                </p>
              </li>
              <li>
                <p>
                  Participating in games of chance or gambling, including
                  internet gambling, sweepstakes, contests, fantasy sports
                  leagues with a monetary or material prize, sports forecasting,
                  or odds-making.
                </p>
              </li>
              <li>
                <p>
                  Participating in lotteries or bidding fee auctions that are
                  designed to exploit or defraud users.
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p className="text-1xl font-bold">
              What Problem Does Litzapp Solve?
            </p>
            <p>
              <br />
            </p>
            <p>
              <b className="font-bold">Ad Revenue Problem:</b>&nbsp;Litzapp
              addresses the challenges faced by independent creators in
              monetizing their ad revenues through the following solutions:
            </p>
            <p>
              <br />
            </p>
            <ol className="list-decimal text-left p-2">
              <li>
                <p>
                  <b className="font-bold">
                    Access to Quality Advertising Platforms:
                  </b>{" "}
                  Many advertising platforms have stringent approval processes,
                  leading to high rejection rates and long waiting periods.
                  Litzapp simplifies and expedites this process, allowing
                  publishers to monetize ads within their content, even if they
                  don&apos;t own a website. Approval typically takes less than 3
                  days and can even be instant, enabling publishers to start
                  displaying ads promptly.
                </p>
              </li>
              <li>
                <p>
                  <b className="font-bold">Lower Payout Threshold:</b> Unlike
                  other platforms that require publishers to accumulate a
                  significant sum, such as $80 or $100, before being eligible
                  for a payout, Litzapp has a lower threshold. Publishers can
                  receive a payout if they have earned at least $10 in ad
                  revenue. Payouts are processed at the beginning of each month.
                  This ensures that publishers receive their earnings in a
                  timely manner.
                </p>
              </li>
              <li>
                <p>
                  <b className="font-bold">Customizable Ad Display Duration:</b>
                  Customizable Ad Display Duration: Litzapp offers flexibility
                  to publishers of books, photo albums, and comics by allowing
                  them to control the duration for which ads are displayed
                  between pages or pictures. Publishers can determine the time
                  required for an ad to be shown before content consumers can
                  proceed to the next or previous page. Content consumers have
                  the option to skip these ads by either purchasing the content
                  or subscribing to the publisher&apos;s account on Litzapp,
                  thereby providing the publisher with alternative revenue
                  streams.
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p>
              <b className="font-bold">
                Selective Paywalls for Specific Content Sections:
              </b>
              &nbsp;While other platforms may offer the ability to place
              paywalls that restrict access to premium content, Litzapp takes it
              a step further. Litzapp enables publishers to strategically
              position paywalls within specific sections of their content,
              allowing users to view the majority of the content while reserving
              the crucial parts for paid access. This approach grants users a
              glimpse of the content but requires payment to unlock the
              designated section.
            </p>
            <p>
              Publishers have two options for implementing paywalls. First, they
              can set up direct purchases, enabling buyers to pay for the
              content section and gain complete access to it. Alternatively,
              publishers can offer paid monthly subscriptions, granting
              subscribers unrestricted access to all of the publisher&apos;s
              content on Litzapp without encountering any additional paywalls.
            </p>
            <p>
              This feature provides publishers with greater flexibility and
              control over monetizing their content, offering a balanced
              approach where users can sample the content while incentivizing
              them to make a payment to access exclusive sections.
            </p>
            <p>
              <br />
            </p>
            <p>
              <b className="font-bold">Earn Revenue for Incomplete Works:</b>
              &nbsp;Litzapp offers publishers the opportunity to start
              generating revenue even while their works are in progress. Whether
              it&apos;s a book being written with a chapter completed each week
              or month, or professional photographers and comic book creators
              showcasing their work, Litzapp allows publishers to release their
              content incrementally and earn revenue through various channels.
            </p>
            <p>
              Publishers can choose to publish their works chapter by chapter,
              allowing them to monetize each section individually. This means
              they can start earning revenue from ad placements or through
              content consumers subscribing to their accounts, even before the
              entire work is completed.
            </p>
            <p>
              By leveraging this feature, creators have the flexibility to
              generate income while actively producing their content, providing
              them with a continuous revenue stream and motivation to further
              develop and share their creative endeavors on Litzapp.
            </p>
            <p>
              <br />
            </p>
            <p>
              <b className="font-bold">Monetizing Journals and Reports:</b>{" "}
              Litzapp offers a platform for publishers, including independent
              journalists, news outlets, businesses, and organizations, to
              generate revenue from their journals, business reports, and
              organizational reports. These valuable publications can be
              monetized through multiple channels, including ad revenue,
              subscriptions, purchases, and donations.
            </p>
            <p>
              By utilizing Litzapp, publishers have the opportunity to earn
              income from their written content. Advertisements strategically
              placed within the journals and reports can generate ad revenue.
              Additionally, readers can opt for subscriptions to gain access to
              exclusive content or make one-time purchases of specific
              publications. Furthermore, Litzapp facilitates the option for
              readers to show support through donations, providing an additional
              avenue for revenue generation.
            </p>
            <p>
              With these monetization options, Litzapp empowers publishers to
              earn income for their journals and reports, fostering a
              sustainable environment for independent journalism, informative
              business reports, and insightful organizational publications.
            </p>
            <p>
              <br />
            </p>
            <p>
              <b className="font-bold">Monetizing Diverse Content:</b>
              &nbsp;Litzapp provides users with the ability to monetize a wide
              range of&nbsp;<b className="font-bold">everyday content</b>,
              including&nbsp;manuals,&nbsp;student
              assignments,&nbsp;illustrations,&nbsp;pamphlets,&nbsp;digital
              leaflets,&nbsp;property postings (for sale or rent),&nbsp;job
              postings,&nbsp;school syllabi,&nbsp;invitations,&nbsp;product
              instructions, and much more. These various types of content can be
              monetized through different channels, such as ad revenue,
              subscriptions, purchases, and donations.
            </p>
            <p>
              Litzapp offers a versatile platform where users can earn revenue
              from their diverse content offerings. Advertisements strategically
              placed within the content can generate ad revenue. Users can also
              offer subscription plans to provide exclusive access to their
              content or enable one-time purchases of specific items.
              Additionally, readers or viewers have the option to show support
              through donations, further contributing to the revenue potential.
            </p>
            <p>
              By embracing Litzapp, users have the opportunity to monetize their
              everyday content, unlocking the earning potential of their
              creations while reaching a broader audience.
            </p>
            <p>
              <br />
            </p>
            <p>
              <b className="font-bold">Easy Patron Donations:</b> As previously
              mentioned, Litzapp enables patrons of a publisher&apos;s work to
              contribute donations directly. Unlike other platforms that may
              have complex setups for donation systems, Litzapp offers a
              straightforward process. Publishers can easily incorporate a
              donate button within their content wherever they desire by simply
              clicking on it.
            </p>
            <p>
              Litzapp simplifies the donations system, allowing publishers to
              seamlessly integrate the donate button instantly into their
              content without any hassle. This flexibility enables publishers to
              customize the placement of the donate button, ensuring it appears
              precisely where they wish within their content.
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p className="text-1xl font-bold">
              How Do Publishers Get Paid Via Litzapp?
            </p>
            <p>
              <br />
            </p>
            <p>
              Litzapp offers multiple payment options for publishers to receive
              their earnings.
            </p>
            <p>
              <br />
            </p>
            <p>
              <b className="font-bold">Ad Revenue:</b> Litzapp equips publishers
              with ad codes, enabling them to embed these into their content.
              Consequently, when these ads are displayed and viewed, publishers
              earn revenue.
            </p>
            <p>
              <br />
            </p>
            <p>
              <b className="font-bold">Subscriptions:</b> Litzapp provides a
              system where patrons can pay for subscriptions to a
              publisher&apos;s account. This feature guarantees subscribers
              unrestricted access to the publisher&apos;s content on Litzapp,
              eliminating any paywall barriers.
            </p>
            <p>
              <br />
            </p>
            <p>
              <b className="font-bold">Purchase:</b> Litzapp offers a feature
              where publishers can profit from the direct purchase of their
              content. This allows the buyer to bypass any paywalls and gain
              unrestricted access to the entire content piece.
            </p>
            <p>
              <br />
            </p>
            <p>
              <b className="font-bold">Donations:</b> Litzapp provides a
              mechanism for publishers to receive donations from their
              supporters, offering another avenue for income generation.&nbsp;
            </p>
            <p>
              <br />
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
