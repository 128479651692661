import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import WebHeader from "./web/header";
import { isAdmin } from "../Utils/helpers";
import { isAuthUser, API_BASE } from "../Utils/helpers";
import Footer from "../layout/footer";
import TermsAccordion from "../accordions/terms-accordion";
import axios from "axios";

export default function Privacy() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const naviagte = useNavigate();

  return (
    <>
      {" "}
      <WebHeader />
      <div class=" px-6 ">
        <br />
        <div className="flex justify-center">
          <div className=" rounded-lg py-4 px-2 w-full/12 md:w-8/12">
            <h3 className="font-bold text-center text-3xl mb-6">
              Privacy Policy
            </h3>
            <div>
              <h3 className="font-bold">1. Introduction</h3>
              <p>
                Welcome to Litzapp! This Privacy Policy explains how Remote
                Analytics Jobs Inc. ("we," "us," or "our") collects, uses,
                discloses, and protects your information when you use our
                platform, Litzapp. By accessing or using Litzapp, you agree to
                the terms of this Privacy Policy. If you do not agree with our
                policies and practices, please do not use Litzapp.
              </p>
              <p>
                We are committed to protecting your privacy and ensuring that
                your personal information is handled in a safe and responsible
                manner. This Privacy Policy applies to information we collect on
                Litzapp and through email, text, and other electronic
                communications between you and Litzapp.
              </p>
              <p>
                Please read this Privacy Policy carefully to understand our
                views and practices regarding your information and how we will
                treat it. If you have any questions about this Privacy Policy,
                please contact us at:
              </p>
              <p>
                Remote Analytics Jobs Inc.126 Southwest 17th Road, Unit
                509Miami, Florida, 33129United States of
                Americaadmin@litzapp.com
              </p>
              <p>
                By using Litzapp, you consent to the collection, use, and
                sharing of your information as described in this Privacy Policy.
                This Privacy Policy is incorporated into and is subject to our
                Terms of Service. Your use of Litzapp and any information you
                provide through Litzapp are subject to the terms of this Privacy
                Policy and our Terms of Service.
              </p>
              <h3>2. Information We Collect</h3>
              <p>
                To provide and improve our services, Litzapp collects various
                types of information from our Users. This section details the
                kinds of information we collect and how we categorize them.
              </p>
              <br />
              <h4 className="font-bold">2.1 Personal Information</h4>
              <p>
                Personal Information refers to any information that can be used
                to identify you directly or indirectly. We may collect the
                following types of personal information:
              </p>
              <ul className="pl-5 list-disc">
                <li>
                  Contact Information: Such as your name, email address, phone
                  number, and postal address.
                </li>
                <li>
                  Account Information: Including your username, password, and
                  profile details.
                </li>
                <li>
                  Payment Information: Such as billing address, and other
                  payment details necessary for processing transactions.
                </li>
                <li>
                  Identification Information: Including government-issued
                  identification numbers (e.g., social security number, driver's
                  license number) for verification purposes.
                </li>
                <li>
                  Demographic Information: Such as your age, gender, and
                  preferences.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">2.2 Non-Personal Information</h4>
              <p>
                Non-Personal Information refers to information that does not
                directly identify you. This can include:
              </p>
              <ul className="pl-5 list-disc">
                <li>
                  Usage Data: Information about how you interact with Litzapp,
                  such as your browsing history, search queries, and other usage
                  patterns.
                </li>
                <li>
                  Technical Data: Such as your IP address, browser type and
                  version, time zone setting, browser plug-in types and
                  versions, operating system and platform, and other technology
                  on the devices you use to access Litzapp.
                </li>
                <li>
                  Aggregated Data: Statistical or demographic data derived from
                  your personal information but not considered personal
                  information by law because it does not directly reveal your
                  identity.
                </li>
              </ul>
              <p>
                By collecting this information, we aim to provide a more
                personalized and efficient experience for our Users. The
                following sections will detail how we collect and use this
                information to enhance our services and your experience on
                Litzapp.
              </p>
              <h3>3. How We Collect Information</h3>
              <p>
                Litzapp collects information from Users in various ways to
                provide and improve our services. This section explains the
                different methods through which we collect information.
              </p>
              <br />
              <h4 className="font-bold">3.1 Information You Provide</h4>
              <p>
                We collect information that you voluntarily provide to us,
                including:
              </p>
              <ul className="pl-5 list-disc">
                <li>
                  Account Registration: When you create an account on Litzapp,
                  you provide personal information such as your name, email
                  address, phone number, and password.
                </li>
                <li>
                  Profile Information: You may provide additional information to
                  personalize your profile, such as a profile picture, bio, and
                  other preferences.
                </li>
                <li>
                  Transactions: When you purchase products, services, or
                  content, or when you monetize offerings on Litzapp, you
                  provide payment information and other transaction details.
                </li>
                <li>
                  Communications: If you contact us directly, we may receive
                  additional information about you, such as your name, email
                  address, phone number, the content of your message, and any
                  attachments.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">
                3.2 Information Collected Automatically
              </h4>
              <p>
                We automatically collect certain information when you use
                Litzapp, including:
              </p>
              <ul className="pl-5 list-disc">
                <li>
                  Usage Data: Information about your interactions with the
                  platform, such as pages viewed, links clicked, and other
                  actions taken.
                </li>
                <li>
                  Technical Data: Details about the devices and technology you
                  use to access Litzapp, such as your IP address, browser type,
                  operating system, and device identifiers.
                </li>
                <li>
                  Cookies and Tracking Technologies: We use cookies, web
                  beacons, and similar tracking technologies to collect
                  information about your usage of Litzapp. These technologies
                  help us understand how you use our platform and improve your
                  experience.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">3.3 Information from Third Parties</h4>
              <p>
                We may receive information about you from third parties,
                including:
              </p>
              <ul className="pl-5 list-disc">
                <li>
                  Social Media Platforms: If you connect your Litzapp account to
                  social media platforms, we may collect information from your
                  social media profile, such as your name, email address, and
                  profile picture.
                </li>
                <li>
                  Payment Processors: We may receive payment information and
                  transaction details from third-party payment processors, such
                  as Stripe, to facilitate transactions on Litzapp.
                </li>
                <li>
                  Service Providers: We may collect information from third-party
                  service providers who assist us in providing services to you,
                  such as identity verification services and analytics
                  providers.
                </li>
              </ul>
              <p>
                By understanding how we collect information, you can better
                appreciate the steps we take to ensure your privacy and the
                measures we implement to protect your data. The following
                sections will explain how we use and share the information
                collected to enhance your experience on Litzapp.
              </p>
              <h3>4. How We Use Your Information</h3>
              <p>
                Litzapp uses the information we collect to provide, maintain,
                and improve our services, as well as to protect and enhance your
                experience. This section outlines the various ways we use your
                information.
              </p>
              <br />
              <h4 className="font-bold">
                4.1 Providing and Improving Services
              </h4>
              <ul className="pl-5 list-disc">
                <li>
                  Account Management: To create and manage your account,
                  including verifying your identity, processing transactions,
                  and providing customer support.
                </li>
                <li>
                  Service Delivery: To deliver the products, services, and
                  content you purchase or access through Litzapp.
                </li>
                <li>
                  Personalization: To personalize your experience by displaying
                  content, products, and services tailored to your preferences
                  and interests.
                </li>
                <li>
                  Platform Improvement: To analyze usage patterns and improve
                  the functionality, performance, and user experience of
                  Litzapp.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">4.2 Communication</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Customer Support: To respond to your inquiries, provide
                  support, and resolve any issues you may encounter.
                </li>
                <li>
                  Transactional Communications: To send you confirmations,
                  invoices, technical notices, updates, security alerts, and
                  support and administrative messages related to your account
                  and transactions.
                </li>
                <li>
                  Promotional Communications: To send you promotional messages
                  and other information that may be of interest to you,
                  including newsletters, marketing materials, and information
                  about events or promotions.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">4.3 Marketing and Advertising</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Targeted Advertising: To deliver personalized advertisements
                  based on your interests and interactions with Litzapp.
                </li>
                <li>
                  Marketing Analytics: To analyze the effectiveness of our
                  marketing campaigns and understand how you interact with our
                  promotional materials.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">4.4 Legal Compliance</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Legal Obligations: To comply with legal and regulatory
                  requirements, such as tax and financial reporting obligations.
                </li>
                <li>
                  Enforcement of Terms: To enforce our Terms of Service and
                  other agreements, including investigating potential
                  violations.
                </li>
                <li>
                  Protection of Rights: To protect the rights, property, or
                  safety of Litzapp, our Users, or others, and to detect,
                  prevent, or address fraud, security, or technical issues.
                </li>
              </ul>
              <p>
                By understanding how we use your information, you can see the
                benefits of providing accurate and up-to-date data. This helps
                us enhance your experience on Litzapp and ensure that we meet
                our legal and regulatory obligations. The next section will
                explain how we share your information with third parties and the
                safeguards we put in place to protect your data.
              </p>
              <h3>5. How We Share Your Information</h3>
              <p>
                Litzapp is committed to maintaining your privacy and protecting
                your information. We only share your information in specific
                circumstances and with trusted partners, as detailed below.
              </p>
              <br />
              <h4 className="font-bold">5.1 Service Providers</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Third-Party Services: We share your information with
                  third-party service providers who perform services on our
                  behalf, such as payment processing, data analysis, email
                  delivery, hosting services, customer service, and marketing
                  assistance. These service providers have access to your
                  information only to perform specific tasks on our behalf and
                  are obligated to protect your information.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">5.2 Business Transfers</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Mergers and Acquisitions: If Litzapp is involved in a merger,
                  acquisition, or sale of all or a portion of its assets, your
                  information may be transferred as part of that transaction. We
                  will notify you of any such change in ownership or control of
                  your personal information.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">5.3 Legal Requirements</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Compliance with Laws: We may disclose your information to
                  comply with any applicable law, regulation, legal process, or
                  governmental request.
                </li>
                <li>
                  Protection of Rights: We may disclose your information to
                  enforce our Terms of Service, investigate potential
                  violations, detect, prevent, or address fraud, security, or
                  technical issues, and protect the rights, property, or safety
                  of Litzapp, our Users, or others.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">5.4 User Consent</h4>
              <ul className="pl-5 list-disc">
                <li>
                  With Your Consent: We may share your information with your
                  consent or at your direction. This includes sharing
                  information with other Users or third parties when you
                  explicitly authorize us to do so.
                </li>
              </ul>
              <p>
                By understanding how we share your information, you can trust
                that we are committed to protecting your privacy and using your
                data responsibly. The next section will explain our use of
                cookies and other tracking technologies to enhance your
                experience on Litzapp.
              </p>
              <h3>6. Cookies and Tracking Technologies</h3>
              <p>
                Litzapp uses cookies and other tracking technologies to improve
                your experience on our platform. This section explains what
                cookies are, the types of cookies we use, and how you can manage
                them.
              </p>
              <br />
              <h4 className="font-bold">6.1 Types of Cookies</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Essential Cookies: These cookies are necessary for the basic
                  functions of Litzapp. They enable you to navigate the
                  platform, access secure areas, and use essential features.
                </li>
                <li>
                  Performance Cookies: These cookies collect information about
                  how you use Litzapp, such as which pages you visit most often
                  and any error messages you encounter. This information helps
                  us improve the performance and usability of our platform.
                </li>
                <li>
                  Functionality Cookies: These cookies allow Litzapp to remember
                  choices you make, such as your username, language preferences,
                  and settings. This personalization enhances your experience on
                  our platform.
                </li>
                <li>
                  Targeting/Advertising Cookies: These cookies are used to
                  deliver advertisements that are relevant to you and your
                  interests. They also limit the number of times you see an ad
                  and help measure the effectiveness of advertising campaigns.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">6.2 How We Use Cookies</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Personalization: We use cookies to remember your preferences
                  and settings, which personalize your experience on Litzapp.
                </li>
                <li>
                  Analytics: We use cookies to analyze how Users interact with
                  Litzapp. This information helps us understand User behavior
                  and improve our platform's functionality.
                </li>
                <li>
                  Advertising: We use cookies to deliver targeted advertisements
                  that are relevant to your interests. Cookies help us track the
                  performance of our advertising campaigns and measure their
                  effectiveness.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">6.3 Managing Cookies</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Browser Settings: Most web browsers allow you to control
                  cookies through their settings preferences. You can set your
                  browser to refuse cookies, delete cookies, or alert you when
                  cookies are being sent.
                </li>
                <li>
                  Opt-Out Options: You can opt out of targeted advertising by
                  adjusting your ad preferences with the relevant advertising
                  networks. Additionally, you can use tools such as the Network
                  Advertising Initiative (NAI) opt-out tool or the Digital
                  Advertising Alliance (DAA) opt-out tool.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">6.4 Other Tracking Technologies</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Web Beacons: We may use web beacons (also known as pixel tags
                  or clear GIFs) to track the online usage patterns of our
                  Users. Web beacons are tiny graphics with a unique identifier
                  that function similarly to cookies.
                </li>
                <li>
                  Analytics Services: We use third-party analytics services,
                  such as Google Analytics, to collect and analyze usage
                  information. These services use cookies and other tracking
                  technologies to help us understand how Users interact with
                  Litzapp.
                </li>
              </ul>
              <p>
                By using Litzapp, you consent to our use of cookies and other
                tracking technologies as described in this section. These
                technologies help us provide a more personalized and efficient
                experience for our Users. The next section will explain how we
                protect your data and ensure its security.
              </p>
              <h3>7. Data Security</h3>
              <p>
                Litzapp is committed to protecting the security of your personal
                information. We implement various security measures to safeguard
                your data from unauthorized access, use, disclosure, alteration,
                and destruction. This section details our data security
                practices and protocols.
              </p>
              <br />
              <h4 className="font-bold">7.1 Security Measures</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Encryption: We use industry-standard encryption protocols to
                  protect sensitive information transmitted online, including
                  SSL/TLS encryption for data in transit and encryption for data
                  at rest.
                </li>
                <li>
                  Access Controls: We limit access to your personal information
                  to authorized personnel who need it to perform their job
                  duties. Access is granted based on the principle of least
                  privilege.
                </li>
                <li>
                  Secure Authentication: We may employ secure authentication
                  methods, such as multi-factor authentication (MFA), to verify
                  the identity of Users accessing their accounts.
                </li>
                <li>
                  Regular Audits: We may conduct regular security audits and
                  assessments to identify and address potential vulnerabilities
                  in our systems.
                </li>
                <li>
                  Firewalls and Intrusion Detection: Our systems may be
                  protected by firewalls and intrusion detection systems that
                  monitor and prevent unauthorized access.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">7.2 Data Breach Response</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Incident Response Plan: We may have an incident response plan
                  in place to promptly address any data breaches or security
                  incidents. This plan includes procedures for containment,
                  investigation, notification, and remediation.
                </li>
                <li>
                  User Notification: In the event of a data breach that
                  compromises your personal information, we may notify you as
                  soon as possible and provide details about the nature of the
                  breach, the data affected, and the steps we are taking to
                  mitigate the impact.
                </li>
                <li>
                  Cooperation with Authorities: We will cooperate with law
                  enforcement and regulatory authorities in the investigation
                  and resolution of any data breaches or security incidents.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">7.3 User Responsibilities</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Account Security: You are responsible for maintaining the
                  security of your Litzapp account. This includes keeping your
                  password confidential and using strong, unique passwords for
                  your account.
                </li>
                <li>
                  Phishing and Fraud: Be vigilant against phishing attempts and
                  fraudulent activities. Litzapp will never ask you for your
                  password or sensitive information via email. If you receive
                  suspicious communications, report them to us immediately.
                </li>
              </ul>
              <p>
                By understanding our data security practices, you can trust that
                we are committed to protecting your information and maintaining
                a secure environment on Litzapp. The next section will explain
                our data retention policies and how we handle data deletion
                requests.
              </p>
              <h3>8. Data Retention</h3>
              <p>
                Litzapp retains your personal information for as long as
                necessary to provide our services, comply with legal
                obligations, resolve disputes, and enforce our agreements. This
                section outlines our data retention practices and your options
                for data deletion.
              </p>
              <br />
              <h4 className="font-bold">8.1 Retention Periods</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Account Information: We retain your account information for as
                  long as your account is active and as needed to provide you
                  with our services. If you choose to close your account, we
                  will delete your information as described below.
                </li>
                <li>
                  Transaction Data: We retain information related to
                  transactions for as long as necessary to complete the
                  transaction, provide customer support, comply with legal and
                  financial record-keeping requirements, and resolve disputes.
                </li>
                <li>
                  Communication Records: We retain records of your
                  communications with us (e.g., customer support inquiries) for
                  as long as necessary to resolve your inquiries, provide
                  support, and improve our services.
                </li>
                <li>
                  Usage Data: We retain usage data for internal analysis
                  purposes. This data is generally retained for a shorter
                  period, except when used to strengthen the security or improve
                  the functionality of our platform, or we are legally obligated
                  to retain this data for longer periods.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">8.2 Data Deletion Requests</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Account Deletion: You can request the deletion of your Litzapp
                  account at any time by contacting us at
                  customerservices@litzapp.com. Upon receiving your request, we
                  will delete your account and personal information within a
                  reasonable timeframe, subject to legal and regulatory
                  requirements.
                </li>
                <li>
                  Partial Deletion: If you do not wish to delete your entire
                  account, you can request the deletion of specific personal
                  information. We will comply with such requests to the extent
                  feasible, considering the nature of the data and the purpose
                  for which it was collected.
                </li>
                <li>
                  Retention After Deletion: Even after you delete your account
                  or request the deletion of specific information, some data may
                  be retained for a limited period to comply with legal
                  obligations, resolve disputes, and enforce our agreements. We
                  will ensure that any retained data is protected and used only
                  for these purposes.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">8.3 Anonymized Data</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Aggregate Data: We may retain and use aggregate or anonymized
                  data derived from your personal information for statistical
                  purposes, research, and to improve our services. This data
                  will not identify you personally and is not subject to
                  deletion requests.
                </li>
              </ul>
              <p>
                By understanding our data retention practices, you can make
                informed decisions about your information on Litzapp. The next
                section will explain your rights and choices regarding your
                personal data.
              </p>
              <h3>9. Your Rights and Choices</h3>
              <p>
                Litzapp is committed to providing you with control over your
                personal information. This section outlines your rights
                regarding your data and the choices you have to manage your
                privacy.
              </p>
              <br />
              <h4 className="font-bold">9.1 Access and Correction</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Accessing Your Information: You have the right to access the
                  personal information we hold about you. You can request a copy
                  of your information by contacting us at
                  customerservices@litzapp.com. We will provide you with a
                  summary of your data and, upon request, a copy of the
                  information we have on file.
                </li>
                <li>
                  Correcting Your Information: If you believe that any personal
                  information we hold about you is incorrect or incomplete, you
                  have the right to request corrections. You can update your
                  information directly through your account settings or by
                  contacting us at customerservices@litzapp.com.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">
                9.2 Opt-Out of Marketing Communications
              </h4>
              <ul className="pl-5 list-disc">
                <li>
                  Email Communications: You can opt out of receiving promotional
                  emails from Litzapp by following the unsubscribe instructions
                  included in each email. Even if you opt out of marketing
                  emails, we may still send you transactional and administrative
                  messages related to your account and our services.
                </li>
                <li>
                  Targeted Advertising: You can manage your preferences for
                  targeted advertising by adjusting your browser settings or
                  using opt-out tools provided by advertising networks.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">9.3 Data Portability</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Requesting Data Portability: You have the right to request a
                  copy of your personal information in a structured, commonly
                  used, and machine-readable format. This right allows you to
                  transfer your data to another service provider. To request
                  data portability, contact us at customerservices@litzapp.com.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">9.4 Right to Erasure</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Requesting Deletion: You have the right to request the
                  deletion of your personal information. You can request the
                  deletion of your account or specific data by contacting us at
                  customerservices@litzapp.com. We will delete your information
                  within a reasonable timeframe, subject to legal and regulatory
                  obligations.
                </li>
                <li>
                  Exceptions to Deletion: In certain circumstances, we may
                  retain specific data to comply with legal obligations, resolve
                  disputes, and enforce our agreements. We will ensure that any
                  retained data is protected and used only for these purposes.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">9.5 Restriction of Processing</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Requesting Restrictions: You have the right to request the
                  restriction of processing your personal information if you
                  believe that the data is inaccurate, the processing is
                  unlawful, or we no longer need the data for specific purposes.
                  To request restrictions, contact us at
                  customerservices@litzapp.com.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">9.6 Withdrawal of Consent</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Withdrawing Consent: If we process your personal information
                  based on your consent, you have the right to withdraw your
                  consent at any time. To withdraw your consent, contact us at
                  customerservices@litzapp.com. This withdrawal will not affect
                  the lawfulness of processing based on consent before its
                  withdrawal.
                </li>
              </ul>
              <p>
                By understanding your rights and choices, you can better manage
                your privacy and the use of your personal information on
                Litzapp. The next section will address our policies regarding
                children's privacy.
              </p>
              <h3>10. Children's Privacy</h3>
              <p>
                Litzapp is committed to protecting the privacy of children. This
                section outlines our policies regarding the collection, use, and
                disclosure of personal information from children under the age
                of 13.
              </p>
              <br />
              <h4 className="font-bold">10.1 Age Restrictions</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Minimum Age Requirement: Litzapp is not intended for use by
                  children under the age of 13. We do not knowingly collect
                  personal information from children under 13. If you are under
                  13, please do not use Litzapp or provide any personal
                  information to us.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">10.2 Parental Consent</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Verifiable Consent: If we become aware that we have collected
                  personal information from a child under 13 without verifiable
                  parental consent, we will take steps to delete that
                  information as soon as possible. Parents or guardians who
                  believe that their child has provided us with personal
                  information without their consent should contact us at
                  admin@litzapp.com.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">10.3 Information for Parents</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Monitoring and Control: We encourage parents and guardians to
                  monitor their children's online activities and to help enforce
                  our policies by instructing their children never to provide
                  personal information on Litzapp without their permission.
                </li>
                <li>
                  Parental Rights: If you are a parent or guardian and discover
                  that your child under 13 has an account on Litzapp, you may
                  contact us at admin@litzapp.com to request that we delete your
                  child's personal information and terminate the account.
                </li>
              </ul>
              <p>
                By adhering to these Children's Privacy policies, we aim to
                ensure a safe and secure environment for all our Users. The next
                section will explain our policies regarding international data
                transfers.
              </p>
              <h3>11. International Data Transfers</h3>
              <p>
                Litzapp operates globally, and your personal information may be
                transferred to, stored, and processed in countries other than
                your own. This section explains our practices regarding
                international data transfers and the safeguards we implement to
                protect your information.
              </p>
              <br />
              <h4 className="font-bold">11.1 Transfer of Information</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Global Operations: As part of our global operations, your
                  information may be transferred to and processed in countries
                  outside of your country of residence. These countries may have
                  data protection laws that are different from the laws of your
                  country.
                </li>
                <li>
                  Data Storage Locations: Your personal information may be
                  stored on servers located in various countries where we or our
                  service providers operate.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">
                11.2 Safeguards for International Transfers
              </h4>
              <ul className="pl-5 list-disc">
                <li>
                  Adequacy Decisions: Where applicable, we rely on adequacy
                  decisions made by relevant data protection authorities to
                  ensure that your personal information is transferred to
                  countries with adequate data protection standards.
                </li>
                <li>
                  Standard Contractual Clauses: For transfers to countries
                  without adequacy decisions, we implement appropriate
                  safeguards, such as standard contractual clauses approved by
                  data protection authorities, to ensure the protection of your
                  personal information.
                </li>
                <li>
                  Binding Corporate Rules: In some cases, we may rely on binding
                  corporate rules or other legal mechanisms to ensure that your
                  data is protected when transferred internationally.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">11.3 User Consent</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Explicit Consent: By using Litzapp and providing us with your
                  personal information, you consent to the transfer of your
                  information to countries outside of your country of residence,
                  including the United States, in accordance with this Privacy
                  Policy.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">11.4 Data Protection Rights</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Consistency in Rights: Regardless of where your personal
                  information is transferred, we are committed to ensuring that
                  your data protection rights remain consistent and that your
                  information is handled in accordance with this Privacy Policy.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">11.5 Contact Information</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Questions and Concerns: If you have any questions or concerns
                  about our international data transfers or the safeguards we
                  have implemented, please contact us at admin@litzapp.com.
                </li>
              </ul>
              <p>
                By understanding our practices regarding international data
                transfers, you can trust that Litzapp is committed to protecting
                your personal information globally. The next section will
                explain how we notify you of changes to this Privacy Policy.
              </p>
              <h3>12. Changes to This Privacy Policy</h3>
              <p>
                Litzapp may update this Privacy Policy from time to time to
                reflect changes in our practices, technologies, legal
                requirements, and other factors. This section explains how we
                will notify you of changes and your responsibilities regarding
                these updates.
              </p>
              <br />
              <h4 className="font-bold">12.1 Notification of Changes</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Posting Updates: When we make changes to this Privacy Policy,
                  we will post the updated policy on Litzapp and update the
                  "Last Updated" date at the beginning of the policy.
                </li>
                <li>
                  Additional Notifications: For significant changes that affect
                  your rights or obligations, we may also provide additional
                  notice, such as an email notification or an in-platform alert,
                  prior to the changes becoming effective.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">12.2 Your Responsibility</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Reviewing Updates: It is your responsibility to review this
                  Privacy Policy periodically for any changes. Your continued
                  use of Litzapp after we post the updated policy constitutes
                  your acceptance of the changes.
                </li>
                <li>
                  Discontinuing Use: If you do not agree with the updated
                  Privacy Policy, you should discontinue using Litzapp and
                  terminate your account as described in the Termination and
                  Suspension section of our Terms of Service.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">12.3 Effective Date of Changes</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Implementation Timeline: Changes to this Privacy Policy will
                  become effective immediately upon posting the updated policy
                  unless otherwise stated in the notification. For significant
                  changes, we will provide a specific effective date to allow
                  you time to review and understand the changes.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">12.4 Contact Information</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Questions and Feedback: If you have any questions, concerns,
                  or feedback regarding changes to this Privacy Policy, please
                  contact us at:Remote Analytics Jobs Inc.126 Southwest 17th
                  Road, Unit 509Miami, Florida, 33129United States of
                  Americaadmin@litzapp.com
                </li>
              </ul>
              <p>
                By understanding our process for updating this Privacy Policy,
                you can stay informed about how we protect your personal
                information and any changes to our practices. The final section
                will provide our contact details for any inquiries related to
                this Privacy Policy.
              </p>
              <h3>13. Contact Us</h3>
              <p>
                If you have any questions, concerns, or feedback about this
                Privacy Policy or our data protection practices, please feel
                free to contact us. We are committed to addressing your
                inquiries and ensuring that your privacy is protected.
              </p>
              <br />
              <h4 className="font-bold">Contact Information</h4>
              <ul className="pl-5 list-disc">
                <li>
                  <span className="font-bold">Mailing Address: </span> Remote
                  Analytics Jobs Inc.126 Southwest 17th Road, Unit 509Miami,
                  Florida, 33129United States of America.
                </li>
                <li>
                  <span className="font-bold">Email: </span>
                  <span className="font-bold text-blue-500">
                    <a href="mailto:customerservices@litzapp.com">
                      customerservices@litzapp.com
                    </a>{" "}
                  </span>
                </li>
                <li>
                  <span className="font-bold">Customer Support: </span> For
                  immediate assistance, you can reach our customer support team
                  through our website or by emailing our support address.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">Additional Resources</h4>
              <ul className="pl-5 list-disc">
                <li>
                  <span className="font-bold">Privacy Inquiries: </span> If you
                  have specific questions about your privacy rights or how we
                  handle your personal information, please direct your inquiries
                  to our privacy team at
                  <span className="font-bold text-blue-500">
                    <a href="mailto:admin@litzapp.com"> admin@litzapp.com.</a>
                  </span>
                </li>
                <li>
                  <span className="font-bold">Report a Concern: </span> If you
                  believe your privacy rights have been violated or if you have
                  any concerns about our data protection practices, please
                  report them to us using the contact information provided
                  above. We take all privacy concerns seriously and will
                  investigate and address them promptly.
                </li>
              </ul>
              <br />
              <p>
                We value your privacy and are here to help with any questions or
                concerns you may have about our Privacy Policy and practices.
                Thank you for using Litzapp and for trusting us with your
                personal information.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
