import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";

import { Routes, Route, Link, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Reader from "./pages/Reader";
import Dashboard from "./pages/dashboard";
import Master from "./pages/master";

/*Publisher routes*/
import Publisher from "./pages/publisher/index";
import CreatePublisher from "./pages/publisher/create-publisher";
import CreateSeries from "./pages/publisher/series/create-series";
import Series from "./pages/publisher/series/index";
import PublicationOptions from "./pages/publisher/publication-options";
import Books from "./pages/publisher/series/books";
import Book from "./pages/publisher/series/book";
import CreateBook from "./pages/publisher/book/create-book";
import CreatePage from "./pages/publisher/book/create-page";
import AdHome from "./pages/publisher/ads";
import AllBooks from "./pages/publisher/book";
import SingleAdUnitReport from "./pages/publisher/reports/single-ad-unit-report";
import CreateAdvert from "./pages/publisher/ads/create-ad";
import PubSubscriptions from "./pages/publisher/email/index";
import CreateOptions from "./pages/publisher/create-options";
import DashboardPublisherProfile from "./pages/publisher/profile";
import DashboardPublisherSettings from "./pages/publisher/settings";
import PublisherPublicationOptions from "./pages/publisher/publication-options";
import DashboardPublisherSubscribersList from "./pages/publisher/subscriber";
import PubPurchases from "./pages/publisher/purchase";
import PubTrackers from "./pages/publisher/track";
import PubHirePurchases from "./pages/publisher/purchase/hire";
import PubMyHirePurchases from "./pages/publisher/purchase/my-hires";
import PublisherMyPurchases from "./pages/publisher/purchase/my-purchases";
import PublisherMySubscriptions from "./pages/publisher/subscriber/my-subscription";
import PublisherRequestedUpdates from "./pages/publisher/email/my-email-subscriptions";
import PublisherDashboardStorage from "./pages/publisher/storage";
import RedirectComponent from "./pages/publisher/storage/redirect";
import PublisherDashboardStorageFiles from "./pages/publisher/storage/files";
import PublisherDashboardStorageSubscriptions from "./pages/publisher/storage/subscriptions";
import PubSendMail from "./pages/publisher/mailing/send-mail";
import PubEmailPurchase from "./pages/publisher/mailing/email-purchase";
import PublisherMyAdsRecord from "./pages/publisher/ads/my-ads-record";
import PubProductTransactionsSingle from "./pages/publisher/product-page/subscriber/payment";
import PubProductTransactionsRecurring from "./pages/publisher/product-page/subscriber/subscription";
import PubMyProductTransactionsSingle from "./pages/publisher/product-page/my/payment";
import PubMyProductTransactionsRecurring from "./pages/publisher/product-page/my/subscription";
import PubProductAvailabilityTable from "./components/product-calendar/availability";
import PubProductAccountCalendar from "./pages/publisher/product/calendar/account";

/*Publisher supplier routes*/
import PubSupplierOnboarding from "./pages/publisher/supplier";
import PubSupplierSampleContracts from "./pages/publisher/supplier/sample-contracts";
import PubSupplierProfile from "./pages/publisher/supplier/profile";
import PubSupplierMyContracts from "./pages/publisher/supplier/my-supplier-contract/contracts";
import PubSupplierMyContractsAdd from "./pages/publisher/supplier/my-supplier-contract/add-contract";
import PubSupplierMyContractsEdit from "./pages/publisher/supplier/my-supplier-contract/edit-contract";
import PubSupplierMyContractsConnButtons from "./pages/publisher/supplier/my-supplier-contract/connected-buttons";
import CustomerServices from "./pages/publisher/customer-service";
import CreateCustomerServiceSet from "./pages/publisher/customer-service/components/create-set";
import EditCustomerServiceSet from "./pages/publisher/customer-service/components/edit-set";

/*admin routes*/
import AdminPublisher from "./pages/admin/index";
import AdminNonMonetizer from "./pages/admin/non-monetizer";
import AdminReaderMySubscriptions from "./pages/admin/non-monetizer/content-subs";
import AdminReaderMyPurchases from "./pages/admin/non-monetizer/content-purchases";
import AdminReaderMyProductSubs from "./pages/admin/non-monetizer/product-subs";
import AdminReaderMyProductPurchases from "./pages/admin/non-monetizer/product-purchases";
import AdminHomePageSort from "./pages/admin/homepage-sort";
import AdminBannedPublishers from "./pages/admin/banned";
import AdminCreatePublisher from "./pages/admin/create-publisher";
import AdminCreateSeries from "./pages/admin/series/create-series";
import AdminSeries from "./pages/admin/series/index";
import AdminPublicationOptions from "./pages/admin/publication-options";
import AdminBooks from "./pages/admin/series/books";
import AdminBook from "./pages/admin/series/book";
import AdminCreateBook from "./pages/admin/book/create-book";
import AdminCreatePage from "./pages/admin/book/create-page";
import AdminAdHome from "./pages/admin/ads/index";
import AdminAdvertRequests from "./pages/admin/ads/advert-requests";
import AdminCreateSalesAccount from "./pages/admin/sales/create-account";
import AdminSystemSubscribers from "./pages/admin/system-subscribers";
import AdminSystemCreateSubscriber from "./pages/admin/system-subscribers/create-subscriber";
import AdminSystemEditSubscriber from "./pages/admin/system-subscribers/edit-subscriber";
import AdminSalesAccounts from "./pages/admin/sales";
import AdminSalesSuppliers from "./pages/admin/sales/suppliers";
import AdminAllBooks from "./pages/admin/book";
import AdminSingleAdUnitReport from "./pages/admin/reports/single-ad-unit-report";
import AdminCreateAdvert from "./pages/admin/ads/create-ad";
import AdminPubSubscriptions from "./pages/admin/subscription/index";
import DashboardAdminSettings from "./pages/admin/settings";
import AdminPublisherActivation from "./pages/admin/activation";
import AdminDashboardStorage from "./pages/admin/storage";
import AdminDashboardCreateTier from "./pages/admin/storage/create-tier";
import AdminDashboardEditTier from "./pages/admin/storage/edit-tier";
import AdminContracts from "./pages/admin/contracts";
import AdminContractsUpload from "./pages/admin/contracts/default/contract";
import AdminREContractsUpload from "./pages/admin/contracts/default/recurring/contract";
import AdminSEContractsUpload from "./pages/admin/contracts/default/single/contract";
import AdminSampleContractsOpt from "./pages/admin/contracts/sample-contract-options";
import AdminDefaultContractsOpt from "./pages/admin/contracts/default/options";
import AdminREDefaultContractsOpt from "./pages/admin/contracts/default/recurring/default-contract-options";
import AdminSEDefaultContractsOpt from "./pages/admin/contracts/default/single/default-contract-options";
import AdminContractViewer from "./pages/admin/contracts/contract-viewer";
import AdminSupplierApplications from "./pages/admin/supplier/components/application";
import AdminSupplierApplicant from "./pages/admin/supplier/components/applicant/applicant";
import AdminSupplierProfile from "./pages/admin/supplier/components/profile/profile";
import AdminSupplierBizRelocation from "./pages/admin/supplier/components/biz-relocation";
import AdminSupplierBizRelocationApplicant from "./pages/admin/supplier/components/biz-relocation/applicant";
import AdminSupplierContracts from "./pages/admin/supplier/components/contracts/contracts";
import AdminSupplierContract from "./pages/admin/supplier/components/contract/contract";

/*Reader & uest Routes*/
import Landing from "./Landing";
import WebPublisher from "./pages/web/publisher";
import WebPublications from "./pages/web/publisher/publications";
import WebSeries from "./pages/web/publisher/series";
import WebReader from "./pages/web-reader";
import CustomPageRenderer from "./pages/presenter/custom-page-renderer";
import CustomPageRendererPreview from "./pages/custom-page-render-preview";
import WebBook from "./pages/web/publisher/book";
import Signup from "./pages/signup";
import SignupOptions from "./pages/signup-options";
import CreatorSignup from "./pages/creator-signup";
import SupplierSignup from "./pages/supplier-signup";
import Login from "./pages/login";
import BookReaders from "./pages/reader/index";
import Payments from "./pages/reader/payments";
import CustomerPurchases from "./pages/reader/purchase";
import CustomerSubscriptions from "./pages/reader/subscription";
import CustomerRequestedUpdates from "./pages/reader/email";
import Subscriptions from "./pages/subscription";
import Donations from "./pages/donation";
import About from "./pages/about";
import Terms from "./pages/terms";
import PaymentTerms from "./pages/payment-terms";
import SalesTerms from "./pages/sales-terms";
import Privacy from "./pages/privacy";
import Contact from "./pages/contact-us";
import PublisherActivationForm from "./pages/reader/activation";
import FAQ from "./pages/faq";
import PasswordReset from "./pages/web/password/password-reset";
import PasswordResetEmailValidation from "./pages/web/password/email-prompt";
import BlockEmailUpdates from "./pages/web/mails/block-email-updates";
import RestoreEmailUpdates from "./pages/web/mails/restore-email-updates";
import PublisherBlockEmailNotice from "./pages/web/mails/publisher-block-notice";
import Footer from "./layout/footer";
import WebReaderHome from "./pages/web/customer/my-books";
import PublisherProfile from "./pages/web/publisher/profile/index";
import CustomBookList from "./components/slider/homepage/components/lists";
import EmailSubscriptions from "./pages/web/customer/email-subscriptions";
import PublisherSubscription from "./pages/web/customer/publisher-subscription";
import CustomerMaster from "./pages/web/customer/cu-master";
import AllReports from "./pages/reports/allReports";
import PubDonations from "./pages/publisher/donation";
import AdminDonations from "./pages/admin/donation";
import CustomerProductSubscriptions from "./pages/reader/product-page/subscription";
import CustomerProductPurchases from "./pages/reader/product-page/purchase";
import CustomerCreateOptions from "./pages/reader/activation/create-options";

/*Sales*/
import SalesIndex from "./pages/sales";
import SalesSupplierOnboarding from "./pages/sales/supplier";
import SalesSuppliers from "./pages/sales/supplier/suppliers";
import SalesBasicRegistration from "./pages/sales/supplier/components/basic-info";
import SalesSettings from "./pages/sales/settings";
// Pay Element
import StripeElementWrapper from "./payment/stripe-element-wrapper";
import SubscriptionComplete from "./pages/web/customer/subscription/subscription-complete";
import CPProdSubscriptionComplete from "./pages/web/customer/subscription/cp-product-subscription-complete";
import CreateRichPage from "./pages/publisher/rich-page/create-rich-page";
import UpdateRichPage from "./pages/publisher/rich-page/update-rich-page";
import CreateRichProductPage from "./pages/publisher/rich-page/product/create-rich-product-page";
import UpdateRichProductPage from "./pages/publisher/rich-page/product/update-rich-product-page";

// import UpdateRichPageSlate from "./pages/publisher/rich-page/update-rich-page-slate";
import { ReaderProfile } from "./pages/web/customer/profile";
import AdminAdProtectionRation from "./pages/admin/ads/ad-protection-ratio";
import AdminGeneralAdCodes from "./pages/admin/ads/general-ad-codes";
import AdminPaidAds from "./pages/admin/ads/paid-ad";
import AdminCreatePaidAdSlot from "./pages/admin/ads/create-paid-ad-slot";
import AdminPaidAdSlots from "./pages/admin/ads/paid-ad-slots";
import AdminEditPaidAdSlot from "./pages/admin/ads/edit-paid-ad-slot";

/*Sponsor Route*/
import SponsorPaymentLink from "./pages/sponsor/payment-link";
import SponsorPaymenRedirect from "./pages/sponsor/payment-redirect";
import SponsorDashboard from "./pages/sponsor";
import SponsorDashboardPaymentLink from "./pages/sponsor/dash-payment-link";
import SponsorPasswordSetup from "./pages/sponsor/setup/password-reset";

function App() {
  if ("scrollRestoration" in window.history) {
    window.history.scrollRestoration = "manual";
  }
  return (
    <>
      {" "}
      <Helmet>
        {/* <title>Buawon</title>
        <meta name="description" content="App Description" />
        <meta name="theme-color" content="#008f68" /> */}
      </Helmet>
      <Helmet>
        <meta name="og:title" content="Bawasa"></meta>
        <meta name="og:url" content="https://litzapp.com"></meta>
        <meta name="og:image" content="/litzap_logo.png"></meta>
        <meta name="og:site_name" content="Litzapp"></meta>
        <meta
          name="og:description"
          content="Litzapp is a literary marketplace platform where writers, authors, photographers, and other digital content creators publish and sell their works in digital format at prices they set."
        ></meta>
      </Helmet>
      <ToastContainer />
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/signup/options" element={<SignupOptions />} />
        <Route exact path="/contentmonetizers" element={<CreatorSignup />} />
        <Route exact path="/supplier/signup" element={<SupplierSignup />} />
        <Route
          exact
          path="/supplier/signup/ref/:id"
          element={<SupplierSignup />}
        />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/faq" element={<FAQ />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/payment-terms" element={<PaymentTerms />} />
        <Route exact path="/sales-terms" element={<SalesTerms />} />
        <Route exact path="/privacy-policy" element={<Privacy />} />
        <Route exact path="/contact-us" element={<Contact />} />
        <Route path="/web/publisher/:uuid" element={<WebPublisher />} />
        <Route path="/web/book/:bookuuid" element={<WebBook />} />
        <Route
          exact
          path="/user/subscription/:userId/complete"
          element={<SubscriptionComplete />}
        />
        <Route
          exact
          path="/user/subscription/product/:userId/complete"
          element={<CPProdSubscriptionComplete />}
        />
        <Route
          path="/user/dashboard"
          element={<CustomerMaster component={<WebReaderHome />} />}
        />
        <Route
          path="/user/email/subscriptions"
          element={<CustomerMaster component={<EmailSubscriptions />} />}
        />
        <Route
          path="/user/product/buys"
          element={<CustomerMaster component={<CustomerProductPurchases />} />}
        />
        <Route
          path="/user/product/subscriptions"
          element={
            <CustomerMaster component={<CustomerProductSubscriptions />} />
          }
        />
        <Route
          path="/user/subscriptions"
          element={<CustomerMaster component={<CustomerSubscriptions />} />}
        />
        <Route
          path="/user/email/requested-updates"
          element={<CustomerMaster component={<CustomerRequestedUpdates />} />}
        />
        <Route
          path="/user/purchases"
          element={<CustomerMaster component={<CustomerPurchases />} />}
        />
        <Route
          path="/user/activation/options"
          element={<CustomerMaster component={<CustomerCreateOptions />} />}
        />
        <Route
          path="/user/activation/:type"
          element={<CustomerMaster component={<PublisherActivationForm />} />}
        />
        <Route
          exact
          path="/user/profile/:userId"
          element={<CustomerMaster component={<ReaderProfile />} />}
        />
        <Route
          path="/donations"
          element={<Master component={<Donations />} />}
        />
        <Route
          path="/password-reset/validate-email"
          element={
            <CustomerMaster component={<PasswordResetEmailValidation />} />
          }
        />
        <Route
          path="/web/mails/unsubscribe/:service/:publisherEmail/:subscriberEmail"
          element={<CustomerMaster component={<BlockEmailUpdates />} />}
        />
        <Route
          path="/web/mails/subscribe/:service/:publisherEmail/:subscriberEmail"
          element={<CustomerMaster component={<RestoreEmailUpdates />} />}
        />
        <Route
          path="/web/mails/unsubscribe/self"
          element={<CustomerMaster component={<PublisherBlockEmailNotice />} />}
        />
        <Route
          path="/password-reset/usr/:userId/tok/:token"
          element={<CustomerMaster component={<PasswordReset />} />}
        />
        <Route
          path="/book-readers"
          element={<Master component={<BookReaders />} />}
        />
        <Route
          path="/payments/:useruuid"
          element={<Master component={<Payments />} />}
        />
        <Route
          exact
          path="/web/publisher/:userId/publications/series/:seriesId/book/:bookId"
          element={<Master component={<WebBook />} />}
        />
        <Route
          exact
          path="/web/publisher/publications/:uuid"
          element={<WebPublications />}
        />
        {/* <Route
        exact
        path="/web/publisher/:useruuid/publications/series/:seriesId/book/:bookId"
        element={<Master component={<Book />} />}
      /> */}
        <Route
          exact
          path="/web/publisher/:useruuid/publications/series/:seriesId"
          element={<WebSeries />}
        />
        <Route path="/reader/:uuid" element={<Reader />} />
        <Route path="/web/reader/:uuid" element={<WebReader />} />
        <Route
          path="/web/rich-page/render/:pageId"
          element={<CustomPageRenderer />}
        />
        <Route
          path="/web/rich-page/render/preview/:pageId"
          element={<CustomPageRendererPreview />}
        />
        <Route path="/web/reader/:uuid/:pageuuid" element={<WebReader />} />
        <Route path="/reader/:uuid/:pageuuid" element={<Reader />} />
        <Route path="/web/:publisheruuid" element={<PublisherProfile />} />
        <Route path="/web/lists/:listid/items" element={<CustomBookList />} />

        <Route
          path="/dashboard"
          element={<Master component={<Dashboard />} />}
        />
        <Route
          exact
          path="/reports"
          element={<Master component={<AllReports />} />}
        />
        {/* Publisher Routes */}
        <Route
          exact
          path="/publishers"
          element={<Master component={<Publisher />} />}
        />
        <Route
          exact
          path="/publisher/create-publisher"
          element={<Master component={<CreatePublisher />} />}
        />
        <Route
          exact
          path="/publisher/create-series/:userId"
          element={<Master component={<CreateSeries />} />}
        />
        <Route
          exact
          path="/publisher/publications/series/:userId"
          element={<Master component={<Series />} />}
        />
        <Route
          exact
          path="/publisher/publications/options/:userId"
          element={<Master component={<PublicationOptions />} />}
        />
        <Route
          exact
          path="/publisher/create/options"
          element={<Master component={<CreateOptions />} />}
        />
        <Route
          exact
          path="/publisher/:userId/publications/series/:seriesId"
          element={<Master component={<Books />} />}
        />
        <Route
          exact
          path="/publisher/:userId/publications/series/:seriesId/book/:bookId"
          element={<Master component={<Book />} />}
        />
        <Route
          exact
          path="/publisher/:userId/publications/series/:seriesId/book/:bookId/:device"
          element={<Master component={<Book />} />}
        />
        {/* Publisher Create Routes */}
        {/* <Route
          exact
          path="/publisher/create-book/:userId/:seriesId"
          element={<Master component={<CreateBook />} />}
        /> */}
        <Route
          exact
          path="/publisher/create/options/:type/:userId/"
          element={<Master component={<CreateBook />} />}
        />
        <Route
          exact
          path="/publisher/profile/:userId/"
          element={<Master component={<DashboardPublisherProfile />} />}
        />
        <Route
          exact
          path="/publisher/settings/:userId/:mode"
          element={<Master component={<DashboardPublisherSettings />} />}
        />
        <Route
          exact
          path="/publisher/subscribers"
          element={<Master component={<DashboardPublisherSubscribersList />} />}
        />
        {/* Publisher Create Routes */}
        <Route
          exact
          path="/publisher/create-page/:type/:userId/:bookId"
          element={<Master component={<CreatePage />} />}
        />
        <Route
          exact
          path="/ads/:publisherId"
          element={<Master component={<AdHome />} />}
        />
        <Route
          exact
          path="/publisher/publications/type/:type/user/:userId/group/:group"
          element={<Master component={<AllBooks />} />}
        />
        <Route
          exact
          path="/reports/publisher/:publisherId/ad/:adId"
          element={<Master component={<SingleAdUnitReport />} />}
        />
        <Route
          exact
          path="/ads/publisher/:publisherId/create"
          element={<Master component={<CreateAdvert />} />}
        />
        <Route
          path="/publisher/subscriptions"
          element={<Master component={<PubSubscriptions />} />}
        />
        <Route
          path="/publisher/purchases"
          element={<Master component={<PubPurchases />} />}
        />
        <Route
          path="/publisher/trackers"
          element={<Master component={<PubTrackers />} />}
        />
        <Route
          path="/publisher/mailing/send/:model"
          element={<Master component={<PubSendMail />} />}
        />
        <Route
          path="/publisher/mailing/email-purchase/:type/:model"
          element={<Master component={<PubEmailPurchase />} />}
        />
        <Route
          path="/publisher/hire/purchases"
          element={<Master component={<PubHirePurchases />} />}
        />
        <Route
          path="/publisher/my/hire/purchases"
          element={<Master component={<PubMyHirePurchases />} />}
        />
        <Route
          path="/publisher/my/purchases"
          element={<Master component={<PublisherMyPurchases />} />}
        />
        <Route
          path="/publisher/product-transactions/single"
          element={<Master component={<PubProductTransactionsSingle />} />}
        />
        <Route
          path="/publisher/product-transactions/recurring"
          element={<Master component={<PubProductTransactionsRecurring />} />}
        />
        <Route
          path="/publisher/my/product-transactions/single"
          element={<Master component={<PubMyProductTransactionsSingle />} />}
        />
        <Route
          path="/publisher/my/product-transactions/recurring"
          element={<Master component={<PubMyProductTransactionsRecurring />} />}
        />
        <Route
          path="/publisher/product/meta/:metaId/availability/:date"
          element={<Master component={<PubProductAvailabilityTable />} />}
        />
        <Route
          path="/publisher/product/account/calendar"
          element={<Master component={<PubProductAccountCalendar />} />}
        />
        <Route
          path="/publisher/my/subscriptions"
          element={<Master component={<PublisherMySubscriptions />} />}
        />
        <Route
          path="/publisher/my/requested-updates"
          element={<Master component={<PublisherRequestedUpdates />} />}
        />
        <Route
          path="/publisher/donations"
          element={<Master component={<PubDonations />} />}
        />
        <Route
          exact
          path="/publisher/rich-page/create/:userId"
          element={<Master component={<CreateRichPage />} />}
        />
        <Route
          exact
          path="/publisher/rich-page/create/:userId/:authType"
          element={<Master component={<CreateRichPage />} />}
        />
        <Route
          exact
          path="/publisher/rich-page/update/user/:userId/page/:pageId"
          element={<Master component={<UpdateRichPage />} />}
        />
        <Route
          exact
          path="/publisher/rich-product-page/create/:userId"
          element={<Master component={<CreateRichProductPage />} />}
        />
        <Route
          exact
          path="/publisher/rich-product-page/update/user/:userId/page/:pageId"
          element={<Master component={<UpdateRichProductPage />} />}
        />
        <Route
          exact
          path="/publisher/storage"
          element={<Master component={<PublisherDashboardStorage />} />}
        />
        <Route
          exact
          path="/publisher/storage/payments"
          element={
            <Master component={<PublisherDashboardStorageSubscriptions />} />
          }
        />
        <Route
          exact
          path="/publisher/storage/:action"
          element={<Master component={<PublisherDashboardStorage />} />}
        />
        <Route
          exact
          path="/publisher/storage/redirect/back"
          element={<Navigate to="/publisher/storage/success" replace />}
        />
        <Route
          exact
          path="/publisher/my/files"
          element={<Master component={<PublisherDashboardStorageFiles />} />}
        />
        <Route
          exact
          path="/publisher/my/ads-record"
          element={<Master component={<PublisherMyAdsRecord />} />}
        />
        <Route
          exact
          path="/publisher/supplier/onboarding/start"
          element={<Master component={<PubSupplierOnboarding />} />}
        />
        <Route
          exact
          path="/publisher/supplier/onboarding/start/:action"
          element={<Master component={<PubSupplierOnboarding />} />}
        />
        <Route
          exact
          path="/publisher/supplier/sample-contracts"
          element={<Master component={<PubSupplierSampleContracts />} />}
        />
        <Route
          exact
          path="/publisher/supplier/profile"
          element={<Master component={<PubSupplierProfile />} />}
        />
        <Route
          exact
          path="/publisher/supplier/my-supplier-contracts"
          element={<Master component={<PubSupplierMyContracts />} />}
        />
        <Route
          exact
          path="/publisher/supplier/my-supplier-contracts/add"
          element={<Master component={<PubSupplierMyContractsAdd />} />}
        />
        <Route
          exact
          path="/publisher/supplier/my-supplier-contracts/:id/edit"
          element={<Master component={<PubSupplierMyContractsEdit />} />}
        />
        <Route
          exact
          path="/publisher/supplier/my-supplier-contracts/:id/view-buttons"
          element={<Master component={<PubSupplierMyContractsConnButtons />} />}
        />
        {/* CustomerServices */}
        <Route
          exact
          path="/publisher/supplier/my-customer-services"
          element={<Master component={<CustomerServices />} />}
        />
        <Route
          exact
          path="/publisher/supplier/my-customer-services/create"
          element={<Master component={<CreateCustomerServiceSet />} />}
        />
        <Route
          exact
          path="/publisher/supplier/my-customer-services/set/:id/edit"
          element={<Master component={<EditCustomerServiceSet />} />}
        />
        {/* <Route
          exact
          path="/publisher/rich-page/update/user/:userId/page/:pageId/slate"
          element={<Master component={<UpdateRichPageSlate />} />}
        /> */}
        {/* Admin Routes */}
        <Route
          exact
          path="/admin/publishers"
          element={<Master component={<AdminPublisher />} />}
        />
        <Route
          exact
          path="/admin/non-monetizers"
          element={<Master component={<AdminNonMonetizer />} />}
        />
        <Route
          exact
          path="/admin/non-monetizers/content-purchases/:uuid"
          element={<Master component={<AdminReaderMyPurchases />} />}
        />
        <Route
          exact
          path="/admin/non-monetizers/content-subs/:uuid"
          element={<Master component={<AdminReaderMySubscriptions />} />}
        />
        <Route
          exact
          path="/admin/non-monetizers/product-subs/:uuid"
          element={<Master component={<AdminReaderMyProductSubs />} />}
        />
        <Route
          exact
          path="/admin/non-monetizers/product-purchases/:uuid"
          element={<Master component={<AdminReaderMyProductPurchases />} />}
        />
        <Route
          exact
          path="/admin/homepage-sort"
          element={<Master component={<AdminHomePageSort />} />}
        />
        <Route
          exact
          path="/admin/publishers/banned"
          element={<Master component={<AdminBannedPublishers />} />}
        />
        <Route
          exact
          path="/admin/publisher/create-publisher"
          element={<Master component={<AdminCreatePublisher />} />}
        />
        <Route
          exact
          path="/admin/publisher/create-series/:userId"
          element={<Master component={<AdminCreateSeries />} />}
        />
        <Route
          exact
          path="/admin/publisher/publications/series/:userId"
          element={<Master component={<AdminSeries />} />}
        />
        <Route
          exact
          path="/admin/publisher/publications/options/:userId"
          element={<Master component={<AdminPublicationOptions />} />}
        />
        <Route
          exact
          path="/admin/publisher/:userId/publications/series/:seriesId"
          element={<Master component={<AdminBooks />} />}
        />
        <Route
          exact
          path="/admin/publisher/:userId/publications/series/:seriesId/book/:bookId"
          element={<Master component={<AdminBook />} />}
        />
        <Route
          exact
          path="/admin/publisher/create-book/:userId/:seriesId"
          element={<Master component={<AdminCreateBook />} />}
        />
        <Route
          exact
          path="/admin/publisher/create-book/:userId/"
          element={<Master component={<AdminCreateBook />} />}
        />
        <Route
          exact
          path="/admin/publisher/create-page/:userId/:bookId"
          element={<Master component={<AdminCreatePage />} />}
        />
        <Route
          exact
          path="/admin/advert-system"
          element={<Master component={<AdminAdHome />} />}
        />
        <Route
          exact
          path="/admin/sales/create-account"
          element={<Master component={<AdminCreateSalesAccount />} />}
        />
        <Route
          exact
          path="/admin/sales/accounts"
          element={<Master component={<AdminSalesAccounts />} />}
        />
        <Route
          exact
          path="/admin/sales/:id/supplier/accounts"
          element={<Master component={<AdminSalesSuppliers />} />}
        />
        <Route
          exact
          path="/admin/system/subscribers"
          element={<Master component={<AdminSystemSubscribers />} />}
        />
        <Route
          exact
          path="/admin/system/subscriber/create"
          element={<Master component={<AdminSystemCreateSubscriber />} />}
        />
        <Route
          exact
          path="/admin/system/subscriber/edit/:id"
          element={<Master component={<AdminSystemEditSubscriber />} />}
        />
        <Route
          exact
          path="/admin/advert-requests/:requestType"
          element={<Master component={<AdminAdvertRequests />} />}
        />
        <Route
          exact
          path="/admin/publisher/publications/:type/:userId"
          element={<Master component={<AdminAllBooks />} />}
        />
        <Route
          exact
          path="/admin/reports/publisher/:publisherId/ad/:adId"
          element={<Master component={<AdminSingleAdUnitReport />} />}
        />
        <Route
          exact
          path="/admin/ads/publisher/:publisherId/create"
          element={<Master component={<AdminCreateAdvert />} />}
        />
        <Route
          exact
          path="/admin/ads/ad-protection-ratio"
          element={<Master component={<AdminAdProtectionRation />} />}
        />
        <Route
          exact
          path="/admin/ads/general-ad-codes"
          element={<Master component={<AdminGeneralAdCodes />} />}
        />
        <Route
          exact
          path="/admin/ads/paid-ads"
          element={<Master component={<AdminPaidAds />} />}
        />
        <Route
          exact
          path="/admin/paid-adverts/set/:id/create"
          element={<Master component={<AdminCreatePaidAdSlot />} />}
        />
        <Route
          exact
          path="/admin/paid-adverts/slot/:id/edit"
          element={<Master component={<AdminEditPaidAdSlot />} />}
        />
        <Route
          exact
          path="/admin/paid-adverts/set/:id/slots"
          element={<Master component={<AdminPaidAdSlots />} />}
        />
        <Route
          path="/admin/subscriptions"
          element={<Master component={<AdminPubSubscriptions />} />}
        />
        <Route
          path="/admin/donations"
          element={<Master component={<AdminDonations />} />}
        />
        <Route
          exact
          path="/admin/settings/:userId/:mode"
          element={<Master component={<DashboardAdminSettings />} />}
        />
        <Route
          exact
          path="/admin/storage/:userId"
          element={<Master component={<AdminDashboardStorage />} />}
        />
        <Route
          exact
          path="/admin/storage/:userId/create"
          element={<Master component={<AdminDashboardCreateTier />} />}
        />
        <Route
          exact
          path="/admin/storage/:userId/edit/:tierId"
          element={<Master component={<AdminDashboardEditTier />} />}
        />
        <Route
          exact
          path="/admin/activation/:userId"
          element={<Master component={<AdminPublisherActivation />} />}
        />
        <Route
          exact
          path="/admin/supplier/product-contracts"
          element={<Master component={<AdminContracts />} />}
        />
        <Route
          exact
          path="/admin/supplier/product-contracts/:type"
          element={<Master component={<AdminContractsUpload />} />}
        />
        <Route
          exact
          path="/admin/supplier/product-contracts/recurring/:type"
          element={<Master component={<AdminREContractsUpload />} />}
        />
        <Route
          exact
          path="/admin/supplier/product-contracts/single/:type"
          element={<Master component={<AdminSEContractsUpload />} />}
        />
        <Route
          exact
          path="/admin/supplier/product-contracts/sample/:type"
          element={<Master component={<AdminSampleContractsOpt />} />}
        />
        <Route
          exact
          path="/admin/supplier/product-contracts/default/options"
          element={<Master component={<AdminDefaultContractsOpt />} />}
        />
        <Route
          exact
          path="/admin/supplier/product-contracts/default/single"
          element={<Master component={<AdminSEDefaultContractsOpt />} />}
        />
        <Route
          exact
          path="/admin/supplier/product-contracts/default/recurring"
          element={<Master component={<AdminREDefaultContractsOpt />} />}
        />
        <Route
          exact
          path="/admin/supplier/product-contracts/viewer/:type"
          element={<Master component={<AdminContractViewer />} />}
        />
        <Route
          exact
          path="/admin/supplier/applications"
          element={<Master component={<AdminSupplierApplications />} />}
        />
        <Route
          exact
          path="/admin/supplier/business/relocations"
          element={<Master component={<AdminSupplierBizRelocation />} />}
        />
        <Route
          exact
          path="/admin/supplier/applications/applicant/:user_id"
          element={<Master component={<AdminSupplierApplicant />} />}
        />
        <Route
          exact
          path="/admin/supplier/biz-relocation/applicant/:user_id"
          element={
            <Master component={<AdminSupplierBizRelocationApplicant />} />
          }
        />
        <Route
          exact
          path="/admin/supplier/product/contracts"
          element={<Master component={<AdminSupplierContracts />} />}
        />
        <Route
          exact
          path="/admin/supplier/product/contract/:contract_id/user/:user_id"
          element={<Master component={<AdminSupplierContract />} />}
        />
        <Route
          exact
          path="/admin/supplier/profile/:user_id"
          element={<Master component={<AdminSupplierProfile />} />}
        />
        {/* Sponsor routes */}
        {/* Publisher Create Routes */}
        <Route
          exact
          path="/sponsor/dashboard"
          element={<Master component={<SponsorDashboard />} />}
        />
        <Route
          exact
          path="/sponsor/dashboard/payment/:id"
          element={<Master component={<SponsorDashboardPaymentLink />} />}
        />
        <Route
          exact
          path="/sponsor/payment/:id"
          element={<SponsorPaymentLink />}
        />
        <Route
          exact
          path="/sponsor/payment/redirect/:id"
          element={<SponsorPaymenRedirect />}
        />
        <Route
          path="/sponsor/password-setup/:userId/tok/:token"
          element={<CustomerMaster component={<SponsorPasswordSetup />} />}
        />
        {/* /*sales*/}
        <Route
          path="/sales/home"
          element={<Master component={<SalesIndex />} />}
        />
        <Route
          exact
          path="/sales/settings/:userId/:mode"
          element={<Master component={<SalesSettings />} />}
        />
        <Route
          path="/sales/suppliers"
          element={<Master component={<SalesSuppliers />} />}
        />
        <Route
          path="/sales/supplier/create"
          element={<Master component={<SalesBasicRegistration />} />}
        />
        <Route
          path="/sales/supplier/:id/supplier-application"
          element={<Master component={<SalesSupplierOnboarding />} />}
        />
        <Route exact path="*" element={<Login />} />
      </Routes>
      {/* <Footer />; */}
    </>
  );
}

export default App;
