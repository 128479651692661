import TextArea from "../../../../../../../components/inputs/text-area";
import Checkbox from "../../../../../../../components/inputs/checkbox";
import EditorTooltip from "../../tooltip/editor-tooltip";
export default function RequestReply(props) {
  const { productMeta, handleMetaChange } = props;
  return (
    <div className="inline-flex gap-1 w-full justify-center relative">
      <div>
        <Checkbox
          handleChange={(e) =>
            handleMetaChange(e, "purchaser_reply_enabled", "checkbox")
          }
          checked={productMeta.purchaser_reply_enabled}
        />
      </div>
      <span>Request Purchaser Reply</span>
      <EditorTooltip
        body={
          "When this option is enabled, a textbox will appear below allowing you to prompt the customer to supply specific details before they can continue with the purchase."
        }
        top={"-135px"}
        right={"-25px"}
      />
    </div>
  );
}
