import TextField from "../components/inputs/text-input";
import BasicButton from "../components/buttons/basic-button";
import { useBookStore } from "../stores/book-store";
import InsertImage from "../pages/publisher/rich-page/components/insert-image";
import { isResponsiveMode } from "../Utils/helpers";
import YouTube from "react-youtube";
import { useState } from "react";
import TextArea from "../components/inputs/text-area";

export default function HireModalEditor({
  modalOpen,
  hideModal,
  insertHire,
  processing,
  handleHireTextChange,
  handleHireReplySubjectChange,
  handleHirePriceChange,
  handleHireResponseStatus,
  hireTextValue,
  replySubjectValue,
  hirePriceValue,
  replyStatus,
  createHireMode,
  user,
}) {
  const canSubmit = () => {
    if (
      hireTextValue.trim().length == 0 &&
      replyStatus == false &&
      hirePriceValue.trim().length !== 0
    ) {
      return true;
    }

    if (replyStatus == true && replySubjectValue.trim().length == 0) {
      return false;
    }

    if (hirePriceValue && hirePriceValue.trim().length == 0) {
      return false;
    }

    if (!hirePriceValue) {
      return false;
    }
    return true;
  };

  return (
    <div
      className={`absolute top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity z-50">
          <div class="absolute inset-0 bg-gray-900 opacity-75 " />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-6/12 h-4/12 z-50 relative"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className="rounded rounded-full p-0 -top-2 -right-1 absolute px-1 cursor-pointer opacity-75"
            onClick={() => hideModal()}
          >
            <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
          </div>

          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <h1 className="text-2xl font-bold text-center">
                {/* {getActiveWidgetTitle(currentEditingWidget, widgets)} */}
                Add Hire Button
              </h1>
            </div>
            <div className="my-6 w-full border flex flex-col items-center justify-center bg-gray-50 p-2 h-48 overflow-y-scroll">
              <label>After Purchase Message (optional):</label>
              <TextArea
                placeholder={"Enter text here.."}
                classes="rounded-lg pr-8"
                handleChange={handleHireTextChange}
                value={hireTextValue}
              />
            </div>
            <div class="flex items-center mb-4">
              <input
                id="disabled-checkbox"
                type="checkbox"
                defaultChecked={replyStatus}
                checked={replyStatus}
                onChange={() => handleHireResponseStatus()}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label for="disabled-checkbox" class="ml-2 text-sm font-medium">
                Request Purchaser Reply Checkbox (optional)
              </label>
            </div>
            {replyStatus ? (
              <div className="my-6 w-full border flex flex-col items-center justify-center bg-gray-50 p-2 h-28 overflow-y-scroll">
                <label>Request Purchase Reply Subject:</label>
                <TextField
                  placeholder={"Enter text here.."}
                  classes="rounded-lg pr-8"
                  handleChange={handleHireReplySubjectChange}
                  value={replySubjectValue}
                />
              </div>
            ) : (
              ""
            )}
            <div className="my-6 w-full border flex flex-col items-start justify-center bg-gray-50 p-2">
              <label>Price</label>
              <TextField
                classes={"p-6 my-2 w-full"}
                placeholder={"Price.."}
                type="number"
                handleChange={handleHirePriceChange}
                value={hirePriceValue}
              />
              {user.id
                ? user.system_setting.constant_fee_on_payments && hirePriceValue
                  ? `Service Charge $${Number(
                      user.system_setting.constant_fee_on_payments
                    )} + ${hirePriceValue} = $${
                      Number(hirePriceValue) +
                      Number(user.system_setting.constant_fee_on_payments)
                    }`
                  : ``
                : ""}
            </div>

            <div className="flex justify-center mb-2">
              <BasicButton
                disabled={!canSubmit()}
                title={
                  processing
                    ? "Inserting..."
                    : createHireMode
                    ? "Insert"
                    : "Update"
                }
                classes="px-8 w-8/12"
                handleClick={() => canSubmit() && insertHire()}
              />
            </div>

            {replyStatus ? <br /> : ""}
            {/* modal body */}
          </div>

          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
            {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
