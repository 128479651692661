import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import BasicButton from "../../../components/buttons/basic-button";
import TextField from "../../../components/inputs/text-input";
import Pagination from "../../../components/pagination";
import useDebounce from "../../../components/debounce/debounce-helper";
import LoadingProgress from "../rich-page/components/loading-progress";
import Checkbox from "../../../components/inputs/checkbox";
import { useSendMailStore } from "../../../stores/send-mail-store";
import FilterBar from "../components/email-list-filter";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";
import DesktopPubTrackersTable from "./components/desktop-table";
import MobilePubTrackersTable from "./components/mobile-table";

import {
  isAdmin,
  API_BASE,
  isPublisher,
  isAuthUser,
  authUserData,
  getLocation,
  isResponsiveMode,
} from "../../../Utils/helpers";
import { toast } from "react-toastify";
import Books from "../series/books";
export default function PubTrackers() {
  const [currentPage, setCurrentPage] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [paginateMode, setPaginateMode] = useState(false);
  const [user, setUser] = useState({});
  const [blockedEmails, setBlockedEmails] = useState([]);
  const mountedRef = useRef();
  const navigate = useNavigate();
  const mailStore = useSendMailStore((state) => state);

  const fetchUser = () => {
    const url = API_BASE + "/publisher/" + authUserData().uuid + "/optimized";
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setUser(response.data.publisher);
          mailStore.setUser(response.data.publisher);
          if (response.data.publisher.emailblocklist)
            setBlockedEmails(
              response.data.publisher.emailblocklist.map((d) => d.email)
            );
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  const doPagination = (page) => {
    if (mailStore.data.first_page_url) {
      mailStore.setData([]);
      setCurrentPage(page);
      setPaginateMode(true);
      setProcessing(true);
      axios
        .get(mailStore.data.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          mailStore.setData(res.data.trackers);
          mailStore.setIds(res.data.ids);
          setProcessing(false);
        })
        .catch((err) => setProcessing(false));
    }
  };

  const getPurchases = () => {
    mailStore.setData([]);
    setProcessing(true);
    setPaginateMode(false);
    const url =
      API_BASE +
      "/publisher/trackers/" +
      authUserData().uuid +
      "/" +
      mailStore.query.countPerPage +
      "/" +
      mailStore.query.pageLimit +
      "/" +
      mailStore.query.searchText +
      " ";

    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          mailStore.setData(response.data.trackers);
          setProcessing(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const computeSelectedCount = () => {
    if (!mailStore.data.total) return false;
    if (mailStore.isAllSelected) {
      if (mailStore.deSelected.length) {
        mailStore.setSelectedCount(
          mailStore.data.total - mailStore.deSelected.length
        );
      } else {
        mailStore.setSelectedCount(mailStore.data.total);
      }
    } else {
      mailStore.setSelectedCount(mailStore.selected.length);
    }
  };

  const handleSearchTextChange = (e) => {
    mailStore.setQuery("searchText", e);
  };

  const handleCountPerPageChange = (e) => {
    mailStore.setQuery("countPerPage", e.target.value);
  };

  const handleLatestEntriesChange = (e) => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
    mailStore.setQuery("selectedLatest", e.target.value);
    mailStore.setQuery("pageLimit", e.target.value);
  };

  const handleOldestEntriesChange = (e) => {
    mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
    mailStore.setQuery("selectedOldest", e.target.value);
    mailStore.setQuery("pageLimit", e.target.value);
  };

  const markItem = (id) => {
    if (!mailStore.isAllSelected && !mailStore.selected.includes(id)) {
      mailStore.setSelType("M");
      const item = mailStore.selected;
      mailStore.setSelected([...item, id]);
    } else {
      const item = mailStore.deSelected;
      if (mailStore.isAllSelected) {
        if (mailStore.deSelected.includes(id)) {
          const filteredItems = mailStore.deSelected.filter((e) => e !== id);
          mailStore.setDeselected(filteredItems);
        } else {
          mailStore.setDeselected([...item, id]);
        }
      }
      const filteredItems = mailStore.selected.filter((e) => e !== id);
      mailStore.setSelected(filteredItems);
    }
  };

  const clearMarkedItems = (id) => {
    mailStore.setSelType("M");
    mailStore.setSelected([]);
    mailStore.setDeselected([]);
    mailStore.setIsAllSelected(false);
    mailStore.setQuery("selectedLatest", "");
    mailStore.setQuery("selectedOldest", "");
    mailStore.setQuery("pageLimit", "normal_1000000");
  };

  const markAllItems = () => {
    mailStore.setSelType("A");
    mailStore.setIsAllSelected();
  };

  const addNewItemsToMakrList = () => {
    const items = mailStore.selected;
    if (mailStore.isAllSelected) {
      if (!mailStore.data.data) return false;
      mailStore.setSelected(items);
    } else {
      if (mailStore.selType == "A") {
        mailStore.setSelected([]);
        mailStore.setDeselected([]);
        console.log("DESELECTING");
      }
    }
  };

  const shouldMarkItem = (id) => {
    if (!mailStore.isAllSelected && mailStore.selected.includes(id)) {
      return true;
    }
    if (mailStore.isAllSelected && !mailStore.deSelected.includes(id)) {
      return true;
    }

    return false;
  };

  const showSendButton = () => {
    if (!mailStore.data.total) return false;
    if (mailStore.selected.length) return true;
    if (mailStore.isAllSelected) {
      if (mailStore.deSelected.length !== mailStore.data.total) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedLatest]);

  useEffect(() => {
    computeSelectedCount();
  }, [mailStore.selected]);

  useEffect(() => {
    computeSelectedCount();
  }, [mailStore.deSelected]);

  useEffect(() => {
    mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedOldest]);

  useEffect(() => {
    addNewItemsToMakrList();
    computeSelectedCount();
    if (!mailStore.isAllSelected) {
      mailStore.setDeselected([]);
    } else {
      mailStore.setSelected([]);
    }
  }, [mailStore.isAllSelected]);

  useEffect(() => {
    addNewItemsToMakrList();
    computeSelectedCount();

    if (
      !mailStore.isAllSelected &&
      mailStore.selected.length &&
      mailStore.refresh &&
      !paginateMode
    ) {
      mailStore.setSelected([]);
    }

    if (!paginateMode && mailStore.refresh) mailStore.setIsAllSelected(false);
    fetchUser();
  }, [mailStore.data]);

  useDebounce(
    () => {
      if (mailStore.refresh) {
        getPurchases();
      }
      mailStore.setRefresh(true);
    },
    [mailStore.query],
    800
  );

  useEffect(() => {
    if (!isPublisher()) {
      window.location.href = "/";
    }
    fetchUser();
  }, []);

  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">
              Tracker(s) List
              {/* {JSON.stringify(mailStore.selected)}
              {JSON.stringify(mailStore.deSelected)}
              {mailStore.isAllSelected ? "Yes" : "No"} ###first load{" "}
              {mailStore.refresh ? "Yes" : "No"}
              ########{mailStore.selectedCount} */}
            </h1>
          </div>
          <div className="flex justify-between gap-2 items-start">
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            <p className="w-full text-center my-4">
              We found {mailStore.data.total ? mailStore.data.total : ""}{" "}
              records
            </p>
            <FilterBar
              handleCountPerPageChange={handleCountPerPageChange}
              handleSearchTextChange={handleSearchTextChange}
              serchQValue={mailStore.query.searchText}
              showSendButton={showSendButton}
              handleLatestEntriesChange={handleLatestEntriesChange}
              latestQValue={mailStore.query.selectedLatest}
              handleOldestEntriesChange={handleOldestEntriesChange}
              oldestQValue={mailStore.query.selectedOldest}
              markAllItems={markAllItems}
              clearMarkedItems={clearMarkedItems}
              selectAllFlag={mailStore.isAllSelected}
              model={"trackers"}
            />

            <div className="w-full flex justify-center">
              {mailStore.data.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            {isResponsiveMode() ? (
              <MobilePubTrackersTable
                blockedEmails={blockedEmails}
                shouldMarkItem={shouldMarkItem}
                markItem={markItem}
              />
            ) : (
              <DesktopPubTrackersTable
                blockedEmails={blockedEmails}
                shouldMarkItem={shouldMarkItem}
                markItem={markItem}
              />
            )}
            <LoadingProgress loading={processing} />
            <div className="w-full flex justify-center">
              {mailStore.data.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
