import TextArea from "../../../../../../../../components/inputs/text-area";
export default function TwitterEmbedBox(props) {
  const { handleEmbdedCodeChange, embedValue } = props;
  return (
    <div>
      <TextArea
        handleChange={(e) => handleEmbdedCodeChange(e, "twitter")}
        value={embedValue}
      />
    </div>
  );
}
