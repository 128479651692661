import BasicButton from "../../../../components/buttons/basic-button";
import { isPublisher } from "../../../../Utils/helpers";
import Checkbox from "../../../../components/inputs/checkbox";
import authRequestService from "../../../../services/HttpRequests/authenticated-request-services";
import { API_BASE, BASE_URL_WITH_LOCAL } from "../../../../Utils/helpers";
import { useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";

export default function BuyerContractConsent(props) {
  const { consent, setConsent, file, file2 } = props;

  const [contract, setContract] = useState({});

  const getContract = () => {
    const url =
      API_BASE + "/admin/supplier/contract/admin/" + "supplier-contract";
    // setProcessing(true);

    authRequestService()
      .get(url)
      .then((response) => {
        // setProcessing(false);
        // setContract(response.data.contract);
      })
      .catch((error) => {
        // setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  useEffect(() => {}, []);

  return (
    <div className="p-2 w-full">
      {/* content start */}
      <div className="flex flex-col items-center mt-5">
        <div>
          <h2 className="font-bold">Terms & Conditions</h2>
        </div>
        <div className="flex justify-center gap-4">
          <Checkbox
            handleChange={(e) => setConsent((prev) => !prev)}
            checked={consent}
          />

          <p className="w-full">
            I acknowledge that I have read and accept the terms and conditions
            of the{" "}
            <a
              target="_blank"
              className="text-blue-500 cursor-pointer"
              href={
                file
                  ? BASE_URL_WITH_LOCAL + "/storage" + file.split("public")[1]
                  : "#"
              }
            >
              <span className="px-1">Product Buyer Contract</span>
            </a>
            by Supplier and
            <a
              target="_blank"
              className="text-blue-500 cursor-pointer"
              href={
                file
                  ? BASE_URL_WITH_LOCAL + "/storage" + file2?.split("public")[1]
                  : "#"
              }
            >
              <span className="px-1">Litzapp User Agreement.</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
