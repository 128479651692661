import TextField from "../../components/inputs/text-input";
import BasicButton from "../../components/buttons/basic-button";
import { useState, useEffect } from "react";
import { isAdmin, API_BASE } from "../../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
export default function AdminCreatePublisher() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const url = API_BASE + "/api/publisher-create";
  const addPublisher = () => {
    setLoading(true);
    axios
      .post(url, {
        name: firstName + " " + lastName,
        first_name: firstName,
        last_name: lastName,
        email: email,
      })
      .then((response) => {
        setLoading(false);
        if (response.status == 200) {
          window.location.replace("/admin/publishers");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 422) {
          toast("Duplicate registration, email address already exists!", {
            type: "error",
          });
        } else {
          toast("Something went wrong!", { type: "error" });
        }
      });
  };

  const handleFirstNameChange = (name) => {
    setFirstName(name);
  };
  const handleLastNameChange = (name) => {
    setLastName(name);
  };
  const handleEmailChange = (email) => {
    setEmail(email);
  };
  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
  }, []);
  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 bold">Create Publisher</h1>
        </div>
        <div>
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-4 mt-8">
        <div className="w-8/12 md:w-4/12">
          <div className="m-2">
            <label className="text-black">First Name</label>
            <TextField
              classes={"p-6 my-2"}
              placeholder={"First Name.."}
              handleChange={handleFirstNameChange}
            />
          </div>

          <div className="m-2">
            <label className="text-black">Last Name</label>
            <TextField
              classes={"p-6 my-2"}
              placeholder={"Last Name.."}
              handleChange={handleLastNameChange}
            />
          </div>
          <div className="m-2">
            <label className="text-black">Email</label>
            <TextField
              classes={"p-6"}
              placeholder={"Email.."}
              handleChange={handleEmailChange}
            />
          </div>
        </div>
        <div>
          <BasicButton
            disabled={!firstName || !lastName || !email || loading}
            title={loading ? "Creating.." : "Create"}
            classes={"p-6"}
            handleClick={addPublisher}
          />
        </div>
      </div>
    </>
  );
}
