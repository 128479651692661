import axios from "axios";
import TextField from "../../../components/inputs/text-input";
import { useEffect, useState } from "react";
import BasicButton from "../../../components/buttons/basic-button";
import { isAdmin, API_BASE, isResponsiveMode } from "../../../Utils/helpers";
import Pagination from "../../../components/pagination";
import useDebounce from "../../../components/debounce/debounce-helper";
import AdminDesktopTable from "./components/desktop-table";
import AdminMobileTable from "./components/mobile-table";
import { toast } from "react-toastify";
export default function AdminNonMonetizer() {
  const [readers, setReaders] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [countPerPage, setCountPerPage] = useState(10);

  const handleSearchTextChange = (e) => {
    setSearchText(e);
  };

  const handleCountPerPageChange = (e) => {
    setCountPerPage(e.target.value);
  };
  const url =
    API_BASE + "/api/admin/readers/" + countPerPage + "/" + searchText;

  const getReaders = () => {
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setReaders(response.data.readers);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const doPagination = (page) => {
    if (readers.first_page_url) {
      setCurrentPage(page);
      axios
        .get(readers.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          setReaders(res.data.readers);
        })
        .catch((err) => console.log(err));
    }
  };

  useDebounce(
    () => {
      setReaders({});
      getReaders();
    },
    [searchText, countPerPage],
    800
  );

  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">Non-Monetizers</h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <p className="w-full text-center my-4">
          We found {readers.total ? readers.total : ""} records
        </p>
        <div className="flex justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            <div className="flex w-full gap-4">
              <div className="w-11/12">
                <TextField
                  title="Search"
                  placeholder={"Search"}
                  classes="rounded-lg"
                  handleChange={handleSearchTextChange}
                  value={searchText}
                />
              </div>

              <div className="w-1/12">
                <label for="timezone" value="Your Timezone" />
                <select
                  onChange={handleCountPerPageChange}
                  required
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full text-black"
                >
                  <option value="10" default>
                    10
                  </option>
                  {/* <option value="2">2</option> */}
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <div className="flex justify-center">
              {" "}
              {readers.data ? (
                readers.data.length ? (
                  <Pagination
                    pagination={readers}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            {isResponsiveMode() ? (
              <AdminMobileTable data={readers} />
            ) : (
              <AdminDesktopTable data={readers} />
            )}
            <div className="flex justify-center">
              {" "}
              {readers.data ? (
                readers.data.length ? (
                  <Pagination
                    pagination={readers}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
