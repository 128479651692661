import { authUserData, isAuthUser } from "../../Utils/helpers";
import BasicButton from "../buttons/basic-button";
export default function TrackBox(props) {
  const { page } = props;
  const { lastRatedBook } = props;
  const { isTrackingPublisher } = props;
  const { untrackPublisher } = props;
  const { trackPublisher } = props;
  const { openAuthPromtModal } = props;
  return (
    <BasicButton
      disabled={isAuthUser() && page?.owner?.uuid == authUserData()?.uuid}
      handleClick={() =>
        isAuthUser()
          ? isTrackingPublisher(
              lastRatedBook && lastRatedBook?.owner?.uuid == page?.owner?.uuid
                ? lastRatedBook
                : page
            )
            ? untrackPublisher(page, authUserData()?.uuid)
            : trackPublisher(page, authUserData()?.uuid)
          : openAuthPromtModal()
      }
      title={
        isTrackingPublisher(
          lastRatedBook &&
            lastRatedBook?.owneroptimized?.uuid == page?.owneroptimized?.uuid
            ? lastRatedBook
            : page
        ) ? (
          <div>
            <span style={{ fontSize: "12px" }}>Untrack</span>
            <span style={{ fontSize: "12px" }}> Publisher</span>
          </div>
        ) : (
          <div>
            <span style={{ fontSize: "12px" }}> Track</span>
            <span style={{ fontSize: "12px" }}> Publisher</span>
          </div>
        )
      }
      classes={`
      
       ${
        isAuthUser() && page?.owner?.uuid == authUserData()?.uuid
          ? "bg-gray-500 hover:bg-gray-500"
          : "bg-black hover:bg-black cursor-pointer"
      }`}
    />
  );
}
