import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { isAuthUser, API_BASE, isSponsor } from "../../Utils/helpers";
import BasicButton from "../../components/buttons/basic-button";
import WebHeader from "../web/header";
import { useParams } from "react-router-dom";
import StripeGeneralSubscriptionModal from "../../modals/stripe-general-sub-modal";

export default function SponsorPaymentLink() {
  const [subscription, setSubscription] = useState({});
  const [firstName, setFirstName] = useState("");
  const [processing, setProcessing] = useState(false);
  const [hasSubscribed, setHasSubscribed] = useState(false);
  const [payModalOpen, setPayModalOpen] = useState(false);
  const [subStatus, setStatus] = useState("");
  const [secretKey, setSecretKey] = useState("");

  const { id } = useParams();

  const getSubscription = () => {
    setProcessing(true);
    const url = API_BASE + "/api/sponsor/system/subscription/single/" + id;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setProcessing(false);
          setSubscription(response.data.product_sub);
          if (!response.data.product_sub.stripesubscription) return;
          if (
            response.data.product_sub.stripesubscription.status == "active" ||
            response.data.product_sub.stripesubscription.status == "cancelled"
          ) {
            setHasSubscribed(true);
            setStatus(response.data.product_sub.stripesubscription.status);
          }
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const getPaymentSecret = () => {
    setProcessing(true);
    const url =
      API_BASE + "/api/sponsor/system/subscription/payment/secret/" + id;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setProcessing(false);
          setSecretKey(response.data.secret_key);
          showPayModal();
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const showPayModal = () => {
    setPayModalOpen(true);
  };

  const hidePayModal = () => {
    setPayModalOpen(false);
  };

  const canShowPayButton = () => {
    if (!subscription.id) return false;
    if (!hasSubscribed || subStatus == "processing") {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (isAuthUser() && isSponsor()) {
      window.location.href = "/sponsor/dashboard/payment/" + id;
    }
    getSubscription();
  }, []);

  return (
    <>
      <WebHeader />
      <div class="container px-6 mx-auto mt-10 flex justify-center">
        <div className="md:w-6/12 text-center">
          {canShowPayButton() && (
            <BasicButton
              disabled={processing}
              handleClick={() => getPaymentSecret()}
              classes={`w-full py-4 ${
                false ? "bg-gray-500" : "bg-black"
              }  rounded-lg text-green-100`}
              title={
                processing
                  ? "Processing..."
                  : `Subscribe Now $${subscription.lateststripeprice.unit_amount}`
              }
            />
          )}
          {!canShowPayButton() && !processing && <p>You already subscribed</p>}
        </div>
      </div>
      <StripeGeneralSubscriptionModal
        modalOpen={payModalOpen}
        hideModal={hidePayModal}
        secretKey={secretKey}
        data={subscription}
      />
    </>
  );
}
