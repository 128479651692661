import BasicButton from "../../../../components/buttons/basic-button";
import { useNavigate } from "react-router-dom";
export default function AdminDesktopTable({ data }) {
  const navigate = useNavigate();

  return (
    <>
      <div className="overflow-x-scroll md:h-screen w-full">
        <table class="table-auto w-full">
          <thead>
            <tr className={window.innerWidth < 720 ? "hidden" : ""}>
              <th class="px-4 py-2 text-gray-600">Name</th>
              <th class="px-4 py-2 text-gray-600">Purchases</th>
              <th class="px-4 py-2 text-gray-600">Subscription</th>
              <th class="px-4 py-2 text-gray-600">Donations</th>
              <th class="px-4 py-2 text-gray-600">Registered</th>
            </tr>
          </thead>
          <tbody>
            {data?.data
              ? data.data.map((reader) => {
                  return (
                    <tr
                      className={window.innerWidth < 820 ? "flex flex-col" : ""}
                    >
                      <td class="border px-4 py-2 font-medium">
                        <p className="break-words">
                          <p className="text-green-500"> First Name</p>
                          <small className="text-base">
                            {" "}
                            {reader.first_name}
                          </small>
                        </p>
                        <p className="break-words">
                          <p className="text-green-500"> Last Name</p>
                          <small className="text-base">
                            {" "}
                            {reader.last_name}
                          </small>
                        </p>
                        <p className="break-words">
                          <p className="text-green-500"> Email</p>
                          <small className="text-base"> {reader.email}</small>
                        </p>
                        <p className="break-words">
                          <p className="text-green-500">Timezone</p>
                          <small className="text-base">
                            {" "}
                            {reader.timezone}
                          </small>
                        </p>
                        <p className="break-words">
                          <p className="text-green-500"> Username</p>
                          <small className="text-base">
                            {" "}
                            {reader.username}
                          </small>
                        </p>
                        <p className="break-words">
                          <p className="text-green-500"> User Handle</p>
                          <small className="text-base">
                            {" "}
                            {reader.handle_id}
                          </small>
                        </p>
                      </td>

                      <td class="border px-4 py-2 font-medium">
                        <div className="flex flex-col items-center gap-1">
                          {" "}
                          <div>
                            <a
                              href={`/admin/non-monetizers/content-purchases/${reader.uuid}`}
                            >
                              <BasicButton
                                classes={"bg-black"}
                                title={"Content Purchases"}
                                handleClick={null}
                              />
                            </a>
                            <p className="text-center">
                              {reader.my_content_purchase_count}
                            </p>
                          </div>
                          <div>
                            <a
                              href={`/admin/non-monetizers/product-purchases/${reader.uuid}`}
                            >
                              <BasicButton
                                classes={"my-bg-yellow-500 text-black"}
                                title={"Product Purchases"}
                                handleClick={null}
                              />
                            </a>
                            <p className="text-center">
                              {reader.my_product_purchase_count}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td class="border  px-4 py-2  font-medium">
                        <div className="flex flex-col items-center gap-1">
                          {" "}
                          <div>
                            {" "}
                            <a
                              href={`/admin/non-monetizers/content-subs/${reader.uuid}`}
                            >
                              <BasicButton
                                classes={"bg-black"}
                                title={"Content Subs"}
                                handleClick={null}
                              />
                            </a>
                            <p className="text-center">
                              {reader.my_author_sub_count}
                            </p>
                          </div>
                          <div>
                            {" "}
                            <a
                              href={`/admin/non-monetizers/content-subs/${reader.uuid}`}
                            >
                              <BasicButton
                                classes={"my-bg-yellow-500 text-black"}
                                title={"Product Subs"}
                                handleClick={null}
                              />
                            </a>
                            <p className="text-center">
                              {reader.my_product_sub_count}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td class="border  px-4 py-2  font-medium">
                        <a href={``}>
                          <BasicButton
                            classes={"bg-green-500 hover:bg-green-400 w-full"}
                            title={"Donations"}
                            handleClick={null}
                          />
                        </a>
                      </td>
                      <td class="border px-4 py-2  font-medium">
                        {reader.refactoredCreatedAtTime}
                      </td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
      </div>
    </>
  );
}
