import axios from "axios";
import { useEffect, useState } from "react";
import BasicButton from "../../../components/buttons/basic-button";
import TextField from "../../../components/inputs/text-input";
import Pagination from "../../../components/pagination";
import useDebounce from "../../../components/debounce/debounce-helper";
import LoadingProgress from "../rich-page/components/loading-progress";
import {
  isAdmin,
  API_BASE,
  isPublisher,
  isAuthUser,
  authUserData,
  getLocation,
  isReader,
} from "../../../Utils/helpers";
import { toast } from "react-toastify";
export default function PublisherMyAdsRecord() {
  const [records, setRecords] = useState([]);
  const [processing, setProcessing] = useState(false);

  const getRecords = () => {
    setProcessing(true);
    const url =
      API_BASE + "/api/publisher/my/advert-records/" + authUserData().uuid;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setProcessing(false);
          setRecords(response.data);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  useEffect(() => {
    if (!isPublisher()) {
      window.location.href = "/";
    }
    getRecords();
  }, []);
  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">My Ads Record</h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            <table class="table-auto w-full my-4">
              <thead>
                <tr className={window.innerWidth < 720 ? "hidden" : ""}>
                  <th class="px-4 py-2 text-gray-600">Today</th>
                  <th class="px-4 py-2 text-gray-600">Yesterday</th>
                  <th class="px-4 py-2 text-gray-600">Last 7 days</th>
                  <th class="px-4 py-2 text-gray-600">Last 30 Days</th>
                  <th class="px-4 py-2 text-gray-600">Last 365 Days</th>
                </tr>
              </thead>
              <tbody>
                {records.records &&
                records.records.ads_enabled == 1 &&
                !processing ? (
                  <tr
                    className={
                      window.innerWidth < 820
                        ? "flex flex-col items-center my-4"
                        : ""
                    }
                  >
                    <td class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden">Today</h3>
                      <p className="text-green-500">Earnings</p>
                      <span>{records.records.TE}</span>
                      <p className="my-text-yellow-500">Impressions</p>
                      <span>{records.records.TI}</span>
                    </td>
                    <td class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden">Yesterday</h3>
                      <p className="text-green-500">Earnings</p>
                      <span>{records.records.YE}</span>
                      <p className="my-text-yellow-500">Impressions</p>
                      <span>{records.records.YI}</span>
                    </td>
                    <td class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden">Last 7 days</h3>
                      <p className="text-green-500">Earnings</p>
                      <span>{records.records.L7E}</span>
                      <p className="my-text-yellow-500">Impressions</p>
                      <span>{records.records.L7I}</span>
                    </td>
                    <td class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden">Last 30 Days</h3>
                      <p className="text-green-500">Earnings</p>
                      <span>{records.records.L30E}</span>
                      <p className="my-text-yellow-500">Impressions</p>
                      <span>{records.records.L30I}</span>
                    </td>
                    <td class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden">Last 365 Days</h3>
                      <p className="text-green-500">Earnings</p>
                      <span>{records.records.L365E}</span>
                      <p className="my-text-yellow-500">Impressions</p>
                      <span>{records.records.L365I}</span>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
            <LoadingProgress loading={processing} />
          </div>
        </div>
      </div>
    </>
  );
}
