import { RichUtils } from "draft-js";
export default function InsertLinkButton(props) {
  const {
    addSpace,
    showLinkBox,
    editorState,
    selectionHasLink,
    selectionAnchors,
    setEditorOutput,
    setEditorState,
  } = props;
  return (
    <i
      class="icon_f7s0tmm _9bfc4d33-formatting-toolbar-iconMini cursor-pointer bg-gray-50 px-1 rounded-lg"
      data-disabled="false"
      data-hovered="false"
      data-active="false"
      data-role="icon"
      aria-hidden="true"
      onClick={(e) => {
        const currentBlockType = RichUtils.getCurrentBlockType(editorState);
        setEditorOutput(editorState.getSelection());

        if (
          selectionHasLink &&
          (currentBlockType === "ordered-list-item" ||
            currentBlockType === "unordered-list-item") &&
          selectionAnchors.start == 0
        ) {
          selectionHasLink ? addSpace(e) : showLinkBox();
        } else {
          {
            /* alert(selectionAnchors.start); */
          }
          selectionHasLink
            ? setEditorState(
                RichUtils.toggleLink(
                  editorState,
                  editorState.getSelection(),
                  null
                )
              )
            : showLinkBox();
        }
      }}
    >
      <svg width="50" viewBox="0 0 24 24" class="text-geay-50 text-lg">
        <rect stroke="none" fill="none" width="16" height="16" rx="4"></rect>
        <path
          d="M10.79289322 15.5355339c.19526214-.19526214.51184462-.19526216.70710678 0 .19526216.19526217.19526214.51184464 0 .70710679l-1.06066017 1.06066017c-1.17157288 1.17157287-3.07106785 1.17157284-4.24264069 0s-1.17157287-3.07106781 0-4.24264069l2.47487374-2.47487373c.97631074-.97631075 2.55922315-.97631075 3.5355339 0 .19526216.19526216.19526216.51184462 0 .70710678-.19526216.19526216-.51184462.19526216-.70710678 0-.58578642-.58578642-1.53553392-.58578642-2.12132034 0l-2.47487374 2.47487373c-.78104858.78104859-.78104858 2.04737854 0 2.82842713.78104859.78104858 2.04737854.78104858 2.82842713 0l1.06066017-1.06066017zm1.41421356-7.0710678c-.19526216.19526216-.51184464.19526214-.70710678 0-.19526214-.19526215-.19526216-.51184462 0-.70710679l1.06066017-1.06066017c1.17157284-1.17157284 3.07106785-1.17157284 4.24264069 0s1.17157284 3.07106785 0 4.24264069l-2.47487374 2.47487373c-.97631074.97631075-2.55922315.97631075-3.5355339 0-.19526216-.19526216-.19526216-.51184462 0-.70710678.19526216-.19526216.51184462-.19526216.70710678 0 .58578642.58578642 1.53553392.58578642 2.12132034 0l2.47487374-2.47487373c.78104858-.78104859.78104858-2.04737854 0-2.82842713-.78104859-.78104858-2.04737854-.78104858-2.82842713 0L12.20710678 8.4644661z"
          stroke="none"
        ></path>
      </svg>
    </i>
  );
}
