import BasicButton from "../../../../components/buttons/basic-button";
import TextField from "../../../../components/inputs/text-input";
import Pagination from "../../../../components/pagination";
import useDebounce from "../../../../components/debounce/debounce-helper";
import LoadingProgress from "../../../publisher/rich-page/components/loading-progress";
import authRequestService from "../../../../services/HttpRequests/authenticated-request-services";
import authPaginatedRequestService from "../../../../services/HttpRequests/authenticated-paginated-req-service";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import {
  API_BASE,
  BASE_URL,
  authUserData,
  isAdmin,
} from "../../../../Utils/helpers";

export default function AdminReaderMySubscriptions() {
  const [user, setUser] = useState();
  const [subscriptions, setSubscriptions] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [countPerPage, setCountPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { uuid } = useParams();

  const getUser = () => {
    let url = API_BASE + "/publisher/" + uuid + "/optimized";
    return authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setUser(response.data.publisher);
        }
      })
      .catch((error) => {
        // dddgdgd
      });
  };

  const getUserSubscriptions = () => {
    setSubscriptions([]);
    setProcessing(true);
    const url =
      API_BASE +
      "/admin/non-monetizer/my/subscriptions/" +
      uuid +
      "/" +
      countPerPage +
      "/" +
      searchText;
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setProcessing(false);
          setSubscriptions(response.data.subscriptions);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const doPagination = (page) => {
    if (subscriptions.first_page_url) {
      setCurrentPage(page);
      axios
        .get(subscriptions.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          setSubscriptions(res.data.subscriptions);
        })
        .catch((err) => console.log(err));
    }
  };

  const cancelSubscription = (subId) => {
    setProcessing(true);
    const url =
      API_BASE +
      "/api/user/" +
      authUserData().uuid +
      "/subscriptions/" +
      subId +
      "/cancel";
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setTimeout(() => {
            setSubscriptions([]);
            getUserSubscriptions();
            setProcessing(false);
          }, 6000);
        }
      })
      .catch((error) => {
        console.log("There was an error!", error);
        setProcessing(false);
      });
  };

  function capitalizeFirstLetter(str) {
    return str[0].toUpperCase() + str.slice(1);
  }

  const handleSearchTextChange = (e) => {
    setSearchText(e);
  };

  const handleCountPerPageChange = (e) => {
    setCountPerPage(e.target.value);
  };

  useDebounce(
    () => {
      getUserSubscriptions();
    },
    [searchText, countPerPage],
    800
  );

  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
    getUser();
  }, []);

  return (
    <>
      <div className="main flex flex-col">
        <div className="border border-b-gray border-t-white border-r-white border-l-white m-2 p-2 flex justify-between rounded-lg flex-1 ">
          <div>
            <h1 className="text-2xl text-center m-2 bold">
              Content Subs:{" "}
              <span className="text-yellow-600 font-bold">
                {user?.handle_id}
              </span>{" "}
            </h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="gap-4 p-2 w-full">
          <div className="flex justify-center gap-4 p-2 flex-col items-center">
            <p className="w-full text-center my-4">
              We found {subscriptions.total ? subscriptions.total : ""} records
            </p>
            <div className="flex flex--row w-full gap-4">
              <div className="w-11/12">
                <TextField
                  title="Search"
                  placeholder={"Search"}
                  classes="rounded-lg"
                  handleChange={handleSearchTextChange}
                  value={searchText}
                />
              </div>

              <div className="w-1/12">
                <label for="timezone" value="Your Timezone" />
                <select
                  onChange={handleCountPerPageChange}
                  required
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full"
                >
                  <option value="10" default>
                    10
                  </option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <div className="flex justify-center">
              {" "}
              {subscriptions.data ? (
                subscriptions.data.length ? (
                  <Pagination
                    pagination={subscriptions}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            <table class="table-auto w-full">
              <thead>
                <tr className={window.innerWidth < 720 ? "hidden" : ""}>
                  <th class="px-4 py-2 text-gray-600">Publisher</th>
                  <th class="px-4 py-2 text-gray-600">Transaction ID</th>
                  <th class="px-4 py-2">Status</th>
                  <th class="px-4 py-2">Duration</th>
                  <th class="px-4 py-2">Date</th>
                  <th class="px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.data
                  ? subscriptions.data.length
                    ? subscriptions.data.map((subscription) => {
                        return (
                          <tr
                            className={
                              window.innerWidth < 820
                                ? "flex flex-col items-center"
                                : ""
                            }
                          >
                            <td class="border border-gray px-4 py-2  font-medium text-center md:text-left">
                              <div className="flex flex-col justify-center items-center">
                                <h3 className="font-bold md:hidden">
                                  Publisher
                                </h3>
                                {subscription.publisher}
                                <p>
                                  <a
                                    href={`/web/${subscription.UserUUID}`}
                                    className="underline"
                                  >
                                    <p className="my-2">
                                      <span className="text-xs">
                                        {" "}
                                        user handle:{" "}
                                      </span>
                                      <span className="text-yellow-600 text-xs font-bold">
                                        {subscription.UserHandle}
                                      </span>
                                    </p>
                                  </a>
                                </p>
                              </div>
                            </td>

                            <td class="border border-gray px-4 py-2  font-medium text-center md:text-left">
                              <h3 className="font-bold md:hidden">
                                Transaction ID
                              </h3>
                              {subscription.transaction_id}
                            </td>

                            <td class="border border-gray px-4 py-2  font-medium text-center md:text-left">
                              <div className="flex flex-col justify-center items-center">
                                <h3 className="font-bold md:hidden">Status</h3>
                                {capitalizeFirstLetter(subscription.status)}
                              </div>
                            </td>
                            <td class="border border-gray px-4 py-2 font-medium text-center md:text-left">
                              <h3 className="font-bold md:hidden ">Duration</h3>
                              <div className="flex flex-col items-center gap-4">
                                {" "}
                                <p>
                                  <b>Starts: </b>
                                  {subscription.start_date
                                    ? new Date(
                                        subscription.start_date.replace(
                                          /-/g,
                                          "/"
                                        )
                                      ).getDate() +
                                      "-" +
                                      (new Date(
                                        subscription.start_date.replace(
                                          /-/g,
                                          "/"
                                        )
                                      ).getMonth() +
                                        1) +
                                      "-" +
                                      new Date(
                                        subscription.start_date.replace(
                                          /-/g,
                                          "/"
                                        )
                                      ).getFullYear()
                                    : "N/A"}
                                </p>
                                <p>
                                  <b>Ends: </b>
                                  {subscription.end_date
                                    ? new Date(
                                        subscription.end_date.replace(/-/g, "/")
                                      ).getDate() +
                                      "-" +
                                      (new Date(
                                        subscription.end_date.replace(/-/g, "/")
                                      ).getMonth() +
                                        1) +
                                      "-" +
                                      new Date(
                                        subscription.end_date.replace(/-/g, "/")
                                      ).getFullYear()
                                    : "N/A"}
                                </p>
                              </div>
                            </td>

                            <td class="border border-gray px-4 py-2  font-medium text-center md:text-left">
                              <div className="flex justify-center">
                                {" "}
                                <h3 className="font-bold md:hidden">Date</h3>
                                {subscription.created_at
                                  ? new Date(
                                      subscription.created_at.replace(/-/g, "/")
                                    ).getDate() +
                                    "-" +
                                    (new Date(
                                      subscription.created_at.replace(/-/g, "/")
                                    ).getMonth() +
                                      1) +
                                    "-" +
                                    new Date(
                                      subscription.created_at.replace(/-/g, "/")
                                    ).getFullYear()
                                  : "N/A"}
                              </div>
                            </td>
                            <td class="border border-gray px-4 py-2 font-medium text-center md:text-left">
                              <div className="flex flex-col justify-center items-center">
                                {/* <h3 className="font-bold md:hidden">Action</h3> */}
                                {/* <BasicButton
                                  disabled={
                                    subscription.status != "active" ||
                                    processing
                                  }
                                  title={
                                    processing
                                      ? "Cancelling.."
                                      : subscription.status == "active"
                                      ? "Cancel Subscription"
                                      : "Cancelled"
                                  }
                                  handleClick={() =>
                                    cancelSubscription(
                                      subscription.subscription_id
                                    )
                                  }
                                /> */}
                                N/A
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : ""
                  : ""}
              </tbody>
            </table>
            <LoadingProgress loading={processing} />
            <div className="flex justify-center">
              {" "}
              {subscriptions.data ? (
                subscriptions.data.length ? (
                  <Pagination
                    pagination={subscriptions}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {!user ||
          (subscriptions < 1 && (
            <div
              className="w-full  flex justify-center  md:h-96 items-center flex-1"
              style={{ minHeigt: "100%" }}
            >
              <p className="text-white">We found 0 subscriptions</p>
            </div>
          ))}
      </div>
    </>
  );
}
