export default function InsertVideo(props) {
  return (
    <button
      className="border border-gray rounded-lg p-1 m-1"
      onClick={() =>
        document.getElementById("draftjs_editor_input_video_file").click()
      }
    >
      <label class="btn cursor-pointer">Select Video</label>
      <input
        title="Add Video"
        type="file"
        id="draftjs_editor_input_video_file"
        onChange={(e) => props.previewVideo(e)}
        className="text-sm 
                        file:mr-2
                        file:py-2
                        file:px-1
                        file:rounded-full
                        file:border
                        file:border-none
                        file:bg-none
                        file:text-md
                        file:text-black
                        hover:file:cursor-pointer
                        z-45
                        hidden
                       "
      />
    </button>
  );
}
