import { ACTION_TYPES } from "../editor-objects";
import { StyleButton } from "../helpers/functions";
export default function PremiumButton(props) {
  return (
    <>
      {" "}
      {ACTION_TYPES.map((type) => (
        <button
          className="border border-gray rounded-lg p-1 m-1 text-xxs"
          onClick={() => props.onToggle(type.style)}
        >
          <StyleButton active={true} label={type.label} style={type.style} />
        </button>
      ))}
    </>
  );
}
