import { useState, useEffect } from "react";
export default function ProductContract(props) {
  const {
    productMeta,
    handleMetaChange,
    productContracts,
    productDefaultContracts,
  } = props;

  const [mergedContracts, setMergedContracts] = useState([]);

  const contractTypes = [
    "subscription-contract",
    "hire-contract",
    "property-rental-contract",
    "item-rental-contract",
    "vacation-rental-contract",
  ];

  const getContractTypeFromPurchaseType = () => {
    if (isWordPresent(productMeta?.p_type_sub_opt, "Subscription")) {
      return "Subscription";
    }
    if (isWordPresent(productMeta?.p_type_sub_opt, "Hire")) {
      return "Hire";
    }
    if (productMeta?.p_type_sub_opt?.includes("Property Rental")) {
      return "Property Rental";
    }
    if (productMeta?.p_type_sub_opt?.includes("Item Rental")) {
      return "Item Rental";
    }

    if (productMeta?.p_type_sub_opt?.includes("Vacation Rental")) {
      return "Vacation Rental";
    }

    return "";
  };

  function isWordPresent(str, word) {
    const regex = new RegExp("\\b" + word + "\\b(?!al)", "g");
    return regex.test(str);
  }
  let titleCase = (str) =>
    str
      .toLowerCase()
      .split("-")
      .map((c) => c.charAt(0).toUpperCase() + c.substring(1))
      .join(" ");

  useEffect(() => {
    const mergedRay = [];
    if (productDefaultContracts.length || productContracts.length) {
      for (let index = 0; index < productDefaultContracts.length; index++) {
        let c = productDefaultContracts[index];
        let name = titleCase(c.type);
        if (
          name.includes(getContractTypeFromPurchaseType()) &&
          c.sub_category == "recurring"
        ) {
          mergedRay.push({
            value: c.type,
            name: "Default " + name,
            id: c.id,
          });
        }
      }

      for (let index = 0; index < productContracts.length; index++) {
        let c = productContracts[index];
        mergedRay.push({
          value: c.id,
          name: c.name,
          id: c.id,
        });
      }
    }

    setMergedContracts(mergedRay);
    //console.log("P META", productMeta);
  }, [productDefaultContracts, productContracts, productMeta.p_type_sub_opt]);

  const derivedContractValue = () => {
    if (productMeta?.contract_type == "default") {
      return productMeta?.default_contract_name;
    } else {
      return productMeta.contract_id;
    }
  };

  return (
    <div className="w-full md:w-6/12 my-5">
      <label className="font-bold">Select Contract</label>
      <div className="flex gap-2">
        <select
          value={derivedContractValue()}
          onChange={(e) => {
            const sel = e.target.value;
            if (contractTypes.includes(sel)) {
              handleMetaChange("default", "contract_type", "text");
              handleMetaChange(e.target.value, "default_contract_name", "text");
              handleMetaChange("", "contract_id", "dropdown");
            } else {
              handleMetaChange("uploaded", "contract_type", "text");
              handleMetaChange("", "default_contract_name", "text");
              handleMetaChange(e, "contract_id", "dropdown");
            }
          }}
          required
          class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
        >
          <option value="">Not Selected</option>

          {mergedContracts.length &&
            mergedContracts.map((c) => (
              <option value={c.value}>{c.name}</option>
            ))}
        </select>
        {!productMeta.contract_id && !productMeta?.default_contract_name && (
          <span className="text-red-500 text-2xl">*</span>
        )}
      </div>
    </div>
  );
}
