import { useNavigate } from "react-router-dom";
import TextField from "../../../../../components/inputs/text-input";
import { REOptions } from "../../../../publisher/rich-page/product/components/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isResponsiveMode } from "../../../../../Utils/helpers";
import { useRef, useState } from "react";
import BasicButton from "../../../../../components/buttons/basic-button";
import Checkbox from "../../../../../components/inputs/checkbox";

export default function FilterBar(props) {
  const { handleCountPerPageChange } = props;
  const { handleSearchTextChange } = props;
  const { serchQValue } = props;
  const { showSendButton } = props;
  const { handleLatestEntriesChange } = props;
  const { latestQValue } = props;
  const { handleOldestEntriesChange } = props;
  const { oldestQValue } = props;

  const { handleEndDateChange } = props;
  const { endDate } = props;
  const { handleStartDateChange } = props;
  const { startDate } = props;

  const { handleSubStatusChange } = props;
  const { subStatus } = props;

  const { handlePurchaseOptionChange } = props;
  const { purchaseOption } = props;

  const { markAllItems } = props;
  const { clearMarkedItems } = props;
  const { selectAllFlag } = props;

  const { clearFilter } = props;
  const { model } = props;
  const navigate = useNavigate();
  const pickeRrefStartDate = useRef();
  const pickeRrefEndDate = useRef();
  const [closeStartCalOnSelct, setCloseStartCalOnSelect] = useState(false);
  const [closeEndCalOnSelct, setCloseEndCalOnSelect] = useState(false);

  return (
    <>
      {" "}
      <div className="flex md:flex-row md:justify-between w-full gap-4 justify-center">
        <div className="w-full">
          <TextField
            title="Search"
            placeholder={"Search"}
            classes="rounded-lg w-full"
            handleChange={handleSearchTextChange}
            value={serchQValue}
          />
        </div>

        <div className="flex items-center">
          <label for="timezone" value="Your Timezone" />
          <select
            onChange={handleCountPerPageChange}
            required
            class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg cursor-pointer"
          >
            <option value="10" default>
              10
            </option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
      <div className="flex justify-center md:justify-center w-full gap-2 md:mr-4 my-2 items-center">
        <div className="flex flex-col md:flex-row gap-2 w-full">
          <div className="flex flex-col md:flex-row justify-around gap-2 w-full">
            {" "}
            <div className="flex flex-col gap-4">
              {" "}
              <div className="flex flex-col items-center">
                <label for="timezone" value="Your Timezone">
                  {" "}
                  Latest Entries
                </label>
                <select
                  value={latestQValue}
                  onChange={handleLatestEntriesChange}
                  required
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
                >
                  <option value="normal_1000000" defaultValue disabled>
                    Not selected
                  </option>
                  <option value="latest_100">Latest 100</option>
                  <option value="latest_500">Latest 500</option>
                  <option value="latest_1000">Latest 1,000</option>
                  <option value="latest_5000">Latest 5,000</option>
                  <option value="latest_10000">Latest 10,000</option>
                </select>
              </div>
              <div className="flex flex-col items-center">
                <label for="timezone" value="Your Timezone">
                  Oldest Entries
                </label>
                <select
                  value={oldestQValue}
                  onChange={handleOldestEntriesChange}
                  required
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
                >
                  <option value="normal_1000000" defaultValue>
                    Not selected
                  </option>
                  <option value="oldest_100">Oldest 100</option>
                  <option value="oldest_500">Oldest 500</option>
                  <option value="oldest_1000">Oldest 1,000</option>
                  <option value="oldest_5000">Oldest 5,000</option>
                  <option value="oldest_10000">Oldest 10,000</option>
                </select>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col items-center">
                <label for="timezone" value="Your Timezone">
                  Subscription Status
                </label>
                <select
                  value={subStatus}
                  onChange={handleSubStatusChange}
                  required
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
                >
                  <option value="*" defaultValue disabled>
                    Not selected
                  </option>
                  <option value="active">Active</option>
                  <option value="active_non_renewal">Active Non-renewal</option>
                  <option value="expired"> Expired</option>
                  <option value="pending">Pending</option>
                  <option value="cancelled">Canceled</option>
                </select>
              </div>
              <div className="flex flex-col items-center">
                <label for="timezone" value="Your Timezone">
                  Purchase Option
                </label>
                <select
                  value={purchaseOption}
                  onChange={handlePurchaseOptionChange}
                  required
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
                >
                  <option value="*" defaultValue disabled>
                    Not selected
                  </option>
                  {REOptions.map((b) => (
                    <option value={b.label}>{b.label}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              {" "}
              <div className="flex flex-col items-center">
                <label for="timezone" value="Your Timezone">
                  Start Date
                </label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  wrapperClassName={isResponsiveMode() ? "w-full" : ""}
                  ref={pickeRrefStartDate}
                  shouldCloseOnSelect={closeStartCalOnSelct}
                  selected={startDate}
                  disabled={false}
                  onChange={(date, e) => {
                    pickeRrefStartDate.current.setOpen(false);
                    handleStartDateChange(date);
                  }}
                  className="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer border"
                />
              </div>
              <div className="flex flex-col items-center">
                <label for="timezone" value="Your Timezone">
                  End Date
                </label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  wrapperClassName={isResponsiveMode() ? "w-full" : ""}
                  ref={pickeRrefEndDate}
                  shouldCloseOnSelect={closeEndCalOnSelct}
                  selected={endDate}
                  disabled={!startDate}
                  minDate={startDate || ""}
                  onChange={(date, e) => {
                    pickeRrefEndDate.current.setOpen(false);
                    handleEndDateChange(date);
                  }}
                  className="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer border"
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center gap-2">
              <div className="flex flex-col items-center gap-2">
                <BasicButton
                  title={"Clear Filter"}
                  handleClick={() => clearFilter()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
