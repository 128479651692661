import { useEffect, useState } from "react";
import BasicButton from "../../../../../components/buttons/basic-button";
import { isAdmin } from "../../../../../Utils/helpers";

export default function AdminSEDefaultContractsOpt() {
  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
  }, []);

  return (
    <>
      <div className="main w-full">
        <div className="bg-white m-2 p-2 flex justify-between shadow px-4">
          <div>
            <h1 className="text-2xl text-center m-2 bold">
              {" "}
              Default Contracts (Single Purchase)
            </h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex justify-center my-4 overflow-hidden">
          <div class="flex flex-col items-center p-10 gap-4 md:w-6/12">
            <div className="w-full">
              <a href="/admin/supplier/product-contracts/single/sp-contract">
                <BasicButton
                  title={"Default Contract"}
                  handleClick={() => window.history.back()}
                  classes={"w-full"}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
