import BasicButton from "../../../../../components/buttons/basic-button";
export default function BlockAction({
  enableBlockButton,
  restoreEmails,
  isBlocked,
  processing,
  isInvalidLink,
}) {
  return (
    isBlocked &&
    !isInvalidLink && (
      <div className="flex flex-col md:flex-row gap-2">
        {" "}
        <BasicButton
          disabled={!enableBlockButton() || processing}
          title={processing ? "Processing.." : "Yes, restore emails"}
          classes={"p-6 w-full md:w-9/12 md:mt-4 bg-red-600 hover:bg-red-500"}
          handleClick={() => restoreEmails()}
        />
        <BasicButton
          title={"No, cancel"}
          classes={"p-6 w-full md:w-9/12 md:mt-4  bg-black"}
        />
      </div>
    )
  );
}
