import axios from "axios";
import { useEffect, useState } from "react";
import BasicButton from "../../components/buttons/basic-button";
import { isAdmin, API_BASE } from "../../Utils/helpers";
export default function AllReports() {
  const [reports, setReports] = useState([
    {
      totalMatchedRows: "1",
      headers: [
        {
          name: "AD_UNIT_ID",
          type: "DIMENSION",
        },
        {
          name: "AD_UNIT_NAME",
          type: "DIMENSION",
        },
        {
          name: "IMPRESSIONS",
          type: "METRIC_TALLY",
        },
        {
          name: "CLICKS",
          type: "METRIC_TALLY",
        },
        {
          name: "TOTAL_EARNINGS",
          type: "METRIC_CURRENCY",
          currencyCode: "USD",
        },
      ],
      rows: [
        {
          cells: [
            {
              value: "ca-pub-5989530552656078:1073369632",
            },
            {
              value: "Bad Omens Mobile",
            },
            {
              value: "11",
            },
            {
              value: "4",
            },
            {
              value: "0.00",
            },
          ],
        },
      ],
      totals: {
        cells: [
          {},
          {},
          {
            value: "11",
          },
          {
            value: "4",
          },
          {
            value: "0.00",
          },
        ],
      },
      averages: {
        cells: [
          {},
          {},
          {
            value: "11",
          },
          {
            value: "4",
          },
          {
            value: "0.00",
          },
        ],
      },
      warnings: [
        "Some of the requested metrics are not available for some of the ad clients used by this report.",
      ],
      startDate: {
        year: 2022,
        month: 10,
        day: 27,
      },
      endDate: {
        year: 2022,
        month: 10,
        day: 27,
      },
    },
  ]);
  const url = "";
  const getPublishers = () => {
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          console.log("Adsense Data", response.data);
        }
      })
      .catch((error) => {
        alert(error.message);
        console.error("There was an error!", error);
      });
  };

  useEffect(() => {
    // getPublishers();

    console.log("Reports", reports[0]["headers"]);
    console.log("Adsence Report", JSON.parse(JSON.stringify(reports)));
    if (!isAdmin()) {
      window.location.href = "/";
    }
  }, []);
  return (
    <>
      <div className="main w-full">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">Reports</h1>
          </div>
          <div>
            {/* <a href="/publisher/create-publisher">
              <BasicButton title={"Create Publisher"} handleClick={null} />
            </a> */}
          </div>
        </div>
        <div className="flex justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            <table class="table-auto w-full">
              <thead>
                <tr className={window.innerWidth < 720 ? "hidden" : ""}>
                  {/* <th class="px-4 py-2 text-gray-600">Estimated earnings</th>
                  <th class="px-4 py-2 text-gray-600">page view</th>
                  <th class="px-4 py-2 text-gray-600">Page RPM</th>
                  <th class="px-4 py-2 text-gray-600"> impression</th>
                  <th class="px-4 py-2 text-gray-600">Impression RPM</th>
                  <th class="px-4 py-2 text-gray-600">Clicks</th> */}
                  {reports[0]["headers"].map((header, i) => (
                    <th class="px-4 py-2 text-gray-600">{header.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {reports[0]["rows"].map((row) => {
                  return (
                    <tr
                      className={window.innerWidth < 820 ? "flex flex-col" : ""}
                    >
                      {row["cells"].map((data, i) => (
                        <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                          <h3 className="font-bold md:hidden">
                            {reports[0]["headers"][i].name}
                          </h3>
                          <span className="break-words">{data.value}</span>
                          {/* {publisher.name} */}
                        </td>
                      ))}
                      {/* <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <h3 className="font-bold md:hidden">Name</h3>
                        {publisher.name}
                      </td> */}
                      {/* <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <h3 className="font-bold md:hidden">Email</h3>
                        <span className="break-words"> {publisher.email}</span>
                      </td>

                      <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <a href={`/publisher/create-series/${publisher.uuid}`}>
                          <BasicButton
                            classes={"bg-teal-500 hover:bg-teal- w-full"}
                            title={"Create Series"}
                            handleClick={null}
                          />
                        </a>
                      </td>
                      <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <a href={`/publisher/create-book/${publisher.uuid}/`}>
                          <BasicButton
                            classes={"bg-green-500 hover:bg-green-400 w-full"}
                            title={"Create Book"}
                            handleClick={null}
                          />
                        </a>
                      </td>
                      <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <a
                          href={`/publisher/publications/options/${publisher.uuid}`}
                        >
                          <BasicButton
                            classes={"bg-indigo-500 hover:bg-indigo-400 w-full"}
                            title={"Plublications"}
                            handleClick={null}
                          />
                        </a>
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
