export default function InsertImage(props) {
  const { handleImageUpload } = props;

  const upload = (e) => {
    let files = e.target.files;
    if (files.length) {
      handleImageUpload(files);
    }
  };
  return (
    <button
      className="border border-gray rounded-lg p-1 m-1"
      onClick={() =>
        document.getElementById("draftjs_editor_input_file").click()
      }
    >
      <label class="btn cursor-pointer">Select Image(s)</label>
      <input
        multiple
        title="Insert Image"
        type="file"
        id="draftjs_editor_input_file"
        onChange={(e) => upload(e)}
        className="text-sm 
                        file:mr-2
                        file:py-2
                        file:px-1
                        file:rounded-full
                        file:border
                        file:border-none
                        file:bg-none
                        file:text-md
                        file:text-black
                        hover:file:cursor-pointer
                        z-45
                        hidden
                       "
      />
    </button>
  );
}
