import { useSupplierApplicationsStore } from "../../../../../../stores/supplier-applications-store";
import BasicButton from "../../../../../../components/buttons/basic-button";
import { useNavigate } from "react-router-dom";

export default function MobileTable(props) {
  const navigate = useNavigate();

  const { openReplaceContractModal } = props;

  const mailStore = useSupplierApplicationsStore((state) => state);

  return (
    <div className="w-full flex flex-col justify-center my-4 text-center">
      <div
        className="overflow-x-scroll inline"
        style={{
          width: document.documentElement.clientWidth - 90 + "px",
          margin: "0 auto",
        }}
      >
        {mailStore.data.data &&
          mailStore.data.data.map((c) => {
            return (
              <div className="flex">
                <div class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Page Name</h3>
                  {c?.book_data?.name}
                </div>
                <div class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Button</h3>
                  {c?.payload ? JSON.parse(c.payload).p_type_sub_opt : ""}
                </div>
                <div class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Any Transaction</h3>
                  {c?.hasactivesub || c?.hasactivepayment ? "Yes" : "No"}
                </div>
                <div class="border  px-4 py-2  font-medium text-center">
                  <h3 className="font-bold md:hidden">Replace Contract</h3>
                  <BasicButton
                    disabled={!c?.id}
                    title={"Replace Contract for All"}
                    classes={
                      "my-bg-yellow-500 text-black hover:text-black  hover:bg-yellow-500"
                    }
                    handleClick={() => openReplaceContractModal()}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
