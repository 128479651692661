export default function BlockSuccess({ data, isBlocked }) {
  return (
    data?.publisher &&
    !isBlocked && (
      <p>
        {" "}
        <b className="font-bold">{data.subscriber_name}</b>, your request has
        been processed successfully.{" "}
        <b className="font-bold">{data.publisher.name}</b>, user handle:
        <b className="font-bold my-text-yellow-500">
          {data.publisher.handle_id}
        </b>{" "}
        , is now able to send update emails to you.
      </p>
    )
  );
}
