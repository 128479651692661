import moment from "moment";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

function CalendarDays(props) {
  const [clickedDateIndex, setClickedDateIndex] = useState("");
  const firstDayOfMonth = new Date(
    props.day.getFullYear(),
    props.day.getMonth(),
    1
  );
  // console.log("mth", props.day.getMonth());
  const weekdayOfFirstDay = firstDayOfMonth.getDay();
  let currentDays = [];

  for (let day = 0; day < 42; day++) {
    if (day === 0 && weekdayOfFirstDay === 0) {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 7);
    } else if (day === 0) {
      firstDayOfMonth.setDate(
        firstDayOfMonth.getDate() + (day - weekdayOfFirstDay)
      );
    } else {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
    }

    let calendarDay = {
      currentMonth: firstDayOfMonth.getMonth() === props.day.getMonth(),
      date: new Date(firstDayOfMonth),
      month: firstDayOfMonth.getMonth(),
      number: firstDayOfMonth.getDate(),
      selected: firstDayOfMonth.toDateString() === props.day.toDateString(),
      year: firstDayOfMonth.getFullYear(),
    };

    currentDays.push(calendarDay);
  }

  const correcDateFormat = (d) => {
    const day = d.getUTCDate();
    const month = d.getUTCMonth() + 1; // Months are zero-based, so add 1
    const year = d.getUTCFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    //console.log(formattedDate);
    return formattedDate;
  };

  const addDateColorClasses = (date) => {
    const dateToCheck = moment(date.date).format("YYYY-MM-DD");
    const todayDate = moment().format("YYYY-MM-DD");

    if (dateToCheck < todayDate) {
      return "text-gray-500 my-text-gray-500 font-bold";
    } else {
      return "text-green-500 my-text-green-500 font-bold";
    }
  };

  const convertToUTC = (date) => {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  };

  const doSearch = (date, i) => {
    const dateToCheck = moment(date.date).format("YYYY-MM-DD");
    const todayDate = moment().format("YYYY-MM-DD");

    if (dateToCheck < todayDate) {
      return;
    } else {
      setClickedDateIndex(i);
      props?.parentProps?.setSelected(
        correcDateFormat(convertToUTC(date.date))
      );
    }
  };

  useEffect(() => {}, [props?.parentProps?.state]);
  useEffect(() => {
    props?.parentProps?.setSelected();
  }, [props.day]);

  const startDate = props?.parentProps?.selDate;

  return (
    <div className="grid grid-cols-7 bg-white w-full">
      {currentDays.map((day, index) => {
        return (
          <div
            key={index}
            className={
              "p-1 calendar-day cursor-pointer flex flex-col items-center justify-center"
            }
            onClick={() => {
              doSearch(day, index);
            }}
          >
            <div
              className={`w-12 md:w-2 h-12 md:h-2 p-3 rounded-full ${
                startDate
                  ? index == clickedDateIndex
                    ? "border-green-500  border-2"
                    : ""
                  : ""
              }  flex justify-center items-center`}
            >
              <p
                className={
                  true
                    ? ` current bold text-gray-300 font-bold ${addDateColorClasses(
                        day
                      )}`
                    : "text-gray-100"
                }
              >
                {day.number}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default CalendarDays;
