import axios from "axios";
import { useEffect, useState } from "react";
import BasicButton from "../../../components/buttons/basic-button";
import {
  isSales,
  isAdmin,
  API_BASE,
  isAuthUser,
  isResponsiveMode,
} from "../../../Utils/helpers";
import useDebounce from "../../../components/debounce/debounce-helper";
import TextField from "../../../components/inputs/text-input";
import Pagination from "../../../components/pagination";
import LoadingProgress from "../../publisher/rich-page/components/loading-progress";
import AdminMobileTable from "./components/mobile-table";
import AdminDesktopTable from "./components/desktop-table";
import { toast } from "react-toastify";
import PubPurchases from "../../publisher/purchase";
import Publishers from "../../publisher";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";
export default function AdminBannedPublishers() {
  const [publishers, setPublishers] = useState({});
  const [searchText, setSearchText] = useState("");
  const [countPerPage, setCountPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [processing, setProcessing] = useState(false);
  const url =
    API_BASE + "/admin/publishers/banned/" + countPerPage + "/" + searchText;

  const getPublishers = () => {
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setPublishers(response.data.publishers);
        }
        setProcessing(false);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const doPagination = (page) => {
    setProcessing(true);
    if (publishers.first_page_url) {
      setCurrentPage(page);
      axios
        .get(publishers.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          setPublishers(res.data.publishers);
          setProcessing(false);
        })
        .catch((err) => {
          setProcessing(false);
          toast("Something went wrong", { type: "error" });
        });
    }
  };

  useEffect(() => {
    getPublishers();
    if (!isAdmin() && !isSales()) {
      window.location.href = "/";
    }
  }, []);

  const handleSearchTextChange = (e) => {
    setSearchText(e);
  };

  const handleCountPerPageChange = (e) => {
    setCountPerPage(e.target.value);
  };

  const adsEnabled = (p) => {
    const data = { provider: "", enabled: "" };
    if (!p.advertrequest) return { provider: "", enabled: "" };
    if (p.advertrequest.status == "approved") {
      data.provider = p.advertrequest.provider;
      data.enabled = "Yes";
    }

    if (p.advertrequest.status == "unprocessed") {
      data.provider = "Unprocessed";
      data.enabled = "Yes";
    }

    return data;
  };

  const dataSubType = (p) => {
    if (!p.activedatasub) return "Free Tier";
    return p.activedatasub?.datathreshold?.name ?? "";
  };

  const totalEstAdsEarnings = (p) => {
    let revenue = 0;
    if (!p.advertrequest) return 0;

    if (p.advertrequest.total_estimated_earnings) {
      revenue += Number(p.advertrequest.total_estimated_earnings);
    }
    if (p.advertrequest.total_estimated_earnings_2) {
      revenue += Number(p.advertrequest.total_estimated_earnings_2);
    }

    return revenue;
  };

  const f = {
    adsEnabled: adsEnabled,
    dataSubType: dataSubType,
    totalEstAdsEarnings: totalEstAdsEarnings,
  };

  useDebounce(
    () => {
      setPublishers({});
      getPublishers();
    },
    [searchText, countPerPage],
    800
  );

  return (
    <>
      <div className="main w-full">
        <div className="bg-white m-2 p-2 flex justify-between shadow px-4">
          <div>
            <h1 className="text-2xl text-center m-2 bold">Publishers</h1>
          </div>
          <div>
            <a href="/admin/publisher/create-publisher">
              <BasicButton title={"Create Publisher"} handleClick={null} />
            </a>
          </div>
        </div>
        <div className="flex justify-center my-4 overflow-hidden">
          <div class="flex flex-col items-center p-10 w-full">
            <p className="w-full text-center my-4">
              We found {publishers.total ? publishers.total : ""} records
            </p>
            <div className="flex w-full gap-4">
              <div className="w-11/12">
                <TextField
                  title="Search"
                  placeholder={"Search"}
                  classes="rounded-lg"
                  handleChange={handleSearchTextChange}
                  value={searchText}
                />
              </div>

              <div className="w-1/12">
                <label for="timezone" value="Your Timezone" />
                <select
                  onChange={handleCountPerPageChange}
                  required
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full text-black"
                >
                  <option value="10" default>
                    10
                  </option>
                  {/* <option value="2">2</option> */}
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <div className="flex justify-center">
              {publishers.data ? (
                publishers.data.length ? (
                  <Pagination
                    pagination={publishers}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>

            {isResponsiveMode() ? (
              <AdminMobileTable data={publishers} f={f} />
            ) : (
              <AdminDesktopTable data={publishers} f={f} />
            )}

            <LoadingProgress loading={processing} />

            <div className="flex justify-center">
              {publishers.data ? (
                publishers.data.length ? (
                  <Pagination
                    pagination={publishers}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
