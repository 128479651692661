import {
  isAuthUser,
  authUserData,
  isAdmin,
  isPublisher,
  isReader,
  isResponsiveMode,
} from "../../../../Utils/helpers";
import BasicButton from "../../../../components/buttons/basic-button";
import { useNavigate, useLocation } from "react-router-dom";

export default function PublisherTutorialBar(props) {
  const location = useLocation();
  const { classes } = props.classes;

  //component is referenced in update-rich-page, create-rich-page, left-nav, create slide, edit slide

  const isSlideBackendPage = () => {
    return (
      location.pathname.includes("publications/series/m/book/") ||
      location.pathname.includes("create/options/photo-album/") ||
      location.pathname.includes("create/options/book/") ||
      location.pathname.includes("create/options/comic/")
    );
  };

  const isCustomBackendPage = () => {
    return (
      location.pathname.includes("publisher/rich-page/create/") ||
      location.pathname.includes("publisher/rich-page/update/")
    );
  };

  const renderForSlidePage = () =>
    isAuthUser() && !isAdmin() && isPublisher() && isSlideBackendPage();

  const renderForCustomPage = () =>
    isAuthUser() && !isAdmin() && isPublisher() && isCustomBackendPage();

  const showTutorial = () => {
    if (renderForSlidePage()) {
      return (
        <div className={classes}>
          <a
            href="https://litzapp.com/web/rich-page/render/f353c1ae-def1-41f6-8be9-f8959012086e"
            target="_blank"
          >
            <BasicButton
              title={"Tutorials"}
              classes={`${props.btClass} my-bg-yellow-500 text-black`}
            />
          </a>
        </div>
      );
    } else if (renderForCustomPage()) {
      return (
        renderForCustomPage() && (
          <div className={classes}>
            <a
              href="https://litzapp.com/web/rich-page/render/190ad389-cd98-44fd-ab8e-61831ce658ea"
              target="_blank"
            >
              <BasicButton
                title={"Tutorials"}
                classes={`${props.btClass} my-bg-yellow-500 text-black`}
              />{" "}
            </a>
          </div>
        )
      );
    }
  };

  return showTutorial();
}
