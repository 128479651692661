import HamburgerBar from "./hamburger-bar";
import MobileLogoBar from "./logo-bar";
import PublisherActivationBar from "./pub-activation-bar";
import PublisherCreateBar from "./pub-create";
import CreateAccountBar from "./create-account-bar";
import PubSuppApplicationResumeBar from "./resume-supplier-app-bar";
import PublisherTutorialBar from "./tutorial-bar";

export default function MobileBar(props) {
  const { togleMobileMenu, location } = props;
  return (
    <div class="flex flex-row justify-between w-full gap-2">
      <MobileLogoBar />
      <PublisherActivationBar location={location} />
      <PubSuppApplicationResumeBar location={location} />
      <PublisherCreateBar location={location} />
      <CreateAccountBar />
      <HamburgerBar togleMobileMenu={togleMobileMenu} />
    </div>
  );
}
