import TextField from "../../../../../../../components/inputs/text-input";
import Checkbox from "../../../../../../../components/inputs/checkbox";
import { toast } from "react-toastify";
export default function TrackQuantity(props) {
  const { productMeta, pageContext, handleMetaChange, setQChangedByUser } =
    props;
  return (
    <>
      <div className="flex gap-2 w-full justify-center">
        <div>
          <Checkbox
            handleChange={(e) => {
              if (pageContext == "update" || productMeta.pricelabs_listing_id) {
                return;
              }
              if (!productMeta.booking_interruption) {
                toast(
                  "Sorry,Track Quantity functionality requires Booking Interruption to be enabled.",
                  {
                    type: "error",
                  }
                );
                return false;
              }

              handleMetaChange(e, "quantity_enabled", "checkbox");
            }}
            checked={productMeta.quantity_enabled}
          />
        </div>
        <span>Track Quantity </span>
      </div>
      {productMeta.quantity_enabled ? (
        <div className="m-2 mb-2 flex- flex-col items-center">
          <div className="flex gap-1 items-center">
            <TextField
              disabled={
                !productMeta.booking_interruption ||
                productMeta.integration_type == "pricelabs"
              }
              classes={"p-6 my-2"}
              placeholder={"Quantity.."}
              type="number"
              handleChange={(e) => {
                if (pageContext == "update") {
                  setQChangedByUser(true);
                }
                handleMetaChange(e, "quantity", "text");
              }}
              value={
                productMeta.quantity_enabled &&
                !productMeta.quantity &&
                !productMeta.booking_interruption
                  ? 1
                  : productMeta.quantity
              }
            />
            {productMeta.quantity_enabled &&
              !productMeta.quantity &&
              productMeta.booking_interruption && (
                <span className="text-red-500 text-lg">*</span>
              )}
          </div>
          <p className="text-base text-pink-500">
            {productMeta.quantity &&
              productMeta.quantity == 0 &&
              "Please enter a quantity greater than 0"}
          </p>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
