import { useSendMailStore } from "../../../../stores/send-mail-store";
import { isResponsiveMode } from "../../../../Utils/helpers";
import BasicButton from "../../../buttons/basic-button";

export default function MobileTable(props) {
  const mailStore = useSendMailStore((state) => state);
  const { processing, preSubCancelPromt, nonRenewals } = props;

  const getUniqueSubs = () => {
    if (mailStore?.data?.data) {
      const mapSubs = new Map(
        mailStore.data.data.map((c) => [c.subscription_id, c])
      );

      const uniqueSubs = [...mapSubs.values()];
      return uniqueSubs;
    }

    return [];
  };

  return (
    <div className="w-full flex flex-col justify-center my-4 text-center">
      {getUniqueSubs().map((subscription) => {
        return (
          <div
            className="overflow-x-scroll inline"
            style={{
              width: document.documentElement.clientWidth - 90 + "px",
              margin: "0 auto",
            }}
          >
            <div className="flex">
              <div class="border border-gray px-4 py-2  font-medium">
                <div className="flex flex-col justify-center items-start gap-2 text-left">
                  <div>
                    <p className="font-bold">Name</p>

                    <a
                      href={`/web/${subscription.subscriberdata.uuid}`}
                      target="_blank"
                    >
                      <span className="underline text-blue-900">
                        {subscription?.subscriberdata?.name}
                      </span>
                    </a>
                  </div>
                  <div>
                    <p className="font-bold">User Handle</p>
                    <a
                      href={`/web/${subscription.subscriberdata.uuid}`}
                      target="_blank"
                    >
                      <span className="underline text-blue-900">
                        {subscription?.subscriberdata?.handle_id}
                      </span>
                    </a>
                  </div>
                  <div>
                    <p className="font-bold">Email</p>
                    <a href={`mailto:${subscription.subscriberdata.email}`}>
                      <span className="underline text-blue-900">
                        {subscription?.subscriberdata?.email}
                      </span>
                    </a>
                  </div>
                  <div>
                    <p className="font-bold ">Phone</p>
                    <a href={"tel:" + subscription.subscriberdata.phone.phone}>
                      <span className="underline text-blue-900">
                        {subscription?.subscriberdata?.phone?.phone || "N/A"}
                      </span>
                    </a>
                  </div>
                </div>
              </div>

              <div class="border border-gray px-4 py-2  font-medium flex flex-col">
                <p className="font-bold">Subscription Type</p>
                {subscription.metadata.purchase_option_name}
                <br />
              </div>
              <div class="border border-gray px-4 py-2  font-medium flex flex-col">
                <p className="font-bold">Start Date</p>
                <p>
                  {subscription.start_date
                    ? new Date(
                        subscription.start_date.replace(/-/g, "/")
                      ).getDate() +
                      "-" +
                      (new Date(
                        subscription.start_date.replace(/-/g, "/")
                      ).getMonth() +
                        1) +
                      "-" +
                      new Date(
                        subscription.start_date.replace(/-/g, "/")
                      ).getFullYear()
                    : "N/A"}
                </p>
              </div>
              <div class="border border-gray px-4 py-2  font-medium flex flex-col">
                <p className="font-bold">Renewal Date</p>
                <p>
                  {subscription.end_date
                    ? new Date(
                        subscription.end_date.replace(/-/g, "/")
                      ).getDate() +
                      "-" +
                      (new Date(
                        subscription.end_date.replace(/-/g, "/")
                      ).getMonth() +
                        1) +
                      "-" +
                      new Date(
                        subscription.end_date.replace(/-/g, "/")
                      ).getFullYear()
                    : "N/A"}
                </p>
                {subscription.status == "cancelled" && (
                  <p className="text-red-500"> (End Date)</p>
                )}

                {nonRenewals.length &&
                  nonRenewals.includes(subscription.subscription_id) && (
                    <p className="text-red-500"> (End Date)</p>
                  )}
              </div>

              <div class="border border-gray px-4 py-2  font-medium">
                <div className="flex flex-col justify-center">
                  {" "}
                  <h3 className="font-bold md:hidden">Action</h3>
                  <BasicButton
                    disabled={
                      (subscription.status != "active" &&
                        subscription.status !== "Pending") ||
                      processing
                    }
                    title={
                      processing
                        ? "Cancelling.."
                        : subscription.status == "active" ||
                          subscription.status == "Pending"
                        ? "Cancel Subscription"
                        : "Cancelled"
                    }
                    handleClick={() => preSubCancelPromt(subscription)}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
