import axios from "axios";
import { useEffect, useState, useRef } from "react";
import BasicButton from "../../../components/buttons/basic-button";
import TextField from "../../../components/inputs/text-input";
import Pagination from "../../../components/pagination";
import useDebounce from "../../../components/debounce/debounce-helper";
import LoadingProgress from "../rich-page/components/loading-progress";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";
import {
  isAdmin,
  API_BASE,
  isPublisher,
  isAuthUser,
  authUserData,
  getLocation,
} from "../../../Utils/helpers";
import { toast } from "react-toastify";
export default function PubMyHirePurchases() {
  const [purchases, setPurchases] = useState({});
  const [searchText, setSearchText] = useState("");
  const [countPerPage, setCountPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [processing, setProcessing] = useState(false);
  const mountedRef = useRef();

  const doPagination = (page) => {
    if (purchases.first_page_url) {
      setCurrentPage(page);
      axios
        .get(purchases.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          setPurchases(res.data.purchases);
        })
        .catch((err) => console.log(err));
    }
  };

  const getPurchases = () => {
    setPurchases([]);
    setProcessing(true);
    const url =
      API_BASE +
      "/publisher/my/hire/purchases/" +
      authUserData().uuid +
      "/" +
      countPerPage +
      "/" +
      searchText +
      " ";

    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setProcessing(false);
          setPurchases(response.data.purchases);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e);
  };

  const handleCountPerPageChange = (e) => {
    setCountPerPage(e.target.value);
  };

  useDebounce(
    () => {
      getPurchases();
    },
    [searchText, countPerPage],
    800
  );

  useEffect(() => {
    if (!isPublisher()) {
      window.location.href = "/";
    }
  }, []);
  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">My Hires</h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex justify-center items-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            <p className="w-full text-center my-4">
              We found {purchases.total ? purchases.total : ""} records
            </p>
            <div className="flex flex-col md:flex-row w-full gap-4">
              <div className="w-11/12">
                <TextField
                  title="Search"
                  placeholder={"Search"}
                  classes="rounded-lg"
                  handleChange={handleSearchTextChange}
                  value={searchText}
                />
              </div>

              <div className="w-1/12">
                <label for="timezone" value="Your Timezone" />
                <select
                  onChange={handleCountPerPageChange}
                  required
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full"
                >
                  <option value="10" default>
                    10
                  </option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <div className="w-full flex justify-center">
              {" "}
              {purchases.data ? (
                purchases.data.length ? (
                  <Pagination
                    pagination={purchases}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            <table class="table-auto w-full my-4">
              <thead>
                <tr className={window.innerWidth < 720 ? "hidden" : ""}>
                  <th class="px-4 py-2 text-gray-600">Publisher</th>
                  <th class="px-4 py-2 text-gray-600">Item Title</th>
                  <th class="px-4 py-2 text-gray-600">Item ID</th>
                  <th class="px-4 py-2 text-gray-600">Price</th>
                  <th class="px-4 py-2 text-gray-600">Transaction ID</th>

                  <th class="px-4 py-2 text-gray-600">Date</th>
                </tr>
              </thead>
              <tbody>
                {purchases.data &&
                  purchases.data.map((purchase) => {
                    return (
                      <tr
                        className={
                          window.innerWidth < 820 ? "flex flex-col" : ""
                        }
                      >
                        <td class="border  px-4 py-2  font-medium">
                          <h3 className="font-bold md:hidden">Publisher</h3>

                          <p className="my-2 flex flex-col gap-2">
                            <span className="text-xs"> Name: </span>
                            <span className="text-yellow-600 text-xs font-bold">
                              {purchase.seller.name}
                            </span>
                            <span className="text-xs"> username: </span>
                            <span className="text-yellow-600 text-xs font-bold">
                              {purchase.seller.username}
                            </span>
                            <span className="text-xs"> user handle: </span>
                            <span className="text-yellow-600 text-xs font-bold">
                              {purchase.seller.handle_id}
                            </span>
                          </p>
                        </td>

                        <td class="border px-4 py-2  font-medium">
                          <h3 className="font-bold md:hidden">Item Title</h3>
                          <p>
                            <a
                              target="_blank"
                              className="underline"
                              href={
                                purchase?.bookbought?.group == "standard"
                                  ? `/publisher/${purchase?.bookbought?.owneruuid}/publications/series/m/book/${purchase?.bookbought.id}`
                                  : `/web/rich-page/render/${purchase?.bookbought?.uuid}`
                              }
                            >
                              {purchase?.bookbought?.name}
                            </a>
                          </p>
                        </td>

                        <td class="border  px-4 py-2  font-medium">
                          <h3 className="font-bold md:hidden">Item ID</h3>
                          {purchase?.bookbought?.item_id}
                        </td>
                        <td class="border  px-4 py-2  font-medium">
                          <h3 className="font-bold md:hidden">Price</h3>$
                          {purchase.price / 100}
                        </td>
                        <td class="border  px-4 py-2  font-medium">
                          <h3 className="font-bold md:hidden">
                            Transaction ID
                          </h3>
                          {purchase.transaction_id}
                        </td>

                        <td class="border  px-4 py-2  font-medium">
                          <h3 className="font-bold md:hidden">Date</h3>
                          <p>{purchase.refactoredCreatedAtTime}</p>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <LoadingProgress loading={processing} />
            <div className="w-full flex justify-center">
              {" "}
              {purchases.data ? (
                purchases.data.length ? (
                  <Pagination
                    pagination={purchases}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
