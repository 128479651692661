import InsertImage from "./insert-image";
import TextField from "../../../../../../../../components/inputs/text-input";
export default function UploadFile(props) {
  const { handleImagePreview } = props;
  const { handleImageLinkChange } = props;
  const { resetImagePreview } = props;
  const { imageFileSelected } = props;
  const { uploadType } = props;
  const { value } = props;

  const showUploader = () => {
    return !imageFileSelected;
  };
  return (
    <>
      {uploadType == "upload" && (
        <div className="flex flex-col items-center">
          {" "}
          {showUploader() && (
            <div className="w-8/12 md:w-3/12 relative my-2 flex flex-col">
              {" "}
              <p className="text-red-700 text-xs">
                Supported Formats (GIF,PNG,JPEG)
              </p>
              <i class="fas fa-image text-5xl"></i>
              <InsertImage handleImagePreview={handleImagePreview} />
            </div>
          )}
          {imageFileSelected && (
            <div className="w-8/12 md:w-3/12 relative my-4">
              <span
                className="bg-white rounded rounded-full bg-white p-0 right-0 absolute px-1 cursor-pointer"
                style={{ top: -19, right: 0 }}
                onClick={() => resetImagePreview()}
              >
                <i class="fa fa-times text-red-500 z-50 text-2xl"></i>
              </span>
              <img src={imageFileSelected} className="w-full my-1" />
            </div>
          )}
          <TextField
            placeholder={"Paste Link... (Optional)"}
            classes="rounded-lg pr-8"
            handleChange={handleImageLinkChange}
            value={value}
          />
        </div>
      )}
    </>
  );
}
