import DumbRatingBox from "../../../rating/components/dumb-rating";
import { toast } from "react-toastify";
import {
  API_BASE,
  BASE_URL,
  isAuthUser,
  authUserData,
  isResponsiveMode,
  axiosRequestInstance,
  getLocation,
} from "../../../../Utils/helpers";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { useState, useEffect } from "react";
import SlidingAds from "./sliding-ads";
import { ads } from "../util/ads";

export default function BookTemplate(props) {
  const { book } = props;
  const { renderBookByGroupType } = props;
  const { rateBook } = props;
  const { currentRating } = props;
  const { isTrackingPublisher } = props;
  const { lastRatedBook } = props;
  const { untrackPublisher } = props;
  const { trackPublisher } = props;
  const { openAuthPromtModal } = props;
  const { renderTags } = props;

  const showCurrentLocation = () => {
    if (getLocation().hostname == "litzapp.com") {
      copyText("https://litzapp.com/share/sh/" + book?.item_id);
    } else {
      copyText("https://wordalbums1.test/sh/" + book?.item_id);
    }
  };

  const copyText = (text) => {
    // standard way of copying
    var textArea = document.createElement("textarea");
    textArea.setAttribute("style", "width:1px;border:0;opacity:0;");
    document.body.appendChild(textArea);
    textArea.value = text;
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    toast("Link Copied", { type: "success" });
  };

  return (
    <div className="w-full">
      <div className="p-4 m-2 bg-white text-center cards-wrapper z-50">
        {renderBookByGroupType(book)}
        <div className="">
          <div className="">
            <span className="text-xs"> Item ID: </span>
            <span className="text-yellow-600 text-xs fo nt-bold">
              {book.item_id}
            </span>
          </div>

          <p className="text-black-500">
            Views{" "}
            {book.pages && book.pages.length && `(${book.pages[0].views})`}
          </p>
        </div>
        {book.type !== "list" ? (
          <DumbRatingBox
            book={book}
            lastRatedBook={lastRatedBook}
            rateBook={rateBook}
            currentRating={currentRating}
          />
        ) : (
          ""
        )}
        <div className="flex gap-3 my-2 bg-gray-50 items-end">
          <div onClick={() => showCurrentLocation()}>
            <i class={`fa fa-share-alt cursor-pointer ${"text-black"}`}></i>
          </div>
        </div>
        <div className="m-2 flex flex-wrap md:w-8/12 justify-center items-center">
          {book.tags && renderTags(book)}
        </div>
      </div>

      <div className="w-full flex justify-center">
        {/* <hr className="w-10/12 md:w-6/12 h-1 text-black" /> */}
        <div className="hrDiv w-10/12 md:w-8/12 "></div>
      </div>
      <div className="w-full md:flex justify-center">
        <div className="md:w-7/12"> </div>
      </div>
    </div>
  );
}
