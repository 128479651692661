import { useSendMailStore } from "../../../../../../stores/send-mail-store";
import BasicButton from "../../../../../../components/buttons/basic-button";
import Checkbox from "../../../../../../components/inputs/checkbox";
import { isResponsiveMode } from "../../../../../../Utils/helpers";

export default function MobileTable(props) {
  const mailStore = useSendMailStore((state) => state);

  const { processing, preSubCancelPromt } = props;
  const { blockedEmails } = props;
  const { shouldMarkItem } = props;
  const { markItem } = props;

  function capitalizeFirstLetter(str) {
    return str[0].toUpperCase() + str.slice(1);
  }

  return (
    <div className="w-full flex flex-col justify-center my-4 text-center">
      {mailStore.data.data
        ? mailStore.data.data.length
          ? mailStore.data.data.map((subscription) => {
              return (
                <div
                  className="overflow-x-scroll inline"
                  style={{
                    width: document.documentElement.clientWidth - 90 + "px",
                    margin: "0 auto",
                  }}
                >
                  <div className="flex">
                    <div
                      class={`${
                        isResponsiveMode() ? "border text-center" : ""
                      } px-4 py-2 font-medium md:hidden `}
                    >
                      {!blockedEmails.includes(
                        subscription?.subscriberdata?.email
                      ) ? (
                        <div className="md:hidden">
                          {" "}
                          <Checkbox
                            checked={
                              shouldMarkItem(subscription.id) ? true : false
                            }
                            handleChange={() => markItem(subscription.id)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="border border-gray px-4 py-2  font-medium">
                      <div className="flex flex-col justify-center items-start gap-2 text-left">
                        <div>
                          <p className="font-bold">Name</p>
                          {subscription?.subscriberdata?.name}
                        </div>
                        <div>
                          <p className="font-bold">User Handle</p>
                          {subscription?.subscriberdata?.handle_id}
                        </div>
                        <div>
                          <p className="font-bold">Email</p>
                          {subscription?.subscriberdata?.email}
                        </div>
                        <div>
                          <p className="font-bold ">Phone</p>
                          {subscription?.subscriberdata?.phone?.phone || "N/A"}
                        </div>
                      </div>
                    </div>

                    <div class="border border-gray px-4 py-2  font-medium">
                      <div className="flex flex-col justify-center items-center">
                        <h3 className="font-bold md:hidden">Country</h3>
                        {subscription?.subscriberdata?.country}
                      </div>
                    </div>
                    <div class="border border-gray px-4 py-2  font-medium">
                      <div className="flex flex-col justify-center items-center">
                        <h3 className="font-bold md:hidden">
                          {" "}
                          Purchase Option
                        </h3>
                        {subscription.metadata.purchase_option_name}
                      </div>
                    </div>
                    <div class="border border-gray px-4 py-2  font-medium">
                      <div className="flex flex-col justify-center items-center">
                        <h3 className="font-bold md:hidden">Price</h3>$
                        {subscription.unit_amount}
                      </div>
                    </div>

                    <div class="border border-gray px-4 py-2  font-medium">
                      <div className="flex flex-col justify-center items-center">
                        <h3 className="font-bold md:hidden">Item Title</h3>
                        {subscription.itemdata.item_name}
                      </div>
                    </div>
                    <div class="border border-gray px-4 py-2  font-medium">
                      <div className="flex flex-col justify-center items-center">
                        <h3 className="font-bold md:hidden">Item ID</h3>
                        {subscription.itemdata.item_id}
                      </div>
                    </div>
                    <div class="border border-gray px-4 py-2  font-medium">
                      <div className="flex flex-col justify-center items-center">
                        <h3 className="font-bold md:hidden">
                          Subscription Cycle
                        </h3>
                        {subscription.metadata.cycle}
                      </div>
                    </div>
                    <div class="border border-gray px-4 py-2  font-medium">
                      <div className="flex flex-col justify-center items-center">
                        <h3 className="font-bold md:hidden">Transaction ID</h3>
                        {subscription.transaction_id}
                      </div>
                    </div>

                    <div class="border border-gray px-4 py-2  font-medium">
                      <div className="flex flex-col justify-center items-center">
                        <h3 className="font-bold md:hidden">Status</h3>
                        {capitalizeFirstLetter(subscription.status)}
                      </div>
                    </div>
                    <div class="border border-gray px-4 py-2 font-medium text-center">
                      <h3 className="font-bold md:hidden ">Duration</h3>
                      <div className="flex flex-col items-center gap-4">
                        {" "}
                        <p>
                          <b>Starts: </b>
                          {subscription.start_date
                            ? new Date(
                                subscription.start_date.replace(/-/g, "/")
                              ).getDate() +
                              "-" +
                              (new Date(
                                subscription.start_date.replace(/-/g, "/")
                              ).getMonth() +
                                1) +
                              "-" +
                              new Date(
                                subscription.start_date.replace(/-/g, "/")
                              ).getFullYear()
                            : "N/A"}
                        </p>
                        <p>
                          <b>Ends: </b>
                          {subscription.end_date
                            ? new Date(
                                subscription.end_date.replace(/-/g, "/")
                              ).getDate() +
                              "-" +
                              (new Date(
                                subscription.end_date.replace(/-/g, "/")
                              ).getMonth() +
                                1) +
                              "-" +
                              new Date(
                                subscription.end_date.replace(/-/g, "/")
                              ).getFullYear()
                            : "N/A"}
                        </p>
                      </div>
                    </div>

                    <div class="border border-gray px-4 py-2  font-medium">
                      <div className="flex justify-center">
                        {" "}
                        <h3 className="font-bold md:hidden">Date</h3>
                        {subscription.simplecreatedat
                          ? new Date(
                              subscription.simplecreatedat.replace(/-/g, "/")
                            ).getDate() +
                            "-" +
                            (new Date(
                              subscription.simplecreatedat.replace(/-/g, "/")
                            ).getMonth() +
                              1) +
                            "-" +
                            new Date(
                              subscription.simplecreatedat.replace(/-/g, "/")
                            ).getFullYear()
                          : "N/A"}
                      </div>
                    </div>

                    <div class="border border-gray px-4 py-2  font-medium">
                      <div className="flex flex-col justify-center">
                        {" "}
                        <h3 className="font-bold md:hidden">Action</h3>
                        <BasicButton
                          disabled={
                            (subscription.status != "active" &&
                              subscription.status !== "Pending") ||
                            processing
                          }
                          title={
                            processing
                              ? "Cancelling.."
                              : subscription.status == "active" ||
                                subscription.status == "Pending"
                              ? "Cancel Subscription"
                              : "Cancelled"
                          }
                          handleClick={() => preSubCancelPromt(subscription)}
                        />
                      </div>
                    </div>
                    <div
                      class={`${
                        isResponsiveMode() ? "border text-center" : ""
                      } px-4 py-2 font-medium md:hidden `}
                    >
                      {!blockedEmails.includes(
                        subscription?.subscriberdata?.email
                      ) ? (
                        <div className="md:hidden">
                          {" "}
                          <Checkbox
                            checked={
                              shouldMarkItem(subscription.id) ? true : false
                            }
                            handleChange={() => markItem(subscription.id)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          : ""
        : ""}
    </div>
  );
}
