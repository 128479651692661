import TextField from "../../../components/inputs/text-input";
import TextArea from "../../../components/inputs/text-area";
import BasicButton from "../../../components/buttons/basic-button";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import StripeRedirectModal from "../../../modals/stripe-redirect-modal";
import StripeRedirectModeModal from "../../../modals/stripe-redirect-mode-modal";
import DeletePromptModal from "../../../modals/delete-warning-modal";
import TextWholeNumberField from "../../../components/inputs/text-whole-number";

import {
  isAdmin,
  API_BASE,
  isPublisher,
  getLocation,
  authUserData,
  isAuthUser,
  getClientBaseURL,
} from "../../../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
import Switch from "react-switch";
import PasswordPromptModal from "../../../modals/password-prompt-modal";
export default function DashboardAdminSettings() {
  const [processing, setProcessing] = useState(false);
  const [adminConstant, setAdminConstant] = useState("");
  const [adminPercentage, setAdminPercentage] = useState("");
  const [freeStorageTier, setFreeStorageTier] = useState("");
  const [setting, setSetting] = useState({});
  const [adControls, setAdControls] = useState(false);
  const [redirectLink, setRedirectLink] = useState("");
  const [tierMessageOne, setTierMessageOne] = useState("");
  const [tierMessageTwo, setTierMessageTwo] = useState("");
  const [redirectModalOpen, setRedirectModalOpen] = useState(false);
  const [redirectModeModalOpen, setRedirectModeModalOpen] = useState(false);
  const [deleteAccountModalOpen, showDeleteAccountModalOpen] = useState(false);
  const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [botThreshold, setBotThreshold] = useState("");
  const [botRatio, setBotRatio] = useState("");
  const [totalBotToViewRatio, setTotalBotToViewRatio] = useState("");
  const [emailBatchLimit, setEmailBatchLimit] = useState("");
  const [salesPayout, setSalesPayout] = useState("");

  const [user, setUser] = useState({});
  let { mode } = useParams();

  const userId = authUserData().uuid;

  const hideDeleteAccountModal = () => {
    showDeleteAccountModalOpen(false);
  };

  const hideRedirectModeModal = () => {
    setRedirectModeModalOpen(false);
  };

  const openPasswordPromptModal = () => {
    setShowPasswordChangeModal(true);
  };

  const closePasswordPromptModal = () => {
    setShowPasswordChangeModal(false);
  };

  const handleTierMessageOneChange = (e) => {
    setTierMessageOne(e);
  };

  const handleTierMessageTwoChange = (e) => {
    setTierMessageTwo(e);
  };

  const handlePasswordChange = (e) => {
    setPassword(e);
  };
  const handleEmailChange = (e) => {
    setEmail(e);
  };

  const handleEmailBatchLimitChange = (e) => {
    setEmailBatchLimit(e);
  };

  const canSubmit = () => {
    return false;
  };

  const getUser = () => {
    let url = API_BASE + "/api/publisher/" + userId;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setUser(response.data.publisher);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const generatePasswordResetLink = () => {
    return getLocation().hostname == "litzapp.com"
      ? getClientBaseURL() + `/password-reset/usr${userId}/tok/`
      : getClientBaseURL() + `:3000/password-reset/usr/${userId}/tok/`;
  };

  const updateEmail = () => {
    const url = API_BASE + "/api/user/update/email";
    setProcessing(true);
    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("user_id", userId);
    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        getUser();
        closePasswordPromptModal();
        toast("Updated!", { type: "success" });
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.status == 422) {
          // console.log(error.response.data);
          if (error.response.data.hasOwnProperty("duplicate_email")) {
            toast("Duplicate registration, email address already registered!", {
              type: "error",
            });
          } else if (error.response.data.hasOwnProperty("invalid_password")) {
            toast("Invalid Password!", {
              type: "error",
            });
          } else {
            toast("Something went wrong!", { type: "error" });
          }
        }
      });
  };

  const updatePassword = () => {
    const url = API_BASE + "/api/user/update/send-password-reset-eamil";
    setProcessing(true);
    let formData = new FormData();
    formData.append("email", email);
    formData.append("user_id", userId);
    formData.append("link", generatePasswordResetLink());
    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        toast("Please check your email for your password reset link!", {
          type: "success",
        });
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const updatePaymentSetting = () => {
    const url = API_BASE + "/api/system/settings/payment/update";
    setProcessing(true);
    let formData = new FormData();
    formData.append("constant_fee_on_payments", adminConstant);
    formData.append("system_percentage_on_payments", adminPercentage);
    formData.append("sales_payout_percentage", salesPayout);

    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        toast("Updated!", { type: "success" });
        getSystemSetting();
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const updateAdvertSetting = () => {
    const url = API_BASE + "/api/system/settings/advert/update";
    setProcessing(true);
    let formData = new FormData();
    formData.append("ads_controls_visibility", adControls ? 1 : 0);

    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        toast("Updated!", { type: "success" });
        getSystemSetting();
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };
  const updateStorageSetting = () => {
    const url = API_BASE + "/api/system/settings/storage/update";
    setProcessing(true);
    let formData = new FormData();
    formData.append("free_storage_tier", freeStorageTier);
    formData.append("free_storage_tier_message_one", tierMessageOne);
    formData.append("free_storage_tier_message_two", tierMessageTwo);

    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        toast("Updated!", { type: "success" });
        getSystemSetting();
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const updateBotSetting = () => {
    const url = API_BASE + "/api/system/settings/bot/update";
    setProcessing(true);
    let formData = new FormData();
    formData.append("bot_threshold", botThreshold);
    formData.append("bot_ratio", botRatio);
    formData.append("total_bot_ratio", totalBotToViewRatio);

    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        toast("Updated!", { type: "success" });
        getSystemSetting();
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const updateEmailBatchLimitSetting = () => {
    const url = API_BASE + "/api/system/settings/mailing/email-batch-limit";
    setProcessing(true);
    let formData = new FormData();
    formData.append("email_dispatch_limit", emailBatchLimit);
    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        toast("Updated!", { type: "success" });
        getSystemSetting();
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const getSystemSetting = () => {
    const url = API_BASE + "/api/system/settings";
    setProcessing(true);

    axios
      .get(url)
      .then((response) => {
        setProcessing(false);
        setSetting(response.data);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const handleAdminConstantChange = (value) => {
    setAdminConstant(value);
  };

  const handleAdminPercentageChange = (value) => {
    setAdminPercentage(value);
  };

  const handleAdminSalesPayoutChange = (value) => {
    setSalesPayout(value);
  };
  const handleAdControlsVisibility = (e) => {
    setAdControls((prev) => !prev);
  };

  const handleFreeStorageTierChange = (e) => {
    setFreeStorageTier(e);
  };

  const handleBotThresholdChange = (e) => {
    setBotThreshold(e);
  };

  const handleBotToViewRatioChange = (e) => {
    setBotRatio(e);
  };

  const handleTotalBotToViewRatioChange = (e) => {
    setTotalBotToViewRatio(e);
  };

  const connectedPaymentInformation = () => {
    let connectedAccount;
    let connectedAccountWebhookEvents;

    if (user.name && user.stripeconnectedaccount) {
      connectedAccount = user.stripeconnectedaccount;
      if (connectedAccount.webhookevents) {
        connectedAccountWebhookEvents =
          connectedAccount?.webhookevents?.data?.object?.external_accounts
            ?.data[0];
      }
    }
    return connectedAccountWebhookEvents;
  };

  const removeAccount = () => {
    const url = API_BASE + "/api/user/account/delete/" + userId;
    setProcessing(true);
    axios
      .delete(url)
      .then((response) => {
        setProcessing(false);
        getUser();
        toast("Removed!", { type: "success" });
        hideDeleteAccountModal();
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const generateAccountSetupLink = () => {
    const url = API_BASE + "/api/user/generate/account-setup-link";
    setProcessing(true);
    let formData = new FormData();
    formData.append("user_id", userId);
    formData.append(
      "return_url",
      getLocation().toString().split("settings/")[0] +
        "settings/" +
        userId +
        "/return"
    );
    formData.append(
      "refresh_url",
      getLocation().toString().split("settings/")[0] +
        "settings/" +
        userId +
        "/refresh"
    );

    // alert(userId);

    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        setRedirectLink(response.data.link);
        setRedirectModalOpen(true);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const hideRedirectModal = () => {
    setRedirectModalOpen(false);
  };

  useEffect(() => {
    if (!isAdmin() || JSON.parse(isAuthUser()).uuid !== userId) {
      window.location.href = "/";
    }
    getSystemSetting();
    getUser();
    if (mode == "return") {
      setRedirectModeModalOpen(true);
    }
  }, []);

  useEffect(() => {
    if (setting.constant_fee_on_payments) {
      setAdminConstant(setting.constant_fee_on_payments);
    }
    if (setting.system_percentage_on_payments) {
      setAdminPercentage(setting.system_percentage_on_payments);
    }

    if (setting.ads_controls_visibility) {
      setAdControls(setting.ads_controls_visibility);
    }

    if (setting.free_storage_tier) {
      setFreeStorageTier(setting.free_storage_tier);
    }
    if (setting.free_storage_tier_message_one) {
      setTierMessageOne(setting.free_storage_tier_message_one);
    }
    if (setting.free_storage_tier_message_two) {
      setTierMessageTwo(setting.free_storage_tier_message_two);
    }
    if (setting.bot_threshold) {
      setBotThreshold(setting.bot_threshold);
    }
    if (setting.bot_ratio) {
      setBotRatio(Number(setting.bot_ratio).toFixed(2));
    }
    if (setting.total_bot_ratio) {
      setTotalBotToViewRatio(Number(setting.total_bot_ratio).toFixed(2));
    }
    if (setting.email_dispatch_limit) {
      setEmailBatchLimit(Number(setting.email_dispatch_limit));
    }
    if (setting.sales_payout_percentage) {
      setSalesPayout(Number(setting.sales_payout_percentage));
    }
  }, [setting]);

  useEffect(() => {
    if (user.name) {
      setEmail(user.email);
    }
  }, [user]);

  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">Settings</h1>
        </div>
        <div>
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center gap-4 mt-8 items-center mb-4">
        <div className="w-10/12 md:w-6/12  p-4 h-26 flex flex-col items-center border border-gray">
          <h1 className="font-bold">Sytem Fees</h1>
          <div className="my-4 text-center flex flex-col justify-between w-full">
            <div className="text-left w-full">
              <label for="html">Constant Charge</label>
              <TextField
                classes={"p-6 my-2 w-full"}
                placeholder={"Constant.."}
                type="number"
                handleChange={handleAdminConstantChange}
                value={adminConstant}
              />
            </div>
            <div className="text-left my-1 w-full">
              <label for="javascript">System Percentage</label>
              <TextField
                classes={"p-6 my-2 w-full"}
                placeholder={"Percentage.."}
                type="number"
                handleChange={handleAdminPercentageChange}
                value={adminPercentage}
              />
            </div>
            <div className="text-left w-full">
              <label for="html">Sales Payout Percentage</label>
              <TextField
                classes={"p-6 my-2 w-full"}
                placeholder={"Constant.."}
                type="number"
                handleChange={handleAdminSalesPayoutChange}
                value={salesPayout}
              />
            </div>
            <div className="my-1 w-full">
              <BasicButton
                disabled={false}
                title={processing ? "Updating.." : "Update"}
                classes={"p-6 mt-4 w-full"}
                handleClick={() => updatePaymentSetting()}
              />
            </div>
          </div>
          <h1 className="font-bold">Free Storage Tier</h1>
          <div className="hrDiv w-10/12 md:w-8/12 h-1"></div>
          <div className="my-4 text-center flex flex-col justify-between w-full">
            <div className="text-left w-full">
              <label for="html">Free Tier</label>
              <TextField
                classes={"p-6 my-2 w-full"}
                placeholder={"Storage Tier.."}
                type="number"
                handleChange={handleFreeStorageTierChange}
                value={freeStorageTier}
              />
            </div>
            <div className="text-left my-1 w-full flex flex-col items-start">
              <label for="javascript">
                Threshold Prompt Message (File Larger than Threshold)
              </label>
              <TextArea
                classes={"p-6 my-2 w-full"}
                placeholder={"Enter Message..."}
                type="number"
                handleChange={handleTierMessageOneChange}
                value={tierMessageOne}
              />
            </div>
            <div className="text-left my-1 w-full flex flex-col items-start">
              <label for="javascript">
                Threshold Prompt Message ( Extra File will break Data Threshold)
              </label>
              <TextArea
                classes={"p-6 my-2 w-full"}
                placeholder={"Enter Message..."}
                type="number"
                handleChange={handleTierMessageTwoChange}
                value={tierMessageTwo}
              />
            </div>

            <div className="my-1 w-full">
              <BasicButton
                disabled={false}
                title={processing ? "Updating.." : "Update"}
                classes={"p-6 mt-4 w-full"}
                handleClick={() => updateStorageSetting()}
              />
            </div>
          </div>

          <h1 className="font-bold">Bots</h1>
          <div className="hrDiv w-10/12 md:w-8/12 h-1"></div>
          <div className="my-4 text-center flex flex-col justify-between w-full">
            <div className="text-left w-full">
              <label for="html">Bot Threshold</label>
              <TextField
                classes={"p-6 my-2 w-full"}
                placeholder={"Enter threshold.."}
                type="text"
                handleChange={handleBotThresholdChange}
                value={botThreshold}
              />
            </div>
            <div className="text-left my-1 w-full flex flex-col items-start">
              <label for="javascript">Bot To View Ratio</label>
              <TextField
                classes={"p-6 my-2 w-full"}
                placeholder={"Enter ratio..."}
                type="text"
                handleChange={handleBotToViewRatioChange}
                value={botRatio}
              />
            </div>

            <div className="text-left my-1 w-full flex flex-col items-start">
              <label for="javascript">Total Bot To View Ratio</label>
              <TextField
                classes={"p-6 my-2 w-full"}
                placeholder={"Enter ratio..."}
                type="text"
                handleChange={handleTotalBotToViewRatioChange}
                value={totalBotToViewRatio}
              />
            </div>

            <div className="my-1 w-full">
              <BasicButton
                disabled={false}
                title={processing ? "Updating.." : "Update"}
                classes={"p-6 mt-4 w-full"}
                handleClick={() => updateBotSetting()}
              />
            </div>
          </div>

          <h1 className="font-bold">Email Pricing</h1>
          <div className="my-4 text-center flex flex-col justify-center items-center gap-2 w-full">
            <div className="flex gap-2 items-center justify-center w-full">
              <label for="timezone" value="Your Timezone" className="font-bold">
                $1 =
              </label>
              <TextWholeNumberField
                classes={"p-6 my-2 w-6/12"}
                placeholder={"Email Dispatches.."}
                handleChange={handleEmailBatchLimitChange}
                value={emailBatchLimit}
              />
            </div>
            <div className="my-1 w-full">
              <BasicButton
                disabled={false}
                title={processing ? "Updating.." : "Update"}
                classes={"p-6 mt-4 w-full"}
                handleClick={() => updateEmailBatchLimitSetting()}
              />
            </div>
          </div>

          <div className="flex flex-col justify-start my-6 border-bt-black w-full">
            <div className="w-full p-2">
              <h1 className="text-center font-bold">
                Publisher Advert Controls
              </h1>
            </div>
            <div className="m-2 mb-6 text-left">
              {/* <label className="text-black">Ad Timer Delay</label> */}
              <p>Hides or reveals advert controls on all publisher screens.</p>
              <br />

              <label className="flex items-center gap-4">
                <span>Enable</span>
                <Switch
                  onChange={handleAdControlsVisibility}
                  checked={adControls}
                />
              </label>
            </div>
            <div className="my-1 w-full">
              <BasicButton
                disabled={false}
                title={processing ? "Updating.." : "Update"}
                classes={"p-6 mt-4 w-full"}
                handleClick={() => updateAdvertSetting()}
              />
            </div>
          </div>

          <div className="hrDiv w-10/12 md:w-8/12 h-1"></div>

          <div className="w-10/12 md:w-10/12  p-4 h-26 flex flex-col items-center w-full">
            <h1 className="font-bold">Payout</h1>

            {user.stripeconnectedaccount &&
            user.stripeconnectedaccount.payouts_enabled ? (
              <div className="w-8/12 flex flex-col items-center justify-center">
                <p className="">
                  You connected one{" "}
                  {connectedPaymentInformation()
                    ? connectedPaymentInformation().object == "card"
                      ? "Card"
                      : "Bank Account"
                    : "Account"}
                </p>
                <div className="flex justify-center w-full">
                  {connectedPaymentInformation() ? (
                    connectedPaymentInformation().object == "card" ? (
                      <div className="w-full flex flex-col items-center border border-gray p-2">
                        <div className="flex flex-col md:flex-row justify-around p-2 gap-1 items-center md:w-10/12">
                          <div className="text-right">
                            <i class="fas fa-credit-card text-6xl text-yellow w-full"></i>
                          </div>
                          <div className="text-center md:text-left">
                            {/* <p className="text-gray text-xs">Account Type</p> */}
                            <p>
                              <b className="text-xs">
                                {connectedPaymentInformation().object == "card"
                                  ? "Card No"
                                  : "Bank Account"}
                              </b>
                            </p>
                            <p className="text-gray text-xs">
                              xxxx xxxx xxxx{" "}
                              {connectedPaymentInformation().last4}
                            </p>
                            <p>
                              <b className="text-xs">
                                {/* {
                              publisher.connectedaccount?.webhookevents
                                .created_at
                            } */}
                              </b>
                            </p>
                          </div>
                        </div>
                        <BasicButton
                          disabled={processing}
                          title={processing ? "Deleting.." : "Delete Card"}
                          classes={"p-6 w-9/12 mt-4 bg-red-500"}
                          handleClick={() => {
                            showDeleteAccountModalOpen(true);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="w-full flex flex-col items-center border border-gray p-2">
                        <div className="flex flex-col md:flex-row justify-around p-2 gap-1 items-center md:w-10/12">
                          <div className="text-right">
                            <i class="fas fa-university text-6xl text-yellow w-full"></i>
                          </div>
                          <div className="text-center md:text-left">
                            {/* <p className="text-gray text-xs">Account Type</p> */}
                            <p>
                              <b className="text-xs">
                                {connectedPaymentInformation().object == "card"
                                  ? "Card No"
                                  : connectedPaymentInformation().bank_name}
                              </b>
                            </p>
                            <p className="text-gray text-xs">
                              xxxx xxxx xxxx{" "}
                              {connectedPaymentInformation().last4}
                            </p>
                            <p>
                              <b className="text-xs">
                                {/* {
                              publisher.connectedaccount?.webhookevents
                                .created_at
                            } */}
                              </b>
                            </p>
                          </div>
                        </div>
                        <BasicButton
                          title={processing ? "Deleting.." : "Delete Account"}
                          classes={"p-6 w-9/12 mt-4 bg-red-500"}
                          handleClick={() => {
                            showDeleteAccountModalOpen(true);
                          }}
                        />
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              <>
                <p className="w-10/12">
                  You do not have any payment profile setup, please setup your
                  payment profile to receive payments when readers purchase or
                  subscribe to your book.
                </p>
                <BasicButton
                  title={processing ? "Redirecting.." : "Connect Account"}
                  classes={"p-6 w-9/12 mt-4"}
                  handleClick={generateAccountSetupLink}
                />
              </>
            )}
            <div className="my-4  text-center flex justify-between"></div>
          </div>
          <div className="hrDiv w-10/12 md:w-8/12 h-0"></div>
          <div className="w-10/12 md:w-10/12  p-4 h-26 flex flex-col items-center ">
            <h1 className="font-bold">Security</h1>

            <div class="flex flex-col w-full my-2">
              <label class="text-gray-500 mb-2">Email</label>
              <TextField
                placeholder={"Email"}
                handleChange={handleEmailChange}
                value={email}
              />
            </div>

            <div className="my-1 text-center w-full">
              <br />
              <BasicButton
                disabled={canSubmit()}
                title={processing ? "Updating.." : "Update Email"}
                classes={"p-6 w-9/12 mt-4"}
                handleClick={() => openPasswordPromptModal()}
              />
            </div>

            <div class="flex flex-col w-full mt-8">
              <label class="text-gray-500 mb-2">Password</label>
              <p className="text-black">******************</p>
            </div>

            <div className="my-1 text-center w-full">
              <br />
              <BasicButton
                disabled={canSubmit()}
                title={processing ? "Updating.." : "Update Password"}
                classes={"p-6 w-9/12 mt-4"}
                handleClick={updatePassword}
              />
            </div>
          </div>

          <div className="my-4  text-center flex justify-between"></div>
        </div>
      </div>
      <StripeRedirectModal
        modalOpen={redirectModalOpen}
        hideModal={hideRedirectModal}
        link={redirectLink}
      />
      <PasswordPromptModal
        handleChange={handlePasswordChange}
        hideModal={closePasswordPromptModal}
        modalOpen={showPasswordChangeModal}
        value={password}
        action={updateEmail}
      />
      <StripeRedirectModeModal
        modalOpen={redirectModeModalOpen}
        hideModal={hideRedirectModeModal}
        msg={
          "Please scroll down to the account setup section to view your account status."
        }
      />
      <DeletePromptModal
        modalOpen={deleteAccountModalOpen}
        hideAdModal={hideDeleteAccountModal}
        title={
          "If you delete your account, you will stop receiving payments. Are you sure you want to delete this account?"
        }
        yesActionText={"Yes, Delete Account"}
        noActionText={"No, Cancel"}
        action={() => removeAccount()}
        processing={processing}
      />
    </>
  );
}
