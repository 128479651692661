import { SPOptions } from "../../constants";
export default function PurchaseOPtionDef(props) {
  const { productMeta, handleMetaChange, pageContext } = props;
  return (
    <div className="w-full flex flex-col items-center justify-center">
      <div className="flex gap-1">
        <label className="font-bold"> Purchase Option</label>
        {!productMeta.p_type_sub_opt && (
          <span className="text-red-500 text-lg">*</span>
        )}
      </div>
      <select
        disabled={pageContext == "update"}
        value={productMeta.p_type_sub_opt}
        onChange={(e) => {
          if (!pageContext == "create") return false;
          handleMetaChange(e, "p_type_sub_opt", "dropdown");
        }}
        required
        class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
      >
        <option value="" defaultValue disabled>
          Not Selected
        </option>
        {SPOptions.map((b) => (
          <option value={b.label}>{b.label}</option>
        ))}
      </select>
    </div>
  );
}
