import { isAuthUser, authUserData } from "../../../../../Utils/helpers";
import BasicButton from "../../../../buttons/basic-button";
export default function ProfileSectionOne(props) {
  const { publisher } = props;
  const { isTrackingPublisher } = props;
  const { setActiveTab } = props;
  const { activeTab } = props;
  const { fileSelected } = props;
  const { untrackPublisher } = props;
  const { trackPublisher } = props;
  const { openAuthPromtModal } = props;
  const { profileText } = props;

  return (
    <header class="flex flex-wrap items-center items-start p-4 ">
      <div class="md:w-3/12 md:ml-16">
        {/* <!-- profile image --> */}
        <img
          class="w-20 h-20 md:w-36 md:h-36 object-cover rounded-full
                       p-1"
          src={fileSelected ? fileSelected : "/profile_avatar.png"}
          alt="profile"
        />
      </div>

      {/* <!-- profile meta --> */}
      <div class="w-8/12 md:w-7/12 ml-4 ">
        <div class="flex flex-col md:items-start my-2 w-full ">
          <div className="flex gap-4 justify-between w-full">
            <p className="text-lg font-bold">
              {publisher.name ? publisher.name : ""}
            </p>
            <BasicButton
              title={"Go Back"}
              classes="my-bg-yellow-500 text-black"
              handleClick={() => window.history.back()}
            />
          </div>
          <p className="">
            <span className=""> username: </span>
            <span className="text-yellow-600 font-bold">
              {publisher.name ? publisher?.username : ""}
            </span>
          </p>
          <p className="mb-2 mt-1">
            <span className=""> user handle: </span>
            <span className="text-yellow-600 font-bold">
              {publisher.name ? publisher.handle_id : ""}
            </span>
          </p>

          {publisher ? (
            <button
              disabled={isAuthUser() && publisher.uuid == authUserData().uuid}
              onClick={() =>
                isAuthUser()
                  ? isTrackingPublisher(publisher)
                    ? untrackPublisher(publisher.uuid, authUserData().uuid)
                    : trackPublisher(publisher.uuid, authUserData().uuid)
                  : openAuthPromtModal()
              }
              class={`whitespace-nowrap inline-flex items-center justify-center px-8 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white ${
                isAuthUser() && publisher.uuid == authUserData().uuid
                  ? "bg-gray-500 hover:bg-gray-500"
                  : "bg-black hover:bg-black"
              }`}
            >
              {isTrackingPublisher(publisher) ? "Untrack" : "Track"}
            </button>
          ) : (
            ""
          )}
        </div>

        {/* <!-- post, following, followers list for medium screens --> */}
        <ul class="hidden md:flex space-x-8 mb-2">
          <li>
            <span class="font-semibold p-1">
              {publisher ? publisher.books_count : 0}
            </span>
            All
          </li>

          <li
            onClick={() => setActiveTab(5)}
            className={
              activeTab == 5
                ? "border-t border-gray-700 -mt-px text-gray-700 text-center cursor-pointer"
                : "text-center cursor-pointer"
            }
          >
            <span class="font-semibold p-1">
              {" "}
              {publisher && publisher.trackers ? publisher.trackers.length : 0}
            </span>
            Trackers
          </li>
          <li
            onClick={() => setActiveTab(4)}
            className={
              activeTab == 4
                ? "border-t border-gray-700 -mt-px text-gray-700 text-center cursor-pointer"
                : "text-center cursor-pointer"
            }
          >
            <span class="font-semibold p-1">
              {" "}
              {publisher && publisher.tracking ? publisher.tracking.length : 0}
            </span>
            Tracking
          </li>
        </ul>

        {/* <!-- user meta form medium screens --> */}
        <div class="hidden md:block">
          {/* <h1 class="font-semibold">
              {publisher.name ? publisher.name : ""}
            </h1> */}

          <p className="font-bold mt-4">Personal Text</p>
          <div className="h-26 overflow-y-auto">
            <p>{profileText}</p>
          </div>
        </div>
      </div>

      {/* <!-- user meta form small screens --> */}
      <div class="md:hidden text-sm mt-2">
        <p>{profileText}</p>
      </div>
    </header>
  );
}
