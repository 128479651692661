import { useEffect } from "react";
export default function RadioBox({
  placeholder,
  handleChange,
  classes,
  value,
  key,
  type,
  disabled,
  checked,
}) {
  return (
    <input
      type={"radio"}
      checked={checked && checked}
      className={`form-control
        ${classes}`}
      id="exampleFormControlInput1"
      value={value}
      onChange={(e) => handleChange && handleChange(e.target.value)}
      disabled={disabled ? disabled : false}
    />
  );
}
