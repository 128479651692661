import { useState, useRef } from "react";
import { useParams } from "react";
export default function BookCoverUpload(props) {
  const previewImageRef = useRef();

  const uploadCover = (e) => {
    let [file] = e.target.files;
    if (file) {
      props.setFileSelected(URL.createObjectURL(file));
      previewImageRef.src = URL.createObjectURL(file);
      props.setCoverPhoto(file);
      const image = getBase64(file);
      image.then((res) => {});
    }
  };

  const removeCover = () => {
    props.setFileSelected("");
    props.setCoverPhoto("");
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center gap-2 mt-1">
        <div className="p-0">
          <div className="m-2 text-center">
            <div className="flex justify-center my-2">
              {!props.fileSelected && (
                <div className="w-full p-2 w-full">
                  <div className="flex flex-col items-center justify-center">
                    <label for="files" class="btn">
                      Select Image{" "}
                      <span className="text-red-500 text-2xl">*</span>
                    </label>
                    <input
                      type="file"
                      onChange={(e) => uploadCover(e)}
                      className="text-sm 
                        file:mr-2
                        file:py-2
                        file:px-1
                        file:rounded-full
                        file:border
                        file:border-gray
                        file:text-md
                        file:text-black
                        hover:file:cursor-pointer
                        z-45"
                    />
                    <p className="text-xs">Click to upload</p>
                    <p className="text-xs"> (jpeg, jpg, png)</p>
                  </div>
                </div>
              )}

              {props.fileSelected && (
                <div className="w-32 bg-gray-100 p-2 relative">
                  <img src={props.fileSelected} className="w-full" />
                  <div
                    className="bg-white rounded rounded-full bg-white p-0 top-0 right-0 absolute px-1 cursor-pointer"
                    onClick={() => removeCover()}
                  >
                    <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
}
