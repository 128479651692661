export default function LeftPanel(props) {
  const { products, truncateText, openProductCalendar } = props;

  return (
    <div className="flex flex-col items-start w-full justify-start col-span-3 md:col-span-1 text-left border">
      {/* <div className="font-bold border w-full p-2 h-10">Product</div> */}
      {products.length ? (
        products.map((p) => {
          return (
            <div
              className="border text-sm w-full p-2 h-10 cursor-pointer"
              onClick={() => openProductCalendar(p)}
            >
              {truncateText(p.book_data.name, 17)}
            </div>
          );
        })
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
