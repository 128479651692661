import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import FreeContentCoverLinks from "../rich-page/components/free-content-cover-links";
import TierUpgradeNoticeModal from "../../../modals/data-tier-upgrade-notice";
import PublisherTutorialBar from "../../../layout/nav/header/mobile/tutorial-bar";
import {
  isAdmin,
  API_BASE,
  isPublisher,
  isAuthUser,
} from "../../../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
import Switch from "react-switch";
export default function CreateBook() {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("mm");
  const [publisherId, setPublisherId] = useState("");
  const [fileSelected, setFileSelected] = useState("");
  const [processing, setProcessing] = useState(false);
  const [published, setPublished] = useState(false);
  const [paymentType, setPaymentType] = useState(["one-time"]);
  const [setting, setSetting] = useState({});
  const [tierNoticeModalOpen, setTierNoticeModalOpen] = useState(false);
  const [tierModalNotice, setTierModalNotice] = useState("");
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState("");

  const url = API_BASE + "/api/book-create";
  let { userId } = useParams();
  let { seriesId } = useParams();
  let { type } = useParams();
  const previewImageRef = useRef();
  const addBook = () => {
    if (Number(price) < 0.5) {
      toast(
        "Sorry, price must be $0.50 or greater as our payment providers take a percentage of the payment",
        { type: "notice" }
      );
      return false;
    }

    setProcessing(true);
    let formData = new FormData();
    formData.append("name", name);
    formData.append("price", Number(price));
    formData.append("user_id", publisherId);
    formData.append("type", type);
    formData.append("publisher_publish_status", published ? 1 : 0);
    if (seriesId) {
      formData.append("series_id", seriesId);
    }
    formData.append("cover_photo", coverPhoto);
    formData.append("tags", JSON.stringify(tags));
    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);

        if (response.status == 200) {
          if (seriesId) {
            window.location.replace(
              `/publisher/${userId}/publications/series/${seriesId}`
            );
          } else {
            window.location.replace(
              ` /publisher/${userId}/publications/series/m/book/${response.data.book.id}`
            );
          }
        }
      })
      .catch((error) => {
        setProcessing(false);
        if ("larger_than_data_tier" in error.response.data) {
          let data = error.response.data["larger_than_data_tier"];
          setTierModalNotice(data.message_one);
          setTierNoticeModalOpen(true);
          return;
        }
        if ("will_break_my_tier_limit" in error.response.data) {
          let data = error.response.data["will_break_my_tier_limit"];
          setTierModalNotice(data.message_two);
          setTierNoticeModalOpen(true);
          return;
        }
        if (error.response.status == 422) {
          if ("duplicate" in error.response.data) {
            toast("A content item already exists with that name", {
              type: "error",
            });

            return false;
          } else {
            let errorValues = Object.values(error.response.data.errors).flat();
            for (let index = 0; index < errorValues.length; index++) {
              const element = errorValues[index];
              toast(element, {
                type: "error",
              });
            }
            return false;
          }
        }

        if ("larger_than_max_upload_limit" in error.response.data) {
          toast("You cannot upload a file larger than 20 MB", {
            type: "error",
          });
          return false;
        }
        toast("Something went wrong!", { type: "error" });
      });
  };

  const uploadCover = (e) => {
    let [file] = e.target.files;
    if (
      file["type"] !== "image/png" &&
      file["type"] !== "image/jpeg" &&
      file["type"] !== "image/gif" &&
      file["type"] !== "image/webp"
    ) {
      toast("Invalid file type, please upload (JPEG, PNG, GIF or WEBP)", {
        type: "error",
      });
      return false;
    }
    if (file) {
      setFileSelected(URL.createObjectURL(file));
      previewImageRef.src = URL.createObjectURL(file);
      setCoverPhoto(file);
      console.log(file);
      //   previewImageRef.classList.remove("hidden");
      const image = getBase64(file);
      image.then((res) => {
        // console.log("My image", res);
      });
    }
  };

  const removeCover = () => {
    setFileSelected("");
    setCoverPhoto("");
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleNameChange = (name) => {
    setName(name);
  };

  const handlePriceChange = (price) => {
    setPrice(price);
  };

  const handlePuublishStatus = (e) => {
    setPublished((prev) => !prev);
  };

  const hideTierNoticeModal = () => {
    setTierNoticeModalOpen(false);
  };

  const handleTagChange = (tag) => {
    setTag(tag);
  };

  const getBookType = () => {
    if (type == "book") return "Book";
    if (type == "comic") return "Comic";
    if (type == "photo-album") return "Photo Album";
    if (type == "slides") return "Slides";
  };

  const handlePaymentTypeChange = (type) => {
    if (!paymentType.includes(type)) {
      setPaymentType([]);
      setPaymentType((prev) => [...prev, type]);
    } else {
      let newTypes = paymentType.filter((t) => t !== type);
      setPaymentType(newTypes);
    }
  };

  const getSystemSetting = () => {
    const url = API_BASE + "/api/system/settings";
    setProcessing(true);

    axios
      .get(url)
      .then((response) => {
        setProcessing(false);
        setSetting(response.data);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const addTag = () => {
    if (tags.length == 10) {
      toast("Only a maximum of 10 tags allowed", { type: "error" });
      return false;
    }
    if (tags) {
      let tgs = tag.split(",");
      if (tgs.length > 1) {
        for (let index = 0; index < tgs.length; index++) {
          if (tags.includes(tgs[index].trim())) {
            toast(tgs[index] + " already exists!", { type: "error" });
            return false;
          }
        }
        let trimEmptyTags = tgs.filter(
          (t) => t !== null && t !== " " && t !== ""
        );
        let trimTags = trimEmptyTags.map((t) => t.trim());
        if (trimTags.length + tags.length > 10) {
          toast("Only a maximum of 10 tags allowed", { type: "error" });
          return false;
        }
        setTags((prev) => [...prev, ...trimTags]);
      } else {
        if (tags.includes(tag)) {
          toast(tag + " already exists!", { type: "error" });
          return false;
        }
        let trimTag = tag.trim();
        setTags((prev) => [...prev, trimTag]);
      }
    }
    setTag("");
  };

  const removeTag = (t) => {
    let currentTags = tags;
    let filteredTags = currentTags.filter((x) => x !== t);
    setTags(filteredTags);
  };

  useEffect(() => {
    // alert(seriesId);
    setPublisherId(userId);
    if (!isPublisher() || JSON.parse(isAuthUser()).uuid !== userId) {
      window.location.href = "/";
    }
    getSystemSetting();
  }, []);

  useEffect(() => {
    console.log("payment type", paymentType);
  }, [paymentType]);
  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div className="md:w-full md:flex md:justify-start">
          <h1 className="text-2xl text-center m-2 font-bold">
            Create {getBookType()}
          </h1>
        </div>

        <PublisherTutorialBar
          classes={
            "w-full text-center md:flex md:justify-center md:items-center self-center"
          }
          btClass={"hidden md:flex"}
        />

        <div className="md:w-full text-end">
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-4 mt-8">
        <div className="w-10/12 md:w-5/12 bg-gray-50 p-4">
          <div className="m-2 mb-6">
            <label className="text-black">Name</label>
            <TextField
              classes={"p-6 my-2"}
              placeholder={"Name.."}
              handleChange={handleNameChange}
            />
          </div>

          <div className="m-2 mb-8">
            <label className="text-black">Price (USD)</label>
            <TextField
              classes={"p-6 my-2"}
              placeholder={"Price.."}
              type="number"
              handleChange={handlePriceChange}
              value={price}
            />
            {setting.constant_fee_on_payments && price
              ? `Service Charge $${Number(
                  setting.constant_fee_on_payments
                )} + ${price} = $${
                  Number(price) + Number(setting.constant_fee_on_payments)
                }`
              : ``}
          </div>

          <div className="my-4 mx-4 text-center ">
            <br />
            <div className="flex justify-around">
              <label className="flex items-center ">
                <div>
                  {" "}
                  <span className="font-bold">
                    {published ? "Published" : "Draft"}
                  </span>
                </div>
              </label>
              <div className="flex items-center">
                <Switch onChange={handlePuublishStatus} checked={published} />
              </div>
            </div>
          </div>
          <hr />
          <div className="m-2 text-center">
            <label className="text-black m-2">Cover Photo</label>
            <div className="flex justify-center my-2">
              {!fileSelected && (
                <div className="w-32 bg-gray-100 p-2">
                  <div className="flex flex-col justify-center  h-full">
                    <i class="fa fa-plus cursor-pointer text-green-500"></i>
                    <p className="text-xs">Click to upload</p>
                    <p className="text-xs"> (jpeg, jpg, png)</p>
                    <label className="my-2">
                      <input
                        id="fileName"
                        type="file"
                        onChange={(e) => uploadCover(e)}
                        class="text-sm 
                        text-grey-500
                        file:mr-2
                        file:py-2
                        file:px-2
                        file:rounded-full
                        file:border-0
                        file:text-md
                        file:font-semibold
                        file:text-white
                        file:bg-gradient-to-r
                        file:from-blue-600
                        file:to-amber-600
                        hover:file:cursor-pointer
                        hover:file:opacity-80"
                      />
                    </label>
                    {/* <input type="file" onChange={(e) => uploadCover(e)} /> */}
                  </div>
                </div>
              )}

              {fileSelected && (
                <div className="w-32  bg-gray-100 p-2 relative">
                  <img src={fileSelected} className="w-full" />
                  <div
                    className="bg-white rounded rounded-full bg-white p-0 top-0 right-0 absolute px-1 cursor-pointer"
                    onClick={() => removeCover()}
                  >
                    <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
                  </div>
                </div>
              )}
            </div>
            {/* <TextField
              classes={"p-6"}
              placeholder={"Email.."}
              handleChange={handleEmailChange}
            /> */}
          </div>
          <FreeContentCoverLinks />
          <div className="m-2 mb-8 flex flex-col items-center">
            <label className="text-black">Enter Tags</label>
            <small>
              Enter at least 3 tags describing your content and separated by
              commas
            </small>
            <TextField
              classes={"p-6 my-2 text-xxs"}
              placeholder={
                "Tags...(eg office manual, news, school work, services, etc)"
              }
              handleChange={handleTagChange}
              value={tag}
            />
            <small>You can always add or delete a tag afterwards</small>
            <BasicButton
              disabled={!tag || tags.length == 10}
              title={processing ? "Adding.." : "Add Tag(s)"}
              classes={"p-6 w-9/12 mt-4"}
              handleClick={addTag}
            />
            <div className="m-2 flex flex-wrap justify-center">
              {tags.map((t) => (
                <div className="rounded-full text-black border p-2 inline relative m-2 px-3">
                  <span className="text-sm pr-1">
                    ${t}
                    <span
                      className="fa fa-times absolute top-3 right-1 text-xs cursor-pointer text-gray-500"
                      onClick={() => removeTag(t)}
                    ></span>
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div className="text-center">
            <BasicButton
              disabled={!name || !fileSelected || processing || tags.length < 3}
              title={processing ? "Creating.." : "Create"}
              classes={"p-6 w-9/12 mt-4"}
              handleClick={addBook}
            />
          </div>
        </div>
        <div></div>
      </div>
      <TierUpgradeNoticeModal
        modalOpen={tierNoticeModalOpen}
        hideModal={hideTierNoticeModal}
        notice={tierModalNotice}
      />
    </>
  );
}
