import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import { useState, useEffect } from "react";
import { IdentityOptions } from "./components/identity-options-list";
import AddressVerification from "./components/address-verification";
import ProfileVerification from "./components/identity-profile-verification";
import LinkVerification from "./components/identity-profile-link-verification";
import FacialVerification from "./components/facial-verification";
import BizNameVerification from "./components/identity-biz-name-verification";
import BizLinkVerification from "./components/identity-biz-link-verification";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";
import { API_BASE } from "../../../Utils/helpers";
import { useParams } from "react-router-dom";

export default function ApplicationStepper(props) {
  const [profileOption, setProfileOption] = useState("");
  const [profileLink, setProfileLink] = useState("");
  const [bizLink, setBizLink] = useState("");
  const [bizName, setBizName] = useState("");
  const [image, setImage] = useState();
  const [code, setCode] = useState();
  const [video, setVideo] = useState();
  const [savedImage, setSavedImage] = useState();
  const [savedVideo, setSavedVideo] = useState();
  const [consent, setConsent] = useState(false);

  const [contractFile, setContractFile] = useState("");
  const [location, setLocation] = useState({
    biz_lat: "",
    biz_lng: "",
    address: "",
  });
  const [savingStage, setSavingStage] = useState(false);
  const [verification, setVerification] = useState({});
  const [mode, setMode] = useState("default");
  const [profileUpdateRedo, setProfileUpdateRedo] = useState(false);
  const [userResponseToAdminMode, setUserResponseToAdminMode] = useState(false);

  const { action } = useParams();
  const { id } = useParams();

  const {
    stage,
    regType,
    setStage,
    setRegType,
    handleNextStage,
    handlePrevStage,
    redoMessageMode,
    setRedoMessageMode,
    setSubmitted,
    submitted,
    approved,
    setApproved,
    rejected,
    setRejected,
    setLoadingPageFail,
    loadingPageFail,
    setLoadingPage,
    loadingPage,
    setProfoileVerification,
  } = props;

  const appicationStatus = {
    redo_visual_verification: "Redo Visual Verification",
    redo_business_verification: "Redo Business Verification",
    redo_contract_verification: "Redo Contract Verification",
    redo_supplier_verification: "Redo Supplier Verification",
  };

  const getVerificationData = () => {
    setLoadingPage(true);
    const url = API_BASE + `/sales/supplier/application/${id}/retrieve`;
    setSavingStage(true);
    authRequestService()
      .get(url)
      .then((res) => {
        setVerification(res.data.verification);
        setLoadingPage(false);
      })
      .catch((err) => {
        setSavingStage(false);
        setLoadingPage(false);
        setLoadingPageFail(true);
      });
  };

  useEffect(() => {
    if (verification?.id) {
      //business location updated, needs to redo visual verification
      // if (isProfileUpdateVerification()) return;
      if (isVerificationApprovedMode()) return;
      if (isVerificationRejectedMode()) return;
      if (
        verification.status == "unprocessed" ||
        verification.status == "redo_supplier_verification"
      ) {
        //submitted and awaiting admin action
        if (verification.stage == 5) {
          setSubmitted(true);
        } else {
          if (verification.status == "redo_supplier_verification") {
            setRedoMessageMode(true);
            setStage(verification.stage + 1);
            setRegType(verification.type);
          } else {
            if (verification.stage == 5) {
              setStage(verification.stage + 1);
            } else {
              setStage(verification.stage + 1);
            }
            setRegType(verification.type);
          }
        }
      } else {
        //admin actions with a redo
        setMode("redo");
        setRedoMessageMode(true);
        setRegType(verification.type);
        if (verification.status == "redo_business_verification") setStage(2);
        if (verification.status == "redo_visual_verification") setStage(5);
        // if (verification.status == "redo_supplier_verification")
        //   setMode("default");
      }
      if (verification?.profile_identity)
        setProfileOption(verification?.profile_identity);
      if (verification?.profile_identity_link)
        setProfileLink(verification?.profile_identity_link);
      if (verification?.business_name) setBizName(verification?.business_name);
      if (verification?.business_link) setBizLink(verification?.business_link);
      if (verification?.address)
        setLocation((prev) => ({ ...prev, address: verification?.address }));
      if (verification?.photo) setSavedImage(verification?.photo);
      if (verification?.video) setSavedVideo(verification?.video);
      if (verification?.consent) setConsent(verification?.consent);
    }
  }, [verification]);

  useEffect(() => {
    console.log("blob", image);
  }, [image]);

  /*modes*/
  // const isProfileUpdateVerification = () => {
  //   if (
  //     verification.status == "approved" &&
  //     verification.updated_address &&
  //     verification.stage == 9 &&
  //     !verification.redo_on_profile_update
  //   ) {
  //     setRegType(verification.type);
  //     setMode("redo");
  //     setProfileUpdateRedo(true);
  //     setSubmitted(true);
  //     setApproved(true);
  //     setProfoileVerification(true);
  //     setStage(5);
  //     return true;
  //   }
  //   return false;
  // };

  const isVerificationApprovedMode = () => {
    if (verification.status == "approved") {
      //approved and no further action required
      setApproved(true);
      return true;
    }
    return false;
  };

  const isVerificationRejectedMode = () => {
    if (verification.status == "rejected") {
      //approved and no further action required
      setRejected(true);
      return true;
    }
    return false;
  };

  const handleProfileOptionChange = (e) => {
    setProfileOption(e);
  };

  const handleProfileLinkChange = (e) => {
    setProfileLink(e);
  };

  const handleBizNameChange = (e) => {
    setBizName(e);
  };

  const handleBizLinkChange = (e) => {
    setBizLink(e);
  };

  useEffect(() => {
    getVerificationData();
  }, []);

  const shouldRenderStepper = () => {
    if (
      !submitted &&
      !approved &&
      !profileUpdateRedo &&
      !redoMessageMode &&
      !rejected
    )
      return true;
    if (submitted && approved && profileUpdateRedo) return true;
    return false;
  };
  // loadingPage;
  return (
    !loadingPage &&
    !loadingPageFail && (
      <div className="w-full flex flex-col items-center h-screen">
        {shouldRenderStepper() ? (
          regType &&
          stage > 1 && (
            <>
              <div className="p-2 w-full flex flex-col items-center">
                {stage == 2 && regType == "individual" && (
                  <div className="flex flex-col justify-center items-center gap-4 mt-8 p-2">
                    <div className="p-2 w-full  shadow">
                      <div className="p-2 w-full flex flex-col">
                        <label for="password" class="text-gray-500 mb-2">
                          Verify Your Identity Using the Following Options
                        </label>
                        <small className="text-xxs">
                          Choose a platform with a clear facial photo and
                          significant activity on your profile. Non-compliance
                          will lead to application rejection.
                        </small>

                        <ProfileVerification
                          stage={stage}
                          regType={regType}
                          profileOption={profileOption}
                          options={IdentityOptions}
                          handlePrevStage={handlePrevStage}
                          handleNextStage={handleNextStage}
                          handleProfileOptionChange={handleProfileOptionChange}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {stage == 2 && regType == "business" && (
                  <div className="flex flex-col justify-center items-center gap-4 mt-8 p-2 w-full">
                    <div className="p-2 w-full  shadow w-full">
                      <div className="p-2 w-full flex flex-col">
                        <BizNameVerification
                          stage={stage}
                          regType={regType}
                          bizName={bizName}
                          handlePrevStage={handlePrevStage}
                          handleNextStage={handleNextStage}
                          handleBizNameChange={handleBizNameChange}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {stage == 3 && regType == "business" && (
                  <div className="flex flex-col justify-center items-center gap-4 mt-8 p-2 w-full">
                    <div className="p-2 w-full  shadow">
                      <div className="p-2 w-full flex flex-col">
                        <BizLinkVerification
                          stage={stage}
                          regType={regType}
                          bizLink={bizLink}
                          profileOption={profileOption}
                          profileLink={profileLink}
                          handlePrevStage={handlePrevStage}
                          handleNextStage={handleNextStage}
                          handleBizLinkChange={handleBizLinkChange}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {stage == 3 && regType == "individual" && (
                  <div className="flex flex-col justify-center items-center gap-4 mt-8 p-2 w-full">
                    <div className="p-2 w-full  shadow">
                      <div className="p-2 w-full flex flex-col">
                        <LinkVerification
                          stage={stage}
                          regType={regType}
                          handleProfileLinkChange={handleProfileLinkChange}
                          profileLink={profileLink}
                          handlePrevStage={handlePrevStage}
                          handleNextStage={handleNextStage}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {stage == 4 && (
                  <div className="flex flex-col items-center mt-10 shadow p-10 w-full">
                    <div className="flex flex-col justify-center items-center gap-4 mt-8 w-full">
                      <AddressVerification
                        mode={mode}
                        stage={stage}
                        regType={regType}
                        handlePrevStage={handlePrevStage}
                        handleNextStage={handleNextStage}
                        setLocation={setLocation}
                        location={location}
                        getVerificationData={getVerificationData}
                      />
                    </div>
                  </div>
                )}

                {stage == 5 && (
                  <div className="flex flex-col items-center my-10 shadow p-10">
                    <div className="flex flex-col justify-center items-center gap-4 my-8">
                      <FacialVerification
                        mode={mode}
                        code={code}
                        image={image}
                        stage={stage}
                        regType={regType}
                        handlePrevStage={handlePrevStage}
                        handleNextStage={handleNextStage}
                        setImage={setImage}
                        profileUpdateRedo={profileUpdateRedo}
                        getVerificationData={getVerificationData}
                      />
                    </div>
                  </div>
                )}
              </div>
            </>
          )
        ) : (
          <>
            {" "}
            {!submitted &&
              !approved &&
              !profileUpdateRedo &&
              redoMessageMode &&
              verification.id && (
                <div className="w-full p-2 text-center">
                  <p>
                    Please &nbsp;
                    <a
                      className="text-blue-900 font-bold cursor-pointer"
                      onClick={() => setRedoMessageMode(false)}
                    >
                      click
                    </a>{" "}
                    Here to {appicationStatus[verification.status]}.
                  </p>
                  <br />
                  <p>{verification.admin_comment}</p>
                </div>
              )}
            {submitted && !profileUpdateRedo && (
              <p className="p-2">
                Your application to become a Product Supplier has been
                successfully submitted and is now under review. You can
                anticipate a response within a few hours to a maximum of four
                business days.
              </p>
            )}
            {approved && !profileUpdateRedo && (
              <div>
                {" "}
                <p className="p-2">
                  Your Product Supplier application has been successfully
                  approved. To create a product page, please click{" "}
                  <a href="/publisher/create/options" className="text-blue-500">
                    Here
                  </a>
                  , and select “Create Product Page”.
                </p>
                <p className="p-2">
                  While you are awaiting approval, you can still create and
                  monetize content pages by using the "Create Content Page"
                  button.
                </p>
              </div>
            )}
            {rejected && (
              <p className="p-2">
                Your Product Supplier application was rejected.
              </p>
            )}
          </>
        )}
      </div>
    )
  );
}
