import TextField from "../../../inputs/text-input";
export default function SearchBar(props) {
  const { clearSearchText, handleSearchTextChange, searchText } = props;

  return (
    <div className="w-full md:col-span-2 relative text-center">
      <i
        class="fa fa-times-circle text-black  z-50 z-50 text-2xl absolute right-1 -top-0 z-40 cursor-pointer"
        onClick={() => clearSearchText()}
      ></i>

      <TextField
        title="Search"
        placeholder={"Search (name, title, ID, or multi-tags)"}
        classes="rounded-lg landing-page-search"
        handleChange={handleSearchTextChange}
        value={searchText}
      />

      <small className="w-full text-center my-xsm">
        Multi-tag search allowed. Include $ before each tag (e.g., John $school
        work $remote services $fun etc.)
      </small>
      {searchText.includes("$") && (
        <p>
          {" "}
          <small className="w-full text-center my-xsm text-blue-500">
            Use & to get a result(s) that include all searched tags (e.g., &
            John $school work $remote services $fun etc.)
          </small>
        </p>
      )}
    </div>
  );
}
