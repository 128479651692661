import { classes } from "istanbul-lib-coverage";
import BasicButton from "../../../../../components/buttons/basic-button";
export default function SubCapNotice(props) {
  const { meta } = props;
  const { data } = props;
  const { canRenderSubCapNotice } = props;
  const { handleSubCapTermsAcceptedChange } = props;
  const { handleSubCapTermsAcceptedChangeBack } = props;

  const getSubCycle = () => {
    if (data?.name?.includes("Monthly")) return "Monthly (30 days)";
    if (data?.name?.includes("Fortnightly")) return "Fortnightly (14 days)";
    if (data?.name?.includes("Weekly")) return "Weekly (7 days)";
    if (data?.name?.includes("Daily")) return "Daily (1 days)";
  };
  return (
    canRenderSubCapNotice() && (
      <div className="pl-5 list-disc">
        <p className="font-bold">
          Please be aware that by proceeding with this purchase, you are
          agreeing to the terms below:
        </p>
        <div className="pl-5 list-disc">
          {" "}
          <li className="text-left">
            {" "}
            You will complete {meta.subscription_cap_cycle} subscriptions.
          </li>
          <li className="text-left">
            Each subscription cycle is {getSubCycle()}.
          </li>
          <li className="text-left">
            You cannot cancel, pause, or modify the subscription until all
            agreed-upon subscriptions are fulfilled.
          </li>
          <li className="text-left">
            You will be charged ${data?.price} for each subscription.
          </li>
        </div>
        <div className="flex gap-1">
          <BasicButton
            title={"Change Date"}
            classes={"bg-black text-white w-full"}
            handleClick={handleSubCapTermsAcceptedChangeBack}
          />{" "}
          <BasicButton
            title={"I agree to the terms"}
            classes={"my-bg-yellow-500 text-black w-full"}
            handleClick={handleSubCapTermsAcceptedChange}
          />
        </div>
      </div>
    )
  );
}
