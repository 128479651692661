import { useState, useRef } from "react";
import { useParams } from "react";
export default function MultiImageUploaderNew(props) {
  const previewImageRef = useRef();

  const uploadCover = (e) => {
    let files = e.target.files;
    if (files.length) {
      props.setFileSelected(files);
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center gap-2 mt-1 my-4">
        <div className="p-0">
          <div className="flex flex-col items-center justify-center">
            <label for="files" class="btn">
              Select Image (optional)
            </label>
            <input
              multiple
              type="file"
              onChange={(e) => uploadCover(e)}
              className="
                    text-sm 
                        file:mr-2
                        file:py-2
                        file:px-1
                        file:rounded-full
                        file:border
                        file:border-gray
                        file:text-md
                        file:text-black
                        hover:file:cursor-pointer
                        z-45"
            />
            <p className="text-xs">Click to upload</p>
            <p className="text-xs"> (jpeg, jpg, png)</p>
          </div>
        </div>
      </div>
    </>
  );
}
