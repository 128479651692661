import BasicButton from "../../../../../../components/buttons/basic-button";
export default function MainSubButton(props) {
  return (
    <div className="my-1">
      {" "}
      {props.showCalendar &&
      props.selType !== "short" &&
      !props.checkingShortAvailability ? (
        <div className="flex justify-center w-full">
          <BasicButton
            disabled={!props.productReplyMeta.start_date || props.processing}
            title={
              props.processing
                ? "Processing.."
                : props.getLabelAndPrice().label +
                  " $" +
                  props.getLabelAndPrice().price
            }
            classes="px-8 w-8/12"
            handleClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              props.setShowCalendar(false);
            }}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
