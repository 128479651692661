import axios from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import WebHeader from "../../web/header";
import "react-toastify/dist/ReactToastify.css";
import {
  API_BASE,
  getClientBaseURL,
  getLocation,
  isAuthUser,
  authUserData,
} from "../../../Utils/helpers";
import { useNavigate, Link } from "react-router-dom";
import Countries from "../../../components/country/country";
import Timezones from "../../../components/timezone/timezone";
import BookCoverUpload from "../../publisher/rich-page/components/book-cover-upload";
import ImageUpload from "../../publisher/rich-page/components/image-upload";
import BasicButton from "../../../components/buttons/basic-button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useParams } from "react-router-dom";
import TextField from "../../../components/inputs/text-input";

export default function PublisherActivationForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("xxx");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [emailConfirmationMode, setEmailConfirmationMode] = useState(false);
  const [timeZone, setTimezone] = useState("xxx");
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [subSecretKey, setSubSecretKey] = useState();
  const [showSubModal, setShowSubModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [coverPhoto, setCoverPhoto] = useState("mm");
  const [fileSelected, setFileSelected] = useState("");
  const [bioFormSubmit, setbioFormSubmit] = useState(false);
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [mode, setMode] = useState("");
  const [emailOTPSent, setEmailOTPSent] = useState(false);
  const [user, setUser] = useState({});
  const [userHasPhoto, setUserHasPhoto] = useState(false);
  const [isAltVerification, setAltVerification] = useState(false);

  const naviagte = useNavigate();
  let userId = authUserData().uuid;
  let { type } = useParams();

  const saveVerificationData = () => {
    setIsAuthenticating(true);
    const url = API_BASE + "/api/user/publisher-activation";
    let formData = new FormData();
    formData.append("timezone", timeZone);
    formData.append("country", country);
    formData.append("photo", coverPhoto);
    formData.append("user_id", userId);
    setProcessing(true);
    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setMode("send-otp");
        }
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.status == 422) {
          toast("Duplicate registration, email address already registered!", {
            type: "error",
          });
        } else {
          toast("Something went wrong!", { type: "error" });
        }
      });
  };

  const sendOTP = () => {
    setIsAuthenticating(true);
    const url = API_BASE + "/api/user/user/publisher-activation/send-otp";
    let formData = new FormData();
    formData.append("phone", phone);
    formData.append("user_id", userId);

    setProcessing(true);
    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setMode("verify-otp");
        }
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.status == 422) {
          toast("Phone number already used!", {
            type: "error",
          });
        } else {
          setMode("verify-otp");
        }
      });
  };

  const verifyOTP = () => {
    setIsAuthenticating(true);
    const url = API_BASE + "/api/user/user/publisher-activation/verify-otp";
    let formData = new FormData();
    formData.append("otp", code);
    formData.append("phone", phone.replace(/\s/g, ""));
    formData.append("user_id", userId);
    formData.append("link", generateAdminLink());
    if (localStorage.getItem("content-creator-redirect") !== null) {
      formData.append("type", "parallel");
    } else {
      formData.append("type", "basic");
    }
    setProcessing(true);
    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          // setMode("verification-complete");
          toast("Your Verififcation was successful!", {
            type: "success",
          });

          setTimeout(() => {
            if (response.data) {
              let user = response.data.user;
              if (user.roles && user.roles.length > 0) {
                localStorage.setItem("user", JSON.stringify(user));
                if (localStorage.getItem("content-creator-redirect") !== null) {
                  localStorage.removeItem("content-creator-redirect");
                  naviagte(
                    "/web/rich-page/render/f3ae1fdc-4e38-4ba2-9aa2-27d2a7c5b74e"
                  );
                } else if (
                  localStorage.getItem("supplier-signup-redirect") !== null ||
                  type == "supplier"
                ) {
                  localStorage.setItem("supplier-signup-redirect", "xx");
                  naviagte("/publisher/supplier/onboarding/start");
                  // localStorage.removeItem("supplier-signup-redirect");
                  // naviagte(
                  //   "/web/rich-page/render/8ac0c3f5-975a-436c-a42a-fc253b3e3fa8"
                  // );
                } else {
                  //  naviagte("/publisher/create/options");
                  window.location.href =
                    "https://litzapp.com/share/sh/9FseccuBc5";
                }
                // if ((type = "content")) {  } else {
                //   naviagte("/publisher/supplier/onboarding/start");
                // }
              } else {
                toast("Unauthorized Access", { type: "error" });
              }
            }
          }, 500);
        }
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.status == 422) {
          toast("Invalid OTP code.", {
            type: "error",
          });
        } else {
          toast("Something went wrong!", { type: "error" });
        }
      });
  };

  const sendEmailOTP = () => {
    setIsAuthenticating(true);
    const url = API_BASE + "/api/user/user/publisher-activation/send-email-otp";
    let formData = new FormData();
    formData.append("phone", phone.replace(/\s/g, ""));
    formData.append("user_id", userId);
    setProcessing(true);
    axios
      .post(url, formData)
      .then((response) => {
        setAltVerification(false);
        setEmailOTPSent(true);
        setProcessing(false);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const generateAdminLink = () => {
    return getLocation().hostname == "litzapp.com"
      ? getClientBaseURL() + `/admin/activation/${userId}`
      : getClientBaseURL() + `:3000/admin/activation/${userId}`;
  };

  const fetchUser = () => {
    const url = API_BASE + "/api/user/" + userId;

    setProcessing(true);
    axios
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setUser(response.data.user);
          // if (response.data.user.profile_photo) {
          //   setUserHasPhoto(true);
          // }
          if (type == "supplier") {
            setUserHasPhoto(true);
            if (!response.data.user.phone) {
              setMode("send-otp");
              return false;
            }
            if (response.data.user.phone.verified) {
              setMode("verification-complete");
              let user = response.data.user;
              if (user.roles && user.roles.length > 0) {
                localStorage.setItem("user", JSON.stringify(user));
                // localStorage.setItem("user_email", user.email);
                localStorage.setItem("token", response.data.access_token);
              }
            } else {
              setMode("send-otp");
            }
          } else {
            if (response.data.user.verification) {
              if (!response.data.user.phone) {
                setMode("send-otp");
                return false;
              }
              if (response.data.user.phone.verified) {
                setMode("verification-complete");
                let user = response.data.user;
                if (user.roles && user.roles.length > 0) {
                  localStorage.setItem("user", JSON.stringify(user));
                  // localStorage.setItem("user_email", user.email);
                  localStorage.setItem("token", response.data.access_token);
                }
              } else {
                setMode("send-otp");
              }
            } else {
              setMode("bio");
            }
          }
        }
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.status == 422) {
          toast("Duplicate registration, email address already registered!", {
            type: "error",
          });
        } else {
          toast("Something went wrong!", { type: "error" });
        }
      });
  };

  const handleTimezoneChange = (e) => {
    setTimezone(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleOTPChange = (e) => {
    setCode(e);
  };

  const handleCoverPhotoChange = (value) => {
    setFileSelected("");
    setCoverPhoto(value);
  };

  const handleSelectedFileChange = (file) => {
    setFileSelected(file);
  };

  const handleConfirmationValidation = () => {
    setMode("send-otp");
  };

  const requiresUploadValidation = () => {
    if (userHasPhoto) {
      return true;
    } else if (!coverPhoto) {
      return false;
    }

    return true;
  };

  const shouldShowPhoneINout = () => {
    if (mode == "send-otp") return true;
    if (mode == "send-otp") return true;
  };

  const showOptionalVerification = () => {
    setAltVerification(true);
  };

  const restoreDefaultOPTMode = () => {
    setMode("send-otp");
    setAltVerification(false);
  };

  useEffect(() => {
    if (!isAuthUser()) {
      window.location.href = "/";
    }
    fetchUser();
  }, []);

  return (
    <>
      <div className="flex flex-col items-center">
        {mode && mode == "bio" && (
          <div class="container px-6 md:w-9/12">
            <br />
            <div class="flex flex-col text-center md:text-left justify-evenly items-center">
              <div class="flex flex-col w-full items-center">
                <h1 class="text-2xl text-gray-800 font-bold my-2">
                  {type == "supplier"
                    ? "Become a Product Monetizer"
                    : "Become a Content Monetizer"}
                </h1>
                <p class="mx-auto md:mx-0 text-gray-500">
                  Please fill out the form to continue
                </p>
              </div>
              <div className={`w-full md:w-6/12  mx-auto md:mx-0`}>
                <div class="bg-white p-10 flex flex-col w-full shadow-xl rounded-xl">
                  <form action="" class="w-full">
                    {/* <div id="input" class="flex flex-col w-full my-5">
                      <label for="password" class="text-gray-500 mb-2">
                        Timezone
                      </label>
                      <Timezones handleTimezoneChange={handleTimezoneChange} />
                    </div>

                    <div id="input" class="flex flex-col w-full my-5">
                      <label for="password" class="text-gray-500 mb-2">
                        Country
                      </label>
                      <Countries handleCountryChange={handleCountryChange} />
                    </div> */}

                    {!userHasPhoto ? (
                      <div
                        id="input"
                        class="flex flex-col items-center w-full my-5"
                      >
                        <label for="password" class="text-gray-500 mb-2">
                          Upload Photo
                        </label>
                        <small>
                          Upload a clear front facing photoraph of yourself
                        </small>
                        <ImageUpload
                          setCoverPhoto={handleCoverPhotoChange}
                          setFileSelected={handleSelectedFileChange}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    <div id="button" class="flex flex-col w-full my-5">
                      <button
                        disabled={
                          !requiresUploadValidation() ||
                          !timeZone ||
                          !country ||
                          processing
                        }
                        onClick={() => saveVerificationData()}
                        type="button"
                        class="w-full py-4 bg-black rounded-lg text-green-100"
                      >
                        {!processing ? (
                          <div class="flex flex-row items-center justify-center">
                            <div class="mr-2">
                              <svg
                                class="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                                ></path>
                              </svg>
                            </div>
                            <div class="font-bold">Apply</div>
                          </div>
                        ) : (
                          "Applying..."
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

        {mode == "send-otp" && (
          <div class="flex flex-col text-center md:text-left justify-evenly items-center w-full md:w-6/12">
            <div class="w-full text-center">
              <p class="text-lg text-gray-800 font-bold my-2">
                Please verify your phone number.
              </p>
            </div>
            <div className="">
              <PhoneInput
                country={"eg"}
                enableSearch={true}
                value={phone}
                onChange={(phone) => setPhone(phone)}
              />
            </div>
            <div id="button" class="flex flex-col my-5 w-full md:w-5/12 h-48">
              <button
                disabled={!phone || processing}
                onClick={() => sendOTP()}
                type="button"
                className="py-2 bg-black rounded-lg text-green-100 w-full"
              >
                {!processing ? (
                  <div class="flex flex-row items-center justify-center w-full">
                    <span class="font-bold w-full">Verify</span>
                  </div>
                ) : (
                  "Verifying..."
                )}
              </button>
            </div>
          </div>
        )}

        {mode && mode == "verify-otp" && (
          <div class="flex flex-col text-center md:text-left justify-evenly items-center w-full md:w-6/12">
            <div class="w-full text-center">
              <p class="text-lg text-gray-800 font-bold my-2">
                Please enter the OTP code sent to {phone}.
              </p>
            </div>
            <div className="">
              <TextField
                placeholder={"Enter OPT Code.."}
                handleChange={handleOTPChange}
              />
            </div>

            {emailOTPSent ? (
              <p className="text-center my-2 font-bold">
                An email has been sent to <b>{user?.email}</b>. Please check
                your Inbox, Spam folder, Trash, Promotions and Socials for this
                email.
              </p>
            ) : (
              ""
            )}

            {!emailOTPSent ? (
              <CountdownTimer
                showOptionalVerification={showOptionalVerification}
                isAltVerification={isAltVerification}
              />
            ) : (
              ""
            )}

            {isAltVerification && !emailOTPSent ? (
              <div
                id="button"
                class="flex flex-col my-2 gap-2 w-full md:w-5/12 h-48"
              >
                <button
                  disabled={processing}
                  onClick={() => restoreDefaultOPTMode()}
                  type="button"
                  className="p-2 bg-black rounded-lg text-green-100 w-full"
                >
                  If {phone} is incorrect, correct and resend OTP
                </button>
                <button
                  disabled={processing}
                  onClick={() => sendEmailOTP()}
                  type="button"
                  className="p-2 bg-black rounded-lg text-green-100 w-full"
                >
                  {processing
                    ? "Processing.."
                    : " Try another verification method"}
                </button>
              </div>
            ) : (
              ""
            )}
            {!isAltVerification ? (
              <div id="button" class="flex flex-col my-5 w-full md:w-5/12 h-48">
                <button
                  disabled={!phone || processing}
                  onClick={() => verifyOTP()}
                  type="button"
                  class="py-2 bg-black rounded-lg text-green-100"
                >
                  {!processing ? (
                    <div class="flex flex-row items-center justify-center">
                      <div class="font-bold">Verify</div>
                    </div>
                  ) : (
                    "Verifying..."
                  )}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        )}

        {mode && mode == "verification-complete" && (
          <div class="flex flex-col text-center md:text-left justify-evenly items-center w-full">
            <div class="w-full text-center">
              <p class="text-lg text-gray-800 font-bold my-2">
                Verification Successful
              </p>
            </div>

            <div id="button" class="flex flex-col my-5 md:w-4/12 h-48">
              <p className="text-center">
                Your application is currently being reviewed, you'll receive an
                email with the status of your application.
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export function CountdownTimer({
  showOptionalVerification,
  isAltVerification,
}) {
  const countDownDate = new Date().getTime();
  const [countDown, setCountDown] = useState(60);
  const [minutes, setMinutes] = useState(1);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const intervalReal = setInterval(() => {
      setCountDown(countDown - 1);
      clearInterval(intervalReal);
    }, 1000);

    if (countDown == 0) {
      clearInterval(intervalReal);
      showOptionalVerification();
      setVisible(false);
    }

    return () => {
      // clearInterval(intervalReal);
      // clearInterval(intervalFake);
    };
  }, [countDownDate]);

  const showOrHideCounter = () => {
    if (!isAltVerification) {
      return countDown;
    } else {
      return "";
    }
  };

  return <span>{` ${showOrHideCounter()}`} </span>;
}
