import BasicButton from "../components/buttons/basic-button";
import { toast } from "react-toastify";
import { API_BASE, BASE_URL } from "../Utils/helpers";
import axios from "axios";
import Switch from "react-switch";
export default function PayPerViewModal({
  modalOpen,
  hideModal,
  book,
  action,
  handlePagePerViewPagePositionChange,
  pagePerViewPositionValue,
  enablePayPerView,
  handlePayPerViewChange,
}) {
  return (
    <div
      className={`absolute z-10 overflow-y-auto top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-6/12 h-4/12 "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <h1 className="text-2xl font-bold text-center">Pay To View</h1>
            </div>

            <div className="flex flex-col justify-start my-6 bg-gray-50">
              <div className="w-full  p-2">
                <h1 className="text-lg  text-center text-black">Pay To View</h1>
              </div>
              <div className="m-2 mb-6 text-left">
                {/* <label className="text-black">Ad Timer Delay</label> */}
                <p>
                  Pay to View allows you to determine at what point content
                  viewers will have to either purchase or subscribe to view the
                  rest of the album in its entirety.
                </p>

                <select
                  disabled={!enablePayPerView}
                  className=" form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  onChange={(e) =>
                    handlePagePerViewPagePositionChange(e.target.value)
                  }
                  value={pagePerViewPositionValue}
                >
                  <option value=""></option>
                  {book &&
                    book.pages &&
                    book.pages.map((option) => (
                      <option value={option.position}>
                        {option.name} (Page {option.position})
                      </option>
                    ))}
                </select>
                <br />
                <label className="flex items-center gap-4">
                  <span>Enable</span>
                  <Switch
                    onChange={handlePayPerViewChange}
                    checked={enablePayPerView}
                  />
                </label>
              </div>
            </div>

            <div className="flex justify-center">
              <div className="">
                <BasicButton
                  title={"Update"}
                  classes="px-8"
                  handleClick={() => action()}
                />
              </div>

              {/* <p className="w-8/12"> Sign up to receive the latest updates:</p> */}
            </div>

            {/* <div className="flex justify-center my-2">
              <div className="w-8/12">
                <TermsAccordion />

                <div class="flex items-center mb-4">
                  <input
                    checked={termsConsent}
                    onChange={(e) => {
                      setTermsConsent((prev) => !prev);
                    }}
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="default-checkbox"
                    class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    I have read the Terms and Conditions, and I accept updates.
                  </label>
                </div>
              </div>
            </div> */}

            {/* <div className="flex justify-center">
              <div className="w-8/12  grid md:grid-cols-2 gap-4">
                <BasicButton
                  disabled={!termsConsent || !email}
                  title={"Agree & Signup"}
                  classes="px-8"
                  handleClick={() => signup()}
                />

                <BasicButton
                  title={"Cancel"}
                  classes="px-8"
                  handleClick={() => hideModal()}
                />
              </div>
            </div> */}

            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 flex justify-end">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
            {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
