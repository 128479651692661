export default function Stats(props) {
  const { productMetaData } = props;

  return (
    <>
      <p className="text-green-500">
        {productMetaData?.availablequantity} Available today{" "}
        {/* {productMetaData.id} */}
      </p>
      <p className="text-green-500">
        {productMetaData?.activesubcounttoday || "0"} concurrently subscribed
      </p>
      <p className="text-green-500">
        {productMetaData?.overallsubcount || "0"} overall subscriptions
      </p>
    </>
  );
}
