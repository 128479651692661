import "react-datepicker/dist/react-datepicker.css";
import TextField from "../../../../components/inputs/text-input";
import {
  isAuthUser,
  generateSalesApplicationLink,
  isResponsiveMode,
} from "../../../../Utils/helpers";
import { toast } from "react-toastify";
import BasicButton from "../../../../components/buttons/basic-button";
export default function PhoneVerification({
  modalOpen,
  hideModal,
  tmpPassword,
  handleTmpPasswordChange,
  phoneVerificationCodeChange,
  phoneVerificationCode,
  handleOTPChange,
  phoneOTP,
  updatePhone,
}) {
  // JSON.parse(isAuthUser()).uuid;

  const copyText = () => {
    // standard way of copying
    var textArea = document.createElement("textarea");
    textArea.setAttribute("style", "width:1px;border:0;opacity:0;");
    document.body.appendChild(textArea);
    textArea.value = generateSalesApplicationLink(
      JSON.parse(isAuthUser()).uuid
    );
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    toast("Copied", { type: "success" });
  };

  return (
    <div
      className={`${
        isResponsiveMode() ? "absolute" : "absolute"
      }  overflow-y-auto top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="relative inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-8/12"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className="rounded rounded-full p-0 -top-2 -right-1 absolute px-1 cursor-pointer opacity-75"
            onClick={() => hideModal()}
          >
            <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
          </div>

          <div class="px-4 pt-3 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <div className="grid grid-cols-1 gap-4">
                <h1>Verify Phone Number</h1>
                <p className="break-normal break-all">
                  <span className="font-bold">
                    Please enter your Phone Verification Code and Account
                    Password to continue:
                  </span>
                  <br />
                </p>
                <TextField
                  placeholder={"Enter OPT Code.."}
                  handleChange={handleOTPChange}
                  value={phoneOTP}
                />
                <TextField
                  placeholder={"Enter Password.."}
                  handleChange={handleTmpPasswordChange}
                  value={tmpPassword}
                />
                <BasicButton
                  disabled={!phoneOTP || !tmpPassword}
                  title={`Verify`}
                  handleClick={updatePhone}
                />
              </div>
            </div>
            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
