import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import TextArea from "../../../components/inputs/text-area";
import { isAdmin, API_BASE, BASE_URL } from "../../../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
export default function AdminPublisherActivation() {
  let { userId } = useParams();
  const [user, setUser] = useState({});
  const [processing, setProcessing] = useState(false);

  const action = (action) => {
    setProcessing(true);
    const url =
      API_BASE +
      "/api/user/publisher-activation/approve-decline/" +
      action +
      "/" +
      userId;

    axios
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          //   window.location.href = `/admin/ads/${publisherId}`;
          fetchUser();
        }
      })
      .catch((error) => {
        setProcessing(false);
        // toast("Something went wrong!", { type: "error" });
      });
  };

  const fetchUser = () => {
    const url = API_BASE + "/api/user/" + userId;

    setProcessing(true);
    axios
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setUser(response.data.user);
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  const isUserVerified = () => {
    if (user.verification.account_verified) {
      return true;
    }
    return false;
  };

  const isUserRejected = () => {
    if (user.verification.status == "rejected") {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
    fetchUser();
  }, []);
  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">Activation</h1>
        </div>
        <div>
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-4 mt-8">
        <div className="w-8/12 md:w-6/12 bg-gray-50 p-4">
          <div className="my-8 text-center">
            {user.name && (
              <div className="text-left flex flex-col items-center">
                <p className="m-2">
                  <b>Name :</b> {user.name}
                </p>
                <p className="m-2">
                  <b>Email:</b> {user.email}
                </p>
                <p className="m-2">
                  <b>Country:</b> {user.verification.country}
                </p>
                <p className="m-2">
                  <b>Phone:</b> {user.phone.phone}
                </p>
                <img
                  className="w-8/12"
                  src={
                    BASE_URL +
                    "/storage/" +
                    user.verification.photo.split("public")[1]
                  }
                />
              </div>
            )}
            <br />
            {user.name && (
              <>
                <BasicButton
                  disabled={processing || isUserVerified()}
                  title={
                    processing
                      ? "Processing"
                      : isUserVerified()
                      ? "Approved"
                      : "Approve"
                  }
                  classes={`p-6 w-9/12 mt-4 ${
                    isUserVerified() ? "bg-gray-300" : ""
                  }`}
                  handleClick={() =>
                    isUserVerified() ? null : action("approve")
                  }
                />
                <BasicButton
                  disabled={processing || isUserRejected()}
                  title={
                    processing
                      ? "Processing"
                      : isUserRejected()
                      ? "Rejected"
                      : "Reject"
                  }
                  classes={`p-6 w-9/12 mt-4 ${
                    isUserRejected() ? "bg-gray-300" : ""
                  }`}
                  handleClick={() => action("reject")}
                />
              </>
            )}
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
}
