import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import WebHeader from "./web/header";
import { isAdmin } from "../Utils/helpers";
import { isAuthUser, API_BASE } from "../Utils/helpers";
import Footer from "../layout/footer";
import TermsAccordion from "../accordions/terms-accordion";
import axios from "axios";

export default function Terms() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const naviagte = useNavigate();

  return (
    <>
      {" "}
      <WebHeader />
      <div class=" px-6 ">
        <br />
        <div className="flex justify-center">
          <div className=" rounded-lg py-4 px-2 w-full md:w-8/12">
            <h3 className="font-bold text-center text-3xl mb-6">
              Terms and Conditions
            </h3>

            <div>
              <br />
              <h3 className="font-bold">1. Introduction</h3>
              <p>
                Welcome to Litzapp, a monetization platform owned and operated
                by Remote Analytics Jobs Inc., located at 126 Southwest 17th
                Road, Unit 509, Miami, Florida, 33129, United States of America.
                By accessing or using Litzapp, you agree to be bound by these
                Terms of Service (the "Terms"). These Terms govern your use of
                our platform and services, including the monetization of
                products, services, and content, as well as the purchase and
                consumption of these offerings.
              </p>
              <p>
                Litzapp provides a versatile platform for users to monetize
                their offerings through configurable payment systems and ad
                revenue, and to connect with Buyers seeking various products,
                services, and content. Users who monetize their products and
                services are referred to as "Product Monetizers," while those
                who monetize content are referred to as "Content Monetizers."
                Collectively, these users are termed "Monetizers." All
                individuals and entities using Litzapp, including Monetizers and
                those who purchase or consume offerings, are referred to as
                "Users."
              </p>
              <p>
                These Terms outline the obligations and responsibilities of all
                Users, including Monetizers and Buyers, and provide guidelines
                to ensure compliance with applicable laws and regulations. By
                using Litzapp, you agree to abide by these Terms, as well as any
                additional guidelines or rules that may be posted on the
                platform from time to time.
              </p>
              <p>
                Please read these Terms carefully before using Litzapp. If you
                do not agree to these Terms, you may not access or use our
                platform. If you have any questions or concerns about these
                Terms, please contact us at admin@litzapp.com.
              </p>
              <p>
                Thank you for choosing Litzapp, where your monetization journey
                begins.
              </p>
              <br />
              <h3 className="font-bold">2. Definitions</h3>
              <p>
                For the purposes of these Terms of Service, the following
                definitions apply:
              </p>
              <ol>
                <li>
                  Litzapp: A monetization platform owned and operated by Remote
                  Analytics Jobs Inc., allowing Users to monetize products,
                  services, and content via configurable payment systems and ad
                  revenue.
                </li>
                <li>
                  Remote Analytics Jobs Inc.: The company that owns and operates
                  Litzapp, located at 126 Southwest 17th Road, Unit 509, Miami,
                  Florida, 33129, United States of America.
                </li>
                <li>
                  Users: All individuals and entities who access or use Litzapp,
                  including both Monetizers and Buyers.
                </li>
                <li>
                  Monetizers: Users who use Litzapp to monetize their offerings.
                  This includes both Product Monetizers and Content Monetizers.
                </li>
                <li>
                  Product Monetizers: Users who monetize their products and/or
                  services on Litzapp.
                </li>
                <li>
                  Content Monetizers: Users who monetize their content on
                  Litzapp.
                </li>
                <li>
                  Buyers: Users who purchase products, services, or content on
                  Litzapp, including those who may also monetize via Litzapp.
                </li>
                <li>
                  Content Subscribers: Buyers who subscribe to a Content
                  Monetizer's account to view content without being blocked by a
                  paywall.
                </li>
                <li>
                  Products: Physical or digital items offered for sale by
                  Product Monetizers on Litzapp.
                </li>
                <li>
                  Services: Professional or personal services offered by Product
                  Monetizers on Litzapp.
                </li>
                <li>
                  Content: Digital media, including but not limited to videos,
                  music, articles, and other forms of digital expression,
                  offered by Content Monetizers on Litzapp.
                </li>
                <li>
                  Litzapp Service Charge: A fee automatically added by Litzapp
                  to the price of a product, service, content, or content
                  subscription, paid by the Buyer.
                </li>
                <li>
                  Litzapp Fee: A percentage of the amount paid by the Buyer for
                  a product, service, content, or content subscription, after
                  gateway providers and payout providers have deducted their
                  fees.
                </li>
                <li>
                  Ad Revenue: Revenue generated from advertisements displayed
                  on, within, or beside content or listings on Litzapp.
                  Monetizers are entitled to the ad revenue generated by their
                  ad accounts as shown in their "My Ads Record" section.
                </li>
                <li>
                  Payment Gateway Providers: Third-party services used by
                  Litzapp to process payments from Buyers.
                </li>
                <li>
                  Payout Providers: Third-party services used by Litzapp to
                  distribute earnings to Monetizers.
                </li>
                <li>
                  Stripe: The payment processing service currently used by
                  Litzapp to handle transactions and payouts, requiring
                  Monetizers to set up an account and agree to Stripe's terms
                  and conditions.
                </li>
                <li>
                  My Ads Record: The section in a Monetizer's account where they
                  can view the ad revenue generated by their ad accounts.
                </li>
                <li>
                  Data Threshold Subscription: A monthly charge for Users who
                  create or upload data in Litzapp above a specified threshold.
                  Failure to pay this subscription may result in the deletion of
                  the User's data from the system.
                </li>
              </ol>
              <p>
                By understanding and agreeing to these definitions, Users can
                better navigate and comply with the Terms of Service on Litzapp.
              </p>
              <br />
              <h3 className="font-bold">
                3. Account Creation and Responsibilities
              </h3>
              <h4 className="font-bold">3.1 Account Creation</h4>
              <p>
                To use Litzapp, all Users must create an account by providing
                accurate and complete information as prompted by the
                registration form. Users must ensure that all information
                provided is current, accurate, and complete. By creating an
                account, Users agree to:
              </p>
              <ul className="pl-5 list-disc">
                <li>
                  Provide true, accurate, current, and complete information
                  during the registration process.
                </li>
                <li>
                  Maintain and promptly update the information to ensure it
                  remains true, accurate, current, and complete.
                </li>
                <li>
                  Be responsible for all activities that occur under their
                  account.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">3.2 Eligibility</h4>
              <p>
                By creating an account on Litzapp, Users represent and warrant
                that they are at least 18 years old and have the legal capacity
                to enter into these Terms. If you are under 18, you may not
                create an account or use Litzapp.
              </p>
              <h4 className="font-bold">3.3 Account Security</h4>
              <p>
                Users are responsible for maintaining the confidentiality of
                their account login information and are fully responsible for
                all activities that occur under their account. Users agree to:
              </p>
              <ul className="pl-5 list-disc">
                <li>
                  Immediately notify Litzapp of any unauthorized use of their
                  account or any other breach of security.
                </li>
                <li>
                  Ensure that they log out from their account at the end of each
                  session. Litzapp will not be liable for any loss or damage
                  arising from a User's failure to comply with these
                  requirements.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">3.4 Prohibited Activities</h4>
              <p>
                Users agree not to engage in any of the following prohibited
                activities:
              </p>
              <ul className="pl-5 list-disc">
                <li>
                  Using Litzapp for any unlawful purpose or in violation of any
                  local, state, national, or international law.
                </li>
                <li>
                  Impersonating any person or entity, or falsely stating or
                  otherwise misrepresenting your affiliation with a person or
                  entity.
                </li>
                <li>
                  Interfering with or disrupting the operation of Litzapp or the
                  servers or networks used to make Litzapp available.
                </li>
                <li>
                  Transmitting any viruses, worms, defects, Trojan horses, or
                  other items of a destructive nature.
                </li>
                <li>
                  Using any automated means, including robots, crawlers, or data
                  mining tools, to download, monitor, or use data or content
                  from Litzapp.
                </li>
                <li>
                  Attempting to gain unauthorized access to any portion of
                  Litzapp, other accounts, computer systems, or networks
                  connected to Litzapp.
                </li>
                <li>
                  Engaging in any activity that interferes with another User's
                  ability to use or enjoy Litzapp.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">3.5 Account Termination</h4>
              <p>
                Litzapp reserves the right to suspend or terminate a User's
                account at any time and for any reason, including but not
                limited to:
              </p>
              <ul className="pl-5 list-disc">
                <li>
                  Violation of these Terms or any other policies or guidelines
                  provided by Litzapp.
                </li>
                <li>Engaging in fraudulent or illegal activities.</li>
                <li>
                  Any other actions that may harm Litzapp, its Users, or its
                  reputation.
                </li>
              </ul>
              <p>
                Users may terminate their account at any time by following the
                instructions provided on Litzapp. Upon termination, Users must
                cease all use of Litzapp, and any provisions of these Terms that
                are intended to survive termination (such as indemnification and
                limitation of liability) shall continue in full force and
                effect.
              </p>
              <p>
                By creating an account on Litzapp, Users agree to comply with
                these responsibilities and understand that failure to do so may
                result in the suspension or termination of their account and
                access to Litzapp.
              </p>
              <br />
              <h3 className="font-bold">4. Monetizer Obligations</h3>
              <p>
                Monetizers on Litzapp, including both Product Monetizers and
                Content Monetizers, have specific obligations to ensure a safe,
                legal, and efficient marketplace. By agreeing to these Terms,
                Monetizers agree to comply with the following obligations:
              </p>
              <br />
              <h4 className="font-bold">4.1 General Obligations</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Compliance with Laws: Monetizers must comply with all
                  applicable local, state, national, and international laws and
                  regulations when using Litzapp.
                </li>
                <li>
                  Accurate Information: All information provided in listings,
                  including product descriptions, service details, and content
                  information, must be accurate, complete, and not misleading.
                </li>
                <li>
                  Intellectual Property: Monetizers must ensure that they have
                  the rights or permissions necessary to use and monetize any
                  content, products, or services listed on Litzapp. Monetizers
                  may not post content or list items that infringe on the
                  intellectual property rights of others.
                </li>
                <li>
                  Content Standards: Monetizers must ensure that all content and
                  listings are not pornographic, illegal, plagiarized,
                  unauthorized, offensive, discriminatory, defamatory, stolen,
                  harmful, misleading, spammy, or in violation of any
                  intellectual property rights.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">4.2 Product Monetizers</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Contract Creation: Product Monetizers are responsible for
                  creating and providing the contract that governs their
                  transactions with Buyers. For certain products, Litzapp may
                  provide an agreement that will govern the transaction; if not,
                  the Product Monetizer must create their own contract.
                </li>
                <li>
                  Contract Compliance: Product Monetizers must ensure that their
                  contracts comply with all applicable laws and regulations.
                </li>
                <li>
                  Liability: Litzapp is not liable for any loss, damage,
                  discomfort, harm, or negative consequences resulting from
                  transactions between Product Monetizers and Buyers. Both
                  parties agree not to hold Litzapp liable in any way.
                </li>
                <li>
                  Product Listings: Product Monetizers must provide clear,
                  accurate, and complete descriptions of their products and
                  services. They must promptly update any changes to the product
                  or service details.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">4.3 Content Monetizers</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Authority to Monetize: Content Monetizers must ensure they
                  have the authority and/or permission to post and monetize the
                  content on Litzapp.
                </li>
                <li>
                  Content Access: When a Buyer purchases content, they have
                  access to it via Litzapp for 30 consecutive days. Content
                  Monetizers must not delete the content before this period ends
                  unless necessary updates are required.
                </li>
                <li>
                  Content Updates: Content Monetizers can update their content
                  as needed but must ensure that updates do not alter the
                  purpose or essence of the content.
                </li>
                <li>
                  Deletion of Content: If a Content Monetizer deletes purchased
                  content before the 30-day period, Buyers can take legal action
                  against them. After the 30-day period, Buyers must confirm
                  that Litzapp has not restricted access before taking legal
                  action.
                </li>
                <li>
                  Content Subscriber Rights: Content Subscribers can view
                  content without paywalls but do not have rights to dictate the
                  content available on a Content Monetizer's account. Content
                  Monetizers can delete content at any time without facing legal
                  consequences from Content Subscribers.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">4.4 Financial Obligations</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Service Charges and Fees: Monetizers must acknowledge and
                  agree to the deduction of Litzapp Service Charges and Litzapp
                  Fees from their earnings. These fees are subject to change,
                  and Monetizers should stay updated with any changes.
                </li>
                <li>
                  Ad Revenue: Monetizers are entitled to a portion of the ad
                  revenue generated by their ad accounts. The amount shown in
                  the "My Ads Record" section is the amount they are entitled
                  to, after deductions by gateway providers, payout providers,
                  and Litzapp Fees.
                </li>
                <li>
                  Email Charges and Data Threshold Subscriptions: Monetizers
                  must pay for sending emails via the Litzapp system and for
                  creating or uploading data above a certain threshold. Failure
                  to pay may result in data deletion from Litzapp.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">4.5 Payment Processing</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Stripe Account: Monetizers must set up an account with Stripe
                  (or other payment providers as added by Litzapp) and agree to
                  its terms and conditions. Monetizers cannot hold Litzapp
                  liable for issues related to Stripe or other payment
                  providers.
                </li>
              </ul>
              <p>
                By fulfilling these obligations, Monetizers help maintain a
                trustworthy and reliable platform for all Users on Litzapp.
                Failure to comply with these obligations may result in the
                suspension or termination of the Monetizer's account.
              </p>
              <br />
              <h3 className="font-bold">5. Buyer Obligations</h3>
              <p>
                As a Buyer on Litzapp, you have specific responsibilities to
                ensure a smooth and fair marketplace for all parties involved.
                By using Litzapp, you agree to comply with the following
                obligations:
              </p>
              <br />
              <h4 className="font-bold">5.1 General Obligations</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Compliance with Laws: Buyers must comply with all applicable
                  local, state, national, and international laws and regulations
                  when using Litzapp.
                </li>
                <li>
                  Accurate Information: Buyers must provide true, accurate,
                  current, and complete information during the purchasing
                  process and any other interactions on Litzapp. Buyers must
                  promptly update any information that is inaccurate or
                  incomplete.
                </li>
                <li>
                  Respectful Conduct: Buyers must engage respectfully with
                  Monetizers and other Users, avoiding abusive, defamatory, or
                  harassing behavior.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">
                5.2 Purchasing Products and Services
              </h4>
              <ul className="pl-5 list-disc">
                <li>
                  Contractual Agreement: By purchasing a product or service,
                  Buyers agree to the terms of the contract provided by the
                  Product Monetizer, except in cases where Litzapp has specified
                  an agreement for certain products. Buyers must review and
                  understand the terms of these contracts before making a
                  purchase.
                </li>
                <li>
                  Payment Obligations: Buyers must ensure that payment for
                  products and services is made in accordance with the agreed
                  terms and through the payment methods supported by Litzapp.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">5.3 Accessing and Using Content</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Content Access: When purchasing content, Buyers are granted
                  access to that content via Litzapp for 30 consecutive days.
                  Buyers understand that this access does not equate to
                  ownership and that Litzapp reserves the right to restrict
                  access after this period.
                </li>
                <li>
                  No Permanent Rights: Buyers acknowledge that access to
                  purchased content is at Litzapp's discretion and is not
                  guaranteed to be permanent.
                </li>
                <li>
                  Content Alteration and Deletion: Buyers cannot demand the
                  permanent availability of content. If a Content Monetizer
                  deletes or alters content before the 30-day access period
                  ends, Buyers can seek legal action against the Content
                  Monetizer. If content is deleted or altered after 30 days,
                  Buyers must first confirm Litzapp's restriction status before
                  taking legal action.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">5.4 Subscribing to Content</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Content Subscription: Buyers who subscribe to a Content
                  Monetizer's account (Content Subscribers) do so to view
                  content without paywalls. This subscription does not grant
                  rights to dictate the content available or to hold the Content
                  Monetizer liable for content deletions.
                </li>
                <li>
                  Subscription Terms: Content Subscribers understand that
                  subscribing allows access to the current content on a Content
                  Monetizer's account and that Content Monetizers can delete or
                  alter their content at any time without facing legal
                  consequences from Content Subscribers.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">5.5 Prohibited Activities</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Unauthorized Use: Buyers may not use Litzapp for any
                  unauthorized or illegal purpose, including but not limited to
                  infringing on the intellectual property rights of others.
                </li>
                <li>
                  Interference with Operations: Buyers may not interfere with or
                  disrupt the operation of Litzapp or the servers or networks
                  used to make Litzapp available.
                </li>
                <li>
                  Prohibited Content: Buyers may not post or transmit any
                  content that is pornographic, illegal, plagiarized,
                  unauthorized, offensive, discriminatory, defamatory, harmful,
                  misleading, spammy, or in violation of any intellectual
                  property rights.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">5.6 Payment Processing</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Payment Providers: Buyers must use the payment processing
                  services provided by Litzapp, currently Stripe, and agree to
                  abide by their terms and conditions. Buyers cannot hold
                  Litzapp liable for any issues arising from transactions
                  processed by Stripe or other payment providers.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">5.7 Dispute Resolution</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Disputes with Monetizers: Buyers must attempt to resolve any
                  disputes directly with the Monetizer involved. Litzapp is not
                  responsible for resolving disputes between Buyers and
                  Monetizers.
                </li>
                <li>
                  Disputes with Litzapp: Any disputes arising between Buyers and
                  Litzapp shall be resolved in accordance with the Governing Law
                  and Dispute Resolution section of these Terms.
                </li>
              </ul>
              <p>
                By adhering to these obligations, Buyers contribute to a fair
                and transparent marketplace on Litzapp. Failure to comply with
                these obligations may result in the suspension or termination of
                the Buyer's account.
              </p>
              <br />
              <h3 className="font-bold">6. Subscription and Access Terms</h3>
              <h4 className="font-bold">6.1 Content Purchase</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Access Period: When a Buyer purchases content on Litzapp, they
                  are granted access to that content for a period of 30
                  consecutive days from the date of purchase. During this
                  period, Buyers can view the content through the Litzapp
                  platform, provided it is up and functioning.
                </li>
                <li>
                  Non-Permanent Access: Buyers understand and agree that their
                  access to purchased content is not permanent. After the 30-day
                  period, access to the content may be restricted or terminated
                  at Litzapp's discretion. The Buyer should not assume permanent
                  access to any purchased content.
                </li>
                <li>
                  Content Updates: Content Monetizers may update their content
                  during the 30-day access period. Updates should not alter the
                  original purpose or essence of the content. If significant
                  changes are made, the original content should still be
                  accessible in its intended form.
                </li>
                <li>
                  Content Deletion: If a Content Monetizer deletes a purchased
                  item of content before the end of the 30-day period, the Buyer
                  may take legal action against the Content Monetizer. Litzapp
                  is not liable for any losses or damages resulting from such
                  deletions. Content monetizers should regard purchased content
                  as permanent and refrain from deleting the content after the
                  30-day period. After the 30-day period, Buyers should verify
                  with Litzapp whether any access restrictions have been imposed
                  by Litzapp before initiating legal action against the Content
                  Monetizer, if the Content Monetizer has deleted the purchased
                  item of content.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">6.2 Content Subscription</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Subscription Model: Buyers can subscribe to a Content
                  Monetizer's account to view all the content available on that
                  account without encountering paywalls. This subscription is
                  referred to as being a Content Subscriber.
                </li>
                <li>
                  Access Rights: Content Subscribers are granted access to view
                  all the content on the Content Monetizer's account for the
                  duration of their subscription. However, Content Subscribers
                  do not have any rights to dictate or control the content that
                  the Content Monetizer posts or retains on their account.
                </li>
                <li>
                  Content Deletion by Monetizer: Content Monetizers have the
                  right to delete or alter their content at any time. Content
                  Subscribers understand and agree that the Content Monetizer
                  can remove content without facing legal consequences from
                  Content Subscribers, even if the content is deleted
                  immediately after the subscription is activated.
                </li>
                <li>
                  No Guarantee of Content: Content Subscribers acknowledge that
                  the availability of content may change, and there is no
                  guarantee that specific content will remain accessible during
                  the subscription period.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">6.3 Renewal and Cancellation</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Automatic Renewal: Subscriptions on Litzapp may automatically
                  renew at the end of the subscription period unless the Buyer
                  cancels the subscription before the renewal date. The renewal
                  terms will be based on the initial subscription terms.
                </li>
                <li>
                  Cancellation Policy: Buyers can cancel their subscriptions at
                  any time through their account settings on Litzapp. Upon
                  cancellation, Buyers will continue to have access to the
                  content until the end of the current subscription period. No
                  refunds will be provided for the remaining subscription period
                  after cancellation.
                </li>
                <li>
                  Subscription Fees: The subscription fees are subject to
                  change, and Litzapp will notify Buyers of any fee changes at
                  least 20 days before they take effect. Buyers are responsible
                  for reviewing any changes to the subscription fees.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">6.4 Restrictions and Limitations</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Usage Limitations: Buyers must use the content and services
                  purchased or subscribed to on Litzapp in accordance with these
                  Terms and any applicable laws and regulations. Unauthorized
                  use, reproduction, distribution, or modification of the
                  content is prohibited.
                </li>
                <li>
                  Platform Availability: Litzapp strives to provide continuous
                  access to the platform and its content. However, Litzapp does
                  not guarantee uninterrupted availability and is not liable for
                  any interruptions or downtime.
                </li>
                <li>
                  Account Termination: Litzapp reserves the right to suspend or
                  terminate a Buyer's account if they violate these Terms or
                  engage in activities that may harm Litzapp, its Users, or its
                  reputation. In such cases, access to purchased or subscribed
                  content may be immediately revoked.
                </li>
              </ul>
              <p>
                By agreeing to these Subscription and Access Terms, Buyers
                acknowledge and accept the conditions under which they can
                access and use the content available on Litzapp. Failure to
                comply with these terms may result in the suspension or
                termination of access to the platform and its content.
              </p>
              <br />
              <h3 className="font-bold">7. Content and Listing Standards</h3>
              <p>
                To maintain a safe, respectful, and legal marketplace, all
                content and listings on Litzapp must adhere to the following
                standards. By using Litzapp, all Users, including Monetizers and
                Buyers, agree to comply with these standards:
              </p>
              <br />
              <h4 className="font-bold">7.1 General Standards</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Accuracy and Honesty: All content and listings must be
                  accurate, truthful, and not misleading. Users must ensure that
                  descriptions of products, services, and content are complete
                  and reflect the actual item or service being offered.
                </li>
                <li>
                  Compliance with Laws: All content and listings must comply
                  with all applicable local, state, national, and international
                  laws and regulations. Users are responsible for ensuring their
                  content adheres to these legal standards.
                </li>
                <li>
                  Intellectual Property Rights: Users must have the necessary
                  rights or permissions to post and monetize any content,
                  products, or services listed on Litzapp. Users must not
                  infringe on the intellectual property rights of others.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">7.2 Prohibited Content</h4>
              <p>
                Users may not post or transmit any content or listings that:
              </p>
              <ul className="pl-5 list-disc">
                <li>
                  Pornographic: Includes explicit sexual content or pornography.
                </li>
                <li>
                  Illegal: Violates any laws or regulations, including but not
                  limited to content that is defamatory, fraudulent, or harmful.
                </li>
                <li>
                  Plagiarized: Infringes on the copyright, trademark, or other
                  intellectual property rights of any person or entity.
                </li>
                <li>
                  Unauthorized: Uses or monetizes content without the necessary
                  permissions or rights.
                </li>
                <li>
                  Offensive: Contains hate speech, harassment, or discriminatory
                  content based on race, ethnicity, gender, sexual orientation,
                  religion, or any other protected characteristic.
                </li>
                <li>
                  Defamatory: Includes false statements that harm the reputation
                  of individuals or entities.
                </li>
                <li>
                  Harmful: Promotes violence, self-harm, or any activity that
                  poses a risk to individuals' health or safety.
                </li>
                <li>
                  Misleading: Contains false or deceptive information meant to
                  defraud or deceive others.
                </li>
                <li>
                  Spammy: Engages in spamming or excessive posting of
                  unsolicited commercial messages.
                </li>
                <li>
                  Violation of Intellectual Property: Includes unauthorized use
                  of copyrighted materials, trademarks, or other proprietary
                  content.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">7.3 Specific Listing Requirements</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Product Listings: Product Monetizers must provide clear,
                  accurate, and detailed descriptions of their products,
                  including relevant specifications, pricing, and any applicable
                  terms and conditions. Photos or videos of products should be
                  high quality and accurately represent the item being sold.
                </li>
                <li>
                  Service Listings: Service Monetizers must clearly describe the
                  nature of the services offered, including scope, pricing, and
                  any terms and conditions. Service listings should specify any
                  prerequisites or requirements for Buyers.
                </li>
                <li>
                  Content Listings: Content Monetizers must ensure that
                  descriptions of their digital content (e.g., videos, articles,
                  music) accurately reflect the substance and value of the
                  content. Any promotional materials or previews should be
                  representative of the full content.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">7.4 Content Management</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Updates and Revisions: Monetizers may update or revise their
                  content, products, or service listings to ensure accuracy and
                  relevancy. However, significant changes should not alter the
                  fundamental nature or purpose of the original listing.
                </li>
                <li>
                  Deletions: Monetizers must not delete purchased content within
                  the specified access period (30 days) without a valid reason,
                  such as necessary updates. If deletion is necessary,
                  Monetizers must notify Buyers and provide an explanation.
                </li>
                <li>
                  Quality Control: Monetizers are responsible for maintaining
                  high-quality standards for all content and listings. This
                  includes ensuring that content is free from errors, broken
                  links, and other issues that may affect the user experience.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">7.5 Reporting and Enforcement</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Reporting Violations: Users can report content or listings
                  that violate these standards through the Litzapp reporting
                  system. Litzapp will review reported content and take
                  appropriate action, which may include removing content or
                  suspending accounts.
                </li>
                <li>
                  Enforcement: Litzapp reserves the right to remove any content
                  or listings that violate these standards, suspend or terminate
                  accounts, and take any other necessary actions to enforce
                  compliance. Users will be notified of any actions taken
                  against their accounts.
                </li>
              </ul>
              <p>
                By adhering to these Content and Listing Standards, Users help
                maintain a trustworthy and reliable platform for all members of
                the Litzapp community. Failure to comply with these standards
                may result in the suspension or termination of User accounts and
                access to Litzapp.
              </p>
              <br />
              <h3 className="font-bold">8. Fees and Charges</h3>
              <p>
                Litzapp uses a transparent fee structure to ensure fair
                compensation for services provided while enabling Monetizers to
                maximize their earnings. By using Litzapp, Users agree to the
                following fees and charges:
              </p>
              <br />
              <h4 className="font-bold">8.1 Litzapp Service Charge</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Definition: The Litzapp Service Charge is a fee added by
                  Litzapp to the price of a product, service, content, or
                  content subscription. This fee is paid by the Buyer in
                  addition to the listed price.
                </li>
                <li>
                  Example: If the Service Charge is $0.50 and a Product
                  Monetizer lists an item for $6.00, the Buyer will pay $6.50 in
                  total. The $0.50 goes to Litzapp.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">8.2 Litzapp Fee</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Definition: The Litzapp Fee is a percentage of the amount paid
                  by the Buyer for a product, service, content, or content
                  subscription, after the fees from payment gateway providers
                  and payout providers have been deducted.
                </li>
                <li>
                  Calculation: The specific percentage of the Litzapp Fee will
                  be communicated to Monetizers and may vary based on the type
                  of transaction or category of product/service/content.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">8.3 Ad Revenue</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Eligibility: Monetizers are entitled to ad revenue generated
                  from their ad accounts. The ad revenue is tracked and
                  displayed in the "My Ads Record" section of their account.
                </li>
                <li>
                  Revenue Distribution: The amount shown in "My Ads Record" is
                  the gross ad revenue. Monetizers will receive this amount
                  after deductions for fees by gateway providers, payout
                  providers, and Litzapp Fees. The Litzapp Service Charge does
                  not apply to ad revenue.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">8.4 Email Charges</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Definition: Litzapp charges Users for sending emails via the
                  Litzapp system. This ensures that the platform can maintain
                  the necessary infrastructure to support email communication.
                </li>
                <li>
                  Fees: The specific charges for email usage will be outlined in
                  the User's account settings and may vary based on usage
                  levels.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">8.5 Data Threshold Subscription</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Definition: Users who create or upload data on Litzapp above a
                  specified threshold will incur a monthly charge. This ensures
                  that data storage and management are sustainable and scalable.
                </li>
                <li>
                  Non-Payment: If a User fails to pay for their data threshold
                  subscription, Litzapp reserves the right to delete the User's
                  data from the system.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">8.6 Payment and Payout Processing</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Payment Gateway Providers: Litzapp currently uses Stripe to
                  process payments. Monetizers must set up an account with
                  Stripe and agree to its terms and conditions.
                </li>
                <li>
                  Other Providers: Should Litzapp include additional providers
                  for payment and payout processing, Users agree to abide by the
                  terms and conditions of these providers and cannot hold
                  Litzapp liable for any issues arising from these transactions.
                </li>
                <li>
                  Fee Deductions: Payouts to Monetizers will occur after the
                  deduction of fees by payment gateway providers, payout
                  providers, Litzapp Service Charge, and Litzapp Fees.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">8.7 Fee Changes</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Notification: Litzapp reserves the right to change the Litzapp
                  Service Charge and Litzapp Fee. Any changes to fees will be
                  communicated to Monetizers at least 20 days before they take
                  effect.
                </li>
                <li>
                  Responsibility: It is the responsibility of Monetizers to stay
                  informed about any changes to fees and charges.
                </li>
              </ul>
              <p>
                By using Litzapp, Users acknowledge and agree to these fees and
                charges. These terms ensure the sustainability and functionality
                of the Litzapp platform while providing clarity and fairness to
                all Users.
              </p>
              <br />
              <h3 className="font-bold">9. Payment and Payout Processing</h3>
              <p>
                Litzapp leverages secure and efficient payment and payout
                processing systems to ensure smooth transactions between Buyers
                and Monetizers. By using Litzapp, all Users agree to the
                following terms regarding payment and payout processing:
              </p>
              <br />
              <h4 className="font-bold">9.1 Payment Processing</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Payment Gateway Provider: Litzapp currently uses Stripe to
                  process payments. Buyers must use Stripe to complete their
                  purchases on Litzapp.
                </li>
                <li>
                  Setting Up an Account: Monetizers must set up an account with
                  Stripe and agree to its terms and conditions. Monetizers are
                  responsible for any issues arising from their dealings with
                  Stripe.
                </li>
                <li>
                  Transaction Security: All transactions processed through
                  Stripe are subject to Stripe's security protocols to ensure
                  the protection of payment information.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">9.2 Payout Processing</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Payout Schedule: Monetizers will receive payouts based on the
                  schedule established by Stripe or any other payment provider
                  used by Litzapp. Payouts are processed after the deduction of
                  applicable fees.
                </li>
                <li>
                  Deductions: Payouts will occur after the deduction of fees by
                  payment gateway providers, payout providers, Litzapp Service
                  Charges, and Litzapp Fees.
                </li>
                <li>
                  Minimum Payout Amount: Monetizers must meet a minimum payout
                  threshold, which will be communicated in their account
                  settings. If the earnings do not meet this threshold, the
                  payout will be deferred until the threshold is met.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">9.3 Payment Disputes</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Dispute Resolution: Any disputes regarding payments processed
                  through Stripe or other payment providers should be resolved
                  directly with the provider. Litzapp is not responsible for
                  resolving such disputes.
                </li>
                <li>
                  Notification of Disputes: Monetizers and Buyers must notify
                  Litzapp of any payment disputes involving transactions on the
                  platform. Litzapp will assist in providing relevant
                  transaction information to facilitate dispute resolution.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">9.4 Payment Failure</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Failed Transactions: If a payment transaction fails, the Buyer
                  will be notified, and the transaction will not be completed.
                  Buyers must ensure that their payment information is accurate
                  and up-to-date to avoid failed transactions.
                </li>
                <li>
                  Payout Issues: If a payout to a Monetizer fails, the Monetizer
                  will be notified. Monetizers must ensure that their payout
                  information is accurate and up-to-date to avoid payout issues.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">9.5 Refunds and Chargebacks</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Refund Policy: Refunds for products, services, or content
                  purchases are subject to the refund policies established by
                  the Product Monetizers and Content Monetizers. Buyers should
                  review these policies before making a purchase.
                </li>
                <li>
                  Chargebacks: In the event of a chargeback, Litzapp reserves
                  the right to deduct the chargeback amount and any associated
                  fees from the Monetizer's account. Monetizers are responsible
                  for resolving chargebacks with Buyers and the payment
                  provider.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">9.6 Changes to Payment Providers</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Additional Providers: Litzapp may add other payment and payout
                  providers to enhance service offerings. Users will be notified
                  of any changes, and any new providers' terms and conditions
                  will apply.
                </li>
                <li>
                  Agreement to Terms: By using additional payment providers,
                  Users agree to abide by the terms and conditions of these
                  providers. Litzapp is not liable for issues arising from
                  transactions with these additional providers.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">9.7 Record Keeping</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Transaction Records: Litzapp will maintain records of all
                  transactions made on the platform. Users can access their
                  transaction history through their account settings.
                </li>
                <li>
                  Account Statements: Monetizers will receive regular account
                  statements detailing their earnings, deductions, and payouts.
                  These statements will be available through the Monetizer's
                  account dashboard.
                </li>
              </ul>
              <p>
                By agreeing to these Payment and Payout Processing terms, Users
                acknowledge their responsibilities in ensuring accurate and
                secure transactions on Litzapp. Failure to comply with these
                terms may result in the suspension or termination of User
                accounts.
              </p>
              <br />
              <h3 className="font-bold">10. Liability and Disclaimers</h3>
              <h4 className="font-bold">10.1 Limitation of Liability</h4>
              <p>
                To the fullest extent permitted by law, Litzapp, Remote
                Analytics Jobs Inc., and their respective directors, officers,
                employees, agents, affiliates, licensors, and service providers
                (collectively, "Litzapp Parties") shall not be liable for any
                indirect, incidental, special, consequential, or punitive
                damages, or any loss of profits or revenues, whether incurred
                directly or indirectly, or any loss of data, use, goodwill, or
                other intangible losses, resulting from:
              </p>
              <ul className="pl-5 list-disc">
                <li>
                  Use of the Platform: Your access to or use of, or inability to
                  access or use, Litzapp.
                </li>
                <li>
                  Content or Listings: Any content or listings provided by
                  Monetizers, including any errors or omissions in any content
                  or listings, or any loss or damage incurred as a result of the
                  use of any content or listings posted, transmitted, or
                  otherwise made available through Litzapp.
                </li>
                <li>
                  Conduct of Users: The conduct or content of other Users or
                  third parties on or through Litzapp.
                </li>
                <li>
                  Unauthorized Access: Any unauthorized access to or use of our
                  servers and/or any personal information stored therein.
                </li>
              </ul>
              <h4 className="font-bold">10.2 Disclaimer of Warranties</h4>
              <p>
                Litzapp provides the platform "as is" and "as available" without
                any warranties of any kind, either express or implied, including
                but not limited to:
              </p>
              <ul className="pl-5 list-disc">
                <li>
                  Merchantability and Fitness: Warranties of merchantability,
                  fitness for a particular purpose, and non-infringement.
                </li>
                <li>
                  Functionality and Availability: Litzapp does not warrant that
                  the platform will function uninterrupted, secure, or available
                  at any particular time or location; any errors or defects will
                  be corrected; the platform is free of viruses or other harmful
                  components; or the results of using the platform will meet
                  your requirements or expectations.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">
                10.3 No Responsibility for Third-Party Content
              </h4>
              <ul className="pl-5 list-disc">
                <li>
                  Third-Party Listings: Litzapp is not responsible for any
                  third-party listings, products, services, or content provided
                  by Monetizers. Monetizers are solely responsible for ensuring
                  that their content and listings comply with all applicable
                  laws and regulations.
                </li>
                <li>
                  External Links: Litzapp may contain links to third-party
                  websites or services that are not owned or controlled by
                  Litzapp. Litzapp has no control over, and assumes no
                  responsibility for, the content, privacy policies, or
                  practices of any third-party websites or services.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">10.4 Indemnification</h4>
              <ul className="pl-5 list-disc">
                <li>
                  User Responsibility: You agree to defend, indemnify, and hold
                  harmless the Litzapp Parties from and against any and all
                  claims, damages, obligations, losses, liabilities, costs, or
                  debt, and expenses (including but not limited to attorney's
                  fees) arising from:
                </li>
              </ul>
              <ul className="pl-5 list-disc">
                <li>Your use of and access to Litzapp.</li>
                <li>Your violation of any term of these Terms of Service.</li>
                <li>
                  Your violation of any third-party right, including without
                  limitation any copyright, property, or privacy right.
                </li>
                <li>
                  Any claim that your content or listings caused damage to a
                  third party.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">10.5 No Guarantee of Results</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Performance and Earnings: Litzapp makes no guarantee regarding
                  the performance or earnings of any Monetizer or the success of
                  any content, product, or service listed on the platform. Users
                  acknowledge that results may vary and are dependent on various
                  factors outside of Litzapp's control.
                </li>
                <li>
                  Platform Availability: While Litzapp strives to ensure the
                  platform's availability and functionality, it does not
                  guarantee that access to the platform will be uninterrupted or
                  error-free.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">10.6 User Content and Interactions</h4>
              <ul className="pl-5 list-disc">
                <li>
                  User Responsibility: Users are solely responsible for their
                  interactions with other Users. Litzapp is not responsible for
                  any damage or harm resulting from any interactions between
                  Users.
                </li>
                <li>
                  Content Ownership: Users retain ownership of the content they
                  create and post on Litzapp. However, by posting content on
                  Litzapp, Users grant Litzapp a non-exclusive, royalty-free,
                  worldwide, transferable, sub-licensable, perpetual license to
                  use, store, display, reproduce, modify, create derivative
                  works, perform, and distribute such content on Litzapp.
                </li>
              </ul>
              <p>
                By using Litzapp, Users acknowledge and accept these Liability
                and Disclaimers terms. These provisions are essential to the
                fair and reasonable operation of the platform and help ensure a
                safe and productive environment for all Users.
              </p>
              <br />
              <h3 className="font-bold">11. Indemnification</h3>
              <p>
                By using Litzapp, you agree to indemnify, defend, and hold
                harmless Remote Analytics Jobs Inc., its subsidiaries,
                affiliates, and their respective directors, officers, employees,
                agents, partners, licensors, and service providers
                (collectively, the "Litzapp Parties") from and against any and
                all claims, liabilities, damages, losses, costs, expenses, or
                fees (including reasonable attorneys' fees) that such parties
                may incur as a result of or arising from:
              </p>
              <br />
              <h4 className="font-bold">11.1 User Conduct</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Use of the Platform: Your access to or use of Litzapp,
                  including any content or information you submit, post,
                  transmit, or otherwise make available through Litzapp.
                </li>
                <li>
                  Violation of Terms: Your violation of these Terms of Service
                  or any other agreement between you and Litzapp, including any
                  terms and conditions or policies incorporated by reference.
                </li>
                <li>
                  Violation of Laws: Your violation of any applicable laws,
                  rules, or regulations, including but not limited to those
                  governing online conduct and acceptable content, consumer
                  protection, data protection, and intellectual property.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">11.2 User Content and Listings</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Infringement Claims: Any claim that your content, product,
                  service, or listing infringes or violates the rights of any
                  third party, including but not limited to intellectual
                  property rights, privacy rights, publicity rights, or other
                  proprietary rights.
                </li>
                <li>
                  Content Accuracy: Any claim that your content, product,
                  service, or listing is false, misleading, defamatory, or
                  otherwise harmful to others.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">11.3 Transactions and Interactions</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Buyer Disputes: Any dispute between you and a Buyer or another
                  User arising from a transaction conducted through Litzapp,
                  including but not limited to disputes over payments,
                  deliveries, product quality, or service performance.
                </li>
                <li>
                  Interactions with Other Users: Any interactions or dealings
                  with other Users of Litzapp, including but not limited to
                  claims related to User behavior, harassment, or misconduct.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">11.4 Technical and Security Issues</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Security Breaches: Any claim arising from your failure to
                  secure and maintain the confidentiality of your account login
                  information or from any unauthorized access to or use of your
                  account.
                </li>
                <li>
                  Technical Problems: Any claim arising from technical problems,
                  malfunctions, or errors in Litzapp that are caused by your
                  actions or negligence.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">11.5 Indemnification Process</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Notice of Claim: Litzapp will promptly notify you of any
                  claim, liability, or demand for which indemnification is
                  sought. You agree to provide Litzapp with reasonable
                  assistance, at your expense, in defending any such claim.
                </li>
                <li>
                  Control of Defense: Litzapp reserves the right, at its own
                  expense, to assume the exclusive defense and control of any
                  matter otherwise subject to indemnification by you. You agree
                  not to settle any matter without the prior written consent of
                  Litzapp.
                </li>
              </ul>
              <p>
                By agreeing to these indemnification terms, you acknowledge your
                responsibility to protect Litzapp and its related parties from
                any potential harm or liability arising from your use of the
                platform. These provisions help ensure that all Users can engage
                with Litzapp in a safe and fair environment.
              </p>
              <br />
              <h3 className="font-bold">12. Termination and Suspension</h3>
              <p>
                Litzapp reserves the right to terminate or suspend your access
                to the platform and your account at its sole discretion, without
                prior notice, for any reason, including but not limited to a
                breach of these Terms of Service. By using Litzapp, you agree to
                the following terms regarding termination and suspension:
              </p>
              <br />
              <h4 className="font-bold">12.1 Termination by Litzapp</h4>
              <ul className="pl-5 list-disc">
                <li>
                  For Breach of Terms: Litzapp may terminate or suspend your
                  account and access to the platform if you violate these Terms
                  of Service, any other agreements or policies incorporated by
                  reference, or any applicable laws or regulations.
                </li>
                <li>
                  For Fraudulent Activity: Litzapp may terminate or suspend your
                  account if we detect or suspect fraudulent, deceptive, or
                  illegal activity associated with your account or use of the
                  platform.
                </li>
                <li>
                  For Misconduct: Litzapp may terminate or suspend your account
                  for any behavior that we deem harmful to other Users, the
                  platform, or Litzapp's reputation, including but not limited
                  to harassment, spamming, or posting prohibited content.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">12.2 Termination by User</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Voluntary Termination: You may terminate your account at any
                  time by following the account termination procedures provided
                  in your account settings. Upon termination, you must cease all
                  use of Litzapp.
                </li>
                <li>
                  Obligations Upon Termination: Upon termination of your
                  account, you remain responsible for any outstanding payments
                  and any obligations incurred prior to termination. Litzapp
                  reserves the right to retain and use your information as
                  necessary to comply with legal obligations, resolve disputes,
                  and enforce these Terms.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">12.3 Effects of Termination</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Access to Content: Upon termination of your account, you will
                  lose access to any content, products, services, or
                  subscriptions associated with your account. Litzapp is not
                  obligated to retain or provide you with access to any data or
                  information after termination.
                </li>
                <li>
                  Outstanding Payments: Any outstanding payments owed to Litzapp
                  or other Users must be settled immediately upon termination.
                  Litzapp reserves the right to deduct any such amounts from
                  your account balance before processing any final payout.
                </li>
                <li>
                  No Refunds: Termination of your account, whether by you or by
                  Litzapp, does not entitle you to any refunds for any fees or
                  payments made prior to termination.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">12.4 Suspension of Services</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Temporary Suspension: Litzapp may temporarily suspend your
                  account and access to the platform to investigate potential
                  violations of these Terms, fraudulent activity, or other
                  misconduct. During the suspension period, you may be unable to
                  access your account or any associated content, products, or
                  services.
                </li>
                <li>
                  Restoration of Services: If Litzapp determines that the issues
                  leading to suspension have been resolved or that no violation
                  occurred, we will restore your account and access to the
                  platform. However, repeated violations or misconduct may lead
                  to permanent termination.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">12.5 Appeals Process</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Appealing Termination or Suspension: If you believe your
                  account has been terminated or suspended in error, you may
                  contact Litzapp's support team to appeal the decision. Your
                  appeal must include a detailed explanation of why you believe
                  the termination or suspension was unwarranted. Litzapp will
                  review your appeal and make a determination at its sole
                  discretion.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">12.6 Survival of Terms</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Survival of Provisions: Provisions of these Terms that by
                  their nature should survive termination, including but not
                  limited to provisions regarding indemnification, limitation of
                  liability, disclaimers, payment obligations, and dispute
                  resolution, will remain in effect after your account is
                  terminated.
                </li>
              </ul>
              <p>
                By agreeing to these Termination and Suspension terms, you
                acknowledge the circumstances under which your access to Litzapp
                may be restricted or revoked and the responsibilities you retain
                in such events. These terms help maintain the integrity and
                safety of the Litzapp platform for all Users.
              </p>
              <br />
              <h3 className="font-bold">
                13. Governing Law and Dispute Resolution
              </h3>
              <h4 className="font-bold">13.1 Governing Law</h4>
              <p>
                These Terms of Service and any disputes arising out of or
                relating to these Terms, the use of Litzapp, or any products,
                services, or content offered on Litzapp will be governed by and
                construed in accordance with the laws of the State of Florida,
                United States of America, without regard to its conflict of law
                principles.
              </p>
              <br />
              <h4 className="font-bold">13.2 Dispute Resolution</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Informal Resolution: Before initiating any formal dispute
                  resolution process, you agree to first contact Litzapp at
                  admin@litzapp.com to seek an informal resolution of any
                  dispute. Both parties agree to make a good faith effort to
                  resolve any dispute, claim, or controversy through
                  discussions.
                </li>
                <li>
                  Arbitration Agreement: If the parties are unable to resolve
                  the dispute informally within 30 days of initiating
                  discussions, the dispute shall be resolved by binding
                  arbitration as set forth below. You and Litzapp agree to
                  submit to the personal and exclusive arbitration of any
                  disputes relating to your use of Litzapp under the rules of
                  the American Arbitration Association.
                </li>
                <li>
                  Arbitration Rules: The arbitration will be conducted in Miami,
                  Florida, and will be subject to the rules of the American
                  Arbitration Association ("AAA"). The arbitrator's decision
                  will be binding and may be entered as a judgment in any court
                  of competent jurisdiction. The arbitration will be conducted
                  by a single arbitrator who is knowledgeable in the subject
                  matter at issue.
                </li>
                <li>
                  Arbitration Fees: Each party will be responsible for its own
                  arbitration fees and costs. The arbitrator shall not have the
                  authority to award punitive damages to either party.
                </li>
                <li>
                  Exceptions to Arbitration: Notwithstanding the above, either
                  party may seek injunctive or other equitable relief in any
                  court of competent jurisdiction for matters related to
                  intellectual property rights or unauthorized access to or use
                  of the platform.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">13.3 Class Action Waiver</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Class Actions Prohibited: You and Litzapp agree that any
                  disputes arising out of or related to these Terms of Service,
                  the use of Litzapp, or any products, services, or content
                  offered on Litzapp must be resolved on an individual basis.
                  Neither party will participate in any class action or other
                  representative proceeding.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">13.4 Jurisdiction and Venue</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Jurisdiction: For any actions not subject to arbitration, you
                  and Litzapp agree to submit to the exclusive jurisdiction of
                  the state and federal courts located in Miami, Florida.
                </li>
                <li>
                  Venue: Any litigation that is permitted under these Terms will
                  be conducted in the state or federal courts located in Miami,
                  Florida. You and Litzapp agree to submit to the personal
                  jurisdiction of these courts for the purpose of litigating any
                  such claims or disputes.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">13.5 Limitation on Claims</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Time Limit: You agree that any claim or cause of action
                  arising out of or related to your use of Litzapp or these
                  Terms of Service must be filed within one year after such
                  claim or cause of action arose. Failure to file within this
                  time period will result in the claim being permanently barred.
                </li>
              </ul>
              <p>
                By agreeing to these Governing Law and Dispute Resolution terms,
                you acknowledge the procedures for resolving disputes with
                Litzapp and the applicable jurisdiction and laws governing these
                Terms. These provisions ensure a structured process for dispute
                resolution and help maintain a fair and consistent approach to
                legal matters.
              </p>
              <br />
              <h3 className="font-bold">14. Amendments to Terms</h3>
              <h4 className="font-bold">14.1 Right to Amend</h4>
              <p>
                Litzapp reserves the right to amend these Terms of Service at
                any time. Amendments will be made at Litzapp's sole discretion
                and will be effective immediately upon posting the updated Terms
                on the Litzapp platform. Users are encouraged to review these
                Terms periodically for any changes.
              </p>
              <br />
              <br />
              <h4 className="font-bold">14.2 Notification of Changes</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Notice of Amendments: When amendments to these Terms are made,
                  Litzapp will notify Users by posting the updated Terms on the
                  platform and updating the "Last Updated" date at the beginning
                  of these Terms. In some cases, Litzapp may also notify Users
                  through additional means, such as email notifications or
                  in-platform alerts.
                </li>
                <li>
                  Review and Acceptance: By continuing to access or use Litzapp
                  after the amended Terms have been posted, you agree to be
                  bound by the updated Terms. If you do not agree to the amended
                  Terms, you must cease using Litzapp immediately.
                </li>
              </ul>
              <br />
              <h4 className="font-bold">14.3 Material Changes</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Definition of Material Changes: Material changes are
                  significant amendments that affect your rights or obligations
                  under these Terms. Examples include changes to payment
                  policies, dispute resolution processes, or other essential
                  aspects of the Terms.
                </li>
                <li>
                  Advance Notice for Material Changes: For material changes,
                  Litzapp will provide Users with at least 20 days' advance
                  notice before the changes become effective. This notice period
                  allows Users to review the changes and decide whether to
                  continue using Litzapp under the updated Terms.
                </li>
              </ul>
              <h4 className="font-bold">14.4 User Feedback</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Solicitation of Feedback: Litzapp values User feedback and may
                  solicit input from Users regarding proposed changes to these
                  Terms. User feedback will be considered, but Litzapp retains
                  final authority to implement amendments as deemed appropriate.
                </li>
                <li>
                  Contact Information: Users may submit feedback or questions
                  regarding amendments to these Terms by contacting Litzapp at
                  admin@litzapp.com. Litzapp will review and respond to User
                  feedback as appropriate.
                </li>
              </ul>
              <h4 className="font-bold">14.5 Continued Use</h4>
              <ul className="pl-5 list-disc">
                <li>
                  Implied Acceptance: Your continued access to or use of Litzapp
                  following the posting of amended Terms constitutes your
                  acceptance of those changes. It is your responsibility to
                  regularly check for updates to these Terms.
                </li>
                <li>
                  Opt-Out Option: If you do not agree with any amendments to
                  these Terms, your sole remedy is to discontinue using Litzapp.
                  You may terminate your account as described in the Termination
                  and Suspension section of these Terms.
                </li>
              </ul>
              <p>
                By agreeing to these Amendments to Terms provisions, you
                acknowledge that Litzapp may modify these Terms from time to
                time and that you are responsible for staying informed about any
                changes. This process ensures that the Terms remain current and
                reflective of Litzapp's services and policies.
              </p>
              <br />
              <h3 className="font-bold">15. Miscellaneous Provisions</h3>
              <h4 className="font-bold">15.1 Entire Agreement</h4>
              <p>
                These Terms of Service, along with any policies or operating
                rules posted by Litzapp on the platform, constitute the entire
                agreement and understanding between you and Litzapp. They
                supersede any prior agreements, communications, and
                understandings, whether oral or written, between you and Litzapp
                regarding the use of the platform.
              </p>
              <br />
              <h4 className="font-bold">15.2 Severability</h4>
              <p>
                If any provision of these Terms is found to be unlawful, void,
                or unenforceable, that provision shall be deemed severable from
                these Terms and shall not affect the validity and enforceability
                of any remaining provisions. The remaining provisions of these
                Terms will remain in full force and effect.
              </p>
              <br />
              <h4 className="font-bold">15.3 Waiver</h4>
              <p>
                No waiver of any term or condition set forth in these Terms
                shall be deemed a further or continuing waiver of such term or
                condition or a waiver of any other term or condition. Any
                failure of Litzapp to assert a right or provision under these
                Terms shall not constitute a waiver of such right or provision.
              </p>
              <br />
              <h4 className="font-bold">15.4 Assignment</h4>
              <p>
                Litzapp may assign its rights and obligations under these Terms
                to any party at any time without notice to you. You may not
                assign or transfer these Terms, or any of your rights or
                obligations hereunder, without Litzapp's prior written consent,
                and any attempt to do so without such consent will be null and
                void.
              </p>
              <h4 className="font-bold">15.5 Relationship of the Parties</h4>
              <p>
                Nothing in these Terms shall be construed to create a
                partnership, joint venture, employment, or agency relationship
                between you and Litzapp. You do not have any authority to bind
                Litzapp in any respect whatsoever.
              </p>
              <h4 className="font-bold">15.6 Third-Party Beneficiaries</h4>
              <p>
                These Terms do not and are not intended to confer any rights or
                remedies upon any person other than you and Litzapp. No other
                person shall have any right to enforce any of these Terms.
              </p>
              <br />
              <h4 className="font-bold">15.7 Force Majeure</h4>
              <p>
                Litzapp shall not be liable for any failure or delay in
                performance under these Terms due to circumstances beyond its
                reasonable control, including but not limited to acts of God,
                war, terrorism, riots, embargoes, acts of civil or military
                authorities, fire, floods, accidents, network infrastructure
                failures, strikes, or shortages of transportation facilities,
                fuel, energy, labor, or materials.
              </p>
              <br />
              <h4 className="font-bold">15.8 Governing Language</h4>
              <p>
                These Terms are written in English, and all communications and
                notices made or given pursuant to these Terms shall be in
                English. If these Terms are translated into any other language,
                the English language version shall govern in the event of any
                conflict or discrepancy between the English version and the
                translated version.
              </p>
              <br />
              <h4 className="font-bold">15.9 Contact Information</h4>
              <p className="mb-2">
                For any questions or concerns about these Terms of Service,
                please contact Litzapp at:
              </p>
              <p>
                Remote Analytics Jobs Inc. <br />
                126 Southwest 17th Road, Unit 509 <br />
                Miami, Florida, 33129 <br />
                United States of America.
                <br />
                <span className="font-bold text-blue-500">
                  <a href="mailto:customerservices@litzapp.com">
                    customerservices@litzapp.com
                  </a>{" "}
                </span>
              </p>
              <br />
              <p>
                By agreeing to these Miscellaneous Provisions, you acknowledge
                and accept the foundational terms that govern the use of
                Litzapp, ensuring clarity and legal coherence in all
                interactions and transactions on the platform.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
