import Checkbox from "../../../../../../inputs/checkbox";
import BasicButton from "../../../../../../buttons/basic-button";
import TextField from "../../../../../../inputs/text-input";

export default function ListsModal(props) {
  const { modalOpen } = props;
  const { hideModal } = props;
  const { allProducts } = props;
  const { selListProducts } = props;
  const { handleCheckListProduct } = props;
  const { listName } = props;
  const { createList } = props;
  const { editList } = props;
  const { deleteList } = props;
  const { handleListNameChange } = props;
  const { isListEditMode } = props;
  const { processing } = props;

  const getListActionTitle = () => {
    if (!isListEditMode) {
      return processing ? "Creating.." : "Create";
    } else {
      return processing ? "Updating.." : "Update";
    }
  };

  return (
    <div
      className={`absolute z-50 overflow-y-auto top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-6/12 h-4/12 P-2"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className="rounded rounded-full p-0 -top-2 -right-1 absolute px-1 cursor-pointer opacity-75"
            onClick={() => hideModal()}
          >
            <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
          </div>
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <h1 className="text-2xl font-bold text-center flex items-center justify-center">
                {" "}
                <span>Create List</span>
              </h1>
              <p>{selListProducts.length} Items Selected</p>
              <div className="w-full flex gap-1">
                {" "}
                <TextField
                  classes={"p-6 my-2"}
                  placeholder={"Enter List Title.."}
                  handleChange={(e) => {
                    handleListNameChange(e);
                  }}
                  value={listName}
                />{" "}
                {!listName && <span className="text-red-500 text-2xl">*</span>}
              </div>
              <p className="text-green-500">
                {!selListProducts.length
                  ? "Select one or more items to create a list"
                  : ""}
              </p>
              <div className="flex gap-2 w-full justify-center flex flex-col h-40 overflow-auto p-2">
                {processing ? (
                  <p>Loading..</p>
                ) : (
                  allProducts.map((p) => (
                    <div className="flex gap-1 flex gap-2 my-2">
                      <div>
                        <Checkbox
                          handleChange={() => handleCheckListProduct(p.uuid)}
                          checked={selListProducts.includes(p.uuid)}
                        />
                      </div>
                      <div>{p.name}</div>
                    </div>
                  ))
                )}
              </div>
              <div className="flex flex-col w-full justify-center "></div>
              <div className="flex flex-row gap-2 items-center justify-center">
                <BasicButton
                  handleClick={!isListEditMode ? createList : editList}
                  disabled={!selListProducts.length || !listName || processing}
                  title={getListActionTitle()}
                  classes={"my-3"}
                />
                <BasicButton
                  handleClick={deleteList}
                  disabled={processing}
                  title={"Delete List"}
                  classes={"my-3 bg-red-500 hover:bg-red-500"}
                />
              </div>
            </div>
            {/* {!createProductMetaMode ? productMeta.p_type_sub_opt : "Invalid"} */}

            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => {
                hideModal();
              }}
            >
              <i class="fas fa-times"></i> Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
