import { useSupplierApplicationsStore } from "../../../../../stores/supplier-applications-store";
import { isResponsiveMode } from "../../../../../Utils/helpers";
import Checkbox from "../../../../../components/inputs/checkbox";
import BasicButton from "../../../../../components/buttons/basic-button";
import { useState } from "react";
import AdminSupplierPhotoViewerModal from "./photo-viewer";
import { BASE_URL, BASE_URL_WITH_LOCAL } from "../../../../../Utils/helpers";

export default function MobileTable(props) {
  const { disableAccount, enableAccount, processing, downloadVideo } = props;
  const mailStore = useSupplierApplicationsStore((state) => state);

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const openImageModal = (d) => {
    const link = BASE_URL + "/storage" + d.photo.split("public")[1];
    setImageUrl(link);
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
  };

  const appicationStatus = {
    redo_visual_verification: "Redo Visual Verification",
    redo_business_verification: "Redo Business Verification",
    redo_contract_verification: "Redo Contract Verification",
    redo_supplier_verification: "Redo Supplier Verification",
    approved: "Application Approved",
    rejected: "Application Rejected",
    unprocessed: "Unprocessed",
  };

  const contractTitles = {
    "subscription-contract": "Subscription Contract (Recurring Purchase)",
    "hire-contract": "Hire Contract (Recurring Purchase)",
    "rent-contract": "Rent Contract (Recurring Purchase)",
    "lease-contract": "Lease Contract (Recurring Purchase)",
    "vacation-rental-contract": "Vacation Rental Contract (Recurring Purchase)",
    "sp-contract": "Default Contract (Single Purchase)",
  };

  return (
    <>
      <div class="w-full flex flex-col justify-center my-4 text-center">
        {mailStore.application?.id && (
          <div
            className="overflow-x-scroll inline"
            style={{
              width: document.documentElement.clientWidth - 90 + "px",
              margin: "0 auto",
            }}
          >
            <div className="flex">
              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold md:hidden">Date</h3>
                <p>
                  {mailStore.application.refactoredCreatedAtTime
                    ? new Date(
                        mailStore.application.refactoredCreatedAtTime.replace(
                          /-/g,
                          "/"
                        )
                      ).getDate() +
                      "-" +
                      (new Date(
                        mailStore.application.refactoredCreatedAtTime.replace(
                          /-/g,
                          "/"
                        )
                      ).getMonth() +
                        1) +
                      "-" +
                      new Date(
                        mailStore.application.refactoredCreatedAtTime.replace(
                          /-/g,
                          "/"
                        )
                      ).getFullYear()
                    : "N/A"}
                </p>
              </div>

              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold md:hidden">Name</h3>
                {mailStore.application.user.name}
              </div>
              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold md:hidden">Username</h3>
                {mailStore.application.user.username}
              </div>
              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold md:hidden">User Handle</h3>
                {mailStore.application.user.handle_id}
              </div>
              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold md:hidden">Supplier Type</h3>
                {mailStore?.application?.type || "Individual"}
              </div>

              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold md:hidden">Email</h3>
                {mailStore.application?.type == "individual"
                  ? mailStore.application?.profile_identity
                  : mailStore.application?.business_name}
              </div>

              <div class="border  px-4 py-2  font-medium wrap-words wrap-all">
                <h3 className="font-bold md:hidden">Email</h3>
                {mailStore.application?.type == "individual"
                  ? mailStore.application?.profile_identity_link
                  : mailStore.application?.business_link}
              </div>

              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold md:hidden">Business Location</h3>
                {mailStore?.application?.address || "Individual"}
              </div>

              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold md:hidden">Face Photo</h3>
                <BasicButton
                  title={"View"}
                  classes={"my-bg-yellow-500 text-black"}
                  handleClick={() => openImageModal(mailStore?.application)}
                />
              </div>
              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold md:hidden">Verification Code</h3>
                {
                  mailStore?.application?.user
                    ?.latest_supplier_verification_code?.code
                }
              </div>
              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold md:hidden">Video</h3>
                <BasicButton
                  title={"View"}
                  classes={"my-bg-yellow-500 text-black"}
                  handleClick={() =>
                    downloadVideo(
                      mailStore?.application.video,
                      mailStore.application.user.handle_id
                    )
                  }
                />
              </div>
              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold md:hidden">Contract</h3>
                {/* <a
                  href={
                    BASE_URL_WITH_LOCAL +
                    "/storage" +
                    mailStore?.application?.user?.product_supplier_contract?.file?.split(
                      "public"
                    )[1]
                  }
                  target="_blank"
                >
                  {" "}
                  <BasicButton
                    disabled={!mailStore.application?.user?.uuid}
                    title={"View"}
                    classes={"my-bg-yellow-500 text-black"}
                    handleClick={() => null}
                  />
                </a> */}
                {!mailStore.application?.contract_type ||
                mailStore.application?.contract_type == "upload" ? (
                  <a
                    href={
                      BASE_URL_WITH_LOCAL +
                      "/storage" +
                      mailStore?.application?.user?.product_supplier_contract?.file?.split(
                        "public"
                      )[1]
                    }
                    target="_blank"
                  >
                    {" "}
                    <BasicButton
                      disabled={!mailStore.application?.user?.uuid}
                      title={"View"}
                      classes={"my-bg-yellow-500 text-black"}
                      handleClick={() => null}
                    />
                  </a>
                ) : (
                  contractTitles[mailStore.application?.contract_type]
                )}
              </div>
              <div class="border  px-4 py-2 font-medium">
                <h3 className="font-bold md:hidden">Contract</h3>
                <div
                  className="flex flex-col items-center"
                  style={{ width: "200px" }}
                >
                  {appicationStatus[mailStore?.application?.status]}
                </div>
              </div>
              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold md:hidden">Process</h3>

                <BasicButton
                  disabled={
                    !mailStore.application?.user?.uuid ||
                    processing ||
                    !mailStore?.application?.user?.supplier_account_enabled
                  }
                  title={processing ? "Processing..." : "Disable Account"}
                  classes={"my-bg-yellow-500 text-black"}
                  handleClick={() => disableAccount()}
                />
              </div>
              <div class="border  px-4 py-2  font-medium">
                <h3 className="font-bold md:hidden">Process</h3>
                <BasicButton
                  disabled={
                    !mailStore.application?.user?.uuid ||
                    processing ||
                    mailStore.application?.user?.supplier_account_enabled
                  }
                  title={processing ? "Processing..." : "Enable Account"}
                  classes={"my-bg-yellow-500 text-black"}
                  handleClick={() => enableAccount()}
                />
              </div>
            </div>
          </div>
        )}
        )
      </div>
      <AdminSupplierPhotoViewerModal
        modalOpen={imageModalOpen}
        closeModal={closeImageModal}
        imageUrl={imageUrl}
      />
    </>
  );
}
