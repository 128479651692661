import TextArea from "../../../../../../../components/inputs/text-area";
import EditorTooltip from "../../tooltip/editor-tooltip";
export default function PurchaserReply(props) {
  const { productMeta, handleMetaChange } = props;
  return (
    <>
      {" "}
      {productMeta.purchaser_reply_enabled && (
        <div className="m-2 mb-2 relative w-full">
          <div className="flex  justify-center gap-2 w-full">
            {" "}
            <label>Enter Reply Request (optional):</label>
            <EditorTooltip
              body={
                "In this section, you have the opportunity to request particular details from the customer such as their email, phone number, address, preferred meeting time, preferences, deadlines, and so forth before they can move forward with the purchase. The information given by the customer will appear in the confirmation emails that both parties will receive after the transaction is completed."
              }
              top={"-255px"}
              right={"-40px"}
            />
          </div>
          <TextArea
            placeholder={"Enter text here.."}
            classes="rounded-lg pr-8"
            handleChange={(e) => handleMetaChange(e, "purchaser_reply", "text")}
            value={productMeta.purchaser_reply}
          />
        </div>
      )}
    </>
  );
}
