import TextNumberField from "../../../../../../../components/inputs/text-number-input";
export default function ServiceZipCode(props) {
  const { productMeta, handleMetaChange, pageContext, user } = props;

  return (
    <div className="m-2 mb-2 w-full md:w-6/12">
      <label className="text-black">Service Zip Code</label>
      <div className="flex gap-2 items-center">
        <TextNumberField
          classes={"p-6 my-2"}
          placeholder={"Enter code.."}
          handleChange={(e) => handleMetaChange(e, "service_zip_code", "text")}
          value={productMeta.service_zip_code}
        />
        {/* {!productMeta.service_zip_code && (
          <span className="text-red-500 text-2xl">*</span>
        )} */}
      </div>

      {/* <p className="text-base text-pink-500">
        {productMeta.service_zip_code && "Please enter a price greater than 0"}
      </p> */}
    </div>
  );
}
