import { useSendMailStore } from "../../../../stores/send-mail-store";
import { isResponsiveMode } from "../../../../Utils/helpers";
import Checkbox from "../../../../components/inputs/checkbox";
export default function DesktopPubSubscribersTable(props) {
  const mailStore = useSendMailStore((state) => state);

  const { blockedEmails } = props;
  const { shouldMarkItem } = props;
  const { markItem } = props;

  function capitalizeFirstLetter(str) {
    return str[0].toUpperCase() + str.slice(1);
  }

  return (
    <table class="table-auto w-full">
      <thead>
        <tr className={window.innerWidth < 720 ? "hidden" : ""}>
          <th class="px-4 py-2 text-gray-600">Name</th>
          <th class="px-4 py-2 text-gray-600">Country</th>
          <th class="px-4 py-2 text-gray-600">Transaction ID</th>
          <th class="px-4 py-2">Status</th>
          <th class="px-4 py-2">Duration</th>
          <th class="px-4 py-2">Date</th>
        </tr>
      </thead>
      <tbody>
        {mailStore.data.data
          ? mailStore.data.data.length
            ? mailStore.data.data.map((subscription) => {
                return (
                  <tr
                    className={
                      window.innerWidth < 820
                        ? "flex flex-col items-center"
                        : ""
                    }
                  >
                    <td
                      class={`${
                        isResponsiveMode() ? "border text-center" : ""
                      } px-4 py-2 font-medium md:hidden `}
                    >
                      {!blockedEmails.includes(
                        subscription.subscriber.email
                      ) ? (
                        <div className="md:hidden">
                          {" "}
                          <Checkbox
                            checked={
                              shouldMarkItem(subscription.id) ? true : false
                            }
                            handleChange={() => markItem(subscription.id)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                    <td class="border border-gray px-4 py-2  font-medium">
                      <div className="flex flex-col justify-center items-center">
                        <h3 className="font-bold md:hidden">Name</h3>
                        {subscription.subscriber.name}
                      </div>
                    </td>

                    <td class="border border-gray px-4 py-2  font-medium">
                      <div className="flex flex-col justify-center items-center">
                        <h3 className="font-bold md:hidden">Country</h3>
                        {subscription.subscriber.country}
                      </div>
                    </td>
                    <td class="border border-gray px-4 py-2  font-medium">
                      <div className="flex flex-col justify-center items-center">
                        <h3 className="font-bold md:hidden">Transaction ID</h3>
                        {subscription.transaction_id}
                      </div>
                    </td>
                    <td class="border border-gray px-4 py-2  font-medium">
                      <div className="flex flex-col justify-center items-center">
                        <h3 className="font-bold md:hidden">Status</h3>
                        {capitalizeFirstLetter(subscription.status)}
                      </div>
                    </td>
                    <td class="border border-gray px-4 py-2 font-medium text-center">
                      <h3 className="font-bold md:hidden ">Duration</h3>
                      <div className="flex flex-col items-center gap-4">
                        {" "}
                        <p>
                          <b>Starts: </b>
                          {subscription.start_date
                            ? new Date(
                                subscription.start_date.replace(/-/g, "/")
                              ).getDate() +
                              "-" +
                              (new Date(
                                subscription.start_date.replace(/-/g, "/")
                              ).getMonth() +
                                1) +
                              "-" +
                              new Date(
                                subscription.start_date.replace(/-/g, "/")
                              ).getFullYear()
                            : "N/A"}
                        </p>
                        <p>
                          <b>Ends: </b>
                          {subscription.end_date
                            ? new Date(
                                subscription.end_date.replace(/-/g, "/")
                              ).getDate() +
                              "-" +
                              (new Date(
                                subscription.end_date.replace(/-/g, "/")
                              ).getMonth() +
                                1) +
                              "-" +
                              new Date(
                                subscription.end_date.replace(/-/g, "/")
                              ).getFullYear()
                            : "N/A"}
                        </p>
                      </div>
                    </td>

                    <td class="border border-gray px-4 py-2  font-medium">
                      <div className="flex justify-center">
                        {" "}
                        <h3 className="font-bold md:hidden">Date</h3>
                        {subscription.created_at
                          ? new Date(
                              subscription.created_at.replace(/-/g, "/")
                            ).getDate() +
                            "-" +
                            (new Date(
                              subscription.created_at.replace(/-/g, "/")
                            ).getMonth() +
                              1) +
                            "-" +
                            new Date(
                              subscription.created_at.replace(/-/g, "/")
                            ).getFullYear()
                          : "N/A"}
                      </div>
                    </td>
                    <td
                      class={`${
                        !isResponsiveMode() ? "border" : ""
                      } px-4 py-2  font-medium `}
                    >
                      {!blockedEmails.includes(
                        subscription.subscriber.email
                      ) ? (
                        <div className="hidden md:block">
                          {" "}
                          <Checkbox
                            checked={
                              shouldMarkItem(subscription.id) ? true : false
                            }
                            handleChange={() => markItem(subscription.id)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })
            : ""
          : ""}
      </tbody>
    </table>
  );
}
