import TextField from "../components/inputs/text-input";
import BasicButton from "../components/buttons/basic-button";
import TermsAccordion from "../accordions/terms-accordion";
import { useBookStore } from "../stores/book-store";
import { forwardRef, useState, useEffect } from "react";
import StripeForm from "../payment/pay-button";
import Register from "../pages/register";
import { ValidateEmail, donationPriceWithSystemFees } from "../Utils/helpers";
import StripeDonateForm from "../payment/donate-button";

export default function DonationEmailModal({
  modalOpen,
  hideModal,
  handleEmailChange,
  email,
  amount,
  book,
}) {
  const [emailValidationMessage, setEmailValidationMessage] = useState("");
  useEffect(() => {
    if (email) {
      if (!ValidateEmail(email)) {
        setEmailValidationMessage("Invalid email address");
        console.log("Invalid");
      } else {
        console.log("Valid");
        setEmailValidationMessage("");
      }
    }
  }, [email]);
  return (
    <div
      className={`absolute z-50 overflow-y-auto top-0 w-full left-0  ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh ">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-2 sm:align-middle md:w-6/12 h-4/12 "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-col justify-center">
            {/* modal body */}

            {/* <Register type={`modal`} action={hideModal} book={book} /> */}

            <div className="p-2">
              <h1 className="text-2xl font-bold text-center">
                {/* {getActiveWidgetTitle(currentEditingWidget, widgets)} */}
                Email Address
              </h1>
            </div>

            <div className="p-2 flex flex-col justify-center items-center ">
              <TextField
                placeholder={"Email..."}
                classes="w-full"
                handleChange={handleEmailChange}
                value={email}
              />
              <p className="text-red-500">{email && emailValidationMessage}</p>
            </div>

            <div className="flex justify-center">
              <p className="w-8/12 text-sm"> </p>
            </div>

            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 flex flex-col md:flex-row justify-between gap-3 items-center">
            {/* <div className="flex justify-center">
              <BasicButton title={`Ad-Free $${book.price}`} />
            </div> */}

            {!email || !ValidateEmail(email) ? (
              <BasicButton
                disabled={!email || !ValidateEmail(email)}
                title={`Donate $${donationPriceWithSystemFees(book, amount)}`}
                classes="px-8 my-2 md:my-0"
                handleClick={() => null}
              />
            ) : (
              <StripeDonateForm
                book={book}
                amount={`${amount}`}
                action={hideModal}
                email={email}
              />
            )}

            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2 xs:w-full"
              onClick={() => hideModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
            {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
