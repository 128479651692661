import { useRef, useEffect, useState } from "react";
import {
  API_BASE,
  BASE_URL,
  isAuthUser,
  authUserData,
  isResponsiveMode,
  getLocation,
  isAdmin,
} from "../../Utils/helpers";
import { toast } from "react-toastify";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
import AdSense from "react-adsense";
import { score, rate, average } from "average-rating";
import RatingBox from "../rating";
import Switch from "react-switch";
import TextField from "../inputs/text-input";
import ProfileList from "../user/profiles/list";
import authRequestService from "../../services/HttpRequests/authenticated-request-services";
import useDebounce from "../debounce/debounce-helper";

export default function BookSliderGridAdmin(props) {
  const { getPublisher, publisher, publisherId } = props;
  const [books, setBooks] = useState([]);
  const [nextPageURL, setNextPageURL] = useState("");
  const [lastRatedBookId, setLastRatedBookId] = useState("");
  const [lastRatedBook, setLastRatedBook] = useState();
  const [currentRating, setCurrentRating] = useState();
  const [currentRatingFilter, setCurrentRatingFilter] = useState(0);
  const [
    currentRatingFilterSpecification,
    setCurrentRatingFilterSpecification,
  ] = useState("");
  const [renderSlider, setRenderSlider] = useState(true);
  const [rating, setRating] = useState(0);
  const boxRef = useRef();
  const cardContainerRef = useRef();
  const [pageNumber, setPageNumber] = useState(1);
  const [published, setPublished] = useState([]);
  const [currEditingBook, setCurrEditingBook] = useState();
  const [activeTab, setactActiveTab] = useState(0);
  const [fileSelected, setFileSelected] = useState();
  const [profileText, setProfileText] = useState("");
  const [showRatingDropdown, setShowRatingDropdown] = useState(false);
  const [bookType, setBookType] = useState("slides");
  const [searchText, setSearchText] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);
  const [moreTags, setMoreTags] = useState([]);

  var autoSlide;

  const getBooks = () => {
    setBooks([]);
    const url =
      API_BASE +
      `/admin/publisher/books/${bookType}/paginated/user/` +
      publisherId;
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setBooks(response.data.books.data);
          setNextPageURL(response?.data?.books?.next_page_url);
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const getBooksByAppliedDFilters = () => {
    setBooks([]);
    const url =
      API_BASE +
      `/admin/publisher/books/${bookType}/paginated/filtered/user/${publisherId}/${currentRatingFilter}/${currentRatingFilterSpecification.value}/${searchText}`;
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.books) {
            setBooks(response.data.books.data);
            setNextPageURL(response.data.books.next_page_url);
          }
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const loadMore = () => {
    if (nextPageURL) {
      const url =
        getLocation().hostname == "litzapp.com"
          ? API_BASE + nextPageURL.split("api/api")[1]
          : nextPageURL.split("api")[1];

      authRequestService()
        .get(url)
        .then((response) => {
          if (response.status == 200) {
            let oldBooks = books;
            let newBooks = oldBooks.concat(response.data.books.data);
            if (response.data.books.data) {
              setBooks(newBooks);
              setNextPageURL(response.data.books.next_page_url);
            }
          }
        })
        .catch((error) => {
          toast("Something went wrong", { type: "error" });
        });
    }
  };

  const rateBook = (value, book) => {
    const url = API_BASE + "/rating";

    if (!isAuthUser()) {
      toast("Log in to leave a rating", { type: "notice" });
      return false;
    }
    if (book.owneroptimized.uuid == authUserData().uuid) {
      return false;
    }
    if (
      book.lastpageviewed &&
      !book.lastpageviewed.filter((p) => p.user_id == authUserData().id)
        .length &&
      !hasPurchasedBook(book)
    ) {
      toast(
        "You can only leave a rating if you have viewed the content to its last slide/page or you have purchased the content.",
        { type: "notice" }
      );
      return false;
    }

    setLastRatedBookId(book.uuid);
    setCurrentRating(value);
    authRequestService()
      .post(url, {
        value: value,
        bookId: book.uuid,
        userId: authUserData().uuid,
      })
      .then((response) => {
        if (response.status == 200) {
          setLastRatedBook(response.data.book);
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const hasPurchasedBook = (book) => {
    if (!isAuthUser()) {
      return false;
    }

    if (book) {
      const bought = book.buyers.filter((buyer) => {
        if (buyer.user_id == authUserData().id) {
          return buyer;
        }
      });

      return bought.length ? true : false;
    }
  };

  const trackPublisher = (publisherId, trackerId) => {
    const url = API_BASE + "/track";
    authRequestService()
      .post(url, { publisherId: publisherId, trackerId: trackerId })
      .then((response) => {
        if (response.status == 200) {
          getPublisher();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const untrackPublisher = (publisherId, trackerId) => {
    const url = API_BASE + "/untrack";

    authRequestService()
      .post(url, { publisherId: publisherId, trackerId: trackerId })
      .then((response) => {
        if (response.status == 200) {
          getPublisher();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const isTrackingPublisher = (owneroptimized) => {
    if (owneroptimized.trackers && isAuthUser()) {
      const isTracking = owneroptimized.trackers.filter(
        (t) => t.tracker_id == authUserData().id
      );
      return isTracking.length ? true : false;
    }

    return false;
  };

  const updateBook = (book) => {
    const url = API_BASE + "/api/book/update";
    let formData = new FormData();
    formData.append("uuid", book.uuid);
    formData.append(
      "admin_publish_status",
      published.includes(book.uuid) ? 1 : 0
    );
    axios
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          toast("Updated!", { type: "success" });
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const handlePublishStatus = (e, book) => {
    let newPublish = published;
    if (newPublish.filter((p) => p == book.uuid).length) {
      let removeItem = newPublish.filter((p) => p !== book.uuid);
      setPublished(removeItem);
      setCurrEditingBook(book);
      console.log("item exists");
    } else {
      console.log("item not exists");
      setCurrEditingBook(book);
      setPublished((prev) => [...prev, book.uuid]);
    }
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e);
    setFirstLoad(false);
  };

  const applyRatingFilter = (value) => {
    setCurrentRatingFilter(value);
    setCurrentRatingFilterSpecification("");
    setShowRatingDropdown(true);
  };

  const resetRating = () => {
    handleSearchTextChange("");
    setCurrentRatingFilter(0);
    setCurrentRatingFilterSpecification("");
    // getBooks();
  };

  const applyRatingFilterSpecification = (e) => {
    setCurrentRatingFilterSpecification(e);
    setShowRatingDropdown(false);
    setFirstLoad(false);
  };

  useEffect(() => {
    books.map((book) =>
      setPublished((prevState) =>
        !prevState.includes(book.uuid) && book.admin_publish_status
          ? [...prevState, book.uuid]
          : prevState
      )
    );
  }, [books]);

  const renderTags = (book) => {
    let tagsToRender = 0;
    let updatedTags = [];

    if (moreTags.includes(book.uuid)) {
      for (let index = 0; index < book.tags.length; index++) {
        updatedTags.push({ type: "tag", content: book.tags[index] });

        if (tagsToRender == book.tags.length - 1) {
          updatedTags.push({ type: "view_less", content: "" });
        }
        tagsToRender++;
      }
    } else {
      for (let index = 0; index < book.tags.length; index++) {
        tagsToRender++;
        if (tagsToRender > 3) {
          updatedTags.push({ type: "view_more", content: "" });
          break;
        } else {
          updatedTags.push({ type: "tag", content: book.tags[index] });
        }
      }
    }

    return updatedTags.map((t) => {
      if (t.type == "tag") {
        return (
          <div
            className="rounded-full text-black border p-1 inline relative m-1 px-1 bg-pink-50 cursor-pointer"
            onClick={() => null}
          >
            <span className="text-xs pr-1">${t.content.name}</span>
          </div>
        );
      }

      if (t.type == "view_more") {
        return (
          <p
            className="text-xs text-blue-500 cursor-pointer underline"
            onClick={() => showMoreTags(book.uuid)}
          >
            View More Tags...
          </p>
        );
      }

      if (t.type == "view_less") {
        return (
          <p
            className="text-xs text-blue-500 cursor-pointer underline"
            onClick={() => showLessTags(book.uuid)}
          >
            View Less Tags...
          </p>
        );
      }
    });
  };

  const showMoreTags = (id) => {
    setMoreTags((prev) => [...prev, id]);
  };

  const showLessTags = (id) => {
    let currTags = moreTags;
    let filteredTags = currTags.filter((t) => t !== id);
    setMoreTags(filteredTags);
  };

  useEffect(() => {
    console.log("published", published);
    if (currEditingBook) {
      updateBook(currEditingBook);
    }
  }, [published]);

  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
  }, []);

  const goToContent = (d) => {
    if (d.group == "standard") {
      window.location.href = `/web/reader/${d.uuid}`;
    }
    if (d.group == "custom" && d.type !== "product") {
      window.location.href = `/web/rich-page/render/${d.uuid}`;
    }

    if (d.group == "custom" && d.type == "product") {
      window.location.href = `/web/rich-page/render/${d.uuid}`;
    }
  };

  useEffect(() => {
    // if (isResponsiveMode()) {
    //   import("../../../src/landing_mobile.css");
    // } else {
    //   import("../../../src/landing.css");
    // }
  }, []);

  useEffect(() => {
    if (activeTab == 0) {
      setBookType("slides");
    }
    if (activeTab == 1) {
      setBookType("photo-album");
    }
    if (activeTab == 2) {
      setBookType("book");
    }
    if (activeTab == 3) {
      setBookType("comic");
    }
    if (activeTab == 4) {
      setBookType("tracking");
    }
    if (activeTab == 5) {
      setBookType("trackers");
    }
    if (activeTab == 6) {
      setBookType("custom");
    }
    if (activeTab == 7) {
      setBookType("product");
    }
    resetRating();
    setSearchText("");
    setFirstLoad(false);
  }, [activeTab]);

  useEffect(() => {
    if (!firstLoad) {
      getBooks();
    }
  }, [bookType]);

  useEffect(() => {
    if (publisher.name) {
      //   alert(JSON.stringify(book));
      setProfileText(publisher.profile_text);
      if (publisher.profile_photo && publisher?.backup_profile_photo) {
        setFileSelected(
          BASE_URL + "/storage" + publisher?.profile_photo?.split("public")[1]
        );
      }
      if (publisher?.profile_photo && !publisher?.backup_profile_photo) {
        setFileSelected(
          BASE_URL + "/storage" + publisher?.profile_photo?.split("public")[1]
        );
      }

      if (!publisher?.profile_photo && publisher?.backup_profile_photo) {
        setFileSelected(
          BASE_URL +
            "/storage" +
            publisher?.backup_profile_photo?.split("public")[1]
        );
      }
    }
  }, [publisher]);

  const renderCoverPhotoWithFallbackImage = (data) => {
    if (data?.cover_photo_thumb) {
      return (
        BASE_URL + "/storage" + data?.cover_photo_thumb?.split("public")[1]
      );
    }

    if (data?.productimages?.length) {
      return (
        BASE_URL +
        "/storage" +
        data?.productimages[0]?.product_thumb?.split("public")[1]
      );
    }

    if (data?.owner_optimized_data?.profile_photo) {
      return (
        BASE_URL +
        "/storage/" +
        data?.owner_optimized_data?.profile_photo?.split("public")[1]
      );
    } else {
      return (
        BASE_URL +
        "/storage/" +
        data?.owner_optimized_data?.backup_profile_photo?.split("public")[1]
      );
    }
  };

  useEffect(() => {
    getPublisher();
  }, []);

  useDebounce(
    () => {
      if (currentRatingFilter > 0) {
      } else {
        setShowRatingDropdown(false);
      }

      if (
        (activeTab < 4 && currentRatingFilterSpecification.value) ||
        searchText
      ) {
        getBooksByAppliedDFilters();
      } else {
        getBooks();
      }
    },
    [currentRatingFilterSpecification, searchText],
    800
  );

  return (
    <section>
      <header class="flex flex-wrap items-center md:items-start p-4 ">
        <div class="md:w-3/12 md:ml-16">
          {/* <!-- profile image --> */}
          <img
            class="w-20 h-20 md:w-40 md:h-40 object-cover rounded-full
                       p-1"
            src={fileSelected ? fileSelected : "/logo_text.png"}
            alt="profile"
          />
        </div>

        {/* <!-- profile meta --> */}
        <div class="w-8/12 md:w-7/12 ml-4 ">
          <div class="w-full text-center flex flex-col items-center md:items-start mb-4">
            <p className="">
              <span className=""> username: </span>
              <span className="text-yellow-600 font-bold">
                {publisher.name ? publisher?.username : ""}
              </span>
            </p>
            <p className="mb-2 mt-1">
              <span className=""> user handle: </span>
              <span className="text-yellow-600 font-bold">
                {publisher.name ? publisher.handle_id : ""}
              </span>
            </p>

            {publisher ? (
              <button
                disabled={
                  !isAuthUser() || publisher.uuid == authUserData().uuid
                }
                onClick={() =>
                  isAuthUser()
                    ? isTrackingPublisher(publisher)
                      ? untrackPublisher(publisher.uuid, authUserData().uuid)
                      : trackPublisher(publisher.uuid, authUserData().uuid)
                    : null
                }
                class={`whitespace-nowrap inline-flex items-center justify-center px-8 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white ${
                  !isAuthUser() || publisher.uuid == authUserData().uuid
                    ? "bg-gray-500 hover:bg-gray-500"
                    : "bg-black hover:bg-black"
                }`}
              >
                {isTrackingPublisher(publisher) ? "Untrack" : "Track"}
              </button>
            ) : (
              ""
            )}
          </div>

          {/* <!-- post, following, followers list for medium screens --> */}
          <ul class="hidden md:flex space-x-8 mb-2">
            <li>
              <span class="font-semibold p-1">
                {publisher ? publisher.books_count : 0}
              </span>
              Slides
            </li>

            <li
              onClick={() => setactActiveTab(5)}
              className={
                activeTab == 5
                  ? "border-t border-gray-700 -mt-px text-gray-700 text-center cursor-pointer"
                  : "text-center cursor-pointer"
              }
            >
              <span class="font-semibold p-1">
                {" "}
                {publisher && publisher.trackers
                  ? publisher.trackers.length
                  : 0}
              </span>
              Trackers
            </li>
            <li
              onClick={() => setactActiveTab(4)}
              className={
                activeTab == 4
                  ? "border-t border-gray-700 -mt-px text-gray-700 text-center cursor-pointer"
                  : "text-center cursor-pointer"
              }
            >
              <span class="font-semibold p-1">
                {" "}
                {publisher && publisher.tracking
                  ? publisher.tracking.length
                  : 0}
              </span>
              Tracking
            </li>
          </ul>

          {/* <!-- user meta form medium screens --> */}
          <div class="hidden md:block">
            {/* <h1 class="font-semibold">
              {publisher.name ? publisher.name : ""}
            </h1> */}

            <p className="font-bold mt-4">Personal Text</p>
            <div className="h-26 overflow-y-auto">
              <p>{profileText}</p>
            </div>
          </div>
        </div>

        {/* <!-- user meta form small screens --> */}
        <div class="md:hidden text-sm my-2">
          <p>{profileText}</p>
        </div>
      </header>
      <main class="bg-gray-100 bg-opacity-25">
        <div class="md:w-10/12 lg:mx-auto mb-4">
          <header class="flex flex-wrap items-center p-4 ">
            {/* <!-- profile meta --> */}

            {/* <!-- user meta form small screens --> */}
          </header>

          {/* <!-- posts --> */}
          <div class="px-px md:px-3">
            {/* <!-- user following for mobile only --> */}
            <ul
              class="flex md:hidden justify-around space-x-8 border-t 
                text-center p-2 text-gray-600 leading-snug text-sm"
            >
              <li>
                <span class="font-semibold text-gray-800 block">
                  {" "}
                  {publisher ? publisher.books_count : 0}
                </span>
                Slides
              </li>

              <li
                onClick={() => setactActiveTab(5)}
                className={
                  activeTab == 5
                    ? "border-t border-gray-700 -mt-px text-gray-700 text-center cursor-pointer"
                    : "text-center cursor-pointer"
                }
              >
                <span class="font-semibold text-gray-800 block">
                  {" "}
                  {publisher && publisher.trackers
                    ? publisher.trackers.length
                    : 0}
                </span>
                Tracker(s)
              </li>
              <li
                onClick={() => setactActiveTab(4)}
                className={
                  activeTab == 4
                    ? "border-t border-gray-700 -mt-px text-gray-700 text-center cursor-pointer"
                    : "text-center cursor-pointer"
                }
              >
                <span class="font-semibold text-gray-800 block">
                  {" "}
                  {publisher && publisher.tracking
                    ? publisher.tracking.length
                    : 0}
                </span>
                Tracking
              </li>
            </ul>

            {/* <!-- insta freatures --> */}
            <div className="flex flex-col md:flex-row md:grid-cols-3 justify-between items-center m-2 gap-2">
              <div className="w-full md:col-span-2 ">
                <TextField
                  title="Search"
                  placeholder={"Search"}
                  handleChange={handleSearchTextChange}
                  value={searchText}
                />
              </div>
              {activeTab < 4 ? (
                <div className="relative flex flex-col md:flex-row justify-around items-center gap-2  w-full md:w-4/12">
                  <div className="flex flex-col justify-between justify-center items-center gap-1 text-xs mt-1 z-99999 w-full">
                    <span>
                      {currentRatingFilterSpecification
                        ? currentRatingFilterSpecification.label
                        : ""}
                    </span>
                    <div className="flex justify-between justify-center w-8/12">
                      {" "}
                      <i
                        className={`fa fa-star cursor-pointer  ${
                          currentRatingFilter >= 1 ? "text-yellow-500" : ""
                        }`}
                        onClick={() => applyRatingFilter(1)}
                      ></i>
                      <i
                        className={`fa fa-star cursor-pointer   ${
                          currentRatingFilter >= 2 ? "text-yellow-500" : ""
                        }`}
                        onClick={() => applyRatingFilter(2)}
                      ></i>
                      <i
                        className={`fa fa-star cursor-pointer   ${
                          currentRatingFilter >= 3 ? "text-yellow-500" : ""
                        }`}
                        onClick={() => applyRatingFilter(3)}
                      ></i>
                      <i
                        className={`fa fa-star cursor-pointer   ${
                          currentRatingFilter >= 4 ? "text-yellow-500" : ""
                        }`}
                        onClick={() => applyRatingFilter(4)}
                      ></i>
                      <i
                        className={`fa fa-star cursor-pointer   ${
                          currentRatingFilter >= 5 ? "text-yellow-500" : ""
                        }`}
                        onClick={() => applyRatingFilter(5)}
                      ></i>
                    </div>
                    <div>
                      {" "}
                      <span>Reset Filter(s)</span>
                      <i
                        class="fas fa-undo border shadow p-1 text-black mx-2 cursor-pointer"
                        aria-hidden="true"
                        onClick={() => resetRating()}
                      ></i>
                    </div>
                    <div
                      className={`w-8/12 absolute ${
                        !showRatingDropdown ? "hidden" : ""
                      }`}
                      style={{ top: "60px" }}
                    >
                      <div className="bg-white rounded-lg shadow p-2">
                        <ul>
                          <li
                            className="cursor-pointer hover:bg-gray-200 text-sm"
                            onClick={() =>
                              applyRatingFilterSpecification({
                                label: " Exact Rating",
                                value: "exact",
                              })
                            }
                          >
                            Exact Rating
                          </li>
                          <li
                            className="cursor-pointer hover:bg-gray-200 text-sm"
                            onClick={() =>
                              applyRatingFilterSpecification({
                                label: "Exact or Less",
                                value: "exact-less",
                              })
                            }
                          >
                            Exact or Less
                          </li>
                          <li
                            className="cursor-pointer hover:bg-gray-200 text-sm"
                            onClick={() =>
                              applyRatingFilterSpecification({
                                label: "Exact or More",
                                value: "exact-more",
                              })
                            }
                          >
                            Exact or More
                          </li>
                        </ul>{" "}
                        <label className="text-xxs text-blue-500">
                          Specify Rating:
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <ul
              class="flex items-center justify-around justify-center   
                    uppercase tracking-widest font-semibold  text-gray-600
                    border-t"
            >
              {/* <!-- posts tab is active --> */}
              <li
                onClick={() => setactActiveTab(0)}
                className={
                  activeTab == 0
                    ? "border-t border-gray-700 text-gray-700 text-center"
                    : "text-center"
                }
              >
                <a class="flex flex-col text-xxs" href="#">
                  <i class="fas fa-window-restore text-xl md:text-xs"></i>
                  <span class="md:inline md:p-1 my-xxsm md:text-xs">
                    All{" "}
                    <span className="hidden md:inline-block">
                      ({publisher?.individual_category_sum?.slides})
                    </span>
                  </span>

                  <span className="md:hidden">
                    ({publisher?.individual_category_sum?.slides})
                  </span>
                </a>
              </li>
              <li
                onClick={() => setactActiveTab(7)}
                className={
                  activeTab == 7
                    ? "border-t border-gray-700 -mt-px text-gray-700 text-center"
                    : "text-center"
                }
              >
                <a class="flex flex-col md:p-3" href="#">
                  <i
                    class="fa fa-file
                             px-1 pt-1 rounded text-xl md:text-xs"
                  ></i>
                  <span class="inline md:p-1 my-xxsm md:text-xs">
                    Products{" "}
                    <span className="hidden md:inline-block">
                      ({publisher?.individual_category_sum?.product})
                    </span>
                  </span>
                  <span className="md:hidden">
                    ({publisher?.individual_category_sum?.product})
                  </span>
                </a>
              </li>
              <li
                onClick={() => setactActiveTab(6)}
                className={
                  activeTab == 6
                    ? "border-t border-gray-700 -mt-px text-gray-700 text-center"
                    : "text-center"
                }
              >
                <a class="flex flex-col md:p-3" href="#">
                  <i
                    class="fa fa-file
                             px-1 pt-1 rounded text-xl md:text-xs"
                  ></i>
                  <span class="inline md:p-1 my-xxsm md:text-xs">
                    Pages{" "}
                    <span className="hidden md:inline-block">
                      ({publisher?.individual_category_sum?.custom})
                    </span>
                  </span>
                  <span className="md:hidden">
                    ({publisher?.individual_category_sum?.custom})
                  </span>
                </a>
              </li>
              <li
                onClick={() => setactActiveTab(1)}
                className={
                  activeTab == 1
                    ? "border-t border-gray-700  text-gray-700 text-center"
                    : "text-center"
                }
              >
                <a class="flex flex-col" href="#">
                  <i class="fas fa-image text-xl md:text-xs"></i>
                  <p class="my-xxsm md:text-xs md:hidden">Photo </p>
                  <p class="my-xxsm md:text-xs md:hidden">Album </p>
                  <span class="md:p-1 my-xxsm md:text-xs hidden md:inline">
                    Photo Albums
                    <span className="hidden md:inline-block">
                      ({publisher?.individual_category_sum?.photo_album})
                    </span>
                  </span>
                </a>
                <span className="md:hidden">
                  ({publisher?.individual_category_sum?.photo_album})
                </span>
              </li>
              <li
                onClick={() => setactActiveTab(2)}
                className={
                  activeTab == 2
                    ? "border-t border-gray-700 text-gray-700 text-center"
                    : "text-center"
                }
              >
                <a class="flex flex-col md:p-1" href="#">
                  <i
                    class="fas fa-book 
                             px-1 pt-1 rounded text-xl md:text-xs"
                  ></i>
                  <span class="inline md:p-1 my-xxsm md:text-xs">
                    Books{" "}
                    <span className="hidden md:inline-block">
                      ({publisher?.individual_category_sum?.book})
                    </span>
                  </span>
                  <span className="md:hidden">
                    ({publisher?.individual_category_sum?.book})
                  </span>
                </a>
              </li>
              <li
                onClick={() => setactActiveTab(3)}
                className={
                  activeTab == 3
                    ? "border-t border-gray-700 -mt-px text-gray-700 text-center"
                    : "text-center"
                }
              >
                <a class="flex flex-col p-1" href="#">
                  <i
                    class="fas fa-meteor
                             px-1 pt-1 rounded text-xl md:text-xs"
                  ></i>
                  <span class="inline p-1 my-xxsm md:text-xs">
                    Comics{" "}
                    <span className="hidden md:inline-block">
                      ({publisher?.individual_category_sum?.comic})
                    </span>
                  </span>
                  <span className="md:hidden">
                    ({publisher?.individual_category_sum?.comic})
                  </span>
                </a>
              </li>

              {/* <li
                onClick={() => setactActiveTab(6)}
                className={
                  activeTab == 6
                    ? "border-t border-gray-700 -mt-px text-gray-700 text-center"
                    : "text-center"
                }
              >
                <a class="flex flex-col p-3" href="#">
                  <i
                    class="fas fa-meteor
                             px-1 pt-1 rounded text-xl md:text-xs"
                  ></i>
                  <span class="inline p-1">Page</span>
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </main>

      {activeTab == 4 ? (
        <ProfileList
          publisherId={publisherId}
          type="tracking"
          searchText={searchText}
        />
      ) : (
        ""
      )}

      {activeTab == 5 ? (
        <ProfileList
          publisherId={publisherId}
          type="trackers"
          searchText={searchText}
        />
      ) : (
        ""
      )}

      {activeTab < 4 || activeTab > 5 ? (
        <div className="w-full">
          <div className="relative flex flex-col md:grid md:grid-cols-4 items-center content-center justify-center w-full gap-4 ">
            {books.map((book, i) => (
              <div className="p-3 shadow m-2 text-center z-50 w-10/12 md:w-full cursor-pointer flex flex-col items-center">
                {/* <img
                  className="w-40 h-40 rounded-full
                       p-1"
                  onClick={() =>
                    book.group == "standard"
                      ? (document.location.href = `/web/reader/${book.uuid}`)
                      : (document.location.href =
                          "/web/rich-page/render/" + book.uuid)
                  }
                  src={
                    BASE_URL + "/storage/" + book.cover_photo.split("public")[1]
                  }
                /> */}

                {book.cover_photo || book.productimages ? (
                  <img
                    className="w-36 h-36 w-36 h-36 md:w-36 md:h-36 object-cover rounded-full"
                    onClick={() => goToContent(book)}
                    src={renderCoverPhotoWithFallbackImage(book)}
                  />
                ) : (
                  <div
                    className="w-full h-28 bg-black text-white flex flex-col justify-center font-bold"
                    onClick={() => goToContent(book)}
                  >
                    <p>No Cover</p>
                  </div>
                )}

                <div className="">
                  <p className="font-bold text-lg text-center">{book.name}</p>
                  {/* <p className="font-bold text-2xl text-center">{book.name}</p> */}

                  {/* <p className="text-gray-600">
                  {book.series && book.series.name}
                </p> */}
                  <p className="my-2 text-xs">
                    <span className=""> Item ID: </span>
                    <span className="text-yellow-600 font-bold">
                      {book.item_id}
                    </span>
                  </p>
                  <p className="text-red-500 text-sm">by</p>
                  <p className="text-black-500">{book?.owneroptimized?.name}</p>

                  <p className="text-black-500 mt-2">
                    Views{" "}
                    {book.pages &&
                      book.pages.length &&
                      `(${book.pages[0].views})`}
                  </p>
                  <p className="text-black-500 mt-2">
                    Bot Count {book.id && `(${book.bot_count})`}
                  </p>
                  <p className="text-black-500 mt-2">
                    BTV Ratio {book.id && `(${book.content_bot_cti})`}
                  </p>
                  <p className="text-black-500 mt-2">
                    Recency Score {book.id && `(${book?.is_recent})`}
                  </p>

                  <p className="text-black-500 mt-2">
                    Rating Score {book.id && `(${book?.avg_count})`}
                  </p>
                  <p className="text-black-500 mt-2">
                    Page Purchase Score{" "}
                    {book.id && `(${book?.product_subscribers_count})`}
                  </p>
                  <p className="text-black-500 mt-2">
                    Page Views Score {book.id && `(${book?.t_views_count})`}
                  </p>
                </div>
                <RatingBox
                  book={book}
                  lastRatedBook={lastRatedBook}
                  rateBook={rateBook}
                  currentRating={currentRating}
                />
                <div className="my-4 mx-1 text-center  rounded-lg p-2">
                  <div className="flex justify-around gap-4">
                    <label className="flex items-center gap-4">
                      <div>
                        {" "}
                        <span className="font-bold">
                          {" "}
                          {published.includes(book.uuid) ? "Hide" : "Unhide"}
                        </span>
                      </div>
                    </label>
                    <div className="flex items-center gap-2">
                      <Switch
                        onChange={(e) => handlePublishStatus(e, book)}
                        checked={published.includes(book.uuid)}
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap  justify-center items-center">
                    {book.tags && renderTags(book)}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center w-full">
            <button
              disabled={!nextPageURL}
              class={`whitespace-nowrap inline-flex items-center justify-center px-8 py-4 mt-8 border border-transparent rounded-md shadow-sm text-base font-medium text-white ${
                !nextPageURL ? "bg-gray-500 hover:bg-gray-500" : "bg-black"
              } hover:bg-black md:mr-8 `}
              onClick={() => loadMore()}
            >
              View More
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </section>
  );
}
