import BasicButton from "../../../../components/buttons/basic-button";
import { BASE_URL } from "../../../../Utils/helpers";
import { useState } from "react";

export default function AdminDesktopSystemSubTable({ data, f }) {
  const { openCancelModal, copyLink, openDeleteModal } = f;
  return (
    <>
      <div className="overflow-x-scroll md:h-screen w-full">
        <table class="table-auto w-full">
          <thead className="text-base">
            <tr className={window.innerWidth < 720 ? "hidden" : ""}>
              {/* <th class="px-4 py-2 text-gray-600">Date Created</th> */}
              <th class="px-4 py-2 text-gray-600">Title</th>
              <th class="px-4 py-2 text-gray-600">Description</th>
              <th class="px-4 py-2 text-gray-600">Email</th>
              <th class="px-4 py-2 text-gray-600">Price</th>
              <th class="px-4 py-2 text-gray-600">Start Date</th>
              <th class="px-4 py-2 text-gray-600">End Date</th>
              <th class="px-4 py-2 text-gray-600">Payment Link</th>
              <th class="px-4 py-2 text-gray-600">Update</th>
              <th class="px-4 py-2 text-gray-600">Cancel</th>
              <th class="px-4 py-2 text-gray-600">Delete</th>
              <th class="px-4 py-2 text-gray-600">Status</th>
            </tr>
          </thead>
          <tbody className="w-full">
            {data.data
              ? data.data.map((p, i) => {
                  return (
                    <tr
                      className={
                        window.innerWidth < 820
                          ? `flex flex-col items-center my-8 w-full bg-gray-50 p-4 `
                          : ""
                      }
                    >
                      {/* <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs">
                          Date Created
                        </h3>
                        {p.created_at}
                      </td> */}
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs">Title</h3>
                        {p.title}
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs">
                          Description
                        </h3>
                        {p.description}
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs">Email</h3>
                        {p.user.email}
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs"></h3>${" "}
                        {p.lateststripeprice.unit_amount}
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs"></h3>
                        {p.stripesubscription?.refactoredStartTime || "N/A"}
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs"></h3>
                        {p.stripesubscription?.refactoredEndTime || "N/A"}
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs"></h3>
                        <BasicButton
                          title={"Copy Link"}
                          classes={"bg-teal-500 text-white"}
                          handleClick={() => copyLink(p.uuid)}
                        />
                      </td>

                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs"></h3>
                        <a href={`/admin/system/subscriber/edit/${p.uuid}`}>
                          <BasicButton
                            title={"Update"}
                            classes={"bg-teal-500 text-white"}
                            handleClick={() => null}
                          />
                        </a>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center text-left">
                        <h3 className="font-bold md:hidden text-xxs"></h3>
                        <BasicButton
                          disabled={p?.stripesubscription?.status !== "active"}
                          title={"Cancel"}
                          classes={"bg-red-500 text-white"}
                          handleClick={() =>
                            openCancelModal(p.stripesubscription)
                          }
                        />
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center text-left">
                        <h3 className="font-bold md:hidden text-xxs"></h3>
                        <BasicButton
                          disabled={
                            p?.stripesubscription?.status == "active" ||
                            p?.stripesubscription?.status == "cancelled"
                          }
                          title={"Delete"}
                          classes={"bg-red-500 text-white"}
                          handleClick={() => openDeleteModal(p)}
                        />
                      </td>

                      <td class="border px-4 py-2  font-medium text-center md:text-center">
                        <h3 className="font-bold md:hidden text-xxs"></h3>
                        {(p?.stripesubscription?.status &&
                          p?.stripesubscription?.status == "processing" &&
                          "N/A") ||
                          p?.stripesubscription?.status ||
                          "N/A"}
                      </td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
      </div>
    </>
  );
}
