import TextField from "../components/inputs/text-input";
import BasicButton from "../components/buttons/basic-button";
import TermsAccordion from "../accordions/terms-accordion";
import { useBookStore } from "../stores/book-store";
import { forwardRef, useState, useEffect, useRef } from "react";
import {
  isAuthUser,
  authUserData,
  API_BASE,
  ValidateEmail,
} from "../Utils/helpers";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import Countries from "../components/country/country";
import axios from "axios";

export default function PlatformUpdateModal({
  modalOpen,
  hideModal,
  type,
  showSignupSuccess,
  book,
}) {
  const { pageAdvertId } = useBookStore((state) => state);
  const [termsConsent, setTermsConsent] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isHuman, setIsHuman] = useState(true);
  const [country, setCountry] = useState("");
  const captchaRef = useRef(null);
  const [emailValidationMessage, setEmailValidationMessage] = useState("");

  //6LcnAzwlAAAAAGFWzNvLsj3t23gGj5OJrgqMCqw9

  const handleCountryChange = (e) => {
    let objectTarget = JSON.parse(e.target.value);
    setCountry(objectTarget.country);
  };

  const validateRecaptcha = () => {
    const token = captchaRef.current.getValue();
    const url = API_BASE + "/api/user/recaptcha/verify";
    axios
      .post(url, { token: token })
      .then((res) => {
        setIsHuman(true);
        // captchaRef.current.reset();
      })
      .catch((error) => {
        console.log(error);
        captchaRef.current.reset();
      });
  };

  const subscribe = () => {
    // console.log("owner", book.owner);
    if (isAuthUser() && book.owner && book.owner.id == authUserData().id) {
      toast("Preview Only", { type: "notice" });
      return false;
    }

    const url = API_BASE + "/api/subscription/create";
    axios
      .post(url, {
        email: email,
        first_name: firstName,
        last_name: lastName,
        user_id: book.owner.id,
        subscriber_id: isAuthUser() ? authUserData().id : null,
        channel: type,
        book_id: book ? book.id : null,
        country: country,
      })
      .then((response) => {
        if (response.status == 200) {
          toast("Success! We'll keep you informed on latest updates", {
            type: "success",
          });
          hideModal();
          setEmail("");
          showSignupSuccess && showSignupSuccess();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
        console.error("There was an error!", error);
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e);
  };

  const handleLastNameChange = (e) => {
    setLastName(e);
  };

  useEffect(() => {
    if (!ValidateEmail(email)) {
      setEmailValidationMessage("Invalid email address");
      console.log("Invalid");
    } else {
      console.log("Valid");
      setEmailValidationMessage("");
    }
  }, [email]);

  return (
    <div
      className={`fixed z-50 overflow-y-auto top-0 w-full left-0 h-full   ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-6/12"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="w-full left-0 right-0">
            {/* modal body */}
            <div className="p-2">
              <h1 className="text-2xl font-bold text-center">
                {/* {getActiveWidgetTitle(currentEditingWidget, widgets)} */}
                Sign Up for Updates
              </h1>
            </div>

            <div className="p-2 flex justify-center flex-col items-center">
              <TextField
                placeholder={"Email..."}
                classes="w-8/12"
                handleChange={handleEmailChange}
                value={email}
              />
              <p className="text-red-500">{email && emailValidationMessage}</p>
            </div>

            <div className="p-2 flex justify-center">
              <TextField
                placeholder={"First Name..."}
                classes="w-8/12"
                handleChange={handleFirstNameChange}
                value={firstName}
              />
            </div>

            <div className="p-2 flex justify-center">
              <TextField
                placeholder={"Last Name..."}
                classes="w-8/12"
                handleChange={handleLastNameChange}
                value={lastName}
              />
            </div>
            {!isAuthUser() ? (
              <div class="p-2 flex flex-col items-center justify-center w-full">
                <div className="flex justify-start text-left w-8/12">
                  <p className="text-left w-full"> Country</p>
                </div>
                <Countries handleCountryChange={handleCountryChange} />
              </div>
            ) : (
              ""
            )}

            <div className="flex justify-center">
              <p className="w-8/12"> Sign up to receive the latest updates:</p>
            </div>

            <div className="flex justify-center my-2">
              <div className="w-8/12">
                <TermsAccordion />

                <div class="flex items-center mb-4">
                  <input
                    checked={termsConsent}
                    onChange={(e) => {
                      setTermsConsent((prev) => !prev);
                    }}
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    class="w-16 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="default-checkbox"
                    class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 my-2"
                  >
                    I have read the Terms and Conditions, and I accept updates.
                  </label>
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <div className="w-8/12  grid md:grid-cols-2 gap-4">
                {isAuthUser() ? (
                  <BasicButton
                    disabled={
                      !email || !firstName || !lastName || !termsConsent
                    }
                    title={"Agree & Sign Up"}
                    classes="px-8"
                    handleClick={() => subscribe()}
                  />
                ) : (
                  <BasicButton
                    disabled={
                      !email ||
                      !firstName ||
                      !lastName ||
                      !termsConsent ||
                      !isHuman ||
                      !country
                    }
                    title={"Agree & Sign Up"}
                    classes="px-8"
                    handleClick={() => subscribe()}
                  />
                )}

                <BasicButton
                  title={"Cancel"}
                  classes="px-8"
                  handleClick={() => hideModal()}
                />
              </div>
            </div>

            {/* modal body */}
          </div>
          {!isAuthUser() ? (
            <div className="flex justify-center w-full my-4">
              <ReCAPTCHA
                sitekey="6Lfk5kckAAAAAJrzP9vN1itokN_LIC9Cvx3nmWAW"
                ref={captchaRef}
                onChange={validateRecaptcha}
              />
            </div>
          ) : (
            ""
          )}

          <div class="bg-gray-200 px-4 py-3 flex justify-center">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
            {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
