import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";

import { isAdmin, API_BASE } from "../../../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
import Switch from "react-switch";
import PasswordPromptModal from "../../../modals/password-prompt-modal";
import CreateTierModal from "../../../modals/create-tier-modal";
import TextArea from "../../../components/inputs/text-area";
export default function AdminCreatePaidAdSlot() {
  const { id } = useParams();
  const [genAds, setGenAds] = useState({});
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [link, setLink] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("mm");
  const [fileSelected, setFileSelected] = useState("");
  const [processing, setProcessing] = useState(false);
  const previewImageRef = useRef();
  const naviagte = useNavigate();

  const handleTitleChange = (d) => {
    setTitle(d);
  };

  const handleBodyChange = (d) => {
    setBody(d);
  };

  const handleLinkChange = (d) => {
    setLink(d);
  };

  const uploadCover = (e) => {
    let [file] = e.target.files;
    if (
      file["type"] !== "image/png" &&
      file["type"] !== "image/jpeg" &&
      file["type"] !== "image/gif"
    ) {
      toast("Invalid file type, please upload (JPEG, PNG or GIF)", {
        type: "error",
      });
      return false;
    }
    if (file) {
      setFileSelected(URL.createObjectURL(file));
      previewImageRef.src = URL.createObjectURL(file);
      setCoverPhoto(file);
      console.log(file);
      //   previewImageRef.classList.remove("hidden");
      const image = getBase64(file);
      image.then((res) => {
        // console.log("My image", res);
      });
    }
  };

  const removeCover = () => {
    setFileSelected("");
    setCoverPhoto("");
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const createAdvert = () => {
    const url = API_BASE + "/api/paid-advert/slot/create";
    setProcessing(true);
    let formData = new FormData();
    formData.append("title", title);
    formData.append("body", body);
    formData.append("cluster_id", id);
    formData.append("link", link);

    formData.append("cover_photo", coverPhoto);

    axios
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          toast("Created!", { type: "success" });
          setTimeout(() => {
            setProcessing(false);
            naviagte(`/admin/paid-adverts/set/${id}/slots`);
          }, 2000);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
        setProcessing(false);
      });
  };
  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
  }, []);
  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">Set {id} </h1>
        </div>
        <div className="flex gap-2">
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center gap-4 mt-8 items-center mb-4">
        {/* <h1 className="font-bold text-xl">Storage Tiers</h1> */}
        <div className="flex flex-col justify-center items-center gap-4 mt-8 w-full">
          <div className="w-10/12 md:w-5/12 bg-gray-50 p-4">
            <div className="m-2 mb-6">
              <label className="text-black">Title</label>
              <TextField
                classes={"p-6 my-2"}
                placeholder={"Title.."}
                handleChange={handleTitleChange}
              />
              {title.length > 35 ? (
                <p className="text-red-500">
                  Max character length of 35 exceeded.
                </p>
              ) : (
                ""
              )}
            </div>

            <div className="m-2 mb-8">
              <label className="text-black">Body</label>
              <TextArea
                classes={"p-6 my-2"}
                placeholder={"Body.."}
                handleChange={handleBodyChange}
              />
              {body.length > 90 ? (
                <p className="text-red-500">
                  Max character length of 90 exceeded.
                </p>
              ) : (
                ""
              )}
            </div>

            <div className="m-2 mb-6">
              <label className="text-black">Link</label>
              <TextField
                classes={"p-6 my-2"}
                placeholder={"Link.."}
                handleChange={handleLinkChange}
              />
            </div>

            <hr />
            <div className="m-2 text-center">
              <label className="text-black m-2">Photo</label>
              <div className="flex justify-center my-2">
                {!fileSelected && (
                  <div className="w-32 bg-gray-100 p-2">
                    <div className="flex flex-col justify-center  h-full">
                      <i class="fa fa-plus cursor-pointer text-green-500"></i>
                      <p className="text-xs">Click to upload</p>
                      <p className="text-xs"> (jpeg, jpg, png)</p>
                      <label className="my-2">
                        <input
                          id="fileName"
                          type="file"
                          onChange={(e) => uploadCover(e)}
                          class="text-sm 
                        text-grey-500
                        file:mr-2
                        file:py-2
                        file:px-2
                        file:rounded-full
                        file:border-0
                        file:text-md
                        file:font-semibold
                        file:text-white
                        file:bg-gradient-to-r
                        file:from-blue-600
                        file:to-amber-600
                        hover:file:cursor-pointer
                        hover:file:opacity-80"
                        />
                      </label>
                      {/* <input type="file" onChange={(e) => uploadCover(e)} /> */}
                    </div>
                  </div>
                )}

                {fileSelected && (
                  <div className="w-32  bg-gray-100 p-2 relative">
                    <img src={fileSelected} className="w-full" />
                    <div
                      className="bg-white rounded rounded-full bg-white p-0 top-0 right-0 absolute px-1 cursor-pointer"
                      onClick={() => removeCover()}
                    >
                      <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="text-center">
              <BasicButton
                disabled={
                  !title ||
                  !body ||
                  !link ||
                  !fileSelected ||
                  processing ||
                  title.length > 35 ||
                  body.length > 90
                }
                title={processing ? "Creating.." : "Create"}
                classes={"p-6 w-9/12 mt-4"}
                handleClick={createAdvert}
              />
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
}
