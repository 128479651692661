import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import WebHeader from "../header";
import { useParams } from "react-router-dom";
import Footer from "../../../layout/footer";
import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import {
  API_BASE,
  getLocation,
  getClientBaseURL,
} from "../../../Utils/helpers";
import axios from "axios";

export default function PasswordResetEmailValidation() {
  const [email, setEmail] = useState("");
  const [emailLinkSent, setEmailLinkSent] = useState(false);
  const [processing, setProcessing] = useState(false);

  const generatePasswordResetLink = (userId) => {
    return getLocation().hostname == "litzapp.com"
      ? getClientBaseURL().replace(":3000", "") +
          `/password-reset/usr/${userId}/tok/`
      : getClientBaseURL() + `:3000/password-reset/usr/${userId}/tok/`;
  };

  const sendPasswordResetEmail = (userId) => {
    const url = API_BASE + "/api/user/update/send-password-reset-eamil";
    setProcessing(true);
    let formData = new FormData();
    formData.append("email", email);
    formData.append("user_id", userId);
    formData.append("link", generatePasswordResetLink(userId));
    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        toast("Please check your email for your password reset link!", {
          type: "success",
        });
        setEmailLinkSent(true);
        setEmail("");
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const validateEmail = () => {
    const url = API_BASE + "/api/user/update/validate-password-reset-email";
    setProcessing(true);
    let formData = new FormData();
    formData.append("email", email);
    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        sendPasswordResetEmail(response.data.user.uuid);
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.status == 422) {
          toast("Invalid Email Address!", {
            type: "error",
          });
        }
        // toast("Something went wrong!", { type: "error" });
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e);
  };

  useEffect(() => {}, []);

  return (
    <>
      <div class=" px-6 ">
        <br />
        <div class="flex flex-col text-center md:text-left justify-evenly items-center">
          <div class="flex flex-col w-full items-center">
            {!emailLinkSent ? (
              <div>
                <h1 class="text-2xl text-gray-800 font-bold my-2">
                  Password Reset
                </h1>
                <div class="flex flex-col w-full my-1">
                  <label class="text-gray-500 mb-2">Email</label>
                  <TextField
                    placeholder={"Email"}
                    handleChange={handleEmailChange}
                  />
                </div>

                <div className="text-center w-full">
                  <br />
                  <BasicButton
                    disabled={!email}
                    title={processing ? "Validating.." : "Validate Email"}
                    classes={"p-6 w-9/12 mt-4"}
                    handleClick={validateEmail}
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center shadow p-4 my-8">
                <i className="fa fa-envelope text-8xl my-text-yellow-500"></i>
                <p>Please check your email for your password reset link!.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
