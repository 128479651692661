import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import StripeRedirectModal from "../../../modals/stripe-redirect-modal";
import StripeRedirectModeModal from "../../../modals/stripe-redirect-mode-modal";
import DeletePromptModal from "../../../modals/delete-warning-modal";
import Pagination from "../../../components/pagination";
import AdminAdInsertModal from "../../../modals/admin-ad-insert-modal";
import useDebounce from "../../../components/debounce/debounce-helper";

import {
  isAdmin,
  API_BASE,
  isPublisher,
  getLocation,
  authUserData,
  isAuthUser,
  getClientBaseURL,
} from "../../../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
import Switch from "react-switch";
import PasswordPromptModal from "../../../modals/password-prompt-modal";
import CreateTierModal from "../../../modals/create-tier-modal";
export default function AdminGeneralAdCodes() {
  const [genAds, setGenAds] = useState({});
  const getGenAdverts = () => {
    let url = API_BASE + "/api/system/settings";
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setGenAds(response.data);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };
  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
    getGenAdverts();
  }, []);
  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">
            General Ad Codes
          </h1>
        </div>
        <div className="flex gap-2">
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center gap-4 mt-8 items-center mb-4">
        {/* <h1 className="font-bold text-xl">Storage Tiers</h1> */}
        <div className="w-full p-8 flex flex-col">
          <div className="hidden md:flex gap-2 justify-around w-full border-b p-4 text-sm font-bold">
            <div className="text-center w-full md:w-1/12">Adsense</div>
            <div className="text-center w-full md:w-1/12">Adsterra</div>
          </div>
          <div className="gap-2 flex flex-col  md:flex md:flex-row justify-around w-full border-b p-4 bg-gray-50 mb-1">
            {" "}
            <div className="text-center w-full md:w-1/12">
              <p className="md:hidden font-bold">Adsense</p>
              <p className="font-bold text-sm">CTI Ratio</p>
              {genAds?.click_impressions_ratio
                ? genAds?.click_impressions_ratio
                : "N/A"}
              <p className="font-bold text-sm">Clicks</p>
              {genAds?.clicks ? genAds?.clicks : "N/A"}
              <p className="font-bold text-sm">Impressions</p>
              {genAds?.impressions ? genAds?.impressions : "N/A"}
              <p className="font-bold text-sm">Total Estimated Earnings</p>
              {genAds?.total_estimated_earnings
                ? genAds?.total_estimated_earnings
                : "N/A"}
              <br />
            </div>
            <div className="text-center w-full md:w-1/12">
              <p className="md:hidden font-bold">Adsterra</p>
              <p className="font-bold text-sm">CTI Ratio</p>
              {genAds?.click_impressions_ratio_2
                ? genAds?.click_impressions_ratio_2
                : "N/A"}
              <p className="font-bold text-sm">Clicks</p>
              {genAds?.clicks_2 ? genAds?.clicks_2 : "N/A"}
              <p className="font-bold text-sm">Impressions</p>
              {genAds?.impressions_2 ? genAds?.impressions_2 : "N/A"}
              <p className="font-bold text-sm">Total Estimated Earnings</p>
              {genAds?.total_estimated_earnings_2
                ? genAds?.total_estimated_earnings_2
                : "N/A"}
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
