import {
  isAuthUser,
  authUserData,
  isAdmin,
  isPublisher,
  isReader,
  isResponsiveMode,
} from "../../../../Utils/helpers";
import MLogoutBar from "./logout-bar";
import MAdminMenuItems from "./menu/admin-bar";
import MPublisherMenuItems from "./menu/publisher-bar";
import MReaderMenuItems from "./menu/reader-bar";
import MSharedMenuItems from "./menu/shared";
import MUnauthorizedMenuItems from "./menu/unauthorized-bar";
import MSponsorMenuItems from "./menu/sponsor-bar";
import MSalesMenuItems from "./menu/sales-bar";
export default function MobileMenuBar(props) {
  const { handleLogout, showMobileMenu } = props;
  return showMobileMenu ? (
    <div className="w-full flex justify-center bg-gray-10">
      <nav className="flex flex-col gap-3 items-center text center">
        {isResponsiveMode() ? (
          <a
            href="/"
            class="text-base font-medium text-gray-500 hover:text-gray-900 "
          >
            Home
          </a>
        ) : (
          ""
        )}
        <MReaderMenuItems />
        <MAdminMenuItems />
        <MSalesMenuItems />
        <MSponsorMenuItems />
        <MPublisherMenuItems />
        <MUnauthorizedMenuItems />
        <MSharedMenuItems />
        <MLogoutBar handleLogout={handleLogout} />
      </nav>
    </div>
  ) : (
    ""
  );
}
