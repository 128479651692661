import BookTemplate from "./book-template";
import { globalAds } from "../../../../Utils/helpers";
import AdsenseBar from "./adsense-bar";

export default function ContentComposer(props) {
  const { d } = props;
  const { renderBookByGroupType } = props;
  const { rateBook } = props;
  const { currentRating } = props;
  const { isTrackingPublisher } = props;
  const { lastRatedBook } = props;
  const { untrackPublisher } = props;
  const { trackPublisher } = props;
  const { openAuthPromtModal } = props;
  const { renderTags } = props;

  const randomPick = Math.floor(Math.random() * 16);
  const imageUrl = globalAds[randomPick].image;
  const imageLink = globalAds[randomPick].link;

  const compose = () => {
    if (d.type == "advert") {
      return <AdsenseBar />;

      //   return (
      //     <div className="flex justify-center">
      //       <img className="cursor-pointer" src={imageUrl} />
      //     </div>
      //   );
    } else if (d.type == "book") {
      return (
        <>
          <BookTemplate
            book={d.content}
            renderBookByGroupType={renderBookByGroupType}
            rateBook={rateBook}
            currentRating={currentRating}
            isTrackingPublisher={isTrackingPublisher}
            lastRatedBook={lastRatedBook}
            untrackPublisher={untrackPublisher}
            trackPublisher={trackPublisher}
            openAuthPromtModal={openAuthPromtModal}
            renderTags={renderTags}
          />
        </>
      );
    } else {
      return "";
    }
  };

  return <>{compose()} </>;
}
