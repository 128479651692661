import { useWebProfileStore } from "../../../../../stores/webProfileStore";
import BasicButton from "../../../../buttons/basic-button";
import { BASE_URL } from "../../../../../Utils/helpers";
import Switch from "react-switch";
import AdSense from "react-adsense";
import RatingBox from "../../../../rating";
import Adsterra from "../../../../advert/adsterra";
import { useState } from "react";
export default function ProfileSectionThree(props) {
  const webProfileStore = useWebProfileStore((state) => state);
  const { handlePublishStatus } = props;
  const { showCurrentLocation } = props;
  const { openProductCalendar } = props;
  const { renderTags } = props;
  const { processing } = props;
  const { currentRating } = props;
  const { lastRatedBook } = props;
  const { openListsModal } = props;
  const { goToContent } = props;
  const { published } = props;
  const { activeTab } = props;
  const { rateBook } = props;
  const { canShare } = props;
  const { loadMore } = props;
  const { isListItemsMode } = props;
  const { goBackToList } = props;

  const renderDisplayImage = (data) => {
    if (data?.content?.type == "list") {
      return (
        BASE_URL +
        "/storage" +
        data?.content?.book_list_thumb?.split("public")[1]
      );
    }
    if (data?.content?.cover_photo_thumb) {
      return (
        BASE_URL +
        "/storage" +
        data?.content?.cover_photo_thumb?.split("public")[1]
      );
    }

    if (data.content?.productimages?.length) {
      return (
        BASE_URL +
        "/storage" +
        data?.content?.productimages[0]?.product_thumb?.split("public")[1]
      );
    }

    if (data.content?.owner_optimized_data?.profile_photo) {
      return (
        BASE_URL +
        "/storage/" +
        data?.content?.owner_optimized_data?.profile_photo?.split("public")[1]
      );
    } else {
      return (
        BASE_URL +
        "/storage/" +
        data?.content?.owner_optimized_data?.backup_profile_photo?.split(
          "public"
        )[1]
      );
    }
  };

  const bookRebderProps = {
    renderDisplayImage,
    handlePublishStatus,
    showCurrentLocation,
    openProductCalendar,
    renderTags,
    processing,
    currentRating,
    lastRatedBook,
    openListsModal,
    goToContent,
    published,
    activeTab,
    rateBook,
    canShare,
    loadMore,
  };

  return (
    <div className="w-full p-2 flex flex-col items-center">
      {isListItemsMode ? (
        <div className="w-full  flex justify-end mb-4 p-2">
          <BasicButton
            title={"Go Back"}
            classes={"my-bg-yellow-500 text-black"}
            handleClick={goBackToList}
          />
        </div>
      ) : (
        ""
      )}

      <div className="relative flex flex-col items-center md:grid md:grid-cols-4 md:items-start content-center justify-center w-full gap-4 ">
        {webProfileStore.booksWithAds.length
          ? webProfileStore.booksWithAds.map((data, i) =>
              data.type == "advert" ? (
                <div className="p-3 shadow m-2 text-center z-50 md:w-full cursor-pointer flex flex-row items-center justify-center relative">
                  {data.provider == "ADSTERRA" ? (
                    <Adsterra slot={data.content} />
                  ) : (
                    <AdSense.Google
                      client="ca-pub-8191791319921859"
                      slot={data.content}
                      style={{ width: 250, height: 250, float: "left" }}
                      layout="in-article"
                      format=""
                    />
                  )}
                </div>
              ) : !processing ? (
                <>
                  {data.content.type !== "list" ? (
                    <RenderBasicBook data={data} {...bookRebderProps} />
                  ) : (
                    <RenderListBook data={data} {...bookRebderProps} />
                  )}
                </>
              ) : (
                <></>
              )
            )
          : ""}
      </div>
      <div className="flex justify-center w-full">
        <button
          disabled={!webProfileStore.nextPageURL}
          class={`whitespace-nowrap inline-flex items-center justify-center px-8 py-4 mt-8 border border-transparent rounded-md shadow-sm text-base font-medium text-white ${
            !webProfileStore.nextPageURL || processing
              ? "bg-gray-500 hover:bg-gray-500"
              : "bg-black"
          } hover:bg-black md:mr-8 `}
          onClick={() => !processing && loadMore()}
        >
          {processing ? "Loading..." : "View More"}
        </button>
      </div>
    </div>
  );
}

const RenderBasicBook = (props) => {
  const { data } = props;
  const { renderDisplayImage } = props;
  const { renderTags } = props;
  const { currentRating } = props;
  const { lastRatedBook } = props;
  const { goToContent } = props;
  const { rateBook } = props;

  return (
    <div className="p-3 shadow m-2 text-center z-50 w-10/12 md:w-full cursor-pointer flex flex-col items-center">
      <img
        className="w-36 h-36 w-36 h-36 md:w-36 md:h-36 object-cover rounded-full bg-gray-300 
                       p-1"
        onClick={() => goToContent(data.content)}
        src={renderDisplayImage(data)}
      />

      <div className="">
        <p className="font-bold text-lg text-center">{data.content.name}</p>

        <p className="my-2 text-xs">
          <span className="text-xs"> Item ID: </span>
          <span className="text-yellow-600 text-xs font-bold">
            {data.content.item_id}
          </span>
        </p>
        <p className="text-red-500 text-sm">by</p>
        <p className="text-black-500">{data.content?.owneroptimized?.name}</p>

        <p className="text-black-500 mt-2">
          Views{" "}
          {data.content.pages &&
            data.content.pages.length &&
            `(${data.content.pages[0].views})`}
        </p>
      </div>
      <RatingBox
        book={data.content}
        lastRatedBook={lastRatedBook}
        rateBook={rateBook}
        currentRating={currentRating}
      />
      <div className="flex flex-wrap  justify-center items-center">
        {data.content.tags && renderTags(data.content)}
      </div>
    </div>
  );
};
const RenderListBook = (props) => {
  const { data } = props;
  const { renderDisplayImage } = props;
  const { goToContent } = props;

  return (
    <div className="p-3 shadow m-2 text-center z-50 w-10/12 md:w-full cursor-pointer flex flex-col items-center">
      <img
        className="w-36 h-36 w-36 h-36 md:w-36 md:h-36 object-cover rounded-full bg-gray-300 
                       p-1"
        onClick={() => goToContent(data.content)}
        src={renderDisplayImage(data)}
      />

      <div className="">
        <p className="font-bold text-lg text-center">{data.content.name}</p>

        <p className="my-2 text-xs">
          <span className="text-xs"> Item ID: </span>
          <span className="text-yellow-600 text-xs font-bold">
            {data.content.item_id}
          </span>
        </p>
        <p className="text-red-500 text-sm">by</p>
        <p className="text-black-500">{data.content?.owneroptimized?.name}</p>

        <p className="text-black-500 mt-2">
          Views{" "}
          {data.content.pages &&
            data.content.pages.length &&
            `(${data.content.pages[0].views})`}
        </p>
      </div>
    </div>
  );
};
