export default function TableButton(props) {
  return (
    <button
      className="border border-gray rounded-lg px-2 py-1 m-1"
      onClick={() => {
        props.showTableModalCreateBox();
      }}
    >
      <span className="px-1">Add Table</span>
    </button>
  );
}
