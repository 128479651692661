import { isAuthUser, authUserData, API_BASE } from "../../../Utils/helpers";
import BasicButton from "../../../components/buttons/basic-button";
import StripeCustomPageForm from "../../../payment/custom-page-pay-button";
import openRequestService from "../../../services/HttpRequests/unauthenticated-request-services";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import axios from "axios";
export default function RenderSPProductMetaBox({ payload, page, state }) {
  const [quantity, setQuantity] = useState(1);
  const [productMeta, setProductMeta] = useState({});
  const [meta, setMeta] = useState({});
  const [processing, setProcessing] = useState(false);

  const data = JSON.parse(payload);
  const fetchMeta = () => {
    const url =
      API_BASE + `/api/book/custom/product/meta/${data.block_meta_id}`;
    axios
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setProductMeta(response.data.meta);
          setMeta(JSON.parse(response.data.meta.payload));
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  useEffect(() => {
    fetchMeta();
  }, []);

  const increaseQuantity = () => {
    setQuantity((prev) => prev + 1);
  };
  const decreaseQuantity = () => {
    setQuantity((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const getHireWithSystemFees = (hirePrice) => {
    if (page.owner) {
      if (page.owner.system_setting.constant_fee_on_payments) {
        if (meta.quantity_sel_enabled) {
          return (
            Number(quantity) * Number(hirePrice) +
            Number(page.owner.system_setting.constant_fee_on_payments)
          );
        } else {
          return (
            Number(hirePrice) +
            Number(page.owner.system_setting.constant_fee_on_payments)
          );
        }
      }
      return meta.quantity_sel_enabled ? hirePrice * hirePrice : hirePrice;
    }

    return meta.quantity_sel_enabled ? hirePrice * hirePrice : hirePrice;
  };

  // alert(meta?.contractfile);
  let metaInfo = {};
  metaInfo.price = getHireWithSystemFees(meta.price);
  metaInfo.base_price = getHireWithSystemFees(meta.price);
  metaInfo.name = meta.p_type_sub_opt;
  metaInfo.contract = productMeta?.contractfile;
  metaInfo.systembuyercontractfile = productMeta?.systembuyercontractfile;
  metaInfo.description = "Product Purchase";
  metaInfo.model_type = "PRODUCT_SERVICE";
  metaInfo.page_id = page.uuid;
  metaInfo.quantity = meta.quantity_sel_enabled ? quantity : 1;
  metaInfo.meta = meta;
  metaInfo.block_meta_id = meta.block_meta_id;

  const computeMetaQuauntity = () => {
    if (!page.productmetas) return "";
    if (meta.quantity_enabled && !meta.quantity_merge) {
      return meta.quantity + " available today";
    } else if (meta.quantity_merge) {
      return page.productmetas.length
        ? page.productmetas.filter(
            (d) => d.block_meta_id == meta.quantity_merge
          )[0].quantity + " available today"
        : "";
    }

    return "";
  };

  const computeMetaQuauntityValue = () => {
    if (!page.productmetas) return "";
    if (meta.quantity_enabled && !meta.quantity_merge) {
      return meta.quantity ? meta.quantity : "";
    } else if (meta.quantity_merge) {
      return page.productmetas.length
        ? page.productmetas.filter(
            (d) => d.block_meta_id == meta.quantity_merge
          )[0].quantity
        : "";
    }

    return "";
  };

  const hasLocationFufilment = () => {
    return meta.location_fulfilment.filter((d) => d.location !== "").length;
  };

  const notProductOwner = () => {
    return isAuthUser() && page.owner && page.owner.id !== authUserData().id;
  };

  const isProductOwner = () => {
    return isAuthUser() && page.owner && page.owner.id == authUserData().id;
  };

  return (
    meta.purchase_type && (
      <>
        {isProductOwner() && !meta.hide_button ? (
          <div className="w-full text-center flex flex-col items-center">
            <div className="flex text-center flex justify-center gap-2">
              <div className="w-full text-center my-2">
                <BasicButton
                  disabled={meta.hide_button || meta.make_inactive}
                  title={
                    meta.p_type_sub_opt +
                    "  " +
                    "$" +
                    getHireWithSystemFees(meta.price)
                  }
                  classes="my-bg-yellow-500 hover:bg-yellow-700 text-black text-white shadow"
                  handleClick={() => toast("Preview Only", { type: "notice" })}
                />
                <div className="">
                  {meta.quantity_sel_enabled &&
                  computeMetaQuauntityValue() !== "" &&
                  computeMetaQuauntityValue() !== 0 ? (
                    <div class="custom-number-input h-10 w-32">
                      <div class="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                        <button
                          onClick={() => quantity > 0 && decreaseQuantity()}
                          className="border text-gray-600 hover:text-gray-700 hover:bg-gray-100 h-full w-20 rounded-l cursor-pointer outline-none"
                        >
                          <span class="m-auto text-2xl font-thin">−</span>
                        </button>
                        <input
                          type="number"
                          className="outline-none focus:outline-none text-center w-full bg-gray-50 border font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                          name="custom-input-number"
                          value={quantity}
                          readOnly
                        ></input>
                        <button
                          onClick={() => {
                            if (quantity < computeMetaQuauntityValue())
                              increaseQuantity();
                          }}
                          className="border text-gray-600 hover:text-gray-700 hover:bg-gray-100 h-full w-20 rounded-r cursor-pointer"
                        >
                          <span class="m-auto text-2xl font-thin">+</span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <p>{computeMetaQuauntity()}</p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {notProductOwner() && !meta.hide_button ? (
          <div className="w-full text-center flex flex-col items-center">
            <div className="flex text-center flex justify-center gap-2">
              <div>
                {" "}
                {/* meta.purchaser_reply_enabled || hasLocationFufilment(meta)  */}
                {true ? (
                  <BasicButton
                    disabled={
                      meta.hide_button ||
                      meta.make_inactive ||
                      ((computeMetaQuauntityValue() == "" ||
                        computeMetaQuauntityValue() == 0) &&
                        meta.quantity_enabled)
                    }
                    title={
                      meta.p_type_sub_opt +
                      " " +
                      "$" +
                      getHireWithSystemFees(meta.price)
                    }
                    classes="my-bg-yellow-500 hover:bg-yellow-700 text-black text-white shadow my-2"
                    handleClick={() => {
                      state.setSelectPurchaseType("single");
                      state.setProductMetaModalOpen(true);
                      state.setProductMetaInfo(metaInfo);
                      if (meta.purchaser_reply_enabled) {
                        state.setProductMetaMode(true);
                      }
                    }}
                  />
                ) : (
                  <StripeCustomPageForm
                    data={metaInfo}
                    computeMetaQuauntityValue={computeMetaQuauntityValue}
                    classes={
                      "my-bg-yellow-500 hover:bg-yellow-700 text-black text-white shadow my-2"
                    }
                  />
                )}
              </div>
              <div className="">
                {meta.quantity_sel_enabled &&
                computeMetaQuauntityValue() !== "" &&
                computeMetaQuauntityValue() !== 0 ? (
                  <div class="custom-number-input h-10 w-32">
                    <div class="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                      <button
                        onClick={() => quantity > 0 && decreaseQuantity()}
                        className="border text-gray-600 hover:text-gray-700 hover:bg-gray-100 h-full w-20 rounded-l cursor-pointer outline-none"
                      >
                        <span class="m-auto text-2xl font-thin">−</span>
                      </button>
                      <input
                        type="number"
                        className="outline-none focus:outline-none text-center w-full bg-gray-50 border font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                        name="custom-input-number"
                        value={quantity}
                        readOnly
                      ></input>
                      <button
                        onClick={() => {
                          if (quantity < computeMetaQuauntityValue())
                            increaseQuantity();
                        }}
                        className="border text-gray-600 hover:text-gray-700 hover:bg-gray-100 h-full w-20 rounded-r cursor-pointer"
                      >
                        <span class="m-auto text-2xl font-thin">+</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <p>{computeMetaQuauntity()}</p>
          </div>
        ) : (
          ""
        )}

        {!isAuthUser() && !meta.hide_button ? (
          <div className="w-full text-center flex flex-col items-center my-4">
            <div className="flex text-center flex justify-center gap-2">
              <BasicButton
                disabled={
                  meta.hide_button ||
                  meta.make_inactive ||
                  ((computeMetaQuauntityValue() == "" ||
                    computeMetaQuauntityValue() == 0) &&
                    meta.quantity_enabled)
                }
                title={
                  meta.p_type_sub_opt + " $" + getHireWithSystemFees(meta.price)
                }
                classes="my-bg-yellow-500 hover:bg-yellow-700 text-black text-white shadow"
                handleClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  {
                    /* meta.purchaser_reply_enabled; */
                  }
                  if (true) {
                    {
                      /* state.setSelectPurchaseType("single");
                    state.setProductMetaModalOpen(true);
                    metaInfo.computeMetaQuauntityValue =
                      computeMetaQuauntityValue;
                    state.setProductMetaInfo(metaInfo);
                    state.setProductMetaMode(true); */
                    }
                    metaInfo.computeMetaQuauntityValue =
                      computeMetaQuauntityValue;
                    state.setProductMetaInfo(metaInfo);
                    state.setRegistration4ModalOpen(true);
                  } else {
                    {
                      /* metaInfo.computeMetaQuauntityValue =
                      computeMetaQuauntityValue;
                    state.setProductMetaInfo(metaInfo);
                    state.setRegistration4ModalOpen(true); */
                    }
                  }
                }}
              />
              <div className="">
                {meta.quantity_sel_enabled &&
                computeMetaQuauntityValue() !== "" &&
                computeMetaQuauntityValue() !== 0 ? (
                  <div class="custom-number-input h-10 w-32">
                    <div class="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                      <button
                        onClick={() => quantity > 0 && decreaseQuantity()}
                        className="border text-gray-600 hover:text-gray-700 hover:bg-gray-100 h-full w-20 rounded-l cursor-pointer outline-none"
                      >
                        <span class="m-auto text-2xl font-thin">−</span>
                      </button>
                      <input
                        type="number"
                        className="outline-none focus:outline-none text-center w-full bg-gray-50 border font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                        name="custom-input-number"
                        value={quantity}
                        readOnly
                      ></input>
                      <button
                        onClick={() => {
                          if (quantity < computeMetaQuauntityValue())
                            increaseQuantity();
                        }}
                        className="border text-gray-600 hover:text-gray-700 hover:bg-gray-100 h-full w-20 rounded-r cursor-pointer"
                      >
                        <span class="m-auto text-2xl font-thin">+</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <p>{computeMetaQuauntity()}</p>
          </div>
        ) : (
          ""
        )}
      </>
    )
  );
}
