import { useRef, useEffect, useState } from "react";
import authRequestService from "../../../../services/HttpRequests/authenticated-request-services";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { API_BASE, authUserData } from "../../../../Utils/helpers";
import BasicButton from "../../../../components/buttons/basic-button";
import { useParams } from "react-router-dom";

const AddressVerification = (props) => {
  const {
    mode,
    setLocation,
    location,
    stage,
    regType,
    handlePrevStage,
    handleNextStage,
    getVerificationData,
  } = props;
  const [savingStage, setSavingStage] = useState(false);
  const [showDefValue, setShowDefValue] = useState(0);
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: "ng" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
  };

  const { id } = useParams();

  const saveStage = () => {
    const url = API_BASE + "/sales/supplier/stages/save";
    setSavingStage(true);
    authRequestService()
      .post(url, {
        address: location.address,
        stage: stage,
        type: regType,
        uuid: id,
        mode: mode,
      })
      .then((res) => {
        setSavingStage(false);
        if (mode == "redo") {
          getVerificationData();
        } else {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          handleNextStage();
        }
      })
      .catch((err) => setSavingStage(false));
  };
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      setLocation((prev) => ({
        ...prev,
        biz_lat: latitude,
        biz_lng: longitude,
        address: place.name,
      }));
      console.log(place);
    });
  }, []);

  const handleGoogleLocationChange = (val) => {
    setLocation((prev) => ({
      ...prev,
      address: val.label,
    }));
    setShowDefValue(true);
    console.log("NEW VAL", val.label);
  };

  const handleLocationChange = () => {};
  return (
    <div className="p-2 w-full">
      <div className="flex flex-col items-center mt-10">
        <div>
          <h2 className="font-bold text-2xl text-center">
            Enter Business Location
          </h2>
        </div>
        <div className="control w-full google-autocomoplete-supplier-form">
          <div className="selected-value">
            <label for="password" class="text-gray-500 mb-2">
              Enter Business Location:
            </label>
            {/* {location.address} */}
            <GooglePlacesAutocomplete
              autocompletionRequest={{
                componentRestrictions: {
                  //to set the specific country
                },
              }}
              selectProps={{
                className: "w-full",
                //set default value
                onInputChange: (newVal) => handleLocationChange(newVal),
                onChange: handleGoogleLocationChange, //save the value gotten from google
                onKeyDown: (newVal) => {
                  setShowDefValue(false);
                  setLocation((prev) => ({
                    ...prev,
                    address: "",
                  }));
                },
                placeholder: location.address,
                styles: {
                  input: (provided) => ({
                    ...provided,
                    color: "#222222",
                    width: "100%",
                    maxWidth: "100%",
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: "#222222",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "#222222",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#000000",
                  }),
                  multiValue: (base, state) => ({
                    ...base,
                    display: "flex",
                    flexWrap: "wrap", // Allow the label to wrap to the next line
                  }),
                  multiValueLabel: (base) => ({
                    ...base,
                    whiteSpace: "normal", // Allow the label to break into multiple lines
                  }),
                },
              }}
              onLoadFailed={(error) => {
                console.log(error);
              }}
            />
            {/* <input ref={inputRef} value={location.address} /> */}
          </div>
        </div>
        <div className="w-full border flex flex-col md:flex-row justify-center items-center gap-1 my-1">
          <BasicButton
            handleClick={() => handlePrevStage()}
            title={"Back"}
            disabled={savingStage}
            classes={"bg-black w-full"}
          />
          <BasicButton
            handleClick={() => saveStage()}
            title={
              savingStage
                ? "Submitting.."
                : mode == "default"
                ? "Next"
                : "Submit Business Verification"
            }
            disabled={savingStage || !location.address}
            classes={"bg-black w-full"}
          />
        </div>
      </div>
    </div>
  );
};
export default AddressVerification;
