import * as faceapi from "face-api.js";
import { useState, useEffect } from "react";
import BasicButton from "../../../../components/buttons/basic-button";
import authRequestService from "../../../../services/HttpRequests/authenticated-request-services";
import { API_BASE, authUserData } from "../../../../Utils/helpers";
import { useRef } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

export default function FacialVerification(props) {
  const {
    mode,
    image,
    setImage,
    stage,
    regType,
    handlePrevStage,
    handleNextStage,
    profileUpdateRedo,
    getVerificationData,
  } = props;
  const [captured, setCaptured] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const [streaming, setStreaming] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifying, setVeryfying] = useState(false);
  const [savingStage, setSavingStage] = useState(false);
  const [errors, setErrors] = useState({
    permission: false,
    no_face: false,
  });
  const [showPermissionErrorModal, setShowPermissionErrorModal] =
    useState(false);
  const [showNoCameraErrorModal, setShowNoCameraErrorModal] = useState(false);
  const [successfullyVerified, setSuccessfullyVerified] = useState(false);
  const [duplicateFaceErrorModal, setDuplicateFaceErrorModal] = useState(false);
  const [stream, setStream] = useState(null);
  const [successful, setSuccessful] = useState(false);
  const [showCapture, setShowCapture] = useState(false);
  const [noFaceErrorModal, setNoFaceErrorModal] = useState(false);
  const [preview, setPreview] = useState("");

  const videoRef = useRef();
  const canvasRef = useRef();
  const blobRef = useRef();
  const blobToFileRef = useRef();
  const intervalIdRef = useRef(null);

  const { id } = useParams();

  async function canvasToBlob() {
    // Convert the canvas content to a Blob
    return new Promise((resolve) => {
      canvasRef.current.toBlob((blob) => {
        resolve(blob);
      }, "image/png");
    });
  }

  const saveStage = async () => {
    const url = API_BASE + "/sales/supplier/stages/save";
    setSavingStage(true);
    setCaptured(true);
    setCapturing(false);
    const blob = await canvasToBlob();
    if (blob) {
      const imageToFile = new File([blob], "image.jpeg", {
        type: blob.type,
      });
      console.log("XXX", imageToFile);
      let formData = new FormData();
      formData.append("image", imageToFile);
      formData.append("stage", stage);
      formData.append("type", regType);
      formData.append("uuid", id);
      formData.append("mode", mode);
      authRequestService()
        .post(url, formData)
        .then((res) => {
          toast("Face Capture Successful", { type: "success" });
          setTimeout(() => {
            handleNextStage();
            killTheCamera();
            getVerificationData();
          }, 500);
        })
        .catch((err) => setSavingStage(false));
    }
  };

  const showNotice = () => {
    let notice = false;
    if (!captured) {
      if (!showCapture) {
        notice = true;
      }
    }
    return notice;
  };
  const enableCamera = () => {
    setLoading(true);
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri("/weights"),
      faceapi.nets.faceLandmark68TinyNet.loadFromUri("/weights"),
      faceapi.nets.faceRecognitionNet.loadFromUri("/weights"),
      faceapi.nets.faceLandmark68Net.loadFromUri("/weights"),
      faceapi.nets.faceExpressionNet.loadFromUri("/weights"),
    ]).then(() => {
      startVideo();
    });
  };
  const startVideo = () => {
    setStreaming(true);
    /* Setting up the constraint */
    let facingMode = "user"; // Can be 'user' or 'environment' to access back or front camera (NEAT!)
    let constraints = {
      audio: false,
      video: {
        facingMode: facingMode,
      },
    };

    /* Stream it to video element */
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        setStreaming(true);
        setLoading(true);
        setStream(stream);
        let video = videoRef.current;
        video.srcObject = stream;
        initStream(video);
      })
      .catch((err) => {
        setStreaming(false);
        if (err.toString().search("Permission denied") !== -1) {
          setPermissionErrorState();
        } else if (err.toString().search("device not found")) {
          setNoCameraErrorState();
        }
        console.log("err", err.toString());
      });
  };
  const setPermissionErrorState = () => {
    setErrors((prev) => ({ ...prev, permission: true }));
    setShowPermissionErrorModal(true);
  };
  const setNoCameraErrorState = () => {
    setErrors((prev) => ({ ...prev, no_camera: true }));
    setShowNoCameraErrorModal(true);
  };

  const capture = () => {
    setShowCapture(false);
    // setCaptured(true);
    intervalIdRef.current = setInterval(() => {
      if (videoRef.current && canvasRef.current) {
        killTheCamera();
        clearInterval(intervalIdRef.current);
        let canvas = canvasRef.current;
        let width = "254";
        let height = "256";
        canvas.width = width;
        canvas.height = height;
        canvas
          .getContext("2d")
          .drawImage(videoRef.current, 0, 0, width, height);
        // for drawing the video element on the canvas
        setTimeout(() => {
          saveStage();
        }, 500);
      }
    }, 200);
  };

  useEffect(() => {
    console.log("UDATED");
  }, [blobRef.current]);
  const killTheCamera = () => {
    if (!stream) {
      return;
    }
    stream.getVideoTracks()[0].stop();
  };

  const initStream = async (video) => {
    video.addEventListener("play", (event) => {
      video.width = 300;
      video.height = 100;
      let canvas = null;
      canvas = faceapi.createCanvasFromMedia(video);
      intervalIdRef.current = setInterval(async () => {
        canvas.className = "capture_canvas";
        document.getElementById("streaming_block").append(canvas);
        const displaySize = { width: 254, height: 190.5 };
        faceapi.matchDimensions(canvas, displaySize);
        const detections = await faceapi
          .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
          .withFaceLandmarks()
          .withFaceExpressions();
        const resizedDetections = faceapi.resizeResults(
          detections,
          displaySize
        );
        //canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
        faceapi.draw.drawDetections(canvas, resizedDetections);
        if (detections.length) {
          if (detections[0].detection.score > 0.87) {
            setShowCapture(true);
            clearInterval(intervalIdRef.current);
            canvasRef.current = canvas;
            capture();
          } else {
            setShowCapture(true);
          }
        } else {
          setShowCapture(false);
        }
      }, 100);
    });
  };

  useEffect(() => {
    if (stage == 5) {
      import("../../../../styles/publisher/supplier/facial-recognition.css");
    } else {
      import("../../../../styles/publisher/supplier/video-verification.css");
    }
    return () => clearInterval(intervalIdRef.current);
  }, []);

  return (
    <div class="flex flex-col items-center justify-center">
      <div>
        <h2 className="font-bold text-2xl text-center">Facial Verification</h2>
      </div>
      {streaming && (
        <div id="streaming_block" class="w-64  border bg-black">
          {!captured && (
            <video
              ref={videoRef}
              width="300"
              height="100"
              muted
              autoPlay
              playsInline
            ></video>
          )}
          {captured && (
            <div class="w-64 h-64">
              <canvas ref={canvasRef} style={{ overflow: "auto" }}></canvas>
            </div>
          )}
          <div class="flex justify-between p-4 bg-white">
            {/* {captured && (
              <BasicButton
                title={"Re-take"}
                handleClick={() => retake()}
                classes={"mr-2"}
              />
            )} */}

            {showNotice() && (
              <div style={{ fontWeight: "bold", textTransform: "lowercase" }}>
                <p>
                  Ensure your FACE is BRIGHTLY LIT and CLOSE to the camera, with
                  your FULL HEAD captured. Move head slightly until the blue
                  camera detection frame becomes active, and wait for it to take
                  a picture of your face.
                </p>
              </div>
            )}

            {/* {captured && (
              <BasicButton
                handleClick={verify}
                disabled={verifying}
                title={verifying ? "Please Wait..." : "Continue "}
              />
            )} */}
          </div>
        </div>
      )}

      {errors.permission && (
        <div className="w-64 h-64 border flex flex-col justify-center items-center bg-gray-100">
          <div class="flex justify-center items-center bg-gray-600 rounded-full w-10 h-10">
            <i class="fas fa-exclamation-triangle fa-1x text-gray-200"></i>
          </div>
          <br />
          <p class="text-bold text-gray-700 uppercase text-sm">
            CAMERA ACCESS BLOCKED!
          </p>
        </div>
      )}

      {errors.no_camera && (
        <div className="w-64 h-64 border flex flex-col justify-center items-center bg-gray-100">
          <div className="flex justify-center items-center bg-gray-600 rounded-full w-10 h-10">
            <i className="fas fa-camera fa-1x text-gray-200"></i>
          </div>
          <br />
          <p className="text-bold text-gray-700 uppercase te-xtsm">
            No Camera Found!
          </p>
        </div>
      )}
      {!streaming && !(errors.permission || errors.no_camera) && (
        <div className="w-64 h-64 border flex flex-col justify-center items-center bg-gray-100">
          <div className="flex justify-center items-center bg-gray-600 rounded-full w-10 h-10">
            <i class="fas fa-video fa-1x text-gray-200"></i>
          </div>
          <br />
          {/* <BasicButton
            handleClick={() => enableCamera()}
            title={loading ? "Working..." : "Start Camera "}
            disabled={loading}
            classes={"bg-black w-full"}
          /> */}
        </div>
      )}
      {!streaming && !(errors.permission || errors.no_camera) && (
        <div className="flex gap-2 items-start w-full my-1">
          <BasicButton
            handleClick={() => enableCamera()}
            title={loading ? "Working..." : "Start Camera "}
            disabled={loading}
            classes={"bg-black w-full"}
          />
        </div>
      )}
    </div>
  );
}
