import { useEffect } from "react";
import BasicButton from "../../../../components/buttons/basic-button";
import {
  isAdmin,
  API_BASE,
  isAuthUser,
  isResponsiveMode,
} from "../../../../Utils/helpers";
import { toast } from "react-toastify";

export default function AdminDefaultContractsOpt() {
  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
  }, []);

  return (
    <>
      <div className="main w-full">
        <div className="bg-white m-2 p-2 flex justify-between shadow px-4">
          <div>
            <h1 className="text-2xl text-center m-2 bold">
              {" "}
              Default Contracts
            </h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex justify-center my-4 overflow-hidden">
          <div class="flex flex-col items-center p-10 gap-4 md:w-6/12">
            <div className="w-full">
              <a href="/admin/supplier/product-contracts/default/single">
                <BasicButton
                  title={"Single Purchase"}
                  handleClick={() => window.history.back()}
                  classes={"w-full"}
                />
              </a>
            </div>
            <div className="w-full">
              <a href="/admin/supplier/product-contracts/default/recurring">
                <BasicButton
                  title={"Recurring Purchase"}
                  handleClick={() => window.history.back()}
                  classes={"w-full"}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
