import TextField from "../../../components/inputs/text-input";
import TextNumberField from "../../../components/inputs/text-number-input";
import BasicButton from "../../../components/buttons/basic-button";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import StripeRedirectModal from "../../../modals/stripe-redirect-modal";
import Countries from "../../../components/country/country";
import Timezones from "../../../components/timezone/timezone";
import PasswordPromptModal from "../../../modals/password-prompt-modal";
import StripeRedirectModeModal from "../../../modals/stripe-redirect-mode-modal";
import DeletePromptModal from "../../../modals/delete-warning-modal";
import TimezonesWithDefault from "../../../components/timezone/timezone-with-default";
import CountriesWithDefault from "../../../components/country/country-with-default";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";

import {
  isAdmin,
  API_BASE,
  isPublisher,
  getLocation,
  getClientBaseURL,
  isAuthUser,
  authUserData,
  isSales,
} from "../../../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
import Switch from "react-switch";
export default function SalesSettings() {
  const [paymentType, setPaymentType] = useState(["one-time"]);
  const [processing, setProcessing] = useState(false);
  const [publisher, setPublisher] = useState({});
  const [price, setPrice] = useState("");
  const [ogPrice, setOGPrice] = useState("");
  const [redirectModalOpen, setRedirectModalOpen] = useState(false);
  const [redirectModeModalOpen, setRedirectModeModalOpen] = useState(false);
  const [redirectLink, setRedirectLink] = useState("");
  const [redirectModeModalMsg, setRedirectModeModalMsg] = useState("");
  const [setting, setSetting] = useState({});
  const [enableSubscription, setEnableSubscription] = useState(false);
  const [timeZone, setTimezone] = useState("");
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [firtName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showEmailChangeModal, setShowEmailChangeModal] = useState(false);
  const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);
  const [deleteAccountModalOpen, showDeleteAccountModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  let { userId } = useParams();
  let { mode } = useParams();

  const hideDeleteAccountModal = () => {
    showDeleteAccountModalOpen(false);
  };
  const hideRedirectModal = () => {
    setRedirectModalOpen(false);
  };

  const hideRedirectModeModal = () => {
    setRedirectModeModalOpen(false);
  };

  const generatePasswordResetLink = () => {
    return getLocation().hostname == "litzapp.com"
      ? getClientBaseURL() + `/password-reset/usr${authUserData().uuid}/tok/`
      : getClientBaseURL() +
          `:3000/password-reset/usr/${authUserData().uuid}/tok/`;
  };

  const getPublisher = () => {
    let url = API_BASE + "/api/publisher/" + authUserData().uuid;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setPublisher(response.data.publisher);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const updateEmail = () => {
    const url = API_BASE + "/api/user/update/email";
    setProcessing(true);
    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("user_id", authUserData().uuid);
    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        getPublisher();
        closePasswordPromptModal();
        toast("Updated!", { type: "success" });
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.status == 422) {
          // console.log(error.response.data);
          if (error.response.data.hasOwnProperty("duplicate_email")) {
            toast("Duplicate registration, email address already registered!", {
              type: "error",
            });
          } else if (error.response.data.hasOwnProperty("invalid_password")) {
            toast("Invalid Password!", {
              type: "error",
            });
          } else {
            toast("Something went wrong!", { type: "error" });
          }
        }
      });
  };

  const updatePassword = () => {
    const url = API_BASE + "/api/user/update/send-password-reset-eamil";
    setProcessing(true);
    let formData = new FormData();
    formData.append("email", email);
    formData.append("user_id", authUserData().uuid);
    formData.append("link", generatePasswordResetLink());
    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        toast("Please check your email for your password reset link!", {
          type: "success",
        });
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const updateHolderInfo = () => {
    const url = API_BASE + "/api/user/update/holder-infiormation";
    if (
      firtName.toLowerCase().includes("litzapp") ||
      firtName.toLowerCase().includes("litzap") ||
      lastName.toLowerCase().includes("litzapp") ||
      lastName.toLowerCase().includes("litzap")
    ) {
      toast(
        "Sorry! Litzapp cannot be used in First Name Or Last Name Field(s)!",
        {
          type: "error",
        }
      );
      return;
    }
    setProcessing(true);
    let formData = new FormData();
    formData.append("first_name", firtName);
    formData.append("last_name", lastName);
    formData.append("country", country);
    formData.append("country_code", countryCode);
    formData.append("timezone", timeZone);
    formData.append("user_id", authUserData().uuid);

    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        toast("Updated!", { type: "success" });
        // getPublisher();
        // getSystemSetting();
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const updateSetting = () => {
    const url = API_BASE + "/api/user/update/payment-setting";
    console.log("did price update", price);
    if (Number(price) < 0.5) {
      toast(
        "Sorry, price must be $0.50 or greater as our payment providers take a percentage of the payment",
        { type: "notice" }
      );
      return false;
    }

    setProcessing(true);
    let formData = new FormData();
    formData.append("payment_type", paymentType ? 1 : 0);
    formData.append("enable_subscription", enableSubscription ? 1 : 0);
    formData.append("user_id", authUserData().uuid);
    formData.append("subscription_cost", price);

    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        toast("Updated!", { type: "success" });
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const generateAccountSetupLink = () => {
    const url = API_BASE + "/api/user/generate/account-setup-link";
    setProcessing(true);
    let formData = new FormData();
    formData.append("user_id", authUserData().uuid);
    formData.append(
      "return_url",
      getLocation().toString().split("settings/")[0] +
        "settings/" +
        authUserData().uuid +
        "/return"
    );
    formData.append(
      "refresh_url",
      getLocation().toString().split("settings/")[0] +
        "settings/" +
        userId +
        "/refresh"
    );

    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        setRedirectLink(response.data.link);
        setRedirectModalOpen(true);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const handleSubscriptionEnabledChange = () => {
    setEnableSubscription((prev) => !prev);
  };

  const handlePriceChange = (e) => {
    setOGPrice(e);
    setPrice(e);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e);
  };

  const handleLastNameChange = (e) => {
    setLastName(e);
  };

  const handleEmailChange = (e) => {
    setEmail(e);
  };

  const handlePhoneChange = (e) => {
    setPhone(e);
  };

  const handlePasswordChange = (e) => {
    setPassword(e);
  };

  const handleTimezoneChange = (e) => {
    setTimezone(e.target.value);
  };

  const handleCountryChange = (e) => {
    // setCountry(e.target.value);
    let objectTarget = JSON.parse(e.target.value);
    setCountry(objectTarget.country);
    setCountryCode(objectTarget.country_code);
  };

  const openPasswordPromptModal = () => {
    setShowPasswordChangeModal(true);
  };

  const closePasswordPromptModal = () => {
    setShowPasswordChangeModal(false);
  };

  const openEmailChangeModal = () => {
    setShowEmailChangeModal(true);
  };

  const closeEmailChangeModal = () => {
    setShowEmailChangeModal(false);
  };

  const connectedPaymentInformation = () => {
    let connectedAccount;
    let connectedAccountWebhookEvents;

    if (publisher.name && publisher.stripeconnectedaccount) {
      connectedAccount = publisher.stripeconnectedaccount;
      if (connectedAccount.webhookevents) {
        connectedAccountWebhookEvents =
          connectedAccount?.webhookevents?.data?.object?.external_accounts
            ?.data[0];
      }
    }
    return connectedAccountWebhookEvents;
  };

  const canSubmit = () => {
    // if (!paymentType.length) {
    //   return true;
    // }
    // if (!price) {
    //   return true;
    // }

    return false;
  };

  const getSystemSetting = () => {
    const url = API_BASE + "/system/publisher/settings/" + authUserData().uuid;
    setProcessing(true);

    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        setSetting(response.data);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  const removeAccount = () => {
    const url = API_BASE + "/api/user/account/delete/" + userId;
    setProcessing(true);
    axios
      .delete(url)
      .then((response) => {
        setProcessing(false);
        getPublisher();
        toast("Removed!", { type: "success" });
        hideDeleteAccountModal();
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", { type: "error" });
      });
  };

  useEffect(() => {
    if (!isSales || JSON.parse(isAuthUser()).uuid !== userId) {
      window.location.href = "/";
    }
    getPublisher();
    getSystemSetting();
    if (mode == "return") {
      setRedirectModeModalOpen(true);
    }
  }, []);

  useEffect(() => {
    if (publisher.name) {
      if (publisher.enable_subscription) {
        setEnableSubscription(true);
        setPrice(publisher.subscription_cost);
        setOGPrice(publisher.subscription_cost);
      }

      setFirstName(publisher.first_name);
      setLastName(publisher.last_name);
      setEmail(publisher.email);
      setCountry(publisher.country);
      setTimezone(publisher.timezone);
      setCountryCode(publisher.country_code);
    }
  }, [publisher]);

  // alert(getLocation().toString().split("settings/")[0] + "settings/" + userId);
  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">
            Account Setting
          </h1>
        </div>
        <div>
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center gap-4 mt-8 items-center mb-4">
        <div className="w-10/12 md:w-6/12  p-4 h-26 flex flex-col items-center border border-gray">
          <h1 className="font-bold">Holder Information</h1>

          <div class="flex flex-col w-full my-5">
            <label class="text-gray-500 mb-2">First Name</label>
            <TextField
              placeholder={"First Name"}
              handleChange={handleFirstNameChange}
              value={firtName}
            />
          </div>

          <div class="flex flex-col w-full my-5">
            <label class="text-gray-500 mb-2">Last Name</label>
            <TextField
              placeholder={"Last Name"}
              handleChange={handleLastNameChange}
              value={lastName}
            />
          </div>

          <div id="input" class="flex flex-col w-full my-5">
            <label class="text-gray-500 mb-2">Timezone</label>
            <TimezonesWithDefault
              handleTimezoneChange={handleTimezoneChange}
              value={timeZone}
            />
          </div>

          <div id="input" class="flex flex-col w-full my-5">
            <label class="text-gray-500 mb-2">Country</label>
            <CountriesWithDefault
              handleCountryChange={handleCountryChange}
              value={JSON.stringify({
                country: country,
                country_code: countryCode,
              })}
            />
          </div>

          <div className="my-8 text-center w-full">
            <br />
            <BasicButton
              disabled={canSubmit()}
              title={processing ? "Updating.." : "Update"}
              classes={"p-6 w-9/12 mt-4"}
              handleClick={updateHolderInfo}
            />
          </div>
        </div>
        <hr className="text-black" />
        <div className="w-10/12 md:w-6/12  p-4 h-26 flex flex-col items-center border border-gray">
          <h1 className="font-bold">Payout</h1>

          {publisher.stripeconnectedaccount &&
          publisher.stripeconnectedaccount.payouts_enabled ? (
            <div className="w-8/12 flex flex-col items-center justify-center">
              <p className="">
                You connected one{" "}
                {connectedPaymentInformation()
                  ? connectedPaymentInformation().object == "card"
                    ? "Card"
                    : "Bank Account"
                  : "Account"}
              </p>

              <div className="flex justify-center w-full">
                {connectedPaymentInformation() ? (
                  connectedPaymentInformation().object == "card" ? (
                    <div className="w-full flex flex-col items-center border border-gray p-2">
                      <div className="flex flex-col md:flex-row justify-around p-2 gap-1 items-center md:w-10/12">
                        <div className="text-right">
                          <i class="fas fa-credit-card text-6xl text-yellow w-full"></i>
                        </div>
                        <div className="text-center md:text-left">
                          {/* <p className="text-gray text-xs">Account Type</p> */}
                          <p>
                            <b className="text-xs">
                              {connectedPaymentInformation().object == "card"
                                ? "Card No"
                                : "Bank Account"}
                            </b>
                          </p>
                          <p className="text-gray text-xs">
                            xxxx xxxx xxxx {connectedPaymentInformation().last4}
                          </p>
                          <p>
                            <b className="text-xs">
                              {/* {
                              publisher.connectedaccount?.webhookevents
                                .created_at
                            } */}
                            </b>
                          </p>
                        </div>
                      </div>
                      <BasicButton
                        disabled={processing}
                        title={processing ? "Deleting.." : "Delete Card"}
                        classes={"p-6 w-9/12 mt-4 bg-red-500"}
                        handleClick={() => {
                          showDeleteAccountModalOpen(true);
                        }}
                      />
                    </div>
                  ) : (
                    <div className="w-full flex flex-col items-center border border-gray p-2">
                      <div className="flex flex-col md:flex-row justify-around p-2 gap-1 items-center md:w-10/12">
                        <div className="text-right">
                          <i class="fas fa-university text-6xl text-yellow w-full"></i>
                        </div>
                        <div className="text-center md:text-left">
                          {/* <p className="text-gray text-xs">Account Type</p> */}
                          <p>
                            <b className="text-xs">
                              {connectedPaymentInformation().object == "card"
                                ? "Card No"
                                : connectedPaymentInformation().bank_name}
                            </b>
                          </p>
                          <p className="text-gray text-xs">
                            xxxx xxxx xxxx {connectedPaymentInformation().last4}
                          </p>
                          <p>
                            <b className="text-xs">
                              {/* {
                              publisher.connectedaccount?.webhookevents
                                .created_at
                            } */}
                            </b>
                          </p>
                        </div>
                      </div>
                      <BasicButton
                        disabled={canSubmit()}
                        title={processing ? "Deleting.." : "Delete Account"}
                        classes={"p-6 w-9/12 mt-4 bg-red-500"}
                        handleClick={() => {
                          showDeleteAccountModalOpen(true);
                        }}
                      />
                    </div>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <>
              <p className="w-8/12">
                You do not have any payment profile setup, please setup your
                payment profile to receive payments when readers purchase or
                subscribe to your book.
              </p>
              <BasicButton
                disabled={canSubmit()}
                title={processing ? "Redirecting.." : "Connect Account"}
                classes={"p-6 w-9/12 mt-4"}
                handleClick={generateAccountSetupLink}
              />
            </>
          )}
          <div className="my-4  text-center flex justify-between"></div>
        </div>

        <div className="w-10/12 md:w-6/12  p-4 h-26 flex flex-col items-center border border-gray">
          <h1 className="font-bold">Security</h1>

          <div class="flex flex-col w-full my-2">
            <label class="text-gray-500 mb-2">Email</label>
            <TextField
              placeholder={"Email"}
              handleChange={handleEmailChange}
              value={email}
            />
          </div>

          <div className="my-1 text-center w-full">
            <br />
            <BasicButton
              disabled={canSubmit()}
              title={processing ? "Updating.." : "Update Email"}
              classes={"p-6 w-9/12 mt-4"}
              handleClick={() => openPasswordPromptModal()}
            />
          </div>

          <div class="flex flex-col w-full mt-8">
            <label class="text-gray-500 mb-2">Password</label>
            <p className="text-black">******************</p>
          </div>

          <div className="my-1 text-center w-full">
            <br />
            <BasicButton
              disabled={canSubmit()}
              title={processing ? "Updating.." : "Update Password"}
              classes={"p-6 w-9/12 mt-4"}
              handleClick={updatePassword}
            />
          </div>
        </div>
      </div>
      <StripeRedirectModal
        modalOpen={redirectModalOpen}
        hideModal={hideRedirectModal}
        link={redirectLink}
      />
      <StripeRedirectModeModal
        modalOpen={redirectModeModalOpen}
        hideModal={hideRedirectModeModal}
        msg={
          "Please scroll down to the account setup section to view your account status."
        }
      />
      <PasswordPromptModal
        handleChange={handlePasswordChange}
        hideModal={closePasswordPromptModal}
        modalOpen={showPasswordChangeModal}
        value={password}
        action={updateEmail}
      />
      <DeletePromptModal
        modalOpen={deleteAccountModalOpen}
        hideAdModal={hideDeleteAccountModal}
        title={
          "If you delete your account, you will stop receiving payments. Are you sure you want to delete this account?"
        }
        yesActionText={"Yes, Delete Account"}
        noActionText={"No, Cancel"}
        action={() => removeAccount()}
        processing={processing}
      />
    </>
  );
}
