import axios from "axios";
import { isAuthUser, authUserData } from "../../../Utils/helpers";
import BasicButton from "../../../components/buttons/basic-button";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { API_BASE } from "../../../Utils/helpers";
export default function RenderREProductMetaBox({ payload, page, state }) {
  const [quantity, setQuantity] = useState(1);
  const [productMeta, setProductMeta] = useState({});
  const [meta, setMeta] = useState({});
  const [currScrollPos, setCurrScrollPos] = useState(0);
  const [litzappMode, setLitzappMode] = useState(true);
  const [priceLabsData, setPriceLabsData] = useState();
  const pl = JSON.parse(payload);
  const getMetaByMetaBlockId = () => {
    const url = API_BASE + "/api/book/custom/product/meta/" + pl.block_meta_id;
    //setProcessing(true);
    setTimeout(() => {
      axios
        .get(url)
        .then((response) => {
          if (response.status == 200) {
            setProductMeta(response.data.meta);
            setMeta(JSON.parse(response.data.meta.payload));
            if (response?.data?.data?.pricelabs)
              setPriceLabsData(response?.data?.data?.pricelabs?.price);
            console.log(response?.data?.data?.pricelabs?.price);
            if (response.data?.data?.pricelabs) setLitzappMode(false);
            if (!response.data?.data?.pricelabs) setLitzappMode(true);
          }
        })
        .catch((error) => {
          toast("Something went wrong!", {
            type: "error",
          });
        });
    }, 100);
  };

  let metaInfo = {};
  metaInfo.price = litzappMode ? productMeta.price : priceLabsData;
  metaInfo.contract = productMeta?.contractfile;
  metaInfo.systembuyercontractfile = productMeta?.systembuyercontractfile;
  metaInfo.name = meta.p_type_sub_opt;
  metaInfo.description = "Product Purchase";
  metaInfo.model_type = "PRODUCT_SERVICE";
  metaInfo.page_id = page.uuid;
  metaInfo.quantity = meta.quantity_sel_enabled ? quantity : 1;
  metaInfo.meta = payload;
  metaInfo.block_meta_id = meta.block_meta_id;
  metaInfo.cycle = productMeta.name;

  const computeMetaQuauntity = () => {
    return productMeta?.availablequantity;
  };

  const quantityIsFullyBooked = () => {
    return productMeta?.availablequantity <= 0;
  };

  const hasQuantity = () => {
    return (
      computeMetaQuauntity() !== "" &&
      computeMetaQuauntity() !== "error" &&
      computeMetaQuauntity() !== "NQ" &&
      computeMetaQuauntity() !== undefined
    );
  };

  const canRenderBookLater = () => {
    if (hasQuantity()) {
      if (quantityIsFullyBooked()) {
        return meta.booking_interruption ? true : false;
      }
    }

    return false;
  };

  const canRenderQuantity = () => {
    if (!canRenderBookLater() && hasQuantity()) return true;
  };

  const canRenderButton = (productMeta) => {
    if (
      productMeta.hasactivesub &&
      !meta.booking_interruption &&
      !meta.quantity_enabled
    ) {
      return false;
    }
    return true;
  };

  const shouldDisableButton = (productMeta) => {
    if (
      productMeta.hasactiveandfuturesubs &&
      !meta.booking_interruption &&
      meta.quantity_enabled
    ) {
      return true;
    } else if (
      productMeta.hasactiveandfuturesubs &&
      !meta.quantity_enabled &&
      !meta.booking_interruption
    ) {
      return true;
    } else {
      return false;
    }
  };

  const notProductOwner = () => {
    return isAuthUser() && page.owner && page.owner.id !== authUserData().id;
  };

  const isProductOwner = () => {
    return isAuthUser() && page.owner && page.owner.id == authUserData().id;
  };

  const getPrice = () => {
    if (!litzappMode) return priceLabsData;
    return productMeta.price;
  };

  useEffect(() => {
    getMetaByMetaBlockId();
  }, []);

  return (
    productMeta.id && (
      <>
        {isProductOwner() && !meta.hide_button ? (
          <div className="w-full text-center my-2">
            <BasicButton
              disabled={meta.hide_button || meta.make_inactive}
              title={meta.p_type_sub_opt + "  " + "$" + getPrice()}
              classes="my-bg-yellow-500 hover:bg-yellow-700 text-black text-white shadow"
              handleClick={() => toast("Preview Only", { type: "notice" })}
            />

            {canRenderQuantity() ? (
              <RenderQauntity
                state={{
                  computeMetaQuauntity: computeMetaQuauntity,
                  meta: meta,
                }}
              />
            ) : (
              ""
            )}
            {canRenderBookLater() ? (
              <RenderBookLater
                state={{
                  setSelectPurchaseType: state.setSelectPurchaseType,
                  setProductMetaModalOpen: state.setProductMetaModalOpen,
                  setProductMetaMode: state.setProductMetaMode,
                  setProductMetaInfo: state.setProductMetaInfo,
                  computeMetaQuauntity: computeMetaQuauntity,
                  metaInfo: metaInfo,
                  meta: meta,
                  isProductOwner: isProductOwner,
                }}
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        {notProductOwner() &&
        canRenderButton(productMeta) &&
        !meta.hide_button ? (
          <div className="w-full text-center flex flex-col items-center my-2">
            <div className="flex text-center flex justify-center gap-2">
              <div>
                <BasicButton
                  disabled={shouldDisableButton(productMeta)}
                  title={meta.p_type_sub_opt + " " + "$" + getPrice()}
                  classes="my-bg-yellow-500 hover:bg-yellow-700 text-black text-white shadow mt-2"
                  handleClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                    state.setSelectPurchaseType("recurring");
                    state.setProductMetaModalOpen(true);
                    state.setProductMetaInfo(metaInfo);
                    state.saveCurrScrollPos();
                    if (meta.purchaser_reply_enabled) {
                      state.setProductMetaMode(true);
                    }
                  }}
                />
              </div>
            </div>

            {canRenderQuantity() ? (
              <RenderQauntity
                state={{
                  computeMetaQuauntity: computeMetaQuauntity,
                  meta: meta,
                }}
              />
            ) : (
              ""
            )}
            {canRenderBookLater() ? (
              <RenderBookLater
                state={{
                  setSelectPurchaseType: state.setSelectPurchaseType,
                  setProductMetaModalOpen: state.setProductMetaModalOpen,
                  setProductMetaMode: state.setProductMetaMode,
                  setProductMetaInfo: state.setProductMetaInfo,
                  computeMetaQuauntity: computeMetaQuauntity,
                  metaInfo: metaInfo,
                  meta: meta,
                  isProductOwner: isProductOwner,
                }}
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {!isAuthUser() && !meta.hide_button ? (
          <div className="w-full text-center my-2">
            <BasicButton
              disabled={shouldDisableButton(productMeta)}
              title={meta.p_type_sub_opt + " $" + getPrice()}
              classes="my-bg-yellow-500 hover:bg-yellow-700 text-black text-white shadow"
              handleClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                state.setProductMetaInfo(metaInfo);
                state.setRegistration5ModalOpen(true);
              }}
            />
            {canRenderQuantity() ? (
              <RenderQauntity
                state={{
                  computeMetaQuauntity: computeMetaQuauntity,
                  meta: meta,
                }}
              />
            ) : (
              ""
            )}
            {canRenderBookLater() ? (
              <RenderBookLater
                state={{
                  setSelectPurchaseType: state.setSelectPurchaseType,
                  setRegistration5ModalOpen: state.setRegistration5ModalOpen,
                  setProductMetaModalOpen: state.setProductMetaModalOpen,
                  setProductMetaMode: state.setProductMetaMode,
                  setProductMetaInfo: state.setProductMetaInfo,
                  computeMetaQuauntity: computeMetaQuauntity,
                  metaInfo: metaInfo,
                  meta: meta,
                  isProductOwner: isProductOwner,
                }}
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </>
    )
  );
}

const RenderBookLater = ({ state }) => {
  return (
    <p
      className="cursor-pointer text-green-700 font-bold"
      onClick={() => {
        if (!isAuthUser()) {
          state.setProductMetaInfo(state.metaInfo);
          state.setRegistration5ModalOpen(true);
        } else {
          if (state.isProductOwner()) {
            toast("Preview Only", { type: "notice" });
            return false;
          }
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          state.setSelectPurchaseType("recurring");
          state.setProductMetaModalOpen(true);
          state.setProductMetaInfo(state.metaInfo);
          if (state.meta.purchaser_reply_enabled) {
            state.setProductMetaMode(true);
          }
        }
      }}
    >
      Click to Select Start Date
    </p>
  );
};

const RenderQauntity = ({ state }) => {
  return state?.meta?.quantity_enabled && state?.meta?.booking_interruption ? (
    <p>{state.computeMetaQuauntity() + " available today"}</p>
  ) : (
    ""
  );
};
