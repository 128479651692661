import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import BasicButton from "../../../../../components/buttons/basic-button";
import TextField from "../../../../../components/inputs/text-input";
import Pagination from "../../../../../components/pagination";
import useDebounce from "../../../../../components/debounce/debounce-helper";
import LoadingProgress from "../../../../publisher/rich-page/components/loading-progress";
import Checkbox from "../../../../../components/inputs/checkbox";
import { useSupplierApplicationsStore } from "../../../../../stores/supplier-applications-store";
import AdminSupplierFilterBar from "./filter";
import authRequestService from "../../../../../services/HttpRequests/authenticated-request-services";
import DesktopPubPurchaseTable from "./desktop-table";
import MobilePubPurchaseTable from "./mobile-table";
import AdminApplicantComment from "./comment-modal";

import {
  isAdmin,
  API_BASE,
  isPublisher,
  isAuthUser,
  authUserData,
  getLocation,
  isResponsiveMode,
} from "../../../../../Utils/helpers";
import { toast } from "react-toastify";
import Books from "../../../series/books";
export default function AdminSupplierApplications() {
  const [currentPage, setCurrentPage] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [paginateMode, setPaginateMode] = useState(false);
  const [user, setUser] = useState({});
  const [blockedEmails, setBlockedEmails] = useState([]);
  const mountedRef = useRef();
  const navigate = useNavigate();
  const mailStore = useSupplierApplicationsStore((state) => state);
  const [showCommentModal, setShowCommentModal] = useState(false);

  const fetchUser = () => {
    const url = API_BASE + "/publisher/" + authUserData().uuid + "/optimized";
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setUser(response.data.publisher);
          mailStore.setUser(response.data.publisher);
          if (response.data.publisher.emailblocklist)
            setBlockedEmails(
              response.data.publisher.emailblocklist.map((d) => d.email)
            );
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  const doPagination = (page) => {
    if (mailStore.data.first_page_url) {
      mailStore.setData([]);
      setCurrentPage(page);
      setPaginateMode(true);
      setProcessing(true);
      axios
        .get(mailStore.data.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          mailStore.setData(res.data.purchases);
          mailStore.setIds(res.data.ids);
          setProcessing(false);
        })
        .catch((err) => setProcessing(false));
    }
  };

  const getApplications = () => {
    mailStore.setData([]);
    setProcessing(true);
    setPaginateMode(false);

    const url =
      API_BASE +
      "/admin/supplier/applications/retrieve" +
      "/" +
      mailStore.query.countPerPage +
      "/" +
      mailStore.query.pageLimit +
      "/" +
      mailStore.query.searchText +
      " ";

    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          mailStore.setData(response.data.verifications);
          setProcessing(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const computeSelectedCount = () => {
    if (!mailStore.data.total) return false;
    if (mailStore.isAllSelected) {
      if (mailStore.deSelected.length) {
        console.log("ALL SEL FLAGS ACTIVE WITH SOME DESELECTED");
        mailStore.setSelectedCount(
          mailStore.data.total - mailStore.deSelected.length
        );
      } else {
        console.log("ALL SEL FLAGS ACTIVE WITH NONE DESELECTED");
        mailStore.setSelectedCount(mailStore.data.total);
      }
    } else {
      console.log("ALL SEL FLAGS INACTIVE");
      mailStore.setSelectedCount(mailStore.selected.length);
    }
  };

  const handleSearchTextChange = (e) => {
    mailStore.setQuery("searchText", e);
  };

  const handleCountPerPageChange = (e) => {
    mailStore.setQuery("countPerPage", e.target.value);
  };

  const handleLatestEntriesChange = (e) => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
    mailStore.setQuery("selectedLatest", e.target.value);
    mailStore.setQuery("pageLimit", e.target.value);
  };

  const handleOldestEntriesChange = (e) => {
    mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
    mailStore.setQuery("selectedOldest", e.target.value);
    mailStore.setQuery("pageLimit", e.target.value);
  };

  const handleStatusChange = (e) => {
    mailStore.setQuery("status", e.target.value);
  };

  useEffect(() => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedLatest]);

  useEffect(() => {
    computeSelectedCount();
  }, [mailStore.selected]);

  useEffect(() => {
    computeSelectedCount();
  }, [mailStore.deSelected]);

  useEffect(() => {
    mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedOldest]);

  useDebounce(
    () => {
      if (mailStore.refresh) {
        getApplications();
      }
      mailStore.setRefresh(true);
    },
    [mailStore.query],
    800
  );

  const openCommentModal = (Comment) => {
    mailStore.handleExistingCommentChange(Comment);
    setShowCommentModal(true);
  };
  const closeCommentModal = () => {
    setShowCommentModal(false);
  };

  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
    fetchUser();
  }, []);

  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">
              Supplier Applications
            </h1>
          </div>
          <div className="flex justify-between gap-2 items-start">
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            <p className="w-full text-center my-4">
              We found {mailStore.data.total ? mailStore.data.total : ""}{" "}
              records
            </p>
            <AdminSupplierFilterBar
              handleCountPerPageChange={handleCountPerPageChange}
              handleSearchTextChange={handleSearchTextChange}
              serchQValue={mailStore.query.searchText}
              handleLatestEntriesChange={handleLatestEntriesChange}
              latestQValue={mailStore.query.selectedLatest}
              handleOldestEntriesChange={handleOldestEntriesChange}
              oldestQValue={mailStore.query.selectedOldest}
              handleStatusChange={handleStatusChange}
              status={mailStore.query.status}
              model={"admin-supplier-applications"}
            />

            <div className="w-full flex justify-center">
              {mailStore.data.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            {isResponsiveMode() ? (
              <MobilePubPurchaseTable
                blockedEmails={blockedEmails}
                openCommentModal={openCommentModal}
              />
            ) : (
              <DesktopPubPurchaseTable
                blockedEmails={blockedEmails}
                openCommentModal={openCommentModal}
              />
            )}
            <LoadingProgress loading={processing} />
            <div className="w-full flex justify-center">
              {mailStore.data.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <AdminApplicantComment
        modalOpen={showCommentModal}
        closeModal={closeCommentModal}
      />
    </>
  );
}
