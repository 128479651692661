import TextField from "../../../../../../../../components/inputs/text-input";
import InsertImage from "./insert-image";
export default function EditUploadedFile(props) {
  const { imageFileSelected } = props;
  const { handleImagePreview } = props;
  const { currEditingImagePreview } = props;
  const { resetImagePreview } = props;
  const { handleImageLinkChange } = props;
  const { value } = props;

  const showUploader = () => {
    return !imageFileSelected && !currEditingImagePreview;
  };

  const showDeleteButton = () => {
    return imageFileSelected || currEditingImagePreview;
  };

  return (
    <>
      <div className="my-6 w-full border flex flex-col items-center justify-center bg-gray-50 p-2">
        {showUploader() && (
          <div className="w-8/12 md:w-3/12 relative flex flex-col">
            {" "}
            <p className="text-red-700 text-xs">
              Supported Formats (GIF,PNG,JPEG)
            </p>
            <i class="fas fa-image text-5xl"></i>
            <InsertImage handleImagePreview={handleImagePreview} />
          </div>
        )}

        <div className="w-8/12 md:w-3/12 relative">
          {showDeleteButton() && (
            <span
              className="bg-white rounded rounded-full bg-white p-0 right-0 absolute px-1 cursor-pointer"
              style={{ top: -19, right: 0 }}
              onClick={() => resetImagePreview()}
            >
              <i class="fa fa-times text-red-500 z-50 text-2xl"></i>
            </span>
          )}

          <img
            src={
              imageFileSelected ? imageFileSelected : currEditingImagePreview
            }
            className="w-full my-1"
          />
        </div>

        <TextField
          placeholder={"Paste Link...(Optional)"}
          classes="rounded-lg pr-8"
          handleChange={handleImageLinkChange}
          value={value}
        />
      </div>
    </>
  );
}
