import axios from "axios";
import { useEffect, useState } from "react";
import BasicButton from "../../../../../components/buttons/basic-button";
import authRequestService from "../../../../../services/HttpRequests/authenticated-request-services";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useParams } from "react-router-dom";
import {
  API_BASE,
  BASE_URL_WITH_LOCAL,
  BASE_URL,
} from "../../../../../Utils/helpers";
import { toast } from "react-toastify";

export default function PubSupplierMyContractsEdit() {
  const [fileSelected, setFileSelected] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");
  const [location, setLocation] = useState("");
  const [name, setName] = useState("");
  const [processing, setProcessing] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [contract, setContract] = useState({});

  const { id } = useParams();

  const options = {
    componentRestrictions: { country: "ng" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
  };

  const handleGoogleLocationChange = (val) => {
    setLocation(val.label);
  };

  const handleLocationChange = () => {};
  const update = (imageToFile) => {
    const url = API_BASE + "/publisher/supplier/contract/update";
    setProcessing(true);
    let formData = new FormData();
    formData.append("location", location);
    formData.append("name", name);
    formData.append("contract_id", id);
    authRequestService()
      .post(url, formData)
      .then((res) => {
        setProcessing(false);
        toast("Updated", { type: "success" });
      })
      .catch((err) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getContract = () => {
    const url = API_BASE + "/publisher/supplier/contract/" + id;
    setProcessing(true);

    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        setContract(response.data.contract);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  useEffect(() => {
    getContract();
  }, []);

  useEffect(() => {
    if (contract?.id) {
      setName(contract.name);
      setLocation(contract.location);
    }
  }, [contract]);
  return (
    <>
      <div className="main w-full">
        <div className="bg-white m-2 p-2 flex justify-between shadow px-4">
          <div>
            <h1 className="text-2xl text-center m-2 bold"> Add Contract</h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center my-4 overflow-hidden">
          <div className="p-2 w-full md:w-6/12">
            <div id="input" class="flex flex-col w-full  my-5">
              <label for="password" class="text-gray-500 mb-2">
                Contract Name
              </label>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                id="identit-name"
                placeholder={`Enter Contract Name..`}
                class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
              />
            </div>

            <div className="p-2 w-full">
              <div className="flex flex-col items-center mt-10">
                <div>
                  <label className="text-black m-2">
                    Location Covered By the Contract
                  </label>
                </div>
                <div className="control w-full">
                  <div className="selected-value">
                    <label for="password" class="text-gray-500 mb-2">
                      Enter Business Location
                    </label>
                    <GooglePlacesAutocomplete
                      autocompletionRequest={{
                        componentRestrictions: {
                          //to set the specific country
                        },
                      }}
                      selectProps={{
                        defaultInputValue: location, //set default value
                        onInputChange: (newVal) => handleLocationChange(newVal),
                        onChange: handleGoogleLocationChange, //save the value gotten from google
                        placeholder: location,
                        styles: {
                          input: (provided) => ({
                            ...provided,
                            color: "#222222",
                          }),
                          option: (provided) => ({
                            ...provided,
                            color: "#222222",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: "#222222",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: "#000000",
                          }),
                        },
                      }}
                      onLoadFailed={(error) => {
                        console.log(error);
                      }}
                    />
                  </div>
                </div>
                <small className="text-left text-red-700  w-full">
                  Enter the address that this specific contract applies to.
                </small>
                <div className="w-full border flex flex justify-center items-center gap-1 my-4">
                  <BasicButton
                    classes={"w-full"}
                    disabled={!name || !location || processing}
                    title={processing ? "Updating.." : "Update"}
                    handleClick={() => update()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
