import BasicButton from "../../../buttons/basic-button";
import RenderProfilePhotoCircle from "./circle-avatar";
import { BASE_URL } from "../../../../Utils/helpers";

import Slider from "react-slick";
export default function RenderCPProductBook(props) {
  const { book } = props;
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const renderProfilePhoto = () => {
    if (book.owneroptimized?.profile_photo) {
      return (
        BASE_URL +
        "/storage/" +
        book.owneroptimized?.profile_photo?.split("public")[1]
      );
    } else {
      return (
        BASE_URL +
        "/storage/" +
        book.owneroptimized?.backup_profile_photo?.split("public")[1]
      );
    }
  };
  return (
    <div
      className="flex flex-col items-center w-full cursor-pointer"
      onClick={() => openInNewTab("/web/rich-page/render/" + book.uuid)}
    >
      <div className="w-full mb-2">
        <BasicButton
          classes={"my-black text-white px-8"}
          title={"View Item"}
          handleClick={() =>
            (window.location.href = `/web/${book.owneroptimized.uuid}`)
          }
        />
      </div>
      <div className="relative w-full w-64 gap-4  z-50 cursor-pointer">
        <div className="px-6 mb-2">
          <Slider {...settings} className="">
            {book.productimages
              ? book.productimages.map((a) => (
                  <div className="text-center cursor-pointer mx-2 relative">
                    <div className="flex flex-col justify-center items-center">
                      <div className="w-full text-center flex justify-center h-32 md:h-40">
                        {" "}
                        <img
                          src={
                            BASE_URL +
                            "/storage" +
                            a.product_thumb?.split("public")[1]
                          }
                          className="w-24 h-24  md:w-36 md:h-36 object-cover rounded-full"
                        />{" "}
                      </div>
                      <div className="absolute absolute m-auto -bottom-0 md:-bottom-3 left-0 right-0 h-16 w-16  rounded-lg flex items-center justify-center">
                        <RenderProfilePhotoCircle book={book} />
                      </div>
                    </div>
                  </div>
                ))
              : ""}
          </Slider>
          {!book?.productimages || book?.productimages == 0 ? (
            <div className="w-full text-center flex justify-center h-32 md:h-40 relative">
              <img
                className="w-24 h-24 md:w-36 md:h-36 object-cover rounded-full"
                onClick={() => null}
                src={renderProfilePhoto()}
              />
              <div className="absolute absolute m-auto -bottom-0 md:-bottom-3 left-0 right-0 h-16 w-16  rounded-lg flex items-center justify-center">
                <RenderProfilePhotoCircle book={book} />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="w-full flex flex-col items-center">
        {" "}
        <div className="flex  flex-col justify-center cursor-pointer">
          <div className="flex justify-center gap-2">
            <div className="flex"> By:</div>
            <div className="flex">{book.owneroptimized.name}</div>
          </div>
        </div>
        <p>
          {" "}
          <span className="text-yellow-600 text-xs font-bold">
            {book.owneroptimized.username}
          </span>
        </p>
        <div>
          <h1 className="font-bold text-xl text-center">{book.name}</h1>
        </div>
      </div>
    </div>
  );
}
