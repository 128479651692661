import BasicButton from "../../../../../../../components/buttons/basic-button";
import UploadFile from "./components/upload";
export default function FileUploaderBox(props) {
  const { uploadImages } = props;
  const { mediaType } = props;
  const { parentIndex } = props;
  const { accordions } = props;
  const { deleteMedia } = props;
  const { processing } = props;
  const { selectedImages } = props;
  const { handleImageUpload } = props;
  const { saveAccordionImages } = props;
  const { accordionSectionKey } = props;
  const { accordionId } = props;
  const media = accordions[parentIndex].media.filter((m) => m.type == "upload");

  return (
    <>
      {mediaType == "upload" ? (
        <div className="flex flex-col items-center justify-center w-full">
          <div className="w-full bg-gray-50 p-2 border">
            <UploadFile
              mediaType={mediaType}
              selectedImages={selectedImages}
              deleteMedia={deleteMedia}
              parentIndex={parentIndex}
              accordions={accordions}
              handleImageUpload={handleImageUpload}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {selectedImages.length ? (
        <div className="w-full my-4">
          {" "}
          <BasicButton
            title={processing ? "Uploading..." : "Upload"}
            classes="px-8 w-8/12 my-bg-yellow-500 text-black"
            handleClick={() =>
              saveAccordionImages(
                selectedImages,
                accordionId,
                parentIndex,
                accordionSectionKey
              )
            }
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
