import TextField from "../../../../inputs/text-input";
import ProductAvailibilitySearch from "../product-availability";
import BasiSearchBar from "./widgets/search";
import RatingBar from "./widgets/rating";
import MenuBox from "./widgets/menu";

export default function DashSectionTwo(props) {
  const { rating } = props;
  const { search } = props;
  const { activeTab } = props;
  const { publisher } = props;
  const { setActiveTab } = props;
  const { multiSearchRef } = props;
  const { openAvailabilityCalendar } = props;

  return (
    <div class="px-px md:px-3">
      {/* <!-- user following for mobile only --> */}
      <ul
        class="flex md:hidden justify-around space-x-8 border-t 
                text-center p-2 text-gray-600 leading-snug text-sm"
      >
        <li>
          <span class="font-semibold text-gray-800 block">
            {" "}
            {publisher ? publisher.books_count : 0}
          </span>
          Content
        </li>

        <li
          onClick={() => setActiveTab(5)}
          className={
            activeTab == 5
              ? "border-t border-gray-700 -mt-px text-gray-700 text-center cursor-pointer"
              : "text-center cursor-pointer"
          }
        >
          <span class="font-semibold text-gray-800 block">
            {" "}
            {publisher && publisher.trackers ? publisher.trackers.length : 0}
          </span>
          Tracker(s)
        </li>
        <li
          onClick={() => setActiveTab(4)}
          className={
            activeTab == 4
              ? "border-t border-gray-700 -mt-px text-gray-700 text-center cursor-pointer"
              : "text-center cursor-pointer"
          }
        >
          <span class="font-semibold text-gray-800 block">
            {" "}
            {publisher && publisher.tracking ? publisher.tracking.length : 0}
          </span>
          Tracking
        </li>
      </ul>
      {/* <!-- insta freatures --> */}
      <input ref={multiSearchRef} className="h-0" />
      <ProductAvailibilitySearch
        openAvailabilityCalendar={openAvailabilityCalendar}
      />
      <SubSectionTwo rating={rating} search={search} />
      <MenuBox
        setActiveTab={setActiveTab}
        publisher={publisher}
        activeTab={activeTab}
      />
    </div>
  );
}

export const SubSectionTwo = (props) => {
  const { rating } = props;
  const { search } = props;
  return (
    <div className="flex flex-col md:flex-row md:grid-cols-3 justify-between items-center m-2 gap-2">
      <BasiSearchBar {...search} />
      <RatingBar {...rating} />
    </div>
  );
};
