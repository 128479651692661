import AlignCenterIcon from "../resources/AlignCenterIcon";
import AlignLeftIcon from "../resources/AlignLeftIcon";
import AlignRightIcon from "../resources/AlignRightIcon";
import UnorderListIcon from "../resources/UnorderListIcon";
import OrderListIcon from "../resources/OrderListIcon";
import BoldIcon from "../resources/BoldIcon";
import ItalicIcon from "../resources/ItalicIcon";
import UnderlineIcon from "../resources/UnderlineIcon";

export const PRESET_COLORS = [
  "#ff00aa",
  "#F5A623",
  "#F8E71C",
  "#8B572A",
  "#7ED321",
  "#417505",
  "#BD10E0",
  "#9013FE",
  "#4A90E2",
  "#50E3C2",
  "#B8E986",
  "#000000",
  "#4A4A4A",
  "#9B9B9B",
  "#FFFFFF",
];

export const FONT_EXPORT_CLASS_MAPPING = {
  f8: {
    element: "span",
    style: { fontSize: "8px" },
    attributes: { class: "draft-8-px" },
  },
  f9: {
    element: "span",
    style: { fontSize: "9px" },
    attributes: { class: "draft-9-px" },
  },
  f10: {
    element: "span",
    style: { fontSize: "10px" },
    attributes: { class: "draft-10-px" },
  },
  f12: {
    element: "span",
    style: { fontSize: "12px" },
    attributes: { class: "draft-12-px" },
  },
  f14: {
    element: "span",
    style: { fontSize: "14px" },
    attributes: { class: "draft-14-px" },
  },
  f16: {
    element: "span",
    style: { fontSize: "16px" },
    attributes: { class: "draft-16-px" },
  },
  f18: {
    element: "span",
    style: { fontSize: "18px" },
    attributes: { class: "draft-18-px" },
  },
  f20: {
    element: "span",
    style: { fontSize: "20px" },
    attributes: { class: "draft-20-px" },
  },
  f22: {
    element: "span",
    style: { fontSize: "22px" },
    attributes: { class: "draft-22-px" },
  },
  f24: {
    element: "span",
    style: { fontSize: "24px" },
    attributes: { class: "draft-24-px" },
  },
  f26: {
    element: "span",
    style: { fontSize: "26px" },
    attributes: { class: "draft-26-px" },
  },
  f28: {
    element: "span",
    style: { fontSize: "28px" },
    attributes: { class: "draft-28-px" },
  },
  f30: {
    element: "span",
    style: { fontSize: "30px" },
    attributes: { class: "draft-30-px" },
  },
  f32: {
    element: "span",
    style: { fontSize: "32px" },
    attributes: { class: "draft-32-px" },
  },
  f34: {
    element: "span",
    style: { fontSize: "34px" },
    attributes: { class: "draft-34-px" },
  },
  f36: {
    element: "span",
    style: { fontSize: "36px" },
    attributes: { class: "draft-37-px" },
  },
  f38: {
    element: "span",
    style: { fontSize: "38px" },
    attributes: { class: "draft-38-px" },
  },
  f40: {
    element: "span",
    style: { fontSize: "40px" },
    attributes: { class: "draft-40-px" },
  },
  f42: {
    element: "span",
    style: { fontSize: "42px" },
    attributes: { class: "draft-42-px" },
  },
  f44: {
    element: "span",
    style: { fontSize: "44px" },
    attributes: { class: "draft-44-px" },
  },
  f46: {
    element: "span",
    style: { fontSize: "46px" },
    attributes: { class: "draft-46-px" },
  },
  f48: {
    element: "span",
    style: { fontSize: "48px" },
    attributes: { class: "draft-48-px" },
  },
  f50: {
    element: "span",
    style: { fontSize: "50px" },
    attributes: { class: "draft-50-px" },
  },
  f52: {
    element: "span",
    style: { fontSize: "52px" },
    attributes: { class: "draft-52-px" },
  },
  f54: {
    element: "span",
    style: { fontSize: "54px" },
    attributes: { class: "draft-54-px" },
  },
  f56: {
    element: "span",
    style: { fontSize: "56px" },
    attributes: { class: "draft-56-px" },
  },
  f58: {
    element: "span",
    style: { fontSize: "58px" },
    attributes: { class: "draft-58-px" },
  },
  f60: {
    element: "span",
    style: { fontSize: "60px" },
    attributes: { class: "draft-60-px" },
  },
  f62: {
    element: "span",
    style: { fontSize: "62px" },
    attributes: { class: "draft-62-px" },
  },
  f64: {
    element: "span",
    style: { fontSize: "64px" },
    attributes: { class: "draft-64-px" },
  },
  f66: {
    element: "span",
    style: { fontSize: "66px" },
    attributes: { class: "draft-66-px" },
  },
  f68: {
    element: "span",
    style: { fontSize: "68px" },
    attributes: { class: "draft-68-px" },
  },
  f70: {
    element: "span",
    style: { fontSize: "70px" },
    attributes: { class: "draft-70-px" },
  },
  f72: {
    element: "span",
    style: { fontSize: "72px" },
    attributes: { class: "draft-72-px" },
  },
  f74: {
    element: "span",
    style: { fontSize: "74px" },
    attributes: { class: "draft-74-px" },
  },
  f76: {
    element: "span",
    style: { fontSize: "76px" },
    attributes: { class: "draft-76-px" },
  },
  f78: {
    element: "span",
    style: { fontSize: "78px" },
    attributes: { class: "draft-78-px" },
  },
  f80: {
    element: "span",
    style: { fontSize: "80px" },
    attributes: { class: "draft-80-px" },
  },
  f82: {
    element: "span",
    style: { fontSize: "82px" },
    attributes: { class: "draft-82-px" },
  },
  f84: {
    element: "span",
    style: { fontSize: "84px" },
    attributes: { class: "draft-84-px" },
  },
  f88: {
    element: "span",
    style: { fontSize: "88px" },
    attributes: { class: "draft-88-px" },
  },
  f90: {
    element: "span",
    style: { fontSize: "90px" },
    attributes: { class: "draft-90-px" },
  },
  f92: {
    element: "span",
    style: { fontSize: "92px" },
    attributes: { class: "draft-92-px" },
  },
  f94: {
    element: "span",
    style: { fontSize: "94px" },
    attributes: { class: "draft-94-px" },
  },
  f96: {
    element: "span",
    style: { fontSize: "96px" },
    attributes: { class: "draft-96-px" },
  },
  f98: {
    element: "span",
    style: { fontSize: "98px" },
    attributes: { class: "draft-98-px" },
  },
  f100: {
    element: "span",
    style: { fontSize: "100px" },
    attributes: { class: "draft-100-px" },
  },
};

export const COLOR_TYPES = {
  premium: {
    backgroundColor: "#faed27",
  },
  f8: { fontSize: 8 },
  f9: { fontSize: 9 },
  f10: { fontSize: 10 },
  f11: { fontSize: 11 },
  f12: { fontSize: 12 },
  f14: { fontSize: 14 },
  f16: { fontSize: 16 },
  f18: { fontSize: 18 },
  f20: { fontSize: 20 },
  f22: { fontSize: 22 },
  f24: { fontSize: 24 },
  f26: { fontSize: 26 },
  f28: { fontSize: 28 },
  f30: { fontSize: 30 },
  f32: { fontSize: 32 },
  f34: { fontSize: 34 },
  f36: { fontSize: 36 },
  f38: { fontSize: 38 },
  f40: { fontSize: 40 },
  f42: { fontSize: 42 },
  f44: { fontSize: 44 },
  f46: { fontSize: 46 },
  f48: { fontSize: 48 },
  f50: { fontSize: 50 },
  f52: { fontSize: 52 },
  f54: { fontSize: 54 },
  f56: { fontSize: 56 },
  f58: { fontSize: 58 },
  f60: { fontSize: 60 },
  f62: { fontSize: 62 },
  f64: { fontSize: 64 },
  f66: { fontSize: 66 },
  f68: { fontSize: 68 },
  f70: { fontSize: 70 },
  f72: { fontSize: 72 },
  f74: { fontSize: 74 },
  f76: { fontSize: 76 },
  f78: { fontSize: 78 },
  f80: { fontSize: 80 },
  f82: { fontSize: 82 },
  f84: { fontSize: 84 },
  f86: { fontSize: 86 },
  f88: { fontSize: 88 },
  f90: { fontSize: 90 },
  f92: { fontSize: 92 },
  f94: { fontSize: 94 },
  f96: { fontSize: 96 },
  f98: { fontSize: 98 },
  f100: { fontSize: 100 },
  // f24: { fontSize: 24 },
  // f30: { fontSize: 36 },
  // f48: { fontSize: 48 },
  // f60: { fontSize: 60 },
  // f72: { fontSize: 72 },
  // f96: { fontSize: 96 },
};

export const ACTION_TYPES = [{ label: "$ Monetize", style: "premium" }];

export const HEADER_TYPES = [
  { label: "(None)", style: "unstyled" },
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  { label: "H4", style: "header-four" },
  { label: "H5", style: "header-five" },
  { label: "H6", style: "header-six" },
];

export const BLOCK_TYPES = [
  // { label: "align-left", icon: <AlignLeftIcon />, style: "left" },
  // { label: "align-center", icon: <AlignCenterIcon />, style: "center" },
  // { label: "align-right", icon: <AlignRightIcon />, style: "right" },
  {
    label: "ordered-list-item",
    icon: <OrderListIcon />,
    style: "ordered-list-item",
  },
  {
    label: "unordered-list-item",
    icon: <UnorderListIcon />,
    style: "unordered-list-item",
  },
];

export const INLINE_STYLES = [
  { label: "bold", icon: <BoldIcon />, style: "BOLD" },
  { label: "italic", icon: <ItalicIcon />, style: "ITALIC" },
  { label: "underline", icon: <UnderlineIcon />, style: "UNDERLINE" },
];
