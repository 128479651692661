import BasicButton from "../../../../../../../components/buttons/basic-button";
import EditUploadedFile from "./components/edit";
import UploadFile from "./components/upload";
export default function FileUploaderBox(props) {
  const { canUpload } = props;
  const { processing } = props;
  const { insertImage } = props;
  const { uploadType } = props;
  const { imageFileSelected } = props;
  const { currEditingImagePreview } = props;
  const { resetImagePreview } = props;
  const { handleImageLinkChange } = props;
  const { handleImagePreview } = props;
  const { createImageUploadMode } = props;
  const { value } = props;

  const showUploadBotton = () => {
    return (
      imageFileSelected || currEditingImagePreview || uploadType == "upload"
    );
  };

  return (
    <>
      {createImageUploadMode && uploadType == "upload" && (
        <div className="flex flex-col items-center justify-center w-full">
          <div className="w-full bg-gray-50 p-2 border">
            <UploadFile
              handleImagePreview={handleImagePreview}
              handleImageLinkChange={handleImageLinkChange}
              resetImagePreview={resetImagePreview}
              imageFileSelected={imageFileSelected}
              uploadType={uploadType}
              insertImage={insertImage}
              value={value}
            />
          </div>
        </div>
      )}

      {!createImageUploadMode && (
        <div className="flex flex-col items-center justify-center w-full">
          <div className="w-full bg-gray-50 p-2 border">
            <EditUploadedFile
              handleImagePreview={handleImagePreview}
              imageFileSelected={imageFileSelected}
              currEditingImagePreview={currEditingImagePreview}
              resetImagePreview={resetImagePreview}
              handleImageLinkChange={handleImageLinkChange}
              value={value}
            />{" "}
          </div>
        </div>
      )}

      {showUploadBotton() && (
        <div className="w-full my-4">
          {" "}
          <BasicButton
            disabled={canUpload()}
            title={processing ? "Uploading..." : "Upload"}
            classes="px-8 w-8/12"
            handleClick={() => insertImage()}
          />
        </div>
      )}
    </>
  );
}
