import { useSupplierApplicationsStore } from "../../../../../../stores/supplier-applications-store";
import { isResponsiveMode } from "../../../../../../Utils/helpers";
import Checkbox from "../../../../../../components/inputs/checkbox";
import BasicButton from "../../../../../../components/buttons/basic-button";
import { useNavigate } from "react-router-dom";

export default function MobileTable(props) {
  const navigate = useNavigate();

  const { openCommentModal, processing } = props;

  const mailStore = useSupplierApplicationsStore((state) => state);

  return (
    <div class="w-full flex flex-col justify-center my-4 text-center">
      <div
        className="overflow-x-scroll inline"
        style={{
          width: document.documentElement.clientWidth - 90 + "px",
          margin: "0 auto",
        }}
      >
        {mailStore.data.data &&
          mailStore.data.data.map((application) => {
            return (
              <div class="flex">
                <div
                  className="overflow-x-scroll inline"
                  style={{
                    width: document.documentElement.clientWidth - 90 + "px",
                    margin: "0 auto",
                  }}
                >
                  <div className="flex">
                    {" "}
                    <div class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold">Date</h3>
                      <p>
                        {application.refactoredCreatedAtTime
                          ? new Date(
                              application.refactoredCreatedAtTime.replace(
                                /-/g,
                                "/"
                              )
                            ).getDate() +
                            "-" +
                            (new Date(
                              application.refactoredCreatedAtTime.replace(
                                /-/g,
                                "/"
                              )
                            ).getMonth() +
                              1) +
                            "-" +
                            new Date(
                              application.refactoredCreatedAtTime.replace(
                                /-/g,
                                "/"
                              )
                            ).getFullYear()
                          : "N/A"}
                      </p>
                    </div>
                    <div class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold">Name</h3>
                      {application.user.name}
                    </div>
                    <div class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold">User Handle</h3>
                      {application.user.handle_id}
                    </div>
                    <div class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold">Email</h3>
                      {application.user.email}
                    </div>
                    <div class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold">Phone</h3>
                      {application.user?.phone?.phone || "N/A"}
                    </div>
                    <div class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold">Country</h3>
                      {application.user.country}
                    </div>
                    <div class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold">Application</h3>
                      <BasicButton
                        disabled={!application?.user?.uuid}
                        title={"View"}
                        classes={"my-bg-yellow-500 text-black"}
                        handleClick={() =>
                          navigate(
                            `/admin/supplier/biz-relocation/applicant/${application.user.uuid}`
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
