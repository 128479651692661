import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import AdInsertModal from "../../../modals/ad-insert-modal";
import AdEditModal from "../../../modals/ad-edit-modal";
import InformationPageModal from "../../../modals/information-page-modal";
import WebHeader from "../header";
import {
  API_BASE,
  BASE_URL,
  authUserData,
  isAuthUser,
} from "../../../Utils/helpers";

export default function WebReaderHome() {
  const [user, setUser] = useState();

  const getUser = () => {
    const url = API_BASE + "/api/user/" + authUserData().uuid;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setUser(response.data.user);
        }
      })
      .catch((error) => {
        // alert(error.message);
        console.error("There was an error!", error);
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <div className="main flex flex-col">
        <div className="border border-b-gray border-t-white border-r-white border-l-white m-2 p-2 flex justify-between rounded-lg flex-1">
          <div>
            <h1 className="text-2xl text-center m-2 bold">Purchases</h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="grid content-start gap-4 p-2 flex-1">
          <div className="grid md:grid-cols-6 lg:grid-cols-8 justify-center gap-4 p-2">
            {user &&
              user.payments.map((payment, i) => {
                return (
                  <>
                    <div
                      className="md:col-span-2 p-6 bg-white rounded-lg cursor-pointer"
                      onClick={() => {
                        document.location.href = `/web/book/${payment.book.book_uuid}`;
                      }}
                    >
                      <img
                        className="w-full shadow"
                        src={
                          BASE_URL +
                          "/storage/" +
                          payment.book.cover_photo.split("public")[1]
                        }
                      />{" "}
                      <div className="text-center py-2">
                        {" "}
                        <p className="font-bold text-2xl text-black">
                          {payment.book.name}
                        </p>
                        <p> by</p>
                        <p className="text-gray-600 text-black">
                          <span className="text-green-500 font-bold">
                            {payment.book.author}
                          </span>
                        </p>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        {!user ||
          (user.payments < 1 && (
            <div
              className="w-full  flex justify-center  md:h-96 items-center flex-1"
              style={{ minHeigt: "100%" }}
            >
              <p className="text-white">We found 0 books</p>
            </div>
          ))}
      </div>
    </>
  );
}
