import axios from "axios";
import { useEffect, useState } from "react";
import Pagination from "../../../components/pagination";
import BasicButton from "../../../components/buttons/basic-button";
import AdminDesktopSystemSubTable from "./components/desktop-table";
import AdminMobileSystemSubTable from "./components/mobile-table";
import LoadingProgress from "../../publisher/rich-page/components/loading-progress";
import useDebounce from "../../../components/debounce/debounce-helper";
import TextField from "../../../components/inputs/text-input";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";
import DeletePromptModal from "../../../modals/delete-warning-modal";
import {
  isAdmin,
  API_BASE,
  isAuthUser,
  isResponsiveMode,
  getLocation,
} from "../../../Utils/helpers";
import { toast } from "react-toastify";

export default function AdminSystemSubscribers() {
  const [subPlans, setSubPlans] = useState({});
  const [searchText, setSearchText] = useState("");
  const [countPerPage, setCountPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [currSelSub, setCurrSelSub] = useState({});
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const getSubcriptionPlans = () => {
    const url =
      API_BASE +
      "/admin/system/subscriptions/" +
      countPerPage +
      "/" +
      searchText;
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setSubPlans(response.data.subscriptions);
        }
        setProcessing(false);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const cancelSubscriptionPlan = (id) => {
    const url = API_BASE + `/sponsor/system/subscription/plan/${id}/cancel`;
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((response) => {
        toast("Cancelled", { type: "success" });
        closeCancelModal();
        setCurrSelSub({});
        setProcessing(false);
        getSubcriptionPlans();
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const deleteSubscription = (id) => {
    const url = API_BASE + `/admin/system/subscription/plan/${id}/delete`;
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((response) => {
        toast("Deleted", { type: "success" });
        closeDeleteModal();
        setCurrSelSub({});
        setProcessing(false);
        getSubcriptionPlans();
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const doPagination = (page) => {
    setProcessing(true);
    if (subPlans.first_page_url) {
      setCurrentPage(page);
      axios
        .get(subPlans.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          setSubPlans(res.data.subscriptions);
          setProcessing(false);
        })
        .catch((err) => {
          setProcessing(false);
          toast("Something went wrong", { type: "error" });
        });
    }
  };

  useEffect(() => {
    getSubcriptionPlans();
    if (!isAdmin()) {
      window.location.href = "/";
    }
  }, []);

  const handleSearchTextChange = (e) => {
    setSearchText(e);
  };

  const handleCountPerPageChange = (e) => {
    setCountPerPage(e.target.value);
  };

  const closeCancelModal = () => {
    setCancelModalOpen(false);
  };

  const openCancelModal = (d) => {
    setCurrSelSub(d);
    setCancelModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const openDeleteModal = (d) => {
    setCurrSelSub(d);
    setDeleteModalOpen(true);
  };

  const copyText = (text) => {
    // standard way of copying
    var textArea = document.createElement("textarea");
    textArea.setAttribute("style", "width:1px;border:0;opacity:0;");
    document.body.appendChild(textArea);
    textArea.value = text;
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    toast("Link Copied", { type: "success" });
  };

  const copyLink = (id) => {
    if (getLocation().hostname == "litzapp.com") {
      copyText("https://litzapp.com/sponsor/payment/" + id);
    } else {
      copyText("localhost:3000/sponsor/payment/" + id);
    }
  };

  useDebounce(
    () => {
      setSubPlans({});
      getSubcriptionPlans();
    },
    [searchText, countPerPage],
    800
  );

  const f = {
    openCancelModal: openCancelModal,
    copyLink: copyLink,
    openDeleteModal: openDeleteModal,
  };

  return (
    <>
      <div className="main w-full">
        <div className="bg-white m-2 p-2 flex justify-between shadow px-4">
          <div>
            <h1 className="text-2xl text-center m-2 bold">
              System Subscribers
            </h1>
          </div>
          <div>
            <a href="/admin/system/subscriber/create">
              <BasicButton title={"Create Subscriber"} handleClick={null} />
            </a>
          </div>
        </div>
        <div className="flex justify-center my-4 overflow-hidden">
          <div class="flex flex-col items-center p-10 w-full">
            <p className="w-full text-center my-4">
              We found {subPlans.total ? subPlans.total : ""} records
            </p>
            <div className="flex w-full gap-4">
              <div className="w-11/12">
                <TextField
                  title="Search"
                  placeholder={"Search"}
                  classes="rounded-lg"
                  handleChange={handleSearchTextChange}
                  value={searchText}
                />
              </div>

              <div className="w-1/12">
                <label for="timezone" value="Your Timezone" />
                <select
                  onChange={handleCountPerPageChange}
                  required
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full text-black"
                >
                  <option value="10" default>
                    10
                  </option>
                  {/* <option value="2">2</option> */}
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <div className="flex justify-center">
              {subPlans.data ? (
                subPlans.data.length ? (
                  <Pagination
                    pagination={subPlans}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>

            {isResponsiveMode() ? (
              <AdminMobileSystemSubTable data={subPlans} f={f} />
            ) : (
              <AdminDesktopSystemSubTable data={subPlans} f={f} />
            )}

            <LoadingProgress loading={processing} />

            <div className="flex justify-center">
              {subPlans.data ? (
                subPlans.data.length ? (
                  <Pagination
                    pagination={subPlans}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      {currSelSub.id ? (
        <DeletePromptModal
          modalOpen={cancelModalOpen}
          hideAdModal={closeCancelModal}
          title={"Are you sure you want to cancel?"}
          yesActionText={"Yes, Delete"}
          noActionText={"No, Cancel"}
          action={() => cancelSubscriptionPlan(currSelSub.uuid)}
          processing={processing}
        />
      ) : (
        ""
      )}

      {currSelSub.id ? (
        <DeletePromptModal
          modalOpen={deleteModalOpen}
          hideAdModal={closeDeleteModal}
          title={"Are you sure you want to delete ?"}
          yesActionText={"Yes, Delete"}
          noActionText={"No, Cancel"}
          action={() => deleteSubscription(currSelSub.uuid)}
          processing={processing}
        />
      ) : (
        ""
      )}
    </>
  );
}
