export default function Filter(props) {
  const {
    selYear,
    handleYearChange,
    selMonth,
    handleMonthChange,
    metaType,
    handleMetaTypeChange,
    productsForFilter,
    monthNames,
  } = props;
  return (
    <div className="w-full flexflex-col">
      <div className="w-full flex flex-col gap-2">
        <div className="flex flex-row gap-2">
          <div className="flex flex-col gap-4 w-full md:w-3/12">
            <div className="flex flex-col items-center">
              <label for="timezone" value="Your Timezone">
                Year
              </label>
              <select
                value={selYear}
                onChange={handleYearChange}
                required
                class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
              >
                <option value="*" defaultValue disabled>
                  Select
                </option>
                <option value="2024">2024</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col gap-4 w-full md:w-3/12">
            <div className="flex flex-col items-center">
              <label for="timezone" value="Your Timezone">
                Month
              </label>
              <select
                value={selMonth}
                onChange={handleMonthChange}
                required
                class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
              >
                <option value="*" defaultValue disabled>
                  Select
                </option>
                {monthNames.map((m) => (
                  <option value={m}>{m}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div className="flex flex-col gap-4 w-full md:w-6/12">
            <div className="flex flex-col items-center">
              <label for="timezone" value="Your Timezone"></label>
              <select
                value={metaType}
                onChange={handleMetaTypeChange}
                required
                class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
              >
                <option value="*" defaultValue>
                  All
                </option>
                {productsForFilter.length &&
                  productsForFilter.map((m) => (
                    <option value={m.id}>{m.book_data.name}</option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div>
        <p>Number Format (# | # | #): </p>
        <p>
          {" "}
          The First Number # is the Quantity of Items that you entered in the
          purchase button for that product page.
        </p>
        <p>
          The Second Number # is the Subscription Count that exists on that
          product page for that particular day.
        </p>
        <p>
          The Third Number # is the Number of Items that are Available for
          booking on that particular day. Click each date cell for a more
          detailed view of the subscriptions for that particular day.
        </p>
        <p> Click each product cell to view the individual Product Calendar</p>
      </div>
    </div>
  );
}
