import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import TextField from "../../../../components/inputs/text-input";
import BasicButton from "../../../../components/buttons/basic-button";
import axios from "axios";
import { toast } from "react-toastify";
import TextArea from "../../../../components/inputs/text-area";
import {
  isAdmin,
  API_BASE,
  isPublisher,
  BASE_URL,
  isAuthUser,
} from "../../../../Utils/helpers";

export default function ProfileText({ userId }) {
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [publisherId, setPublisherId] = useState("");
  const [fileSelected, setFileSelected] = useState("");
  const [processing, setProcessing] = useState(false);
  const [published, setPublished] = useState(false);
  const [profileText, setProfileText] = useState("");
  const [publisher, setPublisher] = useState({});
  const [shouldDeleteCoverPhoto, setShouldDeleteCoverPhoto] = useState(0);

  let { type } = useParams();
  const previewImageRef = useRef();

  const getPublisher = () => {
    let url = API_BASE + "/api/publisher/" + userId;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setPublisher(response.data.publisher);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const updateUser = () => {
    const url = API_BASE + "/api/publisher/update";
    setProcessing(true);
    let formData = new FormData();
    formData.append("profile_text", profileText);
    formData.append("user_id", userId);
    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          toast("Updated!", {
            type: "success",
          });
        }
      })
      .catch((error) => {
        toast("Something went wrong!", {
          type: "error",
        });
        setProcessing(false);
        // toast("Something went wrong!", { type: "error" });
      });
  };

  const handleProfileTextChange = (text) => {
    setProfileText(text);
  };

  useEffect(() => {
    setPublisherId(userId);
    if (!isAuthUser()) {
      window.location.href = "/";
    }
    getPublisher();
  }, []);

  useEffect(() => {
    if (publisher.name) {
      setProfileText(publisher.profile_text);
      if (publisher.profile_photo)
        setFileSelected(
          BASE_URL + "/storage" + publisher.profile_photo.split("public")[1]
        );
    }
  }, [publisher]);

  useEffect(() => {
    if (profileText) {
      // console.log("editor text", profileText);
    }
  }, [profileText]);

  return (
    <>
      <div className="my-0">
        <TextArea
          classes={"p-6 my-2 w-full"}
          placeholder={"Enter Text.."}
          type="text"
          handleChange={handleProfileTextChange}
          value={profileText}
        />
        <div className="my-0 text-center">
          <BasicButton
            disabled={!profileText || processing}
            title={processing ? "Updating.." : "Update"}
            classes={"p-6 w-9/12 mt-4"}
            handleClick={updateUser}
          />
        </div>
      </div>
    </>
  );
}
