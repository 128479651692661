import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BasicButton from "../../../../../components/buttons/basic-button";
import LoadingProgress from "../../../../publisher/rich-page/components/loading-progress";
import { useSupplierApplicationsStore } from "../../../../../stores/supplier-applications-store";
import authRequestService from "../../../../../services/HttpRequests/authenticated-request-services";
import DesktopTable from "./desktop-table";
import MobileTable from "./mobile-table";

import {
  isAdmin,
  API_BASE,
  BASE_URL,
  isResponsiveMode,
  authUserData,
} from "../../../../../Utils/helpers";
import { toast } from "react-toastify";
export default function AdminSupplierProfile() {
  const [processing, setProcessing] = useState(false);
  const { user_id } = useParams();

  const navigate = useNavigate();
  const mailStore = useSupplierApplicationsStore((state) => state);

  const downloadVideo = (video, userHandle) => {
    axios({
      url: BASE_URL + "/storage" + video.split("public")[1],
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fURL = document.createElement("a");

        fURL.href = fileURL;
        fURL.setAttribute("download", userHandle + "." + video.split(".")[1]);
        document.body.appendChild(fURL);

        fURL.click();
      })
      .catch(() => {
        console.log("handle server error from here");
      });
  };

  const getApplicant = () => {
    mailStore.setData([]);
    setProcessing(true);
    const url =
      API_BASE + `/admin/supplier/applicant/${user_id}/application/retrieve`;
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          mailStore.setApplicationData(response.data.application);
          setProcessing(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const disableAccount = () => {
    setProcessing(true);
    const url = API_BASE + `/admin/supplier/application/disable-account`;
    authRequestService()
      .post(url, {
        user_id: user_id,
      })
      .then((response) => {
        if (response.status == 200) {
          setProcessing(false);
          getApplicant();
          mailStore.setSelectedApplicantStatus("");
          toast("Account Disabled", { type: "success" });
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };
  const enableAccount = () => {
    setProcessing(true);
    const url = API_BASE + `/admin/supplier/application/enable-account`;
    authRequestService()
      .post(url, {
        user_id: user_id,
      })
      .then((response) => {
        if (response.status == 200) {
          setProcessing(false);
          getApplicant();
          mailStore.setSelectedApplicantStatus("");
          toast("Account Enabled", { type: "success" });
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
    getApplicant();
  }, []);

  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">Profile Page</h1>
          </div>
          <div className="flex justify-between gap-2 items-start">
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            {isResponsiveMode() ? (
              <MobileTable
                processing={processing}
                disableAccount={disableAccount}
                enableAccount={enableAccount}
                downloadVideo={downloadVideo}
              />
            ) : (
              <DesktopTable
                processing={processing}
                disableAccount={disableAccount}
                enableAccount={enableAccount}
                downloadVideo={downloadVideo}
              />
            )}
            <LoadingProgress loading={processing} />
          </div>
        </div>
      </div>
    </>
  );
}
