import { useSendMailStore } from "../../../../../stores/send-mail-store";
import { isResponsiveMode } from "../../../../../Utils/helpers";
import BasicButton from "../../../../../components/buttons/basic-button";

export default function MobileTable(props) {
  const mailStore = useSendMailStore((state) => state);

  const { blockedEmails } = props;
  const { shouldMarkItem } = props;
  const { markItem } = props;

  return (
    <div class="w-full flex flex-col justify-center my-4 text-center">
      {mailStore.data.data &&
        mailStore.data.data.map((d) => {
          return (
            <div
              className="overflow-x-scroll inline"
              style={{
                width: document.documentElement.clientWidth - 90 + "px",
                margin: "0 auto",
              }}
            >
              <div className="flex">
                <div class="border  px-4 py-2  font-medium">
                  <div className="text-center md:text-left">
                    <p className="break-words">
                      <p className="text-green-500"> First Name</p>
                      <small className="text-base"> {d.first_name}</small>
                    </p>
                    <p className="break-words">
                      <p className="text-green-500"> Last Name</p>
                      <small className="text-base"> {d.last_name}</small>
                    </p>
                    <p className="break-words">
                      <p className="text-green-500"> Username</p>
                      <small className="text-base"> {d.username}</small>
                    </p>
                    <p className="break-words">
                      <p className="text-green-500"> User Handle</p>
                      <small className="text-base"> {d.handle_id}</small>
                    </p>
                  </div>
                </div>
                <div class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Status</h3>
                  {d?.reg_stage}
                </div>
                <div class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Creation Type</h3>
                  {d?.sales_supplier_creation_type}
                </div>

                <div class="border  px-4 py-2  font-medium">
                  <BasicButton
                    classes={"bg-black-500 hover:bg-black-500 w-full py-6"}
                    title={"View Application"}
                    handleClick={() =>
                      (window.location.href = `/sales/supplier/${d?.uuid}/supplier-application`)
                    }
                  />
                </div>
                <div class="border  px-4 py-2  font-medium">
                  <h3 className="font-bold md:hidden">Date</h3>
                  <p>{d?.refactoredCreatedAtTime}</p>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}
