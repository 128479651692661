import { IdentityOptions } from "./identity-options-list";
import { useState, useEffect } from "react";
import { useRef } from "react";
export default function SupplierIDProfileVerification(props) {
  const { verification, profileOption, handleProfileOptionChange } = props;

  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [savingStage, setSavingStage] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    setQuery(() => "");
    handleProfileOptionChange(option["name"]);
    setIsOpen((isOpen) => !isOpen);
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (profileOption) return profileOption;

    return "";
  };

  const filter = (options) => {
    return options.filter(
      (option) => option["name"].toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  };

  return (
    <div className="my-identity dropdown">
      <div className="control">
        <div className="selected-value">
          <input
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
              handleProfileOptionChange(null);
            }}
            onClick={toggle}
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
      </div>

      <div className={`options ${isOpen ? "open" : ""}`}>
        {filter(IdentityOptions).map((option, index) => {
          return (
            <div
              onClick={() => selectOption(option)}
              className={`option ${
                option["name"] === profileOption ? "selected" : ""
              }`}
              key={`${"id"}-${index}`}
            >
              {option["name"]}
            </div>
          );
        })}
      </div>
    </div>
  );
}
