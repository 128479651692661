export const WEEK_DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const SUB_TYPES = [
  { label: "Vacation Rental" },
  { label: "Hire" },
  { label: "Rent" },
  { label: "Subscription" },
  { label: "Lease" },
];

export const SUB_DURATIONS = ["Monthly", "Fortnightly", "Weekly", "Daily"];
