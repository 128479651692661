import BasicButton from "../../../../components/buttons/basic-button";
import { BASE_URL } from "../../../../Utils/helpers";
import { useState } from "react";

export default function AdminMobileSystemSubTable({ data, f }) {
  const { openCancelModal, copyLink, openDeleteModal } = f;
  return (
    <>
      <div className="w-full flex flex-col justify-center my-4 text-center">
        {data.data
          ? data.data.map((p, i) => {
              return (
                <div
                  className="overflow-x-scroll inline"
                  style={{
                    width: document.documentElement.clientWidth - 90 + "px",
                    margin: "0 auto",
                  }}
                >
                  <div className="flex">
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">Title</h3>
                      {p.title}
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">
                        Description
                      </h3>
                      {p.description}
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">Email</h3>
                      {p.user.email}
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">Price</h3>
                      {p.lateststripeprice.unit_amount}
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">
                        Start Date
                      </h3>
                      {p.stripesubscription?.refactoredStartTime || "N/A"}
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">End Date</h3>
                      {p.stripesubscription?.refactoredEndTime || "N/A"}
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">
                        Copy Link
                      </h3>
                      <BasicButton
                        title={"Copy Link"}
                        classes={"bg-teal-500 text-white"}
                        handleClick={() => copyLink(p.uuid)}
                      />
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">Update</h3>
                      <a href={`/admin/system/subscriber/edit/${p.uuid}`}>
                        <BasicButton
                          title={"Update"}
                          classes={"bg-teal-500 text-white"}
                          handleClick={() => null}
                        />
                      </a>
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">Cancel</h3>
                      <BasicButton
                        disabled={p?.stripesubscription?.status !== "active"}
                        title={"Cancel"}
                        classes={"bg-red-500 text-white"}
                        handleClick={() =>
                          openCancelModal(p.stripesubscription)
                        }
                      />
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">Delete</h3>
                      <BasicButton
                        disabled={
                          p?.stripesubscription?.status == "active" ||
                          p?.stripesubscription?.status == "cancelled"
                        }
                        title={"Delete"}
                        classes={"bg-red-500 text-white"}
                        handleClick={() => openDeleteModal(p)}
                      />
                    </div>
                    <div class="border  px-4 py-2  font-medium text-center md:text-left">
                      <h3 className="font-bold md:hidden text-xxs">Status</h3>
                      {(p?.stripesubscription?.status &&
                        p?.stripesubscription?.status == "processing" &&
                        "N/A") ||
                        p?.stripesubscription?.status ||
                        "N/A"}
                    </div>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    </>
  );
}
