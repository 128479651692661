import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import StripeRedirectModal from "../../../modals/stripe-redirect-modal";
import StripeRedirectModeModal from "../../../modals/stripe-redirect-mode-modal";
import DeletePromptModal from "../../../modals/delete-warning-modal";
import Pagination from "../../../components/pagination";
import AdminAdInsertModal from "../../../modals/admin-ad-insert-modal";
import useDebounce from "../../../components/debounce/debounce-helper";

import {
  isAdmin,
  API_BASE,
  isPublisher,
  getLocation,
  authUserData,
  isAuthUser,
  getClientBaseURL,
} from "../../../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
import Switch from "react-switch";
import PasswordPromptModal from "../../../modals/password-prompt-modal";
import CreateTierModal from "../../../modals/create-tier-modal";
export default function AdminAdvertRequests() {
  const [processing, setProcessing] = useState(false);
  const [rejectRequestModalOpen, setRejectRequestModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [adRequests, setAdRequests] = useState([]);
  const [currRequestToReject, setCurrRequestToReject] = useState("");
  const [currReq, setCurrReq] = useState({});
  const [showAdModal, setShowAdModal] = useState(false);
  const [pAd1Code, setPAd1Code] = useState("");
  const [sAd1Code, setSAd1Code] = useState("");
  const [pAd2Code, setPAd2Code] = useState("");
  const [sAd2Code, setSAd2Code] = useState("");

  const [pAd21APICode, setPAd21APICode] = useState("");
  const [sAd22APICode, setSAd22APICode] = useState("");

  const [advertModalMode, setAdvertModalMode] = useState("PROCESS");
  const [provToSwitch, setProvToSwitch] = useState("");

  const [defaultProvider, setDefaultProvider] = useState("");

  const [currentEditingReqId, setcurrentEditingReqId] = useState("");
  const [countPerPage, setCountPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");

  const { requestType } = useParams();
  const hideRejectPromtModal = () => {
    setRejectRequestModalOpen(false);
  };

  const showRejectPromtModal = (id) => {
    setCurrRequestToReject(id);
    setRejectRequestModalOpen(true);
  };

  const processRequest = () => {
    let url = API_BASE + "/api/advert-request/process";
    // validateAdvertFormInput();
    axios
      .post(url, {
        req_id: currReq.uuid,
        ad_code: pAd1Code,
        system_ad_code: sAd1Code,
        ad_2_code: pAd2Code,
        system_ad_2_code: sAd2Code,
        provider: defaultProvider,
      })
      .then((response) => {
        if (response.status == 200) {
          toast("Updated!", { type: "success" });
          getAdReqWithFilters();
          hideAdBox();
        }
      })
      .catch((error) => {
        if (error.response.status == 422) {
          if (
            "duplicate_publisher_adsense_ad_code" == error.response.data.message
          ) {
            toast("Duplicate Publisher Adsense Code!", { type: "error" });
          }

          if (
            "duplicate_litzapp_adsense_ad_code" == error.response.data.message
          ) {
            toast("Duplicate Litzapp Adsense Code!", { type: "error" });
          }
          if (
            "duplicate_publisher_adsterra_ad_code" ==
            error.response.data.message
          ) {
            toast("Duplicate Publisher Adcash Code!", { type: "error" });
          }

          if (
            "duplicate_litzapp_adsterra_ad_code" == error.response.data.message
          ) {
            toast("Duplicate Litzapp Adcash Code!", { type: "error" });
          }

          if (
            "duplicate_publisher_adsterra_api_id" == error.response.data.message
          ) {
            toast("Duplicate Publisher Adcash API Code!", { type: "error" });
          }
          if (
            "duplicate_litzapp_adsterra_api_id" == error.response.data.message
          ) {
            toast("Duplicate Litzapp Adcash API Code!", { type: "error" });
          }
          if ("reserved_adsense_ad_code" == error.response.data.message) {
            toast("7381475967 is reserved for litzapp adsense general use!", {
              type: "error",
            });
          }
          if ("reserved_adcash_ad_code" == error.response.data.message) {
            toast("7280370 is reserved for litzapp adcash general use!", {
              type: "error",
            });
          }
        } else {
          toast("Something went wrong!", { type: "error" });
        }
      });
  };

  const processReqOnceApproved = (req) => {
    let url = API_BASE + "/api/advert-request/process";
    axios
      .post(url, {
        req_id: req.uuid,
        ad_code: req.ad_code,
        sytem_ad_code: req.ad_code,
      })
      .then((response) => {
        if (response.status == 200) {
          toast("Updated!", { type: "success" });
          getAdReqWithFilters();
          hideAdBox();
        }
      })
      .catch((error) => {
        toast("Duplicate System Ad Code!", { type: "error" });
      });
  };

  const setSearchString = () => {
    if (!searchText || searchText == "") {
      return "none_search_xyz";
    }
    return searchText;
  };

  const getAdReqWithFilters = () => {
    let url =
      API_BASE +
      "/api/advert-requests/" +
      requestType +
      "/" +
      countPerPage +
      "/" +
      setSearchString();
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setAdRequests(response.data.advert_requests);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const rejectAdvertRequest = () => {
    let url =
      API_BASE + "/api/advert-request/reject/" + currRequestToReject.uuid;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          toast("Rejected!", { type: "success" });
          getAdReqWithFilters();
          hideRejectPromtModal();
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const doPagination = (page) => {
    if (adRequests.first_page_url) {
      setCurrentPage(page);
      axios
        .get(adRequests.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          setAdRequests(res.data.advert_requests);
        })
        .catch((err) => console.log(err));
    }
  };

  const showAdBox = (req) => {
    if (req.ad_code) {
      processReqOnceApproved(req);
    } else {
      setShowAdModal(true);
      setCurrReq(req);
      setcurrentEditingReqId(req.uuid);
    }
  };

  const hideAdBox = () => {
    setShowAdModal(false);
    setcurrentEditingReqId("");
    setCurrReq("");
    setPAd1Code("");
    setSAd1Code("");
    setPAd2Code("");
    setSAd2Code("");
    setPAd21APICode("");
    setSAd22APICode("");
  };

  const pAd1CodeChange = (e) => {
    setPAd1Code(e);
  };
  const sAd1CodeChange = (e) => {
    setSAd1Code(e);
  };
  const pAd2CodeChange = (e) => {
    setPAd2Code(e);
  };
  const sAd2CodeChange = (e) => {
    setSAd2Code(e);
  };
  const pAd21APICodeChange = (e) => {
    setPAd21APICode(e);
  };
  const sAd22APICodeChange = (e) => {
    setSAd22APICode(e);
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e);
  };

  const handleCountPerPageChange = (e) => {
    setCountPerPage(e.target.value);
  };

  const validateAdvertFormInput = () => {
    if (!pAd1Code && !sAd1Code && defaultProvider !== "ADSENSE") {
      toast(
        "Please enter Adsense ad codes for publisher and Litzapp to use Adsense as default provider!",
        { type: "notice" }
      );
      return false;
    }

    if (!pAd2Code && !sAd2Code && defaultProvider !== "ADSTERRA") {
      toast(
        "Please enter Adcash ad codes for publisher and Litzapp to use Adcash as default provider!",
        { type: "notice" }
      );
      return false;
    }

    return true;
  };

  const onProviderChange = (e) => {
    if (!pAd1Code && !sAd1Code && e.target.value == "ADSENSE") {
      toast(
        "Please enter Adsense ad codes for publisher and Litzapp to continue!",
        { type: "notice" }
      );
      return false;
    }

    if (!pAd2Code && !sAd2Code && e.target.value == "ADSTERRA") {
      toast(
        "Please enter Adcash ad codes for publisher and Litzapp to continue!",
        { type: "notice" }
      );
      return false;
    }

    setDefaultProvider(e.target.value);
  };

  const switchProvider = (req) => {
    setCurrReq(req);
    setAdvertModalMode("SWITCH");
    setShowAdModal(true);
    setcurrentEditingReqId(req.uuid);
  };

  const getStatSum = (a, b) => {
    if (a && !b) return a;
    if (b && !a) return b;
    if (b && a) return Number(a) + Number(b);
    return "N/A";
  };

  useDebounce(
    () => {
      getAdReqWithFilters();
    },
    [countPerPage, searchText],
    800
  );

  useEffect(() => {
    if (currReq.uuid && advertModalMode == "SWITCH") {
      setPAd1Code(currReq.ad_code || "");
      setSAd1Code(currReq.system_ad_code || "");
      setPAd2Code(currReq.ad_2_code || "");
      setSAd2Code(currReq.system_ad_2_code || "");
      setDefaultProvider(currReq.provider || "");
    }
  }, [currReq]);

  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
  }, []);
  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">
            Advert System ({requestType})
          </h1>
        </div>
        <div className="flex gap-2">
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>

      <div className="flex flex-col justify-center gap-4 mt-8 items-center mb-4">
        {/* <h1 className="font-bold text-xl">Storage Tiers</h1> */}

        <div className="w-full p-8 flex flex-col">
          <div className="flex  w-full gap-4">
            <div className="w-11/12">
              <TextField
                title="Search"
                placeholder={"Search"}
                classes="rounded-lg"
                handleChange={handleSearchTextChange}
                value={searchText}
              />
            </div>

            <div className="w-1/12">
              <label for="timezone" value="Your Timezone" />
              <select
                onChange={handleCountPerPageChange}
                required
                class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full"
              >
                <option value="10" default>
                  10
                </option>
                {/* <option value="2">2</option> */}
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
          <div className="hidden md:flex gap-2 justify-between w-full border-b p-4 text-sm font-bold">
            <div className="text-center w-full md:w-1/12">Name</div>
            <div className="text-center w-full md:w-1/12">Timezone</div>
            <div className="text-center w-full md:w-1/12">Date Requested</div>
            <div className="text-center w-full md:w-1/12">Phone</div>
            {requestType == "approved" || requestType == "rejected" ? (
              <>
                {" "}
                <div className="text-center w-full md:w-1/12">Adsense</div>
                <div className="text-center w-full md:w-1/12">Adcash</div>
                <div className="text-center w-full md:w-1/12">Summation</div>
                <div className="text-center w-full md:w-1/12">Est Earnings</div>
                <div className="text-center w-full md:w-1/12">
                  Active Provider
                </div>
              </>
            ) : (
              ""
            )}
            <div className="text-center w-full md:w-1/12">Action</div>
          </div>
          {adRequests.data
            ? adRequests.data.map((adRequest) => (
                <div className="gap-2 flex flex-col  md:flex md:flex-row justify-between w-full border-b p-4 bg-gray-50 mb-1">
                  {" "}
                  <div className="text-center w-full md:w-1/12">
                    <p className="font-bold">First Name</p>
                    {adRequest.user.first_name}
                    <p className="font-bold mt-2">Last Name</p>
                    {adRequest.user.last_name}
                    <p className="font-bold">User Handle</p>
                    {adRequest.user.handle_id}
                    <p className="font-bold mt-2">Email</p>
                    <p className="break-words w-full stext-center">
                      {adRequest.user.email}
                    </p>
                    <p className="font-bold mt-2">Country</p>
                    {adRequest.user.country ? adRequest.user.country : "N/A"}
                  </div>
                  <div className="text-center w-full md:w-1/12">
                    <p className="md:hidden font-bold">Timezone</p>
                    {adRequest.user.timezone ? adRequest.user.timezone : "N/A"}
                  </div>
                  <div className="text-center w-full md:w-1/12">
                    <p className="md:hidden font-bold">Date Requested</p>
                    {adRequest.created_at}
                  </div>
                  <div className="text-center w-full md:w-1/12">
                    <p className="md:hidden font-bold">Phone</p>
                    {adRequest.user.phone ? adRequest.user.phone : "N/A"}
                  </div>
                  {requestType == "approved" || requestType == "rejected" ? (
                    <>
                      <div className="text-center w-full md:w-1/12">
                        <p className="md:hidden font-bold">Adsense</p>

                        <p className="font-bold text-lg mb-2 text-teal-500">
                          Publisher
                        </p>

                        <p className="font-bold text-sm">CTI Ratio</p>
                        {adRequest.click_impressions_ratio
                          ? adRequest.click_impressions_ratio
                          : "N/A"}

                        <p className="font-bold text-sm">Clicks</p>
                        {adRequest.clicks ? adRequest.clicks : "N/A"}

                        <p className="font-bold text-sm">Impressions</p>
                        {adRequest.impressions ? adRequest.impressions : "N/A"}

                        <br />

                        <p className="font-bold text-lg mb-2 text-teal-500">
                          Litzapp
                        </p>

                        <p className="font-bold text-sm">Clicks</p>
                        {adRequest.system_clicks
                          ? adRequest.system_clicks
                          : "N/A"}

                        <p className="font-bold text-sm">Impressions</p>
                        {adRequest.system_impressions
                          ? adRequest.system_impressions
                          : "N/A"}

                        <p className="font-bold text-sm">CTI Ratio</p>
                        {adRequest.system_click_impressions_ratio
                          ? adRequest.system_click_impressions_ratio
                          : "N/A"}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {requestType == "approved" || requestType == "rejected" ? (
                    <>
                      <div className="text-center w-full md:w-1/12">
                        <p className="md:hidden font-bold">Adcash</p>

                        <p className="font-bold text-lg mb-2 text-teal-500">
                          Publisher
                        </p>

                        <p className="font-bold text-sm">CTI Ratio</p>
                        {adRequest.click_impressions_ratio_2
                          ? adRequest.click_impressions_ratio_2
                          : "N/A"}

                        <p className="font-bold text-sm">Clicks</p>
                        {adRequest.clicks_2 ? adRequest.clicks_2 : "N/A"}

                        <p className="font-bold text-sm">Impressions</p>
                        {adRequest.impressions_2
                          ? adRequest.impressions_2
                          : "N/A"}

                        <br />

                        <p className="font-bold text-lg mb-2 text-teal-500">
                          Litzapp
                        </p>

                        <p className="font-bold text-sm">Clicks</p>
                        {adRequest.system_clicks_2
                          ? adRequest.system_clicks_2
                          : "N/A"}

                        <p className="font-bold text-sm">Impressions</p>
                        {adRequest.system_impressions_2
                          ? adRequest.system_impressions_2
                          : "N/A"}

                        <p className="font-bold text-sm">CTI Ratio</p>
                        {adRequest.system_click_impressions_ratio_2
                          ? adRequest.system_click_impressions_ratio_2
                          : "N/A"}
                      </div>

                      <div className="text-center w-full md:w-1/12">
                        <p className="md:hidden font-bold">Summation</p>

                        <p className="font-bold text-lg mb-2 text-teal-500">
                          Publisher
                        </p>

                        <p className="font-bold text-sm">CTI Ratio</p>
                        {getStatSum(
                          adRequest.click_impressions_ratio,
                          adRequest.click_impressions_ratio_2
                        )}

                        <p className="font-bold text-sm">Clicks</p>
                        {getStatSum(adRequest.clicks, adRequest.clicks_2)}

                        <p className="font-bold text-sm">Impressions</p>
                        {getStatSum(
                          adRequest.impressions,
                          adRequest.impressions_2
                        )}

                        <br />

                        <p className="font-bold text-lg mb-2 text-teal-500">
                          Litzapp
                        </p>

                        <p className="font-bold text-sm">Clicks</p>
                        {getStatSum(
                          adRequest.system_clicks,
                          adRequest.system_clicks_2
                        )}

                        <p className="font-bold text-sm">Impressions</p>
                        {getStatSum(
                          adRequest.system_impressions,
                          adRequest.system_impressions_2
                        )}

                        <p className="font-bold text-sm">CTI Ratio</p>
                        {getStatSum(
                          adRequest.system_click_impressions_ratio,
                          adRequest.system_click_impressions_ratio_2
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {requestType == "approved" || requestType == "rejected" ? (
                    <>
                      <div className="text-center w-full md:w-1/12">
                        <p className="md:hidden font-bold">Est Earnings</p>

                        <p className="font-bold text-lg mb-2 text-teal-500">
                          Publisher
                        </p>
                        <p className="font-bold text-sm">Adsense </p>
                        {adRequest.total_estimated_earnings
                          ? adRequest.total_estimated_earnings
                          : "N/A"}
                        <p className="font-bold text-sm">Adcash </p>
                        {adRequest.total_estimated_earnings_2
                          ? adRequest.total_estimated_earnings_2
                          : "N/A"}

                        <p className="font-bold text-sm">Total</p>
                        {getStatSum(
                          adRequest.total_estimated_earnings,
                          adRequest.total_estimated_earnings_2
                        )}
                        <br />
                        <p className="font-bold text-lg mb-2 text-teal-500">
                          Litzapp
                        </p>
                        <p className="font-bold text-sm">Adsense</p>
                        {adRequest.system_total_estimated_earnings
                          ? adRequest.system_total_estimated_earnings
                          : "N/A"}
                        <p className="font-bold text-sm">Adcash</p>
                        {adRequest.system_total_estimated_earnings_2
                          ? adRequest.system_total_estimated_earnings_2
                          : "N/A"}
                        <p className="font-bold text-sm">Total</p>
                        {getStatSum(
                          adRequest.system_total_estimated_earnings,
                          adRequest.system_total_estimated_earnings_2
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {requestType == "approved" || requestType == "rejected" ? (
                    <>
                      <div className="text-center w-full md:w-1/12">
                        <p className="md:hidden font-bold">Active Provider</p>

                        {adRequest.provider
                          ? adRequest.provider == "ADSENSE"
                            ? "Adsense"
                            : "Adcash"
                          : "NA"}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {requestType == "unprocessed" ? (
                    <div className="text-center w-full md:w-1/12 flex flex-col gap-2">
                      <BasicButton
                        title={"Process"}
                        classes="bg-teal-700 w-full"
                        handleClick={() => showAdBox(adRequest)}
                      />
                      <BasicButton
                        title={"Reject"}
                        classes="bg-red-700 w-full"
                        handleClick={() => showRejectPromtModal(adRequest)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {requestType == "approved" ? (
                    <div className="text-center w-full md:w-1/12 flex flex-col gap-2">
                      <BasicButton
                        title={"Switch Provider"}
                        classes="bg-teal-700 w-full"
                        handleClick={() => switchProvider(adRequest)}
                      />
                      <BasicButton
                        title={"Reject"}
                        classes="bg-red-700 w-full"
                        handleClick={() => showRejectPromtModal(adRequest)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {requestType == "rejected" ? (
                    <div className="text-center w-full md:w-1/12 flex flex-col gap-2">
                      <BasicButton
                        title={"Approve"}
                        classes="bg-teal-700 w-full"
                        handleClick={() =>
                          adRequest.provider == "ADSENSE" ||
                          adRequest.provider == "ADSTERRA"
                            ? switchProvider(adRequest)
                            : showAdBox(adRequest)
                        }
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))
            : ""}
        </div>

        <div className="flex justify-center">
          {" "}
          {adRequests.data ? (
            adRequests.data.length ? (
              <Pagination pagination={adRequests} doPagination={doPagination} />
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </div>
      {currRequestToReject.user ? (
        <DeletePromptModal
          modalOpen={rejectRequestModalOpen}
          hideAdModal={hideRejectPromtModal}
          title={
            "Are you sure you want to reject the Ad Code Request of " +
            currRequestToReject.user.first_name +
            " " +
            currRequestToReject.user.last_name +
            " " +
            currRequestToReject.user.handle_id
          }
          yesActionText={"Yes, Reject"}
          noActionText={"No, Cancel"}
          action={() => rejectAdvertRequest()}
          processing={processing}
        />
      ) : (
        ""
      )}

      <AdminAdInsertModal
        modalOpen={showAdModal}
        hideAdModal={hideAdBox}
        saveAdvert={processRequest}
        pAd1Code={pAd1Code}
        sAd1Code={sAd1Code}
        pAd2Code={pAd2Code}
        sAd2Code={sAd2Code}
        pAd1CodeChange={pAd1CodeChange}
        sAd1CodeChange={sAd1CodeChange}
        pAd2CodeChange={pAd2CodeChange}
        sAd2CodeChange={sAd2CodeChange}
        advertMode={advertModalMode}
        provToSwitch={provToSwitch}
        defaultProvider={defaultProvider}
        onProviderChange={onProviderChange}
        currReq={currReq}
      />
    </>
  );
}
