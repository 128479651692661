import EditorTooltip from "../../tooltip/editor-tooltip";
import Checkbox from "../../../../../../../components/inputs/checkbox";
import { toast } from "react-toastify";

export default function HideButton(props) {
  const { productMeta, handleMetaChange, productMetaData, pageContext } = props;
  return (
    <div className="inline-flex gap-1 w-full justify-center relative">
      <div>
        <Checkbox
          handleChange={(e) => {
            if (pageContext == "update" && productMetaData.hasactivepayment) {
              toast(
                "Sorry, the ability to hide a button is reserved for buttons that have no purchase record.",
                { type: "error" }
              );
              return;
            }
            handleMetaChange(e, "hide_button", "checkbox");
          }}
          checked={productMeta.hide_button}
        />
      </div>
      <span>Hide Button</span>
      <EditorTooltip
        body={`If this checkbox is ticked, then the purchase button will not be visible on the page the customer sees.`}
        top={"-120px"}
        right={"25px"}
      />
    </div>
  );
}
