import TextField from "../../../../../../components/inputs/text-input";
import TextArea from "../../../../../../components/inputs/text-area";
import BasicButton from "../../../../../../components/buttons/basic-button";
import SlateEditor from "./slate/simple-text-formatter";
import StarterKit from "@tiptap/starter-kit";
import React from "react";
import AccordionMedia from "./media-index";
import AccordionTextFormatter from "./slate/simple-text-formatter";
import {
  BtnBold,
  BtnItalic,
  DefaultEditor,
  Editor,
  EditorProvider,
  BtnAlignCenter,
  createButton,
  Toolbar,
} from "react-simple-wysiwyg";
import uuid from "react-uuid";
import { useState, useEffect } from "react";

export default function AccordionEditor({
  modalOpen,
  hideLinkModal,
  accordions,
  addAccordionSection,
  deleteAccordionSection,
  handleAccordionBodyChange,
  handleAccordionHeaderChange,
  addAccordionToEditor,
  updateAccordionInEditor,
  createAccordionMode,
  accordionTitle,
  saveAccordionImages,
  accordionId,
  deleteAccordionMediaServer,
  deleteAccordionMediaEditor,
  handleAccordionTitleChange,
  addYoutubeToAccordion,
  addSocialToAccordion,
  processing,
}) {
  const BtnAlignCenter = createButton("Align center", "≡", "justifyCenter");

  const [currActiveMediaTab, setCurrActiveMediaTab] = useState("none");
  return (
    <div
      className={` absolute z-50 overflow-y-auto top-0 w-full left-0  ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-6/12 h-4/12 "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className="rounded rounded-full p-0 -top-2 -right-1 absolute px-1 cursor-pointer opacity-75"
            onClick={() => hideLinkModal()}
          >
            <i class="fa fa-times-circle text-red-500 z-50 text-2xl"></i>
          </div>
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2 flex flex-col justify-center items-center">
              <h1 className="text-2xl font-bold text-center">
                {/* {getActiveWidgetTitle(currentEditingWidget, widgets)} */}
                {createAccordionMode ? "Add Accordion" : "Update Accordion"}
              </h1>
              <TextField
                classes={"p-6 my-2"}
                placeholder={"Accordion Name"}
                handleChange={(e) => {
                  handleAccordionTitleChange(e);
                }}
                value={accordionTitle}
              />
              <div className="m-2 mb-2 w-full flex flex-col items-center gap-2">
                {accordions.length &&
                  accordions.map((c, parentIndex) => (
                    <div className="flex gap-2  flex flex-col text-left w-full">
                      <br />
                      <hr className="text-black" />
                      <p
                        className="text-red-500 cursor-pointer"
                        onClick={() => deleteAccordionSection(parentIndex)}
                      >
                        delete section
                      </p>
                      <TextField
                        classes={"p-6 my-2"}
                        placeholder={c.header.placeholder}
                        handleChange={(e) => {
                          handleAccordionHeaderChange(e, "header", parentIndex);
                          /* handleSocialsLinkChange(e, "instagram", "text") */
                        }}
                        value={c.header.value}
                      />
                      <TextArea
                        placeholder={c.body.placeholder}
                        handleChange={(e) => {
                          handleAccordionBodyChange(e, "body", parentIndex);
                          /* handleSocialsLinkChange(e, "instagram", "text") */
                        }}
                        value={c.body.value}
                      />

                      {/* <div className="w-full ">
                        <AccordionTextFormatter
                          createMode={createAccordionMode}
                          handleChange={handleAccordionBodyChange}
                          parentIndex={parentIndex}
                          data={c}
                        />
                      </div> */}
                      {/* <p>{c.body.value}</p> */}
                      <div className="w-full border rounded-lg p-2 bg-gray-50">
                        {!c.media.length ? (
                          <BasicButton
                            disabled={false}
                            title={
                              currActiveMediaTab == parentIndex
                                ? "Hide Media"
                                : "+ Add Media"
                            }
                            classes="px-8 w-8/12"
                            handleClick={() => {
                              if (parentIndex == currActiveMediaTab) {
                                setCurrActiveMediaTab("none");
                              } else {
                                setCurrActiveMediaTab(parentIndex);
                              }
                            }}
                          />
                        ) : (
                          ""
                        )}

                        {!c.media.length ? (
                          <div className="w-full">
                            {" "}
                            {currActiveMediaTab == parentIndex && (
                              <AccordionMedia
                                parentIndex={parentIndex}
                                accordions={accordions}
                                saveAccordionImages={saveAccordionImages}
                                addYoutubeToAccordion={addYoutubeToAccordion}
                                addSocialToAccordion={addSocialToAccordion}
                                accordionId={accordionId}
                                accordionSectionKey={c.id}
                              />
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        {c.media.length > 0 ? (
                          <div
                            className="flex gap-2 w-fill justify-center items-center my-4"
                            contentEditable="false"
                          >
                            <span className="font-bold">Media :</span>{" "}
                            {c.media[0].type}
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                if ((c.media[0].type = "upload")) {
                                  deleteAccordionMediaServer(parentIndex, c.id);
                                } else {
                                  deleteAccordionMediaEditor(parentIndex);
                                }
                              }}
                            >
                              <i
                                class={`fa fa-times-circle text-red-500  z-50 text-2xl product-page-editor-button`}
                              ></i>
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ))}

                <BasicButton
                  disabled={false}
                  title={"+ Add Section"}
                  classes="px-8 w-8/12 my-bg-yellow-500 text-black"
                  handleClick={() => addAccordionSection()}
                />
              </div>
            </div>

            <div className="flex justify-center">
              <BasicButton
                disabled={false}
                title={createAccordionMode ? "Create" : "Update"}
                classes="px-8 w-8/12 bg-teal-500  hover:bg-teal-300"
                handleClick={() =>
                  createAccordionMode
                    ? addAccordionToEditor()
                    : updateAccordionInEditor()
                }
              />
            </div>

            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideLinkModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
            {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
