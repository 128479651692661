import React, { useState, useEffect, useRef, forwardRef } from "react";
export default function (props) {
  const { page, handleClick } = props;

  return (
    <>
      <span
        className="p-4 rounded-full cursor-pointer border border-white "
        style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
        onClick={() => handleClick()}
        className={
          page !== 0
            ? "p-3 px-4 rounded-full cursor-pointer border border-white text-white  z-50"
            : "hiden"
        }
      >
        <span className={page !== 0 ? "text-white" : "hidden"}>|◄</span>
      </span>
    </>
  );
}
