import BasicButton from "../../../../../components/buttons/basic-button";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import LoadingProgress from "../../../rich-page/components/loading-progress";
import {
  isPublisher,
  isAuthUser,
  API_BASE,
} from "../../../../../Utils/helpers";
import SupplierBizNameVerification from "./biz-components/identity-biz-name-verification";
import SupplierBizLinkVerification from "./biz-components/identity-biz-link-verification";
import AddressVerification from "./biz-components/address-verification";
import authRequestService from "../../../../../services/HttpRequests/authenticated-request-services";

export default function PubSupplierBusinessProfile(props) {
  const { verification, getVerificationData, processing } = props;
  const [bizLink, setBizLink] = useState("");
  const [bizName, setBizName] = useState("");
  const [location, setLocation] = useState({
    biz_lat: "",
    biz_lng: "",
    address: "",
  });
  const [regLocation, setRegLocation] = useState("");
  const [saving, setSaving] = useState(false);
  const [regType, setRegType] = useState("");

  const updateProfile = () => {
    const url = API_BASE + "/publisher/supplier/profile/update";
    setSaving(true);
    let formData = new FormData();
    formData.append("biz_name", bizName);
    formData.append("biz_link", bizLink);
    formData.append("location", regLocation);
    formData.append("updated_address", location.address);
    formData.append("type", regType);
    authRequestService()
      .post(url, formData)
      .then((res) => {
        setSaving(false);

        if (
          regLocation.replace(/\s+/g, "") ==
          location.address.replace(/\s+/g, "")
        ) {
          toast("Updated", { type: "success" });
          getVerificationData();
        } else {
          window.location.href = `/publisher/supplier/onboarding/start/business-location-change`;
        }
      })
      .catch((err) => {
        setSaving(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const handleBizNameChange = (e) => {
    setBizName(e);
  };

  const handleBizLinkChange = (e) => {
    setBizLink(e);
  };

  const capitalizeFirstWord = (str) => {
    // Check if the string is not empty
    if (str.length === 0) {
      return str;
    }

    // Capitalize the first character and concatenate it with the rest of the string
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    if (!isPublisher()) {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    if (verification?.id) {
      setBizName(verification.business_name);
      setBizLink(verification.business_link);
      setLocation((prev) => ({ ...prev, address: verification.address }));
      setRegLocation(verification.address);
      setRegType(verification.type);
    }
  }, [verification]);

  return verification?.id ? (
    <>
      {!verification?.updated_address ? (
        <div className="flex justify-center">
          <h1 className="font-bold">
            Update Your {capitalizeFirstWord(verification?.type)} Profile
          </h1>
        </div>
      ) : (
        ""
      )}

      {verification?.updated_address && verification?.redo_on_profile_update ? (
        <p>
          Your Change of Business Location Request has been successfully
          submitted and is now under review. You can anticipate a response
          within a few hours to a maximum of seven business days.
        </p>
      ) : (
        ""
      )}
      {!verification.redo_on_profile_update ? (
        <div className="p-2 w-full flex flex-col items-center">
          <div className="p-2 w-full md:w-8/12">
            <div className="flex flex-col justify-center items-center gap-4 mt-8 p-2 w-full">
              <div className="p-2 w-full  shadow w-full">
                <div className="p-2 w-full flex flex-col">
                  <SupplierBizNameVerification
                    bizName={bizName}
                    handleBizNameChange={handleBizNameChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center gap-4 mt-8 p-2 w-full">
              <div className="p-2 w-full  shadow">
                <div className="p-2 w-full flex flex-col">
                  <SupplierBizLinkVerification
                    bizLink={bizLink}
                    handleBizLinkChange={handleBizLinkChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center mt-10 shadow p-10">
              <div className="flex flex-col justify-center items-center gap-4 mt-8">
                <AddressVerification
                  location={location}
                  verification={verification}
                  setLocation={setLocation}
                />
              </div>
            </div>
            <div className="flex flex-col justify-center items-center gap-4 mt-8 p-2 w-full">
              <BasicButton
                disabled={saving || !location.address || !bizLink || !bizName}
                title={saving ? "Processing..." : "Update Profile"}
                handleClick={() => updateProfile()}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  ) : (
    <div className="flex justify-center">
      {/* <LoadingProgress loading={processing} /> */}
    </div>
  );
}
