import DatePicker from "react-datepicker";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { useBookStore } from "../../../../../stores/book-store";
import Checkbox from "../../../../../components/inputs/checkbox";
import TextArea from "../../../../../components/inputs/text-area";
import TextField from "../../../../../components/inputs/text-input";
import TextNumberField from "../../../../../components/inputs/text-number-input";
import BasicButton from "../../../../../components/buttons/basic-button";
import EditorTooltip from "./tooltip/editor-tooltip";
import useDebounce from "../../../../../components/debounce/debounce-helper";
import { useRef, useState } from "react";
import ShortDurations from "./recurring/short-durations";
import BookingInterruption from "./recurring/booking-interruption";
import TrackQuantity from "./recurring/track-quantity";
import Stats from "./recurring/stats";
import PreferredPrice from "./recurring/preferred-price";
import ShortOnPreferred from "./recurring/short-on-preferred";
import FutureAvailability from "./recurring/future-availability";
import BlockedStartDates from "./recurring/blocked-start-dates";
import BlockedWeekendStart from "./recurring/blocked-weekend-start";
import ProductContract from "./recurring/product-contracts";
import AfterPurchaseMsg from "./recurring/after-purchase-msg";
import ReqPurchaserReply from "./recurring/req-purchaser-reply";
import ReplyRequest from "./recurring/reply-request";
import MakeInactive from "./recurring/make-inactive";
import PurchaseOptionDef from "./recurring/purchase-opt-def";
import HideButton from "./recurring/hide-button";
import CustomerServices from "./recurring/customer-services";
import SubscriptionCap from "./recurring/subscription-cap";
import ServiceLocation from "./recurring/service-location";
import ServiceZipCode from "./recurring/service-zip-code";
import RadioBox from "../../../../../components/inputs/radio";
import { REPriceLabsOptions } from "./constants";
export default function REPurchaseBox({
  user,
  productContracts,
  productDefaultContracts,
  customerServiceSets,
  productMetaData,
  pageContext,
  state,
  shortDurations,
  hideAdModal,
}) {
  const [pickerOpen, setPickerOpen] = useState(false);
  const [QChangedByUser, setQChangedByUser] = useState(false);
  const productMeta = state.productMeta;

  const pickeRref = useRef();
  function isNumber(value) {
    return typeof value === "number";
  }

  const openDatePicker = () => {
    setPickerOpen(true);
  };

  const addBlockedDate = (d) => {
    state.setProductMeta((prev) => ({
      ...prev,
      ["blocked_dates"]: [
        ...prev["blocked_dates"],
        {
          date: d
            .toLocaleString("en-GB", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })
            .replace(/\//g, "-"),
        },
      ],
    }));
    setPickerOpen(false);
  };

  const removeBlockedDate = (index) => {
    const f = productMeta.blocked_dates;

    state.setProductMeta((prev) => ({
      ...prev,
      ["blocked_dates"]: f.filter((d, i) => i !== index),
    }));
  };

  const getFullShortMetaName = (name) => {
    if (state.productMeta?.p_type_sub_opt) {
      let str = state.productMeta?.p_type_sub_opt;
      let words = str.split(" ");
      words.shift(); // Remove the first word
      let result = words.join(" ");
      console.log("pref name", result);

      return name + " " + result;
    }

    return name;
  };

  const getShortDurationMeta = (e, key) => {
    return {
      purchase_type: 2,
      price: e.price,
      quantity: "",
      quantity_enabled: false,
      quantity_sel_enabled: false,
      quantity_merge: "",
      p_type_sub_opt: getFullShortMetaName(e.label),
      re_type_sub_opt: "",
      make_active: false,
      hide_button: false,
      after_purchase: "",
      purchaser_reply: "",
      purchaser_reply_enabled: false,
      location_fulfilment: [{ location: "", charge: "" }],
      blocked_dates: [],
      block_weekend_start: false,
      booking_interruption: true,
      short_durr_on_preferred_available: true,
      supplier_customer_service_id: "",
      future_availability: "",
      merges: [],
      block_meta_id:
        pageContext == "create" ? e.uuid : state.productMeta[key].block_meta_id,
    };
  };

  const handleMetaChange = (e, key, type) => {
    if (type == "checkbox") {
      state.setProductMeta((prev) => ({ ...prev, [key]: !prev[key] }));
    } else if (type == "text" || type == "radio" || type == "dropdown") {
      if (type == "dropdown") {
        state.setProductMeta((prev) => ({
          ...prev,
          [key]: e?.target?.value || "",
        }));
      } else {
        state.setProductMeta((prev) => ({ ...prev, [key]: e }));
      }
    } else if (type == "product_short") {
      state.setProductMeta((prev) => ({
        ...prev,
        [key]: getShortDurationMeta(e, key),
      }));
    } else {
      state.setProductMeta((prev) => ({ ...prev, [key]: e }));
    }
  };

  useDebounce(
    () => {
      if (pageContext == "update" && QChangedByUser && productMeta.quantity) {
        console.log("User changed  Q");
        state?.getAvailableQuantityForMeta(
          productMeta.block_meta_id,
          productMeta.quantity
        );
        setQChangedByUser(false);
      }
    },
    [productMeta.quantity],
    800
  );

  useDebounce(
    () => {
      if (pageContext == "create") {
        if (productMeta.integration_type_placeholder == "pricelabs") {
          state?.getPriceLabsListings();
          handleMetaChange(1, "quantity", "text");
          if (!productMeta.quantity_enabled) {
            handleMetaChange("", "quantity_enabled", "checkbox");
          }
          if (!productMeta.booking_interruption) {
            handleMetaChange("", "booking_interruption", "checkbox");
          }
          if (productMeta.subscription_cap_enabled) {
            handleMetaChange("", "subscription_cap_enabled", "checkbox");
          }
          handleMetaChange("", "p_type_sub_opt", "dropdown");
          handleMetaChange("pricelabs", "integration_type", "text");
        } else {
          handleMetaChange("", "price", "text");
          handleMetaChange("", "quantity", "text");
          handleMetaChange("", "pricelabs_listing", "text");
          handleMetaChange("", "pricelabs_listing_id", "text");
          handleMetaChange("", "pricelabs_listing_pms", "text");
          handleMetaChange("", "pricelabs_listing_name", "text");
          handleMetaChange("litzapp", "integration_type", "text");
          handleMetaChange("litzapp", "calendar_type", "text");
          state.handleNameChange("");
          if (productMeta.quantity_enabled) {
            handleMetaChange("", "quantity_enabled", "checkbox");
          }
          if (!productMeta.booking_interruption) {
            handleMetaChange("", "booking_interruption", "checkbox");
          }
          handleMetaChange("", "pricelabs_listing", "dropdown");
          handleMetaChange("", "p_type_sub_opt", "dropdown");
        }
      }
    },
    [productMeta.integration_type_placeholder],
    800
  );

  useDebounce(
    () => {
      if (pageContext == "create" && productMeta.pricelabs_listing_id) {
        const listing = state.listings.filter(
          (b) => b.id == productMeta.pricelabs_listing_id
        )[0];

        handleMetaChange(listing.base, "price", "text");
        handleMetaChange(1, "quantity", "text");
        state.handleNameChange(listing.name);
        handleMetaChange(1, "quantity", "text");
        // handleMetaChange(listing.id, "pricelabs_listing_id", "text");
        handleMetaChange(listing.pms, "pricelabs_listing_pms", "text");
        handleMetaChange(listing.name, "pricelabs_listing_name", "text");
        if (!productMeta.quantity_enabled) {
          handleMetaChange("", "quantity_enabled", "checkbox");
        }
      }
    },
    [productMeta.pricelabs_listing_id],
    800
  );

  const getIntegrationType = () => {
    if (productMeta?.integration_type_placeholder)
      return productMeta?.integration_type_placeholder;

    if (pageContext == "update" && !productMeta?.integration_type_placeholder)
      return "litzapp";
  };

  const getCalendarType = () => {
    if (productMeta?.calendar_type_placeholder)
      return productMeta?.calendar_type_placeholder;

    if (pageContext == "update" && !productMeta?.calendar_type_placeholder)
      return "litzapp";
  };

  return (
    <div>
      {state.metaMode == 2 && (
        <div className="flex flex-col gap-4 w-full justify-center items-center mb-4 py-2 md:6/12">
          <div className="flex gap-2 w-full justify-center gap-6">
            <div className="w-full flex items-center justify-center items-center">
              <div className="flex flex-col">
                <div className="text-black font-bold flex justify-center gap-1">
                  <label> Price Integration (Optional):</label>
                  {/* {!productMeta.integration_type && (
                    <span className="text-red-500 text-lg">*</span>
                  )} */}
                </div>
                <select
                  disabled={pageContext == "update"}
                  value={getIntegrationType()}
                  onChange={(e) => {
                    handleMetaChange(
                      e,
                      "integration_type_placeholder",
                      "dropdown"
                    );
                  }}
                  required
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
                >
                  <option value="" defaultValue>
                    Non Required
                  </option>

                  <option value={"litzapp"}>Litzapp (Default)</option>
                  <option value={"pricelabs"}>PriceLabs</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      )}

      {productMeta.integration_type == "pricelabs" ? (
        pageContext == "create" ? (
          <div className="w-full flex items-center justify-center items-center">
            <div className="flex flex-col">
              {" "}
              <div className="text-black font-bold flex justify-center gap-1">
                <span> Select Listing</span>
                {!productMeta.pricelabs_listing_id && (
                  <span className="text-red-500 text-lg">*</span>
                )}
              </div>
              {state?.processing ? (
                <p>Loading Listings..</p>
              ) : (
                <select
                  disabled={pageContext == "update"}
                  value={productMeta.pricelabs_listing_id}
                  onChange={(e) => {
                    handleMetaChange(e, "pricelabs_listing_id", "dropdown");
                  }}
                  required
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
                >
                  <option value="" defaultValue>
                    Not Selected
                  </option>
                  {state?.listings
                    ?.filter((l) => !state?.existingListingIds?.includes(l.id))
                    .map((b) => (
                      <option value={b.id}>{b.name}</option>
                    ))}
                </select>
              )}
            </div>
          </div>
        ) : (
          <div className="m-2 mb-2 flex- flex-col items-center md:4/12">
            <TextField
              disabled={true}
              classes={"p-6 my-2 w-full"}
              placeholder={"Listing Name.."}
              type="text"
              value={productMeta.pricelabs_listing_name}
            />
          </div>
        )
      ) : (
        ""
      )}

      {productMeta?.integration_type == "pricelabs" ? (
        <div className="w-full flex items-center justify-center items-center my-2">
          <div className="flex flex-col">
            {" "}
            <div className="text-black font-bold flex items-center gap-1">
              <span> Preferred Purchase Duration</span>
              {!productMeta.p_type_sub_opt && (
                <span className="text-red-500 text-lg">*</span>
              )}
            </div>
            <select
              disabled={
                pageContext == "update" || !productMeta.pricelabs_listing_id
              }
              value={productMeta.p_type_sub_opt}
              onChange={(e) => {
                handleMetaChange(e, "p_type_sub_opt", "dropdown");
              }}
              required
              class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
            >
              <option value="" defaultValue disabled>
                Not Selected
              </option>
              {REPriceLabsOptions.map((b) => (
                <option value={b.label}>{b.label}</option>
              ))}
            </select>
          </div>
        </div>
      ) : (
        <PurchaseOptionDef
          productMeta={productMeta}
          pageContext={pageContext}
          handleMetaChange={handleMetaChange}
          state={state}
        />
      )}

      {productMeta.p_type_sub_opt && state.metaMode == 2 && (
        <div className={`flex flex-col gap-2 w-full p-2`}>
          {productMeta.p_type_sub_opt && (
            <div className="flex flex-col items-center">
              <SubscriptionCap
                productMeta={productMeta}
                pageContext={pageContext}
                handleMetaChange={handleMetaChange}
                setQChangedByUser={setQChangedByUser}
              />
              <BookingInterruption
                productMeta={productMeta}
                pageContext={pageContext}
                handleMetaChange={handleMetaChange}
              />
              <TrackQuantity
                productMeta={productMeta}
                pageContext={pageContext}
                handleMetaChange={handleMetaChange}
                setQChangedByUser={setQChangedByUser}
              />
              <Stats productMetaData={productMetaData} />
              <PreferredPrice
                productMeta={productMeta}
                pageContext={pageContext}
                handleMetaChange={handleMetaChange}
                user={user}
              />
              {productMeta.booking_interruption &&
                !productMeta.subscription_cap_enabled && (
                  <div className="flex flex-col gap-2 w-full md:w-6/12 justify-center items-center p-2">
                    <p className="font-bold">Short Purchase Durations</p>
                    {productMeta.p_type_sub_opt.includes("Monthly") &&
                      shortDurations.REMonthOptions.map((b) => (
                        <ShortDurations
                          productMeta={productMeta}
                          pageContext={pageContext}
                          handleMetaChange={handleMetaChange}
                          user={user}
                          b={b}
                        />
                      ))}

                    {productMeta.p_type_sub_opt.includes("Fortnightly") &&
                      shortDurations.REForthnightlyOptions.map((b) => (
                        <ShortDurations
                          productMeta={productMeta}
                          pageContext={pageContext}
                          handleMetaChange={handleMetaChange}
                          user={user}
                          b={b}
                        />
                      ))}

                    {productMeta.p_type_sub_opt.includes("Weekly") &&
                      shortDurations.REWeekOptions.map((b) => (
                        <ShortDurations
                          productMeta={productMeta}
                          pageContext={pageContext}
                          handleMetaChange={handleMetaChange}
                          user={user}
                          b={b}
                        />
                      ))}
                  </div>
                )}
              <ShortOnPreferred
                productMeta={productMeta}
                handleMetaChange={handleMetaChange}
              />
              <FutureAvailability
                pageContext={pageContext}
                productMeta={productMeta}
                handleMetaChange={handleMetaChange}
              />
              <BlockedStartDates
                pickeRref={pickeRref}
                pickerOpen={pickerOpen}
                productMeta={productMeta}
                setPickerOpen={setPickerOpen}
                addBlockedDate={addBlockedDate}
                openDatePicker={openDatePicker}
                removeBlockedDate={removeBlockedDate}
              />
              <BlockedWeekendStart
                productMeta={productMeta}
                pageContext={pageContext}
                handleMetaChange={handleMetaChange}
              />
              <ProductContract
                productMeta={productMeta}
                handleMetaChange={handleMetaChange}
                productContracts={productContracts}
                productDefaultContracts={productDefaultContracts}
              />
              <CustomerServices
                productMeta={productMeta}
                handleMetaChange={handleMetaChange}
                customerServiceSets={customerServiceSets}
              />
              <ServiceLocation
                productMeta={productMeta}
                pageContext={pageContext}
                handleMetaChange={handleMetaChange}
                user={user}
              />
              <ServiceZipCode
                productMeta={productMeta}
                pageContext={pageContext}
                handleMetaChange={handleMetaChange}
                user={user}
              />
              <AfterPurchaseMsg
                productMeta={productMeta}
                handleMetaChange={handleMetaChange}
              />
              <ReqPurchaserReply
                productMeta={productMeta}
                handleMetaChange={handleMetaChange}
              />
              <ReplyRequest
                productMeta={productMeta}
                handleMetaChange={handleMetaChange}
              />
              <hr />
              <MakeInactive
                productMeta={productMeta}
                handleMetaChange={handleMetaChange}
              />
              <HideButton
                productMeta={productMeta}
                handleMetaChange={handleMetaChange}
                pageContext={pageContext}
                productMetaData={productMetaData}
              />
              {}
              <BasicButton
                disabled={state.cannotDisplayREButton()}
                classes={"w-full mt-10"}
                title={"Save"}
                handleClick={() => {
                  if (pageContext == "create") {
                    toast("Updated", { type: "success" });
                  }
                  state.saveMetaConfig();
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
