export const IdentityOptions = [
  {
    index: 0,
    name: "Linkedin Profile",
  },
  {
    index: 1,
    name: "Airbnb Host Profile",
  },
  {
    index: 2,
    name: "Turo - Host Profile",
  },
  {
    index: 3,
    name: "1 Rent Place - Host Profile",
  },
  {
    index: 4,
    name: "9flats Host Profile",
  },
  {
    index: 5,
    name: "Aaron's - Host Profile",
  },
  {
    index: 6,
    name: "AbeBooks - Host Profile",
  },
  {
    index: 7,
    name: "Acoustic Rent - Host Profile (Note: Mainly focused on acoustic instruments)",
  },
  {
    index: 8,
    name: "Adventure 16 - Host Profile",
  },
  {
    index: 9,
    name: "Adventure KT - Host Profile",
  },
  {
    index: 10,
    name: "AERIAL9 - Host Profile",
  },
  {
    index: 11,
    name: "AFR Rentals - Host Profile",
  },
  {
    index: 12,
    name: "Agoda Host Profile",
  },
  {
    index: 13,
    name: "AgriBolo (India-based) - Host Profile",
  },
  {
    index: 14,
    name: "Ahoy Club - Host Profile",
  },
  {
    index: 15,
    name: "Airbnb (Event Spaces category) - Host Profile",
  },
  {
    index: 16,
    name: "AirGarage - Host Profile",
  },
  {
    index: 17,
    name: "Airworx Drone Rentals - Host Profile (Note: Arizona-specific)",
  },
  {
    index: 18,
    name: "Alberta Drone Pilots - Host Profile (Note: Alberta, Canada specific)",
  },
  {
    index: 19,
    name: "Alcove9 - Host Profile",
  },
  {
    index: 20,
    name: "Alibris - Host Profile",
  },
  {
    index: 21,
    name: "Alluster (Canadian based storage solution) - Host Profile",
  },
  {
    index: 22,
    name: "AlpinTrek - Host Profile",
  },
  {
    index: 23,
    name: "Amazon Textbook Rental - Host Profile",
  },
  {
    index: 24,
    name: "Antlos - Host Profile",
  },
  {
    index: 25,
    name: "Any Hire - Host Profile",
  },
  {
    index: 26,
    name: "AnyQuip (Canada-based) - Host Profile",
  },
  {
    index: 27,
    name: "Apartment Finder - Owner / Manager Profile",
  },
  {
    index: 28,
    name: "Apartment List - Owner / Manager Profile",
  },
  {
    index: 29,
    name: "Apartments.com - Owner / Manager Profile",
  },
  {
    index: 30,
    name: "Aperturent - Host Profile",
  },
  {
    index: 31,
    name: "Apollo RV Rentals - Host Profile",
  },
  {
    index: 32,
    name: "Armarium - Host Profile",
  },
  {
    index: 33,
    name: "Arrive Outdoors - Host Profile",
  },
  {
    index: 34,
    name: "Atom Drones - Host Profile (Note: Specific to India)",
  },
  {
    index: 35,
    name: "ATS Rentals - Host Profile",
  },
  {
    index: 36,
    name: "Australian Drone Rentals - Host Profile (Note: Specific to Australia)",
  },
  {
    index: 37,
    name: "Auto Europe RV Rentals - Host Profile",
  },
  {
    index: 38,
    name: "Avail - Owner / Manager Profile",
  },
  {
    index: 39,
    name: "Avvay - Host Profile",
  },
  {
    index: 40,
    name: "Back40 - Host Profile",
  },
  {
    index: 41,
    name: "Bandago - Host Profile",
  },
  {
    index: 42,
    name: "Barnes & Noble Textbook Rental - Host Profile",
  },
  {
    index: 43,
    name: "Beam - Host Profile",
  },
  {
    index: 44,
    name: "Beds on Board - Host Profile",
  },
  {
    index: 45,
    name: "BerlinRent (Berlin-based, focused on professional film and photo production equipment) - Host Profile",
  },
  {
    index: 46,
    name: "Better World Books - Host Profile",
  },
  {
    index: 47,
    name: "Bird - Host Profile",
  },
  {
    index: 48,
    name: "BlaBlaCar - Host Profile",
  },
  {
    index: 49,
    name: "Blue Skies Drone Rental - Host Profile",
  },
  {
    index: 50,
    name: "Blue Voyage Yachting - Host Profile",
  },
  {
    index: 51,
    name: "Boataffair - Host Profile",
  },
  {
    index: 52,
    name: "Boatbookings.com - Host Profile",
  },
  {
    index: 53,
    name: "Boatbound - Host Profile",
  },
  {
    index: 54,
    name: "Boatim - Host Profile",
  },
  {
    index: 55,
    name: "Boatsetter - Host Profile",
  },
  {
    index: 56,
    name: "Boels Rental (EU-based) - Host Profile",
  },
  {
    index: 57,
    name: "Bolt (formerly Taxify) - Host Profile",
  },
  {
    index: 58,
    name: "BookByte - Host Profile",
  },
  {
    index: 59,
    name: "BookEventz (India-based) - Host Profile",
  },
  {
    index: 60,
    name: "BookFinder - Host Profile",
  },
  {
    index: 61,
    name: "Booking.com Host Profile",
  },
  {
    index: 62,
    name: "BookRenter - Host Profile",
  },
  {
    index: 63,
    name: "Borrow A Boat - Host Profile",
  },
  {
    index: 64,
    name: "Borrow Our Backyard - Host Profile",
  },
  {
    index: 65,
    name: "BorrowFox - Host Profile (for creative industry assets)",
  },
  {
    index: 66,
    name: "BorrowFox - Host Profile (Note: UK based platform that offers a variety of rentals including instruments)",
  },
  {
    index: 67,
    name: "BorrowFox (UK-based, Appears to be inactive) - Host Profile",
  },
  {
    index: 68,
    name: "BorrowLenses - Host Profile",
  },
  {
    index: 69,
    name: "BorrowLenses - Host Profile",
  },
  {
    index: 70,
    name: "BorrowMyDoggy - Host Profile",
  },
  {
    index: 71,
    name: "Bounce - Host Profile",
  },
  {
    index: 72,
    name: "Boxbee (offers personal storage solutions) - Host Profile",
  },
  {
    index: 73,
    name: "Boxcar - Host Profile",
  },
  {
    index: 74,
    name: "Boxit Storage (primarily operates in the Middle East) - Host Profile",
  },
  {
    index: 75,
    name: "Brainfuse - Host Profile",
  },
  {
    index: 76,
    name: "Breather - Host Profile (Focused on meeting rooms but can be used for events)",
  },
  {
    index: 77,
    name: "Brisbane Camera Hire (Australia-based) - Host Profile",
  },
  {
    index: 78,
    name: "Brook Furniture Rental - Host Profile",
  },
  {
    index: 79,
    name: "Buddy’s Home Furnishings - Host Profile",
  },
  {
    index: 80,
    name: "Buro - Host Profile",
  },
  {
    index: 81,
    name: "By Rotation - Host Profile",
  },
  {
    index: 82,
    name: "Bykea (operating in Pakistan) - Host Profile",
  },
  {
    index: 83,
    name: "Cambly - Host Profile",
  },
  {
    index: 84,
    name: "Camera Hire (Australia-based) - Host Profile",
  },
  {
    index: 85,
    name: "Camera Hire New Zealand (New Zealand-based) - Host Profile",
  },
  {
    index: 86,
    name: "Cameralends (Note: Acquired by KitSplit but once stood as a separate entity) - Host Profile",
  },
  {
    index: 87,
    name: "CameraLends (Note: Acquired by KitSplit) - Host Profile",
  },
  {
    index: 88,
    name: "cameraworks-uk (UK-based) - Host Profile",
  },
  {
    index: 89,
    name: "Camp Easy - Host Profile",
  },
  {
    index: 90,
    name: "Campanda - Host Profile",
  },
  {
    index: 91,
    name: "CampCrate - Host Profile",
  },
  {
    index: 92,
    name: "CampEasy - Host Profile",
  },
  {
    index: 93,
    name: "Campers And Gear - Host Profile",
  },
  {
    index: 94,
    name: "Campervan Hire Sale Finder (Australia & New Zealand focused) - Host Profile",
  },
  {
    index: 95,
    name: "Camplify - Host Profile",
  },
  {
    index: 96,
    name: "Camptoo - Host Profile",
  },
  {
    index: 97,
    name: "Campus Book Rentals - Host Profile",
  },
  {
    index: 98,
    name: "CampusBooks - Host Profile",
  },
  {
    index: 99,
    name: "Car Next Door - Host Profile",
  },
  {
    index: 100,
    name: "Care.com - Host Profile",
  },
  {
    index: 101,
    name: "Carhood - Host Profile",
  },
  {
    index: 102,
    name: "CasaOne - Host Profile",
  },
  {
    index: 103,
    name: "Cengage - Host Profile",
  },
  {
    index: 104,
    name: "Central Texas Drone Rentals - Host Profile (Note: Texas-specific)",
  },
  {
    index: 105,
    name: "Charter Index - Host Profile",
  },
  {
    index: 106,
    name: "Chegg - Host Profile",
  },
  {
    index: 107,
    name: "Chegg Tutors - Host Profile",
  },
  {
    index: 108,
    name: "Chic by Choice (seems to be inactive) - Host Profile",
  },
  {
    index: 109,
    name: "Churchill Living - Host Profile",
  },
  {
    index: 110,
    name: "Circ (now acquired by Bird) - Host Profile",
  },
  {
    index: 111,
    name: "Click Props Backdrops (focused on backdrop and related accessories rental) - Host Profile",
  },
  {
    index: 112,
    name: "Click&Boat - Host Profile",
  },
  {
    index: 113,
    name: "Clothing Swap - Host Profile",
  },
  {
    index: 114,
    name: "College Book Renter - Host Profile",
  },
  {
    index: 115,
    name: "Colorado Drone Chargers - Host Profile",
  },
  {
    index: 116,
    name: "ColorTyme - Host Profile",
  },
  {
    index: 117,
    name: "CORT Furniture Rental - Host Profile",
  },
  {
    index: 118,
    name: "Cosmos Yachting - Host Profile",
  },
  {
    index: 119,
    name: "CoStar - Owner / Manager Profile",
  },
  {
    index: 120,
    name: "Couchsurfing Host Profile",
  },
  {
    index: 121,
    name: "Course Hero - Host Profile",
  },
  {
    index: 122,
    name: "Cruise America - Host Profile",
  },
  {
    index: 123,
    name: "Cubiq - Host Profile",
  },
  {
    index: 124,
    name: "CurbFlip - Host Profile",
  },
  {
    index: 125,
    name: "Curtsy - Host Profile",
  },
  {
    index: 126,
    name: "Depop - Host Profile",
  },
  {
    index: 127,
    name: "Direct Textbook - Host Profile",
  },
  {
    index: 128,
    name: "DogBuddy (merged with Rover) - Host Profile",
  },
  {
    index: 129,
    name: "DogHero (focused on Latin America and Spain) - Host Profile",
  },
  {
    index: 130,
    name: "Dogtopia - Host Profile",
  },
  {
    index: 131,
    name: "DogVacay (merged with Rover but used to be standalone) - Host Profile",
  },
  {
    index: 132,
    name: "DogWalker.com - Host Profile",
  },
  {
    index: 133,
    name: "Domu - Owner / Manager Profile",
  },
  {
    index: 134,
    name: "Doorsteps - Owner / Manager Profile",
  },
  {
    index: 135,
    name: "Dott - Host Profile",
  },
  {
    index: 136,
    name: "Dozr - Host Profile",
  },
  {
    index: 137,
    name: "Dream Yacht Charter - Host Profile",
  },
  {
    index: 138,
    name: "Dress Hire AU (Australia-based) - Host Profile",
  },
  {
    index: 139,
    name: "Drivy - Host Profile",
  },
  {
    index: 140,
    name: "Drone Hire Adelaide - Host Profile (Note: Specific to Adelaide, Australia)",
  },
  {
    index: 141,
    name: "Drone Rental Supply - Host Profile",
  },
  {
    index: 142,
    name: "Drone Rentals Amsterdam - Host Profile (Note: Specific to the Netherlands)",
  },
  {
    index: 143,
    name: "DroneLancer - Host Profile",
  },
  {
    index: 144,
    name: "Dronelend - Host Profile (Note: Based in Spain)",
  },
  {
    index: 145,
    name: "Easy Hire - Host Profile",
  },
  {
    index: 146,
    name: "EasyCar Club - Host Profile",
  },
  {
    index: 147,
    name: "EasyHome - Host Profile",
  },
  {
    index: 148,
    name: "eCampus - Host Profile",
  },
  {
    index: 149,
    name: "ECOVA (EU-based) - Host Profile",
  },
  {
    index: 150,
    name: "ECrent - Host Profile",
  },
  {
    index: 151,
    name: "EduBirdie - Host Profile",
  },
  {
    index: 152,
    name: "Education First - Host Profile",
  },
  {
    index: 153,
    name: "EF Education First - Host Profile",
  },
  {
    index: 154,
    name: "El Monte RV - Host Profile",
  },
  {
    index: 155,
    name: "EM3 Agri Services (India-based) - Host Profile",
  },
  {
    index: 156,
    name: "Empire Furniture Rental - Host Profile",
  },
  {
    index: 157,
    name: "Encore - Host Profile (Note: UK based platform offering instrument rental services)",
  },
  {
    index: 158,
    name: "Enterprise CarShare - Host Profile",
  },
  {
    index: 159,
    name: "EquipmentShare - Host Profile",
  },
  {
    index: 160,
    name: "EventUp - Host Profile",
  },
  {
    index: 161,
    name: "eVenues - Host Profile",
  },
  {
    index: 162,
    name: "Expedia Host Profile",
  },
  {
    index: 163,
    name: "Explore Rentals - Host Profile",
  },
  {
    index: 164,
    name: "EZ Living - Host Profile",
  },
  {
    index: 165,
    name: "Fairline Yachts - Host Profile",
  },
  {
    index: 166,
    name: "Farm-r (UK-based) - Host Profile",
  },
  {
    index: 167,
    name: "Farmease (US and India based) - Host Profile",
  },
  {
    index: 168,
    name: "Fashion Furniture Rental - Host Profile",
  },
  {
    index: 169,
    name: "Fat Lama - Host Profile",
  },
  {
    index: 170,
    name: "Fat Llama - Host Profile",
  },
  {
    index: 171,
    name: "Fat Llama - Host Profile",
  },
  {
    index: 172,
    name: "Fat Llama - Host Profile",
  },
  {
    index: 173,
    name: "Fat Llama - Host Profile",
  },
  {
    index: 174,
    name: "Fat Llama - Host Profile (Note: This platform rents out a wide variety of items, including musical instruments)",
  },
  {
    index: 175,
    name: "Feather - Host Profile",
  },
  {
    index: 176,
    name: "Fernish - Host Profile",
  },
  {
    index: 177,
    name: "Fetch - Host Profile",
  },
  {
    index: 178,
    name: "Fetch! Pet Care - Host Profile",
  },
  {
    index: 179,
    name: "Flannery Plant Hire (UK-based) - Host Profile",
  },
  {
    index: 180,
    name: "FlightClub - Host Profile (Note: Singapore-based platform)",
  },
  {
    index: 181,
    name: "FlipKey Host Profile",
  },
  {
    index: 182,
    name: "Fluid Truck - Host Profile",
  },
  {
    index: 183,
    name: "Flyrobe (India-based, seems to be inactive) - Host Profile",
  },
  {
    index: 184,
    name: "ForRent.com - Owner / Manager Profile",
  },
  {
    index: 185,
    name: "Fraser Yachts - Host Profile",
  },
  {
    index: 186,
    name: "Fretish - Host Profile",
  },
  {
    index: 187,
    name: "FriendWithA - Host Profile",
  },
  {
    index: 188,
    name: "FriendWithA - Host Profile",
  },
  {
    index: 189,
    name: "Furlenco (India-based) - Host Profile",
  },
  {
    index: 190,
    name: "Furnishare - Host Profile",
  },
  {
    index: 191,
    name: "GaragePointer - Host Profile",
  },
  {
    index: 192,
    name: "Garagetime (geared towards workshop and garage space, but can be used for storage) - Host Profile",
  },
  {
    index: 193,
    name: "Gear Commons - Host Profile",
  },
  {
    index: 194,
    name: "Gear Focus - Host Profile (mostly for camera gear)",
  },
  {
    index: 195,
    name: "Gear Focus - Host Profile (Note: Mainly a marketplace but also offers rental options)",
  },
  {
    index: 196,
    name: "Gear Peer - Host Profile",
  },
  {
    index: 197,
    name: "Gear Up - Host Profile",
  },
  {
    index: 198,
    name: "GearFlow - Host Profile",
  },
  {
    index: 199,
    name: "Gearo - Host Profile",
  },
  {
    index: 200,
    name: "Get My Car - Host Profile",
  },
  {
    index: 201,
    name: "Getable (Appears to have ceased operations) - Host Profile",
  },
  {
    index: 202,
    name: "Getaround - Host Profile",
  },
  {
    index: 203,
    name: "GetMyBoat - Host Profile",
  },
  {
    index: 204,
    name: "Giggster - Host Profile",
  },
  {
    index: 205,
    name: "GlamCorner - Host Profile",
  },
  {
    index: 206,
    name: "Globesailor - Host Profile",
  },
  {
    index: 207,
    name: "Go RVing Canada - Host Profile",
  },
  {
    index: 208,
    name: "Goldbell (Singapore-based) - Host Profile",
  },
  {
    index: 209,
    name: "GoMore - Host Profile",
  },
  {
    index: 210,
    name: "Grover - Host Profile (Note: Available in Germany and Austria)",
  },
  {
    index: 211,
    name: "Grover (EU-based) - Host Profile",
  },
  {
    index: 212,
    name: "GrubtoGo (Singapore-based) - Host Profile",
  },
  {
    index: 213,
    name: "Gruv - Host Profile",
  },
  {
    index: 214,
    name: "Gwynnie Bee - Host Profile",
  },
  {
    index: 215,
    name: "Happy Renting - Host Profile (Note: Available in some European countries)",
  },
  {
    index: 216,
    name: "Happy Tails - Host Profile",
  },
  {
    index: 217,
    name: "Haute Vault - Host Profile",
  },
  {
    index: 218,
    name: "Hawaii Drone Rentals - Host Profile (Note: Specific to Hawaii)",
  },
  {
    index: 219,
    name: "HeadBox - Host Profile",
  },
  {
    index: 220,
    name: "Helbiz - Host Profile",
  },
  {
    index: 221,
    name: "Hertz - Host Profile",
  },
  {
    index: 222,
    name: "HireACamera (UK-based) - Host Profile",
  },
  {
    index: 223,
    name: "HireMeOutdoors - Host Profile",
  },
  {
    index: 224,
    name: "HiyaCar - Host Profile",
  },
  {
    index: 225,
    name: "HolidayLettings Host Profile",
  },
  {
    index: 226,
    name: "Holidog - Host Profile",
  },
  {
    index: 227,
    name: "HomeAway Host Profile",
  },
  {
    index: 228,
    name: "Homes.com - Owner / Manager Profile",
  },
  {
    index: 229,
    name: "Homesnap - Owner / Manager Profile",
  },
  {
    index: 230,
    name: "Homestay Storage - Host Profile",
  },
  {
    index: 231,
    name: "HomeToGo Host Profile",
  },
  {
    index: 232,
    name: "Homework Market - Host Profile",
  },
  {
    index: 233,
    name: "HopperStock - Host Profile",
  },
  {
    index: 234,
    name: "Hopr - Host Profile",
  },
  {
    index: 235,
    name: "Host My Pet - Host Profile",
  },
  {
    index: 236,
    name: "HostelBookers Host Profile",
  },
  {
    index: 237,
    name: "Hostelworld.com Host Profile",
  },
  {
    index: 238,
    name: "Hotels.com Host Profile",
  },
  {
    index: 239,
    name: "HotPads - Owner / Manager Profile",
  },
  {
    index: 240,
    name: "Hotwire Host Profile",
  },
  {
    index: 241,
    name: "Housesitter.com - Host Profile",
  },
  {
    index: 242,
    name: "HSS Hire (UK-based) - Host Profile",
  },
  {
    index: 243,
    name: "HURR - Host Profile (for fashion items)",
  },
  {
    index: 244,
    name: "HURR Collective - Host Profile",
  },
  {
    index: 245,
    name: "Hybrid Camera Rentals (New Zealand-based) - Host Profile",
  },
  {
    index: 246,
    name: "HyreCar - Host Profile",
  },
  {
    index: 247,
    name: "In-Lease (EU-based) - Host Profile",
  },
  {
    index: 248,
    name: "Incrediblue - Host Profile",
  },
  {
    index: 249,
    name: "Indie Campers - Host Profile",
  },
  {
    index: 250,
    name: "Infinite Style by Ann Taylor - Host Profile",
  },
  {
    index: 251,
    name: "INSTRUMENTAL Rentals - Host Profile",
  },
  {
    index: 252,
    name: "InstrumentBarn - Host Profile",
  },
  {
    index: 253,
    name: "Italki - Host Profile",
  },
  {
    index: 254,
    name: "iVvy (primarily B2B, but includes unique spaces) - Host Profile",
  },
  {
    index: 255,
    name: "JAM – Just About Music - Host Profile",
  },
  {
    index: 256,
    name: "JUMP (acquired by Lime) - Host Profile",
  },
  {
    index: 257,
    name: "Just Go Motorhome Hire - Host Profile",
  },
  {
    index: 258,
    name: "JustPark - Host Profile",
  },
  {
    index: 259,
    name: "JustPark (they have extended their services to storage) - Host Profile",
  },
  {
    index: 260,
    name: "JustShareIt - Host Profile",
  },
  {
    index: 261,
    name: "K12 - Host Profile",
  },
  {
    index: 262,
    name: "KashDash (allows renting storage sheds and other spaces) - Host Profile",
  },
  {
    index: 263,
    name: "Kayak Host Profile",
  },
  {
    index: 264,
    name: "Kerb - Host Profile",
  },
  {
    index: 265,
    name: "Kit Lender - Host Profile",
  },
  {
    index: 266,
    name: "KitSplit - Host Profile",
  },
  {
    index: 267,
    name: "KitSplit - Host Profile",
  },
  {
    index: 268,
    name: "KitSplit - Host Profile (for creative equipment)",
  },
  {
    index: 269,
    name: "Knetbooks - Host Profile",
  },
  {
    index: 270,
    name: "Kopa - Owner / Manager Profile",
  },
  {
    index: 271,
    name: "Kostad (particularly in Northern Europe) - Host Profile",
  },
  {
    index: 272,
    name: "Kroozzy - Host Profile",
  },
  {
    index: 273,
    name: "KWIPPED - Host Profile",
  },
  {
    index: 274,
    name: "KYTE - Host Profile",
  },
  {
    index: 275,
    name: "Last Minute Gear - Host Profile",
  },
  {
    index: 276,
    name: "Le Tote - Host Profile",
  },
  {
    index: 277,
    name: "LeaseRunner - Owner / Manager Profile",
  },
  {
    index: 278,
    name: "LeaseVille - Host Profile",
  },
  {
    index: 279,
    name: "Lendr - Host Profile",
  },
  {
    index: 280,
    name: "LensLocker - Host Profile",
  },
  {
    index: 281,
    name: "LensPimp (UK-based) - Host Profile",
  },
  {
    index: 282,
    name: "LensProToGo - Host Profile",
  },
  {
    index: 283,
    name: "LensProToGo - Host Profile",
  },
  {
    index: 284,
    name: "LensRentals - Host Profile",
  },
  {
    index: 285,
    name: "LensRentals - Host Profile",
  },
  {
    index: 286,
    name: "Lime - Host Profile",
  },
  {
    index: 287,
    name: "Lingoda - Host Profile",
  },
  {
    index: 288,
    name: "Livelovely.com - Owner / Manager Profile",
  },
  {
    index: 289,
    name: "Loanables - Host Profile",
  },
  {
    index: 290,
    name: "Loanables - Host Profile",
  },
  {
    index: 291,
    name: "Loanables - Host Profile (Note: This platform rents out a variety of items including musical instruments)",
  },
  {
    index: 292,
    name: "Lovely - Owner / Manager Profile",
  },
  {
    index: 293,
    name: "LowerGear Outdoors - Host Profile",
  },
  {
    index: 294,
    name: "Luxe RV Rental - Host Profile",
  },
  {
    index: 295,
    name: "Luxury Retreats Host Profile",
  },
  {
    index: 296,
    name: "Lyft (offers scooter and bike-sharing in some regions) - Host Profile",
  },
  {
    index: 297,
    name: "Machine Yard (India-based) - Host Profile",
  },
  {
    index: 298,
    name: "Mad Paws - Host Profile",
  },
  {
    index: 299,
    name: "Maven - Host Profile",
  },
  {
    index: 300,
    name: "Meet & Jam - Host Profile (Note: This platform mainly focuses on connecting musicians but also offers instrument rentals)",
  },
  {
    index: 301,
    name: "Meetings.com - Host Profile",
  },
  {
    index: 302,
    name: "Meetingsbooker - Host Profile",
  },
  {
    index: 303,
    name: "Mighway - Host Profile",
  },
  {
    index: 304,
    name: "MoBike - Host Profile",
  },
  {
    index: 305,
    name: "MonkeyParking - Host Profile",
  },
  {
    index: 306,
    name: "Motorhome Republic - Host Profile",
  },
  {
    index: 307,
    name: "Mountain Side Gear Rental - Host Profile",
  },
  {
    index: 308,
    name: "Muellermobelfabrikation (EU-based) - Host Profile",
  },
  {
    index: 309,
    name: "MUHU - Host Profile",
  },
  {
    index: 310,
    name: "Music to Your Home - Host Profile (Note: Mainly for NYC area)",
  },
  {
    index: 311,
    name: "Music Traveler - Host Profile",
  },
  {
    index: 312,
    name: "MusicRentalCentral - Host Profile",
  },
  {
    index: 313,
    name: "My Wardrobe HQ - Host Profile",
  },
  {
    index: 314,
    name: "MyCaravan - Host Profile",
  },
  {
    index: 315,
    name: "MyRentParking - Host Profile",
  },
  {
    index: 316,
    name: "MyShed - Host Profile",
  },
  {
    index: 317,
    name: "MySparrow - Host Profile (Note: Canadian drone rental platform)",
  },
  {
    index: 318,
    name: "Nautal - Host Profile",
  },
  {
    index: 319,
    name: "Neighbor - Host Profile",
  },
  {
    index: 320,
    name: "Nestor - Host Profile",
  },
  {
    index: 321,
    name: "Nestpick - Owner / Manager Profile",
  },
  {
    index: 322,
    name: "Nextdoor - Owner / Manager Profile",
  },
  {
    index: 323,
    name: "Nextdoor (not primarily for pet services but often used for finding local pet sitters/walkers) - Host Profile",
  },
  {
    index: 324,
    name: "Nomador - Host Profile",
  },
  {
    index: 325,
    name: "Nooklyn (particularly in their “Storage” listings category) - Host Profile",
  },
  {
    index: 326,
    name: "Northrop & Johnson - Host Profile",
  },
  {
    index: 327,
    name: "Nstrument - Host Profile",
  },
  {
    index: 328,
    name: "Nuuly - Host Profile",
  },
  {
    index: 329,
    name: "OccasionGenius - Host Profile",
  },
  {
    index: 330,
    name: "Ocean Independence - Host Profile",
  },
  {
    index: 331,
    name: "Ofo - Host Profile",
  },
  {
    index: 332,
    name: "Oliver Space - Host Profile",
  },
  {
    index: 333,
    name: "OnDemand Storage - Host Profile",
  },
  {
    index: 334,
    name: "Onloan - Host Profile",
  },
  {
    index: 335,
    name: "Open Shed - Host Profile",
  },
  {
    index: 336,
    name: "Open Shed - Host Profile (specific to Australia)",
  },
  {
    index: 337,
    name: "Open Shed (Australia-based) - Host Profile",
  },
  {
    index: 338,
    name: "OpenFleet - Host Profile",
  },
  {
    index: 339,
    name: "Orbitz Host Profile",
  },
  {
    index: 340,
    name: "Outdoors Geek - Host Profile",
  },
  {
    index: 341,
    name: "Outdoors Geo - Host Profile (mostly for outdoor and camping equipment)",
  },
  {
    index: 342,
    name: "Outdoors.io - Host Profile",
  },
  {
    index: 343,
    name: "Outdoorsy - Host Profile",
  },
  {
    index: 344,
    name: "Outdoorsy - Host Profile",
  },
  {
    index: 345,
    name: "Outdoorsy UK - Host Profile",
  },
  {
    index: 346,
    name: "Outdoorsy.co - Host Profile",
  },
  {
    index: 347,
    name: "Outschool - Host Profile",
  },
  {
    index: 348,
    name: "OYO Rooms Host Profile",
  },
  {
    index: 349,
    name: "Packback - Host Profile",
  },
  {
    index: 350,
    name: "PadMapper - Owner / Manager Profile",
  },
  {
    index: 351,
    name: "Palfish - Host Profile",
  },
  {
    index: 352,
    name: "Parachut - Host Profile",
  },
  {
    index: 353,
    name: "Park Circa (inactive) - Host Profile",
  },
  {
    index: 354,
    name: "Parking Cupid - Host Profile",
  },
  {
    index: 355,
    name: "Parking Panda (acquired by SpotHero) - Host Profile",
  },
  {
    index: 356,
    name: "ParkingForMe - Host Profile",
  },
  {
    index: 357,
    name: "Parklee - Host Profile",
  },
  {
    index: 358,
    name: "Parklet - Host Profile",
  },
  {
    index: 359,
    name: "ParkMobile - Host Profile",
  },
  {
    index: 360,
    name: "ParkOnMyDrive - Host Profile",
  },
  {
    index: 361,
    name: "ParkStash - Host Profile",
  },
  {
    index: 362,
    name: "ParkWhiz - Host Profile",
  },
  {
    index: 363,
    name: "ParqEx - Host Profile",
  },
  {
    index: 364,
    name: "Pavemint - Host Profile",
  },
  {
    index: 365,
    name: "Pawshake - Host Profile",
  },
  {
    index: 366,
    name: "PeerRenters - Host Profile",
  },
  {
    index: 367,
    name: "PeerRenters - Host Profile",
  },
  {
    index: 368,
    name: "Peerspace - Host Profile",
  },
  {
    index: 369,
    name: "Perlego - Host Profile",
  },
  {
    index: 370,
    name: "Pet and Home Care - Host Profile",
  },
  {
    index: 371,
    name: "PetBacker - Host Profile",
  },
  {
    index: 372,
    name: "PetCloud - Host Profile",
  },
  {
    index: 373,
    name: "PetHomeStay (merged with Mad Paws) - Host Profile",
  },
  {
    index: 374,
    name: "Petsitter.com - Host Profile",
  },
  {
    index: 375,
    name: "PetSitters Ireland - Host Profile",
  },
  {
    index: 376,
    name: "Phoenix Drone Rentals - Host Profile (Note: Specific to the Phoenix area)",
  },
  {
    index: 377,
    name: "PierSpace (focused on business-related storage but can facilitate personal storage) - Host Profile",
  },
  {
    index: 378,
    name: "Placeuse - Host Profile",
  },
  {
    index: 379,
    name: "PlantMiner (Australia-based) - Host Profile",
  },
  {
    index: 380,
    name: "Pony.bike - Host Profile",
  },
  {
    index: 381,
    name: "Poppy - Host Profile",
  },
  {
    index: 382,
    name: "Poshmark - Host Profile",
  },
  {
    index: 383,
    name: "Preply - Host Profile",
  },
  {
    index: 384,
    name: "Priceline Host Profile",
  },
  {
    index: 385,
    name: "Private Motorhome Rental - Host Profile",
  },
  {
    index: 386,
    name: "Prked - Host Profile",
  },
  {
    index: 387,
    name: "QKids - Host Profile",
  },
  {
    index: 388,
    name: "Qrent (Singapore-based) - Host Profile",
  },
  {
    index: 389,
    name: "Queenly - Host Profile (mostly for formal dresses and gowns)",
  },
  {
    index: 390,
    name: "Rabbal (India-based) - Host Profile",
  },
  {
    index: 391,
    name: "RadPad - Owner / Manager Profile",
  },
  {
    index: 392,
    name: "RAGFAiR - Host Profile",
  },
  {
    index: 393,
    name: "Realtor.com - Owner / Manager Profile",
  },
  {
    index: 394,
    name: "Redfin - Owner / Manager Profile",
  },
  {
    index: 395,
    name: "RedShelf - Host Profile",
  },
  {
    index: 396,
    name: "REI Co-op - Host Profile",
  },
  {
    index: 397,
    name: "Rent from a Pro - Host Profile",
  },
  {
    index: 398,
    name: "Rent From Rock n Roll - Host Profile",
  },
  {
    index: 399,
    name: "Rent Jungle - Owner / Manager Profile",
  },
  {
    index: 400,
    name: "Rent My Instrument - Host Profile",
  },
  {
    index: 401,
    name: "Rent the Runway - Host Profile",
  },
  {
    index: 402,
    name: "Rent-A-Center - Host Profile",
  },
  {
    index: 403,
    name: "Rent-a-Room (Hong Kong-based) - Host Profile",
  },
  {
    index: 404,
    name: "Rent-a-Tent Canada - Host Profile",
  },
  {
    index: 405,
    name: "Rent.com - Owner / Manager Profile",
  },
  {
    index: 406,
    name: "Rent4Music - Host Profile",
  },
  {
    index: 407,
    name: "RENTaCAM (Sydney based) - Host Profile",
  },
  {
    index: 408,
    name: "Rental Mental - Host Profile",
  },
  {
    index: 409,
    name: "RentalHouses.com - Owner / Manager Profile",
  },
  {
    index: 410,
    name: "Rentalic - Host Profile",
  },
  {
    index: 411,
    name: "Rentalmental - Host Profile",
  },
  {
    index: 412,
    name: "Rentdigs.com - Owner / Manager Profile",
  },
  {
    index: 413,
    name: "Rentecarlo - Host Profile",
  },
  {
    index: 414,
    name: "RentInstead - Host Profile",
  },
  {
    index: 415,
    name: "Rentler - Owner / Manager Profile",
  },
  {
    index: 416,
    name: "RentMy - Host Profile",
  },
  {
    index: 417,
    name: "RentNotBuy - Host Profile",
  },
  {
    index: 418,
    name: "RentNotBuy - Host Profile",
  },
  {
    index: 419,
    name: "Rentoid - Host Profile (specific to Australia)",
  },
  {
    index: 420,
    name: "RentoMojo - Host Profile",
  },
  {
    index: 421,
    name: "Rentomojo (India-based) - Host Profile",
  },
  {
    index: 422,
    name: "RENTPRHO - Host Profile",
  },
  {
    index: 423,
    name: "RentProductsOnline - Host Profile",
  },
  {
    index: 424,
    name: "Revel - Host Profile",
  },
  {
    index: 425,
    name: "Revolution Prep - Host Profile",
  },
  {
    index: 426,
    name: "Rnters - Host Profile",
  },
  {
    index: 427,
    name: "Road Bear RV - Host Profile",
  },
  {
    index: 428,
    name: "Rock n Roll Rentals - Host Profile",
  },
  {
    index: 429,
    name: "Roomster - Owner / Manager Profile",
  },
  {
    index: 430,
    name: "Roost - Host Profile (Note: The platform transitioned to car storage, but it might still offer personal storage options)",
  },
  {
    index: 431,
    name: "Rotaro - Host Profile",
  },
  {
    index: 432,
    name: "Rover - Host Profile",
  },
  {
    index: 433,
    name: "Rover Parking - Host Profile",
  },
  {
    index: 434,
    name: "Ruckify - Host Profile",
  },
  {
    index: 435,
    name: "Ruckify RV - Host Profile",
  },
  {
    index: 436,
    name: "RV Easy - Host Profile",
  },
  {
    index: 437,
    name: "RV Rental Outlet - Host Profile",
  },
  {
    index: 438,
    name: "RV Renters World - Host Profile",
  },
  {
    index: 439,
    name: "RVezy - Host Profile",
  },
  {
    index: 440,
    name: "RVplusYou - Host Profile",
  },
  {
    index: 441,
    name: "RVshare - Host Profile",
  },
  {
    index: 442,
    name: "Ryde - Host Profile",
  },
  {
    index: 443,
    name: "Sailo - Host Profile",
  },
  {
    index: 444,
    name: "SamBoat - Host Profile",
  },
  {
    index: 445,
    name: "SayABC - Host Profile",
  },
  {
    index: 446,
    name: "Scoot (acquired by Bird) - Host Profile",
  },
  {
    index: 447,
    name: "Share Our Style - Host Profile",
  },
  {
    index: 448,
    name: "SHAREaCAMPER - Host Profile",
  },
  {
    index: 449,
    name: "ShareGrid - Host Profile",
  },
  {
    index: 450,
    name: "ShareGrid - Host Profile",
  },
  {
    index: 451,
    name: "ShareGrid - Host Profile (focused on high-end camera equipment)",
  },
  {
    index: 452,
    name: "ShareGrid - Host Profile (more for camera equipment)",
  },
  {
    index: 453,
    name: "ShareGrid - Host Profile (Note: Primarily for camera equipment but also lists other types)",
  },
  {
    index: 454,
    name: "ShareMySpace - Host Profile",
  },
  {
    index: 455,
    name: "ShareMySpace - Host Profile",
  },
  {
    index: 456,
    name: "ShareMyStorage - Host Profile",
  },
  {
    index: 457,
    name: "SharePlant - Host Profile",
  },
  {
    index: 458,
    name: "Sharoo - Host Profile",
  },
  {
    index: 459,
    name: "ShedSpace - Host Profile",
  },
  {
    index: 460,
    name: "Sitly - Host Profile",
  },
  {
    index: 461,
    name: "Sittercity - Host Profile",
  },
  {
    index: 462,
    name: "Skedda (More for booking rooms in a communal workspace but can be utilized for events) - Host Profile",
  },
  {
    index: 463,
    name: "Skip - Host Profile",
  },
  {
    index: 464,
    name: "Skoob - Host Profile (Note: Specific to Brazil)",
  },
  {
    index: 465,
    name: "Skooli - Host Profile",
  },
  {
    index: 466,
    name: "SkyLens Rental - Host Profile",
  },
  {
    index: 467,
    name: "SlugBooks - Host Profile",
  },
  {
    index: 468,
    name: "Smarter Storage Solutions (UK-based peer-to-peer storage platform) - Host Profile",
  },
  {
    index: 469,
    name: "Smarthinking (Pearson) - Host Profile",
  },
  {
    index: 470,
    name: "SnappCar - Host Profile",
  },
  {
    index: 471,
    name: "Snappening - Host Profile",
  },
  {
    index: 472,
    name: "Sniffspot - Host Profile",
  },
  {
    index: 473,
    name: "SocialCar - Host Profile",
  },
  {
    index: 474,
    name: "Spacebase - Host Profile",
  },
  {
    index: 475,
    name: "Spacer - Host Profile",
  },
  {
    index: 476,
    name: "Spacer - Host Profile",
  },
  {
    index: 477,
    name: "Spacesift - Host Profile",
  },
  {
    index: 478,
    name: "SpareFoot - Host Profile",
  },
  {
    index: 479,
    name: "SpareRoom (Meeting Rooms category) - Host Profile",
  },
  {
    index: 480,
    name: "Sparkplug - Host Profile",
  },
  {
    index: 481,
    name: "Sparkplug - Host Profile (for musical instruments)",
  },
  {
    index: 482,
    name: "Speedy Hire (UK-based) - Host Profile",
  },
  {
    index: 483,
    name: "Spin - Host Profile",
  },
  {
    index: 484,
    name: "Splacer - Host Profile",
  },
  {
    index: 485,
    name: "Spot - Host Profile",
  },
  {
    index: 486,
    name: "SpotHero - Host Profile",
  },
  {
    index: 487,
    name: "SpotPog - Host Profile",
  },
  {
    index: 488,
    name: "Stashbee - Host Profile",
  },
  {
    index: 489,
    name: "Stashbee - Host Profile",
  },
  {
    index: 490,
    name: "Storage Together - Host Profile",
  },
  {
    index: 491,
    name: "Store Friendly - Host Profile",
  },
  {
    index: 492,
    name: "StoreAtMyHouse - Host Profile",
  },
  {
    index: 493,
    name: "StoreEasy - Host Profile",
  },
  {
    index: 494,
    name: "Storefront - Host Profile (Mainly for retail spaces but includes event spaces)",
  },
  {
    index: 495,
    name: "StoreMe (mobile app that allows short-term storage rentals) - Host Profile",
  },
  {
    index: 496,
    name: "StoreWithMe - Host Profile",
  },
  {
    index: 497,
    name: "StreetEasy - Owner / Manager Profile",
  },
  {
    index: 498,
    name: "Student 2 Student - Host Profile",
  },
  {
    index: 499,
    name: "Studio Instrument Rentals (SIR) - Host Profile",
  },
  {
    index: 500,
    name: "Studiohire (focused on studio equipment rental, UK-based) - Host Profile",
  },
  {
    index: 501,
    name: "Studiotime - Host Profile (for renting music studios)",
  },
  {
    index: 502,
    name: "Studypool - Host Profile",
  },
  {
    index: 503,
    name: "Style Lend - Host Profile",
  },
  {
    index: 504,
    name: "Sublet.com - Owner / Manager Profile",
  },
  {
    index: 505,
    name: "Summit Hut - Host Profile",
  },
  {
    index: 506,
    name: "SUPrents - Host Profile (focus on stand-up paddleboards)",
  },
  {
    index: 507,
    name: "Swap Society - Host Profile",
  },
  {
    index: 508,
    name: "Swivelfly - Host Profile",
  },
  {
    index: 509,
    name: "Tagvenue - Host Profile",
  },
  {
    index: 510,
    name: "Tailster - Host Profile",
  },
  {
    index: 511,
    name: "TakeLessons - Host Profile",
  },
  {
    index: 512,
    name: "Teachaway - Host Profile",
  },
  {
    index: 513,
    name: "TentShare - Host Profile",
  },
  {
    index: 514,
    name: "Textbook Solutions - Host Profile",
  },
  {
    index: 515,
    name: "Textbook Spyder - Host Profile",
  },
  {
    index: 516,
    name: "TextbookRush - Host Profile",
  },
  {
    index: 517,
    name: "Textbookx - Host Profile",
  },
  {
    index: 518,
    name: "Textsurf - Host Profile",
  },
  {
    index: 519,
    name: "The Camp Kit - Host Profile",
  },
  {
    index: 520,
    name: "The Endless Wardrobe - Host Profile",
  },
  {
    index: 521,
    name: "The Hold (app-based storage solution) - Host Profile",
  },
  {
    index: 522,
    name: "The Lens Depot - Host Profile (primarily for lens and camera equipment)",
  },
  {
    index: 523,
    name: "The LensPal - Host Profile",
  },
  {
    index: 524,
    name: "The Music Loft - Host Profile",
  },
  {
    index: 525,
    name: "The Quiver - Host Profile (specifically for surfing equipment)",
  },
  {
    index: 526,
    name: "The Sound Parcel - Host Profile",
  },
  {
    index: 527,
    name: "The Venue Report - Host Profile",
  },
  {
    index: 528,
    name: "The Volte - Host Profile",
  },
  {
    index: 529,
    name: "The Yard Club - Host Profile",
  },
  {
    index: 530,
    name: "TheYachtMarket - Host Profile",
  },
  {
    index: 531,
    name: "This Is Cameraland - Host Profile (camera equipment)",
  },
  {
    index: 532,
    name: "Thisopenspace - Host Profile",
  },
  {
    index: 533,
    name: "Tier - Host Profile",
  },
  {
    index: 534,
    name: "ToolMates Hire - Host Profile",
  },
  {
    index: 535,
    name: "ToolzDo - Host Profile",
  },
  {
    index: 536,
    name: "ToolzDo - Host Profile",
  },
  {
    index: 537,
    name: "Travelocity Host Profile",
  },
  {
    index: 538,
    name: "Trivago Host Profile",
  },
  {
    index: 539,
    name: "Tripadvisor Host Profile",
  },
  {
    index: 540,
    name: "Trringo (India-based) - Host Profile",
  },
  {
    index: 541,
    name: "Trulia - Owner / Manager Profile",
  },
  {
    index: 542,
    name: "TrustedHousesitters - Host Profile",
  },
  {
    index: 543,
    name: "Tubbber - Host Profile",
  },
  {
    index: 544,
    name: "Tujia Host Profile",
  },
  {
    index: 545,
    name: "Tulerie - Host Profile",
  },
  {
    index: 546,
    name: "TurboTenant - Owner / Manager Profile",
  },
  {
    index: 547,
    name: "Turo Gear - Host Profile (camera and film equipment)",
  },
  {
    index: 548,
    name: "Tutor.com - Host Profile",
  },
  {
    index: 549,
    name: "TutorMe - Host Profile",
  },
  {
    index: 550,
    name: "UAV Rentals - Host Profile",
  },
  {
    index: 551,
    name: "Uber (offers scooter and bike-sharing under Uber Micro) - Host Profile",
  },
  {
    index: 552,
    name: "Unique Venues - Host Profile",
  },
  {
    index: 553,
    name: "Up Sonder - Host Profile",
  },
  {
    index: 554,
    name: "Urban Locker (primarily UK based) - Host Profile",
  },
  {
    index: 555,
    name: "ValoreBooks - Host Profile",
  },
  {
    index: 556,
    name: "Varsity Tutors - Host Profile",
  },
  {
    index: 557,
    name: "Venuefinder - Host Profile",
  },
  {
    index: 558,
    name: "VenueLook (India-based) - Host Profile",
  },
  {
    index: 559,
    name: "Venuse - Host Profile",
  },
  {
    index: 560,
    name: "Venuu - Host Profile",
  },
  {
    index: 561,
    name: "Verbling - Host Profile",
  },
  {
    index: 562,
    name: "VillageLuxe - Host Profile",
  },
  {
    index: 563,
    name: "VintageAndRare - Host Profile (Note: Mainly a sales platform but also offers rental options for rare instruments)",
  },
  {
    index: 564,
    name: "VIPKid - Host Profile",
  },
  {
    index: 565,
    name: "VIPX - Host Profile",
  },
  {
    index: 566,
    name: "Vistek (Canada-based) - Host Profile",
  },
  {
    index: 567,
    name: "VitalSource - Host Profile",
  },
  {
    index: 568,
    name: "Voi - Host Profile",
  },
  {
    index: 569,
    name: "VRBO Host Profile",
  },
  {
    index: 570,
    name: "Wag! - Host Profile",
  },
  {
    index: 571,
    name: "Walk Score - Owner / Manager Profile",
  },
  {
    index: 572,
    name: "Wedio (Europe-focused platform that caters to photographers and filmmakers) - Host Profile",
  },
  {
    index: 573,
    name: "WhereiPark - Host Profile",
  },
  {
    index: 574,
    name: "Whimstay - Host Profile",
  },
  {
    index: 575,
    name: "Wildhire - Host Profile",
  },
  {
    index: 576,
    name: "Wind - Host Profile",
  },
  {
    index: 577,
    name: "WizIQ - Host Profile",
  },
  {
    index: 578,
    name: "WonderKit - Host Profile",
  },
  {
    index: 579,
    name: "Xscape Pod - Host Profile",
  },
  {
    index: 580,
    name: "Yacht Charter Worldwide - Host Profile",
  },
  {
    index: 581,
    name: "Yacht Harbour - Host Profile",
  },
  {
    index: 582,
    name: "Yachtico - Host Profile",
  },
  {
    index: 583,
    name: "YachtLife - Host Profile",
  },
  {
    index: 584,
    name: "Yard Club (Acquired by Caterpillar) - Host Profile",
  },
  {
    index: 585,
    name: "YardYum (allows renting space in someone's yard for storage) - Host Profile",
  },
  {
    index: 586,
    name: "YCloset (China-based) - Host Profile",
  },
  {
    index: 587,
    name: "Yellow (Brazil-based service) - Host Profile",
  },
  {
    index: 588,
    name: "Yescapa - Host Profile",
  },
  {
    index: 589,
    name: "Yescapa - Host Profile",
  },
  {
    index: 590,
    name: "YourParkingSpace - Host Profile",
  },
  {
    index: 591,
    name: "Yulu - Host Profile",
  },
  {
    index: 592,
    name: "Yup - Host Profile",
  },
  {
    index: 593,
    name: "Zagster - Host Profile",
  },
  {
    index: 594,
    name: "Zillow - Owner / Manager Profile",
  },
  {
    index: 595,
    name: "Zilok - Host Profile",
  },
  {
    index: 596,
    name: "Zilok - Host Profile",
  },
  {
    index: 597,
    name: "Zipcar - Host Profile",
  },
  {
    index: 598,
    name: "Zizoo - Host Profile",
  },
  {
    index: 599,
    name: "Zookal - Host Profile (Note: Specific to Australia)",
  },
];
