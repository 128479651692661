export default function RatingBar(props) {
  const { currentRatingFilterSpecification } = props;
  const { applyRatingFilterSpecification } = props;
  const { currentRatingFilter } = props;
  const { applyRatingFilter } = props;
  const { showRatingDropdown } = props;
  const { resetRating } = props;
  return (
    <div className="relative flex flex-col md:flex-row justify-around items-center gap-2  w-full md:w-4/12">
      <div className="flex flex-col justify-between justify-center items-center gap-1 text-xs mt-1 z-99999 w-full">
        <span>
          {currentRatingFilterSpecification
            ? currentRatingFilterSpecification.label
            : ""}
        </span>
        <div className="flex justify-between justify-center w-8/12">
          {" "}
          <i
            className={`fa fa-star cursor-pointer  ${
              currentRatingFilter >= 1 ? "text-yellow-500" : ""
            }`}
            onClick={() => applyRatingFilter(1)}
          ></i>
          <i
            className={`fa fa-star cursor-pointer   ${
              currentRatingFilter >= 2 ? "text-yellow-500" : ""
            }`}
            onClick={() => applyRatingFilter(2)}
          ></i>
          <i
            className={`fa fa-star cursor-pointer   ${
              currentRatingFilter >= 3 ? "text-yellow-500" : ""
            }`}
            onClick={() => applyRatingFilter(3)}
          ></i>
          <i
            className={`fa fa-star cursor-pointer   ${
              currentRatingFilter >= 4 ? "text-yellow-500" : ""
            }`}
            onClick={() => applyRatingFilter(4)}
          ></i>
          <i
            className={`fa fa-star cursor-pointer   ${
              currentRatingFilter >= 5 ? "text-yellow-500" : ""
            }`}
            onClick={() => applyRatingFilter(5)}
          ></i>
        </div>
        <div>
          {" "}
          <span>Reset Filter(s)</span>
          <i
            class="fas fa-undo border shadow p-1 text-black mx-2 cursor-pointer"
            aria-hidden="true"
            onClick={() => resetRating()}
          ></i>
        </div>
        <div
          className={`w-8/12 absolute ${!showRatingDropdown ? "hidden" : ""}`}
          style={{ top: "60px" }}
        >
          <div className="bg-white rounded-lg shadow p-2">
            <ul>
              <li
                className="cursor-pointer hover:bg-gray-200 text-sm"
                onClick={() =>
                  applyRatingFilterSpecification({
                    label: " Exact Rating",
                    value: "exact",
                  })
                }
              >
                Exact Rating
              </li>
              <li
                className="cursor-pointer hover:bg-gray-200 text-sm"
                onClick={() =>
                  applyRatingFilterSpecification({
                    label: "Exact or Less",
                    value: "exact-less",
                  })
                }
              >
                Exact or Less
              </li>
              <li
                className="cursor-pointer hover:bg-gray-200 text-sm"
                onClick={() =>
                  applyRatingFilterSpecification({
                    label: "Exact or More",
                    value: "exact-more",
                  })
                }
              >
                Exact or More
              </li>
            </ul>{" "}
            <label className="text-xxs text-blue-500">Specify Rating:</label>
          </div>
        </div>
      </div>
    </div>
  );
}
