export default function AccordionButton(props) {
  return (
    <button
      className="border border-gray rounded-lg px-2 py-1 m-1"
      onClick={() => {
        props.showAccordionModalCreateBox();
      }}
    >
      <span className="px-1">Add Accordion</span>
    </button>
  );
}
