import { toast } from "react-toastify";
import StripeCheckout from "react-stripe-checkout";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import BasicButton from "../components/buttons/basic-button";
import { authToken, authUserData, API_BASE, STRIPE_PK } from "../Utils/helpers";
import axios from "axios";

function StripeCustomPageForm({
  data,
  computeMetaQuauntityValue,
  action,
  classes,
  title,
}) {
  //   alert(JSON.stringify(data));

  const product = {
    name: data.name,
    price: data.price,
    description: data.description,
    page_uuid: data.page_id,
    model_type: data.model_type,
    message_to_buyer: data.hireText,
    messageg_to_seller: data.hireReply,
    block_meta_id: data.block_meta_id,
    quantity: data.quantity,
    sel_fulfilment_location: data.sel_fulfilment_location,
    sel_fulfilment_charge: data.sel_fulfilment_charge,
    reply: data.reply,
  };

  const [processing, setProcessing] = useState(false);

  const headers = {
    accept: "application/json",
    Authorization: "bearer " + authToken(),
  };

  async function handleToken(token, addresses) {
    try {
      setProcessing(true);
      const response = await axios.request({
        method: "post",
        headers: headers,
        url: API_BASE + "/api/stripe/custom-page/charge",
        data: {
          token,
          product,
          data,
          user_uuid: authUserData().uuid,
        },
      });
      if (response.status === 200) {
        setProcessing(false);
        toast("Payment Successful!", { type: "success" });
        setTimeout(() => {
          action && action();
          window.location.reload();
        }, 3000);
      } else {
        setProcessing(false);
      }
    } catch (error) {
      setProcessing(false);
      toast("Payment error, please try again later!", { type: "error" });
    }
  }

  useEffect(() => {}, []);

  return (
    <StripeCheckout
      class="center"
      stripeKey={STRIPE_PK}
      token={handleToken}
      amount={`$${product.price * 100}`}
      name={data.name}
      disabled={processing}
    >
      <BasicButton
        title={`${data.name ? data.name : "Pay"} $${data.price}`}
        disabled={
          processing ||
          ((computeMetaQuauntityValue() == "" ||
            computeMetaQuauntityValue() == 0) &&
            data.meta.quantity_enabled) ||
          data.meta.hide_button ||
          data.meta.make_inactive
        }
        classes={classes ? classes : ""}
      />
    </StripeCheckout>
  );
}

export default StripeCustomPageForm;
