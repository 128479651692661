import {
  removeAtomicBlocFromEditor,
  editEditorImaeAtBlockPosition,
} from "../helpers/functions";
import { deleteEditorFileRequest } from "../API/editor-api-calls";
import { useBookStore } from "../../../../stores/book-store";
import { convertToRaw } from "draft-js";
import { toast } from "react-toastify";
import { useState } from "react";

export default function ProductMeta({
  pageId,
  blockKey,
  payload,
  editorState,
  setEditorState,
  editorStateHelper,
  showProductMetaModalUpdateBox,
  buttonIds,
  productMetas,
}) {
  const { isDataSubExpired } = useBookStore((state) => state);
  const deleteEditorHire = () => {
    removeAtomicBlocFromEditor(
      blockKey,
      editorState,
      setEditorState,
      editorStateHelper
    );
  };

  const cannotBeDeleted = () => {
    if (!productMetas.length) return false;

    return false;
  };

  return (
    <div class="flex flex-wrap justify-center mb-5">
      {/* {JSON.parse(payload).block_meta_id} */}
      {/* <p className="w-30 z-80"> {payload}</p> */}
      <div
        className="border bg-gray-50 p-2 rounded-lg flex gap-2 items-center"
        contenteditable="false"
      >
        {/* {`product-page-editor-button-${JSON.parse(payload).block_meta_id}`} */}
        <span className="text-black font-bold">
          {JSON.parse(payload).p_type_sub_opt} ${JSON.parse(payload).price}
        </span>{" "}
        {!isDataSubExpired ? (
          <span
            className="cursor-pointer"
            onClick={() => {
              return null;
              showProductMetaModalUpdateBox(payload, blockKey);
            }}
          >
            {/* <i class="fa fa-edit text-blue-500  z-50 text-2xl"></i> */}
          </span>
        ) : (
          ""
        )}
        {!isDataSubExpired && !cannotBeDeleted() ? (
          <span
            className="cursor-pointer"
            onClick={() => {
              deleteEditorHire();
            }}
          >
            <i
              class={`fa fa-times-circle text-red-500  z-50 text-2xl product-page-editor-button-${
                JSON.parse(payload).block_meta_id
              }`}
            ></i>
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
