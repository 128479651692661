import TextField from "../../components/inputs/text-input";
import BasicButton from "../../components/buttons/basic-button";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  authUserData,
  isAuthUser,
  isAdmin,
  API_BASE,
  BASE_URL,
  isPublisher,
  isSupplier,
} from "../../Utils/helpers";
import { toast } from "react-toastify";
import BookSliderGridAdmin from "../../components/slider/book-slider-grid-admin";
import BookSliderGridPublisher from "../../components/slider/dashboard/book-slider-publisher-grid";
import authRequestService from "../../services/HttpRequests/authenticated-request-services";

import ProfileList from "../../components/user/profiles/list";
import { isDataSubExpired } from "../../services/DataThreshold";
import axios from "axios";
export default function PublisherPublicationOptions() {
  const [publisher, setPublisher] = useState({});
  const [platformModalOpen, setPlatformModalOpen] = useState(false);
  const [signupSuccess, setShowSignupSuccess] = useState(false);
  const [books, setBooks] = useState([]);
  const { publisheruuid } = useParams();
  const [activeTab, setactActiveTab] = useState(0);
  const [fileSelected, setFileSelected] = useState();
  const [profileText, setProfileText] = useState("");
  const [showRatingDropdown, setShowRatingDropdown] = useState(false);
  const [currentRating, setCurrentRating] = useState(0);

  const userId = authUserData().uuid;
  const applyRatingFilter = (value, book) => {};

  const renderSupplierActionButton = (status) => {
    return status == "resume" || status == "redo";
  };

  const getSupplierActionText = () => {
    if (localStorage.getItem("resume_supplier_application") == "redo")
      return "Redo Application";
    if (localStorage.getItem("resume_supplier_application") == "resume")
      return "Resume Application";
  };
  const getPublisher = () => {
    let url = API_BASE + "/publisher/" + authUserData().uuid + "/optimized";
    return authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setPublisher(response.data.publisher);
          if (
            renderSupplierActionButton(
              response?.data?.publisher?.supplier_status
            )
          ) {
            localStorage.setItem(
              "resume_supplier_application",
              response?.data?.publisher?.supplier_status
            );
          }
        }
      })
      .catch((error) => {
        // dddgdgd
        return publisher;
      });
  };

  useEffect(() => {
    if (!isPublisher()) {
      window.location.href = "/";
    }
    if (isSupplier()) {
      localStorage.removeItem("resume_supplier_application");
    }
    // getPublisher();
  }, []);

  const showResumeApplicationButton = () => {
    return localStorage.getItem("resume_supplier_application") !== null;
  };

  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow items-center">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold flex flex-col md:flex-row">
            <span className="text-lg font-bold">
              {publisher && publisher.name}
            </span>
          </h1>
        </div>

        <div className="flex flex-row gap-2">
          {showResumeApplicationButton() && (
            <BasicButton
              title={getSupplierActionText()}
              classes="my-bg-yellow-500 text-black hidden md:block"
              handleClick={() =>
                (window.location.href = `/publisher/supplier/onboarding/start`)
              }
            />
          )}

          {!isDataSubExpired(publisher) ? (
            <BasicButton
              title={"Create"}
              classes="bg-teal-500 hover:bg-teal-500"
              handleClick={() =>
                (window.location.href = `/publisher/create/options`)
              }
            />
          ) : (
            ""
          )}

          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <BookSliderGridPublisher
        publisherId={userId}
        publisher={publisher}
        getPublisher={getPublisher}
      />
    </>
  );
}
