import { BASE_URL } from "../../../../Utils/helpers";
import BasicButton from "../../../buttons/basic-button";
import RenderProfilePhotoCircle from "./circle-avatar";
export default function RenderCustomListBook(props) {
  const { book } = props;

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const renderDisplayImage = (data) => {
    if (data?.type == "list") {
      return BASE_URL + "/storage" + data?.book_list_thumb?.split("public")[1];
    }
    if (data?.cover_photo_thumb) {
      return (
        BASE_URL + "/storage" + data?.cover_photo_thumb?.split("public")[1]
      );
    }

    if (data?.productimages?.length) {
      return (
        BASE_URL +
        "/storage" +
        data?.productimages[0]?.product_thumb?.split("public")[1]
      );
    }

    if (data?.owner_optimized_data?.profile_photo) {
      return (
        BASE_URL +
        "/storage/" +
        data?.owner_optimized_data?.profile_photo?.split("public")[1]
      );
    } else {
      return (
        BASE_URL +
        "/storage/" +
        data?.owner_optimized_data?.backup_profile_photo?.split("public")[1]
      );
    }
  };
  return (
    <div
      className="flex flex-col items-center w-full cursor-pointer"
      onClick={() => openInNewTab(`/web/lists/${book.uuid}/items`)}
    >
      <div className="w-full mb-2">
        <BasicButton
          classes={"my-black text-white px-8"}
          title={"View Item"}
          handleClick={() =>
            (window.location.href = `/web/lists/${book.uuid}/items`)
          }
        />
      </div>
      <div className="relative w-full flex w-48 flex-row justify-center gap-4  z-50 cursor-pointer relative">
        <img
          className="w-24 h-24 md:w-36 md:h-36 object-cover rounded-full"
          onClick={() => {
            {
              /* window.location.href = "/web/rich-page/render/" + book.uuid; */
            }
          }}
          src={renderDisplayImage(book)}
        />
        <div className="absolute absolute bottom-5 right-4 md:-right-3 h-16 w-16  rounded-lg flex items-center justify-center">
          <RenderProfilePhotoCircle book={book} />
        </div>
      </div>
      <div className="w-full flex flex-col items-center">
        {" "}
        <div className="flex  flex-col justify-center cursor-pointer">
          <div className="flex justify-center gap-2">
            <div className="flex"> By:</div>
            <div className="flex">{book.owneroptimized.name}</div>
          </div>
        </div>
        <p>
          {" "}
          <span className="text-yellow-600 text-xs font-bold">
            {book.owneroptimized.username}
          </span>
        </p>
        <div>
          <h1 className="font-bold text-xl text-center">{book.name}</h1>
        </div>
      </div>
    </div>
  );
}
