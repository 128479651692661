import {
  isAuthUser,
  authUserData,
  isAdmin,
  isPublisher,
  isReader,
  isResponsiveMode,
  isSales,
  isSponsor,
} from "../../../../Utils/helpers";
export default function PublisherActivationBar(props) {
  const { location } = props;
  const isActivationPage = () => {
    return location.pathname.includes("user/activation/publisher/");
  };

  const readerNotOnActivationPage = () =>
    isAuthUser() &&
    !isAdmin() &&
    !isSales() &&
    !isPublisher() &&
    !isSponsor() &&
    !isActivationPage();
  //old link to monetization options window.location.href = "/user/activation/options
  return readerNotOnActivationPage() ? (
    <div className="w-full flex justify-center self-center">
      <button
        onClick={() => (window.location.href = "/user/activation/supplier")}
        class="text-base font-medium text-white hover:text-white mx-2 bg-black rounded-lg p-2 w-full text-center"
      >
        <span>Become a Monetizer</span>
      </button>
    </div>
  ) : (
    ""
  );
}
