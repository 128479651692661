import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { stateToHTML } from "draft-js-export-html";
import { useState, useEffect, useRef } from "react";
import Hire from "../components/delete-insert-hire";
import Image from "../components/delete-insert-image";
import Video from "../components/delete-insert-video";
import Advert from "../components/delete-insert-advert";
import Table from "../components/delete-insert-table";
import Accordion from "../components/delete-insert-accordion";
import ButtonLinkBox from "../components/delete-button-link";
import { useBookStore } from "../../../../stores/book-store";
import BugReportNotice from "../components/bug-report-notice";
import TextField from "../../../../components/inputs/text-input";
import SelectPurchaseOptions from "./components/purchase-options";
import ProductMeta from "../components/delete-insert-product-meta";
import SignupForUpdatesBox from "../components/delete-email-signup";
import SelectPurchaseModal from "./components/select-purchase-modal";
import BasicButton from "../../../../components/buttons/basic-button";
import { isDataSubExpired } from "../../../../services/DataThreshold";
import DeletePromptModal from "../../../../modals/delete-warning-modal";
import MultiImageUploaderNew from "../components/image-upload-multi-new";
import ButtonLinkModalEditor from "../../../../modals/button-link-modal";
import HireModalEditor from "../../../../modals/hire-insert-modal-editor";
import FreeContentCoverLinks from "../components/free-content-cover-links";
import SocialLinksEmbed from "../components/delete-insert-social_links";
import SocialImageEmbed from "../components/delete-insert-social-embed";
import ImageModalEditor from "../components/image-button/components/image-insert-modal-editor";
import PaymentControlsModal from "../../../../modals/payment-controls-modal";
import PublisherTutorialBar from "../../../../layout/nav/header/mobile/tutorial-bar";
import authRequestService from "../../../../services/HttpRequests/authenticated-request-services";
import SelectImagesModal from "./components/select-images-modal";
import Slides from "../components/delete-insert-slides";
import Banners from "../components/delete-insert-banners";
import SocialsLinkEditor from "../../../../modals/socials-link-modal";
import TableEditor from "../components/table-button/components/table-modal";
import AccordionEditor from "../components/accordion-button/components/accordion-modal";

import {
  isAdmin,
  API_BASE,
  isPublisher,
  BASE_URL,
  isResponsiveMode,
  isAuthUser,
  getLocation,
} from "../../../../Utils/helpers";
import { COLOR_TYPES } from "../editor-objects";

import {
  genKey,
  Modifier,
  RichUtils,
  EditorState,
  ContentBlock,
  convertToRaw,
  convertFromRaw,
  SelectionState,
  AtomicBlockUtils,
  getDefaultKeyBinding,
  CharacterMetadata,
} from "draft-js";
import {
  insertLink,
  makePremium,
  changeIndent,
  insertButtonLink,
  htmlRenderOptions,
  insertVideoIntoEditor,
  insertHireIntoEditor,
  htmlRenderOptionsFree,
  insertAdvertIntoEditor,
  insertImageIntoEditor,
  insertProductMetaIntoEditor,
  insertSignupBoxIntoEditor,
  insertSlideIntoEditor,
  insertBannerIntoEditor,
  insertSocialEmbedIntoEditor,
  insertTableIntoEditor,
  insertAccordionIntoEditor,
  insertSocialImageLinksIntoEditor,
} from "../helpers/functions";

import "../rich.css";
import uuid from "react-uuid";
import "draft-js/dist/Draft.css";
import Immutable from "immutable";
import Editor from "@draft-js-plugins/editor";
import ToolBar from "../components/toolbar-bar";
import BookCoverUpload from "../components/book-cover-upload";
import createLinkifyPlugin from "draft-js-link-detection-plugin";
import ColorPicker, { colorPickerPlugin } from "draft-js-color-picker";
import AdInsertModalEditor from "../../../../modals/ad-insert-modal-editor";
import LinkModalEditor from "../../../../modals/link-insert-modal-editor";
import VideoModalEditor from "../../../../modals/video-insert-modal-editor";
import TierUpgradeNoticeModal from "../../../../modals/data-tier-upgrade-notice";
import ProductCalendarModal from "../../../../components/product-calendar";
import BannerModal from "../components/banner-button/components/banner-modal";

export default function UpdateRichProductPage() {
  const initialProductMeta = {
    purchase_type: 0,
    price: "",
    quantity: "",
    quantity_enabled: false,
    quantity_sel_enabled: false,
    quantity_merge: "",
    p_type_sub_opt: "",
    re_type_sub_opt: "",
    make_active: false,
    hide_button: false,
    after_purchase: "",
    purchaser_reply: "",
    purchaser_reply_enabled: false,
    location_fulfilment: [{ location: "", charge: "" }],
    blocked_dates: [],
    block_weekend_start: false,
    booking_interruption: true,
    short_durr_on_preferred_available: true,
    supplier_customer_service_id: "",
    future_availability: "",
    subscription_cap_enabled: false,
    default_contract_name: "",
    contract_type: "",
    merges: [],
    block_meta_id: uuid(),
  };

  const [publisher, setPublisher] = useState({});
  const [name, setName] = useState("");
  const [data, setData] = useState("");
  const [page, setPage] = useState({});
  const [price, setPrice] = useState("");
  const [processing, setProcessing] = useState(false);
  const [coverPhoto, setCoverPhoto] = useState("");
  const [pageCoverPhoto, setPageCoverPhoto] = useState("");
  const [coverFileSelected, setCoverFileSelected] = useState("");
  const [editorPhoto, setEditorPhoto] = useState("mm");
  const [editorFileSelected, setEditorFileSelected] = useState("");
  const [fileSelected, setFileSelected] = useState("");
  const [editorOutput, setEditorOutput] = useState("");
  const [showAdModal, setShowAdModal] = useState(false);
  const [showPOModal, setShowPOModal] = useState(false);
  /*product slides*/
  const [productSlides, setProductSlides] = useState([]);
  const [uploadingSlides, setUploadingSlides] = useState(false);
  const [deletingSlides, setDeletingSlides] = useState(false);
  /*product slides*/

  const [showPaymentControlsModal, setShowPaymentControlsModal] =
    useState(false);
  const [currPastedLink, setCurrPastedLink] = useState("");
  /*video and image state variables*/
  const [currPastedVideoLink, setCurrPastedVideoLink] = useState("");
  const [videoFileSelected, setVideoFileSelected] = useState("");
  const [videoFileData, setVideoFileData] = useState("");
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [currPastedImageLink, setCurrPastedImageLink] = useState("");
  const [imageFileSelected, setImageFileSelected] = useState("");
  const [imageFileData, setImageFileData] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [currEditingImage, setCurrEditingImage] = useState("");
  const [currEditingImagePreview, setCurrEditingImagePreview] = useState("");
  const [createImageUploadMode, setCreateImageUploadMode] = useState(true);
  const [currImageEditBlockKey, setCurrImageEditBlockKey] = useState("");
  /*video and image state variables*/

  /*advert*/
  const [advertRequestStatus, setAvertRequestStatus] = useState("");
  /*advert*/

  /*hire button*/
  const [showHireModal, setShowHireModal] = useState(false);
  const [currHireText, setCurrHireText] = useState("");
  const [currHireResponseStatus, setCurrHireResponseStatus] = useState(false);
  const [createHireMode, setCreateHireMode] = useState(true);
  const [currHireEditBlockKey, setCurrHireEditBlockKey] = useState(false);
  const [currHireReplySubject, setCurrHireReplySubject] = useState("");
  const [currHirePrice, setCurrHirePrice] = useState("");
  /*hire button*/

  /*Social Images Insert*/
  const [authTokens, setAuthTokens] = useState([]);
  const [selectedSocialPhoto, setSelectedSocialPhoto] = useState([]);
  const [socialEmbedValue, setSocialEmbedValue] = useState("");
  const [socialUploadType, setSocialUploadType] = useState("");
  const [socialUploadSubType, setSocialUploadSubType] = useState("");

  /*select purchase*/
  const [currProductMetaBlockKey, setCurrProductMetaBlockKey] = useState("");
  const [selectPurchaseModalOpen, setSelectPurchaseModelOpen] = useState(false);
  const [createProductMetaMode, setCreateProductMetaMode] = useState("");
  const [productMetaData, setProductMetaData] = useState({});
  const [productContracts, setProductContracts] = useState([]);
  const [productDefaultContracts, setProductDefaultContracts] = useState([]);
  const [customerServiceSets, setCustomerServiceSets] = useState([]);
  const [productMeta, setProductMeta] = useState(initialProductMeta);
  const [productMetas, setProductMetas] = useState([]);
  const [metaMode, setMetaMode] = useState(0);

  /*select images*/
  const [selectImagesModalOpen, setSelectImagesModalOpen] = useState(false);
  const [selectedSlides, setSelectedSlides] = useState([]);

  /*select banners*/
  const [bannerModalOpen, setBannerModalOpen] = useState(false);
  const [bannerSlides, setBannerSlides] = useState([]);
  const [currEditingBannerId, setCurrEditingBannerId] = useState("");
  const [createBannerMode, setCreateBannerMode] = useState(true);

  /*socials*/
  const [socialLinkValue, setSocialLinkValue] = useState({});
  const [socialLinksModelOpen, setSocialLinksModalOpen] = useState(false);

  /*table*/
  const initialTableColumn = {
    header: { placeholder: "Enter Column Header", value: "" },
    rows: [{ placeholder: "Fill Row: Type here...", value: "" }],
  };
  const [tableColumns, setTableColumns] = useState([initialTableColumn]);
  const [currTableEditBlockKey, setCurrTableEditBlockKey] = useState("");
  const [createTableMode, setCreateTableMode] = useState(true);
  const [showTableModal, setShowTableModal] = useState(false);
  const [tableTitle, setTableTitle] = useState("");
  /*table*/

  /*accordion*/
  const initialAccordion = {
    id: uuid(),
    header: { placeholder: "Enter Header Name", value: "" },
    body: { placeholder: "Type here...", value: "" },
    media: [],
  };
  const [showAccordionModal, setShowAccordionModal] = useState(false);
  const [accordions, setAccordions] = useState([]);
  const [currAccordionEditBlockKey, setCurrAccordionEditBlockKey] =
    useState("");
  const [createAccordionMode, setCreateAccordionMode] = useState(true);
  const [accordionTitle, setAccordionTitle] = useState("");
  const [accordionId, setAccordionId] = useState("");
  /*accordion*/

  const [showLinkModal, setShowLinkModal] = useState(false);
  const [buttonLinkModal, setShowButtonLinkModal] = useState(false);
  const [defaultFontSize, setDefaultFontSize] = useState("");
  const [linkModifyMode, setLinkModifyMode] = useState(false);
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [currentEditingAdId, setcurrentEditingAdId] = useState();
  const [selectionHasLink, setSelectionHasLink] = useState(false);
  const [currentSelectedLink, setCurrentSelectedLink] = useState({});
  const [selectionAnchors, setSelectionAnchors] = useState({
    start: null,
    end: null,
  });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [shadowEditorState, setShadowEditorState] = useState();
  const [lastEditorChangeType, setLastEditorChangeType] = useState();
  const [showDeletePromptModal, setShowDeletePromptModal] = useState(false);
  const [bothPaymentsControlsEnabled, setBothPaymentControlsEnabled] =
    useState(false);
  const [purchaseButtonEnabled, setPurchasedButtonEnabled] = useState(false);
  const [subscriptionButtonEnabled, setSubscriptionButtonEnabled] =
    useState(false);
  const [paymentType, setPaymentType] = useState([]);
  const [sessionId, setSessionId] = useState(uuid());
  const [buttonLinkName, setButtonLinkName] = useState("");
  const [buttonLink, setButtonLink] = useState("");
  const [addedSpaceToLinkItem, setAddedSpaceToLinkItem] = useState(false);
  const [tierNoticeModalOpen, setTierNoticeModalOpen] = useState(false);
  const [tierModalNotice, setTierModalNotice] = useState("");
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState("");
  const [fakeRefresh, setFakeRefresh] = useState(false);
  const linkifyPlugin = createLinkifyPlugin();
  let { userId } = useParams();
  let { pageId } = useParams();
  const editorFocusRef = useRef();
  const linkBoxRef = useRef();
  const previewImageRef = useRef();
  const plugins = [linkifyPlugin];
  const editor = useRef(null);
  const indentRef = useRef(null);
  const isRemovingLinkFromList = useRef(null);

  const updateEditorState = (newState) => setEditorState(newState);
  const getEditorState = () => editorState;
  const picker = colorPickerPlugin(updateEditorState, getEditorState);
  const { setIsDataSubExpired } = useBookStore((state) => state);
  const [entityCalculator, setEntityCalculator] = useState("");
  const [buttonIds, setButtonIds] = useState([]);
  const [btWithTrans, setBtWithTrans] = useState([]);
  const [currScrollPos, setCurrScrollPos] = useState(0);
  const [showProductCalendar, setShowProductCalendar] = useState(false);
  const [currSelProduct, setCurrSelProduct] = useState({});
  const { List, Repeat } = Immutable;

  const productCanBeDeleted = (metaId) => {
    const url =
      API_BASE +
      `/api/book/custom/product/meta/${metaId}/check-for-active-subs`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.count > 0) {
          toast(
            "Sory, buttons with purchase or subscription records can only be made inactive or hidden ",
            { type: "notice" }
          );
          return "handled";
        } else {
          return "not-handled";
        }
      })
      .catch((error) => {
        if (error.response.data.invalid_ad_code) {
          toast("Invalid Ad Code", { type: "error" });
        } else {
          toast("Something went wrong", { type: "error" });
        }
      });
  };

  const openProductCalendar = (data) => {
    setShowProductCalendar(true);
    setCurrSelProduct(data);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const closeProductCalendar = () => {
    setShowProductCalendar(false);
    setCurrSelProduct({});
  };

  const processDataThresholdError = (error) => {
    if ("larger_than_data_tier" in error?.response?.data) {
      let data = error.response.data["larger_than_data_tier"];
      setTierModalNotice(data.message_one);
      setTierNoticeModalOpen(true);
      return;
    }
    if ("will_break_my_tier_limit" in error?.response?.data) {
      let data = error.response.data["will_break_my_tier_limit"];
      setTierModalNotice(data.message_two);
      setTierNoticeModalOpen(true);
      return;
    }

    if ("larger_than_max_upload_limit" in error?.response?.data) {
      toast("You cannot upload file(s) larger than 20 MB", { type: "error" });
      return;
    }
  };

  const deletePage = (page) => {
    setProcessing(true);
    const url = API_BASE + "/api/book/delete/" + page.uuid;
    axios
      .delete(url)
      .then((response) => {
        if (response.status == 200) {
          toast("Deleted", { type: "success" });
          setTimeout(() => {
            window.location.href = `/publisher/publications/options/${userId}`;
          }, 300);
          setProcessing(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  const fetchPage = () => {
    const url = API_BASE + "/api/book/custom/single/" + pageId;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          if (response.data?.page?.social_links) {
            console.log("social", JSON.parse(response.data.page.social_links));
            setSocialLinkValue(
              JSON.parse(JSON.parse(response.data.page.social_links))
            );
          }
          setName(response.data.page.name);
          setPrice(response.data.page.price);
          if (response.data.page.productimages) {
            const slides = response.data.page.productimages;
            const slideObj = slides.map((s) => ({
              id: s.client_id,
              file: s.product_thumb,
              slide_id: s.id,
            }));
            setProductSlides(slideObj);
          }

          const contentState = convertFromRaw(
            JSON.parse(response.data.page.data)
          );
          setEditorState(EditorState.createWithContent(contentState));
          setPage(response.data.page);
          if (response.data.page.productmetas) {
            const REServrMetas = response.data.page.productmetas;
            const SPServerMetas = response.data.page.productmetas;

            const REMetas = REServrMetas.filter(
              (b) =>
                JSON.parse(b.payload).purchase_type == 2 &&
                !JSON.parse(b.payload).quantity_merge
            ).map((b) => JSON.parse(b.payload));
            const SPMetas = SPServerMetas.filter(
              (b) => JSON.parse(b.payload).purchase_type == 1
            ).map((b) => JSON.parse(b.payload));
            if (REMetas.length) {
              setProductMeta(REMetas[0]);
              setMetaMode(2);
            }

            if (SPMetas.length) {
              setProductMeta(SPMetas[0]);
              setMetaMode(1);
            }
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.invalid_ad_code) {
          toast("Invalid Ad Code", { type: "error" });
        } else {
          toast("Something went wrong", { type: "error" });
        }
      });
  };

  const deleteAccordionMediaServer = (parentIndex, accordionSectionKey) => {
    setProcessing(true);
    const url = API_BASE + "/custom-page/accordion/files/existing/delete";
    let formData = new FormData();
    formData.append("accordion_id", accordionId);
    formData.append("accordion_section_id", accordionSectionKey);
    formData.append("book_id", pageId);

    authRequestService()
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        const cols = [...accordions];
        cols[parentIndex].media = [];
        console.log("delete accordion media", cols);
        setAccordions(cols);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const saveAccordionImages = (
    items,
    accordionId,
    parentIndex,
    accordionSectionKey
  ) => {
    setProcessing(true);
    const url = API_BASE + "/custom-page/accordion/files/existing/save";
    let formData = new FormData();
    formData.append("session_id", sessionId);
    formData.append("accordion_id", accordionId);
    formData.append("accordion_section_id", accordionSectionKey);
    formData.append("book_id", pageId);
    for (let i = 0; i < items.length; i++) {
      formData.append("accordion_slides[]", items[i].file);
    }

    authRequestService()
      .post(url, formData)
      .then((response) => {
        const ids = response.data.slides.map((slide) => ({
          id: slide.id,
          url: slide.url,
        }));
        setProcessing(false);
        const cols = [...accordions];
        cols[parentIndex].media.push({
          type: "upload",
          assets: ids,
        });
        setAccordions(cols);
      })
      .catch((error) => {
        setProcessing(false);
        if ("larger_than_data_tier" in error.response.data) {
          let data = error.response.data["larger_than_data_tier"];
          setTierModalNotice(data.message_one);
          setTierNoticeModalOpen(true);
          return;
        }
        if ("will_break_my_tier_limit" in error.response.data) {
          let data = error.response.data["will_break_my_tier_limit"];
          setTierModalNotice(data.message_two);
          setTierNoticeModalOpen(true);
          return;
        }
        if ("larger_than_max_upload_limit" in error.response.data) {
          toast("You cannot file(s) larger than 20 MB", {
            type: "error",
          });
          return;
        }
        toast("Something went wrong", { type: "error" });
      });
  };

  const updatePage = (stateWithButtons) => {
    let formData = new FormData();
    setProcessing(true);
    setTimeout(() => {
      formData.append("name", name);
      formData.append("price", 1);
      formData.append("user_id", userId);
      formData.append("cover_photo", pageCoverPhoto);
      formData.append("social_links", JSON.stringify(socialLinkValue));
      formData.append(
        "data",
        JSON.stringify(convertToRaw(editorState.getCurrentContent()))
      );
      formData.append("html_data", getPaidContent());
      formData.append("html_adfree_data", getAdFreeContent());
      formData.append("page_id", pageId);
      const url = API_BASE + "/book/custom/product/update";
      authRequestService()
        .post(url, formData)
        .then((response) => {
          if (response.status == 200) {
            toast("Updated", { type: "success" });
            fetchPage();

            setProcessing(false);
          }
        })
        .catch((error) => {
          setProcessing(false);

          if ("supplier_account_enabled" in error.response.data) {
            toast("Your Supplier Account is currently under restriction.", {
              type: "error",
            });
            return;
          }
          if ("larger_than_data_tier" in error.response.data) {
            let data = error.response.data["larger_than_data_tier"];
            setTierModalNotice(data.message_one);
            setTierNoticeModalOpen(true);
            return;
          }
          if ("will_break_my_tier_limit" in error.response.data) {
            let data = error.response.data["will_break_my_tier_limit"];
            setTierModalNotice(data.message_two);
            setTierNoticeModalOpen(true);
            return;
          }
          if (error.response.status == 422) {
            toast("A content item already exists with that name", {
              type: "error",
            });
            setProcessing(false);
            return false;
          }
          if (error.response.data.invalid_ad_code) {
            toast("Invalid Ad Code", { type: "error" });
            return false;
          }
          if (error?.response?.status == 422) {
            if (error?.response?.data?.message) {
              const msg = error?.response?.data?.message;
              if (msg == "larger_than_max_upload_limit") {
                toast("Sorry, Maximum upload limit of 25 MB exceeded. ", {
                  type: "error",
                });
                return;
              }
            }
          }

          toast("Something went wrong", { type: "error" });
        });
    }, 5000);
  };

  const getApprovedContracts = () => {
    setProcessing(true);
    const url = API_BASE + "/publisher/supplier/contracts/approved/retrieve";
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setProductContracts(response.data.contracts);
          setProcessing(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getDefaultContracts = () => {
    setProcessing(true);
    const url = API_BASE + "/publisher/supplier/contracts/default/retrieve";
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setProductDefaultContracts(response.data.contracts);
          setProcessing(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getVerifiedCustomerServiceSets = () => {
    setProcessing(true);
    const url = API_BASE + "/publisher/supplier/my-customer-services/sets/all";
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setCustomerServiceSets(response.data.sets);
          setProcessing(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const saveTagToDB = (tagsToSave) => {
    const url = API_BASE + "/api/book/update/tags";
    let formData = new FormData();
    formData.append("tags", JSON.stringify(tagsToSave));
    formData.append("uuid", page.uuid);
    formData.append("action", "new");
    axios
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          toast("Added", { type: "success" });
          fetchPage();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const removeTagFromDB = (tagId) => {
    const url = API_BASE + "/api/book/update/tags";
    let formData = new FormData();
    formData.append("tag_id", tagId);
    formData.append("uuid", page.uuid);
    formData.append("action", "delete");
    axios
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          toast("Deleted", { type: "success" });
          fetchPage();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  //product slides
  const addProductSlides = (files) => {
    const url = API_BASE + "/api/book/update/product/slide";
    const f = [...files];

    let newSizes = 0;

    for (let i = 0; i < files.length; i++) {
      const fileSizeMBNew = files[i].size / (1024 * 1024) / 1024;
      newSizes += fileSizeMBNew;
    }

    if (newSizes > 0.025) {
      toast("Sorry, Maximum upload limit of 25 MB exceeded.", {
        type: "error",
      });
      return false;
    }
    setUploadingSlides(true);
    const newSlides = f.map((f) => ({ id: uuid(), file: f }));
    let formData = new FormData();
    formData.append("uuid", page.uuid);
    formData.append("action", "new");
    for (let i = 0; i < newSlides.length; i++) {
      formData.append("product_slides[]", newSlides[i].file);
      formData.append("product_slide_ids[]", newSlides[i].id);
    }
    axios
      .post(url, formData)
      .then((response) => {
        setUploadingSlides(false);
        if (response.data.product_images) {
          const slides = response.data.product_images;
          const slideObj = slides.map((s) => ({
            id: s.client_id,
            file: s.product_thumb,
            slide_id: s.id,
          }));
          setProductSlides(slideObj);

          toast("Added", { type: "success" });
          //fetchPage();
        }
      })
      .catch((error) => {
        setUploadingSlides(false);
        if ("supplier_account_enabled" in error.response.data) {
          toast("Your Supplier Account is currently under restriction.", {
            type: "error",
          });
          return;
        }
        if ("larger_than_data_tier" in error.response.data) {
          let data = error.response.data["larger_than_data_tier"];
          setTierModalNotice(data.message_one);
          setTierNoticeModalOpen(true);
          return;
        }
        if ("will_break_my_tier_limit" in error.response.data) {
          let data = error.response.data["will_break_my_tier_limit"];
          setTierModalNotice(data.message_two);
          setTierNoticeModalOpen(true);
          return;
        }

        if ("larger_than_max_upload_limit" in error.response.data) {
          toast("You cannot upload a file(s) larger than 25 MB", {
            type: "error",
          });
          return;
        }
        toast("Something went wrong", { type: "error" });
      });
  };

  const removeProductSlide = (Id) => {
    const url = API_BASE + "/api/book/update/product/slide";
    let formData = new FormData();
    formData.append("slide_id", Id);
    formData.append("uuid", page.uuid);
    formData.append("action", "delete");
    setDeletingSlides(true);
    axios
      .post(url, formData)
      .then((response) => {
        setDeletingSlides(false);
        if (response.status == 200) {
          if (response.data.product_images) {
            const slides = response.data.product_images;
            const slideObj = slides.map((s) => ({
              id: s.client_id,
              file: s.product_thumb,
              slide_id: s.id,
            }));
            setProductSlides(slideObj);
            toast("Deleted", { type: "success" });
          }
        }
      })
      .catch((error) => {
        setDeletingSlides(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getPublisher = () => {
    let url = API_BASE + "/publisher/" + userId + "/optimized";
    return authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setPublisher(response.data.publisher);
          if (isDataSubExpired(response.data.publisher)) {
            setIsDataSubExpired(true);
          }
          if (response.data?.publisher?.auth_tokens) {
            setAuthTokens(response.data?.publisher?.auth_tokens);
          }
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const addCustomPageAdvert = (clickOrigin) => {
    const url = API_BASE + "/api/publisher/check-ad-request-status";
    axios
      .post(url, {
        user_id: userId,
        click_origin: clickOrigin,
        book_type: "custom",
      })
      .then((response) => {
        if (response.status == 200) {
          if (
            response.data.status == "approved" ||
            response.data.status == "unprocessed"
          ) {
            setAvertRequestStatus("approved");
            insertAdvertIntoEditor(
              "xxx",
              "yyy",
              editorState,
              AtomicBlockUtils,
              EditorState,
              setEditorState
            );
            setShowAdModal(false);
          }

          if (response.data.status == "rejected") {
            setAvertRequestStatus("rejected");
            setShowAdModal(true);
          }

          if (!response.data.status) {
            setShowAdModal(true);
          }
        }
      })
      .catch((error) => {
        if (error.response.data.invalid_ad_code) {
          toast("Invalid Ad Code", { type: "error" });
        } else {
          toast("Something went wrong", { type: "error" });
        }
      });
  };

  const getAdFreeContent = () => {
    let firstSanitization = stateToHTML(
      editorState.getCurrentContent(),
      htmlRenderOptionsFree(picker, editorState)
    ).replaceAll("<p><br></p>", "");
    return firstSanitization;
  };

  const getPaidContent = () => {
    let firstSanitization = stateToHTML(
      editorState.getCurrentContent(),
      htmlRenderOptions(picker, editorState)
    ).replaceAll("<p><br></p>", "");
    return firstSanitization;
  };

  function mediaBlockRenderer(block, data) {
    if (block.getType() === "atomic") {
      return {
        component: Media,
        editable: true,
        data: "123",
      };
    }

    return null;
  }

  const cannotDisplaySPButton = () => {
    const contractValidate =
      productMeta.contract_id || productMeta?.default_contract_name;
    if (productMeta.price && productMeta.price == 0) {
      return true;
    }
    if (productMeta.quantity_enabled && !productMeta.quantity) {
      return true;
    }
    if (
      !contractValidate ||
      !productMeta.p_type_sub_opt ||
      !productMeta.price
    ) {
      return true;
    }
    return false;
  };

  const cannotDisplayREButton = () => {
    const contractValidate =
      productMeta.contract_id || productMeta?.default_contract_name;
    if (productMeta.price && productMeta.price == 0) {
      return true;
    }
    if (
      processing ||
      !contractValidate ||
      !productMeta.future_availability ||
      !productMeta.p_type_sub_opt ||
      !productMeta.price
    ) {
      return true;
    }

    if (productMeta.booking_interruption) {
      if (
        productMeta.p_type_sub_opt.includes("Monthly") &&
        (!productMeta?.Fortnightly?.price ||
          productMeta?.Fortnightly?.price == 0 ||
          !productMeta?.Weekly?.price ||
          productMeta?.Weekly?.price == 0 ||
          !productMeta?.Daily?.price ||
          productMeta?.Daily?.price == 0)
      ) {
        console.log("Monthly");
        return true;
      }

      if (
        productMeta.p_type_sub_opt.includes("Fortnightly") &&
        (!productMeta?.Weekly?.price ||
          productMeta?.Weekly?.price == 0 ||
          !productMeta?.Daily?.price ||
          productMeta?.Daily?.price == 0)
      ) {
        console.log("Fortnightly");
        return true;
      }

      if (
        productMeta.p_type_sub_opt.includes("Weekly") &&
        (!productMeta?.Daily?.price || productMeta?.Daily?.price == 0)
      ) {
        console.log("xxx");
        return true;
      }

      if (productMeta.quantity_enabled && !productMeta.quantity) {
        return true;
      }

      if (productMeta.quantity && productMeta.quantity == 0) {
        console.log("zzz");
        return true;
      }
    }

    return false;
  };

  const purchaseButtonNotCreated = () => {
    return cannotDisplayREButton() && cannotDisplaySPButton();
  };

  const showDisplayPurchaseButtonError = () => {
    return !purchaseButtonNotCreated() && !doesEditorHavePurchase();
  };

  const doesEditorHavePurchase = () => {
    const contentState = editorState.getCurrentContent();
    let rawContent = convertToRaw(contentState);
    for (let i = 0; i < rawContent.blocks.length; i++) {
      let b = rawContent.blocks[i];
      if (b["type"] !== "unstyled" && b.entityRanges.length === 1) {
        const entityKey = b["entityRanges"][0]["key"];
        const entityMap = rawContent["entityMap"][entityKey];
        if (entityMap["type"] === "product_meta") {
          return true;
        }
      }
    }
    return false;
  };

  const addCustomPageProductMeta = (type) => {
    // checkForMeataErrors(payload);
    const contentState = editorState.getCurrentContent();
    let rawContent = convertToRaw(contentState);
    insertProductMetaIntoEditor(
      JSON.stringify(type == 1 ? productMeta : productMeta),
      editorState,
      AtomicBlockUtils,
      EditorState,
      setEditorState
    );
    hidePBOptionsModal();
    hideProductMetaBox();
  };

  //add slides to editor
  const addSlidesToEditor = () => {
    const payload = selectedSlides;
    insertSlideIntoEditor(
      JSON.stringify(payload),
      editorState,
      AtomicBlockUtils,
      EditorState,
      setEditorState
    );
    setSelectedSlides([]);
    closeSelectImagesModal();
  };

  //add banner to editor
  const addBannerToEditor = () => {
    const payload = bannerSlides;
    insertBannerIntoEditor(
      JSON.stringify(payload),
      editorState,
      AtomicBlockUtils,
      EditorState,
      setEditorState
    );
    setBannerSlides([]);
    closeBannerModal();
  };

  const updateBannerInEditor = () => {
    const payload = bannerSlides;
    if (!createBannerMode) {
      const contentState = editorState.getCurrentContent();
      let rawContent = convertToRaw(contentState);
      for (let i = 0; i < rawContent.blocks.length; i++) {
        if (rawContent.blocks[i]["key"] == currEditingBannerId) {
          let b = rawContent.blocks[i];
          if (b["type"] !== "unstyled" && b.entityRanges.length === 1) {
            const entityKey = b["entityRanges"][0]["key"];
            const entityMap = rawContent["entityMap"][entityKey];
            if (entityMap["type"] === "banners") {
              rawContent["entityMap"][entityKey]["data"]["payload"] =
                JSON.stringify(payload);
            }
          }
        }
      }

      const newContentState = convertFromRaw(rawContent);
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "update-contentState"
      );
      setEditorState(newEditorState);
      setBannerSlides([]);
      closeBannerModal();
    }
  };

  const addCustomPageImage = (file) => {
    let url = "";
    if (createImageUploadMode) {
      url = API_BASE + "/api/custom-page/files/save";
    } else {
      url = API_BASE + "/api/custom-page/files/update/existing";
    }
    setProcessing(true);
    let imageLink = currPastedImageLink
      ? currPastedImageLink.indexOf("://") === -1
        ? "http://" + currPastedImageLink
        : currPastedImageLink
      : "";
    let formData = new FormData();
    formData.append("user_id", userId);
    if (imageFileData) {
      formData.append("file", imageFileData);
    }
    formData.append("book_id", pageId);
    formData.append("type", "image");
    formData.append("link", imageLink);
    formData.append("session_id", sessionId);
    formData.append("mode", createImageUploadMode ? "create" : "update");
    formData.append(
      "curr_editing_image",
      currEditingImage.split("custom-pages/")[1]
    );
    if (pageId) {
      formData.append("action", "update");
    } else {
      formData.append("action", "new");
    }
    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          let url =
            BASE_URL + "/storage" + response.data.file.file.split("public")[1];
          let link = response.data.file.link;
          let imageId = response.data.file.uuid;

          if (!createImageUploadMode) {
            const contentState = editorState.getCurrentContent();
            let rawContent = convertToRaw(contentState);
            for (let i = 0; i < rawContent.blocks.length; i++) {
              if (rawContent.blocks[i]["key"] == currImageEditBlockKey) {
                let b = rawContent.blocks[i];
                if (b["type"] !== "unstyled" && b.entityRanges.length === 1) {
                  const entityKey = b["entityRanges"][0]["key"];
                  const entityMap = rawContent["entityMap"][entityKey];
                  if (entityMap["type"] === "image") {
                    rawContent["entityMap"][entityKey]["data"]["src"] = url;
                    rawContent["entityMap"][entityKey]["data"]["imageLink"] =
                      link;
                  }
                }
              }
            }

            const newContentState = convertFromRaw(rawContent);
            const newEditorState = EditorState.push(
              editorState,
              newContentState,
              "update-contentState"
            );
            setEditorState(newEditorState);
          } else {
            setEditorState(
              insertImageIntoEditor(
                url,
                imageLink,
                imageId,
                editorState,
                AtomicBlockUtils,
                EditorState
              )
            );
          }
          hideImageModalBox();
          resetImageLinkPreview();
          resetImagePreview();
        }
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.status !== 422) {
          toast("Something went wrong!", { type: "error" });
        }
        processDataThresholdError(error);
      });
  };

  const addSocialImageLinksToEditor = () => {
    const payload = {};
    payload.type = socialUploadType;
    payload.sub_type = socialUploadSubType;
    payload.data = JSON.stringify(selectedSocialPhoto);
    insertSocialImageLinksIntoEditor(
      JSON.stringify(payload),
      editorState,
      AtomicBlockUtils,
      EditorState,
      setEditorState
    );
    setSelectedSocialPhoto([]);
    setSocialUploadSubType("");
    setSocialUploadType("");
    hideImageModalBox();
  };

  const addSocialEmbedToEditor = () => {
    const payload = {};
    payload.type = socialUploadType;
    payload.sub_type = socialUploadSubType;
    payload.data = socialEmbedValue;
    insertSocialEmbedIntoEditor(
      JSON.stringify(payload),
      editorState,
      AtomicBlockUtils,
      EditorState,
      setEditorState
    );
    setSocialEmbedValue();
    setSocialUploadSubType("");
    setSocialUploadType("");
    hideImageModalBox();
  };

  const addTableToEditor = () => {
    const payload = {};
    payload.name = tableTitle;
    payload.data = tableColumns;

    insertTableIntoEditor(
      JSON.stringify(payload),
      editorState,
      AtomicBlockUtils,
      EditorState,
      setEditorState
    );
    setTableColumns([initialTableColumn]);
    setTableTitle("");
    hideTableModalBox();
  };

  const updateTableInEditor = () => {
    if (!createTableMode) {
      const contentState = editorState.getCurrentContent();
      let rawContent = convertToRaw(contentState);
      for (let i = 0; i < rawContent.blocks.length; i++) {
        if (rawContent.blocks[i]["key"] == currTableEditBlockKey) {
          let b = rawContent.blocks[i];
          if (b["type"] !== "unstyled" && b.entityRanges.length === 1) {
            const entityKey = b["entityRanges"][0]["key"];
            const entityMap = rawContent["entityMap"][entityKey];
            if (entityMap["type"] === "table") {
              const payload = {};
              payload.name = tableTitle;
              payload.data = tableColumns;
              console.log("inside ");
              rawContent["entityMap"][entityKey]["data"]["payload"] =
                JSON.stringify(payload);
            }
          }
        }
      }

      const newContentState = convertFromRaw(rawContent);
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "update-contentState"
      );
      setEditorState(newEditorState);
      setTableColumns([initialTableColumn]);
      setTableTitle("");
      hideTableModalBox();
    }
  };

  const addAccordionToEditor = () => {
    const payload = {};
    payload.name = accordionTitle;
    payload.data = accordions;
    payload.id = accordionId;

    insertAccordionIntoEditor(
      JSON.stringify(payload),
      editorState,
      AtomicBlockUtils,
      EditorState,
      setEditorState
    );

    hideAccordionModalCreateBox();
  };

  const updateAccordionInEditor = () => {
    if (!createAccordionMode) {
      const contentState = editorState.getCurrentContent();
      let rawContent = convertToRaw(contentState);
      for (let i = 0; i < rawContent.blocks.length; i++) {
        if (rawContent.blocks[i]["key"] == currAccordionEditBlockKey) {
          let b = rawContent.blocks[i];
          if (b["type"] !== "unstyled" && b.entityRanges.length === 1) {
            const entityKey = b["entityRanges"][0]["key"];
            const entityMap = rawContent["entityMap"][entityKey];
            if (entityMap["type"] === "accordion") {
              const payload = {};
              payload.name = accordionTitle;
              payload.data = accordions;
              console.log("inside ");
              rawContent["entityMap"][entityKey]["data"]["payload"] =
                JSON.stringify(payload);
            }
          }
        }
      }

      const newContentState = convertFromRaw(rawContent);
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "update-contentState"
      );
      setEditorState(newEditorState);
      hideAccordionModalCreateBox();
    }
  };

  const addCustomPageVideo = (file) => {
    const url = API_BASE + "/api/custom-page/files/save";
    setProcessing(true);
    let formData = new FormData();
    formData.append("user_id", userId);
    formData.append("book_id", pageId);
    formData.append("type", "video");
    formData.append("link", currPastedVideoLink);
    formData.append("session_id", sessionId);
    if (pageId) {
      formData.append("action", "update");
    } else {
      formData.append("action", "new");
    }

    const linkMode = currPastedVideoLink ? "link" : "mp4";

    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          let mp4link = currPastedVideoLink;
          const videoId = response.data.file.uuid;
          setEditorState(
            insertVideoIntoEditor(
              mp4link,
              linkMode,
              editorState,
              AtomicBlockUtils,
              EditorState,
              videoId
            )
          );
          hideVideoModalBox();
        }
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.status !== 422) {
          toast("Something went wrong!", { type: "error" });
        }
        processDataThresholdError(error);
      });
  };

  const updatePaymentControlsSetting = () => {
    const url = API_BASE + "/api/book/update/payment-controls";
    setProcessing(true);
    let formData = new FormData();
    formData.append("user_id", userId);
    formData.append("book_id", pageId);

    if (paymentType.includes("both")) {
      formData.append("enable_custom_page_purchase", 1);
      formData.append("enable_custom_page_subscription", 1);
    } else {
      formData.append(
        "enable_custom_page_purchase",
        paymentType.includes("purchase") ? 1 : 0
      );

      formData.append(
        "enable_custom_page_subscription",
        paymentType.includes("subscription") ? 1 : 0
      );
    }

    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        fetchPage();
        hidePaymentControlsBox();
        toast("Updated!", { type: "success" });
      })
      .catch((error) => {
        setProcessing(false);

        toast("Something went wrong!", { type: "error" });
      });
  };

  const updateProductMeta = (type, payload, metas) => {
    const url =
      API_BASE +
      `/book/custom/product/meta/block/${payload.block_meta_id}/page/` +
      pageId +
      "/update";
    let formData = new FormData();
    setProcessing(true);
    formData.append("block_meta_id", payload.block_meta_id);
    formData.append("page_id", pageId);
    formData.append("payload", JSON.stringify(payload));
    formData.append("type", type);
    authRequestService()
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          setProcessing(false);
          const contentState = editorState.getCurrentContent();
          let rawContent = convertToRaw(contentState);
          for (let i = 0; i < rawContent.blocks.length; i++) {
            let b = rawContent.blocks[i];
            if (b["type"] !== "unstyled" && b.entityRanges.length === 1) {
              const entityKey = b["entityRanges"][0]["key"];
              const entityMap = rawContent["entityMap"][entityKey];
              if (entityMap["type"] === "product_meta") {
                const pl = JSON.parse(
                  rawContent["entityMap"][entityKey]["data"]["payload"]
                );
                rawContent["entityMap"][entityKey]["data"]["payload"] =
                  JSON.stringify(payload);
              }
            }
          }
          const newContentState = convertFromRaw(rawContent);
          const newEditorState = EditorState.push(
            editorState,
            newContentState,
            "update-contentState"
          );
          setEditorState(newEditorState);
          setSelectPurchaseModelOpen(false);
          window.scrollTo({
            top: currScrollPos,
            behavior: "smooth",
          });
          updatePage();
        }
      })
      .catch((error) => {
        setProcessing(false);

        toast("Something went wrong", { type: "error" });
      });
  };
  const onInlineClick = (e) => {
    let nextState = RichUtils.toggleInlineStyle(editorState, e);
    setEditorState(nextState);
  };

  const onBlockClick = (e) => {
    let nextState = RichUtils.toggleBlockType(editorState, e);
    setEditorState(nextState);
  };

  const canDeletePage = () => {
    if (!page.productmetas) return true;
    return page.productmetas.filter(
      (d) => d.hasactiveandfuturesubs || d.hasactivepayment
    ).length
      ? false
      : true;
  };

  const getEntities = (
    block,
    entityType = null,
    charStart = null,
    charEnd = null
  ) => {
    const content = editorState.getCurrentContent();
    const entities = [];
    let selectedEntity = null;
    if (block.getEntityAt(charStart)) {
      const entityKey = block.getEntityAt(charStart);
      const entity = content.getEntity(entityKey);
      if (!entityType || (entityType && entity.getType() === entityType)) {
        selectedEntity = {
          entityKey: entityKey,
          blockKey: block.getKey(),
          entity: content.getEntity(entityKey),
        };
        entities.push(selectedEntity);
      }
      setCurrentSelectedLink(entity.getData());
    } else {
      setEditorOutput("no");
    }

    return entities;
  };

  useEffect(() => {
    if (!editorState.getLastChangeType()) return;
    // setLastEditorChangeType(editorState.getLastChangeType());
    if (
      editorState.getLastChangeType() == "remove-range" ||
      editorState.getLastChangeType() == "apply-entity"
    ) {
      // alert(editorState.getLastChangeType());
      let oldStProdEntities = 0;
      let newStProdEntities = 0;
      let buttonIds = [];

      for (
        let i = 0;
        i < convertToRaw(editorState.getCurrentContent()).blocks.length;
        i++
      ) {
        let b = convertToRaw(editorState.getCurrentContent()).blocks[i];
        if (b == undefined) continue;
        if (b.type == "atomic" && b.entityRanges.length === 1) {
          oldStProdEntities++;
        }
      }
    }
    //console.log("content state", editorState.getCurrentContent());
  }, [shadowEditorState]);

  useEffect(() => {
    // console.log(buttonIds);
  }, [buttonIds]);

  useEffect(() => {
    console.log("productMetas changed ", productMetas);
  }, [productMetas]);

  const handleEditorChange = (st) => {
    const selectionKey = st.getSelection().getAnchorKey();
    const block = st.getCurrentContent().getBlockForKey(selectionKey);
    let start = st.getSelection().getStartOffset();
    let end = st.getSelection().getEndOffset();
    setShadowEditorState(editorState);
    if (addedSpaceToLinkItem) {
      setAddedSpaceToLinkItem(false);
    }

    if (getEntities(block, "LINK", start, end).length) {
      setSelectionHasLink(true);
      setSelectionAnchors((prev) => {
        return { ...prev, start: start, end: end };
      });
    } else {
      setSelectionHasLink(false);
      setCurrentSelectedLink({});
      setSelectionAnchors({});
    }

    const contentState = st.getCurrentContent();
    let rawContent = convertToRaw(contentState);
    for (let i = 0; i < rawContent.blocks.length; i++) {
      if (createHireMode && (currHireText || currHireReplySubject)) {
        //rawContent.blocks[i]["key"] == currHireEditBlockKey
        let b = rawContent.blocks[i];
        if (b["type"] !== "unstyled" && b.entityRanges.length === 1) {
          const entityKey = b["entityRanges"][0]["key"];
          const entityMap = rawContent["entityMap"][entityKey];
          if (entityMap["type"] === "hire_button") {
            rawContent["entityMap"][entityKey]["data"]["hireText"] =
              currHireText;
            rawContent["entityMap"][entityKey]["data"]["replyEnabled"] =
              currHireResponseStatus ? 1 : 0;
            rawContent["entityMap"][entityKey]["data"]["hireReplySubject"] =
              currHireResponseStatus ? currHireReplySubject : "";
            rawContent["entityMap"][entityKey]["data"]["hirePrice"] =
              currHirePrice;
          }
        }
      }
    }
    setEditorState(st);
  };

  const Media = (props) => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const { src } = entity.getData();
    const { linkMode } = entity.getData();

    const { buttonLinkName } = entity.getData();
    const { videoId } = entity.getData();
    const type = entity.getType();
    let media;

    if (type === "signup_for_updates") {
      media = (
        <SignupForUpdatesBox
          blockKey={props.block.getKey()}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
        />
      );
    }

    if (type === "button_link") {
      media = (
        <ButtonLinkBox
          name={buttonLinkName}
          blockKey={props.block.getKey()}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
        />
      );
    }

    if (type === "social_link") {
      const { payload } = entity.getData();
      media = (
        <SocialLinksEmbed
          blockKey={props.block.getKey()}
          payload={payload}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
        />
      );
    }

    if (type === "social_embed") {
      const { payload } = entity.getData();
      media = (
        <SocialImageEmbed
          blockKey={props.block.getKey()}
          payload={payload}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
        />
      );
    }

    if (type === "table") {
      const { payload } = entity.getData();
      media = (
        <Table
          blockKey={props.block.getKey()}
          payload={payload}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
          showTableUpdateBox={showTableUpdateBox}
        />
      );
    }

    if (type === "accordion") {
      const { payload } = entity.getData();
      media = (
        <Accordion
          blockKey={props.block.getKey()}
          payload={payload}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
          showAccordionUpdateBox={showAccordionUpdateBox}
        />
      );
    }

    if (type === "image") {
      const { imageLink } = entity.getData();
      const { imageId } = entity.getData();
      media = (
        <Image
          pageId={pageId}
          blockKey={props.block.getKey()}
          src={src}
          imageLink={imageLink}
          imageId={imageId}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
          showImageModalUpdateBox={showImageModalUpdateBox}
        />
      );
    }

    if (type === "product_meta") {
      const { payload } = entity.getData();

      media = (
        <ProductMeta
          pageId={pageId}
          blockKey={props.block.getKey()}
          payload={payload}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
          showProductMetaModalUpdateBox={showProductMetaModalUpdateBox}
          buttonIds={buttonIds}
          productMetas={productMetas}
        />
      );
    }

    if (type === "video") {
      media = (
        <Video
          blockKey={props.block.getKey()}
          src={src}
          linkMode={linkMode}
          videoIdentifier={videoId}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
        />
      );
    }

    if (type === "slides") {
      const { payload } = entity.getData();

      media = (
        <Slides
          blockKey={props.block.getKey()}
          payload={payload}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
        />
      );
    }

    if (type === "banners") {
      const { payload } = entity.getData();
      media = (
        <Banners
          blockKey={props.block.getKey()}
          payload={payload}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
          showUpdateBannerBox={showUpdateBannerBox}
        />
      );
    }

    if (type === "advert") {
      const { systemAdCode } = entity.getData();
      const { adCode } = entity.getData();
      media = (
        <Advert
          blockKey={props.block.getKey()}
          adCode={adCode}
          systemAdCode={systemAdCode}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
        />
      );
    }

    return media;
  };

  function mediaBlockRenderer(block, data) {
    if (block.getType() === "atomic") {
      return {
        component: Media,
        editable: true,
        data: "123",
      };
    }

    return null;
  }

  const handleSelectedFileChange = (file) => {
    setCoverFileSelected(file);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFocus = () => {
    editorFocusRef.focus = true;
  };

  const handleBlur = () => {
    editorFocusRef.focus = false;
  };

  const showAdBox = (clickOrigin) => {
    setAvertRequestStatus("");
    addCustomPageAdvert(clickOrigin);
  };

  const hidePaymentControlsBox = () => {
    setShowPaymentControlsModal(false);
  };

  const showPaymentControlsBox = () => {
    setShowPaymentControlsModal(true);
  };

  /*select slides update*/
  const handleCheckSlide = (id) => {
    if (!selectedSlides.includes(id)) {
      setSelectedSlides((prev) => [...prev, id]);
    } else {
      const s = selectedSlides;
      const removeSlide = s.filter((s) => s !== id);
      setSelectedSlides(removeSlide);
    }
  };
  const canDisplayImageOptions = () => {
    return true;
  };

  const displaySelectImagesBox = () => {
    setSelectImagesModalOpen(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const openSelectImagesModal = () => {
    setSelectImagesModalOpen(true);
  };

  const closeSelectImagesModal = () => {
    setSelectImagesModalOpen(false);
  };

  /*banner slides*/
  const handleBannerTextChange = (e, type, slideId) => {
    const banns = [...bannerSlides];
    const slideIdById = banns.findIndex((s) => s.id == slideId);
    if (slideIdById !== -1) {
      if (type == "text") {
        banns[slideIdById].text = e;
      }
      setBannerSlides(banns);
    }
  };

  const handleBannerUrlChange = (e, type, slideId) => {
    const banns = [...bannerSlides];
    const slideIdById = banns.findIndex((s) => s.id == slideId);
    if (slideIdById !== -1) {
      if (type == "url") {
        banns[slideIdById].url = e;
      }
      setBannerSlides(banns);
    }
  };

  const bannerSlideSelected = (slideId) => {
    if (!bannerSlides.length) return false;
    return bannerSlides.filter((b) => b.id == slideId).length;
  };

  const getSelBannerSlideInfo = (slideId) => {
    if (!bannerSlides.length) return " ";
    const banns = bannerSlides.findIndex((s) => s.id == slideId);
    if (banns !== -1) return banns;
    return " ";
  };

  const handleCheckBannerSlide = (slideId) => {
    const banns = [...bannerSlides];
    const slideIdById = banns.findIndex((s) => s.id == slideId);
    if (slideIdById == -1) {
      setBannerSlides((prev) => [...prev, { id: slideId, text: "", url: "" }]);
    } else {
      const newBanns = banns.filter((slide) => slide.id !== slideId);
      setBannerSlides(newBanns);
    }
  };
  const displayBannerBox = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setBannerModalOpen(true);
  };

  const showUpdateBannerBox = (blockKey, payload) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const slides = JSON.parse(payload);
    const slideIds = slides.map((s) => s.id);
    const existingBanns = productSlides
      .filter((obj) => slideIds.includes(obj.id))
      .map((slide) => slide.id);

    const newPayload = slides.filter((obj) => existingBanns.includes(obj.id));
    console.log("edit banns", newPayload);
    setBannerSlides(newPayload);
    setCreateBannerMode(false);
    setCurrEditingBannerId(blockKey);
    setBannerModalOpen(true);
  };

  const closeBannerModal = () => {
    setCurrEditingBannerId("");
    setBannerModalOpen(false);
    setCreateBannerMode(true);
    setBannerSlides([]);
  };

  //product meta
  const showProductMetaBox = () => {
    getProductMetaByBlockMetaId(productMeta.block_meta_id);
  };

  const saveMetaConfig = () => {
    const metas = [];
    if (!cannotDisplayREButton() && productMeta.purchase_type == 2) {
      const REobj = productMeta;
      REobj.purchase_type = 2;

      const toExclude = [
        "block_meta_id",
        "price",
        "Fortnightly",
        "Weekly",
        "Daily",
        "quantity",
        "quantity_enabled",
        "quantity_merge",
      ];
      if (productMeta.booking_interruption) {
        if (REobj.p_type_sub_opt.includes("Monthly")) {
          REobj.Fortnightly = copyMeta(REobj, {}, toExclude, REobj.Fortnightly);
          REobj.Weekly = copyMeta(REobj, {}, toExclude, REobj.Weekly);
          REobj.Daily = copyMeta(REobj, {}, toExclude, REobj.Daily);
        }

        if (REobj.p_type_sub_opt.includes("Fortnightly")) {
          REobj.Weekly = copyMeta(REobj, {}, toExclude, REobj.Weekly);
          REobj.Daily = copyMeta(REobj, {}, toExclude, REobj.Daily);
        }

        if (REobj.p_type_sub_opt.includes("Weekly")) {
          REobj.Daily = copyMeta(REobj, {}, toExclude, REobj.Daily);
        }
      }

      updateProductMeta("2", REobj, metas);
    }

    if (!cannotDisplaySPButton() && productMeta.purchase_type == 1) {
      const SPobj = productMeta;

      updateProductMeta("1", SPobj, []);
    }
  };

  const copyMeta = (source, target, exclude = [], extra) => {
    const keysToCopy = Object.keys(source).filter(
      (key) => !exclude.includes(key)
    );

    const objs = {
      price: extra.price,
      block_meta_id: extra.block_meta_id,
      quantity_merge: source.block_meta_id,
      quantity: extra.quantity,
      quantity_enabled: extra.quantity_enabled,
    };
    let firstCopy = Object.assign(
      target || {},
      ...keysToCopy.map((key) => ({ [key]: source[key] }))
    );

    return { ...firstCopy, ...objs };
  };

  const hideProductMetaBox = () => {
    setSelectPurchaseModelOpen(false);
    setCreateProductMetaMode("");
    setProductMetaData({});
    window.scrollTo({
      top: currScrollPos,
      behavior: "smooth",
    });
  };

  const showProductMetaModalUpdateBox = (payload, blockKey) => {
    const meta = JSON.parse(payload);
    getProductMetaByBlockMetaId(meta.block_meta_id, blockKey);
  };

  const resetProductMetaPayload = () => {
    setProductMeta(initialProductMeta);
    setProductMetas([]);
  };

  const hideAdModal = () => {
    setShowAdModal(false);
  };

  const handleAdIdChange = (e) => {
    setcurrentEditingAdId(e);
  };

  const showLinkBox = () => {
    setShowLinkModal(true);
  };

  const hideLinkModal = () => {
    setShowLinkModal(false);
  };

  const handleLinkChange = (e) => {
    setCurrPastedLink(e);
  };
  //hire
  const handleHireTextChange = (e) => {
    setCurrHireText(e);
  };

  const handleHirePriceChange = (e) => {
    setCurrHirePrice(e);
  };

  const handleHireReplySubjectChange = (e) => {
    setCurrHireReplySubject(e);
  };

  const handleHireResponseStatus = () => {
    setCurrHireResponseStatus((prev) => !prev);
  };

  // Video Section
  const showVideoModalBox = () => {
    if (isResponsiveMode()) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setShowVideoModal(true);
  };

  const showSocialsLinkBox = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setSocialLinksModalOpen(true);
  };
  const handleSocialsLinkChange = (e, key, type) => {
    setSocialLinkValue((prev) => ({ ...prev, [key]: e }));
  };

  const saveSocialLinksConfig = () => {
    closeSocialLinksModal();
  };

  const closeSocialLinksModal = () => {
    setSocialLinksModalOpen(false);
  };

  const hideVideoModalBox = () => {
    setShowVideoModal(false);
    resetVideoPreview();
    resetVideoLinkPreview();
    hideImageModalBox();
    setSocialUploadType("");
  };

  const handleVideoLinkChange = (e) => {
    setCurrPastedVideoLink(e);
  };

  const resetVideoPreview = () => {
    setVideoFileSelected("");
    setVideoFileData();
  };
  const resetVideoLinkPreview = () => {
    setCurrPastedVideoLink("");
  };

  const handleVideoPreview = (e) => {
    let [file] = e.target.files;
    if (file) {
      if (!file["type"].includes("mp4")) {
        toast("Invalid video file format, supported format MP4", {
          type: "error",
        });
        return false;
      }
      setVideoFileSelected(URL.createObjectURL(file));
      setVideoFileData(file);
    }
  };

  //accordion
  const showAccordionModalCreateBox = () => {
    setAccordionId(uuid());
    setShowAccordionModal(true);
    setAccordionTitle("");
    setAccordions([generateAccordoinTemplate()]);
    setCurrAccordionEditBlockKey("");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const hideAccordionModalCreateBox = () => {
    setAccordionId("");
    setShowAccordionModal(false);
    setAccordionTitle("");
    setAccordions([]);
    setCurrAccordionEditBlockKey("");
    setCreateAccordionMode(true);
  };
  const generateAccordoinTemplate = () => {
    const initAcc = { ...initialAccordion };
    initAcc.id = uuid();
    return initAcc;
  };

  const addAccordionSection = () => {
    const cols = [...accordions];
    cols.push(generateAccordoinTemplate());
    setAccordions(cols);
  };
  const deleteAccordionSection = (parentIndex) => {
    const cols = [...accordions];
    const colsOldCols = cols.filter((r, i) => i !== parentIndex);
    setAccordions(colsOldCols);
  };
  const handleAccordionHeaderChange = (e, type, index) => {
    const cols = [...accordions];
    if (type == "header") {
      cols[index].header.value = e;
    }
    setAccordions(cols);
  };
  const handleAccordionBodyChange = (e, type, index) => {
    const cols = [...accordions];
    if (type == "body") {
      cols[index].body.value = e;
    }
    setAccordions(cols);
  };

  const showAccordionUpdateBox = (payload, blockKey) => {
    console.log("update payload", payload);
    setShowAccordionModal(true);
    setCreateAccordionMode(false);
    setAccordionTitle(payload.name);
    setAccordions(payload.data);
    setAccordionId(payload.id);
    setCurrAccordionEditBlockKey(blockKey);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleAccordionTitleChange = (e) => {
    setAccordionTitle(e);
  };

  const addYoutubeToAccordion = (link, parentIndex) => {
    const cols = [...accordions];
    cols[parentIndex].media.push({
      type: "youtube",
      assets: [],
      link: link,
    });
    setAccordions(cols);
  };

  const addSocialToAccordion = (type, link, parentIndex) => {
    const cols = [...accordions];
    cols[parentIndex].media.push({
      type: type,
      assets: [],
      link: link,
    });
    setAccordions(cols);
  };

  const deleteAccordionMediaEditor = (parentIndex) => {
    const cols = [...accordions];
    cols[parentIndex].media = [];
    setAccordions(cols);
  };

  //table
  const showTableModalCreateBox = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowTableModal(true);
  };

  const hideTableModalBox = () => {
    setTableColumns([]);
    setTableTitle("");
    setCurrTableEditBlockKey("");
    setCreateTableMode(true);
    setShowTableModal(false);
  };
  const addTableRow = (index) => {
    const cols = [...tableColumns];
    cols[index].rows.push({
      placeholder: "Fill Row: Type here...",
      value: "",
    });
    console.log("adding new row", cols);
    setTableColumns(cols);
  };

  const addTableColumn = () => {
    const cols = [...tableColumns];
    cols.push(initialTableColumn);
    setTableColumns(cols);
  };

  const deleteTableColumn = (parentIndex) => {
    const cols = [...tableColumns];
    const colsOldCols = cols.filter((r, i) => i !== parentIndex);
    setTableColumns(colsOldCols);
  };

  const deleteTableRow = (childIndex, parentIndex) => {
    const cols = [...tableColumns];
    const colsOldRows = cols[parentIndex].rows;
    const colsNewRows = colsOldRows.filter((r, i) => i !== childIndex);
    cols[parentIndex].rows = colsNewRows;
    // cols[parentIndex].rows = colsNewRows;
    setTableColumns(cols);
  };

  const handleTableHeaderChange = (e, type, index) => {
    const cols = [...tableColumns];
    if (type == "header") {
      cols[index].header.value = e;
    }
    setTableColumns(cols);
  };

  const handleTableRowChange = (e, type, index, parentIndex) => {
    const cols = [...tableColumns];
    if (type == "row") {
      cols[parentIndex].rows[index].value = e;
    }
    setTableColumns(cols);
  };

  const handleTableTitleChange = (e) => {
    setTableTitle(e);
  };

  const showTableUpdateBox = (payload, blockKey) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowTableModal(true);
    setCreateTableMode(false);
    setTableTitle(payload.name);
    setTableColumns(payload.data);
    setCurrTableEditBlockKey(blockKey);
    // console.log("TMS", payload);
  };

  useEffect(() => {
    console.log("productMetas changed ", productMetas);
  }, [productMetas]);

  useEffect(() => {
    console.log("RE productMeta changed ", productMeta);
  }, [productMeta]);

  useEffect(() => {
    console.log("SP productMeta changed ", productMeta);
  }, [productMeta]);

  const showPBOptionsModal = () => {
    setShowPOModal(true);
  };

  const hidePBOptionsModal = () => {
    setShowPOModal(false);
  };

  const showHireModalCreateBox = () => {
    setCreateHireMode(true);
    setCurrHireText(" ");
    setCurrHirePrice("");
    setCurrHireReplySubject("");
    setCurrHireResponseStatus(false);
    setShowHireModal(true);

    window.scrollTo(0, 0);
  };

  const hideHireModalBox = () => {
    setShowHireModal(false);
  };

  // Image Section
  const showImageModalUpdateBox = (imageSource, imageLink, blockKey) => {
    setShowImageModal(true);
    setCreateImageUploadMode(false);
    if (imageSource) {
      setCurrEditingImage(imageSource);
      setCurrEditingImagePreview(imageSource);
    }
    if (imageLink) {
      setCurrPastedImageLink(imageLink);
    }

    setCurrImageEditBlockKey(blockKey);
  };

  const showImageModalCreateBox = () => {
    setShowImageModal(true);
    setCreateImageUploadMode(true);
  };

  const hideImageModalBox = () => {
    setShowImageModal(false);
    resetImagePreview();
    resetImageLinkPreview();
    setCurrEditingImage("");
    setSocialUploadType("");
  };

  const handleImageLinkChange = (e) => {
    setCurrPastedImageLink(e);
  };

  const resetImagePreview = () => {
    setImageFileSelected("");
    setCurrEditingImagePreview("");
    setImageFileData();
  };
  const resetImageLinkPreview = () => {
    setCurrPastedImageLink("");
  };

  const handleImagePreview = (e) => {
    let [file] = e.target.files;
    if (file) {
      if (!true) {
        toast("Invalid video file format, supported format MP4", {
          type: "error",
        });
        return false;
      }
      setImageFileSelected(URL.createObjectURL(file));
      setImageFileData(file);
    }
  };

  // button link
  const showButtonLinkModalBox = () => {
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth",
    // });
    setShowButtonLinkModal(true);
  };

  const hideButtonLinkModalBox = () => {
    setShowButtonLinkModal(false);
  };

  const addButtonLink = () => {
    insertButtonLink(
      buttonLinkName,
      buttonLink,
      editorState,
      AtomicBlockUtils,
      EditorState,
      setEditorState
    );
    hideButtonLinkModalBox();
    setButtonLink("");
    setButtonLinkName("");
  };
  const handleButtonLinkChange = (e) => {
    setButtonLink(e);
  };
  const handleButtonLinkNameChange = (e) => {
    setButtonLinkName(e);
  };

  const handlePurchaseButtonChange = () => {
    if (bothPaymentsControlsEnabled) {
      setPurchasedButtonEnabled((prev) => !prev);
    }
  };
  const handleSubscriptionButtonChange = () => {
    if (bothPaymentsControlsEnabled) {
      setSubscriptionButtonEnabled((prev) => !prev);
    }
  };
  const handleBothPaymentControlsChange = () => {
    setBothPaymentControlsEnabled((prev) => !prev);
  };

  const handleNameChange = (name) => {
    setName(name);
  };

  const handlePriceChange = (price) => {
    setPrice(price);
  };

  const hideTierNoticeModal = () => {
    setTierNoticeModalOpen(false);
  };

  const handleTagChange = (tag) => {
    setTag(tag);
  };

  //Advert Section

  const getBlockStyle = (block) => {
    switch (block.getType()) {
      case "blockquote":
        return "RichEditor-blockquote";
      case "left":
        return "align-left";
      case "center":
        return "align-center";
      case "right":
        return "align-right";
      default:
        return null;
    }
  };

  const CaretCoordinates = {
    x: 0,
    y: 0,
  };

  const handlePaymentTypeChange = (type) => {
    setPaymentType([]);
    if (!paymentType.includes(type)) {
      setPaymentType((prev) => [...prev, type]);
    }
  };

  const openDeletePromtModal = () => {
    setShowDeletePromptModal(true);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };

  const hideDeletePromtModal = () => {
    setShowDeletePromptModal(false);
  };

  useEffect(() => {
    if (!isPublisher() || JSON.parse(isAuthUser()).uuid !== userId) {
      window.location.href = "/";
    }
    fetchPage();
    getPublisher();
    getApprovedContracts();
    getDefaultContracts();
    getVerifiedCustomerServiceSets();
    if (isResponsiveMode()) {
      import("../../../../../src/custom-page-mobile.css");
    } else {
      import("../../../../../src/custom-page-desktop.css");
    }
  }, []);

  const getProductMetasBySessionId = (purchaseType) => {
    const url =
      API_BASE +
      "/book/custom/product/metas/page/" +
      pageId +
      "/" +
      purchaseType;
    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          // setProductMetaMerge(response.data.metas);
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  const getAvailableQuantityForMeta = (metaId, quantity) => {
    const url =
      API_BASE +
      `/book/custom/product/meta/block/${metaId}/quantity/${quantity}/page/` +
      pageId;
    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setProductMetaData((prevState) => ({
            ...prevState,
            availablequantity: response.data.availablequantity,
          }));
          // console.log("availcccccc", response.data.availablequantity);
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  const getProductMetaByBlockMetaId = (metaId) => {
    const url =
      API_BASE + `/book/custom/product/meta/block/${metaId}/page/` + pageId;
    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setProductMetaData(response.data.meta);
          setCreateProductMetaMode(false);
          setSelectPurchaseModelOpen(true);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          var scrollPosition = window.scrollY;
          setCurrScrollPos(scrollPosition);
          // alert("Hey");
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  useEffect(() => {
    if (bothPaymentsControlsEnabled) {
      setPurchasedButtonEnabled(true);
      setSubscriptionButtonEnabled(true);
    } else {
      setPurchasedButtonEnabled(false);
      setSubscriptionButtonEnabled(false);
    }
  }, [bothPaymentsControlsEnabled]);

  useEffect(() => {
    if (page.id) {
      if (
        page.enable_custom_page_purchase &&
        page.enable_custom_page_subscription &&
        !paymentType.includes("both")
      ) {
        setPaymentType((prev) => [...prev, "both"]);
      }
      if (
        page.enable_custom_page_subscription &&
        !page.enable_custom_page_purchase &&
        !paymentType.includes("subscription")
      ) {
        setPaymentType((prev) => [...prev, "subscription"]);
      }

      if (
        !page.enable_custom_page_subscription &&
        page.enable_custom_page_purchase &&
        !paymentType.includes("purchase")
      ) {
        setPaymentType((prev) => [...prev, "purchase"]);
      }
      let tagNames = [];
      if (page.tags) {
        page.tags.map((t) => tagNames.push(t));
        setTags(tagNames);
      }
    }
  }, [page]);

  const makeToolbarSticky = () => {
    let el = document.getElementById("my-sp-toolbar");
    let div_top = el?.offsetTop;
    let window_top =
      window.pageYOffset || document.documentElement.scrollTop - 0;
    if (window_top > div_top) {
      if (!el.classList.contains("sticky-style")) {
        el.classList.add("sticky-style");
        if (isResponsiveMode()) {
          el.classList.add("shrink-stuff");
        }
      }
    } else {
      el.classList.remove("sticky-style");
      if (isResponsiveMode()) {
        el.classList.remove("shrink-stuff");
      } else {
        el.classList.remove("sticky-style");
      }
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", makeToolbarSticky);
  }, []);

  const hasNoCoverPhoto = () => {
    if (!page.id) {
      return false;
    }
    // if (!productSlides.length) {
    //   return false;
    // }

    return true;
  };

  const addSpace = (e) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const txt = " ";
    isRemovingLinkFromList.added = true;
    setAddedSpaceToLinkItem(true);
    //Collapses a selection and sets focus to start of selection
    let collapsed = selection.merge({
      anchorOffset: selection.getStartOffset(),
      focusOffset: selection.getStartOffset(),
    });

    let newEditorState = EditorState.forceSelection(editorState, collapsed);
    if (collapsed.isCollapsed()) {
      // alert("is collapsed");
      const nextContentState = Modifier.insertText(
        contentState,
        collapsed,
        txt
      );
      const nextEditorState = EditorState.push(
        newEditorState,
        nextContentState,
        "insert-characters"
      );

      const content = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const block = content.getBlockForKey(startKey);

      const mySelection = SelectionState.createEmpty(block.getKey());
      const updatedSelection = mySelection.merge({
        anchorOffset: selectionAnchors.start,
        focusOffset: selectionAnchors.end + 1,
      });

      newEditorState = EditorState.forceSelection(
        nextEditorState,
        nextEditorState.getSelection()
      );

      const newContentState = Modifier.applyEntity(
        nextContentState,
        updatedSelection,
        null
      );

      setEditorState(
        EditorState.set(nextEditorState, { currentContent: newContentState })
      );
      e.preventDefault();
    }
  };

  const showCurrentLocation = () => {
    if (getLocation().hostname == "litzapp.com") {
      copyText("https://litzapp.com/share/sh/" + page.item_id);
    } else {
      copyText("https://wordalbums1.test/sh/" + page.item_id);
    }
  };

  const copyText = (text) => {
    // standard way of copying
    var textArea = document.createElement("textarea");
    textArea.setAttribute("style", "width:1px;border:0;opacity:0;");
    document.body.appendChild(textArea);
    textArea.value = text;
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    toast("Link Copied", { type: "success" });
  };

  function isEditorEmpty() {
    // const contentState = editorState.getCurrentContent();
    // return contentState.hasText() && contentState.getPlainText().length > 0;
    return false;
  }

  const addTag = () => {
    if (tags.length == 10) {
      toast("Only a maximum of 10 tags allowed", { type: "error" });
      return false;
    }
    if (tags) {
      let currentTagNames = tags.map((t) => t.name);
      let tgs = tag.split(",");
      if (tgs.length > 1) {
        for (let index = 0; index < tgs.length; index++) {
          if (currentTagNames.includes(tgs[index])) {
            toast(tgs[index] + " already exists!", { type: "error" });
            return false;
          }
        }
        let trimEmptyTags = tgs.filter(
          (t) => t !== null && t !== " " && t !== ""
        );
        let trimTags = trimEmptyTags.map((t) => t.trim());
        if (trimTags.length + tags.length > 10) {
          toast("Only a maximum of 10 tags allowed", { type: "error" });
          return false;
        }
        saveTagToDB(trimTags);
        setTags((prev) => [...prev, ...trimTags]);
      } else {
        if (currentTagNames.includes(tag)) {
          toast(tag + " already exists!", { type: "error" });
          return false;
        }

        let trimTag = tag.trim();
        saveTagToDB([tag]);
        setTags((prev) => [...prev, trimTag]);
      }
    }
    setTag("");
  };

  const removeTag = (t) => {
    if (tags.length == 2) {
      toast(
        "There must be at least 2 tags. Before deleting any tags, make sure to first add the new tags that you want, ensuring that there are at least three tags in total.",
        { type: "error" }
      );
      return false;
    }

    let currentTags = tags;
    let filteredTags = currentTags.filter((x) => x.id !== t.id);
    setTags(filteredTags);
    removeTagFromDB(t.id);
  };

  const handleCheckSocialPhoto = (id) => {
    if (!selectedSocialPhoto.includes(id)) {
      setSelectedSocialPhoto((prev) => [...prev, id]);
    } else {
      const s = selectedSocialPhoto;
      const removeSlide = s.filter((s) => s !== id);
      setSelectedSocialPhoto(removeSlide);
    }
  };

  const clearCheckedSocialPhotos = () => {
    setSelectedSocialPhoto([]);
  };

  const handleSocialEmbdedCodeChange = (e) => {
    setSocialEmbedValue(e);
  };

  const handleSocialUploadTypeChange = (e) => {
    setSocialUploadType(e);
  };

  const handleSocialUploadSubTypeChange = (e) => {
    setSocialUploadSubType(e);
  };

  return (
    <>
      <div className="flex flex-col relative">
        <div className="bg-white mx-1 p-2 flex justify-around md:justify-between shadow">
          <div>
            <p className="text-1xl md:text-2xl text-left  font-bold">
              Update Property/Product Page
            </p>
            <BugReportNotice />
            {/* <span className="text-base text-red-700 font-light">
            Report bugs to admin@litzapp.com
          </span> */}
          </div>

          <div className="flex flex-col  md:flex-row justify-between items-center gap-4 hidden md:flex">
            <div>
              <a
                href="https://litzapp.com/web/rich-page/render/190ad389-cd98-44fd-ab8e-61831ce658ea"
                target="_blank"
              >
                <BasicButton
                  title={"Tutorials"}
                  classes={` bg-black text-white`}
                />{" "}
              </a>
            </div>
            {page.id && page?.meta_payment_type == "recurring" ? (
              <BasicButton
                classes={"my-bg-yellow-500 text-black"}
                title={"View Calendar"}
                handleClick={() => openProductCalendar(page)}
              />
            ) : (
              ""
            )}

            {page.id && !isDataSubExpired(publisher) ? (
              <BasicButton
                disabled={
                  processing ||
                  !name ||
                  !hasNoCoverPhoto() ||
                  showDisplayPurchaseButtonError()
                }
                title={processing ? "Updating.." : "Update Page"}
                handleClick={() => {
                  if (!name || !hasNoCoverPhoto()) {
                    toast("Please add one or more product images ", {
                      type: "notice",
                    });
                  } else {
                    updatePage();
                  }
                }}
              />
            ) : (
              ""
            )}

            <div
              onClick={() => (!isEditorEmpty() ? showCurrentLocation() : "")}
            >
              <i
                class={`fa fa-share-alt cursor-pointer ${
                  !isEditorEmpty() ? "text-black" : "text-gray-500"
                }`}
              ></i>
              {/* {getLocation().href} */}
            </div>

            <BasicButton
              title={"Go Back"}
              classes="my-bg-yellow-500 text-black"
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex flex-col md:hidden">
          <div className="flex justify-center gap-4 p-2">
            {" "}
            {page.id && !isDataSubExpired(publisher) ? (
              <div className="w-full">
                <BasicButton
                  disabled={
                    processing ||
                    !name ||
                    !hasNoCoverPhoto() ||
                    showDisplayPurchaseButtonError()
                  }
                  title={processing ? "Updating.." : "Update Page"}
                  classes="w-full"
                  handleClick={() => {
                    if (!name || !hasNoCoverPhoto()) {
                      toast(
                        "Please add a title and cover photo before updating ",
                        {
                          type: "notice",
                        }
                      );
                    } else {
                      updatePage();
                    }
                  }}
                />
              </div>
            ) : (
              ""
            )}
            <div
              onClick={() => (!isEditorEmpty() ? showCurrentLocation() : "")}
            >
              <i
                class={`fa fa-share-alt cursor-pointer ${
                  !isEditorEmpty() ? "text-black" : "text-gray-500"
                }`}
              ></i>
              {/* {getLocation().href} */}
            </div>
            <div className="w-full">
              <BasicButton
                title={"Go Back"}
                classes="my-bg-yellow-500 text-black w-full"
                handleClick={() => window.history.back()}
              />
            </div>
          </div>

          <div className="flex justify-center gap-4 p-2">
            {page.buyers &&
            !page.buyers.length &&
            !isDataSubExpired(publisher) &&
            canDeletePage() ? (
              <div className="w-full">
                <BasicButton
                  classes={"bg-red-500 hover:bg-red-400 w-full"}
                  disabled={!page}
                  title={"Delete Page"}
                  handleClick={() => openDeletePromtModal()}
                />
              </div>
            ) : (
              ""
            )}{" "}
            {page.id && page?.meta_payment_type == "recurring" ? (
              <div className="w-full">
                <BasicButton
                  classes={"my-bg-yellow-500 text-black w-full"}
                  title={"View Calendar"}
                  handleClick={() => openProductCalendar(page)}
                />
              </div>
            ) : (
              ""
            )}
            <div className="w-full">
              <a
                href={`/web/rich-page/render/${page && page.uuid}`}
                target="_blank"
              >
                <BasicButton
                  classes={"bg-teal-500 hover:bg-teal-400 w-full"}
                  disabled={!page}
                  title={"Preview Page"}
                  handleClick={(e) => e.preventDefault()}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:grid md:grid-cols-3 justify-center items-start gap-4 mt-2 content-start">
          <div className="md:col-span-2 relative">
            {isResponsiveMode() ? (
              <p
                className="text-black p-2 text-red-900"
                style={{ fontSize: "14px" }}
              >
                For better experience use the page editor on a desktop or laptop
                because mobile browsers do no have full support.
              </p>
            ) : (
              ""
            )}
            <div className="p-2">
              <TextField
                placeholder={"Add title.."}
                classes={"p-2 h-26"}
                handleChange={handleNameChange}
                value={name}
              />
            </div>

            <ToolBar
              editorState={editorState}
              makePremium={makePremium}
              setEditorState={setEditorState}
              showPaymentControlsBox={showPaymentControlsBox}
              showAdBox={showAdBox}
              showVideoModalBox={showVideoModalBox}
              showSocialsLinkBox={showSocialsLinkBox}
              showImageModalCreateBox={showImageModalCreateBox}
              showTableModalCreateBox={showTableModalCreateBox}
              showAccordionModalCreateBox={showAccordionModalCreateBox}
              showHireModalCreateBox={showHireModalCreateBox}
              insertSignupBoxIntoEditor={insertSignupBoxIntoEditor}
              displayProductMeta={addCustomPageProductMeta}
              showButtonLinkModalBox={showButtonLinkModalBox}
              showProductMetaBox={showPBOptionsModal}
              showProductMetaSetupBox={showProductMetaBox}
              cannotDisplaySPButton={cannotDisplaySPButton}
              cannotDisplayREButton={cannotDisplayREButton}
              displaySelectImagesBox={displaySelectImagesBox}
              displayBannerBox={displayBannerBox}
              canDisplayImageOptions={canDisplayImageOptions}
              onBlockClick={onBlockClick}
              onInlineClick={onInlineClick}
              setEditorOutput={setEditorOutput}
              defaultFontSize={defaultFontSize}
              setDefaultFontSize={setDefaultFontSize}
              selectionHasLink={selectionHasLink}
              selectionAnchors={selectionAnchors}
              RichUtils={RichUtils}
              EditorState={EditorState}
              Modifier={Modifier}
              AtomicBlockUtils={AtomicBlockUtils}
              getDefaultKeyBinding={getDefaultKeyBinding}
              indentRef={indentRef}
              addSpace={addSpace}
              showLinkBox={showLinkBox}
              picker={picker}
              page={page}
              editorType={"product"}
            />

            <div className="p-2 mt-2">
              {/* {`${lastEditorChangeType + entityCalculator}`} */}
              <Editor
                ref={editor}
                plugins={plugins}
                editorState={editorState}
                onChange={(st) => handleEditorChange(st)}
                customStyleFn={picker.customStyleFn}
                customStyleMap={COLOR_TYPES}
                onFocus={handleFocus}
                onBlur={handleBlur}
                blockRendererFn={mediaBlockRenderer}
                blockStyleFn={getBlockStyle}
                onTab={changeIndent}
                placeholder="Type here..."
                autoCapitalize={"none"}
                autoComplete={"off"}
                autoCorrect={"off"}
                spellCheck={false}
              />
            </div>
          </div>
          <div className="bg-gray-50 w-full p-2 z-40">
            <h1 className="text-2xl text-center m-2 font-bold">
              {" "}
              Page Settings
            </h1>
            <div className="w-full flex justify-center my-6">
              <BasicButton
                classes={"my-bg-yellow-500 text-black mb-2"}
                disabled={!page || !productMeta?.block_meta_id}
                title={"Purchase Button"}
                handleClick={() => showProductMetaBox()}
              />
            </div>
            <div className="w-full flex justify-center gap-1 my-6">
              {" "}
              {showDisplayPurchaseButtonError() && (
                <p className="text-red-500 text-1xl w-full text-center">
                  Please Click Display Purchase Button
                </p>
              )}
            </div>
            <div className="m-2 mb-8 flex flex-col items-center">
              <div className="m-2 mb-8 flex flex-col items-center">
                <label className="text-black">Enter Tags</label>
                <small>
                  Enter at least 2 tags describing your product and separated by
                  commas
                </small>
                <TextField
                  classes={"p-6 my-2 text-xxs"}
                  placeholder={
                    "Tags...(eg office manual, news, school work, services, etc)"
                  }
                  handleChange={handleTagChange}
                  value={tag}
                />
                <small>You can always add or delete a tag afterwards</small>
                <BasicButton
                  disabled={!tag || tags.length == 10}
                  title={processing ? "Adding.." : "Add Tag(s)"}
                  classes={"p-6 w-9/12 mt-4"}
                  handleClick={addTag}
                />
              </div>
              <div className="m-2 flex flex-wrap">
                {tags.map((t) => (
                  <div className="rounded-full text-black border p-2 inline relative m-1 px-3">
                    <span className="text-sm pr-1">
                      ${t.name}
                      <span
                        className="fa fa-times absolute top-3 right-1 text-xs cursor-pointer text-gray-500"
                        onClick={() => removeTag(t)}
                      ></span>
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <MultiImageUploaderNew
              setFileSelected={addProductSlides}
              fileSelected={coverFileSelected}
            />
            <p className="text-center mb-2 text-green-500">
              {uploadingSlides ? "Uploading Image(s)..." : ""}
            </p>
            <p className="text-center mb-2 text-green-500">
              {deletingSlides ? "Deleting Image..." : ""}
            </p>
            {productSlides.length ? (
              <div className="w-full p-2 my-2 h-64 overflow-auto">
                <p className="text-center">
                  {productSlides.length} images uploaded
                </p>
                <div className="-m-1 flex flex-wrap md:-m-2 py-3">
                  {productSlides.map((slide, i) => (
                    <div className="flex w-1/3 flex-wrap relative">
                      <div class="w-full p-1 md:p-2">
                        <img
                          class="block h-full w-full rounded-lg object-cover object-center"
                          src={
                            BASE_URL +
                            "/storage" +
                            slide?.file?.split("public")[1]
                          }
                        />
                      </div>

                      <div className="bg-white rounded rounded-full bg-white p-0 top-0 right-0 absolute px-1 cursor-pointer">
                        {!deletingSlides ? (
                          <i
                            class="fa fa-times-circle text-red-500  z-50 text-2xl"
                            onClick={() => removeProductSlide(slide.slide_id)}
                          ></i>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            <FreeContentCoverLinks />
            <div className="p-4">
              <div className="w-full flex justify-center gap-1 my-6">
                <BasicButton
                  classes={"bg-black text-white mb-2"}
                  disabled={!page}
                  title={"Social Media (optional)"}
                  handleClick={() => showSocialsLinkBox()}
                />
              </div>

              {/* buttons with transactions */}
            </div>
            {page.id ? (
              <div className="flex flex-col items-center gap-1 w-full">
                <div className="w-full">
                  {" "}
                  <a
                    href={`/web/rich-page/render/${page && page.uuid}`}
                    target="_blank"
                  >
                    <BasicButton
                      classes={"bg-teal-500 hover:bg-teal-400 w-full"}
                      disabled={!page}
                      title={"Preview Page"}
                      handleClick={(e) => e.preventDefault()}
                    />
                  </a>
                </div>

                <div className="w-full md:hidden">
                  {page.id && !isDataSubExpired(publisher) ? (
                    <BasicButton
                      classes={"w-full"}
                      disabled={
                        processing ||
                        !name ||
                        !hasNoCoverPhoto() ||
                        showDisplayPurchaseButtonError()
                      }
                      title={processing ? "Updating.." : "Update Page"}
                      handleClick={() => {
                        if (!name || !hasNoCoverPhoto()) {
                          toast(
                            "Please add a title and cover photo before updating ",
                            {
                              type: "notice",
                            }
                          );
                        } else {
                          {
                            /* restoreButtonsWithTransactions("before"); */
                          }
                        }
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="w-full">
                  {page.buyers &&
                  !page.buyers.length &&
                  !isDataSubExpired(publisher) &&
                  canDeletePage() ? (
                    <BasicButton
                      classes={"bg-red-500 hover:bg-red-400 w-full"}
                      disabled={!page}
                      title={"Delete Page"}
                      handleClick={() => openDeletePromtModal()}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <AdInsertModalEditor
          modalOpen={showAdModal}
          hideAdModal={hideAdModal}
          advertRequestStatus={advertRequestStatus}
          requestAdvert={addCustomPageAdvert}
        />
        <PaymentControlsModal
          modalOpen={showPaymentControlsModal}
          hideAdModal={hidePaymentControlsBox}
          action={updatePaymentControlsSetting}
          handlePurchaseButtonChange={handlePurchaseButtonChange}
          handleSubscriptionButtonChange={handleSubscriptionButtonChange}
          handleBothChange={handleBothPaymentControlsChange}
          values={{
            purchaseButtonEnabled: purchaseButtonEnabled,
            subscriptionButtonEnabled: subscriptionButtonEnabled,
            bothPaymentsControlsEnabled: bothPaymentsControlsEnabled,
          }}
          paymentType={paymentType}
          handlePaymentTypeChange={handlePaymentTypeChange}
        />
        <LinkModalEditor
          modalOpen={showLinkModal}
          hideLinkModal={hideLinkModal}
          insertLink={() =>
            insertLink(
              editorState,
              currPastedLink,
              EditorState,
              setEditorState,
              setCurrPastedLink,
              hideLinkModal,
              RichUtils
            )
          }
          handleLinkChange={handleLinkChange}
          value={currPastedLink}
        />
        <VideoModalEditor
          modalOpen={showVideoModal}
          hideLinkModal={hideVideoModalBox}
          insertVideo={() => addCustomPageVideo()}
          handleVideoPreview={handleVideoPreview}
          handleVideoLinkChange={handleVideoLinkChange}
          resetVideoPreview={resetVideoPreview}
          resetVideoLinkPreview={resetVideoLinkPreview}
          videoFileSelected={videoFileSelected}
          processing={processing}
          value={currPastedVideoLink}
        />
        <ImageModalEditor
          modalOpen={showImageModal}
          hideLinkModal={hideImageModalBox}
          insertImage={() => addCustomPageImage()}
          handleImagePreview={handleImagePreview}
          handleImageLinkChange={handleImageLinkChange}
          resetImagePreview={resetImagePreview}
          resetImageLinkPreview={resetImageLinkPreview}
          imageFileSelected={imageFileSelected}
          currEditingImagePreview={currEditingImagePreview}
          createImageUploadMode={createImageUploadMode}
          processing={processing}
          value={currPastedImageLink}
          authTokens={authTokens}
          selectedSocialPhoto={selectedSocialPhoto}
          handleCheckSocialPhoto={handleCheckSocialPhoto}
          clearCheckedSocialPhotos={clearCheckedSocialPhotos}
          handleSocialEmbdedCodeChange={handleSocialEmbdedCodeChange}
          socialEmbedValue={socialEmbedValue}
          handleSocialUploadTypeChange={handleSocialUploadTypeChange}
          handleSocialUploadSubTypeChange={handleSocialUploadSubTypeChange}
          socialUploadType={socialUploadType}
          socialUploadSubType={socialUploadSubType}
          addSocialImageLinksToEditor={addSocialImageLinksToEditor}
          addSocialEmbedToEditor={addSocialEmbedToEditor}
          handleVideoPreview={handleVideoPreview}
          handleVideoLinkChange={handleVideoLinkChange}
          resetVideoPreview={resetVideoPreview}
          resetVideoLinkPreview={resetVideoLinkPreview}
          videoFileSelected={videoFileSelected}
          videoLinkValue={currPastedVideoLink}
          insertYoutubeVideo={addCustomPageVideo}
        />

        <ButtonLinkModalEditor
          modalOpen={buttonLinkModal}
          hideLinkModal={hideButtonLinkModalBox}
          insertButtonLink={addButtonLink}
          handleButtonLinkChange={handleButtonLinkChange}
          handleButtonLinkNameChange={handleButtonLinkNameChange}
          linkValue={buttonLink}
          nameValue={buttonLinkName}
        />
        {page && (
          <DeletePromptModal
            modalOpen={showDeletePromptModal}
            hideAdModal={hideDeletePromtModal}
            title={
              "If you delete this product, all the edits and associated buttons will not be recoverable. Are you sure you want to delete this product?"
            }
            yesActionText={"Yes, Delete Product"}
            noActionText={"No, Cancel"}
            action={() => deletePage(page)}
            processing={processing}
          />
        )}
        <TierUpgradeNoticeModal
          modalOpen={tierNoticeModalOpen}
          hideModal={hideTierNoticeModal}
          notice={tierModalNotice}
        />
      </div>

      <SelectPurchaseModal
        modalOpen={selectPurchaseModalOpen}
        hideAdModal={hideProductMetaBox}
        user={publisher}
        addCustomPageProductMeta={addCustomPageProductMeta}
        productMetaData={productMetaData}
        customerServiceSets={customerServiceSets}
        createProductMetaMode={createProductMetaMode}
        getProductMetasBySessionId={getProductMetasBySessionId}
        productContracts={productContracts}
        productDefaultContracts={productDefaultContracts}
        processing={processing}
        state={{
          productMetas: productMetas,
          showProductMetaModalUpdateBox: showProductMetaModalUpdateBox,
          setProductMeta: setProductMeta,
          productMeta: productMeta,
          metaMode: metaMode,
          setMetaMode: setMetaMode,
          addCustomPageProductMeta: addCustomPageProductMeta,
          cannotDisplaySPButton: cannotDisplaySPButton,
          cannotDisplayREButton: cannotDisplayREButton,
          resetProductMetaPayload: resetProductMetaPayload,
          getAvailableQuantityForMeta: getAvailableQuantityForMeta,
          saveMetaConfig: saveMetaConfig,
        }}
        pageContext="update"
      />

      <SelectPurchaseOptions
        state={{
          hideAdModal: hidePBOptionsModal,
          modalOpen: showPOModal,
          addCustomPageProductMeta: addCustomPageProductMeta,
          cannotDisplaySPButton: cannotDisplaySPButton,
          cannotDisplayREButton: cannotDisplayREButton,
          showProductMetaBox: showProductMetaBox,
        }}
      />

      <ProductCalendarModal
        hideModal={closeProductCalendar}
        modalOpen={showProductCalendar}
        state={{ data: currSelProduct }}
      />
      <SelectImagesModal
        modalOpen={selectImagesModalOpen}
        hideAdModal={closeSelectImagesModal}
        productSlides={productSlides}
        selectedSlides={selectedSlides}
        handleCheckSlide={handleCheckSlide}
        addSlidesToEditor={addSlidesToEditor}
        pageContext={"update"}
      />
      <BannerModal
        modalOpen={bannerModalOpen}
        hideAdModal={closeBannerModal}
        productSlides={productSlides}
        bannerSlides={bannerSlides}
        handleCheckBannerSlide={handleCheckBannerSlide}
        addBannerToEditor={addBannerToEditor}
        updateBannerInEditor={updateBannerInEditor}
        handleBannerTextChange={handleBannerTextChange}
        bannerSlideSelected={bannerSlideSelected}
        handleBannerUrlChange={handleBannerUrlChange}
        getSelBannerSlideInfo={getSelBannerSlideInfo}
        createBannerMode={createBannerMode}
        pageContext={"update"}
      />
      <SocialsLinkEditor
        modalOpen={socialLinksModelOpen}
        hideLinkModal={closeSocialLinksModal}
        handleSocialsLinkChange={handleSocialsLinkChange}
        socialLinkValue={socialLinkValue}
        save={saveSocialLinksConfig}
      />
      <TableEditor
        modalOpen={showTableModal}
        hideLinkModal={hideTableModalBox}
        tableColumns={tableColumns}
        addTableRow={addTableRow}
        addTableColumn={addTableColumn}
        addTableToEditor={addTableToEditor}
        handleTableHeaderChange={handleTableHeaderChange}
        handleTableRowChange={handleTableRowChange}
        handleTableTitleChange={handleTableTitleChange}
        createTableMode={createTableMode}
        updateTableInEditor={updateTableInEditor}
        deleteTableColumn={deleteTableColumn}
        deleteTableRow={deleteTableRow}
        tableTitle={tableTitle}
      />
      {showAccordionModal ? (
        <AccordionEditor
          accordions={accordions}
          addAccordionSection={addAccordionSection}
          deleteAccordionSection={deleteAccordionSection}
          handleAccordionHeaderChange={handleAccordionHeaderChange}
          handleAccordionBodyChange={handleAccordionBodyChange}
          updateAccordionInEditor={updateAccordionInEditor}
          showAccordionModalCreateBox={showAccordionModalCreateBox}
          addAccordionToEditor={addAccordionToEditor}
          createAccordionMode={createAccordionMode}
          accordionTitle={accordionTitle}
          modalOpen={showAccordionModal}
          hideLinkModal={hideAccordionModalCreateBox}
          saveAccordionImages={saveAccordionImages}
          deleteAccordionMediaServer={deleteAccordionMediaServer}
          deleteAccordionMediaEditor={deleteAccordionMediaEditor}
          handleAccordionTitleChange={handleAccordionTitleChange}
          addYoutubeToAccordion={addYoutubeToAccordion}
          addSocialToAccordion={addSocialToAccordion}
          accordionId={accordionId}
          processing={processing}
        />
      ) : (
        ""
      )}
    </>
  );
}
