import {
  isAuthUser,
  authUserData,
  isAdmin,
  isPublisher,
} from "../../../../Utils/helpers";
export default function PublisherCreateBar(props) {
  const { location } = props;
  const isCreateContentPage = () => {
    return location.pathname.includes("user/activation/publisher/");
  };

  const renderItem = () =>
    isAuthUser() &&
    isPublisher() &&
    localStorage.getItem("resume_supplier_application") == null;

  return renderItem() ? (
    <div className="w-full flex justify-center self-center">
      <a
        href={`/publisher/create/options`}
        class="text-base font-medium text-black  mx-2 my-bg-yellow-500 rounded-lg p-2 w-full text-center"
      >
        <span className="font-bold">Create</span>
      </a>
    </div>
  ) : (
    ""
  );
}
