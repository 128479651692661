import FacebookEmbedBox from "./components/facebook";
import PinterestEmbedBox from "./components/pinterest";
import TwitterEmbedBox from "./components/twitter";
import MyInstagramBox from "./components/my-instagram";
import InstagramEmbedBox from "./components/instagram";
import BasicButton from "../../../../../../../components/buttons/basic-button";
import YoutubeLinkVideoBox from "./components/youtube/youtube";
import RadioBox from "../../../../../../../components/inputs/radio";
export default function SocialsUploader(props) {
  const { photos } = props;
  const { uploadType } = props;
  const { authButtonToShow } = props;
  const { handleCheckSocialPhoto } = props;
  const { selectedSocialPhoto } = props;
  const { processing } = props;
  const { insertSocial } = props;
  const { handleEmbdedCodeChange } = props;
  const { embedValue } = props;
  const { handleSocialUploadSubTypeChange } = props;
  const { socialUploadSubType } = props;
  const { createImageUploadMode } = props;

  const { handleVideoPreview } = props;
  const { handleVideoLinkChange } = props;
  const { resetVideoPreview } = props;
  const { resetVideoLinkPreview } = props;
  const { videoFileSelected } = props;
  const { videoLinkValue } = props;
  const { insertYoutubeVideo } = props;

  const disableUploadButton = () => {
    if (uploadType == "youtube") {
      if (!socialUploadSubType) return true;
    }

    if (!embedValue && !selectedSocialPhoto.length) return true;

    return false;
  };

  return (
    createImageUploadMode && (
      <div className="my-4 w-full">
        {uploadType == "facebook" && (
          <FacebookEmbedBox
            handleEmbdedCodeChange={handleEmbdedCodeChange}
            embedValue={embedValue}
          />
        )}

        {uploadType == "pinterest" && (
          <PinterestEmbedBox
            handleEmbdedCodeChange={handleEmbdedCodeChange}
            embedValue={embedValue}
          />
        )}

        {uploadType == "twitter" && (
          <TwitterEmbedBox
            handleEmbdedCodeChange={handleEmbdedCodeChange}
            embedValue={embedValue}
          />
        )}

        {uploadType == "instagram" && (
          <InstagramEmbedBox
            handleEmbdedCodeChange={handleEmbdedCodeChange}
            socialUploadSubType={socialUploadSubType}
            embedValue={embedValue}
          />
        )}

        {uploadType == "youtube" && (
          <YoutubeLinkVideoBox
            handleVideoPreview={handleVideoPreview}
            handleVideoLinkChange={handleVideoLinkChange}
            resetVideoPreview={resetVideoPreview}
            resetVideoLinkPreview={resetVideoLinkPreview}
            videoFileSelected={videoFileSelected}
            videoLinkValue={videoLinkValue}
            insertYoutubeVideo={insertYoutubeVideo}
            processing={processing}
          />
        )}

        <div className="w-full my-2">
          {uploadType !== "upload" && uploadType !== "youtube" && (
            <BasicButton
              disabled={disableUploadButton()}
              title={processing ? "Uploading..." : "Upload"}
              classes="px-8 w-8/12"
              handleClick={() => insertSocial()}
            />
          )}
        </div>
      </div>
    )
  );
}
