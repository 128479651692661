import { isAuthUser } from "../../../../Utils/helpers";
export default function MLogoutBar(props) {
  const { handleLogout } = props;
  const renderMenu = () => {
    return isAuthUser();
  };

  return renderMenu() ? (
    <span
      onClick={handleLogout}
      style={{
        cursor: "pointer",
        textAlign: "right",
        color: "tomato",
      }}
      className=""
    >
      Logout
    </span>
  ) : (
    ""
  );
}
