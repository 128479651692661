import axios from "axios";
import { useEffect, useState } from "react";
import BasicButton from "../../../components/buttons/basic-button";
import {
  isSales,
  isAdmin,
  API_BASE,
  isResponsiveMode,
  isSupplier,
} from "../../../Utils/helpers";
import useDebounce from "../../../components/debounce/debounce-helper";
import Pagination from "../../../components/pagination";
import LoadingProgress from "../rich-page/components/loading-progress";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";
import authPaginatedRequestService from "../../../services/HttpRequests/authenticated-paginated-req-service";
import { useSendMailStore } from "../../../stores/send-mail-store";
import MobileTable from "./components/mobile-table";
import DesktopTable from "./components/desktop-table";
import DeleteNoticeModal from "./components/delete-notice";
import DeletePromptModal from "../../../modals/delete-warning-modal";
import FilterBar from "./components/filter";
import { toast } from "react-toastify";
export default function CustomerServices() {
  const [processing, setProcessing] = useState(false);
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currSelSet, setCurSelSet] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [noticeModalOpen, setNoticeModalOpen] = useState(false);
  const [noticeText, setNoticeText] = useState("");

  const mailStore = useSendMailStore((state) => state);

  const openLinkModal = () => {
    setLinkModalOpen(true);
  };
  const closeLinkModal = () => {
    setLinkModalOpen(false);
  };

  const closeDeleteModal = (d) => {
    setCurSelSet({});
    setDeleteModalOpen(false);
  };

  const closeNoticeModal = (d) => {
    setNoticeModalOpen(false);
  };

  const deleteSet = () => {
    setProcessing(true);
    const url =
      API_BASE +
      "/publisher/supplier/my-customer-services/set/" +
      currSelSet.uuid +
      "/delete";
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setTimeout(() => {
            getSets();
            setProcessing(false);
            closeDeleteModal();
          }, 6000);
        }
      })
      .catch((error) => {
        if (error.response.status == 422) {
          setNoticeModalOpen(true);
          //closeDeleteModal();
          setProcessing(false);
          return false;
        }
        toast("Something went wrong", { type: "error" });
        setProcessing(false);
      });
  };

  const getSets = () => {
    mailStore.setData([]);
    const searchText = mailStore.query.searchText || "*";
    const status = mailStore.query.searchText || "*";
    const url =
      API_BASE +
      "/publisher/supplier/my-customer-services/sets/" +
      searchText +
      "/list/paginated";

    setProcessing(true);
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          mailStore.setData(response.data.sets);
        }
        setProcessing(false);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const doPagination = (page) => {
    if (mailStore.data.first_page_url) {
      mailStore.setData([]);
      setCurrentPage(page);

      setProcessing(true);
      authPaginatedRequestService()
        .get(mailStore.data.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          mailStore.setData(res.data.sets);
          setProcessing(false);
        })
        .catch((err) => setProcessing(false));
    }
  };

  const handleSearchTextChange = (e) => {
    mailStore.setQuery("searchText", e);
  };

  useDebounce(
    () => {
      if (mailStore.refresh) {
        getSets();
      }
      mailStore.setRefresh(true);
    },
    [mailStore.query],
    800
  );

  const preDeleteSet = (s) => {
    setDeleteModalOpen(true);
    setCurSelSet(s);
  };

  useEffect(() => {
    if (!isSupplier()) {
      window.location.href = "/";
    }
  }, []);

  return (
    <>
      <div className="main w-full">
        <div className="bg-white m-2 p-2 flex md:flex-row justify-between shadow px-4">
          <div>
            <h1 className="text-2xl text-center m-2 bold">
              My Customer Services
            </h1>
          </div>
          <div className="flex gap-2">
            <div>
              <a href="/publisher/supplier/my-customer-services/create">
                <BasicButton title={"Create Set"} handleClick={null} />
              </a>
            </div>
          </div>
        </div>
        <div className="flex justify-center my-4 overflow-hidden">
          <div class="flex flex-col items-center p-10 w-full">
            <p className="w-full text-center my-4">
              We found {mailStore.data.total ? mailStore.data.total : "0"}{" "}
              records
            </p>
            <p className="w-full text-center my-4"></p>
            {/* <FilterBar
              handleSearchTextChange={handleSearchTextChange}
              serchQValue={mailStore.query.searchText}
              handleStatusOptionChange={handleStatusOptionChange}
              status={mailStore.query.status}
              model={"sales-suppliers"}
            /> */}
            <div className="w-full flex justify-center">
              {mailStore?.data?.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            {isResponsiveMode() ? (
              <MobileTable preDeleteSet={preDeleteSet} />
            ) : (
              <DesktopTable preDeleteSet={preDeleteSet} />
            )}
            <LoadingProgress loading={processing} />
            <div className="w-full flex justify-center">
              {mailStore?.data?.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            <div className="flex w-full gap-4"></div>
          </div>
        </div>
      </div>
      {/* <LinkModal modalOpen={linkModalOpen} hideModal={closeLinkModal} /> */}
      {currSelSet.id ? (
        <DeletePromptModal
          modalOpen={deleteModalOpen}
          hideAdModal={closeDeleteModal}
          title={`Are you sure you want to delete ${currSelSet.name}`}
          yesActionText={"Yes, Delete Set"}
          noActionText={"No, Do Not Cancel"}
          action={deleteSet}
          processing={processing}
        />
      ) : (
        ""
      )}
      {currSelSet.id ? (
        <DeleteNoticeModal
          modalOpen={noticeModalOpen}
          hideAdModal={closeNoticeModal}
          title={noticeText}
          currSelSet={currSelSet}
          action={deleteSet}
          processing={processing}
        />
      ) : (
        ""
      )}
    </>
  );
}
