import TextArea from "../../../../../../../components/inputs/text-area";
import EditorTooltip from "../../tooltip/editor-tooltip";
export default function AfterPurchaseMsg(props) {
  const { productMeta, handleMetaChange } = props;
  return (
    <div className="m-2 mb-2">
      <div className="flex gap-2 relative">
        {" "}
        <label>After Purchase Message (optional):</label>{" "}
        <EditorTooltip
          body={
            "In this textbox, you can input details like guidelines, a personalized greeting, and more, all of which will be featured in the Purchase confirmation email sent to both you and the customer upon completion of the transaction."
          }
          top={"-300px"}
          right={"-80px"}
        />
      </div>
      <TextArea
        placeholder={"Enter text here.."}
        classes="rounded-lg pr-8"
        handleChange={(e) => handleMetaChange(e, "after_purchase", "text")}
        value={productMeta.after_purchase}
      />
    </div>
  );
}
