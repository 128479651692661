import TextField from "../../../../../../components/inputs/text-input";
import TextArea from "../../../../../../components/inputs/text-area";
import BasicButton from "../../../../../../components/buttons/basic-button";
import uuid from "react-uuid";
import { useState, useEffect } from "react";

export default function TableEditor({
  modalOpen,
  hideLinkModal,
  tableColumns,
  addTableRow,
  addTableColumn,
  deleteTableColumn,
  deleteTableRow,
  addTableToEditor,
  handleTableHeaderChange,
  handleTableRowChange,
  handleTableTitleChange,
  tableTitle,
  createTableMode,
  updateTableInEditor,
}) {
  const handleMetaChange = (e, key, type) => {
    // state.setProductMeta((prev) => ({ ...prev, [key]: e }));
  };

  useEffect(() => {
    console.log(tableColumns);
  }, [tableColumns]);

  return (
    <div
      className={` absolute z-50 overflow-y-auto top-0 w-full left-0  ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-6/12 h-4/12 "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className="rounded rounded-full p-0 -top-2 -right-1 absolute px-1 cursor-pointer opacity-75"
            onClick={() => hideLinkModal()}
          >
            <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
          </div>
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2  flex flex-col justify-center items-center">
              <h1 className="text-2xl font-bold text-center">
                {/* {getActiveWidgetTitle(currentEditingWidget, widgets)} */}
                {createTableMode ? "Add Table" : "Update Table"}
              </h1>
              <TextField
                classes={"p-6 my-2"}
                placeholder={"Table Title"}
                handleChange={(e) => {
                  handleTableTitleChange(e);
                  /* handleSocialsLinkChange(e, "instagram", "text") */
                }}
                value={tableTitle}
              />
              <div className="m-2 mb-2 w-full md:w-6/12 flex flex-col itemss-center gap-2 ">
                {tableColumns.length &&
                  tableColumns.map((c, parentIndex) => (
                    <div className="flex gap-2 items-center flex flex-col ">
                      <br />
                      <hr className="text-black" />
                      <p
                        className="text-red-500 cursor-pointer"
                        onClick={() => deleteTableColumn(parentIndex)}
                      >
                        delete column
                      </p>
                      <TextField
                        classes={"p-6 my-2"}
                        placeholder={c.header.placeholder}
                        handleChange={(e) => {
                          handleTableHeaderChange(e, "header", parentIndex);
                          /* handleSocialsLinkChange(e, "instagram", "text") */
                        }}
                        value={c.header.value}
                      />
                      {c.rows.map((r, childIndex) => (
                        <div className="w-full relative">
                          <div
                            className="rounded rounded-full p-0 -top-2 -right-1 absolute px-1 cursor-pointer opacity-75"
                            onClick={() =>
                              deleteTableRow(childIndex, parentIndex)
                            }
                          >
                            <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
                          </div>
                          <TextArea
                            placeholder={r.placeholder}
                            handleChange={(e) => {
                              handleTableRowChange(
                                e,
                                "row",
                                childIndex,
                                parentIndex
                              );
                              /* handleSocialsLinkChange(e, "instagram", "text") */
                            }}
                            value={r.value}
                          />
                        </div>
                      ))}

                      <div className="w-full flex justify-start">
                        {" "}
                        <BasicButton
                          disabled={false}
                          title={"+ Add Row"}
                          classes="px-8 w-8/12"
                          handleClick={() => addTableRow(parentIndex)}
                        />
                      </div>
                    </div>
                  ))}

                <BasicButton
                  disabled={false}
                  title={"+ Add Column"}
                  classes="px-8 w-8/12"
                  handleClick={() => addTableColumn()}
                />
              </div>
            </div>

            <div className="flex justify-center">
              <BasicButton
                disabled={false}
                title={createTableMode ? "Create" : "Update"}
                classes="px-8 w-8/12"
                handleClick={() =>
                  createTableMode ? addTableToEditor() : updateTableInEditor()
                }
              />
            </div>

            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideLinkModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
            {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
