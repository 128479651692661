import BasicButton from "./components/buttons/basic-button";
import PlatformUpdateModal from "./modals/platform-updates-modal";
import {
  authUserData,
  isAuthUser,
  isAdmin,
  API_BASE,
  BASE_URL,
} from "./Utils/helpers";
import { useState, useEffect } from "react";
import WebHeader from "./pages/web/header";
import Footer from "./layout/footer";
import axios from "axios";
import BookSlider from "./components/slider/homepage/book-slider";
import { toast } from "react-toastify";

export const PageHeader = () => {
  const [publishers, setPublishers] = useState([]);
  const [platformModalOpen, setPlatformModalOpen] = useState(false);
  const [signupSuccess, setShowSignupSuccess] = useState(false);
  const [books, setBooks] = useState([]);

  const getPublishers = () => {
    const url = API_BASE + "/api/books";

    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setPublishers(response.data);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const getBooks = () => {
    const url = API_BASE + "/api/books";

    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setBooks(response.data.books);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const showPlatformModal = () => {
    setPlatformModalOpen(true);
  };

  const hidePLatformModal = () => {
    setPlatformModalOpen(false);
  };

  const showSignupSuccess = () => {
    setShowSignupSuccess(true);
  };

  const hasPurchasedBook = (book) => {
    if (!isAuthUser()) {
      return false;
    }

    if (book) {
      const bought = book.buyers.filter((buyer) => {
        // alert(buyer.user_id == authUserData().id);
        if (buyer.user_id == authUserData().id) {
          return buyer;
        }
      });

      //   alert(bought);

      return bought.length ? true : false;
    }

    // return false;
  };

  return (
    <>
      {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
      <WebHeader />
      <div
        class="relative overflow-hidden bg-no-repeat bg-cover bg-black landing_page_hero_section h-56 md:h-72"
        style={{
          backgroundPosition: "50%",
          backgroundImage: `url("https://mdbcdn.b-cdn.net/img/new/slides/146.webp")`,
        }}
      >
        <div
          class="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
          style={{ backgroundColor: `rgba(0, 0, 0, 0.75)` }}
        >
          <div class="flex justify-center items-center h-full">
            <div class="text-center text-white px-6 md:px-12 mt-8 flex flex-col items-center">
              <h1 class="text-5xl font-bold mt-0 mb-6">
                L<span style={{ color: "#f1c232" }}>itz</span>App
              </h1>

              <p className="text-1xl mb-4 md:w-8/12" style={{ fontSize: 16 }}>
                Instantly automate and schedule recurring payments such as rent
                collection, booking renewals, subscriptions, item rentals,
                hires, content monetization, and much more, on the go.
              </p>
              <div className="flex flex-col items-center justify-center">
                {/* <p className="md:text-center md:w-8/12 mb-2">
                  <span style={{ color: "#f1c232" }}> Monetize</span> Your
                  Properties, Products, and Content.
                </p> */}
                <p className="text-white mb-4 font-bold">
                  <a
                    target="_blank"
                    className="underline"
                    href="https://litzapp.com/share/sh/yN3raUvFVs"
                  >
                    START HERE
                  </a>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section */}

      <div className="md:p-2 md:my-2 mb-6 py-4">
        <BookSlider />
      </div>
      {/* section */}

      <section class="pb-10 relative block bg-slate-800">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style={{ height: "80px" }}
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-slate-800 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div class="container mx-auto px-4 lg:pt-24 lg:pb-24">
          <div class="flex flex-col text-center justify-center">
            <div class="w-full px-4 mb-4">
              <h2 class="text-4xl font-semibold text-white">
                Frequently Asked Questions
              </h2>
              <p class="text-lg leading-relaxed mt-4  text-slate-400">
                How do I create an account on Litzapp and activate a Publisher
                Account?
              </p>
            </div>

            <div className="flex justify-center">
              {" "}
              <div class="mt-4 text-white w-10/12 md:w-8/12 text-center justify-evenly">
                First, create a Litzapp account. To do this, simply go to the
                menu and click Sign Up. If you are on mobile, the Sign-Up page
                can be accessed via the square with three lines on the top-right
                corner of the screen. Enter your name, email address, country,
                and password to create a Litzapp account. When you have a
                Litzapp account, log in and go to the menu to activate your
                Publisher account via the Publisher Activation button. This will
                require you to confirm your phone number. When your phone number
                is confirmed, your Publisher account will be activated.
              </div>
            </div>
          </div>
          <br />
          <br />
          <p class="text-white text-center mt-4">
            <a href="/faq" target="_blank" class="underline">
              View More Questions
            </a>
          </p>
        </div>
      </section>
      <PlatformUpdateModal
        modalOpen={platformModalOpen}
        hideModal={hidePLatformModal}
        type={"platform_updates"}
        showSignupSuccess={showSignupSuccess}
      />
      <Footer />
    </>
  );
};

export default PageHeader;
