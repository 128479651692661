import TextField from "../../../../../../../components/inputs/text-input";
import Checkbox from "../../../../../../../components/inputs/checkbox";
import { toast } from "react-toastify";
import TextNumberNoFloatField from "../../../../../../../components/inputs/text-number-no-float";
import TextNumberField from "../../../../../../../components/inputs/text-number-input";
import TextWholeNumberField from "../../../../../../../components/inputs/text-whole-number";
export default function SubscriptionCap(props) {
  const { productMeta, pageContext, handleMetaChange, setQChangedByUser } =
    props;
  return (
    <>
      <div className="flex gap-2 w-full justify-center">
        <div>
          <Checkbox
            disabled={pageContext == "update"}
            handleChange={(e) => {
              if (
                pageContext == "update" ||
                productMeta.integration_type == "pricelabs"
              )
                return;

              if (
                !productMeta.subscription_cap_enabled &&
                productMeta.booking_interruption
              ) {
                handleMetaChange(e, "booking_interruption", "checkbox");
                handleMetaChange("", "quantity", "text");
              }
              handleMetaChange(e, "subscription_cap_enabled", "checkbox");
            }}
            checked={productMeta.subscription_cap_enabled}
          />
        </div>
        <span>Enable Subscription Cap</span>
      </div>
      {productMeta.subscription_cap_enabled ? (
        <div className="m-2 mb-2 flex gap-2 items-center">
          <div>
            <span>Cancel After</span>
          </div>
          <div className="flex gap-1 items-center">
            <TextWholeNumberField
              disabled={pageContext == "update"}
              classes={"p-6 my-2 w-full"}
              placeholder={"Number of subscriptions.."}
              type="number"
              handleChange={(e) => {
                handleMetaChange(e, "subscription_cap_cycle", "text");
              }}
              value={productMeta.subscription_cap_cycle}
            />
            {productMeta.subscription_cap_enabled &&
              !productMeta.subscription_cap_cycle && (
                <span className="text-red-500 text-lg">*</span>
              )}
          </div>

          <div>
            <span>Subscriptions</span>
          </div>
        </div>
      ) : (
        ""
      )}
      {productMeta.subscription_cap_enabled &&
        productMeta.subscription_cap_cycle && (
          <div>
            <p className="text-base text-pink-500">
              {productMeta.subscription_cap_enabled &&
                productMeta.subscription_cap_cycle == 0 &&
                "Please enter a value greater than 0"}
            </p>
          </div>
        )}
    </>
  );
}
