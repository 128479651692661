import axios from "axios";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import BasicButton from "../../../../components/buttons/basic-button";
import {
  isAuthUser,
  API_BASE,
  isSales,
  authUserData,
} from "../../../../Utils/helpers";
import Countries from "../../../../components/country/country";
import Timezones from "../../../../components/timezone/timezone";

export default function SalesBasicRegistration() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [emailConfirmationMode, setEmailConfirmationMode] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [isHuman, setIsHuman] = useState(true);
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [timeZone, setTimezone] = useState("");
  const captchaRef = useRef(null);
  const naviagte = useNavigate();

  const register = () => {
    setIsAuthenticating(true);
    const url = API_BASE + "/api/register";

    axios
      .post(url, {
        name: firstName + " " + lastName,
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        password: password,
        country: country,
        timezone: timeZone,
        country_code: countryCode,
        type: "sales-supplier-application",
        application_reg_type: "sales",
        agent_id: JSON.parse(isAuthUser()).uuid,
      })
      .then((response) => {
        if (response.status == 200) {
          //   toast("Registration Successful", { type: "success" });
          //   setTimeout(() => {
          //     window.location.href = "/login";
          //   }, 500);
          if (response.status == 200) {
            if (!response.data) {
              //   alertMe.show(result.message, { type: "error" });
            } else {
              if (response.data) {
                let user = response.data.data;
                if (user.uuid) {
                  setTimeout(() => {
                    window.location.href = `/sales/supplier/${user.uuid}/supplier-application`;
                  }, 500);
                  // action();
                } else {
                  toast("Error", { type: "error" });
                  // alertMe.show("Unauthorized Access", { type: "error" });
                }
              }
            }
          }
        }
      })
      .catch((error) => {
        if (error.response.status == 422) {
          if ("duplicate_email" in error.response.data) {
            toast("Sorry, the provided email address is already registered!", {
              type: "error",
            });
            setIsAuthenticating(false);
            return false;
          }
          if ("duplicate_phone" in error.response.data) {
            toast("Sorry, the provided phone number is already registered!", {
              type: "error",
            });
            setIsAuthenticating(false);
            return false;
          }
        } else {
          toast("Something went wrong!", { type: "error" });
        }
        setIsAuthenticating(false);
      });
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e);
  };

  const handleLastNameChange = (e) => {
    setLastName(e);
  };

  const handleEmailChange = (e) => {
    setEmail(e);
  };

  const handlePhoneChange = (e) => {
    setPhone(e);
  };

  const handlePasswordChange = (e) => {
    setPassword(e);
  };

  const handlePasswordConfirmationChange = (e) => {
    setPasswordConfirm(e);
  };
  const handleCountryChange = (e) => {
    let objectTarget = JSON.parse(e.target.value);
    setCountry(objectTarget.country);
    setCountryCode(objectTarget.country_code);
  };

  const handleEmailConfirmation = () => {
    if (
      firstName.toLowerCase().includes("litzapp") ||
      firstName.toLowerCase().includes("litzap") ||
      lastName.toLowerCase().includes("litzapp") ||
      lastName.toLowerCase().includes("litzap")
    ) {
      toast(
        "Sorry! Litzapp cannot be used in First Name Or Last Name Field(s)!",
        {
          type: "error",
        }
      );
      return;
    }
    setEmailConfirmationMode((prev) => !prev);
  };

  const handleRegistration = () => {
    register();
  };

  const handleTimezoneChange = (e) => {
    setTimezone(e);
  };

  const validateRecaptcha = () => {
    const token = captchaRef.current.getValue();
    const url = API_BASE + "/api/user/recaptcha/verify";
    axios
      .post(url, { token: token })
      .then((res) => {
        setIsHuman(true);
        // captchaRef.current.reset();
      })
      .catch((error) => {
        console.log(error);
        captchaRef.current.reset();
      });
  };

  useEffect(() => {
    // if (!isSales()) {
    //   window.location.href = "/";
    // }
  }, []);

  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 bold">
            Product Monetizer Account
          </h1>
        </div>
        <div className="flex gap-2 items-start">
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      {!emailConfirmationMode && (
        <div class="container px-6 mx-auto">
          <br />
          <div class="flex flex-col text-center md:text-left justify-evenly items-center">
            <div class="flex flex-col w-full items-center">
              <h1 class="text-2xl text-gray-800 font-bold my-2">Register</h1>
              <p class="mx-auto md:mx-0 text-gray-500">
                Please fill out the form
              </p>
            </div>
            <div className={`w-full md:w-6/12 mx-auto md:mx-0`}>
              <div class="bg-white p-10 flex flex-col w-full shadow-xl rounded-xl">
                {/* <h2 class="text-2xl font-bold text-gray-800 text-left mb-5">
                Sigin
              </h2> */}
                <form action="" class="w-full">
                  <div id="input" class="flex flex-col w-full my-5">
                    <label for="username" class="text-gray-500 mb-2">
                      First Name
                    </label>
                    <input
                      onChange={(e) => handleFirstNameChange(e.target.value)}
                      type="text"
                      id="username"
                      value={firstName}
                      placeholder="Please insert your First Name"
                      class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                    />
                  </div>
                  <div id="input" class="flex flex-col w-full my-5">
                    <label for="username" class="text-gray-500 mb-2">
                      Last Name
                    </label>
                    <input
                      onChange={(e) => handleLastNameChange(e.target.value)}
                      type="text"
                      id="username"
                      value={lastName}
                      placeholder="Please insert your Last Name"
                      class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                    />
                  </div>
                  <div id="input" class="flex flex-col w-full my-5">
                    <label for="username" class="text-gray-500 mb-2">
                      Email
                    </label>
                    <input
                      onChange={(e) => handleEmailChange(e.target.value)}
                      type="text"
                      id="username"
                      value={email}
                      placeholder="Please insert your email"
                      class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                    />
                  </div>

                  <div id="input" class="flex flex-col w-full my-5">
                    <label for="username" class="text-gray-500 mb-2">
                      Phone
                    </label>
                    <input
                      onChange={(e) => handlePhoneChange(e.target.value)}
                      type="text"
                      id="username"
                      value={phone}
                      placeholder="Please insert your phone number"
                      class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                    />
                  </div>

                  <div id="input" class="flex flex-col w-full my-5">
                    <label for="username" class="text-gray-500 mb-2">
                      Country {country}
                    </label>
                    <Countries handleCountryChange={handleCountryChange} />
                  </div>
                  <div id="input" class="flex flex-col w-full my-5 ">
                    <label for="password" class="text-gray-500 mb-2 text-left">
                      Timezone (GMT, UTC..etc) or General Area
                    </label>
                    <Timezones
                      handleTimezoneChange={handleTimezoneChange}
                      value={timeZone}
                    />
                  </div>

                  <div id="input" class="flex flex-col w-full my-5">
                    <label for="password" class="text-gray-500 mb-2">
                      Password
                    </label>
                    <input
                      onChange={(e) => handlePasswordChange(e.target.value)}
                      type="password"
                      id="password"
                      value={password}
                      placeholder="Please insert your password"
                      class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                    />
                  </div>
                  <div id="input" class="flex flex-col w-full my-5">
                    <label for="password" class="text-gray-500 mb-2">
                      Confirm Password
                    </label>
                    <input
                      onChange={(e) =>
                        handlePasswordConfirmationChange(e.target.value)
                      }
                      type="password"
                      id="password"
                      value={passwordConfirm}
                      placeholder="Please insert your password"
                      class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                    />
                    <p className="text-yellow-600">
                      {password &&
                      passwordConfirm &&
                      password !== passwordConfirm
                        ? "Password Mismatch, make sure Password and Confirmed Password are a match"
                        : ""}
                    </p>
                  </div>
                  <div id="button" class="flex flex-col w-full my-5">
                    <button
                      disabled={
                        isAuthenticating ||
                        !firstName ||
                        !lastName ||
                        !country ||
                        !email ||
                        !phone ||
                        !password ||
                        !isHuman ||
                        !timeZone ||
                        password !== passwordConfirm
                      }
                      onClick={() => handleEmailConfirmation()}
                      type="button"
                      class={`w-full py-4 ${
                        isAuthenticating ||
                        !firstName ||
                        !lastName ||
                        !country ||
                        !email ||
                        !phone ||
                        !password ||
                        !isHuman ||
                        !timeZone ||
                        password !== passwordConfirm
                          ? "bg-gray-500"
                          : "bg-black"
                      }  rounded-lg text-green-100`}
                    >
                      {!isAuthenticating ? (
                        <div class="flex flex-row items-center justify-center">
                          <div class="mr-2">
                            <svg
                              class="w-6 h-6"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                              ></path>
                            </svg>
                          </div>
                          <div class="font-bold">Submit</div>
                        </div>
                      ) : (
                        "Creating Account..."
                      )}
                    </button>
                  </div>
                  {/* <div className="flex justify-center w-full my-4">
                    <ReCAPTCHA
                      sitekey="6Lfk5kckAAAAAJrzP9vN1itokN_LIC9Cvx3nmWAW"
                      ref={captchaRef}
                      onChange={validateRecaptcha}
                    />
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {emailConfirmationMode && !registerSuccess && (
        <div class="container px-6">
          <br />
          <div class="flex flex-col text-center md:text-left justify-evenly items-center">
            <div class="w-full text-center">
              <p class="text-lg text-gray-800 font-bold my-2">
                Is <span className="text-green-500">{email}</span> spelt
                correctly.
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <BasicButton
                disabled={isAuthenticating}
                title={`${
                  isAuthenticating ? "Registering..." : "Yes, Register"
                }`}
                classes="w-full bg-teal-500 hover:bg-teal-500"
                handleClick={handleRegistration}
              />
              <BasicButton
                disabled={isAuthenticating}
                title={`No, Go Back`}
                classes="w-full bg-red-700"
                handleClick={handleEmailConfirmation}
              />
            </div>
          </div>
        </div>
      )}

      {registerSuccess && (
        <div class="container px-6">
          <br />
          <div class="flex flex-col text-center md:text-left justify-evenly items-center">
            <div class="w-full text-center">
              <h1 class="text-3xl text-gray-800 font-bold my-2 mb-4">
                Payment
              </h1>
            </div>
            <div className="grid grid-cols-1 gap-4">
              {/* <BasicButton
                title={`Go Back`}
                classes="w-full"
                handleClick={handleEmailConfirmation}
              /> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
