import { useSendMailStore } from "../../../../stores/send-mail-store";
import { isResponsiveMode } from "../../../../Utils/helpers";
import Checkbox from "../../../../components/inputs/checkbox";
export default function MobilePubSubscribersTable(props) {
  const mailStore = useSendMailStore((state) => state);

  const { blockedEmails } = props;
  const { shouldMarkItem } = props;
  const { markItem } = props;

  function capitalizeFirstLetter(str) {
    return str[0].toUpperCase() + str.slice(1);
  }

  return (
    <div className="w-full flex flex-col justify-center my-4 text-center">
      {mailStore.data.data
        ? mailStore.data.data.length
          ? mailStore.data.data.map((subscription) => {
              return (
                <div
                  className="overflow-x-scroll inline"
                  style={{
                    width: document.documentElement.clientWidth - 90 + "px",
                    margin: "0 auto",
                  }}
                >
                  <div className="flex">
                    <div
                      class={`${
                        isResponsiveMode() ? "border text-center" : ""
                      } px-4 py-2 font-medium md:hidden `}
                    >
                      {!blockedEmails.includes(
                        subscription.subscriber.email
                      ) ? (
                        <div className="md:hidden">
                          {" "}
                          <Checkbox
                            checked={
                              shouldMarkItem(subscription.id) ? true : false
                            }
                            handleChange={() => markItem(subscription.id)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="border border-gray px-4 py-2  font-medium">
                      <div className="flex flex-col justify-center items-center">
                        <h3 className="font-bold md:hidden">Name</h3>
                        {subscription.subscriber.name}
                      </div>
                    </div>

                    <div class="border border-gray px-4 py-2  font-medium">
                      <div className="flex flex-col justify-center items-center">
                        <h3 className="font-bold md:hidden">Country</h3>
                        {subscription.subscriber.country}
                      </div>
                    </div>
                    <div class="border border-gray px-4 py-2  font-medium">
                      <div className="flex flex-col justify-center items-center">
                        <h3 className="font-bold md:hidden">Transaction ID</h3>
                        {subscription.transaction_id}
                      </div>
                    </div>

                    <div class="border border-gray px-4 py-2  font-medium">
                      <div className="flex flex-col justify-center items-center">
                        <h3 className="font-bold md:hidden">Status</h3>
                        {capitalizeFirstLetter(subscription.status)}
                      </div>
                    </div>
                    <div class="border border-gray px-4 py-2 font-medium text-center">
                      <h3 className="font-bold md:hidden ">Duration</h3>
                      <div className="flex flex-col items-center gap-4">
                        {" "}
                        <p>
                          <b>Starts: </b>
                          {subscription.start_date
                            ? new Date(
                                subscription.start_date.replace(/-/g, "/")
                              ).getDate() +
                              "-" +
                              (new Date(
                                subscription.start_date.replace(/-/g, "/")
                              ).getMonth() +
                                1) +
                              "-" +
                              new Date(
                                subscription.start_date.replace(/-/g, "/")
                              ).getFullYear()
                            : "N/A"}
                        </p>
                        <p>
                          <b>Ends: </b>
                          {subscription.end_date
                            ? new Date(
                                subscription.end_date.replace(/-/g, "/")
                              ).getDate() +
                              "-" +
                              (new Date(
                                subscription.end_date.replace(/-/g, "/")
                              ).getMonth() +
                                1) +
                              "-" +
                              new Date(
                                subscription.end_date.replace(/-/g, "/")
                              ).getFullYear()
                            : "N/A"}
                        </p>
                      </div>
                    </div>

                    <div class="border border-gray px-4 py-2  font-medium">
                      <div className="flex justify-center">
                        {" "}
                        <h3 className="font-bold md:hidden">Date</h3>
                        {subscription.created_at
                          ? new Date(
                              subscription.created_at.replace(/-/g, "/")
                            ).getDate() +
                            "-" +
                            (new Date(
                              subscription.created_at.replace(/-/g, "/")
                            ).getMonth() +
                              1) +
                            "-" +
                            new Date(
                              subscription.created_at.replace(/-/g, "/")
                            ).getFullYear()
                          : "N/A"}
                      </div>
                    </div>
                    <div
                      class={`${
                        !isResponsiveMode() ? "border" : ""
                      } px-4 py-2  font-medium `}
                    >
                      {!blockedEmails.includes(
                        subscription.subscriber.email
                      ) ? (
                        <div className="hidden md:block">
                          {" "}
                          <Checkbox
                            checked={
                              shouldMarkItem(subscription.id) ? true : false
                            }
                            handleChange={() => markItem(subscription.id)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          : ""
        : ""}
    </div>
  );
}
