import TextField from "../components/inputs/text-input";
import BasicButton from "../components/buttons/basic-button";
import TermsAccordion from "../accordions/terms-accordion";
import { useBookStore } from "../stores/book-store";
import { forwardRef, useState, useEffect } from "react";
import StripeForm from "../payment/pay-button";
import Register2 from "../pages/register2";
import { isAuthUser } from "../Utils/helpers";

export default function Registration2Modal({
  modalOpen,
  hideModal,
  mode,
  action,
  userIdentifier,
}) {
  const { subModalOpen } = useBookStore((state) => state);

  return (
    <div
      className={`absolute z-50  top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class={`relative inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-10/12 ${
            !subModalOpen ? "h-4/12" : "h-screen"
          }`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {!isAuthUser() ? (
            <div
              className="rounded rounded-full p-0 -top-2 -right-1 absolute px-1 cursor-pointer opacity-75"
              onClick={() => hideModal()}
            >
              <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
            </div>
          ) : (
            ""
          )}
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex justify-center">
            {/* modal body */}
            <div class="bg-gray-200 px-4 py-3 flex justify-center items-center">
              {!isAuthUser() ? <SignInButton /> : ""}
            </div>

            <Register2
              action={hideModal}
              mode={mode}
              action2={action}
              userIdentifier={userIdentifier}
            />
            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 flex justify-center items-center">
            {!isAuthUser() ? <SignInButton /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
}

const SignInButton = () => {
  return (
    <a href="/login">
      {" "}
      <div class="flex flex-row items-center justify-center py-2 px-4 my-bg-yellow-500 text-black rounded hover:bg-teal-700 mr-2 cursor-pointer">
        <div class="mr-2">
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
            ></path>
          </svg>
        </div>
        <div class="font-bold"> Sign In</div>
      </div>
    </a>
  );
};
