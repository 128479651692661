import { useRef, useEffect, useState } from "react";
import ListsModal from "./components/dash/lists/components/lists-modal";
import {
  API_BASE,
  BASE_URL,
  isAuthUser,
  authUserData,
  isResponsiveMode,
  getLocation,
} from "../../../Utils/helpers";
import { toast } from "react-toastify";
import ProfileList from "../../user/profiles/list";
import BasicButton from "../../buttons/basic-button";
import useDebounce from "../../debounce/debounce-helper";
import ProductCalendarModal from "../../product-calendar";
import HomepageAvailabilityCalendar from "./components/product";
import DashSectionOne from "./components/dash/section-one";
import DashSectionTwo from "./components/dash/section-two";
import DashSectionThree from "./components/dash/section-three";
import { isDataSubExpired } from "../../../services/DataThreshold";
import RatingPromptModal from "../../../modals/rating-prompt-modal";
import { useDashboardStore } from "../../../stores/dashboardStore";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";
import DeletePromptModal from "../../../modals/delete-warning-modal";

export default function BookSliderGridPublisher(props) {
  const { getPublisher, publisher } = props;
  const [lastRatedBookId, setLastRatedBookId] = useState("");
  const [lastRatedBook, setLastRatedBook] = useState();
  const [currentRating, setCurrentRating] = useState();
  const [currentRatingFilter, setCurrentRatingFilter] = useState(0);
  const [
    currentRatingFilterSpecification,
    setCurrentRatingFilterSpecification,
  ] = useState("");
  const [published, setPublished] = useState([]);
  const [currEditingBook, setCurrEditingBook] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [coverPhoto, setCoverPhoto] = useState("");
  const [fileSelected, setFileSelected] = useState("");
  const [showRatingDropdown, setShowRatingDropdown] = useState(false);
  const [bookType, setBookType] = useState("slides");
  const [searchText, setSearchText] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);
  const [showRatingPromptModal, setShowRatingPromptModal] = useState(false);
  const [ratingPromptModalMessage, setShowRatingPromptModalMessage] =
    useState("");
  const [canShare, setCanShare] = useState([]);
  const [moreTags, setMoreTags] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [showProductCalendar, setShowProductCalendar] = useState(false);
  const [currSelProduct, setCurrSelProduct] = useState({});
  const [showAvailabilityCalendar, setShowAvailabilityCalendar] =
    useState(false);
  const loadMoreBtRef = useRef();
  const multiSearchRef = useRef();
  const dashboardStore = useDashboardStore((state) => state);
  const publisherId = JSON.parse(isAuthUser()).uuid;
  const [profileText, setProfileText] = useState("");

  /*lists*/
  const [listsModalOpen, setListsModalOpen] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [selListProducts, setSelListProducts] = useState([]);
  const [listName, setListName] = useState("");
  const [isListItemsMode, setIsListItemsMode] = useState(false);
  const [isListEditMode, setIsListEditMode] = useState(false);
  const [currentViewingListId, setCurrentViewingListId] = useState("");
  const [deleteListModalOpen, setDeleteListModalOpen] = useState(false);
  // const [currentEditingList, setEditingList] = useState({});

  /*lists*/
  /*select slides update*/
  const handleCheckListProduct = (id) => {
    if (!selListProducts.includes(id)) {
      setSelListProducts((prev) => [...prev, id]);
    } else {
      const s = selListProducts;
      const removeSlide = s.filter((s) => s !== id);
      setSelListProducts(removeSlide);
    }
  };
  const handleListNameChange = (e) => {
    setListName(e);
  };
  const openListsModal = () => {
    setListsModalOpen(true);
    getProducts();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const openDeleteListModal = () => {
    setDeleteListModalOpen(true);
  };

  const closeDeleteListModal = () => {
    setDeleteListModalOpen(false);
  };

  const closeListsModal = () => {
    setListsModalOpen(false);
    setSelListProducts([]);
    setCurrentViewingListId("");
    setListName("");
  };

  const openAvailabilityCalendar = (data) => {
    setShowAvailabilityCalendar(true);
    setCurrSelProduct(data);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const closeAvailabilityCalendar = () => {
    setShowAvailabilityCalendar(false);
    setCurrSelProduct({});
  };

  const openRatingPromtModal = (msg) => {
    setShowRatingPromptModalMessage(msg);
    setShowRatingPromptModal(true);
  };

  const hideRatingPromtModal = () => {
    setShowRatingPromptModalMessage("");
    setShowRatingPromptModal(false);
  };

  const openProductCalendar = (data) => {
    setShowProductCalendar(true);
    setCurrSelProduct(data);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const closeProductCalendar = () => {
    setShowProductCalendar(false);
    setCurrSelProduct({});
  };

  /*lists*/
  const createList = () => {
    setProcessing(true);
    const url = API_BASE + "/publisher/book/lists/create";
    let formData = new FormData();
    formData.append("name", listName);
    formData.append("price", 5);
    for (let i = 0; i < selListProducts.length; i++) {
      formData.append("product_ids[]", selListProducts[i]);
    }
    authRequestService()
      .post(url, formData)
      .then((response) => {
        closeListsModal();
        getBooks();
        toast("Created", { type: "success" });
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.status == 422) {
          if ("duplicate" in error.response.data) {
            toast("An item already exists with that name", {
              type: "error",
            });

            return false;
          }
        }
        toast("Something went wrong", { type: "error" });
      });
  };

  const editList = () => {
    setProcessing(true);
    const url = API_BASE + "/publisher/book/lists/edit";
    let formData = new FormData();
    formData.append("name", listName);
    formData.append("list_id", currentViewingListId);
    for (let i = 0; i < selListProducts.length; i++) {
      formData.append("product_ids[]", selListProducts[i]);
    }
    authRequestService()
      .post(url, formData)
      .then((response) => {
        closeListsModal();
        getBooks();
        toast("Updated", { type: "success" });
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.status == 422) {
          if ("duplicate" in error.response.data) {
            toast("An item already exists with that name", {
              type: "error",
            });

            return false;
          }
        }
        toast("Something went wrong", { type: "error" });
      });
  };

  const deleteList = () => {
    setProcessing(true);
    const url =
      API_BASE + "/publisher/book/lists/delete/" + currentViewingListId;
    authRequestService()
      .get(url)
      .then((response) => {
        closeListsModal();
        getBooks();
        toast("Deleted", { type: "success" });
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };
  const getList = (id) => {
    setProcessing(true);
    setIsListEditMode(true);
    const url = API_BASE + "/publisher/books/list/" + id;
    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        setListName(response.data.list.name);
        setCurrentViewingListId(response.data.list.uuid);
        setSelListProducts(response.data.items);
        console.log(response.data.items);
        openListsModal();
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getProducts = () => {
    setProcessing(true);
    setAllProducts([]);
    const url = API_BASE + `/publisher/books/products/all`;
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setAllProducts(response.data.books);
        }
        setProcessing(false);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
        setProcessing(false);
      });
  };

  const updateCoverPhoto = () => {
    const url = API_BASE + "/publisher/update";
    let formData = new FormData();
    formData.append("user_id", publisherId);
    formData.append("profile_photo", coverPhoto);
    authRequestService()
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          toast("Updated", { type: "success" });
          setCoverPhoto("");
          getPublisher();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const getBooks = () => {
    setProcessing(true);
    setIsListItemsMode(false);
    dashboardStore.setBooks([]);
    dashboardStore.setBooksWithAds([]);
    const url = API_BASE + `/books/${bookType}/paginated/user/` + publisherId;
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          dashboardStore.setBooks(response.data.books.data);
          dashboardStore.setNextPageURL(response.data.books.next_page_url);
        }
        setProcessing(false);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
        setProcessing(false);
      });
  };

  const getBooksByAppliedDFilters = () => {
    dashboardStore.setBooks([]);
    dashboardStore.setBooksWithAds([]);
    setIsListItemsMode(false);
    setProcessing(true);
    const url =
      API_BASE +
      `/books/${bookType}/paginated/filtered/user/${publisherId}/${currentRatingFilter}/${
        currentRatingFilterSpecification.value
      }/${searchText.replace("/", "___")}`;
    window.scrollTo(0, 400);
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          dashboardStore.setBooks(response.data.books.data);
          dashboardStore.setNextPageURL(response.data.books.next_page_url);
        }
        setProcessing(false);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getBooksByListIDFilters = (listId) => {
    dashboardStore.setBooks([]);
    dashboardStore.setBooksWithAds([]);
    setIsListItemsMode(true);
    setProcessing(true);
    const url =
      API_BASE +
      `/publisher/books/lists/paginated/filtered/list/${listId}/${searchText.replace(
        "/",
        "___"
      )}`;
    window.scrollTo(0, 400);
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          dashboardStore.setBooks(response.data.books.data);
          dashboardStore.setNextPageURL(response.data.books.next_page_url);
        }
        setProcessing(false);
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const loadMore = () => {
    if (dashboardStore.nextPageURL) {
      setProcessing(true);
      const url =
        getLocation().hostname == "litzapp.com"
          ? API_BASE + dashboardStore.nextPageURL.split("api/api")[1]
          : dashboardStore.nextPageURL.split("api")[1];

      authRequestService()
        .get(url)
        .then((response) => {
          if (response.status == 200) {
            let oldBooks = dashboardStore.books;
            let newBooks = oldBooks.concat(response.data.books.data);
            if (response.data.books.data) {
              dashboardStore.setBooks(newBooks);
              dashboardStore.setNextPageURL(response.data.books.next_page_url);
            }
          }
          setProcessing(false);
        })
        .catch((error) => {
          setProcessing(false);
          toast("Something went wrong", { type: "error" });
        });
    }
  };

  const rateBook = (value, book) => {
    const url = API_BASE + "/rating";

    if (!isAuthUser()) {
      openRatingPromtModal("Please###");
      return false;
    }
    if (book?.owner_optimized_data?.uuid == authUserData().uuid) {
      return false;
    }
    if (
      book.lastpageviewed &&
      !book.lastpageviewed.filter((p) => p.user_id == authUserData().id)
    ) {
      openRatingPromtModal(
        "You can only leave a rating if you have viewed the content to its last slide/page."
      );
      return false;
    }

    setLastRatedBookId(book.uuid);
    setCurrentRating(value);
    authRequestService()
      .post(url, {
        value: value,
        bookId: book.uuid,
        userId: authUserData().uuid,
      })
      .then((response) => {
        if (response.status == 200) {
          setLastRatedBook(response.data.book);
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const trackPublisher = (publisherId, trackerId) => {
    const url = API_BASE + "/track";
    authRequestService()
      .post(url, { publisherId: publisherId, trackerId: trackerId })
      .then((response) => {
        if (response.status == 200) {
          getPublisher();
          console.log("pub one");
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const untrackPublisher = (publisherId, trackerId) => {
    const url = API_BASE + "/untrack";

    authRequestService()
      .post(url, { publisherId: publisherId, trackerId: trackerId })
      .then((response) => {
        if (response.status == 200) {
          getPublisher();
          console.log("pub two");
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const isTrackingPublisher = (owner) => {
    if (owner.trackers && isAuthUser()) {
      const isTracking = owner.trackers.filter(
        (t) => t.tracker_id == authUserData().id
      );
      return isTracking.length ? true : false;
    }

    return false;
  };

  const updateBook = (book) => {
    const url = API_BASE + "/book/update";
    let formData = new FormData();
    formData.append("uuid", book.uuid);
    formData.append(
      "publisher_publish_status",
      published.includes(book.uuid) ? 1 : 0
    );
    authRequestService()
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          toast("Updated!", { type: "success" });
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const handleCoverPhotoChange = (value) => {
    setCoverPhoto(value);
  };

  const handleSelectedFileChange = (file) => {
    setFileSelected(file);
  };

  const handlePublishStatus = (e, book) => {
    // if (
    //   !published.includes(book.uuid) &&
    //   book.type !== "product" &&
    //   book.type !== "list" &&
    //   !book.cover_photo
    // ) {
    //   toast("Please upload a cover photo to unhide!", { type: "notice" });
    //   return false;
    // }

    if (!published.includes(book.uuid) && Number(book.price) < 0.5) {
      toast(
        "Sorry, price must be $0.50 or greater as our payment providers take a percentage of the payment",
        { type: "notice", className: "z-50" }
      );
      return false;
    }

    let newPublish = published;
    if (newPublish.filter((p) => p == book.uuid).length) {
      let removeItem = newPublish.filter((p) => p !== book.uuid);
      setPublished(removeItem);
      setCurrEditingBook(book);
      console.log("item exists");
    } else {
      console.log("item not exists");
      setCurrEditingBook(book);
      setPublished((prev) => [...prev, book.uuid]);
    }
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e);
    setFirstLoad(false);
  };

  const applyRatingFilter = (value) => {
    setCurrentRatingFilter(value);
    setCurrentRatingFilterSpecification("");
    setShowRatingDropdown(true);
  };

  const resetRating = () => {
    handleSearchTextChange("");
    setCurrentRatingFilter(0);
    setCurrentRatingFilterSpecification("");
    // getBooks();
  };

  const applyRatingFilterSpecification = (e) => {
    setCurrentRatingFilterSpecification(e);
    setShowRatingDropdown(false);
    setFirstLoad(false);
  };

  useEffect(() => {
    dashboardStore.books.map((book) =>
      setPublished((prevState) =>
        !prevState.includes(book.uuid) && book.publisher_publish_status
          ? [...prevState, book.uuid]
          : prevState
      )
    );

    dashboardStore.books.map((book) => {
      if (book.group == "standard") {
        if (
          book.price &&
          book.name &&
          book.cover_photo &&
          book.pages.length > 0
        ) {
          //alert(book.pages.length);
          setCanShare((prev) => [...prev, book.uuid]);
        }
      } else {
        // alert(book.group);
        if (
          (book.price && book.name && book.cover_photo) ||
          (book.price && book.name && book.productimages.length)
        ) {
          setCanShare((prev) => [...prev, book.uuid]);
        }
      }
    });
  }, [dashboardStore.books]);

  useEffect(() => {
    console.log("published", published);
    if (currEditingBook) {
      updateBook(currEditingBook);
    }
  }, [published]);

  useEffect(() => {
    // if (isResponsiveMode()) {
    //   import("../../../../src/landing_mobile");
    // } else {
    //   import("../../../../src/landing.css");
    // }
    getPublisher();
    console.log("pub three");
  }, []);

  //set selected list
  useEffect(() => {
    if (activeTab == 0) {
      setBookType("slides");
    }

    if (activeTab == 3) {
      setBookType("comic_book_photo_album");
    }
    if (activeTab == 4) {
      setBookType("tracking");
    }
    if (activeTab == 5) {
      setBookType("trackers");
    }
    if (activeTab == 6) {
      setBookType("custom");
    }
    if (activeTab == 7) {
      setBookType("product");
    }
    if (activeTab == 9) {
      setBookType("list");
    }
    resetRating();
    setSearchText("");
    setFirstLoad(false);
  }, [activeTab]);

  useEffect(() => {
    if (!firstLoad) {
      getBooks();
    }
  }, [bookType]);

  useEffect(() => {
    if (publisher.name) {
      setProfileText(publisher.profile_text);
      if (publisher.profile_photo && publisher?.backup_profile_photo) {
        setFileSelected(
          BASE_URL + "/storage" + publisher?.profile_photo?.split("public")[1]
        );
      }
      if (publisher?.profile_photo && !publisher?.backup_profile_photo) {
        setFileSelected(
          BASE_URL + "/storage" + publisher?.profile_photo?.split("public")[1]
        );
      }

      if (!publisher?.profile_photo && publisher?.backup_profile_photo) {
        setFileSelected(
          BASE_URL +
            "/storage" +
            publisher?.backup_profile_photo?.split("public")[1]
        );
      }
    }
  }, [publisher]);

  useEffect(() => {
    if (publisher && fileSelected && coverPhoto) {
      updateCoverPhoto();
    }
  }, [fileSelected]);

  const clearSearchText = () => {
    setSearchText("");
  };

  const showCurrentLocation = (book) => {
    if (!book.uuid) return false;

    let copyURL = "";
    if (getLocation().hostname == "litzapp.com") {
      copyURL = `https://litzapp.com/share/sh/${book.item_id}`;
    } else {
      copyURL = `https://wordalbums1.test/sh/${book.item_id}`;
    }

    if (book.group == "standard") {
      copyText(copyURL);
    } else {
      copyText(copyURL);
    }
  };

  const copyText = (text) => {
    // standard way of copying
    var textArea = document.createElement("textarea");
    textArea.setAttribute("style", "width:1px;border:0;opacity:0;");
    document.body.appendChild(textArea);
    textArea.value = text;
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    toast("Link Copied", { type: "success" });
  };

  let adsCounter = 0;

  const shouldRenderAd = () => {
    adsCounter++;
    if (adsCounter == 3) {
      adsCounter = 0;
      return true;
    }
    return false;
  };

  useDebounce(
    () => {
      if (currentRatingFilter > 0) {
      } else {
        setShowRatingDropdown(false);
      }

      if (
        (activeTab < 4 && currentRatingFilterSpecification.value) ||
        searchText
      ) {
        if (isListItemsMode) {
          getBooksByListIDFilters(currentViewingListId);
        } else {
          getBooksByAppliedDFilters();
        }
      } else {
        getBooks();
      }
    },
    [currentRatingFilterSpecification, searchText],
    800
  );

  const initBook = () => {
    var booksWithAdsCollection = [];
    if (
      publisher.advertrequest &&
      publisher.advertrequest.status == "approved"
    ) {
      let adcode = "";

      if (publisher.advertrequest?.provider == "ADSTERRA") {
        adcode = publisher?.advertrequest?.system_ad_2_code;
      } else {
        adcode = publisher?.advertrequest?.system_ad_code;
      }
      for (var i = 0; i < dashboardStore.books.length; i++) {
        booksWithAdsCollection.push({
          content: dashboardStore.books[i],
          type: "book",
        });
        if (dashboardStore.books.length > 2 && shouldRenderAd()) {
          if (true) {
            booksWithAdsCollection.push({
              content: adcode,
              type: "advert",
              provider: publisher.advertrequest?.provider,
            });
          }
        }
      }
    } else {
      for (var i = 0; i < dashboardStore.books.length; i++) {
        booksWithAdsCollection.push({
          content: dashboardStore.books[i],
          type: "book",
        });
      }
    }

    dashboardStore.setBooksWithAds(booksWithAdsCollection);
  };

  const renderTags = (book) => {
    let tagsToRender = 0;
    let updatedTags = [];

    if (moreTags.includes(book.uuid)) {
      for (let index = 0; index < book.tags.length; index++) {
        updatedTags.push({ type: "tag", content: book.tags[index] });

        if (tagsToRender == book.tags.length - 1) {
          updatedTags.push({ type: "view_less", content: "" });
        }
        tagsToRender++;
      }
    } else {
      for (let index = 0; index < book.tags.length; index++) {
        tagsToRender++;
        if (tagsToRender > 3) {
          updatedTags.push({ type: "view_more", content: "" });
          break;
        } else {
          updatedTags.push({ type: "tag", content: book.tags[index] });
        }
      }
    }

    return updatedTags.map((t) => {
      if (t.type == "tag") {
        return (
          <div
            className="rounded-full text-black border p-1 inline relative m-1 px-1 bg-pink-50 cursor-pointer"
            onClick={() => handleSearchTextChange("$" + t.content.name)}
          >
            <span className="text-xs pr-1">${t.content.name}</span>
          </div>
        );
      }

      if (t.type == "view_more") {
        return (
          <p
            className="text-xs text-blue-500 cursor-pointer underline"
            onClick={() => showMoreTags(book.uuid)}
          >
            View More Tags...
          </p>
        );
      }

      if (t.type == "view_less") {
        return (
          <p
            className="text-xs text-blue-500 cursor-pointer underline"
            onClick={() => showLessTags(book.uuid)}
          >
            View Less Tags...
          </p>
        );
      }
    });
  };

  const showMoreTags = (id) => {
    setMoreTags((prev) => [...prev, id]);
  };

  const showLessTags = (id) => {
    let currTags = moreTags;
    let filteredTags = currTags.filter((t) => t !== id);
    setMoreTags(filteredTags);
  };

  const goToContent = (d) => {
    if (d.group == "standard") {
      window.location.href = `/publisher/${d?.owner_optimized_data?.uuid}/publications/series/m/book/${d.id}`;
    }
    if (d.group == "custom" && d.type == "list") {
      getBooksByListIDFilters(d.uuid);
      setCurrentViewingListId(d.uuid);
      return false;
    }
    if (d.group == "custom" && d.type !== "product") {
      window.location.href = `/publisher/rich-page/update/user/${publisherId}/page/${d.uuid}`;
    }

    if (d.group == "custom" && d.type == "product") {
      window.location.href = `/publisher/rich-product-page/update/user/${publisherId}/page/${d.uuid}`;
    }
  };

  const goBackToList = () => {
    getBooks();
  };

  const searchProps = {
    searchText,
    clearSearchText,
    handleSearchTextChange,
    activeTab,
  };

  const ratingProps = {
    currentRatingFilterSpecification,
    currentRatingFilter,
    showRatingDropdown,
    applyRatingFilterSpecification,
    applyRatingFilter,
    resetRating,
    activeTab,
  };

  const dashSectionOne = {
    handleCoverPhotoChange,
    handleSelectedFileChange,
    publisher,
    publisherId,
    isTrackingPublisher,
    setActiveTab,
    activeTab,
    fileSelected,
    untrackPublisher,
    trackPublisher,
  };

  const dashSectionTwoProps = {
    publisher,
    activeTab,
    setActiveTab,
    multiSearchRef,
    openAvailabilityCalendar,
  };

  const dashSectionThreeProps = {
    handlePublishStatus,
    showCurrentLocation,
    openProductCalendar,
    isListItemsMode,
    openListsModal,
    currentRating,
    lastRatedBook,
    goBackToList,
    goToContent,
    renderTags,
    processing,
    published,
    activeTab,
    loadMore,
    rateBook,
    canShare,
    getList,
  };

  useEffect(() => {
    if (dashboardStore.books.length && publisher.id) {
      initBook();
    }
  }, [dashboardStore.books, publisher]);

  return (
    <section>
      <div className="w-full flex justify-center">
        <p className="text-red-700 p-2">
          {isDataSubExpired(publisher)
            ? `Hi ${publisher.name}, your Data Threshold Subscription past due. Please
          renew to remove restrictions on your account.`
            : ""}
        </p>
      </div>

      <main class="bg-gray-100 bg-opacity-25">
        <div class="md:w-10/12 lg:mx-auto mb-4">
          <DashSectionOne {...dashSectionOne} />
          <DashSectionTwo
            rating={ratingProps}
            search={searchProps}
            {...dashSectionTwoProps}
          />
        </div>
      </main>
      {activeTab == 4 ? (
        <ProfileList
          publisherId={publisherId}
          type="tracking"
          searchText={searchText}
        />
      ) : (
        ""
      )}
      {activeTab == 5 ? (
        <ProfileList
          publisherId={publisherId}
          type="trackers"
          searchText={searchText}
        />
      ) : (
        ""
      )}
      {activeTab < 4 || activeTab == 7 || activeTab == 6 || activeTab == 9 ? (
        <DashSectionThree {...dashSectionThreeProps} />
      ) : (
        ""
      )}
      <input ref={loadMoreBtRef} className="h-0" />
      <RatingPromptModal
        hideAdModal={hideRatingPromtModal}
        modalOpen={showRatingPromptModal}
        body={ratingPromptModalMessage}
      />

      <ProductCalendarModal
        hideModal={closeProductCalendar}
        modalOpen={showProductCalendar}
        state={{ data: currSelProduct?.content }}
      />
      <HomepageAvailabilityCalendar
        hideModal={closeAvailabilityCalendar}
        modalOpen={showAvailabilityCalendar}
        state={{ data: [] }}
      />

      <ListsModal
        hideModal={closeListsModal}
        modalOpen={listsModalOpen}
        allProducts={allProducts}
        listName={listName}
        createList={createList}
        editList={editList}
        deleteList={openDeleteListModal}
        handleListNameChange={handleListNameChange}
        selListProducts={selListProducts}
        handleCheckListProduct={handleCheckListProduct}
        isListEditMode={isListEditMode}
        processing={processing}
      />

      {listName ? (
        <DeletePromptModal
          modalOpen={deleteListModalOpen}
          hideAdModal={closeDeleteListModal}
          title={`Are you certain you wish to delete ${listName} `}
          yesActionText={"Yes, Delete the List"}
          noActionText={"No, Do Not Delete"}
          action={deleteList}
          processing={processing}
        />
      ) : (
        ""
      )}
    </section>
  );
}
