import { useNavigate } from "react-router-dom";
import TextField from "../../../../../components/inputs/text-input";
import "react-datepicker/dist/react-datepicker.css";
import { stages } from "./constants";
export default function FilterBar(props) {
  const { handleSearchTextChange } = props;
  const { serchQValue } = props;

  const { handleStatusOptionChange } = props;
  const { status } = props;

  return (
    <>
      {" "}
      <div className="flex justify-center md:justify-center w-full gap-2 md:mr-4 my-2 items-center">
        <div className="flex flex-col md:flex-row gap-4 items-center w-full">
          <div className="flex flex-col items-center w-full">
            <label for="timezone" value="Your Timezone">
              Search
            </label>
            <TextField
              title="Search"
              placeholder={"Search"}
              classes="rounded-lg w-full"
              handleChange={handleSearchTextChange}
              value={serchQValue}
            />
          </div>
          <div className="flex flex-col items-center w-full">
            <label for="timezone" value="Your Timezone">
              Status
            </label>
            <select
              value={status}
              onChange={handleStatusOptionChange}
              required
              class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
            >
              <option value="" defaultValue disabled>
                Not selected
              </option>
              {stages.map((b) => (
                <option value={b}>{b}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
}
