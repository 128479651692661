import { useState, useEffect } from "react";
import BasicButton from "../../../../components/buttons/basic-button";
import authRequestService from "../../../../services/HttpRequests/authenticated-request-services";
import { authUserData, API_BASE } from "../../../../Utils/helpers";
import { useRef } from "react";
export default function VideoVerification(props) {
  const {
    mode,
    stage,
    regType,
    setVideo,
    videoFile,
    handlePrevStage,
    handleNextStage,
    getVerificationData,
    profileUpdateRedo,
  } = props;
  const [captured, setCaptured] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const [streaming, setStreaming] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifying, setVeryfying] = useState(false);
  const [errors, setErrors] = useState({
    permission: false,
    no_face: false,
  });
  const [showPermissionErrorModal, setShowPermissionErrorModal] =
    useState(false);
  const [showNoCameraErrorModal, setShowNoCameraErrorModal] = useState(false);
  const [successfullyVerified, setSuccessfullyVerified] = useState(false);
  const [duplicateFaceErrorModal, setDuplicateFaceErrorModal] = useState(false);
  const [stream, setStream] = useState(null);
  const [successful, setSuccessful] = useState(false);
  const [showCapture, setShowCapture] = useState(false);
  const [noFaceErrorModal, setNoFaceErrorModal] = useState(false);
  const [preview, setPreview] = useState("");
  const [recordedBlobs, setRecordedBlobs] = useState([]);
  const [playing, setPlaying] = useState(false);
  const [savingStage, setSavingStage] = useState(false);

  const videoRef2 = useRef();
  const canvasRef2 = useRef();
  const videoRecordedRef = useState();
  const mediaRecorder = useRef();

  const saveStage = () => {
    const url = API_BASE + "/publisher/supplier/stages/save";
    setSavingStage(true);
    let formData = new FormData();
    formData.append("video", videoFile);
    formData.append("stage", stage);
    formData.append("type", regType);
    formData.append("uuid", authUserData().uuid);
    formData.append("redo_on_profile_uppdate", profileUpdateRedo ? 1 : 0);
    formData.append("mode", mode);

    authRequestService()
      .post(url, formData)
      .then((res) => {
        localStorage.removeItem("resume_supplier_application");
        setSavingStage(false);
        if (mode == "redo") {
          if (profileUpdateRedo) {
            window.location.href = `/publisher/supplier/profile`;
          } else {
            getVerificationData();
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
        } else {
          handleNextStage();
          getVerificationData();
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      })
      .catch((err) => setSavingStage(false));
  };

  const showNotice = () => {
    let notice = false;
    if (!captured) {
      if (!showCapture) {
        notice = true;
      }
    }
    return notice;
  };
  const startRecording = async () => {
    setStreaming(true);
    setRecordedBlobs([]);
    setStream();
    /* Setting up the constraint */
    let facingMode = "user"; // Can be 'user' or 'environment' to access back or front camera (NEAT!)
    let constraints = {
      audio: true,
      video: true,
    };

    /* Stream it to video element */
    try {
      const str = await navigator.mediaDevices.getUserMedia(constraints);
      if (str) {
        setStream(stream);
        let video = videoRef2.current;
        video.srcObject = str;
        recordVideo(str);
      }
    } catch (err) {
      setStreaming(false);
      if (err.toString().search("Permission denied") !== -1) {
        setPermissionErrorState();
      } else if (err.toString().search("device not found")) {
        setNoCameraErrorState();
      }
      console.log("err", err.toString());
    }
  };

  const recordVideo = (stream) => {
    setPlaying(false);
    setStreaming(true);
    setCaptured(false);
    setRecordedBlobs([]);
    let options = { mimeType: "video/webm;codecs=vp9,opus" };
    try {
      let localRecordedBlobs = [];
      const media = new MediaRecorder(stream);
      //set the MediaRecorder instance to the mediaRecorder ref
      mediaRecorder.current = media;
      //invokes the start method to start the recording process
      mediaRecorder.current.start(500);
      mediaRecorder.current.ondataavailable = (event) => {
        if (typeof event.data === "undefined") return;
        if (event.data.size === 0) return;
        localRecordedBlobs.push(event.data);
        console.log("DATA EVENT", event.data);
      };
      setRecordedBlobs(localRecordedBlobs);
      //mediaRecorder.onstop = (event) => console.log(event);
    } catch (e) {
      console.error("Exception while creating MediaRecorder:", e);
      console.log(
        "error",
        `Exception while creating MediaRecorder: ${JSON.stringify(e)}`
      );
      return;
    }
  };

  const stopRecording = () => {
    setStreaming(true);
    setCaptured(true);
    if (mediaRecorder.current) mediaRecorder.current.stop();
    if (stream) stream.getVideoTracks()[0].stop();
    const superBuffer = new Blob(recordedBlobs, { type: "video/webm" });
    const recordedVideo = videoRef2.current;
    recordedVideo.src = null;
    recordedVideo.srcObject = null;
    recordedVideo.src = window.URL.createObjectURL(superBuffer);
    recordedVideo.pause();
    const videoToFile = new File([superBuffer], "demo.webm", {
      type: superBuffer.type,
    });
    setVideo(videoToFile);
  };

  const handleDataAvailable = (event) => {
    console.log("DATA EVENT", event.data);
    if (event.data && event.data.size > 0) recordedBlobs.push(event.data);
  };

  const watchVideo = () => {
    setPlaying(true);
    const superBuffer = new Blob(recordedBlobs, { type: "video/webm" });
    const recordedVideo = videoRef2.current;
    console.log(recordedVideo);
    recordedVideo.src = null;
    recordedVideo.srcObject = null;
    recordedVideo.src = window.URL.createObjectURL(superBuffer);
    recordedVideo.controls = true;
    recordedVideo.play();
  };
  const setPermissionErrorState = () => {
    setErrors((prev) => ({ ...prev, permission: true }));
    setShowPermissionErrorModal(true);
  };
  const setNoCameraErrorState = () => {
    setErrors((prev) => ({ ...prev, no_camera: true }));
    setShowNoCameraErrorModal(true);
  };

  if (stage == 5) {
    import("../../../../styles/publisher/supplier/facial-recognition.css");
  } else {
    import("../../../../styles/publisher/supplier/video-verification.css");
  }

  return (
    <div class="flex flex-col items-center justify-start">
      <h1 className="font-bold my-5">Video Confirmation </h1>
      <div className="flex flex-col gap-2 self-start">
        <p>
          {" "}
          Please make a seventy-five-second video recording of yourself and your
          face while you are outside your business, capturing the surrounding
          area of the premises, and do the following:
        </p>
        <p>
          1. Display the Video Verification Code you have written on paper and
          audibly recite the code.
        </p>
        <p>
          2. Briefly capture parts of the outside surroundings of the business
          entered in the Business Verification Stage, and it should be
          comparable to the imagery found on Google Maps Street View or Bing
          Maps Streetside, for our verification purposes.
        </p>
        <p>3. Enter the building of the business address.</p>
        <p>
          {" "}
          4. End the video with you inside the building in a manner that
          convincingly demonstrates your association with the location.
        </p>
        <p className="text-green-500 font-bold text-center">
          {streaming && !captured && "Recording ends in"}
          {streaming && !captured && (
            <CountdownTimer
              stopRecording={stopRecording}
              isCounting={streaming}
            />
          )}
        </p>
      </div>
      {streaming && (
        <div id="streaming_block" class="w-64  border bg-black flex flex-col">
          <video
            ref={videoRef2}
            width="300"
            height="100"
            muted
            autoPlay
            playsInline
            controls={playing}
          ></video>
        </div>
      )}

      {!streaming && captured && (
        <div id="streaming_block" class="w-64  border bg-black flex flex-col">
          <div class="w-64 h-64">
            hoola
            <video
              width="300"
              height="100"
              ref={videoRecordedRef}
              playsinline
              loop
            ></video>
          </div>
        </div>
      )}

      {errors.permission && (
        <div className="w-64 h-64 border flex flex-col justify-center items-center bg-gray-100">
          <div class="flex justify-center items-center bg-gray-600 rounded-full w-10 h-10">
            <i class="fas fa-exclamation-triangle fa-1x text-gray-200"></i>
          </div>
          <br />
          <p class="text-bold text-gray-700 uppercase text-sm">
            CAMERA ACCESS BLOCKED!
          </p>
        </div>
      )}

      {errors.no_camera && (
        <div className="w-64 h-64 border flex flex-col justify-center items-center bg-gray-100">
          <div className="flex justify-center items-center bg-gray-600 rounded-full w-10 h-10">
            <i className="fas fa-camera fa-1x text-gray-200"></i>
          </div>
          <br />
          <p className="text-bold text-gray-700 uppercase te-xtsm">
            No Camera Found!
          </p>
        </div>
      )}
      {streaming && !captured && (
        <>
          {" "}
          <div className="w-64 border flex flex justify-center items-center gap-1 my-2">
            <BasicButton
              handleClick={() => stopRecording()}
              title={"Stop Recording"}
              disabled={false}
              classes={"bg-black w-full"}
            />
          </div>
        </>
      )}
      {captured && (
        <>
          {" "}
          <div className="w-full border flex flex-col md:flex-row justify-center items-center gap-1 my-2">
            <BasicButton
              handleClick={() => watchVideo()}
              title={"Watch the Video"}
              disabled={savingStage}
              classes={"bg-black w-full"}
            />
            <BasicButton
              handleClick={() => startRecording()}
              title={"Redo the Video"}
              disabled={!captured || savingStage}
              classes={"bg-black w-full"}
            />
          </div>
        </>
      )}
      {!streaming && !(errors.permission || errors.no_camera) && !captured && (
        <>
          {" "}
          <div className="w-64 h-64 border flex flex-col justify-center items-center bg-gray-100">
            <div className="flex justify-center items-center bg-gray-600 rounded-full w-10 h-10">
              <i class="fas fa-video fa-1x text-gray-200"></i>
            </div>
            <br />
          </div>{" "}
          <div className="w-full border flex flex justify-center items-center gap-1 my-1">
            <BasicButton
              disabled={savingStage}
              handleClick={() => startRecording()}
              title={loading || savingStage ? "Working..." : "Record"}
              classes={"bg-black w-full"}
            />
            <BasicButton
              handleClick={() => handlePrevStage()}
              title={"Get New Code"}
              disabled={loading}
              classes={"bg-black w-full"}
            />
          </div>
        </>
      )}
      {captured && (
        <>
          {" "}
          <div className="w-full border flex flex-col md:flex-row justify-center items-center gap-1 my-1">
            <BasicButton
              handleClick={() => handlePrevStage()}
              title={"Get New Code"}
              disabled={loading || savingStage}
              classes={"bg-black w-full"}
            />
            <BasicButton
              handleClick={() => saveStage()}
              title={savingStage ? "Submitting.." : "Submit the Video"}
              disabled={loading || savingStage}
              classes={"bg-black w-full"}
            />
          </div>
        </>
      )}
    </div>
  );
}

export function CountdownTimer({ stopRecording, isCounting }) {
  const countDownDate = new Date().getTime();
  const [countDown, setCountDown] = useState(75);
  const [minutes, setMinutes] = useState(1);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const intervalReal = setInterval(() => {
      setCountDown(countDown - 1);
      clearInterval(intervalReal);
    }, 1000);

    if (countDown == 0) {
      clearInterval(intervalReal);
      stopRecording();
      setVisible(false);
    }

    return () => {
      // clearInterval(intervalReal);
      // clearInterval(intervalFake);
    };
  }, [countDownDate]);

  const showOrHideCounter = () => {
    if (isCounting) {
      return countDown;
    } else {
      return "";
    }
  };

  return <span className="text-black">{` ${showOrHideCounter()}`} </span>;
}
