import RadioBox from "../../../../../components/inputs/radio";
import "react-datepicker/dist/react-datepicker.css";
import "react-tooltip/dist/react-tooltip.css";
import { useState, useEffect } from "react";
import REPurchaseBox from "./re-puchase-box";
import SPPurchaseBox from "./sp-purchase-box";
import {
  SPOptions,
  REOptions,
  REMonthOptions,
  REForthnightlyOptions,
  REWeekOptions,
} from "./constants";
import useDebounce from "../../../../../components/debounce/debounce-helper";

export default function SelectPurchaseModal({
  user,
  state,
  modalOpen,
  hideAdModal,
  addCustomPageProductMeta,
  getProductMetasBySessionId,
  createProductMetaMode,
  productMetaMerge,
  productContracts,
  productDefaultContracts,
  customerServiceSets,
  productMetaData,
  pageContext,
  processing,
}) {
  const mergeQuantities = [
    { label: "Honda Civic $20" },
    { label: "Honda Civic $50" },
  ];

  //old stuff
  useEffect(() => {
    const purchaseType = state.metaMode
      ? state.metaMode == 1
        ? "single"
        : "recurring"
      : "undefined";
    getProductMetasBySessionId(purchaseType);
    if (state.metaMode == 2) {
      let btMetas = [...state.productMetas];
      const buttonCreated = btMetas.findIndex((b) => b.purchase_type == 2);
      if (buttonCreated.length) {
        console.log("Found Payload");
      }
      // state.showProductMetaModalUpdateBox();
    }
  }, [createProductMetaMode, state.metaMode]);

  return (
    <div
      className={`absolute z-50 overflow-y-auto top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-6/12 h-4/12 "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className="rounded rounded-full p-0 -top-2 -right-1 absolute px-1 cursor-pointer opacity-75"
            onClick={() => hideAdModal()}
          >
            <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
          </div>
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <h1 className="text-2xl font-bold text-center flex items-center justify-center">
                {/* {getActiveWidgetTitle(currentEditingWidget, widgets)} */}
                <span>Select Purchase</span>
                {state.metaMode == 0 && (
                  <span className="text-red-500 text-2xl">*</span>
                )}
              </h1>
              Make sure to fill out all the fields marked with an asterisk (
              <span className="text-red-500">*</span>).
            </div>
            {/* {!createProductMetaMode ? productMeta.p_type_sub_opt : "Invalid"} */}

            <div className="flex gap-4 w-full justify-center  mb-4 bg-gray-50 py-2">
              <div className="flex flex-col md:flex-row md:gap-2 items-center">
                <div>
                  {" "}
                  <RadioBox
                    handleChange={(e) => {
                      {
                        if (pageContext == "update") return false;
                      }
                      state.setMetaMode(1);
                      if (pageContext == "create") {
                        state.resetProductMetaPayload();
                      }
                    }}
                    value={1}
                    checked={state.metaMode == 1}
                  />
                </div>
                <div>
                  <span> Single Purchase</span>
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:gap-2 items-center">
                <div>
                  <RadioBox
                    handleChange={(e) => {
                      {
                        if (pageContext == "update") return false;
                      }
                      state.setMetaMode(2);
                      if (pageContext == "create") {
                        state.resetProductMetaPayload();
                      }
                    }}
                    value={2}
                    checked={state.metaMode == 2}
                  />
                </div>
                <div>
                  {" "}
                  <span> Recurring Purchase</span>
                </div>
              </div>
            </div>

            <div className={`flex flex-col items-center justify-center`}>
              <SPPurchaseBox
                user={user}
                SPOptions={SPOptions}
                productContracts={productContracts}
                productDefaultContracts={productDefaultContracts}
                customerServiceSets={customerServiceSets}
                createProductMetaMode={createProductMetaMode}
                addCustomPageProductMeta={addCustomPageProductMeta}
                productMetaData={productMetaData}
                pageContext={pageContext}
                processing={processing}
                state={state}
                hideAdModal={hideAdModal}
              />

              <REPurchaseBox
                user={user}
                REOptions={REOptions}
                productContracts={productContracts}
                productDefaultContracts={productDefaultContracts}
                customerServiceSets={customerServiceSets}
                createProductMetaMode={createProductMetaMode}
                addCustomPageProductMeta={addCustomPageProductMeta}
                productMetaData={productMetaData}
                pageContext={pageContext}
                processing={processing}
                state={state}
                shortDurations={{
                  REMonthOptions: REMonthOptions,
                  REForthnightlyOptions: REForthnightlyOptions,
                  REWeekOptions: REWeekOptions,
                }}
                hideAdModal={hideAdModal}
              />
            </div>

            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => {
                hideAdModal();
              }}
            >
              <i class="fas fa-times"></i> Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
