import { toast } from "react-toastify";
import StripeCheckout from "react-stripe-checkout";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import BasicButton from "../components/buttons/basic-button";
import {
  authToken,
  authUserData,
  API_BASE,
  STRIPE_PK,
  bookPriceWithSystemFees,
} from "../Utils/helpers";
import axios from "axios";

function StripeForm({ book, action, classes, title }) {
  const product = {
    name: book.name,
    price: bookPriceWithSystemFees(book),
    description: book.name,
  };

  const [processing, setProcessing] = useState(false);

  const headers = {
    accept: "application/json",
    Authorization: "bearer " + authToken(),
  };

  async function handleToken(token, addresses) {
    try {
      setProcessing(true);
      const response = await axios.request({
        method: "post",
        headers: headers,
        url: API_BASE + "/api/stripe/charge",
        data: {
          token,
          product,
          book,
          user_uuid: authUserData().uuid,
        },
      });
      // console.log(response.status);
      if (response.status === 200) {
        setProcessing(false);
        // console.log("user", response.data.user);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        toast("Payment Successful!", { type: "success" });
        setTimeout(() => {
          action && action();
          if (book.group == "standard") {
            window.location.href = "/web/book/" + book.uuid;
          } else {
            window.location.reload();
          }
        }, 3000);
      } else {
        setProcessing(false);
      }
    } catch (error) {
      setProcessing(false);
      toast("Payment error, please try again later!", { type: "error" });
    }
  }

  useEffect(() => {}, []);

  return (
    <StripeCheckout
      class="center"
      stripeKey={STRIPE_PK}
      token={handleToken}
      amount={`$${product.price * 100}`}
      name={book.name}
      disabled={processing}
    >
      <BasicButton
        title={`${title ? title : "Buy"} ${
          book.group == "standard" ? "" : ""
        } $${bookPriceWithSystemFees(book)}`}
        disabled={processing}
        classes={classes ? classes : ""}
      />
    </StripeCheckout>
  );
}

export default StripeForm;
