import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import WebHeader from "./web/header";
import { isAdmin } from "../Utils/helpers";
import { isAuthUser, API_BASE } from "../Utils/helpers";
import Footer from "../layout/footer";
import TermsAccordion from "../accordions/terms-accordion";
import axios from "axios";

export default function SalesTerms() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const naviagte = useNavigate();

  return (
    <>
      {" "}
      <WebHeader />
      <div class=" px-6 ">
        <br />
        <div className="flex justify-center">
          <div className="rounded-lg py-4 px-2 w-full md:w-8/12 flex flex-col gap-2">
            <h3 className="font-bold text-center text-3xl mb-6">
              Sales Account Terms of Service
            </h3>

            <p className="font-bold">1. Introduction</p>
            <p>
              Welcome to Litzapp! These Terms and Conditions ("Terms") govern
              your use of the sales account provided to you by Litzapp and
              outline the responsibilities and obligations associated with being
              a Sales Account Holder on our platform. By accessing or using your
              sales account, you agree to comply with these Terms.
            </p>
            <p>
              Litzapp is a monetization platform that empowers users to monetize
              their products, services, and content through configurable payment
              systems and ad revenue. This document outlines the relationship
              between Litzapp and the Sales Account Holder, as well as the terms
              under which sales accounts are created, managed, and utilized
              within our platform.
            </p>
            <p>
              Please read these Terms carefully before using your sales account.
              If you do not agree with any provision of these Terms, you may not
              access or use your sales account. These Terms may be updated or
              modified by Litzapp from time to time, and it is your
              responsibility to review them periodically for any changes.
            </p>
            <p>
              By continuing to access or use your sales account after any
              revisions to these Terms become effective, you agree to be bound
              by the revised Terms. If you do not agree to the new Terms, you
              must stop using your sales account.
            </p>
            <p className="font-bold">2. Definitions</p>
            <p className="font-bold">In these Terms and Conditions:</p>
            <ul className="pl-5 list-disc">
              <li>
                <span className="font-bold">Litzapp:</span> Refers to the
                monetization platform owned and operated by Remote Analytics
                Jobs Inc., located at 126 Southwest 17th Road, Unit 509, Miami,
                Florida, 33129, United States of America.
              </li>
              <li>
                <span className="font-bold">Sales Account Holder:</span> Refers
                to the individual or entity to whom a sales account is provided
                by Litzapp, granting them the ability to create and manage
                Product Monetizer Accounts and generate signup links.
              </li>
              <li>
                <span className="font-bold">Product Monetizer:</span> Refers to
                users of Litzapp's platform who utilize configurable payment
                systems to monetize products or services listed on Litzapp.
              </li>
              <li>
                <span className="font-bold">Product Monetizer Account:</span>{" "}
                Refers to an account created by a Sales Account Holder on
                Litzapp's platform for a Product Monetizer, linking it
                permanently to the Sales Account Holder's account.
              </li>
              <li>
                <span className="font-bold">Litzapp Fee:</span> Refers to the
                percentage of revenue retained by Litzapp from purchases made
                through Product Monetizer Accounts on its platform, after
                deducting payment gateway and payout provider fees.
              </li>
              <li>
                <span className="font-bold">Service Charge:</span> Refers to a
                separate fee automatically added by Litzapp to the price of
                products or services listed by Product Monetizers on its
                platform, paid by buyers in addition to the listed price.
              </li>
            </ul>
            <p className="font-bold">3. Sales Account Creation</p>
            <p>
              <span className="font-bold">3.1 Granting of Sales Account:</span>{" "}
              Litzapp's administrative team may, at its discretion, create and
              provide a sales account ("Sales Account") to individuals or
              entities ("Sales Account Holders") who meet Litzapp's eligibility
              criteria. The Sales Account Holder shall be notified of the
              creation of their Sales Account via email or other means of
              communication determined by Litzapp.
            </p>
            <p>
              <span className="font-bold">3.2 Acceptance of Terms:</span> By
              accepting the Sales Account provided by Litzapp, the Sales Account
              Holder agrees to abide by these Terms and Conditions and any other
              policies or guidelines set forth by Litzapp.
            </p>
            <p>
              <span className="font-bold">
                3.3 Responsibility for Account Information:
              </span>{" "}
              The Sales Account Holder is responsible for providing accurate and
              up-to-date information during the creation of their Sales Account.
              This includes but is not limited to personal or company
              information, contact details, and payment information.
            </p>
            <p>
              <span className="font-bold">3.4 Security of Account:</span> The
              Sales Account Holder is solely responsible for maintaining the
              security and confidentiality of their Sales Account credentials,
              including usernames and passwords. The Sales Account Holder agrees
              not to share their account credentials with any third party and to
              notify Litzapp immediately of any unauthorized use of their
              account or any other breach of security.
            </p>
            <p>
              <span className="font-bold">3.5 Account Activation:</span> Upon
              creation, the Sales Account will be activated and accessible to
              the Sales Account Holder through Litzapp's platform. The Sales
              Account Holder may then begin creating and managing Product
              Monetizer Accounts and generating signup links as outlined in
              these Terms.
            </p>
            <p>
              <span className="font-bold">3.6 Termination of Account:</span>{" "}
              Litzapp reserves the right to suspend or terminate a Sales Account
              at any time, without prior notice or liability, for any reason,
              including but not limited to violation of these Terms, fraudulent
              activity, or misuse of the platform.
            </p>
            <p className="font-bold">
              4. Responsibilities of the Sales Account Holder
            </p>
            <p>
              <span className="font-bold">
                4.1 Compliance with Laws and Regulations:
              </span>{" "}
              The Sales Account Holder shall comply with all applicable laws,
              regulations, and industry standards related to the creation,
              management, and use of Product Monetizer Accounts and signup links
              on Litzapp's platform.
            </p>
            <p>
              <span className="font-bold">4.2 Account Security:</span> The Sales
              Account Holder is responsible for maintaining the security and
              confidentiality of their Sales Account credentials, including
              usernames and passwords. They shall not share their account
              credentials with any third party and must promptly notify Litzapp
              of any unauthorized use of their account.
            </p>
            <p>
              <span className="font-bold">
                4.3 Product Monetizer Account Management:
              </span>{" "}
              The Sales Account Holder is responsible for creating and managing
              Product Monetizer Accounts linked to their Sales Account. This
              includes ensuring that accurate information is provided during the
              creation of Product Monetizer Accounts and monitoring the activity
              of linked accounts.
            </p>
            <p>
              <span className="font-bold">
                4.4 Monitoring Product Monetizer Compliance:
              </span>{" "}
              The Sales Account Holder shall ensure that Product Monetizer
              Accounts linked to their Sales Account adhere to Litzapp's
              policies, guidelines, and terms of service. They are responsible
              for addressing any violations or issues that may arise with linked
              accounts and taking appropriate action to remedy them.
            </p>
            <p>
              <span className="font-bold">
                4.5 Revenue Sharing and Reporting:
              </span>{" "}
              The Sales Account Holder shall accurately report and track revenue
              generated through Product Monetizer Accounts linked to their Sales
              Account. They understand and agree to abide by the revenue sharing
              terms outlined in these Terms and Conditions.
            </p>
            <p>
              <span className="font-bold">
                4.6 Liability for Product Monetizer Actions:
              </span>{" "}
              The Sales Account Holder acknowledges and agrees that they are
              solely responsible for the actions of Product Monetizer Accounts
              linked to their Sales Account. They shall ensure that linked
              accounts conduct themselves in a lawful and responsible manner and
              shall indemnify Litzapp against any liability, damages, or losses
              arising from the actions of linked accounts.
            </p>
            <p>
              <span className="font-bold">4.7 Communication with Litzapp:</span>{" "}
              The Sales Account Holder shall maintain open and transparent
              communication with Litzapp regarding any issues, concerns, or
              questions related to their Sales Account or linked Product
              Monetizer Accounts.
            </p>
            <p>
              <span className="font-bold">
                4.8 Compliance with Litzapp's Policies:
              </span>{" "}
              The Sales Account Holder shall comply with all policies,
              guidelines, and terms of service set forth by Litzapp, as may be
              updated or modified from time to time.
            </p>
            <p className="font-bold">
              5. Creation and Management of Product Monetizer Accounts
            </p>
            <p>
              5.1 Authority to Create Product Monetizer Accounts: The Sales
              Account Holder is granted the authority to create Product
              Monetizer Accounts on Litzapp's platform. Each Product Monetizer
              Account created by the Sales Account Holder becomes permanently
              linked to their Sales Account.
            </p>
            <p>
              <span className="font-bold">5.2 Accuracy of Information:</span>{" "}
              The Sales Account Holder is responsible for providing accurate and
              up-to-date information during the creation of Product Monetizer
              Accounts. This includes but is not limited to the Product
              Monetizer's personal or company information, contact details, and
              payment information.
            </p>
            <p>
              <span className="font-bold">5.3 Account Activation:</span> Upon
              creation, Product Monetizer Accounts will be activated and
              accessible through Litzapp's platform.
            </p>
            <p>
              <span className="font-bold">
                5.4 Management of Linked Accounts:
              </span>{" "}
              The Sales Account Holder shall monitor and manage all Product
              Monetizer Accounts linked to their Sales Account. This includes
              overseeing account activity, addressing any issues or concerns
              that may arise, and ensuring compliance with Litzapp's policies
              and terms of service.
            </p>
            <p>
              <span className="font-bold">
                5.5 Revocation of Account Access:
              </span>{" "}
              Litzapp reserves the right to revoke access to Product Monetizer
              Accounts linked to a Sales Account Holder's Sales Account if there
              is evidence of fraudulent activity, misuse of the platform, or
              violation of these Terms and Conditions.
            </p>
            <p>
              <span className="font-bold">
                5.6 Transfer of Account Ownership:
              </span>{" "}
              The Sales Account Holder may not transfer ownership or management
              of Product Monetizer Accounts linked to their Sales Account to any
              third party without prior written consent from Litzapp.
            </p>
            <p>
              <span className="font-bold">
                5.7 Termination of Linked Accounts:
              </span>{" "}
              Litzapp reserves the right to suspend or terminate Product
              Monetizer Accounts linked to a Sales Account Holder's Sales
              Account if there is evidence of violation of Litzapp's policies or
              terms of service, fraudulent activity, or misuse of the platform.
            </p>
            <br />
            <p className="font-bold">6. Revenue Sharing</p>
            <p>
              <span className="font-bold">6.1 Litzapp Fee:</span> When a
              purchase is made through a Product Monetizer Account on Litzapp's
              platform, a percentage of the total revenue generated (after
              deducting payment gateway and payout provider fees) is retained by
              Litzapp as the "Litzapp Fee."
            </p>
            <p>
              <span className="font-bold">6.2 Sharing of Litzapp Fee:</span> A
              portion of the Litzapp Fee generated from purchases made through
              Product Monetizer Accounts linked to a Sales Account Holder's
              Sales Account will be shared with the Sales Account Holder. The
              specific percentage shared with the Sales Account Holder is
              subject to change by Litzapp.
            </p>
            <p>
              <span className="font-bold">
                6.3 Notification of Percentage Change:
              </span>{" "}
              Litzapp will inform Sales Account Holders of any changes to the
              percentage of the Litzapp Fee shared with them at least 15 days
              before the change is implemented. Notification will be sent to the
              email address associated with the Sales Account Holder's account
              on Litzapp.
            </p>
            <p>
              <span className="font-bold">6.4 Grandfather Clause:</span> Any
              changes to the percentage of the Litzapp Fee will not affect the
              percentage shared with Sales Account Holders for purchases made
              through Product Monetizer Accounts linked to their Sales Account
              before the new percentage comes into effect. Sales Account Holders
              will continue to receive the same percentage for such purchases.
            </p>
            <p>
              <span className="font-bold">6.5 Reporting and Tracking:</span>{" "}
              Litzapp will provide Sales Account Holders with access to reports
              and tracking tools to monitor the revenue generated through
              Product Monetizer Accounts linked to their Sales Account and the
              corresponding Litzapp Fee shared with them.
            </p>
            <p>
              <span className="font-bold">6.6 Payment Schedule:</span> Revenue
              shared with Sales Account Holders will be paid out according to
              the payment schedule determined by Litzapp, subject to any
              applicable minimum payout thresholds and payment processing fees.
            </p>
            <p>
              <span className="font-bold">6.7 Adjustments and Disputes:</span>{" "}
              Any discrepancies or disputes regarding revenue sharing will be
              resolved in accordance with Litzapp's policies and procedures, as
              determined by Litzapp in its sole discretion.
            </p>
            <p className="font-bold">7. Service Charges</p>
            <p>
              <span className="font-bold">7.1 Definition:</span> A "Service
              Charge" refers to a separate fee added by Litzapp to the price of
              products or services listed by Product Monetizers on its platform.
              This fee is automatically included in the total price paid by
              buyers in addition to the listed price.
            </p>
            <p>
              <span className="font-bold">7.2 Amount and Calculation:</span> The
              Service Charge amount is determined by Litzapp and may vary based
              on various factors, including the type of product or service,
              pricing structure, and market conditions. The Service Charge is
              added to the listed price by the Product Monetizer, resulting in
              the total price paid by the buyer.
            </p>
            <p>
              <span className="font-bold">7.3 Exclusivity to Litzapp:</span> The
              Service Charge collected from buyers is exclusive to Litzapp and
              is not shared with Sales Account Holders. It is used to cover
              platform maintenance, development, operational costs, and other
              expenses incurred by Litzapp in providing its services.
            </p>
            <p>
              <span className="font-bold">7.4 Transparency:</span> Litzapp will
              clearly display the Service Charge to buyers during the checkout
              process, ensuring transparency regarding the total amount payable
              for the product or service.
            </p>
            <p>
              <span className="font-bold">7.5 Updates and Changes:</span>{" "}
              Litzapp reserves the right to adjust the Service Charge amount at
              its discretion, with or without prior notice to Sales Account
              Holders. Any changes to the Service Charge will be reflected in
              the total price paid by buyers for products or services listed on
              Litzapp's platform.
            </p>
            <p>
              <span className="font-bold">7.6 Exemptions:</span> Certain
              products or services may be exempt from the Service Charge at
              Litzapp's discretion.
            </p>
            <br />
            <p className="font-bold">
              8. Changes to Revenue Sharing Percentage
            </p>
            <p>
              <span className="font-bold">8.1 Notification of Changes:</span>{" "}
              Litzapp reserves the right to change the percentage of the Litzapp
              Fee shared with Sales Account Holders at its discretion. In the
              event of such changes, Litzapp will provide advance notice to
              Sales Account Holders at least 15 days before the new percentage
              comes into effect.
            </p>
            <p>
              <span className="font-bold">8.2 Notification Method:</span>{" "}
              Notification of changes to the revenue sharing percentage will be
              sent to the email addresses associated with Sales Account Holders'
              accounts on Litzapp. It is the responsibility of Sales Account
              Holders to ensure that their contact information is accurate and
              up to date.
            </p>
            <p>
              <span className="font-bold">8.3 Effectiveness of Changes:</span>{" "}
              The new revenue sharing percentage will come into effect on the
              date specified in the notification sent to Sales Account Holders.
              Any revenue generated through Product Monetizer Accounts linked to
              a Sales Account Holder's account after the effective date of the
              new percentage will be subject to the updated revenue sharing
              terms.
            </p>
            <p>
              <span className="font-bold">8.4 Grandfather Clause:</span> The
              implementation of a new revenue sharing percentage will not affect
              the percentage of the Litzapp Fee shared with Sales Account
              Holders for purchases made through Product Monetizer Accounts
              linked to their Sales Account before the new percentage comes into
              effect. Sales Account Holders will continue to receive the same
              percentage for such purchases.
            </p>
            <p>
              <span className="font-bold">8.5 Communication of Changes:</span>{" "}
              Litzapp will endeavor to communicate changes to the revenue
              sharing percentage clearly and transparently to Sales Account
              Holders, providing relevant information and explanations as
              necessary.
            </p>
            <p>
              <span className="font-bold">8.6 Acceptance of Changes:</span> By
              continuing to use their Sales Account and associated Product
              Monetizer Accounts after the effective date of the new revenue
              sharing percentage, Sales Account Holders agree to abide by the
              updated terms and conditions.
            </p>
            <br />
            <p className="font-bold">9. Liability and Indemnification</p>
            <p>
              <span className="font-bold">
                9.1 Liability of Sales Account Holder:
              </span>{" "}
              The Sales Account Holder acknowledges and agrees that they are
              solely responsible for the actions and conduct of Product
              Monetizer Accounts linked to their Sales Account. Litzapp shall
              not be liable for any damages, losses, claims, or liabilities
              arising from the actions or conduct of linked Product Monetizer
              Accounts.
            </p>
            <p>
              <span className="font-bold">9.2 Indemnification:</span> The Sales
              Account Holder agrees to indemnify, defend, and hold harmless
              Litzapp, its affiliates, directors, officers, employees, agents,
              and licensors from and against any and all claims, liabilities,
              damages, losses, costs, expenses, or fees (including reasonable
              attorneys' fees) arising out of or related to:
            </p>
            <p>
              a) Violation of these Terms and Conditions by the Sales Account
              Holder or linked Product Monetizer Accounts.
            </p>
            <p>
              b) Misuse or unauthorized use of Litzapp's platform or services by
              the Sales Account Holder or linked Product Monetizer Accounts.
            </p>
            <p>
              c) Any breach of applicable laws, regulations, or industry
              standards by the Sales Account Holder or linked Product Monetizer
              Accounts.
            </p>
            <p>
              d) Any claims or disputes between the Sales Account Holder and
              third parties, including but not limited to buyers, sellers, or
              other users of Litzapp's platform.
            </p>
            <p>
              <span className="font-bold">9.3 Limitation of Liability:</span> In
              no event shall Litzapp, its affiliates, directors, officers,
              employees, agents, or licensors be liable to the Sales Account
              Holder or any third party for any indirect, incidental, special,
              consequential, or punitive damages, including but not limited to
              loss of profits, data, goodwill, or business reputation, arising
              out of or in connection with these Terms and Conditions or the use
              of Litzapp's platform or services.
            </p>
            <p>
              <span className="font-bold">9.4 Disclaimer:</span> Litzapp
              provides its platform and services on an "as is" and "as
              available" basis, without any warranties or representations,
              express or implied. Litzapp disclaims all warranties of
              merchantability, fitness for a particular purpose,
              non-infringement, or accuracy of information.
            </p>
            <br />
            <p className="font-bold">10. Termination</p>
            <p>
              <span className="font-bold">10.1 Termination by Litzapp:</span>{" "}
              Litzapp reserves the right to suspend or terminate a Sales Account
              Holder's Sales Account and associated Product Monetizer Accounts
              at any time, with or without cause, and without prior notice or
              liability. Reasons for termination may include but are not limited
              to:
            </p>
            <p>
              a) Violation of these Terms and Conditions or any other policies
              or guidelines set forth by Litzapp.
            </p>
            <p>
              b) Fraudulent activity or misuse of Litzapp's platform or
              services.
            </p>
            <p>
              c) Non-compliance with applicable laws, regulations, or industry
              standards.
            </p>
            <p>
              d) Any other conduct deemed inappropriate or detrimental to
              Litzapp or its users.
            </p>
            <p>
              <span className="font-bold">10.2 Effect of Termination:</span>{" "}
              Upon termination of a Sales Account, all associated Product
              Monetizer Accounts will be deactivated, and access to Litzapp's
              platform and services will be revoked. The Sales Account Holder
              will no longer be able to create or manage Product Monetizer
              Accounts or access revenue sharing benefits.
            </p>
            <p>
              <span className="font-bold">10.3 Outstanding Obligations:</span>{" "}
              Termination of a Sales Account does not relieve the Sales Account
              Holder of any outstanding obligations or liabilities incurred
              prior to termination. The Sales Account Holder remains responsible
              for any fees, charges, or damages owed to Litzapp or third
              parties.
            </p>
            <p>
              <span className="font-bold">10.4 Survival of Provisions:</span>{" "}
              Sections of these Terms and Conditions that, by their nature,
              should survive termination, including but not limited to the
              sections on Liability and Indemnification, Disclaimer, and
              Governing Law, shall remain in full force and effect after
              termination.
            </p>
            <p>11. Miscellaneous</p>
            <p>
              <span className="font-bold">11.1 Governing Law:</span> These Terms
              and Conditions shall be governed by and construed in accordance
              with the laws of the State of Florida, United States of America,
              without regard to its conflict of law principles.
            </p>
            <p>
              <span className="font-bold">11.2 Dispute Resolution:</span> Any
              dispute, controversy, or claim arising out of or relating to these
              Terms and Conditions or the breach, termination, or validity
              thereof shall be resolved through arbitration administered by a
              mutually agreed-upon arbitration service or mediator in Miami,
              Florida.
            </p>
            <p>
              <span className="font-bold">11.3 Severability:</span> If any
              provision of these Terms and Conditions is found to be unlawful,
              void, or unenforceable, that provision shall be deemed severable
              from the remaining provisions and shall not affect the validity
              and enforceability of the remaining provisions.
            </p>
            <p>
              <span className="font-bold">11.4 Waiver:</span> The failure of
              Litzapp to enforce any provision of these Terms and Conditions
              shall not be deemed a waiver of such provision or any other
              provision thereof, nor shall any waiver be considered a continuing
              waiver of such provision or a waiver of any other provision.
            </p>
            <p>
              <span className="font-bold">11.5 Entire Agreement:</span> These
              Terms and Conditions constitute the entire agreement between the
              Sales Account Holder and Litzapp regarding the subject matter
              herein and supersede all prior or contemporaneous communications,
              agreements, and understandings, whether oral or written, between
              the parties.
            </p>
            <p>
              <span className="font-bold">11.6 Modification:</span> Litzapp
              reserves the right to modify or update these Terms and Conditions
              at any time, without prior notice or liability, by posting the
              revised terms on its platform. It is the responsibility of the
              Sales Account Holder to review these Terms and Conditions
              periodically for any changes.
            </p>
            <p>
              <span className="font-bold">11.7 Contact Information:</span> For
              questions or inquiries regarding these Terms and Conditions or any
              other matter, Sales Account Holders may contact Litzapp at the
              following address: Remote Analytics Jobs Inc., 126 Southwest 17th
              Road, Unit 509, Miami, Florida, 33129, United States of America.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
