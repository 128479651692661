export function isDataSubExpired(publisher) {
  if (!publisher.name) {
    console.log("no name");
    return false;
  }

  if (publisher.activedatasub && !publisher.activedatasub.active) {
    return true;
  }

  return false;
}
