import BasicButton from "../../../../../../../components/buttons/basic-button";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { isResponsiveMode } from "../../../../../../../Utils/helpers";
import { useState } from "react";
export default function BizLinkAssistSlider({ modalOpen, closeModal }) {
  const [sliderToShow, setSliderToShow] = useState(0);
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div
      className={` ${
        isResponsiveMode() ? "fixed" : "absolute"
      }  z-40 overflow-y-auto top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen ">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-6/12"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <h1 className="text-2xl font-bold text-center">
                {/* {getActiveWidgetTitle(currentEditingWidget, widgets)} */}
                {sliderToShow == 1 ? "Google" : "Bing"}
              </h1>
            </div>
            <div className="w-full  m-2">
              <div className="flex flex-col md:flex-row justify-center gap-4 my-2">
                <div>
                  <BasicButton
                    title={"Help Find My Google Profile Link"}
                    handleClick={() => setSliderToShow(1)}
                  />
                </div>
                <div>
                  {" "}
                  <BasicButton
                    title={"Help Find My Bing Profile Link"}
                    handleClick={() => setSliderToShow(2)}
                  />
                </div>
              </div>

              <div className="px-6 mb-2 w-full text-center">
                {/* <h2> Single Item</h2> */}
                {sliderToShow == 1 && (
                  <Carousel
                    className="text-center"
                    showArrows={true}
                    showThumbs={false}
                  >
                    <div className="text-center cursor-pointer mx-2 w-full">
                      <img src="https://drive.google.com/uc?export=view&id=1DfQ-oqLOaBlOd4ziVP17g_yxo2CIoP1T" />
                    </div>
                    <div className="text-center cursor-pointer mx-2 w-full">
                      <img src="https://drive.google.com/uc?export=view&id=1zag7JdgSu6pGAahKVxn78qSVfjCALQRj" />
                    </div>
                    <div className="text-center cursor-pointer mx-2 w-full">
                      <img src="https://drive.google.com/uc?export=view&id=1FTpExNwg5AbTsUfYNELtoiO676CEuw-7" />
                    </div>
                  </Carousel>
                )}

                {sliderToShow == 2 && (
                  <Carousel showArrows={true} showThumbs={false}>
                    <div className="text-center cursor-pointer mx-2 w-full">
                      <img src="https://drive.google.com/uc?export=view&id=1Jcs52_VkN9MTMIu4Y45SXdpO9AAyV09a" />
                    </div>
                    <div className="text-center cursor-pointer mx-2 w-full">
                      <img src="https://drive.google.com/uc?export=view&id=1VZvUic9mGd9idslaNyjKMJmfrBv0f3Td" />
                    </div>
                    <div className="text-center cursor-pointer mx-2 w-full">
                      <img src="https://drive.google.com/uc?export=view&id=1rAuDt2Vw5GfXY0OREU40goean8YmRtB3" />
                    </div>
                  </Carousel>
                )}
              </div>
            </div>
            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => closeModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
            {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
