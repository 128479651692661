import DesktopLogoBar from "./logo-bar";
export default function DesktopLeftBar(props) {
  const showResumeApplicationButton = () => {
    return localStorage.getItem("resume_supplier_application") !== null;
  };

  const getRightBarDivision = () => {
    return showResumeApplicationButton() ? "w-6/12" : "w-3/12";
  };
  return (
    <div
      className={`md:flex-row md:justify-between items-center ${getRightBarDivision()}`}
    >
      <DesktopLogoBar />
    </div>
  );
}
