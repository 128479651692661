import {
  isAuthUser,
  isPublisher,
  authUserData,
  isResponsiveMode,
} from "../../../../../Utils/helpers";
import MSharedMenuItems from "./shared";
export default function MPublisherMenuItems() {
  const renderMenu = () => {
    return isAuthUser() && isPublisher();
  };

  return renderMenu() && isResponsiveMode() ? (
    <>
      <a
        href={`/publisher/create/options`}
        class="text-base font-medium my-text-yellow-500"
      >
        Create
      </a>
      <a
        href={`/publisher/publications/options/${authUserData().uuid}`}
        class="text-base font-medium my-text-yellow-500"
      >
        Dashboard
      </a>
    </>
  ) : (
    ""
  );
}
