import axios from "axios";
import { useEffect, useState, useRef } from "react";
import BasicButton from "../../../components/buttons/basic-button";
import TextField from "../../../components/inputs/text-input";
import Pagination from "../../../components/pagination";
import useDebounce from "../../../components/debounce/debounce-helper";
import LoadingProgress from "../rich-page/components/loading-progress";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";
import { useSendMailStore } from "../../../stores/send-mail-store";
import FilterBar from "../components/email-list-filter";
import Checkbox from "../../../components/inputs/checkbox";
import {
  isAdmin,
  API_BASE,
  isPublisher,
  isAuthUser,
  authUserData,
  getLocation,
  isResponsiveMode,
} from "../../../Utils/helpers";
import { toast } from "react-toastify";
export default function PubHirePurchases() {
  const [purchases, setPurchases] = useState({});
  const [searchText, setSearchText] = useState("");
  const [countPerPage, setCountPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [processing, setProcessing] = useState(false);
  const mountedRef = useRef();

  const [paginateMode, setPaginateMode] = useState(false);
  const [user, setUser] = useState({});
  const [blockedIds, setBlockedIds] = useState([]);
  const mailStore = useSendMailStore((state) => state);

  const fetchUser = () => {
    const url = API_BASE + "/api/user/" + authUserData().uuid;
    setProcessing(true);
    axios
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setUser(response.data.user);
          if (response.data.user.emailblocklist)
            setBlockedIds(
              response.data.user.emailblocklist.map((d) => d.subscriber_id)
            );
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  const doPagination = (page) => {
    if (mailStore.data.first_page_url) {
      setCurrentPage(page);
      axios
        .get(mailStore.data.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          mailStore.setData(res.data.purchases);
        })
        .catch((err) => console.log(err));
    }
  };

  const getPurchases = () => {
    setPurchases([]);
    setProcessing(true);
    const url =
      API_BASE +
      "/publisher/hire/purchases/" +
      authUserData().uuid +
      "/" +
      mailStore.query.countPerPage +
      "/" +
      mailStore.query.pageLimit +
      "/" +
      mailStore.query.searchText +
      authRequestService()
        .get(url)
        .then((response) => {
          if (response.status == 200) {
            setProcessing(false);
            mailStore.setData(response.data.purchases);
          }
        })
        .catch((error) => {
          setProcessing(false);
          toast("Something went wrong", { type: "error" });
        });
  };

  const computeSelectedCount = () => {
    if (!mailStore.data.total) return false;
    if (mailStore.isAllSelected) {
      if (mailStore.deSelected.length) {
        console.log("ALL SEL FLAGS ACTIVE WITH SOME DESELECTED");
        mailStore.setSelectedCount(
          mailStore.data.total - mailStore.deSelected.length
        );
      } else {
        console.log("ALL SEL FLAGS ACTIVE WITH NONE DESELECTED");
        mailStore.setSelectedCount(mailStore.data.total);
      }
    } else {
      console.log("ALL SEL FLAGS INACTIVE");
      mailStore.setSelectedCount(mailStore.selected.length);
    }
  };

  const handleSearchTextChange = (e) => {
    mailStore.setQuery("searchText", e);
  };

  const handleCountPerPageChange = (e) => {
    mailStore.setQuery("countPerPage", e.target.value);
  };

  const handleLatestEntriesChange = (e) => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
    mailStore.setQuery("selectedLatest", e.target.value);
    mailStore.setQuery("pageLimit", e.target.value);
  };

  const handleOldestEntriesChange = (e) => {
    mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
    mailStore.setQuery("selectedOldest", e.target.value);
    mailStore.setQuery("pageLimit", e.target.value);
  };

  const markItem = (id) => {
    if (!mailStore.isAllSelected && !mailStore.selected.includes(id)) {
      mailStore.setSelType("M");
      const item = mailStore.selected;
      mailStore.setSelected([...item, id]);
    } else {
      const item = mailStore.deSelected;
      if (mailStore.isAllSelected) {
        if (mailStore.deSelected.includes(id)) {
          const filteredItems = mailStore.deSelected.filter((e) => e !== id);
          mailStore.setDeselected(filteredItems);
        } else {
          mailStore.setDeselected([...item, id]);
        }
      }
      const filteredItems = mailStore.selected.filter((e) => e !== id);
      mailStore.setSelected(filteredItems);
    }
  };

  const clearMarkedItems = (id) => {
    mailStore.setSelType("M");
    mailStore.setSelected([]);
    mailStore.setDeselected([]);
    mailStore.setIsAllSelected(false);
  };

  const markAllItems = () => {
    mailStore.setSelType("A");
    mailStore.setIsAllSelected();
  };

  const addNewItemsToMakrList = () => {
    const items = mailStore.selected;
    if (mailStore.isAllSelected) {
      if (!mailStore.data.data) return false;
      mailStore.setSelected(items);
    } else {
      if (mailStore.selType == "A") {
        mailStore.setSelected([]);
        mailStore.setDeselected([]);
        console.log("DESELECTING");
      }
    }
  };

  const shouldMarkItem = (id) => {
    if (!mailStore.isAllSelected && mailStore.selected.includes(id)) {
      return true;
    }
    if (mailStore.isAllSelected && !mailStore.deSelected.includes(id)) {
      return true;
    }

    return false;
  };

  const showSendButton = () => {
    if (!mailStore.data.total) return false;
    if (mailStore.selected.length) return true;
    if (mailStore.isAllSelected) {
      if (mailStore.deSelected.length !== mailStore.data.total) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedLatest]);

  useEffect(() => {
    computeSelectedCount();
  }, [mailStore.selected]);

  useEffect(() => {
    computeSelectedCount();
  }, [mailStore.deSelected]);

  useEffect(() => {
    mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedOldest]);

  useEffect(() => {
    addNewItemsToMakrList();
    computeSelectedCount();
    if (!mailStore.isAllSelected) {
      mailStore.setDeselected([]);
    } else {
      mailStore.setSelected([]);
    }
  }, [mailStore.isAllSelected]);

  useEffect(() => {
    addNewItemsToMakrList();
    computeSelectedCount();
    if (mailStore.deSelected.length && mailStore.refresh && !paginateMode) {
      const ids = mailStore.ids;
      const deSelectedAndPresent = [];

      mailStore.deSelected.map((d) => {
        if (ids.includes(d)) {
          deSelectedAndPresent.push(d);
        }
      });
      mailStore.setDeselected(deSelectedAndPresent);
    }

    if (
      !mailStore.isAllSelected &&
      mailStore.selected.length &&
      mailStore.refresh &&
      !paginateMode
    ) {
      mailStore.setSelected([]);
    }

    if (!paginateMode && mailStore.refresh) mailStore.setIsAllSelected(false);
    fetchUser();
  }, [mailStore.data]);

  useDebounce(
    () => {
      if (mailStore.refresh) {
        getPurchases();
      }
      mailStore.setRefresh(true);
    },
    [mailStore.query],
    800
  );

  useEffect(() => {
    if (!isPublisher()) {
      window.location.href = "/";
    }
  }, []);
  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">
              Hire Purchase List
            </h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex justify-center items-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            <p className="w-full text-center my-4">
              We found {mailStore.data.total ? mailStore.data.total : ""}{" "}
              records
            </p>
            <FilterBar
              handleCountPerPageChange={handleCountPerPageChange}
              handleSearchTextChange={handleSearchTextChange}
              serchQValue={mailStore.query.searchText}
              showSendButton={showSendButton}
              handleLatestEntriesChange={handleLatestEntriesChange}
              latestQValue={mailStore.query.selectedLatest}
              handleOldestEntriesChange={handleOldestEntriesChange}
              oldestQValue={mailStore.query.selectedOldest}
              markAllItems={markAllItems}
              clearMarkedItems={clearMarkedItems}
              selectAllFlag={mailStore.isAllSelected}
              model={"hire-purchase"}
            />

            <div className="w-full flex justify-center">
              {" "}
              {mailStore.data.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            <table class="table-auto w-full my-4">
              <thead>
                <tr className={window.innerWidth < 720 ? "hidden" : ""}>
                  <th class="px-4 py-2 text-gray-600">Name</th>
                  <th class="px-4 py-2 text-gray-600">Country</th>
                  <th class="px-4 py-2 text-gray-600">Item Title</th>
                  <th class="px-4 py-2 text-gray-600">Item ID</th>
                  <th class="px-4 py-2 text-gray-600">Price</th>
                  <th class="px-4 py-2 text-gray-600">Transaction ID</th>

                  <th class="px-4 py-2 text-gray-600">Date</th>
                </tr>
              </thead>
              <tbody>
                {mailStore.data.data
                  ? mailStore.data.data.length
                    ? mailStore.data.data.map((purchase) => {
                        return (
                          <tr
                            className={
                              window.innerWidth < 820 ? "flex flex-col" : ""
                            }
                          >
                            <td class="border  px-4 py-2  font-medium">
                              <h3 className="font-bold md:hidden">Name</h3>
                              {purchase.buyer.name}
                            </td>

                            <td class="border  px-4 py-2  font-medium">
                              <h3 className="font-bold md:hidden">Country</h3>
                              {purchase.buyeroptimized.country
                                ? purchase.buyeroptimized.country
                                : "N/A"}
                            </td>
                            <td class="border px-4 py-2  font-medium">
                              <h3 className="font-bold md:hidden">
                                Item Title
                              </h3>
                              <p>
                                <a
                                  target="_blank"
                                  className="underline"
                                  href={
                                    purchase.bookbought.group == "standard"
                                      ? `/publisher/${purchase.book.owneruuid}/publications/series/m/book/${purchase.bookbought.id}`
                                      : `/web/rich-page/render/${purchase.bookbought.uuid}`
                                  }
                                >
                                  {purchase.bookbought.name}
                                </a>
                              </p>
                            </td>

                            <td class="border  px-4 py-2  font-medium">
                              <h3 className="font-bold md:hidden">Item ID</h3>
                              {purchase.bookbought.item_id}
                            </td>
                            <td class="border  px-4 py-2  font-medium">
                              <h3 className="font-bold md:hidden">Price</h3>$
                              {purchase.price / 100}
                            </td>
                            <td class="border  px-4 py-2  font-medium">
                              <h3 className="font-bold md:hidden">
                                Transaction ID
                              </h3>
                              {purchase.transaction_id}
                            </td>

                            <td class="border  px-4 py-2  font-medium">
                              <h3 className="font-bold md:hidden">Date</h3>
                              <p>{purchase.refactoredCreatedAtTime}</p>
                            </td>
                          </tr>
                        );
                      })
                    : ""
                  : ""}
              </tbody>
            </table>
            <LoadingProgress loading={processing} />
            <div className="w-full flex justify-center">
              {" "}
              {mailStore.data.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
