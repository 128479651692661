import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  isAdmin,
  API_BASE,
  BASE_URL,
  getBookType,
} from "../../../Utils/helpers";
import EditBookModal from "../../../modals/edit-book-modal";
import axios from "axios";
import Publishers from "..";
import Switch from "react-switch";
import { toast } from "react-toastify";
export default function AdminAllBooks() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [publications, setPublications] = useState([]);
  const [showEditBookModal, setShowEditBookModal] = useState(false);
  const [currEditingBook, setCurrEditingBook] = useState();
  const [published, setPublished] = useState([]);

  let { userId } = useParams();
  let { type } = useParams();
  const getPublication = () => {
    const url = API_BASE + `/api/books/${type}/user/` + userId;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setPublications(response.data.books);
        }
      })
      .catch((error) => {
        alert(error.message);
        console.error("There was an error!", error);
      });
  };

  const showEditBookBox = () => {};

  const hideBookEditModal = () => {
    setShowEditBookModal(false);
  };

  const showBookEditModal = (book) => {
    setCurrEditingBook(book);
    setShowEditBookModal(true);
  };

  // ,,,,

  const deleteBook = (book) => {
    const url = API_BASE + "/api/book/delete/" + book.uuid;
    axios
      .delete(url)
      .then((response) => {
        if (response.status == 200) {
          getPublication();
        }
      })
      .catch((error) => {
        alert(error.message);
        console.error("There was an error!", error);
      });
  };

  const updateBook = (book) => {
    const url = API_BASE + "/api/book/update";
    let formData = new FormData();
    formData.append("uuid", book.uuid);
    formData.append(
      "admin_publish_status",
      published.includes(book.uuid) ? 1 : 0
    );
    axios
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          toast("Updated!", { type: "success" });
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const handlePublishStatus = (e, book) => {
    let newPublish = published;
    if (newPublish.filter((p) => p == book.uuid).length) {
      let removeItem = newPublish.filter((p) => p !== book.uuid);
      setPublished(removeItem);
      setCurrEditingBook(book);
      console.log("item exists");
    } else {
      console.log("item not exists");
      setCurrEditingBook(book);
      setPublished((prev) => [...prev, book.uuid]);
    }
  };
  useEffect(() => {
    getPublication();
    if (!isAdmin()) {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    publications.map((book) =>
      setPublished((prevState) =>
        !prevState.includes(book.uuid) && book.admin_publish_status
          ? [...prevState, book.uuid]
          : prevState
      )
    );
  }, [publications]);

  useEffect(() => {
    console.log("published", published);
    if (currEditingBook) {
      updateBook(currEditingBook);
    }
  }, [published]);
  return (
    <>
      <div className="bg-white m-2 p-2 flex flex-col md:flex-row justify-center md:justify-between shadow">
        <div>
          <h1 className="text-2xl text-center font-bold">
            {getBookType(type)}
            <span className="text-gray-500 font-normal ml-2">
              {/* ({publication && publication.name}) */}
            </span>
          </h1>
        </div>
        <div>
          <nav class="flex justify-center" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-3">
              <li class="inline-flex items-center">
                <a
                  href="/publishers"
                  class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
                >
                  <svg
                    class="w-4 h-4 mr-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                  </svg>
                  Publishers
                </a>
              </li>
              <li>
                <div class="flex items-center">
                  <svg
                    class="w-6 h-6 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>

                  <a
                    href={`/admin/publisher/publications/options/${userId}`}
                    class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                  >
                    Options
                  </a>
                </div>
              </li>
              <li aria-current="page">
                <div class="flex items-center">
                  <svg
                    class="w-6 h-6 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <a
                    href={`#`}
                    class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                  >
                    {getBookType(type)}
                  </a>
                </div>
              </li>
              <li aria-current="page">
                <div class="flex items-center">
                  <svg
                    class="w-6 h-6 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                    {/* {publication && publication.name} */}
                  </span>
                </div>
              </li>
            </ol>
          </nav>
        </div>

        <div className="flex flex-col md:flex-row justify-center gap-4">
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
          {/* <a href={`/publisher/create-book/${userId}/`}>
            <BasicButton
              classes={"bg-teal-500 hover:bg-teal-400 w-full"}
              title={"Create Book"}
              handleClick={null}
            />
          </a> */}
        </div>
      </div>

      <div className="bg-white m-2 p-2 grid md:grid-cols-33 ">
        <div className="md:col-span-2  p-2 rounded ">
          {/* <h1 className="text-black font-bold text-2xl">Books</h1> */}
          {/* <hr /> */}
          <div className="w-full md:w-10/12 p-2">
            <div className="relative grid  md:grid-cols-3 gap-4 p-2 text-center">
              {publications &&
                publications.map((book) => (
                  <div className="relative p-4 shadow m-2 bg-white">
                    {/* <div
                      className="bg-white rounded rounded-full bg-white p-0  right-0 absolute px-1 cursor-pointer"
                      style={{ top: -20, right: -5 }}
                      onClick={() => deleteBook(book)}
                    >
                      <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
                    </div>
                    <div
                      className="rounded rounded-full bg-white p-0  right-0 absolute px-1 cursor-pointer"
                      style={{ top: -20, right: 33 }}
                      onClick={() => showBookEditModal(book)}
                    >
                      <i class="fa fa-edit text-blue-500  z-50 text-2xl"></i>
                    </div> */}
                    <a
                      href={`/admin/publisher/${userId}/publications/series/m/book/${book.id}`}
                    >
                      <div>
                        <img
                          className="w-full"
                          src={
                            BASE_URL +
                            "/storage/" +
                            book.cover_photo.split("public")[1]
                          }
                        />
                        <p className="font-bold text-2xl text-center">
                          {book.name}
                        </p>

                        <p className="text-gray-600">
                          {book.series && book.series.name}
                        </p>
                        <p className="text-red-500 text-sm">by</p>
                        <p className="text-black-500">{book.owner.name}</p>
                      </div>
                    </a>
                    <div className="my-4 mx-4 text-center bg-gray-100 rounded-lg p-2">
                      <div className="flex justify-around">
                        <label className="flex items-center gap-4">
                          <div>
                            {" "}
                            <span className="font-bold">
                              {" "}
                              {published.includes(book.uuid)
                                ? "Hide"
                                : "Unhide"}
                            </span>
                            {/* <span>
                              {book.admin_publish_status
                                ? "Published"
                                : "Draft"}
                            </span> */}
                          </div>
                        </label>
                        <div className="flex items-center gap-2">
                          <Switch
                            onChange={(e) => handlePublishStatus(e, book)}
                            checked={published.includes(book.uuid)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center gap-4 mt-8">
        {/* <div>
          <BasicButton
            title={"Create"}
            classes={"p-6"}
            handleClick={() => null}
          />
        </div>{" "} */}
      </div>
      {/* <EditBookModal
        modalOpen={showEditBookModal}
        hideAdModal={hideBookEditModal}
        book={currEditingBook}
        action={getPublication}
      /> */}
    </>
  );
}
