import BasicButton from "../../../components/buttons/basic-button";
import { useState, useEffect } from "react";
import PubSupplierIndboarding from "./onboarding";
import PubSupplierOnboardingOpts from "./components/onboarding-options";
import {
  isPublisher,
  isAuthUser,
  authUserData,
  API_BASE,
} from "../../../Utils/helpers";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";

export default function PubSupplierOnboarding() {
  const [stage, setStage] = useState(1);
  const [regType, setRegType] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [redoMessageMode, setRedoMessageMode] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingPageFail, setLoadingPageFail] = useState(false);
  const [profileVerification, setProfoileVerification] = useState(false);

  const renderSupplierActionButton = (status) => {
    return status == "resume" || status == "redo";
  };

  const getUser = () => {
    let url = API_BASE + "/publisher/" + authUserData().uuid + "/optimized";
    return authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          if (
            renderSupplierActionButton(
              response?.data?.publisher?.supplier_status
            )
          ) {
            localStorage.setItem(
              "resume_supplier_application",
              response?.data?.publisher?.supplier_status
            );
          }
        }
      })
      .catch((error) => {
        // dddgdgd
      });
  };

  const handleRegTypeChange = (type) => {
    setStage((prev) => prev + 1);
    setRegType(type);
  };

  const handleNextStage = () => {
    setStage((prev) => prev + 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handlePrevStage = () => {
    setStage((prev) => prev - 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (!isPublisher()) {
      window.location.href = "/";
    }
    getUser();
  }, []);

  const userId = JSON.parse(isAuthUser()).uuid;
  return (
    <>
      <div className="bg-white m-2 p-2 flex  items-center justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 bold">
            {!submitted
              ? `Supplier Onboarding`
              : profileVerification
              ? `Profile Update Verification`
              : `Application Submitted`}
          </h1>
        </div>
        <div className="flex gap-2 items-start">
          {/* <BasicButton
            title={"Tutorials"}
            handleClick={() =>
              (window.location.href = "https://litzapp.com/share/sh/nWBVlkmtwF")
            }
            classes={"my-bg-yellow-500 text-black"}
          /> */}
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>

      {/* I removed p-2 from both divs below */}
      <div className="w-full flex flex-col items-center">
        {loadingPage && (
          <div className="w-full md:w-8/12 flex justify-center align-center text-2xl my-10">
            <p>Loading...</p>
          </div>
        )}
        {loadingPageFail && (
          <div className="w-full md:w-8/12 flex justify-center align-center text-2xl my-10">
            <p>Something went wrong...</p>
          </div>
        )}

        <div className="w-full md:w-8/12">
          {/* content start */}
          <PubSupplierOnboardingOpts
            stage={stage}
            handleRegTypeChange={handleRegTypeChange}
            redoMessageMode={redoMessageMode}
            submitted={submitted}
            approved={approved}
            rejected={rejected}
            loadingPage={loadingPage}
            loadingPageFail={loadingPageFail}
          />
          <PubSupplierIndboarding
            stage={stage}
            regType={regType}
            handleNextStage={handleNextStage}
            handlePrevStage={handlePrevStage}
            redoMessageMode={redoMessageMode}
            setRedoMessageMode={setRedoMessageMode}
            setStage={setStage}
            setRegType={setRegType}
            submitted={submitted}
            setSubmitted={setSubmitted}
            approved={approved}
            setApproved={setApproved}
            rejected={rejected}
            setRejected={setRejected}
            setLoadingPage={setLoadingPage}
            loadingPage={loadingPage}
            setLoadingPageFail={setLoadingPageFail}
            loadingPageFail={loadingPageFail}
            setProfoileVerification={setProfoileVerification}
          />
        </div>
      </div>
    </>
  );
}
