import TextField from "../../../../inputs/text-input";
import { SUB_TYPES, SUB_DURATIONS } from "./constants";
import ProductLocationSearch from "./search";
export default function FilterBar(props) {
  const { handleSubTypeChange } = props;
  const { subType } = props;

  const { handleDurationChange } = props;
  const { duration } = props;

  const { handleLocationChange } = props;
  const { location } = props;

  return (
    <>
      <div className="flex justify-center md:justify-center w-full gap-2 md:mr-4 my-2 items-center">
        <div className="flex flex-col gap-2 w-full justify-center items-center">
          <div className="flex gap-2 justify-around gap-2 items-center md:w-8/12 mb-2">
            {" "}
            <div className="flex flex-col items-center w-full">
              <label
                for="timezone"
                value="Your Timezone"
                className="flex justify-center gap-1"
              >
                <span>Subscription Type</span>
                {!subType && <span className="text-red-500 text-2xl">*</span>}
              </label>
              <select
                value={subType}
                onChange={handleSubTypeChange}
                required
                class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
              >
                <option value="" defaultValue disabled>
                  Not selected
                </option>
                {SUB_TYPES.map((b, index) => (
                  <option value={b.label}>{b.label}</option>
                ))}
              </select>
            </div>
            <div className="flex flex-col items-center w-full">
              <label
                for="timezone"
                value="Your Timezone"
                className="flex gap-1"
              >
                <span> Duration</span>
                {!duration && <span className="text-red-500 text-2xl">*</span>}
              </label>
              <select
                value={duration}
                onChange={handleDurationChange}
                required
                class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-1 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full cursor-pointer"
              >
                <option value="" defaultValue disabled>
                  Select
                </option>
                {SUB_DURATIONS.map((b, index) => (
                  <option value={b}>{b}</option>
                ))}
              </select>
            </div>
          </div>
          <label for="timezone" value="Your Timezone">
            Location
          </label>
          <ProductLocationSearch
            title="Search"
            placeholder={""}
            classes="rounded-lg landing-page-search w-8/12"
            handleLocationChange={handleLocationChange}
            location={location}
          />
        </div>
      </div>
    </>
  );
}
