export default function LinkComponent({ entityKey, contentState, children }) {
  let { url } = contentState.getEntity(entityKey).getData();
  return (
    <a
      style={{
        textDecoration: "underline",
      }}
      href={url}
      target="_blank"
      className="text-blue-500"
    >
      {children}
    </a>
  );
}
