import RenderRightAdPageMediaPreview from "../right-ad-media-preview";
import { isResponsiveMode } from "../../../Utils/helpers";
import RightPagination from "./right-pagination";
export default function RightPaginationComposer(props) {
  const {
    views,
    page,
    isAdPage,
    portraitMode,
    isPublisherAdPage,
    setPaginationVerticalPosition,
    checkLastPageHasAdvert,
    isComponentVisible,
    paginateOnAdPage,
    pageNumber,
    onDocumentLoadSuccess,
    showNavigation,
    shouldShowBorder,
    isSystemAdPage,
    refRightNav,
    paginateRight,
    bookPagesCount,
    setNavType,
  } = props;
  return (
    <>
      {" "}
      {true && views.length ? (
        <>
          <div
            className={`right  ${
              isResponsiveMode() && shouldShowBorder() ? "border-l" : ""
            } ${
              portraitMode
                ? "col-span-2 p-1"
                : views.length && views[page].type == "pdf"
                ? "col-span-2"
                : "col-span-4"
            } z-20 ${
              !isResponsiveMode() ||
              (views.length && views[page].type == "advert")
                ? "h-screen flex flex-col items-center justify-center"
                : "h-full flex flex-col items-center justify-center"
            }`}
            style={{ backgroundColor: "" }}
            ref={refRightNav}
            onClick={() => {
              setNavType("CLICK");
              if (views.length && isAdPage()) {
                if (isPublisherAdPage()) {
                  paginateOnAdPage && paginateRight();
                } else {
                  paginateRight();
                }
              } else {
                if (views.length && views[page].type !== "pay_per_view")
                  isResponsiveMode() && paginateRight();
              }
            }}
          >
            {views.length &&
              views[page].type !== "pay_per_view" &&
              showNavigation &&
              isComponentVisible &&
              !isResponsiveMode() &&
              !checkLastPageHasAdvert() && (
                <>
                  {" "}
                  {page !== views.length - 1 ? (
                    <div
                      className="absolute flex flex-col items-center"
                      style={{
                        top: views.length && isAdPage() ? "15%" : "25%",
                        right: views.length && isAdPage() ? "29%" : "11%",
                      }}
                    >
                      <p className="text-white text-xs">Tap Page</p>
                      <p className="text-white text-xs">to Hide/</p>
                      <p className="text-white text-xs">Show</p>
                      <p className="text-white text-xs">Title Panel</p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className="flex items-center justify-center absolute"
                    style={{
                      top:
                        views.length && isAdPage()
                          ? "15%"
                          : setPaginationVerticalPosition(),
                      right: views.length && isAdPage() ? "25%" : "11%",
                    }}
                  >
                    <p className="text-white text-4xl">
                      {/* {bookPagesCount} {page} */}
                    </p>
                    <RightPagination
                      page={page}
                      bookPagesCount={bookPagesCount}
                      handleClick={paginateRight}
                    />
                  </div>
                </>
              )}
            {views.length &&
              views[page].type !== "pay_per_view" &&
              showNavigation &&
              isResponsiveMode() &&
              !checkLastPageHasAdvert() && (
                <div className="flex flex-col items-center  w-full">
                  {" "}
                  <div
                    className={
                      page !== bookPagesCount - 1
                        ? `z-20 ${
                            portraitMode ? "" : "w-full"
                          } flex flex-col items-center justify-center mr-2 text-center text-sm my-4`
                        : "hidden"
                    }
                  >
                    {views.length &&
                    !isAdPage() &&
                    views[page].type !== "info" ? (
                      <>
                        {" "}
                        <p className="text-white">Tap Page</p>
                        <p className="text-white">to Hide/</p>
                        <p className="text-white">Show</p>
                        <p className="text-white">Title Panel</p>
                        <p className="text-white my-4">
                          {" "}
                          <RightPagination handleClick={paginateRight} />
                        </p>
                      </>
                    ) : (
                      <p className="text-white my-4">
                        {" "}
                        <RightPagination handleClick={paginateRight} />
                      </p>
                    )}

                    <p className="text-white"> Next</p>
                  </div>
                </div>
              )}

            {views.length && (
              <RenderRightAdPageMediaPreview
                views={views}
                page={page}
                pageNumber={pageNumber}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                portraitMode={portraitMode}
                isAdPage={isAdPage}
                isPublisherAdPage={isPublisherAdPage}
                isSystemAdPage={isSystemAdPage}
              />
            )}
          </div>
        </>
      ) : (
        <div
          className={`right ${
            portraitMode
              ? "col-span-2"
              : views.length && views[page].type == "pdf"
              ? "col-span-2"
              : "col-span-4"
          } z-20 ${
            !isResponsiveMode() || (views.length && isAdPage())
              ? "h-screen flex items-center"
              : "h-full flex items-center"
          }`}
          style={{ backgroundColor: "" }}
          ref={refRightNav}
        >
          {showNavigation && isComponentVisible && !isResponsiveMode() && (
            <div
              className="flex items-center justify-center absolute"
              style={{
                top: setPaginationVerticalPosition(),
                right: "11%",
              }}
            >
              <RightPagination
                page={page}
                bookPagesCount={bookPagesCount}
                handleClick={paginateRight}
              />
            </div>
          )}

          {showNavigation && isResponsiveMode() && (
            <div
              className={
                page !== bookPagesCount - 1
                  ? `z-20 ${
                      portraitMode ? "" : "w-full"
                    } flex flex-col items-center justify-center mr-2 text-center text-sm my-4`
                  : "hidden"
              }
            >
              {views.length &&
              isAdPage() &&
              views.length &&
              views[page].type !== "info" ? (
                <>
                  {" "}
                  <p className="text-white">Tap Page</p>
                  <p className="text-white">to Hide/</p>
                  <p className="text-white">Show</p>
                  <p className="text-white">Title Panel</p>
                  <p className="text-white my-4">
                    {" "}
                    <RightPagination />
                  </p>
                </>
              ) : (
                <p className="text-white my-4">
                  {" "}
                  <RightPagination handleClick={paginateRight} />
                </p>
              )}

              <p className="text-white"> Next</p>
            </div>
          )}
        </div>
      )}
    </>
  );
}
