import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";

import { isAdmin, API_BASE, BASE_URL } from "../../../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
import DeletePromptModal from "../../../modals/delete-warning-modal";
export default function AdminPaidAdSlots() {
  const { id } = useParams();
  const [genAds, setGenAds] = useState({});
  const [slots, setSlots] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [delModal, showDelModal] = useState(false);
  const [currAdToDelete, setCurrAdToDelete] = useState({});
  const naviagte = useNavigate();

  const openDeletePromtModal = (ad) => {
    showDelModal(true);
    setCurrAdToDelete(ad);
  };

  const hideDelModal = () => {
    showDelModal(false);
  };

  const deleteAdvert = () => {
    const url =
      API_BASE + "/api/paid-advert/slots/" + currAdToDelete.id + "/delete";
    setProcessing(true);

    axios
      .delete(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          getSlots();
          hideDelModal();
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
        setProcessing(false);
      });
  };

  const getSlots = () => {
    const url = API_BASE + "/api/paid-advert/slots/" + id;
    setProcessing(true);

    axios
      .get(url)
      .then((response) => {
        setProcessing(false);

        if (response.status == 200) {
          setSlots(response.data.slots);
          console.log(response.data.slots);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
        setProcessing(false);
      });
  };
  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "/";
    }
    getSlots();
  }, []);
  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">Set {id} </h1>
        </div>
        <div className="flex gap-2">
          <BasicButton
            disabled={slots.length == 6}
            title={"Create Advert"}
            classes={"bg-teal-500 hover:bg-teal-400"}
            handleClick={() => naviagte(`/admin/paid-adverts/set/${id}/create`)}
          />
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center gap-4 mt-8 items-center mb-4 p-4">
        {/* <h1 className="font-bold text-xl">Storage Tiers</h1> */}
        <div
          className={`relative flex flex-col  ${
            slots.length > 0 ? "md:grid md:grid-cols-3 md:items-start" : ""
          } content-center justify-center w-full gap-4`}
        >
          {slots.length ? (
            slots.map((s) => (
              <div className="p-3 shadow m-2 text-center z-50 flex flex-col items-center relative w-full self-center">
                <div
                  className="bg-white rounded rounded-full bg-white p-0 -top-5 right-9 absolute px-1 cursor-pointer shadow"
                  onClick={() =>
                    naviagte(`/admin/paid-adverts/slot/${s.id}/edit`)
                  }
                >
                  <i class="fa fa-pen text-blue-500  z-50 text-2xl"></i>
                </div>
                <div
                  className="bg-white rounded rounded-full bg-white p-0 -top-5 right-0 absolute px-1 cursor-pointer shadow"
                  onClick={() => openDeletePromtModal(s)}
                >
                  <i class="fa fa-times text-red-500  z-50 text-2xl"></i>
                </div>
                <div className="bg-white rounded rounded-full bg-white p-0 -top-5 right-0 absolute px-1 cursor-pointer shadow"></div>

                <div className="w-9/12">
                  <p className="font-bold text-lg text-center">{s.title}</p>
                </div>
                <img
                  className="w-36 h-36 w-36 h-36 md:w-36 md:h-36 object-cover rounded-full"
                  src={BASE_URL + "/storage" + s.cover_photo.split("public")[1]}
                />
                <div className="">
                  <p className="text-lg text-center">{s.body}</p>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center">We found 0 adverts </p>
          )}
        </div>
      </div>
      {currAdToDelete.id ? (
        <DeletePromptModal
          modalOpen={delModal}
          hideAdModal={hideDelModal}
          title={"Are you sure you want to delete this advert?"}
          yesActionText={"Yes, Delete advert"}
          noActionText={"No, Cancel"}
          action={() => deleteAdvert()}
          processing={processing}
        />
      ) : (
        ""
      )}
    </>
  );
}
