import { useSendMailStore } from "../../../../stores/send-mail-store";
import { isResponsiveMode } from "../../../../Utils/helpers";
import Checkbox from "../../../../components/inputs/checkbox";
export default function MobilePubDonationsTable(props) {
  const mailStore = useSendMailStore((state) => state);

  const { blockedEmails } = props;
  const { shouldMarkItem } = props;
  const { markItem } = props;

  return (
    <div class="w-full flex flex-col justify-center my-4 text-center">
      {mailStore.data.data
        ? mailStore.data.data.length
          ? mailStore.data.data.map((purchase) => {
              return (
                <div
                  className="overflow-x-scroll inline"
                  style={{
                    width: document.documentElement.clientWidth - 90 + "px",
                    margin: "0 auto",
                  }}
                >
                  <div className="flex">
                    <div
                      class={`${
                        isResponsiveMode() ? "border text-center" : ""
                      } px-4 py-2 font-medium md:hidden `}
                    >
                      {!blockedEmails.includes(purchase?.email) ? (
                        <div className="md:hidden">
                          {" "}
                          <Checkbox
                            checked={shouldMarkItem(purchase.id) ? true : false}
                            handleChange={() => markItem(purchase.id)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="border px-4 py-2  font-medium">
                      <h3 className="font-bold md:hidden">Item Title</h3>
                      <p className="my-text-yellow-500">
                        {" "}
                        {purchase.book.group == "standard"
                          ? "File"
                          : "Custom Page"}
                      </p>
                      <p>
                        <a
                          target="_blank"
                          className="underline"
                          href={
                            purchase.book.group == "standard"
                              ? `/publisher/${purchase.book.owneruuid}/publications/series/m/book/${purchase.book.book_id}`
                              : `/web/rich-page/render/${purchase.book.book_uuid}`
                          }
                        >
                          {purchase.book.name}
                        </a>
                      </p>
                    </div>

                    <div class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold md:hidden">Item ID</h3>
                      {purchase.book.item_id}
                    </div>
                    <div class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold md:hidden">Price</h3>$
                      {purchase.amount / 100}
                    </div>
                    <div class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold md:hidden">Transaction ID</h3>
                      {purchase.transaction_id}
                    </div>

                    <div class="border  px-4 py-2  font-medium">
                      <h3 className="font-bold md:hidden">Date</h3>
                      <p>
                        {purchase.refactoredCreatedAtTime
                          ? new Date(
                              purchase.refactoredCreatedAtTime.replace(
                                /-/g,
                                "/"
                              )
                            ).getDate() +
                            "-" +
                            (new Date(
                              purchase.refactoredCreatedAtTime.replace(
                                /-/g,
                                "/"
                              )
                            ).getMonth() +
                              1) +
                            "-" +
                            new Date(
                              purchase.refactoredCreatedAtTime.replace(
                                /-/g,
                                "/"
                              )
                            ).getFullYear()
                          : "N/A"}
                      </p>
                    </div>
                    <div
                      class={`${
                        !isResponsiveMode() ? "border" : ""
                      } px-4 py-2  font-medium `}
                    >
                      {!blockedEmails.includes(purchase?.email) ? (
                        <div className="hidden md:block">
                          {" "}
                          <Checkbox
                            checked={shouldMarkItem(purchase.id) ? true : false}
                            handleChange={() => markItem(purchase.id)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          : ""
        : ""}
    </div>
  );
}
