import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import WebHeader from "../header";
import { useParams } from "react-router-dom";
import Footer from "../../../layout/footer";
import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import BlockSuccess from "./components/email-block/block-success";
import BlockWarning from "./components/email-block/block-warning";
import InvalidLink from "./components/email-block/invalid-link";
import BlockAction from "./components/email-block/block-action";

import {
  API_BASE,
  getLocation,
  getClientBaseURL,
} from "../../../Utils/helpers";
import axios from "axios";

export default function PublisherBlockEmailNotice() {
  return (
    <>
      <div class="px-6">
        <br />
        <div class="flex flex-col text-center md:text-left justify-evenly items-center">
          <div class="flex flex-col w-full items-center">
            <div className="text-center w-6/12">
              <h2 className="text-2xl font-bold">STOP UPDATE EMAILS </h2>
              <p>You can’t unsubscribe from yourself.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
