import { isResponsiveMode, API_BASE } from "../../../../../Utils/helpers";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import axios from "axios";
import { toast } from "react-toastify";
import BookingCalendar from "./calendar";
import { useEffect, useState } from "react";
import FilterBar from "./filter";
import BasicButton from "../../../../buttons/basic-button";
import { useDashboardStore } from "../../../../../stores/dashboardStore";
import authRequestService from "../../../../../services/HttpRequests/authenticated-request-services";
export default function HomepageAvailabilityCalendar({
  modalOpen,
  hideModal,
  state,
}) {
  const [meta, setMeta] = useState({});
  const [duration, setDuration] = useState("");
  const [location, setLocation] = useState("");
  const [subType, setSubType] = useState("");
  const [selDate, setSelDateChange] = useState("");
  const [checkingAvailability, setCheckingAvailability] = useState(false);

  const dashboardStore = useDashboardStore((state) => state);

  const handleSelectedDateChange = (e) => {
    setSelDateChange(e);
  };
  useEffect(() => {
    setSelDateChange("");
  }, [modalOpen]);

  const handleSubTypeChange = (e) => {
    setSubType(e.target.value);
  };
  const handleDurationChange = (e) => {
    setDuration(e.target.value);
  };

  const handleLocationChange = (e) => {
    setLocation(e);
  };

  const checkAvailability = () => {
    dashboardStore.setBooks([]);
    dashboardStore.setBooksWithAds([]);
    setCheckingAvailability(true);
    const searchText = location || "*";
    const url =
      API_BASE +
      `/book/custom/availability/date/${selDate}/sub_type/${subType}/duration/${duration}/location/${searchText.replace(
        "/",
        "___"
      )}/dashboard`;
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          dashboardStore.setBooks(response.data.books.data);
          dashboardStore.setNextPageURL(response.data.books.next_page_url);
          hideModal();
        }
        setCheckingAvailability(false);
      })
      .catch((error) => {
        setCheckingAvailability(false);
        toast("Something went wrong!", {
          type: "error",
        });
      });
  };

  return (
    <div
      className={`${
        isResponsiveMode() ? "absolute" : "absolute"
      }  z-99999 overflow-y-auto top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="relative inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-8/12"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className="rounded rounded-full p-0 -top-2 -right-1 absolute px-1 cursor-pointer opacity-75"
            onClick={() => hideModal()}
          >
            <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
          </div>

          <div class="px-4 pt-3 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <BookingCalendar
                minDate={new Date()}
                maxDate={addDays(new Date(), Number(meta.future_availability))}
                setSelected={handleSelectedDateChange}
                selDate={selDate}
                show={true}
                state={{}}
              />
              <FilterBar
                subType={subType}
                duration={duration}
                handleSubTypeChange={handleSubTypeChange}
                handleDurationChange={handleDurationChange}
                handleLocationChange={handleLocationChange}
              />
              <div className="w-full flex justify-center my-6">
                <BasicButton
                  disabled={
                    !selDate || !subType || !duration || checkingAvailability
                  }
                  classes={"my-bg-yellow-500 text-black w-8/12"}
                  title={checkingAvailability ? "Searching.." : "Search"}
                  handleClick={() => checkAvailability()}
                />
              </div>
            </div>
            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
