import BasicButton from "../../../../components/buttons/basic-button";
import { useNavigate } from "react-router-dom";
export default function AdminMobileTable({ data }) {
  const navigate = useNavigate();

  return (
    <>
      <div className="w-full flex flex-col justify-center my-4 text-center">
        {data.data
          ? data.data.map((reader, i) => {
              return (
                <div
                  className="overflow-x-scroll inline"
                  style={{
                    width: document.documentElement.clientWidth - 90 + "px",
                    margin: "0 auto",
                  }}
                >
                  <div className="flex">
                    <div class="border px-4 py-2 font-medium">
                      <p className="break-words">
                        <p className="text-green-500"> First Name</p>
                        <small className="text-base">
                          {" "}
                          {reader.first_name}
                        </small>
                      </p>
                      <p className="break-words">
                        <p className="text-green-500"> Last Name</p>
                        <small className="text-base"> {reader.last_name}</small>
                      </p>
                      <p className="break-words">
                        <p className="text-green-500"> Email</p>
                        <small className="text-base"> {reader.email}</small>
                      </p>
                      <p className="break-words">
                        <p className="text-green-500">Timezone</p>
                        <small className="text-base"> {reader.timezone}</small>
                      </p>
                      <p className="break-words">
                        <p className="text-green-500"> Username</p>
                        <small className="text-base"> {reader.username}</small>
                      </p>
                      <p className="break-words">
                        <p className="text-green-500"> User Handle</p>
                        <small className="text-base"> {reader.handle_id}</small>
                      </p>
                    </div>

                    <div class="border px-4 py-2 font-medium">
                      <div className="flex flex-col items-center gap-1">
                        {" "}
                        <div>
                          <a
                            href={`/admin/non-monetizers/content-purchases/${reader.uuid}`}
                          >
                            <BasicButton
                              classes={"bg-black"}
                              title={"Content Purchases"}
                              handleClick={null}
                            />
                          </a>
                          <p className="text-center">
                            {reader.my_content_purchase_count}
                          </p>
                        </div>
                        <div>
                          <a
                            href={`/admin/non-monetizers/product-purchases/${reader.uuid}`}
                          >
                            <BasicButton
                              classes={"my-bg-yellow-500 text-black"}
                              title={"Product Purchases"}
                              handleClick={null}
                            />
                          </a>
                          <p className="text-center">
                            {reader.my_product_purchase_count}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="border  px-4 py-2  font-medium">
                      <div className="flex flex-col items-center gap-1">
                        {" "}
                        <div>
                          {" "}
                          <a
                            href={`/admin/non-monetizers/content-subs/${reader.uuid}`}
                          >
                            <BasicButton
                              classes={"bg-black"}
                              title={"Content Subs"}
                              handleClick={null}
                            />
                          </a>
                          <p className="text-center">
                            {reader.my_author_sub_count}
                          </p>
                        </div>
                        <div>
                          {" "}
                          <a
                            href={`/admin/non-monetizers/content-subs/${reader.uuid}`}
                          >
                            <BasicButton
                              classes={"my-bg-yellow-500 text-black"}
                              title={"Product Subs"}
                              handleClick={null}
                            />
                          </a>
                          <p className="text-center">
                            {reader.my_product_sub_count}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="border  px-4 py-2  font-medium">
                      <a href={``}>
                        <BasicButton
                          classes={"bg-green-500 hover:bg-green-400 w-full"}
                          title={"Donations"}
                          handleClick={null}
                        />
                      </a>
                    </div>
                    <div class="border px-4 py-2  font-medium">
                      {reader.refactoredCreatedAtTime}
                    </div>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    </>
  );
}
