import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import TextArea from "../../../components/inputs/text-area";
import { isAdmin, API_BASE, isPublisher } from "../../../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
export default function AdHome() {
  let { publisherId } = useParams();
  const [adverts, setAdverts] = useState([]);
  const [data, setData] = useState("");
  const [dataMobile, setDataMobile] = useState("");
  const [name, setName] = useState("");
  const [reports, setReports] = useState([]);
  const getAds = () => {
    const url = API_BASE + "/api/adverts/publisher/" + publisherId;
    axios
      .get(url, {})
      .then((response) => {
        if (response.status == 200) {
          setAdverts(response.data.adverts);
          //   window.location.href = `/publisher/${userId}/publications/series/${seriesId}`;
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const deleteAd = (advert) => {
    const url = API_BASE + "/api/advert/delete/" + advert.id;
    axios
      .delete(url)
      .then((response) => {
        if (response.status == 200) {
          getAds();
          //   window.location.href = `/publisher/${userId}/publications/series/${seriesId}`;
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const handleNameChange = (e) => {
    setName(e);
  };
  const handleDataChange = (e) => {
    setData(e);
  };

  const handleDataMobileChange = (e) => {
    setDataMobile(e);
  };

  const updateAd = () => {};

  useEffect(() => {
    getAds();
    if (!isPublisher()) {
      window.location.href = "/";
    }
  }, []);
  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">Ads</h1>
        </div>
        <div>
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
          {/* <a href={`/ads/publisher/${publisherId}/create`}>
            <BasicButton
              title={"Assign Ad Unit"}
              classes={"bg-teal-500 mx-2"}
              handleClick={() => null}
            />
          </a> */}
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-4 mt-8">
        <div className="w-full md:w-11/12 grid    p-4">
          <table class="table-auto w-full">
            <thead>
              <tr className={window.innerWidth < 720 ? "hidden" : ""}>
                <th class="px-4 py-2 text-gray-600">Name</th>
                <th class="px-4 py-2 text-gray-600">Ad Code</th>
              </tr>
            </thead>
            <tbody>
              {adverts.map((data) => {
                return (
                  <tr
                    className={
                      window.innerWidth < 820
                        ? "flex flex-col items-center"
                        : ""
                    }
                  >
                    <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                      <h3 className="font-bold md:hidden">Name</h3>
                      <span className="break-words">{data.name}</span>
                      {/* {publisher.name} */}
                    </td>
                    <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                      <h3 className="font-bold md:hidden">Ad Code</h3>
                      <span className="break-words">{data.uuid}</span>
                      {/* {publisher.name} */}
                    </td>
                    <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                      <a
                        href={`/reports/publisher/${publisherId}/ad/${data.uuid}`}
                      >
                        <BasicButton
                          classes={"bg-green-500 hover:bg-green-400 w-full"}
                          title={"View Report"}
                          handleClick={null}
                        />
                      </a>
                    </td>
                    {/* <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                      <BasicButton
                        classes={"bg-red-500 hover:bg-red-400 w-full"}
                        title={"Delete"}
                        handleClick={() => deleteAd(data)}
                      />
                    </td> */}

                    {/* <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <h3 className="font-bold md:hidden">Name</h3>
                        {publisher.name}
                      </td> */}
                    {/* <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <h3 className="font-bold md:hidden">Email</h3>
                        <span className="break-words"> {publisher.email}</span>
                      </td>

                      <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <a href={`/publisher/create-series/${publisher.uuid}`}>
                          <BasicButton
                            classes={"bg-teal-500 hover:bg-teal- w-full"}
                            title={"Create Series"}
                            handleClick={null}
                          />
                        </a>
                      </td>
                      <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <a href={`/publisher/create-book/${publisher.uuid}/`}>
                          <BasicButton
                            classes={"bg-green-500 hover:bg-green-400 w-full"}
                            title={"Create Book"}
                            handleClick={null}
                          />
                        </a>
                      </td>
                      <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <a
                          href={`/publisher/publications/options/${publisher.uuid}`}
                        >
                          <BasicButton
                            classes={"bg-indigo-500 hover:bg-indigo-400 w-full"}
                            title={"Plublications"}
                            handleClick={null}
                          />
                        </a>
                      </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div></div>
      </div>
    </>
  );
}
