import { BASE_URL } from "../../../../Utils/helpers";
export default function RenderProfilePhotoCircle(props) {
  const { book } = props;
  if (book.owneroptimized?.profile_photo) {
    return (
      <>
        <img
          className="h-12 w-12 my-1 border-4 border-white rounded-full bg-gray-300 z-2 object-cover"
          onClick={() => null}
          src={
            BASE_URL +
            "/storage/" +
            book.owneroptimized.profile_photo.split("public")[1]
          }
        />
      </>
    );
  } else {
    return (
      <>
        <img
          className="h-12 w-12 my-1 border-4 border-white rounded-full bg-gray-300 z-2 object-cover"
          onClick={() => null}
          src={
            BASE_URL +
            "/storage/" +
            book.owneroptimized?.backup_profile_photo?.split("public")[1]
          }
        />
      </>
    );
  }
}
