import CalendarDays from "./calendar-days";
import "./calendar.css";
import { useEffect, useState } from "react";
import moment from "moment";
import { addDays, getDay, getDate } from "date-fns";

export default function BookingCalendar(props) {
  const [weekdays, setWeekdays] = useState([
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ]);
  const [months, setMonths] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);

  const [currentDay, setCurrentDay] = useState(new Date(moment().format()));
  const [currentMonth, setCurrentMonth] = useState(new Date(moment().format()));

  const changeCurrentDay = (day) => {
    setCurrentDay(new Date(day.year, day.month, day.number));
  };

  const getNextMonth = () => {
    const newDate = new Date(currentMonth); // Create a copy to avoid modifying original
    newDate.setMonth(newDate.getMonth() + 1); // Add 1 to get next month
    setCurrentMonth(newDate);
  };

  const getPreviousMonth = () => {
    const newDate = new Date(currentMonth); // Create a copy
    newDate.setMonth(newDate.getMonth() - 1); // Subtract 1 to get previous month
    setCurrentMonth(newDate);
  };

  return props.show ? (
    <div className="calendar mt-2 bg-gray-50 rounded-lg">
      <div className="calendar-header flex justify-between items-center rounded-lg p-2 underline">
        <button
          disabled={moment(currentMonth).isSame(new Date(), "month")}
          onClick={() => getPreviousMonth()}
        >
          <i
            className={`fas fa-arrow-circle-left text-3xl cursor-pointer ${
              moment(currentMonth).isSame(new Date(), "month")
                ? "text-gray-500"
                : ""
            }`}
          ></i>
        </button>
        <p className="font-bold">
          {/* {months[currentMonth.getMonth()].substring(0, 3)}{" "}
            {currentMonth.getDate()} */}
          {months[currentMonth.getMonth()]} {currentMonth.getFullYear()}
        </p>
        <button
          onClick={() => {
            getNextMonth();
          }}
        >
          <i className="fas fa-arrow-circle-right text-3xl cursor-pointer"></i>
        </button>
      </div>
      <div className="flex flex-col w-full">
        <div className="table-header grid grid-cols-4">
          {weekdays.map((weekday) => {
            return (
              <div className="weekday">
                <p>{weekday}</p>
              </div>
            );
          })}
        </div>

        <CalendarDays
          day={currentMonth}
          changeCurrentDay={changeCurrentDay}
          parentProps={props}
        />
      </div>
    </div>
  ) : (
    ""
  );
}
