import axios from "axios";
import AdSense from "react-adsense";
import { useEffect, useState } from "react";
import { globalAds, getLocation, API_BASE } from "../../../Utils/helpers";
import Adsterra from "../../../components/advert/adsterra";
import { useIntersectionObserver } from "@uidotdev/usehooks";

export function RenderSystemAd({ filename, adRequest }) {
  useEffect(() => {}, []);
  const randomPick = Math.floor(Math.random() * 13);
  const imageUrl = globalAds[randomPick].image;
  const imageLink = globalAds[randomPick].link;

  const renderAdvertTemplate = (code) => {
    if (adRequest.status == "approved") {
      if (adRequest.provider == "ADSENSE") {
        const litzappCode = adRequest.system_ad_code;
        return (
          <>
            {" "}
            <div className="flex justify-center w-full">
              <AdSense.Google
                client="ca-pub-8191791319921859"
                slot={litzappCode}
                style={{ width: 250, height: 250, float: "left" }}
                layout="in-article"
                format=""
              />
            </div>
          </>
        );
        /*  */
      } else {
        const litzappCode = adRequest.system_ad_2_code;
        return (
          <>
            <div className="flex justify-center w-full">
              <Adsterra slot={litzappCode} />;
            </div>
          </>
        );
      }
    }

    if (adRequest.status == "unprocessed") {
      return (
        <>
          <div className="flex justify-center w-full">
            {globalAds.length && (
              <img
                className="cursor-pointer"
                src={imageUrl}
                onClick={() => (window.location.href = imageLink)}
              />
            )}
          </div>
          <p className="mt-0"></p>
        </>
      );
    }
  };

  return getLocation().hostname == "litzapp.com" ? (
    renderAdvertTemplate()
  ) : (
    <>
      <div className="flex justify-center w-full">
        {globalAds.length && (
          <img
            className="cursor-pointer"
            src={imageUrl}
            onClick={() => (window.location.href = imageLink)}
          />
        )}
      </div>
      <p className="mt-0"></p>
    </>
  );
}
