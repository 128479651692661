import BasicButton from "../../../../../components/buttons/basic-button";

// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { isResponsiveMode } from "../../../../../Utils/helpers";
import { useRef, useState } from "react";
import Slider from "react-slick";
export default function BizLinkAssistSlider({ modalOpen, closeModal }) {
  const slider = useRef(null);
  const [sliderToShow, setSliderToShow] = useState(0);
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 1500,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      className={` ${
        isResponsiveMode() ? "fixed" : "absolute"
      }  z-40 overflow-y-auto top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen ">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle  w-full md:w-6/12"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className="rounded rounded-full p-0 -top-2 -right-1 absolute px-1 cursor-pointer opacity-75"
            onClick={() => closeModal()}
          >
            <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
          </div>
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <h1 className="text-2xl font-bold text-center">
                {/* {getActiveWidgetTitle(currentEditingWidget, widgets)} */}
                {sliderToShow == 1 ? "Google" : ""}
                {sliderToShow == 2 ? "Bing" : ""}
              </h1>
            </div>
            <div className="w-full  m-2">
              <div className="flex flex-col md:flex-row justify-center gap-4 my-2">
                <div>
                  <BasicButton
                    title={"Help Find My Google Profile Link"}
                    handleClick={() => setSliderToShow(1)}
                  />
                </div>
                <div>
                  {" "}
                  <BasicButton
                    title={"Help Find My Bing Profile Link"}
                    handleClick={() => setSliderToShow(2)}
                  />
                </div>
              </div>

              {sliderToShow !== 0 ? (
                <div className="px-6 mb-2 w-full text-center flex justify-center items-center">
                  <i
                    onClick={() => slider?.current?.slickPrev()}
                    class="fas fa-arrow-circle-left text-3xl cursor-pointer"
                  ></i>
                  {sliderToShow == 1 && (
                    <Slider ref={slider} {...settings} className="w-full">
                      <img src={"/google_1.PNG"} className="w-full" />

                      <img src={"/google_2.PNG"} className="w-full" />

                      <img src={"/google_3.PNG"} className="w-full" />
                    </Slider>
                  )}
                  {sliderToShow == 2 && (
                    <div className="w-full gap-4 z-50 cursor-pointer">
                      <Slider ref={slider} {...settings} className="w-full">
                        <img src={"/bing_1.PNG"} className="w-full" />{" "}
                        <img src={"/bing_2.PNG"} className="w-full" />{" "}
                        <img src={"/bing_3.PNG"} className="w-full" />{" "}
                      </Slider>
                    </div>
                  )}
                  <i
                    onClick={() => slider?.current?.slickNext()}
                    class="fas fa-arrow-circle-right text-3xl cursor-pointer"
                  ></i>
                </div>
              ) : (
                ""
              )}
            </div>
            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => closeModal()}
            >
              <i class="fas fa-times"></i> Close
            </button>
            {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
