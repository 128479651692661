import { useSendMailStore } from "../../../../stores/send-mail-store";
import { isResponsiveMode } from "../../../../Utils/helpers";
import BasicButton from "../../../buttons/basic-button";

export default function DesktopTable(props) {
  const getUniqueSubs = () => {
    if (mailStore?.data?.data) {
      const mapSubs = new Map(
        mailStore.data.data.map((c) => [c.subscription_id, c])
      );

      const uniqueSubs = [...mapSubs.values()];
      return uniqueSubs;
    }

    return [];
  };
  const mailStore = useSendMailStore((state) => state);
  const { processing, preSubCancelPromt, nonRenewals } = props;
  return (
    <div className="overflow-x-scroll md:h-screen w-full">
      <table class="table-auto w-full">
        <thead>
          <tr className={window.innerWidth < 720 ? "hidden" : ""}>
            <th class="px-4 py-2 text-gray-600">Subscriber</th>
            <th class="px-4 py-2 text-gray-600">Subscription Type</th>
            <th class="px-4 py-2 text-gray-600">Start Date</th>
            <th class="px-4 py-2 text-gray-600">Renewal Date</th>
            <th class="px-4 py-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {getUniqueSubs().map((subscription) => {
            return (
              <tr
                className={
                  window.innerWidth < 820 ? "flex flex-col items-center" : ""
                }
              >
                <td class="border border-gray px-4 py-2  font-medium">
                  <div className="flex flex-col justify-center items-start gap-2">
                    <div>
                      <p className="font-bold">Name</p>

                      <a
                        href={`/web/${subscription.subscriberdata.uuid}`}
                        target="_blank"
                      >
                        <span className="underline text-blue-900">
                          {subscription?.subscriberdata?.name}
                        </span>
                      </a>
                    </div>
                    <div>
                      <p className="font-bold">User Handle</p>
                      <a
                        href={`/web/${subscription.subscriberdata.uuid}`}
                        target="_blank"
                      >
                        <span className="underline text-blue-900">
                          {subscription?.subscriberdata?.handle_id}
                        </span>
                      </a>
                    </div>
                    <div>
                      <p className="font-bold">Email</p>
                      <a href={`mailto:${subscription.subscriberdata.email}`}>
                        <span className="underline text-blue-900">
                          {subscription?.subscriberdata?.email}
                        </span>
                      </a>
                    </div>
                    <div>
                      <p className="font-bold ">Phone</p>
                      <a
                        href={"tel:" + subscription.subscriberdata.phone.phone}
                      >
                        <span className="underline text-blue-900">
                          {subscription?.subscriberdata?.phone?.phone || "N/A"}
                        </span>
                      </a>
                    </div>
                  </div>
                </td>
                <td class="border border-gray px-4 py-2  font-medium">
                  {subscription.metadata.purchase_option_name}
                  <br />
                  {/* {subscription.subscription_id} */}
                  <br />
                </td>
                <td class="border border-gray px-4 py-2  font-medium">
                  <p>
                    {subscription.start_date
                      ? new Date(
                          subscription.start_date.replace(/-/g, "/")
                        ).getDate() +
                        "-" +
                        (new Date(
                          subscription.start_date.replace(/-/g, "/")
                        ).getMonth() +
                          1) +
                        "-" +
                        new Date(
                          subscription.start_date.replace(/-/g, "/")
                        ).getFullYear()
                      : "N/A"}
                  </p>
                </td>
                <td class="border border-gray px-4 py-2  font-medium">
                  <p>
                    {subscription.end_date
                      ? new Date(
                          subscription.end_date.replace(/-/g, "/")
                        ).getDate() +
                        "-" +
                        (new Date(
                          subscription.end_date.replace(/-/g, "/")
                        ).getMonth() +
                          1) +
                        "-" +
                        new Date(
                          subscription.end_date.replace(/-/g, "/")
                        ).getFullYear()
                      : "N/A"}
                  </p>
                  {subscription.status == "cancelled" && (
                    <p className="text-red-500"> (End Date)</p>
                  )}

                  {nonRenewals.length &&
                    nonRenewals.includes(subscription.subscription_id) && (
                      <p className="text-red-500"> (End Date)</p>
                    )}
                </td>

                <td class="border border-gray px-4 py-2 font-medium text-center">
                  <h3 className="font-bold md:hidden ">Duration</h3>
                  <div className="flex flex-col items-center gap-4">
                    <BasicButton
                      disabled={
                        (subscription.status != "active" &&
                          subscription.status !== "Pending") ||
                        processing
                      }
                      title={
                        processing
                          ? "Cancelling.."
                          : subscription.status == "active" ||
                            subscription.status == "Pending"
                          ? "Cancel Subscription"
                          : "Cancelled"
                      }
                      handleClick={() => preSubCancelPromt(subscription)}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
