import { removeAtomicBlocFromEditor } from "../helpers/functions";

export default function Accordion({
  blockKey,
  payload,
  editorState,
  setEditorState,
  editorStateHelper,
  showAccordionUpdateBox,
}) {
  const deleteItem = () => {
    removeAtomicBlocFromEditor(
      blockKey,
      editorState,
      setEditorState,
      editorStateHelper
    );
  };

  const data = JSON.parse(payload);

  return (
    <div class="flex flex-wrap  justify-center mb-5">
      <div className="grid grid-cols-3 gap-2 bg-gray-50">
        <div
          className="col-span-2 border p-2 rounded-lg flex gap-2 items-center "
          contenteditable="false"
        >
          {data.name}
        </div>
        <div className="flex gap-2" contentEditable="false">
          <span className="bg-white rounded rounded-full bg-white p-0 px-1 cursor-pointer">
            <i
              class="fa fa-edit text-blue-500  z-50 text-2xl"
              onClick={() => showAccordionUpdateBox(data, blockKey)}
            ></i>
          </span>
          <span
            className="cursor-pointer"
            onClick={() => {
              deleteItem();
            }}
          >
            <i
              class={`fa fa-times-circle text-red-500  z-50 text-2xl product-page-editor-button`}
            ></i>
          </span>
        </div>
      </div>
    </div>
  );
}
