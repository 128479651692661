import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import axios from "axios";
import moment from "moment";
import Filter from "./components/filter";
import { useEffect, useState, useRef } from "react";
import BasicButton from "../../../../components/buttons/basic-button";
import TextField from "../../../../components/inputs/text-input";
import Pagination from "../../../../components/pagination";
import useDebounce from "../../../../components/debounce/debounce-helper";
import LoadingProgress from "../../rich-page/components/loading-progress";
import authRequestService from "../../../../services/HttpRequests/authenticated-request-services";
import authPaginatedRequestService from "../../../../services/HttpRequests/authenticated-paginated-req-service";
import { useSendMailStore } from "../../../../stores/send-mail-store";
import Checkbox from "../../../../components/inputs/checkbox";
import DesktopPubEmailSubscriptionsTable from "./components/desktop-table";
import MobilePubEmailSubscriptionsTable from "./components/mobile-table";
import StickyHeader from "./components/sticky-header";
import ProductCalendarModal from "../../../../components/product-calendar";
import {
  isAdmin,
  API_BASE,
  isPublisher,
  isAuthUser,
  getLocation,
  authUserData,
  isResponsiveMode,
} from "../../../../Utils/helpers";
import { toast } from "react-toastify";
import LeftPanel from "./components/left-panel";
import RightPanel from "./components/right-panel";
import StickyLeftPanel from "./components/sticky-header/left-panel";
import StickyRightPanel from "./components/sticky-header/right-panel";

export default function PubProductAccountCalendar() {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthDays = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];

  const monthsList = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
  };
  console.log("ccc", monthsList[new Date().getMonth()]);
  const [processing, setProcessing] = useState(false);
  const mountedRef = useRef();
  const [selYear, setSelYear] = useState(new Date().getFullYear());
  const [selMonth, setSelMonth] = useState(moment().format("MMMM"));
  const mailStore = useSendMailStore((state) => state);
  const [productMeta, setProductMeta] = useState({});
  const [products, setProducts] = useState([]);
  const [productsForFilter, setProductsForFilter] = useState([]);
  const [urls, setUrls] = useState([]);
  const [subs, setSubs] = useState([]);
  const [page, setPage] = useState(2);
  const [metaType, setMetaType] = useState("*");

  const publisherId = JSON.parse(isAuthUser()).uuid;

  const [showProductCalendar, setShowProductCalendar] = useState(false);
  const [currSelProduct, setCurrSelProduct] = useState({});

  const openProductCalendar = (data) => {
    setShowProductCalendar(true);
    setCurrSelProduct(data);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const closeProductCalendar = () => {
    setShowProductCalendar(false);
    setCurrSelProduct({});
  };

  const getProducts = () => {
    setProcessing(true);
    setProducts([]);
    const url = API_BASE + `/book/custom/product/metas/preferred/list`;
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.metas) {
            setProductsForFilter(response.data.metas.data);
            setProducts(response.data.metas.data);
            setProductMeta(response.data.metas);
          }
        }
        setProcessing(false);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
        setProcessing(false);
      });
  };

  const getSingleProduct = () => {
    setProcessing(true);
    setProducts([]);
    const url =
      API_BASE + `/book/custom/product/metas/${metaType}/preferred/single`;
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.metas) {
            setProducts(response.data.metas);
            setProductMeta(response.data.metas);
          }
        }
        setProcessing(false);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
        setProcessing(false);
      });
  };

  const doPagination = () => {
    if (productMeta?.next_page_url) {
      mailStore.setData([]);
      setProcessing(true);
      authPaginatedRequestService()
        .get(productMeta.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          const oldp = products;
          setProductMeta(res.data.metas);
          setPage((prev) => prev + 1);
          setProducts((prev) => [...prev, ...res.data.metas.data]);
          setProcessing(false);
        })
        .catch((err) => setProcessing(false));
    }
  };

  const handleSearchTextChange = (e) => {
    mailStore.setQuery("searchText", e);
  };

  const handleCountPerPageChange = (e) => {
    mailStore.setQuery("countPerPage", e.target.value);
  };

  const handleLatestEntriesChange = (e) => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
    mailStore.setQuery("selectedLatest", e.target.value);
    mailStore.setQuery("pageLimit", e.target.value);
  };

  const handleOldestEntriesChange = (e) => {
    mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
    mailStore.setQuery("selectedOldest", e.target.value);
    mailStore.setQuery("pageLimit", e.target.value);
  };

  const handleMetaTypeChange = (e) => {
    setMetaType(e.target.value);
  };

  useEffect(() => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedLatest]);

  useEffect(() => {
    mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedOldest]);

  const handleYearChange = (e) => {
    setSelYear(e.target.value);
  };
  const handleMonthChange = (e) => {
    setSelMonth(e.target.value);
  };

  function truncateText(text, maxLength, ellipsis = "...") {
    maxLength = isResponsiveMode() ? 13 : 17;
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength - ellipsis.length) + ellipsis;
  }

  async function makeSequentialCallsWithRetry(urls, delay, retries = 3) {
    const data = [];
    for (const url of urls) {
      const subData = [];
      let attempts = 0;
      while (attempts < retries) {
        try {
          const response = await axios.get(url);
          if (response?.data?.subs) {
            Object.keys(response.data.subs).forEach(function (key, index) {
              subData.push({
                color: response.data.subs[key].color,
                date: new Date(response.data.subs[key].date),
                code: response.data.subs[key].code,
                message: response.data.subs[key].message,
                date_only: key,
                sub_count: response.data.subs[key].sub_count,
                available_quantity: response.data.subs[key].available_quantity,
                quantity: response.data.subs[key].quantity,
              });
            });
          }

          break; // Exit the while loop on success
        } catch (error) {
          attempts++;
          console.error(
            `Error fetching data from ${url} (attempt ${attempts}/${retries})`,
            error
          );
          await new Promise((resolve) =>
            setTimeout(resolve, delay * Math.pow(2, attempts - 1))
          ); // Exponential backoff delay
        }
      }

      data.push(subData);
    }

    setSubs(data);

    return data;
  }

  // Example usage

  const delay = 1000; // Delay in milliseconds (1 second here)
  const retries = 3; // Maximum retry attempts
  useEffect(() => {
    if (!isPublisher()) {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (metaType !== "*") {
      getSingleProduct();
    } else {
      getProducts();
    }
  }, [metaType]);

  useEffect(() => {
    if (products?.length) {
      const p = [];
      for (let index = 0; index < products.length; index++) {
        const url =
          API_BASE +
          "/api/book/custom/product/meta/map/custom/" +
          products[index].block_meta_id +
          "/subs";
        p.push(url);
      }

      setUrls(p);
    }
  }, [products]);

  useEffect(() => {
    if (urls.length) {
      makeSequentialCallsWithRetry(urls, 3000);
    }
  }, [urls]);

  const div1Ref = useRef(null);
  const div2Ref = useRef(null);
  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">Product Calendar</h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex justify-center my-4">
          <div class="p-10 w-full">
            <p className="w-full text-center my-4">
              We found {mailStore.data.total ? mailStore.data.total : ""}{" "}
              records
            </p>

            <Filter
              selMonth={selMonth}
              selYear={selYear}
              handleYearChange={handleYearChange}
              handleMonthChange={handleMonthChange}
              productsForFilter={productsForFilter}
              monthNames={monthNames}
              selMeta={metaType}
              handleMetaTypeChange={handleMetaTypeChange}
            />

            <div className="w-full justify-center my-4 text-center ">
              <div
                className="inline w-full"
                style={{
                  width: document.documentElement.clientWidth - 90 + "px",
                  margin: "0 auto",
                }}
              >
                <div className="flex flex-col w-full overflow-x-hidden overflow-y-scroll h-10 gap-2">
                  <div className="grid grid-cols-7">
                    <StickyLeftPanel
                      products={products}
                      truncateText={truncateText}
                    />
                    <StickyRightPanel
                      subs={subs}
                      products={products}
                      monthDays={monthDays}
                      monthsList={monthsList}
                      selYear={selYear}
                      selMonth={selMonth}
                      div1Ref={div1Ref}
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full overflow-x-hidden overflow-y-scroll h-80 relative gap-2 ">
                  <div className="grid grid-cols-7">
                    <LeftPanel
                      products={products}
                      truncateText={truncateText}
                      openProductCalendar={openProductCalendar}
                    />
                    <RightPanel
                      subs={subs}
                      products={products}
                      monthDays={monthDays}
                      monthsList={monthsList}
                      selYear={selYear}
                      selMonth={selMonth}
                      div2Ref={div2Ref}
                      div1Ref={div1Ref}
                    />
                  </div>
                  <div className="w-full flex justify-center my-6">
                    <BasicButton
                      disabled={!productMeta?.next_page_url || processing}
                      classes={"my-bg-yellow-500 text-black mb-2"}
                      title={processing ? "Loading..." : "Load More"}
                      handleClick={() => doPagination()}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <LoadingProgress loading={processing} /> */}
            <div className="flex justify-center"></div>
          </div>
        </div>
      </div>
      <ProductCalendarModal
        hideModal={closeProductCalendar}
        modalOpen={showProductCalendar}
        state={{ data: currSelProduct.book_data }}
      />
    </>
  );
}
