import uuid from "react-uuid";
import TextField from "../../../../../../../components/inputs/text-input";
export default function ShortDurations(props) {
  const { productMeta, pageContext, handleMetaChange, user, b } = props;
  return (
    <div className="flex justify-around gap-2 items-center ">
      <div className="flex justify-center w-3/12">{b.label}</div>
      <div className="flex flex-col justify-between items-center w-full">
        <label className="text-black text-sm">Price (USD)</label>
        <TextField
          classes={"p-6 my-2"}
          placeholder={"Price.."}
          type="number"
          handleChange={(e) => {
            if (pageContext == "create") {
              handleMetaChange(
                { label: b.label, price: e, uuid: uuid() },
                b.label,
                "product_short"
              );
            } else {
              handleMetaChange(
                { label: b.label, price: e },
                b.label,
                "product_short"
              );
            }
          }}
          value={productMeta[b.label].price ?? ""}
        />

        <p className="text-xs">
          {user.id
            ? user.system_setting.constant_fee_on_payments &&
              productMeta[b.label].price
              ? `Service Charge $${Number(
                  user.system_setting.constant_fee_on_payments
                )} + ${productMeta[b.label].price} = $${
                  Number(productMeta[b.label].price) +
                  Number(user.system_setting.constant_fee_on_payments)
                }`
              : ``
            : ""}
        </p>
        <p className="text-base text-pink-500">
          {productMeta[b.label].price &&
            productMeta[b.label].price == 0 &&
            "Please enter a price greater than 0"}
        </p>
      </div>
      {!productMeta[b.label].price && (
        <span className="text-red-500 text-2xl">*</span>
      )}
    </div>
  );
}
