import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import {
  isAuthUser,
  authUserData,
  isAdmin,
  isPublisher,
  isReader,
  isResponsiveMode,
} from "../../Utils/helpers";
import { useState } from "react";
import Reader from "../Reader";
import MobileBar from "../../layout/nav/header/mobile/mobile-bar";
import DesktopRightBar from "../../layout/nav/header/desktop/right-bar";
import DesktopCenterBar from "../../layout/nav/header/desktop/center-bar";
import DesktopLeftBar from "../../layout/nav/header/desktop/left-bar";
import MobileMenuBar from "../../layout/nav/header/mobile/menu";

export default function WebHeader() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const naviagte = useNavigate();
  const location = useLocation();
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    localStorage.removeItem("platform");
    localStorage.removeItem("resume_supplier_application");
    naviagte("/login");
  };

  const togleMobileMenu = () => {
    setShowMobileMenu((showMenu) => !showMenu);
  };

  return (
    <div class="relative bg-white shadow">
      <div class="">
        <div class="flex flex-col justify-between items-center gap-4 md:hidden p-2">
          {/* mobile  */}
          <MobileBar togleMobileMenu={togleMobileMenu} location={location} />
          <MobileMenuBar
            showMobileMenu={showMobileMenu}
            handleLogout={handleLogout}
          />
        </div>

        {/* desktop */}
        <div class="hidden md:flex flex-col md:flex-row justify-between items-center  md:justify-start  gap-4 p-1">
          <DesktopLeftBar />
          <DesktopCenterBar showMobileMenu={showMobileMenu} />
          <DesktopRightBar
            handleLogout={handleLogout}
            togleMobileMenu={togleMobileMenu}
          />
        </div>
        {showMobileMenu && (
          <div class="md:flex flex-col justify-between items-center gap-4 p-2 hidden">
            <MobileMenuBar
              showMobileMenu={showMobileMenu}
              handleLogout={handleLogout}
            />
          </div>
        )}
      </div>
    </div>
  );
}
