import axios from "axios";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { stateToHTML } from "draft-js-export-html";
import { useState, useEffect, useRef } from "react";
import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import DeletePromptModal from "../../../modals/delete-warning-modal";
import PaymentControlsModal from "../../../modals/payment-controls-modal";
import Image from "./components/delete-insert-image";
import Video from "./components/delete-insert-video";
import Hire from "./components/delete-insert-hire";
import Table from "./components/delete-insert-table";
import Accordion from "./components/delete-insert-accordion";
import SignupForUpdatesBox from "./components/delete-email-signup";
import ButtonLinkBox from "./components/delete-button-link";
import Advert from "./components/delete-insert-advert";
import SocialImageEmbed from "./components/delete-insert-social-embed";
import SocialLinksEmbed from "./components/delete-insert-social_links";
import ApplyFont from "./components/font-component";
import InsertImage from "./components/insert-image";
import InsertVideo from "./components/insert-video";
import PremiumButton from "./components/premium-button";
import Banners from "./components/delete-insert-banners";
import FreeContentCoverLinks from "./components/free-content-cover-links";
import BugReportNotice from "./components/bug-report-notice";
import ButtonLink from "./components/button-link";
import ButtonLinkModalEditor from "../../../modals/button-link-modal";
import ImageModalEditor from "./components/image-button/components/image-insert-modal-editor";
import HireModalEditor from "../../../modals/hire-insert-modal-editor";
import { isDataSubExpired } from "../../../services/DataThreshold";
import { useBookStore } from "../../../stores/book-store";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";
import PublisherTutorialBar from "../../../layout/nav/header/mobile/tutorial-bar";
import MultiImageUploaderContentNew from "./components/image-upload-multi-content-new";
import BannerModal from "./components/banner-button/components/banner-modal";

import {
  isAdmin,
  API_BASE,
  isPublisher,
  BASE_URL,
  isResponsiveMode,
  isAuthUser,
  getLocation,
} from "../../../Utils/helpers";
import {
  COLOR_TYPES,
  ACTION_TYPES,
  BLOCK_TYPES,
  HEADER_TYPES,
  PRESET_COLORS,
} from "./editor-objects";

import {
  EditorState,
  SelectionState,
  RichUtils,
  ContentState,
  Modifier,
  AtomicBlockUtils,
  convertToRaw,
  KeyBindingUtil,
  getDefaultKeyBinding,
  CompositeDecorator,
  convertFromRaw,
} from "draft-js";
import {
  htmlRenderOptions,
  htmlRenderOptionsFree,
  changeIndent,
  shortenString,
  // makeToolbarSticky,
  insertSignupBoxIntoEditor,
  insertAdvertIntoEditor,
  insertImageIntoEditor,
  insertVideoIntoEditor,
  insertHireIntoEditor,
  InlineStyleControls,
  insertLink,
  removeLink,
  makePremium,
  applyFont,
  BlockStyleControls,
  insertButtonLink,
  insertSocialEmbedIntoEditor,
  insertTableIntoEditor,
  insertBannerIntoEditor,
  insertAccordionIntoEditor,
  insertSocialImageLinksIntoEditor,
} from "./helpers/functions";
import Editor from "@draft-js-plugins/editor";
import "draft-js/dist/Draft.css";
import AdInsertModalEditor from "../../../modals/ad-insert-modal-editor";
import LinkModalEditor from "../../../modals/link-insert-modal-editor";
import VideoModalEditor from "../../../modals/video-insert-modal-editor";
import ColorPicker, { colorPickerPlugin } from "draft-js-color-picker";
import createLinkifyPlugin from "draft-js-link-detection-plugin";
import BookCoverUpload from "./components/book-cover-upload";
import uuid from "react-uuid";
import "./rich.css";
import ToolBar from "./components/toolbar-bar";
import TierUpgradeNoticeModal from "../../../modals/data-tier-upgrade-notice";
import TableEditor from "./components/table-button/components/table-modal";
import AccordionEditor from "./components/accordion-button/components/accordion-modal";

export default function UpdateRichPage() {
  const [publisher, setPublisher] = useState({});
  const [name, setName] = useState("");
  const [data, setData] = useState("");
  const [page, setPage] = useState({});
  const [price, setPrice] = useState("");
  const [processing, setProcessing] = useState(false);
  const [coverPhoto, setCoverPhoto] = useState("");
  const [pageCoverPhoto, setPageCoverPhoto] = useState("");
  const [coverFileSelected, setCoverFileSelected] = useState("");
  const [editorPhoto, setEditorPhoto] = useState("mm");
  const [editorFileSelected, setEditorFileSelected] = useState("");
  const [fileSelected, setFileSelected] = useState("");
  const [editorOutput, setEditorOutput] = useState("");
  const [showAdModal, setShowAdModal] = useState(false);
  const [showPaymentControlsModal, setShowPaymentControlsModal] =
    useState(false);
  const [currPastedLink, setCurrPastedLink] = useState("");
  /*video and image state variables*/
  const [currPastedVideoLink, setCurrPastedVideoLink] = useState("");
  const [videoFileSelected, setVideoFileSelected] = useState("");
  const [videoFileData, setVideoFileData] = useState("");
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [currPastedImageLink, setCurrPastedImageLink] = useState("");
  const [imageFileSelected, setImageFileSelected] = useState("");
  const [imageFileData, setImageFileData] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [currEditingImage, setCurrEditingImage] = useState("");
  const [currEditingImagePreview, setCurrEditingImagePreview] = useState("");
  const [createImageUploadMode, setCreateImageUploadMode] = useState(true);
  const [currImageEditBlockKey, setCurrImageEditBlockKey] = useState("");
  /*video and image state variables*/

  /*advert*/
  const [advertRequestStatus, setAvertRequestStatus] = useState("");
  /*advert*/

  /*hire button*/
  const [showHireModal, setShowHireModal] = useState(false);
  const [currHireText, setCurrHireText] = useState("");
  const [currHireResponseStatus, setCurrHireResponseStatus] = useState(false);
  const [createHireMode, setCreateHireMode] = useState(true);
  const [currHireEditBlockKey, setCurrHireEditBlockKey] = useState(false);
  const [currHireReplySubject, setCurrHireReplySubject] = useState("");
  const [currHirePrice, setCurrHirePrice] = useState("");
  /*hire button*/

  /*Social Images Insert*/
  const [authTokens, setAuthTokens] = useState([]);
  const [selectedSocialPhoto, setSelectedSocialPhoto] = useState([]);
  const [socialEmbedValue, setSocialEmbedValue] = useState("");
  const [socialUploadType, setSocialUploadType] = useState("");
  const [socialUploadSubType, setSocialUploadSubType] = useState("");

  /*table*/
  const initialTableColumn = {
    header: { placeholder: "Enter Column Header", value: "" },
    rows: [{ placeholder: "Fill Row: Type here...", value: "" }],
  };
  const [tableColumns, setTableColumns] = useState([initialTableColumn]);
  const [currTableEditBlockKey, setCurrTableEditBlockKey] = useState("");
  const [createTableMode, setCreateTableMode] = useState(true);
  const [showTableModal, setShowTableModal] = useState(false);
  const [tableTitle, setTableTitle] = useState("");
  /*table*/

  /*accordion*/
  const initialAccordion = {
    header: { placeholder: "Enter Header Name", value: "" },
    body: { placeholder: "Type here...", value: "" },
    media: [],
  };
  const [showAccordionModal, setShowAccordionModal] = useState(false);
  const [accordions, setAccordions] = useState([]);
  const [currAccordionEditBlockKey, setCurrAccordionEditBlockKey] =
    useState("");
  const [createAccordionMode, setCreateAccordionMode] = useState(true);
  const [accordionTitle, setAccordionTitle] = useState("");
  const [accordionId, setAccordionId] = useState("");
  /*accordion*/

  /*product slides*/
  const [productSlides, setProductSlides] = useState([]);
  const [uploadingSlides, setUploadingSlides] = useState(false);
  const [deletingSlides, setDeletingSlides] = useState(false);

  /*select banners*/
  const [bannerModalOpen, setBannerModalOpen] = useState(false);
  const [bannerSlides, setBannerSlides] = useState([]);
  const [currEditingBannerId, setCurrEditingBannerId] = useState("");
  const [createBannerMode, setCreateBannerMode] = useState(true);

  const [showLinkModal, setShowLinkModal] = useState(false);
  const [buttonLinkModal, setShowButtonLinkModal] = useState(false);
  const [defaultFontSize, setDefaultFontSize] = useState("");
  const [linkModifyMode, setLinkModifyMode] = useState(false);
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [currentEditingAdId, setcurrentEditingAdId] = useState();
  const [selectionHasLink, setSelectionHasLink] = useState(false);
  const [currentSelectedLink, setCurrentSelectedLink] = useState({});
  const [selectionAnchors, setSelectionAnchors] = useState({
    start: null,
    end: null,
  });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [showDeletePromptModal, setShowDeletePromptModal] = useState(false);
  const [bothPaymentsControlsEnabled, setBothPaymentControlsEnabled] =
    useState(false);
  const [purchaseButtonEnabled, setPurchasedButtonEnabled] = useState(false);
  const [subscriptionButtonEnabled, setSubscriptionButtonEnabled] =
    useState(false);
  const [paymentType, setPaymentType] = useState([]);
  const [sessionId, setSessionId] = useState(uuid());
  const [buttonLinkName, setButtonLinkName] = useState("");
  const [buttonLink, setButtonLink] = useState("");
  const [addedSpaceToLinkItem, setAddedSpaceToLinkItem] = useState(false);
  const [tierNoticeModalOpen, setTierNoticeModalOpen] = useState(false);
  const [tierModalNotice, setTierModalNotice] = useState("");
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState("");
  const linkifyPlugin = createLinkifyPlugin();
  let { userId } = useParams();
  let { pageId } = useParams();
  const editorFocusRef = useRef();
  const linkBoxRef = useRef();
  const previewImageRef = useRef();
  const plugins = [linkifyPlugin];
  const editor = useRef(null);
  const indentRef = useRef(null);
  const isRemovingLinkFromList = useRef(null);

  const updateEditorState = (newState) => setEditorState(newState);
  const getEditorState = () => editorState;
  const picker = colorPickerPlugin(updateEditorState, getEditorState);
  const { setIsDataSubExpired } = useBookStore((state) => state);

  const processDataThresholdError = (error) => {
    if ("larger_than_data_tier" in error.response.data) {
      let data = error.response.data["larger_than_data_tier"];
      setTierModalNotice(data.message_one);
      setTierNoticeModalOpen(true);
      return;
    }
    if ("will_break_my_tier_limit" in error.response.data) {
      let data = error.response.data["will_break_my_tier_limit"];
      setTierModalNotice(data.message_two);
      setTierNoticeModalOpen(true);
      return;
    }
    if ("larger_than_max_upload_limit" in error.response.data) {
      toast("You cannot upload a file larger than 20 MB", { type: "error" });
      return;
    }
  };

  const deletePage = (page) => {
    setProcessing(true);
    const url = API_BASE + "/api/book/delete/" + page.uuid;
    axios
      .delete(url)
      .then((response) => {
        if (response.status == 200) {
          toast("Deleted", { type: "success" });
          setTimeout(() => {
            window.location.href = `/publisher/publications/options/${userId}`;
          }, 300);
          setProcessing(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  const fetchPage = () => {
    const url = API_BASE + "/api/book/custom/single/" + pageId;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setName(response.data.page.name);
          setPrice(response.data.page.price);
          if (response.data.page.cover_photo) {
            setCoverFileSelected(
              BASE_URL +
                "/storage" +
                response.data.page.cover_photo.split("public")[1]
            );
            setCoverPhoto("");
          }
          if (response.data.page.productimages) {
            const slides = response.data.page.productimages;
            const slideObj = slides.map((s) => ({
              id: s.client_id,
              file: s.product_thumb,
              slide_id: s.id,
            }));
            setProductSlides(slideObj);
          }
          const contentState = convertFromRaw(
            JSON.parse(response.data.page.data)
          );
          setEditorState(EditorState.createWithContent(contentState));
          setPage(response.data.page);
        }
      })
      .catch((error) => {
        if (error.response.data.invalid_ad_code) {
          toast("Invalid Ad Code", { type: "error" });
        } else {
          toast("Something went wrong", { type: "error" });
        }
      });
  };

  const deleteAccordionMediaServer = (parentIndex, accordionSectionKey) => {
    setProcessing(true);
    const url = API_BASE + "/custom-page/accordion/files/existing/delete";
    let formData = new FormData();
    formData.append("accordion_id", accordionId);
    formData.append("accordion_section_id", accordionSectionKey);
    formData.append("book_id", pageId);

    authRequestService()
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        const cols = [...accordions];
        cols[parentIndex].media = [];
        console.log("delete accordion media", cols);
        setAccordions(cols);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const saveAccordionImages = (
    items,
    accordionId,
    parentIndex,
    accordionSectionKey
  ) => {
    setProcessing(true);
    const url = API_BASE + "/custom-page/accordion/files/existing/save";
    let formData = new FormData();
    formData.append("session_id", sessionId);
    formData.append("accordion_id", accordionId);
    formData.append("accordion_section_id", accordionSectionKey);
    formData.append("book_id", pageId);
    for (let i = 0; i < items.length; i++) {
      formData.append("accordion_slides[]", items[i].file);
    }

    authRequestService()
      .post(url, formData)
      .then((response) => {
        const ids = response.data.slides.map((slide) => ({
          id: slide.id,
          url: slide.url,
        }));
        setProcessing(false);
        const cols = [...accordions];
        cols[parentIndex].media.push({
          type: "upload",
          assets: ids,
        });
        setAccordions(cols);
      })
      .catch((error) => {
        setProcessing(false);
        if ("larger_than_data_tier" in error.response.data) {
          let data = error.response.data["larger_than_data_tier"];
          setTierModalNotice(data.message_one);
          setTierNoticeModalOpen(true);
          return;
        }
        if ("will_break_my_tier_limit" in error.response.data) {
          let data = error.response.data["will_break_my_tier_limit"];
          setTierModalNotice(data.message_two);
          setTierNoticeModalOpen(true);
          return;
        }
        if ("larger_than_max_upload_limit" in error.response.data) {
          toast("You cannot file(s) larger than 20 MB", {
            type: "error",
          });
          return;
        }
        toast("Something went wrong", { type: "error" });
      });
  };

  const updatePage = () => {
    let formData = new FormData();
    if (Number(price) < 0.5) {
      toast(
        "Sorry, price must be $0.50 or greater as our payment providers take a percentage of the payment",
        { type: "notice" }
      );
      return false;
    }
    formData.append("name", name);
    formData.append("price", price);
    formData.append("user_id", userId);
    formData.append("cover_photo", pageCoverPhoto);
    formData.append(
      "data",
      JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    );
    formData.append("html_data", getPaidContent());
    formData.append("html_adfree_data", getAdFreeContent());
    formData.append("page_id", pageId);
    const url = API_BASE + "/book/custom/update";
    setProcessing(true);
    authRequestService()
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          fetchPage();
          toast("Updated", { type: "success" });
          setProcessing(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
        if ("larger_than_data_tier" in error.response.data) {
          let data = error.response.data["larger_than_data_tier"];
          setTierModalNotice(data.message_one);
          setTierNoticeModalOpen(true);
          return;
        }
        if ("will_break_my_tier_limit" in error.response.data) {
          let data = error.response.data["will_break_my_tier_limit"];
          setTierModalNotice(data.message_two);
          setTierNoticeModalOpen(true);
          return;
        }
        if (error.response.status == 422) {
          if ("duplicate" in error.response.data) {
            toast("A content item already exists with that name", {
              type: "error",
            });

            return false;
          } else {
            let errorValues = Object.values(error.response.data.errors).flat();
            for (let index = 0; index < errorValues.length; index++) {
              const element = errorValues[index];
              toast(element, {
                type: "error",
              });
            }
            return false;
          }
        }
        if (error.response.data.invalid_ad_code) {
          toast("Invalid Ad Code", { type: "error" });
          return false;
        }
        toast("Something went wrong", { type: "error" });
      });
  };

  const saveTagToDB = (tagsToSave) => {
    const url = API_BASE + "/api/book/update/tags";
    let formData = new FormData();
    formData.append("tags", JSON.stringify(tagsToSave));
    formData.append("uuid", page.uuid);
    formData.append("action", "new");
    axios
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          toast("Added", { type: "success" });
          fetchPage();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const removeTagFromDB = (tagId) => {
    const url = API_BASE + "/api/book/update/tags";
    let formData = new FormData();
    formData.append("tag_id", tagId);
    formData.append("uuid", page.uuid);
    formData.append("action", "delete");
    axios
      .post(url, formData)
      .then((response) => {
        if (response.status == 200) {
          toast("Deleted", { type: "success" });
          fetchPage();
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  //product slides
  const addProductSlides = (files) => {
    const url = API_BASE + "/api/book/update/product/slide";
    const f = [...files];
    let newSizes = 0;

    for (let i = 0; i < files.length; i++) {
      const fileSizeMBNew = files[i].size / (1024 * 1024) / 1024;
      newSizes += fileSizeMBNew;
    }

    if (newSizes > 0.025) {
      toast("Sorry, Maximum upload limit of 25 MB exceeded.", {
        type: "error",
      });
      return false;
    }
    setUploadingSlides(true);
    const newSlides = f.map((f) => ({ id: uuid(), file: f }));
    let formData = new FormData();
    formData.append("uuid", page.uuid);
    formData.append("action", "new");
    for (let i = 0; i < newSlides.length; i++) {
      formData.append("product_slides[]", newSlides[i].file);
      formData.append("product_slide_ids[]", newSlides[i].id);
    }
    axios
      .post(url, formData)
      .then((response) => {
        setUploadingSlides(false);
        if (response.data.product_images) {
          const slides = response.data.product_images;
          const slideObj = slides.map((s) => ({
            id: s.client_id,
            file: s.product_thumb,
            slide_id: s.id,
          }));
          setProductSlides(slideObj);
          toast("Added", { type: "success" });
          //fetchPage();
        }
      })
      .catch((error) => {
        setUploadingSlides(false);
        if ("supplier_account_enabled" in error.response.data) {
          toast("Your Supplier Account is currently under restriction.", {
            type: "error",
          });
          return;
        }
        if ("larger_than_data_tier" in error.response.data) {
          let data = error.response.data["larger_than_data_tier"];
          setTierModalNotice(data.message_one);
          setTierNoticeModalOpen(true);
          return;
        }
        if ("will_break_my_tier_limit" in error.response.data) {
          let data = error.response.data["will_break_my_tier_limit"];
          setTierModalNotice(data.message_two);
          setTierNoticeModalOpen(true);
          return;
        }

        if ("larger_than_max_upload_limit" in error.response.data) {
          toast("You cannot upload a file(s) larger than 25 MB", {
            type: "error",
          });
          return;
        }
        toast("Something went wrong", { type: "error" });
      });
  };

  const removeProductSlide = (Id) => {
    const url = API_BASE + "/api/book/update/product/slide";
    let formData = new FormData();
    formData.append("slide_id", Id);
    formData.append("uuid", page.uuid);
    formData.append("action", "delete");
    setDeletingSlides(true);
    axios
      .post(url, formData)
      .then((response) => {
        setDeletingSlides(false);
        if (response.status == 200) {
          if (response.data.product_images) {
            const slides = response.data.product_images;
            const slideObj = slides.map((s) => ({
              id: s.client_id,
              file: s.product_thumb,
              slide_id: s.id,
            }));
            setProductSlides(slideObj);
            toast("Deleted", { type: "success" });
          }
        }
      })
      .catch((error) => {
        setDeletingSlides(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getPublisher = () => {
    let url = API_BASE + "/publisher/" + userId + "/optimized";
    return authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setPublisher(response.data.publisher);
          if (isDataSubExpired(response.data.publisher)) {
            setIsDataSubExpired(true);
          }
          if (response.data?.publisher?.auth_tokens) {
            setAuthTokens(response.data?.publisher?.auth_tokens);
          }
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const addCustomPageAdvert = (clickOrigin) => {
    const url = API_BASE + "/api/publisher/check-ad-request-status";
    axios
      .post(url, {
        user_id: userId,
        click_origin: clickOrigin,
        book_type: "custom",
      })
      .then((response) => {
        if (response.status == 200) {
          if (
            response.data.status == "approved" ||
            response.data.status == "unprocessed"
          ) {
            setAvertRequestStatus("approved");
            insertAdvertIntoEditor(
              "xxx",
              "yyy",
              editorState,
              AtomicBlockUtils,
              EditorState,
              setEditorState
            );
            setShowAdModal(false);
          }

          if (response.data.status == "rejected") {
            setAvertRequestStatus("rejected");
            setShowAdModal(true);
          }

          if (!response.data.status) {
            setShowAdModal(true);
          }
          // setEditorState(insertAdvertIntoEditor(currentEditingAdId));
          // setcurrentEditingAdId("");
          // hideAdModal();
        }
      })
      .catch((error) => {
        if (error.response.data.invalid_ad_code) {
          toast("Invalid Ad Code", { type: "error" });
        } else {
          toast("Something went wrong", { type: "error" });
        }
      });
  };

  const getAdFreeContent = () => {
    let firstSanitization = stateToHTML(
      editorState.getCurrentContent(),
      htmlRenderOptionsFree(picker, editorState)
    ).replaceAll("<p><br></p>", "");

    // let secondSanitazation = firstSanitization
    //   .replaceAll()
    //   .replaceAll("<figure>&nbsp;</figure>", "");
    return firstSanitization;
  };

  const getPaidContent = () => {
    let firstSanitization = stateToHTML(
      editorState.getCurrentContent(),
      htmlRenderOptions(picker, editorState)
    ).replaceAll("<p><br></p>", "");
    return firstSanitization;
  };

  function mediaBlockRenderer(block, data) {
    if (block.getType() === "atomic") {
      return {
        component: Media,
        editable: true,
        data: "123",
      };
    }

    return null;
  }

  //handleHireTextChange
  const addCustomPageHire = () => {
    if (!createHireMode) {
      const contentState = editorState.getCurrentContent();
      let rawContent = convertToRaw(contentState);
      for (let i = 0; i < rawContent.blocks.length; i++) {
        if (true) {
          //rawContent.blocks[i]["key"] == currHireEditBlockKey
          let b = rawContent.blocks[i];
          if (b["type"] !== "unstyled" && b.entityRanges.length === 1) {
            const entityKey = b["entityRanges"][0]["key"];
            const entityMap = rawContent["entityMap"][entityKey];
            if (entityMap["type"] === "hire_button") {
              rawContent["entityMap"][entityKey]["data"]["hireText"] =
                currHireText;
              rawContent["entityMap"][entityKey]["data"]["replyEnabled"] =
                currHireResponseStatus ? 1 : 0;
              rawContent["entityMap"][entityKey]["data"]["hireReplySubject"] =
                currHireResponseStatus ? currHireReplySubject : "";
              rawContent["entityMap"][entityKey]["data"]["hirePrice"] =
                currHirePrice;
            }
          }
        }
      }

      const newContentState = convertFromRaw(rawContent);
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "update-contentState"
      );
      setEditorState(newEditorState);
    } else {
      insertHireIntoEditor(
        currHireText,
        currHireReplySubject,
        currHireResponseStatus,
        editorState,
        AtomicBlockUtils,
        EditorState,
        setEditorState
      );
    }
    //  setCurrHireText();
    //  setCurrHireResponseStatus(false);
    hideHireModalBox();
  };

  //add banner to editor
  const addBannerToEditor = () => {
    const payload = bannerSlides;
    insertBannerIntoEditor(
      JSON.stringify(payload),
      editorState,
      AtomicBlockUtils,
      EditorState,
      setEditorState
    );
    setBannerSlides([]);
    closeBannerModal();
  };

  const updateBannerInEditor = () => {
    const payload = bannerSlides;
    if (!createBannerMode) {
      const contentState = editorState.getCurrentContent();
      let rawContent = convertToRaw(contentState);
      for (let i = 0; i < rawContent.blocks.length; i++) {
        if (rawContent.blocks[i]["key"] == currEditingBannerId) {
          let b = rawContent.blocks[i];
          if (b["type"] !== "unstyled" && b.entityRanges.length === 1) {
            const entityKey = b["entityRanges"][0]["key"];
            const entityMap = rawContent["entityMap"][entityKey];
            if (entityMap["type"] === "banners") {
              rawContent["entityMap"][entityKey]["data"]["payload"] =
                JSON.stringify(payload);
            }
          }
        }
      }

      const newContentState = convertFromRaw(rawContent);
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "update-contentState"
      );
      setEditorState(newEditorState);
      setBannerSlides([]);
      closeBannerModal();
    }
  };

  const addCustomPageImage = (file) => {
    let url = "";
    if (createImageUploadMode) {
      url = API_BASE + "/api/custom-page/files/save";
    } else {
      url = API_BASE + "/api/custom-page/files/update/existing";
    }
    setProcessing(true);
    let imageLink = currPastedImageLink
      ? currPastedImageLink.indexOf("://") === -1
        ? "http://" + currPastedImageLink
        : currPastedImageLink
      : "";
    let formData = new FormData();
    formData.append("user_id", userId);
    if (imageFileData) {
      formData.append("file", imageFileData);
    }
    formData.append("book_id", pageId);
    formData.append("type", "image");
    formData.append("link", imageLink);
    formData.append("session_id", sessionId);
    formData.append("mode", createImageUploadMode ? "create" : "update");
    formData.append(
      "curr_editing_image",
      currEditingImage.split("custom-pages/")[1]
    );
    if (pageId) {
      formData.append("action", "update");
    } else {
      formData.append("action", "new");
    }
    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          let url =
            BASE_URL + "/storage" + response.data.file.file.split("public")[1];
          let link = response.data.file.link;
          let imageId = response.data.file.uuid;

          if (!createImageUploadMode) {
            const contentState = editorState.getCurrentContent();
            let rawContent = convertToRaw(contentState);
            for (let i = 0; i < rawContent.blocks.length; i++) {
              if (rawContent.blocks[i]["key"] == currImageEditBlockKey) {
                let b = rawContent.blocks[i];
                if (b["type"] !== "unstyled" && b.entityRanges.length === 1) {
                  const entityKey = b["entityRanges"][0]["key"];
                  const entityMap = rawContent["entityMap"][entityKey];
                  if (entityMap["type"] === "image") {
                    rawContent["entityMap"][entityKey]["data"]["src"] = url;
                    rawContent["entityMap"][entityKey]["data"]["imageLink"] =
                      link;
                  }
                }
              }
            }

            const newContentState = convertFromRaw(rawContent);
            const newEditorState = EditorState.push(
              editorState,
              newContentState,
              "update-contentState"
            );
            setEditorState(newEditorState);
          } else {
            setEditorState(
              insertImageIntoEditor(
                url,
                imageLink,
                imageId,
                editorState,
                AtomicBlockUtils,
                EditorState
              )
            );
          }
          hideImageModalBox();
          resetImageLinkPreview();
          resetImagePreview();
          // updatePage();
        }
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.status !== 422) {
          toast("Something went wrong!", { type: "error" });
        }
        processDataThresholdError(error);
      });
  };

  const addSocialImageLinksToEditor = () => {
    const payload = {};
    payload.type = socialUploadType;
    payload.sub_type = socialUploadSubType;
    payload.data = JSON.stringify(selectedSocialPhoto);
    insertSocialImageLinksIntoEditor(
      JSON.stringify(payload),
      editorState,
      AtomicBlockUtils,
      EditorState,
      setEditorState
    );
    setSelectedSocialPhoto([]);
    setSocialUploadSubType("");
    setSocialUploadType("");
    hideImageModalBox();
  };

  const addSocialEmbedToEditor = () => {
    const payload = {};
    payload.type = socialUploadType;
    payload.sub_type = socialUploadSubType;
    payload.data = socialEmbedValue;
    insertSocialEmbedIntoEditor(
      JSON.stringify(payload),
      editorState,
      AtomicBlockUtils,
      EditorState,
      setEditorState
    );
    setSocialEmbedValue();
    setSocialUploadSubType("");
    setSocialUploadType("");
    hideImageModalBox();
  };

  const addTableToEditor = () => {
    const payload = {};
    payload.name = tableTitle;
    payload.data = tableColumns;

    insertTableIntoEditor(
      JSON.stringify(payload),
      editorState,
      AtomicBlockUtils,
      EditorState,
      setEditorState
    );
    setTableColumns([initialTableColumn]);
    setTableTitle("");
    hideTableModalBox();
  };

  const updateTableInEditor = () => {
    if (!createTableMode) {
      const contentState = editorState.getCurrentContent();
      let rawContent = convertToRaw(contentState);
      for (let i = 0; i < rawContent.blocks.length; i++) {
        if (rawContent.blocks[i]["key"] == currTableEditBlockKey) {
          let b = rawContent.blocks[i];
          if (b["type"] !== "unstyled" && b.entityRanges.length === 1) {
            const entityKey = b["entityRanges"][0]["key"];
            const entityMap = rawContent["entityMap"][entityKey];
            if (entityMap["type"] === "table") {
              const payload = {};
              payload.name = tableTitle;
              payload.data = tableColumns;
              console.log("inside ");
              rawContent["entityMap"][entityKey]["data"]["payload"] =
                JSON.stringify(payload);
            }
          }
        }
      }

      const newContentState = convertFromRaw(rawContent);
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "update-contentState"
      );
      setEditorState(newEditorState);
      setTableColumns([initialTableColumn]);
      setTableTitle("");
      hideTableModalBox();
    }
  };

  const addAccordionToEditor = () => {
    let payload = {};
    payload.name = accordionTitle;
    payload.data = accordions;
    payload.id = accordionId;

    insertAccordionIntoEditor(
      JSON.stringify(payload),
      editorState,
      AtomicBlockUtils,
      EditorState,
      setEditorState
    );
    hideAccordionModalCreateBox();
  };

  const updateAccordionInEditor = () => {
    if (!createAccordionMode) {
      const contentState = editorState.getCurrentContent();
      let rawContent = convertToRaw(contentState);
      for (let i = 0; i < rawContent.blocks.length; i++) {
        if (rawContent.blocks[i]["key"] == currAccordionEditBlockKey) {
          let b = rawContent.blocks[i];
          if (b["type"] !== "unstyled" && b.entityRanges.length === 1) {
            const entityKey = b["entityRanges"][0]["key"];
            const entityMap = rawContent["entityMap"][entityKey];
            if (entityMap["type"] === "accordion") {
              const payload = {};
              payload.name = accordionTitle;
              payload.data = accordions;
              console.log("inside ");
              rawContent["entityMap"][entityKey]["data"]["payload"] =
                JSON.stringify(payload);
            }
          }
        }
      }

      const newContentState = convertFromRaw(rawContent);
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "update-contentState"
      );
      setEditorState(newEditorState);
      hideAccordionModalCreateBox();
    }
  };

  const addCustomPageVideo = (file) => {
    const url = API_BASE + "/api/custom-page/files/save";
    setProcessing(true);
    let formData = new FormData();
    formData.append("user_id", userId);
    formData.append("book_id", pageId);
    formData.append("type", "video");
    formData.append("link", currPastedVideoLink);
    formData.append("session_id", sessionId);
    if (pageId) {
      formData.append("action", "update");
    } else {
      formData.append("action", "new");
    }

    const linkMode = currPastedVideoLink ? "link" : "mp4";

    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          let mp4link = currPastedVideoLink;
          const videoId = response.data.file.uuid;
          setEditorState(
            insertVideoIntoEditor(
              mp4link,
              linkMode,
              editorState,
              AtomicBlockUtils,
              EditorState,
              videoId
            )
          );
          hideVideoModalBox();
        }
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.status !== 422) {
          toast("Something went wrong!", { type: "error" });
        }
        processDataThresholdError(error);
      });
  };

  const updatePaymentControlsSetting = () => {
    const url = API_BASE + "/api/book/update/payment-controls";
    setProcessing(true);
    let formData = new FormData();
    formData.append("user_id", userId);
    formData.append("book_id", pageId);

    if (paymentType.includes("both")) {
      formData.append("enable_custom_page_purchase", 1);
      formData.append("enable_custom_page_subscription", 1);
    } else {
      formData.append(
        "enable_custom_page_purchase",
        paymentType.includes("purchase") ? 1 : 0
      );

      formData.append(
        "enable_custom_page_subscription",
        paymentType.includes("subscription") ? 1 : 0
      );
    }

    axios
      .post(url, formData)
      .then((response) => {
        setProcessing(false);
        fetchPage();
        hidePaymentControlsBox();
        toast("Updated!", { type: "success" });
      })
      .catch((error) => {
        setProcessing(false);

        toast("Something went wrong!", { type: "error" });
      });
  };

  const uploadCover = (e) => {
    let [file] = e.target.files;
    if (file) {
      setFileSelected(URL.createObjectURL(file));
      previewImageRef.src = URL.createObjectURL(file);
      setCoverPhoto(file);
      // addCustomPageFile(file);
      const image = getBase64(file);
      image.then((res) => {});
    }
  };

  const uploadVideo = (e) => {};

  const handleCoverPhotoChange = (value) => {
    setFileSelected("");
    setPageCoverPhoto(value);
  };

  const PaymentControls = (props) => {
    return (
      <button
        className="border border-gray rounded-lg p-1 m-1"
        onClick={() => showPaymentControlsBox()}
      >
        Payment Options{" "}
      </button>
    );
  };

  const InsertAdvert = (props) => {
    return (
      <button
        className="border border-gray rounded-lg p-1 m-1"
        onClick={() => showAdBox()}
      >
        Insert Ad
      </button>
    );
  };

  const onInlineClick = (e) => {
    let nextState = RichUtils.toggleInlineStyle(editorState, e);
    setEditorState(nextState);
  };

  const onBlockClick = (e) => {
    let nextState = RichUtils.toggleBlockType(editorState, e);
    setEditorState(nextState);
  };

  const getEntities = (
    block,
    entityType = null,
    charStart = null,
    charEnd = null
  ) => {
    const content = editorState.getCurrentContent();
    const entities = [];
    let selectedEntity = null;
    if (block.getEntityAt(charStart)) {
      const entityKey = block.getEntityAt(charStart);
      const entity = content.getEntity(entityKey);
      if (!entityType || (entityType && entity.getType() === entityType)) {
        selectedEntity = {
          entityKey: entityKey,
          blockKey: block.getKey(),
          entity: content.getEntity(entityKey),
        };
        entities.push(selectedEntity);
      }
      setCurrentSelectedLink(entity.getData());
    } else {
      setEditorOutput("no");
    }

    return entities;
  };

  const handleEditorChange = (st) => {
    const selectionKey = st.getSelection().getAnchorKey();
    const block = st.getCurrentContent().getBlockForKey(selectionKey);
    let start = st.getSelection().getStartOffset();
    let end = st.getSelection().getEndOffset();
    let selectedText = block.getText().slice(start, end);
    const linkKey = block.getEntityAt(start);
    const currentBlockType = RichUtils.getCurrentBlockType(editorState);

    if (addedSpaceToLinkItem) {
      setAddedSpaceToLinkItem(false);
    }

    if (getEntities(block, "LINK", start, end).length) {
      setSelectionHasLink(true);
      setSelectionAnchors((prev) => {
        return { ...prev, start: start, end: end };
      });
    } else {
      setSelectionHasLink(false);
      setCurrentSelectedLink({});
      setSelectionAnchors({});
    }

    const contentState = st.getCurrentContent();
    let rawContent = convertToRaw(contentState);
    for (let i = 0; i < rawContent.blocks.length; i++) {
      if (createHireMode && (currHireText || currHireReplySubject)) {
        //rawContent.blocks[i]["key"] == currHireEditBlockKey
        let b = rawContent.blocks[i];
        if (b["type"] !== "unstyled" && b.entityRanges.length === 1) {
          const entityKey = b["entityRanges"][0]["key"];
          const entityMap = rawContent["entityMap"][entityKey];
          if (entityMap["type"] === "hire_button") {
            rawContent["entityMap"][entityKey]["data"]["hireText"] =
              currHireText;
            rawContent["entityMap"][entityKey]["data"]["replyEnabled"] =
              currHireResponseStatus ? 1 : 0;
            rawContent["entityMap"][entityKey]["data"]["hireReplySubject"] =
              currHireResponseStatus ? currHireReplySubject : "";
            rawContent["entityMap"][entityKey]["data"]["hirePrice"] =
              currHirePrice;
          }
        }
      }
    }
    setEditorState(st);
  };

  const Media = (props) => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const { src } = entity.getData();
    const { linkMode } = entity.getData();

    const { buttonLinkName } = entity.getData();
    const { videoId } = entity.getData();
    const type = entity.getType();
    let media;

    if (type === "signup_for_updates") {
      media = (
        <SignupForUpdatesBox
          blockKey={props.block.getKey()}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
        />
      );
    }

    if (type === "button_link") {
      media = (
        <ButtonLinkBox
          name={buttonLinkName}
          blockKey={props.block.getKey()}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
        />
      );
    }

    if (type === "table") {
      const { payload } = entity.getData();
      media = (
        <Table
          blockKey={props.block.getKey()}
          payload={payload}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
          showTableUpdateBox={showTableUpdateBox}
        />
      );
    }

    if (type === "accordion") {
      const { payload } = entity.getData();
      media = (
        <Accordion
          blockKey={props.block.getKey()}
          payload={payload}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
          showAccordionUpdateBox={showAccordionUpdateBox}
        />
      );
    }

    if (type === "image") {
      const { imageLink } = entity.getData();
      const { imageId } = entity.getData();
      media = (
        <Image
          pageId={pageId}
          blockKey={props.block.getKey()}
          src={src}
          imageLink={imageLink}
          imageId={imageId}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
          showImageModalUpdateBox={showImageModalUpdateBox}
        />
      );
    }

    if (type === "hire_button") {
      const { hireText } = entity.getData();
      const { replyEnabled } = entity.getData();
      const { hireReplySubject } = entity.getData();
      const { hirePrice } = entity.getData();

      media = (
        <Hire
          pageId={pageId}
          blockKey={props.block.getKey()}
          hireText={hireText}
          hireReplySubject={hireReplySubject}
          replyEnabled={replyEnabled}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
          showHireModalUpdateBox={showHireModalUpdateBox}
          hirePrice={hirePrice}
        />
      );
    }

    if (type === "social_link") {
      const { payload } = entity.getData();
      media = (
        <SocialLinksEmbed
          blockKey={props.block.getKey()}
          payload={payload}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
        />
      );
    }

    if (type === "social_embed") {
      const { payload } = entity.getData();
      media = (
        <SocialImageEmbed
          blockKey={props.block.getKey()}
          payload={payload}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
        />
      );
    }

    if (type === "video") {
      media = (
        <Video
          blockKey={props.block.getKey()}
          src={src}
          linkMode={linkMode}
          videoIdentifier={videoId}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
        />
      );
    }

    if (type === "banners") {
      const { payload } = entity.getData();
      media = (
        <Banners
          blockKey={props.block.getKey()}
          payload={payload}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
          showUpdateBannerBox={showUpdateBannerBox}
        />
      );
    }

    if (type === "advert") {
      const { systemAdCode } = entity.getData();
      const { adCode } = entity.getData();
      media = (
        <Advert
          blockKey={props.block.getKey()}
          adCode={adCode}
          systemAdCode={systemAdCode}
          editorState={editorState}
          setEditorState={setEditorState}
          editorStateHelper={EditorState}
        />
      );
    }

    return media;
  };

  function mediaBlockRenderer(block, data) {
    if (block.getType() === "atomic") {
      return {
        component: Media,
        editable: true,
        data: "123",
      };
    }

    return null;
  }

  const handleSelectedFileChange = (file) => {
    setCoverFileSelected(file);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFocus = () => {
    editorFocusRef.focus = true;
  };

  const handleBlur = () => {
    editorFocusRef.focus = false;
  };

  const showAdBox = (clickOrigin) => {
    setAvertRequestStatus("");
    addCustomPageAdvert(clickOrigin);
  };

  const hidePaymentControlsBox = () => {
    setShowPaymentControlsModal(false);
  };

  const showPaymentControlsBox = () => {
    setShowPaymentControlsModal(true);
  };

  /*banner slides*/
  const handleBannerTextChange = (e, type, slideId) => {
    const banns = [...bannerSlides];
    const slideIdById = banns.findIndex((s) => s.id == slideId);
    if (slideIdById !== -1) {
      if (type == "text") {
        banns[slideIdById].text = e;
      }
      setBannerSlides(banns);
    }
  };

  const handleBannerUrlChange = (e, type, slideId) => {
    const banns = [...bannerSlides];
    const slideIdById = banns.findIndex((s) => s.id == slideId);
    if (slideIdById !== -1) {
      if (type == "url") {
        banns[slideIdById].url = e;
      }
      setBannerSlides(banns);
    }
  };

  const bannerSlideSelected = (slideId) => {
    if (!bannerSlides.length) return false;
    return bannerSlides.filter((b) => b.id == slideId).length;
  };

  const getSelBannerSlideInfo = (slideId) => {
    if (!bannerSlides.length) return " ";
    const banns = bannerSlides.findIndex((s) => s.id == slideId);
    if (banns !== -1) return banns;
    return " ";
  };

  const handleCheckBannerSlide = (slideId) => {
    const banns = [...bannerSlides];
    const slideIdById = banns.findIndex((s) => s.id == slideId);
    if (slideIdById == -1) {
      setBannerSlides((prev) => [...prev, { id: slideId, text: "", url: "" }]);
    } else {
      const newBanns = banns.filter((slide) => slide.id !== slideId);
      setBannerSlides(newBanns);
    }
  };

  const displayBannerBox = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setBannerModalOpen(true);
  };

  const showUpdateBannerBox = (blockKey, payload) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const slides = JSON.parse(payload);
    const slideIds = slides.map((s) => s.id);
    const existingBanns = productSlides
      .filter((obj) => slideIds.includes(obj.id))
      .map((slide) => slide.id);

    const newPayload = slides.filter((obj) => existingBanns.includes(obj.id));
    console.log("edit banns", newPayload);
    setBannerSlides(newPayload);
    setCreateBannerMode(false);
    setCurrEditingBannerId(blockKey);
    setBannerModalOpen(true);
  };

  const closeBannerModal = () => {
    setCurrEditingBannerId("");
    setBannerModalOpen(false);
    setCreateBannerMode(true);
    setBannerSlides([]);
  };

  const hideAdModal = () => {
    setShowAdModal(false);
  };

  const handleAdIdChange = (e) => {
    setcurrentEditingAdId(e);
  };

  const showLinkBox = () => {
    setShowLinkModal(true);
  };

  const hideLinkModal = () => {
    setShowLinkModal(false);
  };

  const handleLinkChange = (e) => {
    setCurrPastedLink(e);
  };
  //hire
  const handleHireTextChange = (e) => {
    setCurrHireText(e);
  };

  const handleHirePriceChange = (e) => {
    setCurrHirePrice(e);
  };

  const handleHireReplySubjectChange = (e) => {
    setCurrHireReplySubject(e);
  };

  const handleHireResponseStatus = () => {
    setCurrHireResponseStatus((prev) => !prev);
  };

  // Video Section
  const showVideoModalBox = () => {
    if (isResponsiveMode()) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setShowVideoModal(true);
  };

  const hideVideoModalBox = () => {
    setShowVideoModal(false);
    resetVideoPreview();
    resetVideoLinkPreview();
    hideImageModalBox();
    setSocialUploadType("");
  };

  const handleVideoLinkChange = (e) => {
    setCurrPastedVideoLink(e);
  };

  const resetVideoPreview = () => {
    setVideoFileSelected("");
    setVideoFileData();
  };
  const resetVideoLinkPreview = () => {
    setCurrPastedVideoLink("");
  };

  const handleVideoPreview = (e) => {
    let [file] = e.target.files;
    if (file) {
      if (!file["type"].includes("mp4")) {
        toast("Invalid video file format, supported format MP4", {
          type: "error",
        });
        return false;
      }
      setVideoFileSelected(URL.createObjectURL(file));
      setVideoFileData(file);
    }
  };

  //accordion"
  const showAccordionModalCreateBox = () => {
    setAccordionId(uuid());
    setShowAccordionModal(true);
    setAccordionTitle("");
    setAccordions([generateAccordoinTemplate()]);
    setCurrAccordionEditBlockKey("");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const hideAccordionModalCreateBox = () => {
    setAccordionId("");
    setShowAccordionModal(false);
    setAccordionTitle("");
    setAccordions([]);
    setCurrAccordionEditBlockKey("");
    setCreateAccordionMode(true);
  };

  const generateAccordoinTemplate = () => {
    const initAcc = { ...initialAccordion };
    initAcc.id = uuid();
    return initAcc;
  };

  const addAccordionSection = () => {
    const cols = [...accordions];
    cols.push(generateAccordoinTemplate());
    setAccordions(cols);
  };
  const deleteAccordionSection = (parentIndex) => {
    const cols = [...accordions];
    const colsOldCols = cols.filter((r, i) => i !== parentIndex);
    setAccordions(colsOldCols);
  };
  const handleAccordionHeaderChange = (e, type, index) => {
    const cols = [...accordions];
    if (type == "header") {
      cols[index].header.value = e;
    }
    setAccordions(cols);
  };
  const handleAccordionBodyChange = (e, type, index) => {
    const cols = [...accordions];
    if (type == "body") {
      cols[index].body.value = e;
    }
    setAccordions(cols);
  };

  const showAccordionUpdateBox = (payload, blockKey) => {
    console.log("update payload", payload);
    setShowAccordionModal(true);
    setCreateAccordionMode(false);
    setAccordionTitle(payload.name);
    setAccordions(payload.data);
    setAccordionId(payload.id);
    setCurrAccordionEditBlockKey(blockKey);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleAccordionTitleChange = (e) => {
    setAccordionTitle(e);
  };

  const addYoutubeToAccordion = (link, parentIndex) => {
    const cols = [...accordions];
    cols[parentIndex].media.push({
      type: "youtube",
      assets: [],
      link: link,
    });
    setAccordions(cols);
  };

  const addSocialToAccordion = (type, link, parentIndex) => {
    const cols = [...accordions];
    cols[parentIndex].media.push({
      type: type,
      assets: [],
      link: link,
    });
    setAccordions(cols);
  };

  const deleteAccordionMediaEditor = (parentIndex) => {
    const cols = [...accordions];
    cols[parentIndex].media = [];
    setAccordions(cols);
  };

  //table
  const showTableModalCreateBox = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowTableModal(true);
  };

  const hideTableModalBox = () => {
    setTableColumns([]);
    setTableTitle("");
    setCurrTableEditBlockKey("");
    setCreateTableMode(true);
    setShowTableModal(false);
  };
  const addTableRow = (index) => {
    const cols = [...tableColumns];
    cols[index].rows.push({
      placeholder: "Fill Row: Type here...",
      value: "",
    });
    console.log("adding new row", cols);
    setTableColumns(cols);
  };

  const addTableColumn = () => {
    const cols = [...tableColumns];
    cols.push(initialTableColumn);
    setTableColumns(cols);
  };

  const deleteTableColumn = (parentIndex) => {
    const cols = [...tableColumns];
    const colsOldCols = cols.filter((r, i) => i !== parentIndex);
    setTableColumns(colsOldCols);
  };

  const deleteTableRow = (childIndex, parentIndex) => {
    const cols = [...tableColumns];
    const colsOldRows = cols[parentIndex].rows;
    const colsNewRows = colsOldRows.filter((r, i) => i !== childIndex);
    cols[parentIndex].rows = colsNewRows;
    // cols[parentIndex].rows = colsNewRows;
    setTableColumns(cols);
  };

  const handleTableHeaderChange = (e, type, index) => {
    const cols = [...tableColumns];
    if (type == "header") {
      cols[index].header.value = e;
    }
    setTableColumns(cols);
  };

  const handleTableRowChange = (e, type, index, parentIndex) => {
    const cols = [...tableColumns];
    if (type == "row") {
      cols[parentIndex].rows[index].value = e;
    }
    setTableColumns(cols);
  };

  const handleTableTitleChange = (e) => {
    setTableTitle(e);
  };

  const showTableUpdateBox = (payload, blockKey) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowTableModal(true);
    setCreateTableMode(false);
    setTableTitle(payload.name);
    setTableColumns(payload.data);
    setCurrTableEditBlockKey(blockKey);
    // console.log("TMS", payload);
  };
  //hire section
  useEffect(() => {
    if (currHireResponseStatus == false) {
      setCurrHireReplySubject("");
    }
  }, [currHireResponseStatus]);

  const showHireModalCreateBox = () => {
    setCreateHireMode(true);
    setCurrHireText(" ");
    setCurrHirePrice("");
    setCurrHireReplySubject("");
    setCurrHireResponseStatus(false);
    setShowHireModal(true);

    window.scrollTo(0, 0);
  };

  const hideHireModalBox = () => {
    setShowHireModal(false);
  };

  const showHireModalUpdateBox = (
    hireText,
    hirePrice,
    hireReplySubject,
    replyEnabled,
    blockKey
  ) => {
    setCurrHireEditBlockKey(blockKey);
    setCreateHireMode(false);
    setCurrHireText(hireText);
    setCurrHirePrice(hirePrice);
    setCurrHireReplySubject(hireReplySubject);
    setCurrHireResponseStatus(replyEnabled);
    setShowHireModal(true);
  };

  // Image Section
  const showImageModalUpdateBox = (imageSource, imageLink, blockKey) => {
    setShowImageModal(true);
    setCreateImageUploadMode(false);
    if (imageSource) {
      setCurrEditingImage(imageSource);
      setCurrEditingImagePreview(imageSource);
    }
    if (imageLink) {
      setCurrPastedImageLink(imageLink);
    }

    setCurrImageEditBlockKey(blockKey);
  };

  const showImageModalCreateBox = () => {
    setShowImageModal(true);
    setCreateImageUploadMode(true);
  };

  const hideImageModalBox = () => {
    setShowImageModal(false);
    resetImagePreview();
    resetImageLinkPreview();
    setCurrEditingImage("");
    setSocialUploadType("");
  };

  const handleImageLinkChange = (e) => {
    setCurrPastedImageLink(e);
  };

  const resetImagePreview = () => {
    setImageFileSelected("");
    setCurrEditingImagePreview("");
    setImageFileData();
  };
  const resetImageLinkPreview = () => {
    setCurrPastedImageLink("");
  };

  const handleImagePreview = (e) => {
    let [file] = e.target.files;
    console.log(file);
    if (file) {
      if (!true) {
        toast("Invalid video file format, supported format MP4", {
          type: "error",
        });
        return false;
      }
      setImageFileSelected(URL.createObjectURL(file));
      setImageFileData(file);
    }
  };

  // button link
  const showButtonLinkModalBox = () => {
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth",
    // });
    setShowButtonLinkModal(true);
  };

  const hideButtonLinkModalBox = () => {
    setShowButtonLinkModal(false);
  };

  const addButtonLink = () => {
    insertButtonLink(
      buttonLinkName,
      buttonLink,
      editorState,
      AtomicBlockUtils,
      EditorState,
      setEditorState
    );
    hideButtonLinkModalBox();
    setButtonLink("");
    setButtonLinkName("");
  };
  const handleButtonLinkChange = (e) => {
    setButtonLink(e);
  };
  const handleButtonLinkNameChange = (e) => {
    setButtonLinkName(e);
  };

  const handlePurchaseButtonChange = () => {
    if (bothPaymentsControlsEnabled) {
      setPurchasedButtonEnabled((prev) => !prev);
    }
  };
  const handleSubscriptionButtonChange = () => {
    if (bothPaymentsControlsEnabled) {
      setSubscriptionButtonEnabled((prev) => !prev);
    }
  };
  const handleBothPaymentControlsChange = () => {
    setBothPaymentControlsEnabled((prev) => !prev);
  };

  const handleNameChange = (name) => {
    setName(name);
  };

  const handlePriceChange = (price) => {
    setPrice(price);
  };

  const hideTierNoticeModal = () => {
    setTierNoticeModalOpen(false);
  };

  const handleTagChange = (tag) => {
    setTag(tag);
  };

  //Advert Section

  const getBlockStyle = (block) => {
    switch (block.getType()) {
      case "blockquote":
        return "RichEditor-blockquote";
      case "left":
        return "align-left";
      case "center":
        return "align-center";
      case "right":
        return "align-right";
      default:
        return null;
    }
  };

  const CaretCoordinates = {
    x: 0,
    y: 0,
  };

  const handlePaymentTypeChange = (type) => {
    setPaymentType([]);
    if (!paymentType.includes(type)) {
      setPaymentType((prev) => [...prev, type]);
    }
  };

  const openDeletePromtModal = () => {
    setShowDeletePromptModal(true);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };

  const hideDeletePromtModal = () => {
    setShowDeletePromptModal(false);
  };

  useEffect(() => {
    console.log("accordions", accordions);
  }, [accordions]);

  useEffect(() => {
    if (!isPublisher() || JSON.parse(isAuthUser()).uuid !== userId) {
      window.location.href = "/";
    }
    fetchPage();
    getPublisher();
    if (isResponsiveMode()) {
      import("../../../../src/custom-page-mobile.css");
    } else {
      import("../../../../src/custom-page-desktop.css");
    }
  }, []);

  useEffect(() => {
    if (bothPaymentsControlsEnabled) {
      setPurchasedButtonEnabled(true);
      setSubscriptionButtonEnabled(true);
    } else {
      setPurchasedButtonEnabled(false);
      setSubscriptionButtonEnabled(false);
    }
  }, [bothPaymentsControlsEnabled]);

  useEffect(() => {
    if (page.id) {
      if (
        page.enable_custom_page_purchase &&
        page.enable_custom_page_subscription &&
        !paymentType.includes("both")
      ) {
        setPaymentType((prev) => [...prev, "both"]);
      }
      if (
        page.enable_custom_page_subscription &&
        !page.enable_custom_page_purchase &&
        !paymentType.includes("subscription")
      ) {
        setPaymentType((prev) => [...prev, "subscription"]);
      }

      if (
        !page.enable_custom_page_subscription &&
        page.enable_custom_page_purchase &&
        !paymentType.includes("purchase")
      ) {
        setPaymentType((prev) => [...prev, "purchase"]);
      }
      let tagNames = [];
      if (page.tags) {
        page.tags.map((t) => tagNames.push(t));
        setTags(tagNames);
      }
    }
  }, [page]);

  const makeToolbarSticky = () => {
    let el = document.getElementById("my-sp-toolbar");
    let div_top = el?.offsetTop;
    let window_top =
      window.pageYOffset || document.documentElement.scrollTop - 0;
    if (window_top > div_top) {
      if (!el.classList.contains("sticky-style")) {
        el.classList.add("sticky-style");
        if (isResponsiveMode()) {
          el.classList.add("shrink-stuff");
        }
      }
    } else {
      el.classList.remove("sticky-style");
      if (isResponsiveMode()) {
        el.classList.remove("shrink-stuff");
      } else {
        el.classList.remove("sticky-style");
      }
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", makeToolbarSticky);
  }, []);

  const hasNoCoverPhoto = () => {
    if (!page.id) {
      return false;
    }
    if (!productSlides.length) {
      return false;
    }

    return true;
  };

  const addSpace = (e) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const txt = " ";
    isRemovingLinkFromList.added = true;
    setAddedSpaceToLinkItem(true);
    //Collapses a selection and sets focus to start of selection
    let collapsed = selection.merge({
      anchorOffset: selection.getStartOffset(),
      focusOffset: selection.getStartOffset(),
    });

    let newEditorState = EditorState.forceSelection(editorState, collapsed);
    if (collapsed.isCollapsed()) {
      // alert("is collapsed");
      const nextContentState = Modifier.insertText(
        contentState,
        collapsed,
        txt
      );
      const nextEditorState = EditorState.push(
        newEditorState,
        nextContentState,
        "insert-characters"
      );

      const content = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const block = content.getBlockForKey(startKey);

      const mySelection = SelectionState.createEmpty(block.getKey());
      const updatedSelection = mySelection.merge({
        anchorOffset: selectionAnchors.start,
        focusOffset: selectionAnchors.end + 1,
      });

      newEditorState = EditorState.forceSelection(
        nextEditorState,
        nextEditorState.getSelection()
      );

      const newContentState = Modifier.applyEntity(
        nextContentState,
        updatedSelection,
        null
      );

      setEditorState(
        EditorState.set(nextEditorState, { currentContent: newContentState })
      );
      e.preventDefault();
    }
  };

  const showCurrentLocation = () => {
    if (getLocation().hostname == "litzapp.com") {
      copyText("https://litzapp.com/share/sh/" + page.item_id);
    } else {
      copyText("https://wordalbums1.test/sh/" + page.item_id);
    }
  };

  const copyText = (text) => {
    // standard way of copying
    var textArea = document.createElement("textarea");
    textArea.setAttribute("style", "width:1px;border:0;opacity:0;");
    document.body.appendChild(textArea);
    textArea.value = text;
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    toast("Link Copied", { type: "success" });
  };

  function isEditorEmpty() {
    const contentState = editorState.getCurrentContent();
    return contentState.hasText() && contentState.getPlainText().length > 0;
  }

  const addTag = () => {
    if (tags.length == 10) {
      toast("Only a maximum of 10 tags allowed", { type: "error" });
      return false;
    }
    if (tags) {
      let currentTagNames = tags.map((t) => t.name);
      let tgs = tag.split(",");
      if (tgs.length > 1) {
        for (let index = 0; index < tgs.length; index++) {
          if (currentTagNames.includes(tgs[index])) {
            toast(tgs[index] + " already exists!", { type: "error" });
            return false;
          }
        }
        let trimEmptyTags = tgs.filter(
          (t) => t !== null && t !== " " && t !== ""
        );
        let trimTags = trimEmptyTags.map((t) => t.trim());
        if (trimTags.length + tags.length > 10) {
          toast("Only a maximum of 10 tags allowed", { type: "error" });
          return false;
        }
        saveTagToDB(trimTags);
        setTags((prev) => [...prev, ...trimTags]);
      } else {
        if (currentTagNames.includes(tag)) {
          toast(tag + " already exists!", { type: "error" });
          return false;
        }

        let trimTag = tag.trim();
        saveTagToDB([tag]);
        setTags((prev) => [...prev, trimTag]);
      }
    }
    setTag("");
  };

  const removeTag = (t) => {
    if (tags.length == 3) {
      toast(
        "There must be at least 3 tags. Before deleting any tags, make sure to first add the new tags that you want, ensuring that there are at least three tags in total.",
        { type: "error" }
      );
      return false;
    }

    let currentTags = tags;
    let filteredTags = currentTags.filter((x) => x.id !== t.id);
    setTags(filteredTags);
    removeTagFromDB(t.id);
  };

  const handleCheckSocialPhoto = (id) => {
    if (!selectedSocialPhoto.includes(id)) {
      setSelectedSocialPhoto((prev) => [...prev, id]);
    } else {
      const s = selectedSocialPhoto;
      const removeSlide = s.filter((s) => s !== id);
      setSelectedSocialPhoto(removeSlide);
    }
  };

  const clearCheckedSocialPhotos = () => {
    setSelectedSocialPhoto([]);
  };

  const handleSocialEmbdedCodeChange = (e) => {
    setSocialEmbedValue(e);
  };

  const handleSocialUploadTypeChange = (e) => {
    setSocialUploadType(e);
  };

  const handleSocialUploadSubTypeChange = (e) => {
    setSocialUploadSubType(e);
  };

  return (
    <>
      <div className="flex flex-col relative">
        <div className="bg-white mx-1 p-2 flex justify-around md:justify-between shadow items-center">
          <div className="md:w-full">
            <p className="text-2xl text-left font-bold">Update Page</p>
            <BugReportNotice />
            {/* <span className="text-base text-red-700 font-light">
            Report bugs to admin@litzapp.com
          </span> */}
          </div>

          {!isResponsiveMode() ? (
            <PublisherTutorialBar
              classes={
                "hidden w-full text-center md:flex md:justify-center md:items-center self-center"
              }
              btClass={"hidden md:flex"}
            />
          ) : (
            ""
          )}

          <div className="flex flex-col  md:flex-row md:justify-end items-center gap-4 hidden md:flex md:w-full">
            {page.id && !isDataSubExpired(publisher) ? (
              <BasicButton
                disabled={
                  processing || !name || !hasNoCoverPhoto() || !isEditorEmpty()
                }
                title={processing ? "Updating.." : "Update Page"}
                handleClick={() => {
                  if (!name || !hasNoCoverPhoto()) {
                    toast(
                      "Please add a title and cover photo before updating ",
                      {
                        type: "notice",
                      }
                    );
                  } else {
                    updatePage();
                  }
                }}
              />
            ) : (
              ""
            )}

            <div onClick={() => (isEditorEmpty() ? showCurrentLocation() : "")}>
              <i
                class={`fa fa-share-alt cursor-pointer ${
                  isEditorEmpty() ? "text-black" : "text-gray-500"
                }`}
              ></i>
              {/* {getLocation().href} */}
            </div>

            <BasicButton
              title={"Go Back"}
              classes="my-bg-yellow-500 text-black"
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex flex-col md:hidden">
          <div className="flex justify-center gap-4 p-2">
            {" "}
            {page.id && !isDataSubExpired(publisher) ? (
              <div className="w-full">
                <BasicButton
                  disabled={
                    processing ||
                    !name ||
                    !hasNoCoverPhoto() ||
                    !isEditorEmpty()
                  }
                  title={processing ? "Updating.." : "Update Page"}
                  classes="w-full"
                  handleClick={() => {
                    if (!name || !hasNoCoverPhoto()) {
                      toast(
                        "Please add a title and cover photo before updating ",
                        {
                          type: "notice",
                        }
                      );
                    } else {
                      updatePage();
                    }
                  }}
                />
              </div>
            ) : (
              ""
            )}
            <div className="w-full">
              <BasicButton
                title={"Go Back"}
                classes="my-bg-yellow-500 text-black w-full"
                handleClick={() => window.history.back()}
              />
            </div>
          </div>
          <div className="flex justify-center gap-4 p-2">
            {page.buyers &&
            !page.buyers.length &&
            !isDataSubExpired(publisher) ? (
              <div className="w-full">
                <BasicButton
                  classes={"bg-red-500 hover:bg-red-400 w-full"}
                  disabled={!page}
                  title={"Delete Page"}
                  handleClick={() => openDeletePromtModal()}
                />
              </div>
            ) : (
              ""
            )}

            <div className="w-full">
              <a
                href={`/web/rich-page/render/${page && page.uuid}`}
                target="_blank"
              >
                <BasicButton
                  classes={"bg-teal-500 hover:bg-teal-400 w-full"}
                  disabled={!page}
                  title={"Preview Page"}
                  handleClick={(e) => e.preventDefault()}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:grid md:grid-cols-3 justify-center items-start gap-4 mt-2 content-start">
          <div className="md:col-span-2 relative">
            {isResponsiveMode() ? (
              <p
                className="text-black p-2 text-red-900"
                style={{ fontSize: "14px" }}
              >
                For better experience use the page editor on a desktop or laptop
                because mobile browsers do no have full support.
              </p>
            ) : (
              ""
            )}
            <div className="p-2">
              <TextField
                placeholder={"Add title.."}
                classes={"p-2 h-26"}
                handleChange={handleNameChange}
                value={name}
              />
            </div>

            <ToolBar
              editorState={editorState}
              makePremium={makePremium}
              setEditorState={setEditorState}
              showPaymentControlsBox={showPaymentControlsBox}
              showAdBox={showAdBox}
              showVideoModalBox={showVideoModalBox}
              showImageModalCreateBox={showImageModalCreateBox}
              showTableModalCreateBox={showTableModalCreateBox}
              displayBannerBox={displayBannerBox}
              showAccordionModalCreateBox={showAccordionModalCreateBox}
              showHireModalCreateBox={showHireModalCreateBox}
              insertSignupBoxIntoEditor={insertSignupBoxIntoEditor}
              showButtonLinkModalBox={showButtonLinkModalBox}
              onBlockClick={onBlockClick}
              onInlineClick={onInlineClick}
              setEditorOutput={setEditorOutput}
              defaultFontSize={defaultFontSize}
              setDefaultFontSize={setDefaultFontSize}
              selectionHasLink={selectionHasLink}
              selectionAnchors={selectionAnchors}
              RichUtils={RichUtils}
              EditorState={EditorState}
              Modifier={Modifier}
              AtomicBlockUtils={AtomicBlockUtils}
              getDefaultKeyBinding={getDefaultKeyBinding}
              indentRef={indentRef}
              addSpace={addSpace}
              showLinkBox={showLinkBox}
              picker={picker}
              page={page}
              editorType={"content"}
            />

            <div className="p-2 mt-2">
              <Editor
                ref={editor}
                plugins={plugins}
                editorState={editorState}
                onChange={(st) => handleEditorChange(st)}
                customStyleFn={picker.customStyleFn}
                customStyleMap={COLOR_TYPES}
                onFocus={handleFocus}
                onBlur={handleBlur}
                blockRendererFn={mediaBlockRenderer}
                blockStyleFn={getBlockStyle}
                onTab={changeIndent}
                placeholder="Type here..."
                autoCapitalize={"none"}
                autoComplete={"off"}
                autoCorrect={"off"}
                spellCheck={false}
              />
            </div>
          </div>
          <div className="bg-gray-50 w-full p-2 z-40">
            <h1 className="text-2xl text-center m-2 font-bold">
              Cover Photo(s){" "}
              {!productSlides.length && (
                <span className="text-red-500 text-2xl">*</span>
              )}
            </h1>
            <div className="p-4 relative">
              {/* <BookCoverUpload
                setCoverPhoto={handleCoverPhotoChange}
                setFileSelected={handleSelectedFileChange}
                fileSelected={coverFileSelected}
              /> */}
              <MultiImageUploaderContentNew
                setFileSelected={addProductSlides}
                fileSelected={coverFileSelected}
              />
              <p className="text-center mb-2 text-green-500">
                {uploadingSlides ? "Uploading Image(s)..." : ""}
              </p>
              <p className="text-center mb-2 text-green-500">
                {deletingSlides ? "Deleting Image..." : ""}
              </p>
              {!productSlides.length ? (
                <p className="text-center text-small my-2 text-red-700">
                  One or more image(s) required.
                </p>
              ) : (
                ""
              )}
              {productSlides.length ? (
                <div className="w-full p-2 my-2 h-64 overflow-auto">
                  <p className="text-center">
                    {productSlides.length} images uploaded
                  </p>
                  <div className="-m-1 flex flex-wrap md:-m-2 py-3">
                    {productSlides.map((slide, i) => (
                      <div className="flex w-1/3 flex-wrap relative">
                        <div class="w-full p-1 md:p-2">
                          <img
                            class="block h-full w-full rounded-lg object-cover object-center"
                            src={
                              BASE_URL +
                              "/storage" +
                              slide?.file?.split("public")[1]
                            }
                          />
                        </div>

                        <div className="bg-white rounded rounded-full bg-white p-0 top-0 right-0 absolute px-1 cursor-pointer">
                          {!deletingSlides ? (
                            <i
                              class="fa fa-times-circle text-red-500  z-50 text-2xl"
                              onClick={() => removeProductSlide(slide.slide_id)}
                            ></i>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <FreeContentCoverLinks />
            <div className="p-4">
              <label className="text-black">
                Price (USD){" "}
                {!price && <span className="text-red-500 text-2xl">*</span>}
              </label>
              <TextField
                classes={"p-6 my-2 w-full"}
                placeholder={"Price.."}
                type="number"
                handleChange={handlePriceChange}
                value={price}
              />

              <div className="m-2 mb-8 flex flex-col items-center">
                <label className="text-black">
                  Enter Tags{" "}
                  {tags.length < 3 && (
                    <span className="text-red-500 text-2xl align-baseline">
                      *
                    </span>
                  )}
                </label>
                <small>
                  Enter at least 3 tags describing your content and separated by
                  commas
                </small>
                <TextField
                  classes={"p-6 my-2 text-xxs"}
                  placeholder={
                    "Tags...(eg office manual, news, school work, services, etc)"
                  }
                  handleChange={handleTagChange}
                  value={tag}
                />
                <small>You can always add or delete a tag afterwards</small>
                <BasicButton
                  disabled={!tag || tags.length == 10}
                  title={processing ? "Adding.." : "Add Tag(s)"}
                  classes={"p-6 w-9/12 mt-4"}
                  handleClick={addTag}
                />
              </div>
              <div className="m-2 flex flex-wrap">
                {tags.map((t) => (
                  <div className="rounded-full text-black border p-2 inline relative m-1 px-3">
                    <span className="text-sm pr-1">
                      ${t.name}
                      <span
                        className="fa fa-times absolute top-3 right-1 text-xs cursor-pointer text-gray-500"
                        onClick={() => removeTag(t)}
                      ></span>
                    </span>
                  </div>
                ))}
              </div>

              {page.owner
                ? page.owner.system_setting.constant_fee_on_payments && price
                  ? `Service Charge $${Number(
                      page.owner.system_setting.constant_fee_on_payments
                    )} + ${price} = $${
                      Number(price) +
                      Number(page.owner.system_setting.constant_fee_on_payments)
                    }`
                  : ``
                : ""}
            </div>
            {page.id ? (
              <div className="flex flex-col items-center gap-1 w-full">
                <div className="w-full">
                  {" "}
                  <a
                    href={`/web/rich-page/render/${page && page.uuid}`}
                    target="_blank"
                  >
                    <BasicButton
                      classes={"bg-teal-500 hover:bg-teal-400 w-full"}
                      disabled={!page}
                      title={"Preview Page"}
                      handleClick={(e) => e.preventDefault()}
                    />
                  </a>
                </div>
                <div className="w-full md:hidden">
                  {page.id && !isDataSubExpired(publisher) ? (
                    <BasicButton
                      classes={"w-full"}
                      disabled={processing || !name || !hasNoCoverPhoto()}
                      title={processing ? "Updating.." : "Update Page"}
                      handleClick={() => {
                        if (!name || !hasNoCoverPhoto()) {
                          toast(
                            "Please add a title and cover photo before updating ",
                            {
                              type: "notice",
                            }
                          );
                        } else {
                          updatePage();
                        }
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="w-full">
                  {page.buyers &&
                  !page.buyers.length &&
                  !isDataSubExpired(publisher) ? (
                    <BasicButton
                      classes={"bg-red-500 hover:bg-red-400 w-full"}
                      disabled={!page}
                      title={"Delete Page"}
                      handleClick={() => openDeletePromtModal()}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <AdInsertModalEditor
          modalOpen={showAdModal}
          hideAdModal={hideAdModal}
          advertRequestStatus={advertRequestStatus}
          requestAdvert={addCustomPageAdvert}
        />
        <PaymentControlsModal
          modalOpen={showPaymentControlsModal}
          hideAdModal={hidePaymentControlsBox}
          action={updatePaymentControlsSetting}
          handlePurchaseButtonChange={handlePurchaseButtonChange}
          handleSubscriptionButtonChange={handleSubscriptionButtonChange}
          handleBothChange={handleBothPaymentControlsChange}
          values={{
            purchaseButtonEnabled: purchaseButtonEnabled,
            subscriptionButtonEnabled: subscriptionButtonEnabled,
            bothPaymentsControlsEnabled: bothPaymentsControlsEnabled,
          }}
          paymentType={paymentType}
          handlePaymentTypeChange={handlePaymentTypeChange}
        />
        <LinkModalEditor
          modalOpen={showLinkModal}
          hideLinkModal={hideLinkModal}
          insertLink={() =>
            insertLink(
              editorState,
              currPastedLink,
              EditorState,
              setEditorState,
              setCurrPastedLink,
              hideLinkModal,
              RichUtils
            )
          }
          handleLinkChange={handleLinkChange}
          value={currPastedLink}
        />
        <VideoModalEditor
          modalOpen={showVideoModal}
          hideLinkModal={hideVideoModalBox}
          insertVideo={() => addCustomPageVideo()}
          handleVideoPreview={handleVideoPreview}
          handleVideoLinkChange={handleVideoLinkChange}
          resetVideoPreview={resetVideoPreview}
          resetVideoLinkPreview={resetVideoLinkPreview}
          videoFileSelected={videoFileSelected}
          processing={processing}
          value={currPastedVideoLink}
        />
        <ImageModalEditor
          modalOpen={showImageModal}
          hideLinkModal={hideImageModalBox}
          insertImage={() => addCustomPageImage()}
          handleImagePreview={handleImagePreview}
          handleImageLinkChange={handleImageLinkChange}
          resetImagePreview={resetImagePreview}
          resetImageLinkPreview={resetImageLinkPreview}
          imageFileSelected={imageFileSelected}
          currEditingImagePreview={currEditingImagePreview}
          createImageUploadMode={createImageUploadMode}
          processing={processing}
          value={currPastedImageLink}
          authTokens={authTokens}
          selectedSocialPhoto={selectedSocialPhoto}
          handleCheckSocialPhoto={handleCheckSocialPhoto}
          clearCheckedSocialPhotos={clearCheckedSocialPhotos}
          handleSocialEmbdedCodeChange={handleSocialEmbdedCodeChange}
          socialEmbedValue={socialEmbedValue}
          handleSocialUploadTypeChange={handleSocialUploadTypeChange}
          handleSocialUploadSubTypeChange={handleSocialUploadSubTypeChange}
          socialUploadType={socialUploadType}
          socialUploadSubType={socialUploadSubType}
          addSocialImageLinksToEditor={addSocialImageLinksToEditor}
          addSocialEmbedToEditor={addSocialEmbedToEditor}
          handleVideoPreview={handleVideoPreview}
          handleVideoLinkChange={handleVideoLinkChange}
          resetVideoPreview={resetVideoPreview}
          resetVideoLinkPreview={resetVideoLinkPreview}
          videoFileSelected={videoFileSelected}
          videoLinkValue={currPastedVideoLink}
          insertYoutubeVideo={addCustomPageVideo}
        />

        <ButtonLinkModalEditor
          modalOpen={buttonLinkModal}
          hideLinkModal={hideButtonLinkModalBox}
          insertButtonLink={addButtonLink}
          handleButtonLinkChange={handleButtonLinkChange}
          handleButtonLinkNameChange={handleButtonLinkNameChange}
          linkValue={buttonLink}
          nameValue={buttonLinkName}
        />
        {page && (
          <DeletePromptModal
            modalOpen={showDeletePromptModal}
            hideAdModal={hideDeletePromtModal}
            title={
              "If you delete this page, all the edits you have made will not be recoverable. Are you sure you want to delete this page?"
            }
            yesActionText={"Yes, Delete Page"}
            noActionText={"No, Cancel"}
            action={() => deletePage(page)}
            processing={processing}
          />
        )}
        <TierUpgradeNoticeModal
          modalOpen={tierNoticeModalOpen}
          hideModal={hideTierNoticeModal}
          notice={tierModalNotice}
        />
      </div>
      <HireModalEditor
        modalOpen={showHireModal}
        hideModal={hideHireModalBox}
        insertHire={() => addCustomPageHire()}
        handleHireTextChange={handleHireTextChange}
        handleHireResponseStatus={handleHireResponseStatus}
        handleHireReplySubjectChange={handleHireReplySubjectChange}
        handleHirePriceChange={handleHirePriceChange}
        processing={processing}
        hireTextValue={currHireText}
        replySubjectValue={currHireReplySubject}
        hirePriceValue={currHirePrice}
        replyStatus={currHireResponseStatus}
        createHireMode={createHireMode}
        user={publisher}
      />

      <TableEditor
        modalOpen={showTableModal}
        hideLinkModal={hideTableModalBox}
        tableColumns={tableColumns}
        addTableRow={addTableRow}
        addTableColumn={addTableColumn}
        addTableToEditor={addTableToEditor}
        handleTableHeaderChange={handleTableHeaderChange}
        handleTableRowChange={handleTableRowChange}
        handleTableTitleChange={handleTableTitleChange}
        createTableMode={createTableMode}
        updateTableInEditor={updateTableInEditor}
        deleteTableColumn={deleteTableColumn}
        deleteTableRow={deleteTableRow}
        tableTitle={tableTitle}
      />

      <BannerModal
        modalOpen={bannerModalOpen}
        hideAdModal={closeBannerModal}
        productSlides={productSlides}
        bannerSlides={bannerSlides}
        handleCheckBannerSlide={handleCheckBannerSlide}
        addBannerToEditor={addBannerToEditor}
        updateBannerInEditor={updateBannerInEditor}
        handleBannerTextChange={handleBannerTextChange}
        bannerSlideSelected={bannerSlideSelected}
        handleBannerUrlChange={handleBannerUrlChange}
        getSelBannerSlideInfo={getSelBannerSlideInfo}
        createBannerMode={createBannerMode}
        pageContext={"update"}
      />

      {showAccordionModal ? (
        <AccordionEditor
          accordions={accordions}
          addAccordionSection={addAccordionSection}
          deleteAccordionSection={deleteAccordionSection}
          handleAccordionHeaderChange={handleAccordionHeaderChange}
          handleAccordionBodyChange={handleAccordionBodyChange}
          updateAccordionInEditor={updateAccordionInEditor}
          showAccordionModalCreateBox={showAccordionModalCreateBox}
          addAccordionToEditor={addAccordionToEditor}
          createAccordionMode={createAccordionMode}
          accordionTitle={accordionTitle}
          modalOpen={showAccordionModal}
          hideLinkModal={hideAccordionModalCreateBox}
          saveAccordionImages={saveAccordionImages}
          deleteAccordionMediaServer={deleteAccordionMediaServer}
          deleteAccordionMediaEditor={deleteAccordionMediaEditor}
          handleAccordionTitleChange={handleAccordionTitleChange}
          addYoutubeToAccordion={addYoutubeToAccordion}
          addSocialToAccordion={addSocialToAccordion}
          accordionId={accordionId}
          processing={processing}
        />
      ) : (
        ""
      )}
    </>
  );
}
