import RadioBox from "../../../../../../components/inputs/radio";
export default function UploadOptions(props) {
  const { mediaType } = props;
  const { handleMediaTypeChange } = props;

  const canShowOptions = () => {
    return true;
  };

  return (
    canShowOptions() && (
      <div className="flex gap-2 w-full justify-center text-xs md:text-base">
        <div>
          <RadioBox
            checked={mediaType == "upload"}
            handleChange={() => {
              handleMediaTypeChange("upload");
            }}
          />{" "}
          <span>Upload Image(s)</span>
        </div>
        <div>
          <RadioBox
            checked={mediaType == "youtube"}
            handleChange={() => {
              handleMediaTypeChange("youtube");
            }}
          />{" "}
          <span>YouTube</span>
        </div>
        <div>
          <RadioBox
            checked={mediaType == "instagram"}
            handleChange={() => {
              handleMediaTypeChange("instagram");
            }}
          />{" "}
          <span>Instagram</span>
        </div>

        <div>
          <RadioBox
            checked={mediaType == "facebook"}
            handleChange={() => {
              handleMediaTypeChange("facebook");
            }}
          />{" "}
          <span>Facebook</span>
        </div>
        <div>
          <RadioBox
            checked={mediaType == "twitter"}
            handleChange={() => {
              handleMediaTypeChange("twitter");
            }}
          />{" "}
          <span>Twitter</span>
        </div>
        <div>
          <RadioBox
            checked={mediaType == "pinterest"}
            handleChange={() => {
              handleMediaTypeChange("pinterest");
            }}
          />{" "}
          <span>Pinterest</span>
        </div>
      </div>
    )
  );
}
