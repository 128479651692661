import create from "zustand";
import { ads } from "../components/slider/homepage/util/ads";

export const useWebProfileStore = create((set) => ({
  adsExtIndex: 0,
  nextPageURL: "",
  books: [],
  booksWithAds: [],
  setBooksWithAds: (data) =>
    set((state) => {
      return {
        booksWithAds: data,
      };
    }),
  computeAdsIndex: () =>
    set((state) => {
      console.log("ad counter", state.adsExtIndex);
      return {
        adsExtIndex:
          state.adsExtIndex == ads.length - 1 ? 0 : state.adsExtIndex + 1,
      };
    }),
  setBooks: (data) =>
    set((state) => {
      return {
        books: data,
      };
    }),
  setNextPageURL: (url) =>
    set((state) => {
      return {
        nextPageURL: url,
      };
    }),
}));
