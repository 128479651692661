import TextField from "../components/inputs/text-input";
import TextArea from "../components/inputs/text-area";
import BasicButton from "../components/buttons/basic-button";
import { useBookStore } from "../stores/book-store";
import EditBook from "../pages/publisher/book/edit-book";

export default function TierUpgradeNoticeModal(props) {
  const { pageAdvertId } = useBookStore((state) => state);
  const { modalOpen, hideModal, notice } = props;

  return (
    <div
      className={`fixed z-40 overflow-y-auto top-1/2 -translate-y-1/2 w-full left-0 z-999999 h-screen ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh  pt-4 px-4 pb-20 text-center sm:block sm:p-0 mt-10 md:mt-1">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-6/12 h-4/12 relative"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className="rounded rounded-full p-0 -top-2 -right-1 absolute px-1 cursor-pointer opacity-75"
            onClick={() => hideModal()}
          >
            <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
          </div>
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 ">
            {/* modal body */}
            <p>{notice}</p>
            {/* modal body */}
            <a href="/publisher/storage">
              <button
                type="button"
                class="py-2 px-4 bg-teal-500 text-white rounded hover:bg-teal-700 mr-2 my-5"
              >
                Increase Storage Space
              </button>
            </a>
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            {/* <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideModal()}
            >
              <i class="fas fa-times"></i> Close
            </button> */}
            {/* <button
                type="button"
                class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              >
                <i class="fas fa-plus"></i> Create
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
