import LeftPanel from "./components/left-panel";
import RightPanel from "./components/right-panel";
import StickyLeftPanel from "./components/sticky-header/left-panel";
import StickyRightPanel from "./components/sticky-header/right-panel";
import { useEffect, useState, useRef } from "react";
export default function RenderTableBox(props) {
  const { data } = props;
  const html = data; //Your html string
  const tmpDiv = document.createElement("div");
  tmpDiv.innerHTML = html;
  const payload = tmpDiv.querySelector(".table-xyz").getAttribute("payload");
  const p = JSON.parse(payload);

  const maxRows = Math.max(...p.data.map((column) => column.rows.length));
  const div1Ref = useRef(null);
  const div2Ref = useRef(null);
  return p.data ? (
    <>
      {" "}
      <div className="overflow-x-auto">
        <table className="table-auto">
          <thead>
            <tr>
              {p.data.map((column, index) => (
                <th
                  key={index}
                  className="border border-gray-300 px-4 py-2 text-center"
                >
                  {column.header.value}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: maxRows }).map((_, rowIndex) => (
              <tr key={rowIndex}>
                {p.data.map((column, colIndex) => (
                  <td
                    key={colIndex}
                    className="border border-gray-300 px-4 py-2 whitespace-pre whitespace-pre-line whitespace-pre-wrap"
                    style={{ display: "table-cell" }}
                  >
                    {column.rows[rowIndex] ? column.rows[rowIndex].value : ""}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <div className="w-full justify-center my-4 text-center ">
        <div
          className="inline w-full"
          style={{
            width: document.documentElement.clientWidth - 90 + "px",
            margin: "0 auto",
          }}
        >
          <div className="flex flex-col w-full overflow-x-hidden overflow-y-scroll h-10 gap-2">
            <div className="grid grid-cols-12">
              <StickyLeftPanel />
              <StickyRightPanel items={p.data} div1Ref={div1Ref} />
            </div>
          </div>
          <div className="flex flex-col w-full overflow-x-hidden overflow-y-scroll h-80 relative gap-2 ">
            <div className="grid grid-cols-">
              <LeftPanel />
              <RightPanel items={p.data} div2Ref={div2Ref} div1Ref={div1Ref} />
            </div>
          </div>
        </div>
      </div> */}
    </>
  ) : (
    ""
  );
}
