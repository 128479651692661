import TextField from "../../../../../../../components/inputs/text-input";
export default function ItemPrice(props) {
  const { productMeta, handleMetaChange, user } = props;
  return (
    <div className="m-2 mb-2">
      <label className="text-black">Price (USD)</label>
      <TextField
        classes={"p-6 my-2"}
        placeholder={"Price.."}
        type="number"
        handleChange={(e) => handleMetaChange(e, "price", "text")}
        value={productMeta.price}
      />
      <p className="text-xs">
        {" "}
        {user.id
          ? user.system_setting.constant_fee_on_payments && productMeta.price
            ? `Service Charge $${Number(
                user.system_setting.constant_fee_on_payments
              )} + ${productMeta.price} = $${
                Number(productMeta.price) +
                Number(user.system_setting.constant_fee_on_payments)
              }`
            : ``
          : ""}
      </p>
      <p className="text-base text-pink-500">
        {productMeta.price &&
          productMeta.price == 0 &&
          "Please enter a price greater than 0"}
      </p>
    </div>
  );
}
