import BasicButton from "../../../../../../components/buttons/basic-button";
export default function CheckoutInfo(props) {
  const { processing } = props;
  const { consent } = props;
  const { productReplyMeta } = props;
  const { shortDurationMeta } = props;
  const { meta } = props;
  const { getLabelAndPrice } = props;
  const { defaultMeta } = props;
  const { showCalendar } = props;
  const { selType } = props;
  const { canSubmit } = props;
  const { state } = props;
  const { data } = props;

  return (
    <div className="flex justify-center">
      <BasicButton
        disabled={
          !productReplyMeta.start_date ||
          (meta.purchaser_reply_enabled && !productReplyMeta.reply) ||
          processing ||
          !consent
        }
        title={
          processing
            ? "Processing.."
            : "Proceed to " +
              getLabelAndPrice().label +
              " $" +
              getLabelAndPrice().price
        }
        classes="px-8 w-8/12"
        handleClick={() => {
          if (selType == "preferred") {
            defaultMeta.reply = meta.reply;
            defaultMeta.block_meta_id = meta.block_meta_id;
            defaultMeta.p_type_sub_opt = meta.p_type_sub_opt;
            defaultMeta.price = data.price;
            defaultMeta.start_date = productReplyMeta.start_date;
            defaultMeta.reply = productReplyMeta.reply;
          } else {
            const shortMetaCopy = shortDurationMeta;
            const shortPayload = JSON.parse(shortMetaCopy.payload);
            defaultMeta.reply = productReplyMeta.reply;
            defaultMeta.block_meta_id = shortPayload.block_meta_id;
            defaultMeta.p_type_sub_opt = shortPayload.p_type_sub_opt;
            defaultMeta.price = shortMetaCopy.price;
            defaultMeta.start_date = productReplyMeta.start_date;
          }

          return canSubmit() && state.processRecurringPayment(defaultMeta);
        }}
      />
    </div>
  );
}
