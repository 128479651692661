import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useRef } from "react";
import useDebounce from "../../../../debounce/debounce-helper";
import { API_BASE } from "../../../../../Utils/helpers";
import authRequestService from "../../../../../services/HttpRequests/authenticated-request-services";
export default function ProductLocationSearch(props) {
  const { location, handleLocationChange, classes } = props;

  const [query, setQuery] = useState("");
  const [locations, setLocations] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [savingStage, setSavingStage] = useState(false);

  const getLocations = () => {
    const url =
      API_BASE +
      `/publisher/supplier/product/locations/${query.replace(
        "/",
        "___"
      )}/dashboard`;
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200 && response.data?.locations) {
          setLocations(response.data.locations);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", {
          type: "error",
        });
      });
  };

  const inputRef = useRef(null);

  useDebounce(
    () => {
      if (query) {
        getLocations();
      }
    },
    [query],
    800
  );

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    setQuery(() => option);
    console.log("opp", option);
    if (option) {
      handleLocationChange(option);
    } else {
      handleLocationChange("*");
    }
    setIsOpen((isOpen) => !isOpen);
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (location != "*") return location;

    return "";
  };

  const filter = (options) => {
    return options.filter(
      (option) => option.toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  };

  return (
    <div className="my-identity dropdown w-8/12">
      <div className="control">
        <div className="selected-value">
          <input
            id="xxxxxxxxxxxxxx"
            autocomplete="off"
            ref={inputRef}
            type="text"
            className={"rounded-lg landing-page-search"}
            placeholder="Search..."
            value={getDisplayValue()}
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
              handleLocationChange(e.target.value);
            }}
            onClick={toggle}
          />
        </div>
        {/* <div className={`arrow ${isOpen ? "open" : ""}`}></div> */}
      </div>

      <div
        className={`options text-left h-32 overflox-y-auto ${
          isOpen ? "open" : ""
        }`}
      >
        {locations.length && query
          ? filter(locations).map((option, index) => {
              return (
                <div
                  onClick={() => selectOption(option)}
                  className={`option ${option === location ? "selected" : ""}`}
                  key={`${"id"}-${index}`}
                >
                  {option}
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
}
