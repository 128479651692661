import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import StripeRedirectModal from "../../../modals/stripe-redirect-modal";
import StripeRedirectModeModal from "../../../modals/stripe-redirect-mode-modal";
import DeletePromptModal from "../../../modals/delete-warning-modal";
import StripeDataThresholdSubModal from "../../../modals/stripe-datathreshold-sub-modal";
import useDebounce from "../../../components/debounce/debounce-helper";
import Pagination from "../../../components/pagination";

import {
  isAdmin,
  API_BASE,
  isPublisher,
  getLocation,
  authUserData,
  isAuthUser,
  getClientBaseURL,
} from "../../../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
import Switch from "react-switch";
import PasswordPromptModal from "../../../modals/password-prompt-modal";
import CreateTierModal from "../../../modals/create-tier-modal";
export default function PublisherDashboardStorageFiles() {
  const [files, setFiles] = useState([]);
  const [type, setType] = useState("file");
  const [activeSub, setActiveSub] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [user, setUser] = useState({});

  const getStorageFiles = () => {
    let url =
      API_BASE + "/api/storage/files/all/" + authUserData().uuid + `/${type}`;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setFiles(response.data.files);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const getUser = () => {
    let url = API_BASE + "/api/publisher/" + authUserData().uuid + "/optimized";
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.publisher.has_sub_record) {
            localStorage.setItem("has_sub_record", "exists");
          }
          setUser(response.data.publisher);
          let sub = response.data.publisher.activedatasub;
          if (sub) {
            setActiveSub(sub);
          }
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const downloadFile = ($fileUrl) => {
    const url = $fileUrl.split("public")[1];
    const ext = $fileUrl.split(".")[1];
    axios({
      url: API_BASE + "/storage/" + url,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "my-download." + ext); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const doPagination = (page) => {
    if (files.first_page_url) {
      setCurrentPage(page);
      axios
        .get(files.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          setFiles(res.data.files);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (!isPublisher() || !isAuthUser()) {
      window.location.href = "/";
    }
    getStorageFiles();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getUser();
    }, 300);
  }, [files]);

  useEffect(() => {
    getStorageFiles();
  }, [type]);

  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">My Files</h1>
        </div>
        <div className="flex gap-2">
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center gap-4 mt-8 items-center mb-4">
        {/* <h1 className="font-bold text-xl">Storage Tiers</h1> */}
        <div className="w-full flex justify-center px-6 ap-4">
          <div className="flex gap-4">
            <div>
              {" "}
              Current Plan:{" "}
              {activeSub.id && activeSub.active ? (
                <b>{activeSub.datathreshold.name} </b>
              ) : (
                <b className="font-bold">Free Tier</b>
              )}
            </div>
            <div>
              {" "}
              Data Used:{" "}
              {user ? (
                <b className="font-bold">{user.active_data_usage} </b>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </div>

        <div className="w-full flex justify-center px-6 items-start gap-2">
          <div className="w-full">
            <BasicButton
              title={"File Download"}
              classes={`${
                type == "file"
                  ? "bg-teal-700 hover:bg-teal-500"
                  : "bg-white text-black hover:bg-gray-50 border"
              }  w-full rounded-none`}
              handleClick={() => setType("file")}
            />
          </div>
          <div className="w-full">
            <BasicButton
              title={"Page Download"}
              classes={`${
                type == "page"
                  ? "bg-teal-700 hover:bg-teal-500"
                  : "bg-white text-black border hover:bg-gray-50 hover:bg-white"
              } w-full rounded-none`}
              handleClick={() => setType("page")}
            />
          </div>
        </div>

        <div className="w-full p-8 flex flex-col">
          <div className="hidden md:flex justify-between w-full border-b p-4">
            {" "}
            <div className="text-center w-full font-bold">
              {type == "file" ? "File Name" : "Page Name"}
            </div>
            <div className="text-center w-full font-bold">Size</div>
            <div className="text-center w-full font-bold">Format</div>
            <div className="text-center w-full font-bold">Action</div>
          </div>

          {files.data
            ? files.data.map((f) => (
                <div className="flex flex-col gap-2 md:flex-row justify-between w-full border-b p-4 bg-gray-50 mb-1">
                  {" "}
                  <div className="text-center w-full">
                    <p className="md:hidden font-bold">Name</p>
                    <p>{f.book?.name}</p>
                    {/* {f.name} */}
                  </div>
                  <div className="text-center w-full">
                    <p className="md:hidden font-bold">Size</p>
                    {f?.file_size} GB
                  </div>
                  <div className="text-center w-full">
                    <p className="md:hidden font-bold">Format</p>
                    {f.file ? f.file.split(".")[1] : "N/A"}
                  </div>
                  <div className="text-center w-full">
                    <BasicButton
                      title={"Download"}
                      classes="bg-teal-700 hover:bg-teal-500 w-full"
                      handleClick={() => downloadFile(f.file)}
                    />
                  </div>
                </div>
              ))
            : ""}
        </div>
        <div className="flex justify-center">
          {" "}
          {files.data ? (
            files.data.length ? (
              <Pagination pagination={files} doPagination={doPagination} />
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
