export default function MobileLogoBar() {
  return (
    <div className="w-2/12">
      {" "}
      <a href="/">
        <img class="w-full" src="/litzap_logo.png" alt="" />
      </a>
    </div>
  );
}
