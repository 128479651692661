import { useSupplierApplicationsStore } from "../../../../../../stores/supplier-applications-store";
import BasicButton from "../../../../../../components/buttons/basic-button";
import { useNavigate } from "react-router-dom";
import { BASE_URL_WITH_LOCAL } from "../../../../../../Utils/helpers";

export default function DesktopTable(props) {
  const mailStore = useSupplierApplicationsStore((state) => state);
  const { openReplaceContractModal } = props;

  const navigate = useNavigate();

  return (
    <div className="overflow-x-scroll md:h-screen w-full">
      {" "}
      <table class="table-auto w-full my-4">
        <thead>
          <tr className={window.innerWidth < 720 ? "hidden" : ""}>
            <th class="px-4 py-2 text-gray-600">Page</th>
            <th class="px-4 py-2 text-gray-600">Button</th>
            <th class="px-4 py-2 text-gray-600">Any Transactions?</th>
            <th class="px-4 py-2 text-gray-600">Replace Contract</th>
          </tr>
        </thead>
        <tbody className="w-full">
          {mailStore.data.data &&
            mailStore.data.data.map((c) => {
              return (
                <tr
                  className={
                    window.innerWidth < 820 ? "flex flex-col items-center " : ""
                  }
                >
                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Page Name</h3>
                    {c?.book_data?.name}
                  </td>
                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Button</h3>
                    {c?.payload ? JSON.parse(c.payload).p_type_sub_opt : ""}
                  </td>
                  <td class="border  px-4 py-2  font-medium">
                    <h3 className="font-bold md:hidden">Any Transaction</h3>
                    {c?.hasactivesub || c?.hasactivepayment ? "Yes" : "No"}
                  </td>
                  <td class="border  px-4 py-2  font-medium text-center">
                    <h3 className="font-bold md:hidden">Replace Contract</h3>
                    <BasicButton
                      disabled={!c?.id}
                      title={"Replace Contract for All"}
                      classes={
                        "my-bg-yellow-500 text-black hover:text-black  hover:bg-yellow-500"
                      }
                      handleClick={() => openReplaceContractModal()}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
