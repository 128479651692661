import moment from "moment";
import { useEffect, useState } from "react";
import { isResponsiveMode } from "../../../../../Utils/helpers";
export default function RightPanel(props) {
  const {
    subs,
    products,
    monthDays,
    monthsList,
    selYear,
    selMonth,
    div1Ref,
    div2Ref,
  } = props;

  const addDateColorClasses = (date, i) => {
    const dateToCheck = moment(date).format("YYYY-MM-DD");
    const res = subs[i].filter((d) => {
      return d.date_only === dateToCheck;
    });

    if (res.length) {
      return res[0].color.toString() + " cursor-pointer ";
    }
    return "";
  };

  const getSubCountAndColor = (date, i) => {
    const dateToCheck = moment(date).format("YYYY-MM-DD");
    const res = subs[i].filter((d) => d.date_only === dateToCheck);
    if (res.length) {
      return res[0].sub_count;
    }
    return "";
  };

  const getQuantity = (date, i) => {
    const dateToCheck = moment(date).format("YYYY-MM-DD");
    const res = subs[i].filter((d) => d.date_only === dateToCheck);
    if (res.length) {
      return res[0].quantity;
    }
    return "";
  };

  const getAvailabilityQAndColor = (date, i) => {
    const dateToCheck = moment(date).format("YYYY-MM-DD");
    const res = subs[i].filter((d) => d.date_only === dateToCheck);

    if (res.length) {
      return res[0].available_quantity;
    }
    return "";
  };

  // Function to handle month name conversion
  const getYearAndMonthAsString = (dayToCheck) => {
    const dateString = selYear + " " + selMonth + " " + dayToCheck;
    const [yearString, monthName, dayString] = dateString.split(" ");
    const year = parseInt(yearString);
    const month = monthsList[selMonth];
    const day = parseInt(dayString);
    const dateObject = new Date(year, monthsList[selMonth], day);
    return dateObject;
  };

  const handleDiv2Scroll = () => {
    div1Ref.current.scrollLeft = div2Ref.current.scrollLeft;
  };

  function getDaysInMonth(month, year) {
    // Create a Date object for the first day of the month
    const date = new Date(year, month, 1);

    // Get the last day of the month (0-indexed)
    const lastDay = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();

    const dates = [];
    for (let i = 1; i <= lastDay; i++) {
      // Create a new Date object for each day
      const dayDate = new Date(year, month, i);

      // Add the date object to the dates array
      dates.push(dayDate);
    }
    return dates;
  }

  const convertToUTC = (date) => {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  };

  const correcDateFormat = (d) => {
    const day = d.getUTCDate();
    const month = d.getUTCMonth() + 1; // Months are zero-based, so add 1
    const year = d.getUTCFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    //console.log(formattedDate);
    return formattedDate;
  };

  useEffect(() => {
    if (div2Ref.current && div1Ref.current) {
      div2Ref.current?.addEventListener("scroll", handleDiv2Scroll);
    }
    return () => {
      div2Ref.current?.removeEventListener("scroll", handleDiv2Scroll);
    };
  }, []);

  const cWidth = isResponsiveMode() ? 170 : 550;

  return (
    <div
      ref={div2Ref}
      className="overflow-x-scroll overflow-y-hidden inline "
      style={{
        width: document.documentElement.clientWidth - cWidth + "px",
        margin: "0 auto",
      }}
    >
      <div className="flex w-full">
        {subs.length ? (
          getDaysInMonth(monthsList[selMonth], selYear).map((c, day) => {
            const d = day + 1;
            return (
              <div className="font-bold border flex flex-col">
                {/* <p className="border h-10 w-40"> {d}</p> */}
                {products.length ? (
                  products.map((p, i) => {
                    if (Object.hasOwn(subs, i)) {
                      const subCount = getSubCountAndColor(
                        getYearAndMonthAsString(d),
                        i
                      );

                      const quantity = getQuantity(
                        getYearAndMonthAsString(d),
                        i
                      );

                      return (
                        <div
                          className={
                            "calendar-day cursor-pointer flex flex-col items-center justify-center h-10 w-40"
                          }
                          onClick={() => {
                            window.location.href = `/publisher/product/meta/${
                              products[i].block_meta_id
                            }/availability/${correcDateFormat(
                              convertToUTC(getYearAndMonthAsString(d))
                            )}`;
                          }}
                        >
                          <div className="grid grid-cols-5 gap-1">
                            <div className={`border-b-2`}>
                              <p className={"text-black font-bold"}>
                                {quantity}
                              </p>
                            </div>
                            <div>|</div>
                            <div
                              className={
                                subCount > 0
                                  ? "text-red-500 my-text-red-500 font-bold"
                                  : "text-green-500 my-text-green-500 font-bold"
                              }
                            >
                              {subCount}
                            </div>
                            <div>|</div>
                            <div
                              className={
                                true
                                  ? ` current bold text-gray-300 font-bold ${addDateColorClasses(
                                      getYearAndMonthAsString(d),
                                      i
                                    )}`
                                  : "text-gray-100"
                              }
                            >
                              {getAvailabilityQAndColor(
                                getYearAndMonthAsString(d),
                                i
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return <p>Loading...</p>;
                    }
                  })
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            );
          })
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
}
