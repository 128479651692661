import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";

import {
  isAdmin,
  API_BASE,
  isPublisher,
  getLocation,
  authUserData,
  isAuthUser,
  getClientBaseURL,
} from "../../../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
export default function AdminAdHome() {
  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">Advert System</h1>
        </div>
        <div>
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div className="bg-white m-2 p-2 flex justify-between shadow flex flex-col gap-2">
        <BasicButton
          title={"Unprocessed Ad Code Requests"}
          handleClick={() =>
            (window.location.href = "/admin/advert-requests/unprocessed")
          }
        />
        <BasicButton
          title={"Approved Ad Code Requests"}
          handleClick={() =>
            (window.location.href = "/admin/advert-requests/approved")
          }
        />
        <BasicButton
          title={"Rejected Ad Code Requests"}
          handleClick={() =>
            (window.location.href = "/admin/advert-requests/rejected")
          }
        />
        <BasicButton
          title={"Ad Protection Ratio button"}
          handleClick={() =>
            (window.location.href = "/admin/ads/ad-protection-ratio")
          }
        />
        <BasicButton
          title={"General Ad Codes"}
          handleClick={() =>
            (window.location.href = "/admin/ads/general-ad-codes")
          }
        />
        <BasicButton
          title={"Paid Adverts"}
          handleClick={() => (window.location.href = "/admin/ads/paid-ads")}
        />
      </div>
    </>
  );
}
