import { ACTION_TYPES } from "../editor-objects";
import { StyleButton } from "../helpers/functions";
export default function ProductMetaButton(props) {
  const {
    displayProductMeta,
    cannotDisplaySPButton,
    cannotDisplayREButton,
    showProductMetaSetupBox,
  } = props;
  return (
    <button
      className="border border-gray rounded-lg p-1 m-1"
      onClick={() => {
        if (cannotDisplaySPButton() && cannotDisplayREButton()) {
          showProductMetaSetupBox();
          return false;
        }
        displayProductMeta();
      }}
    >
      Display Purchase Button
    </button>
  );
}
