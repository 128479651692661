import axios from "axios";
import { useEffect, useState } from "react";
import BasicButton from "../../../components/buttons/basic-button";
import { isAdmin, API_BASE } from "../../../Utils/helpers";
import { toast } from "react-toastify";
export default function AdminDonations() {
  const [donations, setDonations] = useState([]);
  const url = API_BASE + "/api/donations";
  const getDonations = () => {
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setDonations(response.data.data);
        }
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  useEffect(() => {
    getDonations();
    if (!isAdmin()) {
      window.location.href = "/";
    }
  }, []);
  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">Donations</h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            <table class="table-auto w-full">
              <thead>
                <tr className={window.innerWidth < 720 ? "hidden" : ""}>
                  <th class="px-4 py-2 text-gray-600">Amount</th>
                  <th class="px-4 py-2 text-gray-600">Member</th>
                  <th class="px-4 py-2 text-gray-600">Book</th>
                  <th class="px-4 py-2 text-gray-600">Date</th>
                </tr>
              </thead>
              <tbody>
                {donations.map((donation) => {
                  return (
                    <tr
                      className={window.innerWidth < 820 ? "flex flex-col" : ""}
                    >
                      <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <h3 className="font-bold md:hidden">Amount</h3>$
                        {donation.amount / 100}
                      </td>
                      <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <h3 className="font-bold md:hidden">Member</h3>
                        {donation.user_id ? "Yes" : "No"}
                      </td>
                      <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <a
                          href={`/publisher/${donation.book.owneruuid}/publications/series/m/book/${donation.book.book_id}`}
                        >
                          <BasicButton
                            classes={"bg-teal-500 hover:bg-teal-400 w-full"}
                            title={"View Book"}
                            handleClick={null}
                          />
                        </a>
                      </td>
                      <td class="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        <h3 className="font-bold md:hidden">Date</h3>
                        {donation.created_at}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
