import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import WebHeader from "./web/header";
import AdSense from "react-adsense";
import {
  isAdmin,
  subscritionCostWithSystemFees,
  bookPriceWithSystemFees,
} from "../Utils/helpers";
import {
  isAuthUser,
  API_BASE,
  authUserData,
  BASE_URL,
  isResponsiveMode,
  getLocation,
  globalAds,
} from "../Utils/helpers";
import Footer from "../layout/footer";
import axios from "axios";
import StripePaymentModal from "../modals/stripe-payment-modal";
import StripeSubscriptionModal from "../modals/stripe-subscription-modal";
import RegistrationModal from "../modals/registration-modal";
import StripeForm from "../payment/pay-button";
import BasicButton from "../components/buttons/basic-button";
import PlatformUpdateModal from "../modals/platform-updates-modal";
import YouTube from "react-youtube";

export default function CustomPageRendererPreview() {
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [password, setPassword] = useState("");
  const [webData, setWebData] = useState("");
  const [webDataTokenized, setWebDataTokens] = useState([]);
  const [webDataNoAds, setWebDataNoAds] = useState("");
  const [page, setPage] = useState({});
  const [paymentType, setPaymentType] = useState("");
  const [subSecretKey, setSubSecretKey] = useState();
  const [processing, setProcessing] = useState(false);
  const [showSubModal, setShowSubModal] = useState(false);
  const [platformModalOpen, setPlatformModalOpen] = useState(false);
  const [signupSuccess, setShowSignupSuccess] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [stripeModalOpen, setStripeModalOpen] = useState(false);
  const [registrationModalOpen, setRegistrationModalOpen] = useState(false);

  const naviagte = useNavigate();
  let { pageId } = useParams();
  const userHasScroledToBottomRef = useRef();
  let monetizationContentMatch = 0;
  let basicContentMatch = 0;

  const fetchPage = () => {
    const url = API_BASE + "/api/book/custom/preview/single/" + pageId;
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setWebData(response.data.page.html_data);
          setTitle(response.data.page.name);
          setPage(response.data.page);
          setWebDataNoAds(response.data.page.html_adfree_data);
        }
      })
      .catch((error) => {
        if (error.response.data.invalid_ad_code) {
          toast("Invalid Ad Code", { type: "error" });
        } else {
          toast("Something went wrong", { type: "error" });
        }
      });
  };

  const getStripePaymentSecret = () => {
    let formData = new FormData();
    const url = API_BASE + "/api/user/payment/get-stripe-payment-secret";
    setProcessing(true);
    axios
      .post(url, {
        book_id: page.uuid,
        user_id: isAuthUser() ? authUserData().uuid : "",
      })
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          // alert(response.data.secret_key);
          setSubSecretKey(response.data.secret_key);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong!", {
          type: "error",
        });
      });
  };

  const recordPageView = () => {
    const url = API_BASE + "/api/page/views";
    axios.post(url, { uuid: page.pages[0].uuid }).catch((e) => {});
  };

  const hasPurchasedBook = () => {
    return false;
    if (!authUserData()) {
      return false;
    }

    if (page.owner && page.owner.id == authUserData().id) {
      return true;
    }
    if (page.buyers) {
      const bought = page.buyers.filter((buyer) => {
        if (buyer.buyer_id == authUserData().id) {
          return buyer;
        }
      });
      const hasSubscribed = hasSubscribedToPublisher();
      return bought.length || hasSubscribed ? true : false;
    }
  };

  const hasSubscribedToPublisher = () => {
    if (!authUserData()) {
      return false;
    }

    if (page.owner.subscribers) {
      const subscribed = page.owner.subscribers.filter((subscriber) => {
        if (subscriber.user_id == authUserData().id && subscriber.active) {
          return subscriber;
        }
      });
      return subscribed.length ? true : false;
    }
  };

  const hideStripeModal = () => {
    setStripeModalOpen(false);
  };
  const showRegistrationModal = (paymentType) => {
    if (paymentType) {
      setPaymentType(paymentType);
    }
    setRegistrationModalOpen(true);
  };

  const hideRegistrationModal = () => {
    setRegistrationModalOpen(false);
  };

  const hideSubModal = () => {
    setSubSecretKey();
    setShowSubModal((prev) => (prev = !prev));
  };

  const openSubModal = () => {
    getStripePaymentSecret();
    setShowSubModal(true);
  };

  const insertDivIntoHTMLBlock = () => {
    if (webData) {
      const doc = new DOMParser().parseFromString(webData, "text/html");
      const arr = [...doc.body.children].map((child) => child.outerHTML);
      setWebDataTokens(arr);
    }
  };

  const insertAdsIntoDom = () => {
    let adCodes = document.getElementsByClassName("advert-block");
    let ads = [];
    if (adCodes && adCodes.length) {
      for (var i = 0; i < adCodes.length; i++) {
        adCodes[i].className += " " + "flex justify-center";
        var elem = document.createElement("img");
        elem.setAttribute("src", "/ad_1.PNG");
        adCodes[i].appendChild(elem);
        ads.push(adCodes[i].getAttribute("code"));
      }
    }
  };

  const showPlatformModal = () => {
    setPlatformModalOpen(true);
  };

  const hidePLatformModal = () => {
    setPlatformModalOpen(false);
  };

  const showSignupSuccess = () => {
    setShowSignupSuccess(true);
  };

  const shoudShowSubscription = (page) => {
    if (
      page &&
      page.enable_custom_page_purchase &&
      page.owner.enable_subscription &&
      page.enable_custom_page_subscription
    ) {
      return true;
    }

    if (
      page.owner.enable_subscription &&
      !page.enable_custom_page_subscription
    ) {
      return true;
    }

    return false;
  };
  const shoudShowPurchase = (page) => {
    if (
      page &&
      page.enable_custom_page_purchase &&
      page.owner.enable_subscription &&
      page.enable_custom_page_subscription
    ) {
      return true;
    }

    if (page.price && !page.enable_custom_page_purchase) {
      return true;
    }
    return false;
  };

  const RenderSignupFoUpdatesBox = () => {
    return (
      <div className="w-full text-center">
        {!signupSuccess ? (
          <BasicButton
            title={`Sign up for updates`}
            handleClick={() => showPlatformModal()}
            classes="bg-black text-white"
          />
        ) : (
          ""
        )}

        {signupSuccess ? (
          <h3 className="text-2xl text-white">Thanks for Signing up</h3>
        ) : (
          ""
        )}
      </div>
    );
  };

  const RenderButtonLinkBox = (data) => {
    const html = data.data; //Your html string
    const tmpDiv = document.createElement("div");
    tmpDiv.innerHTML = html;
    const buttonLinkName = tmpDiv
      .querySelector(".button-link-xyz")
      .getAttribute("name");
    const src = tmpDiv.querySelector(".button-link-xyz").getAttribute("link");
    return (
      <div className="w-full text-center">
        <a href={src} target="_blank">
          <BasicButton
            title={buttonLinkName}
            classes="bg-teal-500 hover:bg-teal-700 text-white shadow"
          />
        </a>
      </div>
    );
  };

  const RenderImageBox = (data) => {
    const html = data.data; //Your html string
    const tmpDiv = document.createElement("div");
    tmpDiv.innerHTML = html;
    const imageLink = tmpDiv
      .querySelector(".image-link-xyz")
      .getAttribute("imageLink");
    const src = tmpDiv.querySelector(".image-link-xyz").getAttribute("src");
    return (
      <div className="w-full text-center">
        {imageLink && imageLink !== "" ? (
          <a href={imageLink} target="_blank" className="cursor-pointer">
            <img src={src} />
          </a>
        ) : (
          <img src={src} />
        )}
      </div>
    );
  };

  const RenderAdvertBox = (data) => {
    const html = data.data; //Your html string
    const tmpDiv = document.createElement("div");
    tmpDiv.innerHTML = html;
    const adCode = tmpDiv.querySelector(".advert-block").getAttribute("adCode");
    const systemAdCode = tmpDiv
      .querySelector(".advert-block")
      .getAttribute("systemAdCode");

    return getLocation().hostname == "litzapp.com" ? (
      <>
        {" "}
        <div className="flex justify-center w-full">
          {adCode ? (
            <AdSense.Google
              client="ca-pub-8191791319921859"
              slot={adCode}
              style={{ width: 250, height: 250, float: "left" }}
              layout="in-article"
              format=""
            />
          ) : (
            ""
          )}
        </div>
        <div className="flex justify-center w-full mt-2">
          {systemAdCode ? (
            <AdSense.Google
              client="ca-pub-8191791319921859"
              slot={systemAdCode}
              style={{ width: 250, height: 250, float: "left" }}
              layout="in-article"
              format=""
            />
          ) : (
            ""
          )}
        </div>
      </>
    ) : (
      <>
        <div className="flex justify-center w-full">
          {adCode && globalAds.length && (
            <img
              className="cursor-pointer"
              src={globalAds[Math.floor(Math.random() * 3)].image}
              onClick={() =>
                (window.location.href =
                  globalAds[Math.floor(Math.random() * 3)].link)
              }
            />
          )}
        </div>
        <div className="flex justify-center w-full">
          {systemAdCode && globalAds.length && (
            <img
              className="cursor-pointer"
              src={globalAds[Math.floor(Math.random() * 3)].image}
              onClick={() =>
                (window.location.href =
                  globalAds[Math.floor(Math.random() * 3)].link)
              }
            />
          )}
        </div>
        <p className="mt-0"></p>
      </>
    );
  };

  const RenderVideoBox = (data) => {
    console.log("hey", data.data);
    const html = data.data; //Your html string
    const tmpDiv = document.createElement("div");
    tmpDiv.innerHTML = html;
    const src = tmpDiv.querySelector(".video-box-xyz").getAttribute("src");
    const linkMode = tmpDiv
      .querySelector(".video-box-xyz")
      .getAttribute("linkMode");
    // console.log(tmpDiv);
    const opts = {
      width: isResponsiveMode() ? "100%" : "500px",
      height: isResponsiveMode() ? "250px" : "500px",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    };
    const getYoutubeVideoId = (url) => {
      const VID_REGEX =
        /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      return url.match(VID_REGEX)[1];
    };

    // const src = "";
    // const linkMode = "";

    const _onReady = (e) => {
      // access to player in all event handlers via event.target
      e.target.pauseVideo();
    };
    return (
      <div class="flex flex-wrap justify-center">
        <div className="relative">
          {linkMode == "link" ? (
            <YouTube
              videoId={getYoutubeVideoId(src)}
              opts={opts}
              onReady={_onReady}
            />
          ) : (
            <video
              controls="controls"
              className="w-full"
              src={src}
              type="video/mp4"
              height="200px"
            ></video>
          )}
        </div>
      </div>
    );
  };

  const RenderUPdatedMonetization = ({
    section,
    monetizedCount,
    basicCount,
  }) => {
    if (monetizedCount > 1) {
      //console.log("match", monetizedCount);
      return (
        <div className="w-full flex justify-center">
          <div
            className="w-full custom-page-content"
            dangerouslySetInnerHTML={{
              __html: section,
            }}
          ></div>
        </div>
      );
    }
    return (
      <div className="relative flex justify-center w-full">
        <div
          className="w-full custom-page-content"
          dangerouslySetInnerHTML={{
            __html: section,
          }}
        ></div>

        <div className="flex md:flex-row items-center gap-2 absolute top-0 bottom-0">
          {page.price && shoudShowPurchase(page) ? (
            <BasicButton
              title={`Purchase Page $${bookPriceWithSystemFees(page)}`}
              handleClick={() => toast("Preview Only", { type: "notice" })}
              classes="my-bg-yellow-500 text-black"
            />
          ) : (
            ""
          )}

          {page.owner &&
          page.owner.enable_subscription &&
          shoudShowSubscription(page) ? (
            <BasicButton
              title={`Subscribe $${subscritionCostWithSystemFees(page)}/mth`}
              handleClick={() => toast("Preview Only", { type: "notice" })}
              classes="my-bg-yellow-500 text-black"
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  const RenderFreeUserPageItems = (html) => {
    if (html.includes('style="color: transparent;')) {
      monetizationContentMatch++;
      basicContentMatch = 0;
      return (
        <RenderUPdatedMonetization
          section={html}
          monetizedCount={monetizationContentMatch}
          basicCount={basicContentMatch}
        />
      );
    }

    if (html.includes('class="signup-block') && !signupSuccess) {
      basicContentMatch++;
      monetizationContentMatch = 0;
      return <RenderSignupFoUpdatesBox />;
    }

    if (html.includes('class="video-box-xyz')) {
      basicContentMatch++;
      monetizationContentMatch = 0;
      return <RenderVideoBox data={html} />;
    }

    if (html.includes('class="image-link-xyz')) {
      basicContentMatch++;
      monetizationContentMatch = 0;
      return <RenderImageBox data={html} />;
    }
    if (html.includes('class="button-link-xyz')) {
      basicContentMatch++;
      monetizationContentMatch = 0;
      return <RenderButtonLinkBox data={html} />;
    }

    // if (html.includes('class="advert-block')) {
    //   basicContentMatch++;
    //   monetizationContentMatch = 0;
    //   return (
    //     <div className="flex justify-center">
    //       <img src="/ad_1.PNG" />
    //     </div>
    //   );
    // }

    if (html.includes('class="advert-block')) {
      basicContentMatch++;
      monetizationContentMatch = 0;
      return <RenderAdvertBox data={html} />;
    }

    basicContentMatch++;
    monetizationContentMatch = 0;
    return (
      <div
        className="custom-page-content"
        dangerouslySetInnerHTML={{ __html: html }}
      ></div>
    );
  };

  const hasNotScrolledToBottom = () => {
    if (page && page.owner && page.owner.id == authUserData().id) {
      return false;
    }
    if (!page.lastpageviewed) {
      return true;
    }
    return !page.lastpageviewed.filter((p) => p.user_id == authUserData().id)
      .length;
  };

  const bottomVisible = () => {
    const el = document.getElementById("bottom");
    const rect = el?.getBoundingClientRect();
    const visible =
      rect?.top >= 0 &&
      rect?.left >= 0 &&
      rect?.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth);

    if (
      page &&
      hasNotScrolledToBottom() &&
      hasPurchasedBook(page) &&
      !userHasScroledToBottomRef.at_bottom &&
      visible
    ) {
      // alert("About to record last page");
      userHasScroledToBottomRef.at_bottom = true;
      recordLastPageView(authUserData().uuid, page.uuid);
    }
    console.log("ppp", hasPurchasedBook(page) ? "Yes" : "No");
    return visible;
  };

  const setupScrollListener = () => {
    document.addEventListener("scroll", bottomVisible);
  };

  const recordLastPageView = (userId, bookId) => {
    const url = API_BASE + "/api/views/last-viewed";
    axios.post(url, { userId: userId, bookId: bookId }).catch((e) => {});
  };

  useEffect(() => {
    if (page) {
      if (!hasPurchasedBook(page)) {
        setTimeout(() => {
          insertDivIntoHTMLBlock();
        }, 500);
      } else {
      }
    }
  }, [webData]);

  useEffect(() => {
    if (page.id) {
    }
  }, [page]);

  useEffect(() => {
    fetchPage();
  }, []);

  return (
    <>
      {" "}
      <WebHeader />
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">
            {" "}
            {title && title}
          </h1>
        </div>
        <div className="flex flex-col md:flex-row  justify-between gap-2">
          {/* <BasicButton
            disabled={processing || !name || !coverPhoto}
            title={processing ? "Creating.." : "Crate Page"}
            handleClick={() => savePage()}
          /> */}
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div class=" px-6  w-full">
        <br />
        <div class="flex flex-col text-center md:text-left justify-evenly items-center">
          <div class="flex flex-col w-full items-center">
            <div></div>
            <h1 class="text-2xl text-gray-800 font-bold mt-2">
              {/* {title && title} */}
            </h1>
            {page.id ? (
              <div className="flex justify-center gap-2 my-4">
                <BasicButton
                  classes={"my-bg-yellow-500 text-black px-8"}
                  title={"By" + " " + page.owner.name}
                  handleClick={() =>
                    (window.location.href = `/web/${page.owner.uuid}`)
                  }
                />
                {page.owner.profile_photo ? (
                  <img
                    onClick={() =>
                      (window.location.href = `/web/${page.owner.uuid}`)
                    }
                    className="rounded-full h-8 w-8 cursor-pointer"
                    src={
                      BASE_URL +
                      "/storage/" +
                      page.owner.profile_photo.split("public")[1]
                    }
                  />
                ) : (
                  <img
                    onClick={() =>
                      (window.location.href = `/web/${page.owner.uuid}`)
                    }
                    className="rounded-full h-8 w-8 cursor-pointer"
                    src={"/avatar.png"}
                  />
                )}
                <span>
                  {" "}
                  {page.created_at
                    ? new Date(
                        page.refactoredCreatedAtTime.replace(/-/g, "/")
                      ).getDate() +
                      "-" +
                      (new Date(
                        page.refactoredCreatedAtTime.replace(/-/g, "/")
                      ).getMonth() +
                        1) +
                      "-" +
                      new Date(
                        page.refactoredCreatedAtTime.replace(/-/g, "/")
                      ).getFullYear()
                    : "N/A"}
                </span>
              </div>
            ) : (
              ""
            )}

            <br />

            <div className="w-full w-10/12 md:w-6/12 text-left">
              {page &&
                !hasPurchasedBook(page) &&
                webDataTokenized.map((html) => RenderFreeUserPageItems(html))}

              {page && hasPurchasedBook(page) && (
                <div
                  className="md:w-7/12 custom-page-content"
                  dangerouslySetInnerHTML={{ __html: webDataNoAds }}
                ></div>
              )}
              <p id="bottom"></p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <StripePaymentModal
        modalOpen={stripeModalOpen}
        hideModal={hideStripeModal}
        book={page}
      />
      <RegistrationModal
        modalOpen={registrationModalOpen}
        hideModal={hideRegistrationModal}
        book={page}
        paymentType={paymentType}
      />
      <StripeSubscriptionModal
        modalOpen={showSubModal}
        hideModal={hideSubModal}
        book={page}
        subSecretKey={subSecretKey}
      />
      {page.id ? (
        <PlatformUpdateModal
          modalOpen={platformModalOpen}
          hideModal={hidePLatformModal}
          type={"page"}
          showSignupSuccess={showSignupSuccess}
          book={page}
        />
      ) : (
        ""
      )}
    </>
  );
}
