import BasicButton from "../../../components/buttons/basic-button";
import { useNavigate } from "react-router-dom";
export default function AdminDesktopTable({ data, f }) {
  const { adsEnabled, dataSubType, totalEstAdsEarnings } = f;
  const navigate = useNavigate();

  return (
    <>
      <div className="overflow-x-scroll md:h-screen w-full">
        <table class="table-auto w-full">
          <thead className="text-base">
            <tr className={window.innerWidth < 720 ? "hidden" : ""}>
              <th class="px-4 py-2 text-gray-600">Date Created</th>
              <th class="px-4 py-2 text-gray-600">Date Publisher Status</th>
              <th class="px-4 py-2 text-gray-600">Name</th>
              <th class="px-4 py-2 text-gray-600">Email</th>
              <th class="px-4 py-2 text-gray-600">Phone</th>
              <th class="px-4 py-2 text-gray-600">Country</th>
              <th class="px-4 py-2 text-gray-600">Timezone</th>
              <th class="px-4 py-2 text-gray-600">Items</th>
              <th class="px-4 py-2 text-gray-600">Ads Enabled</th>
              <th class="px-4 py-2 text-gray-600">Total Data Used:</th>{" "}
              <th class="px-4 py-2 text-gray-600"> Data Threshold Tier:</th>{" "}
              <th class="px-4 py-2 text-gray-600">Total Ads Earnings</th>
              <th class="px-4 py-2 text-gray-600">Total Payment Earnings:</th>
              <th class="px-4 py-2 text-gray-600">
                {" "}
                Total Purchase Earnings:{" "}
              </th>
              <th class="px-4 py-2 text-gray-600">
                {" "}
                Total Subscription Earnings:{" "}
              </th>
              <th class="px-4 py-2 text-gray-600">
                {" "}
                Total Hire Purchase Earnings:{" "}
              </th>
              <th class="px-4 py-2 text-gray-600">
                {" "}
                Total Donation Earnings:{" "}
              </th>
              <th class="px-4 py-2 text-gray-600"> Clicks</th>
              <th class="px-4 py-2 text-gray-600"> Impressions</th>
              <th class="px-4 py-2 text-gray-600"> CTI Ratio</th>
              <th class="px-4 py-2 text-gray-600"> Total Views</th>
              <th class="px-4 py-2 text-gray-600"> Total Bot Count</th>
              <th class="px-4 py-2 text-gray-600"> Total Bot To Views Ratio</th>
              <th class="px-4 py-2 text-gray-600"> Supplier Enabled</th>
              <th class="px-4 py-2 text-gray-600"> Supplier Profile</th>
              <th class="px-4 py-2 text-gray-600">
                Total One-Time Product Earnings
              </th>
              <th class="px-4 py-2 text-gray-600">
                Total Recurring Product Earnings
              </th>
            </tr>
          </thead>
          <tbody className="w-full">
            {data.data
              ? data.data.map((p, i) => {
                  return (
                    <tr
                      className={
                        window.innerWidth < 820
                          ? `flex flex-col items-center my-8 w-full bg-gray-50 p-4 `
                          : ""
                      }
                    >
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs">
                          Date Created
                        </h3>
                        {p.simpleUserCreatedDate}
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs">
                          Date Publisher Status
                        </h3>
                        <span className="break-words">
                          {p.simpleVerificationDate}
                        </span>
                      </td>
                      <td class="border  px-4 py-2 font-medium text-center md:text-left">
                        <p className="break-words">
                          <p className="text-green-500"> First Name</p>
                          <small className="text-base"> {p.first_name}</small>
                        </p>
                        <p className="break-words">
                          <p className="text-green-500"> Last Name</p>
                          <small className="text-base"> {p.last_name}</small>
                        </p>
                        <p className="break-words">
                          <p className="text-green-500"> Username</p>
                          <small className="text-base"> {p.username}</small>
                        </p>
                        <p className="break-words">
                          <p className="text-green-500"> User Handle</p>
                          <small className="text-base"> {p.handle_id}</small>
                        </p>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left w-full">
                        <h3 className="font-bold md:hidden text-xxs">Email</h3>
                        <span className="break-words">{p?.email ?? "N/A"}</span>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left w-full">
                        <h3 className="font-bold md:hidden text-xxs">Phone</h3>
                        <span className="break-words">
                          {p?.verifiedphone?.phone ?? "N/A"}
                        </span>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs">
                          Country
                        </h3>
                        <span className="break-words">
                          {p?.country ?? "N/A"}
                        </span>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs">
                          Timezone
                        </h3>
                        <span className="break-words">
                          {p?.timezone ?? "N/A"}
                        </span>
                      </td>

                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs">Items</h3>
                        <a
                          href={`/admin/publisher/publications/options/${p.uuid}`}
                        >
                          <BasicButton
                            classes={"bg-black-500 w-full"}
                            title={p.books_count}
                            handleClick={null}
                          />
                        </a>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs">
                          Ads Enabled
                        </h3>
                        <span className="break-words">
                          <p> {adsEnabled(p).enabled}</p>
                          <p>{adsEnabled(p).provider}</p>
                        </span>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs">
                          Total Data Used
                        </h3>
                        <span className="break-words">
                          {p.active_data_usage} GB
                        </span>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs">
                          Data Threshold Tier
                        </h3>
                        <span className="break-words">{dataSubType(p)}</span>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs">
                          Total Ads Earnings
                        </h3>
                        <span className="break-words">
                          {totalEstAdsEarnings(p)}
                        </span>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs">
                          Total Payment Earnings
                        </h3>
                        <span className="break-words">
                          {p.total_payment_earning}
                        </span>
                      </td>
                      <td class="border px-4 py-2 font-medium text-center">
                        <div className="flex flex-col items-center gap-2">
                          <h3 className="font-bold md:hidden text-xxs">
                            Total Purchase Earnings
                          </h3>
                          <div>
                            <span className="text-green-500">Amount</span>
                            <p className="text-base">
                              $ {p?.total_publisher_payment_record?.sum}
                            </p>
                          </div>
                          <div>
                            <span className="text-green-500">
                              Total number of purchases
                            </span>
                            <p>{p?.total_publisher_payment_record?.count}</p>
                          </div>
                        </div>
                      </td>
                      <td class="border px-4 py-2  font-medium text-center ">
                        <div className="flex flex-col items-center gap-2">
                          <h3 className="font-bold md:hidden text-xxs">
                            Total Subscription Earnings
                          </h3>
                          <div>
                            <span className="text-green-500">Amount</span>
                            <p>
                              $ {p?.total_publisher_subscription_record?.sum}
                            </p>
                          </div>
                          <div>
                            <span className="text-green-500">
                              Total number of Subscribers
                            </span>
                            <p>
                              {p?.total_publisher_subscription_record?.count}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td class="border px-4 py-2  font-medium text-center ">
                        <div className="flex flex-col items-center gap-2">
                          <h3 className="font-bold md:hidden text-xxs">
                            Total Hire Purchase Earnings
                          </h3>
                          <div>
                            {" "}
                            <span className="text-green-500">Amount</span>
                            <p>
                              $ {p?.total_publisher_hire_purchase_record?.sum}
                            </p>
                          </div>
                          <div>
                            {" "}
                            <span className="text-green-500">
                              Total number of Hire Purchases
                            </span>
                            <p>
                              {p?.total_publisher_hire_purchase_record?.count}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center ">
                        <div className="flex flex-col items-center gap-2">
                          <h3 className="font-bold md:hidden text-xxs">
                            Total Donation Earnings
                          </h3>{" "}
                          <div>
                            <span className="text-green-500">Amount</span>
                            <p>
                              $ {p?.total_publisher_donation_record?.sum}
                            </p>{" "}
                          </div>
                          <div>
                            <span className="text-green-500">
                              Total number of Donors
                            </span>
                            <p>{p?.total_publisher_donation_record?.count}</p>{" "}
                          </div>
                        </div>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center ">
                        <div className="flex flex-col items-center gap-2">
                          <div>
                            <span className="text-green-500">Clicks</span>
                            <p>{p.clicks}</p>{" "}
                          </div>
                        </div>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center ">
                        <div className="flex flex-col items-center gap-2">
                          <div>
                            <span className="text-green-500">Impressions</span>
                            <p>{p.imps}</p>{" "}
                          </div>
                        </div>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center ">
                        <div className="flex flex-col items-center gap-2">
                          <div>
                            <span className="text-green-500">CTI Ratio</span>
                            <p>{Number(p.clicks) / Number(p.imps)}</p>{" "}
                          </div>
                        </div>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center ">
                        <div className="flex flex-col items-center gap-2">
                          <div>
                            <span className="text-green-500">Total Views</span>
                            <p>{p.total_content_bot_cti.total_views}</p>{" "}
                          </div>
                        </div>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center ">
                        <div className="flex flex-col items-center gap-2">
                          <div>
                            <span className="text-green-500">
                              Total Bot Count
                            </span>
                            <p>{p.total_content_bot_cti.total_bot_count}</p>{" "}
                          </div>
                        </div>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center ">
                        <div className="flex flex-col items-center gap-2">
                          <div>
                            <span className="text-green-500">
                              Total BTV Ratio
                            </span>
                            <p>{p.total_content_bot_cti.cti_ratio}</p>{" "}
                          </div>
                        </div>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center ">
                        <div className="flex flex-col items-center gap-2">
                          <div>
                            <span className="text-green-500">
                              Supplier Enabled
                            </span>
                            <p>{p.supplier_enabled}</p>{" "}
                          </div>
                        </div>
                      </td>

                      <td class="border  px-4 py-2  font-medium text-center ">
                        <div className="flex flex-col items-center gap-2">
                          <div>
                            <span className="text-green-500">
                              Supplier Profile
                            </span>
                            <p>
                              {p.supplier_profile == "N/A" &&
                                p.supplier_profile == "N/A"}

                              {p.supplier_profile == "view" && (
                                <BasicButton
                                  classes={"bg-black-500 w-full"}
                                  title={"View"}
                                  handleClick={() =>
                                    navigate(
                                      `/admin/supplier/profile/${p.uuid}`
                                    )
                                  }
                                />
                              )}
                              {p.supplier_profile == "applicant_page" && (
                                <BasicButton
                                  classes={"bg-black-500 w-full"}
                                  title={"Applicant Page"}
                                  handleClick={() =>
                                    navigate(
                                      `/admin/supplier/applications/applicant/${p.uuid}`
                                    )
                                  }
                                />
                              )}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs">
                          Total One-Time Product Earnings
                        </h3>
                        <span className="break-words">
                          ${p?.total_one_time_product_earnings}
                        </span>
                      </td>
                      <td class="border  px-4 py-2  font-medium text-center md:text-left">
                        <h3 className="font-bold md:hidden text-xxs">
                          Total Recurring Product Earnings
                        </h3>
                        <span className="break-words">
                          $ {p?.total_recurring_product_earnings * 100}
                        </span>
                      </td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
      </div>
    </>
  );
}
