import TimezoneList from "./list";
import { useState, useEffect } from "react";
import { useRef } from "react";
import { NewTimezoneList } from "./new-list";
export default function Timezones({ handleTimezoneChange, value }) {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [savingStage, setSavingStage] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    // var x = document.getElementById("droplist");
    // const zones = [];

    // for (let i = 0; i < x.options.length; i++) {
    //   console.log(x.options[i].value);
    //   if (i == 0) {
    //     continue;
    //   }
    //   zones.push({ index: i, name: x.options[i].value });
    // }
    // console.log("zones", zones);
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    setQuery(() => "");
    handleTimezoneChange(option["name"]);
    setIsOpen((isOpen) => !isOpen);
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (value) return value;
    return "";
  };

  const filter = (options) => {
    return options.filter(
      (option) => option["name"].toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  };
  return (
    <div class="mt-1 my-identity dropdown">
      {/* <label for="timezone" value="Your Timezone" />
      <select
        id="droplist"
        onChange={handleTimezoneChange}
        required
        class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full"
      >
        <option value="" default selected disabled>
          Choose an option
        </option>
        <TimezoneList />
      </select> */}
      <div className="control">
        <div className="selected-value">
          <input
            autocomplete="off"
            className="placeholder:text-xs md:placeholder:text-sm"
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            placeholder="Timezone (GMT, UTC..etc)"
            onChange={(e) => {
              setQuery(e.target.value);
              handleTimezoneChange(null);
            }}
            onClick={toggle}
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
      </div>

      <div className={`options ${isOpen ? "open" : ""}`}>
        {filter(NewTimezoneList).map((option, index) => {
          return (
            <div
              onClick={() => selectOption(option)}
              className={`option ${option["name"] === value ? "selected" : ""}`}
              key={`${"id"}-${index}`}
            >
              {option["name"]}
            </div>
          );
        })}
      </div>
    </div>
  );
}
