import { COLOR_TYPES } from "../editor-objects";
import { useState, useEffect, useRef } from "react";
import TextField from "../../../../components/inputs/text-input";
export default function ApplyFont(props) {
  const [visible, setVisible] = useState(false);
  const elemRef = useRef();

  const elemHasClick = (e) => {
    if (!elemRef?.current?.contains(e.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {}, [document.addEventListener("click", elemHasClick, true)]);

  return (
    <div
      className="flex flex-col bg-gray-50 px-1 rounded-lg relative cursor-pointer"
      ref={elemRef}
    >
      <div
        onClick={() => {
          setVisible((prev) => !prev);
        }}
      >
        <span>font size</span>
        <TextField
          value={props.defaultFontSize}
          classes="w-16 h-6"
          disabled={true}
        />
      </div>

      <div
        className={`absolute top-0 bg-white w-16 h-80 overflow-y-scroll z-50 p-1 shadow   ${
          visible ? "" : "hidden"
        }`}
      >
        {Object.keys(COLOR_TYPES)
          .filter((item) => item !== "premium")
          .map((f, i) => (
            <span
              className="hover:bg-gray-200 cursor-pointer flex  justify-center"
              onClick={() => {
                setVisible(false);
                props.setDefaultFontSize(COLOR_TYPES[f].fontSize);
                props.onClick(f);
              }}
            >
              {COLOR_TYPES[f].fontSize}
            </span>
          ))}
      </div>
    </div>
  );
}
