import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import TextField from "../../../components/inputs/text-input";
import BasicButton from "../../../components/buttons/basic-button";
import StripeRedirectModal from "../../../modals/stripe-redirect-modal";
import StripeRedirectModeModal from "../../../modals/stripe-redirect-mode-modal";
import DeletePromptModal from "../../../modals/delete-warning-modal";
import StripeDataThresholdSubModal from "../../../modals/stripe-datathreshold-sub-modal";

import {
  isAdmin,
  API_BASE,
  isPublisher,
  getLocation,
  authUserData,
  isAuthUser,
  getClientBaseURL,
} from "../../../Utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";
import Switch from "react-switch";
import PasswordPromptModal from "../../../modals/password-prompt-modal";
import CreateTierModal from "../../../modals/create-tier-modal";
export default function PublisherDashboardStorageSubscriptions() {
  const [tiers, setTiers] = useState({});
  const [subscriptions, setSubscriptions] = useState([]);
  const [showSubModal, setShowSubModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [subSecretKey, setSubSecretKey] = useState();
  const [dataThreshold, setDataThreshold] = useState();
  const [deleteTierModalOpen, setDeleteTierModalOpen] = useState(false);
  const [user, setUser] = useState({});
  const [activeSub, setActiveSub] = useState({});
  const [subsToDisable, setActiveSubsToDisable] = useState([]);

  const getStorageSubscriptions = () => {
    let url = API_BASE + "/api/storage/my/subscriptions/" + authUserData().uuid;

    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setSubscriptions(response.data.subscriptions);
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  const getUser = () => {
    let url = API_BASE + "/api/publisher/" + authUserData().uuid + "/optimized";
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.publisher.has_sub_record) {
            localStorage.setItem("has_sub_record", "exists");
          }
          setUser(response.data.publisher);
          let sub = response.data.publisher.activedatasub;
          if (sub) {
            setActiveSub(sub);
          }
        }
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  useEffect(() => {
    if (!isPublisher() || !isAuthUser()) {
      window.location.href = "/";
    }
    getStorageSubscriptions();
  }, []);

  //   useEffect(() => {
  //     if (tiers.data && activeSub.active) {
  //       console.log(activeSub);
  //       let activeThreshold = activeSub.datathreshold.threshold;
  //       console.log("threshold", activeThreshold);
  //       const filteredTierToDisable = tiers.data
  //         .filter(
  //           (d) =>
  //             d.uuid == activeSub.datathreshold.uuid ||
  //             Number(d.threshold) < Number(activeThreshold)
  //         )
  //         .map((d) => d.uuid);
  //       setActiveSubsToDisable(filteredTierToDisable);
  //     }
  //   }, [activeSub]);

  return (
    <>
      <div className="bg-white m-2 p-2 flex justify-between shadow">
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">
            Payment History
          </h1>
        </div>
        <div className="flex gap-2">
          <BasicButton
            title={"Go Back"}
            handleClick={() => window.history.back()}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center gap-4 mt-8 items-center mb-4">
        {/* <h1 className="font-bold text-xl">Storage Tiers</h1> */}

        <div className="w-full p-8 flex flex-col">
          <div className="hidden md:flex justify-between w-full border-b p-4">
            {" "}
            <div className="text-center w-full font-bold">Tier</div>
            <div className="text-center w-full font-bold">Price</div>
            <div className="text-center w-full font-bold">Status</div>
            <div className="text-center w-full font-bold">Starts</div>
            <div className="text-center w-full font-bold">Ends</div>
          </div>

          {subscriptions.data
            ? subscriptions.data.map((sub) => (
                <div className="flex flex-col gap-2 md:flex-row justify-between w-full border-b p-4 bg-gray-50 mb-1">
                  {" "}
                  <div className="text-center w-full">
                    <p className="md:hidden font-bold">Name</p>
                    {sub.datathreshold.name}
                  </div>
                  <div className="text-center w-full">
                    {" "}
                    <p className="md:hidden font-bold">Price</p>
                    {sub.billing_cycle == "full"
                      ? "$" + sub.unit_amount + "/ Month"
                      : ""}
                    {sub.billing_cycle == "prorated" ? (
                      <>
                        <p className="text-base">
                          Remnant: ${sub.unit_amount}{" "}
                        </p>
                        <p className="text-base">
                          Renews At: $
                          {sub.datathreshold.latest_price.unit_amount}/ Month
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-center w-full">
                    {" "}
                    <p className="md:hidden font-bold">Status</p>
                    {sub.active ? "Active" : "Cancelled"}
                  </div>
                  <div className="text-center w-full">
                    {" "}
                    <p className="md:hidden font-bold">Starts</p>
                    {sub.refactored_start_date}
                  </div>
                  <div className="text-center w-full">
                    {" "}
                    <p className="md:hidden font-bold">Ends</p>
                    {sub.refactored_end_date}
                  </div>
                </div>
              ))
            : ""}
        </div>
      </div>
    </>
  );
}
