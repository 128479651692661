const PaymentControls = (props) => {
  const { showPaymentControlsBox } = props;
  return (
    <button
      className="border border-gray rounded-lg p-1 m-1"
      onClick={() => showPaymentControlsBox()}
    >
      Payment Options{" "}
    </button>
  );
};

export default PaymentControls;
