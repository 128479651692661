export default function LoadingStatus(props) {
  const { processing } = props;

  return processing ? (
    <div className="flex justify-center w-full my-1 md:my-2 mb-4">
      <button
        disabled={true}
        class={`whitespace-nowrap inline-flex items-center justify-center px-8 py-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white ${"bg-gray-500 hover:bg-gray-500"} hover:bg-black md:mr-8 `}
      >
        {processing ? "Loading..." : "View More"}
      </button>
      <br />
    </div>
  ) : (
    ""
  );
}
