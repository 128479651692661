import { deleteAd } from "../helpers/functions";
import { isResponsiveMode } from "../../../../Utils/helpers";
import { removeAtomicBlocFromEditor } from "../helpers/functions";
import { deleteEditorVideoFileRequest } from "../API/editor-api-calls";
import { useBookStore } from "../../../../stores/book-store";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { toast } from "react-toastify";
import YouTube from "react-youtube";
import { memo } from "react";
import { useMemo } from "react";
import getVideoId from "get-video-id";

const Video = (props) => {
  const videoRef = useRef();
  const playvideoRef = useRef();
  const pausevideoRef = useRef();
  const timerRef = useRef();
  const timerBarRef = useRef();
  const isPlayingRef = useRef();
  const play = document.querySelector(".play");
  const [timer, setTimer] = useState("00:00");
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoDuration, setideoDuration] = useState("");

  const {
    blockKey,
    src,
    linkMode,
    videoIdentifier,
    editorState,
    setEditorState,
    editorStateHelper,
  } = props;

  const { isDataSubExpired } = useBookStore((state) => state);

  const opts = {
    width: isResponsiveMode() ? "100%" : "100%",
    height: isResponsiveMode() ? "250px" : "500px",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  const youtubeVideoIdExists = (url) => {
    if (!url) {
      return false;
    }

    let { id, service } = getVideoId(url);

    if (!id || service !== "youtube") {
      return false;
    }

    return getVideoId(url);
  };

  const _onReady = (e) => {
    // access to player in all event handlers via event.target
    e.target.pauseVideo();
  };

  useEffect(() => {}, []);

  const deleteEditorVideo = (
    blockKey,
    editorState,
    setEditorState,
    EditorState,
    videoIdentifier
  ) => {
    deleteEditorVideoFileRequest(videoIdentifier)
      .then((response) => {
        //delete block from editor
        removeAtomicBlocFromEditor(
          blockKey,
          editorState,
          setEditorState,
          EditorState
        );
        toast("Deleted!", { type: "success" });
      })
      .catch((error) => {
        toast("Something went wrong!", { type: "error" });
      });
  };

  return (
    <div class="flex flex-wrap justify-center relative bg-gray-50 my-2">
      {!isDataSubExpired ? (
        <span
          className="bg-white rounded rounded-full bg-white p-0  right-0 absolute px-1 cursor-pointer"
          style={{ top: -10, right: 0 }}
          onClick={() => {
            deleteEditorVideo(
              blockKey,
              editorState,
              setEditorState,
              editorStateHelper,
              videoIdentifier
            );
          }}
        >
          <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
        </span>
      ) : (
        ""
      )}
      {linkMode == "link" && youtubeVideoIdExists(src) ? (
        <YouTube
          videoId={youtubeVideoIdExists(src).id}
          opts={opts}
          onReady={_onReady}
          className="w-full"
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Video;
