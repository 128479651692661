import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import WebHeader from "./web/header";
import { isAdmin } from "../Utils/helpers";
import { isAuthUser, API_BASE } from "../Utils/helpers";
import Footer from "../layout/footer";
import TermsAccordion from "../accordions/terms-accordion";
import axios from "axios";

export default function PaymentTerms() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const naviagte = useNavigate();

  return (
    <>
      {" "}
      <WebHeader />
      <div class=" px-6 ">
        <br />
        <div className="flex justify-center">
          <div className=" rounded-lg py-4 px-2 w-full md:w-8/12">
            <h3 className="font-bold text-center text-3xl mb-6">
              Payment Terms
            </h3>

            <div>
              <p class="payment-terms-c0">
                <span class="c3 font-bold text-lg">
                  Agreement between User and Litzapp.com
                </span>
              </p>
              <p class="payment-terms-c1">
                <span>
                  Welcome to Litzapp. The Litzapp platform (the &quot;
                </span>
                <span class="payment-terms-c0 font-bold">Platform</span>
                <span>
                  &quot;) is owned and operated by Remote Analytics Jobs Inc.
                  Litzapp is offered to you conditioned on your acceptance
                  without modification of the terms, conditions, and notices
                  contained herein (the &quot;
                </span>
                <span class="payment-terms-c0 font-bold">Payment Terms</span>
                <span class="payment-terms-c1">
                  &quot;). Your use of Litzapp constitutes your agreement to all
                  such Payment Terms. Please read these terms carefully, and
                  keep a copy of them for your reference.
                </span>
              </p>
              <br />
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  Litzapp is a literary marketplace platform where writers,
                  authors, photographers, and other digital content creators can
                  publish and sell their works in digital format at prices they
                  set.{" "}
                </span>
              </p>
              <p class="payment-terms-c2 c3">
                <span class="payment-terms-c1"></span>
              </p>
              <p class="payment-terms-c2">
                <span class="c4 payment-terms-c0">Terms and Conditions</span>
              </p>
              <p class="payment-terms-c2">
                <span>
                  By accessing or using Litzapp, you agree to be bound by
                  Litzapp&#39;s{" "}
                </span>
                <span class="payment-terms-c0">Terms and Conditions</span>
                <span class="payment-terms-c1">
                  . You acknowledge and agree that your use of Litzapp is
                  subject to the Payment Terms, which govern the payment and
                  payout processes on Litzapp. It is your responsibility to
                  review and comply with both the Terms and Conditions and the
                  Payment Terms. Litzapp reserves the right to update or modify
                  these terms at any time without prior notice to you. Your
                  continued use of Litzapp following any changes to the Terms
                  and Conditions or Payment Terms constitutes your acceptance of
                  such changes.
                </span>
              </p>
              <p class="payment-terms-c2 c3">
                <span class="payment-terms-c1"></span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c0">Privacy</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  Your use of Litzapp is subject to Litzapp&#39;s Privacy
                  Policy. Please review our Privacy Policy, which also governs
                  the Platform and informs users of our data collection
                  practices.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c0">Electronic Communications</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  Any visit to Litzapp or communication through email to Litzapp
                  shall be considered as electronic communication. By accessing
                  Litzapp or sending electronic communication, you give your
                  consent to receive electronic communication from Litzapp. You
                  further agree that any agreements, notices, disclosures, and
                  other communication provided to you by Litzapp electronically,
                  either through email or the platform, satisfy any legal
                  requirements that such communication be in writing.
                </span>
              </p>
              <p class="payment-terms-c2 c3">
                <span class="payment-terms-c1"></span>
              </p>
              <p class="payment-terms-c2">
                <span class="c4 payment-terms-c0">Prices</span>
              </p>
              <p class="payment-terms-c2">
                <span>The term &quot;</span>
                <span class="payment-terms-c0">Publisher</span>
                <span>
                  &quot; shall refer to any user who publishes and sells digital
                  content (&ldquo;
                </span>
                <span class="payment-terms-c0">Content</span>
                <span class="payment-terms-c1">
                  &rdquo;) on the Platform. Publishers are allowed to set the
                  price for their Content at their discretion. Litzapp does not
                  set the price for Content on the platform.
                </span>
              </p>
              <p class="payment-terms-c2 c3">
                <span class="payment-terms-c1"></span>
              </p>
              <p class="payment-terms-c2">
                <span class="c4 payment-terms-c0">Payment Options</span>
              </p>
              <p class="payment-terms-c2">
                <span>Content buyers (&quot;</span>
                <span class="payment-terms-c0">Buyers</span>
                <span class="payment-terms-c1">
                  &quot;) can pay for Content or subscribe to a Publisher&#39;s
                  account on Litzapp using the payment methods available on
                  Litzapp, which may include credit/debit cards, online banking,
                  e-wallets, or other forms of digital payment. Buyers
                  acknowledge and agree that the availability of specific
                  payment methods may be subject to change without prior notice.
                  Litzapp may add or remove payment methods at its sole
                  discretion. Buyers further acknowledge and agree that payment
                  processing times may vary depending on the payment method
                  chosen by the Buyer. Litzapp is not liable for any losses or
                  damages resulting from payment processing delays or failures
                  caused by the payment method selected by the Buyer. By making
                  a payment, the Buyer represents and warrants that he or she is
                  authorized to use the selected payment method and that the
                  payment information provided is accurate and up-to-date.
                  Litzapp reserves the right to refuse or cancel any payment or
                  subscription at any time for any reason.
                </span>
              </p>
              <p class="payment-terms-c2 c3">
                <span class="payment-terms-c1"></span>
              </p>
              <p class="payment-terms-c2">
                <span class="c4 payment-terms-c0">Subscription Fees</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  By subscribing to the account of a Publisher on Litzapp,
                  Buyers agree to pay a recurring subscription fee set by the
                  Publisher. The subscription fee will be deducted from the
                  Buyer&#39;s account on a recurring 30-day basis until the
                  subscription is canceled by the Buyer. Buyers acknowledge and
                  agree that they are solely responsible for canceling their
                  subscription in order to avoid future charges. Litzapp and the
                  Publisher shall not be liable for any charges incurred by a
                  Buyer who fails to cancel their subscription in a timely
                  manner. Buyers should be aware that the amount of the
                  recurring subscription fee can change depending on increases
                  or reductions in Litzapp fees, payment gateway fees, taxes, or
                  other factors as determined by the Publisher or applicable
                  laws and regulations. By continuing to use the subscription
                  service after the effective date of the fee change, Buyers
                  acknowledge and agree to the new subscription fee.
                </span>
              </p>
              <p class="payment-terms-c2 c3">
                <span class="payment-terms-c1"></span>
              </p>
              <p class="payment-terms-c2">
                <span class="c4 payment-terms-c0">Litzapp Fees</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  Litzapp will charge a fee for every purchase or subscription
                  made on the platform, which may include a convenience fee, a
                  transaction fee, or other fees as determined by Litzapp at its
                  sole discretion. The fee is subject to change without prior
                  warning to Publishers or Buyers. Publishers acknowledge and
                  agree that the fee will be deducted from the final payout sent
                  to the Publisher. Buyers acknowledge and agree that the fee
                  will be added to the total amount payable for the purchase or
                  subscription. Litzapp is not liable for any losses or damages
                  resulting from changes in the fee structure or from any delays
                  or failures in processing payments or payouts caused by
                  factors beyond Litzapp&#39;s control.
                </span>
              </p>
              <p class="payment-terms-c2 c3">
                <span class="payment-terms-c1"></span>
              </p>
              <p class="payment-terms-c2">
                <span class="c4 payment-terms-c0">
                  Payout Service Provider Fees And Taxes
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  In addition to Litzapp fees, other payout service provider
                  fees and taxes may be deducted from the final payout sent to
                  the Publisher. These fees may include bank charges, currency
                  conversion fees, or other fees as determined by the payout
                  service provider or applicable laws and regulations. The
                  amount of these fees may vary depending on various factors,
                  including the Publisher&#39;s location, the payout amount, and
                  the currency of the payout. These fees will be disclosed to
                  the Publisher prior to the payout. The Publisher acknowledges
                  and agrees that he or she is solely responsible for any taxes,
                  fees, or other charges that may be imposed by applicable laws
                  or regulations in connection with his or her use of Litzapp.
                  Litzapp is not liable for any losses or damages resulting from
                  changes in the fee structure or from any delays or failures in
                  processing payments or payouts caused by factors beyond
                  Litzapp&#39;s control.
                </span>
              </p>
              <p class="payment-terms-c2 c3">
                <span class="payment-terms-c1"></span>
              </p>
              <p class="payment-terms-c2">
                <span class="c4 payment-terms-c0">
                  Payment Gateway Fees And Taxes
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  Buyers agree to pay payment gateway fees and taxes when making
                  purchases or subscribing to a Publisher&#39;s account. These
                  fees and taxes are subject to change without prior warning to
                  Buyers. The total amount charged to the Buyer will include
                  these fees and taxes. By using the Litzapp platform, Buyers
                  acknowledge and agree to the payment of these fees and taxes.
                </span>
              </p>
              <p class="payment-terms-c2 c3">
                <span class="payment-terms-c1"></span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c0 c4">Payouts</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  Publishers will receive payouts for their Content through
                  Litzapp. The payout amount will be calculated by subtracting
                  Litzapp&#39;s fees, other payout service provider fees and
                  taxes, and any refunds or chargebacks from the total amount
                  due to the Publisher. Publishers acknowledge and agree that
                  exchange rates may also affect the payout amount received. The
                  payout will be made to the Publisher&#39;s designated account
                  on Litzapp. Publishers are solely responsible for ensuring
                  that the payment information they provide is accurate and
                  up-to-date. Litzapp is not liable for any losses or damages
                  resulting from incorrect payment information provided by the
                  Publisher. Litzapp may withhold or delay payouts if it has
                  reasonable grounds to suspect fraudulent or illegal
                  activities.
                </span>
              </p>
              <p class="payment-terms-c2 c3">
                <span class="payment-terms-c1"></span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c0">Payout Processing Time</span>
                <span class="payment-terms-c1">
                  <br />
                  The timing of a Publisher&#39;s receipt of payout from Litzapp
                  is subject to the processing times of the platform&#39;s
                  payout service provider and the Publisher&#39;s bank. Litzapp
                  is not responsible for any delays in payout caused by the
                  payout service provider or the Publisher&#39;s bank.
                  Publishers acknowledge and agree that payout processing times
                  may vary based on various factors, including but not limited
                  to, the amount of the payout, the payout service
                  provider&#39;s processing times, and the Publisher&#39;s
                  bank&#39;s processing times. Litzapp is not liable for any
                  damages resulting from delayed or failed payouts.
                </span>
              </p>
              <p class="payment-terms-c2 c3">
                <span class="payment-terms-c1"></span>
              </p>
              <p class="payment-terms-c2">
                <span class="c4 payment-terms-c0">Refunds</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  In case of any refund, kindly refer to the Litzapp Terms and
                  Conditions.
                </span>
              </p>
              <p class="payment-terms-c2 c3">
                <span class="payment-terms-c1"></span>
              </p>
              <p class="payment-terms-c2">
                <span class="c4 payment-terms-c0">Disputes</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  In case of any disputes related to payments, kindly refer to
                  the Litzapp Terms and Conditions..
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c0">
                  Links to Third Party Sites/Third Party Services
                </span>
              </p>
              <p class="payment-terms-c2">
                <span>
                  Litzapp may contain links to other websites or apps (&quot;
                </span>
                <span class="payment-terms-c0">Linked Sites</span>
                <span class="payment-terms-c1">
                  &quot;). The Linked Sites are not under the control of Litzapp
                  and Litzapp is not responsible for the contents of any Linked
                  Site, including without limitation any link contained in a
                  Linked Site, or any changes or updates to a Linked Site.
                  Litzapp is providing these links to you only as a convenience,
                  and the inclusion of any link does not imply endorsement by
                  Litzapp of the Platform or any association with its operators.
                </span>
              </p>
              <p class="payment-terms-c2 c3">
                <span class="payment-terms-c1"></span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  Certain services made available via Litzapp are delivered by
                  third-party sites and organizations. By using any product,
                  service or functionality originating from the Litzapp domain,
                  you hereby acknowledge and consent that Litzapp may share such
                  information and data with any third party with whom Litzapp
                  has a contractual relationship to provide the requested
                  product, service or functionality on behalf of Litzapp users
                  and customers.
                </span>
              </p>
              <p class="c9">
                <span>
                  Currently, the sole payment processor and payout processor
                  (&ldquo;
                </span>
                <span class="payment-terms-c0">Processor</span>
                <span>
                  &rdquo;) on the Litzapp Platform is Stripe. Stripe, Inc. is
                  organized under the laws of Delaware, which is a technical
                  services provider and may offer the services as an agent of
                  one or more financial institutions in the United States (each,
                  a &ldquo;
                </span>
                <span class="payment-terms-c0">
                  Financial Services Provider
                </span>
                <span>
                  &rdquo;). The processing and settlement of Transactions
                  (&ldquo;
                </span>
                <span class="payment-terms-c0">Payment Processing</span>
                <span>
                  &rdquo;) are carried out by the Processor and any of the
                  Financial Services Providers under a separate Stripe Checkout
                  User Terms of Service and/or Stripe Connected Account
                  Agreement, including the United States Stripe Services
                  Agreement and the applicable Financial Services Terms, and to
                  the extent you use a payment and/or payout method that is
                  subject to additional terms (collectively, the &ldquo;
                </span>
                <span class="payment-terms-c0">Processor Terms</span>
                <span>
                  &rdquo;). By accepting this Agreement, you are also accepting
                  and agreeing to be bound by the Processor Terms, which is the
                  legal agreement between you and the Processor.
                </span>
              </p>
              <p class="payment-terms-c2 c3">
                <span class="payment-terms-c1"></span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c0">
                  No Unlawful or Prohibited Use/Intellectual Property{" "}
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  You are granted a non-exclusive, non-transferable, revocable
                  license to access and use Litzapp strictly in accordance with
                  these terms of use. As a condition of your use of the
                  Platform, you warrant to Litzapp that you will not use the
                  Platform for any purpose that is unlawful or prohibited by
                  these Terms. You may not use the Platform in any manner which
                  could damage, disable, overburden, or impair the Platform or
                  interfere with any other party&#39;s use and enjoyment of the
                  Platform. You may not obtain or attempt to obtain any
                  materials or information through any means not intentionally
                  made available or provided for through the Platform.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  All content included as part of the Service, such as text,
                  graphics, logos, images, as well as the compilation thereof,
                  and any software used on the Platform, is the property of
                  Litzapp or its suppliers and protected by copyright and other
                  laws that protect intellectual property and proprietary
                  rights. The concept of allowing users to view and read books
                  for free while placing advertisements in between the pages is
                  the exclusive intellectual property of Litzapp. Also, the
                  concept of the Save Page Position functionality that allows
                  users to access Saved Pages&ndash;via a link&ndash;only on the
                  browser and/or device through which the page was saved, is the
                  exclusive intellectual property of Litzapp. You agree to
                  observe and abide by all copyright and other proprietary
                  notices, legends or other restrictions contained in any such
                  content and will not make any changes thereto.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  You will not modify, publish, transmit, reverse engineer,
                  participate in the transfer or sale, create derivative works,
                  or in any way exploit&nbsp;in whole or in part any of the
                  content found on the Platform or the Litzapp platform itself.
                  Litzapp content is not for resale. Your use of the Platform
                  does not entitle you to make any unauthorized use of any
                  protected content, and in particular you will not delete or
                  alter any proprietary rights or attribution notices in any
                  content. You will use protected content solely for your
                  personal use, and will make no other use of the content
                  without the express written permission of Litzapp and the
                  copyright owner. You agree that you do not acquire any
                  ownership rights in any protected content. We do not grant you
                  any licenses, express or implied, to the intellectual property
                  of Litzapp or our licensors except as expressly authorized by
                  these Terms.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c0">
                  Use of Communication Services
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  The Platform may contain bulletin board services, chat areas,
                  news groups, forums, communities, personal web pages,
                  calendars, and/or other message or communication facilities
                  designed to enable you to communicate with the public at large
                  or with a group (collectively, &quot;Communication
                  Services&quot;). You agree to use the Communication Services
                  only to post, send and receive messages and material that are
                  proper and related to the particular Communication Service.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  By way of example, and not as a limitation, you agree that
                  when using a Communication Service, you will not: defame,
                  abuse, harass, stalk, threaten or otherwise violate the legal
                  rights (such as rights of privacy and publicity) of others;
                  publish, post, upload, distribute or disseminate any
                  inappropriate, profane, defamatory, infringing, obscene,
                  indecent or unlawful topic, name, material or information;
                  upload files that contain software or other material protected
                  by intellectual property laws (or by rights of privacy of
                  publicity) unless you own or control the rights thereto or
                  have received all necessary consents; upload files that
                  contain viruses, corrupted files, or any other similar
                  software or programs that may damage the operation of
                  another&#39;s computer; advertise or offer to sell or buy any
                  goods or services for any business purpose, unless such
                  Communication Service specifically allows such messages;
                  conduct or forward surveys, contests, pyramid schemes or chain
                  letters; download any file posted by another user of a
                  Communication Service that you know, or reasonably should
                  know, cannot be legally distributed in such manner; falsify or
                  delete any author attributions, legal or other proper notices
                  or proprietary designations or labels of the origin or source
                  of software or other material contained in a file that is
                  uploaded; restrict or inhibit any other user from using and
                  enjoying the Communication Services; violate any code of
                  conduct or other guidelines which may be applicable for any
                  particular Communication Service; harvest or otherwise collect
                  information about others, including e-mail addresses, without
                  their consent; violate any applicable laws or regulations.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  Litzapp has no obligation to monitor the Communication
                  Services. However, Litzapp reserves the right to review
                  materials posted to a Communication Service and to remove any
                  materials in its sole discretion. Litzapp reserves the right
                  to terminate your access to any or all of the Communication
                  Services at any time without notice for any reason whatsoever.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  Litzapp reserves the right at all times to disclose any
                  information as necessary to satisfy any applicable law,
                  regulation, legal process or governmental request, or to edit,
                  refuse to post or to remove any information or materials, in
                  whole or in part, in Litzapp&#39;s sole discretion.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  Always use caution when giving out any personally identifying
                  information about yourself or your children in any
                  Communication Service. Litzapp does not control or endorse the
                  content, messages or information found in any Communication
                  Service and, therefore, Litzapp specifically disclaims any
                  liability with regard to the Communication Services and any
                  actions resulting from your participation in any Communication
                  Service. Managers and hosts are not authorized Litzapp
                  spokespersons, and their views do not necessarily reflect
                  those of Litzapp.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  Materials uploaded to a Communication Service may be subject
                  to posted limitations on usage, reproduction and/or
                  dissemination. You are responsible for adhering to such
                  limitations if you upload the materials.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c0">
                  Materials Provided to Litzapp or Posted on Any Litzapp Web
                  Page
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  Litzapp does not claim ownership of the materials you provide
                  to Litzapp (including feedback and suggestions) or post,
                  upload, input or submit to any Litzapp Site or our associated
                  services (collectively &quot;Submissions&quot;). However, by
                  posting, uploading, inputting, providing or submitting your
                  Submission you are granting Litzapp, our affiliated companies
                  and necessary sublicensees permission to use your Submission
                  in connection with the operation of their Internet businesses
                  including, without limitation, the rights to: copy,
                  distribute, transmit, publicly display, publicly perform,
                  reproduce, edit, translate and reformat your Submission; and
                  to publish your name in connection with your Submission.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  No compensation will be paid with respect to the use of your
                  Submission, as provided herein. Litzapp is under no obligation
                  to post or use any Submission you may provide and may remove
                  any Submission at any time in Litzapp&#39;s sole discretion.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  By posting, uploading, inputting, providing or submitting your
                  Submission you warrant and represent that you own or otherwise
                  control all of the rights to your Submission as described in
                  this section including, without limitation, all the rights
                  necessary for you to provide, post, upload, input or submit
                  the Submissions.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c0">Third Party Accounts</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  You will be able to connect your Litzapp account to third
                  party accounts. By connecting your Litzapp account to your
                  third party account, you acknowledge and agree that you are
                  consenting to the continuous release of information about you
                  to others (in accordance with your privacy settings on those
                  third party sites). If you do not want information about you
                  to be shared in this manner, do not use this feature.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c0">International Users</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  The Service is controlled, operated and administered by
                  Litzapp from our offices within the USA. If you access the
                  Service from a location outside the USA, you are responsible
                  for compliance with all local laws. You agree that you will
                  not use the Litzapp Content accessed through Litzapp in any
                  country or in any manner prohibited by any applicable laws,
                  restrictions or regulations.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c0">Indemnification</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  You agree to indemnify, defend and hold harmless Litzapp, its
                  officers, directors, employees, agents and third parties, for
                  any losses, costs, liabilities and expenses (including
                  reasonable attorney&#39;s fees) relating to or arising out of
                  your use of or inability to use the Platform or services, any
                  user postings made by you, your violation of any terms of this
                  Agreement or your violation of any rights of a third party, or
                  your violation of any applicable laws, rules or regulations.
                  Litzapp reserves the right, at its own cost, to assume the
                  exclusive defense and control of any matter otherwise subject
                  to indemnification by you, in which event you will fully
                  cooperate with Litzapp in asserting any available defenses.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c0">Arbitration</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  In the event the parties are not able to resolve any dispute
                  between them arising out of or concerning these Terms and
                  Conditions, or any provisions hereof, whether in contract,
                  tort, or otherwise at law or in equity for damages or any
                  other relief, then such dispute shall be resolved only by
                  final and binding arbitration pursuant to the Federal
                  Arbitration Act, conducted by a single neutral arbitrator and
                  administered by the American Arbitration Association, or a
                  similar arbitration service selected by the parties, in a
                  location mutually agreed upon by the parties. The
                  arbitrator&#39;s award shall be final, and judgment may be
                  entered upon it in any court having jurisdiction. In the event
                  that any legal or equitable action, proceeding or arbitration
                  arises out of or concerns these Terms and Conditions, the
                  prevailing party shall be entitled to recover its costs and
                  reasonable attorney&#39;s fees. The parties agree to arbitrate
                  all disputes and claims in regards to these Terms and
                  Conditions or any disputes arising as a result of these Terms
                  and Conditions, whether directly or indirectly, including Tort
                  claims that are a result of these Terms and Conditions. The
                  parties agree that the Federal Arbitration Act governs the
                  interpretation and enforcement of this provision. The entire
                  dispute, including the scope and enforceability of this
                  arbitration provision shall be determined by the Arbitrator.
                  This arbitration provision shall survive the termination of
                  these Terms and Conditions.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c0">Class Action Waiver</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  Any arbitration under these Terms and Conditions will take
                  place on an individual basis; class arbitrations and
                  class/representative/collective actions are not permitted. THE
                  PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER
                  ONLY IN EACH&#39;S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF
                  OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR
                  REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE
                  ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless
                  both you and Litzapp agree otherwise, the arbitrator may not
                  consolidate more than one person&#39;s claims, and may not
                  otherwise preside over any form of a representative or class
                  proceeding.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c0">Liability Disclaimer</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN
                  OR AVAILABLE THROUGH the Platform MAY INCLUDE INACCURACIES OR
                  TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
                  INFORMATION HEREIN. Remote Analytics Jobs Inc. AND/OR ITS
                  SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN the Platform
                  AT ANY TIME.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  Remote Analytics Jobs Inc. AND/OR ITS SUPPLIERS MAKE NO
                  REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY,
                  AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION,
                  SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON
                  the Platform FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED
                  BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS,
                  SERVICES AND RELATED GRAPHICS ARE PROVIDED &quot;AS IS&quot;
                  WITHOUT WARRANTY OR CONDITION OF ANY KIND. Remote Analytics
                  Jobs Inc. AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES
                  AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE,
                  PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED
                  WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
                  PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                  SHALL Remote Analytics Jobs Inc. AND/OR ITS SUPPLIERS BE
                  LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,
                  SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER
                  INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA
                  OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE
                  USE OR PERFORMANCE OF the Platform, WITH THE DELAY OR
                  INABILITY TO USE the Platform OR RELATED SERVICES, THE
                  PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY
                  INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
                  OBTAINED THROUGH the Platform, OR OTHERWISE ARISING OUT OF THE
                  USE OF the Platform, WHETHER BASED ON CONTRACT, TORT,
                  NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF Remote
                  Analytics Jobs Inc. OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED
                  OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME
                  STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION
                  OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE
                  ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED
                  WITH ANY PORTION OF the Platform, OR WITH ANY OF THESE TERMS
                  OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING
                  the Platform.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c0">
                  Termination/Access Restriction{" "}
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  Litzapp reserves the right, in its sole discretion, to
                  terminate your access to the Platform and the related services
                  or any portion thereof at any time, without notice. To the
                  maximum extent permitted by law, this agreement is governed by
                  the laws of the State of Florida and you hereby consent to the
                  exclusive jurisdiction and venue of courts in Florida in all
                  disputes arising out of or relating to the use of the
                  Platform. Use of the Platform is unauthorized in any
                  jurisdiction that does not give effect to all provisions of
                  these Terms, including, without limitation, this section.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  You agree that no joint venture, partnership, employment, or
                  agency relationship exists between you and Litzapp as a result
                  of this agreement or use of the Platform. Litzapp&#39;s
                  performance of this agreement is subject to existing laws and
                  legal process, and nothing contained in this agreement is in
                  derogation of Litzapp&#39;s right to comply with governmental,
                  court and law enforcement requests or requirements relating to
                  your use of the Platform or information provided to or
                  gathered by Litzapp with respect to such use. If any part of
                  this agreement is determined to be invalid or unenforceable
                  pursuant to applicable law including, but not limited to, the
                  warranty disclaimers and liability limitations set forth
                  above, then the invalid or unenforceable provision will be
                  deemed superseded by a valid, enforceable provision that most
                  closely matches the intent of the original provision and the
                  remainder of the agreement shall continue in effect.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  Unless otherwise specified herein, this agreement constitutes
                  the entire agreement between the user and Litzapp with respect
                  to the Platform and it supersedes all prior or contemporaneous
                  communications and proposals, whether electronic, oral or
                  written, between the user and Litzapp with respect to the
                  Platform. A printed version of this agreement and of any
                  notice given in electronic form shall be admissible in
                  judicial or administrative proceedings based upon or relating
                  to this agreement to the same extent and subject to the same
                  conditions as other business documents and records originally
                  generated and maintained in printed form. It is the express
                  wish of the parties that this agreement and all related
                  documents be written in English.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c0">Changes to Terms</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  Litzapp reserves the right, in its sole discretion, to change
                  the Terms under which Litzapp is offered. The most current
                  version of the Terms will supersede all previous versions.
                  Litzapp encourages you to periodically review the Terms to
                  stay informed of our updates.
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c0">Contact Us</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  Litzapp welcomes your questions or comments regarding the
                  Terms:
                </span>
              </p>
              <br />

              <p class="payment-terms-c2">
                <span class="payment-terms-c1">Remote Analytics Jobs Inc.</span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  126 Southwest 17th Road, Unit 509
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">Miami, Florida 33129</span>
              </p>
              <br />

              <p class="payment-terms-c2">
                <span class="payment-terms-c1">Email Address:</span>
              </p>
              <p class="payment-terms-c2">
                <span class="c8">
                  <a
                    class="c7 text-blue-900 underline"
                    href="mailto:customerservices@spewhub.com"
                  >
                    customerservices@Litzapp.com
                  </a>
                </span>
              </p>

              <p class="payment-terms-c2">
                <span class="payment-terms-c1">
                  Effective as of February 11, 2023
                </span>
              </p>
              <p class="payment-terms-c2">
                <span class="payment-terms-c1">&nbsp;</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
