import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BasicButton from "../../../components/buttons/basic-button";
import TextField from "../../../components/inputs/text-input";
import TextWholeNumberField from "../../../components/inputs/text-whole-number";
import Pagination from "../../../components/pagination";
import useDebounce from "../../../components/debounce/debounce-helper";
import LoadingProgress from "../rich-page/components/loading-progress";
import Checkbox from "../../../components/inputs/checkbox";
import { useSendMailStore } from "../../../stores/send-mail-store";
import StripeEmailDispatchPageForm from "../../../payment/email-dispatch-pay-button";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";
import {
  isAdmin,
  API_BASE,
  isPublisher,
  isAuthUser,
  authUserData,
  getLocation,
} from "../../../Utils/helpers";
import { toast } from "react-toastify";
import Books from "../series/books";

export default function PubEmailPurchase() {
  const [currentPage, setCurrentPage] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [paginateMode, setPaginateMode] = useState(false);
  const [user, setUser] = useState({});
  const [blockedIds, setBlockedIds] = useState([]);
  const mountedRef = useRef();
  const navigate = useNavigate();
  const [price, setPrice] = useState();
  const mailStore = useSendMailStore((state) => state);
  const { type, model } = useParams();

  const fetchUser = () => {
    const url = API_BASE + "/user/" + authUserData().uuid;
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setUser(response.data.user);
          if (response.data.user.emailblocklist)
            setBlockedIds(
              response.data.user.emailblocklist.map((d) => d.subscriber_id)
            );
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  const handlePriceChange = (e) => {
    setPrice(e);
  };

  const redirectToEmailPage = () => {
    navigate("/publisher/mailing/send/" + model);
  };

  const unloadCallback = (event) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
  };

  useEffect(() => {
    if (!isPublisher()) {
      window.location.href = "/";
    }
    fetchUser();
    if (!mailStore.isAllSelected && mailStore.selectedCount == 0) {
      if (model == "purchases") {
        window.location.href = "/publisher/purchases";
      }
      if (model == "subscribers") {
        window.location.href = "/publisher/subscribers";
      }
      if (model == "donations") {
        window.location.href = "/publisher/donations";
      }
      if (model == "email-subscribers") {
        window.location.href = "/publisher/subscriptions";
      }
      if (model == "trackers") {
        window.location.href = "/publisher/trackers";
      }
      if (model == "product-purchases") {
        window.location.href = "/product-transactions/single";
      }
      if (model == "product-subscriptions") {
        window.location.href = "/product-transactions/recurring";
      }
    }
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">Email Purchase</h1>
          </div>
          <div className="flex justify-between gap-2 items-start">
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full md:w-8/12">
            {type == "limit" ? (
              <>
                {" "}
                <p>
                  <b className="font-bold">{user?.first_name}</b>, you intend to
                  send an email to {mailStore?.selectedCount} recipients, but
                  currently, you only have 0 Email Dispatches available.
                </p>
                <p className="my-4">
                  To proceed with your email send, kindly acquire the required
                  number of Email Dispatches below.
                </p>
              </>
            ) : (
              ""
            )}

            <p className="my-4">
              Please input the desired dollar amount you wish to pay in the
              designated price box below. The amount you enter will determine
              the quantity of email dispatches you will purchase. Kindly note
              that only whole numbers such as 1, 3, or 4, etc., are allowed in
              the price box. Decimals like 0.5, 3.8, or 6.34, etc., are not
              permitted.
            </p>

            <p className="my-4 text-center">
              {" "}
              <b className="font-bold">$1</b> ={" "}
              <b className="font-bold">
                {user?.system_setting?.email_dispatch_limit ?? ""}
              </b>{" "}
              Email Dispatches.
            </p>
            <div
              className={`flex flex items-center justify-center w-full gap-2`}
            >
              <label for="timezone" value="Your Timezone" className="font-bold">
                $
              </label>
              <TextWholeNumberField
                classes={"p-6 my-2 w-6/12"}
                placeholder={"Price.."}
                handleChange={handlePriceChange}
                value={price}
              />
              <label for="timezone" value="Your Timezone" className="font-bold">
                =
              </label>
              <label for="timezone" value="Your Timezone" className="font-bold">
                {price
                  ? Number(price) *
                      Number(user?.system_setting?.email_dispatch_limit || 0) +
                    " Email Dispatches"
                  : ""}
              </label>
            </div>

            <div className="w-full flex justify-center">
              <StripeEmailDispatchPageForm
                data={{
                  name: "Email Dispatch Purchase",
                  price: price,
                  description: "Purchased dispatch units to send emails.",
                }}
                classes={"bg-teal-500 hover:bg-teal-700 text-white shadow my-2"}
                disabled={!price || price == 0}
                action={redirectToEmailPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
