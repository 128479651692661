import AdSense from "react-adsense";
export default function AdsenseBar() {
  return (
    <AdSense.Google
      client="ca-pub-8191791319921859"
      slot={"7381475967"}
      style={{ width: 250, height: 250, float: "left" }}
      format=""
    />
  );
}
