import axios from "axios";
import { useEffect, useState } from "react";
import BasicButton from "../../../../../components/buttons/basic-button";
import authRequestService from "../../../../../services/HttpRequests/authenticated-request-services";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import { API_BASE, isSupplier, isAuthUser } from "../../../../../Utils/helpers";
import { toast } from "react-toastify";

export default function PubSupplierMyContractsAdd() {
  const [fileSelected, setFileSelected] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");
  const [location, setLocation] = useState("");
  const [name, setName] = useState("");
  const [processing, setProcessing] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const options = {
    componentRestrictions: { country: "ng" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
  };

  const handleGoogleLocationChange = (val) => {
    setLocation(val.label);
  };

  const handleLocationChange = () => {};
  const submit = (imageToFile) => {
    const url = API_BASE + "/publisher/supplier/contract/create";
    setProcessing(true);
    let formData = new FormData();
    formData.append("type", "product-supplier-own-contract");
    formData.append("upload_type", "not-default");
    formData.append("file", fileSelected);
    formData.append("location", location);
    formData.append("name", name);
    authRequestService()
      .post(url, formData)
      .then((res) => {
        setProcessing(false);
        setSubmitted(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      })
      .catch((err) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const removeCover = () => {
    setFileSelected("");
  };

  const uploadCover = (e) => {
    let [file] = e.target.files;

    if (file) {
      if (!file["type"].includes("pdf")) {
        toast("Invalid file type, please upload (PDF)", {
          type: "error",
        });
        return false;
      } else {
        setSelectedFileType("pdf");
        setFileSelected(file);
      }
    }
  };

  useEffect(() => {
    if (!isAuthUser()) {
      window.location.href = "/login";
    }
    if (!isSupplier()) {
      window.location.href = "/";
    }
  }, []);
  return (
    <>
      <div className="main w-full">
        <div className="bg-white m-2 p-2 flex justify-between shadow px-4">
          <div>
            <h1 className="text-2xl text-center m-2 bold"> Add Contract</h1>
          </div>
          <div>
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center my-4 overflow-hidden">
          {!submitted && (
            <div className="p-2 w-full md:w-6/12">
              <div id="input" class="flex flex-col w-full  my-5">
                <label for="password" class="text-gray-500 mb-2">
                  Contract Name
                </label>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  id="identit-name"
                  placeholder={`Enter Contract Name..`}
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                />
              </div>
              <div className="m-2 text-center">
                <label className="text-black m-2">Upload Contract</label>
                <div className="flex justify-center my-2">
                  {!fileSelected && (
                    <div className="w-32 h-32 bg-gray-100 p-2">
                      <div className="flex flex-col justify-center  h-full">
                        <i class="fa fa-plus cursor-pointer text-green-500"></i>
                        <p className="text-xs">Click to upload a pdf</p>

                        <label className="my-2">
                          <input
                            type="file"
                            onChange={(e) => uploadCover(e)}
                            className="text-sm 
                        text-grey-500
                        file:mr-2
                        file:py-2
                        file:px-2
                        file:rounded-full
                        file:border-0
                        file:text-md
                        file:font-semibold
                        file:text-white
                        file:bg-gradient-to-r
                        file:from-blue-600
                        file:to-amber-600
                        hover:file:cursor-pointer
                        hover:file:opacity-80"
                          />
                        </label>
                      </div>
                    </div>
                  )}

                  {fileSelected && selectedFileType == "pdf" && (
                    <div className="w-32 h-32 bg-gray-100 p-2 relative">
                      <img src={"/pdf-icon.png"} className="w-full" />
                      <div
                        className="bg-white rounded rounded-full bg-white p-0 top-0 right-0 absolute px-1 cursor-pointer"
                        onClick={() => removeCover()}
                      >
                        <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="flex flex-col items-center mt-10">
                  <div>
                    <label className="text-black m-2">
                      Location Covered By the Contract
                    </label>
                  </div>
                  <div className="control w-full">
                    <div className="selected-value">
                      <label for="password" class="text-gray-500 mb-2">
                        Enter Business Location
                      </label>
                      <GooglePlacesAutocomplete
                        autocompletionRequest={{
                          componentRestrictions: {
                            //to set the specific country
                          },
                        }}
                        selectProps={{
                          defaultInputValue: location, //set default value
                          onInputChange: (newVal) =>
                            handleLocationChange(newVal),
                          onChange: handleGoogleLocationChange, //save the value gotten from google
                          placeholder: location,
                          styles: {
                            input: (provided) => ({
                              ...provided,
                              color: "#222222",
                            }),
                            option: (provided) => ({
                              ...provided,
                              color: "#222222",
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: "#222222",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: "#000000",
                            }),
                          },
                        }}
                        onLoadFailed={(error) => {
                          console.log(error);
                        }}
                      />
                    </div>
                  </div>
                  <small className="text-left text-red-700  w-full">
                    Enter the address that this specific contract applies to.
                  </small>
                  <div className="w-full border flex flex justify-center items-center gap-1 my-4">
                    <BasicButton
                      classes={"w-full"}
                      disabled={
                        !fileSelected || !name || !location || processing
                      }
                      title={processing ? "Submit.." : "Submit"}
                      handleClick={() => submit()}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {submitted && (
            <div className="p-2 w-full md:w-6/12 ">
              <p>
                Your Contract Approval Request has been successfully submitted
                and is currently being reviewed. You can expect a response
                within a few hours to a few days. We are committed to processing
                your contract as swiftly as possible. Once an action has been
                taken on your request, you will receive an email notification.
                Please remember to check your spam folder as well.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
