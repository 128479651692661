import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState } from "react";
import WebHeader from "./web/header";
import axios from "axios";
import BasicButton from "../components/buttons/basic-button";
import StripeForm from "../payment/pay-button";
import { API_BASE } from "../Utils/helpers";
import Timezones from "../components/timezone/timezone";
import StripeSubscriptionModal from "../modals/stripe-subscription-modal";
import { useParams } from "react-router-dom";
import {
  isAuthUser,
  authUserData,
  subscritionCostWithSystemFees,
} from "../Utils/helpers";
import Countries from "../components/country/country";
import { useBookStore } from "../stores/book-store";

export default function Register3({ action, action2 }) {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [country, setCountry] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [subSecretKey, setSubSecretKey] = useState();
  const [showSubModal, setShowSubModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameMode, setUsernameMode] = useState(false);
  const [type, setType] = useState("modal");
  const naviagte = useNavigate();
  const { setSubModalOpen } = useBookStore((state) => state);

  const validateUser = () => {
    setIsAuthenticating(true);
    const url = API_BASE + "/api/user/update/validate-username";
    if (
      firstName.toLowerCase().includes("litzapp") ||
      firstName.toLowerCase().includes("litzap") ||
      lastName.toLowerCase().includes("litzapp") ||
      lastName.toLowerCase().includes("litzap")
    ) {
      toast(
        "Sorry! Litzapp cannot be used in First Name Or Last Name Field(s)!",
        {
          type: "error",
        }
      );
      return;
    }
    axios
      .post(url, {
        first_name: firstName,
        last_name: lastName,
        username: username,
      })
      .then((response) => {
        if (response.status == 200) {
          action();
          action2(response.data.user_id);
        }
        setIsAuthenticating(false);
      })
      .catch((error) => {
        if (error.response.status == 422) {
          if ("invalid_data" in error.response.data) {
            toast(
              "One of the entries appears to be incorrect. Please recheck them and attempt again.!",
              {
                type: "error",
              }
            );
          }

          if ("registration_complete" in error.response.data) {
            toast(
              "This username is fully registered. Please login or use the Forgot Password link!",
              {
                type: "error",
              }
            );
          }
        } else {
          toast("Something went wrong!", { type: "error" });
        }
        setIsAuthenticating(false);
      });
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e);
  };

  const handleLastNameChange = (e) => {
    setLastName(e);
  };

  const handleUsernameChange = (e) => {
    setUsername(e);
  };

  const handleValidation = () => {
    validateUser();
  };

  const shouldDisableButton = () => {
    return isAuthenticating || !firstName || !lastName || !username;
  };

  return (
    <>
      <div class="container px-6 mx-auto">
        <br />
        <div class="flex flex-col text-left justify-evenly items-center">
          <div class="flex flex-col w-full items-center">
            <h1 class="text-2xl text-gray-800 font-bold my-2">
              Account Verification
            </h1>
          </div>
          <div
            className={`w-full ${
              type !== "modal" ? "md:w-6/12 " : "md:w-10/12 "
            }${type !== "modal" ? "lg:w-5/12" : "lg:w-10/12"} mx-auto md:mx-0`}
          >
            <div class="bg-white p-10 flex flex-col w-full shadow-xl rounded-xl">
              {/* <h2 class="text-2xl font-bold text-gray-800 text-left mb-5">
                Sigin
              </h2> */}
              <form action="" class="w-full ">
                <div id="input" class="flex flex-col items-start w-full my-5">
                  <label for="username" class="text-gray-500 mb-2 ">
                    Username
                  </label>
                  <input
                    onChange={(e) => handleUsernameChange(e.target.value)}
                    type="text"
                    id="username"
                    value={username}
                    placeholder="Please insert your Username"
                    class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full"
                  />
                </div>
                <div id="input" class="flex flex-col items-start w-full my-5">
                  <label for="username" class="text-gray-500 mb-2 ">
                    First Name
                  </label>
                  <input
                    onChange={(e) => handleFirstNameChange(e.target.value)}
                    type="text"
                    id="username"
                    value={firstName}
                    placeholder="Please insert your First Name"
                    class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full"
                  />
                </div>
                <div id="input" class="flex flex-col items-start w-full my-5">
                  <label for="username" class="text-gray-500 mb-2">
                    Last Name
                  </label>
                  <input
                    onChange={(e) => handleLastNameChange(e.target.value)}
                    type="text"
                    id="username"
                    value={lastName}
                    placeholder="Please insert your Last Name"
                    class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full"
                  />
                </div>

                <div id="button" class="flex flex-col w-full my-5">
                  <button
                    disabled={shouldDisableButton()}
                    onClick={() => {
                      handleValidation();
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    type="button"
                    class={`w-full py-4  ${
                      shouldDisableButton() ? "bg-gray-400" : "bg-black"
                    } rounded-lg text-green-100`}
                  >
                    {isAuthenticating ? "Processing.." : "Continue"}
                  </button>
                  {/* <div class="flex justify-evenly mt-5">
                  <a
                    href="#"
                    class="w-full text-center font-medium text-gray-500"
                  >
                    Recover password!
                  </a>
                  <a
                    href="#"
                    class="w-full text-center font-medium text-gray-500"
                  >
                    Singup!
                  </a>
                </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
