import { IdentityOptions } from "./identity-options-list";
import { useState, useEffect } from "react";
import authRequestService from "../../../../services/HttpRequests/authenticated-request-services";
import { API_BASE, authUserData } from "../../../../Utils/helpers";
import BasicButton from "../../../../components/buttons/basic-button";
import { useRef } from "react";
export default function SupplierIDLinkVerification(props) {
  const {
    options,
    stage,
    regType,
    profileLink,
    profileOption,
    handlePrevStage,
    handleNextStage,
    handleProfileLinkChange,
  } = props;

  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [savingStage, setSavingStage] = useState(false);

  const inputRef = useRef(null);

  const saveStage = () => {
    const url = API_BASE + "/publisher/supplier/stages/save";
    setSavingStage(true);
    authRequestService()
      .post(url, {
        identity_link: profileLink,
        stage: stage,
        type: regType,
        uuid: authUserData().uuid,
      })
      .then((res) => {
        handleNextStage();
      })
      .catch((err) => setSavingStage(false));
  };

  return (
    <div className="my-identity dropdown">
      <div id="input" class="flex flex-col w-full  my-5">
        <label for="password" class="text-gray-500 mb-2">
          Enter Profile Link
        </label>
        <input
          value={profileLink}
          onChange={(e) => handleProfileLinkChange(e.target.value)}
          type="text"
          id="identit-link"
          placeholder={`Paste your ${profileOption} profile link here`}
          class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
        />
      </div>
      <div className="w-full border flex flex justify-center items-center gap-1 my-1">
        <BasicButton
          handleClick={() => handlePrevStage()}
          title={"Back"}
          disabled={savingStage}
          classes={"bg-black w-full"}
        />
        <BasicButton
          handleClick={() => saveStage()}
          title={savingStage ? "Processing.." : "Next"}
          disabled={savingStage || !profileLink}
          classes={"bg-black w-full"}
        />
      </div>
    </div>
  );
}
