import axios from "axios";
import { toast } from "react-toastify";
import Pagination from "../../pagination";
import FilterBar from "./components/filter";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import BasicButton from "../../buttons/basic-button";
import { useEffect, useState, useRef } from "react";
import DesktopTable from "./components/desktop-table";
import MobileTable from "./components/mobile-table";
import useDebounce from "../../debounce/debounce-helper";
import LoadingProgress from "../../../pages/publisher/rich-page/components/loading-progress";
import { useSendMailStore } from "../../../stores/send-mail-store";
import authRequestService from "../../../services/HttpRequests/authenticated-request-services";
import authPaginatedRequestService from "../../../services/HttpRequests/authenticated-paginated-req-service";
import DeletePromptModal from "../../../modals/delete-warning-modal";

import {
  API_BASE,
  isPublisher,
  authUserData,
  isResponsiveMode,
} from "../../../Utils/helpers";

export default function PubProductAvailabilityTable() {
  const [currentPage, setCurrentPage] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [paginateMode, setPaginateMode] = useState(false);
  const [user, setUser] = useState({});
  const [blockedEmails, setBlockedEmails] = useState([]);
  const mountedRef = useRef();
  const navigate = useNavigate();
  const mailStore = useSendMailStore((state) => state);
  const [currSelSub, setCurSelSub] = useState({});
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [nonRenewals, setNonRenewals] = useState([]);

  const { metaId, date } = useParams();

  const convertToUTC = (date) => {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  };

  const correcDateFormat = (d) => {
    const day = d.getUTCDate();
    const month = d.getUTCMonth() + 1; // Months are zero-based, so add 1
    const year = d.getUTCFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    //console.log(formattedDate);
    return formattedDate;
  };

  const fetchUser = () => {
    const url = API_BASE + "/publisher/" + authUserData().uuid + "/optimized";
    setProcessing(true);
    authRequestService()
      .get(url)
      .then((response) => {
        setProcessing(false);
        if (response.status == 200) {
          setUser(response.data.publisher);
          mailStore.setUser(response.data.publisher);
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  const doPagination = (page) => {
    if (mailStore.data.first_page_url) {
      mailStore.setData([]);
      setCurrentPage(page);
      setPaginateMode(true);
      setProcessing(true);
      authPaginatedRequestService()
        .get(mailStore.data.path.replace("https://wordalbums1.test", ""), {
          params: { page: page },
        })
        .then((res) => {
          mailStore.setData(res.data.subscriptions);
          setProcessing(false);
        })
        .catch((err) => setProcessing(false));
    }
  };

  const cancelSubscription = () => {
    setProcessing(true);
    const url =
      API_BASE +
      "/publisher/product-page/my/subscription/" +
      currSelSub.subscription_id +
      "/cancel";
    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          setTimeout(() => {
            getSubscriptions();
            setProcessing(false);
            closCancelModal();
          }, 6000);
        }
      })
      .catch((error) => {
        // console.log("There was an error!", error);
        setProcessing(false);
      });
  };

  const closCancelModal = (d) => {
    setCurSelSub({});
    setCancelModalOpen(false);
  };

  const getSubscriptions = () => {
    mailStore.setData([]);
    setProcessing(true);
    setPaginateMode(false);
    const url =
      API_BASE +
      `/publisher/product-page/subscribers/availability/${metaId}` +
      "/" +
      mailStore.query.countPerPage +
      "/" +
      mailStore.query.pageLimit +
      "/" +
      date +
      "/" +
      mailStore.query.purchaseOption +
      "/" +
      mailStore.query.subscriptionCancelled +
      "/" +
      mailStore.query.searchText;

    authRequestService()
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          // console.log("xxx", response.data.subscriptions);
          mailStore.setData(response.data.subscriptions);
          setNonRenewals(response.data.non_renewals);
          setProcessing(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  const handleSearchTextChange = (e) => {
    mailStore.setQuery("searchText", e);
  };

  const handleCountPerPageChange = (e) => {
    mailStore.setQuery("countPerPage", e.target.value);
  };

  const handleCancelledChange = (e) => {
    mailStore.setQuery("subscriptionCancelled", e.target.value);
  };

  const handleLatestEntriesChange = (e) => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
    mailStore.setQuery("selectedLatest", e.target.value);
    mailStore.setQuery("pageLimit", e.target.value);
  };

  const handleOldestEntriesChange = (e) => {
    mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
    mailStore.setQuery("selectedOldest", e.target.value);
    mailStore.setQuery("pageLimit", e.target.value);
  };

  const handlePurchaseOptionChange = (e) => {
    mailStore.setQuery("purchaseOption", e.target.value);
  };

  useEffect(() => {
    mailStore.setQuery("selectedOldest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedLatest]);

  useEffect(() => {
    mailStore.setQuery("selectedLatest", mailStore.query.pageLimit);
  }, [mailStore.query.selectedOldest]);

  useDebounce(
    () => {
      if (mailStore.refresh) {
        getSubscriptions();
      }
      mailStore.setRefresh(true);
    },
    [mailStore.query],
    800
  );
  const preSubCancelPromt = (s) => {
    setCancelModalOpen(true);
    setCurSelSub(s);
  };

  useEffect(() => {
    if (!isPublisher()) {
      window.location.href = "/";
    }
    fetchUser();
  }, []);

  return (
    <>
      <div className="main">
        <div className="bg-white m-2 p-2 flex justify-between shadow">
          <div>
            <h1 className="text-2xl text-center m-2 bold">Date Availability</h1>
          </div>
          <div className="flex justify-between gap-2 items-start">
            <BasicButton
              title={"Go Back"}
              handleClick={() => window.history.back()}
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center my-4">
          <div class="rounded-t-xl overflow-hidden bg-white p-10 w-full">
            <p className="w-full text-center my-4">
              We found {mailStore?.data?.total || ""} records
            </p>
            <FilterBar
              handleCountPerPageChange={handleCountPerPageChange}
              handleSearchTextChange={handleSearchTextChange}
              serchQValue={mailStore.query.searchText}
              handleLatestEntriesChange={handleLatestEntriesChange}
              latestQValue={mailStore.query.selectedLatest}
              handleOldestEntriesChange={handleOldestEntriesChange}
              oldestQValue={mailStore.query.selectedOldest}
              handlePurchaseOptionChange={handlePurchaseOptionChange}
              purchaseOption={mailStore.query.purchaseOption}
              handleCancelledChange={handleCancelledChange}
              subscriptionCancelled={mailStore.query.subscriptionCancelled}
            />

            <div className="w-full flex justify-center">
              {mailStore?.data?.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            <LoadingProgress loading={processing} />
            {isResponsiveMode() ? (
              <MobileTable
                processing={processing}
                preSubCancelPromt={preSubCancelPromt}
                nonRenewals={nonRenewals}
              />
            ) : (
              <DesktopTable
                processing={processing}
                preSubCancelPromt={preSubCancelPromt}
                nonRenewals={nonRenewals}
              />
            )}

            <div className="w-full flex justify-center">
              {mailStore?.data?.data ? (
                mailStore.data.data.length ? (
                  <Pagination
                    pagination={mailStore.data}
                    doPagination={doPagination}
                    processing={processing}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {currSelSub.id ? (
        <DeletePromptModal
          modalOpen={cancelModalOpen}
          hideAdModal={closCancelModal}
          title={`Are you certain you wish to cancel the subscription for ${currSelSub?.subscriber?.name} ${currSelSub?.subscriber?.handle_id}?`}
          yesActionText={"Yes, Cancel the Subscription"}
          noActionText={"No, Do Not Cancel"}
          action={cancelSubscription}
          processing={processing}
        />
      ) : (
        ""
      )}
    </>
  );
}
