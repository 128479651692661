import {
  removeAtomicBlocFromEditor,
  editEditorImaeAtBlockPosition,
} from "../helpers/functions";
import { deleteEditorFileRequest } from "../API/editor-api-calls";
import { useBookStore } from "../../../../stores/book-store";
import { convertToRaw } from "draft-js";
import { toast } from "react-toastify";
import { useState } from "react";

export default function Image({
  pageId,
  blockKey,
  src,
  imageLink,
  imageId,
  editorState,
  setEditorState,
  editorStateHelper,
  showImageModalUpdateBox,
}) {
  const imageSource = src.split("custom-pages/")[1];
  const imagePath = src;

  const { isDataSubExpired } = useBookStore((state) => state);
  const deleteEditorImage = (
    pageId,
    blockKey,
    editorState,
    setEditorState,
    EditorState,
    imageSource
  ) => {
    deleteEditorFileRequest(imageSource)
      .then((response) => {
        //delete block from editor
        removeAtomicBlocFromEditor(
          blockKey,
          editorState,
          setEditorState,
          EditorState
        );
        toast("Deleted!", { type: "success" });
      })
      .catch((error) => {
        // removeAtomicBlocFromEditor(
        //   blockKey,
        //   editorState,
        //   setEditorState,
        //   EditorState
        // );
        toast("Something went wrong!", { type: "error" });
      });
  };

  const checkKeyMatch = () => {
    // const contentState = editorState.getCurrentContent();
    // let rawContent = convertToRaw(contentState);
    // for (let i = 0; i < rawContent.blocks.length; i++) {
    //   if (rawContent.blocks[i]["key"] == blockKey) {
    //     let b = rawContent.blocks[i];
    //     if (b["type"] !== "unstyled" && b.entityRanges.length === 1) {
    //       const entityKey = b["entityRanges"][0]["key"];
    //       const entityMap = rawContent["entityMap"][entityKey];
    //       if (entityMap["type"] === "image") {
    //         rawContent["entityMap"][entityKey]["data"]["src"] = url;
    //         rawContent["entityMap"][entityKey]["data"]["imageLink"] = link;
    //       }
    //     }
    //   }
    // }
  };

  return (
    <div class="flex flex-wrap justify-center">
      <div className="relative">
        {!isDataSubExpired ? (
          <span
            className="bg-white rounded rounded-full bg-white p-0 right-0 absolute px-1 cursor-pointer"
            style={{ top: -19, right: 40 }}
          >
            <i
              class="fa fa-edit text-blue-500  z-50 text-2xl"
              onClick={() =>
                showImageModalUpdateBox(imagePath, imageLink, blockKey)
              }
            ></i>
          </span>
        ) : (
          ""
        )}

        {!isDataSubExpired ? (
          <span
            className="bg-white rounded rounded-full bg-white p-0  right-0 absolute px-1 cursor-pointer"
            style={{ top: -19, right: 0 }}
            onClick={() =>
              deleteEditorImage(
                pageId,
                blockKey,
                editorState,
                setEditorState,
                editorStateHelper,
                imageSource
              )
            }
          >
            <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
          </span>
        ) : (
          ""
        )}
        <img src={src} class="p-1 bg-white border rounded max-w-sm" />
      </div>
    </div>
  );
}
