import TextField from "../components/inputs/text-input";
import BasicButton from "../components/buttons/basic-button";
import TermsAccordion from "../accordions/terms-accordion";
import { useBookStore } from "../stores/book-store";
import { forwardRef, useState, useEffect } from "react";
import StripeForm from "../payment/pay-button";
import Register from "../pages/register";
import { isAuthUser } from "../Utils/helpers";

export default function RegistrationModal({
  modalOpen,
  hideModal,
  book,
  paymentType,
}) {
  const { subModalOpen, isUserAuthenticated, setIsUserAuthenticated } =
    useBookStore((state) => state);
  const [showAuthButtons, setShowAuthButtons] = useState(true);

  useEffect(() => {}, []);

  return (
    <div
      className={`absolute z-50  top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class={`relative inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-10/12 ${
            !subModalOpen ? "h-4/12" : "h-screen"
          }`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {!isUserAuthenticated ? (
            <div
              className="rounded rounded-full p-0 -top-2 -right-1 absolute px-1 cursor-pointer opacity-75"
              onClick={() => hideModal()}
            >
              <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
            </div>
          ) : (
            ""
          )}
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex justify-center">
            {/* modal body */}
            <Register
              type={`modal`}
              action={hideModal}
              book={book}
              paymentType={paymentType}
            />
            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 flex justify-center items-center">
            {/* <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => hideModal()}
            >
              <i class="fas fa-times"></i> Close
            </button> */}

            {!isUserAuthenticated ? (
              <a href="/login">
                <button
                  type="button"
                  class="py-2 px-4 bg-teal-500 text-white rounded hover:bg-teal-700 mr-2"
                >
                  <i class="fas fa-times"></i> Login
                </button>
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
