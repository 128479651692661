import axios from "axios";
import { API_BASE } from "../../../../Utils/helpers";

export const deleteEditorFileRequest = (imageSource) => {
  const url = API_BASE + "/api/custom-page/files/delete";
  let formData = new FormData();
  formData.append("file", imageSource);
  formData.append("type", "image");
  return axios.post(url, formData);
};

export const deleteEditorVideoFileRequest = (videoId) => {
  const url = API_BASE + "/api/custom-page/files/delete";
  let formData = new FormData();
  formData.append("video_id", videoId);
  formData.append("type", "video");
  return axios.post(url, formData);
};
