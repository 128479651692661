import { countryList } from "./list";
export default function Countries({ handleCountryChange, value }) {
  return (
    <div class="mt-1">
      <label for="timezone" value="Your Timezone" />
      <select
        onChange={handleCountryChange}
        required
        class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-2 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg w-full"
      >
        <option value=" " default disabled selected>
          Choose an option
        </option>
        {countryList.map((country) => (
          <option value={JSON.stringify(country)}>{country.country}</option>
        ))}
      </select>
    </div>
  );
}
