import Checkbox from "../../../../../../components/inputs/checkbox";
import "react-datepicker/dist/react-datepicker.css";
import "react-tooltip/dist/react-tooltip.css";
import BasicButton from "../../../../../../components/buttons/basic-button";
import { BASE_URL } from "../../../../../../Utils/helpers";
import TextArea from "../../../../../../components/inputs/text-area";
import TextField from "../../../../../../components/inputs/text-input";
import { useEffect } from "react";
export default function BannerModal(props) {
  const { modalOpen } = props;
  const { hideAdModal } = props;
  const { bannerSlides } = props;
  const { productSlides } = props;
  const { handleCheckBannerSlide } = props;
  const { bannerSlideSelected } = props;
  const { getSelBannerSlideInfo } = props;
  const { addBannerToEditor } = props;
  const { updateBannerInEditor } = props;
  const { pageContext } = props;
  const { handleBannerTextChange } = props;
  const { handleBannerUrlChange } = props;
  const { createBannerMode } = props;

  useEffect(() => {}, [bannerSlides]);
  const getImageURL = (slide) => {
    if (pageContext == "create") {
      return URL.createObjectURL(slide.file);
    } else {
      return BASE_URL + "/storage" + slide.file.split("public")[1];
    }
  };

  return (
    <div
      className={`absolute z-50 overflow-y-auto top-0 w-full left-0 ${
        modalOpen ? "" : "hidden"
      } id="modal"`}
    >
      <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          class="inline-block align-center bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-6/12 h-4/12 P-2"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className="rounded rounded-full p-0 -top-2 -right-1 absolute px-1 cursor-pointer opacity-75"
            onClick={() => hideAdModal()}
          >
            <i class="fa fa-times-circle text-red-500  z-50 text-2xl"></i>
          </div>
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/* modal body */}
            <div className="p-2">
              <h1 className="text-2xl font-bold text-center flex items-center justify-center">
                {" "}
                <span>{createBannerMode ? "Create Banner" : "Update"}</span>
              </h1>
              <div className="flex gap-2 w-full justify-center">
                <div className="flex gap-1"></div>
              </div>

              <div className="flex flex-col w-full justify-center ">
                {productSlides.length ? (
                  <div className="w-full p-2 my-2 h-64 overflow-auto border">
                    <div className="-m-1 grid grid-cols-1 md:grid-cols-2 md:-m-2 py-3">
                      {productSlides.map((slide, parentIndex) => {
                        return (
                          <div className="flex flex-col items-center bg-gray-100 m-2 p-2">
                            {" "}
                            <div className="flex items-center">
                              <Checkbox
                                handleChange={() =>
                                  handleCheckBannerSlide(slide?.id)
                                }
                                checked={bannerSlideSelected(slide?.id)}
                              />
                              <div class="w-full p-1 md:p-2">
                                {slide?.file && (
                                  <img
                                    alt="gallery"
                                    class="block h-full w-full rounded-lg object-cover object-center"
                                    src={getImageURL(slide)}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="w-full">
                              {" "}
                              <p>Banner Text</p>
                              <TextArea
                                disabled={!bannerSlideSelected(slide?.id)}
                                placeholder={"Enter text.."}
                                handleChange={(e) => {
                                  handleBannerTextChange(e, "text", slide?.id);
                                  /* handleSocialsLinkChange(e, "instagram", "text") */
                                }}
                                value={
                                  !bannerSlideSelected(slide?.id)
                                    ? " "
                                    : bannerSlides[
                                        getSelBannerSlideInfo(slide?.id)
                                      ]?.text
                                }
                              />
                            </div>
                            <div className="w-full">
                              <p>Banner URL</p>
                              <TextField
                                disabled={!bannerSlideSelected(slide?.id)}
                                placeholder={"Enter URL.."}
                                handleChange={(e) => {
                                  handleBannerUrlChange(e, "url", slide?.id);
                                }}
                                value={
                                  !bannerSlideSelected(slide?.id)
                                    ? ""
                                    : bannerSlides[
                                        getSelBannerSlideInfo(slide?.id)
                                      ]?.url
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <BasicButton
                handleClick={
                  createBannerMode ? addBannerToEditor : updateBannerInEditor
                }
                disabled={!bannerSlides.length}
                title={createBannerMode ? "Create Banner" : "Update Banner"}
                classes={"my-3"}
              />
            </div>
            {/* {!createProductMetaMode ? productMeta.p_type_sub_opt : "Invalid"} */}

            {/* modal body */}
          </div>
          <div class="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => {
                hideAdModal();
              }}
            >
              <i class="fas fa-times"></i> Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
